<template>
	<div class="singleCardQuery">
		<div class="title-box clearfix">
			<span class="m-title">单卡查询</span>
			<el-form :inline="true" :model="formInline" class="hook right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="ICCID号/短号：">
					<el-input v-model="formInline.iccidMark1" class="iccid-width"></el-input>
				</el-form-item>
				<el-form-item label="接入号：">
					<el-input v-model="formInline.phone1"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询</el-button>
				</el-form-item>
		         <el-form-item>
					<el-button type="primary"  class="themed-button" @click="clearHistory"   >清除缓存</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- <div class="group">
			<h1 class="title">基本信息</h1>
			<div>
				<table cellspacing="0" cellpadding="0" style="width: 100%" >
					<tr>
						<td>ICCID</td>
						<td>{{ data.iccidMark }}</td>
						<td>套餐类型</td>
						<td>{{ data.mealTypeStr }}</td>
						<td>套餐总流量</td>
						<td v-html="totalFlow('1')"></td>
					</tr>
					<tr>
						<td>接入号</td>
						<td>{{ data.phone }}</td>
						<td>基础套餐</td>
						<td>{{ data.cardPackage }}</td>
						<td>过期时间</td>
						<td>{{ data.expireDate }}</td>
					</tr>
					<tr>
						<td>运营商</td>
						<td>{{ data.accountName }}</td>
						<td>网卡状态</td>
						<td>
							<span v-if="data.cuankaFlag === 1">窜卡</span>
							<span v-else-if="data.status === 1">待激活</span>
							<span v-else-if="data.status === 2">正常</span>
							<span v-else-if="data.status === 3">断网</span>
							<span v-else-if="data.status === 4">停机</span>
							<span v-else-if="data.status === 5">异常</span>
							<span v-else-if="data.status === 6">强制停机</span>
							<span v-else-if="data.status === 7">注销</span>
						</td>
						<td>已使用流量</td>
						<td>{{flowCnt}}</td>
					</tr>
					<tr>
						<td>卡类型</td>
						<td>
							<span v-if="data.type == 1">包月</span>
							<span v-else-if="data.type == 2">包年</span>
						</td>
		
						<td>手机绑定</td>
						<td>
							<span v-if="res.phonebind">{{ res.phonebind }}</span>
							<span v-else>未绑定</span>
						</td>
						<td>剩余流量</td>
						<td v-html="totalFlow('2')">
						</td>
					</tr>
		
					<tr>
						<td>总语音分钟</td>
						<td v-html="totalFlow('4')"></td>
						<td>已使用语音分钟</td>
						<td>
							<span v-if="voiceUsed === 'NaN分钟'"></span>
							<span v-else>{{voiceUsed}}</span></td>
		
						<td>剩余语音分钟</td>
						
						<td v-html="totalFlow('3')"></td>
					</tr>
		
					<tr>
						<td>导入时间</td>
						<td>
							<span v-if="data.createDateTime==undefined"></span>
							<span v-else>{{ importTime }}</span></td>
						<td>当前套餐</td>
						<td v-html="currentMeal"></td>
						<td>代理商</td>
						<td>
							<span v-if="data.agentName==null||data.agentName==''">未分配</span>
							<span v-else>{{ data.agentName }}</span>
						</td>
					</tr>
					<tr>
						<td>实名状态</td>
						<td>
							<span v-if="isReal == 1">已实名</span>
							<span v-else-if="isReal == 2">未实名</span>
							<span v-else>—未知状态—</span>
							<i class="el-icon-refresh-right" @click="_isRealName"></i>
						</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
            		</tr>
		
				</table>
			</div>
		</div> -->
		 <div class="group">
        <h1 class="title">基础信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
				<td>ICCID</td>
				<td>{{ data.iccidMark }}</td>
            <td>接入号</td>
			<td>{{ data.phone }}</td>
			 <td>套餐类型</td>
			<td>{{ data.mealTypeStr }}</td>
			  <td>过期时间</td>
			  <td>{{ data.expireDate }}</td>
             </tr>
			  <tr>
				<td>运营商</td>
				<td>{{ data.accountName }}</td>
            <td>网卡状态</td>
					<td>
							<span v-if="data.cuankaFlag === 1">窜卡</span>
							<span v-else-if="data.status === 1">待激活</span>
							<span v-else-if="data.status === 2">正常</span>
							<span v-else-if="data.status === 3">断网</span>
							<span v-else-if="data.status === 4">停机</span>
							<span v-else-if="data.status === 5">异常</span>
							<span v-else-if="data.status === 6">强制停机</span>
							<span v-else-if="data.status === 7">注销</span>
						</td>
			 <td>代理商</td>
				<td>
							<span v-if="data.agentName==null||data.agentName==''">未分配</span>
							<span v-else>{{ data.agentName }}</span>
						</td>
			 <td>导入时间</td>
				<td>
							<span v-if="data.createDateTime==undefined"></span>
							<span v-else>{{ importTime }}</span></td>
             </tr>
			  <tr>
				
           
			  <td>卡类型</td>
			<td>
							<span v-if="data.type == 1">包月</span>
							<span v-else-if="data.type == 2">包年</span>
						</td>
						<td>绑定手机</td>
				<td>
							<span v-if="res.phonebind">{{ res.phonebind }}</span>
							<span v-else>未绑定</span>
						</td>
						 <td>实名状态</td>
			<td>
							<span v-if="isReal == 1">已实名</span>
							<span v-else-if="isReal == 2">未实名</span>
							<span v-else>—未知状态—</span>
							 <el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="_isRealName" style="margin-left:3px"></el-button>
						</td>
					 <td>提前停机</td>
              <td>
                <span v-if="stopAheadDays!=0">{{stopAheadDays+"天"}}</span>
               
                <span v-else>—</span>
                <el-button
                  icon="el-icon-refresh-right"
                  type="primary"
                  class="themed-button"
                  @click="getStopAheadDaysInfo"
                  style="margin-left: 3px"
                ></el-button>
                <!-- <i class="el-icon-refresh-right" @click="_isRealName"></i> -->
              </td>
             </tr>
			  
           </table>
        </div>
      </div>
	    <div class="group">
        <h1 class="title">流量信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
            <td>套餐总流量</td>
			<td v-html="totalFlow('1')"></td>
			 <td>已使用流量</td>
			<td>{{flowCnt}}</td>
			<td>剩余流量</td>
		<td v-html="totalFlow('2')">
			<td>基础套餐</td>
			<td>{{ data.cardPackage }}</td>
             </tr>
			 <tr>
				<td>总语音(分钟)</td>
						<td v-html="totalFlow('4')"></td>
						<td>已使用语音(分钟)</td>
						<td>
							<span v-if="voiceUsed === 'NaN分钟'"></span>
							<span v-else>{{voiceUsed}}</span></td>
						<td>剩余语音(分钟)</td>
					<td v-html="totalFlow('3')"></td>
					<td></td>
					<td></td>
			 </tr>
			
           </table>
        </div>
      </div>
	    <div class="group">
        <h1 class="title">当前套餐</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
           <!-- <td v-html="currentMeal"></td> -->
		   <td>
                <p>当前生效套餐：</p>
                <span v-html="currentMeal"></span>
                <p>待生效套餐：</p>
                <span v-html="willMeal"></span>
              </td>
             </tr>
           </table>
        </div>
      </div>
		
		<div class="group">
			<h1 class="title">常规操作</h1>
			<div>
				<ul class="clearfix">
					<li @click="mealRecharge">
						<img src="../cardManagement/1.png" alt="" />
						<p>套餐充值</p>
					</li>
					<li @click="znzd">
						<img src="../cardManagement/2.png" alt="" />
						<p>智能诊断</p>
					</li>
		
					<li @click="whiteNubmerSet">
						<img src="../cardManagement/7.png" alt="" />
						<p>白名单设置</p>
					</li>
		
				</ul>
			</div>
		</div>
		<el-dialog title="套餐充值" :visible.sync="dialogAddPackage" :destroy-on-close="true" width="60%">
			<add-package :addPackageData="addPackageData" :curInfor="formInline" @close="dialogAddPackage = false"></add-package>
		</el-dialog>
		<el-dialog title="白名单设置" :visible.sync="dialogWhiteVoice" v-if="dialogWhiteVoice">
        <white-voice :iccidMark="data.iccidMark" :phone="data.phone" @close="dialogWhiteVoice = false"  ></white-voice>
      </el-dialog>
	</div>

</template>

<script>
	import {
		findCardInfo,
		getPackageAll,
		isRealName,
		clearHistory,
		getStopAheadDays
	} from "@/api/cardManagement/cardBillDetail.js";
	import {
		intelliDiagnose
	} from "@/api/packageRecharge.js"
	import AddPackage from "@/views/packageRecharge/addPackage"
	import WhiteVoice from "@/views/cardManagement/cardBillDetail/whiteVoice.vue"
	import loading from "@/utils/Loading.js"
	import {
		messageBox,
		confirmBox
	} from "@/utils/common.js";
	export default {
		name:'singleCarddailishang',
		data() {
			return {
				isReal:0,
				formInline: {
					iccidMark1: "",
					phone1: "",
					iccidOrPhone: "",
					setMealId: "",
					pckCode: "",
					cardType: "",
					isChild: "",
					codeChild: "",
					status: 0,
					type: 0,
				},
				res: {},
				data: {},
				accountEntity: {},
				cardLiftList: [],
				nextCardLifeList: [],
				tianDjbCardLifes: [],
				dialogAddPackage: false,
				addPackageData: {},
				znzd_ing: false,
				dialogWhiteVoice:false,
				stopAheadDays:0,
			}
		},
		computed: {
			flowCnt() {
				let flowCnt = this.data.totalBytesCnt;
				if (flowCnt && flowCnt != "") {
					flowCnt = parseFloat(flowCnt).toFixed(2);
				}else if(flowCnt==undefined){
					flowCnt=""
				}
				return flowCnt + "MB"
			},
			voiceUsed() {
				let flowCnt_voice = parseInt(this.data.totalBytesCntVoice)
				if (flowCnt_voice && flowCnt_voice != "") {
					flowCnt_voice = parseFloat(flowCnt_voice).toFixed(0);
				}
				return flowCnt_voice + "分钟"
			},
			gparams() {
				this.formInline.iccidOrPhone = this.data.iccidMark
				this.formInline.setMealId = this.data.setMealId
				this.formInline.isChild = this.data.isChild
				this.formInline.codeChild = this.data.codeChild
				this.formInline.cardType = this.data.category
				this.formInline.status = this.data.status
				this.formInline.type = this.data.type
			},
			importTime() {
				let createDateTime = this.data.createDateTime + "";
				return (
					createDateTime.substr(0, 4) +
					"-" +
					createDateTime.substr(4, 2) +
					"-" +
					createDateTime.substr(6, 2) +
					" " +
					createDateTime.substr(8, 2) +
					":" +
					createDateTime.substr(10, 2) +
					":" +
					createDateTime.substr(12, 2)
				);
			},
		  willMeal() {
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix < compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      if (this.nextCardLifeList.length > 0) {
        //  msg+="待生效套餐<br>"
        this.nextCardLifeList.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }
      if (this.tianDjbCardLifes.length > 0) {
        this.tianDjbCardLifes.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }

      return msg;
    },
    currentMeal() {
      //当前套餐
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      return msg;
    },
			
		},
		methods: {
		getStopAheadDaysInfo(){
       getStopAheadDays({iccid:this.iccidMark}).then(res=>{
          console.log(res)
          this.stopAheadDays  = res.stopAheadDays
       })  
    },
			clearHistory(){
      clearHistory({iccid:this.data.iccidMark}).then(res=>{
           if (res.system_result_key == '0') {
             messageBox(this,"清除成功")
			 console.log(this.data.iccidMark)
			 if(this.data.iccidMark|| this.data.phone){
              if( this.data.iccidMark||this.data.phone){
				let params={}
					if (this.data.iccidMark) {
					params.iccidOrPhone = this.data.iccidMark
				} else if (this.data.phone) {
					params.iccidOrPhone = this.data.phone
				}
				this._findCardInfo(params); 
			 }    
			 }
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
      })
    },
			totalFlow(type) {
				var currSetmealFlow = 0;
				var currSetmealName = "";
				var list = this.cardLiftList;
				//  responseResult.entity.voiceSize=0;
				if (list) {
					var isLongDiKa = false;
					var isMixedLeiJiMeal = false;
					if (list && list.length > 0) {
						for (var i = 0; i < list.length; i++) {
							if (isLongDiKa == false && (list[i].type == 6 || list[i].type == 23)) {
								isLongDiKa = true;
								break;
							}
			
						}
						for (var i = 0; i < list.length; i++) {
							if (isMixedLeiJiMeal == false && list[i].type == 20 && list[i].secondType == 2) {
								isMixedLeiJiMeal = true;
								break;
							}
			
						}
					}
					//除龙帝卡外（包月等），
					//总流量只显示本周期内的，及叠加包。
					if (!isLongDiKa && list && !isMixedLeiJiMeal) {
						var total = 0;
						var diejiabaoFlow = 0;
						var total_voice = 0;
						var diejiabaoFlow_voice = 0;
						var currDate = new Date();
						currDate.setHours(0);
						currDate.setMinutes(0);
						currDate.setSeconds(0);
						currDate.setMilliseconds(0);
						var ziranyue = false;
						for (var i = 0; i < list.length; i++) {
							if (list[i].type === 9 || list[i].type === 10 || list[i].type === 14 || list[i].type === 20 || list[i].type ===
								23) {
								ziranyue = true;
								break;
							}
						}
						var minExpireDate = null; // new Date(data.cardLiftList[0].expireDate.replace(/-/g,'/').substring(0,19));
						//找最小一个有效期，也就是本周期。
						for (var i = 0; i < list.length; i++) {
							var clObj = list[i];
							var expireDate = new Date(clObj.expireDate.replace(/-/g, '/').substring(0, 19));
			
							if (ziranyue) {
								if (expireDate.getTime() < currDate.getTime()) {
									continue;
								}
							} else {
								if (expireDate.getTime() <= currDate.getTime()) {
									continue;
								}
							}
							if (clObj.type == 1) {
								diejiabaoFlow += clObj.flowSize;
							}
							if (clObj.type == 101) {
								diejiabaoFlow_voice += clObj.voiceSize;
							}
			
							if (ziranyue) {
								if (minExpireDate == null && expireDate.getTime() >= currDate.getTime() && clObj.type != 1 && clObj.type != 101) {
									minExpireDate = expireDate;
								} else if (minExpireDate != null) {
									if (expireDate.getTime() > currDate.getTime() && expireDate.getTime() < minExpireDate.getTime() && clObj.type !=
										1 && clObj.type != 101) {
										minExpireDate = expireDate;
									}
								}
							} else {
								if (minExpireDate == null && expireDate.getTime() > currDate.getTime() && clObj.type != 1 && clObj.type != 101) {
									minExpireDate = expireDate;
								} else if (minExpireDate != null) {
									if (expireDate.getTime() > currDate.getTime() && expireDate.getTime() < minExpireDate.getTime() && clObj.type !=
										1 && clObj.type != 101) {
										minExpireDate = expireDate;
									}
								}
							}
						}
						if (minExpireDate == null) {
							for (var i = 0; i < list.length; i++) {
								var clObj = list[i];
								var expireDate = new Date(clObj.expireDate.replace(/-/g, '/').substring(0, 19));
			
								if (expireDate.getTime() <= currDate.getTime() || clObj.type == 1 || clObj.type == 101) {
									continue;
								}
								minExpireDate = new Date(clObj.expireDate.replace(/-/g, '/').substring(0, 19));
							}
						}
						//本周流量加起来。
						for (var i = 0; i < list.length; i++) {
							var clObj = list[i];
							var expireDate = new Date(clObj.expireDate.replace(/-/g, '/').substring(0, 19));
							if (ziranyue) {
								if (expireDate.getTime() < currDate.getTime()) {
									continue;
								}
							} else {
								if (expireDate.getTime() <= currDate.getTime()) {
									continue;
								}
							}
			
							if (minExpireDate && expireDate.getTime() === minExpireDate.getTime() && clObj.type != 1 && clObj.type != 101) {
								total += parseFloat(clObj.flowSize);
								total_voice += parseFloat(clObj.voiceSize);
							}
						}
						this.data.flowSize = total + diejiabaoFlow;
						this.data.voiceSize = total_voice + diejiabaoFlow_voice;
					}
					var currSetmealFlow = 0
					list.forEach(item => {
						if (item.type != 1 && item.type != 101) {
							currSetmealFlow = item.flowSize;
							currSetmealName = item.mealName;
						}
					})
					if (currSetmealFlow == 0 && !(!isLongDiKa && list)) {
						if (type == "1") {
							return "∞ MB"
						} else if (type == "2") {
							return "<span class='MB'>∞ MB</span>"
						}
						/* $("#surplusFlowId").html("<span class='MB'>∞ MB</span>");
						 $("#totalFlowId").html( "∞ MB"); */
					} else {
						if (type == "1") {
							return this.data.flowSize + "MB"
						} else if (type == "2") {
							if(this.data.totalBytesCnt==undefined){
								this.data.totalBytesCnt = 0
							}
							return (this.data.flowSize - this.data.totalBytesCnt).toFixed(2) + "<span class='MB'>MB</span>"
						} else if (type == "3") {
						
							if(this.data.totalBytesCntVoice==undefined){
								this.data.totalBytesCntVoice = 0
							}
							return (this.data.voiceSize - parseInt(this.data.totalBytesCntVoice)).toFixed(0) + "<span class='MB'>分钟</span>"
						} else if (type == "4") {
							return parseInt(this.data.voiceSize) + "分钟"
						}
						/* $("#surplusFlowId").html((responseResult.entity.flowSize - responseResult.entity.totalBytesCnt).toFixed(2) + "<span class='MB'>MB</span>");
						 $("#totalFlowId").html(responseResult.entity.flowSize + "MB");
			
						 $("#voiceRemaining").html((responseResult.entity.voiceSize - parseInt(responseResult.entity.totalBytesCntVoice)).toFixed(0) + "<span class='MB'>分钟</span>");
						 $("#voiceTotal").html(parseInt(responseResult.entity.voiceSize) + "分钟"); */
					}
				}
			},
			searchData() {
				let iccidMark = this.formInline.iccidMark1
				let phone = this.formInline.phone1
				if (!iccidMark && !phone) {
					messageBox(this, "请输入ICCID或者接入号!")
					return;
				}
				if (iccidMark) {
					this.formInline.iccidOrPhone = iccidMark
				} else if (phone) {
					this.formInline.iccidOrPhone = phone
				}
				this._findCardInfo(this.formInline);
			},
			_findCardInfo(params) {
				//let params = this.$route.query;
				loading.show()
				findCardInfo(params).then((res) => {
					this.res = res;
					this.data = res.entity;
					this.accountEntity = res.accountEntity;
					this.cardLiftList = res.cardLiftList;
					this.nextCardLifeList = res.nextCardLifeList;
					this.tianDjbCardLifes = res.tianDjbCardLifes;
					this.gparams
				});
			},

			znzd() {
				let iccidOrPhone = this.formInline.iccidOrPhone
				if (!iccidOrPhone) {
					messageBox(this, "请输入ICCID卡号或接入号，请点击搜索查询。");
					return;
				}
				if (this.znzd_ing === true) {
					messageBox(this, "正在诊断，请稍后。");
					return
				}
				this.znzd_ing = true
				let params = {
					iccidMark: this.data.iccidMark
				}
				intelliDiagnose(params).then(res => {
					this.znzd_ing = false
					console.log("")
					this.$alert(res.retMsg, '消息提示', {
					          confirmButtonText: '确定',
					          callback: action => {
					            
					          }
					        });
				})
			},

			_isRealName(){  //实名状态
				isRealName({iccidOrPhone:this.data.iccidMark}).then((res)=>{
					if (res.isRealName == false){
						this.isReal = 2
					}else if (res.isRealName == true){
						this.isReal = 1
					}
				})
			},
           compareResult(expireDate){
      let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
         let compareDate = "";
          let compareDate_unix = "";
          if (expireDate) {
            compareDate = expireDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {//当前时间大于等于过期时间   没有有效套餐
            return  false
          }
          return true
    },
			mealRecharge() {
				//套餐充值
				let iccidOrPhone = this.formInline.iccidOrPhone
				if (!iccidOrPhone) {
					messageBox(this, "请输入ICCID卡号或接入号，请点击搜索查询。");
					return;
				}
				if (this.res.noRecharge) {
					messageBox(this, "该卡暂不支持充值。");
					return;
				}
				if (this.data.expireDate != "-" && this.compareResult(this.data.expireDate)) {
					/* confirmBox(
					   this,
					   "该卡已有套餐，确认是否继续充值?",
					   "操作提示",
					   this._mealRecharge
					 ); */
					this.$confirm("该卡已有套餐，确认是否继续充值?", "操作提示", {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this._mealRecharge()
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消充值'
						});
					});

				} else {
					this._mealRecharge()
				}

			},
			_mealRecharge() {
				loading.show();
				let params = {};
				params.iccidOrPhone = this.data.iccidMark;
				params.setMealId = this.data.setMealId;
				params.isChild = this.data.isChild;
				params.codeChild = this.data.codeChild;
				getPackageAll(params).then((res) => {
					this.dialogAddPackage = true;
					this.addPackageData = res;
				});
			},
			whiteNubmerSet() { //白名单设置
				let iccidOrPhone = this.formInline.iccidOrPhone
				if (!iccidOrPhone) {
					messageBox(this, "请输入ICCID卡号或接入号，请点击搜索查询。");
					return;
				}
				// if (this.accountEntity.category === "7" && (this.accountEntity.secondCategory || "") === "2") {
				// 	// var iccidOrPhone = $("#iccidOrPhone").val();
				// 	// selections = [{"iccidMark": iccidOrPhone}]

				// 	// parent.parent.addTab("whitenumber_list","白名单设置",'cardBusiness/white_number_list.html?1=1&callbackFunction=reloadPageData&targetFrame='+frameId+'&iccidMark='+iccidOrPhone,"activity")

				// 	alert('跳转白名单设置 没看到现象 没做')
				// }
				if(!!this.accountEntity.hasVoice){
        //        this.$router.push({
        //           path: '/historyRecord/voiceWhitelist',
        //           query: {
        //               iccid: this.iccidMark
        //           }
        //   })
		this.dialogWhiteVoice = true
      } else {
					messageBox(this, "该卡不支持此操作！")
				}
			},

		},
		components: {
			AddPackage,
			 WhiteVoice,
		}
	}
</script>

<style>
	ul {
	  list-style: none;
	}
	.singleCardQuery {
	  position: relative;
	  width: 100%;
	  height: 100%;
	  background: #fff;
	}
	.singleCardQuery .card-icon {
	  position: absolute;
	  top: 10px;
	  right: 10px;
	  color: rgba(7, 17, 27, 0.5);
	}
	.singleCardQuery .card-icon i {
	  font-size: 18px;
	  font-weight: 700;
	  margin-right: 10px;
	}
	.singleCardQuery .group {
	  padding: 25px;
	  background: #fff;
	}
	/* .singleCardQuery .group table {
	  border-left: 1px solid #e7e7e7;
	  border-top: 1px solid #e7e7e7;
	} */
	.singleCardQuery .group table td {
	  height: 32px;
	  line-height: 32px;
	  /* border-right: 1px solid #e7e7e7;
	  border-bottom: 1px solid #e7e7e7; */
	  text-align: left;
	}
	.singleCardQuery .group .title {
	  height: 32px;
	  line-height: 32px;
	}
	.singleCardQuery ul > li {
	  float: left;
	  width: 125px;
	  text-align: center;
	  margin-right: 20px;
	  margin-top: 10px;
	}
</style>
