<template>
	<div class="mealNumStatics">
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="">
					<div class="tongji-col-div" style="box-shadow:2px 2px 10px #909090;">
						<el-form :inline="true" class=" themed-form search-form"
							style="height: auto;" id="baseForm1">
							<el-form-item label="套餐用量统计" class="span">

							</el-form-item>
							<el-form-item label="" :style="{display:showStyle}">
								<el-select v-model="searchIteam.mealId" filterable @change="mealSelectChange()">
									<el-option label="--套餐--" value="0"></el-option>
									<el-option v-for="(item,index) in mealList" :label="item.mealName" :value="item.mealId" :key="index"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="">
								<el-select v-model="searchIteam.build_time" filterable @change="buildTimeChange()">
									<el-option label="年月" value="0"></el-option>
									<el-option v-for="(item,index) in timeArr" :key="index" :label="item.timeName" :value="item.timeId"></el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</div>
					<div style="width: 100%; height: 500px;" id="chartMainId112">
					</div>
					</div>
			</el-col>
			<el-col :span="12">
				<div class="">
					<div class="tongji-col-div" style="box-shadow:2px 2px 10px #909090;">
						<el-form :inline="true" class=" themed-form search-form"
							style="height: auto;" id="baseForm">
							<el-form-item label="套餐使用用户统计" class="span">

							</el-form-item>
							<el-form-item label="">
								<el-select v-model="searchIteam.category" filterable @change="categoryChange()">
									<el-option label="请选择" value=""></el-option>
									<el-option label="浙江联通" value="4"></el-option>
									<el-option label="深圳移动" value="7"></el-option>
									<el-option label="扬州电信" value="1"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="" :style="{display:showStyle1}">
								<el-select v-model="searchIteam.meal_select2" filterable @change="mealSelectChange1()">
									<el-option label="--套餐类型--" value="0"></el-option>
									<el-option label="3个月套餐" value="3"></el-option>
									<el-option label="6个月套餐" value="6"></el-option>
									<el-option label="12个月套餐" value="12"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="">
								<el-select v-model="searchIteam.build_time2" filterable @change="buildTimeChange1()">
									<el-option label="年月" value="0"></el-option>
									<el-option v-for="(item,index) in timeArr" :key="index" :label="item.timeName" :value="item.timeId"></el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</div>
					<div style="width: 100%; height: 500px;" id="chartMainId113">
					</div>
					</div>
			</el-col>

		</el-row>
	</div>
</template>
<script>
	import echarts from "echarts"
	import {mealNumStatics,mealUseStatics,getMealName} from "@/api/staticsManagement.js"

	export default {
		name:'mealNumStatics',
		data() {
			return {
				timeArr: [],
				searchIteam: {
					mealId: "0",
					build_time: "0",
					build_time2: "0",
					meal_select2: "0",
					category: "",
				},
				showStyle: "none",
				showStyle1: "none",
				titleArr :[],
				valueArr : [],
				titleArr1 : [],
				valueArr1 : [],
				chartMainId1:"",
				chartMainId2:"",
				mealList:[]
			}
		},
		created() {
			this.dateInit();
		},
		mounted() {
			this._getMealName()

			window.onresize = () => {
				return (() => {
					if (!!this.chartMainId2) {
						this.chartMainId2.resize()
					}
					if (!!this.chartMainId1) {
						this.chartMainId1.resize()
					}
		
				})()
			}
		},
		methods: {
			dateInit() {
				var myDate = new Date();
				var startYear = myDate.getFullYear() - 1; //起始年份 这个可以自定义前后多少年。
				var endYear = myDate.getFullYear(); //结束年份
				var timeId
				var timeName
				console.log(endYear,startYear)
				for (var i = startYear; i <= endYear; i++) {
					for (var j = 1; j < 14; j++) {
						if (j.toString().length < 2) {
							j = "0" + j;
							 timeId = i + "-" + j;
							 timeName = i + "-" + j;
						} else if (j == 13) {

							 timeId = "";
							 timeName = "--------";
						} else if (j.toString().length == 2) {
							 timeId = i + "-" + j;
							 timeName = i + "-" + j;
						}

						this.timeArr.push({
							timeId,
							timeName
						})
					}

				}
			},
			buildTimeChange() {
				if (this.searchIteam.build_time != 0) {
					this.showStyle = ""
				} else {
					this.showStyle = "none"
				}
			},
			mealSelectChange() {
				this.search1()
			},
			buildTimeChange1() {
				if (this.searchIteam.build_time2 != 0) {
					this.showStyle1 = ""
				} else {
					this.showStyle1 = "none"
				}
			},
			categoryChange() {
				this.search2()
			},
			mealSelectChange1() {
				this.search2()
			},

			search1() {
				let params = {
					yearMonth: this.searchIteam.build_time,
					mealId: this.searchIteam.mealId
				}
				if (this.searchIteam.mealId != 0) {
					mealNumStatics(params).then(res => {
						this.chartMainId31(res)
					})
				}
			},
			search2() {
				let params = {
					yearMonth: this.searchIteam.build_time2,
					flowSize: this.searchIteam.meal_select2,
					accountId: this.searchIteam.category,
				}
				if (this.searchIteam.meal_select2 != 0) {
					mealUseStatics(params).then(res => {
						this.chartMainId32(res)
					})
				}
			},
			chartMainId31(dataList) {
				console.log(dataList);
				this.titleArr = []
				this.valueArr = [];
				for (var i = 0; i < dataList.length; i++) {
					var record = dataList[i];
					this.titleArr.push(record.flowSize);
					this.valueArr.push(record.flowNum);
				}
				var dom = document.getElementById("chartMainId112");
				console.log(dom);
				var myChart = echarts.init(dom);
				this.chartMainId1 = myChart
				var app = {};
			    let option = null;
				option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.titleArr
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#AE0000',
						lineStyle: {
							color: '#A5B6E0'
						},
						data: this.valueArr,
						type: 'line',
						areaStyle: {}
					}]
				};;
				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
			},
			chartMainId32(dataList) {
				this.titleArr1 = []
				this.valueArr1 = [];
				for (var i = 0; i < dataList.length; i++) {
					var record = dataList[i];
					this.titleArr1.push(record.cardCount);
					this.valueArr1.push(record.usePer);
				}

				var dom = document.getElementById("chartMainId113");
				var myChart = echarts.init(dom);
				this.chartMainId2 = myChart
				var app = {};
			let option = null;
				option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.titleArr1
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#ae0000',
						lineStyle: {
							color: '#A5B6E0'
						},
						data: this.valueArr1,
						type: 'line',
						areaStyle: {}
					}]
				};;
				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
			},
			_getMealName(){
				getMealName().then((res)=>{
					this.mealList = res
				})
			}

		}
	}
</script>
<style>
	.tongji-col-div {
		border-bottom: 1px solid #F5F5F6;
		width: 100%;
		height: 60px;
	}
	.mealNumStatics .span{
		font-size: 16px;
		    float: left;
		   /* width: 40%; */
		    text-align: left;
		    border-left: 8px solid #09A2E3;
		    margin: 20px 20px 10px 20px;
		    padding-left: 10px;
	}
	.mealNumStatics .el-input__inner{
		margin: 20px 0px 10px 0px;
		}
		

	
</style>
