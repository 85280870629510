<template>
    <div class="activity-gift-package fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">赠送套餐网卡</span>
            <el-form inline ref="giftPackageForm" v-model="giftPackageFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
                    <el-input v-model="giftPackageFormData.iccidMark" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>
                 <el-form-item>
                    <el-input v-model="giftPackageFormData.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="giftPackageFormData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item label="开卡公司">
                    <search-select :optionsMetaAll="agentOperators" v-model="giftPackageFormData.accountId"></search-select>
                </el-form-item>
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作起始日期" v-model="giftPackageFormData.startDate" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作结束日期" v-model="giftPackageFormData.endDate" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-circle-plus-outline" type="info" class="themed-button" @click="importByExcel">批量导入</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-delete" type="danger" class="themed-button" @click="deleteByExcel">批量删除</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                 <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="180"
                    />
                <el-table-column
                    prop="phone"
                    label="接入号码"
                    min-width="150"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="mealName"
                    label="赠送套餐"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="开卡公司"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    min-width="120"
                    >  
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTimeStr"
                    label="操作时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="statusStr"
                    label="导入状态"
                    min-width="100"
                    >
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    prop="errorDescription"
                    label="失败原因"
                    min-width="150"
                    >
                </el-table-column>
                
                <el-table-column
                    label="操作"
                    min-width="100">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
                
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="批量导入" :visible.sync="dialogImportByExcelVisible" width="500px" :destroy-on-close='true'>
            <el-form :model="dialogImportByExcelForm"  ref="dialogImportByExcelForm">
                <el-form-item label="请选择excel文件:" :label-width="formLabelWidth">
                    <input class="file-input" type="file" ref='fileImportInput'/>
                    <div class="mt5">
                            <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                    </div>
                </el-form-item>
                <el-form-item label="运营商:" :label-width="formLabelWidth" prop="category">
                    <el-select v-model="dialogImportByExcelForm.category"  filterable @change="getMealList">
                        <el-option v-for="item in accountLists" :key="item.index" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label="套 餐" :label-width="formLabelWidth" prop="mealId">
                    <el-select v-model="dialogImportByExcelForm.mealId" filterable placeholder="请选择">
                        <el-option v-for="item in mealList" :key='item.index' :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="uploadImportExcel">导入excel模板</el-button>
                <el-button class="themed-button" type="info" @click="exportTemplateExcel('gift_card_meal.xlsx')">导出excel模板</el-button>
                <el-button class="themed-button" type="primary" @click="dialogImportByExcelVisible = false">关 闭</el-button>
            </div>
        </el-dialog>

        <el-dialog title="批量删除" :visible.sync="dialogDeleteByExcelVisible" width="500px" :destroy-on-close='true'>
            <el-form :model="dialogDeleteByExcelForm"  ref="dialogDeleteByExcelForm">
                <el-form-item label="请选择excel文件:" :label-width="formLabelWidth">
                    <input class="file-input" type="file" ref='fileDeleteInput'/>
                    <div class="mt5">
                            <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="uploadDeleteExcel">导入excel模板</el-button>
                <el-button class="themed-button" type="info" @click="exportTemplateExcel('gift_card_meal.xlsx')">导出excel模板</el-button>
                <el-button class="themed-button" type="primary" @click="dialogDeleteByExcelVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {agentOperatorList} from '@/api/order.js'
import {getAccountList} from '@/api/cardManagement/cardBill.js'
import {giftPackageCard,giftPackageDeleteById,mealList,importGiftMealByExcel,deleteGiftMealByExcel} from '@/api/activity.js'
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import {messageBox,confirmBox} from '@/utils/common.js'
export default {
    name:'giftPackageCard',
    mixins:[resize],
     components:{SearchSelect},
    data(){
        return {
            agentOperators:[{label:"全部",value:""}],
            giftPackageFormData:{
                iccidMark:'',
                phone:'',
                accountId:'',
                startDate:'',
                endDate:'',
                shortIccid:""
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogImportByExcelVisible:false,
            formLabelWidth:'150px',
            dialogImportByExcelForm:{},
            dialogDeleteByExcelVisible:false,
            dialogDeleteByExcelForm:{},
            mealList:[],
            accountLists:[{label:'请选择',value:''}],
        }
    },
    created(){
        this.getAgentOperatorList()
        this.getGiftPackageList()
        this._getAccountList()
    },
    methods:{
        _getAccountList(){
                getAccountList().then((res) => {
                    this.accountLists = [{label:'请选择',value:''}]
                    if(res.length>0){
                        res.forEach((item)=>{
                            this.accountLists.push({label:item.accountName,value:item.id})
                        })
                    }
                })
        },
        getMealList(val){
            mealList({accountId:val}).then(res=>{
               this.mealList = res.mealList
            })
        },
        getAgentOperatorList(){
             agentOperatorList().then(res=>{
                 let arrList =[{label:"全部",value:""}]
                 res.forEach(element => {
                     arrList.push({label:element.accountName,value:element.id})
                 })               
                 this.agentOperators=arrList
             })
         },
         getGiftPackageList(){
             let params = {
                 pageSize:this.pageSize,
                 pageNo:this.page
             }
             Object.assign(this.giftPackageFormData,params)
             giftPackageCard(this.giftPackageFormData).then(res=>{
                this.tableData = res.rows
                this.total = res.count
             })
         },
         formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
         },
         handleDelete(id){
             this.$confirm("确定要删除吗？", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    giftPackageDeleteById({'id':id}).then(res=>{
                        this.$message({
                                type: 'success',
                                message:'删除成功'
                            });
                        this.getGiftPackageList()
                    })
                    
                })
         },
          onPageChange(page){
            this.page = page
            this.getGiftPackageList()
        },
        handleSizeChange(size){
            this.pageSize = size
            this.getGiftPackageList()
        },

        onSearch(){
            this.page=1
            this.getGiftPackageList()
        },
        exportExcel(){},
        importByExcel(){
            this.dialogImportByExcelVisible = true
        },
        exportTemplateExcel(name){
            exportTemplate({'name':name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        uploadImportExcel(){
            this.$confirm("确定导入吗？", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    let file = this.$refs['fileImportInput'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
                                confirmButtonText:'确定',
                            });
                            return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            if(!!this.dialogImportByExcelForm.category){
                                param.append("category",this.dialogImportByExcelForm.category)
                            }else{
                                this.$alert('请选择类型', '提示', {
                                confirmButtonText:'确定',
                                });
                                return
                            }
                            if(!!this.dialogImportByExcelForm.mealId){
                                param.append("mealId",this.dialogImportByExcelForm.mealId)
                            }else{
                                this.$alert('请选择套餐', '提示', {
                                confirmButtonText:'确定',
                                });
                                return
                            }
                            param.append("fileurl",file)
                            importGiftMealByExcel(param).then(res=>{
                                 if (res.system_result_key == '0') {
                            //  this.$message({
                            // 	type: 'success',
                            // 	message:'导入成功'
                            // });
                  this.$alert("导入成功，请到导入记录中下载", "提示", {
                  confirmButtonText: "确定",
                }).then(() => {
                    this.count = 0
                    this.dialogImportByExcelForm = {}
                    this.$refs['fileImportInput'].value = ''
                  this.dialogImportByExcelVisible = false;
                  this.$router.push("/tob/i_import_list");
                });
                            // this.getGiftPackageList()
                            // this.count = 0
                            // this.dialogImportByExcelForm = {}
                            // this.$refs['fileImportInput'].value = ''
                            // this.dialogImportByExcelVisible = false
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                              
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
                                confirmButtonText:'确定',
                            });
                            return
                    }
                })
        },
        deleteByExcel(){
            this.dialogDeleteByExcelVisible = true
        },
        uploadDeleteExcel(){
            this.$confirm("确定导入吗？", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    let file = this.$refs['fileDeleteInput'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
                                confirmButtonText:'确定',
                            });
                            return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            param.append("fileurl",file)
                            deleteGiftMealByExcel(param).then(res=>{
                            //     this.$message({
                            // 	type: 'success',
                            // 	message:res.system_result_message_key
                            // });
                            // this.getGiftPackageList()
                            // this.count = 0
                            // this.dialogDeleteByExcelForm = {}
                            // this.$refs['fileDeleteInput'].value = ''
                            //  this.dialogDeleteByExcelVisible = false

                                   if (res.system_result_key == '0') {
                           
                             this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                           this.getGiftPackageList()
                            this.count = 0
                            this.dialogDeleteByExcelForm = {}
                            this.$refs['fileDeleteInput'].value = ''
                             this.dialogDeleteByExcelVisible = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
                                confirmButtonText:'确定',
                            });
                            return
                    }
                })
        }

       
    }
}
</script>

<style>
    .activity-gift-package .el-form-item__label{
        vertical-align: top;
    }
</style>