<template>
    <div class="shut-down-record fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">停复机记录</span>
            <el-form inline ref="shutdownRecordForm" v-model="shutdownRecordData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作起始日期" v-model="shutdownRecordData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作结束日期" v-model="shutdownRecordData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
                    <el-input v-model="shutdownRecordData.iccid" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="shutdownRecordData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="ICCID"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    label="类型"
                    min-width="10%"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==1">复机</span>
                        <span v-else-if="scope.row.type==2">停机</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    min-width="10%"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">成功</span>
                        <span v-else-if="scope.row.status==2">失败</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="操作时间"
                    min-width="20%"
                    >   
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    min-width="10%"
                    >   
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="操作"
                    min-width="10%"
                    >
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status==2" size="mini" class="themed-button" type="primary" @click="handleDetail(scope.row.id)">详 情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="失败详情" :visible.sync="dialogFailDetailVisible" width="40%" :destroy-on-close='true'>
            <el-form :model="detailForm"  ref="detailForm">
                <el-form-item label="失败原因" :label-width="formLabelWidth">
                <el-input type="textarea"  v-model="detailForm.remark" disabled></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="primary" @click="dialogFailDetailVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { shutdownRecord,shutdownRecordDetail} from "@/api/history.js"
import resize from '@/mixins/resize.js'
export default {
    name:'shutdownRecord',
    mixins:[resize],
    data(){
        return {
            shutdownRecordData:{
                startTime:'',
                endTime:'',
                iccid:'',
                phone:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            detailForm:{
                remark:''
            },
            dialogFailDetailVisible:false,
            formLabelWidth:'100px'
        }
    },
    created(){
        this.getShutdownRecordList()
    },
    methods:{
        getShutdownRecordList(){
            let params ={
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.shutdownRecordData,params)
            shutdownRecord(this.shutdownRecordData).then(res=>{
                this.tableData=res.rows
                this.total=res.count
            })
        },
        handleSizeChange(size){
            this.pageSize=size
            this.getShutdownRecordList()
        },
        onPageChange(page){
            this.page=page
            this.getShutdownRecordList()
        },
        onSearch(){
            this.page=1
            this.getShutdownRecordList()
        },
        handleDetail(id){
            this.dialogFailDetailVisible=true
            shutdownRecordDetail({id}).then(res=>{
                console.log(res.entity.remark)
                this.detailForm.remark = res.entity.remark
            })
        },

    }
}
</script>