/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from '../request.js'

//imeiLibrary.vue
export function imeiBankCode(){
    return request({
        url: 'bindImei/listImeiBankCode.do?',
        method: 'post'
    })
}

export function exportPatchSync(params){
    return request({
        url: 'bindSimplus/export.do?',
        method: 'post',
        data:params
    })
}

export function listPatch(params){
    return request({
        url: 'bindSimplus/list.do?',
        method: 'post',
        data:params
    })
}

export function deleteByIdPatch(params){
    return request({
        url: 'bindSimplus/deleteById.do?',
        method: 'post',
        data:params
    })
}

export function importAddSync(params){
    return request({
        url: 'bindSimplus/importAddSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function importDeleteSync(params){
    return request({
        url: 'bindSimplus/importDeleteSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}