<template>
    <div class="order-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">订单核算</span>
            <el-form inline ref="orderCheckForm" v-model="orderCheckData" class="right themed-form search-form" style="height: 40px;line-height: 40px;">
                <el-form-item label="状态">
                    <search-select  :optionsMetaAll="optionsMetaAll" v-model="orderCheckData.status"></search-select>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="orderCheckData.pageSearchKeys" placeholder="代理商名称"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-date-picker
                        v-model="orderCheckData.accountingDate"
                        type="date"
                        placeholder="核算周期"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                <iframe name="hidden_down_iframe" id="hidden_down_iframe" style="display: none;"  src=""></iframe>
            </el-form>
        </div>
        <div class="content-box">
            <el-table
                border
                stripe
                ref='table'
                :data="tableData"
                :max-height="maxTableHeight"
                tooltip-effect="dark"
                style="width: 100%"
               >
                <el-table-column
                    prop="agentName"
                    label="代理商名称"
                    min-width="150">
                </el-table-column>
                <el-table-column
                    prop="accountingDate"
                    label="核算周期"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="accountingTime"
                    label="核算时间"
                    min-width="180">
                </el-table-column>
                 <el-table-column
                    prop="orderCount"
                    label="订单总数"
                    min-width="98">
                </el-table-column>

                <el-table-column
                    label="订单总额"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.orderMoney">{{parseFloat(scope.row.orderMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="自定套餐总额"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.setMealMoney">{{parseFloat(scope.row.setMealMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="代理差价"
                    min-width="98">
                    <template slot-scope="scope">
                        <span>{{(scope.row.orderMoney - scope.row.setMealMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="自定套餐返现"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.accountingMoney">{{parseFloat(scope.row.accountingMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="秒返金额"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.mfMoney">{{parseFloat(scope.row.mfMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可提现金额"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.txMoney">{{parseFloat(scope.row.txMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    min-width="98">
                     <template slot-scope="scope">
                        <span v-if="scope.row.status==1">待核算</span>
                        <span v-else-if="scope.row.status==2">核算中</span>
                        <span v-else-if="scope.row.status==3">已核算</span>
                        <span v-else-if="scope.row.status==4">核算异常</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="bank"
                    label="收款银行"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="bankCode"
                    label="银行卡号"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="bankReceiver"
                    label="收款人"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="createUser"
                    label="结算人"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="createDatetime"
                    label="结算时间"
                    min-width="98">
                </el-table-column>
                <el-table-column label="操作" min-width="158">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        class="themed-button" type="info"
                        @click="showOrderDetail(scope.row.id)"
                        >订单详情</el-button>
                        <el-button
                        v-if="scope.row.status==2"
                        size="mini"
                        class="themed-button" type="primary"
                        @click="balanceRecord(scope.row.id)"
                        >结算</el-button>
                    </template>
                </el-table-column>
            </el-table>

             <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div> 

         <!-- 结算弹框 -->
         <el-dialog title="订单结算" :visible.sync="dialogRecordFormVisible" width="40%" :destroy-on-close='true'>
            <el-form :model="recordForm" :rules="rules" ref="recordForm">
                <el-form-item label="代理商名" :label-width="formLabelWidth" prop="agentName">
                <el-input v-model="recordForm.agentName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="核算周期" :label-width="formLabelWidth" prop="accountingDate">
                <el-input v-model="recordForm.accountingDate" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="订单总数" :label-width="formLabelWidth" prop="orderCount">
                <el-input v-model="recordForm.orderCount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="订单总额" :label-width="formLabelWidth" prop="orderMoney">
                <el-input v-model="recordForm.orderMoney" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="套餐总额" :label-width="formLabelWidth" prop="setMealMoney">
                <el-input v-model="recordForm.setMealMoney" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="返现金额" :label-width="formLabelWidth" prop="accountingMoney">
                <el-input v-model="recordForm.accountingMoney" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="提现银行" :label-width="formLabelWidth" prop="bank">
                <el-input v-model="recordForm.bank" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="提现账号" :label-width="formLabelWidth" prop="bankCode">
                <el-input v-model="recordForm.bankCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="收款人" :label-width="formLabelWidth" prop="bankReceiver">
                <el-input v-model="recordForm.bankReceiver" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" prop="remarks">
                <el-input type="textarea" v-model="recordForm.remarks"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureRecord('recordForm')">确认付款</el-button>
                 <el-button class="themed-button" type="info" @click="resetRecord('recordForm')">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="dialogRecordFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {orderAccountingList,orderAccountingRecord,orderAccountingSureRecord,exportAccountOrder} from '@/api/order.js'
import loading from '@/utils/Loading.js'
import resize from '@/mixins/resize.js'
export default {
    name:'orderAccounting',
    components:{
        SearchSelect
    },
    mixins:[resize],
    data(){
        return {
            optionsMetaAll:[
                {label:"全部",value:''},
                {label:"待核算",value:'1'},
                {label:"核算中",value:'2'},
                {label:"已核算",value:'3'},
                {label:"核算异常",value:'4'}
                ],

            orderCheckData:{
                type:'1',
                status:'',
                pageSearchKeys:'',
                accountingDate:''
            },
            tableData:[],
            orderDetailtableData:[],
            multipleSelection: [],




            page:1,
            pageSize:15,
            total:0,
            dialogRecordFormVisible:false,
            currentHandleID:'',
            recordForm:{
                agentName:'',
                accountingDate:'',
                orderCount:'',
                orderMoney:'',
                setMealMoney:'',
                accountingMoney:'',
                bank:'',
                bankCode:'',
                bankReceiver:'',
                remarks:'',
            },
            rules:{
                agentName:[{required: true, message: '请输入代理商名', trigger: 'blur' },],
                accountingDate:[{required: true, message: '请输入核算周期', trigger: 'blur' }],
                orderCount:[{required: true, message: '请输入订单总数', trigger: 'blur' }],
                orderMoney:[{required: true, message: '请输入订单总额', trigger: 'blur' }],
                setMealMoney:[{required: true, message: '请输入套餐总额', trigger: 'blur' }],
                accountingMoney:[{required: true, message: '请输入返现金额', trigger: 'blur' }],
                bank:[{required: true, message: '请输入提现银行', trigger: 'blur' }],
                bankCode:[{required: true, message: '请输入提现账号', trigger: 'blur' }],
                bankReceiver:[{required: true, message: '请输入收款人', trigger: 'blur' }],
                remarks:[{required: true, message: '请输入备注', trigger: 'blur' }],
            },
            formLabelWidth: '100px'
        }
    },
    created() {
        this.getOrderAccountingList()
    },
    methods: {
        getOrderAccountingList(){
            let params ={
                pageSize:this.pageSize,
				pageNo:this.page,
            }
            Object.assign(this.orderCheckData,params)
            orderAccountingList(this.orderCheckData).then(res=>{
                this.tableData = res.rows
				this.total = res.count
            })
        },
        formatURL(obj){
            const sdata = [];
            for (let attr in obj) {
            sdata.push(`${attr}=${obj[attr]}`);
            }
            return sdata.join('&');
        },
        onSearch(){
            this.page = 1
            this.getOrderAccountingList()
        },
        exportExcel(){
            exportAccountOrder(this.orderCheckData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        onPageChange(page){
				this.page = page
				this.getOrderAccountingList()
			},
		handleSizeChange(size){
				this.pageSize = size
				this.getOrderAccountingList()
			},
        showOrderDetail(id){
            this.$router.push({path:'/orderManagement/orderDetail',query:{orderMoneyId:id}})
        },
        //   结算
        balanceRecord(id){
            console.log('没有界面，可能需要完善',321)
            this.dialogRecordFormVisible=true
            orderAccountingRecord({id}).then(res=>{
                console.log(res)
                this.recordForm.bankCode= res.entity.agentEntity.bankCode
                this.recordForm.bank= res.entity.agentEntity.bank
                this.recordForm.bankReceiver= res.entity.agentEntity.bankReceiver
            })
        },
        sureRecord(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                orderAccountingSureRecord(this.recordForm).then(res=>{
                   if (res.slide_msg_key == 1) {
                       this.$message({
							showClose: true,
							message: res.slide_msg_message_key,
							type: 'warning',
                        })
                        this.getOrderAccountingList()
                        this.$refs[formName].resetFields()
                        this.dialogRecordFormVisible=false
                    } 
                })
            } else {
                console.log('error submit!!');
                return false;
            }
            });
            
        },
        resetRecord(formName){
            this.$refs[formName].resetFields()
        },

    },
}
</script>

<style>
    .order-list .el-form-item__label{
        vertical-align: top;
    }
</style>