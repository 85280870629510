<template>
	<div class="main-subAccount">
		<div class="fadeInRight">
			<div class="title-box clearfix">
				<span class="m-title">子账号管理</span>
				<el-form inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
					<el-form-item>
						<el-input  placeholder="账户名称" v-model="searchAccount"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索</el-button>
					</el-form-item>
					<el-form-item>
						<el-button icon="el-icon-plus" type="info" class="themed-button" @click="dialogVisible = true">新增</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="content-box">
				<el-table ref='table'  :data="tableData" stripe style="width: 100%;" :max-height="maxTableHeight">
					<el-table-column label="子账户名">
						<template slot-scope="scope">
							<a href='javascript:;' @click="getCardInfo(scope.row)" style="color: #337ab7;">{{scope.row.realname}}</a>
						</template>
					</el-table-column>
					<el-table-column prop="mobile" label="用户名">
					</el-table-column>
					<el-table-column prop="phone" label="手机号">
					</el-table-column>
					<el-table-column prop="" label="操作" width="320">
						<template slot-scope="scope">
							<el-button class="themed-button" type="success" @click="openDispatchDialog(scope.row.id,true)">分配号码</el-button>
							<el-button class="themed-button" type="info" @click="openDispatchDialog(scope.row.id,false)">取消分配</el-button>
							<el-button class="themed-button" type="warning" @click="onOpenPasswordDialog(scope.row.id)">密码重置</el-button>
							<el-button class="themed-button" type="danger" @click="deleteAccount(scope.row.id)">删除</el-button>
						</template>
					</el-table-column>
				
				</el-table>
				<el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
				 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
			</div>
		</div>
		
		<el-dialog title="新增账户" :visible.sync="dialogVisible" width="500px" destroy-on-close>
			<el-form label-width="120px" :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item label="账户名" prop="realname">
					<el-input v-model="ruleForm.realname" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="登录账号" prop="mobile">
					<el-input v-model="ruleForm.mobile" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="ruleForm.phone" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="初始密码" prop="password">
					<el-input v-model="ruleForm.password" style="width: 240px;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="dialogVisible = false">取 消</el-button>
				<el-button class="themed-button" type="primary" @click="onAddAccount">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="密码重置" :visible.sync="changePwDialogVisible" width="500px" destroy-on-close>
			<el-form label-width="120px" :model="changePwRuleForm" :rules="changePwRules" ref="changePwRuleForm">
				<el-form-item label="初始密码" prop="password">
					<el-input v-model="changePwRuleForm.password" style="width: 240px;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="changePwDialogVisible = false">取 消</el-button>
				<el-button class="themed-button" type="primary" @click="onChangePassword">确 定</el-button>
			</div>
		</el-dialog>
		
		<el-dialog title="分配号码" :visible.sync="dispatchDialogVisisble" width="600px" @close="dispatchdialogClosed" :close-on-click-modal="false">
			<el-tabs v-model="activeName">
				<el-tab-pane label="号段导入" name="first">
					<el-form>
						<el-form-item>
							<span slot="label">ICCID号段:</span>
							<el-input v-model="startNum" style="width: 160px;"></el-input>
							<span> - </span>
							<el-input v-model="endNum" style="width: 160px;"></el-input>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="文件导入" name="second">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item>
							<span class="tips">提示下载模板</span>
							<!-- <a href="../../assets/file/card_toc_num.xls">导出</a> -->
							<el-button class="themed-button" type="primary" style="margin: 0 10px;" @click="getTemplate">导出Excel模板</el-button>
						</el-form-item>
						<el-form-item label="选择导入文件">
							<div class="fileInterGroups">
								<el-input placeholder="点击选择文件" v-model="chooseFileName"></el-input>
								<i class="el-icon-folder-opened"></i>
								<input ref="fileInput" type="file" class="form-control add_file_inter" @change="previewFile()" name="file">
							</div>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			</el-tabs>
			<el-form inline label-width="54px">
				<el-form-item label="成功数">
					<el-input v-model="successCount" style="width: 210px;" disabled></el-input>
				</el-form-item>
				<el-form-item label="失败数">
					<el-input v-model="failCount" style="width: 210px;" disabled></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="failMsg" type="textarea" style="width: 540px;" disabled></el-input>
				</el-form-item>
			</el-form>
	
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="dispatchDialogVisisble = false">取 消</el-button>
				<el-button class="themed-button" type="primary" @click="onDispatch">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {enterpriseList,enterpriseSave,updateEnterprisePsd,delEnterpriseById,addCard,delCard} from '@/api/account.js'
	import {exportTemplate} from '@/api/statistics.js'
	import Utils from '@/utils/utils.js'
	import resize from '@/mixins/resize.js'
	export default{
		name:'subAccount',
		mixins:[resize],
		data(){
			return {
				page:1,
				pageSize:15,
				total:0,
				tableData:[],
				searchAccount:'',
				dialogVisible:false,
				changePwDialogVisible:false,
				dispatchDialogVisisble:false,
				currentOperateId:'',
				activeName:'first',
				startNum:'',
				endNum:'',
				successCount:'',
				failCount:'',
				failMsg:'',
				chooseFileName:'',
				isDispatch:true,
				ruleForm:{
					realname:'',
					mobile:'',
					phone:'',
					password:''
				},
				rules: {
				
					realname: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					},],
					mobile: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					},],
					phone: [{
							required: true,
							message: '不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
							message: '手机号格式不正确',
							trigger: 'blur'
						},
					],
					password: [
						{
							required: true,
							message: '不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^(?![^a-zA-Z]+$)(?!\D+$).{8,15}$/,
							message: '至少包含一个字母和数字,长度控制在8-15',
							trigger: 'blur'
						},
					],
				},
				changePwRuleForm:{
					password:''
				},
				changePwRules:{
					password: [
						{
							required: true,
							message: '不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^(?![^a-zA-Z]+$)(?!\D+$).{8,15}$/,
							message: '至少包含一个字母和数字,长度控制在8-15',
							trigger: 'blur'
						},
					],
				}
			}
		},
		created() {
			this.getEnterpriseList()
		},
		methods:{
			getTemplate(){
				exportTemplate({type:2}).then(res=>{
					const filename = '号码分配.xls'
					Utils.createDownload(res,filename)
				})
			},
			previewFile(e){
				e = e || window.event
				var file = e.target.files[0];
				if (file) {
					this.chooseFileName = file.name
				}
			},
			
			dispatchdialogClosed(){
				this.startNum = ''
				this.endNum = ''
				this.failCount = ''
				this.successCount = ''
				this.failMsg = ''
				this.$refs['fileInput'].value = ''
			},
			openDispatchDialog(id,isDispatch){
				this.currentOperateId = id
				this.isDispatch = isDispatch
				this.dispatchDialogVisisble = true
				
			},
			onDispatch(){
				this.$confirm("确定要导入吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params = new FormData()
					if (this.activeName == 'first') {
						
						if (!!this.startNum && !!this.endNum) {
							params.append("userId",this.currentOperateId)
							params.append("startNum",this.startNum)
							params.append("endNum",this.endNum)
						}else{
							return
						}
						
						// params = {
						// 	'userId':this.currentOperateId,
						// 	'startNum':this.startNum,
						// 	'endNum':this.endNum
						// }
					}else{
						let file = this.$refs['fileInput'].files[0];
						if (file) {
							
							params.append("fileurl",file)
							params.append("userId",this.currentOperateId)
						}else{
							return
						}
					}
					if (this.isDispatch) {
						addCard(params).then(res=>{
							this.failCount = res.fail;
							this.successCount = res.suc;
							if (!!res.failCard) {
								let msg = ''
								for(var i=0;i<res.failCard.length;i++){
								    msg = msg + res.failCard[i] + "  "
								}
								this.failMsg = msg
							}
						})
					}else{
						delCard(params).then(res=>{
							this.failCount = res.fail;
							this.successCount = res.suc;
							if (!!res.failCard) {
								let msg = ''
								for(var i=0;i<res.failCard.length;i++){
								    msg = msg + res.failCard[i] + "  "
								}
								this.failMsg = msg
							}
						})
					}
				})
			},
			getCardInfo(rowData){
				this.$router.push({path:'/enterpriseCard/listenterprise',query:{id:rowData.id}})
			},
			onOpenPasswordDialog(id){
				this.currentOperateId = id
				this.changePwRuleForm.password = ''
				this.changePwDialogVisible = true
			},
			onChangePassword(){
				this.$refs["changePwRuleForm"].validate((validate) => {
					if (validate) {
						updateEnterprisePsd({id:this.currentOperateId,password:this.changePwRuleForm.password}).then(res=>{
							this.$message({
								type: 'success',
								message:'密码更新成功'
							});
							this.changePwDialogVisible = false
						})
					}
				})
			},
			onAddAccount(){
				this.$refs["ruleForm"].validate((validate) => {
					if (validate) {
						enterpriseSave(this.ruleForm).then(res=>{
							this.$message({
								type: 'success',
								message:'新增成功'
							});
							this.dialogVisible = false
							this.getEnterpriseList()
						})
					}
				})
				
			},
			deleteAccount(id){
				this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delEnterpriseById({userId:id}).then(res=>{
						this.$message({
							type: 'success',
							message:'删除成功'
						});
					})
					this.getEnterpriseList()
				})
			},
			onSearch(){
				this.page = 1
				this.getEnterpriseList()
			},
			getEnterpriseList(){
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
					pageSearchKeys:this.searchAccount
				}
				enterpriseList(params).then(res=>{
					this.total = res.count
					this.tableData = res.rows
				})
			},
			
			onPageChange(page){
				this.page = page
				this.getEnterpriseList()
			},
			handleSizeChange(size){
				this.pageSize = size
				this.getEnterpriseList()
			}
		}
	}
</script>

<style>
	.main-subAccount .fileInterGroups{
		position: relative;
	}
	.main-subAccount  .fileInterGroups .add_file_inter{
		position: absolute;
		top: 0; 
		left: 0;
		cursor: pointer;
		width: 160px !important ;
		height: 30px ;
		opacity: 0;
	}
	.main-subAccount  .fileInterGroups i{
		position: absolute;
		color: #a7a7a7;
		top: 7px;
		left: 168px;
		font-size: 20px;
	}
</style>
