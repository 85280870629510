<template>

<div class="stock-out-card-list table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">出库卡列表</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="批次号:">
                <el-select v-model="form.outCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in outCodeList" :label="item.outCode" :value="item.outCode" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="物料编号:">
                <el-select v-model="form.materielCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in materielCodeList" :label="item.materielCode" :value="item.materielCode" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="业务类型:">
                <el-select v-model="form.businessType">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in businessTypeList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="仓库位置:">
                <el-select v-model="form.stockHouse">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in stockHouseList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID" prop="iccid" width="180px"></el-table-column>
            <el-table-column label="接入号" prop="phone"></el-table-column>
            <el-table-column label="物料编码" prop="materielCode"></el-table-column>
            <el-table-column label="入库批次" prop="inCode"></el-table-column>
            <el-table-column label="产品名称" prop="productName">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productName,dictMap.productName)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="业务类型" prop="businessType">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.businessType,dictMap.businessType)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="产品类型" prop="productType">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productType,dictMap.productType)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="产品制式" prop="productStandard">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productStandard,dictMap.productStandard)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="产品功能" prop="productAbility">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productAbility,dictMap.productAbility)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="产品分组" prop="productGroup">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productGroup,dictMap.productGroup)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="所在仓库" prop="stockHouse">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.stockHouse,dictMap.stockHouse)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="status">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.status,dictMap.status)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="采购时间" prop="purchaseTime"></el-table-column>
            <el-table-column label="入库时间" prop="stockTime"></el-table-column>
            <el-table-column label="出库时间" prop="outStockTime"></el-table-column>
            <el-table-column label="出库客户名称" prop="outAgentName"></el-table-column>
            <el-table-column label="出库人" prop="operator"></el-table-column>
            <el-table-column label="库存到期时间" prop="stockExpireTime">
                <template  slot-scope="scope">
                    <span v-html="_testTimeJudge3Month(scope.row.stockExpireTime)"></span>
                </template>
            </el-table-column>
            <el-table-column label="卡板费" prop="cardFee" show-overflow-tooltip></el-table-column>
            <el-table-column label="续费单价（元/月）" prop="renewPrice" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作人" prop="operator" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template  slot-scope="scope">
                    <el-button type="danger" class="themed-button"  @click="deleteById(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

</div>

</template>

<script type="text/ecmascript-6">

import {listOutCode,listCardOut,CardOutExport,deleteByIdCardOut} from '@/api/stockManagement/stock.js'
import {messageBox,confirmBox,dictMapTrans,testTimeJudge3Month} from '@/utils/common.js'

import SearchSelect from '@/components/SearchSelect'

import resize from '@/mixins/resize.js'

export default {
    name:'stockOutCardList',
    mixins:[resize],
    data(){
        return {
            id:'',
            form:{
                outCode:'',
                materielCode:'',
                businessType:'',
                stockHouse:''
            },
            outCodeList:[],
            materielCodeList:[],
            businessTypeList:[],
            stockHouseList:[],
            dictMap:{},
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,

            dialogTitle:'',
            dialogBox:false,
            dialogContent:''
        }
    },
    mounted(){
        let params = this.$route.query
        if(params.outCode){
            this.form.outCode = params.outCode
        }
        this._listOutCode()
        this._listCardOut()
    },
    methods:{
        searchTable(){
            this._listCardOut()
        },
        importLog(){ //查看续期记录
            this.dialogTitle = "导入续期记录"
            this.dialogContent = 'log'
            this.dialogBox = true
            
        },
        importDelete(){ //到期续期
            this.dialogTitle = "到期续期"
            this.dialogContent = 'delete'
            this.dialogBox = true
        },
        deleteById(id){
            confirmBox(this,'您确定要删除吗?','操作提示',()=>{
                deleteByIdCardOut(Object.assign({id},this.form)).then((res)=>{
                    if(res.slide_msg_key == 1){
                        messageBox(this,res.slide_msg_message_key)
                    }
                    this._listCardOut()
                })
            })
        },
        exportExcel(){
            CardOutExport(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        updateClose(){
            this.dialogBox = false
            this._listCardOut()
        },
        handleSizeChange(val){
            this.pageSize = val
            this._listCardOut()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._listCardOut()
        },
        _listOutCode(){
            let  params = {
                'pageSize' : this.pageSize,
                'pageNo' : this.currentPage
            }
            listOutCode(params).then((res)=>{
                this.dictMap = res.dict
                console.log(res)
                if(res.data.length>0){
                    this.outCodeList = res.data
                }else{
                    this.outCodeList = []
                }
                if(res.mCode.length>0){
                    this.materielCodeList = res.mCode
                }else{
                    this.materielCodeList = []
                }
                if(res.dict.businessType.length>0){
                    this.businessTypeList = res.dict.businessType
                }else{
                    this.businessTypeList = []
                }
                if(res.dict.stockHouse.length>0){
                    this.stockHouseList = res.dict.stockHouse
                }else{
                    this.stockHouseList = []
                }

            })
        },
        _listCardOut(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.currentPage
            }
            Object.assign(this.form,params)
            listCardOut(this.form).then((res)=>{
                // this.pageSize = res.pageSize
                // this.currentPage = res.pageNo
                this.tableData = res.rows
                this.total = res.total
            })
        },
        _dictMapTrans(value,dictGroup){
            return dictMapTrans(value,dictGroup)
        },
        _testTimeJudge3Month(val){
            return testTimeJudge3Month(val)
        }
    },
    components:{
        SearchSelect
    }
}
</script>
