<template>
    <div class="order-bill-apply fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">增票资质审核列表</span>
            <el-form inline ref="billApplyForm" v-model="form" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="企业名称:">
                    <el-input v-model="form.bussinessName" placeholder="企业名称"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
            </el-form>
        </div>
        <div class="content-box">
            <el-table
                ref='table'
                border
                stripe
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :max-height="maxTableHeight">
            <el-table-column label="企业名称" prop="bussinessName" show-overflow-tooltip></el-table-column>
            <el-table-column label="代理商" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="纳税人识别号" width="150px" prop="taxNo" show-overflow-tooltip></el-table-column>
            <el-table-column label="开户银行" prop="bank" show-overflow-tooltip></el-table-column>
            <el-table-column label="银行账号" prop="bankCode" show-overflow-tooltip></el-table-column>
            <el-table-column label="注册电话" prop="regPhone" show-overflow-tooltip></el-table-column>
            <el-table-column label="注册地址" prop="address" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status== 0">未审核</span>
                    <span v-if="scope.row.status== 1">审核通过</span>
                    <span v-if="scope.row.status== 2">审核失败</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="240px">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="primary" @click="handleShowDetail(scope.row)">详情</el-button>
                        <el-button v-if="scope.row.status== 0 || scope.row.status== 2" size="mini" class="themed-button" type="primary" @click="OnPass(scope.row.agentId,'1')">通过</el-button>
                        <el-button v-if="scope.row.status== 0" size="mini" class="themed-button" type="primary"  @click="OnPass(scope.row.agentId,'2')">不通过</el-button>
                    </template>
            </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="增值资质详情" :visible.sync="dialogDetail">
            <el-form :model="DetailForm" style="height:650px;overflow:auto">
                <el-form-item label="企业名称" prop="bussinessName">
                    <el-input v-model="DetailForm.bussinessName"  readonly></el-input>
                </el-form-item>    
                <el-form-item label="代理商" prop="agentName">
                    <el-input v-model="DetailForm.agentName"  readonly></el-input>
                </el-form-item>    
                <el-form-item label="纳税人识别号" prop="taxNo">
                    <el-input v-model="DetailForm.taxNo"  readonly></el-input>
                </el-form-item>    
                <el-form-item label="开户银行" prop="bank">
                    <el-input v-model="DetailForm.bank"  readonly></el-input>
                </el-form-item>    
                <el-form-item label="银行账号" prop="bankCode">
                    <el-input v-model="DetailForm.bankCode"  readonly></el-input>
                </el-form-item>    
                <el-form-item label="注册电话" prop="regPhone">
                    <el-input v-model="DetailForm.regPhone"  readonly></el-input>
                </el-form-item>    
                <el-form-item label="注册地址" prop="address">
                    <el-input v-model="DetailForm.address"  readonly></el-input>
                </el-form-item>    
                <el-form-item label="状态" prop="statusName">
                    <el-input v-model="DetailForm.statusName"  readonly></el-input>
                </el-form-item>    
                <el-form-item label="营业执照" prop="statusName">
                    <a :href="DetailForm.bussinessLicense" target="_blank">
                        <img style="width:80%" title="双击可下载" :src="DetailForm.bussinessLicense" alt="">
                    </a>
                </el-form-item>    
                <el-form-item label="纳税人资格证" prop="statusName">
                    <a :href="DetailForm.taxPayerCertificate"  target="_blank">
                        <img style="width:80%" title="双击可下载" :src="DetailForm.taxPayerCertificate" alt="">
                    </a>
                </el-form-item>    
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogDetail = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import resize from '@/mixins/resize.js'
import {increBillList,increBillCheck} from '@/api/order.js'
export default {
    name:'billApply',
    mixins:[resize],
    data(){
        return {
            form:{
                status:'',
                unitName:'',
                startDateStr:'',
                endDateDateStr:'',
                orderNumber:'',
                phone:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogDetail:false,
            DetailForm:{},

        }
    },
    created(){
        this.getIncreBillList()
    },
    methods:{
        OnPass(agentId, status){
            increBillCheck({agentId, status}).then(res =>{
                this.$message({
                    showClose: true,
                    message: "设置成功",
                    type: 'success'
                })
                this.getIncreBillList()
            })
        },
        getIncreBillList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.form,params)
            increBillList(this.form).then(res=>{
                this.tableData = res.rows
                this.total= res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getIncreBillList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getIncreBillList()
        },
        onSearch(){
            this.page = 1
            this.getIncreBillList()
        },
        handleShowDetail(row){
            this.dialogDetail = true
            this.DetailForm = row
            if(row.status == 0){
                this.DetailForm.statusName = "未审核"
            }
            if(row.status == 1){
                this.DetailForm.statusName = "审核通过"
            }
            if(row.status == 2){
                this.DetailForm.statusName = "审核未通过"
            }
        },

    }
}
</script>
<style>
    .order-bill-apply .el-form-item__label{
        vertical-align: top;
    }
</style>