<template>
  <div class="userBlacklistMa fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">用户黑名单管理</span>
      <el-form
        inline
        ref="blacklistForm"
        v-model="blacklistFormData"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item>
          <el-select
            v-model="blacklistFormData.accountId"
            placeholder="请选择运营商"
            filterable
          >
            <el-option label="请选择运营商" value=""></el-option>
            <el-option
              v-for="item in accountList"
              :key="item.id"
              :label="item.accountName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="blacklistFormData.iccidMark"
            placeholder="ICCID"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="blacklistFormData.phone"
            placeholder="接入号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="blacklistFormData.bundPhone"
            placeholder="绑定手机号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="blacklistFormData.idCard"
            placeholder="身份证"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="addLlist"
            >加入黑名单</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-download"
            class="themed-button"
            type="info"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="iccidMark" label="ICCID" min-width="180">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="phone"
          label="接入号"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="accountName"
          label="运营商"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="agentName"
          label="代理商名称"
          min-width="120"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="bundPhone"
          label="绑定手机号"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="realName"
          label="身份证姓名"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="idCard"
          label="身份证号码"
          min-width="120"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="updateUser"
          label="操作人"
          min-width="180"
        >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="danger"
              class="themed-button"
              @click="removeList(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      title="添加黑名单"
      :visible.sync="dialogAddBlackList"
      v-if="dialogAddBlackList"
      @close="closeDialog"
    >
      <el-form label-position="left" label-width="200px" :model="ruleForm">
        <el-form-item label="ICCID" required>
          <el-input v-model="ruleForm.iccidMark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="themed-button" @click="addBlackListOk">确定</el-button>
        <el-button
          type="primary"
          class="themed-button"
          @click="closeDialog"
          >取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { messageBox } from "@/utils/common.js";
import {
  getAccountList,
  getUserBlackList,
  exportBlackList,
  removeBlackList,
  addBlackList,
} from "@/api/agentManagement.js";
import resize from "@/mixins/resize.js";
export default {
  name: "userBlacklistMa",
  mixins: [resize],
  data() {
    return {
      accountList: [],
      blacklistFormData: {
        accountId: "",
        iccidMark: "",
        phone: "",
        bundPhone: "",
        idCard: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      dialogAddBlackList: false,
      ruleForm: {
        iccidMark: "",
      },
    };
  },
  created() {
    this.getOperatorList();
    this.getUserBlackList();
  },
  methods: {
    getOperatorList() {
      getAccountList().then((res) => {
        this.accountList = res;
      });
    },
    addLlist() {
      this.dialogAddBlackList = true
    },
    addBlackListOk() {
        if(!this.ruleForm.iccidMark){
           this.$alert('请输入ICCID', '提示', {
								confirmButtonText: '确定',
							});
           return 
        }
             addBlackList(this.ruleForm).then((res) => {
        if (res.slide_msg_key == 1) {
          messageBox(res.slide_msg_message_key);
        }
        this.dialogAddBlackList = false
        this.ruleForm.iccidMark=""
        this.getUserBlackList()
      })
     
    },
    removeList(id) {
      this.$confirm("确定要删除吗？", "消息提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id,
          };
          removeBlackList(params).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getUserBlackList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getUserBlackList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.blacklistFormData, params);
      getUserBlackList(this.blacklistFormData).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onSearch() {
      this.page = 1
      this.getUserBlackList()
    },
    exportExcel() {
      exportBlackList(this.blacklistFormData).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    onPageChange(page) {
      this.page = page;
      this.getUserBlackList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getUserBlackList();
    },
    closeDialog(){
         this.dialogAddBlackList = false
        this.ruleForm.iccidMark=""
    },
  },
};
</script>
<style>
.userBlacklistMa .el-form-item__label {
  vertical-align: top;
}
.userBlacklistMa .dialog-row-label {
  text-align: right;
}
</style>