<template>
    <div class="user-statistice">
        <el-row :gutter='20'>
            <el-col :span='20'>
                <div class="title-box"><span class="m-title">用户数据统计</span></div>
                <div class="chose-item">
                     <el-form inline style="height: auto;">
                         <el-form-item label="条件选择:">
							</el-form-item>
                            <el-form-item>
                                <el-date-picker v-model="form1.year" type="year" placeholder="选择年份" value-format='yyyy' style="width:100px"></el-date-picker>
                            </el-form-item>

                             <el-form-item>
                                <el-select v-model="form1.productType" clearable filterable placeholder="产品类型" style="width:100px" @visible-change="changeList1">
                                   <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                           
                           <el-form-item>
                                <el-select v-model="form1.accountIds" multiple collapse-tags clearable filterable placeholder="请选择运营商" style="width:150px" @visible-change="changeList1">
                                    <el-option v-for="(item,index) in accountList1" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>

                            <el-form-item>
                                <el-select v-model="form1.agentIds" multiple collapse-tags clearable filterable placeholder="代理商" style="width:150px" @visible-change="changeList1">
                                    <el-option v-for="(item,index) in agentIdList1" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>
                            
                        
                            <el-form-item>
                                <el-select v-model="form1.toType" clearable filterable placeholder="业务类型" style="width:100px" @visible-change="changeList1">
                                    <el-option v-for="(item,index) in typeList1" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form1.mealIds" multiple collapse-tags clearable filterable placeholder="套餐"  style="width:150px">
                                    <el-option v-for="(item,index) in mealList1" :key="index" :label="item.mealName" :value="item.mealId">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button @click="showTable1" size="mini" class="themed-button" type="danger">确 定</el-button>
                                <el-button @click="exportTable('1')" size="mini" class="themed-button" type="danger">导 出</el-button>
                            </el-form-item>

                     </el-form>
                </div>
                <div ref='demo1' style="height:500px;background-color: #fff;"></div>
            </el-col>

            <el-col :span='4'>

            </el-col>
        </el-row>

        <el-row>
         <el-col :span='20'>
                <div class="title-box"><span class="m-title">累计用户数据统计</span></div>
                <div class="chose-item">
                     <el-form inline style="height: auto;">
                         <el-form-item label="条件选择:">
							</el-form-item>
                            <el-form-item>
                                <el-date-picker v-model="form2.year" type="year" placeholder="选择年份" value-format='yyyy' style="width:100px"></el-date-picker>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form2.productType" clearable filterable placeholder="产品类型" style="width:100px" @visible-change="changeList2">
                                   <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form2.accountIds" multiple collapse-tags clearable filterable placeholder="请选择运营商" style="width:150px" @visible-change="changeList2">
                                    <el-option v-for="(item,index) in accountList2" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>

                            <el-form-item>
                                <el-select v-model="form2.agentIds" multiple collapse-tags clearable filterable placeholder="代理商" style="width:150px" @visible-change="changeList2">
                                    <el-option v-for="(item,index) in agentIdList2" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>
                            
                        
                            <el-form-item>
                                <el-select v-model="form2.toType" clearable filterable placeholder="业务类型" style="width:100px" @visible-change="changeList2">
                                    <el-option v-for="(item,index) in typeList2" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form2.mealIds" multiple collapse-tags clearable filterable placeholder="套餐"  style="width:150px" @visible-change="changeList2">
                                    <el-option v-for="(item,index) in mealList2" :key="index" :label="item.mealName" :value="item.mealId">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button @click="showTable2" size="mini" class="themed-button" type="danger">确 定</el-button>
                                <el-button @click="exportTable('2')" size="mini" class="themed-button" type="danger">导 出</el-button>
                            </el-form-item>
                     </el-form>
                </div>
                <div ref='demo2' style="height:500px;background-color: #fff;"></div>
            </el-col>

            <el-col :span='4'>

            </el-col>
        </el-row>

        <el-dialog title="导出信息" :visible.sync="dialogExport" width="500" :destroy-on-close='true'>
            <el-form :model="exportForm"  ref="exportForm" label-position="right" label-width="200px">
                            <el-form-item label="起始时间:">
                                 <el-date-picker v-model="exportForm.startTime" type="month" placeholder="选择月份" value-format='yyyy-MM' style="width:250px"></el-date-picker>
                            </el-form-item>
                             <el-form-item label="终止时间:">
                                    <el-date-picker v-model="exportForm.endTime" type="month" placeholder="选择月份" value-format='yyyy-MM' style="width:250px"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="产品类型:">
                                <el-select v-model="exportForm.productType" clearable filterable placeholder="产品类型" style="width:250px" disabled>
                                   <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="运营商:">
                                <el-select v-model="exportForm.accountIds" multiple collapse-tags clearable filterable placeholder="请选择运营商"  style="width:250px" disabled>
                                    <el-option v-for="(item,index) in accountList" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>
                            <el-form-item label="代理商:">
                                <el-select v-model="exportForm.agentIds" multiple collapse-tags clearable filterable placeholder="代理商" style="width:250px" disabled>
                                    <el-option v-for="(item,index) in agentIdList" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>
                            
                        
                            <el-form-item label="业务类型:">
                                <el-select v-model="exportForm.toType" clearable filterable placeholder="业务类型"  style="width:250px" disabled>
                                    <el-option v-for="(item,index) in typeList" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="套餐:">
                                <el-select v-model="exportForm.mealIds" multiple collapse-tags clearable filterable placeholder="套餐"  style="width:250px" disabled>
                                    <el-option v-for="(item,index) in mealList" :key="index" :label="item.mealName" :value="item.mealId">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureExport('exportForm')">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogExport = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import echarts from "echarts"
import {getAccountList,userNumStatics,exportUserNumStatics,addNumStatics,exportAddNumStatics,searchParamCheck} from "@/api/operationalStatistics/operationalStatistics.js"
import dictGroup from '@/mixins/dictGroup.js'

export default {
    mixins:[dictGroup],
    data(){
        return{
            form1:{
                year:'',
                agentIds:[],
                accountIds:[],
                toType:'',
                productType:'',
                mealIds:[]
            },
            agentIdList1:[],
            accountList1:[],
            typeList1:[],
            mealList1:[],

            totalNewActive:[],  //新激活数
            totalInUse:[],  //在用用户数
            totalLose:[],//流失数
            loseRate:[], //流失比例
            totalAddNum:[], //净增加用户
            totalConUse:[], //月断后再充

            form2:{
                year:'',
                agentIds:[],
                accountIds:[],
                toType:'',
                productType:'',
                mealIds:[]
            },
            agentIdList2:[],
            accountList2:[],
            typeList2:[],
            mealList2:[],
            totalActiveSum:[], //累计激活数
            totalLoseSum:[], //累计流失数

            dialogExport:false,
            exportType:'',
            exportForm:{
                startTime:'',
                endTime:'',
                agentIds:[],
                accountIds:[],
                toType:'',
                productType:'',
                mealIds:[]
            },
             agentIdList:[],
            accountList:[],
            typeList:[],
            mealList:[],
            lineChart1:'',
            lineChart2:'',

        }
    },
    mounted() {
			window.onresize = () => {
				return (() => {
					this.lineChart1.resize()
					this.lineChart2.resize()
				})()
			}
		},
    created(){
        this.$nextTick(function(){
            this.initChart1()
            this.initChart2()
        })
    },
     watch:{
        'form1.productType'	:function(val){
                this.form1.agentIds = []
                this.form1.accountIds = []
                this.form1.mealIds = []
                this.form1.toType = ''
            },
        'form1.accountIds'	:function(val){
                this.form1.agentIds = []
                this.form1.toType = ''
                this.form1.mealIds = []
            },
        'form1.agentIds':function(val){
                this.form1.toType = ''
                this.form1.mealIds = []
            },
        'form1.toType':function(val){
                this.form1.mealIds = []
            },

        'form2.productType'	:function(val){
                this.form2.agentIds = []
                this.form2.accountIds = []
                this.form2.mealIds = []
                this.form2.toType = ''
            },
        'form2.accountIds'	:function(val){
                this.form2.agentIds = []
                this.form2.toType = ''
                this.form2.mealIds = []
            },
        'form2.agentIds':function(val){
                this.form2.toType = ''
                this.form2.mealIds = []
            },
        'form2.toType':function(val){
                this.form2.mealIds = []
            },

    },
    methods:{
        changeList1(e){
            if(e){
                return false
            }else{
                searchParamCheck(this.form1).then(res=>{
                    this.agentIdList1 = res.agents?res.agents:[]
                    this.accountList1 = res.account?res.account:[]
                    this.typeList1 = res.toTypes?res.toTypes:[]
                    this.mealList1 = res.meals
                })
            }

        },
        changeList2(e){
            if(e){
                return false
            }else{
                searchParamCheck(this.form2).then(res=>{
                    this.agentIdList2 = res.agents
                    this.accountList2 = res.account
                    this.typeList2 = res.toTypes
                    this.mealList2 = res.meals
                })
            }

        },

        showTable1(){
            userNumStatics(this.form1).then(res=>{
                this.totalNewActive = res.totalNewActive
                this.totalInUse = res.totalInUse
                this.totalLose = res.totalLose
                this.loseRate = res.loseRate
                this.totalAddNum = res.totalAddNum
                this.totalConUse = res.totalConUse
                this.initChart1()
           })
        },

        initChart1(){
            let dom = this.$refs.demo1
            let myChart = echarts.init(dom)
            this.lineChart1 = myChart
            const option = {
                title:[
                    {
                        top:'30%',
                        left:10,
                        subtextStyle: {
                            align: 'left',
                            color: '#0000ff',
                            fontSize: 12,
                        },
                        subtext: '用\n户\n数\n量'//   \n换行
                    },
                    {
                        top:'30%',
                        right:10,
                        subtextStyle: {
                            align: 'right',
                            color: '#0000ff',
                            fontSize: 12,
                        },
                        subtext: '比\n例'//   \n换行
                    },

                ],

                legend: {
                    data: [
                        {name: '新激活数'},
                        {name: '在用用户数'},
                        {name: '流失用户数'},
                        {name: '流失比例'},
                        {name: '净增加用户'},
                        {name: '月断后再充数'},
                    ],
                    textStyle: {
                        color: '#0000ff',
                        fontSize: 11
                    },
                    y: 'bottom',
                    x: 'center',
                },

                xAxis:{
                   data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
                },

                yAxis:[{
                   type:'value',
                   name:'单位（个）'
                },
                {
                   type:'value',
                   name:'单位（*100%）'
                }],

               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
                ],

                series:[
                   {
                       name:'新激活数',
                       type:'line',
                       data:this.totalNewActive,
                       yAxisIndex:0
                   },
                   {
                       name:'在用用户数',
                       type:'line',
                       data:this.totalInUse,
                       yAxisIndex:0
                   },

                   {
                       name:'流失用户数',
                       type:'line',
                       data:this.totalLose,
                       yAxisIndex:0
                   },
                  
                   {
                       name:'流失比例',
                       type:'line',
                       data:this.loseRate,
                       yAxisIndex:1
                   },

                   {
                       name:'净增加用户',
                       type:'line',
                       data:this.totalAddNum,
                       yAxisIndex:0
                   },

                   {
                       name:'月断后再充数',
                       type:'line',
                       data:this.totalConUse,
                       yAxisIndex:0
                   },

               ],

               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

            }

            myChart.setOption(option)
        },

        showTable2(){
            addNumStatics(this.form2).then(res=>{
                this.totalActiveSum = res.totalActiveSum
                this.totalLoseSum = res.totalLoseSum
                this.initChart2()
           })
        },

        initChart2(){
            let dom = this.$refs.demo2
            let myChart = echarts.init(dom)
            this.lineChart2 = myChart
            const option = {
                title:[
                    {
                        top:'30%',
                        left:10,
                        subtextStyle: {
                            align: 'left',
                            color: '#0000ff',
                            fontSize: 12,
                        },
                        subtext: '数\n量'//   \n换行
                    },

                ],

                legend: {
                    data: [
                        {name: '累计激活用户数'},
                        {name: '累计流失用户数'},
                    ],
                    textStyle: {
                        color: '#0000ff',
                        fontSize: 11
                    },
                    y: 'bottom',
                    x: 'center',
                },

                xAxis:{
                   data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
                },

                yAxis:{
                   type:'value',
                   name:'单位（个）'
                },
                

               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
                ],

                series:[
                   {
                       name:'累计激活用户数',
                       type:'line',
                       data:this.totalActiveSum,
                   },
                   {
                       name:'累计流失用户数',
                       type:'line',
                       data:this.totalLoseSum,
                   },

                   
               ],

               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

            }

            myChart.setOption(option)
        },

        exportTable(type){
            this.resetExport(type)
            this.dialogExport = true
            this.exportType = type
        },

        sureExport(){
            let exportMethod
           if(this.exportType == 1){
               exportMethod = exportUserNumStatics
           }else if(this.exportType == 2){
               exportMethod = exportAddNumStatics
           }
           exportMethod(this.exportForm).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
                            this.dialogExport = false
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
			})
        },

        resetExport(type){
            if(type == '1'){
                this.agentIdList = this.agentIdList1
                this.accountList = this.accountList1
                this.typeList = this.typeList1
                this.mealList = this.mealList1
                this.exportForm = {
                    startTime:'2021-01',
                    endTime:'2021-12',
                    agentIds:this.form1.agentIds,
                    accountIds:this.form1.accountIds,
                    toType:this.form1.toType,
                    productType:this.form1.productType,
                    mealIds:this.form1.mealIds
                }
            }else if(type == '2'){
                this.agentIdList = this.agentIdList2
                this.accountList = this.accountList2
                this.typeList = this.typeList2
                this.mealList = this.mealList2
                this.exportForm = {
                    startTime:'2021-01',
                    endTime:'2021-12',
                    agentIds:this.form2.agentIds,
                    accountIds:this.form2.accountIds,
                    toType:this.form2.toType,
                    productType:this.form2.productType,
                    mealIds:this.form2.mealIds
                }
            }
        }
    }
}
</script>

<style>
  .user-statistice  .el-form-item--small.el-form-item{
        margin-bottom:0
    }

    .el-row {
        margin-bottom: 20px;
    }
</style>