import request from './../request.js'

//stockInList.vue
export function listInCode(){
    return request({
        url: 'stockBatchIn/listInCode.do?',
        method: 'post'
    })
}
export function stockInList(params){
    return request({
        url: '/stockBatchIn/list.do?',
        method: 'post',
        data:params
    })
}
export function limitExportCount(params){
    return request({
        url: 'stockBatchIn/exportSync.do?',
        method: 'post',
        data:params
    })
}
export function stockDeleteById(params){
    return request({
        url: 'stockBatchIn/deleteById.do?',
        method: 'post',
        data:params
    })
}

//stockInList.vue -- formAdd.vue
export function findById(params){
    return request({
        url: 'stockBatchIn/findById.do?',
        method: 'post',
        data:params
    })
}
export function getNewBatchCode(params){
    return request({
        url: 'stockBatchIn/getNewBatchCode.do?',
        method: 'post',
        data:params
    })
}
export function importAdd(params){
    return request({
        url: 'stockBatchIn/importAddSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

//stockInList.vue -- formUpdate.vue
export function importUpdate(params){
    return request({
        url: 'stockBatchIn/importUpdate.do?',
        method: 'post',
        data:params
    })
}

//stockInList.vue -- formDelete.vue
export function importDelete(params){
    return request({
        url: 'stockBatchIn/importDelete.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

//stockInCardList.vue
export function listCardIn(params){
    return request({
        url: 'stockBatchIn/listCardIn.do?',
        method: 'post',
        data:params
    })
}
export function CardInExport(params){
    return request({
        url: 'stockBatchIn/exportCardSync.do?',
        method: 'post',
        data:params
    })
}
export function deleteByIdCardIn(params){
    return request({
        url: 'stockBatchIn/deleteByIdCardIn.do?',
        method: 'post',
        data:params
    })
}

//stockInCardList.vue -- cardDelete.vue
export function importExpire(params){
    return request({
        url: 'stockBatchIn/importExpire.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

//stockInCardList.vue -- loglist.vue
export function listExpireLog(params){
    return request({
        url: 'stockBatchIn/listExpireLog.do?',
        method: 'post',
        data:params
    })
}
//stockInCardList.vue -- loglist.vue  -- dialogShow.vue
export function listExpireDetail(params){
    return request({
        url: 'stockBatchIn/listExpireDetail.do?',
        method: 'post',
        data:params
    })
}

//stockOutList.vue
export function listOutCode(){
    return request({
        url: 'stockBatchOut/listOutCode.do?',
        method: 'post'
    })
}
export function stockOutList(params){
    return request({
        url: 'stockBatchOut/list.do?',
        method: 'post',
        data:params
    })
}
export function OutCodeExport(params){
    return request({
        url: 'stockBatchOut/exportSync.do?',
        method: 'post',
        data:params
    })
}
export function stockOutDeleteById(params){
    return request({
        url: 'stockBatchOut/deleteById.do?',
        method: 'post',
        data:params
    })
}

//stockOutList.vue -- formAdd.vue
export function importAddSync(params){
    return request({
        url: 'stockBatchOut/importAddSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function OutFindById(params){
    return request({
        url: 'stockBatchOut/findById.do?',
        method: 'post',
        data:params
    })
}
export function getNewOutCode(params){
    return request({
        url: 'stockBatchOut/getNewOutCode.do?',
        method: 'post',
        data:params
    })
}
//stockOutList.vue -- formUpdata.vue
export function stockOutImportUpdate(params){
    return request({
        url: 'stockBatchOut/importUpdate.do?',
        method: 'post',
        data:params
    })
}
//stockOutList.vue -- formDelete.vue
export function importDeleteSync(params){
    return request({
        url: 'stockBatchOut/importDeleteSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
//stockOutCardList.vue
export function listCardOut(params){
    return request({
        url: 'stockBatchOut/listCardOut.do?',
        method: 'post',
        data:params
    })
}
export function CardOutExport(params){
    return request({
        url: 'stockBatchOut/exportCardSync.do?',
        method: 'post',
        data:params
    })
}

export function deleteByIdCardOut(params){
    return request({
        url: 'stockBatchOut/deleteByIdCardOut.do?',
        method: 'post',
        data:params
    })
}