<template>

<div class="newCoupons">
    <el-form label-position="right" label-width="200px" ref="form" :model="form" :rules="rules">
        <el-form-item label="买送名称:" prop="buySendName" required>
            <el-input v-model="form.buySendName"></el-input>
        </el-form-item>
        <el-form-item label="买送开始时间:" prop="startTime" required>
            <el-date-picker type="datetime" placeholder="选择日期" value-format="yyyy/MM/dd HH:mm:ss" v-model="form.startTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="买送结束时间:" prop="endTime" required>
            <el-date-picker type="datetime" placeholder="选择日期" value-format="yyyy/MM/dd HH:mm:ss" v-model="form.endTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="ICCID:">
            <el-input v-model="form.iccid"></el-input>
            <p class="red">iccid可以填*(代表所有卡),单个iccid号，iccid区间(XXXX1:XXXX2)</p>
        </el-form-item>
        <el-form-item label="选择代理商:" >
            <el-select v-model="form.filterAgentName" @change="changeAccont">
                <el-option label="请选择" value=""></el-option>
                <el-option v-for="(item,index) in agentList" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="所属类别:">
            <el-select v-model="form.category" @change="changeCategory">
                <!-- <el-option label="电信" value="1"></el-option>
                <el-option label="其他卡务" value="2"></el-option>
                <el-option label="移动" value="3"></el-option>
                <el-option label="联通" value="4"></el-option>
                <el-option label="联通Q" value="6"></el-option>
                <el-option label="DCP电信" value="5"></el-option>
                <el-option label="新移动" value="7"></el-option>
                <el-option label="新联通" value="8"></el-option> -->
                 <el-option label="请选择运营商" value=""></el-option>
                 <el-option v-for="(item,index) in $store.getters.categorys" :label="item.name" :value="item.value" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="form.category ==categorySlist['中国移动'] || form.category == categorySlist['中国联通']" label="子类别:">
            <el-select v-model="form.secondCategory" @change="changeAccont">
                <el-option v-for="(item,index) in secondCategoryList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="购买套餐勾选:" prop="buyMeal" required>
            <el-select v-model="form.buyMeal">
                <el-option v-for="(item,index) in buyMealList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="赠送套餐勾选:" prop="sendMeal" required>
            <el-select v-model="form.sendMeal">
                <el-option v-for="(item,index) in sendMealList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="sure('form')">确定</el-button>
        <el-button type="primary" class="themed-button" @click="cancle('form')">取 消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
    import {buySendSave,getAgentList,getMealListByAccountId,getSongMealListByAccountId} from '@/api/eventDiscountManagement/buySameGet.js'

    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'
    import store from '@/store'
export default {
    data(){
        return {
            form:{
                id:'',
                buySendName:'',
                startTime:'',
                endTime:'',
                iccid:'',
                filterAgent:'',
                filterAgentName:'',
                category:'',
                secondCategory:'',
                buyMeal:'',
                sendMeal:'',
                buyMealCount:1,
                sendMealCount:1
            },
            agentList:[],
            secondCategoryList:[],
            buyMealList:[],
            sendMealList:[],
            categorySlist:{},
            rules:{
                buySendName:[
                    { required: true, message: '买送名称不能为空', trigger: 'blur' }
                ],
                startTime:[
                    { required: true, message: '请设置买送开始时间', trigger: 'blur' }
                ],
                endTime:[
                    { required: true, message: '请设置买送结束时间', trigger: 'blur' }
                ],
                buyMeal:[
                    { required: true, message: '购买套餐', trigger: 'change' }
                ],
                sendMeal:[
                    { required: true, message: '赠送套餐不能为空', trigger: 'change' }
                ]

            }
        }
    },
    mounted(){
        if(store.getters.categorys.length<1){
          store.dispatch('getCategory').then(()=>{
                  if(store.getters.categorys.length>0){
            store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.categorySlist[name] =value
            })
               }
          })
        }else{
             store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.categorySlist[name] =value
            })
        }
        if(store.getters.secondCategory_yd.length<1||store.getters.secondCategory_lt.length<1){
           store.dispatch("getPackageType")
        }
        this._getAgentList()
    },
    methods:{
        sure(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.agentList.forEach((item)=>{
                        if(item.name == this.form.filterAgentName){
                            this.form.filterAgent = item.id
                        }
                    })
                    if(this.form.filterAgentName == ''){
                        this.form.filterAgent = ''
                    }
                    buySendSave(this.form).then((res)=>{
                        if(res.slide_msg_key == 1){
                            messageBox(this,res.slide_msg_message_key)
                        }
                        // this.$refs[formName].resetFields();
                        this.$emit('refresh')

                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        cancle(formName){
            this.$refs[formName].resetFields();
            this.$emit('close')
        },
        changeCategory(){
            if(this.form.category == this.categorySlist['中国移动']){
                // this.secondCategoryList = [
                //     {label:'请选择',value:''},
                //     {label:'GD_IOT',value:'1'},
                //     {label:'OneLink',value:'2'},
                //     {label:'深圳移动',value:'3'}
                // ]
                this.secondCategoryList = []
                if(store.getters.secondCategory_yd.length>0){
                  store.getters.secondCategory_yd.forEach(item=>{
                      if(item.groupName){
                     this.secondCategoryList.push({label:item.groupName,value:item.dictValue})
                      }
                  }) 
                }
                  this.secondCategoryList.unshift( {label:'请选择',value:''})
            }else if(this.form.category == this.categorySlist['中国联通']){
                this.secondCategoryList = []
                if(store.getters.secondCategory_lt.length>0){
                  store.getters.secondCategory_lt.forEach(item=>{
                      if(item.groupName){
                     this.secondCategoryList.push({label:item.groupName,value:item.dictValue})
                      }
                  }) 
                }
                  this.secondCategoryList.unshift( {label:'请选择',value:''})
                // this.secondCategoryList = [
                //     {label:'请选择',value:''},
                //     {label:'浙江联通',value:'1'},
                //     {label:'青岛联通',value:'2'},
                //     {label:'湖南联通',value:'3'}
                // ]
            }
            this.changeAccont()
        },
        changeAccont(){
            this.form.buyMeal = ''
            this.form.sendMeal = ''
            getMealListByAccountId(this.form).then((res)=>{
                this.buyMealList = res.mealList
            })
            getSongMealListByAccountId(this.form).then((res)=>{
                this.sendMealList = res.mealList
            })
        },
        _getAgentList(){
            getAgentList({status:0}).then((res)=>{
                this.agentList = res
            })
        }
    },
    components:{
        SearchSelect
    }
}
</script>

<style></style>
