<template>

<div class="form-add">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="库编号:">
            <el-input v-model="form.imeiBankCode" readonly></el-input>
        </el-form-item>
        <el-form-item label="产品名称:" prop="productName" required>
            <el-input v-model="form.productName"></el-input>
        </el-form-item>
        <el-form-item label="客户:" prop="customerName" required>
            <el-input v-model="form.customerName"></el-input>
        </el-form-item>
        <el-form-item label="备注:">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="代理商">
                <el-select v-model="form.customerId" filterable>
                    <el-option v-for="(item,index) in agentList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
        </el-form-item>
         <el-form-item
          label="是否推送"
          prop="needPush"
        >
          <el-radio-group v-model="form.needPush">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="update('form')">修改</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">关闭</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {importUpdate,findById,getNewImeiBankCode,getList4agent} from '@/api/meCardBindManagement/imeiLibrary.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    props:['id'],
    data(){
        return {
            form:{
                id:'',
                imeiBankCode:'',
                productName:'',
                remark:'',
                customerName:'',
                customerId:'',
                needPush:"",
            },
            agentList:[],
            rules: {
                productName: [
                    { required: true, message: '请选择产品名称', trigger: 'blur' }
                ],
                customerName: [
                    { required: true, message: '请填写客户名称', trigger: 'blur' }
                ]
            },
        }
    },
    mounted(){
        this.getAgentList()
        this.form.id = this.id
        this._findById()
        // this._getNewImeiBankCode()
    },
    methods:{
        getAgentList(){
            getList4agent().then(res=>{
                this.agentList = res
            })
        },
        update(formName){
            confirmBox(this,'确定提交修改吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    importUpdate(this.form).then(res=>{
                        messageBox(this,'修改成功')
                        this.cancle()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        cancle(){
            this.$emit('close')
        },
        _findById(){
            findById(this.form).then((res)=>{
                console.log(res)
                this.form.imeiBankCode = res.entity.imeiBankCode
                this.form.productName = res.entity.productName
                this.form.customerName = res.entity.customerName
                this.form.remark = res.entity.remark
                this.form.customerId = res.entity.customerId
                this.form.needPush = res.entity.needPush
            })
        },
        // _getNewImeiBankCode(){
        //     getNewImeiBankCode(this.form).then((res)=>{
        //         this.form.imeiBankCode = res
        //     })
        // }
    }
}
</script>

