<template>

<div class="lift-card table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">代理商用户平均使用周期</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="产品类型:">
                <el-select v-model="productType" filterable>
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box" v-loading="loading">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="产品类型" prop="productType">
                <template  slot-scope="scope">
                    <span>{{productTypeName(scope.row.productType)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="代理商" prop="agentName"></el-table-column>
            <el-table-column label="累计使用月数" prop="cardMonthCount"></el-table-column>
            <el-table-column label="卡数" prop="cardNum"></el-table-column>
            <el-table-column label="平均使用周期" show-overflow-tooltip>
                <template  slot-scope="scope">
                    <span>{{(scope.row.cardMonthCount/scope.row.cardNum).toFixed(2)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="统计时间" prop="updateTime" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {getActiveCardList} from '@/api/staticsManagement/cardUseCycel.js'
import {messageBox,confirmBox} from '@/utils/common.js'

import resize from '@/mixins/resize.js'
import dictGroup from '@/mixins/dictGroup.js'

export default {
    name:'businessCardLifeCycle',
    mixins:[resize,dictGroup],
    data(){
        return {
            loading:false,
            productType:'',
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0

        }
    },
    mounted(){
        this._getActiveCardList()
    },
    methods:{
        searchTable(){
            this._getActiveCardList()
        },
        handleSizeChange(val){
            this.pageSize = val
            this._getActiveCardList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._getActiveCardList()
        },
        productTypeName(productType){
            let result = this.w_PRODUCT_.find((item)=>{
                return item.dictValue == productType
            })
            if(!result){
                return '-'
            }
            if(result.label){
                return result.label
            }else{
                return '-'
            }
        },
        _getActiveCardList(){
            this.loading = true
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            params.productType = this.productType
            getActiveCardList(params).then((res) => {
                this.loading = false
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    }
}
</script>
