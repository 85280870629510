<template>

<div class="form-add">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="库编号:">
            <el-input v-model="form.imeiBankCode" readonly></el-input>
        </el-form-item>
        <el-form-item label="产品名称:" prop="productName" required>
            <el-input v-model="form.productName"></el-input>
        </el-form-item>
        <el-form-item label="客户:" prop="customerName" required>
            <el-input v-model="form.customerName"></el-input>
        </el-form-item>
        <el-form-item label="需要绑定:">
            <el-select filterable v-model="form.needBind">
                <el-option label="需要绑定" value="1"></el-option>
                <el-option label="不需要绑定" value="0"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="备注:">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="代理商">
                <el-select v-model="form.customerId" disabled>
                    <el-option v-for="(item,index) in agentList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
        </el-form-item>
       
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="update('form')">修改</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">关闭</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {importUpdateImei,findByIdImei,getNewImeiBankCode,getList4agent} from '@/api/meCardBindManagement/imeiLibrary.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    props:['id'],
    data(){
        return {
            form:{
                id:'',
                imeiBankCode:'',
                productName:'',
                needBind:'',
                remark:'',
                customerName:'',
                customerId:'',
            },
            agentList:[],
            rules: {
                productName: [
                    { required: true, message: '请选择产品名称', trigger: 'blur' }
                ],
                customerName: [
                    { required: true, message: '请填写客户名称', trigger: 'blur' }
                ]
            },
        }
    },
    mounted(){
        this.getAgentList()
        this.form.id = this.id
        this._findByIdImei()

        // this._getNewImeiBankCode()
    },
    methods:{
        getAgentList(){
            getList4agent().then(res=>{
                this.agentList = res
            })
        },
        update(formName){
            confirmBox(this,'确定提交修改吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    importUpdateImei(this.form).then(res=>{
                        messageBox(this,'修改成功')
                        this.cancle()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        cancle(){
            this.$emit('close')
        },
        _findByIdImei(){
            findByIdImei(this.form).then((res)=>{
                this.form.imeiBankCode = res.entity.imeiBankCode
                this.form.productName = res.entity.productName
                this.form.customerName = res.entity.customerName
                this.form.needBind = res.entity.needBind
                this.form.remark = res.entity.remark
                this.form.customerId = res.entity.customerId
            })
        },
        // _getNewImeiBankCode(){
        //     getNewImeiBankCode(this.form).then((res)=>{
        //         this.form.imeiBankCode = res
        //     })
        // }
    }
}
</script>

