<template>
  <div class="operatingStrategyList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">运营策略列表</span>
      <el-form
        inline
        ref="virtualConfigForm"
        v-model="operatingStrategyFormData"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
      <el-form-item label="移动优先策略不建议使用天卡套餐" class="label_tip">
        </el-form-item>
        <el-form-item label="策略名称" :label-width="formLabelWidth">
          <el-input
            v-model="operatingStrategyFormData.name"
            placeholder="策略名称"
            class="iccid-width"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="add"
            icon="el-icon-plus"
            class="themed-button"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        border
        ref="table"
        stripe
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <!-- <el-table-column
          show-overflow-tooltip
          prop="id"
          label="id"
          min-width="120"
        >
        </el-table-column> -->
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="策略名称"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="param"
          label="策略参数"
          min-width="120"
        >
        </el-table-column>

        <el-table-column label="是否强制" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.force">是</span>
            <span v-else-if="!scope.row.force">否</span>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="coldTime"
          label="冷却期时间"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="priority"
          label="优先级"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="创建时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="updateTime"
          label="更新时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column label="操作" min-width="160">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="themed-button"
              type="info"
              @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              class="themed-button"
              type="danger"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
    
    <el-dialog
      :title="addTitle"
      :visible.sync="dialogAddvisible"
      width="700"
      :destroy-on-close="true"
    >
      <el-form
        :model="addForm"
        ref="addForm"
        :inline="true"
        style="height: 500px; overflow-y: scroll"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="策略名称" prop="name" :label-width="formLabelWidth">
              <el-input v-model.trim="addForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div>
          <el-row style="margin: 20px 0">
            <el-col :span="12">
              <span>优先级规则:</span>
            </el-col>
          </el-row>

          <el-table :data="addForm.param" style="margin-top: 15px">
            <el-table-column label="第一优先级" prop="" min-width="120">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.priorityList[0].accountName"
                  size="mini"
                  style="width: 80%"
                  clearable 
                >
                  <el-option label="移动" value="移动"></el-option>
                  <el-option label="电信" value="电信"></el-option>
                  <el-option label="联通" value="联通"></el-option>
                   <el-option label="广电" value="广电"></el-option>
                  <!-- <el-option
                    v-for="item in operatorList"
                    :key="item.index"
                    :label="item.speedKey"
                    :value="item.speedValue"
                  ></el-option> -->
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="第二优先级" prop="" min-width="120">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.priorityList[1].accountName"
                  size="mini"
                  style="width: 80%"
                  clearable 
                >
                  <el-option label="移动" value="移动"></el-option>
                  <el-option label="电信" value="电信"></el-option>
                  <el-option label="联通" value="联通"></el-option>
                   <el-option label="广电" value="广电"></el-option>
                  <!-- <el-option
                    v-for="item in operatorList"
                    :key="item.index"
                    :label="item.speedKey"
                    :value="item.speedValue"
                  ></el-option> -->
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="第三优先级" prop="" min-width="120">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.priorityList[2].accountName"
                  size="mini"
                  style="width: 80%"
                  clearable 
                >
                  <el-option label="移动" value="移动"></el-option>
                  <el-option label="电信" value="电信"></el-option>
                  <el-option label="联通" value="联通"></el-option>
                  <el-option label="广电" value="广电"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="第四优先级" prop="" min-width="120">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.priorityList[3].accountName"
                  size="mini"
                  style="width: 80%"
                  clearable 
                >
                  <el-option label="移动" value="移动"></el-option>
                  <el-option label="电信" value="电信"></el-option>
                  <el-option label="联通" value="联通"></el-option>
                   <el-option label="广电" value="广电"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="规则类型" prop="type" min-width="120">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.type"
                  size="mini"
                  style="width: 80%"
                >
                  <el-option label="流量" value="flow"></el-option>
                  <el-option label="默认" value="default"></el-option>
                   <el-option label="最大流量限制" value="maxFlow"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="月真实流量" prop="condition" min-width="160">
              <template slot-scope="scope">
                <!-- <el-input
                  v-model.trim="scope.row.condition"
                  size="mini"
                  style="width: 60%"
                ></el-input> -->
                 <el-input-number v-model.trim="scope.row.condition"  controls-position="right"  size="mini"
                 style="width: 70%" :precision="0"  :min="0" :max="1024000" ></el-input-number>
                <span>(MB)</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="170">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  v-if="!scope.row.status"
                  plain
                  size="mini"
                  @click="deleteRule(scope.row, scope.$index)"
                  >删除</el-button
                >
                <el-button
                  type="info"
                  :disabled="!!(addForm.param.length>=3)"
                  plain
                  size="mini"
                  @click="afterAddRule(scope.row, scope.$index)"
                  >新增</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <el-col :span="12">
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  class="themed-button"
                  type="primary"
                   :disabled="!!(addForm.param.length>=3)"
                  @click="addRule"
                  >新增一行</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <el-col :span="24">
            <el-form-item label="是否强制" prop="force" :label-width="formLabelWidth">
              <el-select v-model="addForm.force">
                <el-option label="是" value="true"></el-option>
                <el-option label="否" value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="冻结期" prop="coldTime" :label-width="formLabelWidth" style="display:inline-block">
              <el-input v-model.trim="addForm.coldTime" placeholder="单位秒"></el-input>
            </el-form-item>
            <span style="height:32px;line-height:32px">(S)</span>
          </el-col>
           <!-- <el-col :span="1">
             <span style="height:32px;line-height:32px">(S)</span>
            </el-col> -->
         
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="优先级" prop="priority" :label-width="formLabelWidth">
             <el-input v-model.trim="addForm.priority"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="success" @click="submitForm"
          >确 认</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogAddvisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import Utils from "@/utils/utils.js";
import {messageBox,confirmBox} from '@/utils/common.js'
import {
  operatingStrategyList,
  operatingStrategyDelete,
  operatingStrategyAdd,
  operatingStrategyEdit,
  operatingStrategyById,
} from "@/api/virtualCard.js";
export default {
  name: "operatingStrategyList",
  mixins: [resize],
  data() {
    return {
      operatingStrategyFormData: {
        name: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      dialogAddvisible: false,
      addForm: {
        name: "",
        priority: "",
        coldTime: 7200,
        force: "false",
        param: [
            {
              priorityList: [
              {
                accountName: "移动",
              },
              {
                accountName: "电信",
              },
              {
                accountName: "联通",
              },
               {
                accountName: "联通",
              },
            ],
            type: "default",
            condition: "",   
            }
        ],
      },
      addTitle: "",
      formLabelWidth:"80px"
    };
  },
  created() {
    this.getOperatingStrategyList();
  },
  methods: {
    getOperatingStrategyList() {
      let params = {
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.operatingStrategyFormData, params);
      operatingStrategyList(this.operatingStrategyFormData).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onPageChange(page) {
      this.page = page;
      this.getOperatingStrategyList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getOperatingStrategyList();
    },

    onSearch() {
      this.page = 1;
      this.getOperatingStrategyList();
    },
resetAddForm(){
this.addForm ={
                name:'',
                priority:'',
                force:'false',
                coldTime:7200,
                param:[
            {
              priorityList: [
              {
                accountName: "移动",
              },
              {
                accountName: "电信",
              },
              {
                accountName: "联通",
              },
              {
                accountName: "广电",
              },
            ],
            type: "default",
            condition: "",   
            }
        ],
            }
},

    add() {
      this.dialogAddvisible = true;
      this.addTitle = "新增"
       this.resetAddForm()
    },

  
    addRule() {
      this.addForm.param.push(
         {
            priorityList: [
              {
                accountName: "移动",
              },
              {
                accountName: "电信",
              },
              {
                accountName: "联通",
              },
              {
                accountName: "广电",
              },
            ],
            type: "default",
            condition: "",
          }
      )  
    },
    afterAddRule(data, index) {
     this.addForm.param.splice(parseInt(index)+1,0,{
            priorityList: [
              {
                accountName: "",
              },
              {
                accountName: "",
              },
              {
                accountName: "",
              },
              {
                accountName: "",
              },
            ],
            type: "default",
            condition: "",
          })
    },
    deleteRule(data, index) {
        this.addForm.param.splice(index,1)
    },

     submitForm(){
            if(this.addForm.name ==""){
             messageBox(this,'请输入策略名称')
                return
            }
            if(this.addForm.param.length < 1){
                messageBox(this,'请至少添加一条规则数据')
                return
            }
          
            if(this.addForm.param.length >=1){
                for(let i=0;i<this.addForm.param.length;i++){
                     var arr = this.addForm.param[i].priorityList.sort();
                     console.log(arr,526)
                     for(var j = 0;j<arr.length-1;j++){
                        //  if(arr[j].accountName == arr[j+1].accountName && arr[j].accountName!=""){
                        //  messageBox(this,'优先级运营商不能重复')
                        // return
                        //  }
                        for(var n = j+1;n<arr.length;n++){
                         if(arr[j].accountName == arr[n].accountName && arr[j].accountName!=""){
                         messageBox(this,'优先级运营商不能重复')
                        return
                         }
                        }
                        
                     }
                }
            }
             if(this.addForm.coldTime ==""||isNaN(parseFloat(this.addForm.coldTime))){
             messageBox(this,'冻结期填写不正确，请重新输入')
                return
            }
            if(this.addForm.priority ==""||isNaN(parseFloat(this.addForm.priority))){
             messageBox(this,'优先级填写不正确，请重新输入')
                return
            }

            if(!!this.addForm.id){ //编辑
                let params ={
                   id:this.addForm.id,
                   name:this.addForm.name,
                   priority:this.addForm.priority,
                   coldTime:this.addForm.coldTime,
                   force:this.addForm.force,
                   param:JSON.stringify(this.addForm.param)
                }
                operatingStrategyEdit(params).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                    });
                this.dialogAddvisible = false
                this.getOperatingStrategyList()
                })
            }else{   //tianjia
                let params ={
                   name:this.addForm.name,
                   priority:this.addForm.priority,
                   coldTime:this.addForm.coldTime,
                   force:this.addForm.force,
                   param:JSON.stringify(this.addForm.param)
                }
                operatingStrategyAdd(params).then(res=>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                        });
                    this.dialogAddvisible = false
                    this.getOperatingStrategyList()
                })
            }
        },

    handleEdit(id) {
     this.dialogAddvisible = true;
     this.addTitle = "编辑"
     this.addForm.id = id
     operatingStrategyById({id}).then(res=>{
        this.addForm.id = res.id
        this.addForm.name = res.name
        this.addForm.priority = res.priority
        this.addForm.force = res.force+""
        this.addForm.coldTime = res.coldTime
        let param = JSON.parse(res.param)
        console.log(param,582)
        if(param.length>0){
          param.forEach(element => {
           if(element.priorityList.length==3){
           element.priorityList.push({accountName:""})
        }
          });
        }
        
        this.addForm.param = param
     })
    },

    
    handleDelete(id) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        operatingStrategyDelete({ id: id }).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getOperatingStrategyList();
        });
      });
    },
  },
};
</script>

<style>
.operatingStrategyList .el-form-item__label {
  vertical-align: top;
}
.operatingStrategyList .label_tip .el-form-item__label{
  color:#f00;
}
</style>