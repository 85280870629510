<template>
    <div class="system-management-role fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">系统角色管理</span>
            <el-form inline ref="systemRoleForm" v-model="systemRoleFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="systemRoleFormData.roleName" placeholder="角色名"></el-input>
                </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch()">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-circle-plus" class="themed-button" type="info" @click="add()">新增</el-button>
				</el-form-item>

            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                show-overflow-tooltip
                    prop="roleName"
                    label="名称"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="remark"
                    label="描述"
                    min-width="200"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createDatetime"
                    label="创建时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="状态"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==0">正常</span>
                        <span v-else>停用</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="150">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        class="themed-button" type="info"
                        @click="handleEdit(scope.row.id)"
                        >编辑</el-button>
                        <el-button
                        v-if="scope.row.status==0"
                        size="mini"
                        class="themed-button" type="primary"
                        @click="roleEnable(scope.row.id,scope.row.status)"
                        >禁用</el-button>
                        <el-button
                        v-else
                        size="mini"
                        class="themed-button" type="primary"
                        @click="roleEnable(scope.row.id,scope.row.status)"
                        >启用</el-button>
                    </template>
                </el-table-column>
                  
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="新增用户" :visible.sync="addDialogVisible" width="700px" :destroy-on-close='true'>
            <el-form :model="addUserForm"  ref="addUserForm" style="height:500px;overflow-y:scroll">
                <el-form-item label="名称" prop="roleName">
                    <el-input v-model="addUserForm.roleName"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="remark">
                    <el-input type="textarea" v-model="addUserForm.remark"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="addUserForm.status">
                    <el-radio :label="0">启用</el-radio>
                    <el-radio :label="1">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-for="item in childrenList" :key="item.index" :label="item.resourceName">
                    <el-checkbox-group v-model="addUserForm.checkList">
                      <div v-if="!!item.arr && item.arr.length > 0" v-for="child in item.arr" :key='child.index'  style="display:inline-block;width:33%">
                             <el-checkbox :label="child.id" >{{child.resourceName}}</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureAdd('addUserForm')">确 定</el-button>
                <el-button class="themed-button" type="info" @click="reset('addUserForm')">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="addDialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改角色" :visible.sync="editDialogVisible" width="900px" :destroy-on-close='true'>
            <el-form :model="editRoleForm"  ref="editRoleForm" style="height:500px;overflow-y:scroll">
                <el-form-item label="名称" prop="roleName">
                    <el-input v-model="editRoleForm.roleName"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="remark">
                    <el-input type="textarea" v-model="editRoleForm.remark"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="editRoleForm.status">
                    <el-radio :label="0">启用</el-radio>
                    <el-radio :label="1">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                   <el-table
                        :data="editTableData"
                        style="width: 100%"
                        row-key="id"
                        border
                        lazy
                        default-expand-all
                        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                        <el-table-column
                        type="index"
                        width="50">
                        </el-table-column>
                        <el-table-column
                        label="类别名称"
                       >
                       <template slot-scope="scope">
                           <el-checkbox-group v-model="editCheckList" style="display:inline-block">
                               <el-checkbox :label="scope.row.id">{{scope.row.text}}</el-checkbox>
                           </el-checkbox-group>
                       </template>
                        </el-table-column>
                        <el-table-column
                        show-overflow-tooltip
                        prop="serviceUrl"
                        label="跳转路径"
                        >
                        </el-table-column>
                        <el-table-column
                        show-overflow-tooltip
                        prop="permission"
                        label="权限标识">
                        </el-table-column>
                    </el-table>
                </el-form-item>
               
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureEdit()">确 定</el-button>
                <el-button class="themed-button" type="info" @click="resetEdit('editRoleForm')">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="editDialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import loading from '@/utils/Loading.js'
import { systemRoleList,addSystemRole,addUserResourceList,editRoleTreeTable,roleCheckedItem,updateRole,updateRoleStatus,updateRoleForm} from "@/api/systemManagement.js";
export default {
    name:'systemRole',
    mixins:[resize],
    data(){
        return {
            systemRoleFormData:{
                roleName:'',
                type:'1'
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            addDialogVisible:false,
            addUserForm:{
                roleName:'',
                remark:'',
                status:'0',
                checkList:[],
            },
            parentList:[],
            childrenList:[],

            editRoleForm:{
                roleName:'',
                remark:'',
                status:'0',
            },
            editDialogVisible:false,
            editCheckList:[],
            editTableData:[],
            editId:'',
        }
    },
    created(){
        this.getSystemRoleList()
    },
    methods:{
        getSystemRoleList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize,
            }
            Object.assign(this.systemRoleFormData,params)
            systemRoleList(this.systemRoleFormData).then(res=>{
                this.tableData=res.rows
                this.total=res.count
            })
        },
        onSearch(){
            this.page=1
            this.getSystemRoleList()
        },
        onPageChange(page){
            this.page = page
           this.getSystemRoleList()
			
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSystemRoleList()
        },
        add(){
            this.addDialogVisible=true
            this.addUserForm.status = 0
            let params={
                fatherId:'0',
                status:'0',
                type:'1'
            }
            addUserResourceList(params).then(res=>{
                res.forEach(item=>{
                    if(!item.parentMenuId){
                        this.parentList.push(item)
                    }
                })
                let parentArr = []
                this.parentList.forEach(item=>{
                    let itemObj = {}
                    let arr = []
                    res.forEach(resItem=>{
                        if(item.id == resItem.parentMenuId){
                            if(!!resItem.id){
                                arr.push(resItem)
                            }
                            
                        }
                    })
                    itemObj.resourceName = item.resourceName
                    itemObj.arr = arr
                    itemObj.id = item.id
                    parentArr.push(itemObj)

                })
                this.childrenList = parentArr
            })
           
            
        },
        sureAdd(formName){
            console.log(this.addUserForm.checkList,219)
            let resources = ''
            if(this.addUserForm.checkList.length>0){
               resources='_'+this.addUserForm.checkList.join('_')
            }
            console.log(resources,287)
            let params= {
                'type':'1',
                'fatherId':'0',
                'roleName':this.addUserForm.roleName,
                'remark':this.addUserForm.remark,
                'status':this.addUserForm.status,
                'resources':resources
            }
            loading.show()
            addSystemRole(params).then(res=>{
                this.$message({
					type: 'success',
					message:'添加成功'
                });
                this.$refs[formName].resetFields()
                this.getSystemRoleList()
                this.addDialogVisible=false
            })

        },
        reset(formName){
            this.$refs[formName].resetFields()
        },
        handleEdit(id){
            this.editId = id
            if(id ==1){
                this.$alert('1号ID角色是默认角色不允许进行修改操作!', "消息提示", {
						confirmButtonText: '确定',
				})
				return
            }
            this.editDialogVisible = true
            updateRoleForm({"id":id}).then(res=>{
                this.editRoleForm.roleName = res.entity.roleName
                this.editRoleForm.status = res.entity.status
                this.editRoleForm.remark = res.entity.remark
            })
            editRoleTreeTable().then(res=>{
                this.editTableData = res.rows
            })

            // 获取已勾选
            this.editCheckList=[]
            roleCheckedItem({"id":id}).then(res=>{
                this.editCheckList = res.entity
            })


        },
        sureEdit(){
            console.log(this.editId,this.editCheckList,302)
            let treeId=''
            if(this.editCheckList.length>0){
               treeId='_'+this.editCheckList.join('_')
            }
            let params={
                'id': this.editId,
                'roleName': this.editRoleForm.roleName,
                'remark':this.editRoleForm.remark,
                'status':this.editRoleForm.status,
                'treeId': treeId
            }

            loading.show()
       
            updateRole(params).then(res=>{
                loading.close();
                this.$message({
					type: 'success',
					message:'编辑成功'
                });
                this.getSystemRoleList()
                this.editDialogVisible = false
            })


        },
        resetEdit(){
            let id=this.editId
            this.handleEdit(id)
        },
        roleEnable(id,status){
            let params ={
                'id':id,
                'status':status
            }
            if(status=='1'){
                params.status = '0'
                updateRoleStatus(params).then(res=>{
                        this.$message({
								type: 'success',
								message:'修改成功'
                            });
                         this.getSystemRoleList()
                    })        
            }else{
                this.$confirm("您确定要停用该角色吗?", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    params.status = '1'
                    updateRoleStatus(params).then(res=>{
                        this.$message({
								type: 'success',
								message:'修改成功'
                            });
                         this.getSystemRoleList()
                    })
                    
                })
            }
        },
    }

}
</script>

<style>
.system-management-role .el-dialog .el-form-item__content{
    margin-left:100px;
}
</style>