import request from './../request.js'

/** -----------推送配置接口 ----------------*/
// 推送配置列表渲染以及查询 /dev-api/iot/pushAddr/list
export function pushAddrList(query) {
  return request({
    url: '/iot/pushAddr/list.do?',
    method: 'get',
    params: query
  })
}
//新增和删除推送地址配置  ​/dev-api​/iot​/pushAddr
export function pushAddr(data) {
    return request({
      url: '/iot/pushAddr.do?',
      method: 'post',
      data: data
    })
  }

// 删除/dev-api/iot/device/{ids}
export function delPushAddr(ids) {
  return request({
    url: '/iot/pushAddr/'+ids+'.do?',
    method: 'DELETE',
    data:ids,
    })
}

// 导出  /dev-api/iot/device/importTemplate    
export function pushAddrExport(data){
  return request({
    url: '/iot/pushAddr/export.do?',
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}

/** -----------推送记录接口 ----------------*/
// 推送记录列表渲染以及查询 /dev-api/iot/pushRecord/list
export function pushRecordList(query) {
  return request({
    url: '/iot/pushRecord/list.do?',
    method: 'get',
    params: query
  })
}
//推送记录详情  /dev-api/iot/pushRecord/{id}
export function pushRecordDetails(id) {
    return request({
      url: '/iot/pushRecord/'+id+'.do?',
      method: 'get',
      params: id
    })
  }

// 删除/dev-api/iot/pushRecord/{ids}
export function delPushRecord(ids) {
  return request({
    url: '/iot/pushRecord/'+ids+'.do?',
    method: 'DELETE',
    data:ids,
    })
}
/** -----------会话流量接口 ----------------*/

// 会话流量列表渲染以及查询 /dev-api/iot/sessionFlow/list
export function sessionFlowList(query) {
  return request({
    url: '/iot/sessionFlow/list.do?',
    method: 'get',
    params: query
  })
}

// 删除/dev-api/iot/sessionFlow/{ids}
export function delSessionFlow(ids) {
  return request({
    url: '/iot/sessionFlow/'+ids+'.do?',
    method: 'DELETE',
    data:ids,
    })
}

// 导出  /dev-api/iot/sessionFlow/export   
export function sessionFlowExport(data){
  return request({
    url: '/iot/sessionFlow/export.do?',
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}

/** -----------天流量接口 ----------------*/

// 天流量列表渲染以及查询 /dev-api/iot/dayFlow/list
export function dayFlowList(query) {
  return request({
    url: '/iot/dayFlow/list.do?',
    method: 'get',
    params: query
  })
}

// 删除/dev-api/iot/dayFlow/{ids}
export function delDayFlow(ids) {
  return request({
    url: '/iot/dayFlow/'+ids+'.do?',
    method: 'DELETE',
    data:ids,
    })
}

// 导出  /dev-api/iot/dayFlow/export
export function dayFlowExport(data){
  return request({
    url: '/iot/dayFlow/export.do?',
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}

/** -----------月流量接口 ----------------*/

// 月流量列表渲染以及查询 /dev-api/iot/monthFlow/list
export function monthFlowList(query) {
  return request({
    url: '/iot/monthFlow/list.do?',
    method: 'get',
    params: query
  })
}

// 删除//dev-api/iot/monthFlow/{ids}
export function delMonthFlow(ids) {
  return request({
    url: '/iot/monthFlow/'+ids+'.do?',
    method: 'DELETE',
    data:ids,
    })
}

// 导出  /dev-api/iot/monthFlow/export
export function monthFlowExport(data){
  return request({
    url: '/iot/monthFlow/export.do?',
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}