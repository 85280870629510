<template>

<div class="setMeal table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">套餐管理</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="套餐类型:">
                <el-select v-model="form.type">
                    <!-- <el-option label="全部" value=""></el-option>
                    <el-option label="叠加包" value="1"></el-option>
                    <el-option label="语音叠加包" value="101"></el-option>
                    <el-option label="月卡套餐" value="2"></el-option>
                    <el-option label="天卡套餐" value="7"></el-option>
                    <el-option label="自然月套餐" value="9"></el-option>
                    <el-option label="自然月套餐（本月/次月生效）" value="10"></el-option>
                    <el-option label="联通自然月(自动顺延)" value="14"></el-option>
                    <el-option label="电信自然月(自动顺延)" value="15"></el-option>
                    <el-option label="复合套餐(联通周期)" value="20"></el-option>
                    <el-option label="复合套餐" value="21"></el-option>
                    <el-option label="自然月累计套餐" value="23"></el-option> -->
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="item in $store.getters.packageType" :key="item.dictKey" :label="item.groupName" :value="item.dictValue">
					</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="类别:">
                <el-select v-model="form.category" @change="getSecondLists">
                    <el-option label="全部" value=""></el-option>
                     <el-option v-for="(item,index) in $store.getters.categorys" :label="item.name" :value="item.value" :key="index"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="子类别:" v-if="currentRole=='4'&&agentTree=='1'||currentRole=='0'">
                <el-select v-model="form.secondCategory" @visible-change="secondClick" placeholder="请选择">
                 	<el-option v-for="item in accountLists" :key="item.index" :label="item.label" :value="item.value">
					</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态:">
                <el-select v-model="form.status" >
                    <el-option label="全部" value=""></el-option>
                    <el-option label="上架" value="1"></el-option>
                    <el-option label="下架" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="套餐名称:">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="一级合并编号:">
                <el-input v-model="form.codeChild"></el-input>
            </el-form-item>
            <el-form-item label="二级合并编号:">
                <el-input v-model="form.secondCodeChild"></el-input>
            </el-form-item>
             <el-form-item label="标签:">
                <el-input v-model="form.label"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addRecord">新增套餐</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-black" class="themed-button" @click="exportExcel">导出excel</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="套餐编码" prop="id" width="300px"></el-table-column>
            <el-table-column label="套餐名称" prop="name" width="180px" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐流量" prop="flowSize">
                <template slot-scope="scope">
                    <span>{{(scope.row.flowSize/1024).toFixed(2)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="套餐语音量" prop="voiceSize" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐类型" prop="typeString" width="180px" show-overflow-tooltip></el-table-column>
            <el-table-column label="二级套餐类型" prop="secondTypeString" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐对账价格" prop="accountPrice"></el-table-column>
            <el-table-column label="套餐金额" prop="money"></el-table-column>
            <el-table-column label="返利分期数" prop="rebateTerm"></el-table-column>
             <el-table-column label="无佣金标识" prop="noProfitFlag" >
                <template slot-scope="scope">
                   <span v-if="scope.row.noProfitFlag==1" style="color:#f00">无佣金</span>
                   <span v-else-if="scope.row.noProfitFlag==0" style="color:#f00"></span>
                   <span v-else style="color:#f00"></span>
                </template>
            </el-table-column>
             <el-table-column label="首充套餐" prop="firstRecharge" show-overflow-tooltip>
                <template slot-scope="scope">
                   <span v-if="scope.row.firstRecharge">是</span>
                   <span v-else>否</span>
                </template>
            </el-table-column>
            <el-table-column label="排序" prop="mealSort" width="90px">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.mealSort" @change="((val)=>{updateSort(val,scope.row.id,false)})"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="前端排序" prop="frontOrder" width="90px" v-if="false">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.frontOrder" @change="((val)=>{updateSort(val,scope.row.id,false)})"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="热卖标识" prop="hotFlagString"></el-table-column>
            <el-table-column label="所属类别" prop="category">
                <template slot-scope="scope">
                    <span>{{categoryString(scope.row.category)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="子类别" prop="secondCategoryString" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" prop="statusString">
            </el-table-column>
            <el-table-column label="一级是否合并" prop="isChildString" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="一级合并编号" prop="codeChild" show-overflow-tooltip></el-table-column>
            <el-table-column label="二级是否合并" prop="secondIsChildString" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="二级合并编号" prop="secondCodeChild" show-overflow-tooltip></el-table-column>
            <el-table-column label="第三方套餐ID" prop="thirdMealId" show-overflow-tooltip></el-table-column>
            <el-table-column label="是否特殊" prop="isSpecialString">
            </el-table-column>
            <el-table-column label="创建人" prop="createUserName" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.createUserName">{{scope.row.createUserName}}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column label="修改人" prop="modifyUserName" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.modifyUserName">{{scope.row.modifyUserName}}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column label="添加时间" prop="modifyDate" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.modifyDate">{{scope.row.modifyDate}}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
             <el-table-column label="标签" prop="label">
            </el-table-column>
            <el-table-column label="操作" prop="createUserName" width="300px">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button"  @click="updateRecord(scope.row.id)">编辑</el-button>
                    <el-button type="info" class="themed-button"  @click="copyRecord(scope.row.id)">复制</el-button>
                    <el-button type="info" class="themed-button"  v-if="scope.row.status === 1" @click="updateStatus(scope.row.id,2)">下架</el-button>
                    <el-button type="info" class="themed-button"  v-if="scope.row.status === 2" @click="updateStatus(scope.row.id,1)">上架</el-button>
                    <el-button type="primary" class="themed-button"  @click="limitRecord(scope.row.id,scope.row.category)">限速设置</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
    <el-dialog  :title="dialogTitle" :visible.sync="dialogBox" v-if="dialogBox">
        <form-add @close="closeBox" v-if="dialogContent=='add'"></form-add>
        <form-update :id="id" @close="closeBox" v-if="dialogContent=='update'"></form-update>
    </el-dialog>
    <el-dialog title="套餐限速" :visible.sync="dialogVisible" v-if="dialogVisible">
        <el-form label-position="right" label-width="200px" :model="formLimit" ref="formLimit" :rules="rules">
            <el-form-item label="已使用量/GB:" required prop="userFlow">
                <el-input v-model="formLimit.userFlow"></el-input>
            </el-form-item>
            <el-form-item label="请选择限速:" required prop="speedList">
                <el-select v-model="formLimit.speedList" placeholder="请选择网速">
                    <el-option v-for="(item,index) in speeddataList" :label="item.speedValue" :value="item.speedKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="已使用量/GB:" required prop="userFlow1">
                <el-input v-model="formLimit.userFlow1"></el-input>
            </el-form-item>
            <el-form-item label="请选择限速:" required prop="speedList1">
                <el-select v-model="formLimit.speedList1" placeholder="请选择网速">
                    <el-option v-for="(item,index) in speeddataList" :label="item.speedValue" :value="item.speedKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="已使用量/GB:" required prop="userFlow2">
                <el-input v-model="formLimit.userFlow2"></el-input>
            </el-form-item>
            <el-form-item label="请选择限速:" required prop="speedList2">
                <el-select v-model="formLimit.speedList2" placeholder="请选择网速">
                    <el-option v-for="(item,index) in speeddataList" :label="item.speedValue" :value="item.speedKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm('formLimit')">确 定</el-button>
            <el-button type="primary" @click="reset('formLimit')">重 置</el-button>
            <el-button @click="cancle('formLimit')">取 消</el-button>
        </span>
    </el-dialog>
    <el-dialog title="套餐限速" :visible.sync="dialogVisible5" v-if="dialogVisible5">
        <el-form label-position="right" label-width="200px" :model="formLimit5" ref="formLimit5" :rules="rules">
            <el-form-item label="已使用量/GB:" required prop="userFlow">
                <el-input v-model="formLimit5.userFlow"></el-input>
            </el-form-item>
            <el-form-item label="上行限速:" required prop="upspeedList">
                <el-select v-model="formLimit5.upspeedList" placeholder="请选择网速">
                    <el-option v-for="(item,index) in upspeeddataList" :label="item.speedValue" :value="item.speedKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="下行限速:" required prop="downspeedList">
                <el-select v-model="formLimit5.downspeedList" placeholder="请选择网速">
                    <el-option v-for="(item,index) in downspeeddataList" :label="item.speedValue" :value="item.speedKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="已使用量/GB:" required prop="userFlow1">
                <el-input v-model="formLimit5.userFlow1"></el-input>
            </el-form-item>
            <el-form-item label="上行限速:" required prop="upspeedList1">
                <el-select v-model="formLimit5.upspeedList1" placeholder="请选择网速">
                    <el-option v-for="(item,index) in upspeeddataList" :label="item.speedValue" :value="item.speedKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="下行限速:" required prop="downspeedList1">
                <el-select v-model="formLimit5.downspeedList1" placeholder="请选择网速">
                    <el-option v-for="(item,index) in downspeeddataList" :label="item.speedValue" :value="item.speedKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="已使用量/GB:" required prop="userFlow2">
                <el-input v-model="formLimit5.userFlow2"></el-input>
            </el-form-item>
            <el-form-item label="上行限速:" required prop="upspeedList2">
                <el-select v-model="formLimit5.upspeedList2" placeholder="请选择网速">
                    <el-option v-for="(item,index) in upspeeddataList" :label="item.speedValue" :value="item.speedKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="下行限速:" required prop="downspeedList2"> 
                <el-select v-model="formLimit5.downspeedList2" placeholder="请选择网速">
                    <el-option v-for="(item,index) in downspeeddataList" :label="item.speedValue" :value="item.speedKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm('formLimit5')">确 定</el-button>
            <el-button type="primary" @click="reset('formLimit5')">重 置</el-button>
            <el-button @click="cancle('formLimit5')">取 消</el-button>
        </span>
    </el-dialog>
    <el-dialog title="套餐限速" :visible.sync="dialogVisible4" v-if="dialogVisible4">
        <el-form label-position="right" label-width="200px" :model="formLimit4"  ref="formLimit4" :rules="rules">
            <el-form-item label="限速:" required prop="speedList">
                <el-select v-model="formLimit4.speedList" placeholder="请选择网速">
                    <el-option v-for="(item,index) in speeddataList4" :label="item.id" :value="item.customer" :key="index"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm('formLimit4')">确 定</el-button>
            <el-button type="primary" @click="reset('formLimit4')">重 置</el-button>
            <el-button @click="cancle('formLimit4')">取 消</el-button>
        </span>
    </el-dialog>
    <el-dialog title="套餐限速" :visible.sync="dialogVisible8" v-if="dialogVisible8">
        <el-form label-position="right" label-width="200px" :model="formLimit8" ref="formLimit8" :rules="rules">
            <el-form-item label="已使用量/GB:" required prop="userFlow">
                <el-input v-model="formLimit8.userFlow"></el-input>
            </el-form-item>
            <el-form-item label="限速:" required  prop="upspeedList">
                <el-select v-model="formLimit8.upspeedList" placeholder="请选择网速">
                    <el-option v-for="(item,index) in speeddataList8" :label="item.label" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="已使用量/GB:" required  prop="userFlow1">
                <el-input v-model="formLimit8.userFlow1"></el-input>
            </el-form-item>
            <el-form-item label="限速:" required  prop="upspeedList1">
                <el-select v-model="formLimit8.upspeedList1" placeholder="请选择网速">
                    <el-option v-for="(item,index) in speeddataList8" :label="item.label" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="已使用量/GB:" required  prop="userFlow2">
                <el-input v-model="formLimit8.userFlow2"></el-input>
            </el-form-item>
            <el-form-item label="限速:" required  prop="upspeedList2">
                <el-select v-model="formLimit8.upspeedList2" placeholder="请选择网速">
                    <el-option v-for="(item,index) in speeddataList8" :label="item.label" :value="item.value" :key="index"></el-option>
                </el-select>
            </el-form-item>
            
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm('formLimit8')">确 定</el-button>
            <el-button type="primary" @click="reset('formLimit8')">重 置</el-button>
            <el-button @click="cancle('formLimit8')">取 消</el-button>
        </span>
    </el-dialog>
</div>

</template>

<script type="text/ecmascript-6">
import {mapGetters} from 'vuex'
import {getAccountList,} from '@/api/cardManagement/cardBill.js'
import {setmealList,importExportRecord,getMealList,findById,update,dcpdowndata,dcpupdata,speeddata,speeddata4,speeddata_,dcpSpeedSave,newLtSpeedSave,speedUnicom,telecomSpeedSave,getFirstName,getSpeedModel,getByCategory,copyRecord} from '@/api/setMealManagment/setMeal.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import store from '@/store'
import FormAdd from '@/views/setMealManagment/setMeal/formAdd'
import FormUpdate from '@/views/setMealManagment/setMeal/formUpdate'

import resize from '@/mixins/resize.js'
import formUpdate from './setMeal/formUpdate.vue'

export default {
    name:'setMeal',
    components: { formUpdate },
    mixins:[resize],
    data(){
        var chackUserFlow=(rule, value, callback)=>{
            var rex = /^[0-9]+$/;
            if(!rex.test(value)){
                callback(new Error('请填写正确使用流量'));
            }else{
                callback()
            }
        };
        var validateUserFlow = (rule, value, callback) => {
            if(value>this.formLimit.userFlow1 || value>this.formLimit.userFlow2 || this.formLimit.userFlow1 > this.formLimit.userFlow2){
                callback(new Error('使用流量必须从小到大'));
            }else{
                callback()
            }
        };
        var validateUserFlow5 = (rule, value, callback) => {
            if(value>this.formLimit.userFlow1 || value>this.formLimit.userFlow2 || this.formLimit5.userFlow1 > this.formLimit5.userFlow2){
                callback(new Error('使用流量必须从小到大'));
            }else{
                callback()
            }
        };
        var validateUserFlow8 = (rule, value, callback) => {
            if(value>this.formLimit8.userFlow1 || value>this.formLimit8.userFlow2 || this.formLimit8.userFlow1 > this.formLimit8.userFlow2){
                callback(new Error('使用流量必须从小到大'));
            }else{
                callback()
            }
        };
 
        return {
            id:'',
            form:{
                type:'',
                category:'',
                secondCategory:"",
                status:'',
                name:'',
                codeChild:'',
                secondCodeChild:'',
                label:"",
            },
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            dialogTitle:'',
            dialogContent:'',
            options:{},
            dialogVisible:false,
            formLimit:{
                userFlow:"",
                speedList:'',
                userFlow1:"",
                speedList1:'',
                userFlow2:"",
                speedList2:'',
            },
            speeddataList:[],
            rules:{
                userFlow:[
                    {required:true,message:'请填写已使用流量',trigger:'blur'},
                    { validator: chackUserFlow, trigger: 'blur' }
                ],
                speedList:[
                    {required:true,message:'请选择限速',trigger:'change'}
                ],
                userFlow1:[
                    {required:true,message:'请填写已使用流量',trigger:'blur'},
                    { validator: chackUserFlow, trigger: 'blur' }
                ],
                speedList1:[
                    {required:true,message:'请选择限速',trigger:'change'}
                ],
                userFlow2:[
                    {required:true,message:'请填写已使用流量',trigger:'blur'},
                    { validator: chackUserFlow, trigger: 'blur' }
                ],
                speedList2:[
                    {required:true,message:'请选择限速',trigger:'change'}
                ],
                upspeedList:[
                    {required:true,message:'请选择上行限速',trigger:'change'}
                ],
                downspeedList:[
                    {required:true,message:'请选择下行限速',trigger:'change'}
                ],
                upspeedList1:[
                    {required:true,message:'请选择上行限速',trigger:'change'}
                ],
                downspeedList1:[
                    {required:true,message:'请选择下行限速',trigger:'change'}
                ],
                upspeedList2:[
                    {required:true,message:'请选择上行限速',trigger:'change'}
                ],
                downspeedList2:[
                    {required:true,message:'请选择下行限速',trigger:'change'}
                ]
            },

            dialogVisible5:false,
            upspeeddataList:[],
            downspeeddataList:[],
            formLimit5:{
               userFlow:'',
               upspeedList:'' ,
               downspeedList:'',
               userFlow1:'',
               upspeedList1:'' ,
               downspeedList1:'',
               userFlow2:'',
               upspeedList2:'' ,
               downspeedList2:'',
            },

            dialogVisible4:false,
            speeddataList4:[],
            formLimit4:{
                speedList:''
            },

            dialogVisible8:false,
            speeddataList8:[],
            formLimit8:{
                userFlow:'',
                upspeedList:'',
                userFlow1:'',
                upspeedList1:'',
                userFlow2:'',
                upspeedList2:'',
            },
            category:'',
            accountLists:[{label:'全部',value:''}],
        } 
    },
    mounted(){
        if(store.getters.packageType.length<1){
           store.dispatch('getPackageType')
        }
         
          if(store.getters.categorys.length<1){
           store.dispatch('getCategory').then(()=>{
               if(store.getters.categorys.length>0){
            store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.options[name] =value
            })
               }
  
           })
        }else{
            store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.options[name] =value
            })
        }
        console.log(this.options)
       
        this._setmealList()
        // this._getAccountList()
         try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
    },
     computed:{
        	...mapGetters(
            	['currentRole','agentTree']
        	),
    	},
    methods:{
        secondClick(val){
          if(this.accountLists.length==1&&val){
                  this.$alert('请先选择类别', '提示', {
								confirmButtonText: '确定',
							});
                            return
          }
          
        },
        getSecondLists(val){
                      this.form.secondCategory = ""
                      this.accountLists = [{label:'全部',value:''}]
                   getAccountList({category:val}).then((res) => {
                    if(res.length>0){
                        res.forEach((item)=>{
                            this.accountLists.push({label:item.accountName,value:item.secondCategory})
                        })
                    }
                })
        },
        // changeSecondList(val){
        //   if(this.accountLists.length==1){
        //    this.$alert('请选择类别', '提示', {
		// 						confirmButtonText: '确定',
		// 					});
        //   }
        // },
       
        closeBox(val){
            this.dialogBox = false
            if(val){
                this._setmealList()
            }
        },
        updateSort(val,id,isFrontOrder){
            if(!val || val==="") {
                return;
            }
            if(isNaN(val)) {
                return;
            }
            let postData = {
                    id, 
                    mealSort: '', 
                    isChild: '', 
                    secondIsChild: '', 
                    updateFrontOrder:'false',
                    updateSort:'false'
                };
            if (isFrontOrder) {
                postData.updateFrontOrder = 'true'
                postData.frontOrder = val
            } else {
                postData.updateSort = 'true'
                postData.mealSort = val
            }
            update(postData).then((res)=>{
                this._setmealList()
            }).catch((res)=>{
                messageBox(this,'请求错误','操作繁忙请稍后重试或联系客服。')
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!');
                    if(this.category == this.options['中国联通CMP']){//5
                        dcpSpeedSave(this.formLimit5).then((res)=>{
                            this.dialogVisible5 = false
                            this._setmealList()
                        })
                    // }else if(this.category == 8){
                    //     newLtSpeedSave(this.formLimit8).then((res)=>{
                    //         this.dialogVisible8 = false
                    //         this._setmealList()
                    //     })
                    }else if(this.category == this.options['中国联通']){//4
                        speedUnicom(this.formLimit4).then((res)=>{
                            this.dialogVisible4 = false
                            this._setmealList()
                        })
                    }else{
                        telecomSpeedSave(this.formLimit).then((res)=>{
                            this.dialogVisible = false
                            this._setmealList()
                        })
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        reset(formName) {
            this.$refs[formName].resetFields();
        },
        cancle(formName){
            this.$refs[formName].resetFields();
            if(this.category == this.options['中国联通CMP']){//5
                this.dialogVisible5 = false
            // }else if(category == 8){
            //     this.dialogVisible8 = false
            }else if(this.category == this.options['中国联通']){//4
                this.dialogVisible4 = false
            }else{
                this.dialogVisible = false
            }
        },
        limitRecord(id,category){
            this.category = category
            console.log(category,this.options,this.options['中国联通CMP'])
            // if(category === 5){//联通CMP
            if(category === this.options['中国联通CMP']){
                this.dialogVisible5 = true
                dcpdowndata().then((res)=>{
                    this.downspeeddataList = res.rows
                })
                dcpupdata().then((res)=>{
                    this.upspeeddataList = res.rows
                })
    
            // }else if(category === 8){
            //     this.dialogVisible8 = true
            //     speeddata().then((res)=>{
            //         this.speeddataList8 = res.rows.map((item)=>{
            //             let name = ''
            //             if(item.upVal == '默认'){
            //                 name = '默认'
            //             }else{
            //                 name = '上行'+item.upValue+',下行'+item.downValue
            //             }
            //             return {
            //                 label:name,
            //                 value:item.id
            //             }
            //         })
            //     })

            // }else if(category == 4){
               }else if(category == this.options['中国联通']){  
                this.dialogVisible4 = true
                speeddata4().then((res)=>{
                    this.speeddataList4 = res.rows
                })
            
            }else{
                this.dialogVisible = true
                speeddata_().then((res)=>{
                    this.formLimit.speeddataList = res.rows
                })
            }

            this.getspeedList(id)
        },
        getspeedList(id){
            findById({id}).then((res)=>{
                let entity = res.entity
                // if(this.category == 5){
                     if(this.category == this.options['中国联通CMP']){
                    this.formLimit5.upspeedList = entity.upKey
                    this.formLimit5.upspeedList1 = entity.upKey1
                    this.formLimit5.upspeedList2 = entity.upKey2
                    this.formLimit5.downspeedList = entity.downKey
                    this.formLimit5.downspeedList1 = entity.downKey1
                    this.formLimit5.downspeedList2 = entity.downKey2
                // }else if(this.category == 8){
                //     if(entity.upVal && entity.downVal){
                //         if(entity.upVal == '默认'){
                //             this.formLimit8.upspeedList = '默认'
                //         }else{
                //             this.formLimit8.upspeedList = '上行'+entity.upVal+',下行'+entity.downVal
                //         }
                //     }
                //     if(entity.upVal1 && entity.downVal1){
                //         if(entity.upVal1 == '默认'){
                //             this.formLimit8.upspeedList1 = '默认'
                //         }else{
                //             this.formLimit8.upspeedList1 = '上行'+entity.upVal1+',下行'+entity.downVal1
                //         }
                //     }
                //     if(entity.upVal2 && entity.downVal2){
                //         if(entity.upVal2 == '默认'){
                //             this.formLimit8.upspeedList2 = '默认'
                //         }else{
                //             this.formLimit8.upspeedList2 = '上行'+entity.upVal2+',下行'+entity.downVal2
                //         }
                //     }
                // }else if(this.category == 4){
                    }else if(this.category == this.options['中国联通CMP']){
                    this.formLimit4.speedList = entity.upKey
                }else{
                    this.formLimit.speedList = entity.speedKey
                    this.formLimit.speedList1 = entity.speedKey1
                    this.formLimit.speedList2 = entity.speedKey2
                }
            })
        },
        updateStatus(id,status){
          update({id,status,shangxiajia:true}).then((res)=>{
              this._setmealList()
          })
        },    
        searchTable(){
            this._setmealList()
        },
        addRecord(){
            this.dialogTitle = '新增套餐'
            this.dialogContent = 'add'
            this.dialogBox = true
        },
        updateRecord(id){
            this.dialogTitle = '修改套餐'
            this.dialogContent = 'update'
            this.dialogBox = true
            this.id = id
        },
        copyRecord(id){
          copyRecord({id}).then(res=>{
                 if (res.system_result_key == '0') {
                    this.$message({
							showClose: true,
							message: '复制成功',
							type: 'success',
						})
                        this._setmealList()
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
          })
        },
        exportExcel(){
            importExportRecord(this.form).then((res)=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        categoryString(category){
            // if (category == 1) return '电信';
            //  else if (category == 2) return '其他卡务';
            //   else if (category == 3) return '移动'; 
            //   else if (category == 6) return '联通Q';
            //    else if (category == 5) return '联通CMP'; 
            //    else if (category == 7) return '新移动'; 
            //    else if (category == 8) return '新联通'; 
            //    else if (category == 4) return '联通';
            // console.log(store.getters.categorys)
            let cuCatagorys
            if(store.getters.categorys.length>0){
               store.getters.categorys.forEach(item=>{
                   if(category==item.value){
                     cuCatagorys = item.name
                   }
               })
            }
            return cuCatagorys
        },
        handleSizeChange(val){
            this.pageSize = val
            this._setmealList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._setmealList()
        },   
        _setmealList(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            setmealList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        } 
    },
    components:{
        FormAdd,
        FormUpdate
    }
}

</script>

<style>
.marginR15{margin-right: 15px;}
</style>
