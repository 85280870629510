/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function amountList(params){
    return request({
        url: 'rechargeAmount/list.do?',
        method: 'post',
        data:params
    })
}
export function edit(params){
    return request({
        url: 'rechargeAmount/edit.do?',
        method: 'post',
        data:params
    })
}
export function add(params){
    return request({
        url: 'rechargeAmount/add.do?',
        method: 'post',
        data:params
    })
}
export function del(params){
    return request({
        url: 'rechargeAmount/del.do?',
        method: 'post',
        data:params
    })
}
export function agentList(params){
    return request({
        url: 'rechargeAmount/agentList.do?',
        method: 'post',
        data:params
    })
}

export function delAgentAmount(params){
    return request({
        url: 'rechargeAmount/delAgentAmount.do?',
        method: 'post',
        data:params
    })
}

export function addSetAmonut(params){
    return request({
        url: '/rechargeAmount/asignAgent.do?',
        method: 'post',
        data:params
    })
}


