<template>
	<div class="main-template">
		<div class="fadeInRight">
			<div class="title-box clearfix">
				<span class="m-title">短信模板</span>
				<el-form inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
					<el-form-item>
						<el-button type="primary" class="themed-button" @click="openTemplateDialog()">新增模板</el-button>
					</el-form-item>
					
				</el-form>
			</div>
			<div class="content-box">
				<el-table ref='table'  :data="tableData" stripe style="width: 100%;" :max-height="maxTableHeight">
					<el-table-column prop="name" label="模板名称">
					</el-table-column>
					<el-table-column prop="content" label="模板内容">
					</el-table-column>
					<el-table-column prop="priority" label="优先级">
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间">
					</el-table-column>
					<el-table-column prop="remarks" label="备注">
					</el-table-column>
					<el-table-column label="操作" width="160px">
						<template slot-scope="scope">
							<el-button class="themed-button" type="info" @click="openTemplateDialog(scope.row)">修改</el-button>
							<el-button class="themed-button" type="danger" @click="deleteTemplate(scope.row.id)">删除</el-button>
						</template>
					</el-table-column>
				
				</el-table>
				<el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
				 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
			</div>
		</div>
		
		<el-dialog title="新增模板" :visible.sync="dialogVisible" width="500px">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item label="模板名称" prop="name">
					<el-input show-word-limit maxlength="32" v-model="ruleForm.name" placeholder="模板名称，最多32字"></el-input>
				</el-form-item>
				<el-form-item label="模板内容" prop="content">
					<el-input rows='3' show-word-limit maxlength="160" type='textarea' v-model="ruleForm.content" placeholder="模板内容，最多160字" @input="contentChanged"></el-input>
				</el-form-item>
				<el-form-item label="优先级" prop="priority">
					<el-input  type="number" v-model="ruleForm.priority" placeholder="优先级1-100,数字越大优先级越高,排序越靠前" @input="priorityChaged"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remarks">
					<el-input show-word-limit maxlength="32" v-model="ruleForm.remarks" placeholder="备注，选填，最多32字"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="dialogVisible = false">取 消</el-button>
				<el-button class="themed-button" type="primary" @click="saveTemplate">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {templateList,saveTemplate,deleteTemplate} from '@/api/sms.js'
	import resize from '@/mixins/resize.js'
	import Utils from '@/utils/utils.js'
	import Loading from '@/utils/Loading.js'
	export default{
		name:'sms_template_list',
		mixins:[resize],
		data(){
			return {
				page:1,
				pageSize:15,
				total:0,
				tableData:[],
				dialogVisible:false,
				ruleForm:{
					name:"",
					content:'',
					priority:'',
					remarks:''
				},
				rules:{
					name: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					},],
					content: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					},],
					priority: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					},],
					
				}
			}
		},
		created() {
			this.getTemplateList()
		},
		methods:{
			contentChanged(val){
				// this.ruleForm.content = val.replace(/[\W]/g,'')
				this.ruleForm.content = val.replace(/[\u4E00-\u9FA5]/g,'')
			},
			priorityChaged(val){
				if (val>100) {
					this.ruleForm.priority = 100
				}
				if (val < 1) {
					this.ruleForm.priority = 1
				}
			},
			openTemplateDialog(rowData){
				if (!!rowData) {
					//不能直接把rowdata 复制给ruleForm
					this.ruleForm.id = rowData.id
					this.ruleForm.name = rowData.name
					this.ruleForm.content = rowData.content
					this.ruleForm.priority = rowData.priority
					this.ruleForm.remarks = rowData.remarks
				}else{
					this.ruleForm = {}
				}
				this.dialogVisible = true
			},
			saveTemplate(){
				this.$refs["ruleForm"].validate((validate) => {
					if (validate) {
						Loading.show()
						saveTemplate(this.ruleForm).then(res=>{
							if (!!this.ruleForm.id) {
								this.$message({
									type: 'success',
									message:'修改模板成功'
								});
							}else{
								this.$message({
									type: 'success',
									message:'新增模板成功'
								});
							}
							
							this.dialogVisible = false
							this.getTemplateList()
						})
					}
				})
				
			},
			deleteTemplate(id){
				this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteTemplate({'id':id}).then(res=>{
						this.$message({
							type: 'success',
							message:'删除成功'
						});
						this.getTemplateList()
					})
				})
				
			},
			getTemplateList(){
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
				}
				templateList(params).then(res=>{
					this.tableData = res.rows.map(item=>{
						item.createTime = Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(item.createTime))
						return item
					})
					this.total = res.count
				})
			},
			onPageChange(page){
				this.page = page
				this.getTemplateList()
			},
			handleSizeChange(size){
				this.pageSize = size
				this.getTemplateList()
			}
		}
	}
</script>

<style>
</style>
