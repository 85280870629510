<template>
    <div class="order-list fadeInRight">
        <div class="title-box"><span class="m-title">订单列表*</span>
            <el-form inline ref="orderListForm" v-model="orderListFormData" class="right themed-form search-form" style="line-height: 40px;">
                <el-form-item>
					<el-date-picker style="width: 140px;" placeholder="下单起始日期" v-model="orderListFormData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 140px;" placeholder="下单结束日期" v-model="orderListFormData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
                    <el-input v-model="orderListFormData.agentThreeName" placeholder="所属代理商"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="orderListFormData.wechatAgentName" placeholder="下单代理商"></el-input>
                </el-form-item>
                <el-form-item label="充值类型">
                    <search-select :optionsMetaAll="rechargeOptions" v-model="orderListFormData.isFirstRecharge"></search-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="orderListFormData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="orderListFormData.mealName" placeholder="套餐"></el-input>
                </el-form-item>
                <el-form-item label="开卡公司">
                    <search-select :optionsMetaAll="cardCompanyOperators" v-model="orderListFormData.accountId"></search-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="orderListFormData.iccidMark" placeholder="卡号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item label="卡来源">
                    <search-select :optionsMetaAll="cardFromOperators" v-model="orderListFormData.type"></search-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="orderListFormData.orderNumber" placeholder="订单号"></el-input>
                </el-form-item>
                <el-form-item label="支付状态">
                    <search-select :optionsMetaAll="payStatusOptions" v-model="orderListFormData.payStatus"></search-select>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>
        <div class="content-box">
             <el-table
                    ref='table'
                    stripe
                    :max-height="maxTableHeight" 
                    :data="tableData"
                    border
                    style="width: 100%"
                    tooltip-effect="dark"
                    >
                <el-table-column
                    prop="orderNum"
                    label="订单号"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="agentThreeName"
                    label="所属代理商"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="agentName"
                    label="所属总代理商"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="phone"
                    label="接入号"
                    min-width="140">
                </el-table-column>
                <el-table-column
                    label="卡号"
                    min-width="180">
                    <template slot-scope="scope">
                        <el-link :underline='false' type="primary" @click="toKawu(scope.row.id,scope.row.iccidMark)">{{scope.row.iccidMark}}</el-link>
                    </template>
                </el-table-column>
                
                
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="mealName"
                    label="套餐名称"
                    min-width="170">
                </el-table-column>

                <el-table-column
                    label="次月生效"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.effectType==1">本月生效</span>
                        <span v-else-if="scope.row.effectType==2">次月生效</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="mealTypeStr"
                    label="套餐类型"
                    min-width="150">
                </el-table-column>
                <!-- <el-table-column
                    label="充值状态"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.rechargeStatus==1">待充值</span>
                        <span v-else-if="scope.row.rechargeStatus==2">已充值</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="推送状态"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.pushStatus==1">待推送</span>
                        <span v-else-if="scope.row.pushStatus==2">已推送</span>
                    </template>
                </el-table-column> -->
                <el-table-column
                    prop="mealSecondTypeStr"
                    label="二级套餐类型"
                    min-width="112">
                </el-table-column>
                <el-table-column
                    label="业务类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.toType=='C'">ToC</span>
                        <span v-else-if="scope.row.toType=='B'">ToB</span>
                        <span v-else-if="scope.row.toType=='API'">API</span> -->
                        <span>{{_getToType(scope.row.toType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="销售类型"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.saleType==1">线上</span>
                        <span v-else-if="scope.row.saleType==2">线下</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="合作类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.coType==1">普通类型</span>
                        <span v-else-if="scope.row.coType==2">按量结算</span>
                        <span v-else-if="scope.row.coType==3">api充值</span> -->
                         <span>{{_getCoType(scope.row.coType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="产品类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.productType==1">大河卡</span>
                        <span v-else-if="scope.row.productType==2">手表卡</span>
                        <span v-else-if="scope.row.productType==3">大流量卡</span> -->
                        <span>{{_getProductType(scope.row.productType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderMoney"
                    label="下单金额"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="payMoney"
                    label="实际支付金额"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    label="支付状态"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payStatus==1">已支付</span>
                        <span v-else-if="scope.row.payStatus==2">已退款</span>
                        <span v-else-if="scope.row.payStatus==4">已退款</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderCount"
                    label="数量"
                    min-width="50">
                </el-table-column>
                <el-table-column
                    prop="orderAgent"
                    label="下单代理商"
                    min-width="140">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="accountName"
                    label="开卡公司"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="source"
                    label="来源"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="createTime"
                    label="下单时间"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="orderExpireDate"
                    label="套餐到期时间"
                    min-width="120">
                </el-table-column>

                <el-table-column
                    label="充值类型"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isFirstRecharge==1">首次充值</span>
                        <span v-else-if="scope.row.isFirstRecharge==2">续费充值</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>

                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="refundTime"
                    label="退款时间"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    prop='refundFee'
                    label="退款金额"
                    min-width="78">
                </el-table-column>

                <el-table-column
                    prop="netMoney"
                    label="最终支付金额"
                    min-width="120">
                </el-table-column>

                <el-table-column
                    prop="rebate2Money"
                    label="返现"
                    min-width="78">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="saleName"
                    label="销售名称"
                    min-width="120">
                </el-table-column>
                
                
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {getAllOrderList,exportAllOrder,cardCompanyList } from "@/api/order.js";
import resize from '@/mixins/resize.js'
import {mapMutations} from "vuex"
import dictGroup from '@/mixins/dictGroup.js'
export default {
    name:'orderList',
    components:{SearchSelect},
    mixins:[resize,dictGroup],
    data(){
        return {
            orderListFormData:{
                startTime:'',
                endTime:'',
                agentThreeName:'',
                wechatAgentName:'',
                isFirstRecharge:'',
                phone:'',
                mealName:'',
                accountId:'',
                iccidMark:'',
                type:'',
                orderNumber:'',
                payStatus:'',

            },
            rechargeOptions:[
                {label:"全部",value:''},
                {label:"首次充值",value:'1'},
                {label:"续费充值",value:'2'},
            ],
            cardCompanyOperators:[{label:'全部',value:''}],
            cardFromOperators:[
                {label:'全部',value:''},
                {label:'电信',value:'1'},
                {label:'其他卡务',value:'2'},
                {label:'移动',value:'3'},
                {label:'联通',value:'4'},
                {label:'DCP电信',value:'5'},
                {label:'联通Q',value:'6'},
                {label:'新移动',value:'7'},
            ],
            payStatusOptions:[
                {label:"全部",value:''},
                {label:"已支付",value:'1'},
                {label:"已退款",value:'2'},
            ],
            page:1,
            pageSize:15,
            total:0,
            tableData:[],
        }
    },
    created(){
        this.getcardCompanyOperators()
        this.getOrderList()
    },
    methods:{
         ...mapMutations(
                ["SET_ICCID_OR_PHONE","SET_STATUS","SET_TYPE"]
            ),
        getcardCompanyOperators(){
            cardCompanyList().then(res=>{
                let arrList =[{label:"全部",value:""}]
                 res.forEach(element => {
                     arrList.push({label:element.accountName,value:element.id})
                 })               
                 this.cardCompanyOperators=arrList
            })
        },
        getOrderList(){
            let params = {
                 pageSize:this.pageSize,
                 pageNo:this.page
             }
             Object.assign(this.orderListFormData,params)
             getAllOrderList(this.orderListFormData).then(res=>{
                 this.tableData=res.rows
                 this.total=res.count
             })   
        },
        exportExcel(){
            exportAllOrder(this.orderListFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        onPageChange(page){
				this.page = page
				this.getOrderList()
			},
		handleSizeChange(size){
				this.pageSize = size
				this.getOrderList()
            },
        onSearch(){
            this.page = 1
            this.getOrderList()
         },
         toKawu(id,cardId){
            this.SET_ICCID_OR_PHONE(cardId)
            this.SET_STATUS('0')
            this.SET_TYPE('0')
             this.$router.push({path:'../cardManagement/cardBillDetail',query:{'iccidOrPhone':cardId,'status':0,'type':0}})
        }
    }
}
</script>
<style>
    .order-list .el-form-item__label{
        vertical-align: top;
    }
</style>