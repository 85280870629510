<template>
    <div class="balanceChangeRecord fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">余额变更记录</span>
             <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
             <el-form-item label="来源:">
                <el-select v-model="form.type" >
                    <el-option v-for="(item,index) in originList" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
          <el-form-item label="iccid:">
          <el-input v-model="form.iccid" placeholder="输入ICCID" :disabled="isDisable"></el-input>
        </el-form-item>
        <el-form-item label="短号">
          <el-input v-model="form.shortIccid" placeholder="输入短号" ></el-input>
        </el-form-item>
         <el-form-item label="订单号:">
          <el-input v-model="form.orderNumber" placeholder="输入订单号"></el-input>
        </el-form-item>
        <el-form-item label="类型:"   >
				<el-select v-model="form.incType">
                     <el-option label="全部" value=""></el-option>
				   <el-option label="增加" value="1"></el-option>
				   <el-option label="扣减" value="2"></el-option>
				 </el-select>
			</el-form-item>
        <el-form-item label="时间:">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="form.startTime"
              style="width: 126px;"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="form.endTime"
              style="width: 126px;"
            ></el-date-picker>
          </el-col>
        </el-form-item>
         <el-form-item label="代理商:" style="margin-left:20px">
                <el-select v-model="form.agentId" filterable clearable>
                    <el-option v-for="(item,index) in agentList" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item style="margin-left:20px">
          <el-button
            type="primary"
            @click="searchData"
            class="themed-button"
            icon="el-icon-search"
            >搜索查询</el-button
          >
        </el-form-item>
          <el-form-item>
          <el-button
            icon="el-icon-download"
            class="themed-button"
            type="info"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </el-form-item>
           
        </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
              
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="时间"
                    min-width="120">
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="orderNumber"
                    label="订单号"
                    min-width="120">
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="iccid"
                    label="ICCID"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="shortIccid"
                    label="短号"
                    min-width="120"/>
                <el-table-column
                    show-overflow-tooltip
                    prop="type"
                    label="来源"
                    min-width="160">
                     <template slot-scope="scope">
                     <span>{{getType(scope.row.type,scope.row.refundStatusStr)}}</span>
                     </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="incType"
                    label="类型"
                    min-width="120">
                     <template slot-scope="scope">
                     <span v-if="scope.row.incType =='1'">增加</span>
                     <span v-if="scope.row.incType =='2'">扣减</span>
                     </template>
                </el-table-column>
                  <el-table-column
                prop="mchId"
                label="商户ID"
                min-width="180"
                >
            </el-table-column>
                 <el-table-column
                    prop="beforeBalance"
                    label="变更前金额"
                    min-width="180">
                </el-table-column>
                 <el-table-column
                    prop="increment"
                    label="操作金额"
                    min-width="180">
                </el-table-column>
                 <el-table-column
                    prop="balance"
                    label="变更后金额"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注"
                    min-width="180">
                </el-table-column>
              
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import {balanceChangeRecord,exportBalanceChangeRecordList} from '@/api/cardManagement/balanceManagement.js';
import resize from '@/mixins/resize.js'
import {orderGetAgents} from '@/api/agentApplyCheck.js'
export default {
    name:'',
    mixins:[resize],
    data(){
        return {
            form:{
              type:"",
              incType:"",
              iccid:this.$route.query.iccid,
              orderNumber:"",
              startTime:"",
              endTime:"",
              shortIccid:"",
              agentId:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            quaryParams:"",
            originList:[{label:"全部",value:""},{label:"充值",value:"1"},{label:"订单退款",value:"2"},{label:"余额退款",value:"3"},{label:"充值套餐",value:"4"},{label:"超额自动扣除",value:"5"},{label:"转卡支出",value:"6"},{label:"转卡收入",value:"7"},{label:"手动调整",value:"8"},{label:"退款失败",value:"9"},{label:"收益转入",value:"10"},{label:"赠送金额",value:"11"},],
            isDisable:false,
            agentList:[],
        }
    },
    created(){
        // this.quaryParams = this.$route.query.iccid==''?'undefined':this.$route.query.iccid
        // this.quaryParams = this.$route.query.iccid
        console.log(this.form.iccid, this.$route.query.iccid)
        if(this.form.iccid){
           this.isDisable = true
        }else {
          this.isDisable = false
        }
         this._orderGetAgents()
        this.getBalanceChangeRecordList()
    },
    methods:{
       _orderGetAgents(){
            this.agentList = []
            this.agentList.push({
                label:'全部',
                value:''
            });
            // this.agentList.push({
            //     label:'当前账号',
            //     value:'-1'
            // });
            orderGetAgents({status:0}).then((res)=>{
                if(res.length>0){
                    res.forEach((item)=>{
                        this.agentList.push({
                            label:item.name,
                            value:item.id
                            })
                    })
                }
            })
        },
        getType(type,refundStatusStr){
            let typeStr = ""
         if(type=="1"){
            typeStr = "充值"
         }else if(type=="2"){
          typeStr = "订单退款"
         }else if(type=="3"){
          typeStr = "余额退款"
         }else if(type=="4"){
          typeStr = "充值套餐"
         }else if(type=="5"){
          typeStr = "超额自动扣除"
         }else if(type=="6"){
         typeStr = "转卡支出"
         }else if(type=="7"){
         typeStr = "转卡收入"
         }else if(type=="8"){
         typeStr = "手动调整"
         }else if(type=="9"){
         typeStr = "退款失败"
         }else if(type=="10"){
         typeStr = "收益转入"
         }else if(type=="11"){
         typeStr = "赠送金额"
         }
         if(refundStatusStr){
          return typeStr + " ("+ refundStatusStr+")"
         } else{
          return typeStr
         }
         
        },
        getBalanceChangeRecordList(){
           
            // this.form.iccid = this.quaryParams
            console.log(this.form)
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page,
            }
            Object.assign(params,this.form)
              console.log(params)
            balanceChangeRecord(params).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        handleSizeChange(size){
            this.pageSize = size
            this.getBalanceChangeRecordList()
        },
        onPageChange(page){
            this.page=page
            this.getBalanceChangeRecordList()
        },
        onSearch(){
            this.page=1
            this.getBalanceChangeRecordList()
        },
        exportExcel() {
      exportBalanceChangeRecordList(this.form).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    searchData(){
         this.page=1
            this.getBalanceChangeRecordList()
    }
      
    }
}
</script>
<style>
    .balanceChangeRecord .el-form-item__label{
        vertical-align: top;
    }
</style>