<template>
  <!--推送记录 -->
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
        <el-col :span="20" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item label="" :label-width="formLabelWidth" prop="imei">
                <el-input
                  placeholder="请输入ICCID"
                  v-model="queryParams.iccid"
                ></el-input>
              </el-form-item>
              <el-form-item
                label=""
                :label-width="formLabelWidth"
                prop="agentId"
              >
                <treeselect
                  v-model="queryParams.agentId"
                  :options="agentOptions"
                  :normalizer="normalizer"
                  :show-count="true"
                  placeholder="请选择代理商"
                />
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth" prop="imei">
                <el-select v-model="queryParams.status" placeholder="状态">
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
        <el-col :span="4" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column prop="id" label="ID" min-width="120">
        </el-table-column>
        <el-table-column prop="gyAgent.agentName" label="代理商" min-width="120">
        </el-table-column>
        <el-table-column prop="iccid" label="ICCID" min-width="200">
        </el-table-column>
        <el-table-column prop="type" label="类型" min-width="180">
        <template slot-scope="scope">
        <span>{{scope.row.type === "1" ? "天流量":"月流量"}}</span>
        </template>
        </el-table-column>
        <!-- <el-table-column prop="pushContent" label="内容" min-width="180">
        </el-table-column> -->
        <el-table-column prop="retry" label="重试次数" min-width="180">
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="180">
        <template slot-scope="scope">
        <span>{{ scope.row.status === "0" ? "推送中" :scope.row.status === "1" ? "成功":"失败"}}</span>
        </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="180">
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="details(scope.row.id)"
              >详情</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    <el-dialog title="推送详情" :visible.sync="dialogFormVisible" width="30%">
    <el-descriptions title=""  :column="1" style="margin-left:20px">
    <el-descriptions-item label="id">{{descriptions.id}}</el-descriptions-item>
    <el-descriptions-item label="客户">{{descriptions.gyAgent}}</el-descriptions-item>
    <el-descriptions-item label="类型">{{descriptions.type === "1" ? "天流量":"月流量"}}</el-descriptions-item>
    <el-descriptions-item label="内容">{{descriptions.pushContent}}</el-descriptions-item>
    <el-descriptions-item label="重试次数">{{descriptions.retry}}</el-descriptions-item>
    <el-descriptions-item label="状态">{{descriptions.status === "0" ? "推送中" :descriptions.status === "1" ? "成功":"失败"}}</el-descriptions-item>
    <el-descriptions-item label="创建时间">{{descriptions.createTime}}</el-descriptions-item>
    <el-descriptions-item label="更新时间">{{descriptions.updateTime}}</el-descriptions-item>
        
</el-descriptions>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { agentGetAllAgent } from "@/api/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {
pushRecordList,
pushRecordDetails,
delPushRecord,
} from "@/api/statistical/statistical.js";
export default {
  mixins:[resize],
  // name: "materialsList",
  components: { Treeselect },
  data() {
    var validGet = (rule, value, callback) => {
      if (value) {
        var reg = /^(\d+\s?)+$/;
        if (!reg.test(value)) {
          callback(new Error("输入不合法,必须输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      formInline: {
        spec: "",
        material: "",
      },
      addForm: {
        radioLable: 0,
        imeiArray: [],
        agentId: undefined,
      },
      loading: false,
      tableData: [],
      descriptions: [],
      agentOptions: [],
      statusOptions: [
      {
        value: '0',
        label: '推送中' 
      },
      {
        value: '1',
        label: '成功' 
      },
      {
        value: '2',
        label: '失败' 
      }
      ],
      dialogFormVisible: false,
      queryParams: {
        agentId: undefined,
        agentName: undefined,
        status:""
      },
      rules: {
        agentId: [
          { required: true, message: "请选择代理商", trigger: "change" },
        ],
        imeiArray: [
          { required: true, message: "请输入IMEI" },
          { validator: validGet, trigger: "blur" },
        ],
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
      dialogTitle: "新增分配",
    };
  },
  created() {
    this.getDeviceList();
    this.getAllAgent();
  },
  methods: {
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams, params);
      pushRecordList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },
    /** 代理商数据 */
    getAllAgent() {
      agentGetAllAgent().then((response) => {
        this.agentOptions = response;
      });
    },
    /** 转换代理商数据结构 */
    normalizer(node) {
      if (
        node.children == null ||
        (node.children == "null" && node.children && !node.children.length)
      ) {
        delete node.children;
      }
      return {
        id: node.agentId,
        label: node.agentName,
        children: node.children,
      };
    },
    /** 搜索 */
    searchData() {
      this.page = 1;
      this.getDeviceList();
    },
    treeSelectChange(raw, instanceId) {
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        this.addForm.agentId = raw.agentId;
        this.addForm.agentName = raw.agentName;
      });
    },
    /** 详情 */
    details(id){
    this.dialogFormVisible=true
    pushRecordDetails(id).then((res) => {
    this.descriptions=res
    });
    },
    /** 删除 */
    delect(id) {
      this.$confirm("是否确认删除该项分配数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delPushRecord(id).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getDeviceList();
        });
      });
    },
     /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 10px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>