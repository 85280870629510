<template>
	<div class="offficalAccount">
		<div class="title-box clearfix">
			<span class="m-title">公众号</span>
			<el-form :inline="true" :model="formInline" class="right themed-form search-form"
				style="height: auto;line-height: 40px;">
				<el-form-item label="关键字：">
					<el-input v-model="formInline.pageSearchKeys" placeholder="请输入公众号名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询
					</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addRecord" icon="el-icon-plus" class="themed-button">新增
					</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="name" label="公众号名称">
				</el-table-column>
				<el-table-column prop="appId" label="appID">
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间">
				</el-table-column>
				
				<el-table-column label="操作">
					<template slot-scope="scope">
						<span @click='editOffical(scope.row.id,scope.row.locateFlag)' class="left_agent">编辑</span>
						<span @click='deleteById(scope.row.id)' class="left_agent" style="margin-left:10px">删除</span>
						<span @click='rechargeUrl(scope.row.appId)' class="left_agent" style="margin-left:10px">充值二维码</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize"
				style="text-align: left;margin-top: 20px;" background layout="total,sizes,prev, pager, next,jumper"
				:total="total" @size-change="handleSizeChange" @current-change="onPageChange">
			</el-pagination>
		</div>
		<el-dialog title="新增公众号" :visible.sync="dialogAddMerchVisible" v-if="dialogAddMerchVisible">
			<add-official @close="dialogAddMerchVisible=false" @reload="reload"></add-official>
		</el-dialog>
		<el-dialog title="编辑公众号" :visible.sync="dialogEditMerchVisible" v-if="dialogEditMerchVisible">
			<edit-official  :editData="editObj"  @close="dialogEditMerchVisible=false" @reload="reload"></edit-official>
		</el-dialog>
         <el-dialog
            title="充值二维码"
            :visible.sync="payURLDialogVisible"
            @close="closeCode"
            width="30%"
            center>
            <div class="paycode" style="width:150px;height:150px;margin:auto">
                <div id="qrcode" ref="qrcode" style="text-align:center"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="copyUrl">复制链接</el-button>
               
            </span>
        </el-dialog>
	</div>
</template>

<script>
    import QRCode from "qrcodejs2"  
	import addOfficial from "./addOfficial.vue"
	import editOfficial from "./editOfficial.vue"
	import onresize from "@/mixins/resize.js"
	import loading from '@/utils/Loading.js'
	import {
		officialList,
		deleteOfficialList,
		rechargeUrl
	} from "@/api/payManagment.js"
	export default {
		name:'officialAccount',
		mixins: [onresize],
		data() {
			return {
				formInline: {
					pageSearchKeys: ""
				},
				tabledata: [],
				page: 1,
				pageSize: 15,
				total: 0,
				dialogAddMerchVisible: false,
				dialogEditMerchVisible:false,
				editObj:{},
				payURLDialogVisible:false,
                payUrl:"",
			}
		},
		created() {
			this.getOfficialList(this.formInline)
		},
		components:{
			addOfficial,
			editOfficial,
		},
		methods: {
			copyUrl(val){
				let that = this 
        this.$copyText(this.payUrl).then(res=>{
			that.$notify({
				title:"复制链接成功",
				type:"success"
			})
		})
			},
			crateQrcode () {
            let qr = new QRCode('qrcode', {
            width: 150,
            height: 150, // 高度
            text: this.payUrl // 二维码内容
            })
        },
			rechargeUrl(id){
              rechargeUrl({appId:id}).then(res=>{
				console.log(res)
                 this.payURLDialogVisible = true
				 this.payUrl = res
				  this.$nextTick(() => {
                    this.crateQrcode()
                })
			  })
			},
			 closeCode(){
            this.payURLDialogVisible = false
            this.$refs.qrcode.innerHTML = ''
        },
			getOfficialList(params) {
				officialList(params).then(res => {
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getOfficialList(params)
			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getOfficialList(params)
			},
			onPageChange(page) {
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getOfficialList(params)
			},
			editOffical(id,locateFlag){
             this.dialogEditMerchVisible = true
			 this.editObj.id = id
			 this.editObj.locateFlag = locateFlag
			},
			deleteById(id) {
				this.$confirm('确定要删除该公众号吗？', '消息提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteOfficialList({
						id: id
					}).then(res => {
						this.$message({
							type: "success",
							message: "删除成功！"
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			addRecord() {
				this.dialogAddMerchVisible = true
			},
			reload() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getOfficialList(params)
			}
		}
	}
</script>

<style>
	.offficalAccount .el-form-item__label {
		vertical-align: top;
	}
</style>
