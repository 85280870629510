<template>

<div class="use-card table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">代理商活跃卡数</span>
        <el-form inline v-model="form" class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="产品类型:">
                <el-select v-model="form.productType" filterable>
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="代理商:">
                <el-select v-model="form.agentId" filterable>
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="(item,index) in agentList" :label="item.agentThreeName" :value="item.agentThreeId" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="月份:">
                <el-date-picker v-model="form.month" type="month"  value-format="yyyy-MM" placeholder="选择月" style="width:100%"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-download" class="themed-button" @click="exportRecord">导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="月份" prop="month" show-overflow-tooltip></el-table-column>
            <el-table-column label="产品类型" prop="productType" show-overflow-tooltip>
                <template  slot-scope="scope">
                    <span>{{productTypeName(scope.row.productType)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="代理商" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="本月新增卡数" prop="addCard" show-overflow-tooltip></el-table-column>
            <el-table-column label="本月流失卡数" prop="loseCard" show-overflow-tooltip></el-table-column>
            <el-table-column label="本月活跃卡数" prop="activeCard" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="统计时间" prop="createTime" show-overflow-tooltip></el-table-column> -->
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {getAgentList,getAgentCardList,agentCardSyncExport} from '@/api/staticsManagement/cardUseCycel.js'
import {messageBox,confirmBox} from '@/utils/common.js'

import resize from '@/mixins/resize.js'
import dictGroup from '@/mixins/dictGroup.js'

export default {
    name:'agentActiveCardNumber',
    mixins:[resize,dictGroup],
    data(){
        return {
            form:{
                productType:'',
                agentId:'',
                month:''
            },
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            agentList:[]
        }
    },
    mounted(){
        this._getAgentList()
        this._getAgentCardList()
    },
    methods:{
        searchTable(){
            this._getAgentCardList()
        },
        exportRecord(){
            agentCardSyncExport(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._getAgentCardList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._getAgentCardList()
        },
        productTypeName(productType){
            let result = this.w_PRODUCT_.find((item)=>{
                return item.dictValue == productType
            })
            if(!result){
                return '-'
            }
            if(result.label){
                return result.label
            }else{
                return '-'
            }
        },
        _getAgentList(){
            getAgentList().then((res)=>{
                this.agentList = res
            })
        },
        _getAgentCardList(){
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            getAgentCardList(Object.assign(params,this.form)).then((res) => {
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    }
}
</script>
