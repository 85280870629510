<template>
	<div class="main-export-in fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">导入记录</span>
		</div>
		<div class="content-box">
			<el-table ref='table' :data="tableData" stripe style="width: 100%;" :max-height="maxTableHeight">
				
				<el-table-column prop="name" label="任务名">
				</el-table-column>
				<el-table-column prop="startTime" label="开始时间">
				</el-table-column>
				<el-table-column prop="endTime" label="结束时间">
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0">失败</span>
						<span v-else-if="scope.row.status == 1">导入中</span>
						<span v-else-if="scope.row.status == 2">成功</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column label="进度">
					<template slot-scope="scope">
						<span>100%</span>
					</template>
				</el-table-column>
				<el-table-column prop="resultMsg" label="执行结果" width="240">
				</el-table-column>
				<el-table-column label="导入文件">
					<template slot-scope="scope">
						<a href='javascript:;' @click="downloadIn(scope.row.id,scope.row.name)" style="color: #337ab7;">点击下载</a>
					</template>
				</el-table-column>
				<el-table-column label="处理结果">
					<template slot-scope="scope">
						<a href='javascript:;' @click="downloadOut(scope.row.id,scope.row.name)" style="color: #337ab7;">点击下载</a>
					</template>
				</el-table-column>
				<el-table-column prop="remark" label="备注">
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {importList,importDownloadIn,importDownloadOut} from '@/api/export.js'
	import resize from '@/mixins/resize.js'
	import Utils from '@/utils/utils.js'
	import loading from '@/utils/Loading.js'
	export default{
		name:'importIn',
		mixins:[resize],
		data(){
			return{
				page:1,
				pageSize:15,
				total:0,
				tableData:[],
				searchForm:{},
			
			}
		},
		created() {
			this.getImportList()
		
		},
		methods:{
			
			downloadIn(id,name){
				importDownloadIn({msgId:id}).then(res=>{
					const filename =  name +'.xls'
					Utils.createDownload(res,filename)
				})
			},
			downloadOut(id,name){
				importDownloadOut({msgId:id}).then(res=>{
					const filename = name + '结果.xls'
					Utils.createDownload(res,filename)
				})
			},
			
			onSearch(){
				this.page = 1
				this.getImportList()
			},
			reset(){
				this.searchForm = {}
			},
			getImportList(){
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
				}
				Object.assign(this.searchForm,params)
				importList(this.searchForm).then(res=>{
					this.tableData = res.rows
					this.total = res.count
				})
			},
			onPageChange(page){
				this.page = page
				this.getImportList()
			},
			handleSizeChange(size){
				this.pageSize = size
				this.getImportList()
			}
		},
		
	}
</script>

<style>

</style>
