<template>
	<div id="tb" style="padding:0px;height:auto;overflow:auto;">
		<div style="height:3px;"></div>
		<form id="baseForm" name="baseForm" enctype="multipart/form-data" action="" method="post" target="hiddenFrame" style="margin:0px;">
		</form>
		<div id="pirMainId" style="width: 600px;height:300px;"></div>
		<div id="barMainId" style="width: 600px;height:300px;margin-top: 50px;"></div>
		<div style="height:3px;"></div>
	</div>
</template>

<script>
	import echarts from 'echarts'
	import {
		orderStatistics,
	} from '@/api/staticsManagement.js'
	export default {
		name:'orderStatics',
		data() {
			return {
				createTime: "",
				monthCount: 0,
				flowCount: 0,
				allMoney: 0,
				allCount: 0,
				currMoney: 0,
				currCount: 0,
				upMoney: 0,
				upCount: 0,
				myChart: "",
				myBarChart: "",
			}
		},
		created() {
			this.getOrderStatistics()
		},
		mounted() {
			window.onresize = () => {
				return (() => {
					this.myChart.resize()
					if (!!this.myBarChart) {
						this.myBarChart.resize()
					}
				})()
			}

		},
		methods: {
			getOrderStatistics() {
				orderStatistics({
					createTime: this.createTime
				}).then(responseResult => {
					this.monthCount = responseResult.monthCount
					this.flowCount = responseResult.flowCount
					this.allMoney = responseResult.allMoney
					this.allCount = responseResult.allCount
					this.currMoney = responseResult.currMoney
					this.currCount = responseResult.currCount
					this.upMoney = responseResult.upMoney
					this.upCount = responseResult.upCount
					this.loadBar(this.allMoney, this.upMoney, this.currMoney, this.allCount, this.upCount, this.currCount)
					this.loadPie(this.monthCount, this.flowCount)
				})
			},
			loadBar(allMoney, upMoney, currMoney, allCount, upCount, currCount) {
				const myBarChart = echarts.init(document.getElementById('barMainId'));
				this.myBarChart = myBarChart
				let option = {
					color:["#ff7f50","#87cefa"],
					tooltip: {
						show: true
					},
					legend: {
						data: ['金额', '次数']
					},
					xAxis: [{
						type: 'category',
						data: ['总计', '上月', '本月']
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							"name": "金额",
							"type": "bar",
							"data": [allMoney, upMoney, currMoney]
						},
						{
							"name": "次数",
							"type": "bar",
							"data": [allCount, upCount, currCount]
						}
					]
				};
				myBarChart.setOption(option, true); //当setOption第二个参数为true时，会阻止数据合并
			},
			loadPie(monthCount, flowCount) {
				// 基于准备好的dom，初始化echarts图表
				const myChart = echarts.init(document.getElementById('pirMainId'));
        this.myChart = myChart
				let option = {
					color:["#ff7f50","#87cefa"],
					title: {
						text: '订单统计',
						subtext: '每日订单',
						x: 'center'
					},
					tooltip: {
						trigger: 'item',
						formatter: "{a} <br/>{b} : {c} ({d}%)"
					},
					legend: {
						orient: 'vertical',
						x: 'left',
						data: ['月卡续费', '叠加包'],
						
					},
					toolbox: {
						show: true,
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: false
							},
							magicType: {
								show: true,
								type: ['pie', 'funnel'],
								option: {
									funnel: {
										x: '25%',
										width: '50%',
										funnelAlign: 'left',
										max: 1548
									}
								}
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						},
					/* 	featureTitle : {
						        mark : '辅助线开关',
						        markUndo : '删除辅助线',
						        markClear : '清空辅助线',
						        dataZoom : '区域缩放',
						        dataZoomReset : '区域缩放后退',
						        dataView : '数据视图',
						        lineChart : '折线图切换',
						        barChart : '柱形图切换',
						        restore : '还原',
						        saveAsImage : '保存为图片'
						    } */
					},
					calculable: true,
					series: [{
						name: '充值类型',
						type: 'pie',
						radius: '55%',
						center: ['50%', '60%'],
						data: [{
								value: monthCount,
								name: '月卡续费'
							},
							{
								value: flowCount,
								name: '叠加包'
							}
						]
					}]
				};

				// 为echarts对象加载数据   
				myChart.setOption(option);
			},


		},
	}
</script>

<style>
</style>
