<template>
    <div class="work-order-transfer fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">工单转单记录</span>
            <el-form inline ref="workOrderTransferForm" v-model="workOrderTransferData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="转单起始日期" v-model="workOrderTransferData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="转单结束日期" v-model="workOrderTransferData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                 <el-form-item>
                    <el-input v-model="workOrderTransferData.workOrderNo" placeholder="工单号"></el-input>
                </el-form-item>
                <el-form-item label="审核状态">
                    <search-select  :optionsMetaAll="optionsStstus" v-model="workOrderTransferData.status"></search-select>
                </el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                
            </el-form>
        </div>

         <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="workOrderNo"
                    label="工单号"
                    >
                </el-table-column>
                <el-table-column
                    label="状态"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">成功</span>
                        <span v-else-if="scope.row.status==2">失败</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="oldHandler"
                    label="转单前处理人"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="newHandler"
                    label="转单后处理人"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="审核时间"
                    > 
                    <template slot-scope="scope">
                        <span v-if="scope.row.remark">{{scope.row.remark}}</span>
                        <span v-else>-</span>
                    </template> 
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import resize from '@/mixins/resize.js'
import { changeWorkOrder } from "@/api/history.js";
export default {
    name:'workOrderTransfer',
    components:{
        SearchSelect
    },
    mixins:[resize],
    data(){
        return {
            optionsStstus:[
                {label:'全部',value:''},
                {label:'成功',value:'1'},
                {label:'失败',value:'2'}
            ],
            workOrderTransferData:{
                startTime:'',
                endTime:'',
                workOrderNo:'',
                status:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getWorkOrderTransferList()
    },
    methods:{
        getWorkOrderTransferList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.workOrderTransferData,params)
            changeWorkOrder(this.workOrderTransferData).then(res=>{
                this.tableData=res.rows
                this.total=res.count
            })
        },
         handleSizeChange(size){
            this.pageSize=size
            this.getWorkOrderTransferList()
        },
        onPageChange(page){
            this.page=page
           this.getWorkOrderTransferList()
        },
        onSearch(){
            this.page = 1
            this.getWorkOrderTransferList()
        },
    }
}
</script>

<style>
    .work-order-transfer .el-form-item__label{
        vertical-align: top;
    }
</style>