<template>

<div class="form-update">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="出库批次编号:">
            <el-input v-model="form.outCode"  readonly></el-input>
        </el-form-item>
        <el-form-item label="入库批次:" required>
            <!-- <search-select :optionsMetaAll="inCodeList" v-model="form.inCode"></search-select> -->
            <el-select v-model="form.inCode" filterable placeholder="请选择">
                <el-option v-for="(item,index) in inCodeList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="物料编号:" required>
            <!-- <search-select :optionsMetaAll="materielCodeList" v-model="form.materielCode"></search-select> -->
            <el-select v-model="form.materielCode" filterable placeholder="请选择">
                <el-option v-for="(item,index) in materielCodeList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="卡板费:" required>
            <el-input v-model="form.cardFee" ></el-input>
        </el-form-item>
        <el-form-item label="出库客户:" required>
            <!-- <search-select :optionsMetaAll="outAgentIdList" v-model="form.outAgentId"></search-select> -->
            <el-select v-model="form.outAgentId" filterable placeholder="请选择">
                <el-option v-for="(item,index) in outAgentIdList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="续费单价:" required>
            <el-input v-model="form.renewPrice" ></el-input>
        </el-form-item>
        <el-form-item label="出库时间:" prop="outStockTime" required>
            <el-date-picker
                v-model="form.outStockTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="备注:" prop="remark">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="update('form')">修改</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {OutFindById,stockOutImportUpdate} from '@/api/stockManagement/stock.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

import SearchSelect from '@/components/SearchSelect'

export default {
    props:['id'],
    data(){
        var checkRemark = (rule, value, callback) => {
            if (value.length>32) {
                return callback(new Error('备注不能大于32个字符'));
            }else{
                callback()
            }
        };
        return {
            form:{
                id:'',
                flowNumber:'',
                outCode:'',
                inCode:'',
                materielCode:'',
                cardFee:'',
                outAgentId:'',
                renewPrice:'',
                outStockTime:'',
                remark:''
            },
            inCodeList:[],
            materielCodeList:[],
            outAgentIdList:[],
            rules: {
                outStockTime: [
                    { required: true, message: '请选出库时间', trigger: 'change'}
                ],
                remark: [
                    { validator: checkRemark, trigger: 'blur' }
                ]
            },
        }
    },
    mounted(){
        this.form.id = this.id
        this._OutFindById()
    },
    methods:{
        update(formName){
            confirmBox(this,'确定提交修改吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    let parames = {}
                    Object.assign(parames,this.form)
                    parames.outStockTime = this.changeDateFormat(this.form.outStockTime)
                    stockOutImportUpdate(parames).then(res=>{
                        if(res.slide_msg_key == 1){
                            messageBox(this,res.slide_msg_message_key)
                        }
                        this.cancle()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        importExcel(){
            let name = 'import.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        _OutFindById(){
            OutFindById(this.form).then((res)=>{
                if(res.inCode.length>0){
                    res.inCode.forEach((item)=>{
                        this.inCodeList.push({label:item.inCode,value:item.inCode})
                    })
                }else{
                    this.inCodeList = []
                }

                if(res.mCode.length>0){
                    res.mCode.forEach((item)=>{
                        this.materielCodeList.push({label:item.materielCode,value:item.materielCode})
                    })
                }else{
                    this.materielCodeList = []
                }

                if(res.customer.length>0){
                    res.customer.forEach((item)=>{
                        this.outAgentIdList.push({label:item.name,value:item.id})
                    })
                }else{
                    this.outAgentIdList = []
                }

                this.form.outCode = res.entity.outCode
                this.form.inCode = res.entity.inCode
                this.form.materielCode = res.entity.materielCode
                this.form.cardFee = res.entity.cardFee
                this.form.outAgentId = res.entity.outAgentId
                this.form.renewPrice = res.entity.renewPrice
                this.form.outStockTime = res.entity.outStockTime
                this.form.remark = res.entity.remark
            })
        },
        changeDateFormat(value){
            let arr = value.split('-')
            return arr[1]+'/'+arr['2']+'/'+arr[0]
        }
    },
    components:{
        SearchSelect
    }
}
</script>

