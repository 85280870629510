<template>
    <div class="activity-management fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">活动管理</span>
        </div>

         <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="activityName"
                    label="活动名称"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="活动类型"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==1">激活赠送活动</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="活动状态"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">启用</span>
                        <span v-else-if="scope.row.status==2">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="setMealName"
                    label="套餐名"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="updateName"
                    label="修改人"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="updateTime"
                    label="修改时间"
                    min-width="150"
                    >
                </el-table-column>
               <el-table-column
                    label="操作"
                    min-width="200">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        class="themed-button" type="info"
                        @click="handleEdit(scope.row.id)"
                        >编辑活动</el-button>
                        <el-button
                        size="mini"
                        class="themed-button" type="danger"
                        @click="handleCardEvent(scope.row.id)"
                        >活动网卡</el-button>
                    </template>
                </el-table-column>
                  
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { activityList } from '@/api/activity.js'
export default {
    name:'eventManagement',
     mixins:[resize],
    data(){
        return {
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getActivityList()
    },
    methods:{
        getActivityList(){
            let params={
                pageSize:this.pageSize,
                pageNo:this.page
            }
            activityList(params).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
            this.page = page
            this.getActivityList()
			
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getActivityList()
        },
        handleEdit(){},
        handleEdit(){},
    }
}
</script>

<style>
   
</style>