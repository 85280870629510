<template>
    <div class="qa-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">QA列表</span>
            <el-form :model="form" inline class="hook right themed-form search-form" style="line-height: 40px;">
                
                <!-- <el-form-item>
                    <el-input v-model="form.name" placeholder="名称"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-select v-model="form.mark" filterable placeholder="类别">
                        <el-option v-for="(item,index) in articleTypeList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>                 -->
                <el-form-item>
					<el-button icon="el-icon-plus" type="primary" class="themed-button" @click="onAdd">新增</el-button>
				</el-form-item>                
                <!-- <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item> -->
                
            </el-form>
        </div>

        <div class="content-box" v-loading='tableLoading'>
            <div ref="table">
                <div class="content_item" v-for="(item,index) in tableData" :key="index">
                    <span class="article_title">{{item.question}}</span>
                    <div class="article_content artical" v-html="item.answer"></div>
                    <div class="align-right">
                        <el-button type="text" @click="edit(item)">编辑</el-button>
                        <el-button type="text" @click="check(item)">查看</el-button>
                        <el-button type="text" @click="delect(item)">删除</el-button>
                    </div>
                </div>
            </div>

            <el-pagination :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog :title="articalForm.title" :visible.sync="dialogCheck" v-if="dialogCheck"> 
            <el-form  style="max-height:600px;overflow:auto;">
                <el-form-item>
                    <div v-html="articalForm.html"></div>   
                </el-form-item>
            </el-form>
            <div class="align-right">
                <el-button type="primary" class="themed-button" @click="dialogCheck=false">确定</el-button>
                <el-button type="primary" class="themed-button" @click="dialogCheck=false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>

import {getQAPageList,deleteQA} from "@/api/setting.js"

export default {


    data(){
        return {
            form:{
                mark:'',
                name:''
            },
            articleTypeList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            tableLoading:false,
            dialogCheck:false,
            articalForm:{
                title:'',
                html:''
            }

        }
    },
    mounted(){
        this._getQAPageList()
    },
    methods: {
        _getQAPageList(){
            this.tableLoading = true
            getQAPageList(this.form).then(res =>{
                console.log(res)
                this.tableLoading = false
                this.tableData = res.rows
                this.total = res.total
            })
            

        },
        check(item){
            this.dialogCheck = true
            this.articalForm.title = item.question
            this.articalForm.html = item.answer
        },
        edit(item){
            let info = {type:"edit",title:item.question, html:item.answer, id:item.id}
            this.$router.push({
                 path:'/editor/editorArtical',
                 query:{info:JSON.stringify(info)}
            })
        },
        delect(item){
            this.$modal.confirm("确定删除【"+item.question+"】这条问答吗?").then(res=>{
               deleteQA({id:item.id}).then(res =>{
                   this.$modal.alertSuccess("删除成功")
                   this._getQAPageList()
               }) 
            })
        },
        onAdd(){
            let info = {type:"add"}
            this.$router.push({
                 path:'/editor/editorArtical',
                 query:{info:JSON.stringify(info)}
            })
        },
        onPageChange(page){
            this.page = page
            this._getQAPageList()

		},
		handleSizeChange(size){
            this.pageSize = size
            this._getQAPageList()
			
        },
        onSearch(){
            this._getQAPageList()
        },
        exportExcel(){
            monthDayFlowExport(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },

    }
}
</script>
<style>
.iccid-width .el-input__inner{width:180px !important;}
.qa-list .content_item{margin-bottom:10px;line-height: 20px;}
.qa-list .article_title{line-height: 24px;font-weight: 600; word-wrap:break-word;}
.artical{
    word-wrap:break-word;
    line-height: 20px;
    text-indent:20px;
     text-overflow: ellipsis;
     display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    display: -moz-box;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;

    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
}

</style>