<template>
    <diV class="agent-statistice">

        <el-row :gutter='20'>
            <el-col :span='20'>
                <div>
                    <div class="title-box"><span class="m-title">月充值统计</span></div>
                    <div class="chose-item">
                        <el-form inline style="height: auto;">
                            <el-form-item label="条件选择:">
							</el-form-item>
                            <el-form-item>
                                <el-date-picker v-model="form1.year" type="year" placeholder="选择年份" value-format='yyyy' style="width:100px"></el-date-picker>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form1.productType" clearable filterable placeholder="产品类型" style="width:100px" @visible-change="changeList1">
                                   <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form1.accountIds" multiple collapse-tags clearable filterable placeholder="请选择运营商" style="width:150px" @visible-change="changeList1">
                                    <el-option v-for="(item,index) in accountList1" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>

                            <el-form-item>
                                <el-select v-model="form1.agentIds" multiple collapse-tags clearable filterable placeholder="代理商" style="width:150px" @visible-change="changeList1">
                                    <el-option v-for="(item,index) in agentIdList1" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>
                            
                        
                            <el-form-item>
                                <el-select v-model="form1.toType" clearable filterable placeholder="业务类型" style="width:100px" @visible-change="changeList1">
                                    <el-option v-for="(item,index) in typeList1" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>

                            

                            <el-form-item>
                                <el-button @click="showTable1" size="mini" class="themed-button" type="danger">确 定</el-button>
                                <el-button @click="exportTable('1')" size="mini" class="themed-button" type="danger">导 出</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div ref="demo1" style="height:500px;background-color: #fff;"></div>
                </div>
            </el-col>
            <el-col :span='4'>

            </el-col>
        </el-row>

        <el-row :gutter='20'>
            <el-col :span='20'>
                <div>
                    <div class="title-box"><span class="m-title">月利率统计</span></div>
                    <div class="chose-item">
                        <el-form inline style="height: auto;">
                            <el-form-item label="条件选择:">
							</el-form-item>

                            <el-form-item>
                                <el-date-picker v-model="form2.year" type="year" placeholder="选择年份" value-format='yyyy' style="width:100px"></el-date-picker>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form2.productType" clearable filterable placeholder="产品类型" style="width:100px" @visible-change="changeList2">
                                   <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form2.accountIds" multiple collapse-tags clearable filterable placeholder="请选择运营商" style="width:150px" @visible-change="changeList2">
                                    <el-option v-for="(item,index) in accountList2" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>

                            <el-form-item>
                                <el-select v-model="form2.agentIds" multiple collapse-tags clearable filterable placeholder="代理商" style="width:150px" @visible-change="changeList2">
                                    <el-option v-for="(item,index) in agentIdList2" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>
                            
                        
                            <el-form-item>
                                <el-select v-model="form2.toType" clearable filterable placeholder="业务类型" style="width:100px" @visible-change="changeList2">
                                    <el-option v-for="(item,index) in typeList2" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>


                            <el-form-item>
                                <el-button @click="showTable2" size="mini" class="themed-button" type="danger">确 定</el-button>
                                <el-button @click="exportTable('2')" size="mini" class="themed-button" type="danger">导 出</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div ref="demo2" style="height:500px;background-color: #fff;"></div>
                </div>
            </el-col>
            <el-col :span='4'>

            </el-col>
        </el-row>

        <el-row :gutter='20'>
            <el-col :span='20'>
                <div>
                    <div class="title-box"><span class="m-title">ARPU统计</span></div>
                    <div class="chose-item">
                        <el-form inline style="height: auto;">
                            <el-form-item label="条件选择:">
							</el-form-item>
                            <el-form-item>
                                <el-date-picker v-model="form3.year" type="year" placeholder="选择年份" value-format='yyyy' style="width:100px"></el-date-picker>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form3.productType" clearable filterable placeholder="产品类型" style="width:100px" @visible-change="changeList3">
                                   <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form3.accountIds" multiple collapse-tags clearable filterable placeholder="请选择运营商" style="width:150px" @visible-change="changeList3">
                                    <el-option v-for="(item,index) in accountList3" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>

                            <el-form-item>
                                <el-select v-model="form3.agentIds" multiple collapse-tags clearable filterable placeholder="代理商" style="width:150px" @visible-change="changeList3">
                                    <el-option v-for="(item,index) in agentIdList3" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>
                            
                        
                            <el-form-item>
                                <el-select v-model="form3.toType" clearable filterable placeholder="业务类型" style="width:100px" @visible-change="changeList3">
                                    <el-option v-for="(item,index) in typeList3" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button @click="showTable3" size="mini" class="themed-button" type="danger">确 定</el-button>
                                <el-button @click="exportTable('3')" size="mini" class="themed-button" type="danger">导 出</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div ref="demo3" style="height:500px;background-color: #fff;"></div>
                </div>
            </el-col>
            <el-col :span='4'>

            </el-col>
        </el-row>

        <el-dialog title="导出信息" :visible.sync="dialogExport" width="500" :destroy-on-close='true'>
            <el-form :model="exportForm"  ref="exportForm" label-position="right" label-width="200px">
                <el-form-item label="起始时间:">
                    <el-date-picker v-model="exportForm.startTime" type="month" placeholder="选择月份" value-format='yyyy-MM' style="width:250px"></el-date-picker>
                </el-form-item>
                <el-form-item label="终止时间:">
                    <el-date-picker v-model="exportForm.endTime" type="month" placeholder="选择月份" value-format='yyyy-MM' style="width:250px"></el-date-picker>
                </el-form-item>

                <el-form-item label="产品类型:">
                     <el-select v-model="exportForm.productType" clearable filterable placeholder="产品类型" style="width:250px" disabled>
                          <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                     </el-select>
                  </el-form-item>

                <el-form-item label="运营商:">
                    <el-select v-model="exportForm.accountIds" multiple collapse-tags clearable filterable placeholder="请选择运营商" style="width:250px" disabled>       
                        <el-option v-for="(item,index) in accountList" :key="index" :label="item.accountName" :value="item.accountId"></el-option>
                    </el-select>
				</el-form-item>

                <el-form-item label="代理商:">
                    <el-select v-model="exportForm.agentIds" multiple collapse-tags clearable filterable placeholder="代理商" style="width:250px" disabled>
                        <el-option v-for="(item,index) in agentIdList" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                    </el-select>
                </el-form-item>
                
                        
                 <el-form-item label="业务类型:">
                     <el-select v-model="exportForm.toType" clearable filterable placeholder="业务类型" style="width:250px" disabled>
                        <el-option v-for="(item,index) in typeList" :key="index" :label="item.name" :value="item.value"></el-option>
                     </el-select>
                 </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureExport('exportForm')">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogExport = false">取 消</el-button>
            </div>
        </el-dialog>
    </diV>
</template>

<script>
import echarts from "echarts"
import {getAccountList,rechargeMonthStatics,exportRechargeMonthStatics,rateMonthStatics,exportRateMonthStatics,ARPUStatics,exportARPUStatics,searchParamCheck} from "@/api/operationalStatistics/operationalStatistics.js";
import dictGroup from '@/mixins/dictGroup.js'

export default {
    mixins:[dictGroup],
    data(){
        return {
            form1:{
                year:'',
                agentIds:[],
                accountIds:[],
                toType:'',
                productType:'',
            },
            agentIdList1:[],
            accountList1:[],
            typeList1:[],
            //月充值统计
            flowCost:[],  //月总成本
            totalRebateShare:[],  //分摊返利
            totalRecharge:[],      //充值总额
            totalRechargeShare:[], //分摊收入
            
            

            //月利率统计
            form2:{
                year:'',
                agentIds:[],
                accountIds:[],
                toType:'',
                productType:'',
            },
            totalFlow:[], //总流量
            rateTotal:[],  //总毛利率
            agentIdList2:[],
            accountList2:[],
            typeList2:[],

            form3:{
                year:'',
                agentIds:[],
                accountIds:[],
                toType:'',
                productType:'',
            },
            agentIdList3:[],
            accountList3:[],
            typeList3:[],

            totalUseNum:[],
            arpu:[],

            dialogExport:false,
            exportType:'',
            exportForm:{
                startTime:'',
                endTime:'',
                agentIds:[],
                accountIds:[],
                toType:'',
                productType:'',
            },
            agentIdList:[],
            accountList:[],
            typeList:[],

            lineChart1:'',
            lineChart2:'',
            lineChart3:'',
        }
       
    },
    mounted() {
			window.onresize = () => {
				return (() => {
					this.lineChart1.resize()
					this.lineChart2.resize()
					this.lineChart3.resize()
				})()
			}
		},
    created(){
        this.$nextTick(function () {
            this.initChart1()
            this.initChart2()
            this.initChart3()
        });
       
    },
    watch:{
		'form1.productType'	:function(val){
                this.form1.agentIds = []
                this.form1.accountIds = []
                this.form1.toType = ''
            },
        'form1.accountIds'	:function(val){
                this.form1.agentIds = []
                this.form1.toType = ''
            },
        'form1.agentIds':function(val){
                this.form1.toType = ''
            },
        
        'form2.productType'	:function(val){
                this.form2.agentIds = []
                this.form2.accountIds = []
                this.form2.toType = ''
            },
        'form2.accountIds'	:function(val){
                this.form2.agentIds = []
                this.form2.toType = ''
            },
        'form2.agentIds':function(val){
                this.form2.toType = ''
            },

        'form3.productType'	:function(val){
                this.form3.agentIds = []
                this.form3.accountIds = []
                this.form3.toType = ''
            },
        'form3.accountIds'	:function(val){
                this.form3.agentIds = []
                this.form3.toType = ''
            },
        'form3.agentIds':function(val){
                this.form3.toType = ''
            }
        },
        
    methods: {
        changeList1(e){
            if(e){
                return false
            }else{
                searchParamCheck(this.form1).then(res=>{
                    this.agentIdList1 = res.agents?res.agents:[]
                    this.accountList1 = res.account?res.account:[]
                    this.typeList1 = res.toTypes?res.toTypes:[]
                })
            }

        },
        changeList2(e){
            if(e){
                return false
            }else{
                searchParamCheck(this.form2).then(res=>{
                    this.agentIdList2 = res.agents
                    this.accountList2 = res.account
                    this.typeList2 = res.toTypes
                })
            }

        },
        changeList3(e){
            if(e){
                return false
            }else{
                searchParamCheck(this.form3).then(res=>{
                    this.agentIdList3 = res.agents
                    this.accountList3 = res.account
                    this.typeList3 = res.toTypes
                })
            }

        },
       showTable1(){
           rechargeMonthStatics(this.form1).then(res=>{
                this.flowCost = res.flowCost
                this.totalRebateShare = res.totalRebateShare
                this.totalRecharge = res.totalRecharge
                this.totalRechargeShare = res.totalRechargeShare
                this.initChart1()
           })
       },

       initChart1(){
        let dom = this.$refs.demo1
        let myChart = echarts.init(dom)
        this.lineChart1 =myChart
           const option={
               title: [
                {
                    top: '40%',
                    left: 10,
                    subtextStyle: {
                        align: 'left',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '金\n额'//   \n换行
                },
            ],
            grid:{
            },
               legend: {
                data: [
                    {name: '月充值总额'},
                    {name: '月分摊返利'},
                    {name: '月分摊收入'},
                    {name: '月总成本'},
                ],
                textStyle: {
                    color: '#0000ff',
                    fontSize: 11
                },
                y: 'bottom',
                x: 'center',
            },
               xAxis:{
                   data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
               },
               yAxis:{
                   type:'value',
                   name:'单位（元）'
               },
               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
            ],
               series:[
                   {
                       name:'月充值总额',
                       type:'line',
                       data:this.totalRecharge,
                      
                   },
                   {
                       name:'月分摊返利',
                       type:'line',
                       data:this.totalRebateShare,
                      
                   },
                   {
                       name:'月分摊收入',
                       type:'line',
                       data:this.totalRechargeShare,
                      
                   },
                   {
                       name:'月总成本',
                       type:'line',
                       data:this.flowCost,
                   },

               ],
               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

           }

           myChart.setOption(option);
           
       },

       showTable2(){
           rateMonthStatics(this.form2).then(res=>{
                this.totalFlow = res.totalFlow
                this.rateTotal = res.rateTotal
                this.initChart2()
           })
       },

       initChart2(){
        let dom = this.$refs.demo2
         let myChart = echarts.init(dom)
         this.lineChart2 =myChart
           const option={
               title: [
                {
                    top: '40%',
                    left: 10,
                    subtextStyle: {
                        align: 'left',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '价\n格'//   \n换行
                },
                {
                    top: '40%',
                    right: 10,
                    subtextStyle: {
                        align: 'right',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '流\n量'
                },
            ],
            grid:{
            },
               legend: {
                data: [
                    {name: '月总毛利'},
                    {name: '月流量总数'},
                ],
                textStyle: {
                    color: '#0000ff',
                    fontSize: 11
                },
                y: 'bottom',
                x: 'center',
            },
               xAxis:{
                   data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
               },
               yAxis:[{
                   type:'value',
                   name:'单位（元）'
               },
               {
                   type:'value',
                   name:'单位（G）'
               }],
               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
            ],
               series:[
                   {
                       name:'月总毛利',
                       type:'line',
                       data:this.rateTotal,
                       yAxisIndex:0
                   },
                  
                   {
                       name:'月流量总数',
                       type:'line',
                       data:this.totalFlow,
                       yAxisIndex:1
                   },
               ],
               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

           }
           myChart.setOption(option);
           
       },


        showTable3(){
           ARPUStatics(this.form3).then(res=>{
                this.totalUseNum = res.totalUseNum
                this.arpu = res.arpu
                this.initChart3()
           })
       },
       initChart3(){
        let dom = this.$refs.demo3
         let myChart = echarts.init(dom)
         this.lineChart3 =myChart
           const option={
               title: [
                {
                    top: '40%',
                    left: 10,
                    subtextStyle: {
                        align: 'left',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '数\n量'//   \n换行
                },
                {
                    top: '40%',
                    right: 10,
                    subtextStyle: {
                        align: 'right',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '比\n例'
                },
            ],
            grid:{
            },
               legend: {
                data: [
                    {name: '月在用用户数'},
                    {name: 'ARPU'},
                ],
                textStyle: {
                    color: '#0000ff',
                    fontSize: 11
                },
                y: 'bottom',
                x: 'center',
            },
               xAxis:{
                   data:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
               },
               yAxis:[{
                   type:'value',
                   name:'单位（个）'
               },
               {
                   type:'value',
                   name:'单位（*100%）'
               }],
               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
            ],
               series:[
                   {
                       name:'月在用用户数',
                       type:'line',
                       data:this.totalUseNum,
                       yAxisIndex:0
                   },
                  
                   {
                       name:'ARPU',
                       type:'line',
                       data:this.arpu,
                       yAxisIndex:1
                   },

               ],
               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

           }

           myChart.setOption(option);
           
       },

       exportTable(type){
           this.resetExport(type)
           this.dialogExport =true
           this.exportType = type
       },

       sureExport(){
           let exportMethod
           if(this.exportType == 1){
               exportMethod = exportRechargeMonthStatics
           }else if(this.exportType == 2){
               exportMethod = exportRateMonthStatics
           }else if(this.exportType == 3){
               exportMethod = exportARPUStatics
           }
           exportMethod(this.exportForm).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
                            this.dialogExport = false
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
			})
       },


       resetExport(type){
           if(type=='1'){
               this.agentIdList = this.agentIdList1
               this.accountList = this.accountList1
               this.typeList = this.typeList1,
               this.exportForm ={
                startTime:'2021-01',
                endTime:'2021-12',
                agentIds:this.form1.agentIds,
                accountIds:this.form1.accountIds,
                toType:this.form1.toType,
                productType:this.form1.productType,
                }
           }else if(type=='2'){
               this.agentIdList = this.agentIdList2
               this.accountList = this.accountList2
               this.typeList = this.typeList2,
               this.exportForm ={
                startTime:'2021-01',
                endTime:'2021-12',
                agentIds:this.form2.agentIds,
                accountIds:this.form2.accountIds,
                toType:this.form2.toType,
                productType:this.form2.productType,
                }
           }else if(type=='3'){
               this.agentIdList = this.agentIdList3
               this.accountList = this.accountList3
               this.typeList = this.typeList3,
               this.exportForm ={
                startTime:'2021-01',
                endTime:'2021-12',
                agentIds:this.form3.agentIds,
                accountIds:this.form3.accountIds,
                toType:this.form3.toType,
                productType:this.form3.productType,
                }
           }
           
       }

    },

}
</script>

<style>
 .agent-statistice   .el-form-item--small.el-form-item{
        margin-bottom:0
    }

    .el-row {
        margin-bottom: 20px;
    }
</style>