/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function getDiscountList(params){
    return request({
        url: 'promotion/discount/list.do?',
        method: 'post',
        data:params
    })
}
export function updateData(params){
    return request({
        url: 'promotion/discount/update.do',
        method: 'post',
        data:params
    })
}
export function deleteData(params){
    return request({
        url: 'promotion/discount/delete.do?',
        method: 'post',
        data:params
    })
}



//newCoupons.vue 
export function discountSave(params){
    return request({
        url: 'promotion/discount/save.do?',
        method: 'post',
        data:params
    })
}
//filterShow.vue 
export function listRuleById(params){
    return request({
        url: 'promotion/discount/listRuleById.do?',
        method: 'post',
        data:params
    })
}
export function delShowFilterById(params){
    return request({
        url: 'promotion/discount/delShowFilterById.do?',
        method: 'post',
        data:params
    })
}
//dialogShow.vue
export function getAgentList(params){
    return request({
        url: 'agent/list4agent.do?',
        method: 'post',
        data:params
    })
}
export function getMealListByAgentId(params){
    return request({
        url: 'promotion/discount/getMealListByAgentId.do?',
        method: 'post',
        data:params
    })
}
export function addShowFilterById(params){
    return request({
        url: 'promotion/discount/addShowFilterById.do?',
        method: 'post',
        data:params
    })
}
