<template>
    <div class="agent-rebate fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">代理商返利统计</span>
            <el-form inline ref="agentRebateForm" v-model="agentRebateData" class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item label="代理商名称:">
                    <search-select :optionsMetaAll="agentList" v-model="agentRebateData.agentId"></search-select>
                </el-form-item>
                <el-form-item label="开始时间:">
					<el-date-picker placeholder="请选择时间" v-model="agentRebateData.startTime" type="date" value-format="yyyy-MM-dd" style="width:100%"/>
				</el-form-item>
				<el-form-item>
					<el-date-picker placeholder="请选择时间" v-model="agentRebateData.endTime" type="date" value-format="yyyy-MM-dd" style="width:100%"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>
            </el-form>
        </div>
        <div class="content-box">
            <el-table
                ref='table'
                border
                stripe
                :data="tableData"
                tooltip-effect="dark"
                :max-height="maxTableHeight">

            <el-table-column label="代理商名称" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="日期" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="新增佣金" prop="addCommission"></el-table-column>
            <el-table-column label="扣减佣金" prop="decCommission"></el-table-column>
            <el-table-column label="新增订单数" prop="orderNum"></el-table-column>
            <el-table-column label="退款订单数" prop="refundNum" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 1">待结算</span>
                    <span v-if="scope.row.status == 2">未审核</span>
                    <span v-if="scope.row.status == 3">审核完成</span>
                    <span v-if="scope.row.status == 4">失败</span>
                </template>
            </el-table-column>
            <el-table-column label="订单总额" prop="orderSum" show-overflow-tooltip></el-table-column>
            <el-table-column label="退款总额" prop="refundSum" show-overflow-tooltip></el-table-column>
            <el-table-column label="总佣金" prop="commissionSum" show-overflow-tooltip></el-table-column>
            
            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button size="mini" class="themed-button" type="primary" @click="orderDetails(scope.row.createTime,scope.row.agentId)">返利详情</el-button>
                </template>
            </el-table-column>
            </el-table>
            <el-pagination 
            :current-page.sync="pageNo"  
            :page-sizes="[5,10,15,50,100,1000]" 
            :page-size="pageSize"
			 background 
             layout="total,sizes,prev, pager, next,jumper" 
             :total="total" 
             @size-change="handleSizeChange" 
             @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'

import {orderGetAgents,getAgentRebate} from '@/api/agentApplyCheck.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'
export default {
    name:'agentRebate',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return{
            agentRebateData:{
                agentId:'',
                startTime:'',
                endTime:''
            },
            agentList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0
        }
    },
    mounted(){
        this._orderGetAgents()
        this._getAgentRebate()
    },
    methods:{
        orderDetails(createTime,agentId){
            if(agentId){
                this.$router.push({
                    path: "/orderManagement/agentApplyCheckDetail",
                    query: {agentId,title:true,"startTime":createTime,"endTime":createTime},
                })
            }
        },
        onSearch(){
            this._getAgentRebate()
        },
        onPageChange(page){
			this.pageNo = page
			this._getAgentRebate()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._getAgentRebate()
        },
        _orderGetAgents(){
            this.agentList = []
            this.agentList.push({
                label:'全部',
                value:''
            });
            this.agentList.push({
                label:'当前账号',
                value:'-1'
            });
            orderGetAgents({status:0}).then((res)=>{
                if(res.length>0){
                    res.forEach((item)=>{
                        this.agentList.push({
                            label:item.name,
                            value:item.id
                            })
                    })
                }
            })
        },
        _getAgentRebate(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                type:2
            }
            getAgentRebate(Object.assign(params,this.agentRebateData)).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.tableData = res.rows
                this.total = res.total

            })
        }
    },
}
</script>
