<template>

<div class="setMealAllocation table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">分配套餐</span>
        <el-form ref="form" :model="form" inline class="hook right themed-form search-form">
            <el-form-item label="分销商:">
                <!-- <search-select :optionsMetaAll="agentList"  v-model="form.agentId" @input="_getsetmealByagentId">
                </search-select> -->
                <el-select v-model="form.agentId"  filterable @change="_getsetmealByagentId">
					<el-option v-for="item in agentList" :key="item.index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
            </el-form-item>
            <el-form-item label="分配状态:">
                <el-select v-model="form.distributionStatus" placeholder="全部">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已分销" value="1"></el-option>
                    <el-option label="未分销" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属类别:">
                <!-- <el-select v-model="form.category" placeholder="全部">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="电信" value="1"></el-option>
                    <el-option label="移动" value="3"></el-option>
                    <el-option label="联通" value="4"></el-option>
                    <el-option label="联通Q" value="6"></el-option>
                    <el-option label="DCP电信" value="5"></el-option>
                    <el-option label="新移动" value="7"></el-option>
                    <el-option label="新联通" value="8"></el-option>
                </el-select> -->
                <el-select v-model="form.category" filterable  @change="getSecondLists">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in $store.getters.categorys" :label="item.name" :value="item.value" :key="index"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="子类别:">
                <el-select v-model="form.secondCategory" @visible-change="secondClick" placeholder="请选择">
                 	<el-option v-for="item in accountLists" :key="item.index" :label="item.label" :value="item.value">
					</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="套餐名称:">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="reloadPageData">搜索查询</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="套餐名称" prop="name" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐类型" prop="typeString" show-overflow-tooltip></el-table-column>
            <el-table-column label="二级套餐类型" prop="secondTypeString" show-overflow-tooltip></el-table-column>
            <el-table-column label="所属类别" show-overflow-tooltip>
               <template slot-scope="scope">
                   <span>{{categoryString(scope.row.category)}}</span>

                   <!-- <span v-if="scope.row.category == 1">电信</span>
                   <span v-if="scope.row.category == 2">其他卡务</span>
                   <span v-if="scope.row.category == 3">移动</span>
                   <span v-if="scope.row.category == 6">联通Q</span>
                   <span v-if="scope.row.category == 5">DCP电信</span>
                   <span v-if="scope.row.category == 7">新移动</span>
                   <span v-if="scope.row.category == 8">新联通</span>
                   <span v-if="scope.row.category == 4">联通</span> -->
               </template>
            </el-table-column>
            <el-table-column label="子类别" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="排序" prop="mealSort" show-overflow-tooltip>
                <template slot-scope="scope">
                     <el-input v-if="scope.row.distributionStatus == 1"   v-model="scope.row.agentOrder" @change="((val)=>{updateSort(val,scope.row.agentSetMealId,1)})"></el-input>
                     <span v-if="scope.row.distributionStatus == 2">-</span>
                     <!-- <el-input v-if="scope.row.distributionStatus == 2"   v-model="scope.row.agentOrder" @change="((val)=>{updateSort(val,scope.row.agentSetMealId,false)})"></el-input> -->
                   <!-- <span v-if="scope.row.distributionStatus == 1">{{scope.row.agentOrder}}</span>
                   <span v-if="scope.row.distributionStatus == 2">-</span> -->
               </template>
            </el-table-column>
              <el-table-column label="无佣金标识" prop="noProfitFlag" >
                <template slot-scope="scope">
                   <span v-if="scope.row.noProfitFlag==1" style="color:#f00">无佣金</span>
                   <span v-else-if="scope.row.noProfitFlag==0" style="color:#f00"></span>
                   <span v-else style="color:#f00"></span>
                </template>
            </el-table-column>
             <!-- <el-table-column label="排序" prop="mealSort" width="90px">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.mealSort" @change="((val)=>{updateSort(val,scope.row.id,false)})"></el-input>
                </template>
            </el-table-column> -->
            <el-table-column label="成本价" prop="originalMoney" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐销售金额" prop="money" show-overflow-tooltip ></el-table-column>
            <el-table-column label="提成比" prop="rebateRatio" show-overflow-tooltip  v-if="isCaptal!='1'"></el-table-column>
            <el-table-column label="返利方式" prop="sellType" show-overflow-tooltip >
                <template slot-scope="scope">
                   <span v-if="scope.row.sellType == 1">提现比模式</span>
                   <span v-if="scope.row.sellType == 2">销售价模式</span>
               </template>
            </el-table-column>
            <el-table-column label="分配状态" prop="type">
                <template slot-scope="scope">
                    <span v-if="scope.row.distributionStatus===1">已分配</span>
                    <span v-else-if="scope.row.distributionStatus===2">未分配</span>
                </template>
            </el-table-column>

            <el-table-column label="虚值策略" v-if="loginType==0">
                <template slot-scope="scope">
                    <!-- <span v-if="scope.row.activeStrategy==1">A</span>
                    <span v-else-if="scope.row.activeStrategy==2">B</span>
                    <span v-else>-</span> -->
                    <span>{{strategyStr(scope.row.activeStrategy)}}</span>
                </template>
            </el-table-column>
              <el-table-column label="显示"  width="105px">
                <template slot-scope="scope">
                 <el-select v-model="scope.row.hideFlagStr"   @change="((val)=>{updateSort(val,scope.row.agentSetMealId,2)})"  v-if="scope.row.distributionStatus == 1">
                    <el-option label="显示" value = "0"></el-option>
                    <el-option label="不显示" value = "1"></el-option>
                </el-select> 
                 <span v-if="scope.row.distributionStatus == 2">-</span>
                </template>
            </el-table-column>
              <el-table-column label="标签" prop="label" width="90px">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.label" @change="((val)=>{updateSort(val,scope.row.agentSetMealId,3)})"  v-if="scope.row.distributionStatus == 1"></el-input>
                     <span v-if="scope.row.distributionStatus == 2">-</span>
                </template>
            </el-table-column>
             <el-table-column label="套餐标记" prop="redMark" width="120px">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.redMark" @change="((val)=>{updateSort(val,scope.row.agentSetMealId,4)})"  v-if="scope.row.distributionStatus == 1"></el-input>
                     <span v-if="scope.row.distributionStatus == 2">-</span>
                </template>
            </el-table-column>
             

            <el-table-column label="操作" width="200px;"> 
                <template slot-scope="scope">
                    <div style="text-align:left">
                        <el-button v-if="scope.row.distributionStatus == 1" type="danger" size="mini" class="themed-button"  @click="deleteInterest(scope.row.agentSetMealId)">移除</el-button>
                        <el-button v-if="scope.row.distributionStatus == 1" type="info" size="mini"  class="themed-button"    @click="editData(scope.row.agentSetMealId,scope.row.noProfitFlag)">编辑</el-button>
                        <el-button v-if="scope.row.distributionStatus == 2" type="danger" size="mini" class="themed-button"  @click="addSetMeal(scope.row.id)">分销</el-button>
                        <el-button v-if="scope.row.distributionStatus == 1&&loginType==0" type="warning" size="mini" class="themed-button"  @click="setup(scope.row.agentSetMealId,scope.row.accountId)">配置</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    
    <el-dialog title="编辑套餐" :visible.sync="dialogUpdate">
        <el-form label-position="right" label-width="200px" :model="setForm">
            <el-form-item label="套餐名称:">
                <el-input v-model="setForm.mealName"></el-input>
            </el-form-item>
            <el-form-item label="套餐原价:">
                <el-input v-model="setForm.originalMoney" :disabled="currentNoProfitFlag==1"></el-input>
            </el-form-item>
            <el-form-item label="套餐售价:" >
                <el-input v-model="setForm.money" ></el-input>
            </el-form-item>
            <el-form-item label="提现比例:" v-if="rebateRatioShow&&isCaptal!='1'">
                <!-- <el-input v-model="setForm.rebateRatio"></el-input> -->
                  <el-input-number v-model="setForm.rebateRatio" controls-position="right" :precision="0"   :min="0" :max="100"></el-input-number>
                <span>%</span>
            </el-form-item>
            <el-form-item label="排序:">
                <el-input v-model="setForm.mealSort"></el-input>
            </el-form-item>
            <el-form-item label="返利方式:"  >
                <el-select v-model="setForm.sellType" @change="sellTypeChange" :disabled="currentNoProfitFlag==1" >
                    <el-option label="-未设置返利模式-" value="0"></el-option>
                    <el-option label="提现比模式" value="1" v-if="isCaptal!='1'"></el-option>
                    <el-option label="销售价模式" value="2" v-if="!isWujiMeal"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="套餐均值:">
                <el-input v-model="setForm.averagePrice" clearable  maxlength="30"></el-input>
            </el-form-item>
            
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="setMealEdit">确定变更</el-button>
            <el-button type="primary" class="themed-button" @click="dialogUpdate = false">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog title="配置" :visible.sync="dialogSetup">
        <el-form label-position="right" label-width="200px" :model="setupForm">
            <el-form-item label="虚值方式:" >
                <el-select v-model="setupForm.activeStrategy">
                    <el-option label="-未设置虚值方式-" value=""></el-option>
                    <!-- <el-option label="A" value="1"></el-option>
                    <el-option label="B" value="2"></el-option> -->
                     <el-option v-for="item in $store.getters.defaultStrategyList" :key="item.dictKey" :label="item.groupName" :value="item.dictValue">
					</el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="限速规则:" >
            <el-select  v-model="setupForm.speedLimitId" placeholder="请选择">
                <el-option v-for="(item,index) in limitRuleList" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>
             </el-form-item>
              <el-form-item label="是否强制使用余额:" >
                <el-select v-model="setupForm.notForceYue">
                    <el-option label="-未设置-" value=""></el-option>
                    <el-option label="不强制" value="1"></el-option>
                    <el-option label="强制" value="0"></el-option>
                     
                </el-select>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="sureUpset">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogSetup = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {getsetmealByagentId,getAgentList,addSetMeal_,deleteInterest_,setMealEdit_,getMealInfo} from '@/api/setMealManagment/setMealChange.js'
    import {limitRuleList} from "@/api/speedLimit/index.js";
    import {loginType} from '@/api/order.js'
    import {messageBox,confirmBox } from '@/utils/common.js'
    import {getFirstName} from '@/api/setMealManagment/setMeal.js'
    import SearchSelect from '@/components/SearchSelect'
    import resize from '@/mixins/resize.js'
    import store from '@/store'
    import {mapGetters} from 'vuex'
     import {getAccountList,} from '@/api/cardManagement/cardBill.js'
    const ERR_OK = 0
    export default {
        name:'setMealAllocation',
        mixins:[resize],
        data(){
            return {
                // categorys:[],
                limitRuleList:[],
                loginType:'',
                id:'',
                form:{
                    agentId:'',
                    distributionStatus:'',
                    category:'',
                    secondCategory:"",
                    name:''
                },
                agentList:[],
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,

                dialogUpdate:false,
                setForm:{
                  mealName:'',
                  originalMoney:'',
                  money:'',
                  rebateRatio:0,
                  mealSort:'',
                  sellType:'',
                  averagePrice:""
                },
                dialogSetup:false,
                setupForm:{
                    id:'',
                    editType:'2',
                    activeStrategy:'',
                    speedLimitId:'',
                    notForceYue:"",
                },
                rebateRatioShow:false,
                currentNoProfitFlag:"",
                accountLists:[{label:'全部',value:''}],
                isWujiMeal:false,
            }
        },
        mounted(){
             if(store.getters.categorys.length<1){
                store.dispatch('getCategory')
        }
         if(store.getters.defaultStrategyList.length<1){
           store.dispatch('getPackageType')
        }
            this._getAgentList()
            this._getsetmealByagentId()
            this.getLoginType()
          //  this.getFirstName()
           try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
        },
         computed:{
        	...mapGetters(
            	['currentRole','agentTree','isCaptal']
        	),
    	},
        methods:{
              secondClick(val){
          if(this.accountLists.length==1&&val){
                  this.$alert('请先选择类别', '提示', {
								confirmButtonText: '确定',
							});
                            return
          }
          
        },
         getSecondLists(val){
             this.form.secondCategory = ""
             this.accountLists = [{label:'全部',value:''}]
                   getAccountList({category:val}).then((res) => {
                    if(res.length>0){
                        res.forEach((item)=>{
                            this.accountLists.push({label:item.accountName,value:item.secondCategory})
                        })
                    }
                })
        },    
     updateSort(val,id,num){
        // console.log(id,val,typeof(val));
            if(  num==1 && (!val || val==="") ) {
                return;
            }
            if(num==1&& isNaN(val)) {
                return;
            }
            if(num == 4){
             if(val.length>15){
                 messageBox(this,'输入长度最大为15个字符','提示')
              return;
             }
            }
          
            let postData = {
                    id, 
                  editType:3,
                };
            let parms = {}
             if(num ==1 ){
              parms.mealSort = val
            }else if(num ==2){
             parms.hideFlag = val
            }else if(num ==3){
           parms.label = val
            }else if(num ==4 ){
          parms.redMark = val
            }
           Object.assign(parms,postData)
            setMealEdit_(parms).then((res)=>{
               this._getsetmealByagentId()
            }).catch((res)=>{
                messageBox(this,'请求错误','操作繁忙请稍后重试或联系客服。')
            })
        },


            // getFirstName(){
            //     getFirstName().then((res)=>{
            //     this.categorys = []
            //     if(res.length>0){
            //         res.forEach((item) => {
            //             if(item.firstName){
            //                 this.categorys.push({
            //                     name:item.firstName,
            //                     value:item.category
            //                 }) 
            //             }
            //         })
            //     }
            // })
            // },
        categoryString(category){
            let cuCatagorys
            if(store.getters.categorys.length>0){
               store.getters.categorys.forEach(item=>{
                   if(category==item.value){
                     cuCatagorys = item.name
                   }
               })
            }
            return cuCatagorys
        },
        strategyStr(strategy){
        let strategyS = "-"
           if(store.getters.defaultStrategyList.length>0){
            store.getters.defaultStrategyList.forEach(item=>{
              if(strategy==item.dictValue){
                   strategyS = item.groupName
              }
            })
               }
               return strategyS
        },
            getLoginType(){
                loginType().then(res=>{
                    this.loginType = res.loginUser.type
                })
            },
            reloadPageData(){
                this.currentPage = 1
                this._getsetmealByagentId()
            },
            addSetMeal(id){
                var params = Object.assign({id}, this.form)
                addSetMeal_(params).then((res)=>{
                    this.handleResult(res)
                })
            },
            deleteInterest(id){
                confirmBox(this,'是否确定移除该套餐，移除后将不能充值该套餐?',null,()=>{
                    var params = Object.assign({id}, this.form)
                    deleteInterest_(params).then((res)=>{
                        this.handleResult(res)
                    })
                })
            },

            setup(id,accountId){
                this.setupForm.activeStrategy=''
                this.dialogSetup = true
                this.setupForm.id = id
                
                limitRuleList({accountId}).then(res=>{
                    this.limitRuleList = []
                    let list = res.rows
                    if(res.rows.length>0){
                        list.forEach(item=>{
                            this.limitRuleList.push({name:item.name,id:item.id})
                        })
                    }

                    getMealInfo({id}).then(res=>{
                        console.log(res)
                    this.setupForm.activeStrategy = res.activeStrategy?res.activeStrategy.toString():''
                    this.setupForm.speedLimitId = res.speedLimitId
                    this.setupForm.notForceYue = res.notForceYue.toString()
                })

                })

            },

            sureUpset(){
                setMealEdit_(this.setupForm).then((res)=>{
                    if (res.system_result_key == 0) {
                       this.$message({
							showClose: true,
							message: '配置成功',
							type: 'success',
						})
						this._getsetmealByagentId()
                        this.dialogSetup=false
                    } 
                })
            },

            editData(id,noProfitFlag){
                this.id = id
                this.currentNoProfitFlag = noProfitFlag
                this._getMealInfo({id})
                this.dialogUpdate = true
            },
            
            setMealEdit(){
                console.log(this.setForm.rebateRatio)
                if(this.setForm.rebateRatio){
                   this.setForm.rebateRatio=this.setForm.rebateRatio/100
                }
                  if(this.setForm.sellType=="1"){
                      this.rebateRatioShow = true
                       
                    }else{
                       this.rebateRatioShow = false 
                       this.setForm.rebateRatio= 0
                    }
                var params = Object.assign({id:this.id}, this.setForm)
                setMealEdit_(params).then((res)=>{
                    this.dialogUpdate = false
                    this.handleResult(res)
                })
            },
            handleResult(res){
                if(typeof res.wodeyongjinError !=="undefined" && res.wodeyongjinError===true) {
                    messageBox(this,"分销比例设置错误，请重新设置" );
                }
                if(res.slide_msg_key==1){
                    messageBox(res.slide_msg_message_key);
                }
                this._getsetmealByagentId()
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._getsetmealByagentId()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._getsetmealByagentId()
            },
		
            _getsetmealByagentId(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                Object.assign(params, this.form)
                getsetmealByagentId(params).then((res)=>{
                    // console.log(res)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            },
            _getAgentList(){
                getAgentList(this.form).then((res) => {
                    this.agentList = res.map((item)=>{
                        return {
                            label:item.name,
                            value:item.id
                        }
                    })
                    this.agentList.unshift({
                        label:"全部",
                        value:""
                    })
                })
            },
            _getMealInfo(params){
                getMealInfo(params).then((res)=>{
                    this.setForm.sellType = res.sellType+""
                    if(this.setForm.sellType=="1"){
                      this.rebateRatioShow = true
                       this.setForm.rebateRatio = Number(res.rebateRatio) *100
                    }else{
                       this.rebateRatioShow = false 
                       this.setForm.rebateRatio= 0
                    }
                    this.setForm.originalMoney = res.originalMoney
                    this.setForm.money = res.money
                   
                    this.setForm.mealName = res.name
                    this.setForm.mealSort = res.mealSort
                    this.setForm.averagePrice = res.averagePrice 

                    //  根据后端返回的条件进行判断
                    if(res.ifWuJi){
                     this.isWujiMeal = true 
                    }else{
                       this.isWujiMeal = false 
                    }
                    
                })
            },
            sellTypeChange(val){
             if(val=="1"){
                      this.rebateRatioShow = true
                    }else{
                       this.rebateRatioShow = false 
                    }
            }
        },
        components:{
            SearchSelect
        }
    }
</script>

