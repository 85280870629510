<template>
  	<div class="virtualCardConfig fadeInRight">
  		<div class="title-box clearfix">
  			<span class="m-title">虚拟卡配置</span>
  			<el-form inline ref="quaryForm" v-model="quaryForm" class="right themed-form search-form"
  				style="height: auto; line-height: 40px">
  				<el-form-item label="运营商">
  					<el-select v-model="quaryForm.accountId" placeholder="运营商">
  						<el-option value="" label="全部">全部</el-option>
  						<el-option v-for="item in virtualAgentList" :key="item.id" :label="item.accountName"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
  				<el-form-item label="运营策略:">
  					<el-input v-model="quaryForm.strategyName" placeholder="策略名称"></el-input>
  				</el-form-item>
  				<el-form-item>
  					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询
  					</el-button>
  				</el-form-item>
  				<el-form-item>
  					<el-button type="primary" @click="addVirtualCardConfig" icon="el-icon-plus" class="themed-button">
  						新增</el-button>
  				</el-form-item>
  			</el-form>
  		</div>

  		<div class="content-box">
  			<el-table border ref="table" stripe :max-height="maxTableHeight" :data="tableData" tooltip-effect="dark"
  				style="width: 100%">
  				<el-table-column show-overflow-tooltip prop="accountName" label="运营商" min-width="200">
  				</el-table-column>
  				<el-table-column show-overflow-tooltip prop="strategyName" label="运营策略" min-width="200">
  				</el-table-column>

  				<el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="200">
  				</el-table-column>
  				<el-table-column show-overflow-tooltip prop="createTime" label="更新时间" min-width="200">
  				</el-table-column>
  				<el-table-column show-overflow-tooltip label="操作" min-width="400">
  					<template slot-scope="scope">
  						<el-button size="mini" class="themed-button" type="info" @click="handleEdit(scope.row.id)">编辑
  						</el-button>
  						<el-button size="mini" class="themed-button" type="danger"
  							@click="handleDelete(scope.row.id)">删除</el-button>
  					</template>
  				</el-table-column>
  			</el-table>
  			<el-pagination :current-page.sync="pageNo" :page-sizes="[5, 10, 15, 50, 100, 1000]" :page-size="pageSize"
  				style="text-align: left; margin-top: 20px" background layout="total,sizes,prev, pager, next,jumper"
  				:total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
  		</div>
  		<el-dialog title="编辑" :visible.sync="dialogFormEditVisible" :destroy-on-close="true">
  			<el-form :model="formEdit" label-position="right" ref="editForm">
  				<el-form-item label="运营商" :label-width="formLabelWidth" prop="accountId">
  					<el-select v-model="formEdit.accountId" placeholder="请选择运营商" filterable>
  						<el-option v-for="item in virtualAgentList" :key="item.id" :label="item.accountName"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
  				<el-form-item label="运营策略" :label-width="formLabelWidth" prop="strategyId">
  					<el-select v-model="formEdit.strategyId" placeholder="请选择运营策略" filterable>
  						<el-option v-for="item in operStrategyList" :key="item.id" :label="item.name"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
  			</el-form>
  			<div slot="footer" class="dialog-footer">
  				<el-button class="themed-button" type="info" @click="sureEdit('editForm')">确 认</el-button>
  				<el-button class="themed-button" type="primary" @click="dialogFormEditVisible = false">关 闭</el-button>
  			</div>
  		</el-dialog>
      	<el-dialog title="新增" :visible.sync="dialogFormVisible" :destroy-on-close="true">
  			<el-form :model="form" label-position="right" ref="addForm">
  				<el-form-item label="运营商" :label-width="formLabelWidth" prop="accountId">
  					<el-select v-model="form.accountId" placeholder="请选择运营商" filterable>
  						<el-option v-for="item in virtualAgentList" :key="item.id" :label="item.accountName"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
  				<el-form-item label="运营策略" :label-width="formLabelWidth" prop="strategyId">
  					<el-select v-model="form.strategyId" placeholder="请选择运营策略" filterable>
  						<el-option v-for="item in operStrategyList" :key="item.id" :label="item.name"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
  			</el-form>
  			<div slot="footer" class="dialog-footer">
  				<el-button class="themed-button" type="info" @click="sureAdd('addForm')">确 认</el-button>
  				<el-button class="themed-button" type="primary" @click="dialogFormVisible = false">关 闭</el-button>
  			</div>
  		</el-dialog>
  	</div>
  </template>

  <script>
  	import resize from "@/mixins/resize.js";
  	import {
  		operatingStrategyList,
  		virtualConfigurationList,
  		virtualConfigurationDelete,
  		virtualConfigurationById,
  		virtualConfigurationEdit,
  		virtualConfigurationAdd,
  	} from "@/api/virtualCard.js"
  	import {
  		agentOperatorList
  	} from "@/api/order.js"
  	export default {
  		name: "virtualCardConfig",
  		mixins: [resize],
  		data() {
  			return {
  				quaryForm: {
  					accountId: "",
  					strategyName: "",
  				},
  				form: {
            accountId:"",
            strategyId:""
          },
  				virtualAgentList: [],
  				operStrategyList: [],
  				dialogFormVisible: false,
          dialogFormEditVisible:false,
  				tableData: [],
  				total: 0,
  				pageNo: 1,
  				pageSize: 15,
  				formLabelWidth: "150px",
          formEdit:{
            id:"",
           accountId:"",
           strategyId:"",
          }
  			};
  		},
  		created() {
  			this.getOperatorList();
  			this.getStrategyList();
        this.getList();
  		},
  		methods: {
  			getList() {
  				let params = {
  					pageNo: this.pageNo,
  					pageSize: this.pageSize,
  				};
  				Object.assign(this.quaryForm, params);
  				virtualConfigurationList(this.quaryForm).then((res) => {
  					this.tableData = res.rows;
  					this.total = res.count;
  				});
  			},
  			getStrategyList() {
  				let params = {
  					pageNo: this.pageNo,
  					pageSize: 20,
  				};
  				operatingStrategyList(params).then((res) => {
  					this.operStrategyList = res.rows;
  				});
  			},

  			onPageChange(page) {
  				this.pageNo = page;
  				this.getList();
  			},
  			handleSizeChange(size) {
  				this.pageSize = size;
  				this.getList();
  			},

  			onSearch() {
  				this.pageNo = 1;
  				this.getList();
  			},
  			addVirtualCardConfig() {
  				this.dialogFormVisible = true
  			},
  			getOperatorList() {
  				agentOperatorList().then((res) => {
  					this.virtualAgentList = res.filter((item) => {
  						return item.accountName.includes("虚拟");
  					});
  				});
  			},
  			sureAdd(formName) {
          if(this.form.accountId==""){
           this.$alert("请选择运营商","提示信息",{
						 confirmButtonText: '确定',
					})
					return
          }
          if(this.form.strategyId==""){
           this.$alert("请选择运营策略","提示信息",{
						 confirmButtonText: '确定',
					})
					return
          }
  				virtualConfigurationAdd(this.form).then(res => {
  					this.$message({
  						showClose: true,
  						message: '新建成功',
  						type: 'success',
  						center: true
  					})
  					this.$refs[formName].resetFields()
  					this.dialogFormVisible = false
  					this.getList()
  				})
  			},
         sureEdit(formName){
            virtualConfigurationEdit(this.formEdit).then(res=>{
                        this.$message({
                            showClose: true,
					                  message: '修改成功',
					                  type: 'success',
						              	center: true
                        })
                        this.$refs[formName].resetFields()
                        this.dialogFormEditVisible=false
                        this.getList() 
                    })
        },
  			handleEdit(id) {
            this.dialogFormEditVisible = true
            virtualConfigurationById({id}).then(res=>{
                this.formEdit.id = res.id
                this.formEdit.accountId = res.accountId
                this.formEdit.strategyId = res.strategyId
            })
  			},
  			handleDelete(id) {
  				this.$confirm("确定要删除吗？", '提示', {
  					confirmButtonText: '确定',
  					cancelButtonText: '取消',
  					type: 'warning'
  				}).then(() => {
  					virtualConfigurationDelete({
  						'id': id
  					}).then(res => {
  						this.$message({
  							type: 'success',
  							message: '删除成功'
  						});
  						this.getList()
  					})

  				})
  			}

  		},
  	};
  </script>

  <style>
  	.virtualCardConfig .el-form-item__label {
  		vertical-align: top;
  	}
  </style>
