<template>
	<div class="singleCardQuery">
		<div class="title-box clearfix">
			<span class="m-title">单卡查询</span>
			<el-form :inline="true" :model="formInline" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="ICCID号/短号：">
					<el-input v-model="formInline.iccidMark1"  class="iccid-width"></el-input>
				</el-form-item>
				<el-form-item label="接入号：">
					<el-input v-model="formInline.phone1" ></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询</el-button>
				</el-form-item>
				  <el-form-item>
					<el-button type="primary"  class="themed-button" @click="clearHistory" >清除缓存</el-button>
				</el-form-item>

			</el-form>
		</div>
		<!-- <div class="group">
			<h1 class="title">基本信息</h1>
			<div>
				<table cellspacing="0" cellpadding="0" style="width: 100%">
					<tr>
						<td>ICCID</td>
						<td>{{ data.iccidMark }}</td>
						<td>套餐类型</td>
						<td>{{ data.mealTypeStr }}</td>
						<td>套餐总流量</td>
						<td>{{ data.flowSize }}MB</td>
					</tr>
					<tr>
						<td>接入号</td>
						<td>{{ data.phone }}</td>
						<td>基础套餐</td>
						
						<td>{{ data.cardPackage }}</td>
						<td>过期时间</td>
						<td>{{ data.expireDate }}</td>
					</tr>
					<tr>
						<td>运营商</td>
						<td>{{ data.accountName }}</td>
						<td>网卡状态</td>
						<td>
							<span v-if="data.cuankaFlag === 1">窜卡</span>
							<span v-else-if="data.status === 1">待激活</span>
							<span v-else-if="data.status === 2">正常</span>
							<span v-else-if="data.status === 3">断网</span>
							<span v-else-if="data.status === 4">停机</span>
							<span v-else-if="data.status === 5">异常</span>
							<span v-else-if="data.status === 6">强制停机</span>
							<span v-else-if="data.status === 7">注销</span>
						</td>
						<td>已使用流量</td>
						<td>{{ data.totalBytesCnt }}MB</td>
					</tr>
					<tr>
						<td>卡类型</td>
						<td>
							<span v-if="data.type == 1">包月</span>
							<span v-else>包年</span>
						</td>
						<td>供应商</td>
						<td>{{ accountEntity.supplier }}</td>
						<td>剩余流量</td>
						<td>
						<span v-if="isNaN(data.flowSize - data.totalBytesCnt )"></span>
						<span v-else>{{parseFloat(data.flowSize - data.totalBytesCnt).toFixed(2)  }}MB</span>
						</td>
					</tr>
					<tr>
						<td>导入时间</td>
						<td>
						<span v-if="data.createDateTime==undefined"></span>
						<span v-else>{{ importTime }}</span></td>
						<td>手机绑定</td>
						<td>
							<span v-if="data.phonebind">{{ data.phonebind }}</span>
							<span v-else>未绑定</span>
						</td>
						<td>增加已使用流量百分比</td>
						<td>
							<span v-if="data.flowAddDiscount">{{ data.flowAddDiscount }}%</span>
							<span v-else>未设置</span>
						</td>
					</tr>
					<tr>
						<td>激活时间</td>
						<td>{{ data.servCreateDate }}</td>
						<td>流量池子</td>
						<td>{{ accountEntity.flowPool }}</td>
						<td>已使用流量（真）</td>
						<td>{{ data.orginTotalBytesCnt }}MB</td>
					</tr>
					<tr>
						<td>当前套餐</td>
						<td v-html="currentMeal"></td>
						<td>代理商</td>
						<td>
							<span v-if="data.agentName==null||data.agentName==''">未分配</span>
							<span v-else>{{ data.agentThreeName }}</span>
						</td>
						<td>生效时间</td>
						<td>{{ res.takeEffectTime }}</td>
					</tr>
					<tr>
						<td>总语音分钟</td>
						<td>{{data.voiceSize}}分钟</td>
						<td>已使用语音分钟</td>
						<td>
							<span v-if="voiceUsed === 'NaN分钟'"></span>
						<span v-else>{{voiceUsed}}</span></td>
						<td>已使用语音分钟(真)</td>
						<td>
							<span v-if="origVoiceUsed==='undefined分钟'"></span>
							<span v-else>{{origVoiceUsed}}</span>
							</td>
					</tr>
					<tr>
						<td>剩余语音分钟</td>
						<td>
						<span v-if="isNaN(data.voiceSize - parseInt(data.totalBytesCntVoice).toFixed(0))"></span>
						<span v-else>{{data.voiceSize - parseInt(data.totalBytesCntVoice).toFixed(0)}}分钟</span></td>
						<td>增加语音百分比</td>
						<td>
							<span v-if="res.voiceAddDiscount&& res.voiceAddDiscount!==''">{{res.voiceAddDiscount}}%</span>
							<span v-else>未设置</span>
						</td>
						<td>运营商状态</td>
						<td id="yunyingstatusspan">{{res.yunyingstatus}}</td>
					</tr>
					<tr>
						<td>当前限速值</td>
						<td>{{ res.limitSpeed }}</td>
						<td>IMEI</td>
						<td>{{ data.imei }}</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>实名状态</td>
						<td>
							<span v-if="isReal == 1">已实名</span>
							<span v-else-if="isReal == 2">未实名</span>
							<span v-else>—未知状态—</span>
							<i class="el-icon-refresh-right" @click="_isRealName"></i>
						</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
            		</tr>
				</table>
			</div>
		</div> -->

  <div class="group">
        <h1 class="title">基础信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
				<td>ICCID</td>
				<td>{{ data.iccidMark }}</td>
            <td>接入号</td>
			<td>{{ data.phone }}</td>
			 <td>套餐类型</td>
			 <td>{{ data.mealTypeStr }}</td>
			  <td>过期时间</td>
			  <td>{{ data.expireDate }}</td>
             </tr>
			  <tr>
				<td>运营商</td>
				<td>{{ data.accountName }}</td>
            <td>网卡状态</td>
				<td>
							<span v-if="data.cuankaFlag === 1">窜卡</span>
							<span v-else-if="data.status === 1">待激活</span>
							<span v-else-if="data.status === 2">正常</span>
							<span v-else-if="data.status === 3">断网</span>
							<span v-else-if="data.status === 4">停机</span>
							<span v-else-if="data.status === 5">异常</span>
							<span v-else-if="data.status === 6">强制停机</span>
							<span v-else-if="data.status === 7">注销</span>
						</td>
			 <td>代理商</td>
				<td>
							<span v-if="data.agentName==null||data.agentName==''">未分配</span>
							<span v-else>{{ data.agentThreeName }}</span>
						</td>
			  <td>供应商</td>
			  <td>{{ accountEntity.supplier }}</td>
             </tr>
			  <tr>
				<td>导入时间</td>
				<td>
						<span v-if="data.createDateTime==undefined"></span>
						<span v-else>{{ importTime }}</span></td>
            <td>激活时间</td>
			<td>{{ data.servCreateDate }}</td>
			 <td>套餐生效时间</td>
			<td>{{ res.takeEffectTime }}</td>
			  <td>卡类型</td>
			 <td>
				<span v-if="data.type == 1">包月</span>
			   <span v-else>包年</span>
						</td>
             </tr>
			  <tr>
				<td>绑定手机</td>
				<td>
							<span v-if="data.phonebind">{{ data.phonebind }}</span>
							<span v-else>未绑定</span>
						</td>
            <td>流量池</td>
			<td>{{ accountEntity.flowPool }}</td>
			 <td>IMEI</td>
			<td>{{ data.imei }}</td>
			  <td>运营商状态</td>
			  <td id="yunyingstatusspan">{{res.yunyingstatus}}</td>
             </tr>
			    <tr>
				<td>当前限速值</td>
				<td>{{ res.limitSpeed }}</td>
            <td>实名状态</td>
			<td>
							<span v-if="isReal == 1">已实名</span>
							<span v-else-if="isReal == 2">未实名</span>
							<span v-else>—未知状态—</span>
							 <el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="_isRealName" style="margin-left:3px"></el-button>
						</td>
			  <td>提前停机</td>
              <td>
                <span v-if="stopAheadDays!=0">{{stopAheadDays+"天"}}</span>
               
                <span v-else>—</span>
                <el-button
                  icon="el-icon-refresh-right"
                  type="primary"
                  class="themed-button"
                  @click="getStopAheadDaysInfo"
                  style="margin-left: 3px"
                ></el-button>
                <!-- <i class="el-icon-refresh-right" @click="_isRealName"></i> -->
              </td>
			  <td></td>
			  <td></td>
             </tr>
           </table>
        </div>
      </div>
	    <div class="group">
        <h1 class="title">流量信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
            <td>套餐总流量</td>
			<td>{{ data.flowSize }}MB</td>
			 <td>已使用流量</td>
			<td>{{ data.totalBytesCnt }}MB</td>
			<td>剩余流量</td>
			<td>
						<span v-if="isNaN(data.flowSize - data.totalBytesCnt )"></span>
						<span v-else>{{parseFloat(data.flowSize - data.totalBytesCnt).toFixed(2)  }}MB</span>
						</td>
			<td>基础套餐</td>
			<td>{{ data.cardPackage }}</td>
             </tr>
			 <tr>
				<td>总语音(分钟)</td>
						<td>{{data.voiceSize}}分钟</td>
						<td>已使用语音(分钟)</td>
						<td>
							<span v-if="voiceUsed === 'NaN分钟'"></span>
						<span v-else>{{voiceUsed}}</span></td>
						<td>剩余语音(分钟)</td>
						<td>
						<span v-if="isNaN(data.voiceSize - parseInt(data.totalBytesCntVoice).toFixed(0))"></span>
						<span v-else>{{data.voiceSize - parseInt(data.totalBytesCntVoice).toFixed(0)}}分钟</span></td>
						
			 </tr>
			 <tr>
				<td>增加已使用流量百分比</td>
				<td>
							<span v-if="data.flowAddDiscount">{{ data.flowAddDiscount }}%</span>
							<span v-else>未设置</span>
						</td>
				<td>已使用流量（真）</td>
				<td>{{ data.orginTotalBytesCnt }}MB</td>
					<td>增加已使用语音百分比</td>
						<td>
							<span v-if="res.voiceAddDiscount&& res.voiceAddDiscount!==''">{{res.voiceAddDiscount}}%</span>
							<span v-else>未设置</span>
						</td>
				<td>已使用语音(真)</td>
						<td>
							<span v-if="origVoiceUsed==='undefined分钟'"></span>
							<span v-else>{{origVoiceUsed}}</span>
							</td>
			 </tr>
           </table>
        </div>
      </div>
	    <div class="group">
        <h1 class="title">当前套餐</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
            <!-- <td v-html="currentMeal"></td> -->
             <td>
                <p>当前生效套餐：</p>
                <span v-html="currentMeal"></span>
                <p>待生效套餐：</p>
                <span v-html="willMeal"></span>
              </td>
			 </tr>
           </table>
        </div>
      </div>


		<div class="group">
<h1 class="title">常规操作</h1>
        <div>
          <ul class="clearfix">
            <li @click="mealRecharge">
              <img src="../cardManagement/1.png" alt="" />
              <p>套餐充值</p>
            </li>
            <li @click="activate">
              <img src="../cardManagement/2.png" alt="" />
              <p>激活</p>
            </li>
            <li @click="cardRecovery">
              <img src="../cardManagement/3.png" alt="" />
              <p>保号复机</p>
            </li>
            <li @click="cardStop">
              <img src="../cardManagement/4.png" alt="" />
              <p>保号停机</p>
            </li>
            <li @click="limitStop">
              <img src="../cardManagement/4.png" alt="" />
              <p>设置达量断网</p>
            </li>
            <li @click="limitStopRecovery">
              <img src="../cardManagement/4.png" alt="" />
              <p>达量断网复机</p>
            </li>
						<li @click="jkcb">
						  <img src="../cardManagement/4.png" alt="" />
						  <p>机卡重绑</p>
						</li>
						
            <li @click="flowDetail">
              <img src="../cardManagement/4.png" alt="" />
              <p>流量详单</p>
            </li>
            <li @click="updateExpire">
              <img src="../cardManagement/5.png" alt="" />
              <p>更改过期时间</p>
            </li>
            <li @click="setThreshold">
              <img src="../cardManagement/6.png" alt="" />
              <p>更新阈值</p>
            </li>
            <li @click="toNewCard">
              <img src="../cardManagement/7.png" alt="" />
              <p>转新卡</p>
            </li>
            <li @click="deviceCardBound">
              <img src="../cardManagement/8.png" alt="" />
              <p>机卡绑定</p>
            </li>
            <li @click="stopRecoveryRecord">
              <img src="../cardManagement/9.png" alt="" />
              <p>停复机记录</p>
            </li>
            <li @click="orderRecord">
              <img src="../cardManagement/9.png" alt="" />
              <p>往期订单</p>
            </li>
            <li @click="singleLimit" style="display: none;">
                        <img src="../cardManagement/7.png" alt="">
                        <p>单卡限速</p>
                    </li>
            <li @click="tamperRecovery">
              <img src="../cardManagement/7.png" alt="" />
              <p>窜卡复机</p>
            </li>
            <li @click="noLoopcard">
              <img src="../cardManagement/7.png" alt="" />
              <p>不轮询网卡</p>
            </li>
            <li @click="modifyFlow">
              <img src="../cardManagement/7.png" alt="" />
              <p>增减流量</p>
            </li>
            <li @click="whiteNubmerSet">
              <img src="../cardManagement/7.png" alt="" />
              <p>白名单设置</p>
            </li>
            
          </ul>
        </div>
		</div>
		<el-dialog title="套餐充值" :visible.sync="dialogAddPackage" :destroy-on-close="true" width="60%">
		  <add-package
		    :addPackageData="addPackageData"
		    :curInfor="formInline"
		    @close="dialogAddPackage = false"
		  ></add-package>
		</el-dialog>
		<el-dialog title="设置达量" :visible.sync="dialogLimitStop">
		  <limit-stop
		    :iccidMark="iccidMark"
		    @close="dialogLimitStop = false"
		  ></limit-stop>
		</el-dialog>
		<el-dialog title="流量详单" :visible.sync="dialogFlowDetail">
		  <flow-detail :iccidMark="iccidMark" ref="childFlowDetail"></flow-detail>
		</el-dialog>
		<el-dialog title="变更过期时间" :visible.sync="dialogUpdateExpire" width="80%">
		  <update-expire :cardId="cardId" ref="childUpdateExpire"></update-expire>
		</el-dialog>
		<el-dialog title="转新卡" :visible.sync="dialogToNewCard" :destroy-on-close="true">
		  <tonew-card
		    :iccidMark="iccidMark"
		    @close="dialogToNewCard = false"
		  ></tonew-card>
		</el-dialog>
		<el-dialog title="增减流量" :visible.sync="dialogModifyFlow" :destroy-on-close="true">
		  <change-flow
		    :curInfor="data"
		    @close="dialogModifyFlow = false"
		  ></change-flow>
		</el-dialog>
		<el-dialog title="白名单设置" :visible.sync="dialogWhiteVoice" v-if="dialogWhiteVoice">
        <white-voice :iccidMark="data.iccidMark" :phone="data.phone" @close="dialogWhiteVoice = false"  ></white-voice>
      </el-dialog>
	</div>
</template>
<script type="text/ecmascript-6">
	import AddPackage from "@/views/packageRecharge/addPackage"
	import LimitStop from "@/views/cardManagement/cardBillDetail/limitStop"
	import FlowDetail from "@/views/cardManagement/cardBillDetail/flowDetail"
	import UpdateExpire from "@/views/packageRecharge/updateExpire"
	// import TonewCard from "@/views/cardManagement/cardBillDetail/toNewCard"
	import ChangeFlow from "@/views/packageRecharge/changeFlow"
	import TonewCard from "@/views/packageRecharge/toNewCard"
	import WhiteVoice from "@/views/cardManagement/cardBillDetail/whiteVoice.vue"
	import {
		findCardInfo,
		getPackageAll,
		activate_,
		cardRecovery_,
		cardStop_,
		limitStopRecovery_,
		setThreshold_,
		deviceCardBound_,
		tamperRecovery_,
		noLoopcard_,
		allowRealname_,
		openVoice_,
		delOnlyLoop_,
		isRealName,
		clearHistory,
		getStopAheadDays
	} from "@/api/cardManagement/cardBillDetail.js";
	import {
		messageBox,
		confirmBox
	} from "@/utils/common.js";
	import loading from "@/utils/Loading.js";
	
	export default {
		name:'singleCardQuery',
		data() {
			return {
				isReal:0,
				res: {},
				data: {
					voiceSize:"",
					totalBytesCntVoice:""
				},
				accountEntity: [],
				cardLiftList: [],
				nextCardLifeList: [],
				tianDjbCardLifes: [],
				dialogAddPackage: false,
				addPackageData: {},
				dialogLimitStop: false,
				dialogFlowDetail: false,
				dialogUpdateExpire: false,
				dialogToNewCard: false,
				dialogModifyFlow: false,
				dialogWhiteVoice:false,
				formInline: {
					iccidMark1: "",
					phone1: "",
					iccidOrPhone: "",
					setMealId: "",
					pckCode: "",
					cardType: "",
					isChild: "",
					codeChild: "",
					status: 0,
					type: 0,
				},
				stopAheadDays:0,
				
			}
		},
 computed: {
    importTime() {
      //导入时间
      let createDateTime = this.data.createDateTime + "";
      return (
        createDateTime.substr(0, 4) +
        "-" +
        createDateTime.substr(4, 2) +
        "-" +
        createDateTime.substr(6, 2) +
        " " +
        createDateTime.substr(8, 2) +
        ":" +
        createDateTime.substr(10, 2) +
        ":" +
        createDateTime.substr(12, 2)
      );
    },
    willMeal() {
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix < compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      if (this.nextCardLifeList.length > 0) {
        //  msg+="待生效套餐<br>"
        this.nextCardLifeList.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }
      if (this.tianDjbCardLifes.length > 0) {
        this.tianDjbCardLifes.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }

      return msg;
    },
    currentMeal() {
      //当前套餐
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      return msg;
    },
    gparams() {
    /* this.formInline.iccidMark1 = this.data.iccidMark */
      this.formInline.iccidOrPhone = this.data.iccidMark
      this.formInline.setMealId = this.data.setMealId
      this.formInline.isChild = this.data.isChild
      this.formInline.codeChild = this.data.codeChild
      this.formInline.cardType = this.data.category
      this.formInline.status = this.data.status
      this.formInline.type = this.data.type
    },
    iccidMark() {
      return this.data.iccidMark;
    },
    cardId() {
      return this.data.id;
    },
		voiceUsed(){
			let flowCnt_voice = parseInt(this.data.totalBytesCntVoice)
			if(flowCnt_voice && flowCnt_voice!=""){
			    flowCnt_voice = parseFloat(flowCnt_voice).toFixed(0);
			}
			return flowCnt_voice + "分钟"
		},
		origVoiceUsed(){
			let orginTotalBytesCntVoice = this.data.orginTotalBytesCntVoice;
			if(orginTotalBytesCntVoice && orginTotalBytesCntVoice!=""){
			    orginTotalBytesCntVoice = parseFloat(orginTotalBytesCntVoice).toFixed(0);
			}
			return orginTotalBytesCntVoice + "分钟"
		},
		yunyingstatusspan(){
			if(this.data.cuankaFlag==="1"){
			    //$("#yunyingstatusspan").html("窜卡");
			}else if(this.res.yunyingstatus==0){
				return "接口异常"
			}else if(this.res.yunyingstatus==1){
			    if(this.data.category=='1'){
						return "在用"
			    }else{
						return "待激活"
			    }                        
			}else if(this.res.yunyingstatus==2){
			    if(this.data.category=='1'){
						return "用户报停"
			    }else{
						return "已激活"
			    }
			}else if(this.res.yunyingstatus==3){
			    if(this.data.category=='1'){
						return "用户拆机"
			    }else{
						return "停机"
			    }
			}else if(this.res.yunyingstatus==4){
			    if(this.data.category=='1'){
			    }else{
						return "停机"
			    }
			}else if(this.res.yunyingstatus==5){
			    if(this.data.category=='1'){
						return "欠停(双向)"
			    }else{
			    }
			}else if(this.res.yunyingstatus==6){
			    if(this.data.category=='1'){
						return "欠停(单向)"
			    }else{
						return "可测试"
			    }
			}else if(this.res.yunyingstatus==7){
			    if(this.data.category=='1'){
						return "违章停机"
			    }else{
						return "库存"
			    }
			}else if(this.res.yunyingstatus==8){
			    if(this.data.category=='1'){
						return "挂失"
			    }else{
						return "预销户"
			    }
			}else if(this.res.yunyingstatus==9){
			    if(this.data.category=='1'){
			    }else{
						return "已销户"
			    }
			}else if(this.res.yunyingstatus==19){
			    if(this.data.category=='1'){
						return "待激活"
			    }else{                            
			    }
			}
			if(this.data.cardInfo){
			    this.data.cardInfo.forEach((item)=>{
			        let productStatusName=item.productStatusName;
							return  productStatusName.productStatusName
			       });
			}
		},
  },
	mounted(){
		console.log(this.data.createDateTime )
		// console.log()
		//typeof(this.data.flowSize - this.data.totalBytesCnt)
	},
		methods: {
			 getStopAheadDaysInfo(){
       getStopAheadDays({iccid:this.iccidMark}).then(res=>{
          console.log(res)
          this.stopAheadDays  = res.stopAheadDays
       })  
    },
			 clearHistory(){
      clearHistory({iccid:this.iccidMark}).then(res=>{
           if (res.system_result_key == '0') {
             messageBox(this,"清除成功")
			 if( this.data.iccidMark||this.data.phone){
				let params={}
					if (this.data.iccidMark) {
					params.iccidOrPhone = this.data.iccidMark
				} else if (this.data.phone) {
					params.iccidOrPhone = this.data.phone
				}
                loading.show()
				this._findCardInfo(params); 
			 }
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
      })
    },
			searchData() {
				let iccidMark = this.formInline.iccidMark1
				let phone = this.formInline.phone1
				if (!iccidMark && !phone) {
					messageBox(this, "请输入ICCID或者接入号!")
					return;
				}
				if (iccidMark) {
					this.formInline.iccidOrPhone = iccidMark
				} else if (phone) {
					this.formInline.iccidOrPhone = phone
				}
				loading.show()
				this._findCardInfo(this.formInline);
			},
			/* getCardInfo(params) {
				findCardInfo(params).then(res => {
					this.res = res;
					this.data = res.entity;
					this.accountEntity = res.accountEntity;
					this.cardLiftList = res.cardLiftList;
					this.nextCardLifeList = res.nextCardLifeList;
					this.tianDjbCardLifes = res.tianDjbCardLifes;
				})
			}, */
			delOnlyLoop() {//删除仅轮询
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。")
			    return  
			}
			  delOnlyLoop_(this.formInline).then((res) => {
			    this._handleResponse(res);
			  });
			},
			openVoice() { //开通语音(GD)
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
			    return ; 
			}
			  if(this.accountEntity.category==="7" && ((this.accountEntity.secondCategory||"")==="1" || (this.accountEntity.secondCategory||"")==="3")) {
			      openVoice_(this.formInline).then((res)=>{
			        this._handleResponse(res)
			      })
			  } else {
			      alertx("非CTBoss移动卡，不支持此操作！");
			  }
			},
			allowRealname() {//允许实名(GD)
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
			    return ; 
			}
			  if (
			    this.accountEntity.category === "7" && ((this.accountEntity.secondCategory || "") === "1" || (this.accountEntity.secondCategory || "") === "3")
			  ) {
			    allowRealname_({iccidOrPhone:iccidOrPhone}).then((res)=>{
			        this._handleResponse(res)
			    })
			  } else {
			    messageBox(this, "非CTBoss移动卡，不支持此操作！")
			  }
			},
			_isRealName(){  //实名状态
				isRealName({iccidOrPhone:this.data.iccidMark}).then((res)=>{
					if (res.isRealName == false){
						this.isReal = 2
					}else if (res.isRealName == true){
						this.isReal = 1
					}
				})
			},
			whiteNubmerSet() {//白名单设置
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
			    return ; 
			}
			//   if(this.accountEntity.category==="7"&& (this.accountEntity.secondCategory||"")==="2") {
			//       // var iccidOrPhone = $("#iccidOrPhone").val();
			//       // selections = [{"iccidMark": iccidOrPhone}]
			
			//       // parent.parent.addTab("whitenumber_list","白名单设置",'cardBusiness/white_number_list.html?1=1&callbackFunction=reloadPageData&targetFrame='+frameId+'&iccidMark='+iccidOrPhone,"activity")
			//       // this.$router.push("")
			//       alert('跳转白名单设置 没看到现象 没做')
			//   }
			  	if(!!this.accountEntity.hasVoice){
        //        this.$router.push({
        //           path: '/historyRecord/voiceWhitelist',
        //           query: {
        //               iccid: this.iccidMark
        //           }
        //   })
		this.dialogWhiteVoice = true
      } else {
			      messageBox(this,"该卡不支持此操作！")
			  }
			},
			modifyFlow() {  //增减流量
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
			    return ; 
			}
			  this.dialogModifyFlow = true
			},
			noLoopcard() {  //不轮询网卡
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
			    return ; 
			}
			  if (this.res.noLoop) {
			    messageBox(this, "该卡已经是不轮询状态。");
			    return
			  }
			  confirmBox(this, "添加成功后该卡将不执行停复机操作同时会出现流量，状态不更新等状态，请谨慎操作！",'操作提示',this._noLoopcard_)
			  
			},
			tamperRecovery() { //窜卡复机
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
			    return ; 
			}
			  tamperRecovery_(this.formInline).then((res) => {
			    this._handleResponse(res)
			  });
			},
			singleLimit() { //单卡限速
			},
			orderRecord() {  //往期订单
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
			    return ; 
			}
			  this.$router.push({
			    path: "/packageRecharge/orderRecord",
			    query: {
			      iccidMark: this.iccidMark
			    },
			  })
			},
			stopRecoveryRecord() { ///停复机记录
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
			    return ; 
			}
			  this.$router.push({
			    path: "/cardManagement/tingfujiRecord",
			    query: {
			      iccidMark: this.iccidMark,
			    },
			  })
			},
			deviceCardBound() {
			  //机卡绑定
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  let params ={
					iccidMark1: this.formInline.iccidMark1,
					phone1: this.formInline.phone1,
					iccidOrPhone: this.formInline.iccidOrPhone,
					setMealId: this.formInline.setMealId,
					pckCode: this.formInline.pckCode,
					cardType: this.formInline.cardType,
					isChild: this.formInline.isChild,
					codeChild: this.formInline.codeChild,
					status: this.formInline.status,
					type: this.formInline.type,
				}
				 Object.assign(params, {
			    iccidMark: this.data.iccidMark,
			  });
			  deviceCardBound_(params).then((res) => {
			    this._handleResponse(res);
			  });
			},
			toNewCard() {
			  //转新卡
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  this.dialogToNewCard = true;
			},
			setThreshold() {
			  //更新阈值
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  setThreshold_(this.formInline).then((res) => {
			    this._handleResponse(res);
			  });
			},
			updateExpire() {
			  //更改过期时间
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  this.dialogUpdateExpire = true;
			  this.$refs.childUpdateExpire._listCardLife()
			},
			flowDetail() {
			  //流量详单
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  this.dialogFlowDetail = true;
			  this.$refs.childFlowDetail._listFlowDetail()
			},
			limitStopRecovery() {
			  //达量断网复机
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  limitStopRecovery_(this.formInline).then((res) => {
			    this._handleResponse(res);
			  });
			},
			limitStop() {
			  //达量断网
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  if (this.data.category === '1' || this.data.category === '2') {
			    this.dialogLimitStop = true;
			  } else {
			    messageBox(this, "该卡不支持此操作！");
			  }
			},
			cardStop() {
			  //保号停机
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  cardStop_(this.formInline).then((res) => {
			    this._handleResponse(res);
			  });
			},
			cardRecovery() {
			  //保号复机
			let iccidOrPhone = this.formInline.iccidOrPhone
			if(!iccidOrPhone){
			    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
			    return ; 
			}
			  cardRecovery_(this.formInline).then((res) => {
			    this._handleResponse(res);
			  });
			},
			activate() {
			  //激活
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  activate_(this.formInline).then((res) => {
			    this._handleResponse(res);
			  });
			},
			compareResult(expireDate){
      let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
         let compareDate = "";
          let compareDate_unix = "";
          if (expireDate) {
            compareDate = expireDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {//当前时间大于等于过期时间   没有有效套餐
            return  false
          }
          return true
    },
			mealRecharge() {
			  //套餐充值
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
			  if (this.res.noRecharge) {
			    messageBox(this, "该卡暂不支持充值。");
			    return;
			  }
			  if (this.data.expireDate != "-"  && this.compareResult(this.data.expireDate)) {
			   /* confirmBox(
			      this,
			      "该卡已有套餐，确认是否继续充值?",
			      "操作提示",
			      this._mealRecharge
			    ); */
					 this.$confirm("该卡已有套餐，确认是否继续充值?", "操作提示", {
					          confirmButtonText: '确定',
					          cancelButtonText: '取消',
					          type: 'warning'
					        }).then(() => {
					         this._mealRecharge()
					        }).catch(() => {
					          this.$message({
					            type: 'info',
					            message: '已取消充值'
					          });          
					        });
					
			  }else{
					this._mealRecharge()
				}
				
			},
			jkcb(){//机卡重绑
				let iccidOrPhone = this.formInline.iccidOrPhone
				if(!iccidOrPhone){
				    messageBox(this,"请输入ICCID卡号或接入号，请点击搜索查询。");
				    return ; 
				}
				let params ={
					iccidMark1: this.formInline.iccidMark1,
					phone1: this.formInline.phone1,
					iccidOrPhone: this.formInline.iccidOrPhone,
					setMealId: this.formInline.setMealId,
					pckCode: this.formInline.pckCode,
					cardType: this.formInline.cardType,
					isChild: this.formInline.isChild,
					codeChild: this.formInline.codeChild,
					status: this.formInline.status,
					type: this.formInline.type,
				}
				 Object.assign(params, {
				  iccidMark: this.data.iccidMark,
				});
				deviceCardBound_(params).then(responseResult=>{
					if (responseResult.app_result_key === "0") {
					    messageBox(this,"机卡重绑成功。")
					} else {
					    messageBox(this,"机卡重绑失败："+responseResult.system_result_message_key);
					}
				})
			},
			
			_noLoopcard_(){
			  noLoopcard_(this.formInline).then((res) => {
			    this._handleResponse(res);
			  });
			},
			_findCardInfo(params) {
			  //let params = this.$route.query;
			  findCardInfo(params).then((res) => {
			    this.res = res;
			    this.data = res.entity;
			    this.accountEntity = res.accountEntity;
			    this.cardLiftList = res.cardLiftList;
			    this.nextCardLifeList = res.nextCardLifeList;
			    this.tianDjbCardLifes = res.tianDjbCardLifes;
					this.gparams
			  });
			},
			_mealRecharge() {
			  loading.show();
			  let params = {};
			  params.iccidOrPhone = this.data.iccidMark;
			  params.setMealId = this.data.setMealId;
			  params.isChild = this.data.isChild;
			  params.codeChild = this.data.codeChild;
			  getPackageAll(params).then((res) => {
			    this.dialogAddPackage = true;
			    this.addPackageData = res;
			  });
			},
			_handleResponse(res) {
			  if (res.app_result_key == 1) {
			    messageBox(this, res.app_result_message_key);
			    return;
			  }else{
			    let msg= res.system_result_message_key
			    if(!msg){
			        msg = "操作成功"
			    }
			    messageBox(this,msg);
			  }
			  this._findCardInfo(this.formInline);
			},
			
				
		},
		components: {
		  AddPackage,
		  LimitStop,
		  FlowDetail,
		  UpdateExpire,
		  TonewCard,
		  ChangeFlow,
		   WhiteVoice,
		},
	}
</script>
<style>
ul {
  list-style: none;
}
.singleCardQuery {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
}
.singleCardQuery .card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(7, 17, 27, 0.5);
}
.singleCardQuery .card-icon i {
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
.singleCardQuery .group {
  padding: 25px;
  background: #fff;
}
/* .singleCardQuery .group table {
  border-left: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
} */
.singleCardQuery .group table td {
  height: 32px;
  line-height: 32px;
  /* border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7; */
  text-align: left;
}
.singleCardQuery .group .title {
  height: 32px;
  line-height: 32px;
}
.singleCardQuery ul > li {
  float: left;
  width: 125px;
  text-align: center;
  margin-right: 20px;
  margin-top: 10px;
}
</style>
