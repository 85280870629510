import request from './request.js'
// 活动管理
// 活动管理表
export function activityList(params) {
    return request({
        url: 'activity/grid.do?',
        method: 'post',
        data: params
    })
}

// 赠送套餐网卡表
export function giftPackageCard(params) {
    return request({
        url: 'meal/giftmealcard/list.do?',
        method: 'post',
        data: params
    })
}

// 删除by Id
export function giftPackageDeleteById(params) {
    return request({
        url: 'meal/giftmealcard/deleteById.do?',
        method: 'post',
        data: params
    })
}


// 套餐列表
export function mealList(params) {
    return request({
        url: 'meal/giftmealcard/findMealList.do?',
        method: 'post',
        data: params
    })
}

// 批量导入赠送套餐网卡
export function importGiftMealByExcel(params) {
    return request ({
        url:'meal/giftmealcard/importExcelSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}


export function deleteGiftMealByExcel(params) {
    return request ({
        url:'meal/giftmealcard/deleteExcelSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

