<template>
	<div  class="carrierPriceImport fadeInRight">
		<div class="title-box clearfix">
			 <span class="m-title">运营商单价设置</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<!-- <el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search"  class="themed-button">搜索</el-button>
				</el-form-item> -->
				
				<el-form-item>
					<el-button type="primary" @click="addCarrierPrice" icon="el-icon-plus"  class="themed-button">新增</el-button>
				</el-form-item>
			
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;"  border  ref="table" :max-height="maxTableHeight">
				<el-table-column prop="accountName" label="运营商" >
				</el-table-column>
				<el-table-column prop="agentName" label="代理商" >
				</el-table-column>
				<el-table-column prop="unprice" label="单价(元)">
				</el-table-column>
				<el-table-column prop="remark" label="备注">
				</el-table-column>
				<el-table-column prop="creatTime" label="创建时间">
				</el-table-column>
				<el-table-column label="操作" width="220px">
						<template v-slot:default="scope">
						<el-button type="info" class="themed-button"  @click='updateAgentRecord(scope.row)'>编辑</el-button>
						<el-button type="danger" class="themed-button" @click='deleteAgentRecord(scope.row.id)'>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
		
		<el-dialog title="修改运营商单价" :visible.sync="dialogFormVisible" v-if="dialogFormVisible">
			<el-form :model="form" :rules="rules" ref="ruleForm">
				<el-form-item label="单价(元):" :label-width="formLabelWidth" prop="unprice">
					<el-input v-model="form.unprice" autocomplete="off" ></el-input>
				</el-form-item>
			  <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input v-model="form.remark" autocomplete="off"></el-input>
            </el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="baseFormSubmit('ruleForm')" class="themed-button">确 定</el-button>
				<el-button type="info" @click="cancelDialog('ruleForm')" class="themed-button">取 消</el-button>
			</div>
		</el-dialog>
        	<el-dialog title="新增运营商单价" :visible.sync="dialogFormAddVisible" v-if="dialogFormAddVisible">
			<el-form :model="formAdd"  ref="ruleFormAdd">
                   <el-form-item label="运营商：" :label-width="formLabelWidth" required>
                                <el-select v-model="formAdd.accountId"  collapse-tags clearable filterable placeholder="请选择运营商" style="width:150px" >
                                    <el-option v-for="(item,index) in accountList" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>

                            <el-form-item label="代理商：" :label-width="formLabelWidth" required>
                                <el-select v-model="formAdd.agentId"  collapse-tags clearable filterable placeholder="请选择代理商" style="width:150px" >
                                    <el-option v-for="(item,index) in agentIdList" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>
				<el-form-item label="单价(元):" :label-width="formLabelWidth" prop="unprice">
					<el-input v-model="formAdd.unprice" autocomplete="off" ></el-input>
				</el-form-item>
			  <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input v-model="formAdd.remark" autocomplete="off"></el-input>
            </el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addSubmit()" class="themed-button">确 定</el-button>
				<el-button type="info" @click="cancelAddDialog()" class="themed-button">取 消</el-button>
			</div>
		</el-dialog>
		
		
	</div>


</template>

<script>
	import onresize from "@/mixins/resize.js"
	import {mapGetters} from 'vuex'
	import {getCarrierPriceList,deleteAccountPrice,updateAccountPrice,addAccountPrice} from "@/api/systemManagement.js"
    import {searchParamCheck} from "@/api/operationalStatistics/operationalStatistics.js";
    import {messageBox} from '@/utils/common.js'
	export default {
		name:'carrierPriceImport',
		mixins: [onresize],
		data() {
          var validateInterestRate = (rule, value, callback) => {
				if (value === "") {
					return callback(new Error("请输入运营商单价！"))
				}
				var interestRate = parseFloat(value);
				if (isNaN(interestRate) ) {
					return callback(new Error("请输入正确的单价"))
				}
				callback()
			}
			return {
				page: 1,
				pageSize: 15,
				total: 0,
			   tabledata:[],
               dialogFormVisible:false,
               dialogFormAddVisible:false,
               formLabelWidth :"100px",
               form:{
                unprice:"",
                remark:"",
               },
               formAdd:{
               accountId:"",
               agentId:"",
                unprice:"",
                remark:"",
               },
                editId:"",
                rules:{
                  unprice: [{
						required: true,
						validator: validateInterestRate,
						trigger: 'blur'
					}, ] 
                },
                accountList:[],
                agentIdList:[],
			}
		},
		created() {
			this.getList()
            this.getAccountAgentList()
		},
		
		methods: {
      getAccountAgentList(){
             searchParamCheck().then(res=>{
                    this.agentIdList = res.agents
                    this.accountList = res.account
                })
      },
		    getList(){
            let params = { pageSize: this.pageSize, pageNo: this.page,}
            getCarrierPriceList(params).then(res=>{
             this.tabledata = res.rows;
             this.total = res.count;
            })
            },
			handleSizeChange(size) {
				this.pageSize = size
				this.getList()
			},
			onPageChange(page) {
				this.page = page
				this.getList()
			},
			// searchData() {
            //      this.page = 1
			// 	this.getList()
			// },
            deleteAgentRecord(id){
        this.$confirm('确定要删除吗？', '消息提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params = {id}
					deleteAccountPrice(params).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						})
						this.getList()
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
            },
            updateAgentRecord(row){
                this.dialogFormVisible = true
            //  let params ={id:row.id}
             this.form.unprice = row.unprice
             this.form.remark = row.remark 
             this.editId = row.id
            },
            baseFormSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					console.log(valid)
					if (valid) {
						this.dialogFormVisible = false
                        Object.assign(this.form,{id:this.editId})
						updateAccountPrice(this.form).then(responseResult => {
							if (responseResult.slide_msg_key == 1) {
								this.$message({
									showClose: true,
									message: responseResult.slide_msg_message_key,
									type: 'warning',
								})
							}
							this.getList()
						})
					} else {
						return false
					}
				});

			},
           cancelDialog(formName) {
				this.dialogFormVisible = false
				this.$refs[formName].clearValidate(["unprice"])
			},
            addCarrierPrice(){
                this.dialogFormAddVisible = true
            },
            addSubmit(){
                if(this.formAdd.accountId==""){
                   messageBox(this,'请选择运营商！')
                return 
                }
                if(this.formAdd.agentId==""){
                      messageBox(this,'请选择代理商！')
                return 
                }
                if (this.formAdd.unprice == "") {
                     messageBox(this,'请输入运营商单价！')
                return 
				}
				var interestRate = parseFloat(this.formAdd.unprice);
				if (isNaN(interestRate) ) {
                      messageBox(this,'请输入正确的单价！')
                return 
				}
             addAccountPrice(this.formAdd).then(res=>{
                if (res.slide_msg_key == 1) {
								this.$message({
									showClose: true,
									message: res.slide_msg_message_key,
									type: 'warning',
								})
							}
                     this.dialogFormAddVisible = false
                     this.getList()
           })
             
            },
            cancelAddDialog(){
                this.dialogFormAddVisible = false
                 this.formAdd.unprice = ""
                 this.formAdd.remark = "" 
                  this.formAdd.accountId = ""
                 this.formAdd.agentId = ""
            }
			
		
			

			
		
		}
	}
</script>

<style>
	.right_agent {
		margin-right: 8px;
	}
	.carrierPriceImport  .el-form-item__label {vertical-align: top;}
	.carrierPriceImport .el-input--small{width:80%;}

	/* .el-input {
		width: 297px;
	} */
</style>
