<template>
<div class="bind-card  fadeInRight">
        <div class="title-box clearfix">
        <span class="m-title">机卡绑定</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="IMEI库编号">
                <el-select filterable v-model="bindCardForm.imeiBankCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in imeiBankCodeList" :label="item.imeiBankCode" :value="item.imeiBankCode" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="卡库编号">
                <el-select filterable v-model="bindCardForm.cardBankCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in cardBankCodeList" :label="item.cardBankCode" :value="item.cardBankCode" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
					<el-input v-model="bindCardForm.imei"  placeholder="IMEI"></el-input>
			</el-form-item>
            
            <el-form-item>
					<el-input v-model="bindCardForm.iccid"  placeholder="ICCID" class="iccid-width"></el-input>
			</el-form-item>
            <el-form-item>
					<el-input v-model="bindCardForm.chipid"  placeholder="随机码" class="iccid-width"></el-input>
			</el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>

            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>

        </div>

        <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="IMEI" prop="imei" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="随机码" prop="chipid" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="IMEI库编号" prop="imeiBankCode" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="ICCID" prop="iccid" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="接入号" prop="phone" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="入库编号" prop="cardBankCode" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="产品名称" prop="productName" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="绑定时间" prop="createTime" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="操作" min-width="120">
                <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="danger" @click="deleteById(scope.row.id)">解绑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination :current-page.sync="page"  :page-sizes="[5, 10, 15, 50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
    </div>


    </div>
</template>
<script>
import resize from '@/mixins/resize.js'
import {imeiBankCodeList,cardBankCodeList} from '@/api/meCardBindManagement/imeiBindHistory.js'
import {bindCardList,exportBindImeiCard,deleteBindCard,findCardById} from '@/api/meCardBindManagement/bindCard.js'
import {messageBox,confirmBox } from '@/utils/common.js'
export default {
    // name:'jiKaBind',
    mixins:[resize],
    data(){
        return{
            imeiBankCodeList:[],
            cardBankCodeList:[],
            bindCardForm:{
                imeiBankCode:'',
                cardBankCode:'',
                imei:'',
                iccid:'',
                chipid:''
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            notice:'',
        }
    },
    created() {
       this.getImeiBankCodeList() 
       this.getCardBankCodeList()
       this.getBindCardList()
    },
    methods: {
        getImeiBankCodeList(){
            imeiBankCodeList().then(res=>{
                this.imeiBankCodeList = res
            })
        },
        getCardBankCodeList(){
            cardBankCodeList().then(res=>{
                this.cardBankCodeList = res
            })
        },
        getBindCardList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.bindCardForm,params)
            bindCardList(this.bindCardForm).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        deleteById(id){
            findCardById({id}).then(res=>{
                this.notice = `IMEI:${res.entity.imei}</br>
                ICCID:${res.entity.iccid}</br>
                本月解绑次数:${res.entity.jsonUpdateFlag}</br>
                请确认是否继续？`
            confirmBox(this,this.notice,'消息提示',()=>{
                deleteBindCard({id}).then(() => {
						this.$message({
							type: 'success',
							message: '解绑成功!'
						})
						this.getBindCardList()
				})
            })
            })
            
        },
        searchTable(){
            this.page = 1 
            this.getBindCardList()
        },
        onPageChange(page){
			this.page = page
			this.getBindCardList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getBindCardList()
        },
        exportExcel(){
             exportBindImeiCard(this.bindCardForm).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
         },
    },
}
</script>
<style>
    
</style>