<template>
  <div class="addTocAgent">
    <el-form :model="formAddTOC" :rules="rulesAddTOC" ref="ruleFormAddTOC">
      <el-form-item
        label="业务类型："
        v-show="typeIsShow"
        :label-width="formLabelWidth"
      >
        <el-select v-model="formAddTOC.type" @change="typeChange">
          <!-- <el-option
				     v-for="(item,index) in options"
				     :key="index"
				     :label="item.label"
				     :value="item.value">
				   </el-option> -->
          <el-option
            v-for="(item, index) in w_agent_business_type"
            :key="index"
            :label="item.groupName"
            :value="item.dictValue"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="istoc">
        <el-form-item
          label="代理商名："
          :label-width="formLabelWidth"
          prop="name"
        >
          <el-input v-model="formAddTOC.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="登录账号："
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input v-model="formAddTOC.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="初始密码："
          :label-width="formLabelWidth"
          prop="password"
        >
          <el-input v-model="formAddTOC.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="手机号(选填)："
          :label-width="formLabelWidth"
          prop="checkPhone"
        >
          <el-input
            v-model="formAddTOC.checkPhone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="超额流量单价(元/G)："
          :label-width="formLabelWidth"
          v-show="preAgentType"
          prop="flowPrice"
        >
          <el-input
            v-model.trim="formAddTOC.flowPrice"
            autocomplete="off"
          ></el-input>
          <i
            class="el-icon-question"
            style="
              color: blue;
              font-size: 18px;
              vertical-align: middle;
              margin-left: 10px;
            "
            title="当配置超额流量单价，套餐流量用完后按单价扣余额，直至余额扣完停机。若不使用扣余额，留空。"
          ></i>
        </el-form-item>
        <el-form-item
          label="提现银行（选填）："
          :label-width="formLabelWidth"
          v-show="bankIsShow"
          prop="bank"
        >
          <el-input v-model="formAddTOC.bank" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="提现账号（选填）："
          :label-width="formLabelWidth"
          v-show="bankCodeIsShow"
          prop="bankCode"
        >
          <el-input v-model="formAddTOC.bankCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="收款人（选填）："
          :label-width="formLabelWidth"
          v-show="bankReceiverIsShow"
          prop="bankReceiver"
        >
          <el-input
            v-model="formAddTOC.bankReceiver"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="返点比例：" :label-width="formLabelWidth" prop="interestRate">
				<el-input v-model="formAddTOC.interestRate" autocomplete="off" type="number" min="0.0" step="0.1" max="1"></el-input>
			</el-form-item> -->
        <el-form-item
          label="佣金单价："
          :label-width="formLabelWidth"
          v-show="commissionIsShow"
          prop="commission"
        >
          <el-input v-model="formAddTOC.commission"></el-input>
        </el-form-item>
        <el-form-item
          label="支付宝账号:"
          :label-width="formLabelWidth"
          prop="zhifubao"
        >
          <el-input v-model="formAddTOC.zhifubao" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="支付宝收款人:"
          :label-width="formLabelWidth"
          prop="zhifubaoReceiver"
        >
          <el-input
            v-model="formAddTOC.zhifubaoReceiver"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="代理商类型："
          :label-width="formLabelWidth"
          v-show="showTypeOne && currentRole == 0"
          prop="agentType"
        >
          <el-select v-model="formAddTOC.agentType" @change="typeChangeOne">
            <el-option label="普通类型" value="1"></el-option>
            <el-option label="VIP代理商" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="结算方式："
          :label-width="formLabelWidth"
          v-show="showTypeOne && currentRole == 0 && showVipAgent"
          prop="settlType"
        >
          <el-select v-model="formAddTOC.settlType">
            <el-option label="套餐结算" value="1"></el-option>
            <el-option label="按量结算" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="收款方式："
          :label-width="formLabelWidth"
          v-show="showTypeOne && currentRole == 0 && showVipAgent"
          prop="collectType"
        >
          <el-select v-model="formAddTOC.collectType">
            <el-option
              label="平台收款"
              value="1"
              v-if="formAddTOC.settlType == 1"
            ></el-option>
            <el-option label="代理商收款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="返利方式："
          :label-width="formLabelWidth"
          prop="rebateType"
          v-if="currentRole == 0"
        >
          <el-select v-model="formAddTOC.rebateType" @change="rebateTypeChange">
            <el-option
              v-for="item in accountArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div v-if="rebateTypeTip" style="color: #f00">
            <div v-if="rebateTypeShow">未设置返利方式将会影响后期提现。</div>
            <div v-else>
              当前账号下没有配置账号提现限制，如需配置，请打开菜单
              “代理商管理》返利设置”中进行配置。
            </div>
          </div>
        </el-form-item>
       <el-form-item
          label="邮箱："
          :label-width="formLabelWidth"
          prop="email"
        >
          <el-input v-model="formAddTOC.email"></el-input>
        </el-form-item>
        <el-form-item
          label="备注："
          :label-width="formLabelWidth"
          prop="remark"
        >
          <el-input v-model="formAddTOC.remark"></el-input>
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="istob">
        <el-form-item
          label="联系电话(账号):"
          :label-width="formLabelWidth"
          prop="tobphone"
        >
          <el-input
            v-model="formAddTOC.tobphone"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="密码:"
          :label-width="formLabelWidth"
          prop="tobpassword"
        >
          <el-input placeholder="请输入密码" v-model="formAddTOC.tobpassword">
          </el-input>
        </el-form-item>
        <el-form-item
          label="公司名称:"
          :label-width="formLabelWidth"
          prop="tobname"
        >
          <el-input
            v-model="formAddTOC.tobname"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="行业信息:"
          :label-width="formLabelWidth"
          prop="professionalInfo"
        >
          <el-cascader
            v-model="formAddTOC.professionalInfo"
            :options="options"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="联系人姓名:"
          :label-width="formLabelWidth"
          prop="contact"
        >
          <el-input
            v-model="formAddTOC.contact"
            placeholder="请输入联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="邀请人:" :label-width="formLabelWidth">
          <el-input
            v-model="formAddTOC.inviter"
            placeholder="请输入邀请人选填"
          ></el-input>
        </el-form-item>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="formAddTOCSubmit('ruleFormAddTOC')"
        class="themed-button"
         v-preventReClick='2000'
          :disabled="isDisable"
        >确 定</el-button
      >
      <el-button
        type="primary"
        @click="formAddTOCReset('ruleFormAddTOC')"
        class="themed-button"
        >重置</el-button
      >
      <el-button
        type="info"
        @click="cancelDialogformAddTOC('ruleFormAddTOC')"
        class="themed-button"
        >取 消</el-button
      >
    </div>
  </div>
</template>

<script>
import loading from '@/utils/Loading.js'
import {
  getAgentType,
  addTocAgentSubmit,
  getBussinessInfoOne,
  registerTob,
} from "@/api/agentManagement.js";
import { mapGetters } from "vuex";
import dictGroup from "@/mixins/dictGroup.js";
import { messageBox } from "@/utils/common.js";
export default {
  name: "addTocAgent",
  mixins: [dictGroup],
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请输入初始密码！"));
      } else {
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请输入代理商名！"));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请输入联系号码！"));
      } else {
        callback();
      }
    };
    var validateRebateType = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请选择返利方式！"));
      } else {
        callback();
      }
    };
    var validateProfessional = (rule, value, callback) => {
      console.log(value);
      if (value.length == "") {
        callback(new Error("请选择行业信息"));
      } else {
        callback();
      }
    };
    return {
      // options:[{label:"toc代理商",value:"1"},{label:"tob代理商",value:"2"},{label:"预存话费代理商",value:"3"}]	,
      formAddTOC: {
        id: "",
        balanceMoney: 0,
        totalMoney: 0,
        fatherAgentId: 0,
        type: "",
        name: "",
        phone: "",
        password: "",
        bank: "",
        bankCode: "",
        bankReceiver: "",
        commission: "",
        agentType: "",
        settlType: "",
        collectType: "",
        flowPrice: "",
        remark: "",
        rebateType: "",
        checkPhone: "",
        zhifubao: "",
        zhifubaoReceiver: "",
        tobphone: "",
        tobpassword: "",
        tobname: "",
        professionalInfo: "",
        contact: "",
        inviter: "",
        email:"",
      },
      showTypeOne: true,
      typeIsShow: true,
      commissionIsShow: true,
      bankIsShow: true,
      bankCodeIsShow: true,
      bankReceiverIsShow: true,
      preAgentType: false,
      rulesAddTOC: {
        name: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: "blur",
          },
        ],
        rebateType: [
          {
            required: true,
            validator: validateRebateType,
            trigger: "change",
          },
        ],

        tobphone: [{ validator: validatePhone, trigger: "blur" }],
        tobpassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        tobname: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        professionalInfo: [
          {
            validator: validateProfessional,
            trigger: "blur",
          },
        ],
        contact: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
      },
      formLabelWidth: "150px",
      showVipAgent: false,
      accountArr: [
        { id: "0", name: "-未设置-" },
        { id: "1", name: "普通" },
        { id: "2", name: "秒返" },
      ],
      rebateTypeShow: false,
      rebateTypeTip: false,
      istoc: false,
      istob: false,
      options: [],
      isDisable:false,
    };
  },
  created() {
    this.showItem();
    this.getAgentType();
    this.getProfessionalInfo();
    if(this.isCaptal=='1'){//独立部署 类型默认为空，其他情况下 默认值从接口获取type值;
     this.formAddTOC.type=""
    }else{
      if(this.curType == 0){
       this.formAddTOC.type = "1"
      }else{
       this.formAddTOC.type = this.curType
      }
      
    }
    if (this.formAddTOC.type == "1") {
      this.istoc = true;
      this.istob = false;
    } else if (this.formAddTOC.type == "2") {
      this.istoc = false;
      this.istob = true;
    } else if (this.formAddTOC.type == "3") {
      this.istoc = true;
      this.istob = false;
    }
  },
  computed: {
    ...mapGetters(["currentRole","isCaptal","curType"]),
  },
  methods: {
    getProfessionalInfo() {
      //获取行业信息  给this.options 赋值；
      getBussinessInfoOne().then((res) => {
        console.log(res);
        this.options = res;
      });
    },
    showItem() {
      this.commissionIsShow = false;
    },
    rebateTypeChange(val) {
      if (val == "") {
        this.rebateTypeTip = false;
      } else {
        this.rebateTypeTip = true;
      }
      if (val == "0") {
        this.rebateTypeShow = true;
      } else {
        this.rebateTypeShow = false;
      }
    },
    typeChange(value) {
      if (value == "1") {
        this.formAddTOC.name = "";
        this.formAddTOC.phone = "";
        this.formAddTOC.password = "";
        this.commissionIsShow = false;
        this.bankIsShow = true;
        this.bankCodeIsShow = true;
        this.bankReceiverIsShow = true;
        this.showTypeOne = true;
        this.preAgentType = false;
        this.istoc = true;
        this.istob = false;
      } else if (value == "2") {
        this.commissionIsShow = false;
        this.bankIsShow = false;
        this.bankCodeIsShow = false;
        this.bankReceiverIsShow = false;
        this.showTypeOne = false;
        this.preAgentType = false;
        this.istoc = false;
        this.istob = true;
      } else if (value == "3") {
        this.formAddTOC.name = "";
        this.formAddTOC.phone = "";
        this.formAddTOC.password = "";
        this.commissionIsShow = false; //预存话费代理商显示佣金单价
        this.bankIsShow = false;
        this.bankCodeIsShow = false;
        this.bankReceiverIsShow = false;
        this.showTypeOne = false;
        this.preAgentType = true;
        this.istoc = true;
        this.istob = false;
      }
    },
    typeChangeOne(value) {
      if (value == "1") {
        this.showVipAgent = false;
      } else if (value == "2") {
        this.showVipAgent = true;
      }
    },
    getAgentType() {
      getAgentType(this.formAddTOC).then((res) => {
        if (res.agentType == "1") {
          this.typeIsShow = false;
          this.istoc = true;
          this.istob = false;
        }
        if (res.agentType == "4") {
          this.typeIsShow = false;
          this.istoc = true;
          this.istob = false;
        }
      });
    },
    formAddTOCSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formAddTOC.checkPhone) {
            let r = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!r.test(this.formAddTOC.checkPhone)) {
              messageBox(this, "手机号格式不对");
              return;
            }
          }
          if(this.formAddTOC.email){
         let r = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
           
                if(!r.test(this.formAddTOC.email)){
                    messageBox(this,'请输入正确的邮箱格式')
                    return 
                }
          }
          
          this.$confirm(
            "请确认账户类型是否符合要求，一旦创建成功将无法修改？",
            "消息提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
             this.isDisable = true
              if (this.istoc) {
                addTocAgentSubmit(this.formAddTOC).then((responseResult) => {
                   this.isDisable = false
                  if (responseResult.slide_msg_key == 1) {
                    this.$message({
                      showClose: true,
                      message: responseResult.slide_msg_message_key,
                      type: "warning",
                    });
                  }

                  this.$emit("refresh");
                  this.$emit("close");
                }).catch(err=>{
                   this.isDisable = false
                })
              }
              if (this.istob) {
                this.formAddTOC.professionalInfo = JSON.stringify(
                  this.formAddTOC.professionalInfo
                );
                this.formAddTOC.phone = this.formAddTOC.tobphone;
                this.formAddTOC.password = this.formAddTOC.tobpassword;
                this.formAddTOC.name = this.formAddTOC.tobname;
                this.formAddTOC.unVerifyFlag = 1;
                registerTob(this.formAddTOC).then((responseResult) => {
                 this.isDisable = false
                  if (responseResult.slide_msg_key == 1) {
                    this.$message({
                      showClose: true,
                      message: responseResult.slide_msg_message_key,
                      type: "warning",
                    });
                  }

                  this.$emit("refresh");
                  this.$emit("close");
                }).catch(err=>{
                    this.isDisable = false
                })
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消新增",
              });
            });
        } else {
          return false;
        }
      });
    },
    formAddTOCReset(formName) {
      this.$refs[formName].resetFields();
      this.formAddTOC.bank = "";
      this.formAddTOC.bankCode = "";
      this.formAddTOC.bankReceiver = "";
      this.formAddTOC.agentType = "";
      this.formAddTOC.settlType = "";
      this.formAddTOC.collectType = "";
      this.formAddTOC.flowPrice = "";
      this.formAddTOC.remark = "";
      this.formAddTOC.rebateType = "";
      this.formAddTOC.checkPhone = "";
      this.formAddTOC.zhifubao = "";
      this.formAddTOC.zhifubaoReceiver = "";
      this.formAddTOC.email = "";
      this.rebateTypeTip = false;
    },
    cancelDialogformAddTOC(formName) {
      this.$emit("close");
      //this.$refs[formName].resetFields()
      // this.formAddTOC.bank = ""
      // this.formAddTOC.bankCode = ""
      // this.formAddTOC.bankReceiver = ""
    },
  },
};
</script>

<style>
.addTocAgent .dialog-footer {
  text-align: right;
}
.addTocAgent .el-input--small {
  width: 80%;
}
</style>
