/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from '../request.js'

export function getApiCardList(params){
    return request({
        url: 'apiCard/list.do?',
        method: 'post',
        data:params
    })
}
export function deleteApiCardList(params) {//删除api卡列表数据
    return request({
        url: '/apiCard/delete.do?',
        method: 'post',
        data: params
    })
}

export function updateApiCardList(params){ //编辑api卡列表数据
    return request({
        url: '/apiCard/update.do?',
        method: 'post',
        data: params
    })
}


export function apiCardImportBatch(params){
    return request({
        url: 'apiCard/importBatch.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function apiCardDeleteBatch(params){
    return request({
        url: 'apiCard/deleteBatch.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function apiCardUpdateRemarkBatch(params){
    return request({
        url: 'apiCard/updateRemarkBatch.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function exportExcelAsyncApi(params){  //导出表格
    return request({
        url: 'apiCard/exportExcelAsync.do?',
        method: 'post',
        data:params
    })
}







