<template>
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
        <el-col :span="20" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
              <el-form-item label="" :label-width="formLabelWidth" prop="gyAgentId"  v-if="currentRole==0">
                <treeselect
                  v-model="queryParams.gyAgentId"
                  :options="agentOptions"
                  :normalizer="normalizer"
                  :show-count="true"
                  placeholder="请选择代理商"
                />
              </el-form-item>
           
            <el-form-item prop="gyAgentId" v-else>
            <el-select v-model="queryParams.gyAgentId" placeholder="请选择代理商">
             <el-option
             v-for="item in Nooptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            >  
            </el-option>
            </el-select>
          </el-form-item>
              <el-form-item style="margin-right: 40px">
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  @click="screenDate"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
              <!-- <el-form-item label="" :label-width="formLabelWidth" prop="imei">
                <el-input
                  placeholder="设备号"
                  v-model="queryParams.imei"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item> -->
            </el-form>
          </div></el-col
        >
        <!-- <el-col :span="4" :xs="24"      
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top: 15px"
            >
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"  
                  class="themed-button"
                  @click="add"
                  >新增</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        > -->
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column prop="imei" label="设备IMEI" min-width="180">
        </el-table-column>
        <el-table-column prop="type" label="任务名称" min-width="180">
        </el-table-column>
       <el-table-column prop="gyAgentId" label="代理商ID" min-width="180">
        </el-table-column>
        <el-table-column prop="gyAgentName" label="代理商名称" min-width="120">
        </el-table-column>
        <el-table-column prop="state" label="执行状态" min-width="180">
          <template slot-scope="scope">
          <el-tag :type="scope.row.state == '1' ? 'primary' :scope.row.state == '2' ? 'warning' :scope.row.state == '3' ? 'success':'danger' ">{{ scope.row.state == "1" ? "已保存" :scope.row.state == "2" ? "已下发":scope.row.state == "3" ? "执行成功":"执行失败"  }}</el-tag>
        </template>
        </el-table-column>
        <el-table-column prop="createTime" label="任务下发时间" min-width="180">
        </el-table-column>
        <el-table-column prop="createBy" label="操作人" min-width="180">
        </el-table-column>
        <!-- <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>


    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { agentGetAllAgent } from "@/api/system/user";
import {getAgentType} from "@/api/agentManagement.js"
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  operationList
} from "@/api/taskList/taskList.js";
import {mapGetters} from 'vuex'
export default {
  mixins:[resize],
  components: { Treeselect },
  data() {
    return {
      formInline: {
        spec: "",
        material: "",
      },
      addForm: {
        radioLable: 0,
        imeiArray: "",
        gyAgentId: undefined,
      },
      tableData: [],
      materialList: [],
      taskOptions: [
        {
          value: "setSsid",
          label: "设置SSID",
        },
        {
          value: "setPwd",
          label: "设置密码",
        },
        {
          value: "hideWifi",
          label: "隐藏wifi",
        },
        {
          value: "showWifi",
          label: "显示wifi",
        },
        {
          value: "reboot",
          label: "重启",
        },
        {
          value: "restore",
          label: "恢复出厂设置",
        },
        {
          value: "rateLimit",
          label: "限速",
        },
         {
          value: "setEncrypt",
          label: "加密方式",
        },
        {
          value: "switchSimMode",
          label: " 切卡",
        },
        {
          value: "setSimSole",
          label: "切卡为手动模式",
        },
         {
          value: "setInterval",
          label: "设置上报周期",
        },
         {
          value: "setMaxClients",
          label: "设置最大连接数",
        },
         {
          value: "setSimAuto",
          label: "设置切卡为自动模式",
        },
        {
          value: "setSimDisable",
          label: "禁用卡",
        },
        {
          value: "setSimEnable",
          label: "启用卡",
        },
      ],
      specList: [],
      agentOptions: [],
      Nooptions: [
        {
          value: "",
          label: "",
        },
      ],
      dialogFormVisible: false,
      queryParams: {
        imei: "",
        gyAgentId: undefined,
        gyAgentName: undefined,
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
    };
  },
    computed:{
         ...mapGetters(
             ['currentRole','agentTree']
         ),
     },
  created() {
    this.getDeviceList();
    this.getAllAgent();
    this.getAgentType()
  },
  methods: {
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams, params);
      operationList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total=res.total
        this.loading = false;
          this.tableData.forEach(ele=>{
          this.taskOptions.forEach(task=>{
           if(task.value==ele.type){
            ele.type=task.label
           }
          })
        })
      });
    },
    /** 代理商数据 */
    getAllAgent() {
      agentGetAllAgent().then((response) => {
        this.agentOptions = response;
      });
    },
    getAgentType(){
    getAgentType().then(res=>{
     this.Nooptions = [];
      this.Nooptions.push({
          label: res.agentThreeName,
          value: res.agentId,
        });
        console.log(this.Nooptions)
    })
    },
    /** 转换代理商数据结构 */
    normalizer(node) {
      if (node.children==null||node.children=='null'&&node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.agentId,
        label: node.agentName,
        children: node.children,
      };
    },
    /** 搜索 */
    screenDate() {
      this.page = 1;
      this.getDeviceList();
    },
    treeSelectChange(raw, instanceId) {
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        this.addForm.gyAgentId = raw.agentId;
        this.addForm.gyAgentName = raw.agentName;
      });
    },   
    /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
     handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 100px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>