<template>
  <div class="tingfujirecord">
    <el-table :data="tableData">
      <el-table-column prop="type" label="类型">
          <template slot-scope="scope">
              <span v-if="scope.row.type === 1">复机</span>
              <span v-else-if="scope.row.type === 2">停机</span>
          </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
              <span v-if="scope.row.status === 1">成功</span>
              <span v-else-if="scope.row.status === 2">失败</span>
          </template>
      </el-table-column>
      <el-table-column prop="createTime" label="操作时间" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="operationRemark" label="执行原因" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="createUsername" label="操作人"> </el-table-column>
      <el-table-column prop="opt" label="操作">
          <template slot-scope="scope">
              <a href="javascript:void(0)" @click="detail(scope.row.id)">详情</a>
          </template>
      </el-table-column>
    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        :total="total"
        layout="total, sizes, prev, pager,next, jumper">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">

import {stopOrStartRecord} from '@/api/cardManagement/cardBillDetail.js'

import {messageBox,confirmBox} from '@/utils/common.js'

export default {
    data(){
        return {
            pageNo:1,
            pageSize:15,
            total:0,
            tableData:[]
        }
    },
    mounted(){
        this._stopOrStartRecord()
    },
    methods:{
        detail(id){
            this.$router.push({
                path: '/cardManagement/tingfujiDetail',
                query: {
                    id:id
                }
            })
        },
        handleSizeChange(val){
            this.pageSize = val
        },
        handleCurrentChange(val){
            this.pageNo = val
        },
        _stopOrStartRecord(){
            let params = this.$route.query
            params.iccid = params.iccidMark
            params.pageNo = this.pageNo
            params.pageSize = this.pageSize
            stopOrStartRecord(params).then((res)=>{
                this.pageSize = res.pageSize
                this.pageNo = res.pageNo
                this.total = res.total
                this.tableData = res.rows
            })
        }
    }

};
</script>

<style>
</style>
