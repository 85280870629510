<template>
	<div class="main-sms-batch clearfix">
		<div class="left fadeInRight">
			<div class="title-box"><span class="m-title">发送短信</span></div>
			<div class="content-box clearfix themed-form">
				<div><span class="m-title">输入卡号</span></div>
				<div style="position: relative;">
					<el-input v-model="form.card" rows="4" type="textarea" style="width: 320px;" placeholder="请输入卡号（msisdn/iccid/imsi）,每行一个，最多50"  @input="inputCard"></el-input>
					<div><span class="card-count">{{count}}/50</span></div>
				</div>
				<div><span class="m-title">输入文件</span></div>
				<div>
					<input class="file-input" type="file" ref='fileInput'/>
					<div class="mt5">
						<span class="text-color" style="font-size: 12px;">仅支持.xls格式，示例文件：</span>
						<a class="theme-color" style="font-size: 12px;cursor: pointer;" @click="exportTemplate">导入文件请参考模板</a>
					</div>
				</div>
				<div><span class="m-title">短信内容</span></div>
				<div><el-input show-word-limit maxlength="160" placeholder="最多160个字" rows="4" type="textarea" style="width: 320px;" v-model="form.content" @input="contentChanged"></el-input></div>
				<div><span class="m-title">短信模板</span></div>
				<div>
					<el-select v-model="form.smsTemplates" style="width: 320px;" @change="templateChanged">
						<el-option label="请选择" value=""></el-option>
						<el-option v-for="item in templateList" :key="item.id" :label="item.name" :value="item.content"></el-option>
					</el-select>
				</div>
				<div><span class="m-title">任务名称</span></div>
				<div><el-input style="width: 320px;" v-model="form.taskName" show-word-limit maxlength="32" placeholder="选填，最多32个字"></el-input></div>
				<div class="right mt20">
					<el-button type="primary" class="themed-button" @click="submitBatch">提交</el-button>
				</div>
			</div>
		</div>
		<div ref="contetn111" class="right fadeInRight" style="width: calc(100% - 370px);">
			<div class="title-box clearfix">
				<span class="m-title">批量短信任务历史</span>
				<el-form inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
					<el-form-item>
						<el-input  placeholder="任务名称" v-model="searchForm.taskName"></el-input>
					</el-form-item>
					<el-form-item>
						
						<el-date-picker style="width: 126px;" placeholder="起始日期" v-model="searchForm.startTime" type="date" value-format="yyyy-MM-dd"/>
					</el-form-item>
					<el-form-item>
						<span class="text-color">到</span>
					</el-form-item>
					<el-form-item>
						
						<el-date-picker style="width: 126px;" placeholder="结束日期" v-model="searchForm.endTime" type="date" value-format="yyyy-MM-dd"/>
					</el-form-item>
					<el-form-item>
						<el-input placeholder="提交用户" v-model="searchForm.createBy"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button icon="el-icon-refresh-left" class="themed-button" type="info" @click="reset">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="content-box">
				<el-table ref='table' :data="tableData" stripe style="width: 100%;" :max-height="maxTableHeight">
					<el-table-column label="序号">
						<template slot-scope="scope">
							<a href='javascript:;' @click="getSmsListByBatchNumber(scope.row.batchNumber)" style="color: #337ab7;">{{scope.row.batchNumber}}</a>
						</template>
					</el-table-column>
					<el-table-column prop="taskName" label="任务名称">
					</el-table-column>
					<el-table-column prop="count" label="提交号码数">
					</el-table-column>
					<el-table-column prop="failCount" label="失败数">
					</el-table-column>
					<el-table-column prop="successCount" label="成功数">
					</el-table-column>
					<el-table-column prop="" label="输入文件">
						<template slot-scope="scope">
							<a href='javascript:;' @click="exportInput(scope.row.id)" style="color: #337ab7;">下载输入文件</a>
						</template>
					</el-table-column>
					<el-table-column prop="" label="输出文件">
						<template slot-scope="scope">
							<a href='javascript:;' @click="exportOutput(scope.row.id)" style="color: #337ab7;">下载输出文件</a>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" width="160" label="提交时间">
					</el-table-column>
					<el-table-column prop="createBy" label="提交用户">
					</el-table-column>
				</el-table>
				<el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
				 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {batchSmsList,submitBatchSms,searchTemplateList,exportInput,exportOutput} from '@/api/sms.js'
	import {exportTemplate} from '@/api/statistics.js'
	import resize from '@/mixins/resize.js'
	import Utils from '@/utils/utils.js'
	import loading from '@/utils/Loading.js'
	export default{
		name:'batch_sms_list',
		mixins:[resize],
		data(){
			return{
				page:1,
				pageSize:15,
				total:0,
				tableData:[],
				searchForm:{},
				form:{},
				templateList:[],
				count:'0'
			}
		},
		created() {
			this.getSmsBatchList()
			this.getSearchTemplateList()
		},
		methods:{
			getSmsListByBatchNumber(batchNumber){
				this.$router.push({path:'/sms/sms_list',query:{batchNumber:batchNumber}})
			},
			exportTemplate(){
				exportTemplate({type:1}).then(res=>{
					const filename = '短信批量发送.xls'
					Utils.createDownload(res,filename)
				})
			},
			exportInput(id){
				exportInput({id:id}).then(res=>{
					const filename = '输入文件.xls'
				
					Utils.createDownload(res,filename)
				})
			},
			exportOutput(id){
				exportOutput({id:id}).then(res=>{
					const filename = '输出文件.xls'
				
					Utils.createDownload(res,filename)
				})
			},
			templateChanged(val){
				this.form.content = val
			},
			contentChanged(val){
				// this.form.content = val.replace(/[\W]/g,'')
				this.form.content = val.replace(/[\u4E00-\u9FA5]/g,'')
			},
			inputCard(val){
				var iccids = val.split("\n");
				iccids = iccids.filter(item=>{return !!item})
				let newStr = ''
				iccids.forEach(item=>{
					if (iccids.length <= 50) {
						newStr += (item + '\n')
					}
				})
				if (iccids.length <= 50) {
					this.count = iccids.length
				}else{
					this.count = 50
				}
				this.form.iccids = newStr
			},
			submitBatch(){
				this.$confirm("确定要导入吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let file = this.$refs['fileInput'].files[0];
					if (file) {
			 let fileName = file.name;
            let index = fileName.lastIndexOf(".");
            if (
              index < 0 ||
              !(
                fileName.substring(index + 1, fileName.length) == "xls" ||
                fileName.substring(index + 1, fileName.length) == "xlsx"
              )
            ) {
              this.$alert("文件格式错误", "提示", {
                confirmButtonText: "确定",
              });
              return;
            } else if (!!Utils.checkFile(file)) {
              if (Utils.checkFile(file) == 1) {
                messageBox(this, "上传文件不能超过20M");
                return;
              } else if (Utils.checkFile(file) == 2) {
                messageBox(this, "上传文件名称长度不能超过36个字符");
                return;
              }
            }else{
let param = new FormData()
						if (!!this.form.taskName) {
							param.append('taskName',this.form.taskName)
						}
						param.append("fileurl",file)
						loading.show()
						submitBatchSms(param).then(res=>{
							 if (res.system_result_key == '0') {
                          this.$message({
								type: 'success',
								message:'发送成功'
							});
							this.getSmsBatchList()
							this.count = 0
							this.form = {}
							this.$refs['fileInput'].value = ''
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
							
						})
			}
					}else{
						if (this.form.iccids == null || this.form.iccids == '' || typeof this.form.iccids == 'undefined') {
							this.$alert('请输入卡号或选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
						}
						if (this.form.content == null || this.form.content == '' || typeof this.form.content == 'undefined') {
							this.$alert('请输入短信内容', '提示', {
							    confirmButtonText:'确定',
							});
							return
						}
						loading.show()
						let param = new FormData()
						param.append('taskName',this.form.taskName)
						param.append('iccids',this.form.iccids)
						param.append('content',this.form.content)
						// param.append('fileurl',new )
						
						submitBatchSms(param).then(res=>{
							this.$message({
								type: 'success',
								message:'发送成功'
							});
							this.getSmsBatchList()
							this.count = 0
							this.form = {}
						})
					}
				})
				
				
			},
			getSearchTemplateList(){
				searchTemplateList({}).then(res=>{
					this.templateList = res
				})
			},
			onSearch(){
				this.page = 1
				this.getSmsBatchList()
			},
			reset(){
				this.searchForm = {}
			},
			getSmsBatchList(){
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
				}
				Object.assign(this.searchForm,params)
				batchSmsList(this.searchForm).then(res=>{
					
					this.tableData = res.rows.map(item=>{
						item.createTime = Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(item.createTime))
						return item
					})
					this.total = res.count
				})
			},
			onPageChange(page){
				this.page = page
				this.getSmsBatchList()
			},
			handleSizeChange(size){
				this.pageSize = size
				this.getSmsBatchList()
			}
		},
		
	}
</script>

<style>
	.card-count{
		font-size: 12px;
		color:#909399;
		font-weight: 500;
		position: absolute;
		right: 10px;
		bottom: 6px;
	}
	input[type='file' i]{
		color: #a6a6a6;
		font-size: 13px;
	}
	
</style>
