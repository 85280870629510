<template>

<div class="recommend table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">推荐</span>
        <el-form inline class="hook right themed-form search-form">   
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addTicket">新增规则</el-button>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="请输入搜索关键字，多个用空格隔开" v-model="filterAgentName" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
                </el-input>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="代理商" prop="filterAgentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐名" prop="filterMealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" class="themed-button" @click="delFilter(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog title="新增规则" :visible.sync="dialogBox" v-if="dialogBox" width="70%">
        <new-rec  @close="dialogBox=false"  @refresh="refreshData"></new-rec>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {getRecommendList,updateData,deleteData} from '@/api/eventDiscountManagement/recommend.js'

    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'

    import NewRec from '@/views/eventDiscountManagement/recommend/newRec'
    import resize from '@/mixins/resize.js'

    const ERR_OK = 0
    export default {
        name:'recommend',
        mixins:[resize],
        data(){
            return {
                filterAgentName:'',
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
                dialogBox:false
            }
        },
        mounted(){
            this._getRecommendList()
        },
        methods:{
            addTicket(){
                this.dialogBox = true
            },
            delFilter(id){
               confirmBox(this,'确定要删除么？',null,()=>{
                   deleteData({id}).then((res)=>{
                       this._getRecommendList()
                   })
               })  
            },
            searchData(){
                this._getRecommendList()
            },
            refreshData(){
                this.dialogBox = false
                this._getRecommendList()
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._getRecommendList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._getRecommendList()
            },
            _getRecommendList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                params.filterAgentName = this.filterAgentName
                getRecommendList(params).then((res)=>{
                    // console.log(res)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        },
        components:{
            SearchSelect,
            NewRec
        }
    }
</script>

<style>
.marginR10{margin-right:10px;}
.recommend .search-form .el-input .el-input__inner {width:150px}
.recommend .el-form-item__content .el-input-group{vertical-align:middle}
.recommend .el-form-item__content .el-input-group{vertical-align:middle}
</style>
