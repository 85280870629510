<template>
  <div class="dialogShow" style="max-height:500px;overflow:auto">
      <el-button type="primary" class="themed-button" @click="exportExcel">批量导出</el-button>
        <el-table ref="table"  :data="tableData" style="width:100%">
            <el-table-column label="iccid(卡号)" prop="iccid" show-overflow-tooltip></el-table-column>
            <el-table-column label="接入号" prop="phone" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作状态" prop="status" show-overflow-tooltip></el-table-column>
            <el-table-column label="原始到期时间" prop="oldExpireTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="当前到期时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作时间" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作人" prop="operator"></el-table-column>
        </el-table>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</template>

<script type="text/ecmascript-6">
import {listExpireDetail} from '@/api/stockManagement/stock.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

import DialogShow from '@/views/stockManagement/stockInCardList/dialogShow'

export default {
    name:'dialogShow',
    data(){
        return {
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0
        }
    },
    mounted(){
        this._listExpireDetail()
    },
    methods:{
        exportExcel(){
            let name = 'import.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._listExpireDetail()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._listExpireDetail()
        },
        _listExpireDetail(){
            listExpireDetail().then((res)=>{
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.tableData = res.rows
                this.total = res.total
            })
        }
    },
    components:{
        DialogShow
    }
}
</script>

