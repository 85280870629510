<template>
	<div  class="orderSummary fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">订单汇总</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="日期:"> 
			    <el-date-picker v-model="value1" type="daterange"  range-separator="至"  start-placeholder="开始日期" end-placeholder="结束日期"  format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd">
			    </el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="info" @click="everyDayData" icon="el-icon-s-opportunity" class="themed-button">每日数据</el-button>
					</el-form-item>
			  </el-form>
		</div>
		<div class="content-box">
			<div class="table_box">
				<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
					<el-table-column prop="dataDate" label="日期">
					</el-table-column>
					<el-table-column prop="everydayRechargeMoney" label="累计充值金额">
						<template slot-scope="scope">
							<span>{{getRechargeMoney(scope.row.everydayRechargeMoney)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="everydayRechargeWechatMoney" label="累计微信充值金额">
						<template slot-scope="scope1">
							<span>{{getRechargeMoney(scope1.row.everydayRechargeWechatMoney)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="todayWechatOrderCount" label="今日微信订单">
					</el-table-column>
					<el-table-column prop="todaySystemOrderCount" label="今日系统订单">
					</el-table-column>
					<el-table-column prop="todayOrderSumCount" label="今日订单总数">
					</el-table-column>
					<el-table-column prop="todayRechargeMoney"  label="今日充值金额">
						<template slot-scope="scope2">
						<span>{{getRechargeMoney(scope2.row.todayRechargeMoney)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="todayHighestAgent" label="今日最高充值代理商">
					</el-table-column>
					<el-table-column prop="todayHighestAgentOrderCount"  label="今日最高充值代理商笔数">
						<template slot-scope="scope3">
						<span>{{getTodayHighestAgentOrderCount(scope3.row)}}</span>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
				 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
				 @current-change="onPageChange">
				</el-pagination>
			</div>
			
		</div>
	</div>
</template>

<script>
	import loading from "@/utils/Loading.js"
	import onresize from "@/mixins/resize.js"
	import {
		orderSummary
	} from "@/api/staticsManagement.js"
	  export default {
		name:'orderSummary',
		mixins: [onresize],
	    data() {
	      return {
				 value1:[],
	       tabledata:[],
				 page:1,
				 pageSize:15,
				 total:0,
				 
	      }
	    },
			created(){
				let params = {
					startTime:"",
					endTime:"",
				}
				this.getOrderSummeryList(params)
			},
			methods:{
				getOrderSummeryList(params){
					loading.show()
					orderSummary(params).then(res=>{
						this.tabledata = res.rows
						this.page = res.pageNo
						this.pageSize = res.pageSize
						this.total = res.total
					})
				},
				getRechargeMoney(rowData){
						if(rowData){
					    return parseFloat(rowData).toFixed(2);
					}else {return '0'}
					
				},
				getTodayHighestAgentOrderCount(rowData){
				if(!rowData.todayHighestAgentOrderCount||rowData.todayHighestAgentOrderCount=="null")return "";
					 return rowData.todayHighestAgentOrderCount;
				},
				searchData(){
					//console.log(this.value1)
					let params = {
						pageSize:this.pageSize,
						pageNo:this.page,
						startTime:this.value1 == null ? "":this.value1[0],
						endTime:this.value1 == null ? "":this.value1[1],
					}
					this.getOrderSummeryList(params)
				},
			everyDayData(){
				loading.show()
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
					startTime:this.value1 == null ? "":this.value1[0],
					endTime:this.value1 == null ? "":this.value1[1],
				}
				this.getOrderSummeryList(params)
			},
			handleSizeChange(size){
				this.pageSize = size
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
					startTime:this.value1 == null ? "":this.value1[0],
					endTime:this.value1 == null ? "":this.value1[1],
				}
				this.getOrderSummeryList(params)
			},
			onPageChange(page){
				this.page = page 
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
				  startTime:this.value1 == null ? "":this.value1[0],
				  endTime:this.value1 == null ? "":this.value1[1],
				}
				this.getOrderSummeryList(params)
			}
			}
	  };
</script>

<style>
	.orderSummary .el-form-item__label {vertical-align: top;}
</style>
