<template>
    <div class="order-agent-profit fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">提现申请</span>
            <el-form inline ref="agentProfitForm" v-model="agentProfitData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="agentProfitData.agentName" placeholder="代理商名称"></el-input>
                </el-form-item>
                <el-form-item label="结算状态">
                    <search-select :optionsMetaAll="optionsStatus" v-model="agentProfitData.status"></search-select>
                </el-form-item>
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="核算周期" v-model="agentProfitData.checkTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="agentName"
                    label="代理商名称"
                    min-width="120">
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="startTime"
                    label="更新时间"
                    min-width="120">
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="endTime"
                    label="核算时间"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    prop="orderNum"
                    label="订单总数"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    label="订单总额"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.orderAmount).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="套餐总额"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.mealAmount).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="agentDifPrice"
                    label="代理差价"
                    min-width="100"> 
                </el-table-column>
                <el-table-column
                    label="返现金额"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.returnAmount).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="秒返金额"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.secondReturnMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可提现金额"
                    min-width="100">
                    <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.applyMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    min-width="100">
                     <template slot-scope="scope">
                        <span v-if="scope.row.status==1">待结算</span>
                        <span v-else-if="scope.row.status==2">待审核</span>
                        <span v-else-if="scope.row.status==3">结算完成</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="checkName"
                    label="结算人"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="checkTime"
                    label="结算时间"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="200">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="primary" @click="handleShowDetail(scope.row.id)">返利详情</el-button>
                        <el-button v-if="(scope.row.status==2)&&(scope.row.applyType==2)" size="mini" class="themed-button" type="primary" @click="updateSure(scope.row.id)">审核确认</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import resize from '@/mixins/resize.js'
import {agentProfitList,agentProfitApplyCheck,exportAgentProfit} from '@/api/order.js'
export default {
    name:'agentProfit',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return{
            agentProfitData:{
                agentName:'',
                status:'',
                checkTime:'',
            },
            optionsStatus:[
                {label:"全部",value:''},
                {label:"待结算",value:'1'},
                {label:"待审核",value:'2'},
                {label:"结算完成",value:'3'}
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,

        }
    },
    created(){
        this.getAgentProfitList()
    },
    methods:{
        getAgentProfitList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.agentProfitData,params)
            agentProfitList(this.agentProfitData).then(res=>{
                this.tableData = res.rows
                this.total=res.count
            })
        },
        handleSizeChange(size){
            this.pageSize = size
            this.getAgentProfitList()
        },
        onPageChange(page){
            this.page = page
            this.getAgentProfitList()
        },
        onSearch(){
            this.page = 1
            this.getAgentProfitList()
        },
        exportExcel(){
            exportAgentProfit(this.agentProfitData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        handleShowDetail(id){
            let params = {
                "agentId":'',
                'applyId':id,
                // "startTime":'',
                // "endTime":''
            }
            this.$router.push({path:'/orderManagement/agentProfitDetail',query:params})
        },
        updateSure(id){
            this.$confirm('确定同意审批吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                agentProfitApplyCheck({'itemId':id}).then(res=>{
                    this.$message({
                    type: 'success',
                    message: '提交成功!'
                    });
                    this.getAgentProfitList()
                })
                
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消审批'
                });          
        });
        },
    }
}
</script>
<style>
    .order-agent-profit .el-form-item__label{
        vertical-align: top;
    }
</style>