<template>
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
        <el-col :span="18" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
              <el-form-item
                label=""
                :label-width="formLabelWidth"
                prop="gyAgentId"
                v-if="currentRole==0"
              >
                <treeselect
                  v-model="queryParams.gyAgentId"
                  :options="agentOptions"
                  :normalizer="normalizer"
                  :show-count="true"
                  placeholder="请选择代理商"
                />
              </el-form-item>



          <el-form-item prop="gyAgentId" v-else>
            <el-select v-model="queryParams.gyAgentId" placeholder="请选择代理商">
             <el-option
             v-for="item in Nooptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            >  
            </el-option>
            </el-select>
          </el-form-item>
              <!-- <el-form-item style="margin-right: 40px">
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  @click="screenDate"
                  >筛选</el-button
                >
              </el-form-item> -->
              <el-form-item label="" :label-width="formLabelWidth" prop="imei">
                <el-input
                  placeholder="请输入设备IMEI"
                  v-model="queryParams.imei"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
        <el-col :span="6" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
              <el-form-item v-if="currentRole==0">
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="add"
                  >新增</el-button
                >
              </el-form-item>
               <el-form-item>
                <el-button
                  icon="el-icon-connection"
                  type="primary"
                  class="themed-button"
                  @click="configuration"
                  >配置</el-button
                >
                 <el-button
                  icon="el-icon-sort"
                  type="primary"
                  class="themed-button"
                  @click="batchMove"
                  >批量移动</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
         :max-height="maxTableHeight"
        tooltip-effect="dark"
        style="width: 100%"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column prop="gyAgentId" label="代理商ID" min-width="200">
        </el-table-column>
        <el-table-column prop="gyAgentName" label="代理商名称" min-width="120">
        </el-table-column>
        <el-table-column prop="imei" label="设备IMEI" min-width="180">
        </el-table-column>
        <el-table-column prop="importTime" label="分配时间" min-width="180">
        </el-table-column>
        <el-table-column prop="createBy" label="分配人" min-width="180">
        </el-table-column>
        <el-table-column label="操作" min-width="150" v-if="currentRole==0">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="moveName(scope.row)"
              >移动</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

      <el-dialog title="新增分配" :visible.sync="dialogFormVisible" width="80%">
        <el-form
          ref="addForm"
          :model="addForm"
          label-width="50px"
          :rules="rules"
        >
          <el-form-item prop="gyAgentId">
            <div class="choose-title">代理商选择</div>
            <div class="choose-optionOne">
              <treeselect
                v-model="addForm.gyAgentId"
                :options="agentOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="请选择代理商"
                @select="treeSelectChange"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <div class="choose-title">管理类型选择</div>
            <div class="choose-optionOne">
              <el-select v-model="addForm.rateLimitRuleId" filterable clearable placeholder="请选择限速管理" style="margin-right:20px">
                <el-option
                  v-for="item in rateLimitOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
                <el-select v-model="addForm.qrcodeId" filterable clearable placeholder="请选择二维码管理" style="margin-right:20px">
                <el-option
                  v-for="item in qrcodeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
                <el-select v-model="addForm.showId" filterable clearable placeholder="请选择屏幕管理">
                <el-option
                  v-for="item in screenOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="radioLable">
            <div class="choose-title">分配方式选择</div>
            <div class="choose-option">
              <el-radio-group v-model="addForm.radioLable">
                <el-radio :label="0">少量设备分配</el-radio>
                <el-radio :label="1">大量设备分配</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item v-show="addForm.radioLable == 1">
            <div class="choose-title">大量设备分配</div>
            <div style="height: 50px; line-height: 50px">
              <a
                href="javascript:void(0);"
                @click="exportTemplateExcel('报表数据.xls')"
                style="color: blue"
                >下载模板</a
              >
            </div>
            <div style="height: 50px; line-height: 50px">
              <input class="file-input" type="file" ref="file" />
              <div class="text-color" style="font-size: 12px">
                仅支持.xls/.xlsx格式
              </div>
            </div>
          </el-form-item>
          <el-form-item v-show="addForm.radioLable == 0" prop="imeiArray">
            <div class="choose-title">少量设备分配</div>
            <div class="choose-imei">
              <div class="choose-imei-title">输入设备IMEI</div>
              <div>
                <el-input
                  class="choose-input"
                  type="textarea"
                  placeholder="请输入设备IMEI"
                  v-model="addForm.imeiArray"
                  :autosize="{ minRows: 3, maxRows: 4 }"
                >
                </el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="isUpdates">
            <div class="choose-titleOne">IMEI重复策略</div>
            <div class="choose-optionOnce">
              <el-radio-group v-model="addForm.isUpdate">
                <el-radio :label="1">覆盖</el-radio>
                <el-radio :label="2">跳过</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="dialogFormVisible = false"
            type="info"
            class="themed-button"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="sureAdd('addForm')"
            class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>


      <!-- 移动弹框 -->
      <el-dialog title="移动" :visible.sync="dialogTableVisible" width="30%">
        <el-form ref="formOnce" :model="formOnce" label-width="120px">
          <el-form-item label="代理商选择">
            <treeselect
              v-model="formOnce.gyAgentId"
              :options="agentOptions"
              :normalizer="normalizer"
              :show-count="true"
              placeholder="请选择代理商"
              @select="treeSelectChange"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="moveSubmit">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 批量移动 -->
      <el-dialog
        title="批量移动"
        :visible.sync="dialogTableVisibleOnce"
        width="30%"
      >
        <el-form ref="formBatch" :model="formBatch" label-width="120px">
          <el-form-item label="移出代理商选择">
            <treeselect
              v-model="formBatch.sourceGyAgentId"
              :options="agentOptions"
              :normalizer="normalizer"
              :show-count="true"
              placeholder="请选择代理商"
              @select="treeSelectChangeOne"
            />
          </el-form-item>
          <el-form-item label="移入代理商选择">
            <treeselect
              v-model="formBatch.targetGyAgentId"
              :options="agentOptions"
              :normalizer="normalizer"
              :show-count="true"
              placeholder="请选择代理商"
              @select="treeSelectChangeTwo"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisibleOnce = false">取 消</el-button>
          <el-button type="primary" @click="batchSubmit">确 定</el-button>
        </div>
      </el-dialog>

        <el-dialog title="配置" :visible.sync="dialogFormVisiblefos" width="80%">
        <el-form
          ref="addFormCount"
          :model="addFormCount"
          label-width="50px"
          :rules="rules"
        >
          <el-form-item prop="gyAgentId" v-if="currentRole==0">
            <div class="choose-title">代理商选择</div>
            <div class="choose-optionOne">
              <treeselect
                v-model="addFormCount.gyAgentId"
                :options="agentOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="请选择代理商"
                @select="treeSelectChange"
              />
            </div>
          </el-form-item>

            <el-form-item prop="gyAgentId" v-else>
            <div class="choose-title">代理商选择</div>
            <div class="choose-optionOne">
            <el-select v-model="addFormCount.gyAgentId" placeholder="请选择代理商" :disabled="currentRole!=0" >
             <el-option
             v-for="item in Nooptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            >
            <!-- :value="`${item.value}|${item.label}`" -->
            </el-option>
            </el-select>
            </div>
          </el-form-item>


          <el-form-item>
            <div class="choose-title">管理类型选择</div>
            <div class="choose-optionOne">
              <el-select v-model="addFormCount.rateLimitRuleId" filterable clearable placeholder="请选择限速管理" style="margin-right:20px">
                <el-option
                  v-for="item in rateLimitOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
                <el-select v-model="addFormCount.qrcodeId" filterable clearable placeholder="请选择二维码管理" style="margin-right:20px">
                <el-option
                  v-for="item in qrcodeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
                <el-select v-model="addFormCount.showId" filterable clearable placeholder="请选择屏幕管理">
                <el-option
                  v-for="item in screenOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="cancellation"
            type="info"
            class="themed-button"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="sureAddfos('addFormCount')"
            class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { agentGetAllAgent } from "@/api/system/user";
import {getAgentType} from "@/api/agentManagement.js"
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {mapGetters} from 'vuex'
import {
  deviceList,
  addDevice,
  deviceExport,
  importBatchStopCardByExcel,
  delDevice,
  rateLimitAllList,
  screenAllList,
  qrcodeAllList,
  batchConfig,
  switchAgent,
  batchSwitchAgent
} from "@/api/deviceAssignment/deviceAssignment.js";
export default {
  name: "materialsList",
   mixins:[resize],
  components: { Treeselect },

  //mixins: [resize],
  data() {
    var validGet = (rule, value, callback) => {
      if (value) {
        var reg = /^(\d+\s?)+$/;
        if (!reg.test(value)) {
          callback(new Error("输入不合法,必须输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      formInline: {
        spec: "",
        material: "",
      },
      addForm: {
        radioLable: 0,
        imeiArray: "",
        gyAgentId: undefined,
        isUpdate: 1,
      },
      addFormCount: {
      gyAgentId: undefined,
      gyAgentName: "",
      },
      tableData: [],
      materialList: [],
      formOnce: {},
      formBatch: {},
      specList: [],
      agentOptions: [],
      Nooptions: [
        {
          value: "",
          label: "",
        },
      ],
      rateLimitOptions: [],
      screenOptions:[],
      qrcodeOptions:[],
      dialogFormVisible: false,
      dialogTableVisible: false,
      dialogFormVisiblefos: false,
      dialogTableVisibleOnce: false,
      queryParams: {
        imei: "",
        gyAgentId: undefined,
        gyAgentName: undefined,
      },
      rules: {
        gyAgentId: [
          { required: true, message: "请选择代理商", trigger: "change" },
        ],
        imeiArray: [
          {
            required: true,
            message: "设备IMEI不能为空",
            trigger: "blur",
          },
        ],
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
      dialogTitle: "新增分配",
    };
  },

  computed:{
         ...mapGetters(
             ['currentRole','agentTree']
         ),
     },

  created() {
    this.getDeviceList();
    //this.getAllAgent();
    if(this.currentRole==0){
    this.getAllAgent()
    }
    this.getRateLimitAllList();
    this.getScreenAllList();
    this.getQrcodeAllList()
    if(this.currentRole!=0){
    this.getAgentType() 
    }
  },
  methods: {
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams, params);
      console.log(this.queryParams, 1111);
      deviceList(this.queryParams).then((res) => {
        console.log(this.queryParams, 3333);
        this.tableData = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },

    /** 代理商数据 */
    getAllAgent() {
      agentGetAllAgent().then((response) => {
      this.agentOptions = response;
      });
    },
    getAgentType(){
    getAgentType().then(res=>{
     this.Nooptions = [];
      this.Nooptions.push({
          label: res.agentThreeName,
          value: res.agentId,
        });
        console.log(this.Nooptions,3433443)
    })
    },

    /** 转换代理商数据结构 */
    normalizer(node) {
      if (
        node.children == null ||
        (node.children == "null" && node.children && !node.children.length)
      ) {
        delete node.children;
      }
      return {
        id: node.agentId,
        label: node.agentName,
        children: node.children,
      };
    },
    /** 搜索 */
    searchData() {
      this.page = 1;
      this.getDeviceList();
    },
    treeSelectChange(raw, instanceId) {
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        this.addForm.gyAgentId = raw.agentId;
        this.addForm.gyAgentName = raw.agentName;
        this.addFormCount.gyAgentId = raw.agentId;
        this.addFormCount.gyAgentName = raw.agentName;
        this.formOnce.gyAgentId = raw.agentId;
        this.formOnce.gyAgentName = raw.agentName;
      });
    },

    // 获取限速管理数据
    getRateLimitAllList(){
     rateLimitAllList().then((response) => {
     this.rateLimitOptions = response;
      });
    },
    // 获取屏幕管理数据
    getScreenAllList(){
     screenAllList().then((response) => {
     this.screenOptions = response;
      });
    },

  // 获取二维码管理数据
    getQrcodeAllList(){
     qrcodeAllList().then((response) => {
     this.qrcodeOptions = response;
      });
    },
     treeSelectChangeOne(raw, instanceId) {
      setTimeout(() => {
        this.formBatch.sourceGyAgentId = raw.agentId;
      });
    },
      treeSelectChangeTwo(raw, instanceId) {
      setTimeout(() => {
        this.formBatch.targetGyAgentId = raw.agentId;
      });
    },
    /** 导出模板 */
    exportTemplateExcel(name) {
      deviceExport({ name: name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
    /** 新增提交 */
    sureAdd() {
      console.log(this.addForm, 12122);
      let flag = true;
      if (this.addForm.radioLable == 0) {
        this.$refs["addForm"].validate((valid) => {
          if (valid) {
            let queryFox = new FormData();
            queryFox.append("isUpdate", this.addForm.isUpdate);
            queryFox.append("rateLimitRuleId", this.addForm.rateLimitRuleId||"");
            queryFox.append("qrcodeId", this.addForm.qrcodeId||"");
            queryFox.append("showId", this.addForm.showId||"");
            if (!!this.addForm.gyAgentId) {
              queryFox.append("gyAgentId", this.addForm.gyAgentId);
              queryFox.append("gyAgentName", this.addForm.gyAgentName);
            } else {
              flag = false;
              this.$alert("请选择代理商", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            // if(!(!this.addForm.rateLimitRuleId &&!this.addForm.qrcodeId &&!this.addForm.showId)) {
            // queryFox.append("rateLimitRuleId", this.addForm.rateLimitRuleId||"");
            // queryFox.append("qrcodeId", this.addForm.qrcodeId||"");
            // queryFox.append("showId", this.addForm.showId||"");
            // } else {
            //    flag = false;
            //   this.$alert("管理类型至少需要选择一个", "提示", {
            //   confirmButtonText: "确定",
            //   });
            //   return;
            // }
            if (!!this.addForm.imeiArray) {
              let real_src_list = this.addForm.imeiArray.split("\n");
              let list = Array.from(real_src_list);
              if (list.length > 1000) {
                flag = false;
                this.$alert("输入上限为10组", "提示", {
                  confirmButtonText: "确定",
                });
              } else {
                list.forEach((item, i) => {
                  const numberConst = !isNaN(parseFloat(item));
                  if (!numberConst) {
                    flag = false;
                    this.$alert("只能为数字", "提示", {
                      confirmButtonText: "确定",
                    });
                  }
                  if (item.length > 17 || item.length < 15) {
                    flag = false;
                    this.$alert("每组为15位到17位长度的数字", "提示", {
                      confirmButtonText: "确定",
                    });
                  } else {
                    queryFox.append("imeiArray", item);
                  }
                });
              }
            } else {
              flag = false;
              this.$alert("请输入设备IMEI", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            if (flag) {
              console.log(queryFox, 3434);
              addDevice(queryFox).then((res) => {
                if (res.code == "0") {
                  const alert_content = res.msg;
                  this.$alert(alert_content, "提示", {
                    confirmButtonText: "确定",
                    dangerouslyUseHTMLString: true,
                  }).then(() => {
                    this.dialogFormVisible = false;
                    this.getDeviceList();
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.system_result_message_key,
                    type: "error",
                  });
                }
              });
            }
          }
        });
      } else if (this.addForm.radioLable == 1) {
        this.$confirm("确定导入吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let file = this.$refs["file"].files[0];
          if (file) {
            let fileName = file.name;
            let index = fileName.lastIndexOf(".");
            if (
              index < 0 ||
              !(
                fileName.substring(index + 1, fileName.length) == "xls" ||
                fileName.substring(index + 1, fileName.length) == "xlsx"
              )
            ) {
              this.$alert("文件格式错误", "提示", {
                confirmButtonText: "确定",
              });
              return;
            } else if (!!Utils.checkFile(file)) {
              if (Utils.checkFile(file) == 1) {
                messageBox(this, "上传文件不能超过20M");
                return;
              } else if (Utils.checkFile(file) == 2) {
                messageBox(this, "上传文件名称长度不能超过36个字符");
                return;
              }
            } else {
            let param = new FormData();
            param.append("isUpdate", this.addForm.isUpdate);
            param.append("rateLimitRuleId", this.addForm.rateLimitRuleId||"");
            param.append("qrcodeId", this.addForm.qrcodeId||"");
            param.append("showId", this.addForm.showId||"");
              if (!!this.addForm.gyAgentId) {
                param.append("gyAgentId", this.addForm.gyAgentId);
                param.append("gyAgentName", this.addForm.gyAgentName);
              } else {
                this.$alert("请选择代理商", "提示", {
                  confirmButtonText: "确定",
                });
                return;
              }
            // if(!(!this.addForm.rateLimitRuleId &&!this.addForm.qrcodeId &&!this.addForm.showId)) {
            // param.append("rateLimitRuleId", this.addForm.rateLimitRuleId||"");
            // param.append("qrcodeId", this.addForm.qrcodeId||"");
            // param.append("showId", this.addForm.showId||"");
            // } else {
            //   this.$alert("管理类型至少需要选择一个", "提示", {
            //   confirmButtonText: "确定",
            //   });
            //   return;
            // }
              param.append("file", file);
              importBatchStopCardByExcel(param).then((res) => {
                if (res.code == "0") {
                  this.$alert("已上传，请到上传记录中查看", "提示", {
                    confirmButtonText: "确定",
                  }).then(() => {
                    this.dialogFormVisible = false;
                    this.getDeviceList();
                    this.$router.push("/equipmentManagement/historyList");
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.system_result_message_key,
                    type: "error",
                  });
                }
              });
            }
          } else {
            this.$alert("请选择文件", "提示", {
              confirmButtonText: "确定",
            });
            return;
          }
        });
      }
    },
    // 清空配置数据
     resetOne() {
      this.addFormCount = {
        gyAgentId: undefined,
        rateLimitOptions:[],
        qrcodeOptions:[],
        screenOptions:[]
      };
    },
    // 取消
    cancellation(){
    this.dialogFormVisiblefos=false
    this.resetOne()
    },
    // 配置提交
    sureAddfos(){
       let batchConfigQuery = new FormData();
      //  batchConfigQuery.append("rateLimitRuleId", this.addFormCount.rateLimitRuleId||"");
      //  batchConfigQuery.append("qrcodeId", this.addFormCount.qrcodeId||"");
      //  batchConfigQuery.append("showId", this.addFormCount.showId||"");
        if (this.currentRole==0) {
           if (!!this.addFormCount.gyAgentId) {
              batchConfigQuery.append("gyAgentId", this.addFormCount.gyAgentId);
              batchConfigQuery.append("gyAgentName", this.addFormCount.gyAgentName);
            } else {
              // flag = false;
              this.$alert("请选择代理商", "提示", {
                confirmButtonText: "确定",
              });
              return;
            } 
        }else{
         if (!!this.addFormCount.gyAgentId) {
              // const [lable, value] = this.addFormCount.gyAgentId.split('|')
              batchConfigQuery.append("gyAgentId", this.addFormCount.gyAgentId);
              batchConfigQuery.append("gyAgentName", this.addFormCount.gyAgentName);
            } else {
              // flag = false;
              this.$alert("请选择代理商", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }  
        }
            if(!(!this.addFormCount.rateLimitRuleId &&!this.addFormCount.qrcodeId &&!this.addFormCount.showId)) {
            batchConfigQuery.append("rateLimitRuleId", this.addFormCount.rateLimitRuleId||"");
            batchConfigQuery.append("qrcodeId", this.addFormCount.qrcodeId||"");
            batchConfigQuery.append("showId", this.addFormCount.showId||"");
            } else {
              this.$alert("管理类型至少需要选择一个", "提示", {
              confirmButtonText: "确定",
              });
              return;
            }
              batchConfig(batchConfigQuery).then((res) => {
                if (res.code == "0") {
                  const alert_content = res.msg;
                  this.$alert(alert_content, "提示", {
                    confirmButtonText: "确定",
                    dangerouslyUseHTMLString: true,
                  }).then(() => {
                    this.dialogFormVisiblefos = false;
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.system_result_message_key,
                    type: "error",
                  });
                }
              });
    },

    /** 删除 */
    delect(id) {
      this.$confirm("是否确认删除该项分配数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delDevice(id).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getDeviceList();
        });
      });
    },

     /** 移除 */
    moveName(row) {
      this.dialogTableVisible = true;
      this.moveId =row.id;
      this.moveGyAgentId =row.gyAgentId;
      this.resetOne()
    },
    moveSubmit() {
      console.log(this.moveId,777)
      let moveCount = new FormData();
      moveCount.append("id", this.moveId);
      if (!!this.formOnce.gyAgentId) {
        moveCount.append("gyAgentId", this.formOnce.gyAgentId);
        moveCount.append("gyAgentName", this.formOnce.gyAgentName);
      } else {
        this.$alert("请选择代理商", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if(this.moveGyAgentId==this.formOnce.gyAgentId){
        this.$alert("代理商未发生改变", "提示", {
          confirmButtonText: "确定",
        })
      return;
      }else{
      switchAgent(moveCount).then((res)=>{
        this.$message({
            type: "success",
            message: "操作成功",
          });
      this.dialogTableVisible = false;
      this.getDeviceList()
      }) 
      }
    },
    /** 批量移动 */
    batchMove() {
    this.dialogTableVisibleOnce = true;
    this.resetTwo()
    },
    batchSubmit() {
      let batchCount = new FormData();
      if (!!this.formBatch.sourceGyAgentId||!!this.formBatch.targetGyAgentId) {
        batchCount.append("sourceGyAgentId", this.formBatch.sourceGyAgentId);
        batchCount.append("targetGyAgentId", this.formBatch.targetGyAgentId);
      } else {
        this.$alert("请选择代理商", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if(this.formBatch.targetGyAgentId==this.formBatch.sourceGyAgentId){
        this.$alert("代理商未发生改变", "提示", {
          confirmButtonText: "确定",
        })
      return;
      }else{
      batchSwitchAgent(batchCount).then((res)=>{
        this.$message({
            type: "success",
            message: "操作成功",
          });
      this.dialogTableVisibleOnce = false;
      this.getDeviceList()
      }) 
      }
    },
    /** 重置表单 */
    reset() {
      this.addForm = {
        gyAgentId: undefined,
        imeiArray: "",
        radioLable: 0,
        isUpdate: 1,
      };
      //this.resetForm("addForm");
    },
     resetOne() {
      this.formOnce = {
        gyAgentId: undefined,
      };
      // this.resetForm("formOnce");
    },
     resetTwo() {
      this.formBatch = {
      sourceGyAgentId: undefined,
      targetGyAgentId: undefined,
      };
      // this.resetForm("formBatch");
    },
    /** 新增按钮弹出dialog */
    add() {
      this.reset();
      this.dialogFormVisible = true;
    },
    configuration(){
    this.resetOne();
    this.dialogFormVisiblefos = true;
    if (this.currentRole!=0) {
    this.addFormCount.gyAgentId = this.Nooptions[0].value
    this.addFormCount.gyAgentName = this.Nooptions[0].label  
    }
    },
    /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 10px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
::v-deep .el-table {
  overflow: visible !important;
}

::v-deep .el-table .cell {
  overflow: visible !important;
}

::v-deep.el-table__body-wrapper {
  overflow: visible !important;
}
.choose-optionOnce {
  margin-top: 30px;
}
.choose-titleOne {
  margin-top: 40px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
</style>