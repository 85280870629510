<template>
  <div class="libraryManagement fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">号码库管理</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="库名">
          <el-input v-model="formInline.name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="号码">
          <el-input v-model="formInline.no"></el-input>
        </el-form-item> -->
        <el-form-item>
						<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索</el-button>
					</el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="add"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="库名称" min-width="180">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          min-width="180"
        ></el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <!-- <el-button
              type="primary"
              class="themed-button"
              @click="importNum(scope.row)"
              >导入号码</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              @click="view(scope.row)"
              >查看号码</el-button
            > -->
            <el-button
              type="danger"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
        <el-form :model="addForm" :rules="rules" ref="addForm">
          <el-form-item
            label="库名称:"
            prop="name"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="addForm.name"
              autocomplete="off"
              placeholder=""
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="dialogFormVisible = false"
            type="primary"
            class="themed-button"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="sureAdd('addForm')"
            class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <el-dialog title="导入号码" :visible.sync="dialogVisible" v-if="dialogVisible">
        <el-form :model="importForm" ref="importForm">
          <el-form-item label="号码:" label-width="80px">
            <el-input
              v-model="importForm.nos"
              type="textarea"
              placeholder="多个号码以空格或换行分隔输入"
              :autosize="{ minRows: 2, maxRows: 20 }"
            ></el-input>
            <!-- <el-input v-model="importForm.nos" autocomplete="off" placeholder=""  ></el-input> -->
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="closeDialg"
            type="primary"
            class="themed-button"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="importAdd('importForm')"
            class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog title="号码列表" :visible.sync="dialogVisibleList" width="60%">
         <div class="updateExpire table-hook " style="max-height:600px;overflow:auto">
        <el-table :data="tableDataOne"   :max-height="maxTableHeight" tooltip-effect="dark">
          <el-table-column     label="序号" prop="id" align="left">
          </el-table-column>
          <el-table-column     label="号码" prop="no" align="left">
          </el-table-column>
          <el-table-column     label="导入时间" prop="createTime" align="left">
          </el-table-column>
          <el-table-column     label="操作账号" prop="createBy" align="left">
          </el-table-column>
           
         
        </el-table>
        <el-pagination
        :current-page.sync="dialogPage"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="dialogPageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="dialogTotal"
        @size-change="dialogHandleSizeChange"
        @current-change="dialogOnPageChange"
      ></el-pagination>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import {
  numbelList,
  addLibrary,
  delLibrary,
  showNo,
  importNo,
} from "@/api/configurationManagement.js";
export default {
  name: "libraryManagement",
  mixins: [resize],
  data() {
    return {
      dialogView: false,
      formInline: {
        name: "",
        // no: "",
      },
      tableData: [],
      radio: "2",
      dialogFormVisible: false,
      addForm: {
        name: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
      },
      page: 1,
      pageSize: 15,
      total: 0,
      dialogTitle: "",
      formLabelWidth: "110px",
      importForm: {
        nos: "",
        id:""
      },
      dialogVisible: false,
      tableDataOne: [],
      dialogVisibleList: false,
       dialogPage: 1,
      dialogPageSize: 15,
      dialogTotal: 0,
    };
  },
  created() {
    this.getnumbelList();
  },
  methods: {
    closeDialg(){
     this.dialogVisible = false
    this.importForm.id = "";
    this.importForm.nos = "";
    },
    importNum(row) {
      this.dialogVisible = true;
      this.importForm.id = row.id;
    },
    importAdd(form) {
      if (this.importForm.nos.trim() == "") {
        this.$message({
          showClose: true,
          message: "号码不能为空",
          type: "warning",
        });
        return;
      }

      importNo(this.importForm)
        .then((res) => {
          console.log(res,252);
          if (res.system_result_key == 0) {
            this.$message({
              showClose: true,
              message: res.import_count+"个号码导入成功！" ,
              type: "success",
            });
            this.dialogVisible = false;
            this.importForm.id = "";
            this.importForm.nos = "";
          } else {
            this.$message({
              showClose: true,
              message: "导入失败",
              type: "warning",
            });
          }
        })
        .catch((error) => {
          this.$message({
            showClose: true,
            message: error,
            type: "warning",
          });
        });
    },
    catNum(flag){

       let params = {
        pageSize: this.dialogPageSize,
        pageNo: flag?"1":this.dialogPage,
      };
      let endParam = {
         id: this.currentId
      }
      Object.assign(endParam, params);
      showNo(endParam).then((res) => {
        console.log(res,260);
        this.tableDataOne = []
        // if(res.length>0){
        //   res.forEach(item => {
        //     this.tableDataOne.push({num:item})
        //   });
        // }
        this.tableDataOne = res.rows
        this.dialogTotal = res.count;
        this.dialogVisibleList = true 
      });
    },
    //查看号码
    view(row) {
      this.currentId = row.id
     this.catNum(true)
    },

    onSearch() {
      this.page = 1;
      this.getnumbelList();
    },
    onPageChange(page) {
      this.page = page;
      this.getnumbelList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getnumbelList();
    },

    dialogOnPageChange(page) {
      this.dialogPage = page;
      this.catNum(false);
    },
    dialogHandleSizeChange(size) {
      this.dialogPageSize = size;
     this.catNum(false);
    },

    getnumbelList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.formInline, params);
      numbelList(this.formInline).then((res) => {
        console.log(res);
        this.tableData = res.rows;
        this.total = res.count;
      });
    },

    add() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      this.addForm.id = "";
      this.addForm.name = "";
    },
    sureAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addLibrary(this.addForm).then((responseResult) => {
            if (responseResult.slide_msg_key == 1) {
              this.$message({
                showClose: true,
                message: responseResult.slide_msg_message_key,
                type: "warning",
              });
            }
            this.getnumbelList();
            this.dialogFormVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    delect(id) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delLibrary({ id }).then((res) => {
          this.getnumbelList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.libraryManagement .el-form-item__label {
  vertical-align: top;
}
/* .libraryManagement  /deep/  .el-table__header-wrapper{display:none;} */
</style>
