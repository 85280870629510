<template>
  <!--推送配置 -->
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
        <el-col :span="18" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
              <el-form-item
                label=""
                :label-width="formLabelWidth"
                prop="agentId"
              >
                <treeselect
                  v-model="queryParams.agentId"
                  :options="agentOptions"
                  :normalizer="normalizer"
                  :show-count="true"
                  placeholder="请选择代理商"
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
        <el-col :span="6" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item style="margin-right: 50px">
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="add"
                  >新增</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  icon="el-icon-download"
                  type="primary"
                  class="themed-button"
                 @click="exportTemplateExcel('推送配置.xls')"
                  >导出</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        :max-height="maxTableHeight"
        style="width: 100%"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column prop="agentId" label="客户ID" min-width="200">
        </el-table-column>
        <el-table-column prop="agentName" label="代理商" min-width="180">
        </el-table-column>
        <el-table-column prop="pushAddr" label="推送地址" min-width="180">
        </el-table-column>
        <el-table-column prop="retry" label="重试次数" min-width="180">
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="180">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="180">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="180">
        </el-table-column>
        <el-table-column prop="createBy" label="创建人" min-width="180">
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="update(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
     <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
      <el-dialog :title="title" :visible.sync="dialogFormVisible" width="40%">
        <el-form ref="addForm" :model="addForm" :rules="rules" label-width="120px">
          <el-form-item label="代理商" style="width:80%" prop="agentId">
           <treeselect style="width:100%"
                v-model="addForm.agentId"
                :options="agentOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="请选择代理商"
                @select="treeSelectChange"
              />
          </el-form-item>
          <el-form-item label="推送URL" style="width:80%" prop="pushAddr">
            <el-input v-model="addForm.pushAddr"></el-input>
          </el-form-item>
          <el-form-item label="重试次数" style="width:80%" prop="retry">
            <el-input v-model="addForm.retry"></el-input>
          </el-form-item>
          <el-form-item label="备注" style="width:80%" prop="remark">
            <el-input v-model="addForm.remark"  type="textarea" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
          </el-form-item>
        </el-form>
           <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureAdd('addForm')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { agentGetAllAgent } from "@/api/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {
pushAddrList,
pushAddr,
delPushAddr,
pushAddrExport
} from "@/api/statistical/statistical.js";
export default {
  name: "materialsList",
  mixins:[resize],
  components: { Treeselect },
  data() {
    var validGet = (rule, value, callback) => {
      if (value) {
        var reg = /^(\d+\s?)+$/;
        if (!reg.test(value)) {
          callback(new Error("输入不合法,必须输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      formInline: {
        spec: "",
        material: "",
      },
      addForm: {
        agentId: undefined,
        pushAddr:"",
        retry:"",
        remark:""
      },
      tableData: [],
      agentOptions: [],
      dialogFormVisible: false,
      queryParams: {
        agentId: undefined,
        agentName: undefined,
      },
      title:"新增",
      rules: {
        agentId: [
          { required: true, message: "请选择代理商", trigger: "change" },
        ],
        pushAddr: [
          { required: true, message: "请输入推送URL" },
        ],
        retry: [
          { required: true, message: "请输入重试次数" },
        ],
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
    };
  },
  created() {
    this.getDeviceList();
    this.getAllAgent();
  },
  methods: {
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams, params);
      pushAddrList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },
    /** 代理商数据 */
    getAllAgent() {
      agentGetAllAgent().then((response) => {
        this.agentOptions = response;
      });
    },
    /** 转换代理商数据结构 */
    normalizer(node) {
      if (
        node.children == null ||
        (node.children == "null" && node.children && !node.children.length)
      ) {
        delete node.children;
      }
      return {
        id: node.agentId,
        label: node.agentName,
        children: node.children,
      };
    },
    /** 搜索 */
    searchData() {
      this.page = 1;
      this.getDeviceList();
    },
    treeSelectChange(raw, instanceId) {
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        this.addForm.agentId = raw.agentId;
        this.addForm.agentName = raw.agentName;
      });
    },
    /** 导出模板 */
    exportTemplateExcel(name) {
      pushAddrExport({ name: name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
    /** 提交表单 */
    sureAdd() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
        if (this.title != "新增") {
           pushAddr(this.addForm).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          } else {
            pushAddr(this.addForm).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          }
        }
      });
    },
    /** 删除 */
    delect(id) {
      this.$confirm("是否确认删除该项数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delPushAddr(id).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getDeviceList();
        });
      });
    },
    /** 重置表单 */
    reset() {
      this.addForm = {
        agentId: undefined,
        pushAddr:"",
        retry:"",
        remark:""      };
      // this.resetForm("addForm");
    },
    /** 新增按钮弹出dialog */
    add() {
      this.reset()
      this.dialogFormVisible = true;
      this.title = "新增";
    },
    update(row) {
      this.addForm= JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true;
      this.title = "修改";
    },
      /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 10px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>