<template>

<div class="form-add">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="出库批次编号:">
            <el-input v-model="form.outCode"  readonly></el-input>
        </el-form-item>
        <el-form-item label="入库批次:" required>
            <!-- <search-select :optionsMetaAll="inCodeList" v-model="form.inCode"></search-select> -->
            <el-select v-model="form.inCode" filterable placeholder="请选择">
                <el-option v-for="(item,index) in inCodeList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="物料编号:" required>
            <!-- <search-select :optionsMetaAll="materielCodeList" ></search-select> -->
            <el-select v-model="form.materielCode" filterable placeholder="请选择">
                <el-option v-for="(item,index) in materielCodeList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="卡板费:" prop="cardFee" required>
            <el-input v-model="form.cardFee" ></el-input>
        </el-form-item>
        <el-form-item label="出库客户:" required>
            <!-- <search-select :optionsMetaAll="outAgentIdList" v-model="form.outAgentId"></search-select> -->
            <el-select v-model="form.outAgentId" filterable placeholder="请选择">
                <el-option v-for="(item,index) in outAgentIdList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="续费单价:" prop="renewPrice" required>
            <el-input v-model="form.renewPrice" ></el-input>
        </el-form-item>
        <el-form-item label="出库时间:" prop="outStockTime" required>
            <el-date-picker
                v-model="form.outStockTime"
                type="date"
                value-format="MM/dd/yyyy"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="备注:" prop="remark">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="上传卡号列表（excel）:" required>
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
            </div>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel('form')">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {OutFindById,getNewOutCode,importAddSync} from '@/api/stockManagement/stock.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

import SearchSelect from '@/components/SearchSelect'

export default {
    data(){
        var checkRemark = (rule, value, callback) => {
            if (value.length>32) {
                return callback(new Error('备注不能大于32个字符'));
            }else{
                callback()
            }
            
        };
        return {
            form:{
                flowNumber:'',
                outCode:'',
                inCode:'',
                materielCode:'',
                cardFee:'',
                outAgentId:'',
                renewPrice:'',
                outStockTime:'',
                remark:''
            },
            inCodeList:[],
            materielCodeList:[],
            outAgentIdList:[],
            rules: {
                cardFee: [
                    { required: true, message: '请输入卡板费', trigger: 'blur' }
                ],
                renewPrice: [
                    { required: true, message: '请输入续费单价', trigger: 'blur' }
                ],
                outStockTime: [
                    { required: true, message: '请选出库时间', trigger: 'blur' }
                ],
                remark: [
                    { validator: checkRemark, trigger: 'blur' }
                ]
            },
        }
    },
    mounted(){
        this._OutFindById()
        this._getNewOutCode()
    },
    methods:{
        uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            // alert('submit!');
                            let params = new FormData()
                            params.append('fileurl',file)
                            params.append('outCode',this.form.outCode)
                            params.append('inCode',this.form.inCode)
                            params.append('materielCode',this.form.materielCode)
                            params.append('cardFee',this.form.cardFee)
                            params.append('outAgentId',this.form.outAgentId)
                            params.append('renewPrice',this.form.renewPrice)
                            params.append('outStockTime',this.form.outStockTime)
                            params.append('remark',this.form.remark)
                            importAddSync(params).then(res=>{
                                if (res.system_result_key == '0') {
                                    this.$alert('导入成功，请到导入记录中下载', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.$emit('close-dialog')
                                        this.$router.push('/tob/i_import_list')
                                    });
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                }
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        importExcel(){
            let name = 'import.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        _OutFindById(){
            OutFindById(this.form).then((res)=>{
                console.log(res)

                if(res.inCode.length>0){
                    res.inCode.forEach((item)=>{
                        this.inCodeList.push({label:item.inCode,value:item.inCode})
                    })
                }else{
                    this.inCodeList = []
                }

                if(res.mCode.length>0){
                    res.mCode.forEach((item)=>{
                        this.materielCodeList.push({label:item.materielCode,value:item.materielCode})
                    })
                }else{
                    this.materielCodeList = []
                }

                if(res.customer.length>0){
                    res.customer.forEach((item)=>{
                        this.outAgentIdList.push({label:item.name,value:item.id})
                    })
                }else{
                    this.outAgentIdList = []
                }

                this.form.inCode = this.inCodeList[0].value
                this.form.materielCode = this.materielCodeList[0].value
                this.form.outAgentId = this.outAgentIdList[0].value
            })
        },
        _getNewOutCode(){
            getNewOutCode(this.form).then((res)=>{
                this.form.flowNumber = res.data
                this.form.outCode = 'CK'+this.form.flowNumber
            })
        }
    },
    components:{
        SearchSelect
    }
}
</script>

