<template>
    <div class="dataClearRecord fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">数据清零记录</span>
            <el-form inline ref="quaryForm" v-model="quaryForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
               	<el-form-item >
					<el-input v-model="quaryForm.iccid" placeholder="虚拟iccid" class="iccid-width"></el-input>
				</el-form-item>
                <el-form-item >
					<el-input v-model="quaryForm.shortIccid" placeholder="短号" ></el-input>
				</el-form-item>
                	<el-form-item >
					<el-input v-model="quaryForm.imei" placeholder="imei" class="iccid-width"></el-input>
				</el-form-item>
                	<el-form-item >
					<el-input v-model="quaryForm.outCard" placeholder="插拔卡号" class="iccid-width"></el-input>
				</el-form-item>
                 
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
            </el-form>

        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="iccid"
                    label="虚拟iccid"
                    min-width="180"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="shortIccid"
                    label="短号"
                    min-width="130"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop='imei'
                    label="imei"
                    min-width="150"
                    >
                </el-table-column>
                
                <el-table-column
                    show-overflow-tooltip
                    prop="outCard"
                    label="插拔卡号"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="创建时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="operator"
                    label="操作人"
                    min-width="150"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    label="操作"
                    min-width="150"
                    >
                    <template v-slot:default="scope" >
                      <el-button type="danger" class="themed-button" @click='deleteDataRecord(scope.row.id)'>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { clearRecordList,deleteRecordSingle} from "@/api/multiCardCompatible/dataClearRecord.js"
import {messageBox} from "@/utils/common.js"
export default {
    mixins:[resize],
    data(){
        return {
            quaryForm:{
             iccid:"", 
             imei:"",
             outCard:"",
             shortIccid:"",
            },
            pageNo:1,
            pageSize:15,
            tableData:[],
            total:0,
        }
    },
    created(){
        this.getList()
    },
    methods: {
        getList(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize
            }
            Object.assign(this.quaryForm,params)
            clearRecordList(this.quaryForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },

        onPageChange(page){
			this.pageNo = page
			this.getList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getList()
        },

        onSearch(){
            this.pageNo=1
            this.getList()
        },
        deleteDataRecord(id){
            this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					  deleteRecordSingle({id}).then(res=>{
						this.$message({
							type: 'success',
							message:'删除成功'
						})
                        this.getList()
					})
					
				})
        },

      
    },
}
</script>

<style>
    .dataClearRecord .el-form-item__label{
        vertical-align: top;
    }
</style>