<template>
  <div class="roleMeal table-hook fadeInRight">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="套餐显示规则管理" name="first">
        <div class="title-box">
          <el-form
            inline
            class="hook right themed-form search-form"
            style="line-height: 40px"
            :model="queryForm"
          >
            <el-form-item label="规则名称:">
              <el-input v-model="queryForm.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                class="themed-button"
                @click="searchTable"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-plus"
                class="themed-button"
                @click="addRecord"
                >新增</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                :disabled="!multiple"
                icon="el-icon-remove"
                class="themed-button"
                @click="removeRecord"
                >删除</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="content-box">
          <el-table
            ref="table"
            :data="tableData"
            @selection-change="handleSelectionChange"
            stripe
            border
            :max-height="maxTableHeight"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column
              label="规则名称"
              prop="name"
              width="180px"
            ></el-table-column>
            <el-table-column
              label="套餐列表"
              prop=""
              width="180px"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-link type="primary" @click="handleMealList(scope.row.id)"
                  >添加套餐</el-link
                >
              </template>
            </el-table-column>
            <el-table-column
              label="用户列表"
              prop=""
              width="180px"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-link type="primary" @click="handleUserList(scope.row.id)"
                  >分配用户</el-link
                >
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              prop="remark"
              width="180px"
            ></el-table-column>
            <el-table-column
              label="操作人"
              prop="createUserName"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.createUser">{{
                  scope.row.createUser
                }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作时间"
              prop="updateTime"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.updateTime">{{
                  scope.row.updateTime
                }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="createUserName" width="300px">
              <template slot-scope="scope">
                <el-button
                  type="info"
                  class="themed-button"
                  @click="updateRecord(scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[5, 10, 15, 50, 100, 1000]"
            :page-size="pageSize"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <el-dialog
          title="套餐显示规则编辑"
          :visible.sync="dialogVisible"
          v-if="dialogVisible"
          @close="closeAddOrEditDialog()"
        >
          <el-form
            label-position="right"
            label-width="200px"
            :model="addOrEditForm"
            ref="addOrEditForm"
            :rules="rules"
          >
            <el-form-item label="名称:" required prop="name">
              <el-input
                v-model="addOrEditForm.name"
                maxlength="150"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input
                v-model="addOrEditForm.remark"
                type="textarea"
                placeholder="请输入内容"
                maxlength="255"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm()">确 定</el-button>
            <el-button @click="cancle()">取 消</el-button>
          </span>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="公众号套餐分类" name="second">
        <div class="title-box">
          <el-form
            inline
            class="hook right themed-form search-form"
            style="line-height: 40px"
          >
           <el-form-item>
            <div style="color:#f00">请联系运营，让研发打开配置开关和代理商编辑不选择套餐样式才生效，自定义套餐排序优先级最低。</div>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-plus"
                class="themed-button"
                @click="addType"
                >新增</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="content-box">
          <el-table
            ref="table"
            :data="tableDataOne"
            stripe
            border
            :max-height="maxTableHeight"
          >
            <el-table-column label="序号" prop="index" width="100px">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.index"
                  @change="
                    (val) => {
                      updateSort(val, scope.row, scope.$index, 0);
                    }
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="名称" prop="name" width="300px">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  @change="
                    (val) => {
                      updateSort(val, scope.row, scope.$index, 1);
                    }
                  "
                ></el-input>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="300px">
              <template slot-scope="scope">
                <el-button
                  type="info"
                  class="themed-button"
                  @click="deleteType(scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[5, 10, 15, 50, 100, 1000]"
            :page-size="pageSize"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div> -->
        <el-dialog
          title="新增套餐分类"
          :visible.sync="dialogAddVisible"
          v-if="dialogAddVisible"
        >
          <el-form
            label-width="200px"
            :model="addForm"
            ref="addForm"
            :rules="rulesOne"
          >
            <el-form-item label="序号:" required prop="index">
              <el-input
                v-model="addForm.index"
                placeholder="请输入序号"
              ></el-input>
            </el-form-item>
            <el-form-item label="名称:" required prop="name">
              <el-input
                v-model="addForm.name"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitFormOne()">确 定</el-button>
            <el-button @click="cancleOne()">取 消</el-button>
          </span>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  mealRulesList,
  saveMealRules,
  updateMealRules,
  deleteMealRules,
} from "@/api/setMealManagment/mealRules.js";
import { messageBox, confirmBox } from "@/utils/common.js";
import store from "@/store";
import {
  getConfigurationModify,
  setConfigurationModify,
} from "@/api/systemManagement.js";
import resize from "@/mixins/resize.js";

export default {
  name: "mealRules",
  mixins: [resize],
  data() {
    var validateIndex = (rule, value, callback) => {
      const regex = /^(?:100|[1-9]?\d)$/;
      console.log(regex.test(value));
      if (!regex.test(value)) {
        console.log(307)
        callback(new Error("序号只能输入100内的整数"));
      } else if (this.originArr.length > 0) {
        this.originArr.forEach((item) => {
          if (item.index == value) {
            console.log(311)
            callback(new Error("序号不能重复"));
          }
        });
        callback();
      } else {
        console.log(317)
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (value.trim() === "") {
        console.log(321)
        callback(new Error("名称不能为空"));
      } else if (value.length > 8) {
         console.log(324)
        callback(new Error("名称不能超过8个字符,请重新输入"));
      } else {
        console.log(329)
        callback();
      }
    };
    return {
      dialogAddVisible: false,
      addForm: {
        index: "",
        name: "",
      },
      ids: [],
      multiple: false,
      queryForm: {
        name: "",
      },
      tableData: [],
      pageNo: 1,
      pageSize: 15,
      total: 0,
      dialogVisible: false,
      addOrEditForm: {
        id: undefined,
        name: "",
        remark: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      rulesOne: {
        index: [{ validator: validateIndex, trigger: "blur" }],
        name: [{ validator: validateName, trigger: "blur" }],
      },
      activeName: "first",
      tableDataOne: [],
      originArr: [],
    };
  },
  mounted() {
    this._mealRulesList();
    this.mealTypeList();
  },
  methods: {
      handleClick(tab, event) {
        if(tab.name=="first"){
         this._mealRulesList();
        }else{
        this.mealTypeList();
        }
      },
    submitFormOne() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          console.log(3333);
          this.tableDataOne.push({
            index: this.addForm.index,
            name: this.addForm.name,
          });
          this.setConfig();
          this.dialogAddVisible = false;
        } else {
          console.log(380);
          return false;
        }
      });
    },
    cancleOne() {
      this.dialogAddVisible = false;
      this.resetFormOne();
    },

    resetFormOne() {
      this.addForm.index = "";
      this.addForm.name = "";
    },

    mealTypeList() {
      this.tableDataOne = [];
      getConfigurationModify({ group: "SYSTEM_CONFIG" }).then((res) => {
        console.log(res, 198);

        res.forEach((item) => {
          if (item.dictKey == "CUSTOM_MEAL_CLASSIFY") {
            if (item.dictValue) {
              console.log(JSON.parse(item.dictValue));
              this.tableDataOne = JSON.parse(item.dictValue);
              this.originArr = JSON.parse(item.dictValue);
              //调用接口传数据
            } else {
              this.tableDataOne = [];
              this.originArr = [];
            }
          }
        });
      });
    },
    setConfig() {
      let params = {
        group: "SYSTEM_CONFIG",
        CUSTOM_MEAL_CLASSIFY: JSON.stringify(this.tableDataOne),
      };

      setConfigurationModify(params).then((res) => {
        console.log(res, 465);
        this.$message({
          showClose: true,
          message: "操作成功",
          type: "success",
          center: true,
        });

        this.mealTypeList();
      }).catch(error=>{
        console.log(error)
        
        this.tableDataOne = this.originArr
      })
    },
    addType() {
      //添加
      if(this.originArr.length==8){
        this.$message({
            showClose: true,
            message: "最多只能添加8条分类",
            type: "error",
          });
          return
      }
      this.dialogAddVisible = true;
      this.addForm.index = ""
      this.addForm.name = "" 
    },
    deleteType(index) {
      //删除
      this.$confirm("确认要删除所选的数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableDataOne.splice(parseInt(index), 1);
          console.log(this.tableDataOne);
          this.setConfig();
        })
        .catch(() => {});
    },
    updateSort(val, row, index, type) {
      //编辑  val:修改的当前值；row :当前行的值；index:table索引
      //    console.log(val,row,index)
      //    console.log(this.tableDataOne)
      //     console.log(this.originArr)
      //     return
      //校验数据
      if (type == 0) {
        //校验排序序号
        const regex = /^(?:100|[1-9]?\d)$/;
        console.log(regex.test(val));
        if (!regex.test(val)) {
          this.$message({
            showClose: true,
            message: "序号只能输入100内的整数",
            type: "error",
          });
          this.tableDataOne = this.originArr;
          return;
        }
        if (this.originArr.length > 0) {
          this.originArr.forEach((item) => {
            if (item.index == val) {
              this.$message({
                showClose: true,
                message: "序号不能重复",
                type: "error",
              });
              this.tableDataOne = this.originArr;
              return;
            }
          });
        }
      } else if (type == 1) {
        //校验名称
        if (val.trim() == "") {
          this.$message({
            showClose: true,
            message: "名称不能为空",
            type: "error",
          });
        } else if (val.length > 8) {
          this.$message({
            showClose: true,
            message: "名称不能超过8个字符,请重新输入",
            type: "error",
          });
          this.tableDataOne = this.originArr;
          return;
        }
      }

      //处理数据
      //    this.tableDataOne.with(index,row)
      console.log(this.tableDataOne);
      this.setConfig();
    },

    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.multiple = selection && selection.length > 0;
    },
    submitForm() {
      this.$refs["addOrEditForm"].validate((valid) => {
        if (valid) {
          let params = {};
          if (!!this.addOrEditForm.id) {
            params = {
              id: this.addOrEditForm.id,
              name: this.addOrEditForm.name,
              remark: this.addOrEditForm.remark,
            };
            updateMealRules(params)
              .then((res) => {
                this._mealRulesList();
                this.dialogVisible = false;
                this.resetForm();
                this.$message({
                  showClose: true,
                  message: "保存成功",
                  type: "success",
                });
              })
              .catch(() => {});
          } else {
            params = {
              name: this.addOrEditForm.name,
              remark: this.addOrEditForm.remark,
            };
            saveMealRules(params)
              .then((res) => {
                this._mealRulesList();
                this.dialogVisible = false;
                this.resetForm();
                this.$message({
                  showClose: true,
                  message: "保存成功",
                  type: "success",
                });
              })
              .catch(() => {});
          }
        } else {
          return false;
        }
      });
    },
    reset(formName) {
      this.$refs[formName].resetFields();
    },
    cancle() {
      this.dialogVisible = false;
      this.resetForm();
    },

    resetForm() {
      this.addOrEditForm.id = undefined;
      this.addOrEditForm.name = "";
      this.addOrEditForm.remark = "";
    },

    searchTable() {
      this._mealRulesList();
    },
    addRecord() {
      this.dialogTitle = "新增套餐";
      this.dialogContent = "add";
      this.dialogVisible = true;
    },
    updateRecord(row) {
      this.dialogVisible = true;
      this.addOrEditForm.id = row.id;
      this.addOrEditForm.name = row.name;
      this.addOrEditForm.remark = row.remark;
    },
    closeAddOrEditDialog() {
      this.resetForm();
    },
    removeRecord() {
      if (!this.ids || this.ids.length == 0) {
        this.$alert("请选择数据!", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      this.$confirm("确认要删除所选的数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            ids: this.ids.join(","),
          };
          deleteMealRules(params)
            .then((res) => {
              this._mealRulesList();
              this.multiple = false;
              this.ids = [];
              this.$message({
                showClose: true,
                message: "删除成功",
                type: "success",
              });
            })
            .catch(() => {
              this.multiple = false;
              this.ids = [];
              this.$message({
                showClose: true,
                message: "删除失败",
                type: "error",
              });
            });
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      this.pageSize = val;
      //this._mealRulesList()
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this._mealRulesList();
    },
    _mealRulesList() {
      let params = Object.assign(this.queryForm, {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      mealRulesList(params).then((res) => {
        this.pageNo = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
        this.tableData = res.rows;
      });
    },
    handleMealList(rowId) {
      //this.$router.push({ path: '/task/detail', query: { id: row.id} });
      this.$router
        .push({ path: "/mealRules/mealList/" + rowId })
        .catch(() => true);
    },
    handleUserList(rowId) {
      this.$router
        .push({ path: "/mealRules/userList/" + rowId })
        .catch(() => true);
    },
  },
};
</script>
