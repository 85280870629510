import request from './request.js'
export function dictSettingList(params) {
    return request({
        url: 'dict/list.do?',
        method: 'post',
        data:params
    })
}


export function findDictById(params) {
    return request({
        url: 'dict/findById.do?',
        method: 'post',
        data:params
    })
}

export function updateDict(params) {
    return request({
        url: 'dict/update.do?',
        method: 'post',
        data:params
    })
}

export function addDict(params) {
    return request({
        url: 'dict/add.do?',
        method: 'post',
        data:params
    })
}

export function customerInventoryList(params) {
    return request({
        url: 'stockStatics/listStaticsAgent.do?',
        method: 'post',
        data:params
    })
}


// 导出
export function exportCustomerInventory(params) {
    return request({
        url: 'stockStatics/exportStaticsAgentSync.do?',
        method: 'post',
        data:params
    })
}

export function outCodeList(params) {
    return request({
        url: 'stockBatchOut/listOutCode.do?',
        method: 'post',
        data:params
    })
}

export function salesCustomer(params) {
    return request({
        url: 'stockStatics/listSaleAgent.do?',
        method: 'post',
        data:params
    })
}

export function exportSalesCustomer(params) {
    return request({
        url: 'stockStatics/exportSaleAgentSync.do?',
        method: 'post',
        data:params
    })
}

export function inCodeList(params) {
    return request({
        url: 'stockBatchIn/listInCode.do?',
        method: 'post',
        data:params
    })
}

export function batchActiveRateList(params) {
    return request({
        url: 'stockStatics/listActiveRate.do?',
        method: 'post',
        data:params
    })
}

export function exportActiveRate(params) {
    return request({
        url: 'stockStatics/exportActiveRateSync.do?',
        method: 'post',
        data:params
    })
}

export function inCodeMonthList(params) {
    return request({
        url:'stockStatics/listInCodeMonth.do?',
        method:'post',
        data:params
    })
}

export function monthInventoryList(params){
    return request({
        url:'stockStatics/listMonth.do?',
        method:'post',
        data:params
    })
}

export function exportMonthInventory(params){
    return request({
        url:'stockStatics/exportMonthSync.do?',
        method:'post',
        data:params
    })
}


