<template>
	<div class="tag-input">
		<el-tag size="small" :key="tag" v-for="tag in value" closable :disable-transitions="false" @close="handleClose(tag)">
			{{tag}}
		</el-tag>
		<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
		 @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm"></el-input>
		<el-button v-else class="button-new-tag" @click="showInput">添加</el-button>
	</div>
</template>

<script>
	export default {
		props:{
			value:Array
		},
		data() {
			return {
				
				inputVisible: false,
				inputValue: ''
			}
		},
		created() {

		},
		methods: {
			handleClose(tag) {
				this.value.splice(this.value.indexOf(tag), 1);
				this.$emit('input',this.value)
			},

			showInput() {
				this.inputVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					this.value.push(inputValue);
					this.$emit('input',this.value)
				}
				this.inputVisible = false;
				this.inputValue = '';
			}
		}
	}
</script>

<style>
	.tag-input .el-tag+.el-tag {
		margin-left: 10px;
	}

	.tag-input .button-new-tag {
		margin-left: 10px;
		height: 25px;
		line-height: 25px;
		padding-top: 0px;
		padding-bottom: 2px;
	}

	.tag-input .input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}
	.tag-input .input-new-tag .el-input__inner{
		height: 25px;
	}
</style>
