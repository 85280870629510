<template>
    <div class="fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">禁网操作记录</span>
            <el-form inline ref="form" v-model="form" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="IMEI:">
                    <el-input v-model="form.imei" class="iccid-width"></el-input>
                </el-form-item>

                 <el-form-item label="虚拟ICCID:">
                    <el-input v-model="form.virtualIccid" class="iccid-width"></el-input>
                 </el-form-item>

                 <el-form-item label="chipid:">
                    <el-input v-model="form.chipid" class="iccid-width"></el-input>
                 </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
                    <el-button type="primary" icon="el-icon-download" class="themed-button" @click="exportExcel">导出excel</el-button>
                </el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                 show-overflow-tooltip
                    prop="imei"
                    label="IMEI"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="chipid"
                    label="chipId"
                    min-width="120"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="virtualIccid"
                    label="虚拟号"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="type"
                    label="禁网类型"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==1">禁用插拔卡</span>
                        <span v-else-if="scope.row.type==2">禁用全部卡</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="simCards"
                    label="SIM卡号"
                    min-width="150"
                    >
                </el-table-column>
               
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="更新时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="160">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
                
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { getBanNetworkRecordList, banNetworkRecordExport, banNetworkRecordDelete} from "@/api/virtualCard.js"
export default {
    name:'BnadNetworkRecord',
    mixins:[resize],
    data(){
        return {
            form:{
                imei:'',
                virtualIccid:'',
                chipid:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getBanNetworkRecordList()
    },
    methods: {
        getBanNetworkRecordList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.form,params)
            getBanNetworkRecordList(this.form).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getBanNetworkRecordList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getBanNetworkRecordList()
        },

        onSearch(){
            this.page=1
            this.getBanNetworkRecordList()
        },

        handleDelete(id){
            this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    banNetworkRecordDelete({'id':id}).then(res=>{
                        this.$message({
								type: 'success',
								message:'删除成功'
                            });
                        this.getBanNetworkRecordList()
                    })
                    
                })
        },

        exportExcel(){
            banNetworkRecordExport(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },  
    },
}
</script>

<style>
.el-form-item__label {
		vertical-align: top;
	}
</style>