<template>
  <div class="strategy fadeInRight">
    <el-form ref="addForm" style="max-height: 500px; overflow-y: scroll">
      <el-table :data="tableList" style="margin-top: 15px">
        <el-table-column label="区间初始值(MB)" prop="" min-width="90">
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.start"
              size="mini"
              style="width: 80%"
              disabled
            ></el-input>
            <span> -</span>
          </template>
        </el-table-column>
        <el-table-column label="区间结束值(MB)" prop="" min-width="90">
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.end"
              size="mini"
              style="width: 80%"
              disabled
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="单价(元/M)" prop="" min-width="90">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.unitPrice" :precision="4" :step="0.1" :min="0.0001"  :disabled="!isDialogAdd"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="150" v-if="isDialogAdd">
          <template slot-scope="scope">
            <el-button
              type="danger"
              plain
              size="mini"
              :disabled="scope.row.end == 99999999"
              @click="deleteParams(scope.row, scope.$index)"
              >删除</el-button
            >
            <el-button
              type="info"
              plain
              size="mini"
              :disabled="isDisable"
              @click="afterAddParams(scope.row, scope.$index)"
              >插入一行</el-button
            >
          </template>
        </el-table-column>
      </el-table>
     
      <!-- <span
        >举例说明：[10,0.1;20,0.2;30,0.3;40,0.4]表示10G以下单价0.1元;10G~20G单价0.2元;20G~30G单价0.3元;30G~40G及以上单价0.4元"</span
      > -->
      <div class="align-right">
        <el-button type="primary" class="themed-button" @click="cancle"  v-if="isDialogAdd"
          >取消</el-button
        >
        <el-button type="primary" class="themed-button" @click="sure"
          >确认</el-button
        >
      </div>
    </el-form>

    <el-dialog
      title="区间值设置"
      :visible.sync="dialogCheckVisible"
      width="600"
      v-if="dialogCheckVisible"
      append-to-body
    >
      <el-form :model="checkForm" ref="checkForm">
        <el-form-item label="区间值(G)">
          <el-input
            v-model="checkForm.insertValue"
            autocomplete="off"
            class="inputWidth"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="success" @click="sureChecked()"
          >确 认</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogCheckVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import { messageBox, confirmBox } from "@/utils/common.js";
export default {
  name: "strategy",
  mixins: [resize],
  props: ["totalValue","isDialogAdd"],
  data() {
    return {
      tableList: [
       
      ],
      dialogCheckVisible: false,
      checkForm: {
        insertValue: "",
      },
      currentIndex: "",
      currentTable:this.totalValue
    };
  },
  created() {
    console.log(this.currentTable)
    if (  this.currentTable.length >0 ) {
   this.tableList = this.currentTable
      
    } else {
      this.tableList.push({
        start: "0",
        end: "99999999",
        unitPrice: 1,
      });
    }
  },
  computed: {
    isDisable() {
      if (this.tableList.length >= 20) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    sureChecked() {
      let checkInt = /^[1-9]\d*$/;
      if (!checkInt.test(this.checkForm.insertValue)) {
        messageBox(this, "插入区间值只能是大于0的整数！");
        return;
      }
      if (
        parseInt(this.checkForm.insertValue) >=
          this.tableList[this.currentIndex].end ||
        parseInt(this.checkForm.insertValue) <=
          this.tableList[this.currentIndex].start
      ) {
        messageBox(
          this,
          "插入区间结束值应该大于该区间起始值小于区间结束值！"
        );
        return;
      }
      this.dialogCheckVisible = false;

      this.tableList.splice(parseInt(this.currentIndex) + 1, 0, {
        start: this.checkForm.insertValue,
        end: this.tableList[parseInt(this.currentIndex)].end,
        unitPrice: 1,
      });
      this.tableList[parseInt(this.currentIndex)].end =
        this.checkForm.insertValue;
    },

    deleteParams(data, index) {
      if (this.tableList.length > 1) {
        this.tableList[index + 1].start = this.tableList[index].start;
      }
      this.tableList.splice(parseInt(index), 1);
    },
    afterAddParams(data, index) {
      console.log(index);
      this.dialogCheckVisible = true;
      this.currentIndex = index;
      this.checkForm.insertValue = "";
    },
    cancle() {
      this.$emit("close");
    },

    sure() {
      //校验
      if(this.isDialogAdd){
      console.log(this.tableList)
      if (this.tableList.length > 0) {
        let value = "";
        for (let i of this.tableList) {
            console.log(i.unitPrice.toString())
          if (i.unitPrice.toString().trim() == "") {
            messageBox(this, "请输入单价");
            return;
          }
        }
        value = this.tableList
        this.$emit("close", value);
      }
      }else{
        this.cancle()
      }
     
    },
  },
};
</script>

<style>
.strategy .el-form-item__label {
  vertical-align: top;
}
.strategy .inputWidth {
  width: 50%;
}
</style>