<template>
<div>
         <el-form :model="addForm"  ref="addForm" label-width="150px" style="max-height:500px;overflow:auto" >
            
         
             
                <el-form-item label="运营商" prop="accountId"  >
                    <el-select v-model="addForm.accountId" filterable placeholder="运营商"  disabled >
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="速率" prop="speedVal" >
                    <el-select v-model="addForm.speedVal" placeholder="请选择" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(item,index) in speedList" :key='index' :label="item.speedKey" :value="item.speedValue"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="批次" prop="batchNo">
                    <el-input v-model="addForm.batchNo" style="width:205px"></el-input>
                </el-form-item>
                  <el-form-item label="ICCID:" >
             <el-input v-model="addForm.iccids" type="text" disabled></el-input>
                  </el-form-item>
                
            </el-form>
            <div class="align-right">
        <el-button type="primary" class="themed-button" @click="comfirmImport('addForm')">确认</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
            </div>
</div>
</template>
<script>
import resize from '@/mixins/resize.js'
import {agentOperatorList} from '@/api/order.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import Utils from '@/utils/utils.js'

import {getSpeedList} from '@/api/virtualCard/accountRateList.js'
import {singleSpeedList,importAddSyncSingleSpeed,exportSingleSpeedLimit} from "@/api/speedLimit/index.js";
export default {
     mixins:[resize],
     props:["iccidMark","accountId"],
    data(){
        return {
            accountIdList:[],
            addForm:{
                type:"1",
             accountId:"",
             speedVal:"",
             speedName:"",
             batchNo:"",
             iccids:this.iccidMark,
            },
            speedList:[],
        }
           
    },
    created(){
        this.getAgentOperatorList()
       this.getspeedList()
    },
    methods: {
        getAgentOperatorList(){
            agentOperatorList().then(res=>{
                this.accountIdList = res 
                this.addForm.accountId = this.accountId      
             })
        },

       
        getspeedList(){
          this.speedList=[]
            getSpeedList({accountId:this.accountId}).then(res=>{
                let list= res.rows
                if(list.length>0){
                    list.forEach((item)=>{
                        this.speedList.push({speedKey:item.speedKey,speedValue:item.speedValue})
                    })
                }else{
                     messageBox(this,'该运营商无速率列表')
                         return     
                }
            })
        },
          
      
         
        cancle(){
         this.$emit("close")
        },
        
        comfirmImport(formName){
            let param = new FormData()
             param.append("type",this.addForm.type)
                     if(!!this.addForm.accountId){
                        param.append("accountId",this.addForm.accountId)
                      }else{
                             this.$alert('请选择运营商', '提示', {
							 confirmButtonText:'确定',
                           });
                         return
                            }
                   if(!!this.addForm.speedVal){
                        param.append("speedVal",this.addForm.speedVal)
                        let arrNew =    this.speedList.filter(item=>item.speedValue==this.addForm.speedVal)
                        console.log(arrNew)
                     this.addForm.speedName = arrNew[0].speedKey
                     param.append("speedName",this.addForm.speedName)
                      }else{
                             this.$alert('请选择速率', '提示', {
							 confirmButtonText:'确定',
                           });
                         return
                            }
                     param.append('iccids',this.addForm.iccids)
                      param.append("batchNo",this.addForm.batchNo)
                   importAddSyncSingleSpeed(param).then(res=>{
                                if (res.system_result_key == '0') {
                                    if(!!res.system_result_message_key){
                                  this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                    this.$emit("close")
                                  
                                    }else{
                                  this.$alert('添加成功', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                         this.$emit("close")
                                    });
                                    }
                                   
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                     this.dialogAddvisible = false
                                }
                            })
           
        },

    },
}
</script>

<style>
   
</style>