<template>
  <div class="balanceManagement fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">余额提现审核列表</span>
      <el-form
        :inline="true"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
        id="baseForm"
      >
        <el-form-item label="iccid:">
          <el-input v-model="form.iccid" placeholder="输入ICCID"></el-input>
        </el-form-item>
        <el-form-item label="短号:">
          <el-input v-model="form.shortNo" placeholder="输入短号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            class="themed-button"
            icon="el-icon-search"
            >搜索查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        ref="table"
        :max-height="maxTableHeight"
        border
      >
        <el-table-column prop="iccid" label="iccid"></el-table-column>
        <el-table-column prop="shortNo" label="短号"></el-table-column>
        <el-table-column prop="money" label="提现金额"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">待审核</span>
            <span v-else-if="scope.row.status == 2">通过</span>
            <span v-else-if="scope.row.status == 3">不通过</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="申请时间"></el-table-column>
        <el-table-column prop="userRemark" label="用户备注"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="themed-button"
              type="primary"
              @click="updateRecord(scope.row)"
              v-if="scope.row.status == 1"
              >审核</el-button
            >
            <el-button
              size="mini"
              class="themed-button"
              type="primary"
              @click="getDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="审核"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
    >
      <el-form :model="auditForm">
        <el-form-item label="审核状态" prop="status">
          <el-radio v-model="auditForm.status" label="2">通过</el-radio>
          <el-radio v-model="auditForm.status" label="3">不通过</el-radio>
        </el-form-item>
        <el-form-item label="审核备注" prop="auditRemark">
          <el-input
            v-model="auditForm.auditRemark"
            placeholder="请输入内容"
            style="width: 60%"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="info"
          @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="sureAudit"
          v-preventReClick="2000"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="详情"
      :visible.sync="dialogDetailVisible"
      :destroy-on-close="true"
    >
      <el-form :model="detailForm">
        <el-form-item label="Iccid:">
          <span>{{ detailForm.Iccid }}</span>
          <!-- <el-input v-model="detailForm.Iccid" :disabled="true"></el-input> -->
        </el-form-item>
        <el-form-item label="短号:">
          <span>{{ detailForm.shortNo }}</span>
          <!-- <el-input v-model="detailForm.shortNo" :disabled="true"></el-input> -->
        </el-form-item>
        <el-form-item label="提现金额:">
          <span>{{ detailForm.money }}</span>
        </el-form-item>
        <el-form-item label="审核状态:">
          <span v-if="detailForm.status == 1">待审核</span>
          <span v-else-if="detailForm.status == 2">通过</span>
          <span v-else-if="detailForm.status == 3">不通过</span>
        </el-form-item>
        <el-form-item label="申请时间:">
          <span>{{ detailForm.createTime }}</span>
        </el-form-item>
        <el-form-item label="用户备注:">
          <span>{{ detailForm.userRemark }}</span>
        </el-form-item>
        <el-form-item label="审核时间:">
          <span>{{ detailForm.auditTime }}</span>
        </el-form-item>
        <el-form-item label="审核人:">
          <span>{{ detailForm.auditBy }}</span>
        </el-form-item>
        <el-form-item label="审核备注:">
          <span>{{ detailForm.auditRemark }}</span>
          <!-- <el-input v-model="auditForm.auditRemark" ></el-input> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogDetailVisible = false"
          v-preventReClick="2000"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import {
  getWithdrawList,
  auditInfo,
  WithdrawDetail,
} from "@/api/withdrawList.js";
import { getDisablingBills } from "@/api/cardManagement/balanceManagement.js";
import { messageBox } from "@/utils/common.js";

export default {
  name: "withdrawList",
  mixins: [onresize],
  data() {
    return {
      form: {
        iccid: "",
        shortNo: "",
      },
      page: 1,
      pageSize: 15,
      total: 0,
      tabledata: [],
      dialogVisible: false,
      auditForm: {
        status: "3",
        id: "",
        auditRemark: "",
      },
      dialogDetailVisible: false,
      detailForm: {
        Iccid: "",
        shortNo: "",
        money: "",
        status: "",
        createTime: "",
        userRemark: "",
        auditTime: "",
        auditBy: "",
        auditRemark: "",
      },
      curMoney: "",
      curIccid: "",
    };
  },

  created() {
    this.getWithdrawList();
  },

  methods: {
    getWithdrawList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.form, params);
      getWithdrawList(this.form).then((res) => {
        this.tabledata = res.rows;
        console.log(this.tableData);
        this.total = res.count;
      });
    },

    searchData() {
      this.page = 1;
      this.getWithdrawList();
    },
    onPageChange(page) {
      this.page = page;
      this.getWithdrawList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getWithdrawList();
    },

    updateRecord(row) {
      this.curIccid = row.iccid;
      this.curMoney = row.money;

      this.dialogVisible = true;
      this.auditForm.id = row.id;
      this.auditForm.status = "3";
      this.auditForm.auditRemark = "";
    },
    // updateRecord(row) {
    //   let selections = [{ iccid: row.iccid }];
    //   console.log(selections);
    //   this.$router.push({
    //     path: "/networkCardBalanceManagement/balanceChangeRecord",
    //     query: selections[0],
    //   });
    // },
    sureAudit() {
      auditInfo(this.auditForm).then((res) => {
            console.log(res, 288);
            let key =
              parseInt(res.app_result_key) ||
              parseInt(res.system_result_key) ||
              0;
            let code = parseInt(res.code) || 0;
            if (code == 0 && key == 0) {
              messageBox(this, "审核完成");
              this.dialogVisible = false;
              this.getWithdrawList();
            } else {
            }
          });
    },
    getDetail(row) {
      this.dialogDetailVisible = true;
      WithdrawDetail({ id: row.id }).then((res) => {
        this.detailForm.Iccid = res.iccid;
        this.detailForm.shortNo = res.shortNo;
        this.detailForm.money = res.money;
        this.detailForm.status = res.status;
        this.detailForm.createTime = res.createTime;
        this.detailForm.userRemark = res.userRemark;
        this.detailForm.auditTime = res.auditTime;
        this.detailForm.auditRemark = res.auditRemark;
        this.detailForm.auditBy = res.auditBy;
      });
    },
  },
};
</script>

<style>
.balanceManagement .el-form-item__label {
  vertical-align: top;
  width: 100px;
}
</style>
