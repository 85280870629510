<template>

<div class="location table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">网卡定位信息</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="省份:">
                <search-select :optionsMetaAll="provinceList"  v-model="form.province" placeholder="请选择" @input="selProvince"></search-select>
            </el-form-item>
            <el-form-item label="市级:">
                <search-select :optionsMetaAll="cities"  v-model="form.city" placeholder="请选择" @input="selCity"></search-select>
            </el-form-item>
            <el-form-item label="地区:">
                <search-select :optionsMetaAll="towns"  v-model="form.town" placeholder="请选择"></search-select>
            </el-form-item>
            <el-form-item label="ICCID号:">
                <el-input v-model="form.iccidMark" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="代理商:">
                <el-input v-model="form.agentName"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-minus" class="themed-button" @click="rickClear">高危停机清除</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID号" prop="iccidMark" width="180px"></el-table-column>
            <el-table-column label="代理商" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="经度" prop="lon" show-overflow-tooltip></el-table-column>
            <el-table-column label="纬度" prop="lac" show-overflow-tooltip></el-table-column>
            <el-table-column label="省" prop="province" show-overflow-tooltip></el-table-column>
            <el-table-column label="市" prop="city" show-overflow-tooltip></el-table-column>
            <el-table-column label="区" prop="town" show-overflow-tooltip></el-table-column>
            <el-table-column label="地址详情" prop="areMsg" show-overflow-tooltip></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

</div>

</template>

<script type="text/ecmascript-6">
import {cardlocation,limitExportCount,cardStopClear} from '@/api/cardManagement/location.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import resize from '@/mixins/resize.js'

import {provinceList} from '@/api/area.js'


export default {
    name:'location',
    mixins:[resize],
    data(){
        return {
            form:{
                province:'',
                city:'',
                town:'',
                iccidMark:'',
                agentName:''
            },
            cityList:[],
            areaList:[],
            cities:[{label:'请选择',name:''}],
            towns:[{label:'请选择',name:''}],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0
        }
    },
    computed:{
        provinceList(){
            let pl = provinceList.map((item)=>{
                return  {
                    label:item.name,
                    value:item.name
                }
            })
            pl.unshift({
                label:'请选择',
                name:''
            })
            return pl
        }
    },
    mounted(){
        this._cardlocation()
    },
    methods:{
        rickClear(){
            if(this.form.iccidMark){
                confirmBox(this,'确定要清除吗?',null,()=>{
                    console.log(1)
                    let pamars = {
                        importType:2,
                        iccidMark:this.form.iccidMark
                    }
                    cardStopClear(pamars).then(res=>{
                        messageBox(this,'清除成功')
                        this._cardlocation()
                    })
                })
            }else{
                messageBox(this,'iccidMark不能为空')
            }
            
        },
        searchTable(){
            this._cardlocation()
        },
        selProvince(val){
            provinceList.forEach((item)=>{
                if(val === item.name){
                    this.cityList = item.cityList
                }
            })
            let cl = this.cityList.map((item)=>{
                return  {
                    label:item.name,
                    value:item.name
                }
            })
            cl.unshift({
                label:'请选择',
                name:''
            })
            this.cities = cl
        },
        selCity(val){
            this.cityList.forEach((item)=>{
                if(val === item.name){
                    this.areaList = item.areaList
                }
            })
            let al = this.areaList.map((item)=>{
                return  {
                    label:item,
                    value:item
                }
            })
            al.unshift({
                label:'请选择',
                name:''
            })
            this.towns = al
        },
        exportExcel(){
            // if(this.form.agentName === ''){
            //     messageBox(this,"代理商不能为空")
            //     return
            // }
            messageBox(this,"数据正在导出，如果导出的数据量较大，文件下载响应会稍慢，也会增加服务器负荷，请稍候片刻。\n请不要重复点击导出，如果需要重新导出，请刷新页面后重新操作。")
            limitExportCount(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._cardlocation()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._cardlocation()
        },
        _cardlocation(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                importType:2
            })
            cardlocation(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        }
    },
    components:{
        SearchSelect
    }
}

</script>
