<template>
  <div class="daheCardMeal fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">大河卡套餐统计</span>
      <el-form
        inline
        v-model="formInline"
        class="right themed-form search-form"
      >
        <el-form-item label="开始时间:">
          <el-date-picker
            style="width: 100%"
            v-model="formInline.startTime"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="结束时间:">
          <el-date-picker
            style="width: 100%"
            v-model="formInline.endTime"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >搜索查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="flowType" label="套餐名称"> </el-table-column>
        <el-table-column prop="useFlow" label="总用量(G)"> </el-table-column>
        <el-table-column prop="cardNum" label="充值卡数"> </el-table-column>
        <el-table-column prop="cardUseFlow" label="单卡平均用量(G)">
        </el-table-column>
        <el-table-column prop="money" label="销售总收入"> </el-table-column>
        <el-table-column prop="originalMoney" label="销售总成本(元)">
        </el-table-column>
        <el-table-column prop="rebateMone" label="返利金额(元)">
        </el-table-column>
        <el-table-column prop="singleRebateMoney" label="实际销售单价(元)">
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template v-slot:default="scope">
            <a href="javascript:;" @click="orderDetails(scope.row.flowSize)"
              >详情</a
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageNo"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import loading from "@/utils/Loading.js";
import onresize from "@/mixins/resize.js";
import { daheList } from "@/api/staticsManagement.js";
export default {
  name:'daheCardMeal',
  mixins: [onresize],
  data() {
    return {
      formInline: {
        id: "",
        startTime: "",
        endTime: "",
      },
      pageNo: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
    };
  },
  created() {
    this.getDaheList(this.formInline);
  },
  methods: {
    onSearch() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      Object.assign(params, this.formInline);
      this.getDaheList(params);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      Object.assign(params, this.formInline);
      this.getDaheList(params);
    },
    onPageChange(page) {
      this.pageNo = page;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      Object.assign(params, this.formInline);
      this.getDaheList(params);
    },
    getDaheList(params) {
      daheList(params).then((res) => {
        this.tableData = res.rows;
        this.pageNo = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    orderDetails(flowSize) {
        let selections = [{
            "startTime":this.formInline.startTime,
            "endTime":this.formInline.endTime,
            "flowSize":flowSize
        }]
        this.$router.push({
            path:"/staticsManagement/daheMealDetail",
            query:selections[0],
        })
    },
  },
};
</script>
<style >
.daheCardMeal  .el-form-item__label{
  vertical-align: top;
}
</style>