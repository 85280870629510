<template>
    <div class="system-interface-authority fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">接口权限管理</span>
            <el-form inline ref="interfaceAuthorityForm" v-model="interfaceAuthorityFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
               <el-form-item>
					<el-button icon="el-icon-circle-plus" class="themed-button" type="info" @click="add('')">增加</el-button>
				</el-form-item>

                <!-- <el-form-item>
					<el-button icon="el-icon-caret-bottom" class="themed-button" type="info" @click="expandall()">展开</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-caret-top" class="themed-button" type="info" @click="isExpand = false">折叠</el-button>
				</el-form-item> -->

                <el-form-item>
					<el-button icon="el-icon-refresh" class="themed-button" type="info" @click="reload()">刷新</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table      
                ref="multipleTable"
                 :data="tableData"
                style="width: 100%"
                row-key="id"
                border
                stripe
                lazy
                :load='load'
                :default-expand-all="false"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column
                type="index"
                min-width="50">
                </el-table-column>

                <el-table-column
                show-overflow-tooltip
                label="类别名称"
                prop="text"
                min-width="180">
                </el-table-column>

                <!-- <el-table-column
                show-overflow-tooltip
                prop="menuUrl"
                label="跳转路径"
                min-width="200">
                </el-table-column>

                <el-table-column
                show-overflow-tooltip
                prop="serviceUrl"
                label="接口路径"
                min-width="200">
                </el-table-column> -->
                <el-table-column
                show-overflow-tooltip
                prop="signHtml"
                label="显示名称"
                min-width="200">
                </el-table-column>

                <el-table-column
                show-overflow-tooltip
                prop="iconCls"
                label="显示图标"
                min-width="200">
                </el-table-column>
                 <el-table-column
                show-overflow-tooltip
                prop="htmlUrl"
                label="显示路径"
                min-width="200">
                </el-table-column>

                <el-table-column
                show-overflow-tooltip
                prop="permission"
                label="权限标识"
                min-width="120">
                </el-table-column>

                <el-table-column
                    label="操作"
                    min-width="180">
                    <template slot-scope="scope">
                        <el-button
                        v-if="scope.row.type!=3"
                        size="mini"
                        class="themed-button" type="primary"
                        @click="add(scope.row.id)"
                        >添加</el-button>
                        <el-button
                        size="mini"
                        class="themed-button" type="info"
                        @click="handleEdit(scope.row.id,scope.row.text,scope.row.signHtml,scope.row.iconCls,scope.row.permission,scope.row.htmlUrl,scope.row.chargeFlag,scope.row.parentMenuId)"
                        >修改</el-button>
                        <el-button
                        size="mini"
                        class="themed-button" type="danger"
                        @click="handleDelete(scope.row.id,scope.row.parentMenuId)"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog title="添加节点" :visible.sync="addDialogFormVisible" :destroy-on-close='true'>
            <el-form :model="addForm" :rules="rules" ref="addForm">
                <el-form-item label="菜单名称" :label-width="formLabelWidth" prop="resourceName">
                <el-input v-model="addForm.resourceName" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="接口路径" :label-width="formLabelWidth" >
                <el-input v-model="addForm.serviceUrl" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="跳转路径" :label-width="formLabelWidth" >
                <el-input v-model="addForm.menuUrl" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权限标识" :label-width="formLabelWidth" >
                <el-input v-model="addForm.permission" autocomplete="off"></el-input>
                </el-form-item> -->
                 <el-form-item label="显示名称" :label-width="formLabelWidth" >
                <el-input v-model="addForm.signHtml" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="显示图标" :label-width="formLabelWidth" >
                <el-input v-model="addForm.iconCls" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="显示路径" :label-width="formLabelWidth" >
                <el-input v-model="addForm.htmlUrl" autocomplete="off"></el-input>
                </el-form-item>
                    <el-form-item label="权限标识" :label-width="formLabelWidth" v-if="isShowPermission" >
                <el-input v-model="addForm.permission" autocomplete="off"></el-input>
                </el-form-item>
                 <el-form-item label="是否为收费项" :label-width="formLabelWidth"   >
        <el-radio-group v-model="addForm.chargeFlag">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>



            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureSubmit('addForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改节点" :visible.sync="editDialogFormVisible" :destroy-on-close='true'>
            <el-form :model="editForm" :rules="rules" ref="editForm">
                <el-form-item label="菜单名称" :label-width="formLabelWidth" prop="resourceName">
                <el-input v-model="editForm.resourceName" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="接口路径" :label-width="formLabelWidth" >
                <el-input v-model="editForm.serviceUrl" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="跳转路径" :label-width="formLabelWidth" >
                <el-input v-model="editForm.menuUrl" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="显示名称" :label-width="formLabelWidth" >
                <el-input v-model="editForm.signHtml" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="显示图标" :label-width="formLabelWidth" >
                <el-input v-model="editForm.iconCls" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="显示路径" :label-width="formLabelWidth" >
                <el-input v-model="editForm.htmlUrl" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="权限标识" :label-width="formLabelWidth" >
                <el-input v-model="editForm.permission" autocomplete="off"></el-input>
                </el-form-item>
                 <el-form-item label="是否为收费项" :label-width="formLabelWidth" >
        <el-radio-group v-model="editForm.chargeFlag">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEdit('editForm')">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import resize from '@/mixins/resize.js'
import { interfaceAuthorityTree,interfaceAddTree,interfaceEditTree, interfaceDeleteTree} from "@/api/systemManagement.js";
export default {
    name:'interfaceAuthority',
    mixins:[resize],
    data(){
        return {
            // isCustomer:  this.isGuangyao,
            interfaceAuthorityFormData:{},
            tableData:[],
            open: true,
            close:false,
            isExpand:false,
            addDialogFormVisible:false,
            addId:'',
            addForm:{
                id:'',
                jihuotype:'2',
                resourceName:'',
                // serviceUrl:'',
                // menuUrl:'',
                // permission:'',
                signHtml:"",
                iconCls:"",
                htmlUrl:"",
                permission:"",
                chargeFlag:false,
            },
            rules:{
                resourceName:[
                    {required: true, message: '请输入菜单名称', trigger: 'blur'}
                ],
            },
            formLabelWidth:'150px',
            editDialogFormVisible:false,
            editId:'',
            editForm:{
                id:'',
                jihuotype:'2',
                resourceName:'',
                // serviceUrl:'',
                // menuUrl:'',
                signHtml:"",
                iconCls:"",
                htmlUrl:"",
                permission:'',
                chargeFlag:"",
                parentMenuId:"",
            },
            isShowPermission:false,
            maps: new Map(),
        }
    },
    created(){
        this.getInterfaceAuthorityList()
    },
    methods:{
        refresh(parentId) {
 
// 根据父级id取出对应节点数据
 
const { tree, treeNode, resolve } = this.maps.get(parentId)
 
this.$set(this.$refs.multipleTable.store.states.lazyTreeNodeMap, parentId, [])
 
if (tree) {
 
this.load(tree, treeNode, resolve)
 
}
 
},

        getInterfaceAuthorityList(){
            interfaceAuthorityTree().then(res=>{
                this.tableData = res.rows
            })
        },
        load(tree, treeNode, resolve){
            this.maps.set(tree.id, { tree, treeNode, resolve })
            setTimeout(()=>{
                interfaceAuthorityTree({'id':tree.id}).then(res=>{
                    resolve(res.rows)
                })
            },100)
        },
        expandall() {
            let els = this.$el.getElementsByClassName('el-table__expand-icon')
				if (this.tableData.length != 0 && els.length != 0) {
					for (let j1 = 0; j1 < els.length; j1++) {
						els[j1].classList.add("agentListDafult")
					}
					if (this.$el.getElementsByClassName('el-table__expand-icon--expanded')) {
						const open = this.$el.getElementsByClassName('el-table__expand-icon--expanded')
						for (let j = 0; j < open.length; j++) {
							open[j].classList.remove("agentListDafult")
						}
						const dafult = this.$el.getElementsByClassName('agentListDafult')
						
						for (let a = 0; a < dafult.length; a++) {
							dafult[a].click()
						}
					}
				}
        },
        add(id){
            this.addDialogFormVisible=true
            this.addId = id
            if(id){
              this.isShowPermission = true
            }else{
                this.isShowPermission = false
            }
        },
        sureSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$confirm('确定要导入吗', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.addForm.id = this.addId
                        interfaceAddTree(this.addForm).then(res=>{
                            this.$message({
                            type: 'success',
                            message: '添加成功!',
                            });
                            this.addDialogFormVisible=false
                            this.getInterfaceAuthorityList()
                             if(this.addId){
                             this.refresh(this.addId)
                            }
                            this.addForm.resourceName=''
                            this.addForm.signHtml=''
                            this.addForm.iconCls=''
                            this.addForm.htmlUrl=''
                            this.addForm.id=''
                            this.addForm.permission =""
                            this.addForm.chargeFlag = false
                        })
                        
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        reload(){
            location.reload()
        },
        handleEdit(id,resourceName,signHtml,iconCls,permission,htmlUrl,chargeFlag,parentMenuId){
            this.editDialogFormVisible = true
            this.editId = id
            this.editForm.resourceName = resourceName
            this.editForm.signHtml = signHtml
            this.editForm.iconCls = iconCls
            this.editForm.permission = permission
             this.editForm.htmlUrl = htmlUrl
             this.editForm.chargeFlag = chargeFlag == ""?false:chargeFlag
             this.editForm.parentMenuId = parentMenuId
        },
        sureEdit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$confirm('确定要导入吗', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.editForm.id = this.editId
                        interfaceEditTree(this.editForm).then(res=>{
                            this.$message({
                            type: 'success',
                            message: '编辑成功!',
                            });
                            this.editDialogFormVisible=false
                            this.getInterfaceAuthorityList()

                            // console.log( this.maps.get(this.editForm.parentMenuId),324)
                            if(this.editForm.parentMenuId){
                             this.refresh(this.editForm.parentMenuId)
                            }
                            this.editForm.resourceName=''
                            this.editForm.signHtml=''
                            this.editForm.iconCls=''
                            this.editForm.permission=''
                            this.editForm.id=''
                            this.editForm.htmlUrl=''
                            
                        })
                        
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleDelete(id,parentMenuId){
            this.$confirm('确定要删除吗', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        interfaceDeleteTree({'id':id}).then(res=>{
                            this.$message({
                            type: 'success',
                            message: '删除成功!',
                            });
                            this.getInterfaceAuthorityList()
                            if(parentMenuId){
                          this.refresh(parentMenuId)
                            }
                        })
                        
                    })
        }
    },
     computed: {
    ...mapGetters(['isGuangyao']),
  },
}
</script>