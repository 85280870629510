<template>
	<div class="trafficStatistics fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">流量统计</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto; line-height: 40px" :model="form">
				<el-form-item>
					<el-select v-model="form.accountId"    filterable	placeholder="请选择运营商" >
						 <el-option label="全部" value=""></el-option>
						<el-option v-for="(item, index) in accountList" :key="index" :label="item.accountName" :value="item.accountId">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-select v-model="form.agentId"   filterable	placeholder="请选择代理商" >
						 <el-option label="全部" value=""></el-option>
						<el-option v-for="(item, index) in agentIdList" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item >
                <el-input v-model="form.iccid" placeholder="卡号"></el-input>
                </el-form-item>
                <el-form-item >
					<el-date-picker style="width: 100%;" placeholder="请选择开始时间" v-model="form.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item >
					<el-date-picker style="width: 100%;" placeholder="请选择结束时间" v-model="form.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索
					</el-button>
				</el-form-item>
                    
				<el-form-item>
                    <el-button type="primary" class="themed-button" icon="el-icon-back" @click="exportExcel()">导出
					</el-button>
				</el-form-item>
			</el-form>
		</div>
        <div class="right" style="height: auto; line-height: 40px;padding: 0px 20px;">
            <span class="level1">汇总</span>     <span class="level2">流量:  </span><span  class="level2Content">{{flowSum}}</span>  <span class="level2">成本:  </span><span class="level2Content">{{priceSum}}</span> <span class="level2">单价:  </span><span class="level2Content">{{price}}</span>
        </div>
		<div class="content-box">
			<el-table :data="tabledata" stripe style="width: 100%" border ref="table" :max-height="maxTableHeight">
				<el-table-column prop="iccid" label="卡号"> </el-table-column>
                <el-table-column prop="accountName" label="运营商"> </el-table-column>
				<el-table-column prop="agentname" label="代理商"> </el-table-column>
				<el-table-column prop="flow" label="流量"> </el-table-column>
				<el-table-column prop="price" label="成本"> </el-table-column>
                <el-table-column prop="unitPrice" label="单价"> </el-table-column>
				
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10, 15, 25, 100]" :page-size="pageSize"
				style="text-align: left; margin-top: 20px" background layout="total,sizes,prev, pager, next,jumper"
				:total="total" @size-change="handleSizeChange" @current-change="onPageChange">
			</el-pagination>
		</div>
		
	</div>
</template>

<script>
	import onresize from "@/mixins/resize.js";
	
	import {
		getTrafficStatisticsList,
		exportTrafficStatisticsList,
	} from "@/api/operationalStatistics/trafficStatistics.js"
	import {
		searchParamCheck
	} from "@/api/operationalStatistics/operationalStatistics.js";
	import {
		messageBox
	} from "@/utils/common.js";

	export default {
		name: "trafficStatistics",
		mixins: [onresize],
		data() {
			
			return {
				page: 1,
				pageSize: 15,
				total: 0,
				tabledata: [],
				formLabelWidth: "100px",
				form: {
					accountId: "",
					agentId: "",
                    iccid:"",
                    startTime:'',
                    endTime:''
				},
				accountList: [],
				agentIdList: [],
                price:0,
                priceSum:0,
                flowSum:0,
			};
		},
		created() {
			// this.getList();
			this.getAccountAgentList();
		},

		methods: {
			getAccountAgentList() {
				searchParamCheck().then((res) => {
					this.agentIdList = res.agents;
					this.accountList = res.account;
				});
			},
			getList() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				};
                Object.assign(params,this.form)
				getTrafficStatisticsList(params).then((res) => {
					console.log(res);
					this.tabledata = res.rows;
					console.log(this.tabledata)
					this.total = res.count;
                    this.price = res.price
                    this.priceSum = res.priceSum
                    this.flowSum = res.flowSum
				});
			},
			handleSizeChange(size) {
				this.pageSize = size;
				this.getList();
			},
			onPageChange(page) {
				this.page = page;
				this.getList();
			},
			searchData() {
				this.page = 1;
                if(this.form.accountId==""){
                  messageBox(this,'请选择运营商！')
                return 
                }
                if(this.form.agentId==""){
                messageBox(this,'请选择代理商！')
                return  
                }
				console.log(this.form.startTime)
                if(this.form.startTime==""){
                 messageBox(this,'请选择开始时间！')
                return  
                }
				if(this.form.endTime==""){
                 messageBox(this,'请选择结束时间！')
                return  
                }
				this.getList();
			},
             exportExcel(){
            exportTrafficStatisticsList(this.form).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
			
		},
	};
</script>

<style>
	.right_agent {
		margin-right: 8px;
	}

	.trafficStatistics .el-form-item__label {
		vertical-align: top;
	}

	.trafficStatistics .el-input--small {
		width: 105%;
	}
	.trafficStatistics .el-select .el-input.is-focus .el-input__inner{
		width: 120px;
	}
	.trafficStatistics .search-form .el-input .el-input__inner{
		width: 120px;
	}
	.trafficStatistics .level1 {color: #676a6c; font-weight: 700;}
	.trafficStatistics .level2{margin-left: 10px;}
	.trafficStatistics .level2Content{margin-left: 10px;}

	/* .el-input {
		width: 297px;
	} */
</style>
