<template>

<div class="widthdraw">
    <el-form label-position="right" label-width="200px" ref="form" :model="form">
        <el-form-item label="截止时间:" prop="endDate" >
            <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="form.endDate"  @change="updateMoney" :picker-options="pickeroptions"></el-date-picker>
        </el-form-item>
        <el-form-item label="本次可提现金额:" >
            <span>{{form.money}}</span>
        </el-form-item>
        <el-form-item v-if="type==1" label="提现方式:" >
            <el-select v-model="form.type" @change='changePayType'>
                <el-option label="微信" value="1"></el-option>
                <el-option label="支付宝" value="4"></el-option>
                <el-option label="银行卡" value="5"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="type!=1" label="提现方式:" >
            <span>{{form.payType}}</span>
        </el-form-item>
        <!-- <el-form-item label="收款账户名:" >
            <span>{{name}}</span>
        </el-form-item> -->
        <el-form-item label="明细:">
            <a href="javascript:void(0);" @click="download">下载</a>
        </el-form-item>
        <el-form-item label="备注">
            <el-input show-word-limit maxlength="50" placeholder="最多50个字"  type="textarea" v-model="form.remark" style="width:250px"></el-input>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="onSure" v-preventReClick='1000'>确定</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
    import {getCountMoney,agentRebateApply,moneyDetailSync} from '@/api/agentApplyCheck.js'
    import {messageBox,confirmBox} from '@/utils/common.js'
    import {exportTemplate} from '@/api/statistics.js'

export default {
    props:['type','startTime','endTime','name'],
    data(){
        return {
            form:{
                endDate:'',
                money:'请选择截止时间',
                type:'',
                payType:'',
                remark:''
            },
            pickeroptions: {
                disabledDate : time => {
                    let startTime =  new Date(this.startTime.replace(/\-/g, "\/")).getTime()
                    let endTime = this.endTime?new Date(this.endTime.replace(/\-/g, "\/")).getTime():((new Date()).getTime() - 24*60*60*1000)
                    if(time.getTime()< startTime|| time.getTime() > endTime){
                        return true
                    }else{
                        return false
                    }
                }
		    }
        }
    },
    mounted(){
        this.renderData()
    },
    created(){
        this.form.type = this.type+''
    },
    methods:{
        // download(){
        //     exportTemplate({name}).then(res=>{
        //         const filename = name
        //         Utils.createDownload(res,filename)
        //     })
        // },

        changePayType(type){
            if(type == 1){
                this.form.payType = '微信'
            }else if(type == 4){
                this.form.payType = '支付宝'
            }else if(type == 5){
                this.form.payType = '银行卡'
            }
        },
        download(){
            moneyDetailSync(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$emit('close')
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        onSure(){
            if(this.form.money == 0){
               messageBox(this,'当前选择的日期范围无可提现金额！')
               return 
            }
            if (isNaN(this.form.money)) {
                messageBox(this,"请选择日期！")
                return;
            }
            agentRebateApply(this.form).then((res)=>{
                this.$emit('close')
            })
        },
        updateMoney(){
            getCountMoney({endDate2:this.form.endDate}).then((res)=>{
                this.form.money = res.money
            })
        },
        renderData(){
            if(this.type==1){
                this.form.payType = '微信'
            }else if(this.type==2){
                this.form.payType = '线下'
            }else if(this.type==3){
                this.form.payType = '转预存'
            }
        }
    }
}
</script>
