<template>
  <div class="operationalInter fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">运营商接口列表</span>
      <el-form
        inline
        ref="quaryForm"
        v-model="quaryForm"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="运营商">
          <el-select filterable v-model="quaryForm.accountId">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(item, index) in operaterList"
              :key="index"
              :label="item.accountName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接口名称">
          <el-select filterable v-model="quaryForm.interfaceName">
            <el-option value="" label="全部"></el-option>
            <el-option
              v-for="(item, index) in w_interfaceName"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>

          <!-- <el-input
            v-model="quaryForm.interfaceName"
            placeholder="接口名称"
            class="iccid-width"
          ></el-input> -->
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-circle-plus"
            class="themed-button"
            type="info"
            @click="add()"
            >新增
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        border
        ref="table"
        stripe
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          show-overflow-tooltip
          prop="accountId"
          label="运营商id"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="accountName"
          label="运营商"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="interfaceName"
          label="接口名称"
          min-width="150"
        >
          <template slot-scope="scope">
            {{ _getInterfaceName(scope.row.interfaceName) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="interfaceParam"
          label="接口参数"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="token"
          label="token"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="frequency"
          label="频次"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="updateTime"
          label="更新时间"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="updateUser"
          label="操作人"
          min-width="150"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="themed-button"
              type="info"
              @click="updateRecord(scope.row)"
              >编辑</el-button
            >
            <!-- <el-button size="mini" class="themed-button" type="info" @click="validateAccount(scope.row.id)">
							验证</el-button> -->
            <el-button
              type="danger"
              class="themed-button"
              @click="removeList(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="remark"
          label="备注"
          min-width="150"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageNo"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        :model="addForm"
        ref="addForm"
        style="max-height: 500px; overflow-y: scroll"
      >
        <el-form-item label="运营商" :label-width="formLabelWidth">
          <el-select
            filterable
            v-model="addForm.accountId"
            @change="operatorChange"
          >
            <el-option label="选择运营商" value=""></el-option>
            <el-option
              v-for="(item, index) in operaterList"
              :key="index"
              :label="item.accountName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="接口名称" :label-width="formLabelWidth">
          <!-- <el-input
            v-model="addForm.interfaceName"
            autocomplete="off" class="inputWidth"
          ></el-input> -->
          <el-select filterable v-model="addForm.interfaceName">
            <el-option
              v-for="(item, index) in w_interfaceName"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="频次" :label-width="formLabelWidth">
          <el-input
            v-model="addForm.frequency"
            autocomplete="off"
            class="inputWidth"
          ></el-input>
        </el-form-item>
        <el-form-item label="参数类型" :label-width="formLabelWidth">
          <el-select
            filterable
            v-model="addForm.type"
            @change="changeParamsType"
          >
            <el-option label="json串" value="0"></el-option>
            <el-option label="页面录入" value="1"></el-option>
          </el-select>
        </el-form-item>

        <div v-if="isJson">
          <el-form-item label="接口参数" :label-width="formLabelWidth">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="接口参数"
              v-model="addForm.interfaceParam"
              style="width: 80%"
            >
            </el-input>
          </el-form-item>
        </div>
        <div v-if="isKeyValue">
          <!-- <el-table :data="tableList" style="margin-top: 15px">
            <el-table-column label="KEY" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.keys"
                  size="mini"
                  style="width: 80%"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="VALUE" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.valuse"
                  size="mini"
                  style="width: 80%"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="deleteParams(scope.row, scope.$index)"
                  >删除</el-button
                >
                <el-button
                  type="info"
                  plain
                  size="mini"
                  @click="afterAddParams(scope.row, scope.$index)"
                  >新增一行</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <el-col :span="12">
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  class="themed-button"
                  type="primary"
                  @click="addOneParams"
                  >新增一行</el-button
                >
              </el-form-item>
            </el-col>
          </el-row> -->

          <el-form-item
            label="接口基础设置:"
            :label-width="formLabelWidth"
            style="font-weight: bold"
          >
          </el-form-item>

          <el-form-item
            label="账户名(AccountCode)"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="interfaceParamData.accountCode"
              autocomplete="off"
              class="inputWidth"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码(Password)" :label-width="formLabelWidth">
            <el-input
              v-model="interfaceParamData.accountPwd"
              autocomplete="off"
              class="inputWidth"
            ></el-input>
          </el-form-item>
          <el-form-item label="密钥(secretKey)" :label-width="formLabelWidth">
            <el-input
              v-model="interfaceParamData.secretKey"
              autocomplete="off"
              class="inputWidth"
            ></el-input>
          </el-form-item>
          <div v-if="ifToken && platformCode!='guangyao'">
            <el-form-item
              label="获取接口token设置:"
              :label-width="formLabelWidth"
              style="font-weight: bold"
            >
            </el-form-item>

            <el-form-item
              label="同步间隔时间"
              :label-width="formLabelWidth"
              v-if="!isOnlink"
            >
              <el-input
                v-model="refreshToken.interval"
                autocomplete="off"
                class="inputWidth"
              ></el-input>
            </el-form-item>
            <el-form-item :label-width="formLabelWidth" v-if="isOnlink">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入json串"
                v-model="tokenParams"
                style="width: 300px"
              >
              </el-input>
              <span style="color: #f00; display: block"
                >请输入获取token接口的配置信息，以json方式输入。</span
              >
            </el-form-item>
          </div>
          <div v-if="isApiRealName && platformCode!='guangyao'">
            <el-form-item
              label="实名接口配置:"
              :label-width="formLabelWidth"
              style="font-weight: bold"
            >
            </el-form-item>
            <el-form-item
              label="protocol"
              :label-width="formLabelWidth"
              v-if="isdsp"
            >
              <el-input
                v-model="apiRealName.protocol"
                autocomplete="off"
                class="inputWidth"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="custId"
              :label-width="formLabelWidth"
              v-if="isdsp"
            >
              <el-input
                v-model="apiRealName.custId"
                autocomplete="off"
                class="inputWidth"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="appKey"
              :label-width="formLabelWidth"
              v-if="isdsp"
            >
              <el-input
                v-model="apiRealName.appKey"
                autocomplete="off"
                class="inputWidth"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="appSecret"
              :label-width="formLabelWidth"
              v-if="isfw"
            >
              <el-input
                v-model="apiRealName.appSecret"
                autocomplete="off"
                class="inputWidth"
              ></el-input>
            </el-form-item>
            <el-form-item :label-width="formLabelWidth" v-if="isqt">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入json串"
                v-model="realNamePara"
                style="width: 300px"
              >
              </el-input>
              <span style="color: #f00; display: block"></span>
            </el-form-item>
          </div>
          <div v-if="ishasLbs && platformCode!='guangyao'">
            <el-form-item
              label="Lbs设置:"
              :label-width="formLabelWidth"
              style="font-weight: bold"
            >
            </el-form-item>

            <el-form-item :label-width="formLabelWidth">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入json串"
                v-model="lbsParams"
                style="width: 300px"
              >
              </el-input>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            v-model="addForm.remark"
            autocomplete="off"
            class="inputWidth"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="confirmAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import { getAccountList } from "@/api/agentManagement.js";
import {
  getOperatorInterfaceList,
  addOperatorInterface,
  checkOperatorInterface,
  editOperatorInterface,
  deleteOperatorInterface,
} from "@/api/operatorManagement/operatorInterfaceList.js";
import { messageBox, confirmBox } from "@/utils/common.js";
import dictGroup from "@/mixins/dictGroup.js";
export default {
  mixins: [resize, dictGroup],
  data() {
    return {
      quaryForm: {
        accountId: "",
        interfaceName: "",
      },
      pageNo: 1,
      pageSize: 15,
      tableData: [],
      total: 0,
      operaterList: [],
      dialogVisible: false,
      addForm: {
        accountId: "",
        interfaceName: "",
        frequency: "",
        type: "1",
        interfaceParam: "",
        remark:"",
      },

      isJson: false,
      isKeyValue: true,
      dialogTitle: "",
      formLabelWidth: "160px",
      ifToken: false,
      isApiRealName: false,
      isOnlink: false,
      interfaceParamData: {
        accountCode: "",
        accountPwd: "",
        secretKey: "",
        refreshToken:{

        },
        apiRealName:{

        },
        lbs:{

        }

      },
      refreshToken: {
        interval: "",
      },
      tokenParams: "",
      apiRealName: {
        appSecret: "",
        appKey: "",
        custId: "",
        protocol: "",
      },
      realNamePara: "",
      platformCode: "",
      isdsp: false,
      isfw: false,
      isqt: true,
      ishasLbs: false,
      lbsParams: "",
    };
  },
  created() {
    this.getList();
    this.getOperatorList();
  },
  methods: {
    operatorChange(val) {
      console.log(val);
      this.operaterList.forEach((item) => {
        if (item.id == val) {
          this.ifToken = item.hasTokenInterface;
          this.isApiRealName = item.hasRealnameInterface;
          this.ishasLbs = item.hasLbs;
          this.platformCode = item.platformCode;
          if (this.platformCode == "telecomDcp") {
            this.isdsp = true;
            this.isfw = true;
            this.isqt = false;
          } else if (this.platformCode == "unicomCmp") {
            this.isdsp = true;
            this.isfw = false;
            this.isqt = false;
          } else {
            this.isdsp = false;
            this.isfw = false;
            this.isqt = true;
          }

          if (this.platformCode == "mobileOnelink") {
            this.isOnlink = true;
          } else {
            this.isOnlink = false;
          }
        }
      });
    },
    handleClose(done) {
      this.closeDialog();
      done();
    },
    getOperatorList() {
      getAccountList().then((res) => {
        this.operaterList = res;
      });
    },
    getList() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      Object.assign(params, this.quaryForm);
      getOperatorInterfaceList(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    add() {
      this.dialogVisible = true;
      this.dialogTitle = "新增";
    },
    closeDialog() {
      this.dialogVisible = false;
      this.addForm.accountId = "";
      this.addForm.interfaceName = "";
      this.addForm.frequency = "";
      this.addForm.type = "1";
      this.addForm.interfaceParam = "";
      this.addForm.remark = "";
      this.addForm.id = "";
      this.isJson = false;
      this.isKeyValue = true;
      this.interfaceParamData.accountCode = "";
      this.interfaceParamData.accountPwd = "";
      this.interfaceParamData.secretKey = "";
       this.interfaceParamData.refreshToken = {};
       this.interfaceParamData.apiRealName = {}
       this.interfaceParamData.lbs = {}
      this.refreshToken.interval = "";
      this.tokenParams = "";
      this.apiRealName.appSecret = "";
      this.apiRealName.appKey = "";
      this.apiRealName.custId = "";
      this.apiRealName.protocol = "";
      this.realNamePara = "";
      this.lbsParams = "";
    },
    updateRecord(res) {
      this.dialogVisible = true;
      this.dialogTitle = "编辑";

      this.addForm.id = res.id;
      this.addForm.accountId = res.accountId;
      this.addForm.interfaceName = res.interfaceName;
      this.addForm.frequency = res.frequency;
      this.addForm.remark = res.remark;
      this.addForm.type = "1";
      if (this.addForm.type == "0") {
        this.isJson = true;
        this.isKeyValue = false;
        console.log(res.interfaceParam);
        this.interfaceParamData.accountCode = "";
        this.interfaceParamData.accountPwd = "";
        this.interfaceParamData.secretKey = "";
        this.refreshToken.interval = "";
        this.tokenParams = "";
        this.apiRealName.appSecret = "";
        this.apiRealName.appKey = "";
        this.apiRealName.custId = "";
        this.apiRealName.protocol = "";
        this.realNamePara = "";
        this.lbsParams = "";
        this.addForm.interfaceParam = res.interfaceParam; //  是否需要转换
      } else if (this.addForm.type == "1") {
        this.isJson = false;
        this.isKeyValue = true;
        this.addForm.interfaceParam = "";
        let editInterfaceParam = JSON.parse(res.interfaceParam);
        this.operaterList.forEach((item) => {
          if (item.id == res.accountId) {
            this.ifToken = item.hasTokenInterface;
            this.isApiRealName = item.hasRealnameInterface;
            this.platformCode = item.platformCode;
            this.ishasLbs = item.hasLbs;
            if (this.ishasLbs) {
              this.lbsParams = JSON.stringify(editInterfaceParam.lbs);
            }
            if (this.platformCode == "telecomDcp") {
              this.isdsp = true;
              this.isfw = true;
              this.isqt = false;
              if (this.isApiRealName &&this.platformCode != "guangyao" ) {
                this.apiRealName.appSecret =
                  editInterfaceParam.apiRealName.appSecret;
                this.apiRealName.appKey = editInterfaceParam.apiRealName.appKey;
                this.apiRealName.custId = editInterfaceParam.apiRealName.custId;
                this.apiRealName.protocol =
                  editInterfaceParam.apiRealName.protocol;
              }
            } else if (this.platformCode == "unicomCmp") {
              this.isdsp = true;
              this.isfw = false;
              this.isqt = false;
              if (this.isApiRealName &&this.platformCode != "guangyao") {
                this.apiRealName.appKey = editInterfaceParam.apiRealName.appKey;
                this.apiRealName.custId = editInterfaceParam.apiRealName.custId;
                this.apiRealName.protocol =
                  editInterfaceParam.apiRealName.protocol;
              }
            } else {
              this.isdsp = false;
              this.isfw = false;
              this.isqt = true;
              if (this.isApiRealName&&this.platformCode != "guangyao") {
                this.realNamePara = JSON.stringify(
                  editInterfaceParam.apiRealName
                );
              }
            }

            if (this.platformCode == "mobileOnelink") {
              this.isOnlink = true;
              if (this.ifToken&&this.platformCode != "guangyao") {
                this.tokenParams = JSON.stringify(
                  editInterfaceParam.refreshToken
                );
              }
            } else {
              this.isOnlink = false;
              if (this.ifToken&&this.platformCode != "guangyao") {
                this.refreshToken.interval =
                  editInterfaceParam.refreshToken.interval;
              }
            }
          }
        });
        this.interfaceParamData.accountCode = editInterfaceParam.accountCode;
        this.interfaceParamData.secretKey = editInterfaceParam.secretKey;
        this.interfaceParamData.accountPwd = editInterfaceParam.accountPwd;
      }
    },
    confirmAdd() {
      if (!this.addForm.accountId) {
        messageBox(this, "请选择运营商");
        return;
      }
      if (!this.addForm.interfaceName) {
        messageBox(this, "请填写接口名称");
        return;
      }

      if (this.isJson) {
        if (!this.addForm.interfaceParam) {
          messageBox(this, "请填写接口参数");
          return;
        }
        try {
          JSON.parse(this.addForm.interfaceParam);
        } catch (e) {
          this.$alert("接口参数设置格式不正确", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      }

      if (this.isKeyValue) {
        if (this.interfaceParamData.accountCode.trim() == "") {
          messageBox(this, "接口基础配置中账户名不能为空");
          return;
        }
        if (this.ifToken&&this.platformCode != "guangyao") {
          if (!this.isOnlink) {
            if (this.refreshToken.interval.trim() == "") {
              messageBox(this, "获取token间隔时间不能为空");
              return;
            }
            let ret = /^[1-9]\d*$/;
            if (!ret.test(this.refreshToken.interval)) {
              this.$alert("获取token间隔时间需是大于0的整数", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            this.interfaceParamData.refreshToken.interval =
              this.refreshToken.interval;
          } else {
            if (this.tokenParams.trim() == "") {
              this.$alert("token参数设置不能为空", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            try {
              JSON.parse(this.tokenParams);
            } catch (e) {
              this.$alert("token参数设置格式不正确", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            for (let key in JSON.parse(this.tokenParams)) {
              this.interfaceParamData.refreshToken[key] = JSON.parse(
                this.tokenParams
              )[key];
            }
          }
        }

        if (this.isApiRealName&&this.platformCode != "guangyao") {
          if (this.isdsp) {
            if (this.apiRealName.appKey.trim() == "") {
              this.$alert("appkey不能为空", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            if (this.apiRealName.custId.trim() == "") {
              this.$alert("custId不能为空", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            if (this.apiRealName.protocol.trim() == "") {
              this.$alert("protocol不能为空", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            this.interfaceParamData.apiRealName.protocol =
              this.apiRealName.protocol;
            this.interfaceParamData.apiRealName.custId =
              this.apiRealName.custId;
            this.interfaceParamData.apiRealName.appKey =
              this.apiRealName.appKey;
          }
          if (this.isfw) {
            if (this.apiRealName.appSecret.trim() == "") {
              this.$alert("appSecret不能为空", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            this.interfaceParamData.apiRealName.appSecret =
              this.apiRealName.appSecret;
          }
          if (this.isqt) {
            if (this.realNamePara.trim() == "") {
              this.$alert("实名参数设置不能为空", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            try {
              JSON.parse(this.realNamePara);
            } catch (e) {
              this.$alert("实名参数设置格式不正确", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            for (let key in JSON.parse(this.realNamePara)) {
              this.interfaceParamData.apiRealName[key] = JSON.parse(
                this.realNamePara
              )[key];
            }
          }
        }
        if (this.ishasLbs&&this.platformCode != "guangyao") {
          if (this.lbsParams.trim() == "") {
            this.$alert("lbs参数设置不能为空", "提示", {
              confirmButtonText: "确定",
            });
            return;
          }
          try {
            JSON.parse(this.lbsParams);
          } catch (e) {
            this.$alert("lbs参数设置格式不正确", "提示", {
              confirmButtonText: "确定",
            });
            return;
          }
          console.log(JSON.parse(this.lbsParams))
          for (let key in JSON.parse(this.lbsParams)) {
            console.log(key)
            this.interfaceParamData.lbs[key] = JSON.parse(this.lbsParams)[key];
          }
        }
        console.log((this.interfaceParamData) )
        this.addForm.interfaceParam = JSON.stringify(this.interfaceParamData,(key,value)=>{
           if (Object.keys(value).length === 0) {
    return undefined ;
  }
  return value;
        });
      }
      if (!!this.addForm.id) {
        let params = {
          id: this.addForm.id,
          interfaceName: this.addForm.interfaceName,
          accountId: this.addForm.accountId,
          type: 0,
          remark: this.addForm.remark,
          frequency: this.addForm.frequency,
          interfaceParam: this.addForm.interfaceParam,
        };
        editOperatorInterface(params).then((res) => {
          if (res.system_result_key == 0) {
            this.$message({
              showClose: true,
              message: "编辑成功",
              type: "success",
            });
            this.closeDialog();
            this.getList();
          }
        });
      } else {
        let params = {
          interfaceName: this.addForm.interfaceName,
          accountId: this.addForm.accountId,
          type: 0,
          remark: this.addForm.remark,
          frequency: this.addForm.frequency,
          interfaceParam: this.addForm.interfaceParam,
        };
        addOperatorInterface(params).then((res) => {
          if (res.system_result_key == 0) {
            this.$message({
              showClose: true,
              message: "添加成功",
              type: "success",
            });
            this.closeDialog();
            this.getList();
          }
        });
      }
    },
    // validateAccount(id){
    // checkOperatorInterface({'id':id}).then(res=>{
    //     this.$alert(res.validateResultStr, '验证结果', {
    //         confirmButtonText: '确定',
    //     });
    // })
    // },

    removeList(id) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteOperatorInterface({ id }).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
        this.getList();
      });
    },

    changeParamsType(val) {
      if (val == "0") {
        this.isJson = true;
        this.isKeyValue = false;
        //  this.addForm.accountId
        if (this.ifToken&&this.platformCode != "guangyao") {
          if (!this.isOnlink) {
            this.interfaceParamData.refreshToken.interval =
              this.refreshToken.interval;
          } else {
            try{
            for (let key in JSON.parse(this.tokenParams)) {
              this.interfaceParamData.refreshToken[key] = JSON.parse(
                this.tokenParams
              )[key];
            }
            }catch(err){
            }
            
          }
        }

        if (this.isApiRealName&&this.platformCode != "guangyao") {
          if (this.isdsp) {
            this.interfaceParamData.apiRealName.protocol =
              this.apiRealName.protocol;
            this.interfaceParamData.apiRealName.custId =
              this.apiRealName.custId;
            this.interfaceParamData.apiRealName.appKey =
              this.apiRealName.appKey;
          }
          if (this.isfw) {
            this.interfaceParamData.apiRealName.appSecret =
              this.apiRealName.appSecret;
          }
          if (this.isqt) {
            for (let key in JSON.parse(this.realNamePara)) {
              this.interfaceParamData.apiRealName[key] = JSON.parse(
                this.realNamePara
              )[key];
            }
          }
        }
        if (this.ishasLbs&&this.platformCode != "guangyao") {
          for (let key in JSON.parse(this.lbsParams)) {
            this.interfaceParamData.lbs[key] = JSON.parse(this.lbsParams)[key];
          }
        }
        this.addForm.interfaceParam = JSON.stringify(this.interfaceParamData);
      } else if (val == "1") {
        this.isJson = false;
        this.isKeyValue = true;
        if (this.addForm.interfaceParam == "") {
        } else {
          try {
            let editInterfaceParam = JSON.parse(this.addForm.interfaceParam);

    this.operaterList.forEach((item) => {
          if (item.id == this.addForm.accountId) {
            this.ifToken = item.hasTokenInterface;
            this.isApiRealName = item.hasRealnameInterface;
            this.platformCode = item.platformCode;
            this.ishasLbs = item.hasLbs;
            console.log(item)
            if (this.ishasLbs&&this.platformCode != "guangyao") {
              this.lbsParams = JSON.stringify(editInterfaceParam.lbs);
            }
            if (this.platformCode == "telecomDcp") {
              this.isdsp = true;
              this.isfw = true;
              this.isqt = false;
              if (this.isApiRealName&&this.platformCode != "guangyao") {
                this.apiRealName.appSecret =
                  editInterfaceParam.apiRealName.appSecret;
                this.apiRealName.appKey = editInterfaceParam.apiRealName.appKey;
                this.apiRealName.custId = editInterfaceParam.apiRealName.custId;
                this.apiRealName.protocol =
                  editInterfaceParam.apiRealName.protocol;
              }
            } else if (this.platformCode == "unicomCmp") {
              this.isdsp = true;
              this.isfw = false;
              this.isqt = false;
              if (this.isApiRealName&&this.platformCode != "guangyao") {
                this.apiRealName.appKey = editInterfaceParam.apiRealName.appKey;
                this.apiRealName.custId = editInterfaceParam.apiRealName.custId;
                this.apiRealName.protocol =
                  editInterfaceParam.apiRealName.protocol;
              }
            } else {
              this.isdsp = false;
              this.isfw = false;
              this.isqt = true;
              if (this.isApiRealName&&this.platformCode != "guangyao") {
                this.realNamePara = JSON.stringify(
                  editInterfaceParam.apiRealName
                );
              }
            }

            if (this.platformCode == "mobileOnelink") {
              this.isOnlink = true;
              if (this.ifToken&&this.platformCode != "guangyao") {
                this.tokenParams = JSON.stringify(
                  editInterfaceParam.refreshToken
                );
              }
            } else {
              this.isOnlink = false;
              if (this.ifToken&&this.platformCode != "guangyao") {
                this.refreshToken.interval =
                  editInterfaceParam.refreshToken.interval;
              }
            }
          }
        });
            this.interfaceParamData.accountCode =
              editInterfaceParam.accountCode;
            this.interfaceParamData.secretKey = editInterfaceParam.secretKey;
            this.interfaceParamData.accountPwd = editInterfaceParam.accountPwd;
          } catch (e) {
            console.log(e)
            this.$confirm(
              "接口参数json串格式不正确将无法转换！是否继续？",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.isJson = false;
                this.isKeyValue = true;
              })
              .catch(() => {
                this.isJson = true;
                this.isKeyValue = false;
                this.addForm.type = "0";
              });
          }
        }
      }
    },
    onPageChange(page) {
      this.pageNo = page;
      this.getList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getList();
    },

    onSearch() {
      this.pageNo = 1;
      this.getList();
    },
  },
};
</script>

<style>
.operationalInter .el-form-item__label {
  vertical-align: top;
}
.inputWidth {
  width: 300px;
}
.el-input.inputWidth {
  width: 300px;
}
</style>
