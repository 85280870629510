<template>
  <div class="flexibleCode fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">活码样式</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <!-- <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item> -->
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="add"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="样式名称" min-width="180">
        </el-table-column>
        <!-- <el-table-column prop="lineOne" label="第一步描述" min-width="180">
        </el-table-column> -->
        <!-- <el-table-column prop="lineTwo" label="第二步描述" min-width="120">
        </el-table-column> -->
        <el-table-column prop="url" label="链接" min-width="120">
        </el-table-column>
          <!-- <el-table-column prop="lineThree" label="第三步描述" min-width="120">
        </el-table-column> -->

        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
             <el-button
              type="primary"
              class="themed-button"
              @click="view(scope.row)"
              >预览</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
 <el-dialog title="预览" :visible.sync="dialogView" @close="closeCode" width="400px">
        <el-form :model="viewForm"  ref="viewForm">
          <el-form-item  >
             <p>{{viewForm.lineOne}}</p>
            <div style="text-align:center">
              <strong>03369800</strong><br>
               <el-button type="primary" round>复制</el-button>
            </div>
          
          </el-form-item>
         <el-form-item   >
          <p>{{viewForm.lineTwo}}</p>
          <div class="paycode" style="width:150px;height:150px;margin:auto">
                <div id="qrcode" ref="qrcode" style="text-align:center"></div>
            </div>
         </el-form-item>
          <el-form-item   >
          <p>{{viewForm.lineThree}}</p>
         </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogView=false"
            class="themed-button" >确 定</el-button
          >
        </div>
      </el-dialog>



      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
        <el-form :model="addForm" :rules="rules" ref="addForm">
          <el-form-item label="样式名称:" prop="name" :label-width="formLabelWidth">
        <el-input v-model="addForm.name" autocomplete="off" placeholder=""  maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="第一步描述:" prop="lineOne" :label-width="formLabelWidth">
         <el-input v-model="addForm.lineOne" autocomplete="off"  placeholder="例如：点击复制，复制号码" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="第二步描述:" prop="lineTwo"  :label-width="formLabelWidth">
            <el-input v-model="addForm.lineTwo" autocomplete="off" placeholder="例如：长按识别二维码" maxlength="100"></el-input>
          </el-form-item>
             <el-form-item label="生成url方式:"  :label-width="formLabelWidth" >
            <el-radio v-model="radio" label="1">上传图片生成URL</el-radio>
            <el-radio v-model="radio" label="2">输入URL</el-radio>
          </el-form-item>
          <el-form-item v-if="radio==1" label="选择图片文件：" :label-width="formLabelWidth" >
				<input class="file-input" type="file" ref='fileImportInput'  @change="fileChange"    />
			</el-form-item>
          <el-form-item label="链接:" prop="url"  :label-width="formLabelWidth">
            <el-input v-model="addForm.url" :disabled="radio==1" autocomplete="off" maxlength="200"></el-input>
          </el-form-item>
         
           <el-form-item label="第三步描述:" prop="lineThree"  :label-width="formLabelWidth">
            <el-input v-model="addForm.lineThree" autocomplete="off" placeholder="例如：关注公众号进入激活续费"  maxlength="100"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" type="primary" class="themed-button">取 消</el-button>
          <el-button type="primary" @click="sureAdd('addForm')"
            class="themed-button" >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
 import QRCode from "qrcodejs2"  
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import {
 getflexibleCodeList,
 deleteflexibleCodeList,
 updateFlexibleCodeList,
 addFlexibleCodeList,
 decodeURL
} from "@/api/configurationManagement.js";
export default {
  name: "flexibleCode",
  mixins: [resize],
  data() {
  
    return {
      dialogView:false,
      formInline: {},
      tableData: [],
      radio:"2",
      dialogFormVisible: false,
      addForm: {
       name:"",
       lineOne:"",
       lineTwo:"",
       url:"",
       lineThree:"",
      },
      viewForm:{
      lineOne:"",
       lineTwo:"",
       lineThree:"",
      },
      rules: {
       	name: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					},],
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "110px",
      dialogTitle: "",
      currentURL:""
    };
  },
  created() {
    this.getflexibleCodeList();
  },
  methods: {
     closeCode(){
            this.dialogView = false
            this.$refs.qrcode.innerHTML = ''
        },
    crateQrcode () {
            let qr = new QRCode('qrcode', {
            width: 150,
            height: 150, // 高度
            text: this.currentURL // 二维码内容
            })
        },
    view(row){
      this.dialogView = true
      this.viewForm.lineOne  = row.lineOne
      this.viewForm.lineTwo = row.lineTwo;
      this.currentURL = row.url;
      this.viewForm.lineThree = row.lineThree;
        this.$nextTick(() => {
                    this.crateQrcode()
                })
    },
    fileChange(val){
      console.log(val)
      if(this.$refs['fileImportInput'].files[0]){
       let fileName = this.$refs['fileImportInput'].files[0].name
							let index = fileName.lastIndexOf(".")
							if (index < 0 || !(fileName.substring(index + 1, fileName.length) == "png" || fileName
									.substring(index + 1, fileName.length) == "jpg"|| fileName
									.substring(index + 1, fileName.length) == "jpeg"|| fileName
									.substring(index + 1, fileName.length) == "svg")) {
								this.$alert('文件格式错误', '提示', {
									confirmButtonText: '确定',
								});
								return
      }
      let param = new FormData()
      param.append("file", this.$refs['fileImportInput'].files[0])
      decodeURL(param).then(res=>{
        this.addForm.url = res.url
      })
      }
    },
    searchData() {
      this.page = 1;
      this.getflexibleCodeList();
    },
    onPageChange(page) {
      this.page = page;
      this.getflexibleCodeList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getflexibleCodeList();
    },

    getflexibleCodeList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };

      getflexibleCodeList(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    edit(row) {
      this.dialogTitle = "编辑";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      this.addForm.id = row.id;
      this.addForm.name = row.name;
      this.addForm.lineOne = row.lineOne;
      this.addForm.lineTwo = row.lineTwo;
      this.addForm.url = row.url;
      this.addForm.lineThree = row.lineThree;
      this.radio=2+""
      
    },
    add() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      this.addForm.id = "";
      this.addForm.name = "";
      this.addForm.lineOne = "";
      this.addForm.lineTwo = "";
      this.addForm.url = "";
      this.addForm.lineThree = "";
      this.radio=2+""
    },
    sureAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addForm.id) {
            updateFlexibleCodeList(this.addForm).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.getflexibleCodeList();
              this.dialogFormVisible = false;
            });
          } else {
            addFlexibleCodeList(this.addForm).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.getflexibleCodeList();
              this.dialogFormVisible = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    delect(id) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteflexibleCodeList({ id }).then((res) => {
          this.getflexibleCodeList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
};
</script>

<style>
/* .materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
} */
.flexibleCode .el-form-item__label {
  vertical-align: top;
}
</style>
