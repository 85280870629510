/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function closeBalanceList(params){
    return request({
        url: '/card/cardBalanceOpen/list.do?',
        method: 'post',
        data:params
    })
}

export function delCloseBalanceRecord(params){
    return request({
        url: '/card/cardBalanceOpen/delete.do?',
        method: 'post',
        data:params
    })
}
export function importBalanceCard(params){
    return request({
        url: '/card/cardBalanceOpen/importBalanceCardSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function exportExcelSync(params) {
    return request({
        url:'/card/cardBalanceOpen/exportExcelSync.do?',
        method:'post',
        data:params,
    })
}

// /card/cardBalanceOpen/exportExcelSync

export function deleteByExcelBalance(params){
    return request({
        url: '/card/cardBalanceOpen/deleteByExcelSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

