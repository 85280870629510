<template>
    <div class="voice-card-order fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">语音白名单</span>
            <el-form inline ref="form" v-model="form" class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item label="ICCID号:">
                    <el-input v-model="form.iccidMark" class="iccid-width"></el-input>
                </el-form-item>
                 <el-form-item label="短号:">
                    <el-input v-model="form.shortIccid" ></el-input>
                </el-form-item>
                <el-form-item label="接入号:">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="白名单:">
                    <el-input v-model="form.whiteNumber"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <!-- <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item> -->
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
                <el-table-column label="ICCID" prop="iccidMark" width="180px"></el-table-column>
                <el-table-column label="短号" prop="shortIccid" width="130px"/>
                <el-table-column label="接入号" prop="phone" show-overflow-tooltip></el-table-column>
                <el-table-column label="白名单" prop="whiteNumber" show-overflow-tooltip></el-table-column>
                <el-table-column label="创建时间" prop="createDate" show-overflow-tooltip></el-table-column>
                 <el-table-column label="名称" prop="tag" show-overflow-tooltip></el-table-column>
                <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
              
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
      
    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {voiceWhitelist,voiceWhitelistEeportSync,} from "@/api/history.js"

export default {
    name:'voiceWhitelist',
     mixins:[resize],
    data(){
        return {
            form:{
                iccidMark:'',
                phone:"",
                whiteNumber:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    mounted(){
        let params = this.$route.query
        console.log(params)
        if(params.iccid){
            this.form.iccidMark = params.iccid
        }
        this._voiceWhitelist()
    },
    methods: {
       
        onPageChange(page){
			this.page = page
			this._voiceWhitelist()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._voiceWhitelist()
        },
        onSearch(){
            this.page = 1
            this._voiceWhitelist()
        },
        // exportExcel(){
        //     voiceWhitelistEeportSync(this.form).then(res=>{
		// 			if (res.system_result_key == '0') {
		// 				this.$alert('导出成功，请到导出记录中下载', '提示', {
		// 				    confirmButtonText:'确定',
		// 				}).then(()=>{
		// 					this.$router.push('/tob/i_export_list')
		// 				});
		// 			}else{
		// 				this.$message({
		// 				    showClose: true,
		// 				    message: res.system_result_message_key,
		// 				    type: 'error'
		// 				});
		// 			}
		// 		})
        // },
        _voiceWhitelist(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            voiceWhitelist(Object.assign(params,this.form)).then((res)=>{
                this.tableData = res.rows
                this.page = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
            })
        }
    },
}
</script>