<template>

<div class="table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">老卡转新卡</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="ICCID:">
                <el-input v-model="form.iccidMark" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="老卡运营商:">
                <search-select :optionsMetaAll="accountLists"  v-model="form.oldAccountId" placeholder="请选择"></search-select>
            </el-form-item>
            <el-form-item label="新卡运营商:">
                <search-select :optionsMetaAll="accountLists"  v-model="form.newAccountId" placeholder="请选择"></search-select>
            </el-form-item>
            <el-form-item label="类型:">
                <el-select placeholder="请选择" v-model="form.transType">
                    <el-option label="请选择" value=""></el-option>
                    <el-option label="转新卡" value="1"></el-option>
                    <el-option label="转层级" value="2"></el-option>
                </el-select>
            </el-form-item>
           <el-form-item label="状态:">
                <el-select placeholder="请选择" v-model="form.status">
                    <el-option label="请选择" value=""></el-option>
                    <el-option label="已处理" value="0"></el-option>
                    <el-option label="失败" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="plzk">批量转卡</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">导出Excel</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="老卡卡号" prop="oldIccidMark" width="180px"> </el-table-column>
            <el-table-column label="新卡卡号" prop="newIccidMark" show-overflow-tooltip></el-table-column>
            <el-table-column label="类型" prop="transTypeString" show-overflow-tooltip></el-table-column>
            <el-table-column label="老卡运营商" prop="oldAccountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="新卡运营商" prop="newAccountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐名称" prop="seMealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="总流量" prop="totalFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="转卡已使用流量" prop="usedFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作人" prop="createUserName" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status === 0">已处理</span>
                    <span v-else-if="scope.row.status === 1">失败</span>
                    <span v-else>{{scope.row.status}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作时间" prop="operateTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" prop="expireDate">
                <template slot-scope="scope">
                    <el-button type="primary" class="themed-button"  @click='showDetail(scope.row.id)'>失败详情</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog  title="详情" :visible.sync="dialogUpdate">
        <el-form label-position="right" label-width="200px">
            <el-form-item label="失败原因:">
                <span>{{errorMsg}}</span>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="dialogUpdate = false">关闭</el-button>
        </div>
    </el-dialog>

    <el-dialog  title="批量转卡" :visible.sync="dialogPil">
        <el-form label-position="right" label-width="200px" :model="pForm" :rules="rules"   ref="pForm">
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="选择运营商类型:" required>
                <el-radio-group v-model="pForm.status">
                    <el-radio :label="1" @change="hide">相同运营商转卡</el-radio>
                    <el-radio :label="2"  @change="show">不同运营商转卡</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="仅转层级关系:" required style="display:none;">
                <el-checkbox v-model="pForm.ckTransType"></el-checkbox>
            </el-form-item>
            <el-form-item v-if="isShow">
                <span style="color:#f00">跨运营商转卡请选择与老卡转卡套餐套餐流量一致的套餐，避免转卡后新卡实际流量与套餐流量不符合</span>
            </el-form-item>
            <el-form-item
        v-if="isShow"
        label="转后套餐运营商:"
        prop="category"
        required
      >
        <el-radio-group
          v-model="pForm.category"
          @change="_getSetMealByCategory"
        >
          <!-- <el-radio :label="1">电信</el-radio>
          <el-radio :label="3">移动</el-radio>
          <el-radio :label="5">dcp电信</el-radio>
          <el-radio :label="6">联通</el-radio> -->
          <el-radio v-for="(item, index) in w_ACCOUNT_" :label="item.dictValue" :key="index">{{item.groupName}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="isShow" label="新卡套餐:" prop="mealId" required>
        <search-select
          :optionsMetaAll="mealList"
          v-model="pForm.mealId"
        ></search-select>
      </el-form-item>
        <el-form-item label="转代理商层级:" prop="transAgent" required>
        <el-radio-group v-model="pForm.transAgent">
          <el-radio :label="1" >是</el-radio>
          <el-radio :label="0" >否</el-radio>
        </el-radio-group>
      </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel"  v-preventReClick='3000' :disabled="isDisable" >导入excel数据</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('old_to_new_card.xlsx')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogPil = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import { getSetMealByCategory } from "@/api/cardManagement/cardBillDetail.js";
import {accountList,recordList,importExcelSync,exportExcelSync,updateForm} from '@/api/cardManagement/oldcardChangerecord.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import resize from '@/mixins/resize.js'
import dictGroup from '@/mixins/dictGroup.js'

export default {
    name:'oldcardChangerecord',
    mixins:[resize,dictGroup],
    data(){
        return {
            form:{
                iccidMark:'',
                oldAccountId:'',
                newAccountId:'',
                transType:'',
                status:'',
            },
            accountLists:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,

            dialogPil:false,
            pForm:{
                status:1,
                ckTransType:false,
                transType:1,
                mealId:"",
                category:"",
                transAgent:0,
            },
           mealList: [],
           dialogUpdate:false,
           errorMsg:"",
            rules: {
        category: [
          { required: true, message: "选择转后套餐运营商", trigger: "blur" },
        ],
        mealId: [
          { required: true, message: "选择新卡套餐", trigger: "change" },
        ],
      },
      isShow:false,
      isDisable:false,
        }
    },
    mounted(){
        this._accountList()
        this._recordList()
    },
    methods:{
        _getSetMealByCategory() {
      let category = "";
      if (this.pForm.category == "") {
        category = this._getDictValue('DX_',this.w_ACCOUNT_);
      } else {
        category = this.pForm.category;
      }
    //   let oldIccidMark = this.iccidMark;
      let status = 1;
      let params = {
        // oldIccidMark,
        category,
        status,
      };
      getSetMealByCategory(params).then((res) => {
        this.mealList = res.map((item) => {
            let flowsize 
            if(item.flowSize>=1024){
             flowsize =  item.flowSize/1024+"G"
            }else{
               flowsize  =   item.flowSize +"M"
            }
          return {
            label: item.name+ "-"+item.secondCategoryStr+"-"+item.flowSize+"M",
            value: item.id,
          };
        });
      });
    },
        hide() {
      this.isShow = false;
      this.pForm.category = "";
      this.pForm.mealId = "";
    },
    show() {
      this.isShow = true;
    },
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        showDetail(id){
            this.dialogUpdate = true
            updateForm({id,selectedId:id}).then((res)=>{
                console.log(res.entity.errorMessage,248)
                // if(res.errorMsg){
                //     this.errorMsg = res.errorMsg
                // }else{
                //     this.errorMsg = ''
                // }
                  if(res.entity.errorMessage){
                    this.errorMsg = res.entity.errorMessage
                }else{
                    this.errorMsg = ''
                }
            })
        },
        uploadExcel(){
            confirmBox(this,'老卡转新卡将耗费较长时间（每个卡5~20秒），建议不要一次导入太多卡，如需继续，请点击确定。','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    this.$refs["pForm"].validate((valid) => {
        if (valid) {
          let params = new FormData()
                    params.append('fileurl',file)
                    params.append('status',this.pForm.status)
                    params.append('ckTransType',this.pForm.ckTransType)
                    params.append('transType',this.pForm.transType)
                    params.append('mealId',this.pForm.mealId)
                    params.append('category',this.pForm.category)
                     params.append('transAgent',this.pForm.transAgent)
                    this.isDisable = true
                    importExcelSync(params).then((res)=>{
                         this.isDisable =  false
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogPil = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    }).catch(err=>{
                          this.isDisable =  false
                    })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
                  
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        exportExcel(){
            messageBox(this,"提示：\n数据正在导出，如果导出的数据量较大，文件下载响应会稍慢，也会增加服务器负荷，请稍候片刻。\n请不要重复点击导出，如果需要重新导出，请刷新页面后重新操作。")
            exportExcelSync(this.form).then((res)=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        plzk(){
            this.dialogPil = true
            this.pForm.status = 1
            this.hide()
            this.$nextTick(() => {
         this.$refs["file"].value = "";
      });
           
            this.pForm.ckTransType = false
        },
        searchTable(){
            this._recordList()
        },
        handleSizeChange(val){
            this.pageSize = val
            this._recordList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._recordList()
        },
        _recordList(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            recordList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
        _accountList(){
            accountList(this.form).then((res)=>{
                this.accountLists = res.map((item)=>{
                    return {
                        label:item.accountName,
                        value:item.id
                    }
                })
            })
        }
    },
    components:{
        SearchSelect
    }
}

</script>
