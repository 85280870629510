<template>
    <div class="history-speed-limit fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">限速记录</span>
            <el-form inline ref="speedLimitForm" v-model="speedLimitFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="speedLimitFormData.iccidMark" placeholder="ICCID号(精确)" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="speedLimitFormData.phone" placeholder="接入号(精确)"></el-input>
                </el-form-item>

                <el-form-item label="开卡运营商">
                    <search-select :optionsMetaAll="agentOperators" v-model="speedLimitFormData.accountId"></search-select>
                </el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="开卡公司"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    prop="speedValue"
                    label="限速值"
                    min-width="120"
                    >
                </el-table-column>
                 <el-table-column
                    prop="deviceSpeedValue"
                    label="设备速率"
                    min-width="120"
                    >
                </el-table-column>
                 <el-table-column
                    prop="limitType"
                    label="类型"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="mealName"
                    label="当前套餐"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="totalFlow"
                    label="总流量"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="usedFlow"
                    label="已使用流量"
                    min-width="120"
                    >  
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="remainingFlow"
                    label="剩余流量"
                    min-width="120"
                    >  
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    prop="createDate"
                    label="限速时间"
                    min-width="150"
                    >
                </el-table-column>
                
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import resize from '@/mixins/resize.js'
import {agentOperatorList} from '@/api/order.js'
import { speedLimit,exportSpeedLimit } from "@/api/history.js";
export default {
    name:'speedLimit',
     components:{SearchSelect},
     mixins:[resize],
    data(){
        return {
            speedLimitFormData:{
                iccidMark:'',
                phone:'',
                accountId:'',
            },
            agentOperators:[{label:"全部",value:""}],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getAgentOperatorList()
        this.getSpeedLimitList()
    },
    methods: {
        getAgentOperatorList(){
            agentOperatorList().then(res=>{
                 let arrList =[{label:"全部",value:""}]
                 res.forEach(element => {
                     arrList.push({label:element.accountName,value:element.id})
                 })               
                 this.agentOperators=arrList
             })
        },
        getSpeedLimitList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.speedLimitFormData,params)
            speedLimit(this.speedLimitFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getSpeedLimitList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSpeedLimitList()
        },

        onSearch(){
            this.page=1
            this.getSpeedLimitList()
        },

        exportExcel(){
            exportSpeedLimit(this.speedLimitFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
    },
}
</script>

<style>
    .history-speed-limit .el-form-item__label{
        vertical-align: top;
    }
</style>