<template>
  <div class="newCoupons">
    <el-form
      label-position="right"
      label-width="200px"
      ref="form"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="优惠券名称:" prop="couponsName" required>
        <el-input v-model="form.couponsName"></el-input>
      </el-form-item>
      <el-form-item label="优惠券金额:" prop="couponsValue" required>
        <el-input v-model="form.couponsValue"></el-input>
      </el-form-item>

      <el-form-item label="优惠券类型:" prop="type" required style="display:none">
        <el-select v-model="form.type" placeholder="请选择">
          <el-option label="请选择" value=""></el-option>
          <el-option
            v-for="(item, index) in w_coupon_type"
            :label="item.groupName"
            :value="item.dictValue"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="有效期类型:" prop="effectType">
      
      </el-form-item> -->
      <el-form-item label="有效期:" v-if="isShow" >
        <el-input-number
          v-model="form.effectCount"
          :step="1"
          :min="1"
        ></el-input-number>
          <el-select
          v-model="form.effectType"
          placeholder="请选择"
          @change="typeChange"
          required
          style="margin-left:10px;width: 80px;"
        >
          <!-- <el-option label="固定时间区间" value="3"></el-option> -->
          <el-option label="月" value="1"></el-option>
          <el-option label="天" value="2"></el-option>
        </el-select>
        <!-- <span v-if="form.effectType==1" style="margin-left:5px">月</span>
        <span v-if="form.effectType==2" style="margin-left:5px">天</span> -->
        <div style="color:#f00;">
          自优惠券获取后的可用时间，过期失效
        </div>
      </el-form-item>

      <el-form-item
        label="优惠券开始时间:"
        prop="startTime"
        required
        v-if="!isShow"
      >
        <el-date-picker
          type="datetime"
          placeholder="选择日期"
          value-format="yyyy/MM/dd HH:mm:ss"
          v-model="form.startTime"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="优惠券结束时间:"
        prop="endTime"
        required
        v-if="!isShow"
      >
        <el-date-picker
          type="datetime"
          placeholder="选择日期"
          value-format="yyyy/MM/dd HH:mm:ss"
          v-model="form.endTime"
        ></el-date-picker>
      </el-form-item>
      
    </el-form>
    <div class="align-right">
      <el-button type="primary" class="themed-button" @click="sure('form')"
        >确定</el-button
      >
      <el-button type="primary" class="themed-button" @click="cancle('form')"
        >取 消</el-button
      >
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { couponsSave } from "@/api/eventDiscountManagement/couponManagement.js";
import { messageBox, confirmBox } from "@/utils/common.js";
import SearchSelect from "@/components/SearchSelect";
import dictGroup from "@/mixins/dictGroup.js";
export default {
  mixins: [dictGroup],
  data() {
    return {
      form: {
        id: "",
        couponsName: "",
        couponsValue: "",
        startTime: "",
        endTime: "",
        type: "1",
        effectType: "1",
        effectCount: "",
      },
      rules: {
        couponsName: [
          { required: true, message: "优惠券名称不能为空", trigger: "blur" },
        ],
        couponsValue: [
          { required: true, message: "优惠券金额不能为空", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "请设置优惠券开始时间", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请设置优惠券结束时间", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        effectType: [
          { required: true, message: "请选择有效类型", trigger: "change" },
        ],
      },
      isShow: false,
    };
  },
  created() {
    if (this.form.effectType == "3") {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  },
  methods: {
    typeChange(val) {
      if (val == "3") {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    sure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          let params = {
          }
          if (this.form.effectType == "3") {
              params.id = this.form.id   
              params.couponsName = this.form.couponsName
              params.couponsValue = this.form.couponsValue
              params.startTime = this.form.startTime
              params.endTime = this.form.endTime
              params.type = this.form.type
              params.effectType = this.form.effectType

          }
          if (this.form.effectType != "3") {
               params.id = this.form.id   
              params.couponsName = this.form.couponsName
              params.couponsValue = this.form.couponsValue
              params.effectCount = this.form.effectCount
              params.type = this.form.type
              params.effectType = this.form.effectType
           }
       
          couponsSave(params).then((res) => {
            if (res.slide_msg_key == 1) {
              messageBox(this, res.slide_msg_message_key);
            }
            // this.$refs[formName].resetFields();
            this.$emit("refresh");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancle(formName) {
      this.$refs[formName].resetFields();
      this.$emit("close");
    },
  },
  components: {
    SearchSelect,
  },
};
</script>

<style></style>
