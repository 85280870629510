<template>

<div class="imeiBase table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">IMEI库</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="运营商账号:"  prop="accountId">
                <search-select :optionsMetaAll="accountList"  v-model="form.accountId" placeholder="请选择"></search-select>
            </el-form-item>
            <el-form-item label="IMEI:">
                <el-input v-model="form.imei" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="IMEI" prop="imei" width="180px"></el-table-column>
            <el-table-column label="运营商账号" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作人" prop="createUsername" show-overflow-tooltip></el-table-column>
            <el-table-column label="导入时间" prop="createDateStr" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

</div>

</template>

<script type="text/ecmascript-6">
import {accountList,imeiList} from '@/api/cardManagement/flowmonitor.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import resize from '@/mixins/resize.js'
export default {
    name:'imeiBase',
    mixins:[resize],
    data(){
        return {
            form:{
                accountId:'',
                imei:''
            },
            accountList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0
        }
    },
    mounted(){
        this._accountList()
        this._imeiList()
    },
    methods:{
        searchTable(){
            this._imeiList()
        },
        
        handleSizeChange(val){
            this.pageSize = val
            this._imeiList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._imeiList()
        },
        _imeiList(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            imeiList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
        _accountList(){
            accountList(this.form).then((res)=>{
                this.accountList = res.map((item)=>{
                    return {
                        label:item.accountName,
                        value:item.id
                    }
                })
            })
        }
    },
    components:{
        SearchSelect
    }
}

</script>

