<template>
	<div class="virtualStrategyBatchConfig fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">批量运营策略</span>
			<el-form inline ref="virtualStrategyBatchConfigForm" v-model=" virtualStrategyBatchConfigFormData"
				class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item label="批量名称" label-width="120px">
          <el-input
            v-model="virtualStrategyBatchConfigFormData.name"
            placeholder="输入名称"
            class="iccid-width"
          ></el-input>
        </el-form-item>
				
                <el-form-item label="运营策略">
					<el-select v-model="virtualStrategyBatchConfigFormData.strategyId">
						<el-option value="" label="全部">全部</el-option>
						<el-option v-for="item in operStrategyList" :key="item.id" :label="item.name"
  							:value="item.id">
  						</el-option>
					</el-select>
				</el-form-item>
               


				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询
					</el-button>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="add" icon="el-icon-plus" class="themed-button">新增</el-button>
				</el-form-item>

			</el-form>
		</div>

		<div class="content-box">
			<el-table border ref="table" stripe :max-height="maxTableHeight" :data="tableData" tooltip-effect="dark"
				style="width: 100%">
                <el-table-column show-overflow-tooltip prop="name" label="批量名称" min-width="120">
                   <template slot-scope="scope">
                   <a href="javascript:void(0)" @click="singleOperationStrategy(scope.row)">{{scope.row.name}}</a>
               </template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="strategyName" label="运营策略" min-width="120">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="type" label="类型" min-width="120">
                <template slot-scope="scope">
						<span v-if="scope.row.type=='1'">手动创建</span>
                        <span v-else-if="scope.row.type=='2'">自动创建</span>
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="priority" label="优先级" min-width="120">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="150">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="updateTime" label="更新时间" min-width="150">
				</el-table-column>
                <el-table-column show-overflow-tooltip prop="accountName" label="运营商" min-width="150">
				</el-table-column>
				<el-table-column label="操作" min-width="160">
					<template slot-scope="scope">
						<!-- <el-button size="mini" class="themed-button" type="info" @click="handleEdit(scope.row.id)">编辑
						</el-button> -->
						<el-button size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">
							删除</el-button>
					</template>
				</el-table-column>


			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize"
				style="text-align: left;margin-top: 20px;" background layout="total,sizes,prev, pager, next,jumper"
				:total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
		</div>

		<el-dialog title="新建" :visible.sync="dialogVisiableAdd" destroy-on-close>
			<el-form label-position="right" label-width="150px" :model="addForm" ref="addForm">
                 <el-form-item label="名称" prop="name" >
                 <el-input  v-model="addForm.name"   placeholder="输入批量名称"   class="iccid-width"></el-input>
            </el-form-item>
				<el-form-item label="虚拟运营商" prop="accountId" >
					<el-select v-model="addForm.accountId" placeholder="请选择" filterable>
						<el-option v-for="item in virtualAgentList" :key='item.index' :label="item.accountName"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
			<el-form-item label="运营策略"  prop="strategyId">
  					<el-select v-model="addForm.strategyId" placeholder="请选择运营策略" filterable>
  						<el-option v-for="item in operStrategyList" :key="item.id" :label="item.name"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
                 <el-form-item label="优先级" prop="priority" >
                 <el-input  v-model="addForm.priority"   placeholder="输入优先级"   class="iccid-width"></el-input>
            </el-form-item>
              <el-form-item label="导入Iccid文件(.xls)" >
                    <input class="file-input" type="file" ref='fileAddInput'/>
                    <div class="mt5">
                            <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                    </div>
                </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="sureAdd('addForm')">确 认</el-button>
                <el-button class="themed-button" type="info" @click="exportTemplateExcel('importIccid.xls')">导出excel模板</el-button>
				<el-button class="themed-button" type="primary" @click="dialogVisiableAdd = false">关 闭</el-button>
			</div>
		</el-dialog>


		<!-- <el-dialog title="编辑" :visible.sync="dialogVisiableEdit" destroy-on-close>
			<el-form label-position="right" label-width="120px" :model="editForm" ref="editForm">
                 <el-form-item label="名称" >
                 <el-input  v-model="editForm.name"   placeholder="输入批量名称"   class="iccid-width"></el-input>
                 </el-form-item>
				<el-form-item label="虚拟运营商" prop="accountId">
					<el-select v-model="editForm.accountId" placeholder="请选择" filterable>
						<el-option v-for="item in virtualAgentList" :key='item.index' :label="item.accountName"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="运营策略"  prop="strategyId">
  					<el-select v-model="editForm.strategyId" placeholder="请选择运营策略" filterable>
  						<el-option v-for="item in operStrategyList" :key="item.id" :label="item.name"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
                 <el-form-item label="优先级" >
                 <el-input  v-model="editForm.priority"   placeholder="输入优先级"   class="iccid-width"></el-input>
            </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="sureEdit('editForm')">确 认</el-button>
				<el-button class="themed-button" type="primary" @click="dialogVisiableEdit = false">关 闭</el-button>
			</div>
		</el-dialog> -->

	</div>
</template>

<script>
	import resize from '@/mixins/resize.js'
	import Utils from '@/utils/utils.js'
    import {messageBox,confirmBox} from '@/utils/common.js'
	import {
		agentOperatorList
	} from '@/api/order.js'
	import {
		virtualStrategyBatchList,
		virtualStrategyBatchAdd,
        virtualStrategyBatchEdit,
        virtualStrategyBatchById,
        virtualStrategyBatchDelete,
		operatingStrategyList
	} from "@/api/virtualCard.js"
	import {exportTemplate} from '@/api/statistics.js'
	export default {
		name: 'virtualStrategyBatchConfig',
		mixins: [resize],
		data() {
			return {
				 virtualStrategyBatchConfigFormData: {
                    name:"",
                    strategyId:"",
				},
				tableData: [],
				page: 1,
				pageSize: 15,
				total: 0,
				accountIdList: [],
                operStrategyList:[],
				virtualAgentList: [],
				dialogVisiableAdd: false,
				dialogVisiableEdit: false,
				addForm: {
					name:"",
                    accountId:"",
                    strategyId:"",
                    priority:"",
				},
				editForm: {
					id: '',
					name:"",
                    accountId:"",
                    strategyId:"",
                    priority:"",
				},
			}
		},
		created() {
			this.getAgentOperatorList()
            this.getStrategyList();
			this.getVirtualStrategyBatchConfigList()
		},
		methods: {
                singleOperationStrategy(row){
                // this.$router.push({
                //         path: '/virtualCard/singleOperationStrategy',
				// 		// name:"singleOperationStrategy",
                //         // 传参
                //         query: {
                //             batchId: row.id,
                //         }   
                // })
                 this.$router.push({
                   path:'/virtualCard/singleOperationStrategy/'+row.id,
       })
				//  this.$router.push(`/virtualCard/singleOperationStrategy?batchId=${row.id}`);

            },
            	getStrategyList() {
  				let params = {
  					pageNo: this.pageNo,
  					pageSize: 20,
  				};
  				operatingStrategyList(params).then((res) => {
  					this.operStrategyList = res.rows;
  				});
  			},
			getAgentOperatorList() {
				agentOperatorList().then(res => {
					this.accountIdList = res
					this.virtualAgentList = res.filter(item => {
						return item.accountName.includes('虚拟')
					})
				})
			},
			getVirtualStrategyBatchConfigList() {
				let params = {
					pageNo: this.page,
					pageSize: this.pageSize
				}
				Object.assign(this.virtualStrategyBatchConfigFormData, params)
				virtualStrategyBatchList(this.virtualStrategyBatchConfigFormData).then(res => {
					this.tableData = res.rows
					this.total = res.count
				})
			},
			onPageChange(page) {
				this.page = page
				this.getVirtualStrategyBatchConfigList()
			},
			handleSizeChange(size) {
				this.pageSize = size
				this.getVirtualStrategyBatchConfigList()
			},

			onSearch() {
				this.page = 1
				this.getVirtualStrategyBatchConfigList()
			},


			add() {
				this.dialogVisiableAdd = true
			},

			sureAdd(formName) {
                if(this.addForm.name==""){
                     messageBox(this,'请输入批量名称')
                     return 
                }
                if(this.addForm.accountId==""){
                 messageBox(this,'请选择运营商')
                     return 
                }
                if(this.addForm.strategyId==""){
                 messageBox(this,'请选择运营策略')
                     return 
                }
                 if(this.addForm.priority==""){
                 messageBox(this,'请输入优先级')
                     return 
                }

                  let file = this.$refs['fileAddInput'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            param.append("file",file)
                            param.append("accountId",this.addForm.accountId)
                            param.append("name",this.addForm.name)
                            param.append("strategyId",this.addForm.strategyId)
                            param.append("priority",this.addForm.priority)
                            virtualStrategyBatchAdd(param).then(res=>{
								  if (res.system_result_key == '0') {
                                this.$message({
								type: 'success',
								message:'导入成功'
                            });
                            
							this.$refs[formName].resetFields()
							this.$refs['fileAddInput'].value = ''
                            this.dialogVisiableAdd = false
                            this.getVirtualStrategyBatchConfigList() 
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }

                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
				// virtualStrategyBatchAdd(this.addForm).then(res => {
				// 			this.$message({
				// 				showClose: true,
				// 				message: '新建成功',
				// 				type: 'success',
				// 				center: true
				// 			})
				// 			this.$refs[formName].resetFields()
				// 			this.dialogVisiableAdd = false
				// 			this.getVirtualStrategyBatchConfigList()
				// 		})
			},
             exportTemplateExcel(name){
            exportTemplate({'name':name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },

			// handleEdit(id) {
			// 	this.dialogVisiableEdit = true
			// 	virtualStrategyBatchById({
			// 		id
			// 	}).then(res => {
			// 		this.editForm.id = res.id
			// 		this.editForm.accountId = res.accountId
			// 		this.editForm.strategyId = res.strategyId
            //         this.editForm.iccid = res.iccid
			// 	})
			// },

			// sureEdit(formName) {
            //      if(this.editForm.name==""){
            //          messageBox(this,'请输入iccid')
            //          return 
            //     }
            //     if(this.editForm.accountId==""){
            //      messageBox(this,'请选择运营商')
            //          return 
            //     }
            //     if(this.editForm.strategyId==""){
            //      messageBox(this,'请选择运营策略')
            //          return 
            //     }
            //     if(this.editForm.priority=="") {
            //      messageBox(this,'请输入优先级')
            //          return 
            //     }
			// 	virtualStrategyBatchEdit(this.editForm).then(res => {
			// 				this.$message({
			// 					showClose: true,
			// 					message: '修改成功',
			// 					type: 'success',
			// 					center: true
			// 				})
			// 				this.$refs[formName].resetFields()
			// 				this.dialogVisiableEdit = false
			// 				this.getVirtualStrategyBatchConfigList()
			// 			})
			// },

			handleDelete(id) {
				this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					virtualStrategyBatchDelete({
						'id': id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});
						this.getVirtualStrategyBatchConfigList()
					})

				})
			}
		},
	}
</script>

<style>
	.virtualStrategyBatchConfig .el-form-item__label {
		vertical-align: top;
	}
</style>
