<template>
  <div class="network-card-distribution fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">网卡分销记录</span>
      <el-form
        inline
        ref="networkCardDistributeForm"
        v-model="networkCardDistributeData"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item>
          <el-input
            v-model="networkCardDistributeData.agentName"
            placeholder="分销商姓名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        border
        ref="table"
        stripe
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          show-overflow-tooltip
          prop="iccidMarkStart"
          label="起始ICCID号"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="iccidMarkEnd"
          label="结束ICCID号"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          prop="importTotal"
          label="导入张数 (张)"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="importSuccess"
          label="导入成功张数 (张)"
          min-width="140"
        >
        </el-table-column>
        <el-table-column
          prop="importFail"
          label="导入失败张数 (张)"
          min-width="140"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createDatetime"
          label="分销时间"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="distributeType"
          label="分销方式"
          min-width="120"
        >
          <template slot-scope="scope">
            <span>{{ getDistributeType(scope.row.distributeType) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="agentName"
          label="分销商姓名"
          min-width="120"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="createUser"
          label="操作人"
          min-width="100"
        >
        </el-table-column>
        <el-table-column label="操作" min-width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="themed-button"
              type="info"
              @click="viewFailDetail(scope.row.id)"
              >查看失败</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import resize from "@/mixins/resize.js";
import { distributeNetworkCard } from "@/api/history.js";
export default {
  name: "networkCardDistribution",
  mixins: [resize],
  data() {
    return {
      networkCardDistributeData: {
        agentName: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
    };
  },
  created() {
    this.getDistributionList();
  },
  methods: {
    getDistributeType(type) {
      let str;
      if (type == 1) {
        str = "号段分销";
      } else if (type == 2) {
        str = "文本分销";
      } else if (type == 3) {
        str = "文件分销";
      }
      return str;
    },
    getDistributionList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.networkCardDistributeData, params);
      distributeNetworkCard(this.networkCardDistributeData).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    viewFailDetail(id) {
      this.$router.push({
        path: "/historyRecord/networkCardFailList",
        query: { id: id },
      });
    },
    onPageChange(page) {
      this.page = page;
      this.getDistributionList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDistributionList();
    },
    onSearch() {
      this.page = 1;
      this.getDistributionList();
    },
  },
};
</script>