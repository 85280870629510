<template>
	<div class="monthlyUsageStatics fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">每月用量统计</span>
			<el-form inline v-model="formInline" class="right themed-form search-form">
				<el-form-item label="开始时间:">
					<el-date-picker style="width: 100%" v-model="formInline.startTime" type="date"
						value-format="yyyy-MM-dd" />
				</el-form-item>
				<el-form-item label="到期时间:">
					<el-date-picker style="width: 100%" v-model="formInline.endTime" type="date"
						value-format="yyyy-MM-dd" />
				</el-form-item>
				<el-form-item label="分销商:">
					<el-input v-model="formInline.agentThreeName"></el-input>
				</el-form-item>
				<el-form-item label="ICCID号:">
					<el-input v-model="formInline.iccidMark" class="iccid-width"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询
					</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="exportExcel" class="themed-button" icon="el-icon-caret-left">
						导出excel</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table ref="table" border stripe :data="tableData" tooltip-effect="dark" :max-height="maxTableHeight">
				<el-table-column prop="iccidMark" label="ICCID"> </el-table-column>
				<el-table-column prop="phone" label="接入号"> </el-table-column>
				<el-table-column prop="agentThreeName" label="代理商"> </el-table-column>
				<el-table-column prop="usedFlow" label="流量">
				</el-table-column>
				<el-table-column prop="usedVoice" label="语言"> </el-table-column>
				<el-table-column prop="accountName" label="运营商">
				</el-table-column>
				
			</el-table>
			<el-pagination :current-page.sync="pageNo" :page-sizes="[10, 15, 25, 100]" :page-size="pageSize" background
				layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
				@current-change="onPageChange"></el-pagination>
		</div>
	</div>
</template>
<script>
	import loading from "@/utils/Loading.js";
	import onresize from "@/mixins/resize.js";
	import {
		cardCycleStaticsList,cardCycleExport
	} from "@/api/staticsManagement.js";
	export default {
		name:'monthlyUsageStatics',
		mixins: [onresize],
		data() {
			return {
				formInline: {
					id: "",
                    flowSize: "",
                    agentId: "",
					startTime: "",
					endTime: "",
					iccidMark: "",
					agentThreeName: "",
				},
				pageNo: 1,
				pageSize: 15,
				total: 0,
				tableData: [],
				
			};
		},
		created() {
			this.getCardCycleStaticsList(this.formInline);
		},
		methods: {
			onSearch() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};
				Object.assign(params, this.formInline);
				this.getCardCycleStaticsList(params);
			},
			handleSizeChange(size) {
				this.pageSize = size;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};
				Object.assign(params, this.formInline);
				this.getCardCycleStaticsList(params);
			},
			onPageChange(page) {
				this.pageNo = page;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};
				Object.assign(params, this.formInline);
				this.getCardCycleStaticsList(params);
			},
			getCardCycleStaticsList(params) {
				cardCycleStaticsList(params).then((res) => {
					this.tableData = res.rows;
					this.pageNo = res.pageNo;
					this.pageSize = res.pageSize;
					this.total = res.total;
				});
			},
            exportExcel(){
                 loading.show()
				 /* ../../statistics/adminstatis/cardCycleExport.do?id=&flowSize=&agentId=&startTime=2021-05-05&endTime=&agentThreeName=&iccidMark= */
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				cardCycleExport(params).then(res => {
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
							confirmButtonText: '确定',
						}).then(() => {
							this.$router.push('/tob/i_export_list')
						});
					} else {
						this.$message({
							showClose: true,
							message: res.system_result_message_key,
							type: 'error'
						});
					}
				})
            }
		

		},
	};
</script>
<style>
	.monthlyUsageStatics .el-form-item__label {
		vertical-align: top;
	}
</style>
