<template>
    <div class="immediate-effective-record fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">立即生效记录</span>
            <el-form inline ref="effectiveForm" v-model="effectiveFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
                    <el-input v-model="effectiveFormData.iccid" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="effectiveFormData.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>
                <el-form-item label="方式">
                    <el-select v-model="effectiveFormData.type" placeholder="全部" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="充值" value="1"></el-option>
                        <el-option label="手动" value="2" ></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                 <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="130"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="accessNumber"
                    label="接入号"
                    min-width="180"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="agentNameTop"
                    label="一级代理商"
                    min-width="150"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="提前生效时间"
                    min-width="180"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="mealName"
                    label="套餐"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="方式"
                    min-width="100">
                    <template slot-scope="scope">
                       <span v-if="scope.row.type==1">充值</span>
                        <span v-else-if="scope.row.type==2">手动</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="operator"
                    label="操作人"
                    min-width="120"
                    >
                </el-table-column>
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { imEffectList } from "@/api/history.js";
export default {
    mixins:[resize],
    data(){
        return {
            effectiveFormData:{
                iccid:'',
                type:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getImEffectList()
    },
    methods: {
        getImEffectList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.effectiveFormData,params)
            imEffectList(this.effectiveFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getImEffectList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getImEffectList()
        },

        onSearch(){
            this.page=1
            this.getImEffectList()
        },
    },
}
</script>

<style>
.immediate-effective-record .el-form-item__label{
     vertical-align: top;
 }
</style>