<template>
  <div class="packageSeriesList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">支持商品选项列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
     <el-form-item label="名称:">
            <el-input v-model="formInline.name" autocomplete="off"></el-input>
          </el-form-item>
       <el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search"  class="themed-button">搜索</el-button>
				</el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="add"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="名称" min-width="120">
        </el-table-column>
        <el-table-column prop="accountType" label="运营商" min-width="120">
          <template slot-scope="scope">
            <span>{{ categoryString(scope.row.accountType) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mealName" label="套餐" min-width="120">
           <template slot-scope="scope">
            <span>{{ getmealName(scope.row.mealName) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="netModel" label="网络模式" min-width="120">
          <template slot-scope="scope">
            <span>{{ getInfo(scope.row.netModel, 1) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cardFunc" label="卡片功能" min-width="120">
          <template slot-scope="scope">
            <span>{{ getInfo(scope.row.cardFunc, 2) }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="netService" label="网络增值服务" min-width="120" >
          <template slot-scope="scope">
            <span>{{ getInfo(scope.row.netService, 3) }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="materialSpec" label="材质+规格" min-width="120">
          <template slot-scope="scope">
            <span>{{ getInfo(scope.row.materialSpec, 4) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="images" label="图片" min-width="120">
          <template slot-scope="scope">
             <el-popover
              placement="bottom"
              title=""
              width="300"
              trigger="click" > 
              <span>{{getImage(scope.row.images)}}</span>
              <el-button class="themed-button" type="primary" slot="reference">点击查看图片地址</el-button>
            </el-popover>
            <!-- <span>{{ getImage(scope.row.images) }}</span> -->
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
             <el-button
              type="primary"
              class="themed-button"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button style="display:none;" 
              type="danger"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="600">
        <el-form :model="addForm" :rules="rules" ref="addForm" >
          <el-form-item label="名称:" prop="name" :label-width="formLabelWidth">
            <el-input
              v-model.number="addForm.name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="运营商:" prop="accountType" :label-width="formLabelWidth">
            <el-select v-model="addForm.accountType" @change="changeAccount">
              <el-option label="请选择运营商" value=""></el-option>
              <el-option
                v-for="(item, index) in categorys"
                :label="item.name"
                :value="item.value"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="套餐分类:" prop="mealIds" :label-width="formLabelWidth">
            <el-select v-model="addForm.mealIds"  filterable multiple multiple-limit='9'>
              <!-- <el-option label="请选择套餐" value=""></el-option> -->
              <el-option
                v-for="(item, index) in packageList"
                :label="item.name"
                :value="item.id"
                :key="index"
              ></el-option>
            </el-select>
            <p style="color:#f00">所选套餐的周期与后面关联商品选择的套餐周期需一致</p>
          </el-form-item>
          <el-form-item label="网络模式:" prop="netModel" :label-width="formLabelWidth">
            <el-checkbox-group v-model="addForm.netModel">
              <el-checkbox
                v-for="item in netModellList"
                :key="item.value"
                :label="item.value"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="卡片功能:" prop="cardFunc" :label-width="formLabelWidth">
            <el-checkbox-group v-model="addForm.cardFunc">
              <el-checkbox
                v-for="item in cardFuncList"
                :key="item.value"
                :label="item.value"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="网络增值服务:" prop="netService" :label-width="formLabelWidth" style="display:none">
            <el-checkbox-group v-model="addForm.netService">
              <el-checkbox
                v-for="item in netServicelList"
                :key="item.value"
                :label="item.value"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="材质+规格:" prop="materialSpec" :label-width="formLabelWidth">
            <el-checkbox-group v-model="addForm.materialSpec">
              <el-checkbox
                v-for="item in materialList"
                :key="item.value"
                :label="item.value"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
           <el-form-item label="起购周期:" prop="leastBuyCount" :label-width="formLabelWidth">
            <el-input
              v-model.number="addForm.leastBuyCount"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片:" prop="images" :label-width="formLabelWidth">
            <el-upload
            accept="image/jpg,image/jpeg,image/png,image/svg" 
              class="upload-demo"
              :action="actionUrl"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :on-success="successUpload"
              :with-credentials='true'
              multiple
              :limit="4"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :before-upload="beforeAvatarUpload"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png/svg文件，且不超过2MB
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
           <el-button type="primary" @click="sureAdd('addForm')" class="themed-button">确 定</el-button>
			<el-button type="info" @click="dialogFormVisible = false" class="themed-button">取 消</el-button>
        
        </div>
      </el-dialog>
      
    </div>
  </div>
</template>

<script>
import  {apiHost} from "@/api/request.js"
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import {mapGetters} from 'vuex'
import { setmealList } from "@/api/setMealManagment/setMeal.js";
import {
  materialsList,
  allChoiceList,
  supportList,
  addSupportList,
  deleteSupportList,
  getLimitPageList,
  getLimitUpdate
} from "@/api/goodList.js";
export default {
  name: "packageSeriesList",
  mixins: [resize],
  data() {
     var validateLeastBuyCount = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('起购周期不能为空'));
        } else {
          if (! /^[1-9]\d*$/.test(value)) {
            callback(new Error('请输入大于0的正整数'));
          }
           if (value>=100000000) {
          callback(new Error("起购周期不能大于等于100000000"));
        }
          callback();
        }
      };
    return {
      fileList:[{name:"",url:""},],
      actionUrl:apiHost+"commonUploadFile/upload.do?",
      formLabelWidth:"120px",
      formInline: {
       name:"",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      materialList: [],
      specList: [],
      packageList: [],
      netModellList: [],
      cardFuncList: [],
      netServicelList: [],
      dialogFormVisible: false,
      addForm: {
        id:"",
        name: "",
        accountType: "",
        // mealId: "",
        mealIds:[],
        netModel: [],
        cardFunc: [],
        netService: [],
        materialSpec: [],
        images: [],
        leastBuyCount:"",
      },
     dialogTitle:"",
      rules: {
        name: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
        ],
        accountType: [
          {
            required: true,
            message: "运营商不能为空",
            trigger: "change",
          },
        ],
        mealIds: [
          // {
          //   required: true,
          //   message: "套餐分类不能为空",
          //   trigger: "change",
          // },
          { type: 'array', required: true, message: '请至少选择一个套餐分类', trigger: 'change' }
        ],
        netModel: [
           { type: 'array', required: true, message: '请至少选择一个网络模式', trigger: 'change' }
          
        ],
        cardFunc: [
          { type: 'array', required: true, message: '请至少选择一个卡片功能', trigger: 'change' }
          
        ],
        // netService: [
        //    { type: 'array', required: true, message: '请至少选择一个网络增值服务', trigger: 'change' }
         
        // ],
        materialSpec: [
           { type: 'array', required: true, message: '请至少选择一个材料规格组合', trigger: 'change' }
         
        ],
        leastBuyCount:[
          { validator: validateLeastBuyCount, trigger: 'blur' }
         
        ]
      },

    };
  },
   computed:{
             ...mapGetters(
            	["categorys"]
            ),
    	},
  created() {
    console.log(apiHost)
    if (store.getters.categorys.length < 1) {
      store.dispatch("getCategory");
    }
    allChoiceList().then((res) => {
      if (JSON.stringify(res.netModelMap) == "{}") {
      } else {
        this.netModellList = [];
        for (let i in res.netModelMap) {
          this.netModellList.push({ value: i, name: res.netModelMap[i] });
        }
        // console.log(this.netModellList);
      }
      if (JSON.stringify(res.cardFuncMap) == "{}") {
      } else {
        this.cardFuncList = [];
        for (let i in res.cardFuncMap) {
          this.cardFuncList.push({ value: i, name: res.cardFuncMap[i] });
        }
        // console.log(this.cardFuncList);
      }
      if (JSON.stringify(res.netServiceMap) == "{}") {
      } else {
        this.netServicelList = [];
        for (let i in res.netServiceMap) {
          this.netServicelList.push({ value: i, name: res.netServiceMap[i] });
        }
        // console.log(this.netServicelList);
      }
    });
    this.getMaterials();
    this.getPackageSeriesList();
  },
  methods: {
     searchData() {
      this.page = 1;
      this.getPackageSeriesList();
    },
    changeAccount(val) {
      console.log("change")
      this.getPackageList(val);
    },
    getPackageList(val) {
      let params = {
        category: val,
        status:"1",
        pageSize:10000,
      };
      setmealList(params).then((res) => {
        // console.log(res.rows);
        this.packageList = [];
        res.rows.forEach((item) => {
          if(item.type!=7){
       this.packageList.push({ id: item.id, name: item.name });
          }
        });
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      try{
        this.addForm.images=  this.addForm.images.filter(item=>item!=file.response.url)
      }catch(error){
      this.addForm.images=  this.addForm.images.filter(item=>item!=file.url)
      }
      // this.addForm.images=  this.addForm.images.filter(item=>item!=file.response.url)
      // console.log( this.addForm.images)
    },
    
    successUpload(response){
      console.log(response)
    this.addForm.images.push(response.url)
    // console.log( this.fileList)
    },
   
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 4 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeAvatarUpload(file) {

      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      console.log(img)
    const suffix = img === 'jpg'
    const suffix2 = img === 'png'
    const suffix3 = img === 'jpeg'
    const suffix4 = img === 'svg'
    const isLt1M = file.size / 1024 / 1024 < 2;
    if (!suffix && !suffix2 && !suffix3&& !suffix4) {
        this.$message.error("只能上传图片！");
        return false
    }
    // 可以限制图片的大小
    if (!isLt1M) {
        this.$message.error('上传图片大小不能超过 2MB!');
    }
    return suffix || suffix2 || suffix3 ||suffix4 


      },
   getmealName(mealName){
    let mealNameStr="";
    let mealNameArr =[]
    try{
 if(mealName){
 mealNameArr=  JSON.parse(mealName) 
      for (let i of mealNameArr) {
        mealNameStr += i+",";
      }
    }
  return mealNameStr.substring(0,mealNameStr.length-1);
    }catch(error){
          return mealName
    }
   
   },
    getImage(img) {
      let imgStr = "";
      let imgArr2 =[]
      if(img){
 imgArr2 = JSON.parse(img)
   for (let i of imgArr2) {
        imgStr += i+",";
      }
      }
    
      return imgStr.substring(0,imgStr.length-1);
    },
    getInfo(idstr, index) {
      // console.log(index)
      let nameStr = "";
      if(idstr){
let idArr = JSON.parse(idstr);
      let arrList = [];
      if ((index == 1)) {
        arrList = this.netModellList;
      } else if ((index == 2)) {
        arrList = this.cardFuncList;
      } else if ((index == 3)) {
        arrList = this.netServicelList;
      } else if ((index == 4)) {
        arrList = this.materialList;
      }
      arrList.forEach((oneitem) => {
        for (let i of idArr) {
          if (i == oneitem.value) {
            nameStr += oneitem.name+",";
          }
        }
      });
      }
      
      return nameStr.substring(0,nameStr.length-1);
    },
    categoryString(category) {
      let cuCatagorys;
      if (store.getters.categorys.length > 0) {
        store.getters.categorys.forEach((item) => {
          if (category == item.value) {
            cuCatagorys = item.name;
          }
        });
      }
      return cuCatagorys;
    },
    getMaterials() {
      materialsList({}).then((res) => {
        this.materialList = [];
        res.forEach((item) => {
          this.materialList.push({
            value: item.id,
            name: item.materialStr + " "+ item.specStr,
          });
        });
      });
    },
    getPackageSeriesList() {
        let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.formInline, params);
      getLimitPageList(this.formInline).then((res) => {
        console.log(res)
        this.tableData = res.rows
        this.total = res.count;

      });
    },
    onPageChange(page) {
      this.page = page;
      this.getPackageSeriesList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPackageSeriesList();
    },
    edit(row){
      this.fileList=[]
 this.dialogTitle = "编辑支持选项"
       this.dialogFormVisible = true;
        this.$nextTick(() => {
        this.$refs['addForm'].clearValidate();
    })
    this.getPackageList(row.accountType)
    this.addForm.id = row.id
      this.addForm.name = row.name;
      this.addForm.accountType = row.accountType+"";
      // this.addForm.mealId = row.mealId;
       this.addForm.mealIds = JSON.parse(row.mealIds);
      this.addForm.netModel=JSON.parse(row.netModel) 
      this.addForm.cardFunc=JSON.parse(row.cardFunc)
      this.addForm.netService=JSON.parse(row.netService)
      this.addForm.materialSpec= JSON.parse(row.materialSpec)
      
      this.addForm.images = JSON.parse(row.images)
      this.addForm.leastBuyCount = row.leastBuyCount
      if(JSON.parse(row.images).length>0){
         for(let i=0 ;i<JSON.parse(row.images).length;i++){
            this.fileList.push({name:JSON.parse(row.images)[i].split('com/')[1],url:JSON.parse(row.images)[i]})
         }
      }
      console.log( this.fileList)
    },
    add() {
       if(this.materialList.length==0){
        console.log(this.materialList.length)
          this.$alert('请先去材质规格列表新增材质规格组合', '提示', {
								confirmButtonText: '确定',
							});
              return 
      }
       this.dialogTitle = "新增支持选项"
       this.dialogFormVisible = true;
        this.$nextTick(() => {
        this.$refs['addForm'].clearValidate();
    })
      this.addForm.id = "";
      this.addForm.name = "";
      this.addForm.accountType = "";
      // this.addForm.mealId = "";
       this.addForm.mealIds = []
      this.addForm.netModel=[]
      this.addForm.cardFunc=[]
      this.addForm.netService=[]
      this.addForm.materialSpec=[]
      this.addForm.images =[]
      this.addForm.leastBuyCount = ""
      this.fileList =[]
     
    },
    sureAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.addForm.id){
 
        let params = {
          id:this.addForm.id,
          name:this.addForm.name,
          accountType:this.addForm.accountType,
          // mealId: this.addForm.mealId,
          netModel:JSON.stringify(this.addForm.netModel),
          cardFunc:JSON.stringify(this.addForm.cardFunc),
          netService:JSON.stringify(this.addForm.netService),
          materialSpec:JSON.stringify(this.addForm.materialSpec),
          leastBuyCount:this.addForm.leastBuyCount,
          images :JSON.stringify(this.addForm.images),
          mealIds:JSON.stringify(this.addForm.mealIds)
        }
          getLimitUpdate(params).then((responseResult) => {
            if (responseResult.slide_msg_key == 1) {
              this.$message({
                showClose: true,
                message: responseResult.slide_msg_message_key,
                type: "warning",
              });
            }
            this.getPackageSeriesList();
            this.dialogFormVisible = false;
          });
          }else{
           
        let params = {
          name:this.addForm.name,
          accountType:this.addForm.accountType,
          // mealId: this.addForm.mealId,
          netModel:JSON.stringify(this.addForm.netModel),
          cardFunc:JSON.stringify(this.addForm.cardFunc),
          netService:JSON.stringify(this.addForm.netService),
          materialSpec:JSON.stringify(this.addForm.materialSpec),
          leastBuyCount:this.addForm.leastBuyCount,
          images :JSON.stringify(this.addForm.images),
           mealIds:JSON.stringify(this.addForm.mealIds)
        }
          addSupportList(params).then((responseResult) => {
            if (responseResult.slide_msg_key == 1) {
              this.$message({
                showClose: true,
                message: responseResult.slide_msg_message_key,
                type: "warning",
              });
            }
            this.getPackageSeriesList();
            this.dialogFormVisible = false;
          });
          }
        
        } else {
          return false;
        }
      });
    },
    delect(id) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteSupportList({ id }).then((res) => {
          this.getPackageSeriesList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
};
</script>

<style>
</style>
