<template>
  <div class="autoRechargeSetmeal fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">自动续费套餐列表</span>
      <el-form
        :inline="true"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
        id="baseForm"
      >
        <el-form-item label="iccid:">
          <el-input v-model="form.iccid" placeholder="输入ICCID"></el-input>
        </el-form-item>
        <el-form-item label="短号:">
          <el-input v-model="form.shortNo" placeholder="输入短号"></el-input>
        </el-form-item>
        <el-form-item label="充值方式:">
          <el-select v-model="form.rechargeType">
            <el-option label="请选择充值方式" value=""></el-option>
            <el-option label="普通类型" value="1"></el-option>
            <el-option label="预存话费类型" value="2" v-if="parseInt(isGuangyao)"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            class="themed-button"
            icon="el-icon-search"
            >搜索查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="themed-button"
            @click="uploadImportExcel"
            >导入</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
           icon="el-icon-delete" 
           type="danger"
            class="themed-button"
            @click="deleteImportExcel"
            >批量删除</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            class="themed-button"
            @click="exportExcel"
            >导出excel</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        ref="table"
        :max-height="maxTableHeight"
        border
      >
        <el-table-column prop="iccid" label="iccid"> </el-table-column>
        <el-table-column prop="shortNo" label="短号"> </el-table-column>
        <el-table-column prop="rechargeType" label="充值方式">
          <template slot-scope="scope">
            <span>{{
              scope.row.rechargeType == "1" ? "普通类型" : "预存话费类型"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="autoRecharge" label="自动续费">
          <template slot-scope="scope">
            <span>{{ scope.row.autoRecharge ? "自动续费" : "手动续费" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="setmealName" label="月租套餐"> </el-table-column>
        <el-table-column prop="expireTime" label="到期时间"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="themed-button"
              type="danger"
              @click="deleteRecord(scope.row.iccid)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="导入"
      :visible.sync="dialogImportCard"
      destroy-on-close
      v-if="dialogImportCard"
    >
      <el-form
        label-position="right"
        label-width="120px"
        :model="importCardForm"
        ref="importCardForm"
      >
        <el-form-item label="选择excel文件:">
          <input class="file-input" type="file" ref="importCardfile" />
          <div class="mt5">
            <span class="text-color" style="font-size: 12px"
              >仅支持.xls/.xlsx格式</span
            >
          </div>
        </el-form-item>
        <el-form-item label="代理商:" required prop="agentId">
          <el-select
            v-model="importCardForm.agentId"
            filterable
            placeholder="请选择代理商"
            @change="agentSetMealForImportCard"
          >
            <el-option
              v-for="(item, index) in agentLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运营商:" required prop="accountId">
          <el-select
            v-model="importCardForm.accountId"
            filterable
            placeholder="请选择运营商"
            @change="agentSetMealForImportCard"
          >
            <el-option
              v-for="(item, index) in accountLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐:" required prop="setmealId">
          <el-select
            v-model="importCardForm.setmealId"
            filterable
            placeholder="请选择套餐"
          >
            <el-option
              v-for="(item, index) in packageLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值方式:"    style="display:none;">
          <el-select v-model="importCardForm.rechargeType">
            <el-option label="请选择充值方式" value=""></el-option>
            <el-option label="普通类型" value="1"></el-option>
            <el-option label="预存话费类型" value="2" v-if="parseInt(isGuangyao)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            v-model="importCardForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="ICCID/短号:">
          <el-input
            v-model="importCardForm.iccids"
            type="textarea"
            placeholder="请输入ICCID/短号换行分割"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="info" @click="importCardExcel()"
          >导入excel模板</el-button
        >
        <el-button
          class="themed-button"
          type="info"
          @click="exportTemplateExcel('importIccidOrShort.xls')"
          >导出excel模板</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogImportCard = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>

     <el-dialog
      title="删除"
      :visible.sync="dialogDeleteCard"
      destroy-on-close
      v-if="dialogDeleteCard"
    >
      <el-form
        label-position="right"
        label-width="120px"
        :model="deleteCardForm"
        ref="deleteCardForm"
      >
        <el-form-item label="选择excel文件:">
          <input class="file-input" type="file" ref="deleteCardfile" />
          <div class="mt5">
            <span class="text-color" style="font-size: 12px"
              >仅支持.xls/.xlsx格式</span
            >
          </div>
        </el-form-item>
        <el-form-item label="代理商:" required prop="agentId">
          <el-select
            v-model="deleteCardForm.agentId"
            filterable
            placeholder="请选择代理商"
            @change="agentSetMealForImportCard"
          >
            <el-option
              v-for="(item, index) in agentLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运营商:" required prop="accountId">
          <el-select
            v-model="deleteCardForm.accountId"
            filterable
            placeholder="请选择运营商"
            @change="agentSetMealForImportCard"
          >
            <el-option
              v-for="(item, index) in accountLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
    
        <el-form-item label="ICCID/短号:">
          <el-input
            v-model="deleteCardForm.iccids"
            type="textarea"
            placeholder="请输入ICCID/短号换行分割"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="info" @click="deleteCardExcel()"
          >导入excel模板</el-button
        >
        <el-button
          class="themed-button"
          type="info"
          @click="exportTemplateExcel('importIccidOrShort.xls')"
          >导出excel模板</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogDeleteCard = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onresize from "@/mixins/resize.js";
import {messageBox,confirmBox} from '@/utils/common.js'
import {
  autoRechargeSetmeal,
  delAutoRechargeSetmeal,
  exportAutoRechargeSetmeal,
  importAutoRechargeSetmeal,
  deleteAutoRechargeSetmeal
} from "@/api/autoRechargeSetmeal.js";
import {
  getAccountList,
  getAgentForImportCard,
  gridAgentSetMealForImportCard,
  gridSetMealForImportCard,
} from "@/api/cardManagement/cardImport.js";
import Utils from "@/utils/utils.js";
import { exportTemplate } from "@/api/statistics.js";
export default {
  name: "autoRechargeSetmeal",
  mixins: [onresize],
  data() {
    return {
      // isCustomer:this.isGuangyao,
      form: {
        iccid: "",
        shortNo: "",
        rechargeType: "",
      },
      page: 1,
      pageSize: 15,
      total: 0,
      tabledata: [],
      dialogImportCard: false,
      importCardForm: {
        iccids: "",
        agentId: "",
        accountId: "",
        setmealId: "",
        // rechargeType: "",
        remark: "",
      },
      accountLists: [], //运营商
      agentLists: [], //代理商
      packageLists: [], //套餐
      dialogDeleteCard:false,
       deleteCardForm: {
        iccids: "",
        agentId: "",
        accountId: "",
      },

    };
  },
  created() {
    this.getAutoRechargeSetmeal();
  },
  methods: {
    deleteImportExcel(){
     this.dialogDeleteCard = true;
      this.deleteCardForm.iccids = "";
      this.deleteCardForm.agentId = "";
      this.deleteCardForm.accountId = "";
      this._getAccountList();
      this._getAgentForImportCard();
    },
    deleteCardExcel(){
     this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let file = this.$refs["deleteCardfile"].files[0];
        if (file) {
          let fileName = file.name;
          let index = fileName.lastIndexOf(".");
          if (
            index < 0 ||
            !(
              fileName.substring(index + 1, fileName.length) == "xls" ||
              fileName.substring(index + 1, fileName.length) == "xlsx"
            )
          ) {
            this.$alert("文件格式错误", "提示", {
              confirmButtonText: "确定",
            });
            return;
          } else if (!!Utils.checkFile(file)) {
            if (Utils.checkFile(file) == 1) {
              messageBox(this, "上传文件不能超过20M");
              return;
            } else if (Utils.checkFile(file) == 2) {
              messageBox(this, "上传文件名称长度不能超过36个字符");
              return;
            }
          } else {
            let param = new FormData();
            param.append("fileurl", file);
            if (!this.deleteCardForm.agentId) {
              messageBox(this, "请选择代理商");
              return;
            }
            if (!this.deleteCardForm.accountId) {
              messageBox(this, "请选择运营商");
              return;
            }
           
            param.append("accountId", this.deleteCardForm.accountId);
            param.append("agentId", this.deleteCardForm.agentId);
            param.append("isAdd", false);

            deleteAutoRechargeSetmeal(param).then((res) => {
              if (res.system_result_key == "0") {
                this.$alert("删除成功，请到导入记录中下载", "提示", {
                  confirmButtonText: "确定",
                }).then(() => {
                  this.dialogDeleteCard = false;
                  this.$router.push("/tob/i_import_list");
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.system_result_message_key,
                  type: "error",
                });
              }
            });
          }
        } else if (!!this.deleteCardForm.iccids.trim()) {
          if (!Utils.checkICCID(this.deleteCardForm.iccids)) {
            messageBox(this, "iccid只能包含数字和字母");
            return;
          }
          let param = new FormData();
          if (!this.deleteCardForm.agentId) {
            messageBox(this, "请选择代理商");
            return;
          }
          if (!this.deleteCardForm.accountId) {
            messageBox(this, "请选择运营商");
            return;
          }
         
           param.append("accountId", this.deleteCardForm.accountId);
           param.append("agentId", this.deleteCardForm.agentId);
           param.append("isAdd", false);
          param.append("iccids", this.deleteCardForm.iccids);
          deleteAutoRechargeSetmeal(param).then((res) => {
            if (res.system_result_key == "0") {
              this.$alert(res.system_result_message_key, "提示", {
                confirmButtonText: "确定",
              }).then(() => {
                this.dialogDeleteCard = false;
                this.getAutoRechargeSetmeal();
              });
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
        } else {
          this.$alert("请选择文件或输入ICCID", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      });
    },
    importCardExcel() {
      this.$confirm("确定导入吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let file = this.$refs["importCardfile"].files[0];
        if (file) {
          let fileName = file.name;
          let index = fileName.lastIndexOf(".");
          if (
            index < 0 ||
            !(
              fileName.substring(index + 1, fileName.length) == "xls" ||
              fileName.substring(index + 1, fileName.length) == "xlsx"
            )
          ) {
            this.$alert("文件格式错误", "提示", {
              confirmButtonText: "确定",
            });
            return;
          } else if (!!Utils.checkFile(file)) {
            if (Utils.checkFile(file) == 1) {
              messageBox(this, "上传文件不能超过20M");
              return;
            } else if (Utils.checkFile(file) == 2) {
              messageBox(this, "上传文件名称长度不能超过36个字符");
              return;
            }
          } else {
            let param = new FormData();
            param.append("fileurl", file);
            if (!this.importCardForm.agentId) {
              messageBox(this, "请选择代理商");
              return;
            }
            if (!this.importCardForm.accountId) {
              messageBox(this, "请选择运营商");
              return;
            }
            if (!this.importCardForm.setmealId) {
              messageBox(this, "请选择套餐");
              return;
            }
            param.append("accountId", this.importCardForm.accountId);
            param.append("agentId", this.importCardForm.agentId);
            param.append("setmealId", this.importCardForm.setmealId);
            // param.append("rechargeType", this.importCardForm.rechargeType);
            param.append("remark", this.importCardForm.remark);
             param.append("isAdd", true);

            deleteAutoRechargeSetmeal(param).then((res) => {
              if (res.system_result_key == "0") {
                this.$alert("导入成功，请到导入记录中下载", "提示", {
                  confirmButtonText: "确定",
                }).then(() => {
                  this.dialogImportCard = false;
                  this.$router.push("/tob/i_import_list");
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.system_result_message_key,
                  type: "error",
                });
              }
            });
          }
        } else if (!!this.importCardForm.iccids.trim()) {
          if (!Utils.checkICCID(this.importCardForm.iccids)) {
            messageBox(this, "iccid只能包含数字和字母");
            return;
          }
          let param = new FormData();
          if (!this.importCardForm.agentId) {
            messageBox(this, "请选择代理商");
            return;
          }
          if (!this.importCardForm.accountId) {
            messageBox(this, "请选择运营商");
            return;
          }
          if (!this.importCardForm.setmealId) {
            messageBox(this, "请选择套餐");
            return;
          }
           param.append("accountId", this.importCardForm.accountId);
           param.append("agentId", this.importCardForm.agentId);
          param.append("setmealId", this.importCardForm.setmealId);
          // param.append("rechargeType", this.importCardForm.rechargeType);
          param.append("remark", this.importCardForm.remark);
          param.append("iccids", this.importCardForm.iccids);
           param.append("isAdd", true);
          deleteAutoRechargeSetmeal(param).then((res) => {
            if (res.system_result_key == "0") {
              this.$alert("添加成功", "提示", {
                confirmButtonText: "确定",
              }).then(() => {
                this.dialogImportCard = false;
                this.getAutoRechargeSetmeal();
              });
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
        } else {
          this.$alert("请选择文件或输入ICCID", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      });
    },
    exportTemplateExcel(name) {
      //修改name
      exportTemplate({ name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
    uploadImportExcel() {
      this.dialogImportCard = true;
      this.importCardForm.iccids = "";
      this.importCardForm.agentId = "";
      this.importCardForm.accountId = "";
      this.importCardForm.setmealId = "";
      // this.importCardForm.rechargeType = "";
      this.importCardForm.remark = "";
      this._getAccountList();
      this._getAgentForImportCard();
    },
    agentSetMealForImportCard() {
      let params = {};
      params.accountId = this.importCardForm.accountId;
      params.accountList = this.importCardForm.accountId;
      params.agentId = this.importCardForm.agentId;
      params.agentList = this.importCardForm.agentId;
      params.isRealName = 2;
      params.isAgentRecharge = 2;
      params.importNoLoopCard = 1;
      if (this.importCardForm.accountId && this.importCardForm.agentId) {
        this._gridAgentSetMealForImportCard(params);
      } else if (this.importCardForm.accountId) {
        this._gridSetMealForImportCard(params);
      }
    },
    _gridAgentSetMealForImportCard(params) {
      gridAgentSetMealForImportCard(params).then((res) => {
        if (res.date.length != 0) {
          this.packageLists = res.date.map((value, index, arr) => {
            return { value: value.setMealId, label: value.name };
          });
        } else {
          this.packageLists = [];
        }
        this.packageLists.unshift({ value: "", label: "请选择套餐" });
      });
    },
    _gridSetMealForImportCard(params) {
      gridSetMealForImportCard(params).then((res) => {
        console.log(res);
        this.packageLists = [{ label: "请选择套餐", value: "" }];
        if (res.data.length != 0) {
          res.data.forEach((item) => {
            if (item.type != 11) {
              this.packageLists.push({ label: item.name, value: item.id });
            }
          });
        }
      });
    },
    _getAccountList() {
      getAccountList()
        .then((res) => {
          this.accountLists = res.map((value, index, arr) => {
            return { value: value.id, label: value.accountName };
          });
        })
        .catch(() => {
          this.accountLists = [];
        });
    },
    _getAgentForImportCard() {
      getAgentForImportCard()
        .then((res) => {
          this.agentLists = res.map((value, index, arr) => {
            return { value: value.id, label: value.agentThreeName };
          });
          this.agentLists.unshift({ value: "", label: "请选择代理商" });
        })
        .catch(() => {
          this.agentLists = [];
        });
    },
    getAutoRechargeSetmeal() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.form, params);
      autoRechargeSetmeal(this.form).then((res) => {
        this.tabledata = res.rows;
        console.log(this.tableData);
        this.total = res.count;
      });
    },

    searchData() {
      this.page = 1;
      this.getAutoRechargeSetmeal();
    },
    onPageChange(page) {
      this.page = page;
      this.getAutoRechargeSetmeal();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getAutoRechargeSetmeal();
    },
    deleteRecord(iccid) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delAutoRechargeSetmeal({cardNo:iccid }).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
        this.getAutoRechargeSetmeal();
      });
    },
    exportExcel() {
      exportAutoRechargeSetmeal(this.form).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
  },
     computed: {
    ...mapGetters(['isGuangyao']),
  },
};
</script>

<style>
.autoRechargeSetmeal .el-form-item__label {
  vertical-align: top;
}
</style>
