import request from '../request.js'
export function getActiveRate(params) {
    return request({
        url: 'cardStatics/statusList.do?',
        method: 'post',
        data: params
    })
}


export function getOnLineTime(params) {
    return request({
        url: 'cardStatics/cardAttrList.do?',
        method: 'post',
        data: params
    })
}

export function getDaySingleCardList(params) {
    return request({
        url: 'cardlifeStatics/statusList.do?',
        method: 'post',
        data: params
    })
}

export function exportActiveRate(params) {
    return request({
        url: 'cardStatics/syncStatusExport.do?',
        method: 'post',
        data: params
    })
}

export function exportOnLineTime(params) {
    return request({
        url: 'cardStatics/syncCardAttrExport.do?',
        method: 'post',
        data: params
    })
}

export function exportDaySingleCardList(params) {
    return request({
        url: 'cardlifeStatics/syncStatusExport.do?',
        method: 'post',
        data: params
    })
}


// 月统计
export function getAccountList() {
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post'
    })
}

export function exportAccountList() {
    return request({
        url: 'system/cardOperator/accountListExport.do?',
        method: 'post'
    })
}

export function rechargeMonthStatics(data) {
    return request({
        url: 'staticsAgent/rechargeMonthStatics.do?',
        method: 'post',
        data: data
    })
}
export function exportRechargeMonthStatics(data) {
    return request({
        url: 'staticsAgent/rechargeMonthStaticsExport.do?',
        method: 'post',
        data: data
    })
}


export function rateMonthStatics(data) {
    return request({
        url: 'staticsAgent/rateMonthStatics.do?',
        method: 'post',
        data: data
    })
}
export function exportRateMonthStatics(data) {
    return request({
        url: 'staticsAgent/rateMonthStaticsExport.do?',
        method: 'post',
        data: data
    })
}


export function ARPUStatics(data) {
    return request({
        url: 'staticsAgent/ARPUStatics.do?',
        method: 'post',
        data: data
    })
}
export function exportARPUStatics(data) {
    return request({
        url: 'staticsAgent/ARPUStaticsExport.do?',
        method: 'post',
        data: data
    })
}


export function userNumStatics(data) {
    return request({
        url: 'mealNumStatics/userNumStatics.do?',
        method: 'post',
        data: data
    })
}
export function exportUserNumStatics(data) {
    return request({
        url: 'mealNumStatics/userNumStaticsExport.do?',
        method: 'post',
        data: data
    })
}

export function addNumStatics(data) {
    return request({
        url: 'mealNumStatics/AddNumStatics.do?',
        method: 'post',
        data: data
    })
}

export function exportAddNumStatics(data) {
    return request({
        url: 'mealNumStatics/AddNumStaticsExport.do?',
        method: 'post',
        data: data
    })
}

export function userRechargerMonthAVG(data) {
    return request({
        url: 'mealNumStatics/userRechargerMonthAVG.do?',
        method: 'post',
        data: data
    })
}
export function exportUserRechargerMonthAVG(data) {
    return request({
        url: 'mealNumStatics/userRechargerMonthAVGExport.do?',
        method: 'post',
        data: data
    })
}



export function packageRateStatic(data) {
    return request({
        url: 'mealFlowStatics/addMealStatics.do?',
        method: 'post',
        data: data
    })
}
export function exportPackageRateStatic(data) {
    return request({
        url: 'mealFlowStatics/addMealStaticsExport.do?',
        method: 'post',
        data: data
    })
}

export function totalFlowStatics(data) {
    return request({
        url: 'mealFlowStatics/fLowStatics.do?',
        method: 'post',
        data: data
    })
}
export function exportTotalFlowStatics(data) {
    return request({
        url: 'mealFlowStatics/fLowStaticsExport.do?',
        method: 'post',
        data: data
    })
}

export function profitStatics(data) {
    return request({
        url: 'mealFlowStatics/profitStatics.do?',
        method: 'post',
        data: data
    })
}
export function exportProfitStatics(data) {
    return request({
        url: 'mealFlowStatics/profitStaticsExport.do?',
        method: 'post',
        data: data
    })
}

export function useFlowRate(data) {
    return request({
        url: 'mealFlowStatics/useFlowRate.do?',
        method: 'post',
        data: data
    })
}


export function geMealList(data) {
    return request({
        url: 'meal/setmeal/getMealListByAgent.do?',
        method: 'post',
        data: data
    })
}

// 根据条件获取选项

export function searchParamCheck(data) {
    return request({
        url: 'staticsAgent/searchParamChecksingle.do?',
        method: 'post',
        data: data
    })
}












