<template>
	<div class="editOfficial">
		<el-form :model="form" ref="ruleFormEdit">
				  <el-form-item label="是否开启定位:" required  :label-width="formLabelWidth">
					<el-select v-model="form.locateFlag">
					<el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
					</el-select>
			    </el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="baseFormSubmit()" class="themed-button">确 定</el-button>
			<el-button type="primary" @click="baseFormReset('ruleFormEdit')" class="themed-button">重 置</el-button>
			<el-button  type="info" @click="cancelDialog" class="themed-button">取 消</el-button>
		</div>
	</div>
</template>

<script>
	import  loading from "@/utils/Loading.js"
	import {editOfficialList,} from "@/api/payManagment.js"
	export default{
		name:'editOfficial',
        props:["editData"],
		data(){
			return {
			form:{
			id:"",
			locateFlag:""
			},
			formLabelWidth:'180px'
			}
		},
        mounted(){
          this.form.id = this.editData.id
          this.form.locateFlag = this.editData.locateFlag+""
        },
		methods:{
			baseFormSubmit(){
			
				if(this.form.locateFlag == null ||this.form.locateFlag  ==""){
                  this.$alert('请选择地理位置权限开关','系统提示',{
					          confirmButtonText:"确定",
				  })
				  return
				}
				loading.show()
				editOfficialList(this.form).then(res=>{
					if(res.slide_msg_key==1){
						this.$alert(res.slide_msg_message_key,"消息提示")
						return ;
					}
					this.$emit("reload")
					this.$emit("close")
					
				})
			},
			baseFormReset(formName){
				//this.$refs[formName].resetFields();
			
				this.form.locateFlag = this.editData.locateFlag
			},
			cancelDialog(){
				console.log("close")
				this.$emit("close")
			}
		}
	}
</script>

<style>
	.editOfficial .dialog-footer{
		text-align: right;
	}
</style>
