<template>
   
  <div class="table-hook fadeInRight common">
    <div class="noRenZhen" v-if="showRZ">
			<p>因运营商规则，在线购卡需进行企业相关资料及法人信息审核， 提交资料后即可购卡</p>
			<p>企业信息认证  <span>{{renZhenStatus}}</span></p>
			<div>
				<el-button type="warning" class="warning"  @click="toRenZhen">去认证</el-button>
			</div>
		</div>
    <div v-else>
 <div class="title-box">
      <span class="m-title">商店</span>
      <div @click="goToShopping" class="right cursor" >
        <el-badge :value="defaultNum" class="item" type="primary">
          <i class="el-icon-shopping-cart-2"></i>
        </el-badge>
        购物车
      </div>
    </div>
    <div class="content-box">
      <el-tabs
        type="border-card"
        v-model="formSearch.cardType"
        @tab-click="handleClick"
      >
        <el-tab-pane
          :label="item.name"
          :name="item.id"
          v-for="item in cardTypeList"
          :key="item.id"
        >
          <div class="container-content">
            <el-form
              label-position="left"
              label-width="150px"
              :model="formSearch"
            >
              <div class="headButton">
                <el-button type="text" class="text" @click="resetSearch">重置</el-button>
                <el-button
                  type="text" class="text"
                  v-if="isExpond"
                  @click="isExpond = !isExpond"
                  >展开</el-button
                >
                <el-button type="text" class="text" v-else @click="isExpond = !isExpond"
                  >收起</el-button
                >
              </div>
              <el-form-item label="套餐类型" v-show="isExpond">
                <!-- <el-checkbox-group
                  v-model="formSearch.packageType"
                  @change="handlePackageTypeChange"
                >
                  <el-checkbox
                    v-for="(item, index) in packageTypeList"
                    :label="item.id + '@@' + item.name"
                    :key="index"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group> -->
                <el-select v-model="formSearch.mealType" class="search-select"  @change="handlePackageTypeChange">
                  <el-option
                    label="选择套餐类型"
                    value=""
                   
                  ></el-option>
                  <el-option label="按月" value="1"></el-option>
                  <el-option label="累计" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="套餐规格范围" v-show="isExpond">
                <div class="block">
                  <span class="slider_span">{{ packageRangeStart }}</span>
                  <el-slider
                    v-model="packageRange"
                    range
                    :step="10"
                    :format-tooltip="formatTooltip"
                    @change="handlePackageRangeChange"
                  >
                  </el-slider>
                  <span class="slider_span" style="text-align: right">{{
                    packageRangeEnd
                  }}</span>
                </div>
              </el-form-item>
              <!-- <el-form-item label="网络" v-show="isExpond">
                <el-checkbox-group
                  v-model="formSearch.network"
                  @change="handleNetworkChange"
                >
                  <el-checkbox
                    v-for="(item, index) in networkList"
                    :label="item.id + '@@' + item.name"
                    :key="index"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item> -->
              <el-form-item label="筛选条件" v-show="!isExpond">
                <span>{{ getSearchItem() }}</span>
              </el-form-item>
            </el-form>
            <el-row>
              <el-col :span="8"
                ><div class="packageList">
                  <div class="packageListContent">
                    <div class="packageListHead">
                      <img src="../../assets/cmcc.svg" alt="" />
                    </div>
                    <div class="packageListBody" v-if="cmccList.length > 0">
                      <div
                        class="packageListItem"
                        v-for="(item, index) in cmccList"
                        :key="index"
                        @click="startShopping(item)"
                      >
                        <div class="itemLeft">
                          <!-- <span class="flowPermonth">{{
                            getFlowPermonth(item.mealSize, item.mealCount)
                          }}</span> -->
                          <span class="flowPermonth">{{
                            item.productName
                          }}</span>
                        </div>
                        <div class="itemRight">
                          <div class="lastTime"></div>
                          <div class="pricePackgeDiv">
                            <span class="pricePackge">{{
                              "￥ " + item.fee.toFixed(2)
                            }}</span>
                            <span style="font-size: 12px"> 起</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="packgeNone" v-else>当前运营商没有套餐哦~</div>
                  </div>
                </div></el-col
              >
              <el-col :span="8"
                ><div class="packageList">
                  <div class="packageListContent">
                    <div class="packageListHead">
                      <img src="../../assets/unicom.svg" alt="" />
                    </div>
                    <div class="packageListBody" v-if="unicomList.length > 0">
                      <div
                        class="packageListItem"
                        v-for="(item, index) in unicomList"
                        :key="index"
                        @click="startShopping(item)"
                      >
                        <div class="itemLeft">
                          <!-- <span class="flowPermonth">{{
                            getFlowPermonth(item.mealSize, item.mealCount)
                          }}</span> -->
                           <span class="flowPermonth">{{
                            item.productName
                          }}</span>
                        </div>
                        <div class="itemRight">
                          <div class="lastTime"></div>
                          <div class="pricePackgeDiv">
                            <span class="pricePackge">{{
                              "￥ " + item.fee.toFixed(2)
                            }}</span>
                            <span style="font-size: 12px"> 起</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="packgeNone" v-else>当前运营商没有套餐哦~</div>
                  </div>
                </div></el-col
              >
              <el-col :span="8"
                ><div class="packageList">
                  <div class="packageListContent">
                    <div class="packageListHead">
                      <img src="../../assets/chinanet.svg" alt="" />
                    </div>
                    <div class="packageListBody" v-if="cuccList.length > 0">
                      <div
                        class="packageListItem"
                        v-for="(item, index) in cuccList"
                        :key="index"
                        @click="startShopping(item)"
                      >
                        <div class="itemLeft">
                          <!-- <span class="flowPermonth">{{
                            getFlowPermonth(item.mealSize, item.mealCount)
                          }}</span> -->
                           <span class="flowPermonth">{{
                            item.productName
                          }}</span>
                        </div>
                        <div class="itemRight">
                          <div class="lastTime"></div>
                          <div class="pricePackgeDiv">
                            <span class="pricePackge">{{
                              "￥ " + item.fee.toFixed(2)
                            }}</span>
                            <span style="font-size: 12px"> 起 </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="packgeNone" v-else>当前运营商没有套餐哦~</div>
                  </div>
                </div></el-col
              >
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    </div>
   
  </div>
</template>

<script type="text/ecmascript-6">
import { googList } from "@/api/onlinePurchase.js";
import { messageBox, confirmBox } from "@/utils/common.js";
import store from "@/store";
import Utils from "@/utils/utils.js";
import resize from "@/mixins/resize.js";

export default {
  name: "buyOfficialCard",
  mixins: [resize],
  data() {
    return {
      cardTypeList: [
        { id: "1", name: "普通物联网卡" },
        // { id: "2", name: "NBIOT卡" },
      ],
      defaultNum: "",
      searchItem: "",
      formSearch: {
        cardType: "1",
        mealType: "",
        mealSizeBegin: "0",
        mealSizeEnd: "",
        accountType: "",
      },
      packageRange: [0, 100],
      isExpond: true,
      packageRangeEnd: "无限",
      packageRangeStart: "0M",
      cmccList: [
      ],
      cuccList: [],
      unicomList: [],
      showRZ:true,
      renZhenStatus:'',
    };
  },
  created() {
    if(store.getters.currentType== 2){
    if (store.getters.authStatus == 0) {
      //未认证
      this.renZhenStatus = "待认证";
      this.showRZ = true
    } else if (store.getters.authStatus == 1) {
      //审核通过
      this.renZhenStatus = "审核通过";
      this.showRZ = false
    } else if (store.getters.authStatus == 2) {
      //审核失败
      this.renZhenStatus = "审核失败"; 
      this.showRZ = true
    }
    }else{
       this.renZhenStatus = "审核通过";
      this.showRZ = false
    }
    
    this.getOperateList(); //获取运营商套餐列表
     try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
  },

  methods: {
    toRenZhen(){
				this.$router.push({path:'/enterprise/AccountSettings',query:{id:"quality"}})
			},
    getFlowPermonth(mealSize, mealCount) {
      let str = "";
      if (parseInt(mealSize) < 1024) {
        mealSize = mealSize + "M";
      } else {
        mealSize = parseInt(mealSize) / 1024 + "G";
      }
      if (parseInt(mealCount) < 12) {
        if (parseInt(mealCount) == 1) {
          mealCount = mealCount + "月";
        } else {
          mealCount = mealCount + "个月";
        }
      } else if (parseInt(mealCount) == 12) {
        mealCount = parseInt(mealCount) / 12 + "年";
      }
      str = mealSize + "/" + mealCount;
      return str;
    },
    handleClick(tab, event) {
      // console.log(tab, event);
      this.getOperateList();
    },
    startShopping(item) {
      console.log(item)
      //跳转到开始购物页面
      // this.$router.push({path:'/shopping/startShopping',query:{goodItem:item,flowPermonth:this.getFlowPermonth(item.mealSize, item.mealCount)}})
   item = encodeURIComponent(Utils.encodeSearchKey(JSON.stringify(item))  )
  this.$router.push({path:'/shopping/startShopping',query:{goodItem:item}})
   },

    resetSearch() {
      this.packageRange = [0, 100];
      this.formSearch.mealType = "";
      // this.formSearch.network = [];
      this.formSearch.mealSizeEnd = "";
      this.formSearch.mealSizeBegin = "0";
      this.packageRangeEnd = "无限";
      this.packageRangeStart = "0M";
      // this.getOperateList();
    },
    getOperateList() {
      let params = {
        cardType: this.formSearch.cardType,
      };
      if (this.formSearch.mealType) {
        params.mealType = this.formSearch.mealType;
      }
       if (this.formSearch.mealSizeBegin) {
        params.mealSizeBegin = this.formSearch.mealSizeBegin;
      }
       if (this.formSearch.mealSizeEnd) {
        params.mealSizeEnd = this.formSearch.mealSizeEnd;
      }
      this.cuccList = [];
      this.cmccList = [];
      this.unicomList = [];

      googList(params).then((res) => {
        console.log(res);
        if (res.dx) {
          this.cuccList = res.dx;
        }
        if (res.yd) {
          this.cmccList = res.yd;
        }
        if (res.lt) {
          this.unicomList = res.lt;
        }

        console.log(this.cuccList, this.cmccList, this.unicomList);
      });
    },
    getSearchItem() {
      let str = "";

      if (this.formSearch.mealType == "1") {
        str += "按月";
      } else if (this.formSearch.mealType == "2") {
        str += "累计";
      }

      if (str == "") {
        str += this.packageRangeStart + "～" + this.packageRangeEnd;
      } else {
        str += ";" + this.packageRangeStart + "～" + this.packageRangeEnd;
      }

      return str;
    },
    formatTooltip(val) {
      if (val == 0) {
        return val + "M";
      } else if (val == 10) {
        console.log(this.packageRange);
        return 50 + "M";
      } else if (val == 20) {
        return 100 + "M";
      } else if (val == 30) {
        return 300 + "M";
      } else if (val == 40) {
        return 500 + "M";
      } else if (val == 50) {
        return 1 + "G";
      } else if (val == 60) {
        return 5 + "G";
      } else if (val == 70) {
        return 10 + "G";
      } else if (val == 80) {
        return 20 + "G";
      } else if (val == 90) {
        return 200 + "G";
      } else if (val == 100) {
        return "无限";
      }
    },
    formatUnit(val) {
      if (val == 0) {
        return val;
      } else if (val == 10) {
        // console.log(this.packageRange);
        return 50;
      } else if (val == 20) {
        return 100;
      } else if (val == 30) {
        return 300;
      } else if (val == 40) {
        return 500;
      } else if (val == 50) {
        return 1024;
      } else if (val == 60) {
        return 5 * 1024;
      } else if (val == 70) {
        return 10240;
      } else if (val == 80) {
        return 20 * 1024;
      } else if (val == 90) {
        return 200 * 1024;
      } else if (val == 100) {
        return "";
      }
    },

    goToShopping() {
      //跳转到购物车界面
       this.$router.push({path:'/purchaseCard/shoppingCart'})
    },
    handlePackageTypeChange(mealType) {
      //套餐类型改变后搜索显示列表
      console.log(mealType)
      this.getOperateList();
    },
   
    handlePackageRangeChange(val) {
      //套餐范围变化后搜索显示列表
      console.log(val);

      this.packageRangeStart = this.formatTooltip(val[0]);
      this.packageRangeEnd = this.formatTooltip(val[1]);
      this.formSearch.mealSizeBegin = this.formatUnit(val[0]);
      this.formSearch.mealSizeEnd = this.formatUnit(val[1]);
      this.getOperateList();
    },
    // isshowLastTime(index,cmccList){
    //  if(index==0){
    //     return "最近订购时间:"+ "2022-09-09"
    //  }
    // }
  },
};
</script>
<style scoped>
.cursor{cursor: pointer;}
.container-content {
  background: rgb(246, 246, 246);
  padding: 20px 30px !important;
}
.el-col {
  height: 440px;
}
.packageList {
  padding-left: 7.5px;
  padding-right: 7.5px;
  height: 100%;
}
.headButton {
  position: absolute;
  right: 30px;
  z-index: 2;
}
.block {
  width: 400px;
  display: flex;
}
.slider_span {
  width: 50px;
  text-align: left;
  flex: 1;
}
.el-slider {
  width: 300px;
}
.packageListContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: auto;
  background-color: #fff;
}
.packageListHead {
  height: 15px;
  padding: 10px;
}
.packageListBody {
  height: calc(100% - 40px);
  overflow: auto;
  min-height: auto;
}
.packageListBody .packageListItem {
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 80px;
  cursor: pointer;
  vertical-align: top;
  padding: 10px;
  min-width: 238px;
}
.flowPermonth {
  font-size: 18px;
}
.pricePackge {
  color: red;
  font-weight: 700;
  font-size: 16px;
}
.packgeNone {
  height: 600px;
  line-height: 600px;
  text-align: center;
}
.lastTime {
  font-size: 12px;
  color: #ccc;
  height: 16px;
}
.itemLeft {
  float: left;
  margin-top: 24px;
}
.itemRight {
  float: right;
  text-align: right;
}
.pricePackgeDiv {
  line-height: 40px;
  float: right;
}
.noRenZhen{line-height: 60px; color:#999; text-align: center;}
.text.el-button--text{color:#ff6b01}
.block .el-slider__bar{color:#ff6b01}
</style>
