/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'
export function rechargeAmountStatics(params) {
    return request({
        url: "/virtual/statics/rechargeAmountStatics.do?",
        method: 'post',
        data: params
    })
}

export function rechargeMonthStatics(params) {
    return request({
        url: "/virtual/statics/rechargeMonthStatics.do?",
        method: 'post',
        data: params
    })
}

export function rechargeMonthStatics1(params) {
    return request({
        url: "/virtual/statics/rechargeMonthStatics1.do?",
        method: 'post',
        data: params
    })
}

export function rechargeAmountStatics1(params) {
    return request({
        url: "/virtual/statics/rechargeAmountStatics1.do?",
        method: 'post',
        data: params
    })
}

export function cardLoseStatics(params) {
    return request({
        url: "/virtual/statics/cardLoseStatics.do?",
        method: 'post',
        data: params
    })
}

export function searchParamChecksingle(params) {
    return request({
        url: "staticsAgent/searchParamChecksingle.do?",
        method: 'post',
        data: params
    })
}

export function cardLoseExport(params) {
    return request({
        url: "/virtual/statics/cardLoseExport.do?",
        method: 'post',
        data: params
    })
}











