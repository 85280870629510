<template>
  <div class="orderRecord">
    <el-table :data="tableData">
      <el-table-column prop="orderNumber" label="订单号" show-overflow-tooltip> </el-table-column>

      <el-table-column prop="mealName" label="套餐名称"> </el-table-column>

      <el-table-column prop="mealTypeStr" label="套餐类型"> </el-table-column>

      <el-table-column prop="type" label="订单类型">
          <template slot-scope="scope">
              <span v-if="scope.row.type === 1">微信订单</span>
              <span v-else-if="scope.row.type === 2">平台订单</span>
              <span v-else-if="scope.row.type === 3">系统订单</span>
          </template>
      </el-table-column>

      <el-table-column prop="flowSize" label="套餐流量"> </el-table-column>

      <el-table-column prop="effectType" label="次月生效">
          <template slot-scope="scope">
            <span v-if="scope.row.mealType==2&&(scope.row.mealType==10||scope.row.mealType==12)">是</span>
            <span v-else>否</span>
          </template>
      </el-table-column>

      <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
              <el-input v-model="scope.row.remark" @change="(value)=>{addRemark(value,scope.row)}"></el-input>
          </template>
      </el-table-column>

      <el-table-column prop="createTime" label="订购时间" show-overflow-tooltip> </el-table-column>
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[5, 10, 15, 50, 100, 1000]"
      :page-size="pageSize"
      :total="total"
      layout="total, sizes, prev, next, jumper"
    >
    </el-pagination>
    </el-table>

    
  </div>
</template>

<script type="text/ecmascript-6">
import { orderRecord,add4remark } from "@/api/cardManagement/cardBillDetail.js";

export default {
  data() {
    return {
        pageNo:1,
        pageSize:15,
        total:0,
        tableData:[],
        iccidMark:''
    }
  },
  mounted(){
     this._orderRecord()
  },
  methods: {
      addRemark(val,data){
        console.log(data)
        let params = {}
        params.type = data.type
        params.id = data.id
        params.cardId = this.iccidMark
        params.remark = val
        params.payStatus = 1
        add4remark(params).then((res)=>{
          this._orderRecord()
        })
      },
      handleSizeChange(val){
          this.pageSize = val
      },
      handleCurrentChange(val){
          this.pageNo = val
      },
      _orderRecord(){
          let params = this.$route.query
          params.cardId = params.iccidMark
          this.iccidMark = params.iccidMark
          orderRecord(params).then((res) => {
              this.pageNo = res.pageNo
              this.pageSize = res.pageSize
              this.total = res.total
              this.tableData = res.rows
          })
      }
      
  }
};
</script>

<style>
</style>
