<template>
    <div class="update-operation fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">变更操作列表</span>
            <el-form inline ref="updateOperateForm" v-model="updateFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="updateFormData.iccid" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="updateFormData.shortIccid"  placeholder="短号"></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select filterable v-model="updateFormData.status">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待发送" value="1"></el-option>
                        <el-option label="发送中" value="2"></el-option>
                        <el-option label="失败" value="3"></el-option>
                    </el-select>
                </el-form-item>
                 
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>  
                <el-form-item>
					<el-button type="primary" @click="exportExcel" class="themed-button" icon="el-icon-caret-left">导出excel</el-button>
				</el-form-item>            
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="id"
                    label="ID"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="iccid"
                    label="ICCID号"
                    min-width="150"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="shortIccid"
                    label="短号"
                    min-width="130"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="type"
                    label="类型"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="data"
                    label="数据"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="状态"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if='scope.row.status==1'>待发送</span>
                        <span v-else-if='scope.row.status==2'>发送中</span>
                        <span v-else-if='scope.row.status==3'>失败</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="tryCount"
                    label="尝试次数"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="updateTime"
                    label="更新时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="remark"
                    label="备注"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleUpdate(scope.row.id)">编辑</el-button>
                        <el-button size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
                  
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
        <el-dialog title="编辑" :visible.sync="dialogUpdate">
        <el-form label-position="right" label-width="200px" :model="updateForm">
            <el-form-item label="ID:">
                <el-input v-model="updateForm.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="运营商:">
                <el-input v-model="updateForm.accountId" disabled></el-input>
            </el-form-item>
            <el-form-item label="ICCID:">
                <el-input v-model="updateForm.iccid" disabled></el-input>
            </el-form-item>
            <el-form-item label="类型:">
                <el-input v-model="updateForm.type" disabled></el-input>
            </el-form-item>
            <el-form-item label="数据:">
                <el-input v-model="updateForm.data" disabled></el-input>
            </el-form-item>
            <el-form-item label="状态:">
                <el-select v-model="updateForm.status">
                    <el-option label="待发送" value="1"></el-option>
                    <el-option label="发送中" value="2"></el-option>
                    <el-option label="失败" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="尝试次数:">
                <el-input v-model="updateForm.tryCount" disabled></el-input>
            </el-form-item>
            <el-form-item label="更新时间:">
                <el-input v-model="updateForm.updateTime" disabled></el-input>
            </el-form-item>
            <el-form-item label="备注:">
                <el-input v-model="updateForm.remark" disabled></el-input>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="sureUpdate">确 定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogUpdate = false">取 消</el-button>
        </div>
    </el-dialog>
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { updateOperationList , findUpdateOperation, editUpdateOperation, deleteUpdateOperation,exportUpdateOperation} from "@/api/history.js";
export default {
     mixins:[resize],
    data(){
        return {
            updateFormData:{
                iccid:'',
                status:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogUpdate:false,
            updateForm:{
                id:'',
                accountId:'',
                iccid:'',
                type:'',
                data:'',
                status:'',
                tryCount:'',
                updateTime:'',
                remark:'',
            }

        }
    },
    created(){
        this.getUpdateOperationList()
    },
    methods: {
        exportExcel(){
            exportUpdateOperation(this.updateFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        getUpdateOperationList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.updateFormData,params)
            updateOperationList(this.updateFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        handleUpdate(id){
            this.dialogUpdate = true
            findUpdateOperation({id}).then(res=>{
                this.updateForm.id = res.id
                this.updateForm.accountId = res.accountId
                this.updateForm.iccid = res.iccid
                this.updateForm.type = res.type
                this.updateForm.data = res.data
                this.updateForm.status = res.status
                this.updateForm.tryCount = res.tryCount
                this.updateForm.updateTime = res.updateTime
                this.updateForm.remark = res.remark
            })
        },
        sureUpdate(){
            let params={
                id:this.updateForm.id,
                status:this.updateForm.status
            }
            editUpdateOperation(params).then(res=>{
                if (res.system_result_key == 0) {
                       this.$message({
							showClose: true,
							message: '编辑成功',
							type: 'success',
						})
						this.getUpdateOperationList()
                        this.dialogUpdate=false
                } 
            })
        },

        handleDelete(id){
            this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    deleteUpdateOperation({id}).then(res=>{
                        this.$message({
								type: 'success',
								message:'删除成功'
                        });
                        this.getUpdateOperationList()
                    }) 
            })
        },


        onPageChange(page){
			this.page = page
			this.getUpdateOperationList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getUpdateOperationList()
        },

        onSearch(){
            this.page=1
            this.getUpdateOperationList()
        },

    },
}
</script>

<style>
    .update-operation .el-form-item__label{
        vertical-align: top;
    }
</style>