<template>

<div class="agentSetMeal table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">代理商套餐列表</span>
        <el-form ref="form" :model="form" inline class="hook right themed-form search-form">
            <el-form-item label="分销商:">
                <search-select :optionsMetaAll="agentList"  v-model="form.agentId" @input="_getAgentMealList">
                </search-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="reloadPageData">搜索查询</el-button>
            </el-form-item>    
           <el-form-item>
				<el-button type="primary" @click="exportExcel" class="themed-button" icon="el-icon-caret-left">导出</el-button>
		  </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="代理商" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐名称" prop="mealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="运营商" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="销售价" prop="money" show-overflow-tooltip  v-if="isCaptal!='1'"></el-table-column>
            <el-table-column label="套餐原价" prop="originalMoney" show-overflow-tooltip></el-table-column>
            <el-table-column label="虚量百分比" prop="imaginaryPercent" show-overflow-tooltip></el-table-column>
            <el-table-column label="提成比" prop="rebateRatio" show-overflow-tooltip  v-if="isCaptal!='1'"></el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

</div>

</template>

<script type="text/ecmascript-6">
    import {getAgentList,getAgentMealList,exportAgentMealListExcelSync} from '@/api/setMealManagment/setMealChange.js'
    import { mapGetters } from "vuex";
    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'
    import resize from '@/mixins/resize.js'
	import loading from '@/utils/Loading.js'
    const ERR_OK = 0
    export default {
        name:'agentSetMeal',
        mixins:[resize],
        data(){
            return {
                form:{
                    agentId:'',
                    distributionStatus:'',
                    category:'',
                    name:'',
                },
                agentList:[],
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0
            }
        },
        mounted(){
            this._getAgentList()
            this._getAgentMealList()
        },
        methods:{
            exportExcel(){
							loading.show()
							let params = {
								agentId:this.form.agentId
							}
							exportAgentMealListExcelSync(params).then(res=>{
								if (res.system_result_key == '0') {
									this.$alert('导出成功，请到导出记录中下载', '提示', {
									    confirmButtonText:'确定',
									}).then(()=>{
										this.$router.push('/tob/i_export_list')
									});
								}else{
									this.$message({
									    showClose: true,
									    message: res.system_result_message_key,
									    type: 'error'
									});
								}
							})
						},
            reloadPageData(){
                this._getAgentMealList()
            },
            
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._getAgentMealList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._getAgentMealList()
            },
		
            _getAgentMealList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                params.importType = 2
                Object.assign(params, this.form)
                getAgentMealList(params).then((res)=>{
                    // console.log(res)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            },
            _getAgentList(){
                getAgentList(this.form).then((res) => {
                    this.agentList = res.map((item)=>{
                        return {
                            label:item.name,
                            value:item.id
                        }
                    })
                })
            }
        },
        components:{
            SearchSelect
        },
         computed: {
    ...mapGetters(["isCaptal"]),
  },
    }
</script>
