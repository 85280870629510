<template>
    <div class="background-order fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">后台订单</span>
            <el-form inline ref="backOrderForm" v-model="backOrderFormData" class="right themed-form search-form" style="line-height: 40px;">
                <el-form-item>
                    <el-input v-model="backOrderFormData.orderNumber" placeholder="订单号"></el-input>
                </el-form-item>
                 <el-form-item label="所属代理商:">
                <el-select v-model="backOrderFormData.agentTreeIdNew"    filterable clearable >
					<el-option v-for="item in agentThreeLists" :key="item.index" :label="item.agentName" :value="item.agentId">
					</el-option>
				</el-select>
                </el-form-item>
                <!-- <el-form-item>
                    <el-input v-model="backOrderFormData.agentThreeName" placeholder="所属代理商"></el-input>
                </el-form-item> -->

                <el-form-item label="运营商">
                    <search-select :optionsMetaAll="agentOperators" v-model="backOrderFormData.accountId"></search-select>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="backOrderFormData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <el-input v-model="backOrderFormData.mealName" placeholder="套餐"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="backOrderFormData.pageSearchKeys" placeholder="卡号" class="iccid-width"></el-input>
                </el-form-item>
                  <el-form-item>
                    <el-input v-model="backOrderFormData.shortIccid" placeholder="短号" class="iccid-width"></el-input>
                </el-form-item>

                 <el-form-item>
                    <el-input v-model="backOrderFormData.createUser" placeholder="操作人"></el-input>
                </el-form-item>

                <el-form-item label="支付状态">
                    <search-select :optionsMetaAll="optionsPayStatus" v-model="backOrderFormData.status"></search-select>
                </el-form-item>

                <el-form-item label="充值类型">
                    <search-select :optionsMetaAll="optionsChargeStatus" v-model="backOrderFormData.isFirstRecharge"></search-select>
                </el-form-item>

                
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="订购起始日期" v-model="backOrderFormData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="订购结束日期" v-model="backOrderFormData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>

                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="退款起始日期" v-model="backOrderFormData.refundStartTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="退款结束日期" v-model="backOrderFormData.refundEndTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="orderNumber"
                    label="订单号"
                    min-width="200">
                </el-table-column>
                <el-table-column
                 :show-overflow-tooltip="true"
                    prop="agentThreeName"
                    label="所属代理商"
                    min-width="150">
                </el-table-column>
                <el-table-column
                 :show-overflow-tooltip="true"
                    prop="rootAgentName"
                    label="所属总代理商"
                    min-width="150">
                </el-table-column>
                <el-table-column
                 :show-overflow-tooltip="true"
                    prop="orderMoney"
                    label="订单金额"
                    min-width="120">
                </el-table-column>
                <el-table-column
                 :show-overflow-tooltip="true"
                    prop="realMoney"
                    label="最总支付金额"
                    min-width="150">
                </el-table-column>
                <el-table-column
                    prop="iccidMark"
                    label="iccid"
                    min-width="180">
                </el-table-column>
                  <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="130"/>
                <el-table-column
                    prop="phone"
                    label="接入号码"
                    min-width="130">
                </el-table-column>
                 <el-table-column
                 :show-overflow-tooltip="true"
                    prop="mealName"
                    label="套餐名称"
                    min-width="160">
                </el-table-column>
                 <el-table-column
                 :show-overflow-tooltip="true"
                    label="次月生效"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.effectType==1">本月生效</span>
                        <span v-else-if="scope.row.effectType==2">次月生效</span>
                    </template>
                </el-table-column>
                 <el-table-column
                :show-overflow-tooltip="true"
                    prop="mealTypeStr"
                    label="套餐类型"
                    min-width="150">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="mealSecondTypeStr"
                    label="二级套餐类型"
                    min-width="150">
                </el-table-column>
                 <el-table-column label="无佣金标识" prop="noProfitFlag">
                <template slot-scope="scope">
                   <span v-if="scope.row.noProfitFlag==1" style="color:#f00">无佣金</span>
                   <span v-else-if="scope.row.noProfitFlag==0" style="color:#f00"></span>
                   <span v-else style="color:#f00"></span>
                </template>
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="accountName"
                    label="开卡公司"
                    min-width="150">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="orderTime"
                    label="下单时间"
                    min-width="150">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="orderCount"
                    label="数量"
                    min-width="100">
                </el-table-column>

                <el-table-column
                    label="销售类型"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.saleType==1">线上</span>
                        <span v-else-if="scope.row.saleType==2">线下</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="合作类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.coType==1">普通类型</span>
                        <span v-else-if="scope.row.coType==2">按量结算</span>
                        <span v-else-if="scope.row.coType==3">api充值</span> -->
                        <span>{{_getCoType(scope.row.coType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="产品类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.productType==1">大河卡</span>
                        <span v-else-if="scope.row.productType==2">手表卡</span>
                        <span v-else-if="scope.row.productType==3">大流量卡</span> -->
                        <span>{{_getProductType(scope.row.productType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="业务类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.toType=='C'">ToC</span>
                        <span v-else-if="scope.row.toType=='B'">ToB</span>
                        <span v-else-if="scope.row.toType=='API'">API</span> -->
                        <span>{{_getToType(scope.row.toType)}}</span>
                    </template>
                </el-table-column>
                 <el-table-column
                    :show-overflow-tooltip="true"
                    prop="orderExpireDate"
                    label="套餐到期时间"
                    min-width="120">
                </el-table-column>

                <el-table-column
                    label="充值类型"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isFirstRecharge==1">首次充值</span>
                        <span v-else-if="scope.row.isFirstRecharge==2">续费充值</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUser"
                    label="操作人"
                    min-width="98">
                </el-table-column>

                <el-table-column
                    label="支付状态"
                    min-width="98">
                     <template slot-scope="scope">
                        <span v-if="scope.row.status==1">已支付</span>
                        <span v-else-if="scope.row.status==2">已退款</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="退款操作人"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.refundUser !=''">{{scope.row.refundUser}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="退款时间"
                    min-width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.refundDatetime !=''">{{scope.row.refundDatetime}}</span>
                        <span v-else-if="scope.row.status==''">-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="saleName"
                    label="销售"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="78">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1"><el-button class="themed-button" type="primary" @click="handleRefund(scope.row.id)" >退款</el-button></span>
                        <span v-else-if="scope.row.status==2">-</span>
                    </template>
                </el-table-column>
                
            </el-table>

             <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog
            title="退款"
            :visible.sync="dialogRefundVisible"
            :destroy-on-close='true'
            >
           <el-form :model="refundForm"  ref="refundForm">
                <el-form-item label="退款理由" prop="refundReason">
                <el-input type="textarea" v-model="refundForm.refundReason"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="dialogRefundVisible = false">取 消</el-button>
                <el-button class="themed-button" type="primary" @click="sureRefund"  v-preventReClick='1000' :disabled="isDisable">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {agentOperatorList,backgroundOrderList,refundOrder,exportBackgroundOrder} from '@/api/order.js'
import resize from '@/mixins/resize.js'
import dictGroup from '@/mixins/dictGroup.js'
import {agentListForUser } from '@/api/cardManagement/cardBill.js'

export default {
    name:'backgroundOrder',
  components: { SearchSelect },
  mixins:[resize,dictGroup],
    data(){
        return{
            optionsPayStatus:[
                {label:'全部',value:''},
                {label:'已支付',value:'1'},
                {label:'已退款',value:'2'}
            ],
            optionsChargeStatus:[
                {label:'全部',value:''},
                {label:'首次充值',value:'1'},
                {label:'续费充值',value:'2'}
            ],
            agentOperators:[{label:"全部",value:""}],
            agentThreeLists:[],
            backOrderFormData:{
                orderNumber:'',
                agentThreeName:'',
                agentTreeIdNew:"",
                accountId:'',
                phone:'',
                mealName:'',
                pageSearchKeys:'',
                createUser:'',
                status:'',
                isFirstRecharge:'',
                startTime:'',
                endTime:'',
                refundStartTime:'',
                refundEndTime:'',
                shortIccid:"",
            },
            tableData:[],
            multipleSelection:[],
            page:1,
            pageSize:15,
            total:0,
            refundOrderID:'',
            dialogRefundVisible:false,
            refundForm:{
                refundReason:''
            },
            isDisable:false,
        }
        
    },
    created(){
        this.getAgentListForUser()
        this.getBackOrderList()
        this.getAgentOperatorList()
         try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
    },
    methods:{
        getAgentListForUser(){
             agentListForUser().then(res=>{
                console.log(391)
                this.agentThreeLists = res
             })
            },
            getAgentOperatorList(){
             agentOperatorList().then(res=>{
                 let arrList =[{label:"全部",value:""}]
                 res.forEach(element => {
                     arrList.push({label:element.accountName,value:element.id})
                 })               
                 this.agentOperators=arrList
             })
         },

            getBackOrderList(){
                let params = {
                    pageSize:this.pageSize,
                    pageNo:this.page,
                }
                Object.assign(this.backOrderFormData,params)
                backgroundOrderList(this.backOrderFormData).then(res=>{
                    this.tableData = res.rows
                    this.total = res.count
                })

            },
           onSearch(){
               this.page = 1
               this.getBackOrderList()
           },
           exportExcel(){
               exportBackgroundOrder(this.backOrderFormData).then(res=>{
                   console.log(res,232)
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
           },
           onPageChange(page){
				this.page = page
				this.getBackOrderList()
			},
		    handleSizeChange(size){
				this.pageSize = size
				this.getBackOrderList()
            },
            handleRefund(id){
                this.refundForm.refundReason = ''
                this.refundOrderID = id
                this.dialogRefundVisible = true
            },
            sureRefund(){
                if(this.refundForm.refundReason == ''){
                    this.$alert('填写退款理由', '提示', {
								confirmButtonText: '确定',
					});
					return
                }
                this.isDisable = true
                let params ={
                    id:this.refundOrderID,
                    refundReason:this.refundForm.refundReason
                }
                refundOrder(params).then(res=>{
                    this.isDisable = false
                    if(res.code == '0'){
                        this.$message({
								type: 'success',
								message:'退款成功'
                        });
                    }
                    this.getBackOrderList()
                    this.dialogRefundVisible = false
                }).catch(()=>{
                    this.isDisable = false
                })
            }

        }
}
</script>

<style>
    .background-order .el-form-item__label{
        vertical-align: top;
    }
</style>