<template>
  <!--天流量 -->
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
        <el-col :span="20" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
              <el-form-item label="" :label-width="formLabelWidth" prop="iccid">
                <el-input
                  placeholder="请输入ICCID"
                  v-model="queryParams.iccid"
                ></el-input>
              </el-form-item>
              <el-form-item
                label=""
                :label-width="formLabelWidth"
                prop="agentId"
              >
                <treeselect
                  v-model="queryParams.agentId"
                  :options="agentOptions"
                  :normalizer="normalizer"
                  :show-count="true"
                  placeholder="请选择代理商"
                />
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth" prop="time">
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  :picker-options="pickerOptions"
                  @change="dateFormat"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
        <el-col :span="4" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item>
                <el-button
                  icon="el-icon-download"
                  type="primary"
                  class="themed-button"
                  @click="exportDayFlow('天流量.xls')"
                  >导出</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        :max-height="maxTableHeight"
        tooltip-effect="dark"
        style="width: 100%"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
       <el-table-column prop="gyAgent.agentName" label="代理商" min-width="150">
        </el-table-column>
        <el-table-column prop="iccid" label="ICCID" min-width="200">
        </el-table-column>
        <el-table-column prop="usedFlow" label="用量" min-width="180">
        <template slot-scope="scope">
        <span>{{ scope.row.usedFlow}}</span>
        <span v-if="scope.row.usedFlow!=null">MB</span>
       </template>
        </el-table-column>
        <el-table-column prop="flowDate" label="日期" min-width="180">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="180">
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
     <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { agentGetAllAgent } from "@/api/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {
dayFlowList,
delDayFlow,
dayFlowExport,
} from "@/api/statistical/statistical.js";
export default {
  name: "materialsList",
  mixins:[resize],
  components: { Treeselect },
  data() {
    return {
      formInline: {
        spec: "",
        material: "",
      },
      time:[],
      addForm: {
        radioLable: 0,
        imeiArray: [],
        agentId: undefined,
      },
       pickerOptions: {
        disabledDate: (time) => {
        const date = new Date()
        return time.getTime() > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59).getTime()
        },
        //  disabledDate(time) {
        //     return time.getTime() > Date.now()- 8.64e6;
        //   },
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      tableData: [],
      agentOptions: [],
      queryParams: {
        iccid:"",
        startTime: '',
        endTime: '',
        agentId: undefined,
        agentName: undefined,
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
    };
  },
  created() {
    this.getDeviceList();
    this.getAllAgent();
  },
  methods: {
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams, params);
      dayFlowList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },
     dateFormatOne (row, column) {
    // 转换后台返回时间格式
      const jsonDate = new Date(row.flowDate).toJSON()
      return new Date(new Date(jsonDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
    },
    /** 代理商数据 */
    getAllAgent() {
      agentGetAllAgent().then((response) => {
        this.agentOptions = response;
      });
    },
    /** 转换代理商数据结构 */
    normalizer(node) {
      if (
        node.children == null ||
        (node.children == "null" && node.children && !node.children.length)
      ) {
        delete node.children;
      }
      return {
        id: node.agentId,
        label: node.agentName,
        children: node.children,
      };
    },
     dateFormat(date){
      this.time = date
      if (this.time==null) {
       this.queryParams.startTime=""
       this.queryParams.endTime=""
      }else{
      this.queryParams.startTime=this.time[0]
      this.queryParams.endTime=this.time[1]
      }
      },
    /** 搜索 */
    searchData() {
      this.page = 1;
      this.getDeviceList();
    },
    treeSelectChange(raw, instanceId) {
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        this.addForm.agentId = raw.agentId;
        this.addForm.agentName = raw.agentName;
      });
    },
    /** 导出模板 */
    exportDayFlow(name) {
      dayFlowExport({ name: name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
    /** 删除 */
    delect(id) {
      this.$confirm("是否确认删除该项数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delDayFlow(id).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功，列表不再显示对应记录",
          });
          this.getDeviceList();
        });
      });
    },
     /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 10px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>