/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from '../request.js'

//imeiLibrary.vue
export function imeiBankCode(){
    return request({
        url: 'bindImei/listImeiBankCode.do?',
        method: 'post'
    })
}

export function bindImeiList(params){
    return request({
        url: 'bindImei/list.do?',
        method: 'post',
        data:params
    })
}
export function exportSync(params){
    return request({
        url: 'bindImei/exportSync.do?',
        method: 'post',
        data:params
    })
}

//imeiLibrary.vue -->  add.vue
export function addSync(params){
    return request({
        url: 'bindImei/addSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function getList4agent(){
    return request({
        url: 'agent/list4agent.do?',
        method: 'post'
    })
}
export function findById(params){
    return request({
        url: 'bindImei/findById.do?',
        method: 'post',
        data:params
    })
}
export function getNewImeiBankCode(params){
    return request({
        url: 'bindImei/getNewImeiBankCode.do?',
        method: 'post',
        data:params
    })
}
export function bindImeiDetele(params){
    return request({
        url: 'bindImei/deleteById.do?',
        method: 'post',
        data:params
    })
}

//imeiLibrary.vue -->  addimei.vue
export function importAddSync(params){
    return request({
        url: 'bindImei/importAddSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

//imeiLibrary.vue -->  deleteimei.vue
export function importDeleteSync(params){
    return request({
        url: 'bindImei/importDeleteSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
//imeiLibrary.vue -->  update.vue
export function importUpdate(params){
    return request({
        url: 'bindImei/importUpdate.do?',
        method: 'post',
        data:params
    })
}

//imeiList.vue
export function listImei(params){
    return request({
        url: 'bindImei/listImei.do?',
        method: 'post',
        data:params
    })
}
export function exportImeiSync(params){
    return request({
        url: 'bindImei/exportImeiSync.do?',
        method: 'post',
        data:params
    })
}
export function findByIdImei(params){
    return request({
        url: 'bindImei/findByIdImei.do?',
        method: 'post',
        data:params
    })
}
export function deleteByIdImei(params){
    return request({
        url: 'bindImei/deleteByIdImei.do?',
        method: 'post',
        data:params
    })
}
//imeiList.vue -->  update.vue
export function importUpdateImei(params){
    return request({
        url: 'bindImei/importUpdateImei.do?',
        method: 'post',
        data:params
    })
}
//bindCard.vue
export function listCardBankCode(params){
    return request({
        url: 'bindCard/listCardBankCode.do?',
        method: 'post',
        data:params
    })
}
export function bindCardList(params){
    return request({
        url: 'bindCard/list.do?',
        method: 'post',
        data:params
    })
}
export function bindCardExportSync(params){
    return request({
        url: 'bindCard/exportSync.do?',
        method: 'post',
        data:params
    })
}
export function bindCardDelete(params){
    return request({
        url: 'bindCard/deleteById.do?',
        method: 'post',
        data:params
    })
}

//bindCard.vue -- add.vue
export function getAccountList(){
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post'
    })
}
export function bindCardFindById(params){
    return request({
        url: 'bindCard/findById.do?',
        method: 'post',
        data:params
    })
}
export function getNewCardBankCode(params){
    return request({
        url: 'bindCard/getNewCardBankCode.do?',
        method: 'post',
        data:params
    })
}
export function bindCardAddSync(params){
    return request({
        url: 'bindCard/addSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

//bindCard.vue -- addCard.vue
export function bindCardImportAddSync(params){
    return request({
        url: 'bindCard/importAddSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
//bindCard.vue -- update.vue
export function bindCardImportUpdate(params){
    return request({
        url: 'bindCard/importUpdate.do?',
        method: 'post',
        data:params
    })
}
//bindCard.vue -- delete.vue
export function bindCardImportDeleteSync(params){
    return request({
        url: 'bindCard/importDeleteSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
 //historyRrcord.vue
export function stSeperateLog(params){
    return request({
        url: 'bindImeiCard/listSeperateLog.do?',
        method: 'post',
        data:params
    })
}
export function exportSeperateLogSync(params){
    return request({
        url: 'bindImeiCard/exportSeperateLogSync.do?',
        method: 'post',
        data:params
    })
}

export function deleteJiKaFenLi(params){
    return request({
        url: 'bindImeiCard/deleteSeperateLog.do?',
        method: 'post',
        data:params
    })
}





