<template>

<div class="usage-statistics table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">虚拟卡用量统计</span>
        <el-form inline v-model="form"  class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="虚拟运营商:">
                <el-select v-model="form.accountId" filterable>
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in accountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="月份:">
                <el-date-picker style="width:100%" 
                    v-model="form.createMonth"
                    type="month" value-format="yyyy-MM" 
                    placeholder="选择月份">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-download" class="themed-button" @click="exportExcel">导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="月份" prop="createMonth" show-overflow-tooltip></el-table-column>
            <el-table-column label="运营商" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="总流量(G)" prop="flowSum" show-overflow-tooltip></el-table-column>
            <el-table-column label="电信流量(G)" prop="dxFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="联通流量(G)" prop="ltFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="移动流量(G)" prop="ydFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="更新时间" prop="createTime" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {getAccountList,getAccountFlowList,exportAccountFlowSync} from '@/api/virtualCard/accountRateList.js'
import {messageBox,confirmBox} from '@/utils/common.js'

import resize from '@/mixins/resize.js'

export default {
    name:'usageStatistics',
    mixins:[resize],
    data(){
        return {
            form:{
                accountId:'',
                createMonth:''
            },
            accountList:[],
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,

        }
    },
    mounted(){
        this._getAccountList()
        this._getAccountFlowList()
    },
    methods:{
        searchTable(){
            this._getAccountFlowList()
        },
        exportExcel(){
            exportAccountFlowSync(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._getAccountFlowList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._getAccountFlowList()
        },
        productTypeName(productType){
            let result = this.productTypeList.find((item)=>{
                return item.value == productType
            })
            if(!result){
                return '-'
            }
            if(result.label){
                return result.label
            }else{
                return '-'
            }
        },
        _getAccountList(){
            getAccountList().then((res)=>{
                this.accountList = res
            })
        },
        _getAccountFlowList(){
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            params.type = this.type
            getAccountFlowList(Object.assign(params,this.form)).then((res) => {
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    }
}
</script>
