<template>
	<div class="fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">网卡管理</span>
			<el-form inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
				<el-form-item>
					<el-select v-model="searchForm.groupId" placeholder="计费组" :disabled="isDisableGroupId">
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item,index) in groupList" :key="index" :label="item.groupName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				 <el-form-item>
          <el-select v-model="searchForm.batchId" placeholder="批次" :disabled="isDisableBatchId">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in newBatchList"
              :key="item.id"
              :label="item.batchName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
				<el-form-item>
					<el-input v-model="searchForm.iccidMark"  placeholder="ICCID号" class="iccid-width"></el-input>
				</el-form-item>
					<el-form-item>
					<el-input v-model="searchForm.phone"  placeholder="接入号" class="iccid-width"></el-input>
				</el-form-item>
					<el-form-item>
					<el-input v-model="searchForm.imsi"  placeholder="IMSI" class="iccid-width"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
				<el-form-item>
                	<el-button type="primary" icon="el-icon-plus" class="themed-button" @click="importRecord">导入网卡</el-button>
            	</el-form-item>
				<el-form-item>
                	<el-button type="primary" icon="el-icon-plus" class="themed-button" @click="cardBatchRechage">批量充值</el-button>
            	</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-delete" type="danger" class="themed-button" @click="deleteByExcel">批量删除</el-button>
				</el-form-item>
				<!-- <el-form-item>
					<el-button icon="el-icon-refresh-left" class="themed-button" type="info" @click="reset">重置</el-button>
				</el-form-item> -->
			</el-form>
		</div>
		<div class="content-box">
			<el-table ref='table'  :data="tableData" stripe style="width: 100%;" :max-height="maxTableHeight">
				<el-table-column prop="iccidMark" label="ICCID号">
				</el-table-column>
				<el-table-column prop="phone" label="MSISDN">
				</el-table-column>
				<el-table-column prop="imsi" label="IMSI">
				</el-table-column>
				<el-table-column prop="groupName" label="计费组字段">
				</el-table-column>
				<el-table-column prop="batchName" label="批次">
				</el-table-column>
				<el-table-column prop="status" label="卡状态">
					<template slot-scope="scope">
						<span v-if="scope.row.cuankaFlag == 1">窜卡</span>
						<span v-else-if="scope.row.status == 1">待激活</span>
						<span v-else-if="scope.row.status == 2">正常</span>
						<span v-else-if="scope.row.status == 3">断网</span>
						<span v-else-if="scope.row.status == 4">停机</span>
						<span v-else-if="scope.row.status == 5">异常</span>
					</template>
				</el-table-column>
				<el-table-column
                    label="操作"
                    >
                    <template slot-scope="scope">
						<el-button  size="mini" class="themed-button" type="info" @click="toKawu(scope.row.id,scope.row.iccidMark)">卡务操作</el-button>
                        <el-button  size="mini" class="themed-button" type="warning" @click="handleDelete(scope.row.iccidMark)">删 除</el-button>
                    </template>
                </el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
		</div>
		<el-dialog title="短信详情" :visible.sync="dialogVisible" width="500px">
			<el-form label-width="120px">
				<el-form-item label="批量号" prop="name">
					<el-input v-model="ruleForm.name" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="任务名称" prop="phone">
					<el-input v-model="ruleForm.phone" style="width: 240px;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="primary" @click="dialogVisible = false">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="导入网卡" :visible.sync="dialogImportCard" destroy-on-close>
        <el-form label-position="right" label-width="120px" :model="importCardForm"  ref="importCardForm">
            <el-form-item label="Excel模板">
                <a href="javascript:volid(0);" @click="exportTemplateExcel('importIccid.xls')">下载模板</a>
            </el-form-item>
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='importCardfile'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="代理商"  prop="agentName">
                    <el-select v-model="importCardForm.agentName" placeholder="请选择" filterable @change='agentChanged'>
                        <el-option v-for="item in agentIdList" :key='item.index' :label="item.name" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
			<el-form-item label="计费组"  prop="groupId">
                    <el-select v-model="importCardForm.groupId" placeholder="请选择" filterable>
                        <el-option v-for="item in groupIdList" :key='item.index' :label="item.groupName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="importCardExcel">导入excel模板</el-button>
                <!-- <el-button class="themed-button" type="info" @click="exportTemplateExcel('gift_card_meal.xlsx')">导出excel模板</el-button> -->
                <el-button class="themed-button" type="primary" @click="dialogImportCard = false">关 闭</el-button>
            </div>
    </el-dialog>

		<el-dialog title="批量充值" :visible.sync="dialogBatchRecharge" destroy-on-close>
        	<el-form label-position="right" label-width="120px" :model="batchRechargeForm"  ref="batchRechargeForm">
            <el-form-item label="Excel模板">
                <a href="javascript:volid(0);" @click="exportTemplateExcel('importIccid.xls')">下载模板</a>
            </el-form-item>
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='batchRechargefile'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="计费组"  prop="groupId">
                    <el-select v-model="batchRechargeForm.groupId" placeholder="请选择" filterable @change='groupIdChanged'>
                        <el-option v-for="item in groupList" :key='item.index' :label="item.groupName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
			<el-form-item label="选择套餐"  prop="mealId">
                    <el-select v-model="batchRechargeForm.mealId" placeholder="请选择" filterable>
                        <el-option v-for="item in mealList" :key='item.index' :label="item.name" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
        	</el-form>
			<div style="color:#f00">
            注意：批量充值卡片只能充值同样的套餐，非同一个运营商的卡请不要混和操作，单次操作请不要超过5W张，否则会引起系统卡顿
           </div>
        	<div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="importBatchRechargeExcel">导入excel模板</el-button>
                <el-button class="themed-button" type="primary" @click="dialogBatchRecharge = false">关 闭</el-button>
            </div>
    	</el-dialog>

		<el-dialog title="批量删除" :visible.sync="dialogDeleteByExcel" width="500px" :destroy-on-close='true'>
            <el-form label-position="right" label-width="120px" :model="deleteByExcelForm"  ref="deleteByExcelForm">
            <el-form-item label="Excel模板">
                <a href="javascript:volid(0);" @click="exportTemplateExcel('importIccid.xls')">下载模板</a>
            </el-form-item>
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='batchDeletefile'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
			</el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="uploadDeleteExcel">批量删除</el-button>
                <el-button class="themed-button" type="primary" @click="dialogDeleteByExcel = false">关 闭</el-button>
            </div>
        </el-dialog>
	</div>
</template>

<script>
import { chargeGroupBatchList} from "@/api/partner.js";
	import {cardList,chargeGroupList,importCardByExcel,getMealList,importBatchRechargeByExcel,batchDelete,deleteCard} from '@/api/group.js'
	import {getAgentList} from '@/api/agentManagement.js'
	import resize from '@/mixins/resize.js'
	import {exportTemplate} from '@/api/statistics.js'
	import Utils from '@/utils/utils.js'
	import {mapMutations} from "vuex"
	import {messageBox,confirmBox} from '@/utils/common.js'
	export default{
		mixins:[resize],
		data(){
			return {
				page:1,
				pageSize:15,
				total:0,
				tableData:[],
				groupList:[],
				dialogVisible:false,
				ruleForm:{},
				searchForm:{
					groupId:'',
					iccidMark:'',
					phone:"",
					imsi:"",
					batchId:"",
				},
				groupId:'',
				dialogImportCard:false,
				importCardForm:{
					jihuotype:'2',
					agentName:'',
					groupId:'',
				},
				agentIdList:[],
				groupIdList:[],
				dialogBatchRecharge:false,
				batchRechargeForm:{
					jihuotype:'2',
					groupId:'',
					mealId:'',
				},
				mealList:[],
				dialogDeleteByExcel:false,
				deleteByExcelForm:{},
				batchList:[],
				newBatchList:[],
				isDisableBatchId:false,
				isDisableGroupId:false,
			}
		},
		created() {

			if (!!this.$route.query.groupId) {
				this.getCardListByGroupId(this.$route.query)
			}else{
				this.getCardList()
			}
			this.getBatchList()
			this.getGroupList()
		},
		methods:{
			...mapMutations(
                ["SET_ICCID_OR_PHONE","SET_STATUS","SET_TYPE"]
            ),
					 getBatchList() {
      //获取所有的批次列表
      this.batchList = []; //赋值
       let params = {
        pageSize: 10000,
        pageNo:1,
        };
     chargeGroupBatchList(params).then(res=>{
        console.log(res)
        this.batchList = res.rows
        this.newBatchList = res.rows
     })
    },
			 groupChange(e){ //toc网卡管理也要同步修改
       console.log(e)
       // 有值时候  通过数组筛选 批次列表；没值 赋值批次列表全部
       if(e){
        this.newBatchList = this.batchList.filter(item=>{
          return item.groupId == e
        })
        console.log(this.newBatchList)
       }else{
        this.newBatchList = (this.batchList) 
       }
    },
			toKawu(id,iccid){
			this.SET_ICCID_OR_PHONE(iccid)
            this.SET_STATUS('0')
            this.SET_TYPE('0')
             this.$router.push({
				 path:'../cardManagement/cardBillDetail',
				 query:{'iccidOrPhone':iccid,'status':0,'type':0}
				 })
			},
			handleDelete(iccid){
				this.$confirm(iccid+":是否从计费组中删除？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    deleteCard({iccid}).then(res=>{
                        this.$message({
								type: 'success',
								message:'删除成功'
                            });
                        this.getCardList()
                    })
                    
                })
			},
			// 导入网卡
			getAgentIdList(){
				getAgentList().then(res=>{
					this.agentIdList = res
				})
			},
			agentChanged(val){
				let agentId = this.importCardForm.agentName
				Object.assign(this.importCardForm,{agentId})
				Object.assign(this.importCardForm,{pageSize:1000})
				chargeGroupList(this.importCardForm).then(res=>{
					this.groupIdList = res.rows
				})
			},

			groupIdChanged(val){
				this.batchRechargeForm.mealId = ''
				getMealList(this.batchRechargeForm).then(res=>{
					this.mealList = res.list
					this.batchRechargeForm.mealId = this.mealList[0].id
				})
			},

			importRecord(){
				this.dialogImportCard = true
				this.getAgentIdList()
				this.groupIdList = this.groupList
			},
			importCardExcel(){
				this.$confirm("确定导入吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['importCardfile'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            if(!!this.importCardForm.agentName){
                                param.append("agentName",this.importCardForm.agentName)
                            }else{
                                this.$alert('请选择代理商', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
                            if(!!this.importCardForm.groupId){
                                param.append("groupId",this.importCardForm.groupId)
                            }else{
                                this.$alert('请选择计费组', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
							param.append("fileurl",file)
							param.append("jihuotype",'2')
                            importCardByExcel(param).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
								this.dialogImportCard = false
                                this.$router.push('/tob/i_import_list')
                            	});
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                               
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
                })
			},
			// 批量充值
			cardBatchRechage(){
				this.dialogBatchRecharge = true
			},
			importBatchRechargeExcel(){
				this.$confirm("确定导入吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['batchRechargefile'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                          }else{
                            let param = new FormData()
                            if(!!this.batchRechargeForm.groupId){
                                param.append("groupId",this.batchRechargeForm.groupId)
                            }else{
                                this.$alert('请选择计费组', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
                            if(!!this.batchRechargeForm.mealId){
                                param.append("mealId",this.batchRechargeForm.mealId)
                            }else{
                                this.$alert('请选择套餐：', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
							param.append("fileurl",file)
							param.append("jihuotype",'2')
                            importBatchRechargeByExcel(param).then(res=>{
								 if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
								this.dialogBatchRecharge = false
                                this.$router.push('/tob/i_import_list')
                            	});
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                               
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
                })
			},
			// 批量删除
			deleteByExcel(){
				this.dialogDeleteByExcel = true
			},
			uploadDeleteExcel(){
				this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['batchDeletefile'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
							param.append("fileurl",file)
							param.append("jihuotype",'2')
                            batchDelete(param).then(res=>{
                             if (res.system_result_key == '0') {
                              this.$alert('删除成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
								this.dialogDeleteByExcel = false
                                this.$router.push('/tob/i_import_list')
                            	});
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }

                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
                })
			},
			exportTemplateExcel(name){
            	exportTemplate({'name':name}).then(res=>{
                	const filename = name
					Utils.createDownload(res,filename)
            	})
        	},
			onSearch(){
				this.page = 1
				if(!!this.$route.query.groupId){
					this.getCardListByGroupId(this.$route.query)
				}else{
					this.getCardList()
				}
				// this.getCardList()
			},
			reset(){
				this.searchForm = {
					groupId:'',
					iccidMark:'',
					phone:"",
					imsi:"",
				}
			},
			getGroupList(){
				let params = {
					jihuotype:'2',
					pageSize:1000
				}
				chargeGroupList(params).then(res=>{
					this.groupList = res.rows
				})
			},
			getCardListByGroupId(query){
				this.searchForm.groupId =  parseInt(query.groupId) 
				this.isDisableGroupId = true
				if(this.$route.query.batchId){
                this.searchForm.batchId = query.batchId
				this.isDisableBatchId  = true
				}
				// this.searchForm.groupId = query.groupId
				// this.searchForm.batchId = query.batchId
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
				}
				Object.assign(this.searchForm,params)
				cardList(this.searchForm).then(res=>{
					this.tableData = res.rows
					this.total = res.count
				})
			},
			getCardList(){
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
				}
				Object.assign(this.searchForm,params)
				cardList(this.searchForm).then(res=>{
					this.tableData = res.rows
					this.total = res.count
				})
			},
			onPageChange(page){
				this.page = page
				if(!!this.$route.query.groupId){
					this.getCardListByGroupId(this.$route.query)
				}else{
					this.getCardList()
				}
				
				
			},
			handleSizeChange(size){
				this.pageSize = size
				// this.getCardList()
				if(!!this.$route.query.groupId){
					this.getCardListByGroupId(this.$route.query)
				}else{
					this.getCardList()
				}
			},
		}
	}
</script>

<style>
</style>
