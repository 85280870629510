<template>

<div class="imeiRepo table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">IMEI-ICCID映射库</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="IMEI:">
                <el-input v-model="form.imei" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="openImportWindow">IMEI管理</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="openSettingWindow">配置管理</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="IMEI" prop="imei"></el-table-column>
            <el-table-column label="使用次数" prop="usedTimes" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" class="themed-button"  @click='showList(scope.row.id,scope.row.imei)'>查看列表</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog title="绑定的iccid列表" :visible.sync="dialogImei">
        <el-table ref="tableImei" :data="tableDataImei">
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column label="iccid" prop="iccid"></el-table-column>
            <el-table-column label="绑定时间">
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.bind_time)}}</span>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>

    <el-dialog title="IMEI导入" :visible.sync="dialogImport" v-if="dialogImport">
        <el-form label-position="right" label-width="200px">
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="操作类型:">
                <el-select v-model="type">
                    <el-option label="添加" value="1"></el-option>
                    <el-option label="删除" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <a href="javascript:void(0);" @click="exportTemplateExcel('importImei2.xls')">下载模板</a>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">导入excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogImport = false">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog title="配置" :visible.sync="dialogSetting" v-if="dialogSetting">
        <el-form label-position="right" label-width="200px" :model="formSet">
            <el-form-item label="生效的代理商">
                <div style="border:1px solid #e7e7e7">
                    <h1 style="border-bottom:1px solid #e7e7e7">勾选代理商</h1>
                    <el-checkbox-group v-model="agents" style="max-height:200px;overflow: auto;">
                        <div v-for="item in agentList" :key="item.index">
                            <el-checkbox :label="item.id" >{{item.name}}</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div>
                
            </el-form-item>
            <el-form-item label="单个IMEI绑定上限:">
                <el-input v-model="formSet.limitTimes"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="sure">确定</el-button>
            <el-button type="primary" class="themed-button" @click="reset">重置</el-button>
            <el-button type="primary" class="themed-button" @click="dialogSetting = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {imeiRepoList,imeiRepoBindList,batchImport,agentList,getSettings,saveSettings} from '@/api/cardManagement/flowmonitor.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import resize from '@/mixins/resize.js'

export default {
    name:'imeiRepo',
    mixins:[resize],
    data(){
        return {
            form:{
                accountId:'',
                imei:''
            },
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogImei:false,
            tableDataImei:[],
            dialogImport:false,
            type:'1',
            dialogSetting:false,     
            agentList:[],
            formSet:{
                agents:'',
                limitTimes:''
            },
            agents:[]

        }
    },
    mounted(){
        this._imeiRepoList()
    },
    methods:{
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },
        sure(){
            this.formSet.agents = this.agents.join(';')
            saveSettings(this.formSet).then((res)=>{
                this.dialogSetting = false
                this._imeiRepoList()
            })
        },
        reset(){
            this.agents = []
            this.formSet.agents = ''
            this.formSet.limitTimes = ''
        },
        showList(id,imei){
            this.dialogImei = true
            imeiRepoBindList({imei,id}).then((res)=>{
                this.tableDataImei = res
            })
        },
        formatDatebox(value) {  
            if (value == null || value == '') {  
                return '';  
            }  
            var dt;  
            if (value instanceof Date) {  
                dt = value;  
            } else {  
                dt = new Date(value);  
            }  
            // return dt.format("yyyy-MM-dd hh:mm:ss")
            return dt.toJSON().substr(0, 19).replace('T', ' ')
        },
        searchTable(){
            this._imeiRepoList()
        },
        openImportWindow(){
            this.dialogImport = true
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('type',this.type)
                    batchImport(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogImport = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        openSettingWindow(){
            this.dialogSetting = true
            agentList(this.formSet).then((res)=>{
                this.agentList = res
            })
            getSettings(this.formSet).then((res)=>{
                res.forEach((item)=>{
                    if (item.name == 'AGENTS') {
                        this.formSet.agents = item.value
                        this.agents = this.formSet.agents.split(';')
                    } else if (item.name == 'LIMIT_TIMES') {
                        this.formSet.limitTimes = item.value
                    }
                })
            })
            
        },
        handleSizeChange(val){
            this.pageSize = val
            this._imeiRepoList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._imeiRepoList()
        },
        _imeiRepoList(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            imeiRepoList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        }
    },
    components:{
        SearchSelect
    }
}

</script>

