import request from './../request.js'


//agentCardUseCycle.vue
export function getAgentList(){
    return request({
        url: 'agent/list4agent.do?',
        method: 'post'
    })
}
export function getAccountList(){
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post'
    })
}
export function getList(params){
    return request({
        url: 'cardActive/getList.do?',
        method: 'post',
        data:params
    })
}
export function cardActiveExportSync(params){
    return request({
        url: 'cardActive/exportSync.do?',
        method: 'post',
        data:params
    })
}

//businessCardLifeCycle.vue
export function getActiveCardList(params){
    return request({
        url: 'cardActive/getAgentCardList.do?',
        method: 'post',
        data:params
    })
}

//agentActiveCardNumber.vue
export function getAgentCardList(params){
    return request({
        url: 'agentcard/getList.do?',
        method: 'post',
        data:params
    })
}
export function agentCardSyncExport(params){
    return request({
        url: 'agentcard/syncExport.do?',
        method: 'post',
        data:params
    })
}

//monthlyReportList.vue
export function monthReportList(params){
    return request({
        url: 'finance/monthReportList.do?',
        method: 'post',
        data:params
    })
}

export function syncExport(params){
    return request({
        url: 'finance/syncExport.do?',
        method: 'post',
        data:params
    })
}
export function monthReportEdit(params){
    return request({
        url: 'finance/monthReportEdit.do?',
        method: 'post',
        data:params
    })
}

//resourceUsageStatistics.vue
export function accountFlow(params){
    return request({
        url: 'finance/accountFlow.do?',
        method: 'post',
        data:params
    })
}

//monthlyMeter.vue
export function getList_monthlyMeter(params){
    return request({
        url: 'cardFlowStatic/getList.do?',
        method: 'post',
        data:params
    })
}
export function exportSync_monthlyMeter(params){
    return request({
        url: 'complaint/exportSync.do	?',
        method: 'post',
        data:params
    })
}