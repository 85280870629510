<template>
    <div class="refund-order-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">退款订单列表</span>
            <el-form inline ref="refundOrderForm" v-model="refundOrderData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="refundOrderData.iccidMark" class="iccid-width" placeholder="ICCID"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="refundOrderData.orderNumber" placeholder="订单编号"></el-input>
                </el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

            </el-form>

            
        </div>
        <div class="content-box">
            <el-table
                ref='table'
                stripe
                border
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :max-height="maxTableHeight">
            
            <el-table-column
                show-overflow-tooltip
                prop="orderNumber"
                label="订单号"
                min-width="180"
                >
            </el-table-column>
            <el-table-column
                    label="ICCID"
                    min-width="180"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-link :underline='false' type="primary" @click="toKawu(scope.row.iccidMark)">{{scope.row.iccidMark}}</el-link>
                    </template>
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="agentTreeName"
                    label="所属总代理商"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="mealName"
                    label="套餐名称"
                    min-width="170">
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="mealTypeStr"
                    label="套餐类型"
                    min-width="150">
            </el-table-column>
             <el-table-column
                    prop="orderMoney"
                    label="下单金额"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="money"
                    label="实际支付金额"
                    min-width="120">
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="createTime"
                label="下单时间"
                min-width="180">
            </el-table-column>
            <el-table-column
                    prop="returnMoney"
                    label="申请退款金额"
                    min-width="120">
                </el-table-column>
            <el-table-column
                show-overflow-tooltip
                    prop="returnReson"
                    label="申请退款理由"
                    min-width="150">
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                    prop="createUser"
                    label="登记者"
                    min-width="90">
            </el-table-column>
            <el-table-column
                label="操作"
                min-width="250">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="primary" @click="handleRefund(scope.row)">确认退款</el-button>
                        <el-button size="mini" class="themed-button" type="primary" @click="cancelRefundMark(scope.row)">取消退款登记</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="确认退款" :visible.sync="dialogRefundVisible" :destroy-on-close='true'>
        <el-form :model="refundForm">
            <el-form-item label="金额" prop="returnMoney">
            <el-input v-model="refundForm.returnMoney" autocomplete="off"  style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="退款理由" prop="returnReson">
            <el-input type="textarea" v-model="refundForm.returnReson"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button class="themed-button" type="info" @click="dialogRefundVisible = false">取 消</el-button>
            <el-button class="themed-button" type="primary" @click="handleSure"  v-preventReClick='1000' :disabled="isDisable">确 定</el-button>
        </div>
        </el-dialog>

    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {refundOrderList,checkRefund} from '@/api/order.js'
import resize from '@/mixins/resize.js'
import {mapMutations} from "vuex"
export default {
    name:'refundOrderList',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return{
            refundOrderData:{
                iccidMark:'',
                orderNumber:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogRefundVisible:false,
            relPayMoney:'',
            refundForm:{
                orderNumber:'',
                returnMoney:'',
                returnReson:'',
                type:'1'
            },
          isDisable:false,
        }
    },
    created(){
        this.getRefundOrderList()
    },
    methods:{
        ...mapMutations(
                ["SET_ICCID_OR_PHONE","SET_STATUS","SET_TYPE"]
            ),
        getRefundOrderList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.refundOrderData,params)
            refundOrderList(this.refundOrderData).then(res=>{
                this.tableData = res.rows
                this.total= res.count
            })
        },
        onSearch(){
            this.page = 1
            this.getRefundOrderList()
        },
        onPageChange(page){
			this.page = page
			this.getRefundOrderList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getRefundOrderList()
        },

        handleRefund(data){
            this.dialogRefundVisible = true
            this.refundForm.orderNumber = data.orderNumber
            this.refundForm.returnMoney = data.returnMoney
            this.refundForm.returnReson = data.returnReson
            this.relPayMoney = data.money
        },

        handleSure(){
            if(parseFloat(this.refundForm.returnMoney) > parseFloat(this.relPayMoney)){
                this.$alert('申请退款金额不能大于实际支付金额', '提示', {
							confirmButtonText: '确定',
					});
					return
            }
            this.isDisable = true
            checkRefund(this.refundForm).then(res=>{
                this.isDisable = false
                if(res.system_result_key == 0){
                    this.$message({
                        showClose: true,
                        message: '操作成功',
                        type: 'success'
                        })
                    this.dialogRefundVisible = false
                    
                }
                this.getRefundOrderList()
            }).catch(()=>{
                this.isDisable = false
            })    
        },

        cancelRefundMark(data){
            let params = {
                type:'2',
                orderNumber:data.orderNumber
            }
            this.$confirm("确定要取消退款登记吗？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                checkRefund(params).then(res=>{
                if(res.system_result_key == 0){
                    this.$message({
                        showClose: true,
                        message: '操作成功',
                        type: 'success'
                    })   
                }
                this.getRefundOrderList()
                })
            }).catch(()=>{

            });
        },

        toKawu(cardId){
            this.SET_ICCID_OR_PHONE(cardId)
            this.SET_STATUS('0')
            this.SET_TYPE('0')
            this.$router.push({path:'../cardManagement/cardBillDetail',query:{'iccidOrPhone':cardId,'status':0,'type':0}})
        }
       
    }
}
</script>
<style>
</style>