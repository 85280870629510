/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from '../request.js'

export function cardList(params){
    return request({
        url: 'bindCard/listCard.do?',
        method: 'post',
        data:params
    })
}

export function importCardByExcel(params){
    return request({
        url:'bindCard/importAddSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function exportCardList(params){
    return request({
        url: 'bindCard/exportCardSync.do?',
        method: 'post',
        data:params
    })
}

export function cardBankCodeSpanList(params){
    return request({
        url: 'bindCard/listCardBankCode.do?',
        method: 'post',
        data:params
    })
}

export function deleteCardByExcel(params){
    return request({
        url:'bindCard/importDeleteSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function bindCardFindById(params){
    return request({
        url: 'bindCard/findByIdCard.do?',
        method: 'post',
        data:params
    })
}

export function editCardList(params){
    return request({
        url: 'bindCard/importUpdateCard.do?',
        method: 'post',
        data:params
    })
}

export function deleteCardList(params){
    return request({
        url: 'bindCard/deleteByIdCard.do?',
        method: 'post',
        data:params
    })
}
