<template>
    <div class="active-rate fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">激活率统计</span>
            <el-form inline ref="quaryForm" v-model="quaryForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="代理商名称">
                    <el-select filterable v-model="quaryForm.agentId">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(item,index) in agentIdList" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                 
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>             
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="agentName"
                    label="代理商名称"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop='cardNum'
                    label="总卡数"
                    min-width="150"
                    >
                </el-table-column>
                
                <el-table-column
                    show-overflow-tooltip
                    prop="activeNum"
                    label="已激活卡数"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="activeRate"
                    label="激活率"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="统计时间"
                    min-width="150"
                    >
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="quaryForm.pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="quaryForm.pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { getActiveRate,exportActiveRate} from "@/api/operationalStatistics/operationalStatistics.js";
import {getAgentList} from "@/api/agentManagement.js"
export default {
    mixins:[resize],
    data(){
        return {
            quaryForm:{
                pageNo:1,
                pageSize:15,
                agentId:'',
            },
            tableData:[],
            total:0,
            agentIdList:[]
        }
    },
    created(){
        this.getList()
        this.getAgentList()
    },
    methods: {
        getAgentList(){
            getAgentList().then(res=>{
				this.agentIdList = res
			})
        },
        getList(){
            getActiveRate(this.quaryForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },

        onPageChange(page){
			this.quaryForm.pageNo = page
			this.getList()
		},
		handleSizeChange(size){
			this.quaryForm.pageSize = size
			this.getList()
        },

        onSearch(){
            this.quaryForm.pageNo=1
            this.getList()
        },

        exportExcel(){
            let params = {
                agentId:this.quaryForm.agentId
            }
            exportActiveRate(params).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        }
    },
}
</script>

<style>
    .active-rate .el-form-item__label{
        vertical-align: top;
    }
</style>