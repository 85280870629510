<template>
  <div class="updateExpire">
    <div style="margin-bottom:8px;">
        <el-checkbox v-model="checked" value="1" @change="refresh">隐藏今天之前的过期时间</el-checkbox>
        <el-button type="primary" class='themed-button' @click="delectSome" style="margin-left:10px;">批量删除</el-button>
    </div>
    <el-table 
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
			border
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="iccidMark" label="卡号" width="180px"></el-table-column>
      <el-table-column prop="phone" label="接入号" width="130px"> </el-table-column>
      <el-table-column prop="mealName" label="套餐" ></el-table-column>
      <el-table-column label="次月生效">
          <template slot-scope="scope">
              <span v-if="scope.row.type && scope.row.type===999">是</span>
              <span v-else>否</span>
          </template>
      </el-table-column>
      <el-table-column label="开始时间" width="140">
          <template slot-scope="scope">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss" v-model="scope.row.startDate"></el-date-picker>
          </template>
      </el-table-column>
      <el-table-column label="过期时间" width="140" >
          <template slot-scope="scope">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss" v-model="scope.row.expireDate"></el-date-picker>
          </template>
      </el-table-column>
      <el-table-column label="备注" show-overflow-tooltip>
          <template slot-scope="scope">
              <el-input v-model="scope.row.remark"></el-input>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="140" >
          <template slot-scope="scope">
              <el-button type="primary" class='themed-button' @click="save(scope.row)">保存</el-button>
              <el-button type="primary" class='themed-button' @click="delect(scope.row)">删除</el-button>
          </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        :total="total"
        layout="total, sizes, prev, next, jumper">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
import {listCardLife,updateOrDeleteCardLife,delAll} from '@/api/cardManagement/cardBillDetail.js'
import {messageBox,confirmBox} from '@/utils/common.js'

export default {
    name:'updateExpire',
    props:['cardId'],
    data(){
        return {
            checked:true,
            multipleSelection:[],
            tableData:[],
            currentPage:1,
            pageSize:15,
            total:0
        }
    },
    mounted(){
        this._listCardLife()
    },
    methods:{
        refresh(){
            this._listCardLife()
        },
        delectSome(){
			if(this.multipleSelection.length<=0){
				messageBox(this,"请选择要删除的行！",'提示')
			    return 
			}
            confirmBox(this,"是否删除选中数据?","操作提示",this._delAll)
        },
        save(data){
            confirmBox(this,"确定要保存吗？","操作提示",()=>{
							//console.log(data)
                this.comfirOperate(data,false)
            })
        },
        delect(data){
            confirmBox(this,"确定要删除吗？","操作提示",()=>{
                this.comfirOperate(data,true)
            })
        },
        comfirOperate(data,type){
            let params = {}
            if(this.checked){
                params.hideBeforeToday  = 1
            }
            params.cardLifeId = data.id
            params.cardId = data.cardId
            if(type){
                params.delete = true
            }else{
							const expireDate =  data.expireDate.indexOf(".")>-1? data.expireDate.substring(0,data.expireDate.indexOf("."))  : data.expireDate
               //console.log(expireDate)
								params.expireDate = expireDate
                params.remark = data.remark
                params.newStartDate = data.startDate
                params.delete = false
            }
            updateOrDeleteCardLife(params).then((res)=>{
                //console.log(res.system_result_message_key)
                messageBox(this,res.system_result_message_key,'提示',this._listCardLife)
            })
        },
        handleSelectionChange(val){
            this.multipleSelection = val;            
        },
        handleSizeChange(val){
            this.pageSize = val
            this._listCardLife()
        },
        handleCurrentChange(){
            this.currentPage = val
            this._listCardLife()
        },
        _delAll(){
            
            let cardLifeIds = ''
           /* let cardId = this.cardId */
            for(let i = 0;i<this.multipleSelection.length;i++){
				if(cardLifeIds ==""){
					 cardLifeIds = this.multipleSelection[i]["id"]
				}else{
                    // console.log(cardLifeIds,145)
					cardLifeIds = this.multipleSelection[i]["id"] +','+cardLifeIds
				}
                
            }
            console.log(cardLifeIds,145)
           /* delAll({cardLifeId,cardId}).then((res)=>{ */
				 delAll({cardLifeIds}).then((res)=>{
                let mag = "删除成功: "+res.sucessNum+' , 删除失败: '+res.failNum
                messageBox(this,mag,'提示',this._listCardLife)
            })
        },
        _listCardLife(){
            let params={}
            if(this.checked){
                params.hideBeforeToday  = 1
            }
            params.cardId = this.cardId
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            listCardLife(params).then((res)=>{
                this.total = res.total
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.tableData = res.rows
            })
        }
    }
};
</script>

<style>
.updateExpire .el-input--prefix .el-input__inner{padding-left:28px}
.updateExpire .el-input--suffix .el-input__inner{padding-right:5px;}
.updateExpire .el-date-editor.el-input,
.updateExpire .el-date-editor.el-input__inner{width:120px;}
</style>
