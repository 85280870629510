<template>

<div class="stock-out table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">出库批次</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="批次号:">
                <el-select v-model="form.outCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in outCodeList" :label="item.outCode" :value="item.outCode" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="物料编号:">
                <el-select v-model="form.materielCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in materielCodeList" :label="item.materielCode" :value="item.materielCode" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="业务类型:">
                <el-select v-model="form.businessType">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in businessTypeList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="importAdd">新增出库批次</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-minus" class="themed-button" @click="importDelete">批量删除导入</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="出库批次" prop="outCode" min-width="160px">
                <template  slot-scope="scope">
                    <a href="javascript:void(0);" @click="viewCard(scope.row.outCode)">{{scope.row.outCode}}</a>
                </template>
            </el-table-column>
            <el-table-column label="物料编码" prop="materielCode" min-width="120px"></el-table-column>
            <el-table-column label="产品名称" prop="productName">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productName,dictMap.productName)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="业务类型" prop="businessType">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.businessType,dictMap.businessType)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="产品类型" prop="productType">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productType,dictMap.productType)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="产品制式" prop="productStandard">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productStandard,dictMap.productStandard)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="产品功能" prop="productAbility">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productAbility,dictMap.productAbility)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="产品分组" prop="productGroup">
                <template  slot-scope="scope">
                    <span>
                        {{_dictMapTrans(scope.row.productGroup,dictMap.productGroup)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="出库数量" prop="batchAmount"></el-table-column>
            <el-table-column label="收取卡板费" prop="totalPayFee"></el-table-column>
            <el-table-column label="出库时间" prop="outStockTime"></el-table-column>
            <el-table-column label="出库客户名称" prop="outAgentName"></el-table-column>
            <el-table-column label="操作人" prop="operator" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="140px">
                <template  slot-scope="scope">
                    <el-button type="primary" class="themed-button" @click="importUpdate(scope.row.id)">编辑</el-button>
                    <el-button type="danger" class="themed-button"  @click="deleteById(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>


    <el-dialog :title="dialogTitle" :visible.sync="dialogBox" v-if="dialogBox">
        <form-add v-if="dialogContent == 'add'" @close="dialogBox=false" @close-dialog='dialogBox=false'></form-add>
        <form-delete v-if="dialogContent == 'delete'" @close="dialogBox=false" @close-dialog='dialogBox=false'></form-delete>
        <form-update v-if="dialogContent == 'update'" :id="id" @close="updateClose"></form-update>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">

import {listOutCode,stockOutList,OutCodeExport,stockOutDeleteById} from '@/api/stockManagement/stock.js'
import {messageBox,confirmBox,dictMapTrans,testTimeJudge3Month} from '@/utils/common.js'

import SearchSelect from '@/components/SearchSelect'
import FormAdd from '@/views/stockManagement/stockOutList/formAdd'
import FormDelete from '@/views/stockManagement/stockOutList/formDelete'
import FormUpdate from '@/views/stockManagement/stockOutList/formUpdate'

import resize from '@/mixins/resize.js'

export default {
    name:'stockOutList',
    mixins:[resize],
    data(){
        return {
            id:'',
            form:{
                outCode:'',
                materielCode:'',
                businessType:''
            },
            outCodeList:[],
            materielCodeList:[],
            businessTypeList:[],
            dictMap:{},
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogTitle:'',
            dialogBox:false,
            dialogContent:''
        }
    },
    mounted(){
        this._listOutCode()
        this._stockOutList()
    },
    methods:{
        viewCard(code){
            this.$router.push({
                path: "/stockManagement/stockOutCardList",
                query: {
                    outCode: code
                },
            })
        },
        searchTable(){
            this._stockOutList()
        },
        importAdd(){ //新增
            this.dialogTitle = "新增出库批次"
            this.dialogContent = 'add'
            this.dialogBox = true
        },
        importUpdate(id){ //编辑
            this.id = id
            this.dialogTitle = "修改出库批次"
            this.dialogContent = 'update'
            this.dialogBox = true
            
        },
        importDelete(id){ //删除批量导入
            this.id = id
            this.dialogTitle = "批量删除导入"
            this.dialogContent = 'delete'
            this.dialogBox = true
        },
        deleteById(id){
            confirmBox(this,'您确定要删除吗?','操作提示',()=>{
                stockOutDeleteById({id}).then((res)=>{
                    if(res.slide_msg_key == 1){
                        messageBox(this,res.slide_msg_message_key)
                    }
                    this._stockOutList()
                })
            })
        },
        exportExcel(){
            OutCodeExport(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        updateClose(){
            this.dialogBox = false
            this._stockOutList()
        },
        handleSizeChange(val){
            this.pageSize = val
            this._stockOutList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._stockOutList()
        },
        _listOutCode(){
            listOutCode().then((res)=>{
                console.log(res)
                if(res.data.length>0){
                    this.outCodeList = res.data
                }else{
                    this.outCodeList = []
                }
                if(res.mCode.length>0){
                    this.materielCodeList = res.mCode
                }else{
                    this.materielCodeList = []
                }
            })
        },
        _stockOutList(){
            let  params = {
                'pageSize' : this.pageSize,
                'pageNo' : this.currentPage
            }
            stockOutList(Object.assign(params,this.form)).then((res)=>{
                let data = res.data 
                this.pageSize = data.pageSize
                this.currentPage = data.pageNo
                this.tableData = data.rows
                this.total = data.total
                this.businessTypeList = res.dict.businessType
                this.dictMap = res.dict
            })
        },
        _dictMapTrans(value,dictGroup){
            return dictMapTrans(value,dictGroup)
        },
        _testTimeJudge3Month(val){
            return testTimeJudge3Month(val)
        }
    },
    components:{
        SearchSelect,
        FormAdd,
        FormDelete,
        FormUpdate
    }
}
</script>
