<template>

<div class="card-import table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">网卡导入</span>
        <el-form ref="cardForm"  inline class="hook right themed-form search-form" style="height: 40px;line-height: 40px;">
            <el-form-item label="运营商:">
                <el-select v-model="operator" placeholder="全部" @change="selectOperator">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in w_operator_group " :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                </el-select>
            </el-form-item>
              <el-form-item label="代理商:"  v-if="currentRole==0">
          <el-select v-model="agentId" filterable placeholder="请选择代理商" clearable @change="selectAgent" >
                    <el-option v-for="(item,index) in agentLists" :key="index" :label="item.label"  :value="item.value"></el-option>
                </el-select>
        </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="importRecord">导入网卡</el-button>
            </el-form-item>
            <!-- <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="importCardAduit">导入自由设置套餐网卡</el-button>
            </el-form-item> -->
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="导入时间" prop="importDate" width="180px"></el-table-column>
            <el-table-column label="导入批次" prop="importBatch" show-overflow-tooltip></el-table-column>
            <el-table-column label="导入数(张)" prop="importTotal" show-overflow-tooltip></el-table-column>
            <el-table-column label="导入成功数(张)" prop="importSuccess" show-overflow-tooltip></el-table-column>
            <el-table-column label="导入失败数(张)" prop="importFail" show-overflow-tooltip></el-table-column>
            <el-table-column label="分销代理商" prop="agentName" show-overflow-tooltip>
                <template  slot-scope="scope">
                    <span v-if="scope.row.agentName">{{scope.row.agentName}}</span>
                    <span v-else>未分销</span>
                </template>
            </el-table-column>
            <el-table-column label="套餐名称" prop="setMealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="运营商" prop="operatorName" show-overflow-tooltip></el-table-column>
            <el-table-column label="卡类型" prop="type" show-overflow-tooltip>
                <template  slot-scope="scope">
                    <span v-if="scope.row.type == 1">月卡</span>
                    <span v-else-if="scope.row.type == 2">年卡</span>
                    <span v-else>-</span>
                </template>    
            </el-table-column>
            <el-table-column label="操作人" prop="createUser" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template  slot-scope="scope">
                    <el-button type="primary" class="themed-button"  @click='showFail(scope.row.id)'>查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        background
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>


    <el-dialog title="网卡导入" :visible.sync="dialogImportRecord">
        <el-form label-position="right" label-width="200px" :model="cardImportForm" ref="cardImportForm" :rules="rules"  style="max-height:500px;overflow:auto">
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                     <span class="text-color" style="font-size: 12px;">导入联通、电信网卡时ICCID只需要19位，接入号需要去除开头的86字段。否则可能会出现部分运营商接口不兼容无法更新用量和停机复机卡片！</span>
                </div>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="导入卡类型:">
                <el-radio-group v-model="cardImportForm.type">
                    <el-radio :label="1">普通卡</el-radio>
                    <el-radio :label="2">虚拟卡</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="代理商:" prop="agentList">
                <el-select v-model="cardImportForm.agentList" filterable placeholder="请选择代理商" @change="agentSetMealForImportCard" :disabled="(currentRole==4&&agentTree==1)">
                    <el-option v-for="(item,index) in agentLists" :key="index" :label="item.label"  :value="item.value"></el-option>
                </el-select>
                <p>不分配网卡的时候可以不选择代理商</p>
            </el-form-item>
            <el-form-item label="运营商:" required prop="accountList">
                <el-select v-model="cardImportForm.accountList" filterable placeholder="请选择运营商" @change="agentSetMealForImportCard">
                    <el-option v-for="(item,index) in accountLists" :key="index" :label="item.label"  :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="套餐:" required prop="setMealList">
                 <!-- <search-select :optionsMetaAll="packageLists" v-model="cardImportForm.setMealList"></search-select> -->
                <el-select v-model="cardImportForm.setMealList"  filterable placeholder="请选择套餐">
                    <el-option v-for="(item,index) in packageLists" :key="index" :label="item.label"  :value="item.value"></el-option>
                </el-select>
               
            </el-form-item>
            <el-form-item label="套餐类型:"  prop="mealType" style="display:none">
                <el-select v-model="cardImportForm.mealType" placeholder="请选择套餐类型">
                    <el-option label="包年" value="2"></el-option>
                    <el-option label="包月" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="卡类型:"  prop="cardType" style="display:none">
                <el-select v-model="cardImportForm.cardType" placeholder="请选择卡类型">
                    <el-option label="2G" value="2G"></el-option>
                    <el-option label="4G" value="4G"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="业务类型:" required prop="toType">
                <el-select v-model="cardImportForm.toType" placeholder="请选择" :disabled="(currentRole==4&&agentTree==1)">
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="(item,index) in w_TOTYPE_" :key="index" :label="item.groupName"  :value="item.dictValue"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="销售类型:" required  prop="saleType">
                <el-select v-model="cardImportForm.saleType" placeholder="请选择" :disabled="(currentRole==4&&agentTree==1)">
                    <el-option label="请选择" value=""></el-option>
                    <el-option label="线上" value="1"></el-option>
                    <el-option label="线下" value="2" v-if="isCaptal!='1'"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合作类型:" required  prop="coType" >
                <el-select v-model="cardImportForm.coType" placeholder="请选择" :disabled="(currentRole==4&&agentTree==1)">
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="(item,index) in w_COTYPE_" :key="index" :label="item.groupName"  :value="item.dictValue"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品类型:" required  prop="productType" >
                <el-select v-model="cardImportForm.productType" placeholder="请选择" :disabled="(currentRole==4&&agentTree==1)">
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="(item,index) in w_PRODUCT_" :key="index" :label="item.groupName"  :value="item.dictValue"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="充值类型:" required  prop="rechargeType">
                <el-select v-model="cardImportForm.rechargeType" placeholder="请选择" :disabled="(currentRole==4&&agentTree==1)">
                   <el-option label="请选择" value=""></el-option>
                    <el-option label="普通类型" value="1"></el-option>
                    <el-option label="预存话费类型" value="2" v-if="parseInt(isGuangyao)"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="实名认证:"  prop="isRealName">
                <el-select v-model="cardImportForm.isRealName" placeholder="请选择" :disabled="(currentRole==4&&agentTree==1)">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="是否仅在代理商公众号充值:"  prop="isAgentRecharge">
                <el-select v-model="cardImportForm.isAgentRecharge" placeholder="请选择">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="2"></el-option>
                </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="是否支持语音白名单:">
                <el-select v-model="cardImportForm.voiceWhitelistType" placeholder="请选择">
                    <el-option label="支持语音白名单" value="1"></el-option>
                    <el-option label="不支持语音白名单" value="0"></el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="导入不轮询网卡"  prop="importNoLoopCard">
                <el-select v-model="cardImportForm.importNoLoopCard" placeholder="请选择" :disabled="(currentRole==4&&agentTree==1)" >
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="赠送套餐"  prop="giftMealId" v-if="isCaptal!='1'">
                <el-select v-model="cardImportForm.giftMealId" placeholder="请选择">
                    <el-option v-for="(item,index) in givePageLists" :key="index" :label="item.label"  :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="测试期至:" prop="testExpireTime" v-if="!(currentRole==4&&agentTree==1) && parseInt(isGuangyao)">
                <el-date-picker
                    v-model="cardImportForm.testExpireTime"
                    type="date"
                    value-format="MM/dd/yyyy"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="沉默期至:" prop="silentExpireTime" v-if="!(currentRole==4&&agentTree==1) && parseInt(isGuangyao)">
                <el-date-picker
                    v-model="cardImportForm.silentExpireTime"
                    type="date"
                    value-format="MM/dd/yyyy"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="有效期至:"  prop="expireTime" v-if="!(currentRole==4&&agentTree==1) && parseInt(isGuangyao)">
                <el-date-picker
                    v-model="cardImportForm.expireTime"
                    type="date"
                    value-format="MM/dd/yyyy"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="销售" v-if="!(currentRole==4&&agentTree==1)">
                <el-select v-model="cardImportForm.saleId" placeholder="请选择" filterable @change="changeSale">
                        <el-option label="请选择" value=""></el-option>
                        <el-option v-for="(item,index) in saleList" :key="index" :label="item.saleName"  :value="item.id"></el-option>
                    </el-select>
            </el-form-item>

            <el-form-item label="套餐类型" v-if="showSaleItem" required>
                    <el-select v-model="cardImportForm.setmealType" placeholder="请选择">
                        <el-option v-for="(item,index) in w_sellType" :key="index" :label="item.groupName"  :value="item.dictValue"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="部门" v-if="showSaleItem" required>
                    <el-input v-model="cardImportForm.department" autocomplete="off" style="width:220px"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="submitForm('cardImportForm')">导入excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="templateExcel('import.xls')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogImportRecord = false">取 消</el-button>
        </div>
    </el-dialog>
    <el-dialog title="自由设置套餐网卡分销" :visible.sync="dialogSetPackage">
        <el-form label-position="right" label-width="200px" :model="setPackageForm" ref="setPackageForm" :rules="rulesPackage" style="max-height:500px;overflow:auto">
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='file1'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="批次号:" prop='number'>
                <el-input v-model.number="setPackageForm.number"></el-input>
                <p>例如:188</p>
            </el-form-item>
            <el-form-item label="流量池号:" prop="flowNumber">
                <el-input v-model="setPackageForm.flowNumber"></el-input>
                <p style="color:#f00">流量池卡必须填写，非流量池卡不要填写</p>
            </el-form-item>
            <el-form-item label="代理商:"  prop="agent">
                <el-select v-model="setPackageForm.agent" placeholder="请选择代理商">
                    <el-option v-for="(item,index) in agentLists" :key="index" :label="item.label"  :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="账号:"  prop="account">
                <el-select v-model="setPackageForm.account" placeholder="请选择运营商">
                    <el-option v-for="(item,index) in accountLists" :key="index" :label="item.label"  :value="item.value"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="submitFormPackage('setPackageForm')">导入excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('import.xls')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogSetPackage = false">取 消</el-button>
        </div>
    </el-dialog>
</div>

</template>

<script type="text/ecmascript-6">
    import {listDataUrl,getAccountList,getAgentForImportCard,gridAgentSetMealForImportCard,gridSetMealForImportCard,importSync,importCardSync} from '@/api/cardManagement/cardImport.js'
    import {messageBox,confirmBox} from '@/utils/common.js'
    import {allSaleList} from "@/api/saleCardBatch.js"
    import Utils from '@/utils/utils.js'
    import {exportTemplate} from '@/api/statistics.js'
    import resize from '@/mixins/resize.js'
    import dictGroup from '@/mixins/dictGroup.js'
    import SearchSelect from '@/components/SearchSelect.vue'
    import {mapGetters} from 'vuex'
    export default {
        name:'cardImport',
        mixins:[resize,dictGroup],
        components:{SearchSelect},
        data(){
            return {
                // isCustomer:this.isGuangyao,
                saleList:[],
                showSaleItem:false,
                operator:'',
                tableData:[],
                pageSize:15,
                currentPage:1,
                dialogImportRecord:false,
                dialogSetPackage:false,
                fileList:[],
                cardImportForm:{
                    type:1,
                    agentList:"",
                    accountList:'',
                    setMealList:'',
                    mealType:"1",
                    cardType:'4G',
                    toType:'',
                    saleType:'',
                    coType:'',
                    productType:'',
                    isRealName:'0',
                    isAgentRecharge:'2', 
                    voiceWhitelistType:'0',
                    importNoLoopCard:'1',  
                    giftMealId:'',
                    testExpireTime:'', //测试期
                    silentExpireTime:'', //沉默期
                    expireTime:'', //有效期
                    saleId:'',
                    setmealType:'',
                    department:'',
                    rechargeType:"",
                },
                total:0,
                accountLists:[], //运营商
                agentLists:[],  //代理商
                packageLists:[], //套餐
                givePageLists:[],  //赠送套餐
                rules: {
                    invNo: [
                        { required: true, message: '请输入虚拟卡号', trigger: 'change' }
                    ],
                    accountList: [
                        { required: true, message: '请选择运营商', trigger: 'change' }
                    ],
                    setMealList: [
                        { required: true, message: '请选择赠送套餐', trigger: 'change' }
                    ],
                    // mealType: [
                    //     { required: true, message: '请选择套餐类型', trigger: 'change' }
                    // ],
                    // cardType: [
                    //     { required: true, message: '请选择卡类型', trigger: 'change' }
                    // ],
                    toType: [
                        { required: true, message: '请选择业务类型', trigger: 'change' }
                    ],
                    saleType: [
                        { required: true, message: '请选择销售类型', trigger: 'change' }
                    ],
                    productType: [
                        { required: true, message: '请选择产品类型', trigger: 'change' }
                    ],
                    coType: [
                        { required: true, message: '请选择合作类型', trigger: 'change' }
                    ],
                    rechargeType: [
                        { required: true, message: '请选择充值类型', trigger: 'change' }
                    ],
                    
                },
                setPackageForm:{
                    number:'',
                    flowNumber:'',
                    agent:'',
                    account:''
                },
                rulesPackage:{
                    number:[
                        { required: true, message: '请输入批次号', trigger: 'blur' },
                        { type: 'number', message: '批次号格式不正确', trigger: 'blur' }
                    ],
                    agent: [
                        { required: true, message: '请选择运营商', trigger: 'change' }
                    ],
                    account: [
                        { required: true, message: '请选择运营商', trigger: 'change' }
                    ],
                },
                agentId:"",
                
            }
        },
        mounted(){
           
          
            this._listDataUrl()
            this.getSaleList()
           this._getAgentForImportCard()
            try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
        },
        methods:{
            getSaleList(){
                allSaleList().then(res=>{     
                    this.saleList=res
                })
            },
            changeSale(val){
                if(!!val){
                    this.showSaleItem = true
                }else{
                    this.showSaleItem = false
                    this.cardImportForm.setmealType = ''
                    this.cardImportForm.department = ''
                }
            },
            templateExcel(){
                let name = ''
                if(this.cardImportForm.type == 2){
                    name = 'invimport.xls'
                }else if(this.cardImportForm.type == 1){
                    name = 'import.xls'
                }
                this.exportTemplateExcel(name)
            },
            exportTemplateExcel(name){
                exportTemplate({name}).then(res=>{
                    const filename = name
                    Utils.createDownload(res,filename)
                })
            },
            showFail(importCardId){
                this.$router.push({
                path: "/cardManagement/cardImportFailList",
                query: {importCardId},
            })
            },
            selectOperator(val){
                this.operator = val
            },
            selectAgent(val){
              this.agentId = val
            },
            searchTable(){
                this._listDataUrl()
            },
            importRecord(){
                this.resetFrom()
                this.dialogImportRecord = true
                this._getAccountList()
                this._getAgentForImportCard()
                  if(this.currentRole==4&&this.agentTree==1){
                this.cardImportForm.agentList = this.currentAgentId
                console.log( this._getToTypeValue("TOC_"),this._getProductValue("LL_"))
                this.cardImportForm.toType =  this._getToTypeValue("TOC_")
                 this.cardImportForm.saleType ="1" 
                  this.cardImportForm.coType=  this._getCoTypeValue("AL_")  
                  this.cardImportForm.productType  = this._getProductValue("LL_")
                  this.cardImportForm.rechargeType ="1"
                  this.cardImportForm.isRealName  = "0"
                  this.cardImportForm.importNoLoopCard ="1"

            }
                
            },
            resetFrom(){
                this.cardImportForm = {
                    type:1,
                    agentList:'',
                    accountList:'',
                    setMealList:'',
                    mealType:"1",
                    cardType:'',
                    toType:'',
                    saleType:'',
                    coType:'',
                    productType:'',
                    isRealName:'0',
                    isAgentRecharge:'2', 
                    voiceWhitelistType:'0',
                    importNoLoopCard:'1',  
                    giftMealId:'',
                    testExpireTime:'', //测试期
                    silentExpireTime:'', //沉默期
                    expireTime:'', //有效期
                    saleId:'',
                    setmealType:'',
                    department:'',
                    rechargeType:""
                }
            },
            importCardAduit(){
                this.dialogSetPackage = true
                this._getAccountList()
                this._getAgentForImportCard()
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._listDataUrl()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._listDataUrl()
            },
            submitForm(formName) {
                confirmBox(this,'确定导入吗？','操作提示',()=>{
                    this.comfirmImport(formName)
                }) 
            },
            comfirmImport(formName){
                let file = this.$refs['file'].files[0];
                if(file){
                    let fileName = file.name
                    let index = fileName.lastIndexOf(".");
                    if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                        messageBox(this,'文件格式错误')
                        return
                    }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                        this.$refs[formName].validate((valid) => {
                            if (valid) {
                                // alert('submit!');
                                let params = new FormData()
                                if(!!this.cardImportForm.saleId){
                                    if(this.cardImportForm.setmealType==''||this.cardImportForm.department==''){
                                        messageBox(this,'套餐类型和部门不能为空')
                                        return
                                    }else{
                                        params.append('saleId',this.cardImportForm.saleId)
                                        params.append('setmealType',this.cardImportForm.setmealType)
                                        params.append('department',this.cardImportForm.department)
                                    } 
                                }
                                params.append('fileurl',file)
                                params.append('agentList',this.cardImportForm.agentList)
                                params.append('agentId',this.cardImportForm.agentList)
                                params.append('accountList',this.cardImportForm.accountList)
                                params.append('accountId',this.cardImportForm.accountList)
                                params.append('setMealList',this.cardImportForm.setMealList)
                                params.append('mealType',this.cardImportForm.mealType)
                                params.append('cardType',this.cardImportForm.cardType)
                                params.append('toType',this.cardImportForm.toType)
                                params.append('saleType',this.cardImportForm.saleType)
                                params.append('coType',this.cardImportForm.coType)
                                params.append('productType',this.cardImportForm.productType)
                                params.append('isRealName',this.cardImportForm.isRealName)
                                params.append('isAgentRecharge',this.cardImportForm.isAgentRecharge)
                                params.append('importNoLoopCard',this.cardImportForm.importNoLoopCard)
                                params.append('giftMealId',this.cardImportForm.giftMealId)
                                params.append('testExpireTime',this.cardImportForm.testExpireTime)
                                params.append('silentExpireTime',this.cardImportForm.silentExpireTime)
                                params.append('expireTime',this.cardImportForm.expireTime)
                                params.append('voiceWhitelistType',this.cardImportForm.voiceWhitelistType)
                                 params.append('rechargeType',this.cardImportForm.rechargeType)
                                if(this.cardImportForm.type == 2){
                                    params.append('invType',1)
                                }else{
                                    params.append('invType',0)
                                }
                                
                                importSync(params).then(res=>{
                                    if (res.system_result_key == '0') {
                                        this.$alert('导入成功，请到导入记录中下载', '提示', {
                                            confirmButtonText:'确定',
                                        }).then(()=>{
                                            this.$refs['file'].value = ''
                                            this.dialogImportRecord = false
                                            this.$router.push('/tob/i_import_list')
                                        });
                                    }else{
                                        this.$message({
                                            showClose: true,
                                            message: res.system_result_message_key,
                                            type: 'error'
                                        });
                                    }
                                })
                            } else {
                                console.log('error submit!!');
                                return false;
                            }
                        });
                    }
                }else{
                    messageBox(this,'请选择文件')
                    return
                }
            },
            submitFormPackage(formName){
                confirmBox(this,'确定导入吗？','操作提示',()=>{
                    this.comfirmImportPackage(formName)
                })
            },
            comfirmImportPackage(formName){
                let file = this.$refs['file1'].files[0];
                if(file){
                    let fileName = file.name
                    let index = fileName.lastIndexOf(".");
                    if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                        messageBox(this,'文件格式错误')
                        return
                    }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                        this.$refs[formName].validate((valid) => {
                            if (valid) {
                                // alert('submit!');
                                let params = new FormData()
                                params.append('fileurl',file)
                                params.append('number',this.setPackageForm.number)
                                params.append('flowNumber',this.setPackageForm.flowNumber)
                                params.append('agent',this.setPackageForm.agent)
                                params.append('account',this.setPackageForm.account)
                                importCardSync(params).then(res=>{
                                    if (res.system_result_key == '0') {
                                        this.$alert('导入成功，请到导入记录中下载', '提示', {
                                            confirmButtonText:'确定',
                                        }).then(()=>{
                                            this.dialogSetPackage = false
                                            this.$router.push('/tob/i_import_list')
                                        });
                                    }else{
                                        this.$message({
                                            showClose: true,
                                            message: res.system_result_message_key,
                                            type: 'error'
                                        });
                                    }
                                })
                            } else {
                                console.log('error submit!!');
                                return false;
                            }
                        });
                    }
                }else{
                    messageBox(this,'请选择文件')
                    return
                }
            },
            _listDataUrl(){
                let  params = {
                    'operatorName':this.operator,
                    'pageSize' : this.pageSize,
                    'pageNo' : this.currentPage,
                    "agentId":this.agentId,
                }
                listDataUrl(params).then((res) => {
                    this.tableData = res.rows
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                }).catch(()=>{
					this.tableData = []
				})
            },
            _getAccountList(){
                getAccountList().then((res) => {
                    this.accountLists = res.map((value,index,arr)=>{
                        return {'value':value.id,'label':value.accountName}
                    })
                }).catch(()=>{
					this.accountLists = []
				})
            },
            _getAgentForImportCard(){
                getAgentForImportCard().then((res) => {
                    this.agentLists = res.map((value,index,arr)=>{
                        return {value:value.id,label:value.agentThreeName}
                    })
                    this.agentLists.unshift({value:'',label:'请选择代理商'})
                }).catch(()=>{
					this.agentLists = []
				})
            },
            agentSetMealForImportCard(){
                let params = {}
                params.accountId = this.cardImportForm.accountList
                params.accountList = this.cardImportForm.accountList
                params.agentId = this.cardImportForm.agentList
                params.agentList = this.cardImportForm.agentList
                params.isRealName = 2
                params.isAgentRecharge = 2
                params.importNoLoopCard = 1
                if(this.cardImportForm.accountList && this.cardImportForm.agentList){
                    this._gridAgentSetMealForImportCard(params)
                }else if(this.cardImportForm.accountList){
                    this._gridSetMealForImportCard(params)
                }
            },
            _gridAgentSetMealForImportCard(params){
                gridAgentSetMealForImportCard(params).then((res) => {
                    if(res.date.length!=0){
                        this.packageLists = res.date.map((value,index,arr)=>{
                            return {'value':value.setMealId,'label':value.name}
                        })
                    }else{
                        this.packageLists = []
                    }
                    this.packageLists.unshift({value:'',label:'请选择套餐'})
                    if(res.giftMealList.length!=0){
                        this.givePageLists = res.giftMealList.map((value,index,arr)=>{
                            return {'value':value.id,'label':value.name}
                        })
                    }else{
                        this.givePageLists = []
                    }
                })
            },
            _gridSetMealForImportCard(params){
                gridSetMealForImportCard(params).then((res)=>{
                    console.log(res)
                    this.packageLists = [{label:'请选择套餐',value:''}]
                    if(res.data.length!=0){
                        res.data.forEach((item)=>{
                            if(item.type != 11){
                                this.packageLists.push({label:item.name,value:item.id})
                            }
                        })
                    }
                    this.givePageLists = [{label:'请选择套餐',value:''}]
                    if(res.giftMealList.length!=0){
                        res.giftMealList.forEach((item)=>{
                            this.givePageLists.push({value:item.id,label:item.name}) 
                        })
                    }
                })
            }
        },
        computed:{
        	...mapGetters(
            	['isCaptal','currentRole','agentTree',"currentAgentId",'isGuangyao']
        	),
    	},
    }

</script>

