<template>
  <div class="withdrawalReviewList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">提现审核列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="合伙人">
          <el-select
            v-model="formInline.partnerId"
            placeholder="合伙人"
            clearable
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formInline.status" placeholder="状态" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="合伙人名称"> </el-table-column>
        <el-table-column prop="monthMoney" label="月交易金额">
        </el-table-column>
        <el-table-column prop="monthReward" label="月奖励金额">
        </el-table-column>
        <el-table-column prop="payMoney" label="打款金额"> </el-table-column>

        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span>{{ getStatus(scope.row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderStatus" label="订单审核状态">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.orderStatus == 0"
              size="mini"
              class="themed-button"
              type="primary"
              @click="handleCheck(scope.row)"
              >去审核</el-button
            >
            <span v-else-if="scope.row.orderStatus == 1">已审核</span>
          </template>
          <!--  0 待审核 1已审核 -->
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间"> </el-table-column>
        <el-table-column prop="endTime" label="结束时间"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="createDate" label="创建时间"> </el-table-column>
        <el-table-column prop="checkTime" label="打款时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 0"
              size="mini"
              class="themed-button"
              type="primary"
              @click="review(scope.row)"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="提现审核"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
    >
      <el-form :model="accessForm">
        <el-form-item label="审核通过金额" prop="payMoney">
          <el-input v-model="accessForm.payMoney"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="accessForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="审核状态" prop="status">
          <el-select v-model="accessForm.status" clearable>
            <el-option label="审核通过" value="1"></el-option>
            <el-option label="审核不通过" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import {
  partnerRewardMonthList,
  partnerList,
  rewardAuth,
} from "@/api/partner.js";

export default {
  name: "withdrawalReviewList",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        status: "",
        startTime: "",
        endTime: "",
        partnerId: "",
      },
      tabledata: [],
      formLabelWidth: "200px",
      statusList: [
        { id: 0, value: "待审核" },
        { id: 1, value: "审核通过" },
        { id: 2, value: "审核不通过" },
      ],
      groupList: [],
      value1: "",
      dialogFormVisible: false,
      accessForm: {
        payMoney: "",
        status: "",
        remark: "",
        id: "",
      },
      curMoney: "",
    };
  },
  created() {
    this.getPartnerList();
    this.getPartnerRewardMonthList();
  },
  watch: {
    value1(value) {
      //console.log(value)
      this.formInline.startTime = value == null ? "" : value[0];
      this.formInline.endTime = value == null ? "" : value[1];
    },
  },
  methods: {
    //使用vuex 存储 传参；
    handleCheck(row) {
      let params = {
        startTime: row.startTime,
        endTime: row.endTime,
        ifPartnerId: row.partnerId,
        status: row.orderStatus,
      };
      this.$router.push({
        path: "/partnerManagement/orderReviewList/" + JSON.stringify(params),
      });
    },
    review(row) {
      if (row.orderStatus == 0) {
        //存在订单待审核
        this.$alert("存在未审核的订单，请先审核订单", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });

        return;
      }
      this.accessForm.id = row.id;
      this.curMoney = row.monthReward;
      this.dialogFormVisible = true;
    },
    sureSubmit() {
        let w =/^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/;   
      if (!w.test(this.accessForm.payMoney)) {
         this.$alert("审核金额只能为数字且保留两位小数且不能为0", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (
        !this.accessForm.payMoney ||
        this.accessForm.payMoney > this.curMoney
      ) {
        this.$alert("请填写审核金额且不能超过提现金额", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      rewardAuth(this.accessForm).then((res) => {
        this.$message({
          type: "success",
          message: "提交成功",
        });
        this.dialogFormVisible = false;
        this.getPartnerRewardMonthList();
      });
    },
    getPartnerList() {
      let params = {
        pageSize: 100000,
        pageNo: 1,
      };

      partnerList(params).then((res) => {
        this.groupList = res.rows;
      });
    },

    getStatus(value) {
      let str = "";
      if (value == 0) {
        str = "待审核";
      } else if (value == 1) {
        str = "审核通过";
      } else if (value == 2) {
        str = "审核不通过";
      }
      return str;
    },
    rebatePayment(row) {
      this.form.id = row.id;
      this.leftPay = row.leftPay;
      this.form.method = "";
      this.form.money = "";
      this.form.remark = "";

      this.dialogFormVisible = true;
    },
    paymentRecord(id) {
      this.dialogTableVisible = true;
      this.currentId = id;
    },
    getPartnerRewardMonthList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };

      Object.assign(params, this.formInline);

      partnerRewardMonthList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPartnerRewardMonthList();
    },
    onPageChange(page) {
      this.page = page;
      this.getPartnerRewardMonthList();
    },
    searchData() {
      this.page = 1;
      this.getPartnerRewardMonthList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
.withdrawalReviewList .el-date-editor.el-input {
  width: 100% !important;
}
.withdrawalReviewList .el-form-item__label {
  vertical-align: top;
}
</style>

