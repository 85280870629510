<template>
	<div class="rebatePay fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">返利付款</span>
			<el-form :inline="true" :model="formInline" class="right themed-form search-form"
				style="height: auto;line-height: 40px;">
				<el-form-item label="开始时间:">
					<el-date-picker v-model="formInline.startDate" type="date" style="width:100%" >
					</el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker v-model="formInline.endDate" type="date" style="width:100%">
					</el-date-picker>
				</el-form-item>


				<el-form-item label="状态:">
					<el-select v-model="formInline.status" filterable >
						<el-option v-for="item in options"  :label="item.name" :value="item.id" :key='item.index'>
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="代理商:">
					<el-select v-model="formInline.agentId" filterable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="item in options1" :label="item.name" :value="item.id" :key="item.index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input v-model="formInline.rebateNo" placeholder="输入提现编号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="formInline.authRemark" placeholder="输入审核备注"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询
					</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="exportExcel" class="themed-button" icon="el-icon-caret-left">
						导出excel</el-button>
				</el-form-item>
				<iframe name="hidden_down_iframe" id="hidden_down_iframe" style="display: none;" src=""></iframe>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="rebateNo" label="提现编号" min-width='150' show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="agentName" label="代理商" min-width='120' show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="realName" label="收款人" min-width='120' show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="applyMoney" label="系统提现金额" min-width='120'>
				</el-table-column>
				<el-table-column prop="money" label="审核通过金额" min-width='120'>
				</el-table-column>

				<el-table-column prop="rebatedMoney" label="已返利金额" min-width='120'>
				</el-table-column>

				<el-table-column prop="leftRebateMoney" label="剩余返利金额" min-width='120'>
				</el-table-column>

				<el-table-column prop="payType" label="支付状态" min-width='120'>
					<template v-slot:default="scope">
						{{statusMap[scope.row.status]}}
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间" min-width='150' show-overflow-tooltip>
				</el-table-column>
					<el-table-column prop="remark" label="客户备注" min-width='150' show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="authRemark" label="审核备注" min-width='150' show-overflow-tooltip>
				</el-table-column>

				<el-table-column label="操作" min-width='150'>
					<template slot-scope="scope">
						<el-button  type="info" class="themed-button" @click="openDetail(scope.row.rebateNo)">支付记录</el-button>
						<el-button v-if="scope.row.status!='2'" type="primary" class="themed-button" @click="openPay(scope.row.id)">付款</el-button>
					</template>
            	</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize"
				style="text-align: left;margin-top: 20px;" background layout="total,sizes,prev, pager, next,jumper"
				:total="total" @size-change="handleSizeChange" @current-change="onPageChange">
			</el-pagination>
		</div>
		<el-dialog :title="title" :visible.sync="dialogVisible" v-if="dialogVisible" :width='dialogWidth'>
			<rebate-detail :rebateNo='rebateNo' v-if="item == 1"> </rebate-detail>
			<pay-ensure :payId="itemId" v-if="item == 2" @close="dialogVisible=false" @reload="reload"></pay-ensure>
		</el-dialog>
		<el-dialog title="手机验证" :visible.sync="dialogCheckVisible" width="600" :destroy-on-close='true'>
            <el-form :model="checkForm"  ref="checkForm">
				<el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
                	<el-input :disabled=true  v-model="checkForm.phone" autocomplete="off"></el-input>
                </el-form-item>

				<el-form-item label="验证码" :label-width="formLabelWidth" prop="smsCode">
                <el-input v-model="checkForm.smsCode" autocomplete="off"></el-input>
				<a href="javascript:void(0);" class="send-code" :class="{disabled:isDisabled}" @click="getMobileCode">{{sendCodeMag}}</a>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureChecked()">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogCheckVisible = false">取 消</el-button>
            </div>
        </el-dialog>
	</div>
</template>

<script>
	import payEnsure from "./payEnsure.vue"
	import rebateDetail from "./rebateDetail.vue"
	import onresize from "@/mixins/resize.js"
	import loading from '@/utils/Loading.js'
	import {
		rebateList,
		agentList,
		payExport,
		hasPhoneCheck,
		sendSmsCode,
		checkSmsCode
	} from "@/api/payManagment.js"
	export default {
		name:'rebatePay',
		mixins: [onresize],
		data() {
			return {
				dialogWidth:'',
				item: "",
				rebateNo: "",
				itemId: "",
				dialogVisible: false,
				options: [
					{
						"id": "",
						"name": "全部"
					},
					{
						"id": "1",
						"name": "未付款"
					},
					{
						"id": "2",
						"name": "已付款"
					},
					{
						"id": "3",
						"name": "异常"
					},
					{
						"id": "4",
						"name": "付款中"
					},
					{
						"id": "5",
						"name": "部分付款"
					}
				],
				options1: [],
				formInline: {
					startDate: "",
					endDate: "",
					status: "",
					agentId: "",
					rebateNo: "",
					authRemark:""
				},
				tabledata: [],
				page: 1,
				pageSize: 15,
				total: 0,
				statusMap: {
					"1": "未付款",
					"2": "已付款",
					"3": "异常",
					"4": "付款中",
					"5": "部分付款"
				},
				payTypeMap: {
					"1": "微信",
					"2": "线下",
					"3": "准备金"
				},
				title: "",
				formLabelWidth:'150px',
				isDisabled:false,
				sendCodeMag:'获取验证码',
				timer:null,
				dialogCheckVisible:false,
				checkForm:{
					phone:"",
					smsCode:""
				},


			}
		},
		components: {
			rebateDetail,
			payEnsure,
		},
		created() {
			this.getRebateList(this.formInline)
			this.getAgentList(this.formInline)
		},
		methods: {
			getRebateList(params) {
				rebateList(params).then(res => {
					this.tabledata = res.rows
					this.total = res.total
					this.page = res.pageNo
					this.pageSize = res.pageSize
				})
			},
			getAgentList(params) {
				agentList(params).then(res => {
					this.options1 = res
				})
			},
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getRebateList(params)
			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getRebateList(params)
			},
			onPageChange(page) {
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getRebateList(params)
			},

			openDetail(rebateNo) {
				this.title = "支付记录"
				this.dialogVisible = true
				this.rebateNo = rebateNo
				this.item = 1
				this.dialogWidth = '80%'
			},
			openPay(id) {
				hasPhoneCheck().then(res=>{
					let isCheck =  res.isCheck
					let phone = res.phone.toString()
					if(isCheck){
	            this.title = "付款"
				this.dialogVisible = true
				this.itemId = id
				this.item = 2
				this.dialogWidth = '50%'
					}else{
                this.dialogCheckVisible = true
				this.checkForm.phone = phone.substr(0,3)+"****"+phone.substr(7)
				this.itemId = id
				this.checkForm.smsCode = ""
					}
				}) 
			},
				getMobileCode(){
				sendSmsCode().then((res)=>{
					this.intervalTime()
				}).catch(()=>{
					this.intervalTime()
				})
				
			},
			intervalTime(){
				let time = 60
				this.isDisabled = true
				this.sendCodeMag =  time + " s 重新发送"
				window.clearInterval(this.timer)
				let that = this
				this.timer = window.setInterval(function () {
					if (time == 0) {
						that.isDisabled = false
						that.sendCodeMag = '获取验证码'
						window.clearInterval(that.timer)
					} else {
						that.sendCodeMag =  time + " s 重新发送"
						time = time - 1
					}
				}, 1000);
			},
            sureChecked(){
				if(this.checkForm.smsCode==""){
                     this.$alert('请输入验证码', '提示', {
							 confirmButtonText:'确定',
							});
					return
				}
			checkSmsCode({smsCode:this.checkForm.smsCode}).then(res=>{
				if(res.system_result_key == 0){
                    this.dialogCheckVisible = false
					this.title = "付款"
				this.dialogVisible = true
				this.item = 2
				this.dialogWidth = '50%'
				}else{
                    this.$message({
							showClose: true,
							message: res.system_result_message_key,
							type: 'error'
						})
				}
			})

			},
			reload() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getRebateList(params)
			},
			exportExcel() {
				/* ../../pay/rebate/export.do?startDate=&endDate=&status=&agentId=&rebateNo= */

				loading.show()
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				payExport(params).then(res => {
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
							confirmButtonText: '确定',
						}).then(() => {
							this.$router.push('/tob/i_export_list')
						});
					} else {
						this.$message({
							showClose: true,
							message: res.system_result_message_key,
							type: 'error'
						});
					}
				})
			}



		}

	}
</script>

<style>
	.rebatePay .el-form-item__label {
		vertical-align: top;
	}
	.send-code.disabled{pointer-events:none}
</style>
