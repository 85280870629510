<template>
  <div class="log-list">
      <div style="max-height:500px;overflow:auto">
        <el-table ref="table"  :data="tableData" style="width:100%">
            <el-table-column label="提交卡数" prop="amount" show-overflow-tooltip></el-table-column>
            <el-table-column label="成功数量" prop="successAmount" show-overflow-tooltip></el-table-column>
            <el-table-column label="失败数量" prop="failAmount" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作人" prop="operator" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="90px">
                <template slot-scope="scope">
                    <a href="javascript:void(0)" @click="importLogDetail(scope.row.id)">查看明细</a>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
    <el-dialog title="明细" :visible.sync="dialogBox" append-to-body>
        <dialog-show :promotionId="id" @close="dialogBox = false"></dialog-show>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import {listExpireLog} from '@/api/stockManagement/stock.js'
import DialogShow from '@/views/stockManagement/stockInCardList/dialogShow'

export default {
    name:'logList',
    data(){
        return {
            id:'',
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogBox:false
        }
    },
    mounted(){
        this._listExpireLog()
    },
    methods:{
        importLogDetail(id){
            this.id = id
            this.dialogBox = true
        },
        handleSizeChange(val){
            this.pageSize = val
            this._listExpireLog()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._listExpireLog()
        },
        _listExpireLog(){
            listExpireLog().then((res)=>{
                console.log(res)
                // let data = res.data
                // this.pageSize = data.pageSize
                // this.currentPage = data.pageNo
                // this.tableData = data.rows
                // this.total = data.total
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.tableData = res.rows
                this.total = res.total
            })
        }
    },
    components:{
        DialogShow
    }
}
</script>

