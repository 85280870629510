<template>
  <div class="fadeInRight submitOrder">
    <div class="title-box clearfix">
      <span class="m-title">提交订单</span>
    </div>
    <div class="content-box confirm-order">
      <div class="confirm_order_receiver margin-bottom20">
        <div class="margin-bottom20">收货人信息</div>
        <div v-if="receiverList.length > 0">
          <div class="margin-bottom20">
            <el-input
              v-model="searchStr"
              class="search-input ml10"
              placeholder="请输入收件人姓名、联系电话、地址"
            ></el-input>
            <el-button 
              @click="search"
              class="themed-button search-button"
              type="primary"
              icon="el-icon-search"
            ></el-button>
          </div>

          <div>
            <div class="receiverList multiple">
              <el-button
                v-for="(item, index) in  receiverOneList"
                :key="index"
                :class="[num == index ? 'type_tips' : '']"
                @click="changeIndex(index,item.id)"
              >
                <div class="buttonName">
                  {{ item.name }}
                </div>
                <div class="buttonPhone">
                  <span class="left">{{ item.phone }}</span>
                  <el-button type="text" @click="modifyAddress(item)"
                    >修改</el-button
                  >
                  <!-- <button type="text" @click="modifyAddress(item)">修改</button> -->
                </div>
                <div class="buttonAddress">
                  {{ item.province+item.city+item.area+item.detail }}
                </div>
                <span class="badge-s"> </span
              ></el-button>
              <el-button @click="addNewAddress">
                <i
                  class="el-icon-plus margin-bottom20"
                  style="font-size: 200%; color: rgb(196, 196, 196)"
                ></i>
                <div>新增收货地址</div>
              </el-button>
              <span class="more" @click="getMoreAddress">更多收信人信息</span>
            </div>
          </div>
        </div>
        <div v-else class="addAddress">
          <div class="margin-bottom20">新增地址</div>
          <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="收货人" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入收货人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入联系手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="所在地区" prop="location">
              <!-- <span class="spanProvince" @click="choose">
                {{ location }}
                <i
                  class="el-icon-arrow-down spanProvinceIcon"
                  :class="{ isActive: isactive }"
                ></i>
              </span> -->
<span v-if="form.province" class="address_tip"  @click="choose">
               {{ location }}
            </span>
            <span v-else class="address_tip"  @click="choose">
                请选择省市地区
                <i class="el-icon-arrow-down" ></i>
            </span>
              <div class="divwrap" v-if="show">
                <v-distpicker
                  type="mobile"
                  @province="onchangeprovince"
                  @city="onchangecity"
                  @area="onchangearea"
                ></v-distpicker>
              </div>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input
                v-model="form.detail"
                placeholder="请输入详细地址"
                maxlength="100"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.defaultFlag"
                >设为默认收货地址</el-checkbox
              >
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit">确认</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-divider></el-divider>
        <div class="inforSub">
          <div class="margin-bottom20">信息报备</div>
          <div style="display: flex">
            <el-upload
             accept="image/jpg,image/jpeg,image/png,image/svg"
              :action="actionUrl"
              list-type="picture-card"
              :on-remove="handleRemove"
              :on-success="successUpload"
              :with-credentials='true'
               :limit="3"
              :on-exceed="handleExceed"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <el-input
              type="textarea"
              rows="6"
              placeholder="请描述设备使用场景."
              v-model="subForm.attachText"
              class="infoTextarea"
              maxlength="200"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="margin-bottom20" style="display:none">
          <label style="margin-right: 45px">送货单</label>
          <el-radio v-model="ifDelivery" label="1">需要</el-radio>
          <el-radio v-model="ifDelivery" label="2">不需要</el-radio>
        </div>

        <div class="consignmenList">
          <div class="margin-bottom20">
            送货清单
            <span class="right" style="cursor: pointer" @click="goBackShopping"
              >返回购物车</span
            >
          </div>
          <div class="margin-bottom20">
            <el-table :data="tableData" border fit stripe style="width: 100%">
              <el-table-column label="商品详情" prop="goodDetail" width="330">
                <template slot-scope="props">
                  <div class="item-1-2 width-70">
                    <div class="goods-attribute">
                      <p class="margin-bottom10">
                        <span>{{ props.row.productName }}</span
                        >&nbsp;<span>{{  _getOperatorName(props.row.accountType, w_operator_list) }}</span
                        >&nbsp;<span>{{props.row.mealTypeStr}}套餐</span>
                      </p>
                      <p class="color-666 f-size-l9 left-width">
                        <span>材料：</span><span>{{ props.row.materialStr }}</span
                        ><br />
                        <span>规格：</span><span>{{ props.row.specStr }}</span>
                      </p>
                      <div class="color-666 f-size-l9 attr-rules left-width">
                        <div>
                          <span>网络模式：</span
                          ><span>{{ props.row.netModelStr }}</span>
                        </div>
                      </div>
                      <div
                        class="color-666 f-size-l9 attr-rules"
                        style="
                          float: right;
                          margin-top: -69px;
                          margin-bottom: 10px;
                        "
                      >
                        <div class="right-width">
                          <span>卡片功能：</span
                          ><span>{{ props.row.cardFuncStr=="null"|| props.row.cardFuncStr.trim()===""?"无":props.row.cardFuncStr }}</span>
                          
                        </div>
                        <!-- <div class="right-width">
                      <span>网络增值服务：</span><span>{{item.netIncreaseSer }}</span>
                    </div> -->
                        <div class="right-width">
                          <span>套餐分类：</span
                          ><span>{{ props.row.mealName }}</span>
                        </div>
                        <div class="right-width" style="display:none">
                          <span>已打开自动续费功能：</span
                          >{{ props.row.autoRenew == 1?"是":"否" }}
                        </div>
                        <el-tooltip class="item" effect="dark" placement="top">
                          <div
                            slot="content"
                            v-html="totalInfo(props.row)"
                          ></div>
                          <el-button type="text">显示全部</el-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="套餐价格" prop="packagePrice">
                <template slot-scope="props">
                  {{"¥ " + props.row.mealPrice }}
                </template>
              </el-table-column>
              <el-table-column label="总周期" prop="subscribeCount">
                 <template slot-scope="props">
                  {{props.row.subscribeCount+" 月" }}
                </template>
                
              </el-table-column>
              <el-table-column label="沉默期" prop="silentCount">
                  <template slot-scope="props">
                  {{props.row.silentCount+" 月" }}
                </template>
              </el-table-column>
              <el-table-column label="单张卡费" prop="singleFee">
                <template slot-scope="props">
                  {{"¥ " + props.row.singleFee }}
                </template>
              </el-table-column>
              <el-table-column label="卡片数量" prop="cardCount"> </el-table-column>
            
            <el-table-column label="小计" prop="totalFee">
                <template slot-scope="props">
                  {{"¥" + parseFloat(props.row.totalFee).toFixed(2)}}
                </template> </el-table-column>
            </el-table>
          </div>
          <div class="orderMark">
            <span class="mark">订单备注</span>
            <el-input
              type="textarea"
              rows="4"
              placeholder="选填：对本次交易的说明（建议填写和平台协商一致的内容）."
              v-model="subForm.remark"
              style="width: 50% !important"
              maxlength="200"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="sendtype">
          <div>
            <span>配送方式</span>
            <div class="sendList">
              <el-button
                v-for="(item, index) in sendtypeList"
                :key="index"
                :class="[sendNum == index ? 'type_tips' : '']"
                @click="changeIndexOne(index,item)"
              >
                <div class="margin-bottom20">
                  {{ item.name }}
                </div>
                <div>
                  <span>{{ item.deliveryTimeLeast +"-"+item.deliveryTimeMost+"天" }}, </span>
                  <span>{{ item.fee.toFixed(2)+"元" }}</span>
                </div>
              </el-button>
            </div>
            <div class="sendListRemark">
              <span>当前运费： {{ currentMoney }}</span>
              <div>
                因疫情原因，国内部分区域物流受影响，快递时效可能无法保障，造成不便，敬请谅解
              </div>
            </div>
          </div>
          <div>
            <span>附加功能</span>
            <div class="sendList">
              <el-checkbox v-model="subForm.autoRenew"
                >为购买的卡开启自动续费</el-checkbox
              >
              <el-button type="text" @click="knowMore">了解更多</el-button>
            </div>
          </div>
          <div>
            <div class="margin-bottom20">用卡须知</div>
            <el-radio v-model="isknow" label="1"
              ><div class="isknowContent">
                <p>
                  移动联通电信三大运营商联合开展物联网卡高危地区管控：云南，新疆，西藏，缅北，海南，广西，福建。如果有需要在高危区域使用，请先联系客户经理。
                </p>
                <p>
                  请规范使用物联网卡，一旦在高危地区使用，可能导致停卡甚至销卡的情况。我司不负责退款退卡，所有责任自行承担。
                </p>
              </div></el-radio
            >
          </div>
        </div>
        <el-divider></el-divider>
        <div class="right">
          <div>
            <span>总卡数</span>
            <span class="right">{{ totalCardNum }}张</span>
          </div>
          <div>
            <span>总计</span>
            <span class="right">￥{{parseFloat(totalFee) .toFixed(2) }}</span>
          </div>
          <div>
            <span>运费</span>
            <span class="right">￥{{parseFloat(currentMoney) .toFixed(2) }}</span>
          </div>
          <div>
            <span>应付金额</span>
            <span class="right">￥{{ (parseFloat(totalFee)+parseFloat(currentMoney)).toFixed(2) }}</span>
          </div>
          <div>根据运营商规则,物联网卡一经售出不予退换</div>
        </div>
        <br style="clear: both" />
        <el-divider></el-divider>
        <div class="right">
          <el-radio v-model="isRead" label="1">
            已阅读并同意
            <span class="link" @click="showCardprocel"
              >《物联网卡入网使用协议》</span
            ></el-radio
          >
          <div class="sendButton">
            <el-button @click="submitOrder" class="themed-button" type="primary"
              >提交订单</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="全部地址" :visible.sync="dialogTableVisible">
      <el-table :data="receiverList">
        <el-table-column
          property="name"
          label="收货人"
          width="150"
        ></el-table-column>
        <el-table-column
          property="phone"
          label="手机号"
          width="200"
        ></el-table-column>
        <el-table-column  label="所在地区">
          <template slot-scope="scope">
            <span>{{scope.row.province+"/"+scope.row.city}}</span>
             </template>
        </el-table-column>
        <el-table-column property="detail" label="详细地址"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="FormTitle" :visible.sync="dialogFormVisible">
    
        <el-form ref="formAdd" :model="formAdd" label-width="80px" :rules="rules">
            <el-form-item label="收货人" prop="name">
              <el-input
                v-model="formAdd.name"
                placeholder="请输入收货人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="formAdd.phone"
                placeholder="请输入联系手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="所在地区" prop="location">
              <!-- <span class="spanProvince" @click="choose">
                {{ locationOne }}
                <i
                  class="el-icon-arrow-down spanProvinceIcon"
                  :class="{ isActive: isactive }"
                ></i>
              </span> -->
               <span v-if="formAdd.province" class="address_tip"  @click="choose">
               {{ locationOne }}
            </span>
            <span v-else class="address_tip"  @click="choose">
                请选择省市地区
                <i class="el-icon-arrow-down" ></i>
            </span>

              <div class="divwrap" v-if="show">
                <v-distpicker
                  type="mobile"
                  @province="onchangeprovinceOne"
                  @city="onchangecityOne"
                  @area="onchangeareaOne"
                ></v-distpicker>
              </div>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input
                v-model="formAdd.detail"
                placeholder="请输入详细地址"
                maxlength="100"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item v-if="editNoshow">
              <el-checkbox v-model="formAdd.defaultFlag"
                >设为默认收货地址</el-checkbox
              >
            </el-form-item>
          </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureAdd">确 定</el-button>
      </div>
    </el-dialog>
     <el-dialog :visible.sync="dialogQA">
        <div v-html="htmlContent"></div>
      </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import { addressList,addAddress ,updateAddress,expressList,addOrder,directBuy} from "@/api/onlinePurchase.js";
import  {host} from "@/api/request.js"
import dictGroup from "@/mixins/dictGroup.js";
import {
  getQAList,
} from "@/api/login.js";
export default {
  name: "submitOrder",
  mixins: [onresize,dictGroup],
  data() {
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入正确11位手机号"));
      } else {
        let r = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!r.test(value)) {
          callback(new Error("请输入正确11位手机号"));
        }
        callback();
      }
    };
    return {
      formAdd: {
        id:"",
        name: "",
        city: "",
        phone: "",
        area: "",
        detail: "",
        province: "",
        defaultFlag: false, //新建地址是否默认
      },
       actionUrl:host+"commonUploadFile/upload.do?",
      FormTitle: "新增收货人",
      formAddchecked: false,
      dialogFormVisible: false,
      dialogTableVisible: false,
      tableData: [
      ],
      receiverList: [
      ],
      receiverOneList:[],
      sendtypeList: [
      ], //选择地址，价格动态变化
      currentMoney: 0,
      show: false,
      isactive: false,

      form: {
        name: "",
        city: "",
        phone: "",
        area: "",
        detail: "",
        province: "",
        defaultFlag: false, //新建地址是否默认
      },
      isknow: "2", //用户须知
      isRead: "2", //
      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        name: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        detail: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        // location: [
        //   { required: true, message: "请选择省市地址", trigger: "change" },
        // ],
      },
      searchStr: "", //搜索收件人地址字段
      num: 0,
      ifDelivery: "2",//送货单是否需要
      // inforSubContent: "",//订单备注
      sendNum: 0,
      locationOne:"",
      location:"",
      subForm:{
        addressId:"",
        attachText:"",
        attachImage:[],
        expressId:"",
        autoRenew:true,
        buyCarIds:[],
        buyCarItems:"",
        remark:""
      },
      totalCardNum:0,
      totalFee:0,
      dialogQA:false,
      htmlContent:"",
      QA1:"",
      QA2:"",
      editNoshow:true,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.subForm.buyCarIds = this.$route.query.id;
      console.log(this.subForm.buyCarIds)
      let data = JSON.parse(this.subForm.buyCarIds)
      console.log(data,501)
    // this.subForm.buyCarIds= data.map(item=>{
    //   return parseInt(item)
    //  })
     console.log(this.subForm.buyCarIds)
    }
    if(this.$route.query.detail){
    this.tableData = this.$route.query.detail
    console.log(this.tableData)
     this.totalCardNum = 0
     this.totalFee = 0
      this.tableData.forEach(item=>{
        this.totalCardNum += item.cardCount
        this.totalFee += item.totalFee
      })
    }
    if(this.$route.query.buyCarItems){
       this.subForm.buyCarItems =JSON.stringify([this.$route.query.buyCarItems]) 
       console.log(this.subForm.buyCarItems)
    }
    if(this.$route.query.otherDetail){
       this.tableData = [this.$route.query.otherDetail]
       
    console.log(this.tableData)
     this.totalCardNum = 0
     this.totalFee = 0
      this.tableData.forEach(item=>{
        this.totalCardNum += item.cardCount
        this.totalFee += item.totalFee
      })
    }
    
    this.getAddressList();
    this.getExpressList();
    this.getQAList()
  },
  watch: {
    show: function (newv, old) {
      if (newv) {
        this.isactive = true;
      } else {
        this.isactive = false;
      }
    },
  },
  computed: {
    
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
     beforeAvatarUpload(file) {

      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      console.log(img)
    const suffix = img === 'jpg'
    const suffix2 = img === 'png'
    const suffix3 = img === 'jpeg'
    const suffix4 = img === 'svg'
    const isLt1M = file.size / 1024 / 1024 < 2;
    if (!suffix && !suffix2 && !suffix3&& !suffix4) {
        this.$message.error("只能上传图片！");
        return false
    }
    // 可以限制图片的大小
    if (!isLt1M) {
        this.$message.error('上传图片大小不能超过 2MB!');
    }
    return suffix || suffix2 || suffix3 ||suffix4 


      },
     getQAList() {
      getQAList({ question: "提交订单自动续卡" }).then((res) => {
        console.log(res.rows[0].answer);
        this.QA1 =res.rows[0].answer;
      });
      getQAList({ question: "提交订单物联网卡入网使用协议" }).then((res) => {
        console.log(res.rows[0].answer);
        this.QA2 =res.rows[0].answer;
      });
    },
    getExpressList(){
      expressList().then(res=>{
       this.sendtypeList = res 
       this.currentMoney= res[0].fee
       this.subForm.expressId =res[0].id
      })
    },
     handleRemove(file, fileList) {
      this.subForm.attachImage=  this.subForm.attachImage.filter(item=>item!=file.response.url)
    },
   
    successUpload(response){
    this.subForm.attachImage.push(response.url)
    },
    getAddressList() {
      addressList({}).then((res) => {
        this.receiverList = res;
        let receiverList1 = res;
        receiverList1.map((item,index)=>{
         if(item.defaultFlag){
             receiverList1.unshift(receiverList1.splice(index,1)[0])
         }
        })
        console.log(receiverList1)
       this.receiverOneList = receiverList1.slice(0, 3)
       this.subForm.addressId =  this.receiverOneList[0].id 
       console.log(this.receiverOneList[0])
      });
    },
    showCardprocel(e) {
    //  e.preventDefault();
    console.log()
      this.dialogQA = true;
      this.htmlContent = this.QA2;
    }, //用卡协议
    knowMore() {
      //弹框显示；
     this.dialogQA=true
     this.htmlContent = this.QA1
    },
    submitOrder() {
      //提交订单
      if(this.subForm.addressId==""){
        this.$alert("请选择收货地址", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if(this.isknow!="1"){
        this.$alert("请勾选用卡须知", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if(this.isRead!="1"){
        this.$alert("请阅读并同意入网协议", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      this.subForm.attachImage = JSON.stringify( this.subForm.attachImage)
      // this.subForm.buyCarIds = JSON.stringify( this.subForm.buyCarIds)
      this.subForm.buyCarIds = this.subForm.buyCarIds
      this.subForm.autoRenew =this.subForm.autoRenew?1:0
      if(this.$route.query.id){
           addOrder(this.subForm).then(res=>{
          
         this.$router.push({path:'/shopping/payOrder',query:{orderNum:res.orderNum,money:(parseFloat(this.totalFee)+parseFloat(this.currentMoney)).toFixed(2)}})
      })
      }else{
           directBuy(this.subForm).then(res=>{
          
         this.$router.push({path:'/shopping/payOrder',query:{orderNum:res.orderNum,money:(parseFloat(this.totalFee)+parseFloat(this.currentMoney)).toFixed(2)}})
      })
      }
     

    },
    totalInfo(item) {
     let str = "";
    //  console.log(item.cardFuncStr.trim()==="")
    //  console.log(typeof(item.cardFuncStr))
    //  console.log(item.cardFuncStr=="null"||!item.cardFuncStr)
      let  cardFuncStr= item.cardFuncStr=="null"||item.cardFuncStr.trim()===""?"无":item.cardFuncStr
      let autoRenew=item.autoRenew == 1?"是":"否"
      str +=
        "卡片功能: " +
       cardFuncStr  +
        "<br>" +
        "套餐分类: " +
        item.mealName 
        // "<br>" +
        // "已打开自动续费功能：" + autoRenew  ;
      return str;
      return str;
    },
    goBackShopping() {
      //返回购物车
        this.$router.push({path:'/purchaseCard/shoppingCart'})

    },
    changeIndex(index,id) {
      //
      if (this.num != index) {
        this.num = index;
        this.subForm.addressId = id
      } else {
        this.num = 1000000;
        this.subForm.addressId = ""
      }
    },
    changeIndexOne(index,item) {
      // if (this.sendNum != index) {
      //   this.sendNum = index;
      //   this.subForm.expressId = item.id
      // } else {
      //   this.sendNum = 0;
      //   this.subForm.expressId = 
      // }
      this.sendNum=index
      this.subForm.expressId = item.id
      this.currentMoney = item.fee
    },
    choose() {
      this.show = !this.show;
    },
    onchangeprovince(a) {
      this.form.province = a.value;
    },
    onchangecity(a) {
      this.form.city = a.value;
    },
    onchangearea(a) {
      this.form.area = a.value;
      console.log(this.form.area)
      this.show = false;
      this.location =
        this.form.province + "/" + this.form.city + "/" + this.form.area;
    },
     onchangeprovinceOne(a) {
      this.formAdd.province = a.value;
    },
    onchangecityOne(a) {
      this.formAdd.city = a.value;
    },
    onchangeareaOne(a) {
      this.formAdd.area = a.value;
      this.show = false;
      this.locationOne = 
        this.formAdd.province + "/" + this.formAdd.city + "/" + this.formAdd.area;
    },
    onSubmit() {
      //新增收货人信息
      this.$refs["form"].validate((validate) => {
        if (validate) {
          if(this.location.trim()==""){
             this.$alert("请选择地区信息", "提示", {
              confirmButtonText: "确定",
            });
          }
          console.log(this.form)
          addAddress(this.form).then(res=>{
          	this.$message({
          		type: 'success',
          		message:'新增成功'
          	});
          	this.getAddressList()//
          })
        }
      });
    },
    sureAdd() {
      if(this.formAdd.id){
this.$refs["formAdd"].validate((validate) => {
        if (validate) {
          if(this.locationOne.trim()==""){
             this.$alert("请选择地区信息", "提示", {
              confirmButtonText: "确定",
            });
          }
          console.log(this.formAdd)
          let  params= {
             id: this.formAdd.id ,
             name:this.formAdd.name,
             city:this.formAdd.city,
             phone:this.formAdd.phone,
             area:this.formAdd.area,
            detail:this.formAdd.detail,
            province:this.formAdd.province
          }
          updateAddress(params).then(res=>{
          	this.$message({
          		type: 'success',
          		message:'编辑成功'
          	});
            this.dialogFormVisible= false
          	this.getAddressList()//
          })
        }
      })
      }else{
  this.$refs["formAdd"].validate((validate) => {
        if (validate) {
          if(this.locationOne.trim()==""){
             this.$alert("请选择地区信息", "提示", {
              confirmButtonText: "确定",
            });
          }
          console.log(this.formAdd)
          addAddress(this.formAdd).then(res=>{
          	this.$message({
          		type: 'success',
          		message:'新增成功'
          	});
            this.dialogFormVisible= false
          	this.getAddressList()//
          })
        }
      })
      }
     
    },
    search() {
      //搜索收件人地址
      let receiverSeachList=[]
     receiverSeachList =    this.receiverList.filter(item=>{
       return  item.name.includes(this.searchStr)||item.phone.includes(this.searchStr)||(item.province+item.city+item.area+item.detail).includes(this.searchStr)
     })
      this.receiverOneList=receiverSeachList.slice(0, 3)
    },
    modifyAddress(item) {
      this.editNoshow = false
      this.dialogFormVisible = true;
      this.FormTitle = "编辑地址";
      this.formAdd.id = item.id
      this.formAdd.name=item.name
      this.formAdd.city= item.city
      this.formAdd.phone=item.phone
      this.formAdd.area=item.area
      this.formAdd.detail=item.detail
      this.formAdd.province=item.province
      // this.formAdd.defaultFlag = item.defaultFlag
      this.locationOne = item.province+"/"+ item.city+"/"+item.area
    },
    addNewAddress() {
      //新增收货地址
      this.editNoshow = true
      this.dialogFormVisible = true;
      this.FormTitle = "新增地址";
      this.formAdd.name=""
      this.formAdd.city=""
      this.formAdd.phone=""
      this.formAdd.area=""
      this.formAdd.detail=""
      this.formAdd.province=""
      this.formAdd.defaultFlag=false
     this.locationOne=""
    },
    getMoreAddress() {
      this.dialogTableVisible = true;
    },
  },
};
</script>

<style>
.submitOrder .sendButton {
  margin: 20px 5px;
  text-align: right;
}
.submitOrder .link {
  color: #ff6b01;
}
.sendtype .isknowContent {
  position: relative;
  left: 24px;
  top: -22px;
  line-height: 20px;
}
.sendtype .sendList {
  display: inline-block;
  margin-left: 40px;
}
.sendListRemark {
  margin-left: 95px;
  margin-top: 15px;
  line-height: 25px;
}
.consignmenList .orderMark .el-textarea__innerrea {
  height: 90px;
  line-height: 90px;
}
.consignmenList .orderMark .mark {
  margin-right: 40px;
   height: 90px;
  line-height: 90px;
}
.inforSub .el-upload--picture-card {
  margin-top: 5px;
  line-height: 140px;
  height: 140px;
}
.inforSub .el-textarea__inner {
  font-size: 14px;
  box-sizing: border-box;
}
.inforSub .infoTextarea {
  width: 350px;
  margin-left: 20px;
  margin-top: 5px;
}
.receiverList .more {
  margin-left: 10px;
  margin-top: 92px;
  color: #20a0ff;
}
.submitOrder .buttonAddress {
  text-indent: 20px;
  width: 150px;
  white-space: pre-wrap;
}
.submitOrder .receiverList {
  display: flex;
  margin-left: 10px;
}
.submitOrder .buttonName {
  text-align: left;
  margin-bottom: 10px;
}
.submitOrder .buttonPhone {
  height: 30px;
  line-height: 30px;
  text-align: right;
  margin-bottom: 10px;
}
.submitOrder .addAddress{
  width:60%
}
/* .submitOrder .addAddress input {
  width: 300px;
} */
.submitOrder .margin-bottom20 {
  margin-bottom: 20px;
}
.submitOrder .search-input {
  display: inline-block;
  width: 300px;
}
.submitOrder .search-button {
  padding: 8px 10px !important;
}
.submitOrder .search-button i {
  font-size: 14px !important;
}

/* .submitOrder .spanProvince {
  border: 1px solid #dcdfe6;
  display: inline-block;
  padding: 0px 14px;
  width: 200px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
}
.submitOrder .spanProvinceIcon {
  float: right;
  margin-top: 8px;
}
.submitOrder .spanProvinceIcon.isActive {
  transform: rotate(180deg);
}
.submitOrder .divwrap {
  width: 251px;
  height: 400px;
  overflow-y: auto;
  position: absolute;
  z-index: 2;
}

.submitOrder .divwrap >>> .distpicker-address-wrapper {
  color: #999;
}

.submitOrder .divwrap >>> .address-header ul li {
  flex-grow: 1;

  text-align: center;
}

.submitOrder .divwrap >>> .address-header .active {
  color: #fff;

  border-bottom: #666 solid 8px;
}

.submitOrder .divwrap >>> .address-container .active {
  color: #000;
} */
.divwrap .distpicker-address-wrapper .address-container ul li{width: 120px; float: left; box-sizing: border-box;cursor: pointer;}
.divwrap .distpicker-address-wrapper .address-container ul li.active{color:#20a0ff;}
.divwrap .distpicker-address-wrapper .address-header ul li{color:#20a0ff;}
.divwrap .distpicker-address-wrapper .address-container ul{max-height: 300px;}
.submitOrder .address_tip{color:#20a0ff; cursor: pointer;}
.receiverList.multiple .badge-s {
  position: absolute;
  width: 20px;
  height: 30px;
  right: -7px;
  bottom: -14px;
  transform: rotate(45deg);
  background: #ccc;
}
.receiverList.multiple .badge-s:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 7px;
  right: 13px;
  bottom: 12px;
  transform: rotate(-4deg);
  border-color: transparent #fff #fff transparent;
  border-style: solid;
  border-width: 1px;
}
.receiverList.multiple .type_tips .badge-s {
  background: #20a0ff;
}
.submitOrder .el-button.type_tips:focus,
.submitOrder .el-button.type_tips:hover {
  color: #20a0ff !important;
  border-color: #20a0ff !important;
}
.submitOrder .type_tips {
  color: #20a0ff !important;
  border-color: #20a0ff !important;
}
.submitOrder .el-button:focus,
.submitOrder .el-button:hover {
  color: #333;
  border: 1px solid #c4c4c4;
  background-color: #fff;
}
.receiverList .el-button {
  position: relative;
  overflow: hidden;
}
 .submitOrder  .goods-attribute  .right-width{   
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;}
</style>
