<template>
  <div class="materialsList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">材质规格列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
       	<el-form-item label="材质：" :label-width="formLabelWidth" >
				<el-select v-model="formInline.material" >
              <el-option label="请选择" value=""></el-option>
              <el-option v-for="(item,index) in materialList" :label="item.name" :value="item.value" :key="index"></el-option>
				</el-select>
			</el-form-item>
      	<el-form-item label="规格：" :label-width="formLabelWidth" >
				<el-select v-model="formInline.spec" >
            <el-option label="请选择" value=""></el-option>
                    <el-option v-for="(item,index) in specList" :label="item.name" :value="item.value" :key="index"></el-option>
			
				</el-select>
			</el-form-item>
       <el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search"  class="themed-button">搜索</el-button>
				</el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="add"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="materialStr" label="材料名" min-width="180">
        </el-table-column>
        <el-table-column prop="specStr" label="规格名" min-width="180">
        </el-table-column>
        <el-table-column    prop="fee" label="费用" min-width="120">
        
        </el-table-column>
        

        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
           <el-button
              type="primary"
              class="themed-button"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button style="display:none"
              type="danger"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
         <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
     
           <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
  <el-form :model="addForm" :rules="rules" ref="addForm">
              <el-form-item label="材质:" prop="material">
                <el-select v-model="addForm.material" >
                     <el-option label="请选择材质" value=""></el-option>
                    <el-option v-for="(item,index) in materialList" :label="item.name" :value="item.value" :key="index"></el-option>
                </el-select>
            </el-form-item>
              <el-form-item label="规格:" prop="spec">
                <el-select v-model="addForm.spec" >
                     <el-option label="请选择规格" value=""></el-option>
                    <el-option v-for="(item,index) in specList" :label="item.name" :value="item.value" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="单价:"  prop="fee"  class="materialsFee">
				<el-input v-model="addForm.fee" autocomplete="off"></el-input>
			</el-form-item>
            
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="sureAdd('addForm')">确 定</el-button>
  </div>
</el-dialog>
    </div>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import { materialsList,deleteMaterialsList,allChoiceList,addMaterialsList,getFeepageList,getFeeupdate } from "@/api/goodList.js";
export default {
  name: "materialsList",
  mixins: [resize],
  data() {
     var validateFee = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入费用"));
      } else {
         if( !/^\d+([.]\d{0,2})?$/.test(value)){
           callback(new Error("卡费用只能输入数字且最多两位小数！"));
            }
         if (value>100) {
          callback(new Error("卡费用不能大于100"));
        }
        callback();
      }
    };
    return {
     formInline:{
      spec:"",
      material:"",
     },
      tableData: [],
      materialList:[],
      specList:[],
      dialogFormVisible:false,
      addForm:{
        id:"",
        material:"",
        spec:"",
        fee:"",
      },
      rules: {
					material: [{
						required: true,
						message: '材料不能为空',
						trigger: 'change'
					}],
					
           fee: [{ validator: validateFee, trigger: "blur" }],
           spec: [{
						required: true,
						message: '规格不能为空',
						trigger: 'change'
					}]
				},
         page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth:"100px",
      dialogTitle:"",
    };
  },
  created() {
    allChoiceList().then(res=>{
       if(JSON.stringify(res.materialMap) == "{}"){

       }else{
        this.materialList= []
        for(let i in res.materialMap){
             this.materialList.push({value: i,name:res.materialMap[i]})  
        }
       console.log(this.materialList)

    }
      if(JSON.stringify(res.specMap) == "{}"){

       }else{
        this.specList= []
        for(let i in res.specMap){
             this.specList.push({value: i,name:res.specMap[i]})  
        }
        console.log(this.specList)
       }
        })
    this.getMaterialsList();
  },
  methods: {
     searchData() {
      this.page = 1;
      this.getMaterialsList();
    },
      onPageChange(page) {
      this.page = page;
      this.getMaterialsList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getMaterialsList();
    },
   
    getMaterialsList() {
        let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      if(this.formInline.spec!==""){
       params.spec=this.formInline.spec
      }
      if(this.formInline.material!==""){
       params.material=this.formInline.material
      }
     
      getFeepageList(params).then((res) => {
        this.tableData = res.rows
        this.total = res.count;
      });
    },
   edit(row){
  this.dialogTitle = "编辑材质规格组合"
   this.dialogFormVisible= true
       this.$nextTick(() => {
        this.$refs['addForm'].clearValidate();
    })
     this.addForm.id=row.id
        this.addForm.material=row.material+""
        this.addForm.spec=row.spec+""
        this.addForm.fee=row.fee
   },
    add() {
      this.dialogTitle = "新增材质规格组合"
      this.dialogFormVisible= true
       this.$nextTick(() => {
        this.$refs['addForm'].clearValidate();
    })
        this.addForm.id=""
        this.addForm.material=""
        this.addForm.spec=""
        this.addForm.fee=""
        

    },
    sureAdd(formName){
this.$refs[formName].validate((valid) => {
					if (valid) {
          if(this.addForm.id){
          getFeeupdate(this.addForm).then(responseResult => {
							if (responseResult.slide_msg_key == 1) {
								this.$message({
									showClose: true,
									message: responseResult.slide_msg_message_key,
									type: 'warning',
								})
							}
							this.getMaterialsList();
							this.dialogFormVisible = false
						})
          }else{
           addMaterialsList(this.addForm).then(responseResult => {
							if (responseResult.slide_msg_key == 1) {
								this.$message({
									showClose: true,
									message: responseResult.slide_msg_message_key,
									type: 'warning',
								})
							}
							this.getMaterialsList();
							this.dialogFormVisible = false
						})
          }
				
					} else {
						return false;
					}
				});
    },
     delect(id){
 this.$confirm('是否确认删除该材料规格组合么?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteMaterialsList({ id }).then((res) => {
         this.getMaterialsList();
          this.$message({
            type: "success",
            message: "删除成功",
          })
        })
      })
    },
  },
};
</script>

<style>
.materialsFee .el-form-item__content{width: 206px;
    display: inline-block;}
.materialsList .el-form-item__label {vertical-align: top;}
</style>
