<template>

<div class="modifyFlow" label-width="120px">
    <el-form :model="form" :rules='rules' ref="form" label-width="130px">
        <el-form-item label="增减真实已用流量" prop="flowSize">
            <el-input v-model="form.flowSize" type="number"></el-input>
        </el-form-item>
				<el-form-item>
					<p class="red">正数为增加流量，负数为减少流量，单位为MB</p>
				</el-form-item>
        <el-form-item label="备注">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item>
             <el-button type="primary" class="themed-button" @click="sure('form')">确定</el-button>
            <el-button type="primary" class="themed-button" @click="close">关闭</el-button>
        </el-form-item>
    </el-form>
</div>

</template>

<script type="text/ecmascript-6">

import { modifyFlow } from "@/api/cardManagement/cardBillDetail.js";
import { messageBox } from "@/utils/common.js";

export default {
    name:'changeFlow',
    props:['curInfor','cardId'],
    data(){
        return {
            form:{
                flowSize:0,
                remark:''
            },
            rules:{
                flowSize:[
                    {require:true,massage:'请输入真实流量',trigger:'blur'}
                ]
            }
        }
    },
    methods:{
        sure(formName){
          /*  this.$refs[formName].validate((valid) => {
							console.log(valid)
                if (valid) {
									
                    this._modifyFlow()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            }); */
						const flowSize = String(this.form.flowSize)
				if(flowSize.trim() ==""||!flowSize){
					messageBox(this,"请输入真实流量")
					return false
				}	
				this._modifyFlow()
            
        },
        close(){
           this.$emit('close') 
        },
        _modifyFlow(){
            let params ={}
            params.cardId = this.curInfor.id
            params.iccidOrPhone = this.curInfor.iccidMark
            params.setMealId = this.curInfor.setMealId
            params.isChild = this.curInfor.isChild
            params.codeChild = this.curInfor.codeChild
						params.flowSize = this.form.flowSize
						params.remark = this.form.remark
            modifyFlow(params).then((responseResult)=>{
							if(responseResult.system_result_message_key && responseResult.system_result_message_key!=="") {
									this.$alert(responseResult.system_result_message_key, '提示', {
									          confirmButtonText: '确定',
									        })
							    return
							}
							if(responseResult.slide_msg_key==1){
								this.$message(responseResult.slide_msg_message_key)
							}
							this.$emit('close')
            })
        }
    }
}
</script>
<style>
.modifyFlow .el-input {  width: auto; }
.modifyFlow .red{color:#f00;width:300px}
</style>