<template>
	<div class="balanceInquiry fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">余额查询</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;" id="baseForm">
				 <el-form-item label="代理商名称：">
				    <el-input v-model="form.agentName" placeholder="代理商名称"></el-input>
				  </el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" class="themed-button" icon="el-icon-search">搜索查询</el-button>
				</el-form-item>
				<el-form-item v-if="currentRole==0">
					<el-button type="primary" @click="reachToDetail" class="themed-button" >充值日志详情</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="agentName" label="代理商名称">
				</el-table-column>
				<el-table-column prop="rechargeMoney" label="充值金额">
				</el-table-column>
				<el-table-column prop="balanceMoney" label="剩余金额">
				</el-table-column>
				<el-table-column prop="consumeMoney" label="消费金额">
				</el-table-column>
				<el-table-column prop="createUser" label="创建人">
				</el-table-column>
				<el-table-column prop="createDatetime" label="创建时间">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<a href='#' @click='rechargeDetails(scope.row.agentId)' style="color: #337ab7;">充值详情</a>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
		
		
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
	import onresize from "@/mixins/resize.js"
	import {
		balanceInquiryList,
	} from "@/api/agentManagement.js"
	export default {
				name:'balanceInquiry',
				mixins: [onresize],
				data(){
					return {
						form :{
							agentName:"",
						},
						page:1,
						pageSize:15,
						total:0,
						tabledata:[],
					}
				},
				created(){
					this.getBalanceInquiryList(this.form)
				},
				methods:{
					getBalanceInquiryList(params){
						balanceInquiryList(params).then((res)=>{
							this.page = res.pageNo
							this.pageSize = res.pageSize
							this.total = res.total
							this.tabledata = res.rows
						})
					},
					searchData(){
						let params = {
							pageSize:this.pageSize,
							pageNo:this.page,
							agentName:this.form.agentName
						}
						this.getBalanceInquiryList(params)
					},
					onPageChange(page){
						this.page = page
						let params = {
							pageSize:this.pageSize,
							pageNo:this.page,
							agentName:this.form.agentName
						}
						this.getBalanceInquiryList(params)
					},
					handleSizeChange(size){
						this.pageSize = size
						let params = {
							pageSize:this.pageSize,
							pageNo:this.page,
							agentName:this.form.agentName
						}
						this.getBalanceInquiryList(params)
					},
					rechargeDetails(id){
						let  selections = [{"agentId":id,}];
						 //console.log(selections)
										this.$router.replace({
											path:"/agentManagement/rechageDetail",
											query:selections[0]
										})
					},
					reachToDetail(){
										this.$router.replace({
											path:"/agentManagement/allRechageDetail",
										
										})
					}
					
				},
				 computed:{
        	     ...mapGetters(
            	['currentRole']
        	),
    	},

	}
	
</script>

<style>
		.balanceInquiry  .el-form-item__label {vertical-align: top;}
</style>
