<template>
	<div class="fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">卡号信息</span>
			<el-form inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
				<el-form-item>
					<el-select v-model="searchForm.status" placeholder="卡状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="待激活" value="1"></el-option>
						<el-option label="正常" value="2"></el-option>
						<el-option label="断网" value="3"></el-option>
						<el-option label="停机" value="4"></el-option>
						<el-option label="异常" value="5"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchForm.mealType" placeholder="套餐状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="已超流量" value="1"></el-option>
						<el-option label="已超语音" value="2"></el-option>
						<el-option label="已超语音和流量" value="3"></el-option>
						<el-option label="未超套餐" value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchForm.accountId" placeholder="运营商">
						<el-option label="全部" value=""></el-option>
						<el-option label="中国移动" value="7"></el-option>
						<el-option label="中国联通" value="4"></el-option>
						<el-option label="中国电信" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input  placeholder="套餐" v-model="searchForm.setMealName"></el-input>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="激活时间" v-model="searchForm.servCreateDate" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<el-input  placeholder="ICCID起始号段" v-model="searchForm.iccidStart"></el-input>
				</el-form-item>
				<el-form-item>
					<span>-</span>
				</el-form-item>
				<el-form-item>
					<el-input  placeholder="ICCID结束起始号段" v-model="searchForm.iccidEnd"></el-input>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="计费结束时间" v-model="searchForm.feeTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<el-input  placeholder="ICCID号" v-model="searchForm.iccidMark"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input placeholder="IMSI" v-model="searchForm.imsi"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table ref='table'  :data="tableData" stripe style="width: 100%;" :max-height="maxTableHeight">
				<el-table-column show-overflow-tooltip width="200" label="ICCID卡号">
					<template slot-scope="scope">
						<a href='javascript:;' @click="getDetail(scope.row)" style="color: #337ab7;">{{scope.row.iccidMark}}</a>
					</template>
				</el-table-column>
				<el-table-column width="140" prop="imsi" label="IMSI">
				</el-table-column>
				<el-table-column width="140" prop="phone" label="MSISDN">
				</el-table-column>
				<el-table-column width="100"  label="运营商名称">
					<template slot-scope="scope">
						<span v-if="scope.row.category == 7 ">中国移动</span>
						<span v-else-if="scope.row.category == 4">中国联通</span>
						<span v-else-if="scope.row.category == 1">中国电信</span>
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip sortable prop="setMealName" label="套餐" width="200">
				</el-table-column>
				<el-table-column sortable width="140" prop="createTime" label="发卡日期">
				</el-table-column>
				<el-table-column sortable width="140" prop="servCreateDate" label="激活时间">
				</el-table-column>
				<el-table-column sortable width="140" prop="feeTime" label="计费结束时间">
				</el-table-column>
				<el-table-column prop="totalBytesCnt" label="当月流量MB">
				</el-table-column>
				<el-table-column prop="monthflowSur" label="超出流量MB">
				</el-table-column>
				<el-table-column label="卡状态">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 1">待激活</span>
						<span v-else-if="scope.row.status == 2">正常</span>
						<span v-else-if="scope.row.status == 3">断网</span>
						<span v-else-if="scope.row.status == 4">停机</span>
						<span v-else-if="scope.row.status == 4">异常</span>
					</template>
				</el-table-column>
				<el-table-column label="卡片类型">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">包月</span>
						<span v-else-if="scope.row.type == 2">包年</span>
						
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
		</div>
		<el-dialog title="短信详情" :visible.sync="dialogVisible" width="500px">
			<el-form label-width="120px">
				<el-form-item label="批量号" prop="name">
					<el-input v-model="ruleForm.name" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="任务名称" prop="phone">
					<el-input v-model="ruleForm.phone" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="接入号" prop="phone">
					<el-input v-model="ruleForm.phone" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="接入号" prop="phone">
					<el-input v-model="ruleForm.phone" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="接入号" prop="phone">
					<el-input v-model="ruleForm.phone" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="接入号" prop="phone">
					<el-input v-model="ruleForm.phone" style="width: 240px;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="primary" @click="dialogVisible = false">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import {cardList,exportCardInfo} from '@/api/card.js'
	import resize from '@/mixins/resize.js'
	import Utils from '@/utils/utils.js'
	import loading from '@/utils/Loading.js'
	export default{
		name:'cardInfo',
		mixins:[resize],
		data(){
			return {
				page:1,
				pageSize:15,
				total:0,
				tableData:[],
				dialogVisible:false,
				searchForm:{
					iccidMark:'',
					phone:''
				},
				ruleForm:{}
			}
		},
		computed: {
			searchItem(){
				return this.$store.getters.searchItem
			},
			
		},
		watch:{
			searchItem:function(newv,old){
				if (newv.type == "ICCID") {
					this.searchForm.iccidMark = newv.searchStr
					this.searchForm.phone = ""
				}else{
					this.searchForm.iccidMark = ""
					this.searchForm.phone = newv.searchStr
				}
				this.getCardList()
			},
			
		},
		created() {
			if (!!this.$route.query.type && !!this.$route.query.searchStr) {
				if (this.$route.query.type == 'ICCID') {
					this.searchForm.iccidMark = this.$route.query.searchStr
				}else{
					this.searchForm.phone = this.$route.query.searchStr
				}
			}
			this.getCardList()
			
		},
		mounted() {
			
		},
		methods:{
			getDetail(rowData){
				this.$router.push({path:'/card/detail',query:{id:rowData.id,iccidMark:rowData.iccidMark}})
			},
			exportExcel(){
				loading.show()
				exportCardInfo({}).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
			},
			onSearch(){
				this.page = 1
				this.getCardList()
			},
			getCardList(){
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
				}
				Object.assign(this.searchForm,params)
				if (!!this.$route.query.id) {
					Object.assign(this.searchForm,{userId:this.$route.query.id})
				}
				cardList(this.searchForm).then(res=>{
					this.total = res.count
					this.tableData = res.rows
				})
			},
			onPageChange(page){
				this.page = page
				this.getCardList()
			},
			handleSizeChange(size){
				this.pageSize = size
				this.getCardList()
			}
		}
	}
</script>

<style>
</style>
