<template>
    <div class="remark-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">计费组叠加包</span>
            <el-form v-model="form" inline class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item>
                    <el-input v-model="form.recordNo" placeholder="记录编号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
					<el-select v-model="form.agentId" placeholder="代理商">
						<el-option label="全部" value=""></el-option>
                        <el-option v-for="item in agentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item> 
                
            </el-form>
        </div>

        <div class="content-box" v-loading='tableLoading'>
            <el-table ref="table" :data="tableData" stripe :max-height="maxTableHeight">
                <el-table-column label="计费组名称" prop="groupName" min-width="150px" show-overflow-tooltip></el-table-column>
                <el-table-column label="计费组编号" prop="groupCode" min-width="150px" show-overflow-tooltip></el-table-column>
                <el-table-column label="续费记录编号" prop="recordNo" min-width="150px" show-overflow-tooltip></el-table-column>
                <el-table-column label="代理商" prop="agentName" min-width="200px" show-overflow-tooltip></el-table-column>
                <el-table-column label="叠加包名称" width="150px" show-overflow-tooltip>
                    流量叠加包
                </el-table-column>
                <el-table-column label="开始时间" prop="startDate" min-width="150px" show-overflow-tooltip></el-table-column>
                <el-table-column label="结束时间" prop="expireDate" min-width="150px" show-overflow-tooltip></el-table-column>
                <el-table-column label="创建时间" prop="createDate" min-width="150px" show-overflow-tooltip></el-table-column>
                <el-table-column label="容量(MB)" prop="flowSize" min-width="150px" show-overflow-tooltip></el-table-column>
                <!-- <el-table-column label="操作" width="150px" show-overflow-tooltip>
                    <template  slot-scope="scope">
                        <el-button type="danger" class="themed-button" @click="delect(scope.row)">删除</el-button>
                    </template>
                </el-table-column> -->

                
            </el-table>
            <el-pagination :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {poolRefuelingList, poolRefuelingDelete} from "@/api/renew.js"
import {getAgentList} from "@/api/account.js";

import dictGroup from "@/mixins/dictGroup.js"
export default {
    name:'flowPoolList',
    mixins:[resize,dictGroup],
    data(){
        return {
            form:{
                iccid:'',
                phone:''
            },
            agentList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            tableLoading:false
        }
    },
    mounted(){
        this._getAgentList()
        this._poolRefuelingList()
    },
    methods: {
        delect(row){
            this.$modal.confirm("确定要删除【"+row.groupName+"】这条记录吗?").then(res =>{
                poolRefuelingDelete({id:row.id}).then(res=>{
                    this.$modal.alertSuccess("删除成功")
                    this._poolRefuelingList()
                })
            })

        },
        onPageChange(page){
			this.page = page
			this._poolRefuelingList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._poolRefuelingList()
        },
        onSearch(){
            this._poolRefuelingList()
        },
        _getAgentList(){
            getAgentList().then((result) => {
                this.agentList = result;
            })
        },
        
        _poolRefuelingList(){
            this.tableLoading = true
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize
            }
            poolRefuelingList(Object.assign(params,this.form)).then((res)=>{
                this.tableLoading = false
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
       
    }
}
</script>
<style>
.iccid-width .el-input__inner{width:180px !important;}
</style>