<template>
    <div class="special-agent-order fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">代理商订单*</span>
            <el-form inline ref="agentOrderForm" v-model="agentOrderData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作起始日期" v-model="agentOrderData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作结束日期" v-model="agentOrderData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                 <el-form-item label="代理商名称:">
                <el-select v-model="agentOrderData.agentTreeIdNew"    filterable clearable>
					<el-option v-for="item in agentThreeLists" :key="item.index" :label="item.agentName" :value="item.agentId">
					</el-option>
				</el-select>
                </el-form-item>
                <!-- <el-form-item>
                    <el-input v-model="agentOrderData.agentName" placeholder="代理商名称"></el-input>
                </el-form-item> -->
                <el-form-item>
                    <el-input v-model="agentOrderData.iccidMark" placeholder="卡号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="agentOrderData.shortIccid" placeholder="短号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item label="运营商">
                    <search-select :optionsMetaAll="agentOperators" v-model="agentOrderData.accountId"></search-select>
                </el-form-item>
                <el-form-item label="支付状态">
                    <search-select :optionsMetaAll="payOperators" v-model="agentOrderData.status"></search-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="agentOrderData.orderNumber" placeholder="订单号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="agentOrderData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item label="充值类型">
                    <search-select :optionsMetaAll="rechargeOperators" v-model="agentOrderData.isFirstRecharge"></search-select>
                </el-form-item>
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="退款起始日期" v-model="agentOrderData.refundStartTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="退款结束日期" v-model="agentOrderData.refundEndTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                 <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>
        
        <div class="content-box">
            <el-table
                ref="table"
                border
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
               >
                <el-table-column
                    label="订单号"
                    min-width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.orderNumber">{{scope.row.orderNumber}}</span>
                        <span v-else-if="!scope.row.orderNumber">-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="iccidMark"
                    label="iccid"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="130">
                </el-table-column>
                <el-table-column
                    prop="phone"
                    label="接入号"
                    min-width="130">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="agentTreeName"
                    label="代理商名称"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="mealName"
                    label="套餐名"
                    min-width="150">
                </el-table-column>
                 <el-table-column
                    :show-overflow-tooltip="true"
                    prop="createUser"
                    label="创建人"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    label="次月生效"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.effectType==1">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="mealTypeStr"
                    label="套餐类型"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="orderCount"
                    label="订购数量"
                    min-width="78">
                </el-table-column>
                <el-table-column
                    prop="oldMoney"
                    label="订单金额"
                    min-width="78">
                </el-table-column>
                <el-table-column
                    prop="realMoney"
                    label="实际支付金额(元)"
                    min-width="140">
                </el-table-column>
                   <el-table-column
                    prop="applyMoney"
                    label="返现"
                    min-width="78">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="accountName"
                    label="运营商"
                    min-width="120">
                </el-table-column>
            
                <el-table-column
                    label="卡过期时间"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.expireDate">{{scope.row.expireDate.substring(0,10)}}</span>
                        <span v-else-if="!scope.row.expireDate">{{scope.row.expireDate}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createDatetime"
                    label="下单时间"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    label="支付状态"
                    min-width="78">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">已支付</span>
                        <span v-else>已退款</span>
                    </template>
                </el-table-column>
                 <el-table-column
                    label="充值类型"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isFirstRecharge==1">首次充值</span>
                        <span v-else-if="scope.row.isFirstRecharge==2">续费充值</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                 <el-table-column
                    label="产品类型"
                    min-width="120">
                    <template slot-scope="scope">
                       
                        <span>{{_getProductType(scope.row.productType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="业务类型"
                    min-width="120">
                    <template slot-scope="scope">
                      
                         <span>{{_getToType(scope.row.toType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="无佣金标识" prop="noProfitFlag" >
                <template slot-scope="scope">
                   <span v-if="scope.row.noProfitFlag==1" style="color:#f00">无佣金</span>
                   <span v-else-if="scope.row.noProfitFlag==0" style="color:#f00"></span>
                   <span v-else style="color:#f00"></span>
                </template>
            </el-table-column>
                <el-table-column
                    label="退款时间"
                    min-width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.refundDatetime">{{scope.row.refundDatetime}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    label="退款人"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.refundUser">{{scope.row.refundUser}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="退款金额"
                    min-width="78">
                    <template slot-scope="scope">
                        <span v-if="scope.row.refundMoney">{{scope.row.refundMoney}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    label="退款理由"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.refundReason">{{scope.row.refundReason}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                 <el-table-column
                    label="操作"
                    min-width="150">
                    <template slot-scope="scope">
                        <el-button v-if="loginType==0&&scope.row.status==1" size="mini" class="themed-button" type="primary" @click="handleRefund(scope.row.id,scope.row.realMoney)">退款</el-button>
                        <el-button v-if="(loginType==0||loginAgentType==4&&agentTree==1)&&scope.row.status==1" size="mini" class="themed-button" type="primary" @click="handleRefund(scope.row.id,scope.row.realMoney)">退款</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="退款" :visible.sync="dialogRefundVisible" :destroy-on-close='true'>
        <el-form :model="refundForm">
            <el-form-item label="金额" prop="reMoney">
            <el-input v-model="refundForm.reMoney" autocomplete="off" placeholder="不填写默认全额退款" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="退款理由" prop="refundReason">
            <el-input type="textarea" v-model="refundForm.refundReason"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button class="themed-button" type="info" @click="dialogRefundVisible = false">取 消</el-button>
            <el-button class="themed-button" type="primary" @click="sureRefund" v-preventReClick='1000' :disabled="isDisable">确 定</el-button>
        </div>
        </el-dialog>
    </div>
</template>
 <script>
 import {agentListForUser } from '@/api/cardManagement/cardBill.js'
 import SearchSelect from '@/components/SearchSelect.vue'
import {agentOperatorList,agentOrderList,loginType,refundMoney,exportAgentOrder} from '@/api/order.js'
import {getAgentType} from "@/api/agentManagement.js"
import resize from '@/mixins/resize.js'
import dictGroup from '@/mixins/dictGroup.js'
 export default {
     name:'specialAgentOrder',
     components:{SearchSelect},
     mixins:[resize,dictGroup],
     data(){
         return {
             agentOrderData:{
                 startTime:'',
                 endTime:'',
                 agentName:'',
                 agentTreeIdNew:"",
                 accountId:'',
                 status:'',
                 orderNumber:'',
                 phone:'',
                 isFirstRecharge:'',
                 refundStartTime:'',
                 refundEndTime:'',
                 shortIccid:"",
             },
             agentThreeLists:[],
             agentOperators:[{label:"全部",value:""}],
             payOperators:[
                 {label:'全部',value:''},
                 {label:'已支付',value:'1'},
                 {label:'已退款',value:'2'}
             ],
             rechargeOperators:[
                 {label:'全部',value:''},
                 {label:'首次充值',value:'1'},
                 {label:'续费充值',value:'2'}
             ],
             tableData:[],
             page:1,
             pageSize:15,
             total:0,
             loginType:'',
             loginAgentType:'',
             agentTree:'',
             dialogRefundVisible:false,
             refundID:'',
             refundForm:{
                 reMoney:'',
                 refundReason:''
             },
             maxRemoney:"",
             isDisable:false,
         }
     },
     created(){
         this.getAgentListForUser()
         this.getAgentOperatorList()
         this.getAgentOrderList()
         this.getLoginType()
         this.getAgentType()
          try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
     },
     methods:{
        getAgentListForUser(){
             agentListForUser().then(res=>{
                this.agentThreeLists = res
             })
            },
         getLoginType(){
             loginType().then(res=>{
                 this.loginType = res.loginUser.type
             })
         },
         getAgentType(){
				getAgentType().then(res=>{
					this.loginAgentType = res.agentType
					this.agentTree = res.agentTree
				})
		},
         getAgentOperatorList(){
             agentOperatorList().then(res=>{
                 let arrList =[{label:"全部",value:""}]
                 res.forEach(element => {
                     arrList.push({label:element.accountName,value:element.id})
                 })               
                 this.agentOperators=arrList
             })
         },
         getAgentOrderList(){
             let params = {
                 pageSize:this.pageSize,
                 pageNo:this.page
             }
             Object.assign(this.agentOrderData,params)
             agentOrderList(this.agentOrderData).then(res=>{
                 this.tableData=res.rows
                 this.total=res.count
             })
         },
        onPageChange(page){
			this.page = page
			this.getAgentOrderList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getAgentOrderList()
        },
         onSearch(){
             this.page = 1
            this.getAgentOrderList()
         },
         exportExcel(){
             exportAgentOrder(this.agentOrderData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
         },
        handleRefund(id,maxRemoney){
            this.dialogRefundVisible = true
            //2022.6.1需求默认显示退全款
             this.refundForm.reMoney = maxRemoney
             this.refundForm.refundReason = ''
             this.refundID = id
             this.maxRemoney = maxRemoney
        },
       
        sureRefund(){
             if(!!this.refundForm.reMoney && parseFloat(this.refundForm.reMoney) > parseFloat(this.maxRemoney)){
                this.$alert('退款金额不大于实际支付金额', '提示', {
								confirmButtonText: '确定',
					    });
				return
            }
            this.isDisable = true
            let params={
                id:this.refundID
            }
            Object.assign(this.refundForm,params)
            refundMoney(this.refundForm).then(res=>{
                this.isDisable = false
                if(res.system_result_key == 0){
                    this.$message({
						    showClose: true,
						    message: res.system_result_message_key||'操作成功',
						    type: 'success'
                        })
                    this.dialogRefundVisible = false
                    this.refundForm={}
                    this.getAgentOrderList()
                }else{

                }
            }).catch(()=>{
                this.isDisable = false
            })

        }
     }
 }
 </script>
 <style>
    .special-agent-order .el-form-item__label{
        vertical-align: top;
    }
</style>
