<template>
    <div class="agent-apply-check table-hook fadeInRight">
        <div class="title-box">
            <span class="m-title">代理商提现审批</span>
            <el-form inline ref="agentApplyCheckForm" v-model="agentApplyCheckData" class="hook right themed-form search-form">
                <el-form-item label="代理商名称:">
                    <search-select :optionsMetaAll="agentList" v-model="agentApplyCheckData.agentId"></search-select>
                </el-form-item>
                <el-form-item label="结算状态:">
                    <el-select v-model="agentApplyCheckData.status">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待结算" value="1"></el-option>
                        <el-option label="未审核" value="2"></el-option>
                        <el-option label="审核通过" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间:">
					<el-date-picker placeholder="请选择时间" v-model="agentApplyCheckData.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%;"/>
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker placeholder="请选择时间" v-model="agentApplyCheckData.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%;"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>
        <div class="content-box">
            <el-table
                ref='table'
                border
                stripe
                :data="tableData"
                tooltip-effect="dark"
                :max-height="maxTableHeight">

            <el-table-column label="提现编号" prop="rebateNo" width="180px" show-overflow-tooltip></el-table-column>
            <el-table-column label="代理商名称" prop="agentTreeName" show-overflow-tooltip></el-table-column>
            <el-table-column label="所属总代理商" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="开始时间" prop="startTime"></el-table-column>
            <el-table-column label="结束时间" prop="endTime"></el-table-column>
            <el-table-column label="订单总数" prop="orderNum"></el-table-column>
            <el-table-column label="订单总额" prop="orderAmount" show-overflow-tooltip></el-table-column>
            <el-table-column label="提现金额" prop="applyMoney" show-overflow-tooltip></el-table-column>
            <el-table-column label="返佣类型" prop="rebateType" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.rebateType == 1">普通</span>
                    <span v-if="scope.row.rebateType == 2">秒返</span>
                </template>
            </el-table-column>
            <el-table-column label="支付类型" prop="payType" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.payType == 1">微信</span>
                    <span v-if="scope.row.payType == 2">线下</span>
                    <span v-if="scope.row.payType == 3">准备金</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 1">待结算</span>
                    <span v-if="scope.row.status == 2">未审核</span>
                    <span v-if="scope.row.status == 3">审核完成</span>
                    <span v-if="scope.row.status == 4">失败</span>
                </template>
            </el-table-column>
             <el-table-column
                    show-overflow-tooltip
                    prop="createDateTime"
                    label="创建时间"
                    min-width="150">
                </el-table-column>
            <el-table-column label="操作" min-width="400px">
                <template slot-scope="scope">
                    <el-button size="mini" class="themed-button" type="primary" @click="orderDetails(scope.row.id)">订单详情</el-button>
                    <el-button v-if="scope.row.status == 2" size="mini" class="themed-button" type="primary" @click="updateM(scope.row.id)">审核确认</el-button>
                    <el-button v-if="scope.row.status == 2" size="mini" class="themed-button" type="primary" @click="checkReject(scope.row.id)">审核不通过</el-button>
                </template>
            </el-table-column>
            </el-table>
            <el-pagination 
            :current-page.sync="pageNo"  
            :page-sizes="[5,10,15,50,100,1000]" 
            :page-size="pageSize"
			 background 
             layout="total,sizes,prev, pager, next,jumper" 
             :total="total" 
             @size-change="handleSizeChange" 
             @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="审核不通过" :visible.sync="dialogRejectVisible" :destroy-on-close='true'>
        <el-form :model="rejectForm">
            <el-form-item label="不通过理由" prop="reason">
            <el-input type="textarea" v-model="rejectForm.reason"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button class="themed-button" type="info" @click="dialogRejectVisible = false">取 消</el-button>
            <el-button class="themed-button" type="primary" @click="handleReject">确 定</el-button>
        </div>
        </el-dialog>

    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {orderGetAgents,GetApplyCheckList,GentApplyCheck,exportExcelAsync} from '@/api/agentApplyCheck.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'
export default {
    name:"agentApplyCheck",
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return{
            agentApplyCheckData:{
                agentId:'',
                status:'',
                startTime:'',
                endTime:''
            },
            agentList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogRejectVisible:false,
            rejectForm:{
                applyId:'',
                reason:'',
                type:'2'
            }
        }
    },
    mounted(){
        this._orderGetAgents()
        this._GetApplyCheckList()
    },
    methods:{
    exportExcel(){
         let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                type:1
            }
           
            exportExcelAsync(Object.assign(params,this.agentApplyCheckData)).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        orderDetails(applyId){
            if(applyId){
                this.$router.push({
                    path: "/orderManagement/agentApplyCheckDetail",
                    query: {applyId},
                })
            }
        },
        updateM(id){
            confirmBox(this,'确定同意审批吗？','消息提示',()=>{
                this.confirmUpdata(id)
            })
        },
        confirmUpdata(applyId){
            GentApplyCheck({applyId:applyId,type:'1'}).then((res)=>{
                messageBox(this,'提交成功。')
                this._GetApplyCheckList()
            })
        },
        checkReject(id){
            this.dialogRejectVisible = true
            this.rejectForm.applyId = id
            this.rejectForm.reason = ''
        },
        handleReject(){
            if(!this.rejectForm.reason){
                this.$alert('请填写理由', '提示', {
					confirmButtonText: '确定',
				});
					return
            }
            GentApplyCheck(this.rejectForm).then((res)=>{
                messageBox(this,'提交成功。')
                this.dialogRejectVisible = false
                this._GetApplyCheckList()
            })
        },
        onSearch(){
            this._GetApplyCheckList()
        },
        onPageChange(page){
			this.pageNo = page
			this._GetApplyCheckList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._GetApplyCheckList()
        },
        _orderGetAgents(){
            this.agentList = []
            this.agentList.push({
                label:'全部',
                value:''
            });
            this.agentList.push({
                label:'当前账号',
                value:'-1'
            });
            orderGetAgents({status:0}).then((res)=>{
                if(res.length>0){
                    res.forEach((item)=>{
                        this.agentList.push({
                            label:item.name,
                            value:item.id
                            })
                    })
                }
            })
        },
        _GetApplyCheckList(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                type:1
            }
            GetApplyCheckList(Object.assign(params,this.agentApplyCheckData)).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.tableData = res.rows
                this.total = res.total

            })
        }
    }
}
</script>
<style>
    .agent-apply-check{position:relative;}
    .agent-apply-check .el-table .cell{height:23px;}
</style>