<template>
    <div class="remark-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">备注列表</span>
            <el-form v-model="form" inline class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item label="ICCID:">
                    <el-input v-model="form.iccidMark" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item label="备注内容:">
                    <el-input v-model="form.remark" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item label="运营商:">
                    <el-select filterable v-model="form.accountId">
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="(item,index) in accountList" :key="index" :value="item.id" :label="item.accountName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>                
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
                <el-table-column label="ICCID" prop="iccidMark" width="180px"></el-table-column>
                <el-table-column label="接入号" prop="phone" show-overflow-tooltip></el-table-column>
                <el-table-column label="代理商" prop="agentThreeName" show-overflow-tooltip></el-table-column>
                <el-table-column label="运营商" prop="accountName" show-overflow-tooltip></el-table-column>
                <el-table-column label="备注内容" prop="remark" show-overflow-tooltip></el-table-column>
                <el-table-column label="更新时间" prop="updateTime" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="160px">
                    <template slot-scope="scope">
                        <el-button type="primary" class="themed-button" @click="importUpdate(scope.row)">编辑</el-button>
                        <el-button type="danger" class="themed-button" @click="delect(scope.row.iccidMark)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="编辑" :visible.sync="dialogBox" v-if="dialogBox">
            <el-form  v-model="setForm" label-position="right" label-width="200px" style="max-height:500px;overflow:auto">
                <el-form-item label="ICCID">
                    <el-input readonly v-model="setForm.iccidMark"></el-input>
                </el-form-item>
                <el-form-item label="接入号">
                    <el-input readonly v-model="setForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="代理商">
                    <el-input readonly v-model="setForm.agentThreeName"></el-input>
                </el-form-item>
                <el-form-item label="运营商">
                    <el-input readonly v-model="setForm.accountName"></el-input>
                </el-form-item>
                <el-form-item label="备注内容">
                    <el-input type="textarea" autosize v-model="setForm.remark"></el-input>
                </el-form-item>
                <el-form-item label="更新时间:">
                    <el-input readonly v-model="setForm.updateTime"></el-input>
                </el-form-item>
            </el-form>  
            <div class="align-right">
                <el-button type="primary" class="themed-button" @click="sure">确定</el-button>
                <el-button type="primary" class="themed-button" @click="dialogBox=false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {getAccountList,cardRemarkList,cardRemarkUpdate, cardRemarkDel,cardRemarkExport} from "@/api/cardManagement/cardBillDetail.js";
import { messageBox, confirmBox } from "@/utils/common.js"

export default {
    name:'remarkList',
    mixins:[resize],
    data(){
        return {
            form:{
                iccidMark:'',
                accountId:'',
                remark:''
            },
            accountList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            title:'',
            dialog:'',
            setForm:{
                iccidMark:'',
                phone:'',
                agentTreeName:'',
                accountName:'',
                remark:'',
                updateTime:''
            }
        }
    },
    mounted(){
        this._getAccountList()
        this._cardRemarkList()
    },
    methods: {
        delect(iccidMark){
            let msg = iccidMark + ':  确定删除吗?'
            confirmBox(this,msg,null,()=>{
                this._cardRemarkDel(iccidMark)
            })
            
        },
        importUpdate(data){
            this.setForm.iccidMark = data.iccidMark
            this.setForm.phone = data.phone
            this.setForm.agentThreeName = data.agentThreeName
            this.setForm.accountName = data.accountName
            this.setForm.remark = data.remark
            this.setForm.updateTime = data.updateTime
            this.dialogBox = true
        },
        sure(){
            cardRemarkUpdate(this.setForm).then((res)=>{
                this.dialogBox = false
                messageBox(this,'修改成功')
                this._cardRemarkList()
            })
        },
        onPageChange(page){
			this.page = page
			this._cardRemarkList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._cardRemarkList()
        },
        onSearch(){
            this._cardRemarkList()
        },
        exportExcel(){
            cardRemarkExport(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        _cardRemarkList(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize
            }
            cardRemarkList(Object.assign(params,this.form)).then((res)=>{
                // console.log(res)
                this.tableData = res.rows
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
            })
        },
        _getAccountList(){
            getAccountList().then((res)=>{
                this.accountList = res
            })
        },
        _cardRemarkDel(iccidMark){
            cardRemarkDel({iccidMark}).then((res)=>{
                messageBox(this,'删除成功')
                this._cardRemarkList()
            })
        }
    }
}
</script>