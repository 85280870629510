<template>

<div class="buySameGet table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">买几送几</span>
        <el-form inline class="hook right themed-form search-form">   
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addTicket">新增买送</el-button>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="请输入搜索关键字，多个用空格隔开" v-model="buySendName" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
                </el-input>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="买送名称" prop="buySendName" show-overflow-tooltip></el-table-column>
            <el-table-column label="购买套餐" prop="buyMeal" show-overflow-tooltip></el-table-column>
            <el-table-column label="购买套餐名" prop="buyMealCount" show-overflow-tooltip></el-table-column>
            <el-table-column label="赠送套餐" prop="sendMeal" show-overflow-tooltip></el-table-column>
            <el-table-column label="赠送套餐名" prop="sendMealCount" show-overflow-tooltip></el-table-column>
            <el-table-column label="代理商" prop="filterAgentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="折扣状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 1">启用</span>
                    <span v-if="scope.row.status == 0">未启用</span>
                </template>
            </el-table-column>
            <el-table-column label="生效时间" prop="startTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.startTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="失效时间" prop="endTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.endTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.createTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="160px">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" v-if="scope.row.status == 1"  @click="clickdisable(scope.row.id)">停止</el-button>
                    <el-button type="info" class="themed-button" v-else @click="clickable(scope.row.id)">启用</el-button>
                    <el-button type="danger" class="themed-button"  @click="delFilter(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog title="新增买送" :visible.sync="dialogBox" v-if="dialogBox" width="70%">
        <new-coupons @close="dialogBox=false"  @refresh="refreshData"></new-coupons>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {getBuySendList,updateData,deleteData} from '@/api/eventDiscountManagement/buySameGet.js'

    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'
    import NewCoupons from '@/views/eventDiscountManagement/buySameGet/newCoupons'

    import resize from '@/mixins/resize.js'

    const ERR_OK = 0
    export default {
        name:'buySameGet',
        mixins:[resize],
        data(){
            return {
                buySendName:'',
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
                dialogBox:false,
                id:''
            }
        },
        mounted(){
            this._getBuySendList()
        },
        methods:{
            addTicket(){
                this.dialogBox = true
            },
            clickdisable(id){
                confirmBox(this,'确定要停止折扣么？',null,()=>{
                   this._updateData(id,0)
                })
            },
            clickable(id){
               confirmBox(this,'确定要开启折扣么？',null,()=>{
                   this._updateData(id,1)
               }) 
            },
            delFilter(id){
               confirmBox(this,'确定要删除么？',null,()=>{
                   deleteData({id}).then((res)=>{
                       this._getBuySendList()
                   })
               })  
            },
            searchData(){
                this._getBuySendList()
            },
            refreshData(){
                this.dialogBox = false
                this._getBuySendList()
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._getBuySendList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._getBuySendList()
            },
            formatDatebox(value){
                if (value == null || value == '') {  
                    return '';  
                }  
                var dt;  
                if (value instanceof Date) {  
                    dt = value;  
                } else {  
                    dt = new Date(value);  
                }  
                return  dt.toLocaleString().replace(/\//g, '-')
                // return dt.format("yyyy-MM-dd hh:mm:ss"); 
                // return  dt.toJSON().substr(0, 19).replace('T', ' ')
            },
            _updateData(id,status){
                let params = {id,status}
                updateData(params).then((res)=>{
                    this._getBuySendList()
                })
            },
            _getBuySendList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                params.buySendName = this.buySendName
                getBuySendList(params).then((res)=>{
                    // console.log(res)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        },
        components:{
            SearchSelect,
            // FilterShow,
            NewCoupons
        }
    }
</script>

<style>
.marginR10{margin-right:10px;}
.buySameGet .search-form .el-input .el-input__inner {width:150px}
.buySameGet .el-form-item__content .el-input-group{vertical-align:middle}
</style>
