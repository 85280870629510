<template>
  <transition>
    <div class="cardBillDetail" v-loading="fullscreenLoading">
      <span class="card-icon">
        <i class="el-icon-refresh-right" @click="refresh"></i>

         <el-button type="primary" class="themed-button" @click="refreshLiuliang(true)"  :disabled ="!iccidMark"  v-preventReClick='2000'
          >刷新流量</el-button
        >
      </span>
    
      <div class="group">
        <h1 class="title">基础信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
              <td>ICCID</td>
              <td>{{ iccidMark }}</td>
               <td>接入号</td>
              <td>{{ data.phone }}</td>
                <td>短号</td>
              <td>{{ res.shortIccid }}</td>
               <td>IMEI</td>
              <td> <span v-if="data.imei">{{ data.imei }}</span> 
              <span v-else>-</span></td>
              
             </tr>
             <tr>
               <td>套餐类型</td>
              <td>{{ data.mealTypeStr }}</td>
               <td>过期时间</td>
              <td>{{ data.expireDate }}</td>
                <td>运营商</td>
              <td>{{ data.accountName }}</td>
              <td>网卡状态</td>
               <td>{{data.statusString}}</td>
              
             </tr>
             <tr>
               <td>代理商</td>
                <td>
                <span v-if="data.agentName">{{ data.agentThreeName }}</span>
                <span v-else>未分配</span>
              </td>
              <td>供应商</td>
              <td>{{ accountEntity.supplier }}</td>
              <td>导入时间</td>
              <td>{{ importTime }}</td>
              <td>激活时间</td>
               <td><span v-if="data.servCreateDate"> {{ data.servCreateDate }}</span>  
                 <span v-else>-</span> </td>
              
             </tr>
             <tr>
               <td>套餐生效时间</td>
               <td>{{ res.takeEffectTime }}</td>
              <td>卡类型</td>
               <td>
                <span v-if="data.type == 1">包月</span>
                <span v-else>包年</span>
              </td>
              <td>绑定手机</td>
                <td>
                <span v-if="res.phonebind">{{ res.phonebind }}</span>
                <span v-else>未绑定</span>
              </td>
              <td>流量池</td>
              <td> <span  v-if="accountEntity.flowPool">{{ accountEntity.flowPool }}</span> 
              <span v-else>-</span></td>
              
             </tr>
             <tr>
               <td>运营商状态</td>
                <td>  <span v-if="yunyingstatusspan">{{yunyingstatusspan}}</span> 
                <span v-else>-</span> </td>
              <td>当前限速值</td>
               <td>{{ res.limitSpeed }}</td>
               <td>实名状态</td>
                <td>
                  <span v-if="isReal == 1">已实名</span>
                  <span v-else-if="isReal == 2">未实名</span>
                  <span v-else>—未知状态—</span>
                   <el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="_isRealName" style="margin-left:3px"></el-button>
              </td>
              <td>
                <span v-if="data.toType == _getToTypeValue('Y_')">话费</span>
                <span v-else></span>
              </td>
              <td>
                <span v-if="data.toType == _getToTypeValue('Y_')">{{data.balanceMoney}}</span>
                <span v-else></span>
              </td>
             
             </tr>
             <tr>
              <td>
                <span v-if="data.invNo">虚拟号</span>
              </td>
              <td>
                <span v-if="data.invNo">{{data.invNo}}</span>
              </td>
                <td> <span >运营策略</span></td>
              <td>{{res.virtualStrategy}}</td>
              <td>是否轮询</td>
                 <td>
              <span v-if="res.noLoop"> 不在轮询中<span>  {{"("+addSource+ ")"}}</span></span>
              <span v-else>轮询中</span>
              </td>
                <td>上次轮询时间</td>
              <td> <span v-if="res.lastLoopTime"> {{  formatTime(res.lastLoopTime) }}</span>
                   <span v-else>-</span></td>
              
             </tr>
             <tr>
               <td>实名方式</td>
              <td>{{getRealNameType(accountEntity.realNameType)}}</td>
              <td>首次充值时间</td>
              <td><span v-if="data.enabledTime">{{data.enabledTime}}</span>
              <span v-else>-</span></td>
               <td>上次停机时间</td>
              <td>
                 <span v-if="res.lastTingfujiTime">{{ res.lastTingfujiTime}}</span>
                <span v-else>-</span>
              </td>
               <td>上次停机原因</td>
              <td>
                 <span v-if="res.lastTingfujiReason">{{ res.lastTingfujiReason}}</span>
                <span v-else>-</span>
              </td>
             </tr>
             <tr>
               <td>提前停机</td>
              <td>
                <span v-if="stopAheadDays!=0">{{stopAheadDays+"天"}}</span>
               
                <span v-else>—</span>
                <el-button
                  icon="el-icon-refresh-right"
                  type="primary"
                  class="themed-button"
                  @click="getStopAheadDaysInfo"
                  style="margin-left: 3px"
                ></el-button>
                <!-- <i class="el-icon-refresh-right" @click="_isRealName"></i> -->
              </td>
             </tr>
           </table>
        </div>
      </div>
           <div class="group">
        <h1 class="title">流量信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
            <td>套餐总流量</td>
            <td>{{totalFlowId}}</td>
             <td>已使用流量</td>
             <td>{{ parseFloat(data.totalBytesCnt).toFixed(2)}}MB</td>
            <td>
                <span v-if="!data.invNo">剩余流量</span>
              </td>
              <td>
                <span v-if="!data.invNo">{{surplusFlowId}}</span>
              </td>
            <td>基础套餐</td>
             <td>{{ data.setMealName }}</td>
             </tr>
             <tr v-if="isShow">
              <td>总语音(分钟)</td>
                 <td><span>{{data.voiceSize}}分钟</span></td>
              <td>已使用语音(分钟)</td>
              <td><span>{{voiceUsed}}</span></td>
              <td>剩余语音(分钟)</td>
                <td><span>{{voiceRemaining}}</span></td>
                <td></td>
                <td></td>
             </tr>
             <tr >
              <td>增加已使用流量百分比<span v-if="res.strategyType==1"> (A)</span><span v-else-if="res.strategyType==2"> (B)</span></td>
              <td>
                <span v-html="curStrategyFormat"></span>
              </td>
               <td>已使用流量（真）</td>
              <td>{{ parseFloat(data.orginTotalBytesCnt).toFixed(2) }}MB</td>
               <td> <span v-if="isShow">增加已使用语音百分比</span> </td>
                <td>
                  <span v-if="isShow">
                    <span v-if="res.voiceAddDiscount">{{res.voiceAddDiscount}}</span>
                  <span v-else>未设置</span>
                  </span>
                  
                </td>
                 <td> <span v-if="isShow">已使用语音(真)</span> </td>
              <td><span v-if="isShow">{{origVoiceUsed}}</span></td>
             </tr>
           </table>
        </div>
      </div>
        <div class="group">
        <h1 class="title">当前套餐</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
            <!-- <td v-html="currentMeal"></td> -->
             <td>
                <p>当前生效套餐：</p>
                <span v-html="currentMeal"></span>
                <p>待生效套餐：</p>
                <span v-html="willMeal"></span>
              </td>
             </tr>
           </table>
        </div>
      </div>
       <div class="group">
        <h1 class="title">设备信息</h1>
        <div>
          <table cellspacing="0" cellpadding="0" style="width: 100%">
            <tr>
              <td>
                电池电量：
              </td>
              <td>{{deviceInfo.battery+"%"}}</td>
              <td>网络状态模式:</td>
              <td>
              </td>
               <td>当前实体卡号:</td>
              <td>{{getCurEntrityCard}}
               </td>
               <td>viccid:</td>
              <td>{{deviceInfo.virtualIccid}}
               </td>
             
            </tr>
            <tr>
              <td>短号:</td>
              <td>{{res.shortIccid}}</td>
               <td>运营商名称:</td>
              <td>{{data.accountName}}</td>
              <td>
               日用量流量：
              </td>
              <td></td>
             
             <td>30天用量流量:</td>
              <td>
              </td>
              
            </tr>
            <tr>
              <td>总流量:</td>
              <td>
              </td>
                <td>平均速率:</td>
              <td>
               </td>
              <td>WiFi数量:</td>
              <td>{{deviceInfo.wifiList.length}}</td>
             
             <td>
               IMEI：
              </td>
              <td>{{deviceInfo.imei}}</td>
            </tr>
              <tr>
                <td>SN:</td>
              <td>{{deviceInfo.sn}}
               </td>
              <td>运行时间:</td>
              <td>{{getRuntime(deviceInfo.runTime)}}</td>
              <td>
               WiFi名称：
              </td>
              <td ><span > {{deviceInfo.wifiList[0].ssid}}</span>  </td>
             <td>
                WiFi密码：
              </td>
              <td>{{deviceInfo.wifiList[0].password}}</td>
            </tr>
             <tr>
                <td>重启：</td>
              <td>
                 <el-button
                  type="primary"
                  class="themed-button"
                  @click="reboot"
                  style="margin-left: 3px"
                >重启</el-button>
               </td>
              <td>恢复出厂设置:</td>
              <td>
                 <el-button
                  type="primary"
                  class="themed-button"
                  @click="factory"
                  style="margin-left: 3px"
                >恢复出厂设置</el-button>
              </td>
              <td>
               软件版本：
              </td>
              <td>{{deviceInfo.softwareVersion}}</td>
             <td>
                硬件版本：
              </td>
              <td></td>
            </tr>
          
            <tr>
               <td>
               RSRP：
              </td>
              <td>{{deviceInfo.rsrp}}</td>
                <td>
               RSRQ：
              </td>
              <td>{{deviceInfo.rsrq}}</td>
               <td>
               SINR：
              </td>
              <td>{{deviceInfo.sinr}}</td>
               <td>
               RSSI：
              </td>
              <td>{{deviceInfo.rssi}}</td>
            </tr>
           
             <tr>
              <td>
                实体卡1：
              </td>
              <td @click="gotoShiTi(jkbdCardDetail.iccidMark1)" class="curor">{{jkbdCardDetail.iccidMark1}}</td>
              <td> 实体卡2：</td>
              <td @click="gotoShiTi(jkbdCardDetail.iccidMark2)" class="curor">
                {{jkbdCardDetail.iccidMark2}}
              </td>
               <td >实体卡3：</td>
              <td @click="gotoShiTi(jkbdCardDetail.iccidMark3)" class="curor">
                 {{jkbdCardDetail.iccidMark3}}
               </td>
               <td>虚拟卡不绑定:</td>
              <td>
                <span v-if="!jkbdCardDetail.needBind||jkbdCardDetail.needBind==0"> 可绑定</span>
                <span v-if="jkbdCardDetail.needBind==1"> 不可绑定</span>
                 <el-button
                  type="primary"
                  class="themed-button"
                  @click="unbindvirCard"
                  style="margin-left: 3px"
                  v-if="!jkbdCardDetail.needBind||jkbdCardDetail.needBind==0"
                >不绑定</el-button>
                <el-button
                  type="primary"
                  class="themed-button"
                  @click="bindvirCard"
                  style="margin-left: 3px"
                  v-if="jkbdCardDetail.needBind==1"
                >绑定</el-button></td>
            </tr>
             <tr>
               <td>连接设备数:</td>
              <td>{{deviceInfo.wifiList[0].clientNumber}}
              </td>
               <td>随机码:</td>
              <td>{{deviceInfo.chipid}}
              </td>
            </tr>
              <tr>
               <td>限速状态:</td>
              <td>{{deviceInfo.limitSpeed==0?"不限速":"限速"+deviceInfo.limitSpeed}}
              </td>
               <td v-if="deviceInfo.rateLimit">限速规则名称:</td>
              <td v-if="deviceInfo.rateLimit">{{deviceInfo.rateLimit.rule.name}}
              </td>
               <td v-if="deviceInfo.rateLimit">限速阈值:</td>
              <td v-if="deviceInfo.rateLimit">{{deviceInfo.rateLimit.rule.threshold}}
              </td>
            </tr>
          </table>
        </div>
      </div>
       <div class="group">
        <h1 class="title">多卡信息</h1>
        <div>
          <table cellspacing="0" cellpadding="0" style="width: 100%">
           
            <tr>
               <td>
                插拔卡需绑定：
              </td>
              <td><span v-if="!jkbdCardDetail.chabaBind||jkbdCardDetail.chabaBind==0">
                否
                </span>
                <span v-if="jkbdCardDetail.chabaBind==1">
                  是
                </span></td>
              <td>IMEI库编号:</td>
              <td><span v-if="!jkbdCardDetail.imeiRepoNo">未导入</span>
              <span v-else>{{jkbdCardDetail.imeiRepoNo}}</span>
              </td>
               <td>已绑定卡号:</td>
              <td>
                <span v-if="!jkbdCardDetail.bindCard">未绑定</span>
              <span v-else>{{jkbdCardDetail.bindCard}}</span></td>
            
             
            </tr>
            <tr>
               <!-- <td>禁网信息:</td>
              <td>{{jkbdCardDetail.netStatusMsg}}</td> -->
               <!-- <td>虚拟卡不绑定:</td>
              <td>
                <span v-if="!jkbdCardDetail.needBind||jkbdCardDetail.needBind==0"> 可绑定</span>
                <span v-if="jkbdCardDetail.needBind==1"> 不可绑定</span>
                 <el-button
                  type="primary"
                  class="themed-button"
                  @click="unbindvirCard"
                  style="margin-left: 3px"
                  v-if="!jkbdCardDetail.needBind||jkbdCardDetail.needBind==0"
                >不绑定</el-button>
                <el-button
                  type="primary"
                  class="themed-button"
                  @click="bindvirCard"
                  style="margin-left: 3px"
                  v-if="jkbdCardDetail.needBind==1"
                >绑定</el-button></td> -->
              <td>
               虚拟卡绑定结果：
              </td>
              <td>{{jkbdCardDetail.virtualBindResult}}</td>
             
            </tr>
            <tr>
              <td>
               实名信息：
              </td>
              <td>{{jkbdCardDetail.realNameInfo.logs}}</td>
            </tr>
              <!-- <tr>
               <span>禁网信息:</span>
              <span  style="white-space: pre-wrap;">{{jkbdCardDetail.netStatusMsg}}</span>
              </tr> -->
          </table>
          <div style="display:talbe;">
            <div style="margin-right:10px;display: table-cell;width:90px">禁网信息:</div>
           <div style="white-space: pre-wrap;display: table-cell; ">{{jkbdCardDetail.netStatusMsg}}</div>
          </div>
          
        </div>
      </div>
       <div class="group">
        <h1 class="title">诊断结果<el-button
                icon="el-icon-refresh-right"
                type="primary"
                class="themed-button"
                @click="diagnose"
                style="margin-left: 3px"
                :loading="diagnosisLoading"
        >诊断</el-button></h1>
        <div>
          <table cellspacing="0" cellpadding="0" style="width: 100%" >
            <tr v-for="(item,index) in  Object.keys(diagnosisMsg)"  :key="index">
              <td>
               {{item}}
              </td>
              <td>{{diagnosisMsg[item]}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="group">
        <h1 class="title">常规操作</h1>
        <div>
          <ul class="clearfix">
            <li @click="mealRecharge" v-show="!data.invNo">
              <img src="../cardManagement/1.png" alt="" />
              <p>套餐充值</p>
            </li>
            <li @click="cardRecovery" v-show="data.invNo">
              <img src="../cardManagement/3.png" alt="" />
              <p>保号复机</p>
            </li>
            <li @click="cardStop" v-show="data.invNo">
              <img src="../cardManagement/4.png" alt="" />
              <p>保号停机</p>
            </li>
            <li @click="flowDetail">
              <img src="../cardManagement/4.png" alt="" />
              <p>流量详单</p>
            </li>
            <li @click="updateExpire">
              <img src="../cardManagement/5.png" alt="" />
              <p>更改过期时间</p>
            </li>
            <li @click="stopRecoveryRecord">
              <img src="../cardManagement/9.png" alt="" />
              <p>停复机记录</p>
            </li>
            <li @click="orderRecord">
              <img src="../cardManagement/9.png" alt="" />
              <p>往期订单</p>
            </li>
            <li @click="noLoopcard" v-show="data.invNo">
              <img src="../cardManagement/7.png" alt="" />
              <p>不轮询网卡</p>
            </li>
            <li @click="modifyFlow" v-show="data.invNo">
              <img src="../cardManagement/7.png" alt="" />
              <p>增减流量</p>
            </li>
            <li @click="delOnlyLoop" v-show="data.invNo">
              <img src="../cardManagement/7.png" alt="" />
              <p>删除仅轮询</p>
            </li>
               <li @click="returnToZero"  >
              <img src="../cardManagement/7.png" alt="" />
              <p>退货清零</p>
            </li>
              <li @click="clearPluginCards"  >
              <img src="../cardManagement/7.png" alt="" />
              <p>清除插拔卡</p>
            </li>
              <li @click="singleLimit">
                        <img src="../cardManagement/7.png" alt="">
                        <p>单卡限速</p>
              </li>
            <li @click="singleSpeedLimit" >
              <img src="../cardManagement/4.png" alt="" />
              <p>单次限速</p>
            </li>
             <li @click="advanceLife" >
              <img src="../cardManagement/4.png" alt="" />
              <p>套餐提前生效</p>
            </li>
          </ul>
        </div>
      </div>

      <el-dialog title="套餐充值" :visible.sync="dialogAddPackage" v-if="dialogAddPackage">
        <add-package
          :addPackageData="addPackageData"
          :curInfor="data"
          @close="dialogAddPackage = false"
        ></add-package>
      </el-dialog>
      <el-dialog title="流量详单" :visible.sync="dialogFlowDetail" v-if="dialogFlowDetail">
        <flow-detail :iccidMark="iccidMark"></flow-detail>
      </el-dialog>
      <el-dialog title="变更过期时间" :visible.sync="dialogUpdateExpire" v-if="dialogUpdateExpire" width="80%">
        <update-expire :cardId="cardId" :iccidMark="iccidMark" @refresh="refresh"></update-expire>
      </el-dialog>
      <el-dialog title="增减流量" :visible.sync="dialogModifyFlow" v-if="dialogModifyFlow"> 
        <change-flow
          :curInfor="data"
          @close="dialogModifyFlow = false"
        ></change-flow>
      </el-dialog>
       <el-dialog title="单卡限速" :visible.sync="dialogSingleSpeed" v-if="dialogSingleSpeed">
        <single-Speed :iccidMark="iccidMark"  @close="dialogSingleSpeed = false"  ></single-Speed>
      </el-dialog>
      <el-dialog title="单次限速" :visible.sync="dialogAddvisible" width="500"   v-if="dialogAddvisible"  :destroy-on-close='true'>
             <singleSpeedLimit @close="dialogAddvisible = false" :iccidMark="iccidMark"  :accountId="data.accountId"></singleSpeedLimit>
        </el-dialog>

          <el-dialog title="清除插拔卡" :visible.sync="dialogClearVisible"  v-if="dialogClearVisible">
  <el-form :model="clearform">
    <el-form-item label="插拔卡号"  label-width="120px">
      <el-checkbox-group
                  v-model="clearform.iccids"
                >
                  <el-checkbox
                    v-for="(item, index) in chabaList"
                    :label="item"
                    :key="index"
                    >{{ item }}</el-checkbox
                  >
                </el-checkbox-group>
    </el-form-item>
  
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogClearVisible = false">取 消</el-button>
    <el-button type="primary" @click="clearSure">确 定</el-button>
  </div>
</el-dialog>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
import AddPackage from "../virtualCard/virtualCardDetail/addPackage";
import FlowDetail from "../virtualCard/virtualCardDetail/flowDetail";
import UpdateExpire from "../virtualCard/virtualCardDetail/updateExpire";
import ChangeFlow from "../virtualCard/virtualCardDetail/changeFlow";
 import singleSpeedLimit from "./singleSpeedLimit.vue";
import {
  changeVirtualStrategy,
  findCardInfo,
  getPackageAll,
  activate_,
  cardRecovery_,
  cardStop_,
  limitStopRecovery_,
  setThreshold_,
  deviceCardBound_,
  tamperRecovery_,
  noLoopcard_,
  allowRealname_,
  openVoice_,
  delOnlyLoop_,
  isRealName,
  statusModify,
  cardRemarkUpdate,
  getRemarkByIccid,
  findSingleCardStopByIccid,
  returnToZero,
  clearPluginCards,
  findCardInfoAll,
  refreshLiuliang,
  diagnoseKf,
  mealAdvanceLife,
  updateNotBind,
  deviceInfo,
  getStopAheadDays,
  clearChaba,
} from "@/api/cardManagement/cardBillDetail.js";

import { findInvCardInfo } from "@/api/virtualCard/accountRateList.js";

import { messageBox, confirmBox } from "@/utils/common.js";
import loading from "@/utils/Loading.js";
import { mapGetters, mapMutations } from "vuex";
import dictGroup from "@/mixins/dictGroup.js";
import SingleSpeed from "@/views/cardManagement/cardBillDetail/singleSpeed.vue";
import Utils from "@/utils/utils.js";
import { operationReboot ,operationRestore} from "@/api/devices/devices.js";
export default {
  name: "virtualCardDetail",
  mixins: [dictGroup],
  props: ["iccidMark"],
  data() {
    return {
       dialogClearVisible:false,
      chabaList:[],
      clearform:{
       iccids:[],
       virtualIccid:"",
      },
      stopAheadDays:0,
      fullscreenLoading: false,
      res: {},
      data: {},
      accountEntity: [],
      cardLiftList: [],
      nextCardLifeList: [],
      tianDjbCardLifes: [],

      dialogAddPackage: false,
      addPackageData: {},
      dialogFlowDetail: false,
      dialogUpdateExpire: false,
      dialogModifyFlow: false,
      strategyType: "1",
      isReal: 0,
      totalFlowId: "",
      surplusFlowId: "",
      dialogSingleSpeed: false,
      addSource: "",
      jkbdCardDetail: {
        realNameInfo:{
          logs:""
      }
      },
      diagnosisMsg: {},
      dialogAddvisible:false,
        diagnosisLoading:false,
        deviceInfo:{
         battery:"",
         virtualIccid:"",
         wifiList:[{ssid:"",password:"",clientNumber:""},],
         imei:"",
         sn:"",
         runTime:"",
         softwareVersion:"",
         rsrp:"",
         rsrq:"",
         sinr:"",
         sinr:"",
         rssi:"",
         limitSpeed:"",
         rateLimit:{
          rule:{
            name:"",
            threshold:""
          }
         },
        },
    };
  },
  computed: {
    ...mapGetters(["iccidOrPhone_v"]),
     getCurEntrityCard(){
     let cardName 
     let  currentSimId = this.deviceInfo.currentSimId
     if(this.deviceInfo.simList){
    for(let i =0;i<this.deviceInfo.simList.length;i++){
            if(currentSimId==this.deviceInfo.simList[i].simId){
              cardName = this.deviceInfo.simList[i].simIccid
            }
     }
     }
     
     return cardName
    },
    curStrategyFormat() {
      let curStrategy = this.res.curStrategy;
      if (curStrategy && curStrategy !== "") {
        if (isNaN(curStrategy)) {
          var addStr = "";
          var pairs = curStrategy.split(";");
          var last = 0;
          var idx = 0;
          var total_count = pairs.length;
          for (var pair of pairs) {
            idx = idx + 1;
            var arr = pair.split(",");
            if (idx === total_count && arr[0] < 100) {
              addStr = addStr + last + "%以上增加" + arr[1] + "%;<br>";
            } else {
              addStr =
                addStr +
                last +
                "% ~ " +
                arr[0] +
                "%部分增加" +
                arr[1] +
                "%;<br>";
            }
            last = arr[0];
          }
          return addStr;
        } else {
          return curStrategy + "%";
        }
      } else {
        return "未设置";
      }
    },
    isShow() {
      if (
        this.data.accountName &&
        (this.data.accountName.startsWith("常州移动") ||
          this.data.accountName.startsWith("重庆移动"))
      ) {
        return false;
      } else {
        return true;
      }
    },
    yunyingstatusspan() {
      let flag = false;
      this.w_ACCOUNT_.forEach((item) => {
        if (item.dictValue == this.data.category) {
          flag = true;
        }
      });
      return flag ? this.res.yunyingstatus : "";
    },
    voiceRemaining() {
      return (
        (this.data.voiceSize - parseInt(this.data.totalBytesCntVoice)).toFixed(
          0
        ) + "分钟"
      );
    },
    voiceUsed() {
      let flowCnt_voice = parseInt(this.data.totalBytesCntVoice);
      if (flowCnt_voice && flowCnt_voice != "") {
        flowCnt_voice = parseFloat(flowCnt_voice).toFixed(0);
      }
      return flowCnt_voice + "分钟";
    },
    origVoiceUsed() {
      let orginTotalBytesCntVoice = this.data.orginTotalBytesCntVoice;
      if (orginTotalBytesCntVoice && orginTotalBytesCntVoice != "") {
        orginTotalBytesCntVoice = parseFloat(orginTotalBytesCntVoice).toFixed(
          0
        );
      }
      return orginTotalBytesCntVoice + "分钟";
    },
    importTime() {
      //导入时间
      let createDateTime = this.data.createDateTime + "";
      return (
        createDateTime.substr(0, 4) +
        "-" +
        createDateTime.substr(4, 2) +
        "-" +
        createDateTime.substr(6, 2) +
        " " +
        createDateTime.substr(8, 2) +
        ":" +
        createDateTime.substr(8, 2) +
        ":" +
        createDateTime.substr(10, 2) +
        ":" +
        createDateTime.substr(12, 2)
      );
    },
    willMeal() {
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix < compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      if (this.nextCardLifeList.length > 0) {
        //  msg+="待生效套餐<br>"
        this.nextCardLifeList.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }
      if (this.tianDjbCardLifes.length > 0) {
        this.tianDjbCardLifes.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }

      return msg;
    },
    currentMeal() {
      //当前套餐
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      return msg;
    },
    gparams() {
      let params = {};
      params.iccidOrPhone = this.iccidMark;
      params.setMealId = this.data.setMealId;
      params.isChild = this.data.isChild;
      params.codeChild = this.data.codeChild;
      params.cardType = this.data.category;
      params.status = this.data.status;
      params.type = this.data.type;
      return params;
    },

    cardId() {
      return this.data.id;
    },
  },
  watch: {
    iccidMark(n, o) {
      console.log(n,2323)
      //this.tableData = n.userList;
      this._findInvCardInfo();
      
    },
  },
  mounted() {
    this.fullscreenLoading = true;
    this._findInvCardInfo();
   
  },
  methods: {
     handleIccids(val){
     console.log(val)
    },
    clearSure(){
      console.log(this.clearform)
      if(this.clearform.iccids.length ==0 ){
          messageBox(this,'请选择插拔卡')
            return
      }
      let params = {
        iccids:this.clearform.iccids.join(","),
        virtualIccid:this.clearform.virtualIccid
      }
      clearChaba(params).then(res=>{
          this.dialogClearVisible = false
          this.$message({
							showClose: true,
							message: '清除插拔卡成功',
							type: 'success',
						})
      })
      
    },
    ...mapMutations(["SET_ICCID_FOR_TFJ"]),
   getStopAheadDaysInfo(){
       getStopAheadDays({iccid:this.iccidMark}).then(res=>{
          console.log(res)
          this.stopAheadDays  = res.stopAheadDays
       })  
    },
    //将秒数转换为天时分秒格式
    getRuntime(value){
     var result = "";
      var sencond = parseInt(value);
      //3600秒等于60分钟等于1小时
      if (sencond > 3600 * 24) {
        //表示大于一天
        var day = sencond / (3600 * 24);
        sencond = sencond % (3600 * 24); //求天数整除外剩余的秒数
        var hour = sencond / 3600; //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
        result = parseInt(day) + "天" + parseInt(hour) + "时";
      } else if (sencond > 3600) {
        //表示大于一个小时
        var hour = sencond / 3600;
        var minute = (sencond % 3600) / 60; //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
        result = parseInt(hour) + "时" + parseInt(minute) + "分";
      } else if (sencond > 60) {
        //表示大于一分钟
        var minute = sencond / 60;
        var sec = sencond % 60; //求分钟数整除外剩余的秒数
        result = parseInt(minute) + "分" + parseInt(sec) + "秒";
      } else {
        //不大于一天、不大于一个小时、也不大于一分钟，那就是秒数
        result = "" + parseInt(sencond) + "秒";
      }
      return result;
    },
    reboot(){
    this.$confirm("是否重启", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.deviceInfo.id);
          operationReboot(paramValue).then((res) => {
            console.log(res,122121)
            if (res.code == "0") {
              this.$message({
                message: "提交请求成功",
                type: "success",
              });
             
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    factory(){
     this.$confirm("是否恢复出厂设置", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.deviceInfo.id);
          operationRestore(paramValue).then((res) => {
            if (res.code == "0") {
              this.$message({
                message: "提交请求成功",
                type: "success",
              });
             
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
   
    unbindvirCard(){
      let params = {
        iccidOrPhone:this.iccidMark,
        notBind:"1",
      }
     updateNotBind(params).then(res=>{
        this.$message({
                showClose: true,
                message: "设置成功",
                type: "success",
              });
         this._findInvCardInfo();    
     })
    },
    bindvirCard(){
        let params = {
        iccidOrPhone:this.iccidMark,
        notBind:"0",
      }
    updateNotBind(params).then(res=>{
      this.$message({
                showClose: true,
                message: "设置成功",
                type: "success",
              });
         this._findInvCardInfo();
     })
    },
     refreshLiuliang(type){
       this.fullscreenLoading = true;
     refreshLiuliang({iccid:this.iccidMark}).then(res=>{
       console.log(res)
        this.fullscreenLoading = false;
       if (res.system_result_key == "0") {
           messageBox(this, "刷新成功");
          this._findInvCardInfo();
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
     }).catch(error=>{
      console.log(error)
      this.fullscreenLoading = false;
     })
    },
    gotoShiTi(iccid){
      console.log(iccid)
     if(!iccid){
     return 
     }
     this.$emit("refreshShi",iccid)
    },
     singleSpeedLimit(){
      this.dialogAddvisible = true
    },
    formatTime(time) {
      return Utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date(time));
    },
    getRealNameType(realNameType) {
      console.log(realNameType);
      let realNameTypeStr = "-";
      if (realNameType == "4") {
        realNameTypeStr = "不用实名";
      } else if (realNameType == "1") {
        realNameTypeStr = "先实名后充值";
      } else if (realNameType == "2") {
        realNameTypeStr = "先充值后强制实名";
      } else if (realNameType == "3") {
        realNameTypeStr = "可选择下次实名";
      }
      return realNameTypeStr;
    },
    singleLimit() {
      //单卡限速
      this.dialogSingleSpeed = true;
    },
    delOnlyLoop() {
      //删除仅轮询
      this.fullscreenLoading = true;
      delOnlyLoop_(this.gparams)
        .then((res) => {
          this._findInvCardInfo();
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    modifyFlow() {
      //增减流量
      this.dialogModifyFlow = true;
    },
    noLoopcard() {
      //不轮询网卡
      if (this.res.noLoop) {
        messageBox(this, "该卡已经是不轮询状态。");
        return;
      }
      confirmBox(
        this,
        "添加成功后该卡将不执行停复机操作同时会出现流量，状态不更新等状态，请谨慎操作！",
        "操作提示",
        this._noLoopcard_
      );
    },
    orderRecord() {
      //往期订单
      // this.$router.push({
      //   path: "/cardManagement/orderRecord",
      //   query: {
      //     iccidMark: this.iccidMark
      //   },
      // })
      this.$router
        .push({ path: "/cardManagement/orderRecord/" + this.iccidMark })
        .catch(() => true);
    },
    stopRecoveryRecord() {
      ///停复机记录
      this.SET_ICCID_FOR_TFJ(this.iccidMark);
      this.$router.push({
        path: "/cardManagement/tingfujiRecord",
        query: {
          iccidMark: this.iccidMark,
        },
      });
    },
    updateExpire() {
      //更改过期时间
      this.dialogUpdateExpire = true;
    },
    flowDetail() {
      //流量详单
      this.dialogFlowDetail = true;
    },
    cardStop() {
      //保号停机
      this.fullscreenLoading = true;
      cardStop_(this.gparams)
        .then((res) => {
          this._findInvCardInfo();
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    cardRecovery() {
      //保号复机
      this.fullscreenLoading = true;
      cardRecovery_(this.gparams)
        .then((res) => {
          this._findInvCardInfo();
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
     compareResult(expireDate){
      let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
         let compareDate = "";
          let compareDate_unix = "";
          if (expireDate) {
            compareDate = expireDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {//当前时间大于等于过期时间   没有有效套餐
            return  false
          }
          return true
    },
    mealRecharge() {
      //套餐充值
      if (this.res.noRecharge) {
        messageBox(this, "该卡暂不支持充值。");
        return;
      }
      if (this.data.expireDate != "-"  && this.compareResult(this.data.expireDate)) {
        confirmBox(this, "该卡已有套餐，确认是否继续充值?", "操作提示", () => {
          this._mealRecharge();
        });
      } else {
        this._mealRecharge();
      }
    },
    _isRealName() {
      isRealName({ iccidOrPhone: this.iccidMark }).then((res) => {
        if (res.isRealName == false) {
          this.isReal = 2;
        } else if (res.isRealName == true) {
          this.isReal = 1;
        }
      });
    },
    advanceLife() {
      //套餐提前生效
      // let iccidOrPhone = this.formInline.iccidOrPhone
			// if(!iccidOrPhone){
			//     messageBox(this,"请输入ICCID卡号或短号或接入号，请点击搜索查询。")
			//     return  
			// }
      mealAdvanceLife({ iccidMark: this.iccidMark }).then((res) => {
        messageBox(this, "操作成功!");
        this._findInvCardInfo(true);
      });
    },
      diagnose() {
          this.diagnosisLoading = true
          diagnoseKf({ iccid: this.data.iccidMark }).then((res) => {
              this.diagnosisLoading = false
              this.diagnosisMsg = res.diagnosisMsg
          });
      },
    returnToZero() {
      let params = {};
      params.iccid = this.iccidMark;
      returnToZero(params)
        .then((res) => {
          this.$message({
            showClose: true,
            message: "退货清零成功",
            type: "success",
          });
        })
        .catch((error) => {
          this.$message({
            showClose: true,
            message: "退货清零失败",
            type: "warning",
          });
        });
    },
    clearPluginCards() {
      let params = {};
      params.iccid = this.iccidMark;
      clearPluginCards(params)
        .then((res) => {
          console.log(res)
            if(res.system_result_message_key.includes('8986')){
          let allArr = res.system_result_message_key.split(":")
          console.log(allArr)
          this.clearform.virtualIccid  = allArr[0]
          let str = ""
           if (allArr[1].endsWith(';')) {
              str = allArr[1].slice(0, -1);
           }else{
              str = allArr[1]
           }
           this.chabaList   = str.split(";")
            this.dialogClearVisible = true
              //
        }else{
        this.$message({
							showClose: true,
							message: '清除插拔卡成功',
							type: 'success',
						})
        }
        })
        .catch((error) => {
          // this.$message({
          //   showClose: true,
          //   message: "清除插拔卡失败",
          //   type: "warning",
          // });
        });
    },
    refresh() {
      this.fullscreenLoading = true;
      this._findInvCardInfo();
    },
    getDeviceInfo(){
       
     deviceInfo({imei:this.data.imei,virtualIccid:this.iccidMark}).then(res=>{
       console.log(res,1063)
       this.deviceInfo = res 
     }).catch(error=>{
         console.log(error)
     })
    },
    _findInvCardInfo() {
      let params = { iccidOrPhone: this.iccidMark };
      if (!this.iccidMark) {
        params = {
          iccidOrPhone: this.iccidOrPhone_v,
        };
      }
      findCardInfoAll(params).then((res) => {
        this.fullscreenLoading = false;
        this.res = res;
        this.data = res.entity;
        this.jkbdCardDetail = res.multiCardDetail;
        this.diagnosisMsg = {};
        this.accountEntity = res.accountEntity;
        this.cardLiftList = res.cardLiftList;
        this.nextCardLifeList = res.nextCardLifeList;
        this.tianDjbCardLifes = res.tianDjbCardLifes;
           this.getDeviceInfo();
        this.strategyType = res.strategyType + "";
        if (res.noLoop) {
          this.addSource = res.addSource;
        }
        let currSetmealFlow = 0;
        let currSetmealName = "";
        let isLongDiKa = false;
        if (this.cardLiftList) {
           isLongDiKa = false;
          if (this.cardLiftList && this.cardLiftList.length > 0) {
            for (var i = 0; i < this.cardLiftList.length; i++) {
              // if(isLongDiKa == false && (this.cardLiftList[i].type == 6 || this.cardLiftList[i].type == 23 )) {
              let typeValue = this._getDictValue("ZRLJTC_", this.w_packageType);
              if (
                isLongDiKa == false &&
                this.cardLiftList[i].type == typeValue
              ) {
                isLongDiKa = true;
                break;
              }
            }
          }
        }
        this.cardLiftList.forEach((item) => {
          // if(item.type != 1 && item.type != 101){
          if (
            item.type != this._getDictValue("DJB_", this.w_packageType) &&
            item.type != this._getDictValue("YYDJB_", this.w_packageType)
          ) {
            currSetmealFlow = item.flowSize;
            currSetmealName = item.mealName;
          }
        });

        if (currSetmealFlow == 0 && !(!isLongDiKa && this.cardLiftList)) {
          this.surplusFlowId = "∞ MB";
          this.totalFlowId = "∞ MB";
        } else {
          this.surplusFlowId =
            parseFloat(this.data.flowSize - this.data.totalBytesCnt).toFixed(
              2
            ) + "MB";
          this.totalFlowId = this.data.flowSize + "MB";
        }
      });
    },
    _mealRecharge() {
      loading.show();
      let params = {};
      params.iccidOrPhone = this.iccidMark;
      params.setMealId = this.data.setMealId;
      params.isChild = this.data.isChild;
      params.codeChild = this.data.codeChild;
      getPackageAll(params).then((res) => {
        this.dialogAddPackage = true;
        this.addPackageData = res;
      });
    },
  },
  components: {
    AddPackage,
    FlowDetail,
    UpdateExpire,
    ChangeFlow,
    SingleSpeed,
    singleSpeedLimit,
  },
};
</script>

<style>
ul {
  list-style: none;
}
.cardBillDetail {
  position: relative;
  width: 100%;
  /* height: 100%; */
  background: #fff;
}
.cardBillDetail .card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(7, 17, 27, 0.5);
}
.cardBillDetail .card-icon i {
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
.cardBillDetail .group {
  padding: 25px;
  background: #fff;
}
/* .cardBillDetail .group table {
  border-left: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
} */
.cardBillDetail .group table td {
  height: 32px;
  line-height: 32px;
  /* border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7; */
  text-align: left;
}
.cardBillDetail .group .title {
  height: 32px;
  line-height: 32px;
}
.cardBillDetail ul > li {
  float: left;
  width: 125px;
  text-align: center;
  margin-right: 20px;
  margin-top: 10px;
}
 .cardBillDetail  .curor{cursor:pointer;}
</style>
