<template>
  <div class="sales-batch fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">销售批次</span>
      <el-form
        inline
        ref="salesBatchForm"
        v-model="salesBatchFormData"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="运营商">
          <search-select
            :optionsMetaAll="agentOperators"
            v-model="salesBatchFormData.accountId"
          ></search-select>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-circle-plus"
            class="themed-button"
            type="info"
            @click="add()"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        border
        stripe
        ref="table"
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column show-overflow-tooltip label="运营商" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.account.accountName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="setmealType"
          label="运营商套餐类型"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="cardType"
          label="卡类型"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="iccidRange"
          label="iccid区间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="count"
          label="数量"
          min-width="80"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip label="出库时间" min-width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.saleTime">{{
              formatTime(scope.row.saleTime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="batch"
          label="批次"
          min-width="120"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip label="销售" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.sale.saleName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="department"
          label="部门"
          min-width="120"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip label="代理商" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.agent.name }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>

    <el-dialog
      title="新增"
      :visible.sync="addDialogFormVisible"
      width="500px"
      :destroy-on-close="true"
    >
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item
          label="运营商"
          :label-width="formLabelWidth"
          prop="accountId"
        >
          <search-select
            :optionsMetaAll="agentOperators1"
            v-model="addForm.accountId"
            style="width: 170px"
          ></search-select>
        </el-form-item>

        <el-form-item
          label="套餐类型"
          :label-width="formLabelWidth"
          prop="setmealType"
        >
          <el-select
            v-model="addForm.setmealType"
            placeholder="请选择"
            style="width: 170px"
          >
            <el-option label="流量套餐" value="data"></el-option>
            <el-option label="语音套餐" value="voice"></el-option>
            <el-option label="流量语音套餐" value="datavoice"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="卡类型"
          :label-width="formLabelWidth"
          prop="cardType"
        >
          <el-select
            v-model="addForm.cardType"
            placeholder="请选择"
            style="width: 170px"
          >
            <el-option label="2G" value="2G"></el-option>
            <el-option label="4G" value="4G"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="销售" :label-width="formLabelWidth" prop="saleId">
          <search-select
            :optionsMetaAll="sellerOperators"
            v-model="addForm.saleId"
            style="width: 170px"
          ></search-select>
        </el-form-item>

        <el-form-item
          label="Iccid区间"
          :label-width="formLabelWidth"
          prop="iccidRange"
        >
          <el-input
            v-model="addForm.iccidRange"
            autocomplete="off"
            style="width: 170px"
          ></el-input>
        </el-form-item>

        <el-form-item label="Iccid文件(.xls)" :label-width="formLabelWidth">
          <input class="file-input" type="file" ref="fileAddInput" />
          <div class="mt5">
            <span class="text-color" style="font-size: 12px"
              >仅支持.xls/.xlsx格式</span
            >
          </div>
        </el-form-item>

        <el-form-item label="数量" :label-width="formLabelWidth" prop="count">
          <el-input
            v-model="addForm.count"
            autocomplete="off"
            style="width: 170px"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="代理商"
          :label-width="formLabelWidth"
          prop="agentId"
        >
          <search-select
            :optionsMetaAll="dailiOptions"
            v-model="addForm.agentId"
            style="width: 170px"
          ></search-select>
        </el-form-item>

        <el-form-item
          label="部门"
          :label-width="formLabelWidth"
          prop="department"
        >
          <el-input
            v-model="addForm.department"
            autocomplete="off"
            style="width: 170px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="info" @click="sureAdd('addForm')"
          >确 定</el-button
        >
        <el-button
          class="themed-button"
          type="info"
          @click="exportTemplateExcel('sale_batch.xls')"
          >导出excel模板</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="addDialogFormVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchSelect from "@/components/SearchSelect.vue";
import resize from "@/mixins/resize.js";
import { agentOperatorList } from "@/api/order.js";
import {
  salesBatchList,
  daiLiList,
  allSaleList,
  addSaleBatch,
} from "@/api/saleCardBatch.js";
import Utils from "@/utils/utils.js";
import { exportTemplate } from "@/api/statistics.js";
export default {
  name: "salesBatch",
  components: { SearchSelect },
  mixins: [resize],
  data() {
    return {
      sellerOperators: [{ label: "全部", value: "" }],
      agentOperators: [{ label: "全部", value: "" }],
      dailiOptions: [{ label: "全部", value: "" }],
      agentOperators1: [{ label: "全部", value: "" }],
      salesBatchFormData: {
        accountId: "",
      },
      departmentList: [],
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      addDialogFormVisible: false,
      addForm: {
        accountId: "",
        setmealType: "data",
        cardType: "2G",
        saleId: "",
        iccidRange: "",
        count: "",
        agentId: "",
        department: "",
      },
      formLabelWidth: "150px",
      rules: {
        department: [
          { required: true, message: "请填写部门", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getSalesBatchList();
    this.getAgentOperatorList();
    this.getDaiLiOperatorList();
    this.getSellerOperators();
  },
  methods: {
    getAgentOperatorList() {
      agentOperatorList().then((res) => {
        let arrList = [];
        res.forEach((element) => {
          arrList.push({ label: element.accountName, value: element.id });
        });
        this.agentOperators1 = arrList;
        this.agentOperators = [{ label: "全部", value: "" }, ...arrList];
      });
    },
    getDaiLiOperatorList() {
      daiLiList().then((res) => {
        let arrList = [];
        res.forEach((element) => {
          arrList.push({ label: element.name, value: element.id });
        });
        this.dailiOptions = arrList;
      });
    },
    getSellerOperators() {
      allSaleList().then((res) => {
        let arrList = [];
        res.forEach((element) => {
          arrList.push({ label: element.saleName, value: element.id });
        });
        this.sellerOperators = arrList;
      });
    },
    getSalesBatchList() {
      let params = {
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.salesBatchFormData, params);
      salesBatchList(this.salesBatchFormData).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    formatTime(time) {
      return Utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date(time));
    },
    add() {
      this.addDialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      this.addForm.accountId = this.agentOperators1[0].value;
      this.addForm.saleId = this.sellerOperators[0].value;
      this.addForm.agentId = this.dailiOptions[0].value;
      this.addForm.setmealType = "data";
      this.addForm.cardType = "2G";
    },
    exportTemplateExcel(name) {
      exportTemplate({ name: name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
    sureAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let file = this.$refs["fileAddInput"].files[0];
          if (file) {
            let fileName = file.name;
            let index = fileName.lastIndexOf(".");
            if (
              index < 0 ||
              !(
                fileName.substring(index + 1, fileName.length) == "xls" ||
                fileName.substring(index + 1, fileName.length) == "xlsx"
              )
            ) {
              this.$alert("文件格式错误", "提示", {
                confirmButtonText: "确定",
              });
              return;
            } else if (!!Utils.checkFile(file)) {
              if (Utils.checkFile(file) == 1) {
                messageBox(this, "上传文件不能超过20M");
                return;
              } else if (Utils.checkFile(file) == 2) {
                messageBox(this, "上传文件名称长度不能超过36个字符");
                return;
              }
            } else {
              let param = new FormData();
              param.append("file", file);
              param.append("accountId", this.addForm.accountId);
              param.append("setmealType", this.addForm.setmealType);
              param.append("cardType", this.addForm.cardType);
              param.append("saleId", this.addForm.saleId);
              param.append("iccidRange", this.addForm.iccidRange);
              param.append("count", this.addForm.count);
              param.append("agentId", this.addForm.agentId);
              param.append("department", this.addForm.department);
              addSaleBatch(param).then((res) => {
                if (res.system_result_key == "0") {
                  this.$message({
                    type: "success",
                    message: "导入成功",
                  });
                  this.getSalesBatchList();
                  this.count = 0;
                  this.addForm = {};
                  this.$refs["fileAddInput"].value = "";
                } else {
                  this.$message({
                    showClose: true,
                    message: res.system_result_message_key,
                    type: "error",
                  });
                }
              });
            }
          } else {
            this.$alert("请选择文件", "提示", {
              confirmButtonText: "确定",
            });
            return;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSearch() {
      this.page = 1;
      this.getSalesBatchList();
    },
    onPageChange(page) {
      this.page = page;
      this.getSalesBatchList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getSalesBatchList();
    },
  },
};
</script>

<style>
.sales-batch .el-form-item__label {
  vertical-align: top;
}
</style>