<template>
	<div class="allCards fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">运营商卡类型</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="销售部门:">

					<el-select v-model="searchIteam.departmentId" collapse-tags placeholder="全部" @visible-change="getDefault">
						<div class="el-input" style="width:auto;margin:0 5%;min-width:90px;max-width:150px">
							<input type="text" placeholder="请输入" class="el-input__inner" v-model="dropDownValue" @keyup="dropDownSearch"
							 style="height:28px;width:98%">
						</div>
						<el-option label="全部" value=""></el-option>
						<el-option v-for="item in optionsMetaShow" :label="item.departmentName" :key="item.departmentId" :value="item.departmentId"></el-option>
					</el-select>

				</el-form-item>
				<el-form-item label="日期:">
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					 format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="exportExcel" icon="el-icon-caret-left" class="themed-button">导出excel</el-button>
				</el-form-item>
				<iframe name="hidden_down_iframe" id="hidden_down_iframe" style="display: none;" src=""></iframe>
			</el-form>
		</div>
		<div class="content-box">
			<div class="table_box">
				<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
					<el-table-column prop="dayDate" label="日期">
					</el-table-column>
					<el-table-column prop="activeTotal" label="激活总数">
					</el-table-column>
					<el-table-column prop="newActive" label="新增激活">
					</el-table-column>
					<el-table-column prop="income" label="新增收入">
					</el-table-column>
					<el-table-column prop="stopCard" label="停卡数">
					</el-table-column>
					<el-table-column prop="averageFlow" label="平均流量">
					</el-table-column>
				</el-table>
				<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
				 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
				 @current-change="onPageChange">
				</el-pagination>
			</div>

		</div>
	</div>
</template>

<script>
	import onresize from "@/mixins/resize.js"
	import loading from "@/utils/Loading.js"
	import {
		getDepartmentList,
		getAllcardReport,
		operatorCardsExport
	} from "@/api/dataReport.js"
	export default {
		name:'operatorCardtype',
		mixins: [onresize],
		data() {
			return {
				value1: [],
				tabledata: [],
				page: 1,
				pageSize: 15,
				total: 0,
				searchIteam: {
					departmentId: "",
					startTime: this.value1 == null ? "" : this.value1[0],
					endTime: this.value1 == null ? "" : this.value1[1],
				},
				agentCardType: [],
				dropDownValue: "",
				optionsMetaShow: [],

			}
		},
		created() {
			this.initDatepicker()
			this.searchIteam.startTime = this.value1 == null ? "" : this.value1[0]
			this.searchIteam.endTime = this.value1 == null ? "" : this.value1[1]

			//this.getOrderSummeryList(params)
			getDepartmentList(this.searchIteam).then(res => {
				this.agentCardType = res.filter((item) => {
					return item.departmentName != ""
				})
				this.optionsMetaShow = this.agentCardType
			})
			this.getAllcardReportList(this.searchIteam)
		},
		watch: {
			value1: (value) => {
				console.log(value)

			},
			startTime: (value1) => {
				console.log(value1)
			}

		},

		methods: {
			initDatepicker() {
				const end = new Date();
				const start = new Date();
				start.setDate(1)
				this.value1[0] = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate()
				this.value1[1] = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate()
			},
			getAllcardReportList(params) {
				getAllcardReport(params).then(res => {
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
					startTime: this.value1 == null ? "" : this.value1[0],
					endTime: this.value1 == null ? "" : this.value1[1],
					departmentId: this.searchIteam.departmentId,
				}
				this.getAllcardReportList(params)
			},
		/* 	exportExcel() {

				let departmentId = this.searchIteam.departmentId
				let startTime = this.value1 == null ? "" : this.value1[0]
				let endTime = this.value1 == null ? "" : this.value1[1]
				let url = "https://iottest.sunshineiot.net/traffic-web/statis/cardReport/all/export.do?" + "departmentId=" +
					departmentId + "&startTime=" + startTime + "&endTime=" + endTime;
				url = encodeURI(encodeURI(url));
				document.getElementById("hidden_down_iframe").src = url; 
			}, */
			exportExcel(){
				loading.show()
				let params = {
					departmentId:this.searchIteam.departmentId,
					startTime: this.value1 == null ? "" : this.value1[0],
					endTime:this.value1 == null ? "" : this.value1[1],
				}
				operatorCardsExport(params).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
			},
			handleSizeChange(size) {
				this.pageSize = size
				this.searchData()
			},
			onPageChange(page) {
				this.page = page
				this.searchData()
			},
			dropDownSearch() {
				this.searchIteam.departmentId = '';
				this.optionsMetaShow = this.agentCardType.filter((item) => {
					return item.departmentName.includes(this.dropDownValue)
				});

				this.$nextTick(() => {
					document.getElementsByClassName('el-scrollbar').forEach(element => {
						element.style.display = 'block'
					});
				})

			},
			getDefault() {
				this.optionsMetaShow = this.agentCardType
				this.dropDownValue = ""
			},
		}
	};
</script>

<style>
	.allCards .el-form-item__label {
		vertical-align: top;
	}
</style>
