<template>
    <div class="card-list  fadeInRight">
        <div class="title-box clearfix">
        <span class="m-title">卡库列表</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item>
					<el-input v-model="cardListForm.iccid"  placeholder="ICCID" class="iccid-width"></el-input>
			</el-form-item>
            <el-form-item>
					<el-input v-model="cardListForm.phone"  placeholder="接入号"></el-input>
			</el-form-item>

            <el-form-item label="库编号">
                <el-select filterable v-model="cardListForm.cardBankCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in cardBankCodeList" :label="item.cardBankCode" :value="item.cardBankCode" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
					<el-input v-model="cardListForm.accountName"  placeholder="运营商"></el-input>
			</el-form-item>
            <el-form-item>
					<el-input v-model="cardListForm.customerName"  placeholder="客户"></el-input>
			</el-form-item>

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>

            <el-form-item>
                	<el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addCard()">新增卡</el-button>
            </el-form-item>
            <el-form-item>
					<el-button icon="el-icon-delete" type="danger" class="themed-button" @click="deleteCard()">删除卡</el-button>
			</el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>
        </div>

        <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID" prop="iccid" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="接入号" prop="phone" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="卡库编号" prop="cardBankCode" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="运营商" prop="accountName" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="客户" prop="customerName" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="操作人" prop="operator" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="操作" min-width="130">
                <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleEdit(scope.row.id)">编辑</el-button>
                        <el-button size="mini" class="themed-button" type="danger" @click="deleteById(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination :current-page.sync="page"  :page-sizes="[5, 10, 15, 50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
    </div>

    <el-dialog title="新增卡" :visible.sync="dialogImportCard" destroy-on-close>
        <el-form label-position="right" label-width="120px" :model="importCardForm"  ref="importCardForm" :rules="importCardrules">
            <el-form-item label="库存号"  prop="cardBankCode">
                    <el-select v-model="importCardForm.cardBankCode" placeholder="请选择" filterable >
                        <el-option v-for="item in cardBankCodeList" :key='item.index' :label="item.cardBankCode" :value="item.cardBankCode"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="数量"  prop="amount">
					<el-input v-model="importCardForm.amount"></el-input>
			</el-form-item>

            <!-- <el-form-item label="客户"  prop="customerName">
					<el-input v-model="importCardForm.customerName"></el-input>
			</el-form-item> -->

            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='importCardfile'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="ICCID:">
                <el-input v-model="importCardForm.iccids" type="textarea"  placeholder="请输入接入号和ICCID,每行一组，用英文逗号隔开"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="importCardExcel()">导入excel模板</el-button>
                <el-button class="themed-button" type="info" @click="exportTemplateExcel('import.xls')">导出excel模板</el-button>
                <el-button class="themed-button" type="primary" @click="dialogImportCard = false">关 闭</el-button>
            </div>
    </el-dialog>

    <el-dialog title="删除卡" :visible.sync="dialogDeleteCardByExcel" destroy-on-close>
        <el-form label-position="right" label-width="180px" :model="deleteCardByExcelFrom"  ref="deleteCardByExcelFrom" :rules="deleteCardrules">
            <el-form-item label="卡库编号"  prop="cardBankCodeSpan">
                    <el-select v-model="deleteCardByExcelFrom.cardBankCodeSpan" placeholder="请选择" filterable >
                        <el-option v-for="item in cardBankCodeSpanList" :key='item.index' :label="item.imeiBankCode" :value="item.cardBankCode"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="上传卡号列表(excel):" required>
                <input class="file-input" type="file" ref='deleteCardfile'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="deleteCardExcel()">导入excel模板</el-button>
                <el-button class="themed-button" type="info" @click="exportTemplateExcel('import.xls')">导出excel模板</el-button>
                <el-button class="themed-button" type="primary" @click="dialogDeleteCardByExcel = false">关 闭</el-button>
            </div>
    </el-dialog>

    <el-dialog title="编辑" :visible.sync="dialogEdit" destroy-on-close>
        <el-form label-position="right" label-width="120px" :model="editForm"  ref="editForm" :rules="editRules">
            <!-- <el-form-item label="库编号:"  prop="cardBankCode">
                <el-input v-model="editForm.cardBankCode" autocomplete="off"></el-input>
            </el-form-item>
			<el-form-item label="运营商:"  prop="accountName">
                <el-input v-model="editForm.accountName" autocomplete="off"></el-input>
            </el-form-item> -->
            <el-form-item label="客户:"  prop="customerName">
                <el-input v-model="editForm.customerName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注"  prop="remark">
                    <el-input v-model="editForm.remark" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureEdit('editForm')">确 定</el-button>
                <el-button class="themed-button" type="primary" @click="dialogEdit = false">关 闭</el-button>
            </div>
    </el-dialog>

    </div>
</template>
<script>
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'
import {imeiBankCodeList,cardBankCodeList} from '@/api/meCardBindManagement/imeiBindHistory.js'
import {cardList,importCardByExcel,exportCardList,cardBankCodeSpanList,deleteCardByExcel,bindCardFindById,editCardList,deleteCardList} from '@/api/meCardBindManagement/cardList.js'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
export default {
    // name:'cardList',
    mixins:[resize],
    data(){
        return{
            imeiBankCodeList:[],
            cardBankCodeList:[],
            cardListForm:{
                iccid:'',
                phone:'',
                cardBankCode:'',
                accountName:'',
                customerName:'',

            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogImportCard:false,
            importCardForm:{
                cardBankCode:'',
                amount:'',
                iccids:'',
            },
            dialogDeleteCardByExcel:false,
            deleteCardByExcelFrom:{
                cardBankCodeSpan:'',
            },
            cardBankCodeSpanList:[],
            dialogEdit:false,
            editForm:{
                id:'',
                customerName:'',
                remark:'',
            },
            editRules:{
                customerName:[
                    {required: true, message: '请输入客户名称', trigger: 'blur'}
                ],
            },
            importCardrules:{
                cardBankCode:[
                    {required: true, message: '请选择库编号', trigger: 'blur'}
                ],
                 amount:[
                    {required: true, message: '请填写数量', trigger: 'blur'}
                ],

            },
            deleteCardrules:{
                cardBankCodeSpan:[
                    {required: true, message: '请选择卡库编号', trigger: 'blur'}
                ],
            }
        }
    },
    created() {
       this.getImeiBankCodeList() 
       this.getCardBankCodeList()
       this.getCardList()
    },
    methods: {
        getCardList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.cardListForm,params)
            cardList(this.cardListForm).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        getImeiBankCodeList(){
            imeiBankCodeList().then(res=>{
                this.imeiBankCodeList = res
            })
        },
        getCardBankCodeList(){
            cardBankCodeList().then(res=>{
                this.cardBankCodeList = res
            })
        },
        getCardBankCodeSpanList(){
            cardBankCodeSpanList().then(res=>{
                this.cardBankCodeSpanList = res
            })
        },
        addCard(){
            this.dialogImportCard = true
        },
        importCardExcel(){
            this.$confirm("确定导入吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['importCardfile'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            if(!!this.importCardForm.cardBankCode){
                                param.append("cardBankCode",this.importCardForm.cardBankCode)
                            }else{
                                this.$alert('请选择库编号', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }

                            if(!!this.importCardForm.amount){
                                param.append("amount",this.importCardForm.amount)
                            }else{
                                this.$alert('请输入数量', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
							param.append("fileurl",file)
                            importCardByExcel(param).then(res=>{
                                this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
                                this.dialogImportCard= false
                                this.$router.push('/tob/i_import_list')
                            	});
                            })
                        }
                    }else if(!!this.importCardForm.iccids){
                        let param = new FormData()
                            if(!!this.importCardForm.cardBankCode){
                                param.append("cardBankCode",this.importCardForm.cardBankCode)
                            }else{
                                this.$alert('请选择库编号', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
                            if(!!this.importCardForm.amount){
                                param.append("amount",this.importCardForm.amount)
                            }else{
                                this.$alert('请输入数量', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
							param.append("iccids",this.importCardForm.iccids)
                            importCardByExcel(param).then(res=>{
                                  if (res.system_result_key == '0') {
                           this.$alert('添加成功', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
                                  this.dialogImportCard= false
                            	});
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                            })
                    }
                    else{
                        this.$alert('请选择文件或输入ICCID', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }
                    })
        },
        deleteCardExcel(){
            this.$confirm("确定导入吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['deleteCardfile'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                    }else{
                            let param = new FormData()
                            if(!!this.deleteCardByExcelFrom.cardBankCodeSpan){
                                param.append("cardBankCodeSpan",this.deleteCardByExcelFrom.cardBankCodeSpan)
                            }else{
                                this.$alert('请选择卡库编号', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
							param.append("fileurl",file)
                            deleteCardByExcel(param).then(res=>{
                                  if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
                                this.dialogDeleteCardByExcel = false
                                this.$router.push('/tob/i_import_list')
                            	});
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
                })
        },
        exportTemplateExcel(name){
            	exportTemplate({'name':name}).then(res=>{
                	const filename = name
					Utils.createDownload(res,filename)
            	})
        },

        deleteCard(){
            this.dialogDeleteCardByExcel = true
            this.getCardBankCodeSpanList()
        },
        addBank(){
            this.dialogAddBank = true
        },

        handleEdit(id){
            this.dialogEdit = true
            this.editForm.id=id
            bindCardFindById({id}).then(res=>{
                this.editForm.customerName = res.entity.customerName
                this.editForm.remark = res.entity.remark
            })
        },

        sureEdit(formName){
            this.$confirm('确定提交修改吗？','消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    editCardList(this.editForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '修改成功',
					        type: 'success',
							center: true
                        })
                        this.$refs[formName].resetFields()
                        this.dialogEdit=false
                        this.getCardList() 
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            }).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
			});
        },
        
        deleteById(id){
            this.$confirm("您确定要删除吗?",'消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                deleteCardList({id}).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						})
						this.getCardList()
				})
            }).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
			});
        },
        searchTable(){
            this.page = 1 
            this.getCardList()
        },
        onPageChange(page){
			this.page = page
			this.getCardList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getCardList()
        },
        exportExcel(){
             exportCardList(this.cardListForm).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
         },
    },
}
</script>
<style>
    
</style>
