<template>
	<div  class="order-agent-profit fadeInRight">
		<div class="title-box clearfix">
			 <span class="m-title">代理商预充值</span>
			<el-form :inline="true" :model="formInline"  class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item id="agentGet" :style="{display:rechargeDisplay}">
					<el-form-item label="代理商名称：">
						<el-input v-model="formInline.agentName" placeholder="代理商名称"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索</el-button>
					</el-form-item>
				</el-form-item>
				<el-form-item>
					<el-form-item>
						<el-button type="primary" @click="agentRecharge" :style="{display:rechargeDisplay}" icon="el-icon-check" class="themed-button">下级代理商充值</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="gotokaipiao"  v-if="currentRole != 0" icon="el-icon-check" class="themed-button">去开票</el-button>
					</el-form-item>
					<el-form-item v-if="agentType!=0">
						<el-button type="primary" @click="selfRecharge"  icon="el-icon-check" class="themed-button">自助充值</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="updateOperationPassword" icon="el-icon-refresh-right" class="themed-button">重置操作密码</el-button>
					</el-form-item>
				</el-form-item>
				

			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="agentName" label="代理商名称">
				</el-table-column>
				<el-table-column prop="rechargeMoney" label="充值金额">
				</el-table-column>
				<el-table-column prop="balanceMoney" label="剩余金额">
				</el-table-column>
				<el-table-column prop="consumeMoney" label="消费金额">
				</el-table-column>
				<el-table-column prop="createUser" label="创建人">
				</el-table-column>
				<el-table-column prop="createDateTime" label="创建时间">
				</el-table-column>
				<el-table-column prop="warnMoney" label="余额预警值">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<span @click='updateRecord(scope.row.id)' class="right_agent">余额修改 </span>
						<span @click='rechargeDetails(scope.row.agentId)' class="right_agent">充值详情</span>
						<span @click='updateWarnMoney(scope.row.agentId)'>预警值</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
		
		<el-dialog title="充值" :visible.sync="dialogRechargeVisible" :before-close="handleCloseRecharge">
			<el-form :model="formRecharge" :rules="rulesRecharge" ref="ruleFormRecharge">
				<el-form-item label="代理商名：" :label-width="formLabelWidth" prop="agentId">
					<el-select v-model="formRecharge.agentId" clearable filterable placeholder="请选择" @change="agentChange">
						<el-option label="请选择" value="0">
						</el-option>
						<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="充值金额：" :label-width="formLabelWidth" prop="rechargeMoney">
					<el-input v-model="formRecharge.rechargeMoney" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="操作密码：" :label-width="formLabelWidth" prop="operationPassword">
					<el-input v-model="formRecharge.operationPassword" autocomplete="off" type="password"></el-input>
				</el-form-item>
				<el-form-item label="备注：" :label-width="formLabelWidth" prop="description">
					<el-input v-model="formRecharge.description" autocomplete="off" type="textarea"
  :rows="2" maxlength="255"></el-input>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="formRechargeSubmit('ruleFormRecharge')">立即充值</el-button>
				<el-button @click="cancelDialogRecharge('ruleFormRecharge')">取 消</el-button>
			</div>
		</el-dialog>
		
		<el-dialog title="重置操作密码" :visible.sync="dialogResetPswVisible" :before-close="handleCloseResetPsw">
				<el-form :model="formResetPsw"  ref="ruleFormResetPsw">
					<el-form-item label="原密码：" :label-width="formLabelWidth" required >
						<el-input v-model="formResetPsw.password" autocomplete="off" type="password"></el-input>
					</el-form-item>
					<el-form-item label="新密码：" :label-width="formLabelWidth" required >
						<el-input v-model="formResetPsw.newPassword" autocomplete="off" type="password"></el-input>
					</el-form-item>
					<el-form-item label="确认密码：" :label-width="formLabelWidth" required>
						<el-input v-model="formResetPsw.confirmPassword" autocomplete="off" type="password"></el-input>
					</el-form-item>
		
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="formResetPswSubmit('ruleFormResetPsw')">确定</el-button>
					<el-button @click="cancelDialogResetPsw('ruleFormResetPsw')">取 消</el-button>
				</div>
			</el-dialog>
			<el-dialog title="修改余额" :visible.sync="dialogChangeBalanceVisible" :before-close="handleCloseChangeBalance">
					<el-form :model="formChangeBalance" :rules="rulesChangeBalance" ref="ruleFormChangeBalance">
						<el-form-item label="减扣金额：" :label-width="formLabelWidth"  prop="deductMoney" >
							<el-input v-model="formChangeBalance.deductMoney" autocomplete="off" ></el-input>
						</el-form-item>
						<el-form-item label="充值金额：" :label-width="formLabelWidth" required >
							<el-input v-model="formChangeBalance.rechargeMoney" autocomplete="off"  readonly></el-input>
							<!-- <span>{{formChangeBalance.rechargeMoney}}</span> -->
						</el-form-item>
						<el-form-item label="剩余金额：" :label-width="formLabelWidth" required >
							<el-input v-model="formChangeBalance.balanceMoney" autocomplete="off"  readonly></el-input>
							<!-- <span>{{formChangeBalance.balanceMoney}}</span> -->
						</el-form-item>
						<el-form-item label="修改描述：" :label-width="formLabelWidth" prop="description1">
							<el-input v-model="formChangeBalance.description1" rows="10" cols="100" maxlength="255" type="textarea"></el-input>
						</el-form-item>
						<el-form-item label="操作密码：" :label-width="formLabelWidth"  prop="operationPassword1" >
							<el-input v-model="formChangeBalance.operationPassword1" autocomplete="off"  type="password"></el-input>
						</el-form-item>
			
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="formChangeBalanceSubmit('ruleFormChangeBalance')">立即减扣</el-button>
						<el-button @click="cancelDialogChangeBalance('ruleFormChangeBalance')">取 消</el-button>
					</div>
				</el-dialog>

				<el-dialog title="选择支付方式" :visible.sync="dialogSelfStyleVisible" width="500px">
					<el-form :model="selfRechargeStyleForm">
						<el-form-item label="支付方式" label-width="100px">
							<el-radio-group v-model="selfRechargeStyleForm.payStyle">
								<el-radio :label="0">自动充值（目前支持微信）</el-radio>
								<el-radio :label="1" v-if="agentType!=0&&agentType!=4" >线下转款</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button @click="dialogSelfStyleVisible = false">取 消</el-button>
						<el-button type="primary" @click="toPayPage">确 定</el-button>
					</div>
				</el-dialog>

				<el-dialog title="线下转账审核" :visible.sync="dialogOfflinePayVisible" width="500px" destroy-on-close>
					<el-form :model="offlinePayForm" :rules="rules" ref="offlinePayForm" label-width="100px">
					<el-form-item label="付款方式" prop="payType" required>
						<el-select v-model="offlinePayForm.payType" placeholder="请选择付款方式">
						<el-option label="支付宝" value="2"></el-option>
						<el-option label="银行卡" value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="充值金额" prop="money" required>
						<el-input v-model="offlinePayForm.money" style='width:220px'></el-input>
					</el-form-item>
					<el-form-item label="上传凭证" required>
						<input ref="fileInput" type="file" class="form-control add_file_inter" @change="changeFile()" name="file">
					</el-form-item>
					<el-form-item label="账号" prop="accountNumber" required>
						<el-input v-model="offlinePayForm.accountNumber" style='width:220px'></el-input>
					</el-form-item>
					<el-form-item label="转账单号" prop="transactionId" required>
						<el-input v-model="offlinePayForm.transactionId" style='width:220px'></el-input>
					</el-form-item>
					<el-form-item label="备注" prop="remark">
						<el-input type="textarea" v-model="offlinePayForm.remark" style="width:300px"></el-input>
					</el-form-item>
					<el-form-item size="large">
						<el-button type="primary" @click="checkApply('offlinePayForm')">提交审核</el-button>
					</el-form-item>
            
        </el-form>
				</el-dialog>
		
	</div>
</template>

<script>
    import { mapGetters } from "vuex";
	import onresize from "@/mixins/resize.js"
	import {
		getAgentType,
		agentRechangeList,
		getAgentList,
		agentRechangeSubmit,
		agentResetPswSubmit,
		changeBalance,
		changeBalanceSubmit,
		updateWarnMoney,
		uploadPayOfflineUrl,
		offlineTransfer
	} from "@/api/agentManagement.js"
	export default {
		name:'agentPrecharge',
		mixins: [onresize],
		data() {
			var validateAgentId = (rule, value, callback) => {
				if (null == value || "" == value || value == 0) {
					return callback(new Error("请选择需要充值的代理商！"))
				} else {
					callback()
				}
			}
			var validateRechargeMoney = (rule, value, callback) => {
				if (null == value || "" == value.trim()) {
					return callback(new Error("请输入充值金额！"))
				} else if(! /^[\d\.]*$/.test(value)) {
					return callback(new Error("只能输入数字！"))
				}else {
					callback()
				}
			}
			var validateDeductMoney = (rule, value, callback) => {
				if (null == value || "" == value.trim()) {
					return callback(new Error("请输入金额！"))
				} else if(! /^[\d\.]*$/.test(value)) {
					return callback(new Error("只能输入数字！"))
				}else {
					callback()
				}
			}
			var validateOperPassword = (rule, value, callback) => {
				if (null == value || "" == value.trim()) {
					return callback(new Error("请确认操作密码！"))
				} else {
					callback()
				}
			}
			var validateDescription = (rule, value, callback) => {
				if (null == value || "" == value.trim()) {
					return callback(new Error("请输入备注！"))
				} else {
					callback()
				}
			}
			var validateDescription1 = (rule, value, callback) => {
				
				if (null == value || "" == value.trim()) {
					return callback(new Error("请输入修改备注！"))
				} else {
					callback()
				}
			}
			var validateOperPassword1 = (rule, value, callback) => {
				if (null == value || "" == value.trim()) {
					return callback(new Error("请输入操作密码！"))
				} else {
					callback()
				}
			}
			
			return {
				dialogSelfStyleVisible:false,
				offlinePayForm:{
					payType:'',
					money:'',
					proofUrl:'',
					accountNumber:'',
					transactionId:'',
					remark:'',
            	},
				selfRechargeStyleForm:{
					payStyle:0
				},
				agentType:'',
				dialogOfflinePayVisible:false,

				formInline: {
					agentName: "",
				},
				rechargeDisplay: "",
				tabledata: [],
				page: 1,
				pageSize: 15,
				total: 0,
				dialogRechargeVisible: false,
				options: [],
				formRecharge: {
					agentId: "0",
					rechargeMoney: "",
					operationPassword: "",
					description: "",
				},
				rulesRecharge: {
					agentId: [{
						required: true,
						validator: validateAgentId,
						trigger: 'blur'
					}],
					rechargeMoney: [{
						required: true,
						validator: validateRechargeMoney,
						trigger: 'blur'
					}],
					operationPassword: [{
						required: true,
						validator: validateOperPassword,
						trigger: 'blur'
					}],
					description: [{
						required: true,
						validator: validateDescription,
						trigger: 'blur'
					}, ]
				},
				rules:{
					payType:[{required: true,message: '请选择支付方式',trigger: 'blur'}],
					money:[{required: true,message: '请填写金额',trigger: 'blur'}],
					accountNumber:[{required: true,message: '请填写账号',trigger: 'blur'}],
					transactionId:[{required: true,message: '请填写转账单号',trigger: 'blur'}],
            	},
				formLabelWidth: "200px",
        dialogResetPswVisible:false,
				formResetPsw:{
					id:"",
					password:"",
					newPassword:"",
					confirmPassword:"",
				},
				dialogChangeBalanceVisible:false,
			formChangeBalance:{
				id:"",
				deductMoney:"",
				description1:"",                   
				operationPassword1:"",
				rechargeMoney:"",
				balanceMoney:"",
			},
			rulesChangeBalance:{
				deductMoney: [{
					required: true,
					validator: validateDeductMoney,
					trigger: 'blur'
				}],
				description1: [{
					required: true,
					validator: validateDescription1,
					trigger: 'blur'
				}],
				operationPassword1:[{required: true,
					validator: validateOperPassword1,
					trigger: 'blur'}]
			}

			}
		},
		created() {
			getAgentType(this.formInline).then(responseResult => {
				this.agentType = responseResult.agentType
				if (responseResult.agentTree == "7") {
					this.rechargeDisplay = "none"
				}
			})
			this.getAgentRechangeList(this.formInline)

		},
		methods: {
		gotokaipiao(){
          this.$router.push({
						path:'/agentManagement/InvoiceRequest'
					})
		},
			changeFile(){
					let formData = new FormData()
					formData.append('file',this.$refs['fileInput'].files[0])
					uploadPayOfflineUrl(formData).then(res=>{
						this.offlinePayForm.proofUrl = res.url		
					})
			},
			checkApply(formName){
				this.$refs["offlinePayForm"].validate((validate) => {
						if (validate) {
							if(!this.offlinePayForm.proofUrl){
								this.$alert('请上传凭证', '提示', {
									confirmButtonText: '确定',
								});
								return
							}
							offlineTransfer(this.offlinePayForm).then(res=>{
								this.$message({
									type: 'success',
									message:'提交审核成功'
								});
								this.$refs['fileInput'].value = ''
								this.$refs[formName].resetFields()
								this.dialogOfflinePayVisible = false	
							})
						}
				})
        	},
			getAgentRechangeList(params) {
				agentRechangeList(params).then(res => {
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				}
				Object.assign(params, this.formInline)
				this.getAgentRechangeList(params)
			},
			onPageChange(page) {
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				}
				Object.assign(params, this.formInline)
				this.getAgentRechangeList(params)
			},
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				}
				Object.assign(params, this.formInline)
				this.getAgentRechangeList(params)
			},
			selfRecharge(){
				this.dialogSelfStyleVisible = true
			},
			toPayPage(){
				this.dialogSelfStyleVisible = false
				if(this.selfRechargeStyleForm.payStyle == 0){
					this.$router.push({
						path:'/agentManagement/payAuto',
						query:{}
					})
				}else if(this.selfRechargeStyleForm.payStyle == 1){
					// this.$router.push({
					// 	path:'/agentManagement/payOffline',
					// 	query:{}
					// })
					this.dialogOfflinePayVisible = true
				}
			},
			agentRecharge() {
				this.dialogRechargeVisible = true
				let params = {
					agentId: "",
					rechargeMoney: "",
					operationPassword: "",
					description: "",
				}
				getAgentList(params).then(result => {
					this.options = result
				})
			},
			updateOperationPassword() {
        this.dialogResetPswVisible = true
			},
			updateRecord(id) {
				this.dialogChangeBalanceVisible = true
				this.formChangeBalance.id = id
				let params = {
					id:id,
					deductMoney:"",
					description: "",                      
					operationPassword:"",
				}
				
			 changeBalance(params).then(res=>{
				this.formChangeBalance.balanceMoney  = res.entity.balanceMoney
				this.formChangeBalance.rechargeMoney  = res.entity.rechargeMoney
			})
			},
			rechargeDetails(id) {
       let selections=[{"id":id}];
			 //console.log(selections[0])
        this.$router.replace({
					path:"/agentManagement/chargeLogDetail",
					query:selections[0],
				})
       // parent.parent.addTab("loadFail","充值日志详情",'../pages/agentRecharge/rechargeLogList.html?1=1&callbackFunction=reloadPageData&targetFrame='+frameId,"activity");
			},
			updateWarnMoney(id) {
         this.$prompt('设置预警值', '设置预警值', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
									 // center: true,
									inputValidator:(val)=>{
										//console.log(val)
										if(val!=null){
											val = val.trim()
										}
										
										if(val===""||val===null) {
										    return "请输入金额";
										}
										if(val<=0) {
										    return "金额错误。";
										}
										if (isNaN(val)) {
										    return "金额错误。";
										}
									},
                }).then(({ value }) => {
									let params = {
										agentId:id,
										warnMoney:value,
										agentName:this.formInline.agentName,
									}
								
									updateWarnMoney(params).then(res=>{
										let params = {
											pageSize:this.pageSize,
											pageNo:this.page,
											agentName:this.formInline.agentName,
										}
										this.getAgentRechangeList(params)
										this.$message({
										  type: 'success',
										  message: "设置预警值成功",
										});
									})
                  
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '取消设置预警值'
                  });       
                });
			},
			agentChange(){
				this.$refs['ruleFormRecharge'].clearValidate("agentId")
			},
			handleCloseRecharge(done) {
				this.$refs['ruleFormRecharge'].resetFields()
				done()
			},
			formRechargeSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						agentRechangeSubmit(this.formRecharge).then(responseResult => {
							if (responseResult.slide_msg_key == 1) {
								return
								this.$alert(responseResult.slide_msg_message_key, '提示', {
									confirmButtonText: '确定',
									callback: action => {
									}
								})
							}
							this.dialogRechargeVisible = false
							this.$refs[formName].resetFields()
							let params = {
								pageSize:this.pageSize,
								pageNo:this.page,
								agentName:this.formInline.agentName,
							}
							this.getAgentRechangeList(params)
							this.$alert("充值成功", '提示', {
								confirmButtonText: '确定',
								callback: action => {
								}
							})
						})
					} else {
						return false
					}
				});
			},
			cancelDialogRecharge(formName) {
				this.dialogRechargeVisible = false
				this.$refs[formName].resetFields()

			},
			handleCloseResetPsw(done){
				this.formResetPsw.confirmPassword =""
				this.formResetPsw.newPassword = ""
				this.formResetPsw.password = ""
				done()
			},
			formResetPswSubmit(formName){
				if (null == this.formResetPsw.password || "" == this.formResetPsw.password.trim()) {
				 return  this.$alert("请输入原始密码", '提示', {
				   	confirmButtonText: '确定',
				   	callback: action => {
				   	}
				   }) 
				}
				
				if (null == this.formResetPsw.newPassword || "" == this.formResetPsw.newPassword.trim()) {
						return  this.$alert("请输入新密码", '提示', {
						  	confirmButtonText: '确定',
						  	callback: action => {
						  	}
						  })
				}
				if (null == this.formResetPsw.confirmPassword || "" == this.formResetPsw.confirmPassword.trim()) {
				    
						return  this.$alert("请确认新密码", '提示', {
						  	confirmButtonText: '确定',
						  	callback: action => {
						  	}
						  })
				}
				if(this.formResetPsw.confirmPassword  != this.formResetPsw.newPassword ){
						return  this.$alert("新密码与确认的新密码不相同", '提示', {
						  	confirmButtonText: '确定',
						  	callback: action => {
						  	}
						  })
				}
				agentResetPswSubmit(this.formResetPsw).then(responseResult=>{
					if (responseResult.slide_msg_key == 1) {
									return		this.$alert(responseResult.slide_msg_message_key, '提示', {
											  	confirmButtonText: '确定',
											  	callback: action => {
											  	}
											  })
					}
				  this.dialogResetPswVisible = false
					this.formResetPsw.confirmPassword =""
					this.formResetPsw.newPassword = ""
					this.formResetPsw.password = ""
					this.$alert("密码修改成功", '提示', {
					  	confirmButtonText: '确定',
					  	callback: action => {
					  	}
					  })
				})
				
			},
			cancelDialogResetPsw(formName){
				this.dialogResetPswVisible = false
				this.formResetPsw.confirmPassword =""
				this.formResetPsw.newPassword = ""
				this.formResetPsw.password = ""
			},
			handleCloseChangeBalance(done){
					this.$refs['ruleFormChangeBalance'].resetFields()
				done()
			},
			formChangeBalanceSubmit(formName){
				  this.$refs[formName].validate((valid) => {
				          if (valid) {
										let params = {
											id:this.formChangeBalance.id,
											deductMoney:this.formChangeBalance.deductMoney,
											description:this.formChangeBalance.description1,
											operationPassword:this.formChangeBalance.operationPassword1,
										}
										
										changeBalanceSubmit(params).then(responseResult=>{
											if (responseResult.slide_msg_key == 1) {
															return		this.$alert(responseResult.slide_msg_message_key, '提示', {
																	  	confirmButtonText: '确定',
																	  	callback: action => {
																	  	}
																	  })
											}
										  this.dialogChangeBalanceVisible = false
											this.$refs['ruleFormChangeBalance'].resetFields()
											let params = {
												pageSize:this.pageSize,
												pageNo:this.page,
												agentName:this.formInline.agentName,
											}
											this.getAgentRechangeList(params)
											this.$alert("减扣成功", '消息提示', {
											  	confirmButtonText: '确定',
											  	callback: action => {
											  	}
											  })
										})
				         
				          } else {
				            return false;
				          }
				        })
			
				
			},
			cancelDialogChangeBalance(formName){
				this.dialogChangeBalanceVisible =false
				this.$refs[formName].resetFields()
				
			},
			

		},
		 computed: {
    ...mapGetters(["currentRole"]),
  },

	}
</script>

<style>
	.right_agent{margin-right: 8px;}
</style>
