import request from './request.js'
export function chargeGroupList(params) {
    return request({
        url: 'chargeGroup/list.do?',
        method: 'post',
        data: params
    })
}
export function chargeGroupUpdate(params) {
    return request({
        url: 'chargeGroup/update.do?',
        method: 'post',
        data: params
    })
}
export function findWarnSettingByGroupId(params) {
    return request({
        url: 'chargeGroup/findWarnSettingByGroupId.do?',
        method: 'post',
        data: params
    })
}
export function updateWarnSettingByGroupId(params) {
    return request({
        url: 'chargeGroup/updateWarnSettingByGroupId.do?',
        method: 'post',
        data: params
    })
}
export function cardList(params) {
    return request({
        url: 'chargeGroup/cardList.do?',
        method: 'post',
        data: params
    })
}
export function exportGroup(params) {
    return request({
        url: 'chargeGroup/export.do?',
        method: 'post',
        data: params
    })
}

// 新增
export function addGroup(params) {
    return request({
        url: 'chargeGroup/insert.do?',
        method: 'post',
        data: params
    })
}

// 获取套餐
export function getMealByAccountId(params) {
    return request({
        url: 'chargeGroup/getMealByAccountId.do?',
        method: 'post',
        data: params
    })
}

export function deleteGroup(params) {
    return request({
        url: 'chargeGroup/deleteById.do?',
        method: 'post',
        data: params
    })
}

// 网卡导入
export function importCardByExcel(params) {
    return request ({
        url:'chargeGroup/importCardSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function getMealList(params) {
    return request({
        url: 'chargeGroup/getMealListByGroupId.do?',
        method: 'post',
        data: params
    })
}

export function importBatchRechargeByExcel(params) {
    return request ({
        url:'chargeGroup/batchRecharge.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 批量删除
export function batchDelete(params) {
    return request ({
        url:'chargeGroup/batchDelete.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function deleteCard(params) {
    return request({
        url: 'chargeGroup/deleteCard.do?',
        method: 'post',
        data: params
    })
}

// 批量断网设置
export function importBatchStopCardByExcel(params) {
    return request ({
        url:'chargeGroup/batchCardStop.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}



