<template>

<div class="delect-imei">
    <el-form ref="form" label-position="right" label-width="200px" style="max-height:500px;overflow:auto">
        <el-form-item label="IMEI库编号:">
            <el-select  v-model="imeiBankCodeSpan" filterable>
                <el-option v-for="(item,index) in imeiBankCodeList" :label="item.imeiBankCode" :value="item.imeiBankCode" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="上传IMEI文件（excel）:" required>
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
            </div>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel('form')">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {importDeleteSync,imeiBankCode} from '@/api/meCardBindManagement/imeiLibrary.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    data(){
        return {
            imeiBankCodeSpan:'',
            imeiBankCodeList:[]
        }
    },
    mounted(){
        this._imeiBankCode()
    },
    methods:{
        uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    if(this.imeiBankCodeSpan == ''){
                        messageBox(this,'请选择IMEI库编号')
                        return
                    }

                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('imeiBankCodeSpan',this.imeiBankCodeSpan)
                    importDeleteSync(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.$emit('close-dialog')
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        importExcel(){
            let name = 'importImei2.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        _imeiBankCode(){
            imeiBankCode({imeiBankCodeSpan:this.imeiBankCodeSpan}).then((res)=>{
                this.imeiBankCodeList = res
            })
        }
    }
}
</script>

