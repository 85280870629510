<template>
  <div class="fadeInRight payOrder">
    <div class="title-box clearfix">
      <span class="m-title"></span>
    </div>
    <div class="content-box">
      <div class="align-center">
        <div class="finish_order_body">
          <div class="finish_order_symbol">
            <i class="el-icon-circle-check"></i>
          </div>
          <div class="finish_order_detail">
            <p class="tip">订单提交成功，等待支付！</p>
            <p title="点击进入查看订单详情" class="margin-bottom10">
              订单编号：<span class="link" @click="orderDetail"
                >{{ orderNo }}</span
              >
            </p>
            <p class="margin-bottom10">
              应付金额：<span class="color-waring">￥{{curMoney}}元</span>
            </p>
            <p>账户余额：￥{{leftMoney}}元  <span v-if="islarge">(余额不足，请先 <span class="link" @click="recharge" > 充值</span>)</span></p>
          </div>
        </div>
        <div class="finish_order_footer">
          <el-button type="primary" @click="surePay"  :disabled="islarge">确认支付</el-button>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import {payOrder,getLeftMoney} from "@/api/onlinePurchase.js";

export default {
  name: "payOrder",
  mixins: [onresize],
  data() {
    return {
      orderNo: "",
      leftMoney:"",
      curMoney:"",
      islarge:false,
    };
  },
  created() {
   this.orderNo =  this.$route.query.orderNum
  this.curMoney =   this.$route.query.money
    this.getLeftMoney()
  },
  watch: {},

  computed: {},
  methods: {
    getLeftMoney(){
   getLeftMoney({}).then(res=>{
    console.log(res.balanceMoney)
    this.leftMoney = res.balanceMoney?res.balanceMoney:0
    if(parseFloat(this.leftMoney)>=parseFloat(this.curMoney)){
        this.islarge= false
    }else{
      this.islarge= true
    }
})
    },
    orderDetail() {
    this.$router.push({path:'/shopping/orderDetails',query:{orderNum:this.orderNo}})
    },
    surePay() {
      payOrder({orderNum:this.orderNo}).then(res=>{
           this.$message({
              type: "success",
              message: "支付成功",
            });
          this.$router.push({path:'/shopping/orderDetails',query:{orderNum:this.orderNo}})  
      })
    },
    recharge(){
       this.$router.push({path:'/finance/recharge'})
     
    },
  },
};
</script>

<style>
.payOrder .finish_order_symbol {
  margin-top: 40px;
  color: #20a0ff;
}
.finish_order_symbol i {
  font-size: 6rem;
}
.payOrder .finish_order_detail {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.finish_order_detail .tip {
  margin-bottom: 20px;
  font-size: 20px !important;
}
.payOrder .align-center {
  text-align: center !important;
}
.payOrder .margin-bottom10 {
  margin-bottom: 10px !important;
}
.payOrder .finish_order_body {
  display: inline-block;
}
.finish_order_detail .link {
  cursor: pointer;
  color: #20a0ff;
}
.finish_order_detail .color-waring {
  color: #f65623 !important;
  font-size: 20px !important;
}
</style>
