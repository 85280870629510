/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

// /complaintsList.vue
export function complaintList(params) {
    return request({
        url:'complaint/list.do?',
        method:'post',
        data:params,
    })
}
export function complaintAdd(params) {
    return request({
        url:'complaint/add.do?',
        method:'post',
        data:params,
    })
}
export function complaintUpdate(params) {
    return request({
        url:'complaint/update.do?',
        method:'post',
        data:params,
    })
}
export function complaintDel(params) {
    return request({
        url:'complaint/del.do?',
        method:'post',
        data:params,
    })
}
export function complaintExport(params) {
    return request({
        url:'complaint/exportSync.do?',
        method:'post',
        data:params,
    })
}