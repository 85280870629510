<template>
    <div class="sales-customer fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">销售客户统计</span>
            <el-form inline ref="salesCustomerForm" v-model="salesCustomerData" class="right themed-form search-form" style="height: 40px;line-height: 40px;">
                <el-form-item label="批次号">
                    <search-select :optionsMetaAll="optionsOutCode" v-model="salesCustomerData.outCode"></search-select>
                </el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="saleName"
                    label="销售名称"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="outCode"
                    label="出库批次"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    label="产品名称"
                    min-width="120">
                    <template slot-scope="scope">
                        <span>{{dictMapTrans(scope.row.productName,dictMap.productName)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    label="产品类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <span>{{dictMapTrans(scope.row.productType,dictMap.productType)}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="stockExpireTime"
                    label="库存到期时间"
                    min-width="150">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="cardFeeTotal"
                    label="卡板费总计"
                    min-width="120">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="batchAmount"
                    label="剩余库存数"
                    min-width="120">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="operator"
                    label="操作人"
                    min-width="120">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="createTime"
                    label="统计时间"
                    min-width="150">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="remark"
                    label="备注"
                    min-width="150">
                </el-table-column>
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {outCodeList,salesCustomer,exportSalesCustomer} from '@/api/stockManagement.js'
import resize from '@/mixins/resize.js'
export default {
    name:'salesCustomer',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return {
            salesCustomerData:{
                outCode:'',
            },
            optionsOutCode:[
                {label:'全部',value:''}
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0, 
            dictMap:[],
        }
    },
    created(){
        this.getOutCode()
        this.getSalesCustomerList()
    },
    methods:{
        getOutCode(){
            outCodeList().then(res=>{
                this.dictMap = res.dict
                let arrList = [{label:'全部',value:''}]
                res.data.forEach(item=>{
                    arrList.push({label:item.outCode,value:item.outCode})
                })
                this.optionsOutCode = arrList
            })
        },
        dictMapTrans(val,dictGroup){
            let returnVal = val
            dictGroup.forEach(element=>{
                if(val == element.dictKey){
                    returnVal = element.dictValue
                }
            })
            return returnVal
        },
        getSalesCustomerList(){
            let parame = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.salesCustomerData,parame)
            salesCustomer(this.dicSettingData).then(res=>{
                this.tableData = res.data.rows
                this.total = res.data.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getSalesCustomerList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSalesCustomerList()
        },
        onSearch(){
            this.page = 1
            this.getSalesCustomerList()
        },
        exportExcel(){
            exportSalesCustomer(this.salesCustomerData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
       
    }
}
</script>
<style>
    .sales-customer .el-form-item__label{
        vertical-align: top;
    }
</style>