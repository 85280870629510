<template>
  <div class="operatorMeal table-hook fadeInRight">
    <div class="title-box">
      <span class="m-title">运营商套餐</span>
      <el-form
        ref="operatorMeal"
        inline
        class="hook right themed-form search-form"
        style="height: 40px; line-height: 40px"
      >
        <el-form-item label="运营商:">
          <el-select v-model="searchForm.accountId" filterable>
            <el-option
              v-for="item in accountLists"
              :key="item.index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="themed-button"
            @click="searchTable"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="addMeal"
            icon="el-icon-plus"
            class="themed-button"
            >手动添加</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="addMealByid"
            icon="el-icon-plus"
            class="themed-button"
            >卡号添加</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        :data="tableData"
        stripe
        border
        :max-height="maxTableHeight"
      >
        <el-table-column
          label="运营商"
          prop="accountName"
          width="180px"
        ></el-table-column>
        <el-table-column
          label="套餐ID"
          prop="mealId"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="套餐名称"
          prop="mealName"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              class="themed-button"
              type="danger"
              @click="deleteAccount(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        background
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="手动新增"
      :visible.sync="dialogVisible"
      width="500px"
      destroy-on-close
    >
      <el-form
        label-width="120px"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="运营商" prop="accountId">
          <el-select v-model="ruleForm.accountId" filterable>
            <el-option
              v-for="item in accountLists"
              :key="item.index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐id" prop="mealId">
          <el-input v-model="ruleForm.mealId" style="width: 240px"></el-input>
        </el-form-item>
        <el-form-item label="套餐名" prop="mealName">
          <el-input v-model="ruleForm.mealName" style="width: 240px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="info"
          @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="onAddAccount('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="新增"
      :visible.sync="dialogBox"
      width="500px"
      destroy-on-close
    >
      <el-form
        label-width="120px"
        :model="addForm"
        :rules="rulesOne"
        ref="addForm"
      >
        <el-form-item label="运营商" prop="accountId">
          <el-select v-model="addForm.accountId" filterable>
            <el-option
              v-for="item in accountLists"
              :key="item.index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="iccid" prop="iccid">
          <el-input v-model="addForm.iccid" style="width: 240px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="info" @click="dialogBox = false"
          >取 消</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="AddAccount('addForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  operatorMealList,
  deletOperatorMeal,
  operatorMealAdd,
  operatorMealAddbyICCID,
} from "@/api/operator.js";
import { messageBox, confirmBox } from "@/utils/common.js";
import { getAccountList } from "@/api/cardManagement/cardBill.js";
import resize from "@/mixins/resize.js";
import dictGroup from "@/mixins/dictGroup.js";
import SearchSelect from "@/components/SearchSelect.vue";
export default {
  name: "cardImport",
  mixins: [resize, dictGroup],
  components: { SearchSelect },
  data() {
    return {
      searchForm: {
        accountId: "",
      },
      tableData: [],
      pageSize: 15,
      currentPage: 1,
      total: 0,
      accountLists: [{ label: "请选择", value: "" }],
      dialogVisible: false,
      ruleForm: {
        accountId: "",
        mealId: "",
        mealName: "",
      },
      rules: {
        accountId: [
          { required: true, message: "请选择运营商", trigger: "change" },
        ],
        mealId: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
        mealName: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
      },
      dialogBox: false,
      addForm: {
        accountId: "",
        iccid: "",
      },
      rulesOne: {
        accountId: [
          { required: true, message: "请选择运营商", trigger: "change" },
        ],
        iccid: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this._getAccountList();
    this.getOperatorMealList();
  },
  methods: {
    AddAccount(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        //   alert("submit!");
          operatorMealAddbyICCID(this.addForm).then((res) => {
            if (res.system_result_key == "0") {
              messageBox(this, "添加成功");
              this.getOperatorMealList();
              this.dialogBox = false;
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onAddAccount(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        //   alert("submit!");
          operatorMealAdd(this.ruleForm).then((res) => {
            if (res.system_result_key == "0") {
              messageBox(this, "添加成功");
              this.getOperatorMealList();
              this.dialogVisible = false;
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addMeal() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
      this.ruleForm.accountId = "";
      this.ruleForm.mealId = "";
      this.ruleForm.mealName = "";
    },
    addMealByid() {
      this.dialogBox = true;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      this.addForm.accountId = "";
      this.addForm.iccid = "";
    },
    deleteAccount(id) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletOperatorMeal({ id }).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getOperatorMealList();
        });
        
      });
    },
    _getAccountList() {
      getAccountList().then((res) => {
        this.accountLists = [{ label: "请选择", value: "" }];
        if (res.length > 0) {
          res.forEach((item) => {
            this.accountLists.push({ label: item.accountName, value: item.id });
          });
        }
      });
    },
    getOperatorMealList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
      };
      Object.assign(this.searchForm, params);
      operatorMealList(this.searchForm)
        .then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
          this.currentPage = res.pageNo;
          this.pageSize = res.pageSize;
        })
        .catch(() => {
          this.tableData = [];
        });
    },
    searchTable() {
      this.currentPage = 1;
      this.getOperatorMealList();
    },

    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getOperatorMealList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getOperatorMealList();
    },
  },
};
</script>

