/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function useraddress(params){
    return request({
        url: 'user/useraddress/list.do?',
        method: 'post',
        data:params
    })
}

export function exportExcel_(params){  //导出
    return request({
        url: 'user/useraddress/exportExcelSync.do',
        method: 'post',
        data:params
    })
}

export function importCard(params){  
    return request({
        url: 'user/useraddress/importCard.do?',
        method: 'post',
        data:params
    })
}
export function importCardSync(params){ //导入
    return request({
        url: 'user/useraddress/importCardSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function findById(params){  //处理
    return request({
        url: 'user/useraddress/findById.do?',
        method: 'post',
        data:params
    })
}

export function update(params){  //处理确认
    return request({
        url: 'user/useraddress/update.do?',
        method: 'post',
        data:params
    })
}
