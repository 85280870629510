<template>
	<div class="rebateUpdate">
		<el-form :model="form" ref="ruleFormAdd">
			<el-form-item label="代理商名称：" required :label-width="formLabelWidth" readonly="true">
				<el-input v-model="form.agentName" ></el-input>
			</el-form-item>
			<el-form-item label="月提现次数："  :label-width="formLabelWidth">
				<el-input v-model="form.applyFrequency" ></el-input>
			</el-form-item>
			<el-form-item label="冻结金额："  :label-width="formLabelWidth">
				<el-input v-model="form.frozenMoney" ></el-input>
			</el-form-item>
			<el-form-item label="最大提现金额："  :label-width="formLabelWidth">
				<el-input v-model="form.maxApply" ></el-input>
			</el-form-item>
			<el-form-item label="单笔免审金额："  :label-width="formLabelWidth">
				<el-input v-model="form.singleAuditFreeAmount" ></el-input>
			</el-form-item>
			<el-form-item label="返利方式："  :label-width="formLabelWidth">
				<el-select v-model="form.rebateType" >
				   <el-option
				     v-for="item in accountArr"
				     :key="item.id"
				     :label="item.name"
				     :value="item.id">
				   </el-option>
				 </el-select>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="baseFormSubmit" class="themed-button">提 交</el-button>
			<el-button type="info" @click="cancelDialog" class="themed-button">取 消</el-button>
		</div>
	</div>
	
</template>

<script>
	import {findByIdList,agentRebateCinfigUpdate} from "@/api/agentManagement.js"
	export default{
		name:'rebateUpdate',
		props:["childId"],
		data(){
			return {
				form:{
					id: "",
					agentName: "",
					applyFrequency: "",
					frozenMoney: "",
					maxApply: "",
					singleAuditFreeAmount: "",
					rebateType: "0",
				},
				formLabelWidth:"120px",
				accountArr:[
					{id:'0',name:"-未设置-"},
					{id:'1',name:"普通"},
					{id:'2',name:"秒返"},
				]
			}
		},
		created(){
		this.form.id = this.childId
		this.getFindByIdList(this.form)
		},
		methods:{
			getFindByIdList(params){
				findByIdList(params).then(res=>{
					if(res.system_result_key=="0" && res.app_result_key=="0"){
					}
					this.form= res
					this.form.rebateType = res.rebateType + "";
                    this.form.agentName = res.agentName
					this.form.applyFrequency = res.applyFrequency
					this.form.frozenMoney = res.frozenMoney
					this.form.maxApply = res.maxApply
					this.form.singleAuditFreeAmount = res.singleAuditFreeAmount
					
				})
			},
			cancelDialog(){
				this.$emit("close")
			},
			baseFormSubmit(){
				agentRebateCinfigUpdate(this.form).then(res=>{
					if (res.slide_msg_key == 1) {
						        this.$alert(res.slide_msg_message_key,"提示",{
									confirmButtonText: '确定',
								})
								return 
					        }
							this.$emit("reload")
							this.$emit("close")
				})
			}
			
		}
	}
</script>

<style>
	.rebateUpdate .dialog-footer{
		text-align: right;
	}
</style>
