<template>

<div class="account-rate table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">运营商速率列表</span>
        <el-form inline v-model="form"  class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="运营商:">
                <el-select v-model="form.accountId" filterable>
                    <el-option v-for="(item,index) in accountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="速率档位:">
                <el-input v-model="form.speedKey"></el-input>
            </el-form-item>
            <el-form-item label="系统档位:">
                <el-input v-model="form.systemGear"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addList">新增</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="运营商" prop="accounName" show-overflow-tooltip></el-table-column>
            <el-table-column label="速率档位" prop="speedKey" show-overflow-tooltip></el-table-column>
            <el-table-column label="速率值" prop="speedValue" show-overflow-tooltip></el-table-column>
             <el-table-column label="设备速率" prop="deviceSpeedValue" show-overflow-tooltip></el-table-column>
            <el-table-column label="系统档位" prop="systemGear" show-overflow-tooltip></el-table-column>
            <el-table-column label="排序" prop="sort" show-overflow-tooltip></el-table-column>
            <el-table-column label="更新时间" prop="updateTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="160">
                <template  slot-scope="scope">
                    <el-button type="primary" class="themed-button" @click="edit(scope.row)">编辑</el-button>
                    <el-button type="danger" class="themed-button" @click="delect(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog title="编辑" :visible.sync="dialogEdit" v-if="dialogEdit"> 
        <el-form v-model="formEdit" label-position="right" label-width="130px">
            <el-form-item label="运营商:" required>
                <el-select v-model="formEdit.accountId" filterable>
                    <el-option v-for="(item,index) in accountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="速率档位:" required>
                <el-input v-model="formEdit.speedKey"></el-input>
            </el-form-item>
            <el-form-item label="速率值:(kbs)" required>
                <el-input v-model.trim="formEdit.speedValue"></el-input>
            </el-form-item>
             <el-form-item label="设备速率值:(kbs)" >
                <el-input v-model.trim="formEdit.deviceSpeedValue"></el-input>
            </el-form-item>
            <el-form-item label="系统档位:" required>
                <el-input v-model="formEdit.systemGear"></el-input>
            </el-form-item>
            <el-form-item label="排序:" required>
                <el-input v-model="formEdit.sort"></el-input>
            </el-form-item>
        </el-form>  
        <div class="align-right">
            <el-button type="primary" class="themed-button" @click="sure">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogEdit=false">取消</el-button>
        </div>
    </el-dialog>
    <el-dialog title="新增" :visible.sync="dialogAdd" v-if="dialogAdd"> 
        <el-form v-model="formAdd" label-position="right" label-width="130px">
            <el-form-item label="运营商:" required>
                <el-select v-model="formAdd.accountId" filterable>
                    <el-option v-for="(item,index) in accountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="速率档位:" required>
                <el-input v-model="formAdd.speedKey"></el-input>
            </el-form-item>
            <el-form-item label="速率值:(kbs)" required>
                <el-input v-model.trim="formAdd.speedValue"></el-input>
            </el-form-item>
             <el-form-item label="设备速率值:(kbs)" >
                <el-input v-model.trim="formAdd.deviceSpeedValue"></el-input>
            </el-form-item>
            <el-form-item label="系统档位:" required>
                <el-input v-model="formAdd.systemGear"></el-input>
            </el-form-item>
            <el-form-item label="排序:" required>
                <el-input v-model="formAdd.sort"></el-input>
            </el-form-item>
        </el-form>  
        <div class="align-right">
            <el-button type="primary" class="themed-button" @click="addSure">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogAdd=false">取消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">

import {getAccountList,getSpeedList,insertSpeed,deleteSpeed} from '@/api/virtualCard/accountRateList.js'
import {messageBox,confirmBox} from '@/utils/common.js'

import resize from '@/mixins/resize.js'

export default {
    name:'accountRateList',
    mixins:[resize],
    data(){
        return {
            form:{
                accountId:'',
                speedKey:'',
                systemGear:''
            },
            accountList:[],
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogEdit:false,
            formEdit:{
                id:'',
                accountId:'',
                speedKey:'',
                speedValue:'',
                systemGear:'',
                sort:'',
                updateTime:'',
                deviceSpeedValue:"",
            },
            dialogAdd:false,
            formAdd:{
                accountId:'',
                speedKey:'',
                speedValue:'',
                systemGear:'',
                sort:'',
                deviceSpeedValue:"",
            },
        }
    },
    mounted(){
        this._getAccountList()
        this._getSpeedList()
    },
    methods:{
        addList(){
            this.resetAddForm()
            this.dialogAdd = true  
        },
        addSure(){
            insertSpeed(this.formAdd).then((res)=>{
                this.dialogAdd = false
                this._getSpeedList()
                messageBox(this,'添加成功')
            })
        },
        delect(id){
            confirmBox(this,'是否确定删除?',null,()=>{
                deleteSpeed({id}).then((res)=>{
                    this._getSpeedList()
                    messageBox(this,'删除成功')
                })
            })
        },
        edit(data){
            this.dialogEdit = true
            this.formEdit.id = data.id
            this.formEdit.accountId = data.accountId
            this.formEdit.speedKey = data.speedKey
            this.formEdit.speedValue = data.speedValue
            this.formEdit.systemGear = data.systemGear
            this.formEdit.sort = data.sort
            this.formEdit.updateTime = data.updateTime
            this.formEdit.deviceSpeedValue = data.deviceSpeedValue
        },
        sure(){
            insertSpeed(this.formEdit).then((res)=>{
                this.dialogEdit = false
                this._getSpeedList()
                messageBox(this,'编辑成功')
            })
        },
        searchTable(){
            this._getSpeedList()
        },
        handleSizeChange(val){
            this.pageSize = val
            this._getSpeedList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._getSpeedList()
        },
        productTypeName(productType){
            let result = this.productTypeList.find((item)=>{
                return item.value == productType
            })
            if(!result){
                return '-'
            }
            if(result.label){
                return result.label
            }else{
                return '-'
            }
        },
        _getAccountList(){
            getAccountList().then((res)=>{
                this.accountList = res
            })
        },
        _getSpeedList(){
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            params.type = this.type
            getSpeedList(Object.assign(params,this.form)).then((res) => {
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        },
        resetAddForm(){
            this.formAdd ={
                accountId:'',
                speedKey:'',
                speedValue:'',
                systemGear:'',
                sort:'',
                deviceSpeedValue:"",
            }
        }
    }
}
</script>
