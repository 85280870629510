<template>
  <div class="merchNum fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">商户号</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="关键字：">
          <el-input
            v-model="formInline.pageSearchKeys"
            placeholder="输入商户号名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="addAgentRecord"
            icon="el-icon-plus"
            class="themed-button"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        ref="table"
        :max-height="maxTableHeight"
        border
      >
      <el-table-column
                    show-overflow-tooltip
                    label="商户类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==1||scope.row.type==''">微信</span>
                        <span v-else-if="scope.row.type==2">乐刷</span>
                         <span v-else-if="scope.row.type==3">银联</span>
                    </template>
            </el-table-column>
        <el-table-column prop="name" label="商户号名称"> </el-table-column>
        <el-table-column prop="mchId" label="商户号ID"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span v-if="scope.row.isDefault">当前默认</span>
            <span v-else @click="updateRecord(scope.row.id)">设为默认</span>
            <span @click="edit(scope.row.id)" class="left_agent">编辑</span>
            <span @click="deleteById(scope.row.id)" class="left_agent"
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新增商户号"
      :visible.sync="dialogAddMerchVisible"
      :before-close="handleCloseAddMerch"
      v-if="dialogAddMerchVisible"
    >
      <el-form :model="formAddMerch" ref="ruleFormAddMerch">
         <el-form-item
          label="商户类型:"
          :label-width="formLabelWidth"
          required
        >
          <el-select v-model="formAddMerch.type" required @change="typeChange">
            <el-option label="微信" value="1"></el-option>
            <el-option label="乐刷" value="2"></el-option>
            <el-option label="银联" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称：" :label-width="formLabelWidth" required>
          <el-input v-model="formAddMerch.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="ID：" :label-width="formLabelWidth" required>
          <el-input v-model="formAddMerch.mchId" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="交易秘钥：" :label-width="formLabelWidth" required>
          <el-input v-model="formAddMerch.key" autocomplete="off"></el-input>
        </el-form-item>
       <el-form-item label="异步回调密钥:" :label-width="formLabelWidth" required v-show="formAddMerch.type=='2'" >
					<el-input v-model="formAddMerch.keyAsync" autocomplete="off"></el-input>
				</el-form-item>
        <el-form-item label="证书：" :label-width="formLabelWidth" required  v-show="formAddMerch.type=='1'">
          <input
            class="file-input"
            type="file"
            ref="fileInput"
            @change="fileChange"
          />
        </el-form-item>
          <el-form-item label="银联商户号类型:" :label-width="formLabelWidth" v-if="formAddMerch.type=='3'">
                <el-radio-group v-model="formAddMerch.unionType" >
                    <el-radio label="1">关注商户号</el-radio>
                    <el-radio label="2">特邀商户号</el-radio>
                </el-radio-group>
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="formAddMerchSubmit('ruleFormAddMerch')"
          class="themed-button"
          >确 定</el-button
        >
        <el-button
          type="info"
          @click="formAddMerchReset('ruleFormAddMerch')"
          class="themed-button"
          >重置</el-button
        >
        <el-button
          type="info"
          @click="cancelDialogformAddMerch('ruleFormAddMerch')"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="编辑商户号"
      :visible.sync="dialogEditMerchVisible"
      v-if="dialogEditMerchVisible"
    >
      <el-form :model="formEditMerch">
        <el-form-item label="密钥V3(非必填):" :label-width="formLabelWidth">
          <el-input
            v-model="formEditMerch.keyVerThree"
            autocomplete="off"
          ></el-input>
		  <p>若使用[商家转账到零钱]请配置此项</p>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="formEditMerchSubmit"
          class="themed-button"
          >确 定</el-button
        >
        <el-button type="info" @click="formEditMerchReset" class="themed-button"
          >重置</el-button
        >
        <el-button
          type="info"
          @click="formEditMerchCancel"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import {
  merchantList,
  deleteMerchant,
  addMerchantNum,
  uploadFile,
  setDefault,
  editMerchant,
  editSaveMerchant,
} from "@/api/payManagment.js";
export default {
  name: "merchNum",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        pageSearchKeys: "",
      },
      tabledata: [],
      dialogAddMerchVisible: false,
      formAddMerch: {
        type:"1",
        certPath: "",
        name: "",
        mchId: "",
        key: "",
        keyAsync:"",
        unionType:"1",
      },
      formLabelWidth: "150px",
      dialogEditMerchVisible: false,
      formEditMerch: {
        id: "",
        keyVerThree: "",
      },
      resetkeyVerThree: "",
    };
  },
  created() {
    this.getMerchantList(this.formInline);
  },
  methods: {
    typeChange(val){
      this.formAddMerch.certPath = ""
      this.formAddMerch.name = ""
      this.formAddMerch.mchId  = ""
      this.formAddMerch.key = ""
      this.formAddMerch.keyAsync = ""
      this.formAddMerch.unionType  = "1"
    },
    getMerchantList(params) {
      merchantList(params).then((res) => {
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        pageSearchKeys: this.formInline.pageSearchKeys,
      };
      this.getMerchantList(params);
    },
    onPageChange(page) {
      this.page = page;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        pageSearchKeys: this.formInline.pageSearchKeys,
      };
      this.getMerchantList(params);
    },
    searchData() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        pageSearchKeys: this.formInline.pageSearchKeys,
      };
      this.getMerchantList(params);
    },
    updateRecord(id) {
      this.$confirm(
        "未关联商户号的代理商将使用该商户号，若需关联商户号，请至[代理商-商户号]页面，确定设为默认？",
        "消息提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let params = {
            id: id,
          };
          setDefault(params).then(() => {
            this.$message({
              type: "success",
              message: "设置成功!",
            });
            this.searchData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消设置",
          });
        });
    },
    deleteById(id) {
      this.$confirm("确定要删除该商户号吗？", "消息提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: id,
          };
          deleteMerchant(params).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getMerchantList(this.formInline);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    edit(id) {
      this.dialogEditMerchVisible = true;
      this.formEditMerch.id = id;
      editMerchant({ id }).then((res) => {
        this.formEditMerch.keyVerThree = res.keyVerThree;
        this.resetkeyVerThree = res.keyVerThree;
      });
    },
	formEditMerchCancel(){
    this.formEditMerch.id = ""
    this.formEditMerch.keyVerThree =""
	this.dialogEditMerchVisible = false
	},
    formEditMerchSubmit() {
      editSaveMerchant(this.formEditMerch).then((res) => {
        if (res.system_result_key == 0) {
          this.$message({
            showClose: true,
            message: "编辑成功",
            type: "success",
          });
          let params = {
            pageSize: this.pageSize,
            pageNo: 1,
            pageSearchKeys: this.formInline.pageSearchKeys,
          };
          this.getMerchantList(params);
          this.dialogEditMerchVisible = false;
        } else if (res.slide_msg_key == 1) {
          this.$message({
            showClose: true,
            message: res.slide_msg_message_key,
          });
          this.dialogEditMerchVisible = false;
        }
      });
    },
    formEditMerchReset() {
      this.formEditMerch.keyVerThree = this.resetkeyVerThree;
    },
    addAgentRecord() {
      this.dialogAddMerchVisible = true;
        this.formAddMerch.certPath = ""
      this.formAddMerch.name = ""
      this.formAddMerch.mchId  = ""
      this.formAddMerch.key = ""
      this.formAddMerch.keyAsync = ""
      this.formAddMerch.unionType = "1"
      this.$nextTick(()=>{
         this.$refs["fileInput"].value = "";
       })
     
    },
    formAddMerchSubmit() {
      if (
        this.formAddMerch.name == null ||
        this.formAddMerch.name == "" ||
        this.formAddMerch.name == 0
      ) {
        this.$alert("请填写名称", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (
        this.formAddMerch.mchId == null ||
        this.formAddMerch.mchId == "" ||
        this.formAddMerch.mchId == 0
      ) {
        this.$alert("请填写商户ID！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (
        this.formAddMerch.key == null ||
        this.formAddMerch.key == "" ||
        this.formAddMerch.key == 0
      ) {
        this.$alert("请填写秘钥！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
       if ((
        this.formAddMerch.keyAsync == null ||
        this.formAddMerch.keyAsync == "" ||
        this.formAddMerch.keyAsync == 0)  && this.formAddMerch.type == "2"
      ) {
       
        this.$alert("请填写异步回调密钥！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if ((
        this.$refs["fileInput"].files[0] == undefined ||
        this.$refs["fileInput"].files[0] == "" ||
        this.$refs["fileInput"].files[0] == null) && this.formAddMerch.type == "1"
      ) {
        this.$alert("请上传证书！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      this.dialogAddMerchVisible = false;

      loading.show();
      addMerchantNum(this.formAddMerch).then((res) => {
        this.$message({
          type: "success",
          message: "新增成功",
        });
        this.getMerchantList(this.formInline);
        this.dialogAddMerchVisible = false;
      this.formAddMerch.certPath = ""
      this.formAddMerch.name = ""
      this.formAddMerch.mchId  = ""
      this.formAddMerch.key = ""
      this.formAddMerch.keyAsync = ""
      this.formAddMerch.type = "1"
      this.formAddMerch.unionType = "1"
        this.$refs["fileInput"].value = "";
      });
    },
    formAddMerchReset() {
      this.dialogAddMerchVisible = false;
      this.formAddMerch.certPath = ""
      this.formAddMerch.name = ""
      this.formAddMerch.mchId  = ""
      this.formAddMerch.key = ""
      this.formAddMerch.keyAsync = ""
      this.formAddMerch.type = "1"
       this.formAddMerch.unionType = "1"
      this.$refs["fileInput"].value = "";
    },
    cancelDialogformAddMerch() {
      this.dialogAddMerchVisible = false;
      this.formAddMerch.certPath = ""
      this.formAddMerch.name = ""
      this.formAddMerch.mchId  = ""
      this.formAddMerch.key = ""
      this.formAddMerch.keyAsync = ""
      this.formAddMerch.type = "1"
      this.formAddMerch.unionType = "1"
      this.$refs["fileInput"].value = "";
    },
    fileChange() {
      let formData = new FormData();
      formData.append("file", this.$refs["fileInput"].files[0]);
      uploadFile(formData).then((res) => {
        console.log(res.filePath);
        this.formAddMerch.certPath = res.filePath;
      });
    },
    handleCloseAddMerch(done) {
      this.dialogAddMerchVisible = false;
      this.formAddMerch.certPath = ""
      this.formAddMerch.name = ""
      this.formAddMerch.mchId  = ""
      this.formAddMerch.key = ""
      this.formAddMerch.keyAsync = ""
      this.formAddMerch.type = "1"
      this.formAddMerch.unionType = "1"
      this.$refs["fileInput"].value = "";
      done();
    },
  },
};
</script>

<style>
.merchNum .el-form-item__label {
  vertical-align: top;
}

.left_agent {
  margin-left: 8px;
}
</style>
