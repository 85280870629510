<template>

<div class="setMealChange table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">套餐变更</span>
        <el-form ref="cardForm" :model="cardForm" inline class="hook right themed-form search-form">
            <el-form-item label="是否分销:">
                <el-select v-model="cardForm.isdistribution" placeholder="全部">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已分销" value="yes"></el-option>
                    <el-option label="未分销" value="no"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="卡状态:">
                <el-select v-model="cardForm.status" placeholder="全部">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="待激活" value="1"></el-option>
                    <el-option label="正常" value="2"></el-option>
                    <el-option label="断网" value="3"></el-option>
                    <el-option label="停机" value="4"></el-option>
                    <el-option label="异常" value="5"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="卡片类型:">
                <el-select v-model="cardForm.type" placeholder="全部">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="包年" value="1"></el-option>
                    <el-option label="包月" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开卡运营商:">
                <search-select :optionsMetaAll="accountLists" v-model="cardForm.accountName">
                </search-select>
            </el-form-item>
            <el-form-item label="分销商:">
                <el-input v-model="cardForm.agentName"></el-input>
            </el-form-item>
            <el-form-item label="ICCID号/短号：">
                <el-input v-model="cardForm.iccidMark" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="接入号:">
                <el-input v-model="cardForm.phone" class="iccid-width"></el-input>
            </el-form-item>
            
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="reloadPageData">搜索查询</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID号" prop="iccidMark" width="180px"></el-table-column>
             <el-table-column label="短号" prop="shortIccid" width="130px"></el-table-column>
            <el-table-column label="接入号码" prop="phone" show-overflow-tooltip></el-table-column>
            <el-table-column label="开卡公司" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="到期时间" show-overflow-tooltip>
               <template slot-scope="scope">
                   <span>{{expirationDate(scope.row)}}</span>
               </template>
            </el-table-column>
            <el-table-column label="套餐" prop="setMealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="卡片类型" prop="type">
                <template slot-scope="scope">
                    <span v-if="scope.row.type===1">包月</span>
                    <span v-else-if="scope.row.type===2">包年</span>
                </template>
            </el-table-column>
            <el-table-column label="分销商姓名" prop="agentThreeName" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.agentThreeName">{{scope.row.agentThreeName}}</span>
                    <span v-else>未分配</span>
                </template>
            </el-table-column>
            <el-table-column label="卡状态" prop="status">
                <template slot-scope="scope">
                    <span v-if="scope.row.status===1">待激活</span>
                    <span v-else-if="scope.row.status===2">正常</span>
                    <span v-else-if="scope.row.status===3">断网</span>
                    <span v-else-if="scope.row.status===4">停机</span>
                    <span v-else-if="scope.row.status===5">异常</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" class="themed-button"  @click="updateRecord(scope.row.id,scope.row.setMealName,scope.row.category)">套餐变更</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    
    <el-dialog title="套餐变更" :visible.sync="dialogUpdate">
        <el-form label-position="right" label-width="200px">
            <el-form-item label="原本套餐">
                <el-input v-model="originalId"></el-input>
            </el-form-item>
            <el-form-item label="变更套餐" >
                <search-select :optionsMetaAll="mealList"  v-model="setPackageForm.setMealId" @input="getMealName">
                </search-select>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="setMealOk">确定变更</el-button>
            <el-button type="primary" class="themed-button" @click="dialogUpdate = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {getAccountList ,getList,getSetMeal,updateMeal} from '@/api/setMealManagment/setMealChange.js'

    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'
    import resize from '@/mixins/resize.js'

    const ERR_OK = 0
    export default {
        name:'setMealChange',
        mixins:[resize],
        data(){
            return {
                cardForm:{
                    isdistribution:'',
                    status:'',
                    type:'',
                    accountName:'',
                    agentName:'',
                    iccidMark:'',
                    phone:'',
                },
                accountLists:[{label:'请选择',value:''}],
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
                dialogUpdate:false,
                originalId:'',
                setPackageForm:{
                  setMealId:'',
                  setMealName:'',
                  id:''
                },
                mealList:[]
            }
        },
        mounted(){
            this._getAccountList()
            this._getList()
        },
        methods:{
            reloadPageData(){
                this._getList()
            },
            updateRecord(id,setMealName,category){
                let selections = {id,setMealName,category}
                if(!this.selectOnlyOne(selections)){
                    return 
                }
                this.dialogUpdate = true
                this.originalId = setMealName,
                this.setPackageForm.id = id
                getSetMeal({category}).then((res)=>{
                    let mealList = []
                    res.forEach((val)=>{
                        if(val.type == 1){
                            return 
                        }
                        mealList.push({
                            label:val.name,
                            value:val.id
                        })
                    })
                    
                    this.mealList = mealList

                })
            },
            getMealName(val){
                this.mealList.forEach((item)=>{
                    if(val==item.value){
                        this.setPackageForm.setMealName = item.label
                        return
                    }
                })
            },
            setMealOk(){
                updateMeal(this.setPackageForm).then((res)=>{
                    if(res.slide_msg_key==1){
                        messageBox(res.slide_msg_message_key);
                    }
                    this.dialogUpdate = false
                    this._getList()
                })
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._getList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._getList()
            },
            selectOnlyOne(selections){
                if(selections.length==0){
                    messageBox(this,'请选择要操作的记录!');
                    return false;
                }
            
                if(selections.length>1){
                    messageBox(this,'该操作不允许选择多行!');
                    return false;
                }
                return true;
            },
		
            _getList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                Object.assign(params, this.cardForm)
                getList(params).then((res)=>{
                    // console.log(res)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            },
            _getAccountList(){
                getAccountList(this.cardForm).then((res) => {
                    this.accountLists = [{label:'请选择',value:''}]
                    if(res.length>0){
                        res.forEach((item)=>{
                            this.accountLists.push({label:item.accountName,value:item.accountName})
                        })
                    }
                })
            },
            expirationDate(rowData){
                if(rowData.cardLifeEntity!=null && null != rowData.cardLifeEntity.expireDate){
                var date = new Date(rowData.cardLifeEntity.expireDate);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                var d = date.getDate();
                return y + '-' +m + '-' + d;
                }
            }
        },
        components:{
            SearchSelect
        }
    }
</script>