<template>

<div class="imei-list table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">IMEI列表</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="IMEI:">
                <el-input v-model="form.imei"></el-input>
            </el-form-item>
            <el-form-item label="随机码:">
                <el-input v-model="form.chipid"></el-input>
            </el-form-item>
            <el-form-item label="库编号:">
                <el-select filterable v-model="form.imeiBankCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in imeiBankCodeList" :label="item.imeiBankCode" :value="item.imeiBankCode" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="需要绑定:">
                <el-select filterable v-model="form.needBind">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="需要绑定" value="1"></el-option>
                    <el-option label="不需要绑定" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品名称:">
                <el-input v-model="form.productName"></el-input>
            </el-form-item>
            <el-form-item label="客户:">
                <el-input v-model="form.customerName"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchData">搜索查询</el-button>
            </el-form-item>
        
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="importAdd">新增IMEI</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-minus" class="themed-button" @click="importDelete">删除IMEI</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="IMEI" prop="imei" show-overflow-tooltip></el-table-column>
            <el-table-column label="随机码" prop="chipid" show-overflow-tooltip></el-table-column>
            <el-table-column label="IMEI库编号" prop="imeiBankCode" show-overflow-tooltip></el-table-column>
            <el-table-column label="产品名称" prop="productName" show-overflow-tooltip></el-table-column>
            <el-table-column label="客户" prop="customerName" show-overflow-tooltip></el-table-column>
            <el-table-column label="代理商" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="需要绑定" prop="needBind" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.needBind == '1'">需要绑定</span>
                    <span v-else>不需要绑定</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作人" prop="operator" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" @click="importUpdate(scope.row.id)">编辑</el-button>
                    <el-button type="info" class="themed-button" @click="deleteById(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogBox" v-if="dialogBox">
        <add-imei v-if="dialogContent == 'add-imei'"  @close="dialogBox=false" @close-dialog='dialogBox=false'></add-imei>
        <delete-imei v-if="dialogContent == 'delete'" @close="dialogBox=false" @close-dialog='dialogBox=false'></delete-imei> 
        <update v-if="dialogContent == 'update'" :id="id" @close="refresh"></update>  
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {imeiBankCode,listImei,exportImeiSync,deleteByIdImei} from '@/api/meCardBindManagement/imeiLibrary.js'
    import {messageBox,confirmBox } from '@/utils/common.js'
    import resize from '@/mixins/resize.js'

    import AddImei from '@/views/meCardBindManagement/imeiList/addImei'
    import DeleteImei from '@/views/meCardBindManagement/imeiList/deleteImei'
    import Update from '@/views/meCardBindManagement/imeiList/update'

    export default {
        // name:'imeiList',
        mixins:[resize],
        data(){
            return {
                form:{
                    imei:'',
                    imeiBankCode:'',
                    productName:'',
                    customerName:'',
                    chipid:''
                },
                imeiBankCodeList:[],
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,

                dialogBox:false,
                dialogTitle:'',
                dialogContent:'',
                id:''
            }
        },
        mounted(){
            this._imeiBankCode()
            this._listImei()
        },
        methods:{
            refresh(){
                this._listImei()
                this.dialogBox = false
            },
            importAdd(){
                this.dialogTitle = '新增IMEI'
                this.dialogContent = 'add-imei'
                this.dialogBox = true
            },
            importDelete(){
                this.dialogTitle = '删除IMEI'
                this.dialogContent = 'delete'
                this.dialogBox = true
            },
            importUpdate(id){
                this.id = id
                this.dialogTitle = '修改库'
                this.dialogContent = 'update'
                this.dialogBox = true
            },
            deleteById(data){
                let msg = "IMEI：" + data.imei + "</br>" + "IMEI库编号：" + data.imeiBankCode + "</br>" + "绑定卡：" + data.jsonUpdateFlag + "</br><br>设备和卡片关系将一起删除。</br>是否确认删除?"
                confirmBox(this,msg,'操作提示',()=>{
                    deleteByIdImei(Object.assign({id:data.id},this.form)).then((res)=>{
                        messageBox(this,'删除成功')
                        this._listImei()
                    })
                })
            },
            exportExcel(){
                exportImeiSync(this.form).then((res)=>{
                    if (res.system_result_key == '0') {
                        this.$alert('导出成功，请到导出记录中下载', '提示', {
                            confirmButtonText:'确定',
                        }).then(()=>{
                            this.$router.push('/tob/i_export_list')
                        });
                    }else{
                        this.$message({
                            showClose: true,
                            message: res.system_result_message_key,
                            type: 'error'
                        });
                    }
                })
            },
            searchData(){
                this._listImei()
            },
            handleSizeChange(val) {
                this.pageSize = val
                this._listImei()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this._listImei()
            },
            _imeiBankCode(){
                imeiBankCode().then((res)=>{
                    this.imeiBankCodeList = res
                })
            },
            _listImei(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                listImei(Object.assign(params,this.form)).then((res)=>{
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        },
        components:{
            AddImei,
            DeleteImei,
            Update
        }
    }
</script>

