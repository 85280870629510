<template>
  <div class="fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">批次卡列表</span>
      <el-form
        inline
        class="right themed-form search-form"
        style="height: 40px; line-height: 40px"
      >
        <el-form-item>
          <el-select v-model="searchForm.groupId" placeholder="计费组" @change="groupChange" clearable filterable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.batchName" placeholder="批次" clearable filterable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in newBatchList"
              :key="item.id"
              :label="item.batchName"
              :value="item.batchName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        :data="tableData"
        stripe
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="id" label="批次编号"> </el-table-column>
        <el-table-column prop="batchName" label="批次名称">
          <template slot-scope="scope">
            <a
              href="javascript:;"
              @click="getCardDetail(scope.row)"
              style="color: #337ab7"
              >{{ scope.row.batchName }}</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="groupCode" label="计费组编号"> </el-table-column>
        <el-table-column prop="groupName" label="计费组名称"> </el-table-column>
        <el-table-column prop="mode" label="计费模式">
          <template slot-scope="scope">
            <span>{{ scope.row.mode==1?"共享计费组" :"单卡计费组"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cardNum" label="总卡数"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间" >
          <template slot-scope="scope">
            <span>{{formatData(scope.row.createTime) }}</span>
          </template>
           </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.remark"
              @change="
                (val) => {
                  updateSort(val, scope.row.id);
                }
              "
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              class="themed-button"
              type="warning"
              @click="deleteBatch(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { messageBox } from "@/utils/common.js"
import { chargeGroupList } from "@/api/group.js";
import { batchEdit ,delBatchCardById,chargeGroupBatchList} from "@/api/partner.js";
import resize from "@/mixins/resize.js";
export default {
  mixins: [resize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      groupList: [],
      batchList: [],
      newBatchList:[],
      searchForm: {
        groupId: "",
        batchName: "",
       
      },
    };
  },
  created() {
    this.getGroupList();
    this.getBatchList();
    this.getCurBatchList();
  },
  methods: {
    formatData(cellValue){
		
		var s =	new Date(cellValue).toLocaleString();
	    return s;
		 
	    },

    deleteBatch(id){
				this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delBatchCardById({id}).then(res=>{
						this.$message({
							type: 'success',
							message:'删除成功'
						});
					})
					this.getCurBatchList()
				})
			},
    groupChange(e){
       console.log(e)
       // 有值时候  通过数组筛选 批次列表；没值 赋值批次列表全部
       if(e){
        this.newBatchList = this.batchList.filter(item=>{
          return item.groupId == e
        })
        console.log(this.newBatchList)
       }else{
        this.newBatchList = (this.batchList) 
       }
    },
    updateSort(val, id) {
      let parms = { id: id };
      parms.remark = val;

      batchEdit(parms)
        .then((res) => {
          this.getCurBatchList();
        })
        .catch((res) => {
          messageBox(this, "请求错误", "操作繁忙请稍后重试或联系客服。");
        });
    },
    getCardDetail(rows) {
      let groupId = rows.groupId;//计费组id
      let batchId = rows.id;  //批次id
     
      this.$router.push({
        path: "/chargeGroup/card",
        query: { groupId ,batchId},
      });
    },
    getBatchList() {
      //获取所有的批次列表
      this.batchList = []; //赋值
       let params = {
        pageSize: 10000,
        pageNo:1,
        };
     chargeGroupBatchList(params).then(res=>{
        console.log(res)
        this.batchList = res.rows
        this.newBatchList = res.rows
     })

      
    },
    getCurBatchList(){
        let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      let obj={}
      Object.assign(obj ,this.removeProperty(this.searchForm), params);
      chargeGroupBatchList(obj).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
   removeProperty(obj) {
  Object.keys(obj).forEach(item => {
    if (obj[item] === '' || obj[item] === undefined || obj[item] === null || obj[item] === 'null') delete obj[item]
  })
  return obj
},


    onSearch() {
      this.page = 1;
      this.getCurBatchList();
    },

    getGroupList() {
      chargeGroupList({}).then((res) => {
        this.groupList = res.rows;
      });
    },

  
    onPageChange(page) {
      this.page = page;
      this.getCurBatchList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getCurBatchList();
    },
  },
};
</script>

<style>
</style>
