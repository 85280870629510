import request from './request.js'
export function networkCutHistory(params) {
    return request({
        url: 'virtualChangeLog/list.do?',
        method: 'post',
        data: params
    })
}

export function exportNetworkCutHistory(params) {
    return request({
        url: 'virtualChangeLog/export.do?',
        method: 'post',
        data: params
    })
}

export function deleteNetworkCutHistory(params) {
    return request({
        url: 'virtualChangeLog/deleteById.do?',
        method: 'post',
        data: params
    })
}


export function virtualCardInfo(params) {
    return request({
        url: 'virtualCardInfo/list.do?',
        method: 'post',
        data: params
    })
}

// 实名
export function realNameList(params) {
    return request({
        url: 'innerRealName/list.do?',
        method: 'post',
        data: params
    })
}

export function exportRealName(params) {
    return request({
        url: 'innerRealName/exportSync.do?',
        method: 'post',
        data: params
    })
}

export function deleteRealName(params) {
    return request({
        url: 'innerRealName/delete.do?',
        method: 'post',
        data: params
    })
}

export function editRealName(params) {
    return request({
        url: 'innerRealName/update.do?',
        method: 'post',
        data: params
    })
}

// 配置列表
export function virtualConfigList(params) {
    return request({
        url: 'virtualConfig/list.do?',
        method: 'post',
        data: params
    })
}

export function addVirtualConfig(params) {
    return request({
        url: 'virtualConfig/add.do?',
        method: 'get',
        // data: params
        params: params
    })
}

export function editVirtualConfig(params) {
    return request({
        url: 'virtualConfig/edit.do?',
        method: 'get',
        params: params,
    })
}

export function deleteVirtualConfig(params) {
    return request({
        url: 'virtualConfig/deleteById.do?',
        method: 'post',
        data: params
    })
}

export function findVirtualConfigById(params) {
    return request({
        url: 'virtualConfig/findById.do?',
        method: 'post',
        data: params
    })
}


export function getVirtualInfoList(params) {
    return request({
        url: 'virtualConfig/list.do?',
        method: 'post',
        data: params
    })
}

export function exportVirtualInfoList(params) {
    return request({
        url: 'virtualCardInfo/export.do?',
        method: 'post',
        data: params
    })
}

export function virtualStaticsList(params) {
    return request({
        url: '/virtual/statics/getList.do?',
        method: 'post',
        data: params
    })
}

export function virtualCycleExport(params) {
    return request({
        url: '/virtual/statics/virtualCycleExport.do?',
        method: 'post',
        data: params
    })
}

export function deleteVirtualCard(params) {
    return request({
        url: '/virtualCardInfo/delete.do?',
        method: 'post',
        data: params
    })
}

// 虚拟异常卡
export function virtualCardException(params) {
    return request({
        url: '/virtualCardException/list.do?',
        method: 'get',
        params: params
    })
}

export function virtualCardExceptionDelete(params) {
    return request({
        url: '/virtualCardException/delete.do?',
        method: 'get',
        params: params
    })
}

export function virtualCardExceptionExport(params) {
    return request({
        url: '/virtualCardException/export.do?',
        method: 'get',
        params: params
    })
}

// 禁用卡记录
export function getBanNetworkRecordList(params) {
    return request({
        url: '/restrictedNetworkLog/list.do?',
        method: 'get',
        params: params
    })
}

export function banNetworkRecordExport(params) {
    return request({
        url: '/restrictedNetworkLog/export.do?',
        method: 'get',
        params: params
    })
}

export function banNetworkRecordDelete(params) {
    return request({
        url: '/restrictedNetworkLog/delete.do?',
        method: 'get',
        params: params
    })
}
//运营策略列表
export function operatingStrategyList(params) {
    return request({
        url: '/virtualStrategy/list.do?',
        method: 'post',
        data: params
    })
}
export function operatingStrategyDelete(params) {
    return request({
        url: '/virtualStrategy/deleteById.do?',
        method: 'post',
        data: params
    })
}

export function operatingStrategyAdd(params) {
    return request({
        url: '/virtualStrategy/add.do?',
        method: 'post',
        data: params
    })
}


export function operatingStrategyEdit(params) {
    return request({
        url: '/virtualStrategy/edit.do?',
        method: 'post',
        data: params
    })
}
export function operatingStrategyById(params) {
    return request({
        url: '/virtualStrategy/findById.do?',
        method: 'post',
        data: params
    })
}

// 虚拟卡配置
export function virtualConfigurationList(params) {
    return request({
        url: '/virtualStrategyAccount/list.do?',
        method: 'post',
        data: params
    })
}

export function virtualConfigurationAdd(params) {
    return request({
        url: '/virtualStrategyAccount/add.do?',
        method: 'post',
        data: params
    })
}

export function virtualConfigurationEdit(params) {
    return request({
        url: '/virtualStrategyAccount/edit.do?',
        method: 'post',
        data: params
    })
}
export function virtualConfigurationById(params) {
    return request({
        url: '/virtualStrategyAccount/findById.do?',
        method: 'post',
        data: params
    })
}
export function virtualConfigurationDelete(params) {
    return request({
        url: '/virtualStrategyAccount/deleteById.do?',
        method: 'post',
        data: params
    })
}
// 单卡运营策略
export function virtualStrategyCardList(params) {
    return request({
        url: '/virtualStrategyCard/list.do?',
        method: 'post',
        data: params
    })
}

export function virtualStrategyCardAdd(params) {
    return request({
        url: '/virtualStrategyCard/add.do?',
        method: 'post',
        data: params
    })
}
export function virtualStrategyCardEdit(params) {
    return request({
        url: '/virtualStrategyCard/edit.do?',
        method: 'post',
        data: params
    })
}
export function virtualStrategyCardById(params) {
    return request({
        url: '/virtualStrategyCard/findById.do?',
        method: 'post',
        data: params
    })
}

export function virtualStrategyCardDelete(params) {
    return request({
        url: '/virtualStrategyCard/deleteById.do?',
        method: 'post',
        data: params
    })
}

//批量运营策略
export function virtualStrategyBatchDelete(params) {
    return request({
        url: '/virtualStrategyBatch/deleteById.do?',
        method: 'post',
        data: params
    })
}

export function virtualStrategyBatchById(params) {
    return request({
        url: '/virtualStrategyBatch/findById.do?',
        method: 'post',
        data: params
    })
}

export function virtualStrategyBatchEdit(params) {
    return request({
        url: '/virtualStrategyBatch/edit.do?',
        method: 'post',
        data: params
    })
}

export function virtualStrategyBatchAdd(params) {
    // return request({
    //     url: '/virtualStrategyBatch/add.do?',
    //     method: 'post',
    //     data: params
    // })

    return request({
        url: '/virtualStrategyBatch/add.do?',
        method: 'post',
        data: params,
        timeout: 20000,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}
export function virtualStrategyBatchList(params) {
    return request({
        url: '/virtualStrategyBatch/list.do?',
        method: 'post',
        data: params
    })
}

export function freeStaticsList(params) {
    return request({
        url: '/virtual/statics/freeUseList.do?',
        method: 'post',
        data: params
    })
}
export function updateVirtualCardInfo(params) {
    return request({
        url: '/virtualCardInfo/update.do?',
        method: 'post',
        data: params
    })
}
export function importIccidAsync(params) {
    return request({
        url: '/virtualStrategyBatch/importIccidAsync.do?',
        method: 'post',
        data: params,
        timeout: 20000,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}



