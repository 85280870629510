<template>
    <div class="withdrawal-application fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">后台提现审批</span>
            <el-form inline ref="withdrawalApplicationForm" v-model="withdrawalApplicationData" class="right themed-form search-form" style="height: 40px;line-height: 40px;">
                <el-form-item label="代理商名称:">
                    <search-select :optionsMetaAll="agentList" v-model="withdrawalApplicationData.agentId"></search-select>
                </el-form-item>
                <el-form-item label="结算状态">
                    <search-select :optionsMetaAll="optionsjsStatus" v-model="withdrawalApplicationData.status"></search-select>
                </el-form-item>
                <el-form-item label="开始时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择时间" v-model="withdrawalApplicationData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择时间" v-model="withdrawalApplicationData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rebateNo"
                    label="提现编号"
                    min-width="150">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="agentTreeName"
                    label="代理商名称"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="agentName"
                    label="所属总代理商"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="startTime"
                    label="开始时间"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="endTime"
                    label="结束时间"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    prop="orderNum"
                    label="订单总数"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="orderAmount"
                    label="订单总额"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="applyMoney"
                    label="系统提现金额"
                    min-width="98">
                </el-table-column>

                <el-table-column
                    prop="checkMoney"
                    label="审核通过金额"
                    min-width="120">
                </el-table-column>

                <el-table-column
                    label="返佣类型"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.rebateType == 1">普通</span>
                        <span v-else-if="scope.row.rebateType == 2">秒返</span>
                    </template>
                </el-table-column>
                
                <el-table-column
                    label="支付类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payType == 1">微信</span>
                        <span v-else-if="scope.row.payType == 2">线下</span>
                        <span v-else-if="scope.row.payType == 3">准备金</span>
                        <span v-else-if="scope.row.payType == 4">支付宝</span>
                        <span v-else-if="scope.row.payType == 5">银行卡</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="状态"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">待结算</span>
                        <span v-else-if="scope.row.status == 2">待审核</span>
                        <span v-else-if="scope.row.status == 3">审核完成</span>
                        <span v-else-if="scope.row.status == 4">失败</span>
                    </template>
                </el-table-column>
                
                <el-table-column
                    show-overflow-tooltip
                    prop="checkName"
                    label="审核人"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="checkTime"
                    label="审核时间"
                    min-width="150">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="remark"
                    label="备注"
                    min-width="150">
                </el-table-column>
                  <el-table-column
                    show-overflow-tooltip
                    prop="createDateTime"
                    label="创建时间"
                    min-width="150">
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="270">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleShowDetail(scope.row.id)">订单详情</el-button>
                        <el-button v-if="scope.row.status==2" size="mini" class="themed-button" type="primary" @click="handleCheck(scope.row)">审核通过</el-button>
                        <el-button v-if="scope.row.status == 2" size="mini" class="themed-button" type="primary" @click="checkReject(scope.row.id)">审核不通过</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="审核通过" :visible.sync="dialogAccessVisible" :destroy-on-close='true' width="500px">
        <el-form :model="accessForm">
            <el-form-item label="审核通过金额" prop="money">
                <el-input  v-model="accessForm.money"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="accessForm.remark"></el-input>
            </el-form-item>
              <!-- <el-form-item label="支付方式"  prop="payType">
                <el-select v-model="accessForm.payType">
                    <el-option label="微信" value="1"></el-option>
                    <el-option label="转预存" value="3"></el-option>
                </el-select>
            </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button class="themed-button" type="info" @click="dialogAccessVisible = false">取 消</el-button>
            <el-button class="themed-button" type="primary" @click="handleAccess">确 定</el-button>
        </div>
        </el-dialog>

        <el-dialog title="审核不通过" :visible.sync="dialogRejectVisible" :destroy-on-close='true'>
        <el-form :model="rejectForm">
            <el-form-item label="不通过理由" prop="reason">
            <el-input type="textarea" v-model="rejectForm.reason"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button class="themed-button" type="info" @click="dialogRejectVisible = false">取 消</el-button>
            <el-button class="themed-button" type="primary" @click="handleReject">确 定</el-button>
        </div>
        </el-dialog>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {withdrawalApprovalList,checkWithdrawalApproval,exportCheckWithdrawalApproval} from '@/api/order.js'
import {orderGetAgents} from '@/api/agentApplyCheck.js'
import resize from '@/mixins/resize.js'
export default {
    name:'withdrawalApproval',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return {
            withdrawalApplicationData:{
                agentId:'',
                status:'',
                startTime:'',
                endTime:''
            },
            optionsjsStatus:[
                {label:"全部",value:''},
                {label:"待结算",value:'1'},
                {label:"待审核",value:'2'},
                {label:"审核完成",value:'3'},
                {label:"失败",value:'4'},
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            agentList:[],
            dialogRejectVisible:false,
            rejectForm:{
                applyId:'',
                reason:'',
                type:'2'
            },
            dialogAccessVisible:false,
            accessForm:{
                money:'',
                remark:'',
                applyId:'',
                type:'1',
                // payType:""
            },
            maxMoney:'',
        }
    },
    created(){
        this.getWithdrawalApprovalList()
        this._orderGetAgents()
    },
    methods:{
        _orderGetAgents(){
            this.agentList = []
            this.agentList.push({
                label:'全部',
                value:''
            });
            this.agentList.push({
                label:'当前账号',
                value:'-1'
            });
            orderGetAgents({status:0}).then((res)=>{
                if(res.length>0){
                    res.forEach((item)=>{
                        this.agentList.push({
                            label:item.name,
                            value:item.id
                            })
                    })
                }
            })
        },
        getWithdrawalApprovalList(){
            let parame = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.withdrawalApplicationData,parame)
            withdrawalApprovalList(this.withdrawalApplicationData).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getWithdrawalApprovalList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getWithdrawalApprovalList()
        },
        onSearch(){
            this.page = 1
            this.getWithdrawalApprovalList()
        },
        exportExcel(){
            exportCheckWithdrawalApproval(this.withdrawalApplicationData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        handleShowDetail(id){
            this.$router.push({
                path:'/orderManagement/agentApplyCheckDetail',
                query:{'applyId':id}
            })
        },
        handleCheck(data){
            this.dialogAccessVisible = true
            this.accessForm.money = data.applyMoney
            this.accessForm.remark = data.remark
            this.accessForm.applyId = data.id
            // this.accessForm.payType = data.payType +""
            this.maxMoney = data.applyMoney 
        },

        handleAccess(){
            if(!this.accessForm.money || this.accessForm.money > this.maxMoney){
                this.$alert('请填写审核通过金额且不能超过提现金额', '提示', {
					confirmButtonText: '确定',
				});
				return
            }
            checkWithdrawalApproval(this.accessForm).then(res=>{
						this.$message({
							type: 'success',
							message:'提交成功'
                        });
                        this.dialogAccessVisible = false
                        this.getWithdrawalApprovalList()
					})
        },

        checkReject(id){
            this.dialogRejectVisible = true
            this.rejectForm.applyId = id
            this.rejectForm.reason = ''
        },
        handleReject(){
            if(!this.rejectForm.reason){
                this.$alert('请填写理由', '提示', {
					confirmButtonText: '确定',
				});
					return
            }
            checkWithdrawalApproval(this.rejectForm).then((res)=>{
                this.$message({
					type: 'success',
					message:'提交成功'
                });
                this.dialogRejectVisible = false
                this.getWithdrawalApprovalList()
            })
        },
    }
}
</script>
<style>
    .withdrawal-application .el-form-item__label{
        vertical-align: top;
        /* float:none; */
    }
</style>