<template>

<div class="content table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">网卡分销</span>
        <div class="right">
            <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="distributionCard">分销网卡</el-button>
        </div>
    </div>
    
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border>
            <el-table-column label="导入时间" prop="importDate"></el-table-column>
            <el-table-column label="导入数(张)" prop="importTotal"></el-table-column>
            <el-table-column label="导入成功数(张)" prop="importSuccess"></el-table-column>
            <el-table-column label="导入失败数(张)" prop="importFail"></el-table-column>
            <el-table-column label="操作人" prop="createUser"></el-table-column>
            <el-table-column label="操作" prop="opt">
                <template slot-scope="scope">
                    <el-button type="primary" class="themed-button"  @click='loadFail(scope.row.id)'>查看失败</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    
    <div class="pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 50,100,1000]"
            :page-size="pageSize"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>

    <el-dialog title="网卡分销" :visible.sync="dialogBox">
        <el-form label-position="right" label-width="200px">
            <el-form-item label="请选择excel文件:" >
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="代理商:"  prop="agent">
                <!-- <search-select :optionsMetaAll="agentLists"  v-model="agent" placeholder="请选择代理商"></search-select> -->
                <el-select v-model="agent" placeholder="请选择代理商" filterable>
					<el-option v-for="(item,index) in agentLists" :key="index" :label="item.label" :value="item.value"></el-option>
				</el-select>
            </el-form-item>
             <el-form-item label="ICCID/短号:">
                <el-input v-model="iccids" type="textarea"  placeholder="请输入ICCID(短号),每行一组，用英文逗号隔开"></el-input>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="importModel">导入excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('importIccidOrShort.xls')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取 消</el-button>
        </div>
    </el-dialog>
</div>

</template>

<script type="text/ecmascript-6">
import {listData,getagentList,importCardSync} from '@/api/cardManagement/distributionCard.js'
import SearchSelect from '@/components/SearchSelect'

import {messageBox,confirmBox} from '@/utils/common.js'

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import resize from '@/mixins/resize.js'

export default {
    name:'distributionCard',
    mixins:[resize],
    data(){
        return {
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogBox:false,
            agent:'',
            agentLists:[],
            iccids:""
        }
    },
    mounted(){
        this._listData()
         try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
    },
    methods:{
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        importModel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    if(this.agent==""){
                        messageBox(this,'请选择代理商')
                    return 
                    }
                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('agentList',this.agent)
                    importCardSync(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else if(!!this.iccids){
                 if(this.agent==""){
                        messageBox(this,'请选择代理商')
                    return 
                    }
                        let param = new FormData()
                            param.append('agentList',this.agent)
							param.append("iccids",this.iccids)
                            importCardSync(param).then(res=>{
                                 if (res.system_result_key == '0'){
                              this.$alert('添加成功', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
                                  this.dialogBox= false
                            	});
                                this._listData()
                                 }else{
                                     this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                                 }
                            })
                    }else{
                messageBox(this,'请选择文件或输入ICCID')
                return
            }
        },
        exportModel(){},
        loadFail(importCardId){
            this.$router.push({
                path: "/cardManagement/failList",
                query: {importCardId},
            })
        },
        distributionCard(){
            this.dialogBox = true
            getagentList().then((res)=>{
                this.agentLists = res.map((item)=>{
                    return {
                        label:item.name,
                        value:item.id
                    }
                })
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._listData()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._listData()
        },
        _listData(){
            let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                listData(params).then((res)=>{
                    // console.log(res)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
        }
    },
    components:{
        SearchSelect
    }
}
</script>

