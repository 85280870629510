import request from '../request.js'

//imeiWhite.vue
export function imeiWhitelist(params){
    return request({
        url: 'imeiWhitelist/list.do?',
        method: 'post',
        data:params
    })
}

export function deleteByIdImei(params){
    return request({
        url: 'imeiWhitelist/deleteByImei.do?',
        method: 'post',
        data:params
    })
}
export function batchImportSync(params){
    return request({
        url: 'imeiWhitelist/batchImportSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}





