<template>
	<div class="addPool">
		<el-form :model="addForm"  ref="addForm">
			<el-form-item label="入池扣费规则：" :label-width="formLabelWidth" >
				<el-select v-model="addForm.joinRule"  filterable clearable>
					<el-option value="">请选择入池扣费规则</el-option>
					<el-option v-for="item in xianxingArrEdit" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				 <div style="color:#f00;">入池扣费规则只能选择线性扣费规则</div>
			</el-form-item>
            	<el-form-item label="超量扣费规则：" :label-width="formLabelWidth" required prop="overFlowRule">
				<el-select v-model="addForm.overFlowRule"  filterable clearable>
					<el-option value="">请选择超量扣费规则</el-option>
					<el-option v-for="item in dialogEditRuleLists" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth" prop="name" >
				<el-input v-model="addForm.remark" autocomplete="off"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer" style="text-align: right;">
			<el-button type="primary" @click="uploadExcel('addForm')" class="themed-button">提交</el-button>
			<el-button type="info" @click="cancelDialog()" class="themed-button">取 消</el-button>
		</div>
	</div>
</template>
<script>
	import { editCardPool} from "@/api/trafficPoolManagement.js";
	import loading from '@/utils/Loading.js'
	export default {
		name:'editCardPool',
        props:["dialogEditRuleLists","edit"],
		data() {
			return {
				addForm: {
                    joinRule:"",
                    overFlowRule:"",
                    remark:"",
					id:""
				},
				formLabelWidth:"150px",
				xianxingArrEdit:[],
			};
		},
		 watch:{
			dialogEditRuleLists(value){
				//console.log(value)
			  this.xianxingArrEdit = value.filter(element => element.type == 1)
        console.log(this.xianxingArrEdit)
			}
		},
		mounted() {
		this.initData()	
		},
		methods: {
			initData(){
             this.addForm.joinRule = this.edit.joinRule
			 this.addForm.overFlowRule = this.edit.overFlowRule
			 this.addForm.remark = this.edit.remark
			 this.addForm.id = this.edit.id
			},
			cancelDialog() {
				this.$emit("close");
			},
			uploadExcel(formName) {
		 this.$refs[formName].validate((valid) => {
        if (valid) {
        	loading.show();
			editCardPool(this.addForm).then(responseResult => {
							if (responseResult.slide_msg_key == 1) {
								this.$alert(responseResult.slide_msg_message_key, "提示", {
									confirmButtonText: '确定',
								})
								return
							}
							// this.$emit("refresh")
							this.$emit("close")
						})
        } else {
          return false;
        }
      });
					
						
						
			},
		},
	};
</script>
