<template>

<div class="history-record table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">卡机分离历史记录</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="IMEI:">
                <el-input v-model="form.imei"></el-input>
            </el-form-item>
            <el-form-item label="库编号:">
                <el-select filterable v-model="form.imeiBankCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in imeiBankCodeList" :label="item.imeiBankCode" :value="item.imeiBankCode" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="ICCID:">
                <el-input v-model="form.iccid" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="接入号:">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="开始时间:">
                <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="form.startTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
                <el-date-picker placeholder="选择日期" value-format="yyyy-MM-dd" v-model="form.endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
        
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchData">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID" prop="iccid" show-overflow-tooltip></el-table-column>
            <el-table-column label="接入号" prop="phone" show-overflow-tooltip></el-table-column>
            <el-table-column label="绑定的IMEI" prop="imei" show-overflow-tooltip></el-table-column>
            <el-table-column label="实际IMEI" prop="factImei" show-overflow-tooltip></el-table-column>
            <el-table-column label="客户" prop="customerName" show-overflow-tooltip></el-table-column>
            <el-table-column label="分离时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="所属卡库" prop="cardBankCode" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" @click="deleteById(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

</div>

</template>

<script type="text/ecmascript-6">
    import {imeiBankCode,stSeperateLog,exportSeperateLogSync,deleteJiKaFenLi} from '@/api/meCardBindManagement/imeiLibrary.js'
    import {messageBox,confirmBox } from '@/utils/common.js'
    import resize from '@/mixins/resize.js'

    export default {
        // name:'historyRecord',
        mixins:[resize],
        data(){
            return {
                form:{
                    imeiBankCode:'',
                    iccid:'',
                    phone:'',
                    startTime:null,
                    endTime:null
                },
                imeiBankCodeList:[],
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0
            }
        },
        mounted(){
            this._imeiBankCode()
            this._stSeperateLog()
        },
        methods:{
            deleteById(id){
                confirmBox(this,"您确定要删除吗",'操作提示',()=>{
                    deleteJiKaFenLi(Object.assign({id},this.form)).then((res)=>{
                        messageBox(this,'删除成功')
                        this._stSeperateLog()
                    })
                })
            },
            exportExcel(){
                exportSeperateLogSync(this.form).then((res)=>{
                    if (res.system_result_key == '0') {
                        this.$alert('导出成功，请到导出记录中下载', '提示', {
                            confirmButtonText:'确定',
                        }).then(()=>{
                            this.$router.push('/tob/i_export_list')
                        });
                    }else{
                        this.$message({
                            showClose: true,
                            message: res.system_result_message_key,
                            type: 'error'
                        });
                    }
                })
            },
            searchData(){
                this._stSeperateLog()
            },
            handleSizeChange(val) {
                this.pageSize = val
                this._stSeperateLog()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this._stSeperateLog()
            },
            _imeiBankCode(){
                imeiBankCode().then((res)=>{
                    this.imeiBankCodeList = res
                })
            },
            _stSeperateLog(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                stSeperateLog(Object.assign(params,this.form)).then((res)=>{
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        }
    }
</script>

