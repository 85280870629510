<template>
    <div class="imaginary-switching fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">虚量切换列表</span>
            <el-form v-model="form" inline class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item label="运营商:">
                    <el-select filterable v-model="form.accountId">
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="(item,index) in accountList" :key="index" :value="item.id" :label="item.accountName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="ICCID:">
                    <el-input v-model="form.iccid" class="iccid-width"></el-input>
                </el-form-item>
                 <el-form-item label="短号:">
                    <el-input v-model="form.shortIccid" ></el-input>
                </el-form-item>
                <el-form-item label="虚量:">
                    <el-select v-model="form.afterStrategy">
                        <el-option value="" label="全部"></el-option>
                        <!-- <el-option value="1" label="虚量A"></el-option>
                        <el-option value="2" label="虚量B"></el-option> -->
                   <el-option v-for="item in $store.getters.defaultStrategyList" :key="item.dictKey" :label="item.groupName" :value="item.dictValue">
					</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注:">
                    <el-input v-model="form.remark"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>                
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
                <el-table-column label="ICCID" prop="iccid" width="180px"></el-table-column>
                 <el-table-column label="短号" prop="shortIccid" width="120px"></el-table-column>
                <el-table-column label="接入号" prop="accessNumber" show-overflow-tooltip></el-table-column>
                <el-table-column label="运营商" prop="accountName" show-overflow-tooltip></el-table-column>
                <el-table-column label="套餐" prop="mealName" show-overflow-tooltip></el-table-column>
                <el-table-column label="开始时间" prop="startDate" show-overflow-tooltip></el-table-column>
                <el-table-column label="结束时间" prop="endDate" show-overflow-tooltip></el-table-column>
                <el-table-column label="切换时间" prop="createTime" show-overflow-tooltip></el-table-column>
                <el-table-column label="修改前" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.beforeStrategy == 1">A</span> 
                        <span v-if="scope.row.beforeStrategy == 2">B</span>  -->
                         <span>{{strategyStr(scope.row.beforeStrategy)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="修改后" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.afterStrategy == 1">A</span> 
                        <span v-if="scope.row.afterStrategy == 2">B</span>  -->
                         <span>{{strategyStr(scope.row.afterStrategy)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作人" prop="creatorName" show-overflow-tooltip></el-table-column>
                <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" class="themed-button" @click="importUpdate(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="编辑" :visible.sync="dialogBox" v-if="dialogBox">
            <el-form  v-model="setForm" label-position="right" label-width="90px" style="max-height:500px;overflow:auto">
                <el-form-item label="ICCID:">
                    <el-input readonly v-model="setForm.iccid"></el-input>
                </el-form-item>
                <el-form-item label="接入号:">
                    <el-input readonly v-model="setForm.accessNumber"></el-input>
                </el-form-item>
                <el-form-item label="运营商:">
                    <el-input readonly v-model="setForm.accountName"></el-input>
                </el-form-item>
                <el-form-item label="套餐:">
                    <el-input readonly v-model="setForm.mealName"></el-input>
                </el-form-item>
                <el-form-item label="开始时间:">
                    <el-input readonly v-model="setForm.startDate"></el-input>
                </el-form-item>
                <el-form-item label="结束时间:">
                    <el-input readonly v-model="setForm.endDate"></el-input>
                </el-form-item>
                <el-form-item label="切换时间:">
                    <el-input readonly v-model="setForm.createTime"></el-input>
                </el-form-item>
                <el-form-item label="修改前:">
                    <el-input readonly>{{strategyStr(setForm.beforeStrategy)}}</el-input>
                    <!-- <el-input readonly v-if="setForm.beforeStrategy == 1">A</el-input>
                    <el-input readonly v-if="setForm.beforeStrategy == 2">B</el-input> -->
                </el-form-item>
                <el-form-item label="修改后:">
                    <!-- <el-input readonly v-if="setForm.afterStrategy == 1">A</el-input>
                    <el-input readonly v-if="setForm.afterStrategy == 2">B</el-input> -->
                    <el-input readonly>{{strategyStr(setForm.afterStrategy)}}</el-input>
                </el-form-item>
                <el-form-item label="操作人:">
                    <el-input readonly v-model="setForm.creatorName"></el-input>
                </el-form-item>
                <el-form-item label="备注:">
                    <el-input type="textarea" autosize v-model="setForm.remark"></el-input>
                </el-form-item>
            </el-form>  
            <div class="align-right">
                <el-button type="primary" class="themed-button" @click="sure">确定</el-button>
                <el-button type="primary" class="themed-button" @click="dialogBox=false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {getAccountList,imaginarySwitchList,imaginarySwitchExportSync,imaginarySwitchEdit} from "@/api/history.js"
import { messageBox, confirmBox } from "@/utils/common.js"
import store from '@/store'
export default {
    name:'ImaginarySwitching',
     mixins:[resize],
    data(){
        return {
            form:{
                accountId:'',
                iccid:'',
                afterStrategy:'',
                remark:'',
                shortIccid:"",
            },
            accountList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            setForm:{
                id:'',
                iccid:'',
                accessNumber:'',
                accountName:'',
                mealName:'',
                startDate:'',
                endDate:'',
                createTime:'',
                beforeStrategy:'',
                afterChange:'',
                creatorName:'',
                remark:''
            }
        }
    },
    mounted(){
         if(store.getters.defaultStrategyList.length<1){
           store.dispatch('getPackageType')
        }
        this._getAccountList()
        this._imaginarySwitchList()
    },
    methods: {
            strategyStr(strategy){
        let strategyS = "-"
           if(store.getters.defaultStrategyList.length>0){
            store.getters.defaultStrategyList.forEach(item=>{
              if(strategy==item.dictValue){
                   strategyS = item.groupName
              }
            })
               }
               return strategyS
        },
        importUpdate(data){
            this.setForm.id = data.id
            this.setForm.iccid = data.iccid
            this.setForm.phone = data.phone
            this.setForm.accountName = data.accountName
            this.setForm.mealName = data.mealName
            this.setForm.startDate = data.startDate
            this.setForm.endDate = data.endDate
            this.setForm.createTime = data.createTime
            this.setForm.beforeStrategy = data.beforeStrategy
            this.setForm.afterStrategy = data.afterStrategy
            this.setForm.creatorName = data.creatorName
            this.setForm.remark = data.remark
            this.dialogBox = true
        },
        sure(){
            imaginarySwitchEdit(this.setForm).then((res)=>{
                this.dialogBox = false
                messageBox(this,'修改成功')
                this._imaginarySwitchList()
            })
        },
        onPageChange(page){
			this.pageNo = page
			this._imaginarySwitchList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._imaginarySwitchList()
        },
        onSearch(){
            this._imaginarySwitchList()
        },
        exportExcel(){
            imaginarySwitchExportSync(this.form).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        _getAccountList(){
            getAccountList().then((res)=>{
                this.accountList = res
            })
        },
        _imaginarySwitchList(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize
            }
            imaginarySwitchList(Object.assign(params,this.form)).then((res)=>{
                console.log(res)
                this.tableData = res.rows
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
            })
        }
    },
}
</script>