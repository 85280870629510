<template>
    <div class="operator-label-setting fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">标签设置</span>
            <el-form inline ref="labelSettingForm" v-model="labelSettingFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
					<el-button icon="el-icon-circle-plus" class="themed-button" type="info" @click="add()">新增</el-button>
				</el-form-item>
            </el-form>
        </div>

         <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="minVal"
                    label="最小值"
                    min-width="100"
                    >
                </el-table-column>
                <el-table-column
                    prop="maxVal"
                    label="最大值"
                    min-width="100"
                    >
                </el-table-column>

                <el-table-column
                    prop="level"
                    label="档位"
                    min-width="100"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    prop="field"
                    label="字段"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="remark"
                    label="备注"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="创建时间"
                    min-width="150"
                    >
                </el-table-column>
                
                <el-table-column
                    label="操作"
                    min-width="150">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        class="themed-button" type="info"
                        @click="handleEdit(scope.row.id)"
                        >编辑</el-button>
                        <el-button
                        size="mini"
                        class="themed-button" type="danger"
                        @click="handleDelete(scope.row.id)"
                        >删除</el-button>
                    </template>
                </el-table-column>
                  
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="新增设置" :visible.sync="addDialogFormVisible" :destroy-on-close='true'>
            <el-form :model="addForm" :rules="rules" ref="addForm">
                <el-form-item label="区间最小值(G/分钟)" :label-width="formLabelWidth" prop="minVal">
                    <el-input v-model="addForm.minVal" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="区间最大值(G/分钟)" :label-width="formLabelWidth" prop="maxVal">
                    <el-input v-model="addForm.maxVal" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="字段" :label-width="formLabelWidth" prop="field">
                    <el-input v-model="addForm.field" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="档位" :label-width="formLabelWidth" prop="level">
                    <el-input v-model="addForm.level" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="运营商" :label-width="formLabelWidth" prop="accountId">
                    <el-select v-model="addForm.accountId" placeholder="请选择">
                        <el-option v-for="item in operatorList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                        
                    </el-select>
                </el-form-item>

                <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="addForm.remark" autocomplete="off"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureAdd('addForm')">确 定</el-button>
                <el-button class="themed-button" type="info" @click="reset('addForm')">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="addDialogFormVisible = false">关 闭</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改设置" :visible.sync="editDialogFormVisible" :destroy-on-close='true'>
            <el-form :model="editForm" :rules="rules" ref="editForm">
                <el-form-item label="区间最小值(G/分钟)" :label-width="formLabelWidth" prop="minVal">
                    <el-input v-model="editForm.minVal" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="区间最大值(G/分钟)" :label-width="formLabelWidth" prop="maxVal">
                    <el-input v-model="editForm.maxVal" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="字段" :label-width="formLabelWidth" prop="field">
                    <el-input v-model="editForm.field" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="档位" :label-width="formLabelWidth" prop="level">
                    <el-input v-model="editForm.level" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="运营商" :label-width="formLabelWidth" prop="accountId">
                    <el-select v-model="editForm.accountId" placeholder="请选择">
                        <el-option v-for="item in operatorList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="editForm.remark" autocomplete="off"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureEdit('editForm')">确 定</el-button>
                <el-button class="themed-button" type="info" @click="resetEdit()">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="editDialogFormVisible=false">关 闭</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { labelSettingList,addLabelSetting,operatorLabelList,labelDataById,updateLabelSetting,deleteLabelSetting} from "@/api/operator.js"
export default {
    name:'labelSetting',
    mixins:[resize],
    data(){
        return {
            operatorList:[],
            labelSettingFormData:{},
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            addDialogFormVisible:false,
            addForm:{
                minVal:'',
                maxVal:'',
                field:'',
                level:'',
                accountId:'',
                remark:'',
            },
            formLabelWidth:'150px',
            editDialogFormVisible:false,
            editForm:{
                id:'',
                minVal:'',
                maxVal:'',
                field:'',
                level:'',
                accountId:'',
                remark:'',
            },
            rules:{
                minVal:[
                    {required: true, message: '请输入区间最小值', trigger: 'blur'}
                ],
                 maxVal:[
                    {required: true, message: '请输入区间最大值', trigger: 'blur'}
                ],
                 field:[
                    {required: true, message: '请输入字段名称', trigger: 'blur'}
                ],
                 level:[
                    {required: true, message: '请输入档位', trigger: 'blur'}
                ],

            },
        }
    },
    created(){
        this.getLabelSettingList()
        this.getOperatorList()
    },
    methods:{
        getOperatorList(){
            operatorLabelList().then(res=>{
                this.operatorList = res
            })
        },
        getLabelSettingList(){
            let params={
                pageNo:this.page,
                pageSize:this.pageSize
            }
            labelSettingList(params).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        add(){
            this.addDialogFormVisible=true
        },
        sureAdd(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    addLabelSetting(this.addForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '新增成功',
					        type: 'success',
							center: true
                        })
                        this.$refs[formName].resetFields()
                        this.addDialogFormVisible=false
                        this.getLabelSettingList() 
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        reset(formName){
            this.$refs[formName].resetFields()
        },
        onPageChange(page){
            this.page = page
            this.getLabelSettingList()
		},
		handleSizeChange(size){
            this.pageSize = size
            this.getLabelSettingList()

        },
        handleEdit(id){
            this.editDialogFormVisible=true
            this.$nextTick(()=>{
                this.$refs['editForm'].clearValidate();
            })
            this.editForm.id = id
            labelDataById({'id':id}).then(res=>{
                this.editForm.minVal = res.entity.minVal
                this.editForm.maxVal = res.entity.maxVal
                this.editForm.field = res.entity.field
                this.editForm.level = res.entity.level
                this.editForm.accountId = res.entity.accountId
                this.editForm.remark = res.entity.remark
            })
        },
        sureEdit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    updateLabelSetting(this.editForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '修改成功',
					        type: 'success',
							center: true
                        })
                        this.editDialogFormVisible=false
                        this.getLabelSettingList()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetEdit(){
            let id = this.editForm.id
            this.handleEdit(id)
        },
        handleDelete(id){
            this.$confirm('删除将不可恢复,请确认是否删除?', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        deleteLabelSetting({'id':id}).then(res=>{
                            this.$message({
                            showClose: true,
                            type: 'success',
                            message: '删除成功!',
                            center: true
                            });
                           this.getLabelSettingList()
                    })
                        
             })
        },

    }
}
</script>