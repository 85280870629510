<template>
<div class="real-name-unbind fadeInRight">
    <div class="title-box clearfix">
        <span class="m-title">实名解绑</span>
        <el-form ref="quaryForm"  inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
            <el-form-item label="ICCID号:">
                    <el-input v-model="quaryForm.iccid" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                  <el-form-item label="短号:">
                    <el-input v-model="quaryForm.shortIccid" placeholder="短号" class="iccid-width"></el-input>
                </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="onSearch">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="info" icon="el-icon-plus" class="themed-button" @click="importByExcel">批量导入</el-button>
            </el-form-item>
        </el-form>
    </div>
    
    <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="ICCID号"
                    min-width="180"
                    >
                </el-table-column>
                 <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="创建时间"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    label="类型"
                    min-width="100">
                    <template slot-scope="scope">
                       <span v-if="scope.row.type==1">单卡</span>
                        <span v-else-if="scope.row.type==2">批量</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="msg"
                    label="信息"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="operator"
                    label="操作人"
                    min-width="120"
                    >
                </el-table-column>
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

    <el-dialog title="批量导入" :visible.sync="dialogBoxImport">
        <el-form label-position="right" label-width="120px">
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">提交</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('importIccidOrShort.xls')">导出模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import Utils from '@/utils/utils.js'
import resize from '@/mixins/resize.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import {exportTemplate} from '@/api/statistics.js'
import {getUnbindList,importUnbindSync} from '@/api/cardManagement/realNameUnbind.js'


export default {
    mixins:[resize],
    data(){
        return {
           quaryForm:{
               iccid:'',
               shortIccid:"",
           },
           dialogBoxImport:false,
           tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    mounted(){
        this.getUnbindRealNameList()
    },
    methods:{
        getUnbindRealNameList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.quaryForm,params)
            getUnbindList(this.quaryForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },

        importByExcel(){
            this.dialogBoxImport = true
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    importUnbindSync(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
       onPageChange(page){
			this.page = page
			this.getUnbindRealNameList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getUnbindRealNameList()
        },

        onSearch(){
            this.page=1
            this.getUnbindRealNameList()
        },
    }
}

</script>
<style >
.real-name-unbind  .el-form-item__label {vertical-align: top;}
</style>

