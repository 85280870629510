import request from './request.js'

export function speedServiceList(params){
    return request({
        url:'cardJiasu/list.do?',
        method:'post',
        data: params
    })
}

export function editSpeedService(params){
    return request({
        url:'cardJiasu/edit.do?',
        method:'post',
        data: params
    })
}

export function findByIccid(params){
    return request({
        url:'cardJiasu/findOne.do?',
        method:'post',
        data: params
    })
}

export function exportSpeedService(params){
    return request({
        url:'cardJiasu/export.do?',
        method:'post',
        data:params
    })
}

export function subscribeSpeed(params){
    return request({
        url:'cardJiasu/subscribe.do?',
        method:'post',
        data:params
    })
}

export function unsubscribeSpeed(params){
    return request({
        url:'cardJiasu/unsubscribe.do?',
        method:'post',
        data:params
    })
}



export function speedOperateList(params){
    return request({
        url:'cardJiasuLog/list.do?',
        method:'post',
        data:params
    })
}

export function exportSpeedOperate(params){
    return request({
        url:'cardJiasuLog/export.do?',
        method:'post',
        data:params
    })
}