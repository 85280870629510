/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function balanceManagementList(params){
    return request({
        url: '/card/balance/list.do?',
        method: 'post',
        data:params
    })
}

export function balanceChangeRecord(params){
    return request({
        url: '/card/balance/log.do?',
        method: 'post',
        data:params
    })
}
export function balanceRefund(params){
    return request({
        url: '/card/balance/refund.do?',
        method: 'post',
        data:params
    })
}
export function balanceModify(params){//修改
    return request({
        url: '/card/balance/change.do?',
        method: 'post',
        data:params
    })
}
export function exportBalanceList(params){//导出
    return request({
        url: 'card/balance/ExportExcelSync.do?',
        method: 'post',
        data:params
    })
}
export function exportBalanceChangeRecordList(params){//导出
    return request({
        url: 'card/balance/logExportExcelSync.do?',
        method: 'post',
        data:params
    })
}

export function getDisablingBills(params){//获取作废的订单
    return request({
        url: '/card/getDisablingBills.do?',
        method: 'post',
        data:params
    })
}
export function balanceRebateList(params){
    return request({
        url: '/card/balanceRebate/list.do?',
        method: 'post',
        data:params
    })
}

export function exportBalanceRebate(params){
    return request({
        url: '/card/balanceRebate/exportExcelSync.do?',
        method: 'post',
        data:params
    })
}
export function balanceRebateAgentList(params){
    return request({
        url: '/card/balanceRebate/agentList.do?',
        method: 'post',
        data:params
    })
}

export function exportAgentExcelSync(params){
    return request({
        url: '/card/balanceRebate/exportAgentExcelSync.do?',
        method: 'post',
        data:params
    })
}











