import request from './request.js'


export function delCardById(params) {
    return request({
        url: 'card/accelerationOpen/delete.do?',
        method: 'post',
        data: params
    })
}
export function addCard(params) {
    return request({
        url: 'card/accelerationOpen/importCardSync.do?',
        method: 'post',
        data: params,
		timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function delCard(params) {
    return request({
        url: 'card/accelerationOpen/deleteByExcelSync.do?',
        method: 'post',
        data: params,
		timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function getAcceleratorPackageCardList(params) {
    return request({
       url:"card/accelerationOpen/list.do?",
        method: 'post',
        data: params
    })
}
export function cardNoExport(params) {//加速卡导出
    return request({
        url: "card/accelerationOpen/exportExcelSync.do?",
        method: 'post',
        data: params
    })
}


//加速包 相关
export function getAcceleratorPackage(params) {
    return request({
       url:"card/accelerationPack/list.do?",
        method: 'post',
        data: params
    })
}

export function delPackageById(params) {
    return request({
        url: 'card/accelerationPack/delete.do?',
        method: 'post',
        data: params
    })
}
export function addAcceleratorPackage(params) {
    return request({
       url:"card/accelerationPack/add.do?",
        method: 'post',
        data: params
    })
}

export function updateAcceleratorPackage(params) {
    return request({
       url:"card/accelerationPack/update.do?",
        method: 'post',
        data: params
    })
}

//加速订购记录
export function getAcceleratorPackageRecord(params) {
    return request({
       url:"card/accPackRecord/list.do?",
        method: 'post',
        data: params
    })
}

export function accPackRecordExport(params) {//
    return request({
        url: "card/accPackRecord/exportExcelAsync.do?",
        method: 'post',
        data: params
    })
}
export function basicInfoById(params) {//
    return request({
        url: "card/accPackRecord/findById.do?",
        method: 'post',
        data: params
    })
}
export function refundMoney(params) {//退款
    return request({
        url: "order/agentwechatorder/accPackRefund.do?",
        method: 'post',
        data: params
    })
}





