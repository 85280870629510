<template>
    <div class='history-download-export fadeInRight'>
        <div class="title-box clearfix">
            <span class="m-title">下载导出记录</span>
            <el-form inline ref="downloadExportForm" v-model="downloadExportData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="下载类型">
                    <search-select  :optionsMetaAll="downloadOptions" v-model="downloadExportData.type"></search-select>
                </el-form-item>
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="下载起始日期" v-model="downloadExportData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="下载结束日期" v-model="downloadExportData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="businessType"
                    label="业务类型"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    label="下载类型"
                    min-width="10%"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==1">下载</span>
                        <span v-else-if="scope.row.type==2">导入</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    min-width="10%"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">完成</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="startTime"
                    label="开始时间"
                    min-width="20%"
                    >   
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="endTime"
                    label="结束时间"
                    min-width="20%"
                    >   
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="操作"
                    min-width="20%"
                    >
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="primary" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import resize from '@/mixins/resize.js'
import { downloadExportList,deleteDownloadExport } from "@/api/history.js";
export default {
    name:'downloadExport',
    components:{
        SearchSelect
    },
    mixins:[resize],
    data(){
        return {
            downloadExportData:{
                type:'',
                startTime:'',
                endTime:''
            },
            downloadOptions:[
                {label:"全部",value:''},
                {label:"下载",value:'1'},
                {label:"上传",value:'2'},
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0

        }
    },
    created(){
        this.getDownloadExportList()
    },
    methods:{
        getDownloadExportList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.downloadExportData,params)
            downloadExportList(this.downloadExportData).then(res=>{
                this.tableData=res.rows
                this.total=res.count
            })
        },
        onSearch(){
            this.page = 1
            this.getDownloadExportList()
        },
        handleDelete(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                deleteDownloadExport({'id':id}).then(res=>{
                    this.$message({
                    type: 'success',
                    message: '删除成功!',
                    });
                    this.getDownloadExportList()
                })
                
            })
        },
        handleSizeChange(size){
            this.pageSize=size
            this.getDownloadExportList()
        },
        onPageChange(page){
            this.page=page
            this.getDownloadExportList()
        },
    }
}
</script>

<style>
   .history-download-export .el-form-item__label{
        vertical-align: top;
    }
</style>