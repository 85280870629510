<template>
	<div class="trafficPool fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">流量池统计</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="日期：">
					<el-date-picker v-model="value1"  value-format="yyyy-MM-dd"   type="date" placeholder="选择日期" style="width:100%">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="开卡公司:">
					<el-select placeholder="开卡公司" v-model="searchIteam.currentComp" filterable>
						<el-option label="全部" value=""></el-option>
						<el-option v-for="item in companyArr" :key="item.id" :label="item.accountName" :value="item.id"></el-option>
					</el-select>

				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button" >搜索</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="info" @click="exportExcel" icon="el-icon-caret-left" class="themed-button">导出excel</el-button>
				</el-form-item>
				 <iframe name="hidden_down_iframe" id="hidden_down_iframe" style="display: none;"  src=""></iframe>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tableData" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column label="日期">
					<template slot-scope="scope">
						<span>{{millsToStr(scope.row.createDate, true, false)}}</span>
					</template>
				</el-table-column>
				<el-table-column  label="名称">
					<template slot-scope="scope">
						<a  class="a_color" @click="showDialog(scope.row.accountId,scope.row.accountName,scope.row.flowPool)" >{{scope.row.accountName}}</a>
							</template>
				</el-table-column>
				<el-table-column prop="cardType" label="流量池">
				</el-table-column>
				<el-table-column label="总流量(GB)">
					<template slot-scope="test">
						<span>{{getTotalTra(test.row.flowSizeTotal)}}</span>
					</template>
				</el-table-column>
				<el-table-column label="已使用流量(GB)">
					<template slot-scope="test">
						<span>{{getTotalTra(test.row.flowSizeUsed)}}</span>
					</template>
				</el-table-column>
				<el-table-column label="已使用流量百分比">
					<template slot-scope="test">
						<span>{{getTotalPercent(test.row.flowbaifenbi)}}</span>
					</template>
				</el-table-column>
				<el-table-column label="剩余流量(GB)">
					<template slot-scope="test">
						<span>{{getTotalTra(test.row.flowSizeRemaining)}}</span>
					</template>
				</el-table-column>
				<el-table-column label="昨日使用量(GB)">
					<template slot-scope="test">
						<span>{{getTotalTra(test.row.yesFlowSizeUsed)}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="cardCountTotal" label="总网卡数">
				</el-table-column>
				<el-table-column prop="cardCountInUse" label="在用网卡数">
				</el-table-column>
				<el-table-column prop="expiredCountThisMonth" label="本月已停机网卡数">
				</el-table-column>
				<el-table-column prop="cardCountToBeActivated" label="待激活网卡数">
				</el-table-column>
				<el-table-column prop="cardCountInTingji" label="停机网卡数">
				</el-table-column>
				<el-table-column prop="cardCountOther" label="其他网卡数">
				</el-table-column>
				<el-table-column prop="orderCountThisMonth" label="本月订单数">
				</el-table-column>
				<el-table-column prop="orderTotalMoneyThisMonth" label="本月订单金额">
				</el-table-column>
				<el-table-column prop="orderPrice" label="客单价">
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
		
	<el-dialog title="流量池成员列表" :visible.sync="dialogTableVisible" width="80%">
		<div>
			<span class="m-title">流量池成员列表</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="运营商：">
					  <el-input v-model="clickedAccountName" disabled></el-input>
				</el-form-item>
				<el-form-item label="流量池：" style="display: none;">
					  <el-input v-model="clickedFlowPool" ></el-input>
				</el-form-item>
				
				<el-form-item label="类型:">
					<el-select placeholder="类型" v-model="searchIteam.currentType" filterable>
						<el-option v-for="(item,index) in typeArr" :key="index" :label="item.name" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData1" icon="el-icon-search" class="themed-button">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
	  <el-table :data="gridData" stripe border style="width: 100%;" ref="table" :max-height="maxTableHeight" >
	    <el-table-column property="iccidMark" label="ICCID" ></el-table-column>
	    <el-table-column property="phone" label="接入号" ></el-table-column>
	    <el-table-column property="imei" label="IMEI"></el-table-column>
			<el-table-column property="accountName" label="开卡公司" ></el-table-column>
			<el-table-column property="flowPool" label="流量池" ></el-table-column>
			<el-table-column  label="卡状态">
				<template slot-scope = "scope1">
					<span>{{getCardStatus(scope1.row)}}</span>
				</template>
			</el-table-column>
			<el-table-column property="isRecharged" label="本月流量" >
				<template v-slot:default="scope">
					<span v-if="scope.row.isRecharge">已使用</span>
					<span v-else>未使用</span>
				</template>
			</el-table-column>
			<el-table-column property="enabledTime" label="上次激活时间" ></el-table-column>
			<el-table-column  label="操作">
				<template slot-scope = "scope2">
					<span v-if="!scope2.row.isRecharge">
					<!-- <a href='#' @click='usedCardFlow(scope2.row.accountId ,scope2.row.iccidMark)' >使用该卡流量 </a> -->
					<el-button type="warning"  class="themed-button" @click='usedCardFlow(scope2.row.accountId ,scope2.row.iccidMark)' >使用该卡流量</el-button>
					</span>
				</template>
			</el-table-column>
			
	  </el-table>
		<!-- <div class="page_box"> -->
			<el-pagination :current-page.sync="dialogPage" :page-sizes="[10,15,25,100]" :page-size="dialogPageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="dialogPageTotal" @size-change="dialogPagehandleSizeChange"
			 @current-change="dialogPageonPageChange">
			</el-pagination>
		<!-- </div> -->
	</el-dialog>
	</div>
</template>

<script>
	 import onresize from "@/mixins/resize.js"
	 import loading from '@/utils/Loading.js'
	import {
		trafficPoolStatics,
		trafficPoolGetComList,
		trafficPoolExport,
		trafficPoolMemberList,
		useCardTraffic
	} from "@/api/staticsManagement.js"
	export default {
		name:'trafficPoolStatistics',
		mixins: [onresize],
		data() {
			return {
				value1: "",
				searchIteam: {
					currentType:"0",
				},
				companyArr: [],
				tableData:[],
				page: 1,
				pageSize: 15,
				total: 0,
				dialogTableVisible:false,
				gridData:[],
				typeArr:[
					{value:"0",name:"全部"},
					{value:"1",name:"已充值使用"},
					{value:"2",name:"未充值停机"},
					{value:"3",name:"待激活"},
				],
				clickedAccountName:"",
				clickedID :"",
				clickedFlowPool:"",
				dialogPage:1,
				dialogPageSize:15,
				dialogPageTotal:0,
			}
		},
		created() {
			this.getCompanyList()
			this.getTrafficPoolList()
		},
		methods: {
			searchData() {
       let params = {
				 pageSize:this.pageSize,
				 pageNo:this.page,
				 createDateStr:this.value1,
				 accountId:this.searchIteam.currentComp
			 }
			 this.getTrafficPoolList(params)
			},
			/* exportExcel() {
        let params = {
					createDateStr:this.value1,
					accountId:this.searchIteam.currentComp
				}
				trafficPoolExport(params).then(()=>{ */
					/* ../../statistics/adminstatispool/export2excel.do?createDateStr=&accountId= */
					/* let createDateStr = this.value1==undefined ? "":this.value1
					let accountId = this.searchIteam.currentComp == undefined? "":this.searchIteam.currentComp
					let url = "https://iottest.sunshineiot.net/traffic-web/statistics/adminstatispool/export2excel.do?"+"createDateStr="+createDateStr +"&accountId="+accountId ;
					url = encodeURI(encodeURI(url));
					document.getElementById("hidden_down_iframe").src= url;
				})
				
			}, */
			exportExcel(){
							loading.show()
							let params = {
								createDateStr:this.value1,
								accountId:this.searchIteam.currentComp
							}
							trafficPoolExport(params).then(res=>{
								if (res.system_result_key == '0') {
									this.$alert('导出成功，请到导出记录中下载', '提示', {
									    confirmButtonText:'确定',
									}).then(()=>{
										this.$router.push('/tob/i_export_list')
									});
								}else{
									this.$message({
									    showClose: true,
									    message: res.system_result_message_key,
									    type: 'error'
									});
								}
							})
						},
			onPageChange(page) {
        this.page = page
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
					createDateStr:this.value1,
					accountId:this.searchIteam.currentComp,
				}
				this.getTrafficPoolList(params)
			},
			handleSizeChange(size) {
      this.pageSize = size
			let params = {
				pageSize:this.pageSize,
				pageNo:this.page,
				createDateStr:this.value1,
				accountId:this.searchIteam.currentComp,
			}
		this.getTrafficPoolList(params)
			},
			millsToStr(dateMills, hasTime, hasSec) {
				if (!dateMills) return "";
				var date = new Date(dateMills);
				var str = "";

				var mon = date.getMonth() + 1;
				if (mon < 10) {
					mon = "0" + mon;
				} else {
					mon = "" + mon;
				}
				var day = date.getDate();
				if (day < 10) {
					day = "0" + day;
				} else {
					day = "" + day;
				}

				str += date.getFullYear();
				str += ("-" + mon);
				str += ("-" + day);

				if (hasTime) {
					var hour = date.getHours();
					if (hour < 10) {
						hour = "0" + hour;
					} else {
						hour = "" + hour;
					}

					var min = date.getMinutes();
					if (min < 10) {
						min = "0" + min;
					} else {
						min = "" + min;
					}
					str += (" " + hour);
					str += (":" + min);

					if (hasSec) {
						var sec = date.getSeconds();
						if (sec < 10) {
							sec = "0" + sec;
						} else {
							sec = "" + sec;
						}
						str += (":" + sec);
					}
				}
				return str;
			},
			getTotalTra(value) {
				if (value) return (value / 1024).toFixed(2);
				else return "0";
			},
			getTotalPercent(value) {
				if (value) return (parseFloat(value) * 100).toFixed(2) + "%";
				else return "0";
			},
			getTrafficPoolList(params) {
       trafficPoolStatics(params).then(res=>{
				 this.tableData = res.rows
				 this.page = res.pageNo
				 this.total = res.total
				 this.pageSize = res.pageSize
			 })
			},
			
			getCompanyList(){
				trafficPoolGetComList().then(res1=>{
				this.companyArr = res1
				})
			},
			searchData1(){
				let params = {
					accountId:this.clickedID,
					iccid:"",
					accountName:this.clickedAccountName,
					flowPool:this.clickedFlowPool,
					type:this.searchIteam.currentType,
					pageSize: this.dialogPageSize,
					pageNo:this.dialogPage
				}
				this.getTrafficPoolMemberList(params)
			},
			dialogPagehandleSizeChange(size){
				this.dialogPageSize  = size
				let params = {
					pageSize:size,
					pageNo:this.dialogPage,
					accountId:this.clickedID,
					iccid:"",
					accountName:this.clickedAccountName,
					flowPool:this.clickedFlowPool,
					type:this.searchIteam.currentType,
				}
				this.getTrafficPoolMemberList(params)
			
			},
			dialogPageonPageChange(page){
				this.dialogPage = page
				let params = {
					pageSize:size,
					pageNo:this.dialogPage,
					accountId:this.clickedID,
					iccid:"",
					accountName:this.clickedAccountName,
					flowPool:this.clickedFlowPool,
					type:this.searchIteam.currentType,
				}
				this.getTrafficPoolMemberList(params)
			},
			getTrafficPoolMemberList(params){
				trafficPoolMemberList(params).then(rst=>{
					//console.log(rst)
					this.gridData = rst.rows
				  this.dialogPage = rst.pageNo
				  this.dialogPageTotal = rst.total
				  this.dialogPageSize = rst.pageSize
				})
			},
			showDialog(id,name,flowPool){
				let params = {
					accountId:id,
					iccid:"",
					accountName:"",
					flowPool:flowPool,
					type:this.searchIteam.currentType
				}
				this.clickedFlowPool = flowPool
				this.clickedID = id
				this.clickedAccountName =  name
				this.getTrafficPoolMemberList(params)
				this.dialogTableVisible = true
			},
			getCardStatus(data){
				if (data.cuankaFlag === "1") return "窜卡"
				if (data.status == 1) return "待激活"
				else if (data.status == 2) return "正常"
				else if (data.status == 3) return "断网"
				else if (data.status == 4) return "停机"
				else if (data.status == 5) return "异常"
			},
			
		 usedCardFlow(accountId, iccid) {
				//console.log("accountId=" + accountId + " iccid=" + iccid);
				let  params = {
					accountId:accountId,
					iccid:iccid,
					accountName:this.clickedAccountName,
					flowPool:this.clickedFlowPool,
					type:this.searchIteam.currentType
				}
				useCardTraffic(params).then(response=>{
					for(var i=0;i<response[sysResIds].length;i++){
								/* 原始代码处理 */
					/* 	if(document.getElementById('sys_res_'+response[sysResIds][i])!=null&&typeof(document.getElementById('sys_res_'+response[sysResIds][i]))!='undefined'){
							document.getElementById('sys_res_'+response[sysResIds][i]).style.display='';
						} */
					}
				})
			},
		

		}
	}
</script>

<style>
	.a_color{
		color:#337ab7
	}
	.trafficPool  .el-form-item__label {vertical-align: top;}
</style>
