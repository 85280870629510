<template>
    <div class="history-intel-shutdown fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">智能停复机记录</span>
            <el-form inline ref="intelShutdownForm" v-model="intelShutdownFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="intelShutdownFormData.iccid" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                  <el-form-item>
                    <el-input v-model="intelShutdownFormData.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch('1')">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>

                <el-form-item>
					<el-badge :value="totalStop" class="item" type="primary">
                        <el-button class="themed-button" type="primary" @click="onSearch('2')">停复机总数</el-button>
                    </el-badge>
				</el-form-item>

                <el-form-item>
					<el-badge :value="totalRecovery" class="item" type="primary">
                        <el-button  class="themed-button" type="primary" @click="onSearch('3')">复机总数</el-button>
                    </el-badge>
				</el-form-item>

                <el-form-item>
					<el-badge :value="smartRecovery" class="item" type="primary">
                        <el-button  class="themed-button" type="primary" @click="onSearch('4')">智能诊断复机数</el-button>
                    </el-badge>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                  <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="120"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="imei"
                    label="imei"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="stopTime"
                    label="停机时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="recoveryTime"
                    label="复机时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="recoveryWay"
                    label="复机方式"
                    min-width="150"
                    >
                </el-table-column>
                  
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { smartStopRecord,exportSmartStopRecord } from "@/api/history.js";
export default {
    name:'intelligentShutdown',
    mixins:[resize],
    data(){
        return {
            intelShutdownFormData:{
                iccid:'',
                shortIccid:"",
            },
            totalStop:'',
            totalRecovery:'',
            smartRecovery:'',
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            searchType:''
        }
    },
    created(){
        this.getSmartStopRecordList()
    },
    methods:{
        getSmartStopRecordList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize,
                searchType:this.searchType
            }
            Object.assign(this.intelShutdownFormData,params)
            smartStopRecord(this.intelShutdownFormData).then(res=>{
                this.tableData=res.rows
                this.total=res.count
                this.totalStop=res.params.totalStop
                this.totalRecovery=res.params.totalRecovery
                this.smartRecovery=res.params.smartRecovery
            })
        },
        onSearch(type){
            this.searchType = type
            if (type!=1) {
                this.intelShutdownFormData.iccid=''
            }
            this.getSmartStopRecordList()
        },
        exportExcel(){
            exportSmartStopRecord(this.intelShutdownFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        onPageChange(page){
			this.page = page
			this.getSmartStopRecordList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSmartStopRecordList()
        },
    }

}
</script>

<style>
.history-intel-shutdown .item {
  margin-right: 40px;
}
.history-intel-shutdown .el-badge__content.is-fixed{
    top:50%;
    right:1px;
}
</style>