<template>
  <div class="fadeInRight maintenanceOperation">
    <div class="title-box clearfix">
      <span class="m-title">运维操作</span>
    </div>

    <div class="content-box">
      <p>重启应用：</p>

      <el-button
        class="themed-button testReboot"
        type="primary"
        @click="sureRecord"
        >重启monitor</el-button
      >
    </div>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { restartMonitor } from "@/api/systemManagement.js";
export default {
  name: "maintenanceOperation",
  mixins: [onresize],
  data() {
    return {};
  },
  created() {},
  methods: {
    sureRecord() {
      restartMonitor({}).then((res) => {
        console.log(res);
        if (res.system_result_key == "0") {
          this.$alert("请求执行成功，会自动完成重启，请稍后。", "标题", {
            confirmButtonText: "确定",
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style>
.maintenanceOperation .testReboot {
  margin-top: 15px;
}
</style>