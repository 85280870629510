<template>
  <div class="filterUse">
      <div class="content-header">
          <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addTicket">新增使用规则</el-button>
          <el-input style="width:50%;max-width:250px;margin-left:20px;" placeholder="请输入搜索关键字，多个用空格隔开" v-model="couponsName" class="input-with-select">
               <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
          </el-input>
      </div>
      <div class="content-body">
        <el-table ref="table"  :data="tableData" style="width:100%"   max-height="250">
            <!-- <el-table-column label="代理商" prop="filterAgentName" show-overflow-tooltip></el-table-column> -->
            <el-table-column label="优惠券id" prop="promotionId" show-overflow-tooltip></el-table-column>
            <el-table-column label="可用套餐" prop="promotionMeal" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐名" prop="mealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="90px">
                <template slot-scope="scope">
                    <a href="javascript:void(0)" @click="delFilter(scope.row.id)">删除</a>
                </template>
            </el-table-column>
        </el-table>
    </div>

    <el-dialog title="新增规则" :visible.sync="dialogBox" append-to-body>
        <dialog-use :promotionId="id" @close="dialogBox = false" @refresh="refresh"></dialog-use>
    </el-dialog>
    
  </div>
</template>

<script type="text/ecmascript-6">
import { listRuleById,delUseFilterById } from "@/api/eventDiscountManagement/couponManagement.js";

import { messageBox, confirmBox } from "@/utils/common.js";
import SearchSelect from "@/components/SearchSelect";

import DialogUse from '@/views/eventDiscountManagement/couponManagement/dialogUse'

export default {
    props:['id'],
    data(){
        return {
            couponsName:'',
            tableData:[],
            dialogBox:false
        }
    },
    mounted(){
        this._listRuleById()
    },
    methods:{
        addTicket(){
            this.dialogBox = true
        },
        delFilter(id){
            delUseFilterById({id}).then((res)=>{
                this._listRuleById()
            })
        },
        searchData(){
            this._listRuleById()
        },
        refresh(){
            this._listRuleById()
        },
        _listRuleById(){
            let params = {id:this.id,couponsName:this.couponsName}
            listRuleById(params).then((res)=>{
                if(res.app_result_key == 1){
                    messageBox(this,res.system_result_key)
                    return
                }
                this.tableData = res.couponsUseList
            })
        }
    },
    components:{
        DialogUse
    }
};
</script>
<style>
    .content-header{margin-bottom:15px}
</style>
