import request from './../request.js'

export function clearRecordList(params){ //get 清理记录列表
    return request({
        url: '/card/clearRecord/list.do?',
        method: 'post',
        data:params
    })
}



export function deleteRecordSingle(params){ //删除单条记录
    return request({
        url: '/card/clearRecord/deleteById.do?',
        method: 'post',
        data:params
    })
}
