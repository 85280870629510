/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function getBuySendList(params){
    return request({
        url: 'promotion/buySend/list.do?',
        method: 'post',
        data:params
    })
}
export function updateData(params){
    return request({
        url: 'promotion/buySend/update.do',
        method: 'post',
        data:params
    })
}
export function deleteData(params){
    return request({
        url: 'promotion/buySend/delete.do?',
        method: 'post',
        data:params
    })
}



//newCoupons.vue 
export function buySendSave(params){
    return request({
        url: 'promotion/buySend/save.do?',
        method: 'post',
        data:params
    })
}
export function getAgentList(params){
    return request({
        url: 'agent/list4agent.do?',
        method: 'post',
        data:params
    })
}
export function getMealListByAccountId(params){
    return request({
        url: 'promotion/buySend/getMealListByAccountId.do?',
        method: 'post',
        data:params
    })
}
export function getSongMealListByAccountId(params){
    return request({
        url: 'promotion/buySend/getSongMealListByAccountId.do?',
        method: 'post',
        data:params
    })
}

