<template>
  <div class="addGood">
    <el-form :model="formAddTOC" :rules="rulesAddTOC" ref="ruleFormAddTOC">
      <el-form-item
        label="商品名称:"
        :label-width="formLabelWidth"
        prop="productName"
      >
        <el-input
          v-model="formAddTOC.productName"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="单价:" :label-width="formLabelWidth" prop="fee">
        <el-input v-model="formAddTOC.fee" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="套餐类型:"
        :label-width="formLabelWidth"
        prop="mealType"
      >
        <el-select v-model="formAddTOC.mealType">
          <el-option label="按月" value="1"></el-option>
          <el-option label="累计" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="套餐规格:(M)"
        :label-width="formLabelWidth"
        prop="mealSize"
      >
        <el-input v-model="formAddTOC.mealSize" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="套餐月数:(月)"
        :label-width="formLabelWidth"
        prop="mealCount"
      >
       <el-select v-model="formAddTOC.mealCount">
          <el-option label="1" value="1"></el-option>
          <el-option label="3" value="3"></el-option>
          <el-option label="6" value="6"></el-option>
          <el-option label="12" value="12"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态:" :label-width="formLabelWidth" prop="status">
        <el-select v-model="formAddTOC.status">
          <el-option label="上架" value="1"></el-option>
          <el-option label="下架" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="卡类型:" :label-width="formLabelWidth" prop="cardType">
        <el-select v-model="formAddTOC.cardType">
          <el-option label="普通物联网卡" value="1"></el-option>
          <!-- <el-option label="NBIOT卡" value="2"></el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item
        label="运营商:"
        prop="accountType"
        :label-width="formLabelWidth"
      >
        <el-select v-model="formAddTOC.accountType" @change="changeAccount">
          <el-option label="请选择运营商" value=""></el-option>
          <el-option
            v-for="(item, index) in categorys"
            :label="item.name"
            :value="item.value"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="支持选项列表:"
        :label-width="formLabelWidth"
        prop="choiceId"
      >
        <el-select v-model="formAddTOC.choiceId">
          <el-option label="请选择支持项" value=""></el-option>
          <el-option
            v-for="(item, index) in childList"
            :label="item.name"
            :value="item.value"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="formAddTOCSubmit('ruleFormAddTOC')"
        class="themed-button"
        >确 定</el-button
      >
      <el-button
        type="primary"
        @click="formAddTOCReset('ruleFormAddTOC')"
        class="themed-button"
        >重置</el-button
      >
      <el-button
        type="info"
        @click="cancelDialogformAddTOC('ruleFormAddTOC')"
        class="themed-button"
        >取 消</el-button
      >
    </div>
  </div>
</template>

<script>
import { addGoodinfo, supportList } from "@/api/goodList.js";
import { mapGetters } from "vuex";
import store from "@/store";
import dictGroup from "@/mixins/dictGroup.js";
export default {
  name: "addGood",
  mixins: [dictGroup],
  // props:["childList"],
  data() {
     var  validateMealSize = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('套餐规格不能为空'));
        } else {
          if (! /^[1-9]\d*$/.test(value)) {
            callback(new Error('请输入大于0的正整数'));
          }
          callback();
        }
      };
    var validatefee = (rule, value, callback) => {
       if (value === "") {
        callback(new Error("请输入商品费用"));
      } else {
        if (!/^\d+(\.\d{0,2})?$/.test(value)) {
          callback(new Error("商品费用只能为数字最多保留2位小数"));
        }
          if (value>99999) {
          callback(new Error("商品费用不能大于99999"));
        }
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请输入商品名称！"));
      } else {
        callback();
      }
    };

    return {
      formAddTOC: {
        productName: "",
        fee: "",
        status: "1",
        cardType: "1",
        choiceId: "",
        accountType: "",
        mealType: "",
        mealSize: "",
        mealCount:""
      },
      childList:[],
      rulesAddTOC: {
        productName: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        fee: [
          {
            required: true,
            validator: validatefee,
            trigger: "blur",
          },
        ],
        accountType: [
          {
            required: true,
            message: "运营商不能为空",
            trigger: "change",
          },
        ],
        choiceId: [
          {
            required: true,
            message: "商品支持选项列表不能为空",
            trigger: "change",
          },
        ],
         mealType: [
          {
            required: true,
            message: "套餐类型不能为空",
            trigger: "change",
          },
        ],
         mealSize: [
           {
            required: true,
            validator: validateMealSize,
            trigger: "blur",
          },
        
        ],
         mealCount: [
          {
            required: true,
            message: "套餐月数不能为空",
            trigger: "change",
          },
        ],
      },
      formLabelWidth: "120px",
    };
  },
  created() {
    if (store.getters.categorys.length < 1) {
      store.dispatch("getCategory");
    }
    // this.getSupportGood();
  },
  computed: {
    ...mapGetters(["categorys"]),
  },
  methods: {
     changeAccount(val){
      this.childList=[]
      this.formAddTOC.choiceId =""
       supportList({accountType:val}).then((res) => {
        res.forEach((item) => {
          this.childList.push({ value: item.id, name: item.name });
        });

        console.log(this.childList);
      });
    },
    // getSupportGood() {
    //   supportList({}).then((res) => {
    //     res.forEach((item) => {
    //       this.supportList.push({ value: item.id, name: item.name });
    //     });

    //     console.log(this.supportList);
    //   });
    // },
    formAddTOCSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addGoodinfo(this.formAddTOC).then((responseResult) => {
            if (responseResult.slide_msg_key == 1) {
              this.$message({
                showClose: true,
                message: responseResult.slide_msg_message_key,
                type: "warning",
              });
            }

            this.$emit("refresh");
            this.$emit("close");
          });
        } else {
          return false;
        }
      });
    },
    formAddTOCReset(formName) {
      this.$refs[formName].resetFields();
      this.formAddTOC.productName = "";
      this.formAddTOC.fee = "";
      this.formAddTOC.status = "1";
      this.formAddTOC.cardType = "1";
      this.formAddTOC.packageType = "";
      this.formAddTOC.accountType = "";
      this.formAddTOC.mealType = "1";
      this.formAddTOC.mealSize = "";
    },
    cancelDialogformAddTOC(formName) {
      this.$emit("close");
    },
  },
};
</script>

<style>
.addGood .dialog-footer {
  text-align: right;
}
.addGood .el-input--small {
  width: 80%;
}
</style>
