<template>

<div class="account-rate table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">虚拟运营商速率列表</span>
        <el-form inline v-model="form"  class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="运营商:">
                <el-select v-model="form.accountId" filterable>
                    <el-option v-for="(item,index) in accountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="速率档位:">
                <el-input v-model="form.speedKey"></el-input>
            </el-form-item>
            <el-form-item label="限速值:">
                <el-input v-model="form.speedValue"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addList">新增</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="运营商" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="速率档位" prop="speedKey" show-overflow-tooltip></el-table-column>
            <el-table-column label="速率值" prop="speedValue" show-overflow-tooltip></el-table-column>
             <el-table-column label="设备速率值" prop="deviceSpeedValue" show-overflow-tooltip></el-table-column>
            <el-table-column label="系统档位" prop="systemGear" show-overflow-tooltip></el-table-column>
            <el-table-column label="排序" prop="sort" show-overflow-tooltip></el-table-column>
            <el-table-column label="电信速率" prop="dxKey" show-overflow-tooltip></el-table-column>
            <el-table-column label="电信限速值" prop="dxValue" show-overflow-tooltip></el-table-column>
            <el-table-column label="移动速率" prop="ydKey" show-overflow-tooltip></el-table-column>
            <el-table-column label="移动限速值" prop="ydValue" show-overflow-tooltip></el-table-column>
            <el-table-column label="联通速率" prop="ltKey" show-overflow-tooltip></el-table-column>
            <el-table-column label="联通限速值" prop="ltValue" show-overflow-tooltip></el-table-column>
            <el-table-column label="更新时间" prop="updateTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="160">
                <template  slot-scope="scope">
                    <el-button class="themed-button" type="info" size="mini" @click="edit(scope.row)">编辑</el-button>
                    <el-button class="themed-button" type="danger" size="mini" @click="delect(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogAdd" v-if="dialogAdd"> 
        <el-form v-model="formAdd" label-position="right" label-width="130px" v-loading="addLoading" style="height:600px;overflow:auto">
            <el-form-item label="运营商:" required>
                <el-select v-model="formAdd.accountId" filterable>
                    <el-option v-for="(item,index) in accountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="速率档位:" required>
                <el-input v-model="formAdd.speedKey"></el-input>
            </el-form-item>
            <el-form-item label="速率值:(kbs)" required>
                <el-input v-model="formAdd.speedValue"></el-input>
            </el-form-item>
             <el-form-item label="设备速率值:(kbs)">
                <el-input v-model="formAdd.deviceSpeedValue"></el-input>
            </el-form-item>
            <el-form-item label="系统档位:" required>
                <el-input v-model="formAdd.systemGear"></el-input>
            </el-form-item>
            <el-form-item label="排序:" required>
                <el-input v-model="formAdd.sort"></el-input>
            </el-form-item>
            <el-form-item label="移动运营商:" required>
                <el-select v-model="formAdd.ydAccount" @change="(val)=>{_getSpeedList(val,2)}">
                    <el-option v-for="(item,index) in ydAccountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="移动速率" required>
                <el-select v-model="formAdd.ydSpeedId" @change="(val)=>{getSpeedValue(val,2)}">
                    <el-option v-for="(item,index) in ydSpeedList" :label="item.speedKey" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="移动限速值">
                <el-input v-model="speedValue.yd" readonly></el-input>
            </el-form-item>
            <el-form-item label="联通运营商:" required>
                <el-select v-model="formAdd.ltAccount" @change="(val)=>{_getSpeedList(val,3)}">
                    <el-option v-for="(item,index) in ltAccountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="联通速率" required>
                <el-select v-model="formAdd.ltSpeedId" @change="(val)=>{getSpeedValue(val,3)}">
                    <el-option v-for="(item,index) in ltSpeedList" :label="item.speedKey" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="联通限速值">
                <el-input v-model="speedValue.lt" readonly></el-input>
            </el-form-item>
            <el-form-item label="电信运营商:" required>
                <el-select v-model="formAdd.dxAccount" @change="(val)=>{_getSpeedList(val,1)}">
                    <el-option v-for="(item,index) in dxAccountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="电信速率" required>
                <el-select v-model="formAdd.dxSpeedId" @change="(val)=>{getSpeedValue(val,1)}">
                    <el-option v-for="(item,index) in dxSpeedList" :label="item.speedKey" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="电信限速值">
                <el-input v-model="speedValue.dx" readonly></el-input>
            </el-form-item>
        </el-form>  
        <div class="align-right">
            <el-button type="primary" class="themed-button" @click="handleSubmit">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogAdd=false">取消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">

import {getAccountList,getSpeedList,getAccountIdsByAc,getInvSpeedList,insertInvSpeed,delInvSpeed,getSpeedListUpdate} from '@/api/virtualCard/accountRateList.js'
import {messageBox,confirmBox} from '@/utils/common.js'

import resize from '@/mixins/resize.js'

export default {
    name:'accountRateList',
    mixins:[resize],
    data(){
        return {
            form:{
                accountId:'',
                speedKey:'',
                speedValue:''
            },
            accountList:[],
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogEdit:false,
            formEdit:{
                accountId:'',
                speedKey:'',
                speedValue:'',
                systemGear:'',
                sort:'',
                updateTime:'',
                deviceSpeedValue:""
            },
            dialogTitle:'',
            dialogAdd:false,
            formAdd:{
                id:'',
                accountId:'',
                speedKey:'',
                speedValue:'',
                systemGear:'',
                sort:'',
                ydAccount:'',
                ltAccount:'',
                dxAccount:'',
                ydSpeedId:'',
                ltSpeedId:'',
                dxSpeedId:'',
                deviceSpeedValue:"",
            },
            addLoading:false,
            dxAccountList:[],
            ydAccountList:[],
            ltAccountList:[],
            dxSpeedList:[],
            ydSpeedList:[],
            ltSpeedList:[],
            speedValue:{
                yd:'',
                lt:'',
                dx:''
            }
        }
    },
    mounted(){
        this._getAccountList()
        this._getAccountListByCard()
        this._getInvSpeedList()
    },
    methods:{
        addList(){
          this.dialogAdd = true  
          this.resetAddFrom()
          this.dialogTitle = '新增'
          this.formAdd.id=''
        },

        edit(data){
            console.log(data,203)
             this.dialogTitle = '编辑'
             this.formAdd.id = data.id
             this.formAdd.accountId = data.accountId
             this.formAdd.speedKey = data.speedKey
             this.formAdd.speedValue = data.speedValue
             this.formAdd.systemGear = data.systemGear
             this.formAdd.sort = data.sort
             this.formAdd.ydAccount = data.ydAccount
             this.formAdd.ltAccount = data.ltAccount
             this.formAdd.dxAccount = data.dxAccount
             this.formAdd.ydSpeedId = data.ydSpeedId
             this.formAdd.ltSpeedId = data.ltSpeedId
             console.log(this.formAdd.ltSpeedId,data.ltSpeedId,216)
             this.formAdd.dxSpeedId = data.dxSpeedId,
             this.formAdd.deviceSpeedValue = data.deviceSpeedValue

             this._getEditSpeedList(this.formAdd.dxAccount,'1')
             this._getEditSpeedList(this.formAdd.ydAccount,'2')
             this._getEditSpeedList(this.formAdd.ltAccount,'3')
             this.speedValue = {
                yd:data.ydValue,
                lt:data.ltValue,
                dx:data.dxValue
            }
            this.dialogAdd = true

        },
        handleSubmit(){
            if(!!this.formAdd.id){ //bianji
                insertInvSpeed(this.formAdd).then((res)=>{
                this.dialogAdd = false
                this._getInvSpeedList()
                messageBox(this,'编辑成功')
                })
            }else{ //tianjia
                insertInvSpeed(this.formAdd).then((res)=>{
                this.dialogAdd = false
                this._getInvSpeedList()
                messageBox(this,'添加成功')
                })
            }
            
        },
        resetAddFrom(){
            this.formAdd = {
                accountId:'',
                speedKey:'',
                speedValue:'',
                systemGear:'',
                sort:'',
                ydAccount:'',
                ltAccount:'',
                dxAccount:'',
                ydSpeedId:'',
                ltSpeedId:'',
                dxSpeedId:'',
                deviceSpeedValue:"",
            }
            this.speedValue={
                yd:'',
                lt:'',
                dx:''
            }
        },

        delect(id){
            confirmBox(this,'是否确定删除?',null,()=>{
                delInvSpeed({id}).then((res)=>{
                    this._getInvSpeedList()
                    messageBox(this,'删除成功')
                })
            })
        },
        searchTable(){
            this._getInvSpeedList()
        },
        handleSizeChange(val){
            this.pageSize = val
            this._getInvSpeedList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._getInvSpeedList()
        },
        productTypeName(productType){
            let result = this.productTypeList.find((item)=>{
                return item.value == productType
            })
            if(!result){
                return '-'
            }
            if(result.label){
                return result.label
            }else{
                return '-'
            }
        },
        _getAccountList(){
            getAccountList().then((res)=>{
                this.accountList = res
            })
        },
        _getInvSpeedList(){
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            getInvSpeedList(Object.assign(params,this.form)).then((res) => {
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        },
        _getAccountListByCard(){
            this.addLoading = true
            getAccountIdsByAc({type:'1'}).then(res=>{ //电信
                this.dxAccountList = res
                this.addLoading = false
            })
            getAccountIdsByAc({type:'2'}).then(res=>{ //移动
                this.ydAccountList = res
                this.addLoading = false
            })
            getAccountIdsByAc({type:'3'}).then(res=>{ //联通
                this.ltAccountList = res
                this.addLoading = false
            })
        },
        _getSpeedList(accountId,type){
            getSpeedListUpdate({accountId}).then((res)=>{
                if(type == 1){  //电信
                    this.formAdd.dxSpeedId = ''
                    this.dxSpeedList = res.rows
                }else if(type == 2){ //移动
                    this.formAdd.ydSpeedId = ''
                    this.ydSpeedList = res.rows
                }else if(type == 3){ //联通
                    this.formAdd.ltSpeedId = ''
                    this.ltSpeedList = res.rows
                }
            })
        },
        _getEditSpeedList(accountId,type){
            getSpeedListUpdate({accountId}).then((res)=>{
                if(type == 1){  //电信
                    this.dxSpeedList = res.rows
                }else if(type == 2){ //移动
                    this.ydSpeedList = res.rows
                }else if(type == 3){ //联通
                    this.ltSpeedList = res.rows
                }
            })
        },
        getSpeedValue(value,type){
            let arr = []
            console.log(value,359)
            if(type == 1){
                arr = this.dxSpeedList
            }else if(type == 2){
                arr = this.ydSpeedList
            }else if(type == 3){
                arr = this.ltSpeedList
            }
            let speedValue = ''
            arr.forEach((item)=>{
                if(value == item.id){
                    speedValue = item.speedValue
                }
            })
            if(type == 1){
                this.speedValue.dx = speedValue
            }else if(type == 2){
                this.speedValue.yd = speedValue
            }else if(type == 3){
                this.speedValue.lt = speedValue
            }
        }
    }
}
</script>
