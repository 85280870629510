<template>
	<div class="agentRebateConfig  fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">返利设置</span>
			<el-form :inline="true" :model="formInline" class="right themed-form search-form"
				style="height: auto;line-height: 40px;">
				<el-form-item label="分销商：">
					<el-select v-model="formInline.agentId" filterable>
						<el-option label="全部" value="">
						</el-option>
						<el-option v-for="item in options" :key="item.agentId" :label="item.agentName" :value="item.agentId">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询
					</el-button>
				</el-form-item>

			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="agentName" label="代理商">
				</el-table-column>
				<el-table-column prop="rebateType" label="返佣方式">
					<template v-slot:default="scope">
						<span v-if="scope.row.rebateType ==1">普通</span>
						<span v-else-if="scope.row.rebateType ==2">秒返</span>
					</template>

				</el-table-column>
				<el-table-column prop="applyFrequency" label="月返现频率">
				</el-table-column>
				<el-table-column prop="maxApply" label="单笔限额">
				</el-table-column>
				<el-table-column prop="bankReceiver" label="收款人">
				</el-table-column>
				<el-table-column prop="receiverIdCard" label="收款人身份证">
				</el-table-column>
				<el-table-column prop="bankCode" label="收款账户">
				</el-table-column>

				<el-table-column prop="phone" label="手机号">
				</el-table-column>
				<el-table-column prop="singleAuditFreeAmount" label="单笔免审金额">
				</el-table-column>

				<el-table-column prop="frozenMoney" label="冻结金额">
				</el-table-column>
				<el-table-column prop="updateUser" label="修改人">
				</el-table-column>
				<el-table-column prop="updateTime" label="修改时间">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">

						<span @click='editSetMeal(scope.row.id)' style="color: #337ab7;">编辑</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize"
				style="text-align: left;margin-top: 20px;" background layout="total,sizes,prev, pager, next,jumper"
				:total="total" @size-change="handleSizeChange" @current-change="onPageChange">
			</el-pagination>
		</div>
		<el-dialog title="修改返利比" :visible.sync="dialogVisible" v-if="dialogVisible">
			<rebate-update  :childId="fathId"  @close="dialogVisible=false" @reload="reload"></rebate-update>
		</el-dialog>
	</div>
</template>

<script>
	import rebateUpdate from "./rebateUpdate.vue"
	import onresize from "@/mixins/resize.js"
	import {
		agentListAll,
		agentRebateCinfigList
	} from "@/api/agentManagement.js"
	export default {
		name:'agentRebateConfig',
		mixins: [onresize],
		data() {
			return {
				page: 1,
				pageSize: 15,
				total: 0,
				tabledata: [],
				options: [],
				formInline: {
					agentId: ""
				},
				defaultRate: 0.0,
				dialogVisible:false,
				fathId:"",
			}
		},
		created(){
			this.getAgentListAll(this.formInline)
			this.getAgentRebateCinfigList(this.formInline)
		},
		components:{
			rebateUpdate
		},
		methods: {
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getAgentRebateCinfigList(params)
			},
			onPageChange(page) {
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getAgentRebateCinfigList(params)
			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.getAgentRebateCinfigList(params)
			},
			getAgentListAll(params) {
				//console.log("111")
				agentListAll(params).then(res => {

					if (res.slide_msg_key == 1) {
						this.$alert(res.slide_msg_message_key, "提示", {
							confirmButtonText: '确定',
						})
					}
					this.options = res
				})
			},
			getAgentRebateCinfigList(params) {
				agentRebateCinfigList(params).then(res => {
					if (typeof res.defaultRate !== "undefined" && res.defaultRate) {
						this.defaultRate = parseFloat(res.defaultRate);
						if (isNaN(this.defaultRate) || this.defaultRate < 0 || this.defaultRate > 1) {
							this.defaultRate = 0.0;
						}
					}
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			editSetMeal(id) {
            this.dialogVisible = true
			this.fathId = id
			},
			reload(){
			let params = {
				pageSize: this.pageSize,
				pageNo: this.page
			}
			Object.assign(params, this.formInline)
			this.getAgentRebateCinfigList(params)	
			},
		}
	}
</script>

<style>
	.agentRebateConfig .el-form-item__label {
		vertical-align: middle;
	}
</style>
