<template>

<div class="add-patch">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="库编号:">
            <el-select  v-model="form.imeiBankCode" filterable>
                <el-option v-for="(item,index) in imeiBankCodeList" :label="item.imeiBankCode" :value="item.imeiBankCode" :key="index"></el-option>
            </el-select>
        </el-form-item>
       
        <!-- <el-form-item label="数量:" prop="amount" required>
            <el-input v-model="form.amount"></el-input>
        </el-form-item> -->
        <el-form-item label="上传文件（excel）:" required>
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
            </div>
        </el-form-item>
        <el-form-item label="IMEI:">
             <el-input v-model="form.imeiIccids" type="textarea"  placeholder="请输入imei和贴片卡号,每行一组，用英文逗号分开"></el-input>
        </el-form-item>
       
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel('form')">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {importAddSync,imeiBankCode} from '@/api/meCardBindManagement/patchCardList.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    data(){
        return {
            form:{
                imeiBankCode:'',
                // amount:'',
                // customerName:''
                imeiIccids:'',
            },
            imeiBankCodeList:[],
            rules: {
                amount: [
                    { required: true, message: '请选择数量', trigger: 'blur' }
                ],
            },
        }
    },
    mounted(){
        this._imeiBankCode()
    },
    methods:{
      
        uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            // alert('submit!');
                            let params = new FormData()
                            params.append('fileurl',file)
                            params.append('imeiBankCode',this.form.imeiBankCode)
                            // params.append('amount',this.form.amount)
                            importAddSync(params).then(res=>{
                                if (res.system_result_key == '0') {
                                    this.$alert('导入成功，请到导入记录中下载', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.$emit('close-dialog')
                                        this.$router.push('/tob/i_import_list')
                                    });
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                }
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
            }else if(!!this.form.imeiIccids){
                        this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let params = new FormData()
                            params.append('imeiIccids',this.form.imeiIccids)
                            params.append('imeiBankCode',this.form.imeiBankCode)
                            //  params.append('amount',this.form.amount)
                            importAddSync(params).then(res=>{
                                if (res.system_result_key == '0') {
                                    if(!!res.system_result_message_key){
                                  this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                    this.$emit('close-dialog')
                                    this.$emit("refresh")
                                    }else{
                         this.$alert('添加成功', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.$emit('close-dialog')
                                        this.$emit("refresh")
                                        // this.$router.push('/tob/i_import_list')
                                    });
                                    }
                                   
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                    this.$emit('close-dialog')
                                }
                            })
                        } 
                    });
            }else{
                messageBox(this,'请选择文件或输入IMEI')
                return
            }
        },
        importExcel(){
            let name = 'import_simplus.xlsx'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        _imeiBankCode(){
            imeiBankCode(this.form).then((res)=>{
                this.imeiBankCodeList = res
            })
        }
    }
}
</script>

