<template>
  <div class="materialsList fadeInRight app-container">
    <div class="title-box">
      <el-form
        ref="queryParams"
        :model="queryParams"
        inline
        class="hook right themed-form search-form"
        :rules="rules"
      >
        <el-form-item label="" v-if="currentRole == 0">
          <treeselect
            v-model="queryParams.gyAgentId"
            :options="agentOptions"
            :normalizer="normalizer"
            :show-count="true"
            placeholder="请选择代理商"
          />
        </el-form-item>

        <el-form-item prop="gyAgentId" v-else>
          <el-select v-model="queryParams.gyAgentId" placeholder="请选择代理商">
            <el-option
              v-for="item in Nooptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="IMEI:">
          <el-input
            placeholder="请输入IMEI"
            v-model="queryParams.imei"
            class="iccid-width"
          ></el-input>
        </el-form-item>

        <el-form-item label="iccid/短号:">
          <el-input
            placeholder="请输入iccid/短号"
            v-model="queryParams.iccid_shortNo"
            class="iccid-width"
          ></el-input>
        </el-form-item>

        <el-form-item label="SN:">
          <el-input
            placeholder="请输入SN"
            v-model="queryParams.sn"
            class="iccid-width"
          ></el-input>
        </el-form-item>

        <el-form-item label="在线:">
          <el-select v-model="queryParams.status" placeholder="全部">
            <el-option label="离线" value="0"></el-option>
            <el-option label="在线" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="软件版本:">
          <el-input
            placeholder="请输入软件版本"
            v-model="queryParams.softwareVersion"
            class="iccid-width"
          ></el-input>
        </el-form-item>

        <el-form-item label="模型/型号:">
          <el-input
            placeholder="请输入模型/型号"
            v-model="queryParams.model"
            class="iccid-width"
          ></el-input>
        </el-form-item>

        <el-form-item label="最后连接时间:">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="queryParams.offline_time_start"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>至</el-form-item>
        <el-form-item>
          <el-date-picker
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="queryParams.offline_time_end"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="themed-button"
            @click="searchData"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="gyAgentName" label="代理商" min-width="180">
        </el-table-column>
        <el-table-column prop="imei" label="设备IMEI" min-width="180">
          <template slot-scope="scope">
            <el-link type="primary" @click="handleCheck(scope.row)">{{
              scope.row.imei
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="virtualIccid" label="虚拟ICCID" min-width="250">
          <template slot-scope="scope">
            <div>{{ scope.row.virtualIccid }}</div>
            <ul
              v-for="(item, index) in scope.row.simList"
              :key="index"
              style="padding-left: 10px"
            >
              <li>{{ item.simIccid }}</li>
            </ul>
          </template>
        </el-table-column>
         <!-- <el-table-column
          prop="sn"
          label="SN"
          min-width="200"
        >
        </el-table-column> -->
        <el-table-column prop="gyUsedFlow" label="网卡流量" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.gyUsedFlow }}</span>
            <span>{{
              scope.row.gyUsedFlow == "" || scope.row.gyUsedFlow == null
                ? ""
                : "MB"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="currentSim.simIccid"
          label="当前ICCID"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="currentSim.simName"
          label="SIM类型"
          min-width="120"
        >
        </el-table-column>
         <el-table-column
          prop="currentSim.sim345g"
          label="网络状态"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="客户手机号"
          min-width="120"
        >
        </el-table-column>
        <el-table-column prop="status" label="设备状态" min-width="120">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == '1' ? 'success' : 'danger'">{{
              scope.row.status === "1" ? "在线" : "离线"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="shortNo" label="短号/充值号" min-width="100">
          <template slot-scope="scope">
            <span>{{
              scope.row.shortNo == "" || scope.row.shortNo == null
                ? scope.row.virtualIccid
                : scope.row.shortNo
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="stopIccid"
          label="禁用卡号"
          min-width="100"
          v-if="tableData.stopType == '1'"
          key="stopIccid"
        >
        </el-table-column>
        <el-table-column prop="limitSpeed" label="限速" min-width="100">
          <template slot-scope="scope">
            <span>{{
              scope.row.limitSpeed == "0" ? "无限速" : scope.row.limitSpeed
            }}</span>
            <span v-if="scope.row.limitSpeed != '0'">kbps</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dailyUsage"
          label="日用量"
          min-width="100"
          :formatter="sizeTostr"
        >
        </el-table-column>
        <el-table-column
          prop="recent_30d_flow"
          label="近30天流量"
          min-width="100"
        >
          <template slot-scope="scope">
            <span>{{scope.row.recent_30d_flow}} MB</span>
          </template>
        </el-table-column>
        <el-table-column prop="sTime" label="最后连接时间" min-width="120">
        </el-table-column>
        <el-table-column prop="rsrp" label="RSRP" min-width="100">
        </el-table-column>
        <el-table-column prop="rsrq" label="RSRQ" min-width="100">
        </el-table-column>
        <el-table-column prop="rssi" label="RSSI" min-width="100">
        </el-table-column>
        <el-table-column
          prop="softwareVersion"
          label="设备软件版本"
          min-width="180"
        >
        </el-table-column>
        <el-table-column prop="type" label="设备类型" min-width="120">
        </el-table-column>
        <el-table-column prop="model" label="设备模型" min-width="120">
        </el-table-column>
        <el-table-column prop="stopType " label="网卡状态" min-width="100">
          <template slot-scope="scope">
            <span>{{
              scope.row.stopType == null || scope.row.stopType == ""
                ? "正常"
                : scope.row.stopType === "0"
                ? "部分禁网"
                : "全部禁网"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="stopDesc "
          label="禁用说明"
          min-width="100"
          v-if="tableData.stopType != '0'"
          key="stopDesc"
        >
        </el-table-column>
        <!-- <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="details(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import { agentGetAllAgent } from "@/api/system/user";
import { getAgentType } from "@/api/agentManagement.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapGetters } from "vuex";
import Utils from "@/utils/utils.js";
import {
  deviceList,
  addDevice,
  deviceExport,
  importBatchStopCardByExcel,
  delDevice,
} from "@/api/devices/devices.js";
export default {
  name: "materialsList",
  mixins: [resize],
  components: { Treeselect },
  //mixins: [resize],
  data() {
    return {
      formInline: {
        spec: "",
        material: "",
      },
      tableData: [],
      materialList: [],
      specList: [],
      rules: [],
      agentOptions: [],
      height: 0,
      isFixed: false,
      offsetTop: 0,
      Nooptions: [
        {
          value: "",
          label: "",
        },
      ],
      queryParams: {
        iccid_shortNo: "",
        gyAgentId:undefined,
        status: "",
        softwareVersion: "",
        model: "",
        offline_time_start: "",
        offline_time_end: "",
        imei:"",
        sn:"",
        // params: {
        //   offline_time_start: "",
        //   offline_time_end: "",
        //   imei:"",
        //   sn:"",
        // },
      },
      rules: {},
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
      dialogTitle: "新增分配",
    };
  },
  computed: {
    ...mapGetters(["currentRole", "agentTree"]),
  },
  created() {
    this.autoHeight();
    this.getDeviceList();
    this.getAllAgent();
    this.getAgentType();
  },
  methods: {
    //将用量byte转换为mb kb等 dailyUsage
    sizeTostr(row) {
      var size = parseInt(row.dailyUsage);
      var data = "";
      if (size < 0.1 * 1024) {
        //如果小于0.1KB转化成B
        data = size.toFixed(2) + "B";
      } else if (size < 0.1 * 1024 * 1024) {
        //如果小于0.1MB转化成KB
        data = (size / 1024).toFixed(2) + "KB";
      } else if (size < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        //其他转化成GB
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      var sizestr = data + "";
      var len = sizestr.indexOf(".");
      var dec = sizestr.substr(len + 1, 2);
      if (dec == "00") {
        //当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
      }
      return sizestr;
    },
    initHeight() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //计算滚动条距离顶部的距离
      this.isFixed = scrollTop > this.offsetTop ? true : false; //当滚动条大于吸顶距离顶部的距离试,就加上css样式
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.height = innerHeight - 60; // 这里的X就是顶部栏的高度
      });
    },
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams,params);
      deviceList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },
    getAgentType() {
      getAgentType().then((res) => {
        this.Nooptions = [];
        this.Nooptions.push({
          label: res.agentThreeName,
          value: res.agentId,
        });
        console.log(this.Nooptions);
      });
    },
    /** 代理商数据 */
    getAllAgent() {
      agentGetAllAgent().then((response) => {
        this.agentOptions = response;
      });
    },
    /** 转换代理商数据结构 */
    normalizer(node) {
      if (
        node.children == null ||
        (node.children == "null" && node.children && !node.children.length)
      ) {
        delete node.children;
      }
      return {
        id: node.agentId,
        label: node.agentName,
        children: node.children,
      };
    },
    /** 搜索 */
    searchData() {
      this.page = 1;
      this.getDeviceList();
    },
    treeSelectChange(raw, instanceId) {
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        this.addForm.gyAgentId = raw.agentId;
        this.addForm.gyAgentName = raw.agentName;
      });
    },
    handleCheck(row) {
      this.$router.push({
        path: "/equipmentManagement/operationsManagement",
        query: { data: JSON.stringify(row) },
      });
    },
    /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 100px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
.title-box {
  border-bottom: none !important;
}
</style>