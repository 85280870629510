<template>
    <div class="history-change-price fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">改价记录</span>
            <el-form inline ref="changePriceForm" v-model="changePriceFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="分销商">
                    <search-select :optionsMetaAll="agentOperators" v-model="changePriceFormData.agentId"></search-select>
                </el-form-item>
                 <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="开始日期" v-model="changePriceFormData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="结束日期" v-model="changePriceFormData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>   
            </el-form>
        </div>

         <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="agentName"
                    label="代理商"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="mealName"
                    label="套餐名称"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="oldOriginalMoney"
                    label="成本价（旧）"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="originalMoney"
                    label="成本价（新）"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="oldMoney"
                    label="销售价（旧）"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="money"
                    label="销售价（新）"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="oldRebateRatio"
                    label="返现比（旧）"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="rebateRatio"
                    label="返现比（新）"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="updateUser"
                    label="修改人"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="updateTime"
                    label="修改时间"
                    min-width="150"
                    >
                </el-table-column>
                  
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import resize from '@/mixins/resize.js'
import { changePriceAgentList,changePriceList } from '@/api/history.js'
export default {
    name:'changePrice',
     components:{SearchSelect},
     mixins:[resize],
    data(){
        return {
            agentOperators:[{label:'全部',value:''}],
            changePriceFormData:{
                agentId:'',
                startTime:'',
                endTime:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getAgentOperators()
        this.getChangePriceList()
    },
    methods:{
        getAgentOperators(){
            changePriceAgentList({'importType':'2','status':'0'}).then(res=>{
                let listArr = [{label:'全部',value:''}]
                res.forEach(item=>{
                    listArr.push({label:item.name,value:item.id})
                })
                this.agentOperators = listArr 
            })
        },
        getChangePriceList(){
            let params={
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.changePriceFormData,params)
            changePriceList(this.changePriceFormData).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
            this.page = page
            this.getChangePriceList()
			
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getChangePriceList()
        },
        onSearch(){
            this.page = 1
            this.getChangePriceList()
        },
    }
}
</script>

<style>
    .history-change-price .el-form-item__label{
        vertical-align: top;
    }
</style>