import request from './../request.js'

// 列表渲染以及查询
export function deviceList(query) {
  console.log(query)
  return request({
    url: '/iot/imei/list.do?',
    method: 'get',
    params: query,
  })
}
// 下载文件模板  /dev-api/iot/device/importTemplate
  export function deviceExport(data){
    return request({
      url: '/iot/imei/importTemplate.do?',
      method: 'post',
      data: data,
      responseType: 'blob'
    })
  }

// 批量新增 /dev-api/iot/device/import
export function importBatchStopCardByExcel(params) {
    return request ({
        url:'iot/imei/import.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 新增分配
export function addDevice(data) {
    return request({
      url: '/iot/imei.do?',
      method: 'post',
      data: data,
       timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
      
    })
  }
  // 删除/dev-api/iot/device/{ids}
  export function delDevice(ids) {
    return request({
        url: "/iot/imei/"+ids+".do?",
        method: 'DELETE',
        data:ids,
    })
}


// 获取限速管理下拉框数据/dev-api/iot/rateLimit/allList
export function rateLimitAllList(query) {
  return request({
    url: '/iot/rateLimit/allList.do?',
    method: 'get',
    params: query,
  })
}



// 获取屏幕管理下拉框数据 /dev-api/iot/screen/allList
export function screenAllList(query) {
  return request({
    url: '/iot/screen/allList.do?',
    method: 'get',
    params: query,
  })
}

// 获取二维码管理下拉框数据/dev-api/iot/qrcode/allList
export function qrcodeAllList(query) {
  return request({
    url: '/iot/qrcode/allList.do?',
    method: 'get',
    params: query,
  })
}
// 新增配置/dev-api/iot/imei/batchConfig
export function batchConfig(data) {
  return request({
    url: '/iot/imei/batchConfig.do?',
    method: 'post',
    data: data,
    timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
    
  })
}

// 单个移除/dev-api/iot/imei/switchAgent
export function switchAgent(data) {
  return request({
    url: '/iot/imei/switchAgent.do?',
    method: 'post',
    data: data,
    timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}
// 批量移除/dev-api/iot/imei/batchSwitchAgent
export function batchSwitchAgent(data) {
  return request({
    url: '/iot/imei/batchSwitchAgent.do?',
    method: 'post',
    data: data,
    timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}