<template>
    <div class="pin-order-rebate fadeInRight">
        <div class="title-box">
            <span class="m-title">拼单返现列表</span>
            <el-form inline v-model="form"  class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item label="开始时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择时间" v-model="form.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择时间" v-model="form.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item label='ICCID'>
					<el-input v-model="form.iccid" placeholder="输入iccid号" class="iccid-width"></el-input>
				</el-form-item>
                <el-form-item label='接入号'>
					<el-input v-model="form.accessNumber" placeholder="接入号"></el-input>
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">导出文件</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="content-box">
             <el-table
                    ref='table'
                    stripe
                    :max-height="maxTableHeight" 
                    :data="tableData"
                    border
                    style="width: 100%"
                    tooltip-effect="dark"
                    >
                    <el-table-column
                        prop="time"
                        label="时间"
                        min-width="120"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="nickname"
                        label="微信名"
                        min-width="120"
                        show-overflow-tooltip>
                    </el-table-column>
                     <el-table-column
                        prop="iccid"
                        label="iccid"
                        min-width="120"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="accessNumber"
                        label="接入号"
                        min-width="120"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="openid"
                        label="openid"
                        min-width="120"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="money"
                        label="金额"
                        min-width="80">
                    </el-table-column>
                    <el-table-column
                        label="状态"
                        min-width="100">
                        <template slot-scope="scope">
                        <span v-if="scope.row.status==1">未返现</span>
                        <span v-else-if="scope.row.status==2">已返现</span>
                        <span v-else-if="scope.row.status==3">返现异常</span>
                        <span v-else>未知状态</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="remark"
                        label="备注"
                        min-width="200">
                    </el-table-column>
                    
                    <el-table-column label="操作" min-width="120">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.status==3" size="mini" class="themed-button" type="primary" @click="handleRebate(scope.row.pinKey)">重新返现</el-button>
                        </template>
                    </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import { rebateAgain , pinRebateList,exportPinOrderRebate} from "@/api/order.js";
import resize from '@/mixins/resize.js'
export default {
    name:'pinOrderRebate',
    mixins:[resize],
    data(){
        return {
            form:{
                startTime:'',
                endTime:'',
                iccid:'',
                accessNumber:'',
            },
            page:1,
            pageSize:15,
            total:0,
            tableData:[],
        }
    },
    created(){
        this.getPinRebateList()
    },
    methods:{
        getPinRebateList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page,
            }
            Object.assign(params,this.form)
            pinRebateList(params).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })

        },
        searchTable(){
            this.page = 1
            this.getPinRebateList()
        },
        exportExcel(){
            exportPinOrderRebate(this.form).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        handleRebate(pinKey){
            this.$confirm('确定重新返现吗？请确认返现异常问题已处理', '消息提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					rebateAgain({pinKey}).then(() => {
						this.$message({
							type: 'success',
							message: '返现成功!'
						})
						this.getPinRebateList()
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
        },
        onPageChange(page){
				this.page = page
				this.getPinRebateList()
			},
		handleSizeChange(size){
				this.pageSize = size
				this.getPinRebateList()
			},
    }
}
</script>
<style>
    .pin-order-rebate .el-form-item__label{
        vertical-align: top;
    }
</style>