/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'
export function getUnbindList(params){
    return request({
        url: '/card/unbound/list.do?',
        method: 'post',
        data:params
    })
}

export function importUnbindSync(params){
    return request({
        url: '/card/unbound/import.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}