<template>
    <div class="card-batch fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">卡批次</span>
            <el-form inline ref="cardBatchForm" v-model="cardBatchFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="cardBatchFormData.batch" placeholder="批次"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-delete" type="danger" class="themed-button" @click="deleteByExcel">批量删除</el-button>
				</el-form-item>
            </el-form>
        </div>

         <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="iccidMark"
                    label="iccid"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="batch"
                    label="批次"
                    min-width="260"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="销售"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                       <span>{{scope.row.sale.saleName}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    label="代理商"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                       <span>{{scope.row.agent.name}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    label="销售时间"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.saleTime">{{formatTime(scope.row.saleTime)}}</span>
                    </template>
                </el-table-column>
   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="批量删除" :visible.sync="dialogDeleteByExcel" width="500px" :destroy-on-close='true'>
            <el-form label-position="right" label-width="120px" :model="deleteByExcelForm"  ref="deleteByExcelForm">
            <el-form-item label="Excel模板">
                <a href="javascript:volid(0);" @click="exportTemplateExcel('sale_batch_iccid.xls')">下载模板</a>
            </el-form-item>
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='batchDeletefile'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
			</el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="uploadDeleteExcel">批量删除</el-button>
                <el-button class="themed-button" type="primary" @click="dialogDeleteByExcel = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'
import {cardBatchList,deleteCardBatchByExcel} from "@/api/saleCardBatch.js"
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
export default {
    name:'cardBatch',
    mixins:[resize],
    data(){
        return {
            cardBatchFormData:{
                batch:''
            },
           
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogDeleteByExcel:false,
			deleteByExcelForm:{},
        }
    },
    created(){
        this.getCardBatchList()   
    },
    methods:{
        exportTemplateExcel(name){
            exportTemplate({'name':name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },
        deleteByExcel(){
			this.dialogDeleteByExcel = true
		},
		uploadDeleteExcel(){
				this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['batchDeletefile'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
							param.append("file",file)
                            deleteCardBatchByExcel(param).then(res=>{
                                  if (res.system_result_key == '0') {
                            this.$alert('删除成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
								this.dialogDeleteByExcel = false
                                this.$router.push('/tob/i_import_list')
                            	});
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
                })
        },
        
        getCardBatchList(){
            let params={
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.cardBatchFormData,params)
            cardBatchList(this.cardBatchFormData).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
        },
        onSearch(){
            this.page = 1
            this.getCardBatchList()
        },
        onPageChange(page){
            this.page = page
            this.getCardBatchList()
		},
		handleSizeChange(size){
            this.pageSize = size
            this.getCardBatchList()
        },
    }
}
</script>