<template>
  <div class="content-box">
    <el-table
      ref="table"
      :data="tableData"
      stripe
      style="width: 100%"
      :max-height="maxTableHeight"
    >
      <el-table-column label="推送地址" prop="" min-width="200">
        <template slot-scope="scope">
          <el-input
            v-model.trim="scope.row.pushUrl"
            :disabled="!!scope.row.accountId"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="客户名" prop="" min-width="120">
        <template slot-scope="scope">
          <el-input
            v-model.trim="scope.row.customerName"
            :disabled="!!scope.row.accountId"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="" min-width="120">
        <template slot-scope="scope">
          <el-input
            v-model.trim="scope.row.remark"
            :disabled="!!scope.row.accountId"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            plain
            size="mini"
            @click="deleteTokenAdd(scope.row)"
            v-if="!!scope.row.accountId"
            >删除</el-button
          >
          <el-button
            type="danger"
            plain
            size="mini"
            @click="saveTokenAdd(scope.row)"
            v-else
            >保存</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="btn">
      <el-button
        :disabled="!!(tableData.length >= 5)"
        icon="el-icon-plus"
        class="themed-button"
        type="primary"
        @click="addTokenAddress"
        >新增一行</el-button
      >
    </div>
  </div>
</template>
<script>
import { messageBox } from "@/utils/common.js";
import { getTokenAddList,addTokenAdd,delTokenAddess } from "@/api/tokenAddress.js";
import resize from "@/mixins/resize.js";
import Utils from "@/utils/utils.js";
export default {
  name: "tokenAddress",
  mixins: [resize],
  props: ["accountId"],
  data() {
    return {
      type: "",
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      dialogVisible: false,
      ruleForm: {},
      searchForm: {},
    };
  },
  created() {
    this.getTokenAddList();
  },
  methods: {
    saveTokenAdd(row) {
    if(!row.pushUrl){
       messageBox(this, "推送地址不能为空");
        return;
    }
    if(!row.customerName){
       messageBox(this, "客户名不能为空");
              return;
    }
    let params ={
      accountId:this.accountId
    }
    Object.assign(params,row)
    addTokenAdd(params).then(res=>{
      this.getTokenAddList()
    })
    },
    addTokenAddress() {
      this.tableData.push({
        pushUrl: "",
        customerName: "",
        remark: "",
      });
    },
    getTokenAddList() {
      getTokenAddList({ accountId: this.accountId }).then((res) => {
      
        this.tableData = res.rows;
      });
    },
    deleteTokenAdd(row) {
    delTokenAddess({id:row.id}).then(res=>{
      this.getTokenAddList()
       this.$message({
          message: '删除成功！',
          type: 'success'
        });
    })
    },
  },
};
</script>

<style>
.btn {
  text-align: right;
  margin-bottom: 10px;
}
</style>