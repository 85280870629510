import request from './request.js'
export function getAllCategory(params) {
    return request({
				url:"/statis/cardReport/getCategorylist.do?",
        method: 'post',
        data: params
    })
}

export function getCardReport(params) {
    return request({
				url:"/statis/cardReport/category.do?",
        method: 'post',
        data: params
    })
}

export function saleList(params) {
    return request({
				url:"/batch/sale/allList.do?",
        method: 'post',
        data: params
    })
}

export function getSaleCardReport(params) {
    return request({
				url:"statis/cardReport/sale.do?",
        method: 'post',
        data: params
    })
}

export function getAgentList(params) {
    return request({
				url:"/statis/cardReport/getAgentList.do?",
        method: 'post',
        data: params
    })
}

export function getAgentcardReport(params) {
    return request({
				url:"/statis/cardReport/agent.do?",
        method: 'post',
        data: params
    })
}

export function getDepartmentList(params) {
    return request({
				url:"/statis/cardReport/getDepartmentList.do?",
        method: 'post',
        data: params
    })
}

export function getAllcardReport(params) {
    return request({
				url:"/statis/cardReport/all.do?",
        method: 'post',
        data: params
    })
}

export function  operatorCardsExport(params){
	return request({
		url:"statis/cardReport/all/exportSync.do?",
		method:'post',
		data:params
	})
	
}
export function  allCardsExport(params){
	return request({
		url:"statis/cardReport/category/exportSync.do?",
		method:'post',
		data:params
	})
	
}

export function  agentCardsExport(params){
	return request({
		url:"statis/cardReport/agent/exportSync.do?",
		method:'post',
		data:params
	})
	
}

export function  saleCardsExport(params){
	return request({
		url:"statis/cardReport/sale/exportSync.do?",
		method:'post',
		data:params
	})
	
}














