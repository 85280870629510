<template>
  <div class="deliveryCompany fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">快递公司列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
       <el-form-item label="快递名称:">
            <el-input v-model="formInline.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="快递最短时间(天):" >
            <el-input
              v-model.number="formInline.deliveryTimeLeast"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="快递最长时间(天):">
            <el-input
              v-model.number="formInline.deliveryTimeMost"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search"  class="themed-button">搜索</el-button>
				</el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="add"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="快递名称" min-width="180">
        </el-table-column>
        <el-table-column
          prop="deliveryTimeLeast"
          label="送达最短时间"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          prop="deliveryTimeMost"
          label="送达最长时间"
          min-width="120"
        >
        </el-table-column>
        <el-table-column prop="fee" label="快递费用" min-width="120">
        </el-table-column>

        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
      <el-dialog :title="title" :visible.sync="dialogFormVisible">
        <el-form
          :model="addForm"
          :rules="rules"
          ref="addForm"
          :label-width="formLabelWidth"
        >
          <el-form-item label="快递名称:" prop="name">
            <el-input v-model="addForm.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="快递最短时间(天):" prop="deliveryTimeLeast">
            <el-input
              v-model.number="addForm.deliveryTimeLeast"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="快递最长时间(天):" prop="deliveryTimeMost">
            <el-input
              v-model.number="addForm.deliveryTimeMost"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="价格:" prop="fee">
            <el-input
              v-model="addForm.fee"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="sureAdd('addForm')"
            class="themed-button"
            >确 定</el-button
          >
          <el-button
            type="info"
            @click="dialogFormVisible = false"
            class="themed-button"
            >取 消</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import {
  expressList,
  addExpressList,
  deleteExpressList,
  editExpressList,
  getexpressPageList
} from "@/api/goodList.js";
export default {
  name: "deliveryCompany",
  mixins: [resize],
  data() {
    var deliveryTimeLeast = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("快递最短时间不能为空"));
      } else {
        if (!/^[1-9]\d*$/.test(value)) {
          callback(new Error("请输入大于0的正整数"));
        }
        callback();
      }
    };
    var deliveryTimeMost = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("快递最长时间不能为空"));
      } else {
        if (!/^[1-9]\d*$/.test(value)) {
          callback(new Error("请输入大于0的正整数"));
        }
        if (value < this.addForm.deliveryTimeLeast) {
          callback(new Error("快递最长时间不小于最短时间"));
        }
        callback();
      }
    };
    var validateFee = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入快递费用"));
      } else {
        if (!/^\d+(\.\d{0,1})?$/.test(value)) {
          callback(new Error("快递费用只能为数字最多保留1位小数"));
        }
         if (value>=100000000) {
          callback(new Error("快递费用不能大于等于100000000"));
        }
        callback();
      }
    };
    return {
      formLabelWidth: "130px",
      formInline: {
        name:"",
        deliveryTimeLeast:"",
        deliveryTimeMost:"",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      title: "",
      dialogFormVisible: false,
      addForm: {
        id: "",
        name: "",
        deliveryTimeLeast: "",
        deliveryTimeMost: "",
        fee: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "快递名称不能为空",
            trigger: "blur",
          },
        ],
        deliveryTimeLeast: [{ validator: deliveryTimeLeast, trigger: "blur" }],
        deliveryTimeMost: [{ validator: deliveryTimeMost, trigger: "blur" }],
        fee: [{ validator: validateFee, trigger: "blur" }],
      },
    };
  },
  created() {
    this.deliveryCompanyList();
    
  },
  methods: {
   
    searchData() {
      this.page = 1;
      this.deliveryCompanyList();
    },
    deliveryCompanyList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.formInline, params);
      getexpressPageList(this.formInline).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onPageChange(page) {
      this.page = page;
      this.deliveryCompanyList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.deliveryCompanyList();
    },
    add() {
      this.addForm.id = "";
      this.addForm.name = "";
      this.addForm.deliveryTimeLeast = "";
      this.addForm.deliveryTimeMost = "";
      this.addForm.fee = "";
      this.dialogFormVisible = true;
      this.title = "新增快递公司";
    },
    edit(row) {
      this.dialogFormVisible = true;
      this.title = "编辑快递公司";
      this.addForm.id = row.id;
      this.addForm.name = row.name;
      this.addForm.deliveryTimeLeast = row.deliveryTimeLeast;
      this.addForm.deliveryTimeMost = row.deliveryTimeMost;
      this.addForm.fee = row.fee;
    },

    sureAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addForm.id) {
            editExpressList(this.addForm).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.deliveryCompanyList();
              this.dialogFormVisible = false;
            });
          } else {
            let params = {
              name: this.addForm.name,
              deliveryTimeLeast: this.addForm.deliveryTimeLeast,
              deliveryTimeMost: this.addForm.deliveryTimeMost,
              fee: this.addForm.fee,
            };
            addExpressList(params).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.deliveryCompanyList();
              this.dialogFormVisible = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    delect(id) {
      this.$confirm("是否确认删除快递公司么?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteExpressList({ id }).then((res) => {
          this.deliveryCompanyList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
};
</script>

<style>

.deliveryCompany .el-form-item__label {vertical-align: top;}
</style>
