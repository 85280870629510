<template>
<div class="noneTheam">
<el-form :model="formData" :rules="rules" ref="dataForm">

  
    <el-form-item label="配置名:" prop="name" :label-width="formLabelWidth">
      <el-input v-model="formData.name" class="widthClass" autocomplete="off"></el-input>
      <el-button @click="clickData" class="">复制已有配置</el-button>
      <div v-if="configShow" class="configList">
        <ul>
          <li v-for="item in conOptions" :key="item.id" @click="nameChange(item.name)" >{{item.name}}</li>
        </ul>
      </div>
       <!-- <el-select  v-model="formData.name" filterable allow-create placeholder="请输入或下拉选择" default-first-option
      @blur="selectBlur($event)"  class="btnclass" @change="nameChange">
    <el-option
      v-for="item in conOptions"
      :key="item.id"
      :label="item.name"
      :value="item.name">
    </el-option>
  </el-select> -->
    </el-form-item>
    <el-form-item
      label="是否开启号码转换:"
      :label-width="formLabelWidth"
      v-if="currentRole == 0"
    >
      <el-radio v-model="jsonObject.ifSwitch" :label="true"
        >开</el-radio
      >
      <el-radio v-model="jsonObject.ifSwitch" :label="false"
        >关</el-radio
      > 
        <el-button @click="clickMapping" class="" v-if="jsonObject.ifSwitch">管理映射</el-button>
    </el-form-item>
     

    <el-form-item label="激活查询" class="boldForm"> </el-form-item>
    <el-form-item
      label="直接跳转模式:"
      :label-width="formLabelWidth"
    >
      <el-radio v-model="jsonObject.rechargePage.directJump" :label="true"
        >开</el-radio
      >
      <el-radio v-model="jsonObject.rechargePage.directJump" :label="false"
        >关</el-radio
      >
    </el-form-item>
    <div v-if="jsonObject.rechargePage.directJump">
      <el-form-item label="跳转地址:" :label-width="formLabelWidth">
        <el-input  v-model="jsonObject.rechargePage.directJumpUrl" :placeholder="holderText"></el-input>
        <div style="color:#f00">{{tip}}</div>
      </el-form-item>
    </div>
     <el-form-item
        label="顶部标题"
        label-width="130px"
      >
       <el-input v-model="jsonObject.rechargePage.topTitle" placeholder="请输入顶部标题"></el-input>
      </el-form-item>
    <div v-if="!jsonObject.rechargePage.directJump">
      <el-form-item
        label="是否隐藏复制号码:"
        label-width="130px"
      >
        <el-radio v-model="jsonObject.rechargePage.copyNoShow" :label="true"
          >是</el-radio
        >
        <el-radio v-model="jsonObject.rechargePage.copyNoShow" :label="false"
          >否</el-radio
        >
      </el-form-item>
      <div v-if="!jsonObject.rechargePage.copyNoShow">
      <el-form-item
        label="复制按钮文字"
        label-width="130px"
      >
       <el-input v-model="jsonObject.rechargePage.copyButtonText" placeholder="请输入复制按钮文字"></el-input>
      </el-form-item>
      </div>
       
      <el-table :data="jsonObject.rechargePage.steps" style="width: 100%" >
        
        <el-table-column  align="center"  show-overflow-tooltip>
             <template v-slot="scope">
              <span v-if="scope.row.type==1" style="float:left">{{scope.row.text}}</span>
             <el-image v-else-if = "scope.row.type==2"  style="width: 100px; height: 100px" :src="scope.row.url" ></el-image>
              <span v-else-if="scope.row.type==3">
                  <el-link :href="scope.row.url" target="_blank" type="primary">{{scope.row.text}}</el-link>
              </span>
             
                <!-- <el-button type="danger" icon="el-icon-delete" circle  @click="deleteRow(scope.row, scope.$index)" ></el-button> -->
               <i class="el-icon-close icomdel" @click="deleteRow(scope.row, scope.$index)"  ></i>
                <i class="el-icon-edit icomdelOne" @click="editRow(scope.row, scope.$index)"></i>
             </template>
        </el-table-column>
       
        <!-- <el-table-column label="">
          <template slot-scope="scope">
          
             <el-button  type="primary" icon="el-icon-delete" @click="deleteRow(scope.row, scope.$index)" ></el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-form-item>
        <el-button
          icon="el-icon-plus"
          class="themed-button"
          type="primary"
          @click="addRow"
          >新增一行</el-button
        >
      </el-form-item>
    </div>

    <el-form-item label="客服" class="boldForm"> </el-form-item> 
    <el-form-item
      label="直接跳转模式:"
      :label-width="formLabelWidth"
    >
      <el-radio v-model="jsonObject.kefuPage.directJump" :label="true"
        >开</el-radio
      >
      <el-radio v-model="jsonObject.kefuPage.directJump" :label="false"
        >关</el-radio
      >
    </el-form-item>
    <div v-if="jsonObject.kefuPage.directJump">
      <el-form-item label="跳转地址:" :label-width="formLabelWidth">
        <el-input  v-model="jsonObject.kefuPage.directJumpUrl" ></el-input>
        <div style="color:#f00">{{tip1}}</div> 
      </el-form-item>
    </div>
     <el-form-item
        label="顶部标题"
        label-width="130px"
      >
       <el-input v-model="jsonObject.kefuPage.topTitle" placeholder="请输入顶部标题"></el-input>
      </el-form-item>
    <div v-if="!jsonObject.kefuPage.directJump">
      <el-form-item
        label="是否隐藏复制号码:"
        label-width="130px"
      >
        <el-radio v-model="jsonObject.kefuPage.copyNoShow" :label="true"
          >是</el-radio
        >
        <el-radio v-model="jsonObject.kefuPage.copyNoShow" :label="false"
          >否</el-radio
        >
      </el-form-item>
      <div v-if="!jsonObject.kefuPage.copyNoShow">
      <el-form-item
        label="复制按钮文字"
        label-width="130px"
      >
       <el-input v-model="jsonObject.kefuPage.copyButtonText" placeholder="请输入复制按钮文字"></el-input>
      </el-form-item>
      </div>


    <!-- <el-form-item
        label="是否隐藏复制号码:"
        label-width="130px"
      >
        <el-radio v-model="jsonObject.kefuPage.copyNoShow" :label="true"
          >是</el-radio
        >
        <el-radio v-model="jsonObject.kefuPage.copyNoShow" :label="false"
          >否</el-radio
        >
      </el-form-item> -->
    <el-table :data="jsonObject.kefuPage.steps" style="width: 100%" >
     
    <el-table-column  align="center"  show-overflow-tooltip>
             <template v-slot="scope">
              <span v-if="scope.row.type==1"  style="float:left">{{scope.row.text}}</span>
             
             <el-image v-else-if = "scope.row.type==2"  style="width: 100px; height: 100px" :src="scope.row.url" ></el-image>
             
              <span v-else-if="scope.row.type==3">
                  <el-link :href="scope.row.url" target="_blank" type="primary">{{scope.row.text}}</el-link>
              </span>
              <!-- <el-button type="danger" icon="el-icon-delete" circle  @click="deleteRowPic(scope.row, scope.$index)" ></el-button> -->
              <i class="el-icon-close icomdel" @click="deleteRowPic(scope.row, scope.$index)"  ></i>
               <i class="el-icon-edit icomdelOne" @click="editRowPic(scope.row, scope.$index)"></i>
             </template>
        </el-table-column>
     
      <!-- <el-table-column label="">
        <template slot-scope="scope">
            <el-button  type="primary" icon="el-icon-delete" @click="deleteRowPic(scope.row, scope.$index)" ></el-button>
          
        </template>
      </el-table-column> -->
    </el-table>
    <el-form-item>
      <el-button
        icon="el-icon-plus"
        class="themed-button"
        type="primary"
        @click="addRowPic"
        >新增一行</el-button
      >
    </el-form-item>
    </div>
  </el-form>
   <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible"  v-if="dialogFormVisible" append-to-body>
        <step @close="dialogFormVisible=false"  :rowData="curData" :index="curindex" @addEvent="addSubmit"></step>
       
      </el-dialog>
</div>
  
  
</template>

<script>
import { mapGetters } from "vuex";
 import step from "./step.vue"
import { uploadPayOfflineUrl } from "@/api/agentManagement.js";
import {
 getCommonConfigList,
} from "@/api/configurationManagement.js";
export default {
  name: "flexibleCode",
  props: ["initData"],
  watch: {
    jsonObject: {
      handler(newVal, oldVal) {
        console.log(newVal,171)
        this.formData.jsonStr = JSON.stringify(newVal);
      },
      deep: true,
    },
    initData: function (newVal, oldVal) {
        console.log(newVal,177)
      this.formData = newVal;
      if(newVal.jsonStr == undefined ||
        newVal.jsonStr == "" ||
        newVal.jsonStr == "{}"){
        this.jsonObject = {
          rechargePage: {
            directJump: false,
            directJumpUrl:"",
            copyNoShow: false,
            steps: [],
            copyButtonText:"",
            topTitle:"",
          },
          kefuPage: {
             directJump: false,
            directJumpUrl:"",
            copyNoShow: false,
            steps: [],
            copyButtonText:"",
            topTitle:"",
          },
         ifSwitch:false
        }
      }else{
         this.jsonObject = JSON.parse(newVal.jsonStr);
         console.log(this.jsonObject)
      }
    //   this.jsonObject = JSON.parse(newVal.jsonStr);
    },
  },
  data() {
    return {
      formData: this.initData,
      jsonObject: this.initDataMethod(this.initData),
      formLabelWidth: "130px",
      rules: {
        name: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
      },
      dialogFormVisible:false,
      dialogTitle:"",
      curData:{},
      curindex:"",
      conOptions:[],
      testName:"",
      holderText:"https://iot.sunshineiot.net/traffic-weixin/wechatUser/recharge.do?id={{no}}",
      tip:"填写一机一码链接，若需带号码请用{{no}}表示，为空则默认为流量系统一机一码",
      tip1:"填写客服地址，若需带号码请用{{no}}表示",
      configShow:false,
    };
  },
  components:{
            step,
        },
   created(){
         this.getCommonList()
        },
  methods: {
    clickMapping(){
      this.$emit("flexCode")
    //  this.$router.push({
		// 				path:'/mappingManage/mappingList'
		// 			})
    },
    clickData(){
      if(!this.configShow){
      this.configShow = true
      }else{
        this.configShow = false
      }
    
    },
     getCommonList() {
      let params = {
        pageSize: 10000,
        type: "1",
      };

        getCommonConfigList(params).then((res) => {
          this.conOptions = res.rows
          console.log(this.conOptions)
      });
    },
    selectBlur(e){
      console.log(e.target.value)
      if(e.target.value){
       this.formData.name = e.target.value
        this.conOptions.forEach(item=>{
        if(item.name==e.target.value){
          console.log("已有配置项")
           this.jsonObject =   JSON.parse(item.jsonStr)
              
        }
      })
      // let arr =  this.conOptions.filter(item=>{return item.name==e.target.value})
      // if(arr.length==0){
      //    this.jsonObject = {
      //     rechargePage: {
      //       directJump: false,
      //       directJumpUrl:"",
      //       copyNoShow: false,
      //       steps: [],
      //     },
      //     kefuPage: {
      //       steps: [],
      //     },
      //   }
      // }
      }
      
    },
    nameChange(val){
      
      this.conOptions.forEach(item=>{
        if(item.name==val){
          console.log("aaaa")
           this.jsonObject =   JSON.parse(item.jsonStr)
              
        }
      })
      this.configShow = false
    },
    // getName(name){
    //   console.log(name)
    //   this.formData.name = name
    // },
    addSubmit(data,index){
      console.log(data,index,this.curindex)
      if(this.dialogTitle=="编辑激活充值步骤"){
         for(var i=0;i<this.jsonObject.rechargePage.steps.length;i++){
          this.jsonObject.rechargePage.steps.splice(this.curindex, 1, data);
              //  this.jsonObject.rechargePage.steps[this.curindex]= data
               console.log(this.jsonObject.rechargePage.steps)
         }
      }
      if(this.dialogTitle=="编辑客服步骤"){
         for(var i=0;i<this.jsonObject.kefuPage.steps.length;i++){
            this.jsonObject.kefuPage.steps.splice(this.curindex, 1, data);
              //  this.jsonObject.kefuPage.steps[this.curindex] = data
              console.log(this.jsonObject.kefuPage.steps)
         }
      }
      if(this.dialogTitle=="增加客服步骤"){
       this.jsonObject.kefuPage.steps.push(
              data
            )
      }
      if(this.dialogTitle=="增加激活充值步骤"){
            this.jsonObject.rechargePage.steps.push(
              data
            )
      }
    },
    editRow(row,index){
     this.dialogFormVisible = true
     this.dialogTitle = "编辑激活充值步骤"
     console.log(row,index)
     this.curData = row 
     this.curindex = index  
    },
    editRowPic(row,index){
     this.dialogFormVisible = true
     this.dialogTitle = "编辑客服步骤"
     console.log(row,index)
     this.curData = row 
     this.curindex = index  
    },
    addRow(){
    //   this.jsonObject.rechargePage.steps.push(
    //             {
    //             "type": 1,
    //             "text": "1.点击复制按钮复制号码"
    //         },
    //         )
    this.dialogFormVisible = true
    this.dialogTitle = "增加激活充值步骤"
    },
    addRowPic(){
     this.dialogFormVisible = true
     this.dialogTitle = "增加客服步骤"
    },
    deleteRowPic(data,index){
     this.jsonObject.kefuPage.steps.splice(index,1)
    },
    deleteRow(data,index){
     this.jsonObject.rechargePage.steps.splice(index,1)
    },
    initDataMethod(data) {
      console.log(data,363);
      if (
        data.jsonStr == undefined ||
        data.jsonStr == "" ||
        data.jsonStr == "{}"
      ) {
        console.log("1");
        return {
          rechargePage: {
            directJump: false,
            directJumpUrl:"",
            copyNoShow: false,
            steps: [],
            topTitle:"",
            copyButtonText:"",
          },
          kefuPage: {
             directJump: false,
            directJumpUrl:"",
            copyNoShow: false,
            steps: [],
            copyButtonText:"",
            topTitle:"",
          },
          ifSwitch:false,
        };
      } else {
        console.log("2");
        return JSON.parse(data.jsonStr);
      }
    },
    fileChange(val) {
      if (this.$refs["fileImportInput" + val].files[0]) {
        let fileName = this.$refs["fileImportInput" + val].files[0].name;
        let index = fileName.lastIndexOf(".");
        if (
          index < 0 ||
          !(
            fileName.substring(index + 1, fileName.length) == "png" ||
            fileName.substring(index + 1, fileName.length) == "jpg" ||
            fileName.substring(index + 1, fileName.length) == "jpeg" ||
            fileName.substring(index + 1, fileName.length) == "svg"
          )
        ) {
          this.$alert("文件格式错误", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        let param = new FormData();
        param.append("file", this.$refs["fileImportInput" + val].files[0]);
        uploadPayOfflineUrl(param).then((res) => {
          this.jsonObject["pic" + val] = res.url;
          console.log(this.jsonObject);
        });
      }
    },
  },
  computed: {
    ...mapGetters(["currentRole"]),
  },
};
</script>

<style scoped>
.configList ul{border: 1px solid #eee;margin-top: 10px;}
.configList ul li{list-style-type:none;line-height: 20px;padding: 5px 10px;cursor: pointer;}
.configList ul li:hover{background: #eee;}
.boldForm {
  font-weight: bold;
}
.noneTheam /deep/ .el-table__header-wrapper{display:none;}
.icomdel{color: #f00;float: right;}
.icomdelOne{color: #00f;float: right;margin-right: 6px;}
.btnclass{position: absolute;}
.widthClass{width: 230px;margin-right: 10px;}
</style>