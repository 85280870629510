<template>
	<div class="fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">短信列表</span>
			<el-form inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
				<el-form-item>
					<el-input v-model="searchForm.taskName"  placeholder="任务名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="searchForm.batchNumber" placeholder="批量序号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="searchForm.iccid" placeholder="ICCID" class="iccid-width"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="searchForm.createBy" placeholder="操作人"></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchForm.type" placeholder="类型">
						<el-option label="全部" value=""></el-option>
						<el-option label="上行" value="0"></el-option>
						<el-option label="下行" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-refresh-left" class="themed-button" type="info" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table ref='table'  :data="tableData" stripe style="width: 100%;" :max-height="maxTableHeight">
				<el-table-column label="批量号">
					<template slot-scope="scope">
						<span>{{scope.row.batchSms.batchNumber}}</span>
					</template>
					
				</el-table-column>
				<el-table-column prop="taskName" label="任务名称">
				</el-table-column>
				<el-table-column prop="iccid" label="iccid" width="180">
				</el-table-column>
				<el-table-column prop="phone" label="接入号" width="140">
				</el-table-column>
				<el-table-column prop="type" label="类型">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 0">上行</span>
						<span v-else-if="scope.row.type == 1">下行</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column prop="content" label="短信内容">
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<!-- 上行短信显示成功 -->
						<span v-if="scope.row.type == 0">成功</span>
						<!-- 下行短信根据装填判断 -->
						<span v-else-if="scope.row.status == 0">待发送</span>
						<span v-else-if="scope.row.status == 1">发送中</span>
						<span v-else-if="scope.row.status == 2">成功</span>
						<span v-else-if="scope.row.status == 3">失败</span>
						<span v-else-if="scope.row.status == 4">错误</span>
					</template>
				</el-table-column>
				<el-table-column prop="createBy" label="操作者">
				</el-table-column>
				<el-table-column  prop="createTime" label="更新时间" width="160">
				</el-table-column>
				<el-table-column  label="详情">
					<template slot-scope="scope">
						<a title="点击查看详情" href='javascript:;' @click="getDetail(scope.row)" style="color: #337ab7;">详情</a>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
		</div>
		
		<el-dialog title="短信详情" :visible.sync="dialogVisible" width="600px">
			<el-form label-width="120px" :model="ruleForm"  ref="ruleForm">
				<el-form-item label="批量号" prop="batchNumber">
					<el-input style="width: 400px;" v-model="ruleForm.batchNumber" disabled></el-input>
				</el-form-item>
				<el-form-item label="任务名称" prop="taskName">
					<el-input  style="width: 400px;" v-model="ruleForm.taskName" disabled></el-input>
				</el-form-item>
				<el-form-item label="接入号(phone)" prop="phone">
					<el-input  style="width: 400px;" v-model="ruleForm.phone" disabled></el-input>
				</el-form-item>
				<el-form-item label="类型" prop="type">
					<el-input  style="width: 400px;" v-model="ruleForm.type" disabled></el-input>
				</el-form-item>
				<el-form-item label="短信内容" prop="content">
					<el-input  style="width: 400px;" rows='3' type='textarea' v-model="ruleForm.content" disabled></el-input>
				</el-form-item>
				<el-form-item label="状态" prop="status">
					<el-input  style="width: 400px;" v-model="ruleForm.status" disabled></el-input>
				</el-form-item>
				<el-form-item label="描述" prop="reason">
					<el-input  style="width: 400px;" v-model="ruleForm.reason" disabled></el-input>
				</el-form-item>
				<el-form-item label="操作者" prop="createBy">
					<el-input  style="width: 400px;" v-model="ruleForm.createBy" disabled></el-input>
				</el-form-item>
				
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="dialogVisible = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {smslist} from '@/api/sms.js'
	import resize from '@/mixins/resize.js'
	import Utils from '@/utils/utils.js'
	export default{
		name:'sms_list',
		mixins:[resize],
		data(){
			return {
				type:'',
				page:1,
				pageSize:15,
				total:0,
				tableData:[],
				dialogVisible:false,
				ruleForm:{},
				searchForm:{}
			}
		},
		created() {
			if (!!this.$route.query.batchNumber) {
				this.searchForm.batchNumber = this.$route.query.batchNumber
			}
			this.getSmsList()
		},
		methods:{
			getDetail(rowData){
				rowData.batchNumber = rowData.batchSms.batchNumber
				if (rowData.type == 0) {
					rowData.type = '上行'
					rowData.status = '成功'
				}else if (rowData.type == 1) {
					rowData.type = '下行'
					if (rowData.status == 0) {
						rowData.status = '待发送'
					}else if (rowData.status == 1) {
						rowData.status = '发送中'
					}else if (rowData.status == 2) {
						rowData.status = '成功'
					}else if (rowData.status == 3) {
						rowData.status = '失败'
					}else if (rowData.status == 4) {
						rowData.status = '错误'
					}
				}
				this.ruleForm = rowData
				this.dialogVisible = true
			},
			onSearch(){
				this.page = 1
				this.getSmsList()
			},
			reset(){
				this.searchForm = {}
			},
			getSmsList(){
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
				}
				Object.assign(this.searchForm,params)
				smslist(this.searchForm).then(res=>{
					
					this.tableData = res.rows.map(item=>{
						item.createTime = Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(item.createTime))
						return item
					})
					this.total = res.count
				})
			},
			onPageChange(page){
				this.page = page
				this.getSmsList()
			},
			handleSizeChange(size){
				this.pageSize = size
				this.getSmsList()
			}
		}
	}
</script>

<style>
</style>
