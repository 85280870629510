<template>
<div class="smartStop table-hook fadeInRight" v-loading="loading">
    <div class="title-box">
        <span class="m-title">智能停机</span>
        <el-form inline class="hook right themed-form search-form" style="height: 40px;line-height: 40px;" :model="form">
            <el-form-item label="时间:">
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.startTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>至</el-form-item>
            <el-form-item>
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="refundRecord">批量停机</el-button>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="exportExcel">全部导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="iccid" prop="iccidMark" width="180px"></el-table-column>
            <el-table-column label="接入号" prop="phone"></el-table-column>
            <el-table-column label="imei" prop="imei" show-overflow-tooltip></el-table-column>
            <el-table-column label="当前套餐" prop="mealName"></el-table-column>
            <el-table-column label="充值时间" prop="createTime"></el-table-column>
            <el-table-column label="最后套餐过期时间" prop="maxExpireDate"></el-table-column>
            <el-table-column label="代理商" prop="agentName"></el-table-column>
            <el-table-column label="运营商" prop="supplier"></el-table-column>
            <el-table-column label="操作">
                <template  slot-scope="scope">
                    <el-button type="danger" class="themed-button" @click="stopCard(scope.row.iccidMark)">停机</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog title="批量停机" :visible.sync="dialogBox">
        <el-form label-position="right" label-width="120px">
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">全部停机</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('importIccid.xls')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {smartStopList,batchStop,stop,exportCardbusines} from '@/api/cardManagement/smartStop.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import resize from '@/mixins/resize.js'

export default {
    name:'cardBusines',
    mixins:[resize],
    data(){
        return {
            loading:false,
            form:{
                startTime:'',
                endTime:''
            },
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false
        }
    },
    mounted(){
        let startTime = new Date().setDate(15)
        let endTime = new Date().setDate(30)
        this.form.startTime = this.toDates(startTime)
        this.form.endTime = this.toDates(endTime)
        this._smartStopList()
    },
    methods:{
        toDates (times) {
            const date = new Date(times)
            const Y = date.getFullYear()
            const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
            const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
            // const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
            // const Min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
            // const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
            const dateTime = Y + '-' + M + '-' + D 
            return dateTime
        },
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },
        stopCard(iccidMark){
            confirmBox(this,'确定停机？','操作提示',()=>{
                stop({iccidMark}).then((res)=>{
                    this._smartStopList()
                })
            })
        },
        searchTable(){
            this._smartStopList()
        },
        refundRecord(){
            this.dialogBox = true
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    if(this.packageIds === ''){
                        messageBox(this,'请选择套餐')
                        return
                    }
                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('startTime',this.form.startTime)
                    params.append('endTime',this.form.endTime)
                    batchStop(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        exportExcel(){
            exportCardbusines(this.form).then(res=>{
            if (res.system_result_key == '0') {
                this.$alert('导出成功，请到导出记录中下载', '提示', {
                    confirmButtonText:'确定',
                }).then(()=>{
                    this.$router.push('/tob/i_export_list')
                });
            }else{
                this.$message({
                    showClose: true,
                    message: res.system_result_message_key,
                    type: 'error'
                });
            }
            })
        },
         handleSizeChange(val){
            this.pageSize = val
            this._smartStopList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._smartStopList()
        },
        _smartStopList(){
            this.loading = true
            let params = Object.assign(this.form,{pageNo:this.pageNo,pageSize:this.pageSize})
            smartStopList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
                this.loading = false
            })
        }
       
    },
    components:{
        SearchSelect
    }
}

</script>

