<template>
    <div class="singleSpeedLimitList fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">一次性限速列表</span>
            <el-form inline ref="searchForm" v-model="searchForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="searchForm.iccid" placeholder="iccid" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="searchForm.shortIccid" placeholder="短号"></el-input>
                </el-form-item>

                <el-form-item label="运营商">
                    <el-select v-model="searchForm.accountId" filterable placeholder="运营商">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item>
                    <el-input v-model="searchForm.batchNo" placeholder="批次号" class="iccid-width"></el-input>
                </el-form-item>
  
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>

                <el-form-item>
						<el-button icon="el-icon-plus" type="info" class="themed-button" @click="handleAdd">新增</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="卡号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="130"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="agentName"
                    label="代理商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="限速名"
                    min-width="120"
                    prop="speedName"
                    >
                </el-table-column>
                 <el-table-column
                    label="设备速率"
                    min-width="120"
                    prop="deviceSpeedValue"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="speedVal"
                    label="限速值"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="batchNo"
                    label="批次号"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="创建时间"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="creator"
                    label="创建人"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="statusStr"
                    label="状态"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        {{getStatus(scope.row.status)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="retryCount"
                    label="重试次数"
                    min-width="120"
                    >
                </el-table-column>
                  <el-table-column
                    prop="failReason"
                    label="失败原因"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="updateTime"
                    label="更新时间"
                    min-width="120"
                    >
                </el-table-column>
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog :title="addTitle" :visible.sync="dialogAddvisible" width="500" :destroy-on-close='true'>
            <el-form :model="addForm"  ref="addForm" label-width="150px" style="max-height:500px;overflow:auto" >
                <!-- <el-form-item label="类型:"  >
				<el-select v-model="addForm.type"   @change="typeChange">
                     <el-option label="全部" value=""></el-option>
				   <el-option label="传iccid" value="1"></el-option>
				   <el-option label="传文件" value="2"></el-option>
				 </el-select>
			</el-form-item> -->
            <el-form-item label="类型" >
					<el-radio-group v-model="addForm.type" @change="typeChange">
						<el-radio label="2">传文件</el-radio>
						<el-radio label="1">传iccid</el-radio>
					</el-radio-group>

				</el-form-item>
               <el-form-item label="上传文件（excel）:"       v-show="fileShow">
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                <div style="color:#f00;">单次限速暂不支持虚拟卡，导入后不会生效</div>
            </div>
        </el-form-item>
                <el-form-item label="运营商" prop="accountId"  >
                    <el-select v-model="addForm.accountId" filterable placeholder="运营商" @change="operatorChange">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="速率" prop="speedVal" >
                    <el-select v-model="addForm.speedVal" placeholder="请选择" filterable @change="getDeviceSpeed">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(item,index) in speedList" :key='index' :label="item.speedKey" :value="item.speedValue"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="批次" prop="batchNo">
                    <el-input v-model="addForm.batchNo" style="width:205px"></el-input>
                </el-form-item>
                  <el-form-item label="ICCID/短号:" v-show="iccidShow">
             <el-input v-model="addForm.iccids" type="textarea"  placeholder="请输入ICCID/短号,每行一个"></el-input>
                  </el-form-item>
                
            </el-form>
            <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel('addForm')">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
            </div>
        </el-dialog>

        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import {agentOperatorList} from '@/api/order.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import {getSpeedList} from '@/api/virtualCard/accountRateList.js'
import {singleSpeedList,importAddSyncSingleSpeed,exportSingleSpeedLimit} from "@/api/speedLimit/index.js";
export default {
     mixins:[resize],
    data(){
        return {
            accountIdList:[],
            searchForm:{
                iccidMark:'',
                accountId:'',
                batchNo:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            addTitle:'',
            dialogAddvisible:false,
            addForm:{
                type:"2",
             accountId:"",
             speedVal:"",
             speedName:"",
             batchNo:"",
             iccids:"",
             deviceSpeedValue:""
            },
            speedList:[],
            fileShow:true,
            iccidShow:false,
        }
           
    },
    created(){
        this.getAgentOperatorList()
        this.getSingleSpeedLimitList()
    },
    methods: {
        getAgentOperatorList(){
            agentOperatorList().then(res=>{
                this.accountIdList = res        
             })
        },
        getStatus(status){
           let statusStr = ""
           if(status=="1"){
            statusStr  = "新建"
           }else if(status=="2"){
            statusStr  = "成功"
           }else if(status=="3"){
            statusStr  = "失败"
           }else if(status=="4"){
            statusStr  = "处理中"
           }
           return  statusStr
        },
      
        getSingleSpeedLimitList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.searchForm,params)
            singleSpeedList(this.searchForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },


        onPageChange(page){
			this.page = page
			this.getSingleSpeedLimitList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSingleSpeedLimitList()
        },

        onSearch(){
            this.page=1
            this.getSingleSpeedLimitList()
        },

        handleAdd(){ 
            this.dialogAddvisible = true
            this.resetAddForm()
            this.addTitle =  '新增'
            
        },

      
        
        resetAddForm(){
            this.addForm = {
            accountId:"",
             speedVal:"",
             batchNo:"",
             iccids:"",
             type:"2",
            speedName:"",
            deviceSpeedValue:"",
            }
            this.fileShow = true
            this.iccidShow = false
            // this.$refs['file'].value = ''
            this.speedList = []
        },
        operatorChange(accountId){
          this.speedList=[]
            getSpeedList({accountId}).then(res=>{
                let list= res.rows
                if(list.length>0){
                    list.forEach((item)=>{
                        this.speedList.push({speedKey:item.speedKey,speedValue:item.speedValue,deviveSpeedValue:item.deviceSpeedValue})
                    })
                }else{
                     messageBox(this,'该运营商在无速率列表请重新选择运营商')
                         return     
                }
            })
        },
        getDeviceSpeed(val){
        console.log(val,337)
        this.speedList.forEach(item=>{
            if(item.speedValue==val){
             this.addForm.deviceSpeedValue = item.deviveSpeedValue
            }
        })
        },
          
        typeChange(val){
         if(val=="1"){
            this.iccidShow = true
            this.fileShow = false
         }else if(val=="2"){
            this.iccidShow = false
            this.fileShow = true
         }else{
            this.iccidShow = false
            this.fileShow = true
         }
        },
         importExcel(){
            let name = 'importIccidOrShort.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
         this.dialogAddvisible = false
        },
         uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let param = new FormData()
            if(!!this.addForm.type){
             param.append("type",this.addForm.type)
            }else{
                  this.$alert('请选择上传类型', '提示', {
							 confirmButtonText:'确定',
                           });
                  return
            }
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                     if(!!this.addForm.accountId){
                        param.append("accountId",this.addForm.accountId)
                      }else{
                             this.$alert('请选择运营商', '提示', {
							 confirmButtonText:'确定',
                           });
                         return
                            }
                   if(!!this.addForm.speedVal){
                        param.append("speedVal",this.addForm.speedVal)
                     let arrNew =    this.speedList.filter(item=>item.speedValue==this.addForm.speedVal)
                     this.addForm.speedName = arrNew[0].speedKey
                     param.append("speedName",this.addForm.speedName)
                     param.append("deviceSpeedValue",this.addForm.deviceSpeedValue)
                      }else{
                             this.$alert('请选择速率', '提示', {
							 confirmButtonText:'确定',
                           });
                         return
                            }
                     param.append('fileurl',file)
                     param.append("batchNo",this.addForm.batchNo)
                    importAddSyncSingleSpeed(param).then(res=>{
                                if (res.system_result_key == '0') {
                                    this.$alert('导入成功，请到导入记录中下载', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.dialogAddvisible = false
                                        this.$router.push('/tob/i_import_list')
                                    });
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                }
                            })

                }
            }else if(!!this.addForm.iccids.trim()){
                console.log(2222222)
                console.log(Utils.checkICCID(this.addForm.iccids))
                 if(!Utils.checkICCID(this.addForm.iccids)){
              messageBox(this,'iccid只能包含数字和字母')
              return 
            }
                     if(!!this.addForm.accountId){
                        param.append("accountId",this.addForm.accountId)
                      }else{
                             this.$alert('请选择运营商', '提示', {
							 confirmButtonText:'确定',
                           });
                         return
                            }
                   if(!!this.addForm.speedVal){
                        param.append("speedVal",this.addForm.speedVal)
                        let arrNew =    this.speedList.filter(item=>item.speedValue==this.addForm.speedVal)
                        console.log(arrNew)
                     this.addForm.speedName = arrNew[0].speedKey
                     param.append("speedName",this.addForm.speedName)
                     param.append("deviceSpeedValue",this.addForm.deviceSpeedValue)
                     
                      }else{
                             this.$alert('请选择速率', '提示', {
							 confirmButtonText:'确定',
                           });
                         return
                            }
                     param.append('iccids',this.addForm.iccids)
                      param.append("batchNo",this.addForm.batchNo)
                   importAddSyncSingleSpeed(param).then(res=>{
                                if (res.system_result_key == '0') {
                                    if(!!res.system_result_message_key){
                                  this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                    this.dialogAddvisible = false
                                    this.getSingleSpeedLimitList()
                                    }else{
                                  this.$alert('添加成功', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.dialogAddvisible = false
                                        this.getSingleSpeedLimitList()
                                    });
                                    }
                                   
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                     this.dialogAddvisible = false
                                }
                            })
                        
            }else{
                if(this.addForm.type=="1"){
               messageBox(this,'请输入iccid')
                return     
                }else if(this.addForm.type=="2"){
                messageBox(this,'请选择文件')
                return
                }
            }
        },
      

        exportExcel(){
            exportSingleSpeedLimit(this.searchForm).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },

    },
}
</script>

<style>
    .singleSpeedLimitList .el-form-item__label{
        vertical-align: top;
    }
</style>