<template>
    <div class="recharge-without-renewal fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">不续费充值</span>
            <el-form inline ref="rechargeWithoutRenewalForm" v-model="rechargeWithoutRenewalData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
                    <el-input v-model="rechargeWithoutRenewalData.iccidMark" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="rechargeWithoutRenewalData.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="rechargeWithoutRenewalData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-delete" type="danger" class="themed-button" @click="deleteByExcel">批量删除</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID号"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="20%"
                    />
                <el-table-column
                    prop="phone"
                    label="接入号"
                    min-width="20%"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="开卡公司"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createDate"
                    label="导入时间"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    min-width="10%"
                    >  
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="10%">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
                
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="批量删除" :visible.sync="dialogDeleteVisible" width="500px" :destroy-on-close='true'>
            <el-form :model="dialogDeleteForm"  ref="dialogDeleteForm">
                <el-form-item label="请选择excel文件:" :label-width="formLabelWidth">
                    <input class="file-input" type="file" ref='fileDeleteInput'/>
                    <div class="mt5">
                            <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="uploadDeleteExcel">导入文件</el-button>
                <el-button class="themed-button" type="info" @click="exportTemplateExcel('importIccid.xls')">导出excel模板</el-button>
                <el-button class="themed-button" type="primary" @click="dialogDeleteVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import { rechargeWithoutRenewal, deleteRechargeWithoutRenewal,deleteRechargeWithoutRenewalByExcel } from "@/api/history.js";
export default {
    name:'rechargeWithoutRenewal',
    mixins:[resize],
    data(){
        return {
            rechargeWithoutRenewalData:{
                iccidMark:'',
                phone:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogDeleteVisible:false,
            formLabelWidth:'150px',
            dialogDeleteForm:{},

        }
    },
    created(){
        this.getRechargeWithoutRenewalList()
    },
    methods:{
        getRechargeWithoutRenewalList(){
            let params={
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.rechargeWithoutRenewalData,params)
            rechargeWithoutRenewal(this.rechargeWithoutRenewalData).then(res=>{
                this.tableData = res.rows.map(item=>{
                    item.createDate = Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(item.createDate))
                    return item
                })
                this.total = res.count
            })
        },
        onSearch(){
            this.page=1
            this.getRechargeWithoutRenewalList()
        },
        deleteByExcel(){
            this.dialogDeleteVisible = true
        },
        uploadDeleteExcel(){
            this.$confirm("确定要进行回收吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['fileDeleteInput'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            param.append("fileurl",file)
                            deleteRechargeWithoutRenewalByExcel(param).then(res=>{
                                 if (res.system_result_key == '0') {
                            //  this.$message({
							// 	type: 'success',
							// 	message:'导入批量删除成功'
                            // });
                            // this.getRealNameImportList()
							// this.count = 0
							// this.dialogDeleteForm = {}
                            // this.$refs['fileDeleteInput'].value = ''
                            // this.dialogDeleteVisible = false
                            this.$alert('导入批量删除成功，请到导入记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
                             this.getRechargeWithoutRenewalList()
							this.count = 0
							this.dialogDeleteForm = {}
                            this.$refs['fileDeleteInput'].value = ''
                            this.dialogDeleteVisible = false
							this.$router.push('/tob/i_import_list')
						});
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
                })
        },
        exportTemplateExcel(name){
            exportTemplate({'name':name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },
        handleDelete(id){
            this.$confirm("确定要进行回收吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let params = {
                        'id':id,
                        'pageSize':this.pageSize,
                        'pageNo':this.page
                    }
                    deleteRechargeWithoutRenewal(params).then(res=>{
                        this.$message({
								type: 'success',
								message:'删除成功'
                            });
                    })
                    this.getRechargeWithoutRenewalList()
                })
        },
        handleSizeChange(size){
            this.pageSize=size
            this.getRechargeWithoutRenewalList()
        },
        onPageChange(page){
            this.page=page
            this.getRechargeWithoutRenewalList()
        },

    }
}
</script>