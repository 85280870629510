<template>
    <div class="sunshine-real-name fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">{{parseInt(isGuangyao)?"光耀":""}}实名</span>
            <el-form inline ref="realNameForm" v-model="realNameFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="realNameFormData.iccid" placeholder="虚拟号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item label="运营商">
                    <el-select v-model="realNameFormData.accountId" filterable placeholder="运营商">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="realNameFormData.name" placeholder="姓名"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="realNameFormData.idCard" placeholder="身份证" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="realNameFormData.phone" placeholder="手机号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="虚拟号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="name"
                    label="姓名"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="idCard"
                    label="身份证"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="手机号"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column prop="img" label="身份证照片1" min-width="150" align="center">
                    <template slot-scope="scope">
                        <el-link :underline='false' type="primary" @click="showImg(scope.row.idCardBack)">查看</el-link>
                    </template>
                </el-table-column>

                <el-table-column label="身份证照片2" min-width="150" align="center">
                    <template slot-scope="scope">
                        <el-link :underline='false' type="primary" @click="showImg(scope.row.idCardFront)">查看</el-link>
                    </template>
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    label="实名状态"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == '1'">已实名</span>
                        <span v-else-if="scope.row.status == '2'">未实名</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                     prop="createTime"
                    label="实名时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                     prop="updateTime"
                    label="更新时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                     prop="updateBy"
                    label="更新人"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="200">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="info" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button  size="mini" class="themed-button" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
                
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>


        <el-dialog title="编辑" :visible.sync="dialogEditFormVisible" width="500" :destroy-on-close='true'>
            <el-form :model="editForm" :rules="rules" ref="editForm">
                <el-form-item label="虚拟号" :label-width="formLabelWidth" prop="iccid">
                <el-input v-model="editForm.iccid" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="运营商" :label-width="formLabelWidth" prop="accountName">
                     <el-input v-model="editForm.accountName" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
                <el-input v-model="editForm.name" autocomplete="off" ></el-input>
                </el-form-item>
                <el-form-item label="身份证" :label-width="formLabelWidth" prop="idCard">
                <el-input v-model="editForm.idCard" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
                <el-input v-model="editForm.phone" autocomplete="off"></el-input>
                </el-form-item>
                
				<el-form-item label="实名状态" :label-width="formLabelWidth" prop="status">
                <el-select v-model="editForm.status"  placeholder="状态">
                        <el-option  label="已实名" value="1"></el-option>
                        <el-option  label="未实名" value="2"></el-option>
                    </el-select>
                </el-form-item>
				<el-form-item label="实名时间" :label-width="formLabelWidth" prop="createTime">
                <el-date-picker style="width: 200px;" v-model="editForm.createTime" type="date" value-format="yyyy-MM-dd" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureRecord('editForm')">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogEditFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="图片查看" :visible.sync="dialogImgVisible" width="500" :destroy-on-close='true'>
            <div style='margin:auto;text-align:center'>
                <img :src="imgURL" style='width:250px'/>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="primary" @click="dialogImgVisible = false">取 消</el-button>
            </div>
        </el-dialog>



        <el-dialog
        title="详情"
        :visible.sync="dialogDetailVisible"
        :destroy-on-close='true'
        >
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">虚拟号:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.iccid}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">运营商:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.accountName}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">姓名:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.name}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">身份证:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.idCard}}</div></el-col>
        </el-row>
        
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">手机:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.phone}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">实名状态:</div></el-col>
        <el-col :span="12">
            <div v-if="detailInfo.status==1">已实名</div>
            <div v-else-if="detailInfo.status==2">未实名</div>
        </el-col>
        </el-row>
        
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">实名时间:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.createTime}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">更新时间:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.updateTime}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">更新人:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.updateBy}}</div></el-col>
        </el-row>
        
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button"  @click="dialogDetailVisible = false">关闭</el-button>
        </span>
        </el-dialog>
        
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {agentOperatorList} from '@/api/order.js'
import { realNameList ,exportRealName , deleteRealName ,editRealName} from "@/api/virtualCard.js"
export default {
    name:'sunshineRealname',
     mixins:[resize],
    data(){
        return {
            realNameFormData:{
                iccid:'',
                accountId:'',
                name:'',
                idCard:'',
                phone:'',
            },
            accountIdList:[],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogEditFormVisible:false,
            formLabelWidth:'150px',
            editForm:{
                id:'',
                iccid:'',
                accountName:'',
                name:'',
                phone:'',
                idCard:'',
                status:'',
                createTime:'',
            },
            dialogDetailVisible:false,
            detailInfo:{
                iccid:'',
                accountName:'',
                name:'',
                phone:'',
                idCard:'',
                status:'',
                createTime:'',
                updateTime:'',
                updateBy:'',
            },
            imgURL:'',
            dialogImgVisible:false,
            rules:{
                status:[
                    {required:true, message:'请选择状态', trigger: 'blur'}
                ],
            },
        //  showName:this.isGuangyao?"光耀":"",

        }
    },
    created(){
        this.getRealNameList()
        this.getAgentOperatorList()
    },
    methods: {
         getAgentOperatorList(){
            agentOperatorList().then(res=>{
                this.accountIdList = res        
             })
        },
        getRealNameList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.realNameFormData,params)
            realNameList(this.realNameFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getRealNameList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getRealNameList()
        },

        onSearch(){
            this.page=1
            this.getRealNameList()
        },

        showImg(URL){
            this.dialogImgVisible = true
            this.imgURL = URL
        },

        handleDetail(data){
            this.dialogDetailVisible = true
            this.detailInfo.iccid = data.iccid
            this.detailInfo.name = data.name
             this.detailInfo.idCard = data.idCard
            this.detailInfo.accountName = data.accountName
            this.detailInfo.phone = data.phone
            this.detailInfo.status = data.status.toString()
            this.detailInfo.createTime = data.createTime
            this.detailInfo.updateTime = data.updateTime
            this.detailInfo.updateBy = data.updateBy
        },


        exportExcel(){
            exportRealName(this.realNameFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },

        handleEdit(data){
            this.dialogEditFormVisible = true
            this.editForm.iccid = data.iccid
            this.editForm.id = data.id
            this.editForm.accountName = data.accountName
            this.editForm.name = data.name
            this.editForm.phone = data.phone
            this.editForm.idCard = data.idCard
            this.editForm.status = data.status.toString()
            this.editForm.createTime = data.createTime
        },
        sureRecord(formName){
            this.$confirm('确定提交修改吗？','消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    editRealName(this.editForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '修改成功',
					        type: 'success',
							center: true
                        })
                        this.$refs[formName].resetFields()
                        this.dialogEditFormVisible=false
                        this.getRealNameList()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            }).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
			});
        },

        handleDelete(id){
            this.$confirm('确定删除吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                deleteRealName({id}).then(res=>{
                    this.$message({
                    type: 'success',
                    message: '删除成功!'
                    });
                    this.getRealNameList()
                })
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消'
                });          
            });
        },
        formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
         },

        
    },
      computed: {
    ...mapGetters(['isGuangyao']),
  },
    
}
</script>

<style>
    .sunshine-real-name .el-form-item__label{
        vertical-align: top;
    }
    .sunshine-real-name .dialog-row-label{
        text-align: right;
    }
</style>