<template>
    <div class="day-single-card fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">卡套餐周期统计</span>
            <el-form inline ref="quaryForm" v-model="quaryForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="quaryForm.iccidMark" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item label="运营商名称">
                    <el-select filterable v-model="quaryForm.accountId">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(item,index) in accountIdList" :key="index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

               <el-form-item label="代理商名称" v-if="currentRole == 0">
                    <el-select filterable v-model="quaryForm.agentId">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(item,index) in agentIdList" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                 <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="起始日期" v-model="quaryForm.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="结束日期" v-model="quaryForm.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>

                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="有效起始日期" v-model="quaryForm.effecStartTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="有效截止日期" v-model="quaryForm.effecEndTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                 
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item> 
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>             
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="iccidMark"
                    label="ICCID号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号"
                    min-width="150"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="realFlowSize"
                    label="套餐阈值"
                    min-width="150"
                    v-if="currentRole == 0"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="cardCount"
                    label="订单数量"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="rebateRatio"
                    label="返利比"
                    min-width="120"
                    v-if="isCaptal!='1'"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="originalMoney"
                    label="结算价"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="rebateType"
                    label="返利方式"
                    min-width="120"
                    v-if="isCaptal!='1'"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.rebateType==1">销售价模式</span>
                        <span v-else-if="scope.row.rebateType==2">提现比模式</span>
                        <span v-else-if="scope.row.rebateType==0">未设置返利方式</span>
                    </template> 
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="mealName"
                    label="套餐名称"
                    min-width="150"
                    >
                </el-table-column>
                
                <el-table-column
                    show-overflow-tooltip
                    prop="startTime"
                    label="套餐开始时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="endTime"
                    label="套餐结束时间"
                    min-width="150"
                    >
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商名称"
                    min-width="120"
                    >
                </el-table-column>
                
                <el-table-column
                    show-overflow-tooltip
                    prop="agentTreeName"
                    label="代理商名称"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="agentName"
                    label="所属总代理商"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="useFlow"
                    label="周期内用量"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="usedVoice"
                    label="周期内语音"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="isExpired"
                    label="是否已到期"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="status"
                    label="状态"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">未生效</span>
                        <span v-else-if="scope.row.status==2">生效中</span>
                        <span v-else-if="scope.row.status==3">已失效</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="realExpireTime"
                    label="失效时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="orderMoney"
                    label="订单金额"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="orderNumber"
                    label="订单号"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="orderType"
                    label="订单类型"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.orderType==1">微信订单</span>
                        <span v-else-if="scope.row.orderType==2">代理商订单</span>
                        <span v-else-if="scope.row.orderType==3">后台订单</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="统计时间"
                    min-width="150"
                    >
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="quaryForm.pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="quaryForm.pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import {getDaySingleCardList,exportDaySingleCardList} from "@/api/operationalStatistics/operationalStatistics.js";
import {accountList,getAgentList} from "@/api/agentManagement.js"
import {mapGetters} from 'vuex'
export default {
    mixins:[resize],
    data(){
        return {
            quaryForm:{
                pageNo:1,
                pageSize:15,
                startTime:'',
                endTime:'',
                effecStartTime:'',
                effecEndTime:'',
                iccidMark:'',
                agentId:'',
                accountId:'',
            },
            tableData:[],
            total:0,
            agentIdList:[],
            accountIdList:[],
        }
    },
     computed:{
        	...mapGetters(
            	['currentRole','isCaptal']
        	),
    	},
    created(){
        this.getList()
        this.getAgentList()
        this.getAccountList()
    },
    methods: {
        getAgentList(){
            getAgentList().then(res=>{
				this.agentIdList = res
			})
        },
        getAccountList(){
            accountList().then(res=>{
                this.accountIdList = res
            })
        },

        getList(){
            getDaySingleCardList(this.quaryForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },

        onPageChange(page){
			this.quaryForm.pageNo = page
			this.getList()
		},
		handleSizeChange(size){
			this.quaryForm.pageSize = size
			this.getList()
        },

        onSearch(){
            this.quaryForm.pageNo=1
            this.getList()
        },

        exportExcel(){
            let params = {
                startTime:this.quaryForm.startTime,
                endTime:this.quaryForm.endTime,
                iccidMark:this.quaryForm.iccidMark,
                agentId:this.quaryForm.agentId,
                accountId:this.quaryForm.accountId,
                effecStartTime:this.quaryForm.effecStartTime,
                effecEndTime:this.quaryForm.effecEndTime,
            }
            exportDaySingleCardList(params).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        }
    },
}
</script>

<style>
    .day-single-card .el-form-item__label{
        vertical-align: top;
    }
</style>