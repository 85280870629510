<template>
    <div class="stop-reason fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">移动停机原因</span>
            <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item label="接入号:">
                    <el-input v-model="phone"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-plus" class="themed-button" type="primary" @click="importExcel">导入Excel</el-button>
				</el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
<!--                <el-table-column label="ICCID" prop="iccid" width="180px"></el-table-column>-->
                <el-table-column label="接入号" prop="phone" show-overflow-tooltip></el-table-column>
                <el-table-column label="IMEI" prop="imei" show-overflow-tooltip></el-table-column>
                <el-table-column label="生命周期状态" prop="lifeStatus" show-overflow-tooltip></el-table-column>
                <el-table-column label="停机状态" prop="stopStatus" show-overflow-tooltip></el-table-column>
                <el-table-column label="停机类型" prop="stopType" show-overflow-tooltip></el-table-column>
                <el-table-column label="开户时间" prop="startTime" show-overflow-tooltip></el-table-column>
                <el-table-column label="开机时间" prop="activeTime" show-overflow-tooltip></el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="导入表格" :visible.sync="dialogBox" v-if="dialogBox">
            <el-form ref="form" label-position="right" label-width="200px" style="max-height:500px;overflow:auto">
                <el-form-item label="上传文件（excel）:" required>
                    <input class="file-input" type="file" ref='file'/>
                    <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.csv/.xls/.xlsx格式</span>
                    </div>
                </el-form-item>
            </el-form>  
            <div class="align-right">
                <el-button type="primary" class="themed-button" @click="uploadExcel('form')">导入excel模板</el-button>
                <el-button type="primary" class="themed-button" @click="dialogBox=false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {stopReasonlist,stopReasonExportSync,stopReasonImportSync} from "@/api/history.js"
import { messageBox, confirmBox } from "@/utils/common.js"
import Utils from '@/utils/utils.js'
export default {
    name:'stopReason',
     mixins:[resize],
    data(){
        return {
            phone:'',
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogBox:false
        }
    },
    mounted(){
        this._stopReasonlist()
    },
    methods: {
        importExcel(){
            this.dialogBox = true
        },
        uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx'|| fileName.substring(index + 1, fileName.length)=='csv')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    stopReasonImportSync(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        onPageChange(page){
			this.page = page
			this._stopReasonlist()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._stopReasonlist()
        },
        onSearch(){
            this._stopReasonlist()
        },
        exportExcel(){
            stopReasonExportSync({phone:this.phone}).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        _stopReasonlist(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize,
                phone:this.phone
            }
            stopReasonlist(params).then((res)=>{
                res = res.data
                this.tableData = res.rows
                this.page = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.count
            })
        }
    },
}
</script>