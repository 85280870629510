<template>
    <div>
  <div class="title-box clearfix">
     
      <!-- <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item>
          <el-select
            v-model="formInline.status"
            placeholder="状态"
            clearable
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
      </el-form> -->
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="transferOrder" label="转账单号"> </el-table-column>
        <el-table-column prop="method" label="方式"> 
          <template slot-scope="scope">
             <span v-if="scope.row.method==1">微信</span>
             <span v-else-if="scope.row.method==2">其他</span>            
          </template>
        </el-table-column>
        
        
        <el-table-column prop="money" label="支付金额"> </el-table-column>
       
        <el-table-column prop="status" label="状态">
             <template slot-scope="scope">
             <span v-if="scope.row.status==1">成功</span>
             <span v-else-if="scope.row.status==2">失败</span>            
          </template> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="createTime" label="时间"> </el-table-column>
           <el-table-column label="操作" > 
              <template slot-scope="scope" v-if="scope.row.status==2">
                        <el-button  size="mini" class="themed-button" type="primary" @click="retryPay(scope.row.id)">重试</el-button>
                        <el-button size="mini" class="themed-button" type="primary" @click="cancelpay(scope.row.id)">取消</el-button>
                    </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>

    </div>
</template>
<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import { payRecordList ,cancelPay,recordRetry} from "@/api/partner.js";

export default {
  name: "payRecord",
  mixins: [onresize],
  props:["curId"],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
    //   formInline: {
    //     status: "",
    //   },
      tabledata: [],
      formLabelWidth: "200px",
    };
  },
  created(){
    this.getPayRecordList();
  },
  methods: {
   retryPay(id){
     recordRetry({id}).then(res=>{
       this.$message("重试成功")
       this.getPayRecordList()
     })
   },
   cancelpay(id){
   cancelPay({id}).then(res=>{
       this.$message("取消成功")
     this.getPayRecordList()
     })
   },
    getPayRecordList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.formInline);
      payRecordList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPayRecordList();
    },
    onPageChange(page) {
      this.page = page;
      this.getPayRecordList();
    },
    searchData() {
      this.page = 1;
      this.getPayRecordList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
.withdrawalManagement .el-date-editor.el-input {
  width: 100% !important;
}
.withdrawalManagement .el-form-item__label {
  vertical-align: top;
}
</style>
