<template>
	<div class="skuList fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">订单录入</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto; line-height: 40px"
				id="baseForm">
				<el-form-item>
					<el-button type="primary" @click="addRecord" icon="el-icon-plus" class="themed-button">添加订单
					</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addsRecord" icon="el-icon-plus" class="themed-button">批量导入订单
					</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe style="width: 100%" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="tbOrderNo" label="订单编号"> </el-table-column>
				<el-table-column prop="createTime" label="创建时间"> </el-table-column>
				<el-table-column prop="accountName" label="运营商"> </el-table-column>
				<el-table-column prop="mealName" label="套餐名称"> </el-table-column>
				<el-table-column prop="state" label="状态">
					<template v-slot:default="scope">
						{{ getStatus(scope.row.state) }}
					</template>
				</el-table-column>
				<el-table-column prop="iccId" label="iccid"> </el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<span v-if="scope.row.state == 0">
							<a href="javascript:;" @click="editDate(scope.row.id)" style="color: #337ab7;margin-right: 8px;">编辑</a>
							<a href="javascript:;" @click="delDate(scope.row.id)" style="color: #337ab7;">删除</a>
						</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10, 15, 25, 100]" :page-size="pageSize"
				style="text-align: left; margin-top: 20px" background layout="total,sizes,prev, pager, next,jumper"
				:total="total" @size-change="handleSizeChange" @current-change="onPageChange">
			</el-pagination>
		</div>
		<el-dialog :title="titleName" :visible.sync="dialogUpdateSkuVisible" v-if="dialogUpdateSkuVisible">
			<update-sku   v-if="popNum == 1" :id="updateId" @close="dialogUpdateSkuVisible = false" @refresh="refreshData">
			</update-sku>
			<add-sku  v-if="popNum == 2"   @close="dialogUpdateSkuVisible = false" @refresh="refreshData">
			</add-sku>
			<import-sku  v-if="popNum == 3"   @close="dialogUpdateSkuVisible = false" >
			</import-sku>
		</el-dialog>
	</div>
</template>
<script>
	import onresize from "@/mixins/resize.js";
	import {
		agentSKUList,
		delAgentSKU
	} from "@/api/agentManagement.js";
	import updateSku from "@/views/agentManagement/skuUpdate.vue";
	import addSku from "@/views/agentManagement/addSku.vue"
	import  importSku from "@/views/agentManagement/importSku.vue"
	export default {
		name:'skuList',
		mixins: [onresize],
		data() {
			return {
				tabledata: [],
				page: 1,
				pageSize: 15,
				total: 0,
				importType: "2",
				dialogUpdateSkuVisible: false,
				updateId: "",
                popNum:0,
				titleName:"",
			};
		},
		created() {
			let params = {
				importType: "2",
			};
			this.getagentSKUList(params);
		},
		components: {
			updateSku,
			addSku,
			importSku,
		},
		methods: {
			getagentSKUList(params) {
				agentSKUList(params).then((res) => {
					this.total = res.total;
					this.page = res.page;
					this.pageSize = res.pageSize;
					this.tabledata = res.rows;
				});
			},
			addRecord() {
				this.dialogUpdateSkuVisible = true;
                this.popNum = 2;
				this.titleName = "添加订单";
			},
			addsRecord() {
				this.dialogUpdateSkuVisible = true;
				this.popNum = 3;
				this.titleName = "批量导入订单";
			},
			getStatus(state) {
				if (state == 0) {
					return "未绑定";
				} else if (state == 1) {
					return "已绑定";
				} else if (state == 2) {
					return "已充值";
				} else {
					return "-";
				}
			},
			handleSizeChange(size) {
				this.pageSize = size;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
					importType: "2",
				};
				this.getagentSKUList(params);
			},
			onPageChange(page) {
				this.page = page;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
					importType: "2",
				};
				this.getagentSKUList(params);
			},
			editDate(id) {
				this.dialogUpdateSkuVisible = true;
				this.updateId = id;
                this.popNum = 1;
				this.titleName = "编辑订单"
			},
			delDate(id) {
				this.$confirm("确定要删除吗？", "消息提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						let params = {
							skuId: id,
							importType: "2",
						};
						delAgentSKU(params).then((res) => {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.getagentSKUList({
								importType: "2",
							});
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			refreshData() {
				let params = {
					importType: "2",
				};
				this.getagentSKUList(params);
			},
		},
	};
</script>
