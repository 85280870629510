<template>
  <div class="leftdayDiscount fadeInRight">
    <el-form ref="addForm" style="max-height: 500px; overflow-y: scroll">
      <el-table :data="tableList" style="margin-top: 15px">
        <el-table-column label="当月剩余天数" prop="" min-width="90">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.leftDay"  :min="1" :max="31" :step="1" step-strictly label="剩余天数"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="折扣" prop="" min-width="90">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.discount"  :min="0" :max="1" :precision="2" :step="0.1" label="输入折扣"></el-input-number>
          </template>
        </el-table-column>
       
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="danger"
              plain
              size="mini"
              :disabled="tableList.length==1"
              @click="deleteParams(scope.row, scope.$index)"
              >删除</el-button
            >
            <el-button
              type="info"
              plain
              size="mini"
              :disabled="isDisable"
              @click="afterAddParams(scope.row, scope.$index)"
              >插入一行</el-button
            >
          </template>
        </el-table-column>
      </el-table>
     
      <span></span>
      <div class="align-right">
        <el-button type="primary" class="themed-button" @click="cancle"
          >取消</el-button
        >
        <el-button type="primary" class="themed-button" @click="sure"
          >确认</el-button
        >
      </div>
    </el-form>
  
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import { messageBox, confirmBox } from "@/utils/common.js";
export default {
  name: "leftdayDiscount",
  mixins: [resize],
  props: ["curDiscount"],
  data() {
    return {
      tableList: [
       
      ],
      currentTable:this.curDiscount
    };
  },
  created() {
     if(!this.currentTable){
      this.tableList.push({
        leftDay: 1,
        discount:1,
      });
    } else if (this.currentTable.indexOf(",") > 0) {
      this.currentTable = this.currentTable + ";";
      let arr = this.currentTable.split(";");
      console.log(arr);
      arr.forEach((item, index) => {
       
          if (item) {
            let arrValue = item.split(",");
            this.tableList.push({
              leftDay: arrValue[0],
              discount: arrValue[1],
            });
          }
       
      });
    } else {
      this.tableList.push({
        leftDay: 1,
        discount:1,
      });
    }
  },
  computed: {
    isDisable() {
      if (this.tableList.length >= 3) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    deleteParams(data, index) {
      
      this.tableList.splice(parseInt(index), 1);
    },
    afterAddParams(data, index) {
    this.tableList.splice(parseInt(index)+1,0,{ leftDay:"1",discount:"1"})
    },
    cancle() {
      this.$emit("close");
    },

    sure() {
      //校验
      if (this.tableList.length > 0) {
        let value = "";
        var arr = this.tableList.sort()
        console.log(arr)
        for(var i =0;i<arr.length-1;i++){
           if(arr[i].leftDay==arr[i+1].leftDay){
             messageBox(this, "设置的剩余天数重复");
            return;
           }
        }
        for (let i of arr) {
          
          value += i.leftDay + "," + i.discount + ";";
        }
        value = value.substring(0, value.length - 1);
        console.log(value);
        this.$emit("close", value);
      }
    },
  },
};
</script>

<style>
.leftdayDiscount .el-form-item__label {
  vertical-align: top;
}
.leftdayDiscount .inputWidth {
  width: 50%;
}
</style>