<template>
	<div class="agentlist fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">代理商列表</span>
			<el-form inline ref="agentListForm" v-model="agentListFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
			<!-- 	<el-form-item>
					<el-button icon="el-icon-caret-bottom" class="themed-button" type="primary" @click="Toexpandall()">展开</el-button>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-caret-top" class="themed-button" type="primary" @click="fold()">折叠</el-button>
				</el-form-item> -->
				<el-form-item>
					<el-button icon="el-icon-refresh" class="themed-button" type="primary" @click="reload()">刷新</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tableData" style="width: 100%" row-key="id"  lazy :load='load' 
			 :tree-props="{children: 'children', hasChildren: 'hasChildren'}" ref="agentListTable"  border>
				<el-table-column type="index" min-width="50">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="text" label="代理商名称" min-width="200">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="bank" label="提现银行" min-width="200">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="bankCode" label="提现账户" min-width="200">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="bankReceiver" label="收款人" min-width="200">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="zhifubao" label="支付宝" min-width="200">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="zhifubaoReceiver" label="支付宝收款人" min-width="200">
				</el-table-column>
			</el-table>
		</div>

	</div>
</template>

<script>
	import resize from '@/mixins/resize.js'
	import loading from '@/utils/Loading.js'
	import {
		agentTreeList
	} from "@/api/agentManagement.js"
	export default {
		name:'agentList',
		mixins: [resize],
		data() {
			return {
				agentListFormData: {},
				tableData: [],
			}
		},
		created() {
			this.getAgentTreeList()
		},
		methods: {
			getAgentTreeList() {
				agentTreeList().then(res => {
					this.tableData = res.rows
				})
			},
			load(tree, treeNode, resolve) {
				//var that = this
				setTimeout(() => {
					agentTreeList({
						'id': tree.id
					}).then(res => {
						resolve(res.rows);
					})
				}, 100)
			},
			reload() {
				loading.show()
				this.getAgentTreeList()
			},
			fold() {
				if (this.tableData.length != 0) {
					const els = this.$el.getElementsByClassName('el-table__expand-icon el-table__expand-icon--expanded')
					if (this.$el.getElementsByClassName('el-table__expand-icon el-table__expand-icon--expanded')) {
						for (let i = 0; i < els.length; i++) {
							els[i].click()
						}
					}
				}
			},
			Toexpandall() {
				let els = this.$el.getElementsByClassName('el-table__expand-icon')
				if (this.tableData.length != 0 && els.length != 0) {
					for (let j1 = 0; j1 < els.length; j1++) {
						els[j1].classList.add("agentListDafult")
					}
					if (this.$el.getElementsByClassName('el-table__expand-icon--expanded')) {
						const open = this.$el.getElementsByClassName('el-table__expand-icon--expanded')
						for (let j = 0; j < open.length; j++) {
							open[j].classList.remove("agentListDafult")
						}
						const dafult = this.$el.getElementsByClassName('agentListDafult')
						
						for (let a = 0; a < dafult.length; a++) {
							dafult[a].click()
						}
					}
				}
			},

		},
	

	}
</script>

<style>
</style>
