<template>
	<div class="agent_merchNum fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">代理商-商户号</span>
			<el-form :inline="true" :model="formInline" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="关键字：">
					<el-input v-model="formInline.pageSearchKeys" placeholder="代理商或者商户号名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addAgentRecord" icon="el-icon-plus" class="themed-button">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="agentName" label="代理商">
				</el-table-column>
				<el-table-column prop="merchantName" label="商户号">
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间">
				</el-table-column>

				<el-table-column label="操作">
					<template slot-scope="scope">
						<span @click='deleteById(scope.row.id)' class="left_agent">删除</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
		<el-dialog title="新增代理商关联商户号" :visible.sync="dialogAddMerchVisible" :before-close="handleCloseAddMerch">
			<el-form :model="formAddMerch" ref="ruleFormAddMerch">
				<el-form-item label="代理商:" :rules="{required:true}" :label-width="formLabelWidth">
					<el-select v-model="formAddMerch.agentId" placeholder="请选择" @change="selectAgentName" filterable>
						<el-option label="请选择" value="0"></el-option>
						<el-option v-for="item in agentNameItem" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="商户号：" :rules="{required:true}" :label-width="formLabelWidth">
					<el-select v-model="formAddMerch.merchantId" placeholder="请选择" @change="selectMerchantName" filterable>
						<el-option label="请选择" value="0"></el-option>
						<el-option v-for="item in agentMerchantNameItem" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="formAddMerchSubmit('ruleFormAddMerch')" class="themed-button">确 定</el-button>
				<el-button  type="info" @click="cancelDialogformAddMerch('ruleFormAddMerch')" class="themed-button">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import onresize from "@/mixins/resize.js"
	import loading from '@/utils/Loading.js'
	import {
		agentMerchantNumList,
		merchantList,
		addAgentMerchantNum,
		deleteAgentMerchantNum
	} from "@/api/payManagment.js"
	import {
		getAgentList
	} from "@/api/agentManagement.js"

	export default {
		name:'agentMerchNum',
		mixins: [onresize],
		data() {
			return {
				formInline: {
					pageSearchKeys: "",
				},
				tabledata: [],
				page: 1,
				pageSize: 15,
				total: 0,
				dialogAddMerchVisible: false,
				formAddMerch: {
					agentName: "",
					merchantName: "",
					merchantId: "0",
					agentId: "0",
				},
				formLabelWidth: "150px",
				agentNameItem: [],
				agentMerchantNameItem: [],
				currentAgentNameItem:{},
				currentAgentMerchantNameItem:{},
			}
		},
		created() {
			this.getAgentMerchantNumList(this.formInline)
		},
		methods: {
			getAgentMerchantNumList(params) {
				agentMerchantNumList(params).then(res => {
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
					this.tabledata = res.rows
				})
			},
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
					pageSearchKeys: this.formInline.pageSearchKeys
				}
				this.getAgentMerchantNumList(params)
			},
			handleSizeChange(size) {
				this.pageSize = size
				this.searchData()
			},
			onPageChange(page) {
				this.page = page
				this.searchData()
			},

			addAgentRecord() {
				getAgentList(this.formAddMerch).then(res => {
					this.agentNameItem = res
				})
				merchantList(this.formAddMerch).then(res => {
          this.agentMerchantNameItem= res.rows
				})
				this.dialogAddMerchVisible = true

			},
			deleteById(id) {
				this.$confirm('确定要删除关系吗？', '消息提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params = {
						id: id,
						pageSearchKeys:this.formInline.pageSearchKeys
					}
					deleteAgentMerchantNum(params).then(() => {
						this.getAgentMerchantNumList(this.formInline)
					/* 	this.$alert('删除成功!', '提示', {
							confirmButtonText: '确定',
						}); */
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

			},
			formAddMerchSubmit() {
				if (null == this.formAddMerch.agentId  || "" == this.formAddMerch.agentId  || this.formAddMerch.agentId  == 0) {
				    this.$alert('请选择代理商!', '提示', {
				    	confirmButtonText: '确定',
				    });
				    return;
				}
				if (null == this.formAddMerch.merchantId  || "" == this.formAddMerch.merchantId  || this.formAddMerch.merchantId  == 0) {
						this.$alert('请选择商户号！', '提示', {
							confirmButtonText: '确定',
						});
				    return;
				}
				this.dialogAddMerchVisible = false
				addAgentMerchantNum(this.formAddMerch).then(res=>{
					this.$message({
						type: 'success',
						message: '新增成功'
					});
					this.getAgentMerchantNumList(this.formInline)
					this.formAddMerch.agentId ="0"
					this.formAddMerch.merchantId ="0"
					this.formAddMerch.merchantName =""
					this.formAddMerch.agentName =""
				})
			},
			cancelDialogformAddMerch() {
       this.dialogAddMerchVisible = false
       this.formAddMerch.agentId ="0"
       this.formAddMerch.merchantId ="0"
       this.formAddMerch.merchantName =""
       this.formAddMerch.agentName =""
			},
			selectMerchantName(value){
				this.currentAgentMerchantNameItem = this.agentMerchantNameItem.find(item=>{
					return item.id ===value
				})
				this.formAddMerch.merchantName = this.currentAgentMerchantNameItem.name
			},
			selectAgentName(value){
			this.currentAgentNameItem = this.agentNameItem.find(item=>{
				return item.id ===value
			})
			this.formAddMerch.agentName = this.currentAgentNameItem.name	
			},
			handleCloseAddMerch(done){
				this.formAddMerch.agentId ="0"
				this.formAddMerch.merchantId ="0"
				this.formAddMerch.merchantName =""
				this.formAddMerch.agentName =""
				done()
			}
			
		},
	}
</script>

<style>
	.agent_merchNum .el-form-item__label {
		vertical-align: top;
	}
</style>
