<template>

<div class="form-add">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="库编号:">
            <el-input v-model="form.cardBankCode" readonly></el-input>
        </el-form-item>
        <el-form-item label="运营商:" prop="accountId" required>
            <el-select v-model="form.accountId" filterable @change="accountChange">
                <el-option v-for="(item,index) in accountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="客户:" prop="customerName" required>
            <el-input v-model="form.customerName"></el-input>
        </el-form-item>
        <el-form-item label="数量:" prop="amount" required>
            <el-input v-model="form.amount"></el-input>
        </el-form-item>
        <el-form-item label="备注:">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="上传卡文件（excel）:" required>
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
            </div>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel('form')">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {getAccountList,bindCardAddSync,bindCardFindById,getNewCardBankCode} from '@/api/meCardBindManagement/imeiLibrary.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    data(){
        return {
            form:{
                cardBankCode:'',
                accountId:'',
                accountName:'',
                customerName:'',
                amount:'',
                remark:''
            },
            accountList:[],
            rules: {
                accountId: [
                    { required: true, message: '请选择运营商', trigger: 'blur' }
                ],
                amount: [
                    { required: true, message: '请选择数量', trigger: 'blur' }
                ],
                customerName: [
                    { required: true, message: '请填写客户名称', trigger: 'blur' }
                ]
            },
        }
    },
    mounted(){
        this._getAccountList()
        this._getNewCardBankCode()
    },
    methods:{
        accountChange(){
             let account = this.accountList.find((item)=>{
                return item.id == this.form.accountId
            })
            this.form.accountName = account.accountName
        },
        uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            // alert('submit!');
                            let params = new FormData()
                            params.append('fileurl',file)
                            params.append('cardBankCode',this.form.cardBankCode)
                            params.append('accountId',this.form.accountId)
                            params.append('accountName',this.form.accountName)
                            params.append('customerName',this.form.customerName)
                            params.append('amount',this.form.amount)
                            params.append('remark',this.form.remark)
                            bindCardAddSync(params).then(res=>{
                                if (res.system_result_key == '0') {
                                    this.$alert('导入成功，请到导入记录中下载', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.$emit('close-dialog')
                                        this.$router.push('/tob/i_import_list')
                                    });
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                }
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        importExcel(){
            let name = 'import.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        _getAccountList(){
            getAccountList().then((res)=>{
                if(res.length>0){
                    this.accountList = res
                }else{
                    this.accountList = []
                }
            })
        },
        _getNewCardBankCode(){
            getNewCardBankCode(this.form).then((res)=>{
                this.form.cardBankCode = res
            })
        }
    }
}
</script>

