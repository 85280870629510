import request from './../request.js'
export function getOperatorInterfaceList(params) {//获取
    return request({
        url: "/account/accountInterface/getList.do?",
        method: 'post',
        data: params
    })
}
export function addOperatorInterface(params) {//新增
    return request({
        url: "/account/accountInterface/insert.do?",
        method: 'post',
        data: params
    })
}

export function checkOperatorInterface(params) {//验证(暂时未用)
    return request({
        url: "/account/accountInterface/insert.do?",
        method: 'post',
        data: params
    })
}

export function editOperatorInterface(params) {//编辑
    return request({
        url: "/account/accountInterface/update.do?",
        method: 'post',
        data: params
    })
}

export function deleteOperatorInterface(params) {//删除
    return request({
        url: "/account/accountInterface/del.do?",
        method: 'post',
        data: params
    })
}




