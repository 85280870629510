<template>
    <div class="imaginary-switching fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">用户投诉列表</span>
            <el-form v-model="form" inline class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item label="ICCID:">
                    <el-input v-model="form.iccidMark" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item label="接入号:">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="手机号:">
                    <el-input v-model="form.telephone"></el-input>
                </el-form-item>
                <el-form-item label="处理人:">
                    <el-input v-model="form.updateUser"></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="form.status">
                        <el-option value="" label="全部"></el-option>
                        <el-option value="0" label="未处理"></el-option>
                        <el-option value="1" label="已处理"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="渠道:">
                    <el-select v-model="form.channel">
                        <el-option value="" label="全部"></el-option>
                        <el-option value="1" label="终端用户"></el-option>
                        <el-option value="2" label="客服"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客诉类别:">
                    <el-select v-model="form.type" filterable>
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="(item,index) in typeList" :value="item.value" :label="item.name" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="开始时间:">
          <el-date-picker
            placeholder="请选择时间" style="width: 100%;" 
            v-model="form.startTime"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker style="width: 100%;" 
            placeholder="请选择时间"
            v-model="form.endTime"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>                
                <el-form-item>
					<el-button icon="el-icon-plus" type="primary" class="themed-button" @click="add">新增投诉</el-button>
				</el-form-item>                
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
                <el-table-column label="ICCID" prop="iccidMark" width="180px">
                    <template slot-scope="scope">
                        <a href="javascript:void(0);" @click="detailIccid(scope.row.iccidMark)">{{scope.row.iccidMark}}</a>
                    </template>
                </el-table-column>
                <el-table-column label="接入号" prop="phone" show-overflow-tooltip></el-table-column>
                <el-table-column label="代理商" prop="agenthreeName" show-overflow-tooltip></el-table-column>
                <el-table-column label="联系人" prop="contacts" show-overflow-tooltip></el-table-column>
                <el-table-column label="风险" prop="riskType" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.riskType == 1">高风险</span>
                        <span v-if="scope.row.riskType == 2">普通</span>
                    </template>
                </el-table-column>
                <el-table-column label="客诉类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{complaintType(scope.row.type)}}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
                <el-table-column label="处理时间" prop="updateTime" show-overflow-tooltip></el-table-column>
                <el-table-column label="处理人" prop="updateUser" show-overflow-tooltip></el-table-column>
                <el-table-column label="投诉内容" prop="problem" show-overflow-tooltip></el-table-column>
                <el-table-column label="回复内容" prop="reply" show-overflow-tooltip></el-table-column>
                <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
                <el-table-column label="状态" prop="status" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == '0'">未处理</span>
                        <span v-if="scope.row.status == '1'">已处理</span>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" prop="telephone" show-overflow-tooltip></el-table-column>
                <el-table-column label="渠道" prop="channel" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.channel == 1">终端用户</span>
                        <span v-if="scope.row.channel == 2">客服</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="220px">
                    <template slot-scope="scope">
                        <el-button type="primary" class="themed-button" @click="detailFile(scope.row)">详情</el-button>
                        <el-button type="primary" class="themed-button" @click="importUpdate(scope.row)">编辑</el-button>
                        <el-button type="danger" class="themed-button" v-if="currentRole==0 ||(currentRole==4&&agentTree==1)"  @click="delectFile(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog :title="title" :visible.sync="dialogBox" v-if="dialogBox">
            <add v-if="dialog == 'add'" @close="close" @refresh="refresh"></add>
            <update v-if="dialog == 'update'" :updateDate="updateDate" @close="close" @refresh="refresh"></update>
            <detail v-if="dialog == 'detail'" :updateDate="updateDate" @close="close"></detail>
        </el-dialog>

    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {complaintList,complaintDel,complaintExport} from "@/api/cardManagement/complaintsList.js"
import { messageBox, confirmBox } from "@/utils/common.js"

import Add from '@/views/cardManagement/complaintsList/add'
import Detail from '@/views/cardManagement/complaintsList/detail'
import Update from '@/views/cardManagement/complaintsList/update'
import {mapGetters} from 'vuex'
import {mapMutations} from "vuex"

export default {
    name:'complaintsList',
     mixins:[resize],
    data(){
        return {
            form:{
                iccidMark:'',
                phone:'',
                telephone:'',
                updateUser:'',
                status:'',
                channel:'',
                type:'',
                endTime:"",
                startTime:"",
            },
            typeList:[
                {name:'无法使用',value:'1'},
                {name:'套餐过期',value:'2'},
                {name:'套餐用超',value:'3'},
                {name:'换设备',value:'4'},
                {name:'不会使用',value:'5'},
                {name:'功能性问题',value:'6'},
                {name:'虚量大问题',value:'7'},
                {name:'限速问题',value:'8'},
                {name:'虚假宣传',value:'9'},
                {name:'无效投诉',value:'10'}
            ],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            title:'',
            dialog:'',
            updateDate:null
        }
    },
    mounted(){
        this._complaintList()
    },
    methods: {
        ...mapMutations(
                ["SET_ICCID_OR_PHONE","SET_STATUS","SET_TYPE"]
            ),
        delectFile(data){
            console.log(1)
            let msg = data.iccidMark + ": 确定是否删除?"
            confirmBox(this,msg,null,()=>{
                complaintDel({id:data.id}).then((res)=>{
                    messageBox(this,'删除成功')
                    this._complaintList()
                })
            })
        },
        complaintType(type){
            let obj = this.typeList.find((item)=>{
                return item.value == type
            })
            return obj.name
        },
        detailIccid(iccidMark){
            console.log(iccidMark)
            this.SET_ICCID_OR_PHONE(iccidMark)
            this.SET_STATUS('')
            this.SET_TYPE('')
            this.$router.push({
                path: '/cardManagement/cardBillDetail',
                query: {
                    iccidOrPhone: iccidMark,
                    status: '',
                    type: '',
                }
            })
        },
        refresh(){
            this._complaintList()
        },
        close(){
            this.dialogBox = false  
        },
        add(){
            this.dialog = 'add'
            this.title = '新增'
            this.dialogBox = true
        },
        importUpdate(data){
             console.log(data)
            this.dialog = 'update'
            this.title = '编辑'
            this.updateDate = data
            this.dialogBox = true
        },
        detailFile(data){
            console.log(data)
            this.dialog = 'detail'
            this.title = '详情'
            this.updateDate = data
            this.dialogBox = true
        },
        onPageChange(page){
			this.page = page
			this._complaintList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._complaintList()
        },
        onSearch(){
            this._complaintList()
        },
        exportExcel(){
            complaintExport(this.form).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        _complaintList(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize
            }
            complaintList(Object.assign(params,this.form)).then((res)=>{
                console.log(res)
                this.tableData = res.rows
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
            })
        }
    },
    components:{
        Add,
        Update,
        Detail
    },
     computed:{
        	...mapGetters(
            	['currentRole','agentTree']
        	),
    	},
}
</script>