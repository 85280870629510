<template>

<div class="unlock-card-slot table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">解锁插拔卡槽</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="IMEI:">
                <el-input v-model="form.imei" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="需要绑定:">
                <el-select filterable v-model="form.needBind">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="不需要绑定" value="0"></el-option>
                    <el-option label="需要绑定" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchData">搜索查询</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="IMEI" prop="imei" show-overflow-tooltip></el-table-column>
            <el-table-column label="需要绑定" prop="needBind" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.needBind==0">不需要绑定</span>
                    <span v-if="scope.row.needBind==1">需要绑定</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="updateTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="创建人" prop="operator" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" @click="handleEdit(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

     <el-dialog title="编辑" :visible.sync="editDialog" destroy-on-close>
        <el-form label-position="right" label-width="120px" :model="editForm"  ref="editForm">
            <el-form-item label="IMEI">
                    <el-input v-model="editForm.imei" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="需要绑定">
                    <el-select v-model="editForm.needBind">
                        <el-option label="不需要绑定" value="0"></el-option>
                        <el-option label="需要绑定" value="1"></el-option>
                    </el-select>
            </el-form-item>      
            
        </el-form>
        <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="submitEdit('editForm')">确 定</el-button>
                <el-button class="themed-button" type="primary" @click="editDialog = false">关 闭</el-button>
            </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {bindImei2List,updateBindImei2} from '@/api/meCardBindManagement/unlockCardSlot.js'
    import {messageBox,confirmBox } from '@/utils/common.js'
    import resize from '@/mixins/resize.js'

    export default {
        mixins:[resize],
        data(){
            return {
                form:{
                   imei:'',
                   needBind:'',
                },
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
                editDialog:false,
                editForm:{
                    imei:'',
                    needBind:'',
                    id:'',
                }
            }
        },
        mounted(){
            this.getList()
        },
        methods:{
            searchData(){
                this.currentPage = 1
                this.getList()
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getList()
            },
            getList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                Object.assign(params,this.form)
                bindImei2List(params).then((res)=>{
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.tableData = res.rows
                })
            },

            handleEdit(data){
                this.editDialog = true
                this.editForm.imei = data.imei
                this.editForm.needBind = data.needBind
                this.editForm.id = data.id
            },

            submitEdit(){
                this.$confirm('确定此操作吗？','消息提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                updateBindImei2(this.editForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '操作成功',
					        type: 'success',
							center: true
                        })
                        this.editDialog = false
                        this.getList() 
                    })
                }).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
			    });
            }

        }
    }
</script>

