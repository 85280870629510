<template>

<div class="show-new">
    <el-form :model="form" label-position="right" label-width="100px" >
        <el-form-item label="ICCID:">
            <el-input v-model="form.filterIccid"></el-input>
            <p class="red">iccid可以填*(代表所有卡),单个iccid号，iccid区间(XXXX1:XXXX2)</p>
        </el-form-item>
        <el-form-item label="选择代理商:">
            <el-select v-model="form.filterAgentName"  filterable  @change="getAgentMeal">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item,index) in agentList" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="所属类别:" style="display:none;">
            <el-select v-model="form.category" @change="changeCategory">
                 <el-option label="请选择运营商" value=""></el-option>
                 <el-option v-for="(item,index) in $store.getters.categorys" :label="item.name" :value="item.value" :key="index"></el-option>
            </el-select>
            <p class="red">若不选代理，这个套餐对应的所有代理商都可以看见优惠券</p>
        </el-form-item>
        <el-form-item v-if="form.category == categorySlist['中国移动'] || form.category == categorySlist['中国联通']" label="子类别:">
        <!-- <el-form-item v-if="form.category ==7 || form.category == 4" label="子类别:"> -->
            <el-select v-model="form.secondCategory" @change="getAgentMeal"  filterable>
                <el-option v-for="(item,index) in secondCategoryList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="套餐勾选:" style="display:none;">
            <div style="max-height:250px;overflow:auto">
                <el-checkbox-group v-model="filterMeal">
                    <el-checkbox v-for="(item,index) in mealList" :label="item.id+'@@'+item.name" :key="index">{{item.name}}</el-checkbox>
                </el-checkbox-group>
            </div>
        </el-form-item>
         <el-form-item label="预存金额:">
        <el-input-number v-model="rechargeMoney" :step="1" :min=1></el-input-number>
            <!-- <div style="max-height:250px;overflow:auto">
                <el-checkbox-group v-model="rechargeAmounts">
                    <el-checkbox v-for="(item,index) in rechargeList" :label="item.id" :key="index">{{"预存金额"+item.rechargeAmount+"赠送金额"+item.sendMoney}}</el-checkbox>
                </el-checkbox-group>
            </div> -->
        </el-form-item>
        <div style="line-height: 24px; font-size: 18px;color: #303133;margin-bottom:10px;">数量设置</div>
         <el-form-item label="数量:">
             <el-input-number v-model="form.count" :step="1" step-strictly :min="0"></el-input-number>
            <!-- <el-input v-model="form.count" ></el-input> -->
        </el-form-item>
         <el-form-item label="生效方式:" v-if="effectType!=3">
            <el-select v-model="form.type" >
                <el-option label="请选择" value=""></el-option>
                <el-option label="一次性全部生效" value="1"></el-option>
                <el-option label="顺延生效" value="2"></el-option>
            </el-select>
            <div style="color:#f00;">
                当数量大于1时，顺延指优惠券的有效期时间按周期顺延，一次生效指所有券同一天生效，同一天失效
            </div>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="sure">确定</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取 消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {getAgentList, getMealListByAgentId,addShowFilterById,rechargeAmountlist } from "@/api/eventDiscountManagement/couponManagement.js";
import {getAccountList} from '@/api/cardManagement/cardBill.js'
import { messageBox, confirmBox } from "@/utils/common.js";
import SearchSelect from "@/components/SearchSelect";
import store from '@/store'
export default {
    props:['promotionId',"effectType"],
    data(){
        return {
            form:{
                filterIccid:'*',
                filterAgent:'',
                filterAgentName:'',
                category:"",
                secondCategory:'',
                count:1,
                type:"2",
            },
            filterMeal:[],
            agentList:[],
            secondCategoryList:[],
            mealList:[],
            categorySlist:{},
            rechargeList:[],
            rechargeMoney:"",
        }
    },
    mounted(){
        if(store.getters.categorys.length<1){
          store.dispatch('getCategory').then(()=>{
                  if(store.getters.categorys.length>0){
            store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.categorySlist[name] =value
            })
               }
          })
        }else{
             store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.categorySlist[name] =value
            })
        }
        if(store.getters.secondCategory_yd.length<1||store.getters.secondCategory_lt.length<1){
           store.dispatch("getPackageType")
        }
        this._getAgentList()
        this.getAgentMeal()
        this.getrechargeAmountlist()
    },
    methods:{
        getrechargeAmountlist(){
         let params={
            importType: 2
         }
         rechargeAmountlist(params).then(res=>{
          console.log(res.rows)
          this.rechargeList=[]
          if(res.rows.length>0){
            this.rechargeList = res.rows
          }else{
            this.rechargeList=[]
          }
          
         })
        },
        sure(){
            this.agentList.forEach((item)=>{
                if(item.name == this.form.filterAgentName){
                    this.form.filterAgent = item.id
                    return
                }
            })
            if(this.form.filterAgent == ''){
                this.form.filterAgentName = ''
            }
            let parames = {
                promotionId: this.promotionId,
                filterMeal:this.filterMeal.join(','),
                rechargeMoney:this.rechargeMoney
            }
            Object.assign(parames,this.form)
            addShowFilterById(parames).then((res)=>{
                if(res.slide_msg_key == 1){
                    messageBox(this.res.slide_msg_message_key)
                    this.$emit('close')
                    return
                }
                this.$emit('refresh')
                this.$emit('close')
                
            })
        },
        cancle(){
            this.$emit('close')
        },
        changeCategory(){
             if(this.form.category == this.categorySlist['中国移动']||this.form.category == this.categorySlist['中国联通']){
                this.secondCategoryList = []
               getAccountList({category:this.form.category}).then((res) => {
                    this.secondCategoryList = [{label:'请选择',value:''}]
                    if(res.length>0){
                        res.forEach((item)=>{
                            this.secondCategoryList.push({label:item.accountName,value:item.secondCategory})
                        })
                    }
                })
            }
            // console.log(this.form.category,this.categorySlist['中国移动'],store.getters)
            // if(this.form.category == this.categorySlist['中国移动']){
            //     // this.secondCategoryList = [
            //     //     {label:'请选择',value:''},
            //     //     {label:'GD_IOT',value:'1'},
            //     //     {label:'OneLink',value:'2'},
            //     //     {label:'深圳移动',value:'3'}
            //     // ]
            //       this.secondCategoryList = []
            //     if(store.getters.secondCategory_yd.length>0){
            //       store.getters.secondCategory_yd.forEach(item=>{
            //           if(item.groupName){
            //          this.secondCategoryList.push({label:item.groupName,value:item.dictValue})
            //           }
            //       }) 
            //     }
            //       this.secondCategoryList.unshift( {label:'请选择',value:''})
            // }else if(this.form.category == this.categorySlist['中国联通']){
            //     // this.secondCategoryList = [
            //     //     {label:'请选择',value:''},
            //     //     {label:'浙江联通',value:'1'},
            //     //     {label:'青岛联通',value:'2'},
            //     //     {label:'湖南联通',value:'3'}
            //     // ]
            //         this.secondCategoryList = []
            //     if(store.getters.secondCategory_lt.length>0){
            //       store.getters.secondCategory_lt.forEach(item=>{
            //           if(item.groupName){
            //          this.secondCategoryList.push({label:item.groupName,value:item.dictValue})
            //           }
            //       }) 
            //     }
            //       this.secondCategoryList.unshift( {label:'请选择',value:''})
            // }
            this.getAgentMeal()
        },
        getAgentMeal(){
            this.filterMeal = []
            let parames = {
                agtId:this.form.filterAgent,
                cate1:this.form.category,
                secondCategory2:this.form.secondCategory
            }
            getMealListByAgentId(parames).then((res)=>{
                if(res.mealList!=null && res.mealList.length>0){
                    this.mealList = res.mealList
                }else{
                    this.mealList = []
                }
                
            })
        },
        _getAgentList(){
            let parames = {
                promotionId:this.promotionId,
                status:0
            }
            Object.assign(parames,this.form)
            console.log(parames)
            getAgentList(parames).then((res)=>{
                if(res.length>0){
                    this.agentList = res
                }else{
                    this.agentList = []
                }
            })
        }
    }
}
</script>

