/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from '../request.js'

export function imeiBankCodeList(params){
    return request({
        url: 'bindImei/listImeiBankCode.do?',
        method: 'post',
        data:params
    })
}


export function cardBankCodeList(params){
    return request({
        url: 'bindCard/listCardBankCode.do?',
        method: 'post',
        data:params
    })
}

export function imeiBindHistory(params){
    return request({
        url: 'bindImeiCard/listLog.do?',
        method: 'post',
        data:params
    })
}

export function exportImeiHistory(params){
    return request({
        url: 'bindImeiCard/exportLogSync.do?',
        method: 'post',
        data:params
    })
}
