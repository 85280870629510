import request from './request.js'
// 运营商管理

export function operatorList(params) {
    return request({
        url: 'system/cardOperator/list.do?',
        method: 'post',
        data:params
    })
}


// 添加运营商
export function addSupplier(params) {
    return request({
        url: 'system/cardOperator/addSupplier.do?',
        method: 'post',
        data:params
    })
}

//编辑运营商
export function editSupplier(params) {
    return request({
        url: 'system/cardOperator/updateSave.do?',
        method: 'post',
        data:params
    })
}

// 修改运营商
// 获取信息
export function initEditOperator(params) {
    return request({
        url: 'system/cardOperator/initEdit.do?',
        method: 'post',
        data:params
    })
}

// 修改提交
export function saveEditOperator(params) {
    return request({
        url: 'system/cardOperator/editSave.do?',
        method: 'post',
        data:params
    })
}

// 验证
export function checkOperator(params) {
    return request({
        url: 'system/cardOperator/checkAccount.do?',
        method: 'post',
        data:params
    })
}

// 获取apn列表
export function getApnAllList(params) {
    return request({
        url: 'apnset/getApnsetAllList.do?',
        method: 'post',
        data:params
    })
}

// 编辑apn
export function editApn(params) {
    return request({
        url: 'system/cardOperator/editApn.do?',
        method: 'post',
        data:params
    })
}

// 修改禁用状态
export function editMiaoFan(params) {
    return request({
        url: 'system/cardOperator/editMiaoFan.do?',
        method: 'post',
        data:params
    })
}

// 编辑实名方式
export function editRealName(params) {
    return request({
        url: 'system/cardOperator/editRealName.do?',
        method: 'post',
        data:params
    })
}

// 标签设置
// 表
export function labelSettingList(params) {
    return request({
        url: 'tagconfig/list.do?',
        method: 'post',
        data:params
    })
}

// 新增设置
export function addLabelSetting(params) {
    return request({
        url: 'tagconfig/save.do?',
        method: 'post',
        data:params
    })
}

// 获取运营商列表
export function operatorLabelList(params) {
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data: params
    })
}

// 获取编辑数据
export function labelDataById(params) {
    return request({
        url: 'tagconfig/findById.do?',
        method: 'post',
        data: params
    })
}

// 保存修改
export function updateLabelSetting(params) {
    return request({
        url: 'tagconfig/update.do?',
        method: 'post',
        data: params
    })
}

// 删除
export function deleteLabelSetting(params) {
    return request({
        url: 'tagconfig/deleteById.do?',
        method: 'post',
        data: params
    })
}

//获取平台类型 月结日列表
export function getDictGroupList(params) {
    return request({
        url: 'dict/qury.do?',
        method: 'post',
        data: params
    })
}


export function interfacePacketList(params) {
    return request({
        url: '/card/log/get.do?',
        method: 'post',
        data: params,
        // timeout:20000,
		// headers:{'Content-Type': 'application/json'}
    })
}

export function operatorMealList(params) {
    return request({
        url: '/account/meal/list.do?',
        method: 'post',
        data: params
    })
}

export function deletOperatorMeal(params) {
    return request({
        url: '/account/meal/deleteById.do?',
        method: 'post',
        data: params
    })
}

export function operatorMealAdd(params) {
    return request({
        url: '/account/meal/add.do?',
        method: 'post',
        data: params
    })
}

export function operatorMealAddbyICCID(params) {
    return request({
        url: '/account/meal/addByIccid.do?',
        method: 'post',
        data: params
    })
}

export function getAccountList(params) {
    return request({
        url: '/system/cardOperator/accountList.do?',
        method: 'post',
        data: params
    })
}

export function flowPoolList(params) {
    return request({
        url: '/account/flowPool/monitor/list.do?',
        method: 'post',
        data: params
    })
}
export function addflowPool(params) {
    return request({
        url: '/account/flowPool/monitor/add.do?',
        method: 'post',
        data: params
    })
}
export function updateflowPool(params) {
    return request({
        url: '/account/flowPool/monitor/update.do?',
        method: 'post',
        data: params
    })
}
export function copySupplier(params) {
    return request({
        url: '/system/cardOperator/copySupplier.do?',
        method: 'post',
        data: params
    })
}


export function exportExcelAsync(params) {
    return request({
        url: "/system/cardOperator/exportExcelAsync.do?",
        method: 'post',
        data: params
    })
}
// 













