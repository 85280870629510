<template>
	<div class=" packageSale fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">套餐销售记录</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;"  id="baseForm">
				<el-form-item label="套餐名称：">
					<el-input v-model="searchIteam.packageName" placeholder="套餐名称"></el-input>
				</el-form-item>
				<el-form-item label="运营商:">
					<el-select placeholder="运营商" v-model="searchIteam.currentOpera" filterable>
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item,index) in w_ACCOUNT_" :key="index" :label="item.groupName" :value="item.dictValue"></el-option>
					</el-select>

				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" class="themed-button" icon="el-icon-search">搜索查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="info" @click="exportExcel" class="themed-button" icon="el-icon-caret-left">导出excel</el-button>
				</el-form-item>
				<iframe name="hidden_down_iframe" id="hidden_down_iframe" style="display: none;"  src=""></iframe>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="createDatetime" label="统计时间">
				</el-table-column>
				<el-table-column prop="mealName" label="套餐名称">
				</el-table-column>
				<el-table-column prop="categoryStr" label="运营商">
				</el-table-column>
				<el-table-column label="套餐流量(G)">
					<template slot-scope="scope">
						<span>{{ parseFloat(scope.row.flowSize/1024).toFixed(0) }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="orderCount" label="本月订单数">
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import onresize from "@/mixins/resize.js"
	import loading from '@/utils/Loading.js'
	import {
		packageSaleRecordList, mealsaleExport
	} from "@/api/staticsManagement.js"
	import dictGroup from '@/mixins/dictGroup.js'

	export default {
		name:'packageSalesRecord',
		mixins: [onresize,dictGroup],
		data() {
			return {
				tabledata: [],
				searchIteam: {},

				page: 1,
				pageSize: 15,
				total: 0,
				exportBaseUrl :""

			}
		},
		created() {
			let params = {
             id: "",
             mealName: "",
             category: "",
			}
			this.getPackageSales(params)
		},
		methods: {
			getPackageSales(params) {
				/* 	let params = {
						id: "",
						mealName: "",
						category: "",
						pageSize: "",
						pageNo: ""
					} */
				packageSaleRecordList(params).then(res => {
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			onPageChange(page) {
				this.page = page
				let params = {
					pageNo: this.page,
					pageSize: this.pageSize,
					id: "",
					mealName: this.searchIteam.packageName,
					category: this.searchIteam.currentOpera,
				}
				this.getPackageSales(params)

			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				  id: "",
				  mealName: this.searchIteam.packageName,
				  category: this.searchIteam.currentOpera,
				}
				this.getPackageSales(params)
			},
			searchData() {
				let params = {
					id: "",
					mealName: this.searchIteam.packageName,
					category: this.searchIteam.currentOpera,
					pageSize: this.pageSize,
					pageNo: this.page
				}
				this.getPackageSales(params)
			},
			/* exportExcel() {
          let params ={
					id: "",
					mealName: this.searchIteam.packageName,
					category: this.searchIteam.currentOpera,
					}
					packageSaleExport(params).then(()=>{
						let mealName =  this.searchIteam.packageName == undefined? "":this.searchIteam.packageName
						let category = this.searchIteam.currentOpera == undefined? "":this.searchIteam.currentOpera
						let url = "https://iottest.sunshineiot.net/traffic-web/statistics/mealsale/export2excel.do?"+"id=&mealName="+mealName+"&category="+category;
						url = encodeURI(encodeURI(url));
						document.getElementById("hidden_down_iframe").src= url;  /* id=&mealName=%2525E5%25258F%252591%2525E7%25259A%252584&category=3  $('#baseForm').serialize() 序列化表单未实现 */
				/*	})
					
			} */
			
			exportExcel(){
							loading.show()
							let params ={
							id: "",
							mealName: this.searchIteam.packageName,
							category: this.searchIteam.currentOpera,
							}
							mealsaleExport(params).then(res=>{
								if (res.system_result_key == '0') {
									this.$alert('导出成功，请到导出记录中下载', '提示', {
									    confirmButtonText:'确定',
									}).then(()=>{
										this.$router.push('/tob/i_export_list')
									});
								}else{
									this.$message({
									    showClose: true,
									    message: res.system_result_message_key,
									    type: 'error'
									});
								}
							})
						},



		},
	}
</script>

<style>
	.el-icon-search {
		margin-right: 5px;
	}
 .packageSale .el-form-item__label {vertical-align: top;}
	.el-icon-caret-left {
		margin-right: 2px;
	}
</style>
