import request from "./request.js"
export function getTokenAddList(params) {
    return request({
		url:"account/push/list.do?",
        method: 'post',
        data: params
    })
		
}

export function addTokenAdd(params) {
    return request({
		url:"account/push/add.do?",
        method: 'post',
        data: params
    })
		
}

export function delTokenAddess(params) {
    return request({
		url:"account/push/deleteById.do?",
        method: 'post',
        data: params
    })
		
}
