<template>

<div class="form-add">
    <el-form label-position="right" label-width="200px">
        <el-form-item label="批次编号:">
            <el-select v-model="inCodeSpan">
                <el-option v-for="(item,index) in inCodeSpanList" :label="item.inCode" :value="item.inCode" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="上传卡号列表（excel）:" required>
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
            </div>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {listInCode,importDelete} from '@/api/stockManagement/stock.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    data(){
        return {
            inCodeSpan:'',
            inCodeSpanList:[]
        }
    },
    mounted(){
        this._listInCode()
    },
    methods:{
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('inCodeSpan',this.inCodeSpan)
                    importDelete(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.$emit('close-dialog')
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        importExcel(){
            let name = 'import.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        _listInCode(){
            listInCode().then((res)=>{
                console.log(res)
                this.inCodeSpanList = res.data
                this.inCodeSpan = this.inCodeSpanList[0]['inCode']
            })
        }
    }
}
</script>

