<template>
    <div class="freeTrafficStatistics fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">自由使用流量统计</span>
            <el-form inline ref="quaryForm" v-model="quaryForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="quaryForm.virtualIccid" placeholder="虚拟号" class="iccid-width"></el-input>
                </el-form-item>
                 <el-form-item>
                    <el-input v-model="quaryForm.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>

                <el-form-item label="运营商名称">
                    <el-select filterable v-model="quaryForm.accountId">
                        <el-option label="全部" value=""></el-option>
                        <!-- <el-option v-for="(item,index) in accountIdList" :key="index" :label="item.accountName" :value="item.id"></el-option> -->
						<el-option v-for="item in accountIdList" :key="item.id" :label="item.accountName"
							:value="item.id">
						</el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
               
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    label="虚拟号"
                    min-width="180"
                    prop="virtualIccid"
                    >
                </el-table-column>
                 <el-table-column
                    label="短号"
                    min-width="130"
                    prop="shortIccid"
                    />
                <el-table-column
                    label="运营商"
                    min-width="150"
                    prop="accountName"
                    >
                </el-table-column>
                
                <el-table-column
                    label="开始时间"
                    min-width="150"
                    show-overflow-tooltip
                    prop="startTime"
                    >
                </el-table-column>
                <el-table-column
                    label="统计结束时间"
                    min-width="150"
                    show-overflow-tooltip
                    prop="expireTime"
                    >
                </el-table-column>
              

                <el-table-column
                    label="使用流量(M)"
                    min-width="120"
                    show-overflow-tooltip
                    prop="usedFlow"
                    >
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    label="ESIM1"
                    min-width="230"
                    prop="iccidMark1"
                    >
                    <template slot-scope="scope">
                    {{getOperate(scope.row.iccidMark1)}}
                </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM2"
                    min-width="230"
                    prop="iccidMark2"
                    >
                <template slot-scope="scope">
                    {{getOperate(scope.row.iccidMark2)}}
                </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM3"
                    min-width="230"
                    prop="iccidMark3"
                    >
                    <template slot-scope="scope">
                    {{getOperate(scope.row.iccidMark3)}}
                </template>
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    label="USIM1"
                    min-width="230"
                    prop="iccidMark4"
                    >
                    <template slot-scope="scope">
                    {{getOperate(scope.row.iccidMark4)}}
                </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM1流量(M)"
                    min-width="120"
                    prop="usedFlow1"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM1流量百分比"
                    min-width="150"
                    prop="usedFlowPer1"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.usedFlowPer1">{{(scope.row.usedFlowPer1*100).toFixed(2)+'%'}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM2流量(M)"
                    min-width="120"
                    prop="usedFlow2"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM2流量百分比"
                    min-width="150"
                    prop="usedFlowPer2"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.usedFlowPer2">{{(scope.row.usedFlowPer2*100).toFixed(2)+'%'}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM3流量(M)"
                    min-width="120"
                    prop="usedFlow3"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM3流量百分比"
                    min-width="150"
                    prop="usedFlowPer3"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.usedFlowPer3">{{(scope.row.usedFlowPer3*100).toFixed(2)+'%'}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    label="USIM1流量(M)"
                    min-width="120"
                    prop="usedFlow4"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="USIM1流量百分比"
                    min-width="150"
                    prop="usedFlowPer4"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.usedFlowPer4">{{(scope.row.usedFlowPer4*100).toFixed(2)+'%'}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    show-overflow-tooltip
                    label="创建时间"
                    min-width="150"
                    >
                </el-table-column>
             
             
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {accountList,} from "@/api/agentManagement.js"
import { freeStaticsList} from "@/api/virtualCard.js"
export default {
    name:'freeTrafficStatistics',
    mixins:[resize],
    data(){
        return {
            quaryForm:{
                virtualIccid:'',
                accountId:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            accountIdList:[],
        }
    },
    created(){
        this.getList()
        this.getAccountList()
    },
    methods: {
          getOperate(iccid){
        let str = iccid.substring(0,6)
        let operator="广电"
            if(str=="898600"||str=="898602"||str=="898604"||str=="898607" ||str=="898608"){
              operator ="移动"
            }
            if(str=="898601"||str=="898606"||str=="898609"){
              operator ="联通"
            }
            if(str=="898603"||str=="898611"){
              operator ="电信"
            }
            if(!!iccid  && iccid.length>=19){
            return iccid+"("+operator+")"
            }else{
             return ""
            }
            
        },
        getAccountList(){
            accountList().then(res=>{
                // this.accountIdList = res
                this.accountIdList = res.filter(item => {
						return item.accountName.includes('虚拟')
					})
            })
        },
        getList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.quaryForm,params)
            freeStaticsList(this.quaryForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getList()
        },

        onSearch(){
            this.page=1
            this.getList()
        },


        
    },
}
</script>

<style>
    .freeTrafficStatistics .el-form-item__label{
        vertical-align: top;
    }
</style>