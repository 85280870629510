<template>
	<div class="rebateDetail">
			<el-table :data="tabledata" stripe style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="createTime" label="支付时间" min-width='150'>
				</el-table-column>
				<el-table-column prop="money" label="支付金额" min-width='100'>
				</el-table-column>
				<el-table-column prop="payType" label="支付方式" min-width='100'>
					<template slot-scope="scope">
						<span v-if="scope.row.payType == 1">微信</span>
						<span v-else-if="scope.row.payType == 3">充值至准备金</span>
						<span v-else-if="scope.row.payType == 4">支付宝</span>
						<span v-else-if="scope.row.payType == 5">银行卡</span>
						<span v-else-if="scope.row.payType == 6">其他</span>
					</template>
				</el-table-column>
				<el-table-column prop="result" label="支付结果" min-width='100'>
					<template slot-scope="scope">
						<span v-if="scope.row.result == '1'">成功</span>
						<span v-else-if="scope.row.result == '2'">失败</span>
					</template>
				</el-table-column>

				<el-table-column prop="mchName" label="商户号" min-width='120'>
				</el-table-column>

				<el-table-column label="凭证" min-width='100'>
					<template slot-scope="scope">
						<span v-if="scope.row.proof" @click="download(scope.row.proof)" style="color: rgb(51, 122, 183);">下载</span>
					</template>
				</el-table-column>

				<el-table-column prop="remark" label="备注" min-width='150' show-overflow-tooltip>
				</el-table-column>

				<el-table-column prop="selfRemark" label="备注2" min-width='150' show-overflow-tooltip>
				</el-table-column>

				<el-table-column label="操作" min-width='150'>
					<template slot-scope="scope">
						<el-button v-if="scope.row.result==2&&scope.row.payType==1" type="info" class="themed-button" @click="tryAgain(scope.row.id)">重试</el-button>
						<el-button v-if="scope.row.result==2&&scope.row.payType==1" type="primary" class="themed-button" @click="cancel(scope.row.id)">取消</el-button>
					</template>
            	</el-table-column>
				
			</el-table>
	</div>
</template>

<script>
	import resize from '@/mixins/resize.js'
	import {exportTemplate} from '@/api/statistics.js'
	import Utils from '@/utils/utils.js'
	import loading from "@/utils/Loading.js"
	import {getRebateDetail,downloadPingZ,wxRetry,wxCancel} from "@/api/payManagment.js"
	export default{
		name:'rebateDetail',
		mixins:[resize],
		props:["rebateNo"],
		data(){
			return {
				tabledata:[]
			}
		},
		created(){
			this.getRebateDetailList()
		},
		methods:{
			getRebateDetailList(){
				getRebateDetail({'rebateNo':this.rebateNo}).then(res=>{
					this.tabledata = res
				})
			},
			download(certPath){
					let name;
					let startIndex = certPath.lastIndexOf("/")+1;
					let endIndex = certPath.lastIndexOf(".");
					name = certPath.substring(startIndex,endIndex);
					downloadPingZ({certPath}).then(res=>{
               		const filename = name
                	Utils.createDownload(res,filename)
            })
			
			},
			tryAgain(id){
				wxRetry({id}).then(res=>{
					this.$message({
						type: 'success',
						message:'操作成功'
					});
					this.getRebateDetailList()
				})
			},

			cancel(id){
				wxCancel({id}).then(res=>{
					this.$message({
						type: 'success',
						message:'操作成功'
					});
					this.getRebateDetailList()
				})
			}


		}
	}
</script>

<style>
</style>
