<template>
  <div class="operatorLog fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">日志信息列表</span>
      <el-form
        :inline="true"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
        id="baseForm"
      >
        <el-form-item>
          <el-input
            v-model="searchIteam.operator"
            placeholder="操作人名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchIteam.title" placeholder="标题"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="block">
            <!-- <span class="demonstration"></span> -->
            <el-date-picker
              v-model="dateTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期" 
              @change="dateTimeChange"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            class="themed-button"
            icon="el-icon-search"
            >搜索查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="exportExcel"
            class="themed-button"
            icon="el-icon-caret-left"
            >导出excel</el-button
          >
        </el-form-item>
        
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        ref="table"
        :max-height="maxTableHeight"
        border
      >
        <el-table-column prop="operator" label="操作人"> </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="content" label="内容"> </el-table-column>
        <el-table-column prop="operateTime" label="操作时间"> </el-table-column>
     
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import {
  operatorLoglist,
  operatorlogExport,
} from "@/api/systemManagement.js"
export default {
  name: "operatorLog",
  mixins: [onresize],
  data() {
    return {
      searchIteam: {
        operator: "",
        title: "",
        startTime:"",
        endTime:""
      },
      page: 1,
      pageSize: 15,
      total: 0,
      tabledata: [],
      dateTime: [],
    };
  },
  created() {
    // let date =  new Date()
    // let year = date.getFullYear()
    // let month = date.getMonth()
    // let day = date.getDate()
    // this.dateTime.push(new Date(year, month, day,0,0,0))
    // this.dateTime.push(new Date(year, month, day,23,59,59))
    // this.searchIteam.startTime = new Date(year, month, day,0,0,0)
    // this.searchIteam.endTime = new Date(year, month, day,23,59,59)
    this.searchIteam.startTime=""
    this.searchIteam.endTime= ""
    this.getOperatorLoglist(this.searchIteam);
  },
  
  methods: {
	dateTimeChange(val){
   console.log(val)
   if(val){
  this.searchIteam.startTime =val[0]
    this.searchIteam.endTime = val[1]
   }else{
     this.searchIteam.startTime =""
    this.searchIteam.endTime = ""
   }
 
  },
    getOperatorLoglist() {
		let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.searchIteam);
      operatorLoglist(params).then((res) => {
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
   
    searchData() {
       this.page = 1
      this.getOperatorLoglist();
    },
 
    exportExcel() {
      loading.show();
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.searchIteam);
     operatorlogExport(params).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      
      this.getOperatorLoglist();
    },
    onPageChange(page) {
      this.page = page;
   
      this.getOperatorLoglist();
    },
  },
};
</script>

<style>
.operatorLog .el-form-item__label {
  vertical-align: top;
}
</style>
