<template>
    <div class="administrator-recharge-review fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">管理员充值审核</span>
            <el-form inline ref="selfRechargeForm" v-model="selfRechargeFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="代理商">
                    <el-select v-model="selfRechargeFormData.agentId" placeholder="请选择" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in agentIdList" :key='item.index' :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item>
                    <el-input v-model="selfRechargeFormData.orderNumber" placeholder="订单号"></el-input>
                </el-form-item>
                <el-form-item label="充值方式">
                    <el-select v-model="selfRechargeFormData.rechargeType" placeholder="请选择" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="微信" value="1"></el-option>
                        <el-option label="支付宝" value="2"></el-option>
                        <el-option label="银行卡" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker class="renew_datetimerange" placeholder="选择充值时间" v-model="effectiveDate" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
                </el-form-item>
                
                <el-form-item label="状态">
                    <el-select v-model="selfRechargeFormData.status" placeholder="全部" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已支付" value="1"></el-option>
                        <el-option label="未支付" value="2" ></el-option>
                        <el-option label="审核中" value="3" ></el-option>
                        <el-option label="审核不通过" value="4" ></el-option>
                    </el-select>
                </el-form-item>              
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>

            
        </div>
        <div class="content-box">
            <el-table
                ref='table'
                border
                stripe
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :max-height="maxTableHeight">
            <el-table-column
                prop="orderNumber"
                label="订单号"
                min-width="180"
                >
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="agentName"
                label="代理商"
                min-width="150"
                >
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    label="充值方式"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.rechargeType==1">微信</span>
                        <span v-else-if="scope.row.rechargeType==2">支付宝</span>
                        <span v-else-if="scope.row.rechargeType==3">银行卡</span>
                    </template>
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="money"
                    label="充值金额"
                    min-width="120">
            </el-table-column>
            
            <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="充值时间"
                    min-width="180">
            </el-table-column>

            <el-table-column
                    show-overflow-tooltip
                    label="状态"
                    min-width="120">
                    <template slot-scope="scope">
                       <span v-if="scope.row.status=='1'">已支付</span>
                       <span v-else-if="scope.row.status=='2'">未支付</span>
                       <span v-else-if="scope.row.status=='3'">审核中</span>
                       <span v-else-if="scope.row.status=='4'">审核不通过</span>
                    </template>
            </el-table-column>
            
            <el-table-column
                label="操作"
                min-width="150">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="primary" @click="handleShowDetail(scope.row)">详情</el-button>
                        <el-button v-if="scope.row.status==3" size="mini" class="themed-button" type="primary" @click="handleCheck(scope.row.id)">审核</el-button>
                    </template>
            </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog
        title="详情"
        :visible.sync="dialogDetailVisible"
        :destroy-on-close='true'
        >
        <el-row :gutter="20">
        <el-col :span="6"><div class="dialog-row-label">订单号:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.orderNumber}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="6"><div class="dialog-row-label">代理商:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.agentName}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="6"><div class="dialog-row-label">充值方式:</div></el-col>
        <el-col :span="18">
            <div v-if="detailInfo.rechargeType==1">微信</div>
            <div v-else-if="detailInfo.rechargeType==2">支付宝</div>
            <div v-else-if="detailInfo.rechargeType==3">银行卡</div>
        </el-col>
        </el-row>

        <el-row :gutter="20" v-if="detailInfo.rechargeType==3">
        <el-col :span="6"><div class="dialog-row-label">支付人:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.bankPayer}}</div></el-col>
        </el-row>
        <el-row :gutter="20" v-if="detailInfo.rechargeType==3">
        <el-col :span="6"><div class="dialog-row-label">支付账号:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.bankCode}}</div></el-col>
        </el-row>
        <el-row :gutter="20" v-if="detailInfo.rechargeType==3">
        <el-col :span="6"><div class="dialog-row-label">支付凭证:</div></el-col>
        <el-col :span="18">
            <!-- <div>{{detailInfo.payImage}}</div> -->
            <div>
                <img style="width:100%" :src="detailInfo.payImage" alt="">
            </div>
        </el-col>
        </el-row>

        <el-row :gutter="20">
        <el-col :span="6"><div class="dialog-row-label">充值金额:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.money}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="6"><div class="dialog-row-label">充值时间:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.createTime}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="6"><div class="dialog-row-label">当前状态:</div></el-col>
        <el-col :span="18"><div v-if="detailInfo.status==1">已支付</div>
            <div v-else-if="detailInfo.status==2">未支付</div>
            <div v-else-if="detailInfo.status==3">审核中</div>
            <div v-else-if="detailInfo.status==4">审核不通过</div>
        </el-col>
        </el-row>
        <el-row :gutter="20" v-if="detailInfo.proofUrl">
        <el-col :span="6"><div class="dialog-row-label">下载凭证:</div></el-col>
        <el-col :span="18"><div><span v-if="detailInfo.proofUrl" @click="download(detailInfo.proofUrl)" style="color: rgb(51, 122, 183);cursor:pointer">点击下载</span></div></el-col>
        </el-row>

        <el-row :gutter="20" v-if='detailInfo.accountNumber'>
        <el-col :span="6"><div class="dialog-row-label">账号:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.accountNumber}}</div></el-col>
        </el-row>

        <el-row :gutter="20" v-if='detailInfo.transactionId'>
        <el-col :span="6"><div class="dialog-row-label">转账单号:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.transactionId}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="6"><div class="dialog-row-label">备注:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.remark}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="6"><div class="dialog-row-label">审核备注:</div></el-col>
        <el-col :span="18"><div>{{detailInfo.auditRemark}}</div></el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button"  @click="dialogDetailVisible = false">关闭</el-button>
        </span>
        </el-dialog>

        <el-dialog title="审核" :visible.sync="checkDialog" destroy-on-close>
            <el-form label-position="right" label-width="120px" :model="checkForm"  ref="checkForm">
                <el-form-item label="状态">
                        <el-select v-model="checkForm.type" placeholder="请选择" filterable>
                            <el-option label="通过" value="1"></el-option>
                            <el-option label="不通过" value="2"></el-option>
                        </el-select>
                </el-form-item>      
                <el-form-item label="备注">
                        <el-input v-model="checkForm.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                    <el-button class="themed-button" type="info" @click="sureCheck('checkForm')">确 定</el-button>
                    <el-button class="themed-button" type="primary" @click="checkDialog = false">关 闭</el-button>
                </div>
        </el-dialog>


    </div>
</template>
<script>
import {getAgentList,agentRechargeList,exportSelfRecharge,checkSelfRecharge} from "@/api/agentManagement.js"
import resize from '@/mixins/resize.js'
export default {
    name:'administratorRechargeReview',
    mixins:[resize],
    data(){
        return{
            agentIdList:[],
            selfRechargeFormData:{
                agentId:'',
                orderNumber:'',
                status:'',
                startTime:'',
                endtTime:'',
                rechargeType:''
            },
            effectiveDate:'',
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogDetailVisible:false,
            detailInfo:{
                orderNumber:'',
                agentName:'',
                money:'',
                rechargeType:'',
                createTime:'',
                status:'',
                proofUrl:'',
                remark:'',
                auditRemark:'',
            },
            checkDialog:false,
            checkForm:{
                id:'',
                type:'',
                remark:''
            }
        }
    },
    watch:{
        effectiveDate(value){
            this.selfRechargeFormData.startTime = value == null ? "" : value[0]
            this.selfRechargeFormData.endtTime = value == null ? "" : value[1]
        }
    },
    created(){
        this.getAgentList()
        this.getSelfRechargeList()
    },
    methods:{
        getAgentList(){
            getAgentList().then(res=>{
				this.agentIdList = res
			})
        },
        getSelfRechargeList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.selfRechargeFormData,params)
            agentRechargeList(this.selfRechargeFormData).then(res=>{
                this.tableData = res.rows
                this.total= res.count
            })
        },
        onSearch(){
            this.page = 1
            this.getSelfRechargeList()
        },
        exportExcel(){
            exportSelfRecharge(this.selfRechargeFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        onPageChange(page){
			this.page = page
			this.getSelfRechargeList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSelfRechargeList()
        },
        handleShowDetail(data){
            this.dialogDetailVisible = true
                this.detailInfo.orderNumber = data.orderNumber
                this.detailInfo.agentName = data.agentName
                this.detailInfo.money = data.money
                this.detailInfo.rechargeType = data.rechargeType
                this.detailInfo.createTime = data.createTime
                this.detailInfo.status = data.status
                this.detailInfo.proofUrl = data.proofUrl
                this.detailInfo.remark = data.remark
                this.detailInfo.auditRemark = data.auditRemark 
                this.detailInfo.accountNumber = data.accountNumber
                this.detailInfo.transactionId = data.transactionId       
                this.detailInfo.bankPayer = data.bankPayer       
                this.detailInfo.bankCode = data.bankCode       
                this.detailInfo.payImage = data.payImage       
        },
        handleCheck(id){
            this.checkDialog = true
            this.checkForm.id= id
            this.checkForm.type=''
            this.checkForm.remark = ''
        },

        sureCheck(formName){
            this.$confirm('确定此操作吗？','消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                checkSelfRecharge(this.checkForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '操作成功',
					        type: 'success',
							center: true
                        })
                        this.$refs[formName].resetFields()
                        this.checkDialog=false
                        this.getSelfRechargeList() 
                })
            }).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
			});
        },
        download(url){
            window.location.href = url
        }
    }
}
</script>
<style>
    .administrator-recharge-review .el-form-item__label{
        vertical-align: top;
    }
    .administrator-recharge-review .dialog-row-label{
        text-align: right;
    }
    .administrator-recharge-review .el-range-editor--small.el-input__inner{height:26px;}
.administrator-recharge-review .el-range-editor.el-input__inner{padding:0 15px;}
/* .renew_datetimerange {margin-top:0px;} */
</style>