<template>

<div class="form-update">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="批次编号:">
            <el-input v-model="form.inCode"  readonly></el-input>
        </el-form-item>
        <el-form-item label="产品名称:" required>
            <el-select v-model="form.productName">
                <el-option v-for="(item,index) in productNameList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="业务类型:" required>
            <el-select v-model="form.businessType">
                <el-option v-for="(item,index) in businessTypeList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="产品类型:" required>
            <el-select v-model="form.productType">
                <el-option v-for="(item,index) in productTypeList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="产品制式:" required>
            <el-select v-model="form.productStandard">
                <el-option v-for="(item,index) in productStandardList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="产品功能:" required>
            <el-select v-model="form.productAbility">
                <el-option v-for="(item,index) in productAbilityList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="产品分组:" required>
            <el-select v-model="form.productGroup">
                <el-option v-for="(item,index) in productGroupList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="仓库类别:" required>
            <el-select v-model="form.stockHouse">
                <el-option v-for="(item,index) in stockHouseList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="备注:" prop="remark">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="采购时间:" prop='purchaseTime' required>
            <el-date-picker
                v-model="form.purchaseTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="入库时间:" prop="stockTime" required>
            <el-date-picker
                v-model="form.stockTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="开户时间:" prop="createAccountTime" required>
            <el-date-picker
                v-model="form.createAccountTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="卡测试到期时间:" prop="testExpireTime" required>
            <el-date-picker
                v-model="form.testExpireTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="卡库存到期时间:" prop="stockExpireTime" required>
            <el-date-picker
                v-model="form.stockExpireTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="库存期时长:" prop="stockTerm" required>
            <el-input v-model="form.stockTerm"></el-input>
        </el-form-item>
        <el-form-item label="卡板费:" prop="cardFee" required>
            <el-input v-model="form.cardFee"></el-input>
        </el-form-item>
        <el-form-item label="续费单价:" prop="renewPrice" required>
            <el-input v-model="form.renewPrice"></el-input>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="update('form')">修改</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">关闭</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {findById,importUpdate} from '@/api/stockManagement/stock.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    props:['id'],
    data(){
        var checkRemark = (rule, value, callback) => {
            if (value.length>32) {
                return callback(new Error('备注不能大于32个字符'));
            }else{
                callback()
            }
            
        };
        return {
            form:{
                id:'',
                flowNumber:'',
                inCode:'',
                productName:'',
                businessType:'',
                productType:'',
                productStandard:'',
                productAbility:'',
                productGroup:'',
                stockHouse:'',
                remark:'',
                purchaseTime:'',
                stockTime:'',
                createAccountTime:'',
                testExpireTime:'',
                stockExpireTime:'',
                stockTerm:'',
                cardFee:'',
                renewPrice:''
            },
            dictGroup:{},
            productNameList:[],
            businessTypeList:[],
            productTypeList:[],
            productStandardList:[],
            productAbilityList:[],
            productGroupList:[],
            stockHouseList:[],
            rules: {
                stockTerm: [
                    { required: true, message: '请输入库存期时长', trigger: 'blur' }
                ],
                cardFee: [
                    { required: true, message: '请输入卡板费', trigger: 'blur' }
                ],
                renewPrice: [
                    { required: true, message: '请输入续费单价', trigger: 'blur' }
                ],
                purchaseTime: [
                    { required: true, message: '请选择采购时间', trigger: 'blur' }
                ],
                stockTime: [
                    { required: true, message: '请选择业务类型', trigger: 'blur' }
                ],
                saleType: [
                    { required: true, message: '请选择入库时间', trigger: 'blur' }
                ],
                createAccountTime: [
                    { required: true, message: '请选择开户时间', trigger: 'blur' }
                ],
                testExpireTime: [
                    { required: true, message: '请选择卡测试到期时间', trigger: 'blur' }
                ],  
                stockExpireTime: [
                    { required: true, message: '请选择卡库存到期时间', trigger: 'blur' }
                ],
                remark: [
                    { validator: checkRemark, trigger: 'blur' }
                ]
            },
        }
    },
    mounted(){
        this.form.id = this.id
        this._findById()
    },
    methods:{
        update(formName){
            confirmBox(this,'确定提交修改吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    let parames = {}
                    Object.assign(parames,this.form)
                    parames.purchaseTime = this.changeDateFormat(this.form.purchaseTime)
                    parames.stockTime = this.changeDateFormat(this.form.stockTime)
                    parames.createAccountTime = this.changeDateFormat(this.form.createAccountTime)
                    parames.testExpireTime = this.changeDateFormat(this.form.testExpireTime)
                    parames.stockExpireTime = this.changeDateFormat(this.form.stockExpireTime)
                    importUpdate(parames).then(res=>{
                        if(res.slide_msg_key == 1){
                            messageBox(this,res.slide_msg_message_key)
                        }
                        this.cancle()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        importExcel(){
            let name = 'import.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        _findById(){
            findById(this.form).then((res)=>{
                console.log(res)
                this.dictGroup = res.dict
                this.productNameList = res.dict.productName
                this.businessTypeList = res.dict.businessType
                this.productTypeList = res.dict.productType
                this.productStandardList = res.dict.productStandard
                this.productAbilityList = res.dict.productAbility
                this.productGroupList = res.dict.productGroup
                this.stockHouseList = res.dict.stockHouse

                this.form.inCode = res.entity.inCode
                this.form.productName = res.entity.productName
                this.form.businessType = res.entity.businessType+''
                this.form.productType = res.entity.productType+''
                this.form.productStandard = res.entity.productStandard+''
                this.form.productAbility = res.entity.productAbility+''
                this.form.productGroup = res.entity.productGroup
                this.form.stockHouse = res.entity.stockHouse+''
                this.form.remark = res.entity.remark
                this.form.purchaseTime = res.entity.purchaseTime
                this.form.stockTime = res.entity.stockTime
                this.form.createAccountTime = res.entity.createAccountTime
                this.form.testExpireTime = res.entity.testExpireTime
                this.form.stockExpireTime = res.entity.stockExpireTime
                this.form.stockTerm = res.entity.stockTerm
                this.form.cardFee = res.entity.cardFee
                this.form.renewPrice = res.entity.renewPrice
            })
        },
        changeDateFormat(value){
            let arr = value.split('-')
            return arr[1]+'/'+arr['2']+'/'+arr[0]
        }
    }
}
</script>

