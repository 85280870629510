<template>
  <div class="goodList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">商品列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item >
						<el-input v-model="formInline.productName" placeholder="名称"></el-input>
					</el-form-item>
                       <el-form-item >
                    <el-select v-model="formInline.status" placeholder="状态" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="上架" value="1"></el-option>
                         <el-option label="下架" value="2"></el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item >
                    <el-select v-model="formInline.mealType" placeholder="套餐类型" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="按月" value="1"></el-option>
                         <el-option label="累计" value="2"></el-option>
                    </el-select>
                </el-form-item>
             
        <el-form-item>
          <el-select
            v-model="formInline.cardType"
            placeholder="卡类型"
            filterable
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="普通物联网卡" value="1"></el-option>
            <!-- <el-option label="NBIOT卡" value="2"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="add"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="productName" label="商品名称" min-width="180">
        </el-table-column>
        <el-table-column prop="fee" label="费用" min-width="180">
        </el-table-column>
        <el-table-column show-overflow-tooltip label="商品状态" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">上架</span>
            <span v-else-if="scope.row.status == 2">下架</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="套餐类型" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.mealType == 1">按月</span>
            <span v-else-if="scope.row.mealType == 2">累计</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="cardType"
          label="卡类型"
          min-width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.cardType == 1">普通物联网卡</span>
            <span v-else-if="scope.row.cardType == 2">NBIOT卡</span>
          </template>
        </el-table-column>
        <el-table-column prop="mealSize" label="套餐规格(M)" min-width="180">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="accountType"
          label="运营商"
          min-width="180"
        >
          <template slot-scope="scope">
            <span>{{ categoryString(scope.row.accountType) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="choiceId"
          label="支持选项列表"
          min-width="180"
        >
          <template slot-scope="scope">
            <span>{{ getChoiceName(scope.row.choiceId) }}</span>
          </template>
        </el-table-column>
 <el-table-column prop="createTime" label="创建时间" min-width="180">
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              @click="edit(scope.row)"
              >编辑</el-button
            >

            <el-button v-if="scope.row.status == 2"
              type="danger"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
      <el-dialog
        title="新增商品"
        :visible.sync="dialogAddGood"
        v-if="dialogAddGood"
      >
        <add-good
          @close="dialogAddGood = false"
          @refresh="refreshData"
        >
        </add-good>
      </el-dialog>
      <el-dialog title="编辑商品" :visible.sync="dialogFormVisible">
        <el-form :model="editForm" :rules="rules" ref="editForm">
          <el-form-item
            label="商品名称:"
            :label-width="formLabelWidth"
            prop="productName"
          >
            <el-input
              v-model="editForm.productName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="单价:" :label-width="formLabelWidth" prop="fee">
            <el-input v-model="editForm.fee" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="套餐类型:"
            :label-width="formLabelWidth"
            prop="mealType"
          >
            <el-select v-model="editForm.mealType">
              <el-option label="按月" value="1"></el-option>
              <el-option label="累计" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="套餐规格:(M)"
            :label-width="formLabelWidth"
            prop="mealSize"
          >
            <el-input v-model="editForm.mealSize" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="套餐月数:(月)"
            :label-width="formLabelWidth"
            prop="mealCount"
          >
            <el-select v-model="editForm.mealCount">
              <el-option label="1" value="1"></el-option>
              <el-option label="3" value="3"></el-option>
              <el-option label="6" value="6"></el-option>
              <el-option label="12" value="12"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="状态:"
            :label-width="formLabelWidth"
            prop="status"
          >
            <el-select v-model="editForm.status">
              <el-option label="上架" value="1"></el-option>
              <el-option label="下架" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="类型:"
            :label-width="formLabelWidth"
            prop="cardType"
          >
            <el-select v-model="editForm.cardType">
              <el-option label="普通物联网卡" value="1"></el-option>
              <!-- <el-option label="NBIOT卡" value="2"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item
            label="运营商:"
            prop="accountType"
            :label-width="formLabelWidth"
          >
            <el-select v-model="editForm.accountType" @change="changeAccount">
              <el-option
                v-for="(item, index) in categorys"
                :label="item.name"
                :value="item.value"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="支持选项列表:"
            :label-width="formLabelWidth"
            prop="choiceId"
          >
            <el-select v-model="editForm.choiceId">
              <el-option
                v-for="(item, index) in filterSupportList"
                :label="item.name"
                :value="item.value"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureEdit('editForm')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import addGood from "./goodList/addGood.vue";
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import {
  getGoodList,
  deleteGoodinfo,
  editGoodinfo,
  supportList,
} from "@/api/goodList.js";
import { mapGetters } from "vuex";
import dictGroup from "@/mixins/dictGroup.js";
export default {
  name: "goodList",
  mixins: [resize],
  data() {
    var validatefee = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入商品费用"));
      } else {
        if (!/^\d+(\.\d{0,2})?$/.test(value)) {
          callback(new Error("商品费用只能为数字最多保留2位小数"));
        }
          if (value>99999) {
          callback(new Error("商品费用不能大于99999"));
        }
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请输入商品名称！"));
      } else {
        callback();
      }
    };
    var validateMealSize = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("套餐规格不能为空"));
      } else {
        if (!/^[1-9]\d*$/.test(value)) {
          callback(new Error("请输入大于0的正整数"));
        }
        callback();
      }
    };
    return {
      formLabelWidth: "120px",
      productLists: [],
      agentLists: [],
      dialogBox: false,
      formInline: {
        cardType: "",
        mealType:"",
        status:"",
        productName: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      dialogAddGood: false,
      dialogFormVisible: false,
      editForm: {
        id: "",
        productName: "",
        fee: "",
        status: "1",
        cardType: "1",
        choiceId: "",
        accountType: "",
        mealSize: "",
        mealType: "",
        mealCount: "",
      },
      rules: {
        productName: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        fee: [
          {
            required: true,
            validator: validatefee,
            trigger: "blur",
          },
        ],
        accountType: [
          {
            required: true,
            message: "运营商不能为空",
            trigger: "blur",
          },
        ],
        mealSize: [
          {
            required: true,
            validator: validateMealSize,
            trigger: "blur",
          },
        ],
      },
      supportList: [],
      filterSupportList:[],
    };
  },
  computed: {
    ...mapGetters(["categorys"]),
  },
  created() {
    if (store.getters.categorys.length < 1) {
      store.dispatch("getCategory");
    }
    this.getSupportGood();
    this.getGoodList();
  },
  methods: {
    getChoiceName(id) {
      let choiceName = "";
      this.supportList.forEach((item) => {
        if (item.value == id) {
          choiceName = item.name;
        }
      });
      return choiceName;
    },
    changeAccount(val,num){
      this.filterSupportList=[]
      if(num===1){
      
      }else{
       this.editForm.choiceId =""
      }
      
       supportList({accountType:val}).then((res) => {
        res.forEach((item) => {
          this.filterSupportList.push({ value: item.id, name: item.name });
        });

        // console.log(this.filterSupportList);
      });
    },
    getSupportGood() {
      supportList({}).then((res) => {
        res.forEach((item) => {
          this.supportList.push({ value: item.id, name: item.name });
        });

        console.log(this.supportList);
      });
    },

    categoryString(category) {
      let cuCatagorys;
      if (store.getters.categorys.length > 0) {
        store.getters.categorys.forEach((item) => {
          if (category == item.value) {
            cuCatagorys = item.name;
          }
        });
      }
      return cuCatagorys;
    },
    searchData() {
      this.page = 1;
      this.getGoodList();
    },
    getGoodList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      let paramsOne = {};
      if (this.formInline.cardType !== "") {
        paramsOne.cardType = this.formInline.cardType;
      }
      if (this.formInline.mealType !== "") {
        paramsOne.mealType = this.formInline.mealType;
      }
      if (this.formInline.status !== "") {
        paramsOne.status = this.formInline.status;
      }
      if (this.formInline.productName !== "") {
        paramsOne.productName = this.formInline.productName;
      }

      Object.assign(paramsOne, params);
      getGoodList(paramsOne).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onPageChange(page) {
      this.page = page;
      this.getGoodList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getGoodList();
    },
    add() {
      
      if(this.supportList.length==0){
          this.$alert('请先去支持商品选项列表新增支持选项', '提示', {
								confirmButtonText: '确定',
							});
              return 
      }
      this.dialogAddGood = true;
    },
    refreshData() {
      this.getGoodList();
    },
    delect(id) {
      this.$confirm("是否确认删除该商品?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteGoodinfo({ id }).then((res) => {
          this.getGoodList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
    edit(row) {
      this.dialogFormVisible = true;
      this.editForm.productName = row.productName;
      this.editForm.fee = row.fee;
      this.editForm.status = row.status + "";
      this.editForm.cardType = row.cardType + "";
      this.editForm.id = row.id;
      this.editForm.choiceId = parseInt(row.choiceId);
      this.editForm.accountType = row.accountType + "";
      this.editForm.mealType = row.mealType + "";
      this.editForm.mealSize = row.mealSize;
      this.editForm.mealCount = row.mealCount;
      this.changeAccount(this.editForm.accountType,1)
    },
    sureEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editGoodinfo(this.editForm).then((responseResult) => {
            if (responseResult.slide_msg_key == 1) {
              this.$message({
                showClose: true,
                message: responseResult.slide_msg_message_key,
                type: "warning",
              });
            }
            this.getGoodList();
            this.dialogFormVisible = false;
          });
        } else {
          return false;
        }
      });
    },
  },
  components: {
    addGood,
  },
};
</script>

<style>
</style>
