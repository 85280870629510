<template>

<div class="form-add">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="库编号:">
            <el-input v-model="form.imeiBankCode" readonly></el-input>
        </el-form-item>
        <el-form-item label="产品名称:" prop="productName" required>
            <el-input v-model="form.productName"></el-input>
        </el-form-item>
        <el-form-item label="客户:" prop="customerName" required>
            <el-input v-model="form.customerName"></el-input>
        </el-form-item>
        <el-form-item label="数量:" prop="amount" required>
            <el-input v-model="form.amount"></el-input>
        </el-form-item>
        <el-form-item label="需要绑定:">
            <el-select filterable v-model="form.needBind">
                <el-option label="需要绑定" value="1"></el-option>
                <el-option label="不需要绑定" value="0"></el-option>
            </el-select>
        </el-form-item>
      
        <el-form-item label="上传IMEI文件（excel）:">
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
            </div>
        </el-form-item>
         <el-form-item label="代理商">
                <el-select v-model="form.customerId" filterable @change="changeCustom">
                    <el-option v-for="(item,index) in agentList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
        </el-form-item>
           <el-form-item
          label="是否推送"
          prop="needPush"
        >
          <el-radio-group v-model="form.needPush">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel('form')">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {getList4agent,addSync,findById,getNewImeiBankCode} from '@/api/meCardBindManagement/imeiLibrary.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    data(){
        return {
            form:{
                imeiBankCode:'',
                productName:'',
                amount:'',
                needBind:'1',
                remark:'',
                customerName:'',
                customerId:'',
                agentId:"",
                agentName:"",
                needPush:0,
            },
            agentList:[],
            rules: {
                productName: [
                    { required: true, message: '请选择产品名称', trigger: 'blur' }
                ],
                amount: [
                    { required: true, message: '请选择数量', trigger: 'blur' }
                ],
                customerName: [
                    { required: true, message: '请填写客户名称', trigger: 'blur' }
                ]
            },
        }
    },
    mounted(){
        this._findById()
        this._getNewImeiBankCode()
        this.getAgentList()
    },
    methods:{
        changeCustom(e){
        console.log(e)
        this.form.agentId = e
        this.agentList.forEach(item=>{
            if(item.id == e){
            this.form.agentName = item.name
            }
        })
        },
        getAgentList(){
            getList4agent().then(res=>{
                this.agentList = res
            })
        },
        uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = new FormData()
                    if(file){
                        params.append('fileurl',file)
                    }
                    
                    params.append('imeiBankCode',this.form.imeiBankCode)
                    params.append('productName',this.form.productName)
                    params.append('amount',this.form.amount)
                    params.append('needBind',this.form.needBind)
                    params.append('remark',this.form.remark)
                    params.append('customerName',this.form.customerName)
                    params.append('customerId',this.form.customerId)
                    params.append('agentId',this.form.agentId)
                    params.append('agentName',this.form.agentName)
                     params.append('needPush',this.form.needPush)
                    addSync(params).then(res=>{
                        console.log(res,155)
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.$emit('close-dialog')
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        importExcel(){
            let name = 'importImei2.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        _findById(){
            findById(this.form).then((res)=>{
                console.log(res)
            })
        },
        _getNewImeiBankCode(){
            getNewImeiBankCode(this.form).then((res)=>{
                this.form.imeiBankCode = res
            })
        }
    }
}
</script>

