<template>
	<div class="subscribeAgent">
		<el-form :model="form" ref="ruleFormAdd">
			<el-form-item label="代理商:" required :label-width="formLabelWidth">
				<el-select v-model="form.agentId" filterable placeholder="">
					<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="baseFormSubmit" class="themed-button">确 定</el-button>
			<el-button type="primary" @click="baseFormReset('ruleFormAdd')" class="themed-button">重 置</el-button>
			<el-button type="info" @click="cancelDialog" class="themed-button">取 消</el-button>
		</div>
	</div>
</template>

<script>
	import {
		getAllUnAssAgent,
		assAgent
	} from "@/api/payManagment.js"
	export default {
		name:'subscribeAgent',
		props: ["openId"],
		data() {
			return {
				form: {
					agentId: "",
					openid: 0,
				},
				options: [],
				formLabelWidth:"100px",
			}
		},
		created() {
			this.getAllUnAssAgentList(this.form)
		},
		methods: {
			getAllUnAssAgentList(params) {
				getAllUnAssAgent(params).then(res => {
					this.options = res
				})
			},
			baseFormSubmit() {
				if (null == this.form.agentId || "" == this.form.agentId || this.form.agentId == 0) {
					this.$alert('请选择代理商', '系统提示', {
						confirmButtonText: '确定',
					});
					return
				}

				this.form.openid = this.openId
				assAgent(this.form).then(res => {
					if (res.slide_msg_key == 1) {
						this.$alert(res.slide_msg_message_key, "消息提示")
						return;
					}
					this.$emit("reload")
					this.$emit("close")
				})
			},
			baseFormReset(form) {
				this.form.agentId = ""
			},
			cancelDialog() {
				this.$emit("close")
			}
		}

	}
</script>

<style>
	.subscribeAgent .dialog-footer{
		text-align: right;
	}
</style>
