<template>
  <el-form :model="formData" :rules="rules" ref="dataForm">
    <el-form-item label="配置名:" prop="name" :label-width="formLabelWidth">
      <el-input v-model="formData.name" autocomplete="off"></el-input>
    </el-form-item>
    <div>
      <el-row style="margin: 20px 0">
        <el-col :span="12">
          <span>优先级规则:</span>
        </el-col>
      </el-row>

      <el-form-item
        label="第一优先级:"
        prop="first"
        :label-width="formLabelWidth"
      >
        <el-select
          v-model="jsonObject.first"
          size="mini"
          style="width: 80%"
          clearable
        >
          <el-option label="移动" value="中国移动"></el-option>
          <el-option label="电信" value="中国电信"></el-option>
          <el-option label="联通" value="中国联通"></el-option>
          <el-option label="广电" value="中国广电"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="第二优先级:"
        prop="second"
        :label-width="formLabelWidth"
      >
        <el-select
          v-model="jsonObject.second"
          size="mini"
          style="width: 80%"
          clearable
        >
          <el-option label="移动" value="中国移动"></el-option>
          <el-option label="电信" value="中国电信"></el-option>
          <el-option label="联通" value="中国联通"></el-option>
          <el-option label="广电" value="中国广电"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="第三优先级:"
        prop="third"
        :label-width="formLabelWidth"
      >
        <el-select
          v-model="jsonObject.third"
          size="mini"
          style="width: 80%"
          clearable
        >
          <el-option label="移动" value="中国移动"></el-option>
          <el-option label="电信" value="中国电信"></el-option>
          <el-option label="联通" value="中国联通"></el-option>
          <el-option label="广电" value="中国广电"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="第四优先级:"
        prop="fourth"
        :label-width="formLabelWidth"
      >
        <el-select
          v-model="jsonObject.fourth"
          size="mini"
          style="width: 80%"
          clearable
        >
          <el-option label="移动" value="中国移动"></el-option>
          <el-option label="电信" value="中国电信"></el-option>
          <el-option label="联通" value="中国联通"></el-option>
          <el-option label="广电" value="中国广电"></el-option>
        </el-select>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { uploadPayOfflineUrl } from "@/api/agentManagement.js";
export default {
  name: "realName",
  props: ["initData"],
  watch: {
    jsonObject: {
      handler(newVal, oldVal) {
        this.formData.jsonStr = JSON.stringify(newVal);
      },
      deep: true,
    },
    initData: function (newVal, oldVal) {
      this.formData = newVal;
      this.jsonObject = JSON.parse(newVal.jsonStr);
    },
  },
  data() {
    return {
      formData: this.initData,
      jsonObject: this.initDataMethod(this.initData),
      formLabelWidth: "110px",
      rules: {
        name: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    initDataMethod(data) {
      console.log(data);
      if (
        data.jsonStr == undefined ||
        data.jsonStr == "" ||
        data.jsonStr == "{}"
      ) {
        console.log("1");
        return {
         first:"",
         second:"",
         third:"",
         fourth:"",
        };
      } else {
        console.log("2");
        return JSON.parse(data.jsonStr);
      }
    },
  },
};
</script>

<style scoped>
</style>