<template>
    <diV class="doka-statistice">
        <el-row :gutter='20'>
            <el-col :span='20'>
                <div>
                    <div class="title-box"><span class="m-title">首月平均充值金额</span></div>
                    <div class="chose-item">
                        <el-form inline style="height: auto;position:relative">
                            <el-form-item label="条件选择:" style="margin-right:50px">
							</el-form-item>
                            <el-form-item>
                                <el-select v-model="form1.month"  filterable placeholder="统计时间" style="width:150px">
                                   <el-option label="最近1月" value="1"></el-option >
                                    <el-option label="最近3月" value="3"></el-option>
                                    <el-option label="最近6月" value="6"></el-option>
                                    <el-option label="最近12月" value="12"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="form1.accountId" clearable filterable placeholder="运营商" style="width:150px" @change="changeAccount1">
                                    <el-option v-for="(item,index) in accountList" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>
                            <el-form-item>
                                <el-select v-model="form1.agentId" clearable filterable placeholder="代理商" style="width:150px">
                                    <el-option v-for="(item,index) in agentIdList1" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button @click="showTable1" size="mini" class="themed-button" type="danger">确 定</el-button>
                            </el-form-item>

                            <el-form-item label="汇总:" style="position:absolute;right:10px;top:0">
                                <el-input v-model="sum1" readonly style="width:100px"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div ref="chart1" style="height:600px;background-color: #fff;"></div>
                </div>
            </el-col>
            <el-col :span='4'>

            </el-col>
        </el-row>

         <el-row :gutter='20'>
            <el-col :span='20'>
                <div>
                    <div class="title-box"><span class="m-title">首月平均充值月数</span></div>
                    <div class="chose-item">
                        <el-form inline style="height: auto;position:relative">
                            <el-form-item label="条件选择:" style="margin-right:50px">
							</el-form-item>
                            <el-form-item>
                                <el-select v-model="form2.month"  filterable placeholder="统计时间" style="width:150px">
                                   <el-option label="最近1月" value="1"></el-option >
                                    <el-option label="最近3月" value="3"></el-option>
                                    <el-option label="最近6月" value="6"></el-option>
                                    <el-option label="最近12月" value="12"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form2.accountId" filterable placeholder="运营商" style="width:150px" @change="changeAccount2">
                                    <el-option v-for="(item,index) in accountList" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>

                            <el-form-item>
                                <el-select v-model="form2.agentId" clearable filterable placeholder="代理商" style="width:150px">
                                    <el-option v-for="(item,index) in agentIdList2" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button @click="showTable2" size="mini" class="themed-button" type="danger">确 定</el-button>
                            </el-form-item>

                            <el-form-item label="汇总:" style="position:absolute;right:10px;top:0">
                                <el-input v-model="sum2" readonly style="width:100px"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div ref="chart2" style="height:600px;background-color: #fff;"></div>
                </div>
            </el-col>
            <el-col :span='4'>

            </el-col>
        </el-row>

        <el-row :gutter='20'>
            <el-col :span='20'>
                <div>
                    <div class="title-box"><span class="m-title">平均在网月数</span></div>
                    <div class="chose-item">
                        <el-form inline style="height: auto;">
                            <el-form-item label="条件选择:" style="margin-right:50px">
							</el-form-item>
                            <el-form-item>
                                <el-select v-model="form3.month"  filterable placeholder="统计时间" style="width:150px">
                                    <el-option label="最近1月" value="1"></el-option >
                                    <el-option label="最近3月" value="3"></el-option>
                                    <el-option label="最近6月" value="6"></el-option>
                                    <el-option label="最近12月" value="12"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="form3.accountId" clearable filterable placeholder="运营商" style="width:150px" @change="changeAccount3">
                                    <el-option v-for="(item,index) in accountList" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>
                            <el-form-item>
                                <el-select v-model="form3.agentId" clearable filterable placeholder="代理商" style="width:150px">
                                    <el-option v-for="(item,index) in agentIdList3" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button @click="showTable3" size="mini" class="themed-button" type="danger">确 定</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div ref="chart3" style="height:600px;background-color: #fff;"></div>
                </div>
            </el-col>
            <el-col :span='4'>

            </el-col>
        </el-row>


        <el-row :gutter='20'>
            <el-col :span='20'>
                <div>
                    <div class="title-box"><span class="m-title">平均总充值金额</span></div>
                    <div class="chose-item">
                        <el-form inline style="height: auto;">
                            <el-form-item label="条件选择:" style="margin-right:50px">
							</el-form-item>
                            <el-form-item>
                                <el-select v-model="form4.month" filterable placeholder="统计时间" style="width:150px">
                                   <el-option label="最近1月" value="1"></el-option >
                                    <el-option label="最近3月" value="3"></el-option>
                                    <el-option label="最近6月" value="6"></el-option>
                                    <el-option label="最近12月" value="12"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="form4.accountId" clearable filterable placeholder="运营商" style="width:150px" @change="changeAccount4">
                                    <el-option v-for="(item,index) in accountList" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>
                            <el-form-item>
                                <el-select v-model="form4.agentId" clearable filterable placeholder="代理商" style="width:150px">
                                    <el-option v-for="(item,index) in agentIdList4" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button @click="showTable4" size="mini" class="themed-button" type="danger">确 定</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div ref="chart4" style="height:600px;background-color: #fff;"></div>
                </div>
            </el-col>
            <el-col :span='4'>

            </el-col>
        </el-row>

         <el-row :gutter='20'>
            <el-col :span='20'>
                <div>
                    <div class="title-box"><span class="m-title">离网率</span></div>
                    <div class="chose-item">
                        <el-form inline style="height: auto;position:relative">
                            <el-form-item label="条件选择:" style="margin-right:50px">
							</el-form-item>
                            <el-form-item>
                                <el-select v-model="form5.accountId" clearable filterable placeholder="运营商" style="width:150px" @change="changeAccount5">
                                    <el-option v-for="(item,index) in accountList" :key="index" :label="item.accountName" :value="item.accountId">
                                    </el-option>
                                </el-select>
							</el-form-item>
                            <el-form-item>
                                <el-select v-model="form5.agentId" clearable filterable placeholder="代理商" style="width:150px" @change="searchMeal">
                                    <el-option v-for="(item,index) in agentIdList5" :key="index" :label="item.agentName" :value="item.agentId"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-select v-model="form5.mealId"  filterable placeholder="套餐" style="width:150px">
                                    <el-option v-for="(item,index) in mealIdList" :key="index" :label="item.mealName" :value="item.mealId"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button @click="showTable5" size="mini" class="themed-button" type="danger">确 定</el-button>
                            </el-form-item>

                            <el-form-item  style="position:absolute;right:10px;top:0;">
                                <el-button @click="exportChart5" size="mini" class="themed-button right-button" type="danger">导 出</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div ref="chart5" style="height:500px;background-color: #fff;"></div>
                </div>
            </el-col>
            <el-col :span='4'>

            </el-col>
        </el-row>

    </diV>
</template>

<script>
import echarts from "echarts"
// import {getAgentList} from "@/api/agentManagement.js"
import {rechargeAmountStatics,rechargeMonthStatics,rechargeMonthStatics1,rechargeAmountStatics1,cardLoseStatics,searchParamChecksingle,cardLoseExport} from "@/api/virtualCard/dokaStatistics.js";
export default {
    data(){
        return {
            accountList:[],
            form1:{
                accountId:'',
                agentId:'',
                month:'12',
                category:"8",
            },
            agentIdList1:[],
            sum1:"",
            
            form2:{
                accountId:'',
                agentId:'',
                month:'12',
                category:"8",
            },
            sum2:'',
            agentIdList2:[],

            form3:{
                accountId:'',
                agentId:'',
                month:'12',
                category:"8",
            },
            agentIdList3:[],
           
            
            form4:{
                accountId:'',
                agentId:'',
                month:'12',
                category:"8",
            },
            agentIdList4:[],

            mealIdList:[],
            form5:{
                accountId:'',
                agentId:'',
                mealId:'',
                category:"8",
            },
            agentIdList5:[],

            lineChart1:'',
            lineChart2:'',
            lineChart3:'',
            lineChart4:'',
            lineChart5:'',
        }
       
    },
     watch:{
        'form1.accountId'	:function(val){
                this.form1.agentId = ''
            },
        'form2.accountId'	:function(val){
                this.form2.agentId = ''
            },
        'form3.accountId'	:function(val){
                this.form3.agentId = ''
            },
        'form4.accountId'	:function(val){
                this.form4.agentId = ''
            },
        'form5.accountId'	:function(val){
                this.form5.agentId = ''
                this.form5.mealId = ''
            },
        'form5.agentId'	:function(val){
                this.form5.mealId = ''
            },
    },
    mounted() {
			window.onresize = () => {
				return (() => {
					this.lineChart1.resize()
					this.lineChart2.resize()
                    this.lineChart3.resize()
                    this.lineChart4.resize()
                    this.lineChart5.resize()
				})()
			}
		},
    created(){
        this.getAccountList()
        this.$nextTick(function () {
            this.showTable1()
            this.showTable2()
            this.showTable3()
            this.showTable4()
            this.showTable5()
        });
    },
    
        
    methods: {
        changeAccount1(){
            searchParamChecksingle(this.form1).then(res=>{
                this.agentIdList1 = res.agents
                this.form1.agentId = ''
            })
        },
        changeAccount2(){
            searchParamChecksingle(this.form2).then(res=>{
                this.agentIdList2 = res.agents
            })
        },
        changeAccount3(val){
            searchParamChecksingle(this.form3).then(res=>{
                this.agentIdList3 = res.agents
            })
        },
        changeAccount4(val){
            searchParamChecksingle(this.form4).then(res=>{
                this.agentIdList4 = res.agents
            })
        },
        changeAccount5(val){
            searchParamChecksingle(this.form5).then(res=>{
                this.agentIdList5 = res.agents
                this.searchMeal()
            })
        },
        
        getAccountList(){
            searchParamChecksingle({category:8}).then(res=>{
                let agentIdList = res.agents
                this.accountList = res.account
                this.agentIdList1 = agentIdList
                this.agentIdList2 = agentIdList
                this.agentIdList3 = agentIdList
                this.agentIdList4 = agentIdList
                this.agentIdList5 = agentIdList
                this.mealIdList = res.meals
            })
        },

       showTable1(){
           rechargeAmountStatics(this.form1).then(res=>{
                let totalRebateShare = res.totalRebateShare.reverse() //金额
                let month = res.month.reverse()
                this.sum1 = res.sum
                this.initChart1(totalRebateShare,month)
           })
       },

       initChart1(totalRebateShare,month){
        let dom = this.$refs.chart1
        let myChart = echarts.init(dom)
        this.lineChart1 =myChart
           const option={
               title: [
                {
                    top: '40%',
                    left: 10,
                    subtextStyle: {
                        align: 'left',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '金\n额'//   \n换行
                },
            ],
            grid:{
                bottom:"15%"
            },
               legend: {
                data: [
                    {name: '首月平均充值金额'},
                ],
                textStyle: {
                    color: '#0000ff',
                    fontSize: 11
                },
                y: 'bottom',
                x: 'center',
            },
               xAxis:{
                   data:month,
                   axisLabel:{
                       interval:0,
                       rotate:45
                   }
               },
               yAxis:{
                   type:'value',
                   name:'单位（元）'
               },
               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
            ],
               series:[
                   {
                       itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#ff6b01',
						lineStyle: {
							color: '#A5B6E0'
						},
                       name:'首月平均充值金额',
                       type:'line',
                       data:totalRebateShare,
                      
                   },
               ],
               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

           }

           myChart.setOption(option);
           
       },


       showTable2(){
           rechargeMonthStatics(this.form2).then(res=>{
                let monthNums = res.monthNums.reverse()
                let month = res.month.reverse()
                this.sum2 = res.sum
                this.initChart2(monthNums,month)
           })
       },

       initChart2(monthNums,month){
        let dom = this.$refs.chart2
        let myChart = echarts.init(dom)
        this.lineChart2 =myChart
           const option={
               title: [
                {
                    top: '40%',
                    left: 10,
                    subtextStyle: {
                        align: 'left',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '月\n数'//   \n换行
                },
            ],
            grid:{
                bottom:"15%"
            },
               legend: {
                data: [
                    {name: '首月平均充值月数'},
                ],
                textStyle: {
                    color: '#0000ff',
                    fontSize: 11
                },
                y: 'bottom',
                x: 'center',
            },
               xAxis:{
                   data:month,
                   axisLabel:{
                       interval:0,
                       rotate:45
                   }
               },
               yAxis:{
                   type:'value',
                   name:'单位（个）'
               },
               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
            ],
               series:[
                   {
                       itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#ff6b01',
						lineStyle: {
							color: '#A5B6E0'
						},
                       name:'首月平均充值月数',
                       type:'line',
                       data:monthNums,
                      
                   },
               ],
               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

           }
           myChart.setOption(option);
       },

       showTable3(){
           rechargeMonthStatics1(this.form3).then(res=>{
                let monthNums = res.monthNums.reverse()
                let month = res.month.reverse()
                this.initChart3(monthNums,month)
           })
       },

       initChart3(monthNums,month){
        let dom = this.$refs.chart3
        let myChart = echarts.init(dom)
        this.lineChart3 =myChart
           const option={
               title: [
                {
                    top: '40%',
                    left: 10,
                    subtextStyle: {
                        align: 'left',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '月\n数'//   \n换行
                },
            ],
            grid:{
                bottom:'15%'
            },
               legend: {
                data: [
                    {name: '平均在网月数'},
                ],
                textStyle: {
                    color: '#0000ff',
                    fontSize: 11
                },
                y: 'bottom',
                x: 'center',
            },
               xAxis:{
                   data:month,
                   axisLabel:{
                       interval:0,
                       rotate:45
                   }
               },
               yAxis:{
                   type:'value',
                   name:'单位（个）'
               },
               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
            ],
               series:[
                   {   
                        itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#ff6b01',
						lineStyle: {
							color: '#A5B6E0'
						},
                       name:'平均在网月数',
                       type:'line',
                       data:monthNums,
                      
                   },
               ],
               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

           }
           myChart.setOption(option);
       },


      showTable4(){
           rechargeAmountStatics1(this.form4).then(res=>{
                let amounts = res.amounts.reverse()
                let month = res.months.reverse()
                this.initChart4(amounts,month)
           })
       },

       initChart4(amounts,month){
        let dom = this.$refs.chart4
        let myChart = echarts.init(dom)
        this.lineChart4 =myChart
           const option={
               title: [
                {
                    top: '40%',
                    left: 10,
                    subtextStyle: {
                        align: 'left',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '金\n额'//   \n换行
                },
            ],
            grid:{
                bottom:'15%'
            },
               legend: {
                data: [
                    {name: '平均总充值金额'},
                ],
                textStyle: {
                    color: '#0000ff',
                    fontSize: 11
                },
                y: 'bottom',
                x: 'center',
            },
               xAxis:{
                   data:month,
                   axisLabel:{
                       interval:0,
                       rotate:45
                   }
               },
               yAxis:{
                   type:'value',
                   name:'单位（元）'
               },
               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
            ],
               series:[
                   {
                       itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#ff6b01',
						lineStyle: {
							color: '#A5B6E0'
						},
                       name:'平均总充值金额',
                       type:'line',
                       data:amounts,
                      
                   },
               ],
               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

           }
           myChart.setOption(option);
       },

       searchMeal(){
           searchParamChecksingle(this.form5).then(res=>{
               this.mealIdList = res.meals
               this.form5.mealId = ''
           })
       },

       showTable5(){
           cardLoseStatics(this.form5).then(res=>{
                let activeOff = res.activeOff
                let firstOff = res.firstOff
                let continueOff = res.continueOff
                let offTime = res.offTime
                this.initChart5(activeOff,firstOff,continueOff,offTime)
           })
       },

       initChart5(activeOff,firstOff,continueOff,offTime){
        let dom = this.$refs.chart5
        let myChart = echarts.init(dom)
        this.lineChart5 =myChart
           const option={
               title: [
                {
                    top: '40%',
                    left: 10,
                    subtextStyle: {
                        align: 'left',
                        color: '#0000ff',
                        fontSize: 12,
                    },
                    subtext: '个\n数'//   \n换行
                },
            ],
            grid:{
            },
               legend: {
                data: [
                    {name: '激活离网'},
                    {name: '首次充值'},
                    {name: '续费充值'},
                ],
                textStyle: {
                    color: '#0000ff',
                    fontSize: 11
                },
                y: 'bottom',
                x: 'center',
            },
               xAxis:{
                   data:offTime,
               },
               yAxis:{
                   type:'value',
                   name:'单位（个）'
               },
               dataZoom: [
                {  
                    type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
                    start: 0,      // 左边在 % 的位置。
                    end: 100         // 右边在 % 的位置。
                }
            ],
               series:[
                   {
                       itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
                       name:'激活离网',
                       type:'line',
                       data:activeOff,
                      
                   },
                   {
                       itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
                       name:'首次充值',
                       type:'line',
                       data:firstOff,
                      
                   },
                   {
                       itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
                       name:'续费充值',
                       type:'line',
                       data:continueOff,
                      
                   },
               ],
               tooltip : {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                }

           }
           myChart.setOption(option);
       },

       exportChart5(){
           cardLoseExport(this.form5).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
			})
       }


    },

}
</script>

<style>
 .doka-statistice  .el-form-item--small.el-form-item{
        margin-bottom:0
    }

    .el-row {
        margin-bottom: 20px;
    }
</style>