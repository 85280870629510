<template>

<div class="noloopCard table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">不轮询网卡</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="ICCID卡号:">
                <el-input v-model="form.iccidMark" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="短号:">
                <el-input v-model="form.shortIccid" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="IMEI状态:">
                <el-select v-model="form.status" >
                    <el-option label="全部" value=""></el-option>
                    <el-option label="成功" value="0"></el-option>
                    <el-option label="失败" value="1"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="开卡公司:"  prop="accountId">
                <search-select :optionsMetaAll="accountList"  v-model="form.accountId" placeholder="请选择代理商"></search-select>
            </el-form-item>
               <el-form-item label="代理商:"  >
          <el-select v-model="form.agentId" filterable placeholder="请选择代理商" clearable  >
                    <el-option v-for="(item,index) in agentLists" :key="index" :label="item.label"  :value="item.value"></el-option>
                </el-select>
        </el-form-item>
            
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="addRecord">导入Excel</el-button>
            </el-form-item>
             <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID" prop="iccidMark" width="180px"></el-table-column>
             <el-table-column label="短号" prop="shortIccid" width="180px"></el-table-column>
            <el-table-column label="开卡公司" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="导入时间" prop="createDateString" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作人" prop="createUserName" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" prop="statusString" show-overflow-tooltip></el-table-column>
            <el-table-column label="失败原因" prop="failDescription" show-overflow-tooltip></el-table-column>
            <el-table-column label="来源" prop="addSourceString">
                <template slot-scope="scope">
                    <span v-if="scope.row.addSourceString=='3'">窜卡后不轮询</span>
                    <span v-else-if="scope.row.addSourceString=='4'">充值套餐不轮询</span>
                    <span v-else-if="scope.row.addSourceString=='5'">强制停机</span>
                    <span v-else-if="scope.row.addSourceString=='6'">提前停机</span>
                    <span v-else-if="scope.row.addSourceString=='7'">运营商卡已清除</span>
                    <span v-else>{{scope.row.addSourceString}}</span>
                    
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" class="themed-button"  @click='deleteById(scope.row.id)'>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>


    <el-dialog title="网卡分销" :visible.sync="dialogBox">
        <el-form label-position="right" label-width="200px">
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="导入类型:">
                <el-select v-model="isDelete" >
                    <el-option label="新增不轮询" value="false"></el-option>
                    <el-option label="删除不轮询" value="true"></el-option>
                </el-select>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">导入excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('importIccidOrShort.xls')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
 import {getAgentForImportCard} from '@/api/cardManagement/cardImport.js'
import {accountList,noloopcard,deleteById_,importExcel,exportExcelAsync} from '@/api/cardManagement/noloopCard.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import resize from '@/mixins/resize.js'

export default {
    name:'noloopCard',
    mixins:[resize],
    data(){
        return {
            form:{
                iccidMark:'',
                status:'',
                accountId:'',
                shortIccid:"",
                agentId:"",
            },
            accountList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            isDelete:"false",
            agentLists:[],  //代理商
        }
    },
    mounted(){
        this._accountList()
        this._noloopcard()
         this._getAgentForImportCard()
    },
    methods:{
         exportExcel(){
            exportExcelAsync(this.form).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
         _getAgentForImportCard(){
                getAgentForImportCard().then((res) => {
                    this.agentLists = res.map((value,index,arr)=>{
                        return {value:value.id,label:value.agentThreeName}
                    })
                    this.agentLists.unshift({value:'',label:'请选择代理商'})
                }).catch(()=>{
					this.agentLists = []
				})
            },
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },
        addRecord(){
            this.dialogBox = true
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                     this.$confirm('导入成功后该卡将不执行停复机操作同时会出现流量，状态不更新等状态，请谨慎操作！ ', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            let params = new FormData()
                    params.append('fileurl',file)
                    params.append('isDelete',this.isDelete)
                    importExcel(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消导入'
          });          
        });
                   
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        deleteById(id){
            confirmBox(this,'确定要删除吗？','消息提示',()=>{
                deleteById_({id}).then((res)=>{
                   messageBox(this,'删除成功') 
                   this._noloopcard()
                })
            })
        },
        searchTable(){
            this._noloopcard()
        },
        
        handleSizeChange(val){
            this.pageSize = val
            this._noloopcard()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._noloopcard()
        },
        _noloopcard(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            noloopcard(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
        _accountList(){
            accountList(this.form).then((res)=>{
                this.accountList = []
                if(res.length>0){
                    this.accountList = res.map((item)=>{
                        return {
                            label:item.accountName,
                            value:item.id
                        }
                    })
                }
                this.accountList.unshift({
                    label:'请选择',
                    value:""
                })
            })
        }
    },
    components:{
        SearchSelect
    }
}

</script>

