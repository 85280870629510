<template>
	<div class="addSku">
		<el-form :model="formUpdateSku"  ref="ruleFormUpdateSku">
			<el-form-item label="淘宝订单号：" :label-width="formLabelWidth" prop="orderNo" required>
				<el-input v-model="formUpdateSku.orderNo" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="运营商：" :label-width="formLabelWidth" required>
				<el-select v-model="formUpdateSku.accountList" @change="accountListChange">
					<option value="">请选择运营商</option>
					<el-option v-for="item in accountListArr" :key="item.id" :label="item.accountName" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="套 餐：" :label-width="formLabelWidth">
				<el-select v-model="formUpdateSku.mealId" @change="setMealListChange" filterable>
					<option value="">请选择套餐</option>
					<el-option v-for="item in setMealListArr" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer" style="text-align: right;">
			<el-button type="primary" @click="uploadExcel()" class="themed-button">提交</el-button>
			<el-button type="info" @click="cancelDialog()" class="themed-button">取 消</el-button>
		</div>
	</div>
</template>
<script>
	import {
		accountList,
		geMealList,
		saveAddSKU,
	} from "@/api/agentManagement.js";
	import loading from '@/utils/Loading.js'
	export default {
		name:'addSku',
		data() {
			return {
				setMealListArr: [],
				accountListArr: [],
				formUpdateSku: {
					agentId: "",
					accountId: "",
					jihuotype: 2,
					orderNo: "",
					accountList: "",
					mealId: "",
				},
				formLabelWidth:"150px"
			};
		},
		created() {
			this.getAccountList(this.formUpdateSku);
			
		},
		methods: {
			
			getAccountList(params) {
				accountList(params).then((res) => {
					this.accountListArr = res;
				});
			},
			accountListChange() {
				this.formUpdateSku.accountId = this.formUpdateSku.accountList;
				if (this.formUpdateSku.accountList) {
					geMealList(this.formUpdateSku).then((res) => {
						this.setMealListArr = res;
						if (this.setMealListArr.length > 0) {
							this.setMealListArr = this.setMealListArr.find(function(item) {
								return item.type + "" != "11";
							});
						}
					});
				}
			},
			setMealListChange() {},
			cancelDialog() {
				this.$emit("close");
			},
			uploadExcel() {
				this.$confirm("确定导入吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						if (!this.formUpdateSku.accountList) {
							this.$alert('请选择运营商', '提示', {
								confirmButtonText: '确定',
							});
							return
						}
						if (!this.formUpdateSku.mealId) {
							this.$alert('请选择套餐', '提示', {
								confirmButtonText: '确定',
							});
							return
						}
						loading.show();
						
						saveAddSKU(this.formUpdateSku).then(responseResult => {
							if (responseResult.slide_msg_key == 1) {
								this.$alert(responseResult.slide_msg_message_key, "提示", {
									confirmButtonText: '确定',
								})
								return
							}
							this.$emit("refresh")
							this.$emit("close")
						})


					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消导入",
						});
					});
			},
		},
	};
</script>
