<template>
	<div class="monthly-traffic-statistics fadeInRight">
			<div class="title-box clearfix">
				<span class="m-title">每月流量统计</span>
				<el-form ref="quaryForm" inline class="right themed-form search-form" style="line-height: 40px;">
					<el-form-item label="套餐:">
						<el-select filterable v-model="quaryForm.mealId">
                        	<el-option value="" label="全部"></el-option>
                        	<el-option v-for="(item,index) in mealList" :key="index" :value="item.mealId" :label="item.mealName+'('+item.accountName+')'"></el-option>
                    	</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" class="themed-button" @click="onSearch">搜索查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="content-box">
				<el-table :data="tableData"  style="width: 100%;" ref="table" :max-height="maxTableHeight" stripe  border>
					<el-table-column prop="supplier" label="运营商" min-width="150">
					</el-table-column>
					<el-table-column prop="mealName" label="套餐名称" min-width="150">
					</el-table-column>
					<el-table-column prop="jan" label="第1月(GB)">
					</el-table-column>
					<el-table-column prop="feb" label="第2月(GB)">
					</el-table-column>
					<el-table-column prop="mar" label="第3月(GB)">
					</el-table-column>
					<el-table-column prop="apr" label="第4月(GB)">
					</el-table-column>
					<el-table-column prop="may" label="第5月(GB)">
					</el-table-column>
					<el-table-column prop="jun" label="第6月(GB)">
					</el-table-column>
					<el-table-column prop="jul" label="第7月(GB)">
					</el-table-column>
					<el-table-column prop="aug" label="第8月(GB)">
					</el-table-column>
					<el-table-column prop="sep" label="第9月(GB)">
					</el-table-column>
					<el-table-column prop="oct" label="第10月(GB)">
					</el-table-column>
					<el-table-column prop="nov" label="第11月(GB)">
					</el-table-column>
					<el-table-column prop="dec" label="第12月(GB)">
					</el-table-column>
					<el-table-column prop="num" label="使用人数">
					</el-table-column>
					<el-table-column label="操作" min-width="120">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleDetail(scope.row.mealId)">详情</el-button>
                    </template>
                	</el-table-column>
				</el-table>
				<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
				 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
				 @current-change="onPageChange">
				</el-pagination>
			</div>
			<details-from ref="detail" :mealId="mealId" />
	</div>
</template>

<script>
	import { monthlyStaticsList,getMealMonthName,} from "@/api/staticsManagement.js"
	import detailsFrom from './monthlyTrafficStatisticsDeatil'
	import onresize from "@/mixins/resize.js"
	export default {
		mixins: [onresize],
		name:'monthlyTrafficStatistics',
		components: {detailsFrom},
		data() {
			return {
				/* 总的展示数据 */
				tableData: [],
				/* 每页容纳多少条数据 */
				pageSize: 15,
				/* 总共多少条数据 */
				total: 0,
				/* 当前页码 */
				page: 1,
				quaryForm:{
					mealId:'',
				},
				mealList:[],
				mealId:'',
			}
		},
		created() {
			this.getMonthlyStaticsList()
			this.getMealMonthName()
		},
		mixins: [onresize],
		methods: {
			getMealMonthName(){
				getMealMonthName().then(res=>{
					this.mealList = res
				})
			},
			getMonthlyStaticsList() {
				 let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				} 
				Object.assign(this.quaryForm,params)
				monthlyStaticsList(this.quaryForm).then(res => {
					this.tableData = res.rows
					this.total = res.total
					this.page = res.pageNo
					this.pageSize = res.pageSize
				})
			},
			handleDetail(id){
				this.mealId = id
				this.$nextTick(()=> {
					this.$refs.detail.dialogVisible = true
					this.$refs.detail.getDetailList()
				});
			},
			onPageChange(page){
				this.page = page
				this.getMonthlyStaticsList()
			},
			handleSizeChange(size){
				this.pageSize = size
				this.getMonthlyStaticsList()
			},
			onSearch(){
				this.page=1
				this.getMonthlyStaticsList()
			},
			
		}
	}
</script>

<style>
.monthly-traffic-statistics .el-form-item__label{
        vertical-align: top;
    }
</style>
