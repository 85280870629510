<template>
  <div class="interfacePacket fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">接口报文</span>
      <el-form
        :inline="true"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
        id="baseForm"
      >
        <el-form-item label="ICCID">
          <el-input v-model="form.iccid" placeholder="请输入iccid"></el-input>
        </el-form-item>
        <el-form-item label="运营商" prop="accountId">
          <el-select
            v-model="form.accountId"
            filterable
            placeholder="运营商"
            @change="operatorChange"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in accountIdList"
              :key="item.index"
              :label="item.accountName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="速率" prop="speedValue"  v-if="speedIsShow">
          <el-select v-model="speedValue" placeholder="请选择" filterable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(item, index) in speedList"
              :key="index"
              :label="item.speedKey"
              :value="item.speedValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="接口">
          <el-select v-model="form.action" filterable   @change="interfaceChange">
            <el-option
              v-for="(item, index) in w_interface"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="sendData"
            class="themed-button"
            icon="el-icon-search"
            >发送</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        border
        ref="table"
        stripe
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
       <el-table-column
      type="index"
      label="序号"
      min-width="50">
    </el-table-column>
        <el-table-column prop="request" label="报文" min-width="180">
         <template slot-scope="scope">
          <span v-html="getValue(scope.row)"></span>
          <div  style="float:right;">
           <el-button type="primary" @click="copyData(scope.row)" >复制报文</el-button>
          </div>
           
          </template>
        </el-table-column>
        <!-- <el-table-column
          show-overflow-tooltip
          prop="response"
          label="响应数据"
          min-width="150"
        >
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { interfacePacketList } from "@/api/operator.js";
import {agentOperatorList} from '@/api/order.js'
import {getSpeedList} from '@/api/virtualCard/accountRateList.js'
import dictGroup from "@/mixins/dictGroup.js";
import store from "@/store";
import {messageBox,confirmBox} from '@/utils/common.js'
export default {
  name: "testVue",
  mixins: [onresize, dictGroup],
  data() {
    return {
      form: {
        iccid: "",
        accountId: "",
        action: "",
      },
      accountIdList: [],
      speedList: [],
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 1,
      speedIsShow:false,
      speedValue:'',
    };
  },
  created() {
    this.getAgentOperatorList();
    // this.getInterfacePacketList();
  },
  methods: {
    
    	copyData(val){
				let that = this 
        let content =`${val.request}
      ${val.response}`
        this.$copyText(content).then(res=>{
			that.$notify({
				title:"复制报文成功",
				type:"success"
			})
		})
			},
    getValue(row){
      let request = row.request
      let url = row.request.substring(0,row.request.indexOf("{"))   
      let params = row.request.substring(row.request.indexOf("{")) 
      // console.log(url,params) 
      return `【请求】${url}<br>
            ${params}<br>
      【响应】${row.response}`

    },
    getAgentOperatorList() {
      agentOperatorList().then((res) => {
        this.accountIdList = res;
      });
    },
    operatorChange(accountId) {
      this.speedList = [];
      getSpeedList({ accountId }).then((res) => {
        let list = res.rows;
        if (list.length > 0) {
          list.forEach((item) => {
            this.speedList.push({
              speedKey: item.speedKey,
              speedValue: item.speedValue,
            });
          });
        } else {
          messageBox(this, "该运营商在无速率列表请重新选择运营商");
          return;
        }
      });
    },
    interfaceChange(val){
     if(val=="changeSpeedValue"){
    this.speedIsShow = true
     }else{
      this.speedIsShow = false
     }
    },
    getInterfacePacketList() {
      let params = {
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      let sendParams= {}
      if(this.form.action=="changeSpeedValue"){
          sendParams= {
             iccid: this.form.iccid,
        accountId: this.form.accountId,
        action: this.form.action,
       speedValue: this.speedValue,
           }  
      }else{
         sendParams= {
             iccid: this.form.iccid,
        accountId: this.form.accountId,
        action: this.form.action,
      }}
      Object.assign(sendParams, params);
      interfacePacketList(sendParams).then((res) => {
        console.log(res);
        if(res){
 this.tableData=res
        }else{
         this.tableData=[] 
        }
         
          // this.total = res.count
      }).catch(error=>{
       this.tableData=[] 
      });
    },
    sendData() {
      if(this.form.iccid.trim()==""){
           messageBox(this,'请输入iccid')
                         return  
      }
       if(this.form.accountId==""){
           messageBox(this,'请选择运营商')
                         return  
      }
      if(this.form.action==""){
     messageBox(this,'请选择接口参数')
                         return  
      }
      if(this.form.action=="changeSpeedValue"){
        //  this.speedIsShow = true
         if(this.speedValue==""){
       messageBox(this,'请选择速率')
                         return  
         }
      }
      this.getInterfacePacketList()
    },
     onPageChange(page){
			this.page = page
			this.getInterfacePacketList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getInterfacePacketList()
        },
  },
};
</script>

<style>
.interfacePacket .el-form-item__label {
  vertical-align: top;
}
</style>
