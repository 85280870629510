<template>
  <div class="rulerManagement fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">扣费规则管理</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="规则名称:">
          <el-input v-model="formInline.name" placeholder="规则名称"  clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="addRuler"
            icon="el-icon-plus"
            class="themed-button"
            >新增规则</el-button
          >
          
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="规则名称"> </el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == '1'">线性</span>
            <span v-else-if="scope.row.type == '2'">阶梯</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="discount" label="折扣"> </el-table-column> -->

          <el-table-column label="折扣" prop="discount" show-overflow-tooltip>
                <template slot-scope="scope">
                     <!-- <el-input v-if="scope.row.distributionStatus == 1"   v-model="scope.row.agentOrder" @change="((val)=>{updateSort(val,scope.row.agentSetMealId,1)})"></el-input> -->
                     <el-input-number
            v-model="scope.row.discount"
            :precision="4"
            :min="0.0001"
            :step="0.1"
            :max="1"
            @change="((val)=>{updateDiscount(val,scope.row.id)})"
          ></el-input-number>
                  
               </template>
            </el-table-column>
         <el-table-column prop="addFlow" label="流量(G)">
            <template v-slot:default="scope">
              <span v-if="scope.row.type==1">{{scope.row.addFlow}}</span>
              <span v-else>-</span>
          </template>
           </el-table-column>
          <el-table-column prop="deductMoney" label="扣费(元)"> 
             <template v-slot:default="scope">
              <span v-if="scope.row.type==1">{{scope.row.deductMoney}}</span>
              <span v-else>-</span>
          </template>
          </el-table-column>
        <el-table-column label="操作" width="320px" >
          <template v-slot:default="scope">
            <el-button
              type="info"
              class="themed-button"
              @click="getDetail(scope.row)"
              v-if="scope.row.type==2"
              >规则详情</el-button
            >
             <el-button
              type="info"
              class="themed-button"
              @click="copy(scope.row)"
              >复制</el-button
            >
             <el-button
              type="info"
              class="themed-button"
              @click="deleteData(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新建规则"
      :visible.sync="dialogAddVisible"
      v-if="dialogAddVisible"
    >
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item
          label="名称"
          :label-width="formLabelWidth"
          prop="name"
          required
        >
          <el-input v-model="addForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型" :label-width="formLabelWidth" prop="type">
          <el-select v-model="addForm.type">
            <el-option label="线性" value="1"></el-option>
            <el-option label="阶梯" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="流量（G）"
          :label-width="formLabelWidth"
          prop="addFlow"
          v-if="addForm.type == 1"
        >
          <el-input-number
            v-model="addForm.addFlow"
            :precision="2"
            :min="0.01"
            :max="99999999"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="扣费（元）"
          :label-width="formLabelWidth"
          prop="deductMoney"
          v-if="addForm.type == 1"
        >
          <el-input-number
            v-model="addForm.deductMoney"
            :precision="2"
            :min="0"
            :max="99999999999"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="阶梯详细:"
          :label-width="formLabelWidth"
          v-if="addForm.type == 2"
        >
          <el-button
            type="primary"
            class="themed-button leftMar"
            @click="showAddPrice"
            >阶梯资费设置</el-button
          >
        </el-form-item>
        <el-form-item
          label="折扣"
          :label-width="formLabelWidth"
          prop="discount"
        >
          <el-input-number
            v-model="addForm.discount"
            :precision="4"
            :min="0.0001"
            :step="0.1"
            :max="1"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="formAddSubmit('addForm')"
          class="themed-button"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="formAddReset('addForm')"
          class="themed-button"
          >重置</el-button
        >
        <el-button
          type="info"
          @click="dialogAddVisible = false"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :title="isEditTitle"
      :visible.sync="dialogVisibleTwo"
      width="50%"
      v-if="dialogVisibleTwo"
      append-to-body
    >
      <ladder-setting
        @close="getladderDetail"
        :totalValue="ladderDetail"
        :isDialogAdd = "isAdd"
      ></ladder-setting>
    </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import { messageBox } from "@/utils/common.js";
import { getRuleList, addRule ,ruleDeleteById,updateRule} from "@/api/trafficPoolManagement.js";
import ladderSetting from "./ladderSetting.vue";
export default {
  name: "rulerManagement",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        name: "",
      },
      tabledata: [],
      formLabelWidth: "200px",
      dialogAddVisible: false,
      addForm: {
        name: "",
        type: "2",
        addFlow: 1,
        deductMoney: 1,
        discount: 1,
        ladderDetail: [
          // {
          //   start: 0,
          //   end: 100,
          //   unitPrice: "0.033",
          // },
          // {
          //   start: 100,
          //   end: 300,
          //   unitPrice: "0.03",
          // },
          // {
          //   start: 300,
          //   end: 99999999,
          //   unitPrice: "0.0036",
          // },
        ],
       
      },
      dialogVisibleTwo: false,
      ladderDetail: "",
      rules: {
        name: [{ required: true, message: "请输入规则名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        addFlow: [{ required: true, message: "请输入流量", trigger: "change" }],
        deductMoney: [
          { required: true, message: "请输入扣费金额", trigger: "change" },
        ],
        discount: [
          { required: true, message: "请输入折扣", trigger: "change" },
        ],
      },
       isEditTitle:"",
        isAdd:false,
    };
  },
  created() {
    this.getRuleList();
  },
  components: {
    ladderSetting,
  },
  computed: {
    ...mapGetters(["currentRole", "currentAgentId"]),
  },
  methods: {
    updateDiscount(val,id){
       
          
            let postData = {
                    id, 
                  discount:val,
                };
            updateRule(postData).then((res)=>{
              this.getRuleList();
            }).catch((res)=>{
                messageBox(this,'请求错误','操作繁忙请稍后重试或联系客服。')
            })
        },
    copy(row){
      console.log(row)
        this.dialogAddVisible = true
      this.addForm.name = row.name + "复制"
      this.addForm.type = row.type+"" //线性1  阶梯2
       this.addForm.discount = row.discount
      if(row.type==1){
      this.addForm.addFlow = row.addFlow
      this.addForm.deductMoney = row.deductMoney
      }else{
       this.addForm.ladderDetail = row.ladderDetail  //string
     
      }
    
     
     
    },

    deleteData(id){
      console.log(88)
    this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        ruleDeleteById({ id }).then((res) => {
          this.getRuleList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
    formAddSubmit(formName) {
      //新增规则确认
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {};
          params.name = this.addForm.name;
          params.type = this.addForm.type;
          params.discount = this.addForm.discount;
          if (this.addForm.type == 1) {
            params.addFlow = this.addForm.addFlow;
            params.deductMoney = this.addForm.deductMoney;
          } else if (this.addForm.type == 2) {
          //  if(this.addForm.discount>=1){
           //  this.$message({
            //    showClose: true,
             //   message: "折扣应该小于1",
              //  type: "error",
            //  });
             // return
            // }
            if( typeof  this.addForm.ladderDetail  === 'string'){//字符串的时候不转换了
              params.ladderDetail =  this.addForm.ladderDetail
            }else{
           params.ladderDetail = JSON.stringify(this.addForm.ladderDetail) ;
            }
           
            
          }

          addRule(params).then((res) => {
            if (res.system_result_key == "0") {
              this.$message({
                showClose: true,
                message: "新增成功",
                type: "success",
              });
              this.dialogAddVisible = false;
              this.getRuleList();
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    formAddReset(formName) {
      this.$refs[formName].resetFields();
    },
    getladderDetail(val) {
       console.log(val)
       if(val){
       this.addForm.ladderDetail =  val
       }
       
       this.dialogVisibleTwo = false
    },
    showAddPrice() {
      this.dialogVisibleTwo = true;
      this.isEditTitle = "设置阶梯价格"
      this.isAdd = true
      this.ladderDetail =   this.addForm.ladderDetail?JSON.parse(this.addForm.ladderDetail):this.addForm.ladderDetail
    },
    addRuler() {
      this.dialogAddVisible = true;
      this.addForm.name = "";
      this.addForm.type = "";
      this.addForm.addFlow = 1;
      this.addForm.deductMoney = 1;
      this.addForm.discount = 1;
      this.addForm.ladderDetail = "";
      // this.$refs['addForm'].resetFields();
    },
    getDetail(data) {
      if(data.type ==1){
        //规则详情 未做
      } else if (data.type == 2){
       //规则详情 未做
        this.dialogVisibleTwo = true;
        this.isEditTitle = "查看规则详情"
         this.isAdd = false
         console.log()
         this.ladderDetail =   data.ladderDetail? JSON.parse(data.ladderDetail)  :[]
         console.log( this.ladderDetail)
      }
    },
    getRuleList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.formInline);
      getRuleList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getRuleList();
    },
    onPageChange(page) {
      this.page = page;
      this.getRuleList();
    },
    searchData() {
      this.page = 1;
      this.getRuleList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
.rulerManagement .el-form-item__label {
  vertical-align: top;
}

</style>
