<template>
    <div class="agent-public-recharge fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">代理商公众号充值网卡</span>
            <el-form inline ref="agentPublicRechargeForm" v-model="agentPublicRechargeData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
                    <el-input v-model="agentPublicRechargeData.iccidMark" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="agentPublicRechargeData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item label="开卡运营商">
                    <search-select :optionsMetaAll="agentOperators" v-model="agentPublicRechargeData.accountId"></search-select>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-circle-plus-outline" type="info" class="themed-button" @click="importByExcel">批量导入</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-delete" type="danger" class="themed-button" @click="deleteByExcel">批量删除</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidCard"
                    label="ICCID号码"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    prop="phone"
                    label="接入号"
                    min-width="150"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="开卡运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="操作时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    min-width="120"
                    >  
                </el-table-column>
                
                <el-table-column
                    label="操作"
                    min-width="120">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id,scope.row.iccidCard)">删除</el-button>
                    </template>
                </el-table-column>
                
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="批量导入" :visible.sync="dialogImportByExcelVisible" width="500px" :destroy-on-close='true'>
            <el-form :model="dialogImportByExcelForm"  ref="dialogImportByExcelForm">
                <el-form-item label="请选择excel文件:" :label-width="formLabelWidth">
                    <input class="file-input" type="file" ref='fileImportInput'/>
                    <div class="mt5">
                            <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="uploadImportExcel">导入excel模板</el-button>
                <el-button class="themed-button" type="info" @click="exportTemplateExcel('importIccid.xls')">导出excel模板</el-button>
                <el-button class="themed-button" type="primary" @click="dialogImportByExcelVisible = false">关 闭</el-button>
            </div>
        </el-dialog>

        <el-dialog title="批量删除" :visible.sync="dialogDeleteByExcelVisible" width="500px" :destroy-on-close='true'>
            <el-form :model="dialogDeleteByExcelForm"  ref="dialogDeleteByExcelForm">
                <el-form-item label="请选择excel文件:" :label-width="formLabelWidth">
                    <input class="file-input" type="file" ref='fileDeleteInput'/>
                    <div class="mt5">
                            <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="uploadDeleteExcel">导入excel模板</el-button>
                <el-button class="themed-button" type="info" @click="exportTemplateExcel('importIccid.xls')">导出excel模板</el-button>
                <el-button class="themed-button" type="primary" @click="dialogDeleteByExcelVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect.vue'
import {agentOperatorList} from '@/api/order.js'
import {agentPublicRecharge,deleteAgentPublicRechargeById,importAgentPublicRechargeByExcel,deleteAgentPublicRechargeByExcel,exportAgentPublicRecharge} from '@/api/history.js'
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
export default {
    name:'agentPublicRechargeCard',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return {
            agentOperators:[{label:"全部",value:""}],
            agentPublicRechargeData:{
                iccidMark:'',
                phone:'',
                accountId:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogImportByExcelVisible:false,
            formLabelWidth:'150px',
            dialogImportByExcelForm:{},
            dialogDeleteByExcelVisible:false,
            dialogDeleteByExcelForm:{},
        }
    },
    created(){
        this.getAgentOperatorList()
        this.getagentPublicList()
    },
    methods:{
        getAgentOperatorList(){
             agentOperatorList().then(res=>{
                 let arrList =[{label:"全部",value:""}]
                 res.forEach(element => {
                     arrList.push({label:element.accountName,value:element.id})
                 })               
                 this.agentOperators=arrList
             })
         },
         getagentPublicList(){
             let params = {
                 pageSize:this.pageSize,
                 pageNo:this.page
             }
             Object.assign(this.agentPublicRechargeData,params)
             agentPublicRecharge(this.agentPublicRechargeData).then(res=>{
                this.tableData = res.rows
                this.total = res.count
             })
         },
         handleDelete(id,iccid){
             this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    deleteAgentPublicRechargeById({'id':id,'iccidMark':iccid}).then(res=>{
                        this.$message({
								type: 'success',
								message:'删除成功'
                            });
                         this.getagentPublicList()
                    })
                    
                })
         },
          onPageChange(page){
			this.page = page
			 this.getagentPublicList()
		},
		handleSizeChange(size){
			this.pageSize = size
			 this.getagentPublicList()
        },

        onSearch(){
            this.page=1
             this.getagentPublicList()
        },
        exportExcel(){
            exportAgentPublicRecharge(this.agentPublicRechargeData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        importByExcel(){
            this.dialogImportByExcelVisible = true
        },
        uploadImportExcel(){
            this.$confirm("确定导入吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['fileImportInput'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            param.append("fileurl",file)
                            importAgentPublicRechargeByExcel(param).then(res=>{
                                if(res.system_result_key == '0'){
                            //          this.$message({
							// 	type: 'success',
							// 	message:'导入成功'
                            // });
                            // this.getagentPublicList()
							// this.count = 0
							// this.dialogImportByExcelForm = {}
                            // this.$refs['fileImportInput'].value = ''
                            // this.dialogImportByExcelVisible = false
                               this.$alert('导入成功', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
                              this.getagentPublicList()
							this.count = 0
							this.dialogImportByExcelForm = {}
                            this.$refs['fileImportInput'].value = ''
                            this.dialogImportByExcelVisible = false
							this.$router.push('/tob/i_export_list')
						});

                                }else{
                                     this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            }); 
                                }
                              
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
                })
        },
        exportTemplateExcel(name){
            exportTemplate({'name':name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },
        deleteByExcel(){
            this.dialogDeleteByExcelVisible = true
        },
        uploadDeleteExcel(){
            this.$confirm("确定导入吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['fileDeleteInput'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            param.append("fileurl",file)
                            deleteAgentPublicRechargeByExcel(param).then(res=>{
                                if (res.system_result_key == '0') {
                            // this.$message({
							// 	type: 'success',
							// 	message:'导入成功'
                            // });
                            // this.getagentPublicList()
							// this.count = 0
							// this.dialogDeleteByExcelForm = {}
                            // this.$refs['fileDeleteInput'].value = ''
                            // this.dialogDeleteByExcelVisible = false
                           
                              this.$alert('导入成功', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
                             this.getagentPublicList()
							this.count = 0
							this.dialogDeleteByExcelForm = {}
                            this.$refs['fileDeleteInput'].value = ''
                            this.dialogDeleteByExcelVisible = false
							this.$router.push('/tob/i_export_list')
						});


                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                              
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
                })
        }

       
    }
}
</script>

<style>
    .agent-public-recharge .el-form-item__label{
        vertical-align: top;
    }
</style>