import request from './../request.js'
export function getTrafficStatisticsList(params) {//获取
    return request({
        url: "/flowStatics/flowList.do?",
        method: 'post',
        data: params
    })
}

export function exportTrafficStatisticsList(params) {
    return request({
       url:"/flowStatics/export.do?",
        method: 'post',
        data: params
    })
}