<template>

<div class="add-package">
    <div class="group">
        <h1>叠加包</h1>
        <div>
            <div  v-for="(item,index) in packageOne" :key="index" >
                <input type="radio" name="packageIds" :value="item.id" v-model="packageIds">
                <!-- <span>{{item.name}}({{item.accountName}})</span> -->
                 <span> <strong>套餐名称：</strong>{{item.name}}</span>  
                <span> <strong>&nbsp;&nbsp;&nbsp; 所属运营商： </strong>{{item.accountName}}</span>
                <span> <strong>&nbsp;&nbsp; 套餐数量： </strong> {{item.cardCount}}</span>
                <span><strong>&nbsp;&nbsp; 充值价格： </strong>{{item.money}} 元</span>
            </div>
        </div>
        
    </div>
    <div class="group">
        <h1>叠加包(语音)</h1>
        <div>
            <div  v-for="(item,index) in packageVoice" :key="index" >
                <input type="radio" name="packageIds" :value="item.id" v-model="packageIds">
                <!-- <span>{{item.name}}({{item.accountName}})</span> -->
                 <span> <strong>套餐名称：</strong>{{item.name}}</span>  
                <span> <strong>&nbsp;&nbsp;&nbsp; 所属运营商： </strong>{{item.accountName}}</span>
                <span> <strong>&nbsp;&nbsp; 套餐数量： </strong> {{item.cardCount}}</span>
                <span><strong>&nbsp;&nbsp; 充值价格： </strong>{{item.money}} 元</span>
            </div>
        </div>
    </div>
    <div class="group">
        <h1>基础套餐</h1>
        <div v-show="showBasic">
             <div  v-for="(item,index) in packageBasic" :key="index" >
                <input type="radio" name="packageIds" :value="item.id" v-model="packageIds">
                 <!-- <span>{{item.name}}({{item.accountName}})</span>
                 <span v-show="item.cardCount">({{item.cardCount}})</span> -->
                  <span> <strong>套餐名称：</strong>{{item.name}}</span>  
                <span> <strong>&nbsp;&nbsp;&nbsp; 所属运营商： </strong>{{item.accountName}}</span>
                <span> <strong>&nbsp;&nbsp; 套餐数量： </strong> {{item.cardCount}}</span>
                <span><strong>&nbsp;&nbsp; 充值价格： </strong>{{item.money}} 元</span>
            </div>
        </div>
    </div>
    <div class="foot">
        <el-button type="primary" class="themed-button" @click="sure" :disabled="isDisable">确定</el-button>
        <el-button type="primary" class="themed-button" @click="reset">重置</el-button>
        <el-button type="primary" class="themed-button" @click="close">取 消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {mealRecharge} from '@/api/cardManagement/cardBillDetail.js'

export default {
    name:'addPackage',
    props:["addPackageData","curInfor"],
    data(){
        return {
            showBasic:true,
            packageIds:'',
            packageOne:[],
            packageVoice:[],
            packageBasic:[],
            isDisable:false,
        }
    },
    Mounted(){
			// this.$nextTick()
			//console.log(this.addPackageData)
        //this.dateShow()
    },
		created(){
			this.dateShow()
		},
		watch:{
			addPackageData(newValue){
				//console.log(newValue)
				this.dateShow()
			}
		},
		
    methods:{
        sure(){
           /* if(!this.packageIds){
                return 
            } */
             this.isDisable = true
            let params ={}
						/* params.iccidOrPhone = this.curInfor.iccidMark1; */
			params.iccidOrPhone = this.curInfor.iccidOrPhone;
            params.setMealId = this.curInfor.setMealId;
            params.isChild = this.curInfor.isChild;
            params.codeChild = this.curInfor.codeChild;
            params.packageIds = this.packageIds
            params.countNum = 1
            params.effectType = 1
            mealRecharge(params).then((res)=>{
                this.isDisable = false
                if(res.system_result_message_key && res.system_result_message_key!=="") {
                    this.$alert(res.system_result_message_key, '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.close()
                        }
                    })
                    return
                }
                if(res.slide_msg_key==1){
                    this.$alert(res.slide_msg_message_key, '提示', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    })
                }
								this.close()
            }).catch(()=>{
                this.isDisable = false
            })
        },
        reset(){
            this.packageIds = ""
        },
        close(){
					  this.packageIds = ""
            this.$emit('close')
        },
        dateShow(){
            this.packageOne =[]
            this.packageVoice =[]
            this.packageBasic = []
            let list = this.addPackageData
						//console.log(list)
            if(list && typeof list !=="undefined" && list.length > 0){
                var cardSecondCode = "";
                list.forEach(item=>{
                if (1 == item.type)
                        return 
                if (101 == item.type)
                        return 
                if (2 == item.type)
                        return item.cardCount = item.cardCount +"月"
                if (3 == item.type)
                        return item.cardCount = item.cardCount +"月"
                if (4 == item.type)
                        return item.cardCount = item.cardCount +"月"
                if (5 == item.type)
                        return item.cardCount = item.cardCount +"月"
                if (6 == item.type)
                        return 
                if (7 == item.type)
                        return item.cardCount = item.cardCount +"天"
                if (8 == item.type)
                        return 
                if (9 == item.type)
                        return item.cardCount = item.cardCount +"月"
                if (11 == item.type)
                        if(item.termType==1){
                return item.cardCount = item.cardCount +"月"
                        }else if(item.termType==2){
                return item.cardCount = item.cardCount +"天"
                        }
                if (13 == item.type)
                       return
                if (99 == item.type)
                       return
                if (14 == item.type)
                        return item.cardCount = item.cardCount +"月"
                if (15 == item.type)
                        return item.cardCount = item.cardCount +"月"
                if (20 == item.type)
                        return item.cardCount = item.cardCount +"月"
                if (21 == item.type)
                        return item.cardCount = item.cardCount +"月"
                if (23 == item.type) {
                        return item.cardCount = item.cardCount +"月"
                }
                if (33 == item.type) {
                        return 
                }
                if(40 == item.type){
                        return 
                }
                })
                list.forEach((item) => {
                        if(item.id===this.curInfor.setMealId && item.secondIsChild === "2") {
                            cardSecondCode = item.secondCodeChild;
                            return;
                        }
                    }
                );
                list.forEach((item)=>{
                    if(item.type==1){
                        this.packageOne.push({id:item.id,name:item.name,accountName:item.accountName,cardCount:item.cardCount,money:item.money})
                    }else if(item.type==101){
                        console.log(item.id)
                        this.packageVoice.push({id:item.id,name:item.name,accountName:item.accountName,cardCount:item.cardCount,money:item.money})
                    }else {
                        let setMealId = this.curInfor.setMealId;
                        let isChild = this.curInfor.isChild;
                        let codeChild = this.curInfor.codeChild;
                    
                        if(cardSecondCode && cardSecondCode!=="") {
                            if(item.secondCodeChild===cardSecondCode) {
                                console.log(item.id)
                                this.packageBasic.push({"id":item.id,name:item.name,cardCount:item.cardCount,accountName:item.accountName,money:item.money})
                            }
                        } else {
                            if(isChild=="1"){
                                if(item.id==setMealId){
                                    console.log(item.id)
                                    this.packageBasic.push({id:item.id,name:item.name,cardCount:"",accountName:item.accountName,money:item.money})
                                }
                            }else{
                                this.showBasic = false
                                console.log(item.id)
                                if(item.codeChild==codeChild){
                                    this.packageBasic.push({id:item.id,name:item.name,cardCount:item.cardCount,accountName:item.accountName,money:item.money})
                                }
                            }
                        }

                    }
            });

            }

        }
    }
}
</script>

<style>
.add-package .foot{text-align: right;}
</style>
