<template>
  <div class="balanceDetail fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">余额返利详情</span>
      <el-form
        inline
        class="hook right themed-form search-form"
        style="line-height: 40px"
      >
        <el-form-item label="iccid:">
          <el-input
            v-model="form.iccid"
            placeholder="输入ICCID"
            :disabled="isDisable"
          ></el-input>
        </el-form-item>

        <el-form-item label="时间:">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="form.startTime"
              style="width: 126px"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="form.endTime"
              style="width: 126px"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="代理商:" style="margin-left: 20px">
          <el-select v-model="form.agentId" filterable clearable>
            <el-option
              v-for="(item, index) in agentList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="margin-left: 20px">
          <el-button
            type="primary"
            @click="searchData"
            class="themed-button"
            icon="el-icon-search"
            >搜索查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-download"
            class="themed-button"
            type="info"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        border
        stripe
        ref="table"
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          show-overflow-tooltip
          prop="agentName"
          label="代理商"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="iccidMark"
          label="卡号"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="rebateMoney"
          label="返利金额(元)"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="创建时间"
          min-width="120"
        />
        <el-table-column
          show-overflow-tooltip
          prop="flowCount"
          label="流量总数(G)"
          min-width="160"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="rebateRatio"
          label="返利比"
          min-width="120"
        >
        </el-table-column>
      </el-table>

      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import {
  balanceRebateList,
  exportBalanceRebate,
} from "@/api/cardManagement/balanceManagement.js";
import resize from "@/mixins/resize.js";
import { orderGetAgents } from "@/api/agentApplyCheck.js";
export default {
  name: "",
  mixins: [resize],
  data() {
    return {
      form: {
        iccid: "",
        startTime: "",
        endTime: "",
        agentId: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      quaryParams: "",
      isDisable: false,
      agentList: [],
    };
  },
  created() {
    // console.log(this.form.iccid, this.$route.query.iccid);
    // if (this.form.iccid) {
    //   this.isDisable = true;
    // } else {
    //   this.isDisable = false;
    // }
    this._orderGetAgents();
    this.getBalanceDetailList();
  },
  methods: {
    _orderGetAgents() {
      this.agentList = [];
      this.agentList.push({
        label: "全部",
        value: "",
      });
      
      orderGetAgents({ status: 0 }).then((res) => {
        if (res.length > 0) {
          res.forEach((item) => {
            this.agentList.push({
              label: item.name,
              value: item.id,
            });
          });
        }
      });
    },
   
    getBalanceDetailList() {
      // this.form.iccid = this.quaryParams
      console.log(this.form);
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.form);
      console.log(params);
      balanceRebateList(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getBalanceDetailList();
    },
    onPageChange(page) {
      this.page = page;
      this.getBalanceDetailList();
    },
    onSearch() {
      this.page = 1;
      this.getBalanceDetailList();
    },
    exportExcel() {
      exportBalanceRebate(this.form).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    searchData() {
      this.page = 1;
      this.getBalanceDetailList();
    },
  },
};
</script>
<style>
.balanceDetail .el-form-item__label {
  vertical-align: top;
}
</style>