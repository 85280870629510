<template>

<div class="form-add">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="库编号:">
            <el-input v-model="form.cardBankCode" readonly></el-input>
        </el-form-item>
        <el-form-item label="产品名称:" prop="accountName" required>
            <el-input v-model="form.accountName"></el-input>
        </el-form-item>
        <el-form-item label="客户:" prop="customerName" required>
            <el-input v-model="form.customerName"></el-input>
        </el-form-item>
        <el-form-item label="备注:">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="update('form')">修改</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">关闭</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {bindCardImportUpdate,bindCardFindById} from '@/api/meCardBindManagement/imeiLibrary.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    props:['id'],
    data(){
        return {
            form:{
                id:'',
                cardBankCode:'',
                accountName:'',
                remark:'',
                customerName:''
            },
            rules: {
                accountName: [
                    { required: true, message: '请选择产品名称', trigger: 'blur' }
                ],
                customerName: [
                    { required: true, message: '请填写客户名称', trigger: 'blur' }
                ]
            },
        }
    },
    mounted(){
        this.form.id = this.id
        this._bindCardFindById()
    },
    methods:{
        update(formName){
            confirmBox(this,'确定提交修改吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    bindCardImportUpdate(this.form).then(res=>{
                        messageBox(this,'修改成功')
                        this.cancle()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
},
        cancle(){
            this.$emit('close')
        },
        _bindCardFindById(){
            bindCardFindById(this.form).then((res)=>{
                this.form.cardBankCode = res.entity.cardBankCode
                this.form.accountName = res.entity.accountName
                this.form.remark = res.entity.remark
            })
        }
    }
}
</script>

