<template>
  <div class="cardExceptionRecord fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">已使用不轮询网卡列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form "
        style="height: auto; line-height: 40px"
      >
                   <el-form-item label="开始时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择开始时间" v-model="formInline.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择结束时间" v-model="formInline.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
				</el-form-item>
       
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
            <el-form-item>
          <el-button
            icon="el-icon-download"
            class="themed-button"
            type="info"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </el-form-item>
        
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="iccid" label="iccid"></el-table-column>
        <el-table-column prop="shortNo" label="短号"> </el-table-column>
       <!-- <el-table-column prop="firstAgent" label="一级代理商ID">
           </el-table-column> -->
        <el-table-column prop="remark" label="备注"> </el-table-column>
         <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>

     
    </div>
 
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
 getCardExceptionRecord,exportExceptionRecord
} from '@/api/history.js';
import {messageBox,confirmBox } from '@/utils/common.js' 

export default {
  name: "cardExceptionRecord",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
       type:"1",
       startTime:"",
       endTime:"",
      },
      tabledata: [],
  
    };
  },
  created() {
  
   this.getCardExceptionRecord()
    
  },
  mounted(){
  
  },
  methods: {
     exportExcel() {
      exportExceptionRecord(this.formInline).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
     
    getCardExceptionRecord() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.formInline);
      getCardExceptionRecord(params).then((res) => {
        /* 表格数据 */
        console.log(this.tabledata)
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getCardExceptionRecord();
    },
    onPageChange(page) {
      this.page = page;
      this.getCardExceptionRecord();
    },
    searchData() {
      this.page = 1;
      this.getCardExceptionRecord();
    },
  },
};
</script>

<style>
/* .right_agent {
  margin-right: 8px;
}
 .customerList  .el-date-editor.el-input{width:100%!important;}
.customerList .el-form-item__label {
  vertical-align: top;
} */
.cardExceptionRecord  .el-form--inline .el-form-item__content{vertical-align: middle;}
</style>

