<template>
  <div class="acceleratorPackageCardList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">加速卡列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form "
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="ICCID:">
          <el-input
            v-model="formInline.iccid"
            placeholder="请输入ICCID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="短号:">
          <el-input
            v-model="formInline.shortIccid"
            placeholder="请输入短号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="addPool"
            icon="el-icon-plus"
            class="themed-button"
            >导入</el-button
          >
          <el-button
            type="primary"
            @click="batchUpdate"
            icon="el-icon-edit-outline"
            class="themed-button"
            >删除</el-button
          >

          <el-button
            type="primary"
            @click="exportList"
            icon="el-icon-plus"
            class="themed-button"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="iccid" label="卡号" min-width="180">
        </el-table-column>
        <el-table-column prop="shortIccid" label="短号" min-width="130">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="100">
        </el-table-column>
        <el-table-column label="操作" width="320px" >
          <template v-slot:default="scope">
            <el-button
              type="info"
              class="themed-button"
              @click="deleteRow(scope.row.iccid)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogBox">
      <el-form label-position="right" label-width="200px">
        <el-form-item label="请选择excel文件:">
          <input class="file-input" type="file" ref="file" />
          <div class="mt5">
            <span class="text-color" style="font-size: 12px"
              >仅支持.xls/.xlsx格式</span
            >
          </div>
        </el-form-item>

        <el-form-item label="ICCID/短号:">
          <el-input
            v-model="iccids"
            type="textarea"
            placeholder="请输入ICCID(短号),每行一组"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="themed-button" @click="importModel"
          >导入excel模板</el-button
        >
        <el-button
          type="primary"
          class="themed-button"
          @click="exportTemplateExcel('importIccidOrShort.xls')"
          >导出excel模板</el-button
        >
        <el-button
          type="primary"
          class="themed-button"
          @click="dialogBox = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  getAcceleratorPackageCardList,
  delCardById,
  cardNoExport,
  addCard,
  delCard,
} from "@/api/acceleratorPackage.js";
import { messageBox, confirmBox } from "@/utils/common.js";

import Utils from "@/utils/utils.js";
import { exportTemplate } from "@/api/statistics.js";

export default {
  name: "acceleratorPackageCardList",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        iccid: null,
        shortIccid: null,
      },
      tabledata: [],
      //   formLabelWidth: "200px",
      dialogBox: false,
      dialogTitle: "",
      iccids: "",
    };
  },
  created() {
    this.getCardList();
  },
  components: {},
  computed: {
    ...mapGetters(["currentRole"]),
  },
  methods: {
    exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
    batchUpdate() {
      this.dialogBox = true;
      this.dialogTitle = "批量删除";
      this.iccids = "";
      this.$refs["file"].value = "";
    },

    addPool() {
      this.dialogBox = true;
      this.dialogTitle = "批量导入";
      this.iccids = "";
      this.$refs["file"].value = "";
    },
    deleteRow(iccid) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delCardById({ iccid }).then((res) => {
          this.getCardList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
    importModel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                
                this.comfirmImport()
            })
        },
        comfirmImport(){
     
            let file = this.$refs["file"].files[0];
          if (file) {
            let fileName = file.name;
            let index = fileName.lastIndexOf(".");
            if ( index < 0 || !(fileName.substring(index + 1, fileName.length) == "xls" || fileName.substring(index + 1, fileName.length) == "xlsx" )) {
              messageBox(this, "文件格式错误");
              return;
            } else if (!!Utils.checkFile(file)) {
              if (Utils.checkFile(file) == 1) {
                messageBox(this, "上传文件不能超过20M");
                return;
              } else if (Utils.checkFile(file) == 2) {
                messageBox(this, "上传文件名称长度不能超过36个字符");
                return;
              }
            } else {
              let params = new FormData();
              params.append("fileurl", file);
                  if(this.dialogTitle == "批量导入"){
                    addCard(params).then((res) => {
                if (res.system_result_key == "0") {
                  this.$alert("导入成功，请到导入记录中下载", "提示", {
                    confirmButtonText: "确定",
                  }).then(() => {
                    this.dialogBox = false;
                    this.$router.push("/tob/i_import_list");
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.system_result_message_key,
                    type: "error",
                  });
                }
              });
                  }else{
             delCard(params).then((res) => {
                if (res.system_result_key == "0") {
                  this.$alert("导入成功，请到导入记录中下载", "提示", {
                    confirmButtonText: "确定",
                  }).then(() => {
                    this.dialogBox = false;
                    this.$router.push("/tob/i_import_list");
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.system_result_message_key,
                    type: "error",
                  });
                }
              });
                  }
            }
            
          } else if (!!this.iccids) {
            let param = new FormData();
            param.append("iccids", this.iccids);
              if(this.dialogTitle == "批量导入"){
               addCard(param).then((res) => {
              if (res.system_result_key == "0") {
                this.$alert("添加成功", "提示", {
                  confirmButtonText: "确定",
                }).then(() => {
                  this.dialogBox = false;
                });
                this.getCardList();
              } else {
                this.$message({
                  showClose: true,
                  message: res.system_result_message_key,
                  type: "error",
                });
              }
            });
              }else{
                   delCard(param).then((res) => {
              if (res.system_result_key == "0") {
                this.$alert("添加成功", "提示", {
                  confirmButtonText: "确定",
                }).then(() => {
                  this.dialogBox = false;
                });
                this.getCardList();
              } else {
                this.$message({
                  showClose: true,
                  message: res.system_result_message_key,
                  type: "error",
                });
              }
            });
              }
            
          } else {
            messageBox(this, "请选择文件或输入ICCID");
            return;
          }
        
        },
   
   

    exportList() {
      //导出
      let params = {};
      if (!!this.formInline.iccid) {
        params.iccid = this.formInline.iccid;
      }
      if (!!this.formInline.shortIccid) {
        params.shortIccid = this.formInline.shortIccid;
      }

      cardNoExport(params).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },

    getCardList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };

      if (!!this.formInline.iccid) {
        params.iccid = this.formInline.iccid;
      }
      if (!!this.formInline.shortIccid) {
        params.shortIccid = this.formInline.shortIccid;
      }

      // Object.assign(params, this.formInline);
      getAcceleratorPackageCardList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getCardList();
    },
    onPageChange(page) {
      this.page = page;
      this.getCardList();
    },
    searchData() {
      this.page = 1;
      this.getCardList();
    },
  },
};
</script>

<style>

/* .acceleratorPackageCardList .el-form-item__label {
  vertical-align: top;
} */

.acceleratorPackageCardList  .el-form--inline .el-form-item__content{vertical-align: middle;}
</style>
