<template>
	<div class="singleOperationStrategy fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">单卡运营策略</span>
			<el-form inline ref="singleOperationStrategyForm" v-model="singleOperationStrategyFormData"
				class="right themed-form search-form" style="height: auto;line-height: 40px;">
			<el-form-item label="短号" label-width="120px">
          <el-input
            v-model="singleOperationStrategyFormData.shortIccid"
            placeholder="输入短号"
          ></el-input>
        </el-form-item>
        <el-form-item label="卡号" label-width="120px">
          <el-input
            v-model="singleOperationStrategyFormData.iccid"
            placeholder="输入卡号"
            class="iccid-width"
          ></el-input>
        </el-form-item>
		
				<el-form-item label="虚拟运营商">
					<el-select v-model="singleOperationStrategyFormData.accountId">
						<el-option value="" label="全部">全部</el-option>
						<el-option v-for="item in virtualAgentList" :key="item.id" :label="item.accountName"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="运营策略">
					<el-select v-model="singleOperationStrategyFormData.strategyId">
						<el-option value="" label="全部">全部</el-option>
						<el-option v-for="item in operStrategyList" :key="item.id" :label="item.name"
  							:value="item.id">
  						</el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="类型">
                 <el-select v-model="singleOperationStrategyFormData.importType">
					<el-option value="" label="全部">全部</el-option>
					<el-option label="单卡" value="1"></el-option>
                    <el-option label="批量" value="2"></el-option>
				</el-select>
               </el-form-item>


				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询
					</el-button>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="add" icon="el-icon-plus" class="themed-button">新增</el-button>
				</el-form-item>
				<el-form-item>
					 <el-button
            type="primary"
            @click="exportAdd"
            icon="el-icon-plus"
            class="themed-button"
            >导入</el-button
          >
				</el-form-item>
				 

			</el-form>
		</div>

		<div class="content-box">
			<el-table border ref="table" stripe :max-height="maxTableHeight" :data="tableData" tooltip-effect="dark"
				style="width: 100%">
                <el-table-column show-overflow-tooltip prop="iccid" label="卡号" min-width="120">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="shortIccid" label="短号" min-width="120"/>
				<el-table-column show-overflow-tooltip prop="accountName" label="虚拟运营商" min-width="120">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="strategyName" label="运营策略" min-width="120">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="importType" label="类型" min-width="120">
                <template slot-scope="scope">
						<span v-if="scope.row.importType=='1'">单卡</span>
                        <span v-else-if="scope.row.importType=='2'">批量</span>
					</template>
				</el-table-column>
				<!-- <el-table-column show-overflow-tooltip prop="priority" label="优先级" min-width="120">
				</el-table-column> -->
				<el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="150">
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="updateTime" label="更新时间" min-width="150">
				</el-table-column>
				<el-table-column label="操作" min-width="160">
					<template slot-scope="scope">
						<el-button size="mini" class="themed-button" type="info" @click="handleEdit(scope.row.id)">编辑
						</el-button>
						<el-button size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">
							删除</el-button>
					</template>
				</el-table-column>


			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize"
				style="text-align: left;margin-top: 20px;" background layout="total,sizes,prev, pager, next,jumper"
				:total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
		</div>

		<el-dialog title="新建" :visible.sync="dialogVisiableAdd" destroy-on-close>
			<el-form label-position="right" label-width="120px" :model="addForm" ref="addForm">
                 <el-form-item label="卡号/短号" prop="iccid">
                 <el-input  v-model="addForm.iccid"   placeholder="输入卡号"   class="iccid-width"></el-input>
            </el-form-item>
				<el-form-item label="虚拟运营商" prop="accountId">
					<el-select v-model="addForm.accountId" placeholder="请选择" filterable>
						<el-option v-for="item in virtualAgentList" :key='item.index' :label="item.accountName"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
			<el-form-item label="运营策略"  prop="strategyId">
  					<el-select v-model="addForm.strategyId" placeholder="请选择运营策略" filterable>
  						<el-option v-for="item in operStrategyList" :key="item.id" :label="item.name"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="sureAdd('addForm')">确 认</el-button>
				<el-button class="themed-button" type="primary" @click="dialogVisiableAdd = false">关 闭</el-button>
			</div>
		</el-dialog>


		<el-dialog title="编辑" :visible.sync="dialogVisiableEdit" destroy-on-close>
			<el-form label-position="right" label-width="120px" :model="editForm" ref="editForm">
                 <el-form-item label="卡号" prop="iccid">
                 <el-input  v-model="editForm.iccid"   placeholder="输入卡号"   class="iccid-width"></el-input>
                 </el-form-item>
				<el-form-item label="虚拟运营商" prop="accountId">
					<el-select v-model="editForm.accountId" placeholder="请选择" filterable>
						<el-option v-for="item in virtualAgentList" :key='item.index' :label="item.accountName"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="运营策略"  prop="strategyId">
  					<el-select v-model="editForm.strategyId" placeholder="请选择运营策略" filterable>
  						<el-option v-for="item in operStrategyList" :key="item.id" :label="item.name"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="sureEdit('editForm')">确 认</el-button>
				<el-button class="themed-button" type="primary" @click="dialogVisiableEdit = false">关 闭</el-button>
			</div>
		</el-dialog>

		<el-dialog title="导入" :visible.sync="dialogVisiableExport" destroy-on-close>
			<el-form label-position="right" label-width="150px" :model="exportForm" ref="exportForm">
               
			
			<el-form-item label="批量运营策略" >
  					<el-select v-model="exportForm.importId" placeholder="请选择批量运营策略" filterable clearable="">
  						<el-option v-for="item in batchStrategyList" :key="item.id" :label="item.name"
  							:value="item.id">
  						</el-option>
  					</el-select>
  				</el-form-item>
              
              <el-form-item label="导入Iccid文件(.xls)" >
                    <input class="file-input" type="file" ref='fileAddInput'/>
                    <div class="mt5">
                            <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                    </div>
                </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="sureExport('exportForm')">确 认</el-button>
                <el-button class="themed-button" type="info" @click="exportTemplateExcel('importIccid.xls')">导出excel模板</el-button>
				<el-button class="themed-button" type="primary" @click="dialogVisiableExport = false">关 闭</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import resize from '@/mixins/resize.js'
	import Utils from '@/utils/utils.js'
    import {messageBox,confirmBox} from '@/utils/common.js'
	import {
		agentOperatorList
	} from '@/api/order.js'
	import {
		virtualStrategyCardList,
		virtualStrategyCardAdd,
        virtualStrategyCardEdit,
        virtualStrategyCardById,
        virtualStrategyCardDelete,
		operatingStrategyList,
		virtualStrategyBatchList,
		importIccidAsync
	} from "@/api/virtualCard.js"
	import {exportTemplate} from '@/api/statistics.js'
	export default {
		name: 'singleOperationStrategy',
		mixins: [resize],
		data() {
			return {
				singleOperationStrategyFormData: {
                    iccid:"",
					accountId: '',
                    strategyId:"",
                    importType:"",
					batchId:"",
					shortIccid:"",
				},
				tableData: [],
				page: 1,
				pageSize: 15,
				total: 0,
				accountIdList: [],
                operStrategyList:[],
				virtualAgentList: [],
				dialogVisiableAdd: false,
				dialogVisiableEdit: false,
				addForm: {
					iccid:"",
                    accountId:"",
                    strategyId:""
				},
				editForm: {
					id: '',
					iccid:"",
                    accountId:"",
                    strategyId:""
				},
				dialogVisiableExport:false,
				batchStrategyList:[],
				exportForm:{
                 importId:"",
				}
			}
		},
		mounted() {
        //  let params = this.$route.query
		let params = this.$route.params 
        console.log(params)
        if(params.batchId&&params.batchId!=':batchId'){
            this.singleOperationStrategyFormData.batchId= params.batchId
        }else{
			 this.singleOperationStrategyFormData.batchId= ""
		}

			this.getAgentOperatorList()
            this.getStrategyList();
			this.getSingleOperationStrategyList()
			
		},
		methods: {
				sureExport(formName) {
                if(this.exportForm.importId==""){
                     messageBox(this,'请选择批量运营策略')
                     return 
                }
              

                  let file = this.$refs['fileAddInput'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            param.append("fileurl",file)
                            param.append("importId",this.exportForm.importId)
                         
                            importIccidAsync(param).then(res=>{
								  if (res.system_result_key == '0') {
                                this.$message({
								type: 'success',
								message:'导入成功'
                            });
                            
							this.$refs[formName].resetFields()
							this.$refs['fileAddInput'].value = ''
                            this.dialogVisiableExport = false
                            this.getSingleOperationStrategyList() 
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }

                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
			
			},
			      exportTemplateExcel(name){
            exportTemplate({'name':name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },
			getVirtualStrategyBatchConfigList() {
				let params = {
					pageNo: 1,
					pageSize: 100000,
				}
				this.batchStrategyList = []
				virtualStrategyBatchList(params).then(res => {
					this.batchStrategyList = res.rows
				    console.log(res.rows)
				})
			},
			exportAdd(){
				this.getVirtualStrategyBatchConfigList()
                this.dialogVisiableExport = true
                this.exportForm.importId = ""
				  this.$nextTick(() => {
       this.$refs["fileAddInput"].value = "";
      });
				
			},
            	getStrategyList() {
  				let params = {
  					pageNo: this.page,
  					pageSize: 20,
  				};
  				operatingStrategyList(params).then((res) => {
  					this.operStrategyList = res.rows;
  				});
  			},
			getAgentOperatorList() {
				agentOperatorList().then(res => {
					this.accountIdList = res
					this.virtualAgentList = res.filter(item => {
						return item.accountName.includes('虚拟')
					})
				})
			},
			getSingleOperationStrategyList() {
				let params = {
					pageNo: this.page,
					pageSize: this.pageSize
				}
				Object.assign(this.singleOperationStrategyFormData, params)
				virtualStrategyCardList(this.singleOperationStrategyFormData).then(res => {
					this.tableData = res.rows
					this.total = res.count
				})
			},
			onPageChange(page) {
				this.page = page
				this.getSingleOperationStrategyList()
			},
			handleSizeChange(size) {
				this.pageSize = size
				this.getSingleOperationStrategyList()
			},

			onSearch() {
				this.page = 1
				this.getSingleOperationStrategyList()
			},


			add() {
				this.dialogVisiableAdd = true
			},

			sureAdd(formName) {
                if(this.addForm.iccid==""){
                     messageBox(this,'请输入iccid/短号')
                     return 
                }
                if(this.addForm.accountId==""){
                 messageBox(this,'请选择运营商')
                     return 
                }
                if(this.addForm.strategyId==""){
                 messageBox(this,'请选择运营策略')
                     return 
                }
				virtualStrategyCardAdd(this.addForm).then(res => {
							this.$message({
								showClose: true,
								message: '新建成功',
								type: 'success',
								center: true
							})
							this.$refs[formName].resetFields()
							this.dialogVisiableAdd = false
							this.getSingleOperationStrategyList()
						})
			},

			handleEdit(id) {
				this.dialogVisiableEdit = true
				virtualStrategyCardById({
					id
				}).then(res => {
					this.editForm.id = res.id
					this.editForm.accountId = res.accountId
					this.editForm.strategyId = res.strategyId
                    this.editForm.iccid = res.iccid
				})
			},

			sureEdit(formName) {
                 if(this.editForm.iccid==""){
                     messageBox(this,'请输入iccid')
                     return 
                }
                if(this.editForm.accountId==""){
                 messageBox(this,'请选择运营商')
                     return 
                }
                if(this.editForm.strategyId==""){
                 messageBox(this,'请选择运营策略')
                     return 
                }
				virtualStrategyCardEdit(this.editForm).then(res => {
							this.$message({
								showClose: true,
								message: '修改成功',
								type: 'success',
								center: true
							})
							this.$refs[formName].resetFields()
							this.dialogVisiableEdit = false
							this.getSingleOperationStrategyList()
						})
			},

			handleDelete(id) {
				this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					virtualStrategyCardDelete({
						'id': id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});
						this.getSingleOperationStrategyList()
					})

				})
			}
		},
	}
</script>

<style>
	.singleOperationStrategy .el-form-item__label {
		vertical-align: top;
	}
</style>
