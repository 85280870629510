<template>
  <div class="libraryNumManagement fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">号码库号码管理</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
       style="line-height: 40px;height:auto;"
      >
        <el-form-item label="号码库">
          <el-select
            placeholder="选择号码库"
            v-model="formInline.setId"
            filterable
            clearable
            @change="onSearch"
          >
            <el-option
              v-for="item in numberList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代理商" v-if="currentRole==0">
          <el-select
            placeholder="选择代理商"
            v-model="formInline.tenantId"
            filterable
            clearable
            @change="onSearch"
          >
            <el-option
              v-for="item in operationAgent"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="号码">
          <el-input v-model="formInline.no"  clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item v-if="currentRole==0">
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="importNum"
            >导入号码</el-button
          >
        </el-form-item>
         <el-form-item >
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="assign"
            >分配号码</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
       <el-table-column     label="号码" prop="no" align="left">
          </el-table-column>
          <el-table-column     label="号码库" prop="setName" align="left">
          </el-table-column>
          <el-table-column     label="代理商" prop="tenantName" align="left" v-if="currentRole==0">
          </el-table-column>
          <el-table-column     label="导入时间" prop="createTime" align="left">
          </el-table-column>
          <el-table-column     label="操作账号" prop="createBy" align="left">
          </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
        <el-form :model="addForm" :rules="rules" ref="addForm">
           <el-form-item label="号码:" label-width="80px" prop="nos">
            <el-input
              v-model="addForm.nos"
              type="textarea"
              placeholder="多个号码以空格或换行分隔输入"
              :autosize="{ minRows: 2, maxRows: 20 }"
            ></el-input>
            <!-- <el-input v-model="importForm.nos" autocomplete="off" placeholder=""  ></el-input> -->
          </el-form-item>
           <el-form-item label="分配项：" label-width="80px" v-if="currentRole==0">
            <el-radio v-model="addForm.assignType" label="2">分配给代理商</el-radio>
            <el-radio v-model="addForm.assignType" label="1">分配到号码库</el-radio>
         </el-form-item>
          <el-form-item label="号码库：" label-width="80px" v-if="addForm.assignType=='1'">
          <el-select
            placeholder="选择号码库"
            v-model="addForm.setId"
            filterable
            clearable
          >
            <el-option
              v-for="item in numberList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代理商：" label-width="80px" v-if="addForm.assignType=='2'&&currentRole==0">
          <el-select
            placeholder="选择代理商"
            v-model="addForm.tenantId"
            filterable
            clearable
          >
            <el-option
              v-for="item in operationAgent"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
         
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="dialogFormVisible = false"
            type="primary"
            class="themed-button"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="sureAdd('addForm')"
            class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <el-dialog
        title="导入号码"
        :visible.sync="dialogVisible"
        v-if="dialogVisible"
      >
        <el-form :model="importForm" ref="importForm">
          <!-- <el-form-item label="">
               <el-radio v-model="radio" label="1">备选项</el-radio>
  <el-radio v-model="radio" label="2">备选项</el-radio>
  </el-form-item> -->
   <el-form-item label="号码:" label-width="80px">
            <el-input
              v-model="importForm.nos"
              type="textarea"
              placeholder="多个号码以空格或换行分隔输入"
              :autosize="{ minRows: 2, maxRows: 20 }"
            ></el-input>
            <!-- <el-input v-model="importForm.nos" autocomplete="off" placeholder=""  ></el-input> -->
          </el-form-item>
            <el-form-item label="号码库：" label-width="80px">
          <el-select
            placeholder="选择号码库"
            v-model="importForm.setId"
            filterable
            clearable
          >
            <el-option
              v-for="item in numberList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代理商：" label-width="80px">
          <el-select
            placeholder="选择代理商"
            v-model="importForm.tenantId"
            filterable
            clearable
          >
            <el-option
              v-for="item in operationAgent"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div style="color:#f00;">建议号码库和代理商二选一</div>
        </el-form-item>
         
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialg" type="primary" class="themed-button"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="importAdd('importForm')"
            class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    
    </div>
  </div>
</template>

<script>
import { getAgentSwitchList } from "@/api/agentManagement.js";
import { mapGetters } from "vuex";
import QRCode from "qrcodejs2";
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import {
  numbelList,
  showNo,
  importNo,
  checkImport,
  distributeNo
} from "@/api/configurationManagement.js";
export default {
  name: "libraryNumManagement",
  mixins: [resize],
  data() {
    return {
      numberList: [],
      operationAgent: [],
      formInline: {
        tenantId: null,
        no: "",
        setId:null,
      },
      tableData: [],
      dialogFormVisible: false,
      addForm: {
        nos: "",
        setId:null,
        tenantId:"",
        assignType:"1",
      },

      rules: {
        nos: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
        ],
      },
      page: 1,
      pageSize: 15,
      total: 0,
      dialogTitle: "",
      formLabelWidth: "110px",
      importForm: {
        nos: "",
        repeatDeal:"",
        setId:null,
        tenantId:null,
      },
      dialogVisible: false,
     
     
    };
  },
  computed: {
    ...mapGetters(["currentRole",]),
  },
  created() {
    this.getAgentSwitchList();
    this.getnumbelList();
    this.getLibNumList();
  },
  methods: {
    getLibNumList(){
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      if(!this.formInline.setId){
        this.formInline.setId=null
      }
      if(!this.formInline.tenantId){
        this.formInline.tenantId=null
      }
      Object.assign(this.formInline, params);
      showNo(this.formInline).then((res) => {
        console.log(res);
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
   
    closeDialg() {
      this.dialogVisible = false;
      this.importForm.nos =""
      this.importForm.repeatDeal = ""
      this.importForm.setId = null
      this.importForm.tenantId = null
    },
    importNum() {
      this.dialogVisible = true;
      this.importForm.nos =""
      this.importForm.repeatDeal = ""
      this.importForm.setId = null
      this.importForm.tenantId = null
    },
    importAdd(form) {
      if (this.importForm.nos.trim() == "") {
        this.$message({
          showClose: true,
          message: "号码不能为空",
          type: "warning",
        });
        return;
      }
      checkImport({nos:this.importForm.nos}).then(res=>{
         if(res.isRepeat){//存在重复
              this.$confirm('存在重复号码['+ res.repeatNo +']，请选择重复号码的处理方式', '提示', {
          confirmButtonText: '全部覆盖',
          cancelButtonText: '全部跳过',
          type: 'warning'
        }).then(() => {
          this.importNo(1)
        }).catch(() => {
         this.importNo(2)   
        });
         }else{
           this.importNo()
         }
      })

   
    },
    importNo(num){
      if(num==1){
       this.importForm.repeatDeal = 1
      }else if(num==2){
      this.importForm.repeatDeal = 2
      }
      console.log(this.importForm.setId,373,this.importForm.tenantId,!this.importForm.setId)
       if(!this.importForm.setId){
        this.importForm.setId = null
      }
      if(!this.importForm.tenantId){
        this.importForm.tenantId = null
      }
       importNo(this.importForm)
        .then((res) => {
          console.log(res, 252);
          if (res.system_result_key == 0) {
            this.$message({
              showClose: true,
              message: res.import_count + "个号码导入成功！",
              type: "success",
            });
            this.dialogVisible = false;
            this.getLibNumList();
          } else {
            this.$message({
              showClose: true,
              message: "导入失败",
              type: "warning",
            });
          }
        })
        .catch((error) => {
          this.$message({
            showClose: true,
            message: error,
            type: "warning",
          });
        });
    },
    onSearch() {
      this.page = 1;
      this.getLibNumList();
    },
    onPageChange(page) {
      this.page = page;
      this.getLibNumList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getLibNumList();
    },

   

    getnumbelList() {
      let params = {
        pageSize: 100000,
        pageNo: 1,
      };
      numbelList(params).then((res) => {
        console.log(res);
        this.numberList = res.rows;
      });
    },
    getAgentSwitchList() {
      getAgentSwitchList({}).then((responseResult) => {
        //console.log(responseResult)
        if (responseResult.slide_msg_key == 1) {
          this.$alert(responseResult.slide_msg_message_key, "消息提示", {
            confirmButtonText: "确定",
          });
        }
        this.operationAgent = responseResult;
      });
    },

    assign() {
      this.dialogTitle = "分配号码";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
     this.addForm.nos = ""
     this.addForm.assignType ="1"
     this.addForm.setId = null
     this.addForm.tenantId = null
    },
    sureAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          distributeNo(this.addForm).then((responseResult) => {
            if (responseResult.slide_msg_key == 1) {
              this.$message({
                showClose: true,
                message: responseResult.slide_msg_message_key,
                type: "warning",
              });
            }
            if (responseResult.system_result_key == 0) {
            this.$message({
              showClose: true,
              message: responseResult.import_count + "个号码分配成功！",
              type: "success",
            });
           this.getLibNumList();
            this.dialogFormVisible = false;
          } else {
            this.$message({
              showClose: true,
              message: "分配失败",
              type: "warning",
            });
          }
            
          });
        } else {
          return false;
        }
      });
    },
    
  },
};
</script>

<style scoped>
/* .libraryNumManagement .el-form-item__label {
  vertical-align: top;
} */
/* .libraryManagement  /deep/  .el-table__header-wrapper{display:none;} */
.libraryNumManagement /deep/  .el-form--inline .el-form-item__content{vertical-align: middle;}
</style>
