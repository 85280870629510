<template>
    <div class="withdrawal-application fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">提现申请</span>
            <el-form inline ref="withdrawalApplicationForm" v-model="withdrawalApplicationData" class="right themed-form search-form" style="height: 40px;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="withdrawalApplicationData.pageSearchKeys" placeholder="代理商名称"></el-input>
                </el-form-item>
                <el-form-item label="结算状态">
                    <search-select :optionsMetaAll="optionsjsStatus" v-model="withdrawalApplicationData.jsStatus"></search-select>
                </el-form-item>
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="核算周期" v-model="withdrawalApplicationData.accountingDate" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="agentName"
                    label="代理商名称"
                    min-width="120">
                </el-table-column>
                 <el-table-column
                    prop="accountingDate"
                    label="核算周期"
                    min-width="98">
                </el-table-column>
                 <el-table-column
                    prop="accountingTime"
                    label="核算时间"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    prop="orderCount"
                    label="订单总数"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    label="订单总额"
                    min-width="98">
                    <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.orderMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="套餐总额"
                    min-width="98">
                    <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.setMealMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="代理差价"
                    min-width="98">
                    <template slot-scope="scope">
                        <span>{{(scope.row.orderMoney - scope.row.setMealMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="返现金额"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.accountingMoney">{{parseFloat(scope.row.accountingMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="秒返金额"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.mfMoney">{{parseFloat(scope.row.mfMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可提现金额"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.txMoney">{{parseFloat(scope.row.txMoney).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    min-width="98">
                     <template slot-scope="scope">
                        <span v-if="scope.row.status==1">待结算</span>
                        <span v-else-if="scope.row.status==2">申请结算</span>
                        <span v-else-if="scope.row.status==3">结算完成</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createUser"
                    label="结算人"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="createDatetime"
                    label="结算时间"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="150">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleShowDetail(scope.row.id)">订单详情</el-button>
                        <el-button v-if="scope.row.status==1" size="mini" class="themed-button" type="primary" @click="withdrawRecord(scope.row.id)">提现</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {withdrawalApplicationList,exportWithdrawalOrder} from '@/api/order.js'
import resize from '@/mixins/resize.js'
export default {
    name:'withdrawalApplication',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return {
            withdrawalApplicationData:{
                pageSearchKeys:'',
                jsStatus:'',
                accountingDate:''

            },
            optionsjsStatus:[
                {label:"全部",value:''},
                {label:"待结算",value:'1'},
                {label:"申请结算",value:'2'},
                {label:"结算完成",value:'3'},
                {label:"核算异常",value:'4'}
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getWithdrawalApplicationList()
    },
    methods:{
        getWithdrawalApplicationList(){
            let parame = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.withdrawalApplicationData,parame)
            withdrawalApplicationList(this.withdrawalApplicationData).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getWithdrawalApplicationList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getWithdrawalApplicationList()
        },
        onSearch(){
            this.page = 1
            this.getWithdrawalApplicationList()
        },
        exportExcel(){
            exportWithdrawalOrder(this.withdrawalApplicationData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        handleShowDetail(id){
            this.$router.push({path:'/orderManagement/orderDetail',query:{orderMoneyId:id}})
        },
        withdrawRecord(id){

        },
    }
}
</script>
<style>
    .withdrawal-application .el-form-item__label{
        vertical-align: top;
    }
</style>