/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function pinOrderDiscountList(params){
    return request({
        url: '/pin/discount/list.do?',
        method: 'post',
        data:params
    })
}

export function getPinDiscountDetail(params){
    return request({
        url: '/pin/discount/findById.do?',
        method: 'post',
        data:params
    })
}

export function updatePinDiscount(params){
    return request({
        url: '/pin/discount/updateDiscount.do?',
        method: 'post',
        data:params
    })
}

export function delPinDiscountById(params){
    return request({
        url: '/pin/discount/delete.do?',
        method: 'post',
        data:params
    })
}

export function addPinDiscount(params){
    return request({
        url: '/pin/discount/insert.do?',
        method: 'post',
        data:params
    })
}





