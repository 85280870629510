<template>
  <div class="orderReviewList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">订单审核列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form "
        style="height: auto; line-height: 40px"
      >
       <el-form-item label="订单号:">
          <el-input
            v-model="formInline.orderNumber"
            placeholder="请输入订单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="奖励合伙人">
					<el-select v-model="formInline.ifPartnerId" placeholder="奖励合伙人" clearable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in groupList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
					</el-select>
		</el-form-item>
        	<el-form-item label="一级合伙人">
					<el-select v-model="formInline.firstPartnerId" placeholder="一级合伙人" clearable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in groupList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
					</el-select>
		</el-form-item>
        <el-form-item label="上级合伙人">
					<el-select v-model="formInline.secondPartnerId" placeholder="上级合伙人" clearable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in groupList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
					</el-select>
		</el-form-item>
        	<el-form-item label="客户">
					<el-select v-model="formInline.agentId" placeholder="客户" clearable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in customerList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
		</el-form-item>

       	<el-form-item label="订单状态">
            <!-- 状态 0 待审核 1 已审核 -->
					<el-select v-model="formInline.status"  clearable >
						<el-option label="全部" value=""></el-option>
						<el-option label="待审核" value="0"></el-option>
                        <el-option label="已审核" value="1"></el-option>
					</el-select>
				</el-form-item>
                   <el-form-item label="开始时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择开始时间" v-model="formInline.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择结束时间" v-model="formInline.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
				</el-form-item>
        	<el-form-item >
					 <el-checkbox v-model="formInline.updateNull">已打款</el-checkbox>
				</el-form-item>
       
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
         <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="exportExcel">导出excel</el-button>
				</el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="agentName" label="客户"> </el-table-column>
        <el-table-column prop="orderNumber" label="订单号"></el-table-column>
        <el-table-column prop="orderMoney" label="订单金额"> </el-table-column>
       <el-table-column prop="type" label="订单类型">
 <template slot-scope="scope">
  {{getOrderType(scope.row.type)}}
                </template>
           </el-table-column>
        <el-table-column prop="firstRebateRate" label="一级奖励比例"> </el-table-column>
         <el-table-column prop="firstRebateMoney" label="一级奖励金额"> </el-table-column>
        <el-table-column prop="secondRebateRate" label="二级奖励比例"> </el-table-column>
         <el-table-column prop="secondRebateMoney" label="二级奖励金额"> </el-table-column>
         <el-table-column prop="firstPartner" label="一级合伙人"> </el-table-column>
         <el-table-column prop="secondPartner" label="上级合伙人"> </el-table-column>
         <el-table-column prop="createTime" label="创建时间"> </el-table-column>
          <el-table-column label="备注" prop="remark" width="90px">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.remark" @change="((val)=>{updateSort(val,scope.row.id)})"></el-input>
                </template>
            </el-table-column>
         <el-table-column prop="status" label="订单状态"> 
              <template slot-scope="scope">
                <!--  0 待审核 1已审核 -->
                   <span v-if="scope.row.status==0">待审核</span>
                   <span v-else-if="scope.row.status==1">已审核</span>
                </template>
         </el-table-column>
         <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
          <el-table-column prop="promoterName" label="操作"> 
                 <template slot-scope="scope">
                    <el-button size="mini" class="themed-button" type="primary"  v-if="scope.row.status==0" @click="handleCheck(scope.row)">审核</el-button>
                </template>
          </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>

        <el-dialog title="审核" :visible.sync="dialogFormVisible" v-if="dialogFormVisible">
  <el-form :model="form">
    <el-form-item label="一级奖励比例:" :label-width="formLabelWidth">
      <el-input-number v-model="form.firstRebateRate"  @change="handleChange"     :step="1" step-strictly :min="0" :max="100"></el-input-number> %  
    </el-form-item>
     <el-form-item label="一级奖励金额:" :label-width="formLabelWidth">
      <span>{{form.firstRebateMoney}} 元</span>
      <!-- <el-input-number v-model="form.firstRebateMoney" :step="1" step-strictly :min="0" :max="100"></el-input-number> %   -->
    </el-form-item>
   
    <el-form-item label="二级奖励比例:" :label-width="formLabelWidth"  v-if="!ishide">
     <el-input-number v-model="form.secondRebateRate"   @change="handleChangeTwo"      :step="1" step-strictly :min="0" :max="100"></el-input-number>%
    </el-form-item>
    <el-form-item label="二级奖励金额:" :label-width="formLabelWidth" v-if="!ishide">
      <span>{{form.secondRebateMoney}} 元</span>
     <!-- <el-input-number v-model="form.secondRebateMoney" :step="1" step-strictly :min="0" :max="100"></el-input-number>% -->
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
     <el-button type="primary" @click="sureConfig" >确 定</el-button>
    <el-button @click="dialogFormVisible = false">关闭</el-button>
  
  </div>
</el-dialog>
    </div>
 
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  getOrderReviewList,
  orderExportExcelAsync,
  enterpriseList,
  partnerList,
  authTobRebateAuth,
  updateRemark
} from "@/api/partner.js";
import {messageBox,confirmBox } from '@/utils/common.js' 

export default {
  name: "orderReviewList",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
       agentId:"",
       startTime:"",
       endTime:"",
       orderNumber:"",
       status:"",
       firstPartnerId:"",
       secondPartnerId:"",
       ifPartnerId:"",
       updateNull:false,
      },
      tabledata: [],
      formLabelWidth: "200px",
     groupList:[],
     customerList:[],
     dialogFormVisible:false,
     form:{
       firstRebateRate:"",
       firstRebateMoney:"",
       secondRebateRate:"",
       secondRebateMoney:"",
       id:"",
     },
     currentMoney:0,//当前订单金额
     ishide:false
    };
  },
  created() {
   this.getEnterpriseList();//获取客户列表
    this.getPartnerList();//获取合伙人列表
    
  },
  mounted(){
   let params = this.$route.params
//       let params =  {startTime:row.startTime,endTime:row.endTime,ifPartnerId:row.partnerId,status:row.orderStatus}
   console.log(params)
        // console.log(params?JSON.parse(params):"")
        if(params.partnerId&&params.partnerId!=':partnerId'){
            let objectStr =  JSON.parse(params.partnerId)
            //  agentId:"",
    //    startTime:"",
    //    endTime:"",
    //    orderNumber:"",
    //    status:"",
    //    firstPartnerId:"",
    //    secondPartnerId:"", 
            this.formInline.startTime = objectStr.startTime
            this.formInline.endTime = objectStr.endTime
            this.formInline.ifPartnerId = parseInt(objectStr.ifPartnerId)   //合伙人
            this.formInline.status =  objectStr.status +""
            console.log(226)
             this.getOrderReviewList();
        }else{
		   this.getOrderReviewList();
		}

  },
  methods: {
          updateSort(val,id){
             if(val.length>20){
                 messageBox(this,'输入长度最大为20个字符','提示')
              return;
             }
            
          
            let postData = {
                    id, 
                  remark:val,
                };
         
          
            updateRemark(postData).then((res)=>{
               this.getOrderReviewList()
            }).catch((res)=>{
                messageBox(this,'请求错误','操作繁忙请稍后重试或联系客服。')
            })
        },
    sureConfig(){//
    if(this.form.secondRebateRate){
        this.form.secondRebateRate = this.form.secondRebateRate/100
    }
    this.form.firstRebateRate = this.form.firstRebateRate/100
    authTobRebateAuth(this.form).then(res=>{
       console.log(res)
       this.dialogFormVisible = false
       this.getOrderReviewList()
    })
    },
    handleCheck(data){
      // firstRebateRate:"",
      //  firstRebateMoney:"",
      //  secondRebateRate:"",
      //  secondRebateMoney:"",
      //  id:"",
      this.currentMoney = data.orderMoney
       this.dialogFormVisible = true 
       this.form.firstRebateMoney  = data.firstRebateMoney
       this.form.firstRebateRate  = parseFloat(data.firstRebateRate )*100
       if(data.secondRebateRate==""){//隐藏 二级奖励设置
       this.form.secondRebateRate = "" 
       this.form.secondRebateMoney = "" 
       this.ishide  = true
       }else {
         this.ishide  = false
       this.form.secondRebateRate = parseFloat(data.secondRebateRate)*100  
       this.form.secondRebateMoney = data.secondRebateMoney
       }
       
       this.form.id = data.id
    },
     handleChange(value) {//firstRebateRate 变更
        console.log(value);
        this.form.firstRebateMoney  = (parseFloat(this.currentMoney)* parseInt(value)/100) .toFixed(2)
      },
      handleChangeTwo(value){//secondRebateRate 变更
        console.log(value);
         this.form.secondRebateMoney  = (parseFloat(this.currentMoney)* parseInt(value)/100) .toFixed(2)
      },
      getOrderType(type){
   let typeStr = ""
      //  1.充值，2.下单，3.收益转入, 4.公众号下单,5.接口下单,6订单退款转入,7.分配余额,8.分配余额
      if(type==1){
        typeStr="充值"
      }else if(type==2){
 typeStr="下单"
      }else if(type==3){
 typeStr="收益转入"
      }else if(type==4){
 typeStr="公众号下单"
      }else if(type==5){
 typeStr="接口下单"
      }else if(type==6){
 typeStr="订单退款转入"
      }else if(type==7){
 typeStr="分配余额"
      }else if(type==8){
 typeStr="TOB二级支出扣减余额"
      }
      return typeStr
  },
     exportExcel(){
      let params = this.formInline
          params.updateNull =  this.formInline.updateNull? 1 :""
            orderExportExcelAsync(params).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
    getPartnerList() {
      let params = {
        pageSize: 100000,
        pageNo: 1,
      };
      
      partnerList(params).then((res) => {
        this.groupList = res.rows
        console.log(this.groupList)
      
      });
    },
       getEnterpriseList() {
      let params = {
        pageSize: 100000,
        pageNo: 1,
      };
     
      enterpriseList(params).then((res) => {
        this.customerList = res.rows;
      });
    }, 
    getOrderReviewList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      //  agentId:"",
      //  startTime:"",
      //  endTime:"",
      //  orderNumber:"",
      //  status:"",
      //  firstPartnerId:"",
      //  secondPartnerId:"",
      //  ifPartnerId:"",
      //  updateNull:false,
      params.agentId = this.formInline.agentId
      params.startTime = this.formInline.startTime
      params.endTime = this.formInline.endTime
      params.orderNumber = this.formInline.orderNumber
      params.status = this.formInline.status
      params.firstPartnerId = this.formInline.firstPartnerId
      params.secondPartnerId = this.formInline.secondPartnerId
      params.ifPartnerId = this.formInline.ifPartnerId
      params.updateNull = this.formInline.updateNull? 1 :""
      // Object.assign(params, this.formInline);
      getOrderReviewList(params).then((res) => {
        /* 表格数据 */
        console.log(this.tabledata)
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getOrderReviewList();
    },
    onPageChange(page) {
      this.page = page;
      this.getOrderReviewList();
    },
    searchData() {
      this.page = 1;
      this.getOrderReviewList();
    },
  },
};
</script>

<style>
/* .right_agent {
  margin-right: 8px;
}
 .customerList  .el-date-editor.el-input{width:100%!important;}
.customerList .el-form-item__label {
  vertical-align: top;
} */
.orderReviewList  .el-form--inline .el-form-item__content{vertical-align: middle;}
</style>

