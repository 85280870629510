<template>
    <div class="history-modify-traffic fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">流量增减记录</span>
            <el-form inline ref="modifyTrafficForm" v-model="modifyTrafficFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="modifyTrafficFormData.iccidMark" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="modifyTrafficFormData.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>

                <el-form-item label="来源">
                    <search-select :optionsMetaAll="sourceOperators" v-model="modifyTrafficFormData.source"></search-select>
                </el-form-item>
                 
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    prop = "shortIccid"
                    label="短号"
                    min-width="120"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="createDateStr"
                    label="操作时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    prop="flowSize"
                    label="增减流量MB"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="relatedNcafDateBeginStr"
                    label="流量开始时间段"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    prop="relatedNcafDateEndStr"
                    label="流量结束时间段"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    prop="sourceStr"
                    label="来源"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="createUserName"
                    label="操作人"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="remark"
                    label="备注"
                    min-width="200"
                    >
                </el-table-column>
                  
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import resize from '@/mixins/resize.js'
import { modifyTraffic } from "@/api/history.js";
export default {
    name:'trafficIncreaseDecrease',
     components:{SearchSelect},
     mixins:[resize],
    data(){
        return {
            modifyTrafficFormData:{
                iccidMark:'',
                source:'',
                shortIccid:"",
            },
            sourceOperators:[
                {label:"全部",value:""},
                {label:"单个操作",value:"1"},
                {label:"批量操作",value:"2"},
                {label:"转卡",value:"3"},
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getModifyTrafficList()
    },
    methods: {
        getModifyTrafficList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.modifyTrafficFormData,params)
            modifyTraffic(this.modifyTrafficFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getModifyTrafficList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getModifyTrafficList()
        },

        onSearch(){
            this.page=1
            this.getModifyTrafficList()
        },
    },
}
</script>

<style>
    .history-modify-traffic .el-form-item__label{
        vertical-align: top;
    }
</style>