<template>
    <div class="update-operation-log fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">变更操作日志</span>
            <el-form inline ref="updateOperateForm" v-model="updateFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="updateFormData.iccid" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="updateFormData.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select filterable v-model="updateFormData.status">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="成功" value="1"></el-option>
                        <el-option label="失败" value="0"></el-option>
                    </el-select>
                </el-form-item>
                 
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>              
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="id"
                    label="ID"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountId"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="iccid"
                    label="ICCID号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="shortIccid"
                    label="短号"
                    min-width="130"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="type"
                    label="类型"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="data"
                    label="数据"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="状态"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if='scope.row.status==0'>失败</span>
                        <span v-else-if='scope.row.status==1'>成功</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="tryCount"
                    label="尝试次数"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="updateTimeStr"
                    label="更新时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="remark"
                    label="备注"
                    min-width="150"
                    >
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { updateOperationLog} from "@/api/history.js";
export default {
     mixins:[resize],
    data(){
        return {
            updateFormData:{
                iccid:'',
                status:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getUpdateOperationLog()
    },
    methods: {
        getUpdateOperationLog(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.updateFormData,params)
            updateOperationLog(this.updateFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },

        onPageChange(page){
			this.page = page
			this.getUpdateOperationLog()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getUpdateOperationLog()
        },

        onSearch(){
            this.page=1
            this.getUpdateOperationLog()
        },

    },
}
</script>

<style>
    .update-operation-log .el-form-item__label{
        vertical-align: top;
    }
</style>