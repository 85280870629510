<template>
	<div class="packageUsageStatic fadeInRight table-hook fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">套餐用量统计</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="开卡公司:">
					<el-select placeholder="开卡公司" v-model="searchIteam.currentCompany" filterable>
						<el-option label="全部" value=""></el-option>
						<el-option v-for="item in companyArr" :key="item.id" :label="item.accountName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="套餐名称：">
					<el-input v-model="searchIteam.packageName" placeholder="套餐名称" class="inputClass"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="info" @click="exportExcel" icon="el-icon-caret-left" class="themed-button">批量导出</el-button>
				</el-form-item>
				<iframe name="hidden_down_iframe" id="hidden_down_iframe" style="display: none;" src=""></iframe>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="synDate" label="日期">
				</el-table-column>
				<el-table-column prop="accountName" label="开卡公司">
				</el-table-column>
				<el-table-column prop="setMealName" label="套餐名">
				</el-table-column>
				<el-table-column prop="orderCount" label="当日订单数">
				</el-table-column>

				<el-table-column prop="cardCount" label="当日在用网卡数">
				</el-table-column>
				<el-table-column prop="todayUsedFlow" label="当日已用流量">
				</el-table-column>


				<el-table-column label="操作">
					<template slot-scope="scope">
						<span v-if="scope.row.cardStatus==0"><a title="点击查看详情" href='javaScript:;' @click='selectDetail(scope.row.accountId,scope.row.setMealId,scope.row.synDate,scope.row.type)' style="color: #337ab7;">详情</a></span>
						<!-- <router-link :to="{path:'/staticsManagement/detail',query:{name:'lili',age:18,height:1.60}}"
						 v-if="scope.row.cardStatus==0">详情</router-link> -->
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import onresize from "@/mixins/resize.js"
  import loading from '@/utils/Loading.js'
	import {
		trafficPoolGetComList,
		packageSaleExport,
		packgUsageStatList,
	} from "@/api/staticsManagement.js"

	export default {
		name:'packageUsageStatistics',
		mixins: [onresize],
		data() {
			return {
				companyArr: [],
				searchIteam: {},
				page: 1,
				pageSize: 15,
				total: 0,
				tabledata: [],
			}
		},
		created() {
			this.getCompanyList()
			let params = {
				payStatus: "1",
				type: "1",
				accountId: this.searchIteam.currentCompany,
				setMealName: this.searchIteam.packageName,
			}
			this.getTotalList(params)
		},
		methods: {
			getCompanyList() {
				let params = {
					payStatus: "1",
					type: "1",
					accountId: "",
					setMealName: "",
				}
				trafficPoolGetComList(params).then(res => {
					//console.log(res)
					this.companyArr = res
					//console.log(this.companyArr)
				})
			},
			getTotalList(params) {

				packgUsageStatList(params).then(res => {
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			exportExcel(){
							loading.show()
							let params = {
								payStatus: "1",
								type: "1",
								accountId: this.searchIteam.currentCompany,
								setMealName: this.searchIteam.packageName,
							}
							packageSaleExport(params).then(res=>{
								if (res.system_result_key == '0') {
									this.$alert('导出成功，请到导出记录中下载', '提示', {
									    confirmButtonText:'确定',
									}).then(()=>{
										this.$router.push('/tob/i_export_list')
									});
								}else{
									this.$message({
									    showClose: true,
									    message: res.system_result_message_key,
									    type: 'error'
									});
								}
							})
						},
			
			searchData() {
				let params = {
					payStatus: "1",
					type: "1",
					accountId: this.searchIteam.currentCompany,
					setMealName: this.searchIteam.packageName,
					pageSize: this.pageSize,
					pageNo: this.page
				}
				this.getTotalList(params)
			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					payStatus: "1",
					type: "1",
					accountId: this.searchIteam.currentCompany,
					setMealName: this.searchIteam.packageName,
					pageSize: this.pageSize,
					pageNo: this.page
				}
				this.getTotalList(params)
			},
			onPageChange(page) {
				this.page = page
				let params = {
					payStatus: "1",
					type: "1",
					accountId: this.searchIteam.currentCompany,
					setMealName: this.searchIteam.packageName,
					pageSize: this.pageSize,
					pageNo: this.page
				}
				this.getTotalList(params)
			},
			/* opt(rowData){
				var str=""
				if(rowData.cardStatus==0){
				    str=str+"<a href='#' onclick='selectDetail(\""+rowData.accountId+"\",\""+rowData.setMealId+"\",\""+rowData.synDate+"\",\""+rowData.type+"\")'>详情</a>&nbsp;&nbsp;&nbsp;&nbsp;"
				}
				return str
			}, */
			selectDetail(accountId, setMealId, synDate, type) {
				let selections = [{
					"accountId": accountId,
					"setMealId": setMealId,
					"synDate": synDate,
					"type": type
				}];
				 //console.log(selections[0])

				this.$router.replace({
					path: "/staticsManagement/detail",
					query: selections[0]
				})

				/* 此处缺路由跳转*/
			},

		}
	}
</script>

<style>
.packageUsageStatic .el-form-item__label {vertical-align: top;}
.packageUsageStatic .el-input__inner{width: 200px!important;}
</style>
