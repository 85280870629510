<template>

<div class="flowMonitorMob table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">新移动流量监控</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="网卡状态:">
                <el-select v-model="form.cardStatus">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="接口异常" value="0"></el-option>
                    <el-option label="待激活" value="1"></el-option>
                    <el-option label="已激活" value="2"></el-option>
                    <el-option label="停机" value="4"></el-option>
                    <el-option label="可测试" value="6"></el-option>
                    <el-option label="库存" value="7"></el-option>
                    <el-option label="预销户" value="8"></el-option>
                    <el-option label="已销户" value="9"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="类型:">
                <el-select v-model="form.dataType" >
                    <el-option label="全部" value=""></el-option>
                    <el-option label="剩余流量不足" value="1"></el-option>
                    <el-option label="没有过期时间" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="过期状态:">
                <el-select v-model="form.expireStatus" >
                    <el-option label="全部" value=""></el-option>
                    <el-option label="将要过期" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="ICCID卡号:">
                <el-input v-model="form.iccidMark" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="开卡公司:"  prop="accountId">
                <search-select :optionsMetaAll="accountList"  v-model="form.accountId" placeholder="请选择"></search-select>
            </el-form-item>
            <el-form-item label="套餐:">
                <el-input v-model="form.setMealName"></el-input>
            </el-form-item>
            <el-form-item label="截止日期:">
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID" prop="iccidMark" width="180px">
                <template slot-scope="scope">
                    <a href="javascript:void(0);" @click="detailData(scope.row)">{{scope.row.iccidMark}}</a>
                </template>
            </el-table-column>
            <el-table-column label="接入号" prop="phone" show-overflow-tooltip></el-table-column>
            <el-table-column label="代理商" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐名称" prop="setMealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="开卡公司" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="总流量(MB)" prop="totalFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="总语音(分钟)" prop="totalVoice" show-overflow-tooltip></el-table-column>
            <el-table-column label="剩余流量(MB)" prop="remainingFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="剩余语音(分钟)" prop="remainingVoice" show-overflow-tooltip></el-table-column>
            <el-table-column label="真实已用流量(MB)" prop="realUsedFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="真实已用语音(分钟)" prop="realUsedVoice" show-overflow-tooltip></el-table-column>
            <el-table-column label="已用流量(MB)" prop="usedFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="已用语音(分钟)" prop="usedVoice" show-overflow-tooltip></el-table-column>
            <el-table-column label="已用流量百分比" prop="flowbaifenbi" show-overflow-tooltip></el-table-column>
            <el-table-column label="开始时间" prop="mealStartTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="充值时间" prop="rechargeTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="过期时间" prop="expireDate" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{expireDate(scope.row.expireDate)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="网卡状态" prop="cardStatus" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{cardStatus(scope.row.cardStatus)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="用户等级" prop="userLevelStr" show-overflow-tooltip></el-table-column>
            <el-table-column label="类型" prop="createDate" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.dataType === 1">剩余流量不足</span>
                    <span v-if="scope.row.dataType === 2">没有过期时间</span>
                    <span v-else>其他</span>
                </template>
            </el-table-column>
            <el-table-column label="时间" prop="createDate" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{createDate(scope.row.createDate)}}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

</div>

</template>

<script type="text/ecmascript-6">
import {flowmonitorList,accountList,flowmonitorExport} from '@/api/cardManagement/flowmonitor.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import resize from '@/mixins/resize.js'
export default {
    name:'flowMonitorMob',
    mixins:[resize],
    data(){
        return {
            form:{
                cardStatus:'',
                dataType:'',
                expireStatus:'',
                iccidMark:'',
                accountId:'',
                setMealName:'',
                endTime:''
            },
            accountList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0
        }
    },
    mounted(){
        this._accountList()
        this._flowmonitorList()
    },
    methods:{
        detailData(row){
            this.$router.push({
                path: '/cardManagement/cardBillDetail',
                query: {
                    iccidOrPhone: row.iccidMark,
                    status:0,
                    type: 0,
                }
            })
        },
        exportExcel(){
            messageBox(this,"提示：\n数据正在导出，如果导出的数据量较大，文件下载响应会稍慢，也会增加服务器负荷，请稍候片刻。\n请不要重复点击导出，如果需要重新导出，请刷新页面后重新操作。")
            let params = {payStatus:1,type:100}
            flowmonitorExport(Object.assign(params, this.form)).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        searchTable(){
            this._flowmonitorList()
        },
        expireDate(expireDate){
            if(!expireDate || expireDate==="") {
                return ""
            }
            var d = new Date(expireDate);
            return d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()
        },
        createDate(createDate){
            if(!createDate || createDate==="") {
                return ""
            }
            var d = new Date(createDate)
            return d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()+" "+d.getHours()+":"+d.getMinutes()+":"+d.getSeconds()
        },
        cardStatus(cardStatus){
            if(cardStatus==1) return "待激活"; else if(cardStatus==2) return "在用";else if(cardStatus==3) return "断网";else if(cardStatus==4) return "停机";else if(cardStatus==5) return "异常";
        },
        handleSizeChange(val){
            this.pageSize = val
            this._flowmonitorList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._flowmonitorList()
        },

        _flowmonitorList(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            flowmonitorList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
        _accountList(){
            let params = Object.assign(this.form,{
                payStatus:1,
                type:100,
            })
            accountList(params).then((res)=>{
                this.accountList = res.map((item) => {
                    return {
                        label:item.accountName,
                        value:item.id
                    }
                })
            })
        },
    },
    components:{
        SearchSelect
    }
}

</script>

