<template>
  <div class="rulerManagement fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">池卡管理</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="流量池:">
          <el-select
            v-model="formInline.poolId"
            filterable
            placeholder="请选择流量池"
            clearable
          >
            <el-option
              v-for="(item, index) in carPoolLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡状态:">
          <el-select
            v-model="formInline.status"
            filterable
            placeholder="请选择卡状态"
            clearable
          >
            <el-option label="未激活" value="1"></el-option>
            <el-option label="正常" value="2"></el-option>
            <el-option label="停机" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号:">
          <el-input
            v-model="formInline.iccid"
            placeholder="请输入卡号"  clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="addPool"
            icon="el-icon-plus"
            class="themed-button"
            v-if="currentRole==0"
            >导卡入池</el-button
          >
          <el-button
            type="primary"
            @click="batchUpdate"
            icon="el-icon-edit-outline"
            class="themed-button"
            >批量更改</el-button
          >
          
          <el-button
            type="primary"
            @click="exportList"
            icon="el-icon-plus"
            class="themed-button"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="iccid" label="iccid"   min-width="180"> </el-table-column>
        <el-table-column prop="cardNo" label="接入号" min-width="130"> </el-table-column>
        <el-table-column prop="poolName" label="卡池名称" min-width="100"> </el-table-column>
        <el-table-column prop="status" label="卡状态" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status == '1'">未激活</span>
            <span v-else-if="scope.row.status == '2'">正常</span>
            <span v-else-if="scope.row.status == '4'">停机</span>
          </template>
        </el-table-column>
        <el-table-column prop="realnameStatus" label="实名状态" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.realnameStatus">已实名</span>
            <span v-else>未实名</span>
          </template>
        </el-table-column>
        <el-table-column prop="joinStatus" label="入池状态" min-width="100">
          <template v-slot:default="scope">
            <span v-if="scope.row.joinStatus">已入池</span>
            <span v-else>未入池</span>
          </template>
        </el-table-column>
        <el-table-column prop="joinTime" label="入池时间" min-width="130"> </el-table-column>
        <el-table-column prop="exitTime" label="出池时间" min-width="130"> </el-table-column>
         <el-table-column prop="joinStatus" label="第三方管理" min-width="100">
          <template v-slot:default="scope">
            <span v-if="scope.row.thirdManage">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        
        <el-table-column label="操作" width="320px" v-if="currentRole==0">
          <template v-slot:default="scope">
            <el-button
              type="info"
              class="themed-button"
              @click="deleteRow(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogAddVisible"
      v-if="dialogAddVisible"
    >
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item
          label="流量池"
          :label-width="formLabelWidth"
          prop="poolId"
          v-if="dialogTitle=='导卡入池'"
        >
          <el-select
            v-model="addForm.poolId"
            filterable
            placeholder="请选择流量池"
            clearable
          >
            <el-option
              v-for="(item, index) in carPoolLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号" :label-width="formLabelWidth" prop="iccid">
          <el-input
            type="textarea"
            autosize
            placeholder="请输入卡号，多个卡号换行隔开"
            v-model="addForm.iccid"
          >
          </el-input>
        </el-form-item>
         <el-form-item label="是否第三方管理" :label-width="formLabelWidth" prop="thirdManage" >
         <el-radio-group v-model="addForm.thirdManage" >
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="formAddSubmit('addForm')"
          class="themed-button"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="formAddReset('addForm')"
          class="themed-button"
          >重置</el-button
        >
        <el-button
          type="info"
          @click="dialogAddVisible = false"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  cardNoExport,
  cardNoList,
  deleteCardNo,
  getCardPoolList,
  cardNoAdd,
  batchUpdate
} from "@/api/trafficPoolManagement.js";

export default {
  name: "poolCardManagement",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        iccid: null,
        poolId: null,
        status: null,
      },
      tabledata: [],
      formLabelWidth: "200px",
      dialogAddVisible: false,
      addForm: {
        iccid:"",
        poolId:"",
        thirdManage:""
      },
      rules: {
        iccid: [{ required: true, message: "请输入卡号", trigger: "blur" }],
        poolId: [{ required: true, message: "请选择流量池", trigger: "change" }],
        thirdManage: [{ required: true, message: "请选择是否第三方管理", trigger: "change" }],
      },
      carPoolLists: [],
      dialogTitle:"",
    };
  },
  created() {
    this.getCardPoolList();
    this.getPoolCardList();
  },
  components: {},
   computed: {
    ...mapGetters(["currentRole"]),
  },
  methods: {
  batchUpdate(){
   this.dialogAddVisible  =  true
   this.dialogTitle = "批量更改"
    this.addForm.iccid = "";
      this.addForm.thirdManage  = ""
  },
    getCardPoolList() {
      let params = {
        pageSize: 1000000,
        pageNo: 1,
      };
      getCardPoolList(params).then((res) => {
        this.carPoolLists = res.rows.map((value, index, arr) => {
          return { value: value.id, label: value.name };
        });
      });
    },
    addPool() {
      this.getCardPoolList();
      this.dialogAddVisible = true;
      this.dialogTitle = "导卡入池"
      this.addForm.iccid = "";
      this.addForm.poolId = "";
      this.addForm.thirdManage  = ""
    },
    deleteRow(id) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCardNo({ id }).then((res) => {
          this.getPoolCardList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
    formAddSubmit(formName) {
      //新增规则确认
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let tipContent = ""
          if(this.addForm.thirdManage==1){
            // this.addForm.thirdManage = true
             tipContent = "请确认该批卡已由第三方系统进行套餐停复机管理，否则可能导致不停机。"
          }else{
             tipContent = "请确认该批卡不在第三方系统中，否则可能导致反复停复机。"
            //  this.addForm.thirdManage = false
          }
 this.$confirm(tipContent, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
          if(this.dialogTitle=="导卡入池"){
             cardNoAdd(this.addForm).then((res) => {
            if (res.system_result_key == "0") {
              this.$message({
                showClose: true,
                message: "导卡入池成功",
                type: "success",
              });
              this.dialogAddVisible = false;
              this.getPoolCardList();
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
          }else {
             console.log(this.addForm)
             let params = {
              iccid :this.addForm.iccid,
              thirdManage:this.addForm.thirdManage,
             }
             batchUpdate(params).then((res) => {
            if (res.system_result_key == "0") {
              this.$message({
                showClose: true,
                message: "批量更改成功",
                type: "success",
              });
              this.dialogAddVisible = false;
              this.getPoolCardList();
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
          }
      });
          
        } else {
          return false;
        }
      });
    },
    formAddReset(formName) {
      this.$refs[formName].resetFields();
    },

    exportList() {
      //导出
      let params = {}
      if(!!this.formInline.iccid){
        params.iccid= this.formInline.iccid
      }
      if(!!this.formInline.poolId){
        params.poolId= this.formInline.poolId
      }
      if(!!this.formInline.status){
        params.status= this.formInline.status
      }
      cardNoExport(params).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },

    getPoolCardList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
    
      if(!!this.formInline.iccid){
        params.iccid= this.formInline.iccid
      }
      if(!!this.formInline.poolId){
        params.poolId= this.formInline.poolId
      }
      if(!!this.formInline.status){
        params.status= this.formInline.status
      }
      // Object.assign(params, this.formInline);
      cardNoList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPoolCardList();
    },
    onPageChange(page) {
      this.page = page;
      this.getPoolCardList();
    },
    searchData() {
      this.page = 1;
      this.getPoolCardList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
.rulerManagement .el-form-item__label {
  vertical-align: top;
}
</style>
