<template>

<div class="refund table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">批量退款</span>
        <el-form inline class="hook right themed-form search-form" style="height: 40px;line-height: 40px;" >
            <el-form-item label="导入时间:">
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="startTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>至</el-form-item>
            <el-form-item>
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="refundRecord">批量退款</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="退款类型" prop="type">
                <template slot-scope="scope">
                    <span v-if="scope.row.type === 1">微信订单</span>
                    <span v-if="scope.row.type === 2&&currentRole==0">系统订单</span>
                    <span v-if="scope.row.type === 2&&currentRole==4">代理商订单</span>
                </template>    
            </el-table-column>
            <el-table-column label="导入时间" prop="importDate" show-overflow-tooltip></el-table-column>
            <el-table-column label="导入数（张）" prop="importTotal"></el-table-column>
            <el-table-column label="导入成功数（张）" prop="importSuccess"></el-table-column>
            <el-table-column label="导入失败数（张）" prop="importFail"></el-table-column>
            <el-table-column label="操作人" prop="createUser"></el-table-column>
            <el-table-column label="操作">
                <template  slot-scope="scope">
                    <el-button type="primary" class="themed-button"  @click='loadFail(scope.row.id)'>查看失败</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog title="批量导入" :visible.sync="dialogBox">
        <el-form label-position="right" label-width="120px">
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="导入类型:" required>
                <el-radio-group v-model="type">
                    <el-radio :label="1">微信订单</el-radio>
                    <el-radio :label="2" v-if="currentRole==0">系统订单</el-radio>
                    <el-radio :label="2" v-if="currentRole==4">代理商订单</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">提交</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('refund.xls')">导出模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {refundrecord,refundSync} from '@/api/cardManagement/refund.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import resize from '@/mixins/resize.js'
import {mapGetters} from 'vuex'

export default {
    name:'refund',
    mixins:[resize],
    data(){
        return {
            startTime:'',
            endTime:'',
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            type:1
        }
    },
    mounted(){
        this._refundrecord()
    },
     computed:{
        	...mapGetters(
            	['currentRole']
        	),
    	},
    methods:{
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    if(this.packageIds === ''){
                        messageBox(this,'请选择套餐')
                        return
                    }
   
                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('type',this.type)
                    refundSync(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        searchTable(){
            this._refundrecord()
        },
        refundRecord(){
            this.dialogBox = true
        },
        loadFail(importCardId){
            this.$router.push({
                path: "/cardManagement/refundFailList",
                query: {importCardId},
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._refundrecord()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._refundrecord()
        },
        _refundrecord(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                startTime:this.startTime,
                endTime:this.endTime
            }
            refundrecord(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        }
    }
}

</script>

