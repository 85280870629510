import request from './request.js'
export function exportList(params) {
    return request({
        url: 'tobIExport/exportList.do?',
        method: 'post',
        data: params
    })
}
export function importList(params) {
    return request({
        url: 'tobImport/importList.do?',
        method: 'post',
        data: params
    })
}
export function exportOutDownload(params) {
   return request({
     url: 'tobIExport/download.do?',
     method: 'get',
     params:params,
     timeout:300000
   })
}
export function importDownloadIn(params) {
   return request({
     url: 'tobImport/downloadIn.do?',
     method: 'get',
     params:params,
   	responseType: 'blob'
   })
}
export function importDownloadOut(params) {
   return request({
     url: 'tobImport/downloadOut.do?',
     method: 'get',
     params:params,
   	responseType: 'blob'
   })
}