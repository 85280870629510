<template>
  <div>
    <el-dialog
      title="套餐详情"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
    <el-form ref="dFrom" inline class="right themed-form search-form" style="line-height: 40px;">
		<el-form-item>
			<el-button type="primary" icon="el-icon-download" class="themed-button"  @click="exportDetail">导出</el-button>
		</el-form-item>
	</el-form>
    <el-table :data="detailData"  style="width: 100%;overflow-y:scroll" ref="table"  :max-height="800" stripe  border>
		<el-table-column
            show-overflow-tooltip
            prop="iccidMark"
            label="卡号"
            min-width="150"
            >
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="accountName"
            label="运营商"
            min-width="150"
            >
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="agentName"
            label="总代理商"
            min-width="150"
            >
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="agentTreeName"
            label="代理商"
            min-width="150"
            >
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="mealName"
            label="套餐名称"
            min-width="150"
            >
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="startTime"
            label="开始时间"
            min-width="150"
            >
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="endTime"
            label="结束时间"
            min-width="150"
            >
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="useFlow"
            label="使用流量"
            min-width="150"
            >
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="useNum"
            label="逻辑月"
            min-width="120"
            >
        </el-table-column>
        <!-- <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
                <el-button size="mini" class="themed-button" type="info" @click="handleDetail(scope.row.id)">详情</el-button>
                    </template>
        </el-table-column> -->
	</el-table>
    <el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			@current-change="onPageChange">
	</el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { getMealMonthDetail,exportMealMonthDetail} from "@/api/staticsManagement.js"
import onresize from "@/mixins/resize.js"
export default {
mixins: [onresize],
  props:["mealId"],
  data() {
    return {
      dialogVisible: false,
      detailData:[],
      pageSize: 15,
	  total: 0,		
      page: 1,
    }
  },
  created(){
  },
  methods: {
    getDetailList(){
        let params = {
		    pageSize: this.pageSize,
            pageNo: this.page,
            mealId:this.mealId
        } 
        getMealMonthDetail(params).then(res=>{
            this.detailData = res.rows
            this.total = res.total
        })
    },

    onPageChange(page){
		this.page = page
		this.getDetailList()
	},
	handleSizeChange(size){
		this.pageSize = size
		this.getDetailList()
    },

    handleClose(){
        this.pageSize = 15
        this.total = 0
        this.page = 1
        this.dialogVisible = false
    },
    
    exportDetail(){
        exportMealMonthDetail({mealId:this.mealId}).then(res=>{
            if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
                            this.dialogVisible = false
							this.$router.push('/tob/i_export_list')
						});
			}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
			}
        })
    }
  }
}
</script>

<style>
 

</style>
