<template>
    <div class="history-packge-change fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">套餐变更记录</span>
            <el-form inline ref="packgeChangeForm" v-model="packgeChangeData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
                    <el-input v-model="packgeChangeData.iccidMark" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="packgeChangeData.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="packgeChangeData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作起始日期" v-model="packgeChangeData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作结束日期" v-model="packgeChangeData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

         <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    label="ICCID"
                    min-width="20%"
                    >
                    <template slot-scope="scope">
                        <el-link :underline='false' type="primary" @click="updateRecord(scope.row.id,scope.row.iccidMark)">{{scope.row.iccidMark}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="shortIccid"
                    label="短号"
                    min-width="15%"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号"
                    min-width="15%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="originalMealName"
                    label="原套餐"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="newMealName"
                    label="变更后套餐"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createDate"
                    label="操作时间"
                    min-width="15%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    min-width="10%"
                    >   
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

    </div>
</template>

<script>
import {mealChange,exportPackageChange} from '@/api/history.js'
import resize from '@/mixins/resize.js'
import {mapMutations} from "vuex"
export default {
    name:'packageChange',
    mixins:[resize],
    data(){
        return {
            packgeChangeData:{
                iccidMark:'',
                phone:'',
                startTime:'',
                endTime:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    
    created(){
        this.getPackageChangeList()
    },
    methods:{
        ...mapMutations(
                ["SET_ICCID_OR_PHONE","SET_STATUS","SET_TYPE"]
            ),
        getPackageChangeList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.packgeChangeData,params)
            mealChange(this.packgeChangeData).then(res=>{
                this.tableData=res.rows
                this.total=res.count
            })
        },
        updateRecord(id,iccid){
            this.SET_ICCID_OR_PHONE(iccid)
            this.SET_STATUS('0')
            this.SET_TYPE('0')
            this.$router.push({path:'../cardManagement/cardBillDetail',query:{'iccidOrPhone':iccid,'status':0,'type':0}})
        },
        handleSizeChange(size){
            this.pageSize=size
            this.getPackageChangeList()
        },
        onPageChange(page){
            this.page=page
            this.getPackageChangeList()
        },
        onSearch(){
            this.page = 1
            this.getPackageChangeList()
        },
        exportExcel(){
            exportPackageChange(this.packgeChangeData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        }
    }
}
</script>