<template>

<div class="imei-library table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">卡库</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="库编号:">
                <el-select filterable v-model="cardBankCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in cardBankCodeList" :label="item.cardBankCode" :value="item.cardBankCode" :key="index"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="客户:">
                <el-input v-model="customerName" placeholder="请输入客户" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchData">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="add">新增库</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="importAdd">新增卡</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-minus" class="themed-button" @click="importDelete">删除卡</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="卡库编号" prop="cardBankCode" show-overflow-tooltip></el-table-column>
            <el-table-column label="运营商" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="客户" prop="customerName" show-overflow-tooltip></el-table-column>
            <el-table-column label="数量" prop="amount" show-overflow-tooltip></el-table-column>
            <el-table-column label="已绑定数" prop="bindAmount" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.bindAmount">{{scope.row.bindAmount}}</span>
                    <span v-else>0</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作人" prop="operator" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" @click="importUpdate(scope.row.id)">编辑</el-button>
                    <el-button type="info" class="themed-button" @click="deleteById(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogBox" v-if="dialogBox" >
        <add v-if="dialogContent == 'add'" @close="dialogBox=false" @close-dialog='dialogBox=false'></add>
        <add-card v-if="dialogContent == 'add-card'"  @close="dialogBox=false" @close-dialog='dialogBox=false'></add-card>
        <delete-card v-if="dialogContent == 'delete'" @close="dialogBox=false" @close-dialog='dialogBox=false'></delete-card> 
        <update v-if="dialogContent == 'update'" :id="id" @close="refresh"></update>  
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {listCardBankCode,bindCardList,bindCardExportSync,bindCardDelete} from '@/api/meCardBindManagement/imeiLibrary.js'
    import {messageBox,confirmBox } from '@/utils/common.js'
    import resize from '@/mixins/resize.js'

    import Add from '@/views/meCardBindManagement/bindCard/add'
    import AddCard from '@/views/meCardBindManagement/bindCard/addCard'
    import DeleteCard from '@/views/meCardBindManagement/bindCard/deleteCard'
    import Update from '@/views/meCardBindManagement/bindCard/update'

    export default {
        // name:'bindCard',
        mixins:[resize],
        data(){
            return {
                customerName:"",
                cardBankCode:'',
                cardBankCodeList:[],
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,

                dialogBox:false,
                dialogTitle:'',
                dialogContent:'',
                id:''
            }
        },
        mounted(){
            this._listCardBankCode()
            this._bindCardList()
        },
        methods:{
            refresh(){
                this._bindCardList()
                this.dialogBox = false
            },
            add(){
                this.dialogTitle = '新增库'
                this.dialogContent = 'add'
                this.dialogBox = true
            },
            importAdd(){
                this.dialogTitle = '新增卡'
                this.dialogContent = 'add-card'
                this.dialogBox = true
            },
            importDelete(){
                this.dialogTitle = '删除卡'
                this.dialogContent = 'delete'
                this.dialogBox = true
            },
            importUpdate(id){
                this.id = id
                this.dialogTitle = '修改库'
                this.dialogContent = 'update'
                this.dialogBox = true
            },
            deleteById(data){
                let msg = "库编号：" + data.cardBankCode + "</br>" + "数量：" + data.amount + "</br>" + "已绑定数：" + (data.bindAmount?data.bindAmount:0) + "</br>" + "</br>删除该库后，设备和关系将一并删除。</br>您确定要删除吗?"
                confirmBox(this,msg,'操作提示',()=>{
                    bindCardDelete(Object.assign({id:data.id,cardBankCode:this.cardBankCode})).then((res)=>{
                        messageBox(this,'删除成功')
                        this._bindCardList()
                    })
                })
            },
            exportExcel(){
                bindCardExportSync({cardBankCode:this.cardBankCode,customerName:this.customerName}).then((res)=>{
                    if (res.system_result_key == '0') {
                        this.$alert('导出成功，请到导出记录中下载', '提示', {
                            confirmButtonText:'确定',
                        }).then(()=>{
                            this.$router.push('/tob/i_export_list')
                        });
                    }else{
                        this.$message({
                            showClose: true,
                            message: res.system_result_message_key,
                            type: 'error'
                        });
                    }
                })
            },
            searchData(){
                this._bindCardList()
            },
            handleSizeChange(val) {
                this.pageSize = val
                this._bindCardList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this._bindCardList()
            },
            _listCardBankCode(){
                listCardBankCode().then((res)=>{
                    this.cardBankCodeList = res
                })
            },
            _bindCardList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                params.cardBankCode = this.cardBankCode
                params.customerName = this.customerName
                bindCardList(params).then((res)=>{
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        },
        components:{
            Add,
            AddCard,
            DeleteCard,
            Update
        }
    }
</script>

