<template>
    <div class="fadeInRight">
        <div class="title-box">
            <span class="m-title">批量查询</span>
        </div>
        <div class="content-box">
            <el-form label-position="right" label-width="120px" :model="form" ref="form">
            <el-form-item label="查询类型:"  prop="type" required>
                <el-radio-group v-model="form.type">
                    <el-radio :label="1">区间流量</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>

            <el-form-item label="起始时间:" required  prop="startDate" v-if="form.type===1">
                <el-date-picker
                    v-model="form.startDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="终止时间:" required  prop="endDate" v-if="form.type===1">
                <el-date-picker
                    v-model="form.endDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" class="themed-button" @click="uploadExcel">确认</el-button>
                <el-button type="primary" class="themed-button" @click="exportTemplateExcel">导出excel模板</el-button>
            </el-form-item>
        </el-form>
        </div>
    </div>
</template>

<script>
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import {batchQuaryExcel} from '@/api/cardManagement/batchQuary.js'
export default {
    data(){
        return {
            form:{
               type:1,
               startDate:'',
               endDate:'',
            }
        }
    },
    methods: {
        exportTemplateExcel(){
            let name ;
            if(this.form.type == 1){
                name = "importIccid.xls";
            }
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        uploadExcel(){
             confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
             let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('file',file)
                    let url ;
                    if(this.form.type == 1){
                        params.append('startDate',this.form.startDate)
                        params.append('endDate',this.form.endDate)
                        url ="/batch/query/intervalFlow.do?"
                    }
                    batchQuaryExcel(url,params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('操作成功，请到导出记录中查看查询结果', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.resteForm()
                                this.$router.push('/tob/i_export_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        resteForm(){
            this.$refs['file'].value = ''
            this.form ={
               type:1,
               startDate:'',
               endDate:'',
            }
        }
    },
}
</script>