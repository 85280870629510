<template>
    <div class="admin-rebate table-hook fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">后台返佣统计</span>
            <el-form inline ref="adminRebateForm" v-model="admintRebateData" class="hook right themed-form search-form">
                <el-form-item label="代理商名称:">
                    <search-select :optionsMetaAll="agentList" v-model="admintRebateData.agentId"></search-select>
                </el-form-item>
                <el-form-item label="开始时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择时间" v-model="admintRebateData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择时间" v-model="admintRebateData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="exportExcel">导出excel</el-button>
				</el-form-item>
            </el-form>
        </div>
        <div class="content-box">
            <el-table
                ref='table'
                border
                stripe
                :data="tableData"
                tooltip-effect="dark"
                :max-height="maxTableHeight">

            <el-table-column label="代理商名称" prop="agentTreeName" show-overflow-tooltip></el-table-column>
            <el-table-column label="所属总代理商" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="新增佣金" prop="addCommission"></el-table-column>
            <el-table-column label="扣减佣金" prop="decCommission"></el-table-column>
            <el-table-column label="新增订单数" prop="orderNum"></el-table-column>
            <el-table-column label="退款订单数" prop="refundNum" show-overflow-tooltip></el-table-column>
            <el-table-column label="订单总额" prop="orderSum" show-overflow-tooltip></el-table-column>
          
            <el-table-column label="退款总额" prop="refundSum" show-overflow-tooltip></el-table-column>
            <el-table-column label="总佣金" prop="commissionSum" show-overflow-tooltip></el-table-column>
            
            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button size="mini" class="themed-button" type="primary" @click="orderDetails(scope.row.id,scope.row.agentId)">返利详情</el-button>
                </template>
            </el-table-column>
            </el-table>
            <el-pagination 
            :current-page.sync="pageNo"  
            :page-sizes="[5,10,15,50,100,1000]" 
            :page-size="pageSize"
			 background 
             layout="total,sizes,prev, pager, next,jumper" 
             :total="total" 
             @size-change="handleSizeChange" 
             @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'

import {getAdmineAgent,getAdmineList,limitExportCount} from '@/api/agentApplyCheck.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'
export default {
    name:"adminRebateList",
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return{
            admintRebateData:{
                agentId:'',
                startTime:'',
                endTime:''
            },
            agentList:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0
        }
    },
    mounted(){
        this._getAdmineAgent()
        this._getAdmineList()
    },
    methods:{
        orderDetails(id,agentId){
            if(agentId){
                let date = new Date()
                let y = date.getFullYear()
                let m = date.getMonth();
                let cStartTime = new Date(y, m, 1);
                let cEndTime = new Date(y, m+1, 0);
                let startTime = this.admintRebateData.startTime == ''?this.format('yyyy-MM-dd',cStartTime):this.admintRebateData.startTime
                let endTime = this.admintRebateData.endTime == ''?this.format('yyyy-MM-dd',cEndTime):this.admintRebateData.endTime
                this.$router.push({
                    path: "/orderManagement/agentApplyCheckDetail",
                    query: {agentId,title:true,startTime,endTime},
                })
            }
        },
        format(fmt,date){
             let o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
            };
            if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (var k in o)
                if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        },

        exportExcel(){
            limitExportCount(this.admintRebateData).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        onSearch(){
            this._getAdmineList()
        },
        onPageChange(page){
			this.pageNo = page
			this._getAdmineList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._getAdmineList()
        },
        _getAdmineAgent(){
            this.agentList = []
            this.agentList.push({
                label:'全部',
                value:''
            });
            getAdmineAgent({status:0}).then((res)=>{
                if(res.length>0){
                    res.forEach((item)=>{
                        this.agentList.push({
                            label:item.agentName,
                            value:item.agentId
                        })
                    })
                }
            })
        },
        _getAdmineList(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                type:2
            }
            getAdmineList(Object.assign(params,this.admintRebateData)).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.tableData = res.rows
                this.total = res.total

            })
        }
    }
}
</script>
<style>
    .admin-rebate{position:relative;}
    .admin-rebate .el-table .cell{height:23px;}
</style>