<template>
    <div class="speed-limit-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">限速规则列表</span>
            <el-form inline ref="searchForm" v-model="searchForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="searchForm.id" placeholder="id" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="searchForm.name" placeholder="名称" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item label="运营商">
                    <el-select v-model="searchForm.accountId" filterable placeholder="运营商">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="限速算法">
                    <el-select v-model="searchForm.algorithm" filterable placeholder="限速算法">
                        <el-option label="全部" value=""></el-option>
                        <!-- <el-option label="线性" value="linear"></el-option>
                        <el-option label="波浪" value="wave"></el-option> -->
                        <el-option v-for="(item,index) in w_algorithm_group" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>

                <el-form-item>
						<el-button icon="el-icon-plus" type="info" class="themed-button" @click="handleAdd">新增</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="id"
                    label="id"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="name"
                    label="名称"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="type"
                    label="类型"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">周期限速</span>
                        <span v-else-if="scope.row.type == 2">日期限速</span>
                        <span v-else-if="scope.row.type == 3">日周期都限速</span>
                        <span v-else-if="scope.row.type == 4">不限速</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="创建时间"
                    min-width="120"
                    >
                       <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.createTime)}}</span>
                </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="updateTime"
                    label="更新时间"
                    min-width="120"
                    >
                      <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.updateTime)}}</span>
                </template>
                </el-table-column>
                <el-table-column
                    prop="updateBy"
                    label="更新人"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="250">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="info" @click="handleDetail(scope.row.id)">详情</el-button>
                        <el-button  size="mini" class="themed-button" type="info" @click="handleEdit(scope.row.id)">编辑</el-button>
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
                
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="详情" :visible.sync="dialogDetail"  :destroy-on-close='true'>
            <el-form :model="detailForm"  ref="detailForm" :inline="true" style="height:500px;overflow-y:scroll">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称"  prop="name">
                            <el-input v-model="detailForm.name" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="运营商"  prop="accountId">
                            <el-select v-model="detailForm.accountId"  disabled>
                                <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="限速类型"  prop="type">
                            <el-select v-model="detailForm.type" disabled>
                                    <el-option label="周期限速" value="1"></el-option>
                                    <el-option label="日限速" value="2"></el-option>
                                    <el-option label="日周期都限速" value="3"></el-option>
                                    <el-option label="不限速" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                 
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="创建时间">
                            <el-input v-model="detailForm.createTime" readonly></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="更新时间">
                            <el-input v-model="detailForm.updateTime" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="更新人">
                            <el-input v-model="detailForm.updateBy" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div v-if="showWeekly">
                    <el-row style="margin:20px 0">
                        <el-col :span="12">
                            <span>周期限速</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="算法"  prop="">
                                <el-select v-model="detailForm.rule.periodic.algorithm" disabled>
                                    <!-- <el-option label="线性" value="linear"></el-option>
                                    <el-option label="波浪" value="wave"></el-option> -->
                                    <el-option v-for="(item,index) in w_algorithm_group" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-table :data="detailForm.rule.periodic.rule" style="margin-top:15px;">
                        <el-table-column label="流量" prop="" min-width="90">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.usage" size="mini" style="width:80%" readonly></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="限速值" prop="" min-width="90">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.speed" size="mini" style="width:80%" disabled>
                                    <el-option v-for="item in accountSpeedList" :key="item.index" :label="item.speedKey" :value="item.speedValue"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div v-if="showDay">
                    <el-row style="margin:20px 0">
                        <el-col :span="12">
                                <span>日限速</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="算法">
                                <el-select v-model="detailForm.rule.day.algorithm" disabled>
                                    <!-- <el-option label="线性" value="linear"></el-option>
                                    <el-option label="波浪" value="wave"></el-option> -->
                                    <el-option v-for="(item,index) in w_algorithm_group" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-table :data="detailForm.rule.day.rule" style="margin-top:15px;">
                        <el-table-column label="流量" prop="" min-width="90">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.usage" size="mini" style="width:80%" readonly></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="限速值" prop="" min-width="90">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.speed" size="mini" style="width:80%" disabled>
                                    <el-option v-for="item in accountSpeedList" :key="item.index" :label="item.speedKey" :value="item.speedValue"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>


                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="primary" @click="dialogDetail = false">取 消</el-button>
            </div>
        </el-dialog>


        <el-dialog :title="addTitle" :visible.sync="dialogAddvisible" width="500" :destroy-on-close='true'>
            <el-form :model="addForm"  ref="addForm" :inline="true" style="height:500px;overflow-y:scroll">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称"  prop="name">
                            <el-input v-model="addForm.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="运营商"  prop="accountId">
                            <el-select v-model="addForm.accountId" filterable placeholder="运营商" @change="changeAccount">
                                <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="限速类型"  prop="type" >
                            <el-select v-model="addForm.type" filterable  @change='changeLimitType'>
                                    <el-option label="周期限速" value="1"></el-option>
                                    <el-option label="日限速" value="2"></el-option>
                                    <el-option label="日周期都限速" value="3"></el-option>
                                    <el-option label="不限速" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                 <el-row style="color:#f00;text-align:center;">
                    <p >必须添加至少一个限速档位，且不能为空值</p>
                 </el-row>
                <!-- 周期限速 -->
                <div v-if="showWeekly">
                    <el-row style="margin:20px 0">
                        <el-col :span="12">
                            <span>周期限速</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="算法"  prop="">
                                <el-select v-model="addForm.rule.periodic.algorithm" filterable  @change='changeWeeklyArithmetic'>
                                    <!-- <el-option label="线性" value="linear"></el-option>
                                    <el-option label="波浪" value="wave"></el-option> -->
                                    <el-option v-for="(item,index) in w_algorithm_group" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-table :data="addForm.rule.periodic.rule" style="margin-top:15px;">
                        <el-table-column label="流量(MB)" prop="" min-width="90">
                            <template slot-scope="scope">
                                <el-input :disabled='!!scope.row.status' v-model.trim="scope.row.usage" size="mini" style="width:80%"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="限速值" prop="" min-width="90">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.speed" size="mini" style="width:80%" filterable @change="getDeviceSpeed(scope.$index,$event)">
                                    <el-option v-for="item in accountSpeedList" :key="item.index" :label="item.speedKey" :value="item.speedValue"></el-option>
                                </el-select>
                            </template>
                            
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                            <el-button type="danger" v-if="!scope.row.status" plain size="mini" @click="deleteWeeklyRule(scope.row,scope.$index)">删除</el-button>
                            <el-button type="info" :disabled="!!(addForm.rule.periodic.rule.length>=maxWeeklyRule)" plain size="mini" @click="afterAddWeeklyRule(scope.row,scope.$index)">新增一行</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item>
                                <el-button :disabled="!!(addForm.rule.periodic.rule.length>=maxWeeklyRule)" icon="el-icon-plus" class="themed-button" type="primary" @click="addOneWeekly">新增一行</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                <!-- 日限速 -->
                <div v-if="showDay">
                    <el-row style="margin:20px 0">
                        <el-col :span="12">
                                <span>日限速</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="算法"  prop="">
                                <el-select v-model="addForm.rule.day.algorithm"  @change='changeDayArithmetic'>
                                    <!-- <el-option label="线性" value="linear"></el-option>
                                    <el-option label="波浪" value="wave"></el-option> -->
                                    <el-option v-for="(item,index) in w_algorithm_group" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-table :data="addForm.rule.day.rule" style="margin-top:15px;">
                        <el-table-column label="流量(MB)" prop="" min-width="90">
                            <template slot-scope="scope">
                                <el-input :disabled='!!scope.row.status' v-model.trim="scope.row.usage" size="mini" style="width:80%"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="限速值" prop="" min-width="90">
                           <template slot-scope="scope">
                                <el-select v-model="scope.row.speed" size="mini" style="width:80%" filterable  @change="getDeviceSpeedDay(scope.$index,$event)">
                                    <el-option v-for="item in accountSpeedList" :key="item.index" :label="item.speedKey" :value="item.speedValue"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                            <el-button type="danger" v-if="!scope.row.status" plain size="mini" @click="deleteDayRule(scope.row,scope.$index)">删除</el-button>
                            <el-button type="info" :disabled='!!(addForm.rule.day.rule.length>=3)' plain size="mini" @click="afterAddDayRule(scope.row,scope.$index)">新增一行</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item>
                                <el-button  :disabled='!!(addForm.rule.day.rule.length>=3)' icon="el-icon-plus" class="themed-button" type="primary" @click="addOneDay">新增一行</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="submitForm">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogAddvisible = false">取 消</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {agentOperatorList} from '@/api/order.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import {limitRuleList,addLimitRule,findLimitRuleById,editLimitRule,deleteLimitRule,exportLimitRule} from "@/api/speedLimit/index.js";
import {getSpeedListUpdate} from '@/api/virtualCard/accountRateList.js'
import dictGroup from '@/mixins/dictGroup.js'
export default {
     mixins:[resize,dictGroup],
    data(){
        return {
            searchForm:{
                id:'',
                name:'',
                accountId:'',
                algorithm:'',
            },
            accountIdList:[],
            accountSpeedList:[],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            formLabelWidth:'150px',
            dialogAddvisible:false,
            addForm:{
                id:'',
                name:'',
                accountId:'',
                type:'',
                rule:{
                    periodic:{
                        algorithm:'',
                        rule:[
                            {
                                usage:'',
                                speed:''
                            }
                        ]
                    },
                    day:{
                        algorithm:'',
                        rule:[
                        {
                                usage:'',
                                speed:''
                            } 
                        ]
                    }
                }              
            },

            maxWeeklyRule:20,
            dialogDetail:false,
            detailForm:{
                name:'',
                accountId:'',
                type:'',
                createTime:'',
                updateTime:'',
                updateBy:'',
                rule:{
                    periodic:{
                        algorithm:'',
                        rule:[]
                    },
                    day:{
                        algorithm:'',
                        rule:[]
                    }
                } 
            },

            showDay:true,
            showWeekly:true,

            tableDataWeekly:[],
            tableDataDay:[],
            addTitle:'',
            accountSpeedListDetail:''
        }
    },
    created(){
        this.getAgentOperatorList()
        this.getLimitRulesList()
    },
    methods: {
         formatDatebox(value){
                if (value == null || value == '') {  
                    return '';  
                }  
                var dt;  
                if (value instanceof Date) {  
                    dt = value;  
                } else {  
                    dt = new Date(value);  
                }  
                return  dt.toLocaleString().replace(/\//g, '-')
                // return dt.format("yyyy-MM-dd hh:mm:ss"); 
                // return  dt.toJSON().substr(0, 19).replace('T', ' ')
            },
        _getalgorithm(name){
            let val = ''
            this.w_algorithm_group.forEach(item => {
                if(name == item.groupName){
                    val = item.dictValue
                }
            })
            return val
        },
        changeAccount(accountId){
            this.accountSpeedList=[]
            getSpeedListUpdate({accountId}).then(res=>{
                let list= res.rows
                if(list.length>0){
                    list.forEach((item)=>{
                        this.accountSpeedList.push({speedKey:item.speedKey,speedValue:item.speedValue,deviceSpeedValue:item.deviceSpeedValue})
                    })
                }
            })
        },
        getDeviceSpeed(val,e){
        console.log(val,e,501)
         this.accountSpeedList.forEach(item=>{
            if(item.speedValue==e){
             this.addForm.rule.periodic.rule[val].deviceSpeed =  item.deviceSpeedValue
            }
         })
        
        },

        getDeviceSpeedDay(val,e){
        console.log(val,e,501)
         this.accountSpeedList.forEach(item=>{
            if(item.speedValue==e){
             this.addForm.rule.day.rule[val].deviceSpeed =  item.deviceSpeedValue
            }
         })
        
        },
        getAgentOperatorList(){
            agentOperatorList().then(res=>{
                this.accountIdList = res        
             })
        },
        getLimitRulesList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.searchForm,params)
            limitRuleList(this.searchForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
            this.page = page
            this.getLimitRulesList()
        },
        handleSizeChange(size){
            this.pageSize = size
            this.getLimitRulesList()
        },

        onSearch(){
            this.page=1
            this.getLimitRulesList()
        },

        handleAdd(){ 
            this.dialogAddvisible = true
            this.resetAddForm()
            this.addTitle =  '新增'
        },

        handleEdit(id){
            this.addForm.id=id
            this.dialogAddvisible = true
            this.addTitle =  '编辑'
            findLimitRuleById({id}).then(res=>{
                this.addForm.id = res.id
                this.addForm.name = res.name
                this.addForm.accountId = res.accountId
                this.addForm.type = res.type
                let resRule = JSON.parse(res.rule)
                if(res.type == 1 || res.type==4){
                    this.showDay = false
                    this.showWeekly = true
                    this.addForm.rule.periodic.rule=resRule.periodic.rule
                    this.addForm.rule.day.rule = []
                    this.addForm.rule.periodic.algorithm = resRule.periodic.algorithm
                }else if(res.type == 2){
                    this.showDay = true
                    this.showWeekly = false
                    this.addForm.rule.periodic.rule=[]
                    this.addForm.rule.day.rule = resRule.day.rule
                    this.addForm.rule.day.algorithm = resRule.day.algorithm
                }else if(res.type == 3){
                    this.showDay = true
                    this.showWeekly = true
                    this.addForm.rule.day.rule = resRule.day.rule
                    this.addForm.rule.periodic.rule=resRule.periodic.rule
                    this.addForm.rule.periodic.algorithm = resRule.periodic.algorithm
                     this.addForm.rule.day.algorithm = resRule.day.algorithm
                }
                this.changeAccount(res.accountId)
            })

        },

        submitForm(){
            if(this.addForm.rule.periodic.rule.length < 1 &&  this.addForm.rule.day.rule.length<1){
                messageBox(this,'请至少添加一条限速数据')
                return
            }

            if(this.addForm.rule.periodic.rule.length >1){
                for(let i=0;i<this.addForm.rule.periodic.rule.length-1;i++){
                    if(parseInt(this.addForm.rule.periodic.rule[i].usage)> parseInt(this.addForm.rule.periodic.rule[i+1].usage)){
                        messageBox(this,'流量值输入不正确，只能递增')
                        return
                    }
                   
                }
            }
              if(this.addForm.rule.periodic.rule.length >=1){
                 if(this.addForm.rule.periodic.rule[0].speed==""){
                        messageBox(this,'首个档位的限速值不能为空值')
                        return
                    }
            }
            
            if(this.addForm.rule.day.rule.length >1){
                for(let i=0;i<this.addForm.rule.day.rule.length-1;i++){
                    if(parseInt(this.addForm.rule.day.rule[i].usage) > parseInt(this.addForm.rule.day.rule[i+1].usage)){
                        messageBox(this,'流量值输入不正确，只能递增')
                        return
                    }
                      
                }
            }
            if(this.addForm.rule.day.rule.length >=1){
                 if(this.addForm.rule.day.rule[0].speed==""){
                        messageBox(this,'首个档位的限速值不能为空值')
                        return
                    }
            }

            if(!!this.addForm.id){ //编辑
                let params ={
                   id:this.addForm.id,
                   name:this.addForm.name,
                   accountId:this.addForm.accountId,
                   type:this.addForm.type,
                   rule:JSON.stringify(this.addForm.rule)
                }
                editLimitRule(params).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                    });
                this.dialogAddvisible = false
                this.getLimitRulesList()
                })
            }else{   //tianjia
                let params ={
                   name:this.addForm.name,
                   accountId:this.addForm.accountId,
                   type:this.addForm.type,
                   rule:JSON.stringify(this.addForm.rule)
                }
                addLimitRule(params).then(res=>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                        });
                    this.dialogAddvisible = false
                    this.getLimitRulesList()
                })
            }
        },

        showSpeed(id){
            console.log(id,this.accountSpeedListDetail)
            if(!!this.accountSpeedListDetail){
                this.accountSpeedListDetail.forEach(item=>{
                    if(id == item.speedValue)
                    return item.speedKey
                })
            }
        },


        handleDetail(id){
            this.dialogDetail = true
            findLimitRuleById({id}).then(res=>{
                this.detailForm.name = res.name
                this.detailForm.accountId = res.accountId
                this.detailForm.type = res.type
                this.detailForm.createTime = res.createTime
                this.detailForm.updateTime = res.updateTime
                this.detailForm.updateBy = res.updateBy
                let resRule = JSON.parse(res.rule)
                if(res.type == 1 || res.type==4){
                    this.showDay = false
                    this.showWeekly = true
                    this.detailForm.rule.periodic.rule=resRule.periodic.rule
                    this.detailForm.rule.day.rule = []
                    this.detailForm.rule.periodic.algorithm = resRule.periodic.algorithm
                }else if(res.type == 2){
                    this.showDay = true
                    this.showWeekly = false
                    this.detailForm.rule.periodic.rule=[]
                    this.detailForm.rule.day.rule = resRule.day.rule
                    this.detailForm.rule.day.algorithm = resRule.day.algorithm
                }else if(res.type == 3){
                    this.showDay = true
                    this.showWeekly = true
                    this.detailForm.rule.day.rule = resRule.day.rule
                    this.detailForm.rule.periodic.rule=resRule.periodic.rule
                    this.detailForm.rule.periodic.algorithm = resRule.periodic.algorithm
                     this.detailForm.rule.day.algorithm = resRule.day.algorithm
                }
                this.changeAccount(res.accountId)
            })

        },

        handleDelete(id){
            this.$confirm('确定删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteLimitRule({id}).then(res=>{
                    this.$message({
                    type: 'success',
                    message: '删除成功!'
                    });
                    this.getLimitRulesList()
                })
                
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消'
                });          
            });
        },
        resetAddForm(){
            this.addForm ={
                name:'',
                accountId:'',
                type:'',
                rule:{
                    periodic:{
                    algorithm:'',
                    rule:[]
                    },
                    day:{
                        algorithm:'',
                        rule:[]
                    }
                }
            }
            this.showDay = true
            this.showWeekly = true
        },

        changeWeeklyArithmetic(val){
            if(val == this._getalgorithm('线性')){
                this.maxWeeklyRule = 10
            }else if(val == this._getalgorithm('波浪')){
                this.maxWeeklyRule = 20
            }

            if(this.addForm.type == '1' || this.addForm.type == '4'){
                this.addForm.rule.periodic.rule=[{usage:0,speed:'',status:'1'}]
            }else{
                this.addForm.rule.periodic.rule=[]
            }
        },

        changeDayArithmetic(){},

        changeLimitType(val){
            if(val == '1'){  //周期限速
                this.showDay = false
                this.showWeekly = true
                this.addForm.rule.day.rule=[]
                this.addForm.rule.periodic.rule=[{usage:0,speed:'',status:'1'}]
            }else if(val == '2'){
                this.showDay = true
                this.showWeekly = false
                this.addForm.rule.periodic.rule=[]
                this.addForm.rule.day.rule=[{usage:0,speed:'',status:'1'}]
            }else if(val == '3'){
                this.showDay = true
                this.showWeekly = true
                this.addForm.rule.periodic.rule=[]
                this.addForm.rule.day.rule=[{usage:0,speed:'',status:'1'}]
            }else if(val == '4'){
                this.showDay = false
                this.showWeekly = true
                this.addForm.rule.day.rule=[]
                this.addForm.rule.periodic.rule=[{usage:0,speed:'',status:'1'}]
            }
        },

        addOneWeekly(){
            this.addForm.rule.periodic.rule.push(
                {
                    usage:'',
                    speed:''
                }
            )
        },
        deleteWeeklyRule(data,index){
            console.log(index,782)
            this.addForm.rule.periodic.rule.splice(index,1)
        },

        afterAddWeeklyRule(data,index){
            this.addForm.rule.periodic.rule.splice(parseInt(index)+1,0,{ usage:"",speed:""})
        },

        addOneDay(){
            this.addForm.rule.day.rule.push(
                {
                    usage:'',
                    speed:''
                }
            )
        },
        deleteDayRule(data,index){
            this.addForm.rule.day.rule.splice(index,1)
        },

        afterAddDayRule(data,index){
            this.addForm.rule.day.rule.splice(parseInt(index)+1,0,{ usage:"",speed:""})
        },


        exportExcel(){
            exportLimitRule(this.searchForm).then(res=>{
                    if (res.system_result_key == '0') {
                        this.$alert('导出成功，请到导出记录中下载', '提示', {
                            confirmButtonText:'确定',
                        }).then(()=>{
                            this.$router.push('/tob/i_export_list')
                        });
                    }else{
                        this.$message({
                            showClose: true,
                            message: res.system_result_message_key,
                            type: 'error'
                        });
                    }
                })
        },


        formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
         },
    },
}
</script>

<style>
    .speed-limit-list .el-form-item__label{
        vertical-align: top;
    }
</style>