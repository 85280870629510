<template>
    <div class="virtual-info-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">虚拟卡信息列表</span>
            <el-form inline ref="quaryForm" v-model="quaryForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="quaryForm.virtualIccid" placeholder="虚拟号" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    label="虚拟号"
                    min-width="180"
                    prop="virtualIccid"
                    >
                </el-table-column>
                <el-table-column
                    label="运营商"
                    min-width="150"
                    prop="accountId"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="电信号"
                    min-width="150"
                    prop="telecomIccid"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="联通号"
                    min-width="150"
                    prop="unicomIccid"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="移动号"
                    min-width="150"
                    prop="mobileIccid"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="activeIccid"
                    label="在线卡号"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    prop="changeReason"
                    show-overflow-tooltip
                    label="切换原因"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    prop="changeTime"
                    show-overflow-tooltip
                    label="切换时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    show-overflow-tooltip
                    label="创建时间"
                    min-width="150"
                    >
                </el-table-column>
                <!-- <el-table-column
                    prop="chipId"
                    show-overflow-tooltip
                    label="chipId"
                    min-width="120"
                    >
                </el-table-column> -->
                <el-table-column
                    prop="mac"
                    show-overflow-tooltip
                    label="物理地址"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="imei"
                    show-overflow-tooltip
                    label="IMEI号"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="ssid"
                    show-overflow-tooltip
                    label="SSID"
                    min-width="120"
                    >
                </el-table-column>
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import { getVirtualInfoList,exportVirtualInfoList } from "@/api/virtualCard.js"
export default {
    mixins:[resize],
    data(){
        return {
            quaryForm:{
                virtualIccid:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getList()
    },
    methods: {
        getList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.quaryForm,params)
            getVirtualInfoList(this.quaryForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getList()
        },

        onSearch(){
            this.page=1
            this.getList()
        },

         exportExcel(){
             exportVirtualInfoList(this.quaryForm).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
         }

        
    },
}
</script>

<style>
    .virtual-info-list .el-form-item__label{
        vertical-align: top;
    }
</style>