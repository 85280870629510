<template>

<div class="add-imei">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="库编号:">
            <el-select  v-model="form.imeiBankCode" @change="showAgent" clearable filterable>
                <el-option v-for="(item,index) in imeiBankCodeList" :label="item.imeiBankCode" :value="item.imeiBankCode" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="需要绑定:">
            <el-select filterable v-model="form.needBind">
                <el-option label="需要绑定" value="1"></el-option>
                <el-option label="不需要绑定" value="0"></el-option>
            </el-select>
        </el-form-item>
        <!-- <el-form-item label="客户:" prop="customerName" required>
            <el-input v-model="form.customerName"></el-input>
        </el-form-item> -->
        <el-form-item label="数量:" prop="amount" required>
            <el-input v-model="form.amount"></el-input>
        </el-form-item>
        <el-form-item label="上传IMEI文件（excel）:" required>
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
            </div>
        </el-form-item>
        <el-form-item label="IMEI:">
             <el-input v-model="form.imeis" type="textarea"  placeholder="请输入imei和随机码,每行一组，用英文逗号分开"></el-input>
        </el-form-item>
        <el-form-item label="代理商">
                <el-select v-model="form.customerId" disabled>
                    <el-option v-for="(item,index) in agentList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="重复IMEI：" required prop="cover">
					<el-radio-group v-model="form.cover">
						<el-radio label="0">跳过</el-radio>
						<el-radio label="1">覆盖</el-radio>
					</el-radio-group>

				</el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel('form')">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {importAddSync,imeiBankCode,getList4agent} from '@/api/meCardBindManagement/imeiLibrary.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    data(){
        return {
            form:{
                imeiBankCode:'',
                amount:'',
                needBind:'1',
                // customerName:''
                imeis:'',
                customerId:'',
                cover:"",
                agentId:"",
                agentName:"",
            },
            imeiBankCodeList:[],
            agentList:[],
            rules: {
                amount: [
                    { required: true, message: '请选择数量', trigger: 'blur' }
                ],
                cover: [
                    { required: true, message: '请选择重复IMEI处理方法', trigger: 'blur' }
                ],
                // customerName: [
                //     { required: true, message: '请选择客户名称', trigger: 'blur' }
                // ]
            },
        }
    },
    mounted(){
        this._imeiBankCode()
        this.getAgentList()
    },
    methods:{
         getAgentList(){
            getList4agent().then(res=>{
                this.agentList = res
            })
        },
        showAgent(val){
            this.imeiBankCodeList.forEach(item=>{
                if(item.imeiBankCode==val){
                    this.form.customerId = item.customerId
                    this.form.agentId = item.customerId
                    this.agentList.forEach(itemone=>{
            if(itemone.id == this.form.agentId ){
             this.form.agentName = itemone.name
            }
            })
                }
            })
        },
        uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            // alert('submit!');
                            let params = new FormData()
                            params.append('fileurl',file)
                            params.append('imeiBankCode',this.form.imeiBankCode)
                            params.append('amount',this.form.amount)
                            params.append('needBind',this.form.needBind)
                            params.append('customerId',this.form.customerId)
                            // params.append('customerName',this.form.customerName)
                              params.append('agentId',this.form.agentId)
                            params.append('agentName',this.form.agentName)
                            params.append('cover',this.form.cover)
                            importAddSync(params).then(res=>{
                                if (res.system_result_key == '0') {
                                    this.$alert('导入成功，请到导入记录中下载', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.$emit('close-dialog')
                                        this.$router.push('/tob/i_import_list')
                                    });
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                }
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
            }else if(!!this.form.imeis){
                        this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let params = new FormData()
                            params.append('imeis',this.form.imeis)
                            params.append('imeiBankCode',this.form.imeiBankCode)
                            params.append('needBind',this.form.needBind)
                             params.append('amount',this.form.amount)
                             params.append('cover',this.form.cover)
                             params.append('agentId',this.form.agentId)
                            params.append('agentName',this.form.agentName)
                            importAddSync(params).then(res=>{
                                if (res.system_result_key == '0') {
                                    this.$alert('添加成功', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.$emit('close-dialog')
                                        // this.$router.push('/tob/i_import_list')
                                    });
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                }
                            })
                        } 
                    });
            }else{
                messageBox(this,'请选择文件或输入IMEI')
                return
            }
        },
        importExcel(){
            let name = 'importImei2.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        _imeiBankCode(){
            imeiBankCode(this.form).then((res)=>{
                this.imeiBankCodeList = res
            })
        }
    }
}
</script>

