<template>

<div class="setMeal table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">代理商套餐管理</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="套餐编码:">
                <el-input v-model="form.id"></el-input>
            </el-form-item>
            <el-form-item label="套餐名称:">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="类别:" v-if="currentRole=='4'&&agentTree=='1'||currentRole=='1'">
                <el-select v-model="form.category" @change="getSecondLists">
                    <el-option label="全部" value=""></el-option>
                     <el-option v-for="(item,index) in $store.getters.categorys" :label="item.name" :value="item.value" :key="index"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="子类别:" v-if="currentRole=='4'&&agentTree=='1'||currentRole=='1'">
                <el-select v-model="form.secondCategory" @visible-change="secondClick" placeholder="请选择">
                 	<el-option v-for="item in accountLists" :key="item.index" :label="item.label" :value="item.value">
					</el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
               <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column  label="套餐编码" prop="setMealId" width="300px"   show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐名称" prop="name" width="180px" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐类型" prop="typeStr" width="180px" show-overflow-tooltip></el-table-column>
            <el-table-column label="所属类别" prop="category">
                <template slot-scope="scope">
                    <span>{{categoryString(scope.row.category)}}</span>
                </template>
            </el-table-column>
              <el-table-column label="子类别" prop="secondCategoryStr" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐流量" prop="flowSize">
                <template slot-scope="scope">
                    <span>{{(scope.row.flowSize/1024).toFixed(2)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="套餐语音量" prop="voiceSize" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐排序" prop="mealSort" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐原价" prop="originalMoney"></el-table-column>
            <el-table-column label="套餐售价" prop="money"></el-table-column>
              <el-table-column label="无佣金标识" prop="noProfitFlag" >
                <template slot-scope="scope">
                   <span v-if="scope.row.noProfitFlag==1" style="color:#f00">无佣金</span>
                   <span v-else-if="scope.row.noProfitFlag==0" style="color:#f00"></span>
                   <span v-else style="color:#f00"></span>
                </template>
            </el-table-column>
             <el-table-column label="首充套餐" prop="firstRecharge" show-overflow-tooltip>
                <template slot-scope="scope">
                   <span v-if="scope.row.firstRecharge">是</span>
                   <span v-else>否</span>
                </template>
            </el-table-column>
              <el-table-column label="提现比" prop="rebateRatio"></el-table-column>
            <el-table-column label="修改人" prop="updateUser" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.updateUser">{{scope.row.updateUser}}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" prop="rebateRatio">
                <template slot-scope="scope">
                    <el-button type="primary" class="themed-button" @click="editSetMeal(scope.row.id,scope.row.money,scope.row.mealSort)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog title="修改销售价" :visible.sync="dialogBox" v-if="dialogBox">
        <el-form label-position="right" label-width="150px" :model="formSet">
            <el-form-item label="套餐售价" required>
                <el-input v-model="formSet.money" style="width:200px"></el-input><span style="margin-left:15px">请输入大于0的正数</span>
            </el-form-item>
            <el-form-item label="套餐排序" required>
                <el-input v-model="formSet.mealSort" style="width:200px"></el-input><span style="margin-left:15px">请输入大于0的正整数</span>
            </el-form-item>
            <el-form-item>
                <span style='color:#f00'>*注:为了保证利润请输入大于原价的套餐售价，套餐排序规则为从小到大排序</span>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button @click="dialogBox=false">取 消</el-button>
        </span>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {agentList,setSellMoney,exportAgentMealList} from '@/api/setMealManagment/setMeal.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import resize from '@/mixins/resize.js'
import store from '@/store'
import {mapGetters} from 'vuex'
import {getAccountList,} from '@/api/cardManagement/cardBill.js'

export default {
    name:'agentMeal',
    mixins:[resize],
    data(){
        return {
            form:{
                id:"",
                name:'',
                category:"",
                secondCategory:"",
            },
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            formSet:{
                money:'',
                mealSort:'',
                id:''
            },
             accountLists:[{label:'全部',value:''}],
            
        } 
    },
    mounted(){
        if(store.getters.categorys.length<1){
        store.dispatch('getCategory')
                }
        this._agentList()
         try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
    },
     computed:{
        	...mapGetters(
            	['currentRole','agentTree']
        	),
    	},
    methods:{
            secondClick(val){
          if(this.accountLists.length==1&&val){
                  this.$alert('请先选择类别', '提示', {
								confirmButtonText: '确定',
							});
                            return
          }
          
        },
         getSecondLists(val){
                      this.form.secondCategory = ""
                      this.accountLists = [{label:'全部',value:''}]
                   getAccountList({category:val}).then((res) => {
                    if(res.length>0){
                        res.forEach((item)=>{
                            this.accountLists.push({label:item.accountName,value:item.secondCategory})
                        })
                    }
                })
        },
          exportExcel(){
            exportAgentMealList(this.form).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        submitForm(){
            setSellMoney(this.formSet).then((res)=>{
                this.dialogBox = false
                this._agentList()
            })
        },
        editSetMeal(id,money,mealSort){
            this.formSet.money = money
            this.formSet.mealSort = mealSort
            this.formSet.id = id
            this.dialogBox = true
        },
        searchTable(){
            this._agentList()
        },
        categoryString(category){
             let cuCatagorys
            if(store.getters.categorys.length>0){
               store.getters.categorys.forEach(item=>{
                   if(category==item.value){
                     cuCatagorys = item.name
                   }
               })
            }
            return cuCatagorys
            // if (category == 1) return '电信';
            //  else if (category == 2) return '其他卡务';
            //   else if (category == 3) return '移动';
            //    else if (category == 6) return '联通Q'; 
            //    else if (category == 5) return 'DCP电信'; 
            //    else if (category == 7) return '新移动'; 
            //    else if (category == 8) return '新联通';
            //     else if (category == 4) return '联通';
        },
        handleSizeChange(val){
            this.pageSize = val
            this._agentList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._agentList()
        },
        _agentList(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            agentList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            }).catch((res)=>{
				messageBox(this,"暂无数据")
			})
        }
    },
    components:{
        SearchSelect
    }
}

</script>

<style>
.marginR15{margin-right: 15px;}
</style>
