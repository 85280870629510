<template>

<div class="discountManagement table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">折扣管理</span>
        <el-form inline class="hook right themed-form search-form">   
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addTicket">新增折扣</el-button>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="请输入搜索关键字，多个用空格隔开" v-model="discountName" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
                </el-input>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="折扣名称" prop="discountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="折扣额度" prop="discountValue" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.discountValue}}折</span>
                </template>
            </el-table-column>
            <el-table-column label="折扣状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 1">启用</span>
                    <span v-if="scope.row.status == 0">未启用</span>
                </template>
            </el-table-column>
            <el-table-column label="生效时间" prop="startTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.startTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="失效时间" prop="endTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.endTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="创建人" prop="createBy" show-overflow-tooltip></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.createTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="246px">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" v-if="scope.row.status == 1" @click="clickdisable(scope.row.id)">停止</el-button>
                    <el-button type="info" class="themed-button" v-else @click="clickable(scope.row.id)">启用</el-button>
                    <el-button type="info" class="themed-button" @click="showFilter(scope.row.id)">规则配置</el-button>
                    <el-button type="danger" class="themed-button" @click="delFilter(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogBox" v-if="dialogBox" width="70%">
        <filter-show :id="id" v-if="dialogContent == 1"></filter-show>
        <new-coupons v-if="dialogContent == 3"  @close="dialogBox=false"  @refresh="refreshData"></new-coupons>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {getDiscountList,updateData,deleteData} from '@/api/eventDiscountManagement/discountManagement.js'

    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'

    import FilterShow from '@/views/eventDiscountManagement/discountManagement/filterShow'
    import NewCoupons from '@/views/eventDiscountManagement/discountManagement/newCoupons'

    import resize from '@/mixins/resize.js'

    const ERR_OK = 0
    export default {
        name:'discountManagement',
        mixins:[resize],
        data(){
            return {
                discountName:'',
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
                dialogBox:false,
                dialogTitle:'',
                dialogContent:'',
                id:''
            }
        },
        mounted(){
            this._getDiscountList()
        },
        methods:{
            addTicket(){
                this.dialogBox = true
                this.dialogContent = 3
                this.dialogTitle = '新增折扣'
            },
            clickdisable(id){
                confirmBox(this,'确定要停止折扣么？',null,()=>{
                   this._updateData(id,0)
                })
            },
            clickable(id){
               confirmBox(this,'确定要开启折扣么？',null,()=>{
                   this._updateData(id,1)
               }) 
            },
            showFilter(id){
                this.dialogBox = true
                this.dialogContent = 1
                this.dialogTitle = '规则配置'
                this.id = id
            },
            delFilter(id){
               confirmBox(this,'确定要删除么？',null,()=>{
                   deleteData({id}).then((res)=>{
                       this._getDiscountList()
                   })
               })  
            },
            searchData(){
                this._getDiscountList()
            },
            refreshData(){
                this.dialogBox = false
                this._getDiscountList()
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._getDiscountList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._getDiscountList()
            },
            formatDatebox(value){
                // if (value == null || value == '') {  
                //     return '';  
                // }  
                // var dt;  
                // if (value instanceof Date) {  
                //     dt = value;  
                // } else {  
                //     dt = new Date(value);  
                // }  
                // // return dt.format("yyyy-MM-dd hh:mm:ss"); 
                // return  dt.toJSON().substr(0, 19).replace('T', ' ')
                  if(value){
            var date = new Date(value);
             var y = date.getFullYear();
             var m = date.getMonth() + 1;
             m = m < 10 ? ('0' + m) : m;
             var d = date.getDate();
             d = d < 10 ? ('0' + d) : d;
             var h = date.getHours();
             var minute = date.getMinutes();
             minute = minute < 10 ? ('0' + minute) : minute;
	         var second = date.getSeconds()
	         second = second < 10 ? ('0' + second) : second;
            return y + '-' + m + '-' + d+' '+h+':'+minute+":"+second;
			}else{
				return "-"
			}
            },
            _updateData(id,status){
                let params = {id,status}
                updateData(params).then((res)=>{
                    this._getDiscountList()
                })
            },
            _getDiscountList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                params.discountName = this.discountName
                getDiscountList(params).then((res)=>{
                    // console.log(res)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        },
        components:{
            SearchSelect,
            FilterShow,
            NewCoupons
        }
    }
</script>

<style>
.marginR10{margin-right:10px;}
.discountManagement .search-form .el-input .el-input__inner {width:150px}
.discountManagement .el-form-item__content .el-input-group{vertical-align:middle}
</style>
