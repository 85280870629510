<template>
    <div class="speed-service-operate fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">加速服务操作记录</span>
            <el-form inline ref="operateForm" v-model="operateFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="operateFormData.iccid" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item label="运营商">
                    <el-select v-model="operateFormData.accountId" filterable placeholder="运营商">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="操作类型">
                    <el-select v-model="operateFormData.type" filterable placeholder="操作类型">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="订购" value="1"></el-option>
                        <el-option label="退订" value="2"></el-option>
                        <el-option label="编辑" value="3"></el-option>
                        <el-option label="充值" value="4"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="operateFormData.qq" placeholder="QQ" class="iccid-width"></el-input>
                </el-form-item>

                
                <el-form-item label="状态">
                    <el-select v-model="operateFormData.status" filterable placeholder="状态">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="成功" value="1"></el-option>
                        <el-option label="失败" value="0"></el-option>
                        
                    </el-select>
                </el-form-item>
                 <el-form-item label="执行时间范围">
					<el-date-picker style="width: 126px;" placeholder="起始日期" v-model="operateFormData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="结束日期" v-model="operateFormData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="iccid"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="qq"
                    label="qq"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="模式"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.requestModel == 0">直充模式</span>
                        <span v-else-if="scope.row.requestModel == 1">订阅直充模式</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="setmealDay"
                    label="加速服务天数"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="setmealCount"
                    label="加速服务份数"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="type"
                    label="操作类型"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == '1'">订购</span>
                        <span v-else-if="scope.row.type == '2'">退订</span>
                        <span v-else-if="scope.row.type == '3'">编辑</span>
                        <span v-else-if="scope.row.type == '4'">充值</span>
                    </template>

                </el-table-column>
                 <el-table-column
                 show-overflow-tooltip
                    prop="content"
                    label="变更内容"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="执行时间"
                    min-width="150"
                    >
                     <template slot-scope="scope">
                        <span v-if="scope.row.createTime">{{formatTime(scope.row.createTime)}}</span>
                    </template> 
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="状态"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == '1'">成功</span>
                        <span v-else-if="scope.row.status == '0'">失败</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="orderId"
                    label="订单id"
                    min-width="120"
                    >  
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="operator"
                    label="操作人"
                    min-width="120"
                    >  
                </el-table-column>
                
                
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {agentOperatorList} from '@/api/order.js'
import { speedOperateList,exportSpeedOperate } from "@/api/speedService.js"
export default {
    name:'speedServiceOperate',
    mixins:[resize],
    data(){
        return {
            operateFormData:{
                iccid:'',
                accountId:'',
                type:'',
                qq:'',
                status:'',
                createTime:'',
                startTime:'',
                endTime:'',
            },
            accountIdList:[],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            editForm:{

            }
        }
    },
    created(){
        this.getAgentOperatorList()
        this.getSpeedOperateList()
    },
    methods: {
        getAgentOperatorList(){
            agentOperatorList().then(res=>{
                this.accountIdList = res        
             })
        },
        getSpeedOperateList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.operateFormData,params)
            speedOperateList(this.operateFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getSpeedOperateList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSpeedOperateList()
        },

        onSearch(){
            this.page=1
            this.getSpeedOperateList()
        },

        exportExcel(){
            exportSpeedOperate(this.operateFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
         },

        
    },
}
</script>

<style>
    .speed-service-operate .el-form-item__label{
        vertical-align: top;
    }
</style>