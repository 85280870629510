<template>

<div class="card-bill table-hook fadeInRight" v-loading="loading">
    <div class="title-box">
        <span class="m-title">卡台账单</span>
        <el-form ref="cardForm" :model="cardForm" inline class="hook right themed-form search-form" :rules="rules">
            <el-form-item label="是否分销:">
                <el-select v-model="cardForm.isdistribution" placeholder="全部">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已分销" value="yes"></el-option>
                    <el-option label="未分销" value="no"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="卡状态:">
                <el-select v-model="cardForm.status" placeholder="全部">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="库存" value="0"></el-option>
                    <el-option label="待激活" value="1"></el-option>
                    <el-option label="正常" value="2"></el-option>
                    <el-option label="断网" value="3"></el-option>
                    <el-option label="停机" value="4"></el-option>
                    <el-option label="异常" value="5"></el-option>
                    <el-option label="注销" value="7"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="卡片类型:">
                <el-select v-model="cardForm.type" placeholder="全部">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="包月" value="1"></el-option>
                    <el-option label="包年" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开卡公司:">
                <el-select v-model="cardForm.accountId"  filterable>
					<el-option v-for="item in accountLists" :key="item.index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
            </el-form-item>
            <!-- <el-form-item label="分销商:">
                <el-input v-model="cardForm.agentThreeName"></el-input>
            </el-form-item> -->
            <el-form-item label="分销商:">
                <el-select v-model="cardForm.agentIds" multiple   filterable :multiple-limit="2">
					<el-option v-for="item in agentThreeLists" :key="item.index" :label="item.agentName" :value="item.agentId">
					</el-option>
				</el-select>
            </el-form-item>
            <!-- <el-form-item label="套餐">
                <el-input v-model="cardForm.setMealName"></el-input>
            </el-form-item> -->
            <el-form-item label="套餐">
                <el-select v-model="cardForm.setMealIds" multiple    filterable    @change="vvvchange">
					<el-option v-for="(item,index) in setMealNameLists" :key="index" :label="item.mealName" :value="item.mealId">
					</el-option>
				</el-select>
            </el-form-item>
            <el-form-item label="接入号:">
                <el-input v-model="cardForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="ICCID号/短号">
                <el-input v-model="cardForm.iccidMark" class="iccid-width"></el-input>
            </el-form-item>
             <el-form-item label="短号号段:">
                <el-input v-model="cardForm.shortIccidStart" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item>至</el-form-item>
            <el-form-item><el-input v-model="cardForm.shortIccidEnd" class="iccid-width"></el-input></el-form-item>
            <el-form-item label="ICCID号段:">
                <el-input v-model="cardForm.iccidStart" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item>至</el-form-item>
            <el-form-item><el-input v-model="cardForm.iccidEnd" class="iccid-width"></el-input></el-form-item>
            <el-form-item label="导入时间:">
                <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="cardForm.startTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>至</el-form-item>
            <el-form-item>
                <el-date-picker placeholder="选择日期" value-format="yyyy-MM-dd" v-model="cardForm.endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="导入批次:">
                <el-input v-model="cardForm.importNum"></el-input>
            </el-form-item>
            <el-form-item label="激活时间:">
                <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="cardForm.servstartTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>至</el-form-item>
            <el-form-item>
                <el-date-picker placeholder="选择日期" value-format="yyyy-MM-dd" v-model="cardForm.servendTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="到期时间:">
                <el-select v-model="cardForm.expireMonth" placeholder="全部">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="一个月" value="1"></el-option>
                    <el-option label="二个月" value="2"></el-option>
                    <el-option label="三个月" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="IMEI:">
                <el-input v-model="cardForm.imei"></el-input>
            </el-form-item> <el-form-item label="IMSI:">
                <el-input v-model="cardForm.imsi"></el-input>
            </el-form-item>
            <el-form-item label="备注:">
                <el-input v-model="cardForm.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="reloadPageData">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-download" class="themed-button" @click="exportExcel">导出excel</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addRecord">网卡分销</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="cardBatchRechage">批量充值</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-s-claim" class="themed-button" @click="recycleSim">网卡回收</el-button>
            </el-form-item>
             <el-form-item>
                <el-button type="primary" icon="el-icon-s-claim" class="themed-button" @click="oneDownload">生成一机一码</el-button>
            </el-form-item>
            <el-form-item>
				<el-popover
					placement="bottom"
							trigger="click">
							<div style="width:149px; line-height:22px;overflow-y:auto;max-height:350px">
									<p style="margin-bottom:10px">筛选条件</p>
                                    <el-checkbox v-model="checkList[0]">接入号码</el-checkbox>
                                    <el-checkbox v-model="checkList[1]">IMEI</el-checkbox>
                                    <el-checkbox v-model="checkList[2]">短号</el-checkbox>
									<el-checkbox v-model="checkList[3]">IMSI</el-checkbox>
									<el-checkbox v-model="checkList[4]">开卡公司</el-checkbox>
									<el-checkbox v-model="checkList[5]">首次充值时间</el-checkbox>
									<el-checkbox v-model="checkList[6]">导入时间</el-checkbox>
									<el-checkbox v-model="checkList[7]">套餐总流量</el-checkbox>
									<el-checkbox v-model="checkList[8]">已使用流量</el-checkbox>
									<el-checkbox v-model="checkList[9]">剩余流量</el-checkbox>
									<el-checkbox v-model="checkList[10]">激活时间</el-checkbox>
									<el-checkbox v-model="checkList[11]">导入批次</el-checkbox>
									<el-checkbox v-model="checkList[12]">到期时间</el-checkbox>
									<el-checkbox v-model="checkList[13]">套餐</el-checkbox>
									<el-checkbox v-model="checkList[14]">卡片类型</el-checkbox>
									<el-checkbox v-model="checkList[15]">分销商姓名</el-checkbox>
									<el-checkbox v-model="checkList[16]">卡状态</el-checkbox>
                                    <el-checkbox v-model="checkList[17]" v-if="isCaptal!='1'">余额</el-checkbox>
                                    <el-checkbox v-model="checkList[18]">备注</el-checkbox>
                                    <el-checkbox v-model="checkList[19]">总代理</el-checkbox>
                                    <el-checkbox v-model="checkList[20]">代理商层级</el-checkbox>
								</div>
							<el-button slot="reference" icon="el-icon-finished"></el-button>
						</el-popover>
			</el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData"  stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID号" width="180px">
                <template slot-scope="scope">
                   <a href="javascript:void(0)" @click="detailDate(scope.row)">{{scope.row.iccidMark}}</a>
               </template>
            </el-table-column>
            <el-table-column label="接入号码" prop="phone" show-overflow-tooltip   v-if="checkList[0]"></el-table-column>
            <el-table-column label="IMEI" prop="imei" show-overflow-tooltip v-if="checkList[1]"></el-table-column>
             <el-table-column label="短号" prop="shortIccid" show-overflow-tooltip v-if="checkList[2]"></el-table-column>
              <el-table-column label="IMSI" prop="imsi" show-overflow-tooltip v-if="checkList[3]"></el-table-column>
            <el-table-column label="开卡公司" prop="accountName" show-overflow-tooltip v-if="checkList[4]"></el-table-column>
            <el-table-column label="首次充值时间" prop="enabledTime" show-overflow-tooltip v-if="checkList[5]"></el-table-column>
            <el-table-column label="导入时间" show-overflow-tooltip v-if="checkList[6]">
               <template slot-scope="scope">
                   <span>{{createDateTime(scope.row.createDateTime)}}</span>
               </template>
            </el-table-column>
             <el-table-column label="套餐总流量" prop="flowSize" show-overflow-tooltip v-if="checkList[7]">
                <template slot-scope="scope">
                    <span v-if="scope.row.cardLifeEntity.flowSize">{{parseFloat(scope.row.cardLifeEntity.flowSize).toFixed(2) + 'MB'}}</span>
                    <span v-else-if="!scope.row.cardLifeEntity.flowSize">-</span>
                </template>
            </el-table-column>
             <el-table-column label="已使用流量" prop="totalBytesCnt" show-overflow-tooltip  v-if="checkList[8]">
                <template slot-scope="scope">
                    <span v-if="scope.row.totalBytesCnt">{{parseFloat(scope.row.totalBytesCnt).toFixed(2)+"MB"}}</span>
                    <span v-else-if="!scope.row.totalBytesCnt">-</span>
                </template>
            </el-table-column>
             <el-table-column label="剩余流量" prop="" show-overflow-tooltip v-if="checkList[9]">
                <template slot-scope="scope">
                    <span v-if="(scope.row.cardLifeEntity.flowSize - scope.row.totalBytesCnt)">{{parseFloat(scope.row.cardLifeEntity.flowSize - scope.row.totalBytesCnt).toFixed(2) +'MB'}}</span>
                    <span v-else-if="!(scope.row.cardLifeEntity.flowSize - scope.row.totalBytesCnt)">-</span>
                </template>
            </el-table-column>

            <el-table-column label="激活时间" prop="servCreateDate" show-overflow-tooltip v-if="checkList[10]">
                <template slot-scope="scope">
                    <span v-if="scope.row.servCreateDate">{{scope.row.servCreateDate.substring(0,10)}}</span>
                    <span v-else-if="!scope.row.servCreateDate">-</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="激活时间" prop="enabledTime" show-overflow-tooltip v-if="checkList[10]">
                <template slot-scope="scope">
                    <span v-if="scope.row.enabledTime">{{scope.row.enabledTime.substring(0,10)}}</span>
                    <span v-else-if="!scope.row.enabledTime">-</span>
                </template>
            </el-table-column> -->
            <el-table-column label="导入批次" prop="importNum" show-overflow-tooltip v-if="checkList[11]"></el-table-column>
            <el-table-column label="到期时间" prop="expirationDate" show-overflow-tooltip v-if="checkList[12]"></el-table-column>
            <el-table-column label="套餐" prop="setMealName" show-overflow-tooltip v-if="checkList[13]"></el-table-column>
            <el-table-column label="卡片类型" prop="type"  v-if="checkList[14]">
                <template slot-scope="scope">
                    <span v-if="scope.row.type===1">包月</span>
                    <span v-else-if="scope.row.type===2">包年</span>
                </template>
            </el-table-column>
            <el-table-column label="分销商姓名" prop="agentThreeName" show-overflow-tooltip v-if="checkList[15]"></el-table-column>
            <el-table-column label="卡状态" prop="statusString"  v-if="checkList[16]"></el-table-column>
            <el-table-column label="余额" prop="balance" v-if="checkList[17]&&isCaptal!='1'"></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip  v-if="checkList[18]"></el-table-column>
             <el-table-column label="总代理" prop="agentTopName" show-overflow-tooltip  v-if="checkList[19]"></el-table-column>
            <el-table-column label="代理商层级" prop="agentThreeName" show-overflow-tooltip  v-if="checkList[20]"></el-table-column>
            <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                    <el-button type="danger" class="themed-button" @click="delectDate(scope.row.iccidMark)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    
    <el-dialog title="网卡分销" :visible.sync="dialogDistributionCard">
        <el-form label-position="right" label-width="200px" :model="setPackageForm" ref="setPackageForm">
            <el-form-item label="代理商:" >
                <el-select v-model="setPackageForm.agentList"  filterable>
					<el-option v-for="item in agentList" :key="item.index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="recordSave">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogDistributionCard = false">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog title="批量充值" :visible.sync="dialogCardBatchRechage" v-if="dialogCardBatchRechage">
        <el-form label-position="right" label-width="100px" :model="cardBatchRechageForm" ref="cardBatchRechageForm">
            <el-form-item label="套餐:" >
                <el-select v-model="cardBatchRechageForm.packageIds"  filterable>
					<el-option v-for="item in setMeal" :key="item.index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
            </el-form-item>
        </el-form>
        <div style="color:#f00">
            注意：批量充值卡片只能充值同样的套餐，非同一个运营商的卡请不要混和操作，单次操作请不要超过5W张，否则会引起系统卡顿
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="rechargeSave"   v-preventReClick='2000' :disabled="isDisable"   >确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogCardBatchRechage = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {listData,getAccountList,checkExportCount,getagentList,distributionCard,getSetMealNew,batchRecharge,cardRecycleByExcel,findCardInfo,deleteCard,oneDownloadExport,setmealListForUser,agentListForUser} from '@/api/cardManagement/cardBill.js'

    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'
    import loading from '@/utils/Loading.js'
    import resize from '@/mixins/resize.js'
    import storage from '@/utils/storage.js'
    import { mapGetters } from "vuex";
    import {mapMutations} from "vuex"
import chargeLogDetailVue from '../agentManagement/chargeLogDetail.vue'

    const ERR_OK = 0
    export default {
        name:'cardBill',
        mixins:[resize],
        data(){
            return {
                setMealNameLists:[],
                agentThreeLists:[],
                loading:false,
                cardForm:{
                    isdistribution:'',
                    status:'',
                    type:'',
                    accountId:'',
                    agentThreeName:'',
                    setMealIds:"",
                    agentIds:"",
                    setMealName:'',
                    phone:'',
                    iccidMark:'',
                    iccidStart:'',
                    iccidEnd:'',
                    startTime:'',
                    endTime:'',
                    importNum:'',
                    servstartTime:'',
                    servendTime:'',
                    expireMonth:'',
                    imei:'',
                    remark:'',
                    shortIccidStart:"",
                    shortIccidEnd:"",
                    imsi:"",
                },
                rules:{
                    iccidMark:[
                        { required: true, message: 'ICCID号不能为空', trigger: 'blur' }
                    ],
                    iccidStart:[
                        { required: true, message: 'ICCID号段不能为空', trigger: 'blur' }
                    ],
                    iccidEnd:[
                        { required: true, message: 'ICCID号段不能为空', trigger: 'blur' }
                    ],
                },
                accountLists:[{label:'请选择',value:''}],
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
                dialogDistributionCard:false,
                setPackageForm:{
                    "agentList":[]
                },
                agentList:[],
                cardBatchRechageForm:{
                    packageIds:''
                },
                dialogCardBatchRechage:false,
                setMeal:[],
                isDisable:false,
                checkList:[],
            }
        },
        mounted(){
            this._getAccountList()
            this._listData()
            this.getAgentListForUser()
            this.getSetmealListForUser()
        },
        created(){
          let list = []
			for (let index = 0; index < 21; index++) {
				list.push(true)
			}
			let checkList = ""
			if(storage.support){
				checkList = storage.get("checkList")
				if(!checkList){
					this.checkList = list
					storage.set("checkList",JSON.stringify(list))
				}else{
					this.checkList = JSON.parse(checkList)
				}
			}else{
				this.checkList = list
			}
            try{
               let test = null
               test.forEach(item=>{
                console.log(item)
               }) 
               
            }catch(error){
               console.log("notioni:"+error)
            }
          

        },
        watch:{
			
			checkList: function(newValue, old){
				if(storage.support){
					storage.set("checkList",JSON.stringify(newValue))
				}
			}
			
		},
        
        methods:{
            ...mapMutations(
                ["SET_ICCID_OR_PHONE","SET_STATUS","SET_TYPE"]
            ),
            vvvchange(e){
            console.log(e)
            },
            getAgentListForUser(){
             agentListForUser().then(res=>{
                console.log(391)
                this.agentThreeLists = res
             })
            },
            getSetmealListForUser(){
              setmealListForUser().then(res=>{
                console.log(396)
                this.setMealNameLists = res
             })
            },
            createDateTime(createDateTime){
                return createDateTime.substr(0,4)+"-"+createDateTime.substr(4,2)+"-"+createDateTime.substr(6,2)+
                " "+createDateTime.substr(8,2)+":"+createDateTime.substr(10,2)+":"+createDateTime.substr(12,2); 
            },
            detailDate(row){
                this.SET_ICCID_OR_PHONE(row.iccidMark)
                this.SET_STATUS(row.status)
                this.SET_TYPE(row.type)
                this.$router.push({
                        path: '/cardManagement/cardBillDetail',
                        query: {
                            iccidOrPhone: row.iccidMark,
                            status: row.status,
                            type: row.type,
                        }
                })

            },
            delectDate(iccidMark){
                this.delect_iccidMark = iccidMark
                confirmBox(this,iccidMark+": 确定要删除吗?",'操作提示',()=>{
                    deleteCard({'iccid':iccidMark}).then((res)=>{
                        messageBox(this,'操作成功')
                        this._listData()
                    })
                })
            },
            reloadPageData(){
               let r = /^[0-9a-zA-Z]+$/
               if(this.cardForm.iccidStart.trim()!="" && !r.test(this.cardForm.iccidStart)  ){
                messageBox(this,'ICCID只能包含数字和字母','网卡卡台账单')
                  return false
				}
              if(this.cardForm.iccidEnd.trim()!="" && !r.test(this.cardForm.iccidEnd)){
               messageBox(this,'ICCID只能包含数字和字母','网卡卡台账单')
                  return false
               }

               let m = /^[0-9]+$/
			 if(this.cardForm.shortIccidStart.trim()!="" && !m.test(this.cardForm.shortIccidStart)  ){
                messageBox(this,'短号只能包含数字','网卡卡台账单')
                  return false
				}
              if(this.cardForm.shortIccidEnd.trim()!="" && !m.test(this.cardForm.shortIccidEnd)){
               messageBox(this,'短号只能包含数字','网卡卡台账单')
                  return false
               }
                this._listData("search")
            },
            exportExcel(){
                this._checkExportCount()
            },
            addRecord(){
                if(!this._checkIccid()){
                    return
                }
                this.dialogDistributionCard = true
                getagentList(this.cardForm).then((res) => {
                    if(res.length>0){
                        this.agentList = res.map((item)=>{
                            return {
                                label:item.name,
                                value:item.id
                            }
                        })
                    }else{
                        this.agentList = []
                    }
                    
                })
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._listData()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._listData()
            },
            recordSave(){
                let params = {}
                Object.assign(params, this.cardForm)
                Object.assign(params, this.setPackageForm)
                distributionCard(params).then((res) => {
                    // messageBox(this,'分销成功','网卡分销')
                    // this.dialogDistributionCard = false
                    // this._listData()
                    if (res.system_result_key == '0') {
                        this.dialogDistributionCard = false
						this.$alert('提示，请到网卡分销记录查看分销结果', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/historyRecord/networkCardDistribution')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
                    
                })
            },
            cardBatchRechage(){
                if(!this._checkIccid()){
                    return
                }
                this.dialogCardBatchRechage = true
                this.cardBatchRechageForm.packageIds=""
                getSetMealNew(this.cardForm).then((res) => {
                    this.setMeal = res.map((value,index,arr)=>{
                        return {value:value.id,label:value.name+'('+value.accountName+')'}
                    })
                }).catch(error=>{
                  console.log(error)
                  this.setMeal =[]
                })
            },
            rechargeSave(){
                if(this.cardBatchRechageForm.packageIds === ''){
                        messageBox(this,'请选择套餐')
                        return
                    }
                this.isDisable = true
                let params = {}
                Object.assign(params, this.cardForm)
                Object.assign(params, this.cardBatchRechageForm)
                batchRecharge(params).then((res) => {
                    this.isDisable = false
                    messageBox(this,'充值成功','批量充值')
                    this.dialogCardBatchRechage = false
                })
            },
            recycleSim(){
                if(!this._checkIccid()){
                    return
                }
                this.$confirm("确定要进行回收吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this._cardRecycleByExcel()
				})
            },
            _cardRecycleByExcel(){
                let params = {fromRange:true}
                cardRecycleByExcel(Object.assign(params, this.cardForm)).then((res) => {
                    if(res.isSuccess === "1"){
                        messageBox(this,'回收完成','网卡回收')
                    }
                    this.$router.push('/historyRecord/NICrRecovery')
                })
            },
            oneDownload(){
             oneDownloadExport(this.cardForm).then(res=>{
                if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
             })
            },
            _listData(str){
                this.loading = true
                let params = {}
                params.pageSize = this.pageSize
                if(str=="search"){
                  params.pageNo = 1
                }else{
                 params.pageNo = this.currentPage
                }
               
                Object.assign(params, this.cardForm)
                listData(params).then((res)=>{
                    this.loading = false
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                }).catch((error)=>{
                    this.loading = false
                })
            },
            _getAccountList(){
                getAccountList().then((res) => {
                    this.accountLists = [{label:'请选择',value:''}]
                    if(res.length>0){
                        res.forEach((item)=>{
                            this.accountLists.push({label:item.accountName,value:item.id})
                        })
                    }
                })
            },
            _checkExportCount(){
                let params = this.cardForm//添加条件导出
				params.includeFields = this.checkList.join(',')
                checkExportCount(params).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
            },
            _checkIccid(){
                let that = this
                if((this.cardForm.iccidStart === '' || this.cardForm.iccidEnd === '')&&(this.cardForm.shortIccidStart === '' || this.cardForm.shortIccidEnd === '')){
                    messageBox(that,'ICCID/短号号段不能为空','网卡卡台账单')
                    return false
                }
                if(this.cardForm.iccidStart !=""  &&this.cardForm.iccidEnd !=""&&this.cardForm.iccidStart > this.cardForm.iccidEnd){
                    messageBox(that,'ICCID结束号段小于开始号段','网卡卡台账单')
                    return false
                }
                  let r = /^[0-9a-zA-Z]+$/
                  if(this.cardForm.iccidStart !=""  &&this.cardForm.iccidEnd !=""){
                 if(!r.test(this.cardForm.iccidStart) || !r.test(this.cardForm.iccidEnd)){
                messageBox(this,'ICCID只能包含数字和字母','网卡卡台账单')
                  return false
				}
                  }

                  if(this.cardForm.shortIccidStart !=""  &&this.cardForm.shortIccidEnd !=""&&this.cardForm.shortIccidStart > this.cardForm.shortIccidEnd){
                    messageBox(that,'短号结束号段小于开始号段','网卡卡台账单')
                    return false
                }
                  let m = /^[0-9]+$/
                  if(this.cardForm.shortIccidStart !=""  &&this.cardForm.shortIccidEnd !=""){
                 if(!m.test(this.cardForm.shortIccidStart) || !m.test(this.cardForm.shortIccidEnd)){
                messageBox(this,'短号只能包含数字和字母','网卡卡台账单')
                  return false
				}
                  }
				
                return true
            }
        },
        components:{
            SearchSelect
        },
        computed: {
    ...mapGetters(["isCaptal"]),
  },
    }
</script>

<style>
    .card-bill{position:relative;}
    .card-bill .el-table .cell{height:23px;}
</style>
