<template>

<div class="file">
    <div class="title-box clearfix">
			<span class="m-title">文档管理</span>
		</div>
		<div class="content-box">
			<el-table ref='table'  :data="tableData" stripe style="width: 100%;" :max-height="maxTableHeight">
				<el-table-column prop="fileName" label="文档名称">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button class="themed-button" type="primary" @click="downLoad">下载</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
		</div>
</div>

</template>

<script type="text/ecmascript-6">
import resize from '@/mixins/resize.js'
export default {
    mixins:[resize],
    data(){
        return {
            page:1,
			pageSize:15,
			total:1,
			tableData:[{fileName:'操作手册'}],
        }
    },
    mounted(){

    },
    methods:{
        downLoad(){
           window.location.href = 'http://file.sunshineiot.net/upload/tob/1629170611266_78.pdf'
        },
        onPageChange(page){
            this.page = page
            // this.getCardList()
        },
        handleSizeChange(size){
            this.pageSize = size
            // this.getCardList()
        }
    },
    
}
</script>
