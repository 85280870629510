<template>
    <el-form :model="formData" :rules="rules" ref="dataForm">
        <el-form-item label="配置名:" prop="name" :label-width="formLabelWidth">
            <el-input v-model="formData.name" autocomplete="off"></el-input>
        </el-form-item>

        <!--<el-form-item label="亲情号:" prop="showQinNum" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showQinNum" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showQinNum" :label=false>隐藏</el-radio>
        </el-form-item>-->
        <el-form-item label="隐藏所有菜单:" prop="hideAll" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.hideAll" :label=true>是</el-radio>
            <el-radio v-model="jsonObject.hideAll" :label=false>否</el-radio>
        </el-form-item>
        <div v-if="!jsonObject.hideAll">
        <el-form-item label="客服:" prop="showKeFu" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showKeFu" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showKeFu" :label=false>隐藏</el-radio>
        </el-form-item>
        <el-form-item v-if="jsonObject.showKeFu" label="客服链接:" prop="keFuUrl" :label-width="formLabelWidth">
            <el-input v-model="jsonObject.keFuUrl" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="公众号:" prop="showGzh" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showGzh" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showGzh" :label=false>隐藏</el-radio>
        </el-form-item>
        <el-form-item v-if="jsonObject.showGzh" label="公众号名称:" prop="gzhName" :label-width="formLabelWidth">
            <el-input v-model="jsonObject.gzhName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="jsonObject.showGzh" label="公众号图片:" prop="gzhImageUrl" :label-width="formLabelWidth">
<!--            <el-input v-model="jsonObject.gzhImageUrl" autocomplete="off" placeholder="选择图片生成链接" :disabled=true></el-input>-->
            <input class="file-input" type="file" ref='fileImportInput'  @change="fileChange(0)"    />
        </el-form-item>
        <el-form-item label="点我优惠:" prop="showDwyh" :label-width="formLabelWidth" v-if="parseInt(isGuangyao)">
            <el-radio v-model="jsonObject.showDwyh" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showDwyh" :label=false>隐藏</el-radio>
        </el-form-item>
        <!--<el-form-item label="智能诊断:" prop="showSd" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showSd" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showSd" :label=false>隐藏</el-radio>
        </el-form-item>-->
        <!--<el-form-item label="提前生效套餐:" prop="showAheadMeal" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showAheadMeal" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showAheadMeal" :label=false>隐藏</el-radio>
        </el-form-item>-->
        <!--<el-form-item label="购买套餐:" prop="showBuyMeal" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showBuyMeal" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showBuyMeal" :label=false>隐藏</el-radio>
        </el-form-item>-->
        <!--<el-form-item label="实名状态:" prop="showRealName" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showRealName" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showRealName" :label=false>隐藏</el-radio>
        </el-form-item>-->
        <!--<el-form-item label="流量查询:" prop="showFlowQuery" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showFlowQuery" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showFlowQuery" :label=false>隐藏</el-radio>
        </el-form-item>-->
        <!--<el-form-item label="我的钱包:" prop="showWallet" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showWallet" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showWallet" :label=false>隐藏</el-radio>
        </el-form-item>-->
        <!--<el-form-item label="充值记录:" prop="showRechargeRecord" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showRechargeRecord" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showRechargeRecord" :label=false>隐藏</el-radio>
        </el-form-item>-->
        <!--<el-form-item label="退出登录:" prop="showLoginOut" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showLogOut" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showLogOut" :label=false>隐藏</el-radio>
        </el-form-item>-->
        <el-form-item label="官方投诉:" prop="showComplaint" :label-width="formLabelWidth" v-if="isCaptal!='1'">
            <el-radio v-model="jsonObject.showComplaint" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showComplaint" :label=false>隐藏</el-radio>
        </el-form-item>
        <el-form-item label="设备管理:" prop="showSbManager" :label-width="formLabelWidth" v-if="isCaptal!='1'">
            <el-radio v-model="jsonObject.showSbManager" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showSbManager" :label=false>隐藏</el-radio>
        </el-form-item>
        <el-form-item label="小诺商城:" prop="showNuo" :label-width="formLabelWidth" v-if="parseInt(isGuangyao)">
            <el-radio v-model="jsonObject.showNuo" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showNuo" :label=false>隐藏</el-radio>
        </el-form-item>
        <el-form-item v-if="jsonObject.showNuo&&parseInt(isGuangyao)" label="小诺链接:" prop="nuoUrl" :label-width="formLabelWidth">
            <el-input v-model="jsonObject.nuoUrl" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="话费提现:" prop="showProfit" :label-width="formLabelWidth" v-if="parseInt(isGuangyao)">
            <el-radio v-model="jsonObject.showProfit" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showProfit" :label=false>隐藏</el-radio>
        </el-form-item>
          <el-form-item label="自定义菜单:" prop="showDefine" :label-width="formLabelWidth">
            <el-radio v-model="jsonObject.showDefine" :label=true>显示</el-radio>
            <el-radio v-model="jsonObject.showDefine" :label=false>隐藏</el-radio>
        </el-form-item>
        <el-form-item v-if="jsonObject.showDefine" label="名称:" prop="menuName" :label-width="formLabelWidth">
            <el-input v-model="jsonObject.menuName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="jsonObject.showDefine" label="链接:" prop="defineUrl" :label-width="formLabelWidth">
            <el-input v-model="jsonObject.defineUrl" autocomplete="off"></el-input>
        </el-form-item>
         <el-form-item v-if="jsonObject.showDefine" label="图标:" prop="defineImageUrl" :label-width="formLabelWidth">

            <input class="file-input" type="file" ref='fileImportInput1'  @change="fileChange(1)"    />
        </el-form-item>
        </div>
    </el-form>
    
</template>

<script>
    import {uploadPayOfflineUrl} from "@/api/agentManagement.js"
    import { mapGetters } from "vuex";
   
    export default {
        name: "MobileMenu",
        props: ['initData'],
        watch: {
            jsonObject:{
                handler (newVal, oldVal) {
                    this.formData.jsonStr = JSON.stringify(newVal)
                    console.log(this.formData)
                },
                deep: true
            },
            initData:function (newVal, oldVal) {
                this.formData = newVal;
                console.log(newVal)
                this.jsonObject = JSON.parse(newVal.jsonStr)
                console.log(this.jsonObject.showProfit)
                // if(!this.jsonObject.showProfit){
                //    this.jsonObject.showProfit=false
                // }else{
                //     this.jsonObject.showProfit=true
                // }
            }
        },
        data() {
            return {
                formData:this.initData,
                jsonObject:JSON.parse(this.initData.jsonStr),
                formLabelWidth: "110px",
                rules: {
                    name: [{
                        required: true,
                        message: '不能为空',
                        trigger: 'blur'
                    },],
                },
               isNotIndependently:this.isGuangyao,
            }
        },
        
        methods:{
            fileChange(val){
                console.log(val)
                if(val==0){
                if(this.$refs['fileImportInput'].files[0]){
                    let fileName = this.$refs['fileImportInput'].files[0].name
                    let index = fileName.lastIndexOf(".")
                    if (index < 0 || !(fileName.substring(index + 1, fileName.length) == "png" || fileName
                        .substring(index + 1, fileName.length) == "jpg"|| fileName
                        .substring(index + 1, fileName.length) == "jpeg"|| fileName
                        .substring(index + 1, fileName.length) == "svg")) {
                        this.$alert('文件格式错误', '提示', {
                            confirmButtonText: '确定',
                        });
                        return
                    }
                    let param = new FormData()
                    param.append("file", this.$refs['fileImportInput'].files[0])
                    uploadPayOfflineUrl(param).then(res=>{
                        this.jsonObject.gzhImageUrl = res.url
                        this.formData.jsonStr = JSON.stringify(this.jsonObject)
                    })
                }
                }else if(val==1){
                if(this.$refs['fileImportInput1'].files[0]){
                    let fileName = this.$refs['fileImportInput1'].files[0].name
                    let index = fileName.lastIndexOf(".")
                    if (index < 0 || !(fileName.substring(index + 1, fileName.length) == "png" || fileName
                        .substring(index + 1, fileName.length) == "jpg"|| fileName
                        .substring(index + 1, fileName.length) == "jpeg"|| fileName
                        .substring(index + 1, fileName.length) == "svg")) {
                        this.$alert('文件格式错误', '提示', {
                            confirmButtonText: '确定',
                        });
                        return
                    }
                    let param = new FormData()
                    param.append("file", this.$refs['fileImportInput1'].files[0])
                    uploadPayOfflineUrl(param).then(res=>{
                        this.jsonObject.defineImageUrl = res.url
                        console.log( this.jsonObject)
                        this.formData.jsonStr = JSON.stringify(this.jsonObject)
                    })
                }
                }
                
            },
        },
        computed: {
    ...mapGetters(["isCaptal",'isGuangyao']),
  },
    }
</script>

<style scoped>

</style>