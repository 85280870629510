<template>
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
         <el-col :span="18" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              ref="queryParams"
              class="demo-form-inline"
              :rules="rulesOne"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
              <el-form-item label="" :label-width="formLabelWidth" prop="gyAgentId">
                <treeselect
                  v-model="queryParams.gyAgentId"
                  :options="agentOptions"
                  :normalizer="normalizer"
                  :show-count="true"
                  placeholder="请选择代理商"
                />
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth" prop="name">
                <el-input
                  placeholder="名称"
                  v-model="queryParams.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth" prop="imei">
                <el-input
                  placeholder="IMEI"
                  v-model="queryParams.imei"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button  
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
        <el-col :span="4" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="add"
                  >新增</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column prop="name" label="名称" min-width="100">
        </el-table-column>
        <el-table-column prop="gyAgent.agentName" label="代理商名称" min-width="100">
        </el-table-column>
        <el-table-column prop="qrcodeMode" label="二维码模式" min-width="180">
        </el-table-column>
        <el-table-column prop="qrcodeTextType" label="文本类型" min-width="100">
        </el-table-column>
        <el-table-column prop="qrcodeUrl" label="qrcodeUrl" min-width="200">
        </el-table-column>
        <el-table-column prop="state" label="状态" min-width="180">
          <template slot-scope="scope">
            <el-switch
            v-model="scope.row.state"
            active-value="1" inactive-value="0"
            @change="chg(scope.row)"
            :disabled="scope.row.state==1"
            >
           </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="操作时间" min-width="180">
        </el-table-column>
        <el-table-column prop="createBy" label="操作人" min-width="100">
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="updata(scope.row)"
              >修改</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="getManagementDetails(scope.row)"
              >管理详情</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

      <el-dialog :title="title" :visible.sync="dialogFormVisible" width="40%">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="20%">
          <el-form-item label="代理商选择" prop="gyAgentId" v-show="currentRole==0">
           <treeselect style="width:70%"
                v-model="addForm.gyAgentId"
                :options="agentOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="请选择代理商"
                @select="treeSelectChange"
                :disabled="disabled"
              />
          </el-form-item>
          <el-form-item label="名称" prop="name" >
          <el-input v-model="addForm.name" style="width:70%"></el-input>
         </el-form-item>
         <el-form-item label="二维码模式"  prop="qrcodeMode">
        <el-select v-model="addForm.qrcodeMode" placeholder="请选择二维码模式" style="width:70%">
        <el-option
              v-for="item in qrOptions"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
        </el-select>
        </el-form-item>
          <el-form-item label="qrcodeUrl" prop="qrcodeUrl" >
            <el-input v-model="addForm.qrcodeUrl" style="width:70%"></el-input>
         </el-form-item>
         <el-form-item label="文本类型" prop="qrcodeTextType">
        <el-select v-model="addForm.qrcodeTextType" placeholder="请选择活文本类型" style="width:70%">
         <el-option
              v-for="item in textOptions"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
        </el-select>
        </el-form-item>
            
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false" type="info" class="themed-button" >取 消</el-button>
    <el-button type="primary" @click="sureAdd" class="themed-button" >确 定</el-button>
  </div>
</el-dialog>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { agentGetAllAgent } from "@/api/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {mapGetters} from 'vuex'
import {
  qrcodePageList,
  qrcodeAdd,
  qrcodeSave,
  qrcodeEnable,
  qrcodeRemove
} from "@/api/qrcode/qrcode.js";
export default {
  name: "materialsList",
  mixins:[resize],
  components: { Treeselect },
  //mixins: [resize],
  data() {
   var validateFee = (rule, value, callback) => {
          if(!this.addForm.qrcodeUrl){
            callback(new Error('请输入qrcodeUrl'));
          }
          let r =/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g
          if(!!r.test(this.addForm.qrcodeUrl)){
              callback(new Error('不能输入中文'));
          }
          callback()
      }
    return {
     qrOptions:[
        {
          label: "固定连接",
          value: 1,
        },
        {
          label: "固定连接+实体ICCID",
          value: 2,
        },
        {
          label: "固定连接+虚拟ICCID/短号",
          value: 3,
        },
         {
          label: "固定连接+IMEI",
          value: 4,
        },
      ],
      textOptions:[
        {
          label: "微信扫⼀扫",
          value: 1,
        },
        {
          label: "查询",
          value: 2,
        },
        {
          label: "充值",
          value: 3,
        },
        {
          label: "客服",
          value: 4,
        },
        {
          label: "服务",
          value: 5,
        }
      ],
      formInline: {
        spec: "",
        material: "",
      },
      addForm: {
       qrcodeMode:"",
       qrcodeTextType:"",
       qrcodeUrl:"",
       name:""
      },
      agentOptions: [],
      tableData: [],
      materialList: [],
      specList: [],
      dialogFormVisible: false,
      queryParams: {
        imei: "",
        name:'',
        gyAgentId: undefined,
        gyAgentName: undefined,
      },
      rules: {
        qrcodeMode:[
          { required: true, message: '请选择二维码模式', trigger: 'change' }
          ],
          //  gyAgentId:[
          // { required: true, message: '请选择代理商', trigger: 'change' }
          // ],
           qrcodeTextType:[
            { required: true, message: '请选择活文本类型', trigger: 'change' }
          ],
         qrcodeUrl: [{ validator: validateFee,required: true, trigger: "blur" },
         { min: 8, max: 128, message: '长度在 8 到 128 个字符', trigger: 'blur' }],
          name: [{ required: true, message: "请输入名称", trigger: "blur" },
        { pattern:/^[\u4e00-\u9fa5_a-zA-Z0-9_.-]{1,32}$/ , message: "名称只能为1到32位,包含中文数字字母下划线点连接符", trigger: "blur" }
        ],
        },
         rulesOne: {
         imei:[{message: "请输入IMEI", trigger: "blur" },
         { pattern:/^[\d]/g , message: "只能为数字", trigger: "blur" },
         { min: 15, max: 17, message: '长度在 15 到 17个字符', trigger: 'blur' }
        ],
        },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
      title: "",
      disabled:false
    };
  },
   computed:{
         ...mapGetters(
             ['currentRole','agentTree']
         ),
     },
  created() {
    this.getDeviceList();
    this.getAllAgent();
  },
  methods: {
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams, params);
      qrcodePageList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.tableData.forEach((ele=>{
        this.textOptions.forEach((textele=>{
        if(textele.value==ele.qrcodeTextType){
         ele.qrcodeTextType =textele.label
          }
        }))
        this.qrOptions.forEach((qrele=>{
        if(qrele.value==ele.qrcodeMode){
        ele.qrcodeMode =qrele.label
          }
        }))
        }))
        this.total=res.total
        this.loading = false;
      });
    },
     /** 代理商数据 */
    getAllAgent() {
      agentGetAllAgent().then((response) => {
        this.agentOptions = response;
      });
    },
    /** 转换代理商数据结构 */
    normalizer(node) {
      if (
        node.children == null ||
        (node.children == "null" && node.children && !node.children.length)
      ) {
        delete node.children;
      }
      return {
        id: node.agentId,
        label: node.agentName,
        children: node.children,
      };
    },
     treeSelectChange(raw, instanceId) {
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        this.addForm.gyAgentId = raw.agentId;
        this.addForm.gyAgentName = raw.agentName;
      });
    },
    /** 搜索 */
    searchData() {
    this.$refs["queryParams"].validate((valid) => {
      if (valid) {
      this.page = 1;
      this.getDeviceList();
        }})
    },
    /** 筛选 */
    screenDate() {
      this.page = 1;
      this.getDeviceList();
    },
    /** 导出模板 */
    exportTemplateExcel(name) {
      deviceExport({ name: name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
     /** 重置表单 */
    reset() {
       this.addForm = {
       qrcodeMode:undefined,
       qrcodeTextType:undefined,
       qrcodeUrl:undefined,
       name:undefined
      };
      //this.resetForm("addForm");
    },
      /** 新增按钮弹出dialog */
    add() {
      this.reset()
      this.dialogFormVisible = true;
      this.title = "新增";
       this.disabled = false;
    },
    /** 修改按钮弹出dialog */
    updata(row) {
      this.addForm= JSON.parse(JSON.stringify(row))
      console.log(this.addForm,23)
       this.qrOptions.forEach((item) => {
        console.log(item)
        if (this.addForm.qrcodeMode == item.label) {
          this.$set(this.addForm, "qrcodeMode", item.value)
        }
      })
      this.textOptions.forEach((ele) => {
        if (this.addForm.qrcodeTextType == ele.label) {
          this.$set(this.addForm, "qrcodeTextType", ele.value)
        }
      })
      // this.textOptions.forEach((ele) => {
      //   console.log(item)
      //   if (this.addForm.qrcodeMode == item.label) {
      //     this.$set(this.addForm, "qrcodeMode", item.value)
      //   }
      // })
      // this.$set(this.addForm, "qrcodeTextType", this.qrOptions[0].value);
      // this.$set(this.addForm, "qrcodeMode", this.textOptions[0].value);
      this.dialogFormVisible = true;
      this.title = "修改";
      this.disabled=true
    },
    /** 提交 */
    sureAdd() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          if (this.currentRole==0) {
           if (!!this.addForm.gyAgentId) {
           if (this.title != "新增") {
           qrcodeSave(this.addForm).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          } else {
            qrcodeAdd(this.addForm).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          } 
            } else {
              this.$alert("请选择代理商", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
          }else{
           if (this.title != "新增") {
           qrcodeSave(this.addForm).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          } else {
            qrcodeAdd(this.addForm).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          }
          }
        // if (this.title != "新增") {
        //    qrcodeSave(this.addForm).then((response) => {
        //       this.$modal.msgSuccess("修改成功");
        //       this.dialogFormVisible = false;
        //       this.getDeviceList();
        //     });
        //   } else {
        //     qrcodeAdd(this.addForm).then((response) => {
        //       this.$modal.msgSuccess("新增成功");
        //       this.dialogFormVisible = false;
        //       this.getDeviceList();
        //     });
        //   }
        }
      });
    },
    /** 删除 */
    delect(id) {
      this.$confirm("是否确认删除该项分配数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let queryFox = new FormData();
        queryFox.append("id", id);
        qrcodeRemove(queryFox).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getDeviceList();
        });
      });
    },
     /** 关联跳转 */
    getManagementDetails(row){
       this.$router.push({
        path: "/equipmentManagement/managementDetails",
        query: { data: JSON.stringify(row) },
      });

    },
     chg(r){
      let text = r.active == "0" ? "未禁用" : "启用";
      this.$modal.confirm('是否' + text).then(function() {
      return qrcodeEnable({id:r.id,state:r.state});
      }).then(() => {
        this.$modal.msgSuccess(text + "成功");
        this.getDeviceList();
      }).catch(function() {
        r.state = r.state == "0" ? "1" : "0";
      });
    },
    /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
     handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 100px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>