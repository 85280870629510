<template>
    <div class="speed-service-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">加速服务列表</span>
            <el-form inline ref="speedServiceForm" v-model="speedServiceFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="speedServiceFormData.iccid" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="speedServiceFormData.qq" placeholder="qq" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item label="运营商">
                    <el-select v-model="speedServiceFormData.accountId" filterable placeholder="运营商">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="speedServiceFormData.status" filterable placeholder="状态">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待订购" value="1"></el-option>
                        <el-option label="已订购" value="2"></el-option>
                        <el-option label="已退订" value="3"></el-option>
                        <el-option label="订购失败" value="4"></el-option>
                        <el-option label="退订失败" value="5"></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="iccid"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="qq"
                    label="qq"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="模式"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.requestModel == 0">直充模式</span>
                        <span v-else-if="scope.row.requestModel == 1">订阅直充模式</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="setmealDay"
                    label="加速服务天数"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="setmealCount"
                    label="加速服务份数"
                    min-width="120"
                    >
                </el-table-column>
                
                <el-table-column
                    show-overflow-tooltip
                    label="订购开始时间"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.subscribeTime">{{formatTime(scope.row.subscribeTime)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="订购结束时间"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.unsubscribeTime">{{formatTime(scope.row.unsubscribeTime)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="状态"
                    min-width="100"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == '1'">待订购</span>
                        <span v-else-if="scope.row.status == '2'">已订购</span>
                        <span v-else-if="scope.row.status == '3'">已退订</span>
                        <span v-else-if="scope.row.status == '4'">订购失败</span>
                        <span v-else-if="scope.row.status == '5'">退订失败</span>
                    </template>  
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="创建时间"
                    min-width="150"
                    > 
                    <template slot-scope="scope">
                        <span v-if="scope.row.createTime">{{formatTime(scope.row.createTime)}}</span>
                    </template> 
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    label="执行时间"
                    min-width="150"
                    >
                     <template slot-scope="scope">
                        <span v-if="scope.row.updateTime">{{formatTime(scope.row.updateTime)}}</span>
                    </template> 
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="orderId"
                    label="订单id"
                    min-width="120"
                    >  
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="180">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status =='1'||scope.row.status =='3'||scope.row.status =='4'" size="mini" class="themed-button" type="info" @click="handleEdit(scope.row.id)">编辑</el-button>
                        <el-button v-if="scope.row.status =='1'||scope.row.status =='4'" size="mini" class="themed-button" type="primary" @click="handleOrder(scope.row.id)">订购</el-button>
                        <el-button v-if="scope.row.status =='2'||scope.row.status =='5'" size="mini" class="themed-button" type="primary" @click="handleUnsubscribe(scope.row.id)">退订</el-button>
                    </template>
                </el-table-column>
                
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="编辑" :visible.sync="dialogEditFormVisible" width="500" :destroy-on-close='true'>
            <el-form :model="editForm" :rules="rules" ref="editForm">
                <el-form-item label="ICCID" :label-width="formLabelWidth" prop="iccid">
                <el-input v-model="editForm.iccid" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="运营商" :label-width="formLabelWidth" prop="accountName">
                <el-input v-model="editForm.accountName" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="QQ账号" :label-width="formLabelWidth" prop="qq">
                <el-input v-model="editForm.qq" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="状态" :label-width="formLabelWidth" prop="status">
                <el-select v-model="editForm.status" filterable placeholder="状态">
                        <el-option v-if="editStatus =='1'|| editStatus =='3'|| editStatus =='4'" label="待订购" value="1"></el-option>
                        <el-option v-if="editStatus =='2'" label="已订购" value="2"></el-option>
                        <el-option v-if="editStatus =='3'" label="已退订" value="3"></el-option>
                        <el-option v-if="editStatus =='4'" label="订购失败" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模式" :label-width="formLabelWidth" prop="requestModel">
                    <el-select v-model="editForm.requestModel" placeholder="状态" disabled>
                        <el-option  label="直充模式" value="0"></el-option>
                        <el-option  label="订阅直充模式" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="加速服务天数" :label-width="formLabelWidth" prop="setmealDay">
                <el-input v-model="editForm.setmealDay" autocomplete="off" disabled></el-input>
                </el-form-item>
				<el-form-item label="加速服务份数" :label-width="formLabelWidth" prop="setmealCount">
                <el-input v-model="editForm.setmealCount" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="订购开始时间" :label-width="formLabelWidth" prop="subscribeTime">
                <el-date-picker style="width: 200px;" v-model="editForm.subscribeTime" type="date" value-format="yyyy-MM-dd" :disabled="isReadonly"/>
                </el-form-item>
				<el-form-item label="订购结束时间" :label-width="formLabelWidth" prop="unsubscribeTime">
                <el-date-picker style="width: 200px;" v-model="editForm.unsubscribeTime" type="date" value-format="yyyy-MM-dd" disabled/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureRecord('editForm')">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogEditFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {agentOperatorList} from '@/api/order.js'
import { speedServiceList,exportSpeedService ,subscribeSpeed,unsubscribeSpeed,editSpeedService,findByIccid} from "@/api/speedService.js";
export default {
    name:'speedServiceList',
     mixins:[resize],
    data(){
        return {
            speedServiceFormData:{
                iccid:'',
                qq:'',
                accountId:'',
                status:'',
            },
            accountIdList:[],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            editForm:{
                id:'',
                iccid:'',
                accountName:'',
                qq:'',
                status:'',
                setmealCount:'',
                subscribeTime:'',
                unsubscribeTime:'',
                requestModel:'',
                setmealDay:'',

            },
            isReadonly:false,
            editStatus:'',
            dialogEditFormVisible:false,
            formLabelWidth:'150px',
            rules:{
                accountName:[
                    {required: true, message: '请选择运营商', trigger: 'blur'}
                ],
                 qq:[
                    {required: true, message: '请填写qq号', trigger: 'blur'},
                    {min:5,max: 11, message: '长度必须5到11内', trigger: 'blur' }
                ],
                status:[
                    {required:true, message:'请选择状态', trigger: 'blur'}
                ],
                setmealCount:[
                     {required:true, message:'请填写加速服务个数', trigger: 'blur'}
                ],
                 subscribeTime:[
                     {required:true, message:'请填写开始时间', trigger: 'blur'}
                ],
                unsubscribeTime:[
                     {required:true, message:'请填写结束时间', trigger: 'blur'}
                ],
            },
        }
    },
    created(){
        this.getAgentOperatorList()
        this.getSpeedServiceList()
    },
    methods: {
        getAgentOperatorList(){
            agentOperatorList().then(res=>{
                this.accountIdList = res        
             })
        },
        getSpeedServiceList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.speedServiceFormData,params)
            speedServiceList(this.speedServiceFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getSpeedServiceList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSpeedServiceList()
        },

        onSearch(){
            this.page=1
            this.getSpeedServiceList()
        },

        exportExcel(){
            exportSpeedService(this.speedServiceFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },

        handleEdit(id){
            this.dialogEditFormVisible = true
            findByIccid({id}).then(res=>{
                this.editForm.iccid = res.iccid
                this.editForm.accountName = res.accountName
                this.editForm.qq = res.qq
                this.editForm.status = res.status.toString()
                this.editForm.setmealCount = res.setmealCount
                this.editForm.requestModel = res.requestModel.toString()
                this.editForm.setmealDay = res.setmealDay
                this.editForm.subscribeTime = this.formatTime(res.subscribeTime)
                this.editForm.unsubscribeTime =this.formatTime(res.unsubscribeTime) 
                this.editStatus = res.status
                this.editForm.id= id
                if(res.requestModel == 0){
                    this.isReadonly = true
                }else{
                    this.isReadonly = false
                }
            })
        },

        sureRecord(formName){
            this.$confirm('确定提交修改吗？','消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    editSpeedService(this.editForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '修改成功',
					        type: 'success',
							center: true
                        })
                        this.$refs[formName].resetFields()
                        this.dialogEditFormVisible=false
                        this.getSpeedServiceList()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            }).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
			});
        },
        handleOrder(id){
            this.$confirm('确定订购加速服务吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                subscribeSpeed({id}).then(res=>{
                    this.$message({
                    type: 'success',
                    message: '订购成功!'
                    });
                    this.getSpeedServiceList()
                })
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消'
                });          
            });
        },

        handleUnsubscribe(id){
            this.$confirm('确定退订加速服务吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                unsubscribeSpeed({id}).then(res=>{
                    this.$message({
                    type: 'success',
                    message: '退订成功!'
                    });
                    this.getSpeedServiceList()
                })
                
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消'
                });          
            });
        },
        formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
         },
    },
}
</script>

<style>
    .speed-service-list .el-form-item__label{
        vertical-align: top;
    }
</style>