<template>

<div class="table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">公告</span>
        <el-form inline v-model="form"  class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item>
                <!-- <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button> -->
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addList">新增</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="标题" prop="title" show-overflow-tooltip min-width="120px"></el-table-column>
            <el-table-column label="发送对象" prop="audience" show-overflow-tooltip min-width="100px">
                <template slot-scope="scope">
                    <span>{{_getAudienceObjct(scope.row.audience)}}</span>
                    <!-- <span v-if="scope.row.audience==1">全部</span>
                    <span v-else-if="scope.row.audience==2">TOB</span>
                    <span v-else-if="scope.row.audience==3">TOC</span> -->
                </template>
            </el-table-column>
            <el-table-column label="发送状态" prop="sendStatus" show-overflow-tooltip min-width="100px">
                <template slot-scope="scope">
                    <span v-if="scope.row.sendStatus==1">已发送</span>
                    <span v-else-if="scope.row.sendStatus==2">待发送</span>
                </template>
            </el-table-column>
            <el-table-column label="发送时间" prop="sendTime" show-overflow-tooltip min-width="150px"></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip min-width="150px"></el-table-column>
            <el-table-column label="创建人" prop="createUser" show-overflow-tooltip min-width="120px"></el-table-column>
            <el-table-column label="操作" width="160">
                <template  slot-scope="scope">
                    <el-button type="info" class="themed-button" v-if="scope.row.sendStatus==1" @click="handleDetail(scope.row)">详情</el-button>
                    <el-button type="primary" class="themed-button" v-if="scope.row.sendStatus==2" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="danger" class="themed-button" v-if="scope.row.sendStatus==2" @click="handleDelete(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogEdit" v-if="dialogEdit" width="500px"> 
        <el-form v-model="formEdit" label-position="right" label-width="100px">
            <el-form-item label="发送对象:" required>
                <el-select v-model="formEdit.audience" :disabled="isDisabled">
                    <!-- <el-option  label="全部" value="1" ></el-option>
                    <el-option  label="TOB" value="2" ></el-option>
                    <el-option  label="TOC" value="3" ></el-option> -->
                    <el-option v-for="(item,index) in w_audience_object" :key="index" :label="item.groupName"  :value="item.dictValue"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发送时间:" required>
                 <el-date-picker v-model="formEdit.sendTime" type="datetime" placeholder="选择日期时间" :disabled="isDisabled" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="标题:" required >
                <el-input type="text" show-word-limit maxlength="20" v-model.trim="formEdit.title" style="width:300px" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="内容:" required>
                <el-input type="textarea" autosize show-word-limit maxlength="200" v-model="formEdit.content" style="width:300px" :disabled="isDisabled"></el-input>
            </el-form-item>
        </el-form>  
        <div class="align-right">
            <el-button v-if="dialogTitle=='编辑'" type="primary" class="themed-button" @click="sureEdit">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogEdit=false">取消</el-button>
        </div>
    </el-dialog>
    <el-dialog title="新增" :visible.sync="dialogAdd" v-if="dialogAdd" width="500px"> 
        <el-form v-model="formAdd" label-position="right" label-width="100px"  ref="formAdd">
            <el-form-item label="发送对象:" required>
                <el-select v-model="formAdd.audience">
                    <!-- <el-option  label="全部" value="1" ></el-option>
                    <el-option  label="TOB" value="2" ></el-option>
                    <el-option  label="TOC" value="3" ></el-option> -->
                    <el-option v-for="item in w_audience_object" :label="item.groupName"  :value="item.dictValue" :key="item.dictKey"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发送方式:" required>
                <el-radio  v-model="sendStyle" label="1" @change="changeSendStyle">立即发送</el-radio>
                <el-radio  v-model="sendStyle" label="2" @change="changeSendStyle">定时发送</el-radio>
            </el-form-item>
            <el-form-item label="发送时间:" required v-if="sendStyle==2">
                 <el-date-picker v-model="formAdd.sendTime" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickeroptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="标题:" required>
                <el-input  type="text" show-word-limit maxlength="20" v-model.trim="formAdd.title" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="内容:" required>
                <el-input type="textarea"  show-word-limit maxlength="200" v-model="formAdd.content" style="width:300px"></el-input>
            </el-form-item>
        </el-form>  
        <div class="align-right">
            <el-button type="primary" class="themed-button" @click="addSure">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogAdd=false">取消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {adminAnnounceList,adminAnnounceAdd,adminAnnounceDel,adminAnnounceEdit} from '@/api/systemManagement.js'
import {messageBox,confirmBox} from '@/utils/common.js'

import resize from '@/mixins/resize.js'
import dictGroup from "@/mixins/dictGroup.js"

export default {
    name:'Announcement',
    mixins:[resize,dictGroup],
    data(){
        return {
            inventoryDate:'',
            inventoryDateTime:'',
            form:{},
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogEdit:false,
            formEdit:{
                id:'',
                audience:'',
                sendTime:'',
                title:'',
                content:'',
            },
            dialogAdd:false,
            sendStyle:'',
            formAdd:{
                audience:'',
                sendTime:'',
                title:'',
                content:'',
            },
            dialogTitle:'',
            isDisabled:false,
            pickeroptions: {
                disabledDate : time => {
                    let nowTime =  new Date().getTime()
                    if(time.getTime()< (nowTime-24*60*60*1000)){
                        return true
                    }else{
                        return false
                    }
                },
		    }
        }
    },
    mounted(){
        this.getAnnounceList()
        console.log(this.w_audience_object)
    },
    methods:{
        addList(){
            this.resetAddForm()
            this.dialogAdd = true  
        },
        addSure(){
            if(this.sendStyle=='1'){
                this.formAdd.sendTime=""
            }
 
            if(this.formAdd.audience==''||this.formAdd.title==''||this.formAdd.content==''){
                messageBox(this,'请填写相关信息')
                return
            }
            if(this.sendStyle=='2'&&this.formAdd.sendTime==""){
                messageBox(this,'请选择发送时间')
                return
            }

            adminAnnounceAdd(this.formAdd).then((res)=>{
                this.dialogAdd = false
                this.getAnnounceList()
                messageBox(this,'添加成功')
            })
        },
        changeSendStyle(val){
            this.sendStyle==val
        },
        handleDelete(id){
            confirmBox(this,'是否确定删除?',null,()=>{
                adminAnnounceDel({id}).then((res)=>{
                    this.getAnnounceList()
                    messageBox(this,'删除成功')
                })
            })
        },
        handleEdit(data){
            this.isDisabled = false
            this.dialogTitle = '编辑'
            this.dialogEdit = true
            this.formEdit.id = data.id
            this.formEdit.audience = data.audience+''
            this.formEdit.sendTime = data.sendTime
            this.formEdit.title = data.title
            this.formEdit.content = data.content
        },
        sureEdit(){
            if(this.formEdit.audience==''||this.formEdit.title==''||this.formEdit.content==''||this.formEdit.sendTime==''){
                messageBox(this,'请填写相关信息')
                return
            }
            adminAnnounceEdit(this.formEdit).then((res)=>{
                this.dialogEdit = false
                this.getAnnounceList()
                messageBox(this,'编辑成功')
            })
        },
        handleDetail(data){
            this.isDisabled = true
            this.dialogTitle = '详情'
            this.dialogEdit = true
            this.formEdit.sendTime = data.sendTime
            this.formEdit.audience = data.audience+''
            this.formEdit.title = data.title
            this.formEdit.content = data.content 
        },
        handleSizeChange(val){
            this.pageSize = val
            this.getAnnounceList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.getAnnounceList()
        },
        getAnnounceList(){
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            params.type = this.type
            adminAnnounceList(Object.assign(params,this.form)).then((res) => {
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        },
        resetAddForm(){
            this.formAdd ={
                audience:'',
                sendTime:'',
                title:'',
                content:'',
            }
            this.sendStyle='1'
        }
    }
}
</script>
