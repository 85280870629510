<template>
    <div class="agent-apply table-hook fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">代理商提现申请</span>
            <el-form inline ref="agentApplyForm" v-model="agentApplyData" class="hook right themed-form search-form">
                <el-form-item label="代理商名称：">
				    <el-input v-model="agentApplyData.agentName" placeholder="代理商名称"></el-input>
				  </el-form-item>
                <el-form-item label="结算状态:">
                    <el-select v-model="agentApplyData.status">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待结算" value="1"></el-option>
                        <el-option label="未审核" value="2"></el-option>
                        <el-option label="审核通过" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择时间" v-model="agentApplyData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker style="width: 100%;" placeholder="请选择时间" v-model="agentApplyData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>
                <el-form-item v-if="currentRole!=0"> 
					<el-button icon="el-icon-plus" type="primary" class="themed-button" @click="onWithdraw(1)" >提现</el-button>
				</el-form-item>
                <el-form-item v-if="isShow&&currentRole!=0">
					<el-button icon="el-icon-refresh" type="primary" class="themed-button" @click="onWithdraw(3)">转预存</el-button>
				</el-form-item> 
            </el-form>
        </div>
        <div class="content-box">
            <el-table
                ref='table'
                border
                stripe
                :data="tableData"
                tooltip-effect="dark"
                :max-height="maxTableHeight">

            <el-table-column label="提现编号" prop="rebateNo" width="180px" show-overflow-tooltip></el-table-column>
            <el-table-column label="代理商名称" prop="agentName" show-overflow-tooltip></el-table-column>
            <el-table-column label="开始时间" prop="startTime"></el-table-column>
            <el-table-column label="结束时间" prop="endTime"></el-table-column>
            <el-table-column label="订单总数" prop="orderNum"></el-table-column>
            <el-table-column label="订单总额" prop="orderAmount" show-overflow-tooltip></el-table-column>
            <el-table-column label="提现金额" prop="applyMoney" show-overflow-tooltip></el-table-column>
             <el-table-column label="打款金额" prop="payMoney" show-overflow-tooltip></el-table-column>
              <!-- <el-table-column label="打款状态" prop="payStatus" show-overflow-tooltip >
                <template slot-scope="scope">
                    <span v-if="scope.row.payStatus == 1">未付款</span>
                    <span v-else-if="scope.row.payStatus == 2">已付款</span>
                    <span v-else-if="scope.row.payStatus == 3">异常</span>
                     <span v-else-if="scope.row.payStatus == 4">付款中</span>
                       <span v-else-if="scope.row.payStatus == 5">部分付款</span>
                       <span v-else>-</span>
                </template>
              </el-table-column> -->
            <el-table-column label="返佣类型" prop="rebateType" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.rebateType == 1">普通</span>
                    <span v-if="scope.row.rebateType == 2">秒返</span>
                </template>
            </el-table-column>
            <el-table-column label="支付类型" prop="payType" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.payType == 1">微信</span>
                    <span v-if="scope.row.payType == 2">线下</span>
                    <span v-if="scope.row.payType == 3">准备金</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 1">待结算</span>
                    <span v-if="scope.row.status == 2">未审核</span>
                    <span v-if="scope.row.status == 3">审核完成</span>
                    <span v-if="scope.row.status == 4">失败</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button size="mini" class="themed-button" type="primary" @click="orderDetails(scope.row.id)">订单详情</el-button>
                </template>
            </el-table-column>
            </el-table>
            <el-pagination 
            :current-page.sync="pageNo"  
            :page-sizes="[5,10,15,50,100,1000]" 
            :page-size="pageSize"
			 background 
             layout="total,sizes,prev, pager, next,jumper" 
             :total="total" 
             @size-change="handleSizeChange" 
             @current-change="onPageChange"></el-pagination>
        </div>
        <el-dialog :title="dialogBoxTitle" :visible.sync="dialogBox" v-if="dialogBox">
            <widthdraw :type="type"  :startTime="lastDate" :endTime="canEndDate" :name="name" @close="dialogBox = false"></widthdraw>
        </el-dialog>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import Widthdraw from '@/views/orderManagement/agentApply/widthdraw.vue'

import {Permission,GetAgentApply,agentRebateCheck} from '@/api/agentApplyCheck.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'
import {mapGetters} from 'vuex'
export default {
    name:"agentApply",
    mixins:[resize],
    data(){
        return{
            agentApplyData:{
                status:'',
                startTime:'',
                endTime:'',
                agentName:""
            },
            isShow:true,
            isLevel2AndOrd:'',
            type:'',
            lastDate:'',
            canEndDate:'',
            name:'',
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            dialogBoxTitle:'',
        }
    },
    mounted(){
        this._Permission()
        this._GetAgentApply()
    },
    methods:{
        onWithdraw(type){
            this.type = type
            this.dialogBoxTitle = (type == 1 ? '提现' : '预转存')
            agentRebateCheck({type}).then((res)=>{
                if(this.sLevel2AndOrd && this.type == 1){
                    this.type = 2
                }
                if(res.isAllowed){
                    this.dialogBox = true
                    this.lastDate = res.last_apply_date
                    this.canEndDate = res.end_can_apply_date
                    this.name = res.name
                }
            })
        },
        orderDetails(applyId){
            if(applyId){
                this.$router.push({
                    path: "/orderManagement/agentApplyCheckDetail",
                    query: {applyId,title:true},
                })
            }
        },
        onSearch(){
            this._GetAgentApply()
        },
        onPageChange(page){
			this.pageNo = page
			this._GetAgentApply()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._GetAgentApply()
        },
        _Permission(){
            Permission(this.agentApplyData).then((res)=>{
                this.isLevel2AndOrd = res.isLevel2AndOrd
                if(res.isLevel2AndOrd){
                    this.isShow = false
                }
            })
        },
        _GetAgentApply(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                type:1
            }
            GetAgentApply(Object.assign(params,this.agentApplyData)).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.tableData = res.rows
                this.total = res.total

            })
        }
    },
    components:{
        SearchSelect,
        Widthdraw
    },
     computed:{
        	...mapGetters(
            	['currentRole']
        	),
    	},
}
</script>
<style>
    .agent-apply{position:relative;}
    .agent-apply .el-table .cell{height:23px;}
</style>