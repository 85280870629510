<template>
	<div class="fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">计费组管理</span>
			<el-form inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
				<el-form-item>
					<el-input v-model="searchForm.pageSearchKeys"  placeholder="计费组代码或名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button"  @click="onSearch">查询</el-button>
				</el-form-item>
				<!-- <el-form-item>
					<el-button icon="el-icon-refresh-left" class="themed-button" type="info" @click="reset">重置</el-button>
				</el-form-item> -->
				<el-form-item>
					<el-button icon="el-icon-circle-plus" class="themed-button" type="info" @click="add()">新增</el-button>
				</el-form-item>
				<el-form-item>
                	<el-button type="primary" icon="el-icon-plus" class="themed-button" @click="batchStopCard()">批量设置断网</el-button>
           		</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table ref='table'  :data="tableData" stripe style="width: 100%;" :max-height="maxTableHeight">
				<el-table-column label="计费组代码" width="170">
					<template slot-scope="scope">
						<a href='javascript:;' @click="getCardDetail(scope.row.id)" style="color: #337ab7;">{{scope.row.groupCode}}</a>
					</template>
				</el-table-column>
				<el-table-column label="计费组名称" show-overflow-tooltip width="200">
					<template slot-scope="scope">
						<a href='javascript:;' @click="getGroupStatistic(scope.row.id)" style="color: #337ab7;">{{scope.row.groupName}}</a>
					</template>
					
				</el-table-column>
				<el-table-column prop="chargeMode" label="计费模式">
					<template slot-scope="scope">
						<span v-if="scope.row.chargeMode == 1">共享</span>
						<span v-else-if="scope.row.chargeMode == 2">单卡</span>
					</template>
				</el-table-column>
				<el-table-column sortable prop="availableCardNum" label="总卡数">
				</el-table-column>
				<el-table-column sortable prop="activeCardNum" label="已激活卡数">
				</el-table-column>
				<el-table-column sortable prop="totalFlow" label="总流量(MB)">
				</el-table-column>
				<el-table-column sortable prop="useFlow" label="已用流量(MB)">
				</el-table-column>
				<el-table-column sortable prop="useFlowPercent" label="已用流量百分比">
					<template slot-scope="scope">
						<span v-if="scope.row.useFlowPercent != '' && scope.row.useFlowPercent != null ">{{scope.row.useFlowPercent}}%</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column  prop="createTime" label="创建时间" width="100">
					 <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.createTime)}}</span>
                </template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="remark" label="自定义备注">
				</el-table-column>
				<el-table-column prop="" label="操作" width="200">
					<template slot-scope="scope">
						<el-button class="themed-button" type="warning" @click="openWarnDialog(scope.row)">预警</el-button>
						<el-button class="themed-button" type="info" @click="eidtGroup(scope.row)">编辑</el-button>
						<!-- <el-button class="themed-button" type="primary" @click="exportGroup(scope.row.id)">导出</el-button> -->
						<el-button v-if="loginType==0" class="themed-button" type="danger" @click="deleteGroup(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
		</div>
		<el-dialog title="新增" :visible.sync="addDialogFormVisible" :destroy-on-close='true' width="600px">
            <el-form :model="addForm" :rules="rules"  ref="addForm">
                <el-form-item label="代理商" :label-width="formLabelWidth" prop="agentId">
					<el-select v-model="addForm.agentId"   allow-create  placeholder="请选择" filterable>
						<el-option v-for="item in agentIdList" :key='item.index' :label="item.name" :value="item.id"></el-option>
					</el-select>
                </el-form-item>

                <el-form-item label="运营商" :label-width="formLabelWidth" prop="accountId">
					<el-select v-model="addForm.accountId"   allow-create @change="accountIdChanged" placeholder="请选择" filterable>
						<el-option v-for="item in accountIdList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
					</el-select>
                </el-form-item>

                <el-form-item label="计费模式" :label-width="formLabelWidth" prop="chargeMode">
                    <el-select v-model="addForm.chargeMode" placeholder="请选择" @change="accountIdChanged">
						<el-option label="共享" value="1"></el-option>
						<el-option label="单卡" value="2"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="基础套餐" :label-width="formLabelWidth" prop="baseMealId">
                    <el-select v-model="addForm.baseMealId"   allow-create  placeholder="请选择" @change="autoGenerate">
						<el-option v-for="item in mealList" :key='item.index' :label="item.name" :value="item.id"></el-option>
					</el-select>
                </el-form-item>
				<el-form-item label="计费组代码" :label-width="formLabelWidth" prop="groupCode">
                    <el-input v-model="addForm.groupCode" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="计费组名称" :label-width="formLabelWidth" prop="groupName">
                    <el-input v-model="addForm.groupName" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="addForm.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureAdd('addForm')">确 定</el-button>
                <el-button class="themed-button" type="info" @click="reset('addForm')">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="addDialogFormVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
		<el-dialog title="修改计费组" :visible.sync="dialogVisible" width="500px">
			<el-form label-width="100px">
				<el-form-item label="计费组名称">
					<el-input v-model="ruleForm.groupName" style="width: 360px;"></el-input>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="ruleForm.remark" style="width: 360px;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="dialogVisible = false">取 消</el-button>
				<el-button class="themed-button" type="primary" @click="onChageGroup">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="预警配置" :visible.sync="warnDialogVisible" width="900px">
			<el-form label-width="190px">
				<el-form-item label="单卡流量预警(%)(多个)">
					<tag-input style="display: inline-block;" v-model="warnRuleForm.singleCardValue"></tag-input>
					<span><i class="el-icon-warning-outline" style="color: red;" v-if="warnMsg.length>0"></i><span style="font-size: 13px;color: red;margin-left: 4px;">{{warnMsg}}</span></span>
				</el-form-item>
				<el-form-item label="计费结束时间预警">
					<el-checkbox  style="margin-left: 10px;" label="剩余3个月到期" v-model="warnRuleForm.isChecked3"></el-checkbox>
					<el-checkbox label="剩余2个月到期" v-model="warnRuleForm.isChecked2"></el-checkbox>
					<el-checkbox label="剩余1个月到期" v-model="warnRuleForm.isChecked1"></el-checkbox>
				</el-form-item>
				<el-form-item label="流量池用量预警(%)(多个)">
					<tag-input style="display: inline-block;" v-model="warnRuleForm.poolUseValue"></tag-input>
					<span><i class="el-icon-warning-outline" style="color: red;" v-if="poolUsedWarnMsg.length>0"></i><span style="font-size: 13px;color: red;margin-left: 4px;">{{poolUsedWarnMsg}}</span></span>
				</el-form-item>
				<el-form-item label="预警发送邮箱(多个)">
					<tag-input style="display: inline-block;" v-model="warnRuleForm.mail"></tag-input>
					<span><i class="el-icon-warning-outline" style="color: red;" v-if="mailWarnMsg.length>0"></i><span style="font-size: 13px;color: red;margin-left: 4px;">{{mailWarnMsg}}</span></span>
				</el-form-item>
				<el-form-item label="启用" style="margin-left: 10px;">
					<el-checkbox v-model="warnRuleForm.isCheckdUsed"></el-checkbox>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="warnDialogVisible = false">取 消</el-button>
				<el-button class="themed-button" type="primary" @click="onSetWarn">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog  title="批量断网" :visible.sync="stopCardDialog">
			<el-form label-position="right" label-width="200px" ref="batchStopForm" :model="batchStopForm">
				<el-form-item label="请选择excel文件:" required>
					<input class="file-input" type="file" ref='file'/>
					<div class="mt5">
							<span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
					</div>
				</el-form-item>
				<el-form-item label="计费组:" required>
					<el-select v-model="batchStopForm.groupId" placeholder="请选择计费组" filterable>
						<el-option v-for="(item,index) in groupList" :key="index" :label="item.groupName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="断网阀值" required>
					<el-input v-model="batchStopForm.flowStop" style="width: 360px;"></el-input>
					<p>（只能输入数字，单位：M，-1表示无限制）</p>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" class="themed-button" @click="uploadExcel">提 交</el-button>
				<el-button type="primary" class="themed-button" @click="exportTemplateExcel('importIccid.xls')">导出excel模板</el-button>
				<el-button type="primary" class="themed-button" @click="stopCardDialog = false">取 消</el-button>
			</div>
    	</el-dialog>
	</div>
</template>

<script>
	import TagInput from '@/components/TagInput.vue'
	import {chargeGroupList,chargeGroupUpdate,findWarnSettingByGroupId,updateWarnSettingByGroupId,exportGroup,addGroup,getMealByAccountId,deleteGroup,importBatchStopCardByExcel} from '@/api/group.js'
	import {exportTemplate} from '@/api/statistics.js'
	import {getAgentList} from '@/api/agentManagement.js'
	import {operatorLabelList} from '@/api/operator.js'
	import {loginType} from '@/api/order.js'
	import resize from '@/mixins/resize.js'
	import Utils from '@/utils/utils.js'
	import {messageBox,confirmBox} from '@/utils/common.js'
	export default{
		name:'list',
		mixins:[resize],
		components:{TagInput},
		data(){
			return {
				type:'',
				page:1,
				pageSize:15,
				total:0,
				tableData:[],
				dialogVisible:false,
				warnDialogVisible:false,
				addDialogFormVisible:false,
				formLabelWidth:'150px',
				addForm:{
					agentName:'0',
					agentId:'',
					accountId:'',
					chargeMode:'',
					baseMealId:'',
					groupCode:'',
					groupName:'',
					remark:''
				},
				ruleForm:{
					remark:'',
					groupName:'',
				},
				warnRuleForm:{
					singleCardValue:[],
					poolUseValue:[],
					mail:[],
					isChecked3:false,
					isChecked2:false,
					isChecked1:false,
					isCheckdUsed:false
				},
				groupList:[],
				stopCardDialog:false,
				batchStopForm:{
					groupId:'',
					flowStop:'',
				},
				searchForm:{},
				currentOperateId:'',
				warnMsg:'',
				poolUsedWarnMsg:'',
				mailWarnMsg:'',
				agentIdList:[],
				accountIdList:[],
				mealList:[],
				objAccountIdSelectLabel:{},
				objBaseMealIdLabel:{},
				objAgentIdLabel:{},
				loginType:'',
				rules:{
                	agentId:[
                    {required: true, message: '请选择代理商', trigger: 'blur'}
               		 ],
                 	baseMealId:[
                    {required: true, message: '请选择套餐！', trigger: 'blur'}
                	],
                 	groupCode:[
                    {required: true, message: '计费组代码为空！', trigger: 'blur'}
                	],
                 	groupName:[
                    {required: true, message: '计费组名称为空！', trigger: 'blur'}
                	],
				},
				
			}
		},
		created() {
			this.getGroupList()
			this.getAgentIdList()
			this.getAccountIdList()
			this.getLoginType()
			this.getBatchStopList()
		},
		methods:{
			 formatDatebox(value){
				// console.log(value)
            if(value){
            var date = new Date(value);
             var y = date.getFullYear();
             var m = date.getMonth() + 1;
             m = m < 10 ? ('0' + m) : m;
             var d = date.getDate();
             d = d < 10 ? ('0' + d) : d;
             var h = date.getHours();
             var minute = date.getMinutes();
             minute = minute < 10 ? ('0' + minute) : minute;
	         var second = date.getSeconds()
	         second = second < 10 ? ('0' + second) : second;
            return y + '-' + m + '-' + d+' '+h+':'+minute+":"+second;
			}else{
				return "-"
			}
        
                // if (value == null || value == '') {  
                //     return '';  
                // }  
                // var dt;  
                // if (value instanceof Date) {  
                //     dt = value;  
                // } else {  
                //     dt = new Date(value);  
                // }  
                // // return dt.format("yyyy-MM-dd hh:mm:ss"); 
                // return  dt.toJSON().substr(0, 19).replace('T', ' ')
            },
			batchStopCard(){
				this.stopCardDialog = true
				this.batchStopForm={
					groupId:'',
					flowStop:'',
				}
			},
			getBatchStopList(){
				let params = {
					chargeMode:'1',
					pageSize:1000
				}
				chargeGroupList(params).then(res=>{
					this.groupList = res.rows
				})
			},
			uploadExcel(){
				this.$confirm("确定导入吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    let file = this.$refs['file'].files[0];
                    if(file){
                        let fileName = file.name
                        let index = fileName.lastIndexOf(".");
                        if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                            this.$alert('文件格式错误', '提示', {
							    confirmButtonText:'确定',
							});
							return
                        }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                            let param = new FormData()
                            if(!!this.batchStopForm.groupId){
                                param.append("groupId",this.batchStopForm.groupId)
                            }else{
                                this.$alert('请选择计费组', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
                            if(!!this.batchStopForm.flowStop){
                                param.append("flowStop",this.batchStopForm.flowStop)
                            }else{
                                this.$alert('请填写阀值：', '提示', {
							    confirmButtonText:'确定',
                                });
                                return
                            }
							param.append("file",file)
                            importBatchStopCardByExcel(param).then(res=>{
                            if (res.system_result_key == '0') {
                             this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
								this.stopCardDialog = false
                                this.$router.push('/tob/i_import_list')
                            	});
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                            })
                        }
                    }else{
                        this.$alert('请选择文件', '提示', {
							    confirmButtonText:'确定',
							});
							return
                    }
                })
			},
			exportTemplateExcel(name){
            	exportTemplate({'name':name}).then(res=>{
                	const filename = name
					Utils.createDownload(res,filename)
            	})
        	},
			getLoginType(){
             loginType().then(res=>{
                 this.loginType = res.loginUser.type
             })
         	},
			getAgentIdList(){
				getAgentList().then(res=>{
					this.agentIdList = res
				})
			},
			getAccountIdList(){
				operatorLabelList().then(res=>{
					this.accountIdList = res
				})
			},
			accountIdChanged(val){
				if(!this.addForm.chargeMode || !this.addForm.accountId){
					return
				}else{
					getMealByAccountId(this.addForm).then(res=>{
					this.mealList = res.list
				})
				}	
			},
			getAccountIdSelectLabel(accountId){
				this.objAccountIdSelectLabel = this.accountIdList.find(item=>{
					return item.id == accountId
				})
			},
			getBaseMealIdLabel(baseMealId){
				this.objBaseMealIdLabel = this.mealList.find(item=>{
					return item.id == baseMealId
				})
			},
			getAgentIdLabel(agentId){
				this.objAgentIdLabel = this.agentIdList.find(item=>{
					return item.id == agentId
				})
			},
			autoGenerate(){
				this.getAccountIdSelectLabel(this.addForm.accountId)
				this.getBaseMealIdLabel(this.addForm.baseMealId)
				this.getAgentIdLabel(this.addForm.agentId)
				let pinyinAccount = this.makePy(this.objAccountIdSelectLabel.accountName)
				let pinyinAgent = this.makePy(this.objAgentIdLabel.name)
				let categoryStr = ""
				let pCategoryStr = this.objAccountIdSelectLabel.accountName
				console.log(pCategoryStr,284)
				if (pCategoryStr.indexOf("移动") != -1) {
                categoryStr = "中国移动";
				} else if (pCategoryStr.indexOf("联通") != -1) {
					categoryStr = "中国联通";
				} else if (pCategoryStr.indexOf("电信") != -1) {
					categoryStr = "中国电信";
				} else if (pCategoryStr.indexOf("虚拟") != -1){
					categoryStr = "虚拟运营商";
				}else {
					categoryStr = "未知";
				}
				this.addForm.groupName = categoryStr + '-' + this.objBaseMealIdLabel.name
				this.addForm.groupCode = pinyinAgent + '-' + this.addForm.baseMealId.substring(0, 4)+ '-' + pinyinAccount
			},

			getCardDetail(id){
				this.$router.push({path:'/chargeGroup/card',query:{groupId:id}})
			},
			getGroupStatistic(id){
				this.$router.push({path:'/chargeGroup/statistic',query:{groupId:id}})
			},
			exportGroup(id){
				exportGroup({groupId:id}).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
			},
			openWarnDialog(rowData){
				this.warnDialogVisible = true
				this.currentOperateId = rowData.id

				this.warnRuleForm.singleCardValue = []
				this.warnRuleForm.poolUseValue = []
				this.warnRuleForm.mail = []
				this.warnRuleForm.isChecked3 = false
				this.warnRuleForm.isChecked2 = false
				this.warnRuleForm.isChecked1 = false
				this.warnMsg = ''
				this.poolUsedWarnMsg = ''
				this.mailWarnMsg = ''
			
				findWarnSettingByGroupId({groupId:this.currentOperateId}).then(res=>{
					if (!!res.entity.singleCardValue && res.entity.singleCardValue.length > 0) {
						this.warnRuleForm.singleCardValue = res.entity.singleCardValue.split(";")
					}
					if (!!res.entity.poolUseValue && res.entity.poolUseValue.length > 0) {
						this.warnRuleForm.poolUseValue = res.entity.poolUseValue.split(";")
					}
					if (!!res.entity.mail && res.entity.mail.length > 0) {
						this.warnRuleForm.mail = res.entity.mail.split(";")
					}
					if (!!res.entity.leftTime && res.entity.leftTime.length > 0) {
						let arr = res.entity.leftTime.split(";")
						arr.forEach(item=>{
							if (item == '3') {
								this.warnRuleForm.isChecked3 = true
							}
							if (item == '2') {
								this.warnRuleForm.isChecked2 = true
							}
							if (item == '1') {
								this.warnRuleForm.isChecked1 = true
							}
						})
					}
					this.warnRuleForm.isCheckdUsed = res.entity.active
					this.warnRuleForm.id = res.entity.id
					
					
				})
			},
			onSetWarn(){
				if (this.warnRuleForm.singleCardValue.length>3) {
					this.warnMsg = "设置失败，单卡预警值数量限制3个以内"
					return
				}
				if (this.warnRuleForm.poolUseValue.length>3) {
					this.poolUsedWarnMsg = "设置失败，流量池预警值数量限制3个以内"
					return
				}
				if (this.warnRuleForm.mail.length>5) {
					this.mailWarnMsg = "设置失败，报警邮箱数量限制5个以内"
					return
				}
				const singleCardValue = this.warnRuleForm.singleCardValue.join(';')
				const poolUseValue = this.warnRuleForm.poolUseValue.join(';')
				const mail = this.warnRuleForm.mail.join(';')
				
				let leftTime = (this.warnRuleForm.isChecked3 ? '3;':'') + (this.warnRuleForm.isChecked2 ? '2;':'') + (this.warnRuleForm.isChecked1 ? '1':'')
				const active = this.warnRuleForm.isCheckdUsed
				let params = {"singleCardValue":singleCardValue,
							  "poolUseValue":poolUseValue,
							  "mail":mail,
							  "leftTime":leftTime,
							  "active":active,
							  "groupId":this.currentOperateId,
							  "id":this.warnRuleForm.id}
				updateWarnSettingByGroupId(params).then(res=>{
					this.$message({
						type: 'success',
						message:'更新成功'
					});
					this.warnDialogVisible = false
				})
			},
			eidtGroup(rowData){
				this.currentOperateId = rowData.id
				this.ruleForm.remark = rowData.remark
				this.ruleForm.groupName =  rowData.groupName
				this.dialogVisible = true
			},
			onChageGroup(){
				chargeGroupUpdate({id:this.currentOperateId,remark:this.ruleForm.remark,groupName:this.ruleForm.groupName}).then(res=>{
					this.$message({
						type: 'success',
						message:'编辑成功'
					});
					this.getGroupList()
					this.dialogVisible = false
				})
			},
			// reset(){
			// 	this.searchForm = {}
			// },
			reset(formName){
            	this.$refs[formName].resetFields()
        	},
			add(){
				this.addDialogFormVisible=true
			},
			sureAdd(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						addGroup(this.addForm).then(res=>{
							this.$message({
								showClose: true,
								message: '新增成功',
								type: 'success',
								center: true
							})
							this.$refs[formName].resetFields()
							this.addDialogFormVisible=false
							this.getGroupList() 
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			deleteGroup(id){
				this.$confirm('将会删除组中所有的卡，确定要删除吗？', '消息提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteGroup({id}).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						})
						this.getGroupList()
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			onSearch(){
				this.page = 1
				this.getGroupList()
			},
			getGroupList(){
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page,
				}
				Object.assign(this.searchForm,params)
				chargeGroupList(this.searchForm).then(res=>{
					this.total = res.count
					this.tableData = res.rows
				})
			},
			onPageChange(page){
				this.page = page
				this.getGroupList()
			},
			handleSizeChange(size){
				this.pageSize = size
				this.getGroupList()
			},
			makePy(str) {
				if (typeof (str) != "string") {
					throw new Error(-1, "\u51fd\u6570makePy\u9700\u8981\u5b57\u7b26\u4e32\u7c7b\u578b\u53c2\u6570!");
				}
				let arrResult = new Array();
				for (var i = 0, len = str.length; i < len; i++) {
					var ch = str.charAt(i);
					arrResult.push(Utils.checkCh(ch));
				}
				return Utils.mkRslt(arrResult);
			}
		}
	}
</script>

<style>
	 
</style>
