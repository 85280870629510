<template>
    <div class="merchant-bind-review fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">代理商商户号绑定审核</span>
            <el-form inline ref="searchForm" v-model="searchForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="searchForm.agentNameLike" placeholder="代理商"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="searchForm.mchId" placeholder="商户号码"></el-input>
                </el-form-item>
                
                <el-form-item label="审核状态">
                    <el-select v-model="searchForm.auditStatus" placeholder="全部" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待审核" value="1"></el-option>
                        <el-option label="审核通过" value="2"></el-option>
                        <el-option label="审核失败" value="3"></el-option>
                    </el-select>
                </el-form-item>              
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <!-- <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item> -->
            </el-form>

            
        </div>
        <div class="content-box">
            <el-table
                ref='table'
                border
                stripe
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :max-height="maxTableHeight">
            <el-table-column
                show-overflow-tooltip
                prop="agentName"
                label="代理商"
                min-width="150"
                >
            </el-table-column>
             <el-table-column
                    show-overflow-tooltip
                    label="商户类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==1">微信</span>
                        <span v-else-if="scope.row.type==2">乐刷</span>
                    </template>
            </el-table-column>

            <el-table-column
                show-overflow-tooltip
                prop="name"
                label="商户号名称"
                min-width="150"
                >
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="mchId"
                label="商户号id"
                min-width="150"
                >
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="applyTime"
                    label="申请时间"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="auditTime"
                    label="审核时间"
                    min-width="180">
            </el-table-column>

            <el-table-column
                    show-overflow-tooltip
                    label="状态"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.auditStatus==1">待审核</span>
                        <span v-else-if="scope.row.auditStatus==2">审核通过</span>
                        <span v-else-if="scope.row.auditStatus==3">审核失败</span>
                    </template>
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="failReason"
                    label="失败原因"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="auditRemark"
                    label="备注"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="auditor"
                    label="操作人"
                    min-width="120">
            </el-table-column>
            <el-table-column
                label="操作"
                min-width="150">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.auditStatus==1" size="mini" class="themed-button" type="primary" @click="handleCheck(scope.row.id)">审核</el-button>
                        <el-button v-if="scope.row.auditStatus==2" size="mini" class="themed-button" type="primary" @click="resetCheck(scope.row.id)">解绑</el-button>
                    </template>
            </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>


        <el-dialog title="审核" :visible.sync="checkDialog" destroy-on-close>
        <el-form label-position="right" label-width="120px" :model="checkForm"  ref="checkForm">
             <el-form-item label="状态">
                    <el-select v-model="checkForm.auditStatus" placeholder="请选择">
                        <el-option label="通过" value="2"></el-option>
                        <el-option label="不通过" value="3"></el-option>
                    </el-select>
            </el-form-item>      
            <el-form-item label="备注">
                    <el-input v-model="checkForm.auditRemark" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="submitCheck('checkForm')">确 定</el-button>
                <el-button class="themed-button" type="primary" @click="checkDialog = false">关 闭</el-button>
            </div>
    </el-dialog>


    </div>
</template>
<script>
import {agentMerchantList,auditAgentMerchant,unboundAgentMerchant} from "@/api/agentManagement.js"
import resize from '@/mixins/resize.js'
export default {
    name:'administratorRechargeReview',
    mixins:[resize],
    data(){
        return{
            agentIdList:[],
            searchForm:{
                agentNameLike:'',
                mchId:'',
                auditStatus:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            checkDialog:false,
            checkForm:{
                id:'',
                auditStatus:'',
                auditRemark:'',
            }
        }
    },
    created(){
        this.getList()
    },
    methods:{
        getList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.searchForm,params)
            agentMerchantList(this.searchForm).then(res=>{
                this.tableData = res.rows
                this.total= res.count
            })
        },
        onSearch(){
            this.page = 1
            this.getList()
        },
        // exportExcel(){
        //     exportSelfRecharge(this.searchForm).then(res=>{
		// 			if (res.system_result_key == '0') {
		// 				this.$alert('导出成功，请到导出记录中下载', '提示', {
		// 				    confirmButtonText:'确定',
		// 				}).then(()=>{
		// 					this.$router.push('/tob/i_export_list')
		// 				});
		// 			}else{
		// 				this.$message({
		// 				    showClose: true,
		// 				    message: res.system_result_message_key,
		// 				    type: 'error'
		// 				});
		// 			}
		// 		})
        // },
        onPageChange(page){
			this.page = page
			this.getList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getList()
        },
        handleCheck(id){
            this.checkDialog = true
            this.checkForm.id= id
            this.checkForm.auditStatus=''
            this.checkForm.auditRemark = ''
        },

        resetCheck(id){
            this.$confirm('确定此操作吗？','消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                unboundAgentMerchant({id}).then(res=>{
                    this.$message({
                            showClose: true,
					        message: '操作成功',
					        type: 'success',
							center: true
                    })
                    this.getList()
                })
            })
        },

        changeCheckType(val){

        },

        submitCheck(formName){
            this.$confirm('确定此操作吗？','消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                auditAgentMerchant(this.checkForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '操作成功',
					        type: 'success',
							center: true
                        })
                        this.$refs[formName].resetFields()
                        this.checkDialog=false
                        this.getList() 
                })
            }).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
			});
        },
        download(url){
            window.location.href = url
        }
    }
}
</script>
<style>
    .merchant-bind-review .el-form-item__label{
        vertical-align: top;
    }
    .merchant-bind-review .dialog-row-label{
        text-align: right;
    }
</style>