<template>

<div class="couponManagement table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">优惠券管理</span>
        <el-form inline class="hook right themed-form search-form">   
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addTicket">新增优惠券</el-button>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="请输入搜索关键字，多个用空格隔开" v-model="couponsName" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
                </el-input>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="优惠券名称" prop="couponsName" show-overflow-tooltip></el-table-column>
            <el-table-column label="优惠券金额" prop="couponsValue" show-overflow-tooltip>
                <!-- <template slot-scope="scope">
                    <span>{{scope.row.couponsValue/100}}元</span>
                </template> -->
            </el-table-column>
            <el-table-column label="优惠券码" prop="couponsCode" show-overflow-tooltip></el-table-column>
            <el-table-column label="券状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status == 1">启用</span>
                    <span v-if="scope.row.status == 0">未启用</span>
                </template>
            </el-table-column>
          
             <el-table-column label="类型" prop="type" show-overflow-tooltip>
               <template slot-scope="scope">
                   {{_getCoupon_type(scope.row.type)}}
                </template>
             </el-table-column>
              <el-table-column label="有效类型" prop="effectType" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.effectType == '1'">月</span>
                    <span v-else-if="scope.row.effectType == '2'">天</span>
                    <span v-if="scope.row.effectType == '3'">固定时长</span>
                </template>
            </el-table-column>
             <el-table-column label="有效时长" prop="effectCount" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="生效时间" prop="startTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.startTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="失效时间" prop="endTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.endTime)}}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.createTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="360px">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" v-if="scope.row.status == 1"  @click="clickdisable(scope.row.id)">停止</el-button>
                    <el-button type="info" class="themed-button" v-else @click="clickable(scope.row.id)">启用</el-button>
                    <el-button type="info" class="themed-button"  @click="showFilter(scope.row.id,scope.row.effectType)">赠送规则配置</el-button>
                    <el-button type="info" class="themed-button"  @click="useFilter(scope.row.id)">使用规则配置</el-button>
                    <el-button type="danger" class="themed-button"  @click="delFilter(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogBox" v-if="dialogBox" width="70%">
        <filter-show :id="id"  :effectType="effectType" v-if="dialogContent == 1"></filter-show>
        <filter-use :id="id" v-if="dialogContent == 2" ></filter-use>
        <new-coupons v-if="dialogContent == 3"  @close="dialogBox=false"  @refresh="refreshData"></new-coupons>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {getCouponsList,updateData,deleteData} from '@/api/eventDiscountManagement/couponManagement.js'

    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'

    import FilterShow from '@/views/eventDiscountManagement/couponManagement/filterShow'
    import FilterUse from '@/views/eventDiscountManagement/couponManagement/filterUse'
    import NewCoupons from '@/views/eventDiscountManagement/couponManagement/newCoupons'
    import dictGroup from "@/mixins/dictGroup.js";
    import resize from '@/mixins/resize.js'

    const ERR_OK = 0
    export default {
        name:'couponManagement',
        mixins:[resize,dictGroup],
        data(){
            return {
                couponsName:'',
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
                dialogBox:false,
                dialogTitle:'',
                dialogContent:'',
                id:'',
                effectType:""
            }
        },
        mounted(){
            this._getCouponsList()
        },
        methods:{
            addTicket(){
                this.dialogBox = true
                this.dialogContent = 3
                this.dialogTitle = '新增优惠券'
            },
            clickdisable(id){
                confirmBox(this,'确定要停止优惠券么？',null,()=>{
                   this._updateData(id,0)
                })
            },
            clickable(id){
               confirmBox(this,'确定要开启优惠券么？',null,()=>{
                   this._updateData(id,1)
               }) 
            },
            showFilter(id,effectType){
                this.dialogBox = true
                this.dialogContent = 1
                this.dialogTitle = '赠送规则配置'
                this.id = id
                this.effectType = effectType
            },
            useFilter(id){
                this.dialogBox = true
                this.dialogContent = 2
                this.dialogTitle = '使用规则配置'
                this.id = id
            },
            delFilter(id){
               confirmBox(this,'确定要删除么？',null,()=>{
                   deleteData({id}).then((res)=>{
                       this._getCouponsList()
                   })
               })  
            },
            searchData(){
                this._getCouponsList()
            },
            refreshData(){
                this.dialogBox = false
                this._getCouponsList()
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._getCouponsList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._getCouponsList()
            },
            formatDatebox(value){
                if (value == null || value == '') {  
                    return '';  
                }  
                var dt;  
                if (value instanceof Date) {  
                    dt = value;  
                } else {  
                    dt = new Date(value);  
                }
              return     dt.toLocaleString().replace(/\//g, '-')  
                // return dt.format("yyyy-MM-dd hh:mm:ss"); 
                // return  dt.toJSON().substr(0, 19).replace('T', ' ')
            },
            _updateData(id,status){
                let params = {id,status}
                updateData(params).then((res)=>{
                    this._getCouponsList()
                })
            },
            _getCouponsList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                params.couponsName = this.couponsName
                getCouponsList(params).then((res)=>{
                    // console.log(res)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        },
        components:{
            SearchSelect,
            FilterShow,
            FilterUse,
            NewCoupons
        }
    }
</script>

<style>
.marginR10{margin-right:10px;}
.couponManagement .search-form .el-input .el-input__inner {width:150px}
.couponManagement .el-form-item__content .el-input-group{vertical-align:middle}
</style>
