<template>
  <div class="balanceManagement fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">余额管理</span>
      <el-form
        :inline="true"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
        id="baseForm"
      >
        <el-form-item label="iccid:">
          <el-input v-model="form.iccid" placeholder="输入ICCID"></el-input>
        </el-form-item>
         <el-form-item label="订单号:">
          <el-input v-model="form.orderNumber" placeholder="输入订单号"></el-input>
        </el-form-item>
        <el-form-item label="短号:">
          <el-input v-model="form.shortIccid" placeholder="输入短号"></el-input>
        </el-form-item>
         <el-form-item label="运营商:">
                <el-select v-model="form.category">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in w_ACCOUNT_" :key="index" :label="item.groupName" :value="item.dictValue"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="代理商:">
                <el-select v-model="form.agentName" filterable>
                    <el-option v-for="(item,index) in agentList" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            class="themed-button"
            icon="el-icon-search"
            >搜索查询</el-button
          >
        </el-form-item>
          <el-form-item>
          <el-button
            icon="el-icon-download"
            class="themed-button"
            type="info"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table  :data="tabledata"    stripe style="width: 100%" ref="table"   :max-height="maxTableHeight"  border>
        <el-table-column prop="iccid" label="iccid"> </el-table-column>
        <el-table-column prop="shortIccid" label="短号"> </el-table-column>
        <el-table-column prop="balance" label="金额"> </el-table-column>
         <el-table-column prop="invoicableBalance" label="可开票余额"> </el-table-column>
          <el-table-column prop="invoicedBalance" label="已开票余额"> </el-table-column>
        <el-table-column prop="adminBalance" label="后台充值金额"> </el-table-column>
        <el-table-column prop="sendMoney" label="赠送金额"> </el-table-column>
        
      <el-table-column prop="agentName" label="代理商"> </el-table-column>
<el-table-column prop="category" label="运营商类型"> 
     <template slot-scope="scope">
       <span>{{_getCategory(scope.row.category,1)}}</span>
     </template>
</el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
               <el-button  size="mini" class="themed-button" type="primary"  v-if="currentRole==0 || (currentRole==4 && agentTree==1)"  @click="refund(scope.row)" >退款</el-button>
                <el-button  size="mini" class="themed-button" type="primary" v-if="currentRole==0 || (currentRole==4 && agentTree==1)"  @click="modify(scope.row)" >修改</el-button>
                <el-button  size="mini" class="themed-button" type="primary" @click="updateRecord(scope.row)" >变更记录</el-button>
            <!-- <span @click="refund(scope.row)" class="right_agent">退款 </span>
            <span @click="updateRecord(scope.row)" class="right_agent">变更记录</span> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="退款" :visible.sync="dialogRefundVisible" :destroy-on-close='true'>
             <span v-if="refundTip" style="color:#F00">{{refundTipText}}</span>
        <el-form :model="refundForm">
            <el-form-item label="金额" prop="reMoney">
            <el-input v-model="refundForm.money" autocomplete="off" placeholder="不填写默认全额退款" style="width:200px"></el-input>
            </el-form-item>
            <!-- <el-form-item label="退款理由" prop="refundReason">
            <el-input type="textarea" v-model="refundForm.refundReason"></el-input>
            </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button class="themed-button" type="info" @click="dialogRefundVisible = false">取 消</el-button>
            <el-button class="themed-button" type="primary" @click="sureRefund" v-preventReClick='2000' :disabled="isDisable">确 定</el-button>
        </div>
        </el-dialog>

     <el-dialog title="修改" :visible.sync="dialogModifyVisible" :destroy-on-close='true'>
            
        <el-form :model="modifyForm">
           <el-form-item label="方式:">
                <el-select v-model="modifyForm.type">
                    <el-option label="增加" value="1"></el-option>
                     <el-option label="扣减" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="金额:">
            <el-input v-model="modifyForm.money" autocomplete="off"  style="width:200px"></el-input>
            </el-form-item>
         
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button class="themed-button" type="info" @click="dialogModifyVisible = false">取 消</el-button>
            <el-button class="themed-button" type="primary" @click="sureModify">确 定</el-button>
        </div>
        </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import {
  balanceManagementList,
  balanceRefund,balanceModify,
  exportBalanceList,
  getDisablingBills
} from "@/api/cardManagement/balanceManagement.js";
import {messageBox} from '@/utils/common.js'
import dictGroup from '@/mixins/dictGroup.js'
import {orderGetAgents} from '@/api/agentApplyCheck.js'
import {mapGetters} from 'vuex'
export default {
  name: "balanceManagement",
  mixins: [onresize,dictGroup],
  data() {
    return {
      form: {
        iccid: "",
        orderNumber:"",
        shortIccid:"",
        category:"",
        agentName:"",
      },
      page: 1,
      pageSize: 15,
      total: 0,
      tabledata: [],
      dialogRefundVisible:false,
      refundForm:{
          money:"",
      },
      maxRemoney:"",
      refundID:"",
      refundTip:false,
      refundTipText:"",
      dialogModifyVisible:false,
      modifyForm:{
        type:"2",
        money:"",
      },
      modifyId:"",
     isDisable:false,
     agentList:[],
    };
  },
  
  created() {
      this._orderGetAgents()
    this.getBalanceList();
  },
   computed:{
        	...mapGetters(
            	['currentRole','agentTree']
        	),
    	},
  methods: {
    getBalanceList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.form, params);
      balanceManagementList(this.form).then((res) => {
        this.tabledata = res.rows;
        console.log(this.tableData )
        this.total = res.count;
      });
    },
     _orderGetAgents(){
            this.agentList = []
            this.agentList.push({
                label:'全部',
                value:''
            });
            // this.agentList.push({
            //     label:'当前账号',
            //     value:'-1'
            // });
            orderGetAgents({status:0}).then((res)=>{
                if(res.length>0){
                    res.forEach((item)=>{
                        this.agentList.push({
                            label:item.name,
                            value:item.id
                            })
                    })
                }
            })
        },
    searchData() {
      this.page = 1;
      this.getBalanceList();
    },
    onPageChange(page) {
      this.page = page;
      this.getBalanceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getBalanceList();
    },
  
    refund(row) {
      this.dialogRefundVisible = true
            this.refundForm.money = row.balance
            this.refundID = row.iccid
            this.maxRemoney = row.balance
            let realMoney ;
            if( parseFloat(row.sendMoney)+parseFloat(row.adminBalance)>0 ){
              this.refundTip = true
            if(parseFloat(row.balance)-parseFloat(row.sendMoney)-parseFloat(row.adminBalance) >=0){
                realMoney = (parseFloat(row.balance)-parseFloat(row.sendMoney)-parseFloat(row.adminBalance)).toFixed(2);
            } else {
              realMoney = 0
            }
              this.refundTipText = "包含赠送金额"+(parseFloat(row.sendMoney)+parseFloat(row.adminBalance)).toFixed(2)+"元，建议退款"+realMoney+"元"
            }else {
              this.refundTip = false
              this.refundTipText =  ""
            }
    },
    sureRefund(){
 if(!!this.refundForm.money && parseFloat(this.refundForm.money) > parseFloat(this.maxRemoney)){
                this.$alert('退款金额不大于剩余金额', '提示', {
								confirmButtonText: '确定',
					    });
				return
            }
           this.isDisable = true
            let params={
                iccid:this.refundID
            }
            Object.assign(this.refundForm,params)
            balanceRefund(this.refundForm).then(res=>{
              this.isDisable = false
                if(res.system_result_key == 0){
                    this.$message({
						    showClose: true,
						    message: '退款成功',
						    type: 'success'
                        })
                    this.dialogRefundVisible = false
                    this.getBalanceList()
                }else{

                }
            }).catch(()=>{
               this.isDisable = false
            })
            
    },
    updateRecord(row) {
         let selections = [{ iccid: row.iccid }];
      console.log(selections)
      this.$router.push({
        path: "/networkCardBalanceManagement/balanceChangeRecord",
        query: selections[0],
      });
    },

    modify(row){
     this.dialogModifyVisible = true 
     this.modifyId = row.iccid
     this.modifyForm.type = "2"
     this.maxRemoney = row.balance
     this.modifyForm.money =""
    },
    sureModify(){
      if(this.modifyForm.type==""){
            messageBox(this,'请选择修改方式！')
                return 
      }
      if(isNaN(this.modifyForm.money)){
         messageBox(this,'请输入正确的金额！')
                return 
      }
       if(!!this.modifyForm.money&& this.modifyForm.type== "2"&& parseFloat(this.modifyForm.money) > parseFloat(this.maxRemoney)){
                this.$alert('扣减金额不大于剩余金额', '提示', {
								confirmButtonText: '确定',
					    });
				return
            }
       if(!!this.modifyForm.money&& this.modifyForm.type== "1"&& parseFloat(this.modifyForm.money) > 1000){
                this.$alert('增加金额不大于1000', '提示', {
								confirmButtonText: '确定',
					    });
				return
            }
        Object.assign(this.modifyForm,{iccid:this.modifyId})
        balanceModify(this.modifyForm).then(res=>{
             if(res.system_result_key == 0){
                    this.$message({
						    showClose: true,
						    message: '修改成功',
						    type: 'success'
                        })
                    this.dialogModifyVisible = false
                    this.getBalanceList()
                }else{

                }
        })

    },
     exportExcel() {
      exportBalanceList(this.form).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style>
.balanceManagement .el-form-item__label {
  vertical-align: top;
}
</style>
