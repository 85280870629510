

<template>
  <div class="configModification fadeInRight">
    <div class="title-box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="业务域名配置" name="first"></el-tab-pane>
        <el-tab-pane label="阿里云配置" name="fourth"></el-tab-pane>
        <el-tab-pane label="微信配置" name="five"></el-tab-pane>
        <el-tab-pane label="邮件配置" name="second"></el-tab-pane>
        <el-tab-pane label="钉钉配置" name="third"></el-tab-pane>
        <el-tab-pane label="其他配置" name="six"></el-tab-pane>
        <el-tab-pane label="卡务配置" name="seven"></el-tab-pane>
        <el-tab-pane label="首都在线配置" name="eight"  v-if="isCaptal=='1'"></el-tab-pane>
        <el-tab-pane label="天翼云配置" name="nine"  v-if="isCaptal=='2'"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="content-box">
      <div v-show="activeName == 'seven'">
        <el-form ref="form7" :model="form" label-width="110px">
          <el-form-item
            label="停机提前天数"
            prop="CARD_AHEAD_STOP_DAYS"
            :rules="[
              { required: true, message: '停机提前天数不能为空' },
              { type: 'number', message: '天数必须为数字值' },
            ]"
          >
            <el-input
              v-model.number="form.CARD_AHEAD_STOP_DAYS"
              max="5"
              min="0"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit(activeName)"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-show="activeName == 'second'">
        <el-form ref="form1" :model="form" label-width="88px">
          <el-form-item label="邮件域名">
            <el-input v-model="form.MAIL_HOST"></el-input>
          </el-form-item>

          <el-form-item label="邮件端口">
            <el-input v-model="form.MAIL_SMTP_PORT"></el-input>
          </el-form-item>
          <el-form-item label="邮件协议">
            <el-input v-model="form.MAIL_TRANSPORT_PROTOCOL"></el-input>
          </el-form-item>
          <el-form-item label="用户名">
            <el-input v-model="form.MAIL_USER"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="form.MAIL_PASSWORD"></el-input>
          </el-form-item>
          <el-form-item label="管理员邮箱">
            <el-input v-model="form.ADMIN_EMAIL"></el-input>
          </el-form-item>
          
          <el-form-item>
            <el-button type="primary" @click="onSubmit(activeName)"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-show="activeName == 'third'">
        <el-form ref="form2" :model="form" label-width="80px">
          <el-form-item label="推送地址">
            <el-input v-model="form.DINGTALK_WEBHOOK"></el-input>
          </el-form-item>

          <el-form-item label="提醒人">
            <el-input v-model="form.DINGTALK_REMIND_PHONE"></el-input>
          </el-form-item>
          <el-form-item label="关键词">
            <el-input v-model="form.DINGTALK_KEY_WORD"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit(activeName)"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-show="activeName == 'fourth'">
        <el-form
          ref="form3"
          :model="form"
          label-width="145px"
          :rules="rulesOne"
        >
          <el-form-item label="OSS配置" class="title"> </el-form-item>
          <el-form-item label="endpoint" prop="ALI_OSS_ENDPOINT">
            <el-input v-model="form.ALI_OSS_ENDPOINT"></el-input>
          </el-form-item>

          <el-form-item label="accesskey" prop="ALI_OSS_ACCESS_KEY">
            <el-input v-model="form.ALI_OSS_ACCESS_KEY"></el-input>
          </el-form-item>
          <el-form-item label="secretkey" prop="ALI_OSS_SECRET_KEY">
            <el-input v-model="form.ALI_OSS_SECRET_KEY"></el-input>
          </el-form-item>

          <el-form-item label="短信配置" class="title"> </el-form-item>
          <el-form-item label="region_id" prop="ALI_SMS_REGION_ID">
            <el-input v-model="form.ALI_SMS_REGION_ID"></el-input>
          </el-form-item>

          <el-form-item label="access_key_id" prop="ALI_SMS_ACCESS_KEY_ID">
            <el-input v-model="form.ALI_SMS_ACCESS_KEY_ID"></el-input>
          </el-form-item>
          <el-form-item label="secret" prop="ALI_SMS_SECRET">
            <el-input v-model="form.ALI_SMS_SECRET"></el-input>
          </el-form-item>
          <el-form-item label="sign_name" prop="ALI_SMS_SIGN_NAME">
            <el-input v-model="form.ALI_SMS_SIGN_NAME"></el-input>
          </el-form-item>
          <el-form-item
            label="verify_template_code"
            prop="ALI_SMS_TEMPLATE_CODE_VERIFY"
          >
            <el-input v-model="form.ALI_SMS_TEMPLATE_CODE_VERIFY"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit(activeName)"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-show="activeName == 'first'">
        <el-form ref="form4" :model="form" label-width="80px">
          <el-form-item
            label="业务域名"
            prop="BUSSINESS_HOST"
            :rules="[{ required: true, message: '业务域名不能为空' }]"
          >
            <el-input v-model="form.BUSSINESS_HOST"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit(activeName)"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-show="activeName == 'five'">
        <el-form ref="form5" :model="form" label-width="150px" :rules="rules">
          <el-form-item label="公众号配置" class="title"> </el-form-item>
          <el-form-item label="app_id" prop="OFFICIAL_APP_ID">
            <el-input v-model="form.OFFICIAL_APP_ID"></el-input>
          </el-form-item>

          <el-form-item label="app_secret" prop="OFFICIAL_APP_SECRET">
            <el-input v-model="form.OFFICIAL_APP_SECRET"></el-input>
          </el-form-item>
          <el-form-item
            label="real_name_template"
            prop="OFFICIAL_MSG_PUSH_TEMPLATE_REAL_NAME"
          >
            <el-input
              v-model="form.OFFICIAL_MSG_PUSH_TEMPLATE_REAL_NAME"
            ></el-input>
          </el-form-item>

  <el-form-item label="tob公众号配置" class="title"> </el-form-item>
          <el-form-item label="app_id" prop="XIAOLU_APP_ID">
            <el-input v-model="form.XIAOLU_APP_ID"></el-input>
          </el-form-item>

          <el-form-item label="app_secret" prop="XIAOLU_APP_SECRET">
            <el-input v-model="form.XIAOLU_APP_SECRET"></el-input>
          </el-form-item>
          <el-form-item
            label="预警通知模版"
            prop="XIAOLU_MSG_PUSH_TEMPLATE_WARING"
          >
            <el-input
              v-model="form.XIAOLU_MSG_PUSH_TEMPLATE_WARING"
            ></el-input>
          </el-form-item>


          <el-form-item label="商户号配置" class="title"> </el-form-item>
          <el-form-item label="ID" prop="MERCHANT_ID">
            <el-input v-model="form.MERCHANT_ID"></el-input>
          </el-form-item>

          <el-form-item label="秘钥" prop="MERCHANT_KEY">
            <el-input v-model="form.MERCHANT_KEY"></el-input>
          </el-form-item>
          <el-form-item label="cert_path" prop="MERCHANT_CERT_LOCAL_PATH">
            <el-input v-model="form.MERCHANT_CERT_LOCAL_PATH"></el-input>
          </el-form-item>
          <el-form-item label="证书：">
            <input
              class="file-input"
              type="file"
              ref="fileInput"
              @change="fileChange"
            />
          </el-form-item>
          <el-form-item label="密钥V3(非必填)">
            <el-input v-model="form.MERCHANT_KEY_V3"></el-input>
            <p>若使用[商家转账到零钱]请配置此项</p>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit(activeName)"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-show="activeName == 'six'">
        <el-form ref="form6" label-width="150px">
          <el-form-item label="其他配置" class="title"> </el-form-item>
          <el-form-item label="域名验证文件" prop="a_verifyFile">
            <el-input
              v-model="verifyFile"
              placeholder="请复制文件内容到此处"
            ></el-input>
            <el-button type="primary" @click="addVerifyFile2()">添加</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="activeName == 'eight'">
        <el-form
          ref="form8"
          :model="form"
          label-width="145px"
          :rules="rulesTwo"
        >
          <el-form-item label="对象存储配置" class="title"> </el-form-item>
          
          <el-form-item label="配置开关" prop="AWS_S3_OPEN">
            <el-radio v-model="form.AWS_S3_OPEN" label="0">关闭</el-radio>
            <el-radio v-model="form.AWS_S3_OPEN" label="1">开启</el-radio>
          </el-form-item>

          <el-form-item label="endpoint" prop="AWS_S3_ENDPOINT">
            <el-input v-model="form.AWS_S3_ENDPOINT"></el-input>
          </el-form-item>

          <el-form-item label="accesskey" prop="AWS_S3_ACCESS_KEY">
            <el-input v-model="form.AWS_S3_ACCESS_KEY"></el-input>
          </el-form-item>
          <el-form-item label="secretkey" prop="AWS_S3_SECRET_KEY">
            <el-input v-model="form.AWS_S3_SECRET_KEY"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit(activeName)"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
        <div v-show="activeName == 'nine'">
        <el-form
          ref="form9"
          :model="form"
          label-width="145px"
          :rules="rulesThree"
        >
          <el-form-item label="对象存储配置" class="title"> </el-form-item>
          
          <el-form-item label="配置开关" prop="TELECOM_S3_OPEN">
            <el-radio v-model="form.TELECOM_S3_OPEN" label="0">关闭</el-radio>
            <el-radio v-model="form.TELECOM_S3_OPEN" label="1">开启</el-radio>
          </el-form-item>

          <el-form-item label="endpoint" prop="TELECOM_S3_ENDPOINT">
            <el-input v-model="form.TELECOM_S3_ENDPOINT"></el-input>
          </el-form-item>

          <el-form-item label="accesskey" prop="TELECOM_S3_ACCESS_KEY">
            <el-input v-model="form.TELECOM_S3_ACCESS_KEY"></el-input>
          </el-form-item>
          <el-form-item label="secretkey" prop="TELECOM_S3_SECRET_KEY">
            <el-input v-model="form.TELECOM_S3_SECRET_KEY"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit(activeName)"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import resize from "@/mixins/resize.js";
import {
  getConfigurationModify,
  setConfigurationModify,
  uploadFile,
  addVerifyFile,
} from "@/api/systemManagement.js";
export default {
  name: "configurationModification",
  mixins: [resize],
  data() {
    return {
      activeName: "first",
      verifyFile: "",
      form: {
        ADMIN_EMAIL:"",
        MAIL_HOST: "",
        MAIL_SMTP_PORT: "",
        MAIL_TRANSPORT_PROTOCOL: "",
        MAIL_USER: "",
        MAIL_PASSWORD: "",
        DINGTALK_WEBHOOK: "",
        DINGTALK_REMIND_PHONE: "",
        DINGTALK_KEY_WORD: "",
        ALI_OSS_ENDPOINT: "",
        ALI_OSS_ACCESS_KEY: "",
        ALI_OSS_SECRET_KEY: "",
        ALI_SMS_REGION_ID: "",
        ALI_SMS_ACCESS_KEY_ID: "",
        ALI_SMS_SECRET: "",
        ALI_SMS_SIGN_NAME: "",
        ALI_SMS_TEMPLATE_CODE_VERIFY: "",
        BUSSINESS_HOST: "",
        OFFICIAL_APP_ID: "",
        OFFICIAL_APP_SECRET: "",
        OFFICIAL_MSG_PUSH_TEMPLATE_REAL_NAME: "",
        XIAOLU_APP_ID:"",
        XIAOLU_APP_SECRET:"",
        XIAOLU_MSG_PUSH_TEMPLATE_WARING:"",
        MERCHANT_ID: "",
        MERCHANT_KEY: "",
        MERCHANT_CERT_LOCAL_PATH: "",
        CARD_AHEAD_STOP_DAYS: "",
        MERCHANT_KEY_V3: "",
        AWS_S3_SECRET_KEY: "",
        AWS_S3_ACCESS_KEY: "",
        AWS_S3_ENDPOINT: "",
        AWS_S3_OPEN:"0",
         TELECOM_S3_SECRET_KEY: "",
        TELECOM_S3_ACCESS_KEY: "",
        TELECOM_S3_ENDPOINT: "",
        TELECOM_S3_OPEN:"0",
      },
      params: {},
      rules: {
        OFFICIAL_APP_ID: [
          { required: true, message: "请输入endpoint", trigger: "blur" },
        ],
        OFFICIAL_APP_SECRET: [
          { required: true, message: "请输入accesskey", trigger: "blur" },
        ],
        OFFICIAL_MSG_PUSH_TEMPLATE_REAL_NAME: [
          { required: true, message: "请输入secretkey", trigger: "blur" },
        ],
        XIAOLU_APP_ID: [
          { required: true, message: "请输入endpoint", trigger: "blur" },
        ],
        XIAOLU_APP_SECRET: [
          { required: true, message: "请输入accesskey", trigger: "blur" },
        ],
        XIAOLU_MSG_PUSH_TEMPLATE_WARING: [
          { required: true, message: "请输入secretkey", trigger: "blur" },
        ],
        MERCHANT_ID: [{ required: true, message: "请输入ID", trigger: "blur" }],
        MERCHANT_KEY: [
          { required: true, message: "请输入密钥", trigger: "blur" },
        ],
        MERCHANT_CERT_LOCAL_PATH: [
          { required: true, message: "请输入证书路径", trigger: "blur" },
        ],
      },
      rulesOne: {
        ALI_OSS_ENDPOINT: [
          { required: true, message: "请输入endpoint", trigger: "blur" },
        ],
        ALI_OSS_ACCESS_KEY: [
          { required: true, message: "请输入accesskey", trigger: "blur" },
        ],
        ALI_OSS_SECRET_KEY: [
          { required: true, message: "请输入secretkey", trigger: "blur" },
        ],
        ALI_SMS_REGION_ID: [
          { required: true, message: "请输入region_id", trigger: "blur" },
        ],
        ALI_SMS_ACCESS_KEY_ID: [
          { required: true, message: "请输入ACCESS_KEY_ID", trigger: "blur" },
        ],
        ALI_SMS_SECRET: [
          { required: true, message: "请输入secret", trigger: "blur" },
        ],
        ALI_SMS_SIGN_NAME: [
          { required: true, message: "请输入sign_name", trigger: "blur" },
        ],
        ALI_SMS_TEMPLATE_CODE_VERIFY: [
          {
            required: true,
            message: "请输入verify_template_code",
            trigger: "blur",
          },
        ],
      },
      rulesTwo: {
        AWS_S3_ENDPOINT: [
          { required: true, message: "请输入endpoint", trigger: "blur" },
        ],
        AWS_S3_ACCESS_KEY: [
          { required: true, message: "请输入accesskey", trigger: "blur" },
        ],
        AWS_S3_SECRET_KEY: [
          { required: true, message: "请输入secretkey", trigger: "blur" },
        ],
      },
       rulesThree: {
        TELECOM_S3_ENDPOINT: [
          { required: true, message: "请输入endpoint", trigger: "blur" },
        ],
        TELECOM_S3_ACCESS_KEY: [
          { required: true, message: "请输入accesskey", trigger: "blur" },
        ],
       TELECOM_S3_SECRET_KEY: [
          { required: true, message: "请输入secretkey", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getGroup();
    this.getConfigInfo(this.params);
  },
   computed: {
    ...mapGetters(["isCaptal"]),
  },
  methods: {
    getGroup() {
      this.params = {};
      if (this.activeName == "first") {
        this.params.group = "BUSSINESS_";
      } else if (this.activeName == "second") {
        this.params.group = "MAIL_";
      } else if (this.activeName == "third") {
        this.params.group = "DINGTALK_";
      } else if (this.activeName == "fourth") {
        this.params.group = "ALI_";
      } else if (this.activeName == "five") {
        this.params.group = "WEIXIN_";
      } else if (this.activeName == "seven") {
        this.params.group = "CARD_";
      } else if (this.activeName == "eight") {
        this.params.group = "AWS_";
      }else if (this.activeName == "nine") {
        this.params.group = "TELECOM_";
      }
    },
    getConfigInfo(param) {
      getConfigurationModify(param).then((res) => {
        let map = new Map();
        if (!!res) {
          res.forEach((item) => {
            map.set(item.dictKey, item.dictValue);
          });
        }
        this.$refs["form7"].resetFields();
        this.$refs["form4"].resetFields();
        this.$refs["form5"].resetFields();
        this.$refs["form3"].resetFields();
        this.$refs["form8"].resetFields();
        this.$refs["form9"].resetFields();
        if (param.group == "BUSSINESS_") {
          this.form.BUSSINESS_HOST = map.get("BUSSINESS_HOST");
        } else if (param.group == "MAIL_") {
          this.form.MAIL_HOST = map.get("MAIL_HOST");
          this.form.MAIL_SMTP_PORT = map.get("MAIL_SMTP_PORT");
          this.form.MAIL_TRANSPORT_PROTOCOL = map.get(
            "MAIL_TRANSPORT_PROTOCOL"
          );
          this.form.MAIL_USER = map.get("MAIL_USER");
          this.form.MAIL_PASSWORD = map.get("MAIL_PASSWORD");
          this.form.ADMIN_EMAIL = map.get("ADMIN_EMAIL");
        } else if (param.group == "DINGTALK_") {
          this.form.DINGTALK_WEBHOOK = map.get("DINGTALK_WEBHOOK");
          this.form.DINGTALK_REMIND_PHONE = map.get("DINGTALK_REMIND_PHONE");
          this.form.DINGTALK_KEY_WORD = map.get("DINGTALK_KEY_WORD");
        } else if (param.group == "ALI_") {
          this.form.ALI_OSS_ENDPOINT = map.get("ALI_OSS_ENDPOINT");
          this.form.ALI_OSS_ACCESS_KEY = map.get("ALI_OSS_ACCESS_KEY");
          this.form.ALI_OSS_SECRET_KEY = map.get("ALI_OSS_SECRET_KEY");
          this.form.ALI_SMS_REGION_ID = map.get("ALI_SMS_REGION_ID");
          this.form.ALI_SMS_ACCESS_KEY_ID = map.get("ALI_SMS_ACCESS_KEY_ID");
          this.form.ALI_SMS_SECRET = map.get("ALI_SMS_SECRET");
          this.form.ALI_SMS_SIGN_NAME = map.get("ALI_SMS_SIGN_NAME");
          this.form.ALI_SMS_TEMPLATE_CODE_VERIFY = map.get(
            "ALI_SMS_TEMPLATE_CODE_VERIFY"
          );
        } else if (param.group == "WEIXIN_") {
          this.form.OFFICIAL_APP_ID = map.get("OFFICIAL_APP_ID");
          this.form.OFFICIAL_APP_SECRET = map.get("OFFICIAL_APP_SECRET");
          this.form.OFFICIAL_MSG_PUSH_TEMPLATE_REAL_NAME = map.get(
            "OFFICIAL_MSG_PUSH_TEMPLATE_REAL_NAME"
          );
          this.form.XIAOLU_APP_ID = map.get("XIAOLU_APP_ID");
          this.form.XIAOLU_APP_SECRET = map.get("XIAOLU_APP_SECRET");
          this.form.XIAOLU_MSG_PUSH_TEMPLATE_WARING = map.get(
            "XIAOLU_MSG_PUSH_TEMPLATE_WARING"
          );
          this.form.MERCHANT_ID = map.get("MERCHANT_ID");
          this.form.MERCHANT_KEY = map.get("MERCHANT_KEY");
          // this.form.MERCHANT_CERT_PATH =  map.get("MERCHANT_CERT_LOCAL_PATH")
          this.form.MERCHANT_CERT_LOCAL_PATH = map.get(
            "MERCHANT_CERT_LOCAL_PATH"
          );
          this.form.MERCHANT_KEY_V3 = map.get("MERCHANT_KEY_V3");
        } else if (param.group == "CARD_") {
          this.form.CARD_AHEAD_STOP_DAYS = parseInt(
            map.get("CARD_AHEAD_STOP_DAYS")
          );
        } else if (param.group == "AWS_") {
          this.form.AWS_S3_ENDPOINT = map.get("AWS_S3_ENDPOINT");
          this.form.AWS_S3_ACCESS_KEY = map.get("AWS_S3_ACCESS_KEY");
          this.form.AWS_S3_SECRET_KEY = map.get("AWS_S3_SECRET_KEY");
          this.form.AWS_S3_OPEN = map.get("AWS_S3_OPEN");
        }else if (param.group == "TELECOM_") {
          this.form.TELECOM_S3_ENDPOINT = map.get("TELECOM_S3_ENDPOINT");
          this.form.TELECOM_S3_ACCESS_KEY = map.get("TELECOM_S3_ACCESS_KEY");
          this.form.TELECOM_S3_SECRET_KEY = map.get("TELECOM_S3_SECRET_KEY");
          this.form.TELECOM_S3_OPEN = map.get("TELECOM_S3_OPEN");
          console.log( this.form.TELECOM_S3_OPEN )
        }
      });
    },
    handleClick(tab, event) {
      console.log(this.activeName);
      this.getGroup();
      this.getConfigInfo(this.params);
    },
    fileChange() {
      let formData = new FormData();
      formData.append("file", this.$refs["fileInput"].files[0]);
      uploadFile(formData).then((res) => {
        console.log(res.filePath);
        this.form.MERCHANT_CERT_LOCAL_PATH = res.filePath;
      });
    },
    addVerifyFile2() {
      addVerifyFile({ content: this.verifyFile }).then((res) => {
        this.$message({
          type: "success",
          message: "添加成功",
        });
      });
    },
    onSubmit(index) {
      this.getGroup();
      let param = {};
      if (index == "first") {
        this.$refs["form4"].validate((valid) => {
          if (valid) {
            param.BUSSINESS_HOST = this.form.BUSSINESS_HOST;
            Object.assign(param, this.params);
            setConfigurationModify(param).then((res) => {
              this.$message({
                type: "success",
                message: "添加成功",
              });
            });
          } else {
            return false;
          }
        });
      } else if (index == "second") {
        param.MAIL_HOST = this.form.MAIL_HOST;
        param.MAIL_SMTP_PORT = this.form.MAIL_SMTP_PORT;
        param.MAIL_TRANSPORT_PROTOCOL = this.form.MAIL_TRANSPORT_PROTOCOL;
        param.MAIL_USER = this.form.MAIL_USER;
        param.MAIL_PASSWORD = this.form.MAIL_PASSWORD;
        param.ADMIN_EMAIL = this.form.ADMIN_EMAIL;
        Object.assign(param, this.params);
        setConfigurationModify(param).then((res) => {
          this.$message({
            type: "success",
            message: "添加成功",
          });
        });
      } else if (index == "third") {
        param.DINGTALK_WEBHOOK = this.form.DINGTALK_WEBHOOK;
        param.DINGTALK_REMIND_PHONE = this.form.DINGTALK_REMIND_PHONE;
        param.DINGTALK_KEY_WORD = this.form.DINGTALK_KEY_WORD;
        Object.assign(param, this.params);
        setConfigurationModify(param).then((res) => {
          this.$message({
            type: "success",
            message: "添加成功",
          });
        });
      } else if (index == "fourth") {
        this.$refs["form3"].validate((valid) => {
          if (valid) {
            param.ALI_OSS_ENDPOINT = this.form.ALI_OSS_ENDPOINT;
            param.ALI_OSS_ACCESS_KEY = this.form.ALI_OSS_ACCESS_KEY;
            param.ALI_OSS_SECRET_KEY = this.form.ALI_OSS_SECRET_KEY;
            param.ALI_SMS_REGION_ID = this.form.ALI_SMS_REGION_ID;
            param.ALI_SMS_ACCESS_KEY_ID = this.form.ALI_SMS_ACCESS_KEY_ID;
            param.ALI_SMS_SECRET = this.form.ALI_SMS_SECRET;
            param.ALI_SMS_SIGN_NAME = this.form.ALI_SMS_SIGN_NAME;
            param.ALI_SMS_TEMPLATE_CODE_VERIFY =
              this.form.ALI_SMS_TEMPLATE_CODE_VERIFY;
            Object.assign(param, this.params);
            setConfigurationModify(param).then((res) => {
              this.$message({
                type: "success",
                message: "添加成功",
              });
            });
          } else {
            return false;
          }
        });
      } else if (index == "five") {
        this.$refs["form5"].validate((valid) => {
          if (valid) {
            param.OFFICIAL_APP_ID = this.form.OFFICIAL_APP_ID;
            param.OFFICIAL_APP_SECRET = this.form.OFFICIAL_APP_SECRET;
            param.OFFICIAL_MSG_PUSH_TEMPLATE_REAL_NAME =
              this.form.OFFICIAL_MSG_PUSH_TEMPLATE_REAL_NAME;
           param.XIAOLU_APP_ID = this.form.XIAOLU_APP_ID;
            param.XIAOLU_APP_SECRET = this.form.XIAOLU_APP_SECRET;
            param.XIAOLU_MSG_PUSH_TEMPLATE_WARING =
              this.form.XIAOLU_MSG_PUSH_TEMPLATE_WARING;

            param.MERCHANT_ID = this.form.MERCHANT_ID;
            param.MERCHANT_KEY = this.form.MERCHANT_KEY;
            // param.MERCHANT_CERT_PATH = this.form.MERCHANT_CERT_PATH
            param.MERCHANT_CERT_LOCAL_PATH = this.form.MERCHANT_CERT_LOCAL_PATH;
            param.MERCHANT_KEY_V3 = this.form.MERCHANT_KEY_V3;
            Object.assign(param, this.params);
            setConfigurationModify(param).then((res) => {
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.$refs["fileInput"].value = "";
            });
          } else {
            return false;
          }
        });
      } else if (index == "seven") {
        this.$refs["form7"].validate((valid) => {
          if (valid) {
            if (
              this.form.CARD_AHEAD_STOP_DAYS % 1 === 0 &&
              this.form.CARD_AHEAD_STOP_DAYS >= 0 &&
              this.form.CARD_AHEAD_STOP_DAYS <= 5
            ) {
              param.CARD_AHEAD_STOP_DAYS = this.form.CARD_AHEAD_STOP_DAYS;
              Object.assign(param, this.params);
              setConfigurationModify(param).then((res) => {
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
              });
            } else {
              this.$alert("提前停机天数只能输入0-5的整数", "消息提示", {
                confirmButtonText: "确定",
              });
              return;
            }
          } else {
            return false;
          }
        });
      } else if (index == "eight") {
        this.$refs["form8"].validate((valid) => {
          if (valid) {
            param.AWS_S3_ENDPOINT = this.form.AWS_S3_ENDPOINT;
            param.AWS_S3_ACCESS_KEY = this.form.AWS_S3_ACCESS_KEY;
            param.AWS_S3_SECRET_KEY = this.form.AWS_S3_SECRET_KEY;
            param.AWS_S3_OPEN = this.form.AWS_S3_OPEN;
            Object.assign(param, this.params);
            setConfigurationModify(param).then((res) => {
              this.$message({
                type: "success",
                message: "添加成功",
              });
            });
          } else {
            return false;
          }
        });
      }else if (index == "nine") {
        this.$refs["form9"].validate((valid) => {
          if (valid) {
            param.TELECOM_S3_ENDPOINT = this.form.TELECOM_S3_ENDPOINT;
            param.TELECOM_S3_ACCESS_KEY = this.form.TELECOM_S3_ACCESS_KEY;
            param.TELECOM_S3_SECRET_KEY = this.form.TELECOM_S3_SECRET_KEY;
            param.TELECOM_S3_OPEN = this.form.TELECOM_S3_OPEN;
            Object.assign(param, this.params);
            setConfigurationModify(param).then((res) => {
              this.$message({
                type: "success",
                message: "添加成功",
              });
            });
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>
<style >
.configModification .el-input {
  width: 395px;
}
.configModification .title {
  font-weight: bold;
}
</style>