<template>
  <div class="addPool">
    <el-form :model="addForm" ref="addForm" :rules="rules" >
      <el-form-item
        label="流量池名称"
        :label-width="formLabelWidth"
        prop="name"
        required
      >
        <el-input v-model="addForm.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        label="统计模式："
        :label-width="formLabelWidth"
        prop="statMode"
        required
      >
        <el-select v-model="addForm.statMode">
          <el-option value="">请选择统计模式</el-option>
          <el-option label="组池" value="1"></el-option>
          <el-option label="单卡" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="付费模式："
        :label-width="formLabelWidth"
        prop="deductMode"
        required
      >
        <el-select v-model="addForm.deductMode">
          <el-option value="">请选择付费模式</el-option>
          <el-option label="预付" value="1"></el-option>
          <el-option label="后付" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="运营商："
        :label-width="formLabelWidth"
        required
        prop="accountId"
      >
        <el-select
          v-model="addForm.accountId"
          filterable
          placeholder="请选择运营商"
          clearable
        >
          <el-option value="">请选择运营商</el-option>
          <el-option
            v-for="(item, index) in dialogAccountLists"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="代理商:"
        :label-width="formLabelWidth"
        required
        prop="agentId"
      >
        <el-select
          v-model="addForm.agentId"
          filterable
          placeholder="请选择代理商"
          clearable
        >
          <el-option
            v-for="(item, index) in dialogAgentLists"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入池扣费规则：" :label-width="formLabelWidth">
        <el-select v-model="addForm.joinRule" filterable clearable>
          <el-option value="">请选择入池扣费规则</el-option>
          <el-option
            v-for="item in xianxingArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div style="color:#f00;">入池扣费规则只能选择线性扣费规则</div>
      </el-form-item>
      <el-form-item
        label="超量扣费规则："
        :label-width="formLabelWidth"
        required
        prop="overFlowRule"
      >
        <el-select v-model="addForm.overFlowRule" filterable clearable>
          <el-option value="">请选择超量扣费规则</el-option>
          <el-option
            v-for="item in dialogRuleLists"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
        <el-form-item
        label="停机保号费："
        :label-width="formLabelWidth"
        prop="keepNumFee"
      >
        <el-input style="width:40%" v-model="addForm.keepNumFee" step="0.1" max="100"  type="number" min="0" placeholder="请输入停机保号费"></el-input> 元
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth" >
        <el-input v-model="addForm.remark" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button
        type="primary"
        @click="uploadExcel('addForm')"
        class="themed-button"
        >提交</el-button
      >
      <el-button type="info" @click="cancelDialog()" class="themed-button"
        >取 消</el-button
      >
    </div>
  </div>
</template>
<script>
import { messageBox } from "@/utils/common.js";
import { addCardPool } from "@/api/trafficPoolManagement.js";
import loading from "@/utils/Loading.js";
export default {
  name: "addPool",
  props: ["dialogAccountLists", "dialogAgentLists", "dialogRuleLists"],
  data() {
    return {
      addForm: {
        agentId: "",
        accountId: "",
        name: "",
        statMode: "",
        deductMode: "",
        joinRule: "",
        overFlowRule: "",
        keepNumFee:"",
        remark: "",
      },
      formLabelWidth: "150px",
      rules: {
         name: [{ required: true, message: "请输入流量池名称", trigger: "blur" }],
        agentId: [{ required: true, message: "请选择代理商", trigger: "change" }],
        accountId: [{ required: true, message: "请选择运营商", trigger: "change" }],
        statMode: [{ required: true, message: "请选择统计模式", trigger: "change" }],
        deductMode: [{ required: true, message: "请选择付费模式", trigger: "change" }],
        overFlowRule: [{ required: true, message: "请选择超量扣费规则", trigger: "change" }],
      },
      xianxingArr:this.dialogRuleLists,
    };
  },
 watch:{
			dialogRuleLists(value){
				//console.log(value)
			  this.xianxingArr = value.filter(element => element.type == 1)
        console.log(this.xianxingArr)
			}
		},
  mounted() {
   
  },
  methods: {
    cancelDialog() {
      this.$emit("close");
    },
    uploadExcel(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(parseFloat(this.addForm.keepNumFee)<0 ){
                 messageBox(this, "停机保号费需要大于0");
           return 
          }
          loading.show();
          addCardPool(this.addForm).then((responseResult) => {
            if (responseResult.slide_msg_key == 1) {
              this.$alert(responseResult.slide_msg_message_key, "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            // this.$emit("refresh")
            this.$emit("close");
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
