<template>
    <div class="history-package-allocate fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">套餐分配记录</span>
            <el-form inline ref="packageAllocateForm" v-model="packageAllocateData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
                    <el-input v-model="packageAllocateData.agentName" placeholder="分销商"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="packageAllocateData.setMealName" placeholder="套餐名称"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="agentName"
                    label="分销商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="setMealName"
                    label="套餐名"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="typeStr"
                    label="套餐类型"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    label="所属类别"
                    min-width="100"
                    >
                    <template slot-scope = "scope">
                        <span>{{categoryString(scope.row.category)}}</span>
                        <!-- <span v-if="scope.row.category==1">电信</span>
                        <span v-else-if="scope.row.category==2">其他卡务</span>
                        <span v-else-if="scope.row.category==3">移动</span>
                        <span v-else-if="scope.row.category==4">联通</span>
                        <span v-else-if="scope.row.category==5">DCP电信</span>
                        <span v-else-if="scope.row.category==6">联通Q</span>
                        <span v-else-if="scope.row.category==7">新移动</span>
                        <span v-else-if="scope.row.category==8">新联通</span> -->
                    </template>
                </el-table-column>
                <el-table-column
                    prop="accountMoney"
                    label="套餐对账金额"
                    min-width="120"
                    >  
                </el-table-column>
                <el-table-column
                    prop="money"
                    label="套餐销售价"
                    min-width="100"
                    >  
                </el-table-column>
                <el-table-column
                    prop="originalMoney"
                    label="套餐分销金额"
                    min-width="120"
                    >  
                </el-table-column>
                <el-table-column
                    prop="interestRate"
                    label="分销比例"
                    min-width="80"
                    >  
                </el-table-column>
                <el-table-column
                    label="分配状态"
                    min-width="80"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">已分配</span>
                        <span v-else-if="scope.row.status==2">已取消</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="分配时间"
                    min-width="150"
                    >  
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="operatorName"
                    label="操作人"
                    min-width="120"
                    >  
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import { allocatePackage } from "@/api/history.js";
import store from '@/store'
export default {
    name:'packageAllocation',
    mixins:[resize],
    data(){
        return {
            packageAllocateData:{
                agentName:'',
                setMealName:''
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,

        }
    },
    created(){
           if(store.getters.categorys.length<1){
                store.dispatch('getCategory')
        }
        this.getAllocatePackageList()
    },
    methods:{
        categoryString(category){
            let cuCatagorys
            if(store.getters.categorys.length>0){
               store.getters.categorys.forEach(item=>{
                   if(category==item.value){
                     cuCatagorys = item.name
                   }
               })
            }
            return cuCatagorys
        },
        getAllocatePackageList(){
            let params={
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.packageAllocateData,params)
            allocatePackage(this.packageAllocateData).then(res=>{
                this.tableData= res.rows
                this.total = res.count
            })
        },
        onSearch(){
            this.page=1
            this.getAllocatePackageList()
        },
        exportExcel(){

        },
        handleSizeChange(size){
            this.pageSize=size
            this.getAllocatePackageList()
        },
        onPageChange(page){
            this.page=page
            this.getAllocatePackageList()
        },
    }
}
</script>