<template>
  <div class="agent fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">代理商</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="代理商名称:">
          <el-input
            v-model="formInline.pageSearchKeys"
            placeholder="代理商名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="addTOC"
            icon="el-icon-plus"
            class="themed-button"
            >新增代理商</el-button
          >
        </el-form-item>
          <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
        <!-- <el-form-item>
					<el-button type="primary" @click="addTOC" :style="{display:tocDisplay}" icon="el-icon-plus"  class="themed-button">新增TOC代理商</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addTOB" :style="{display:tobDisplay}" icon="el-icon-plus"  class="themed-button">新增TOB代理商</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addPre" :style="{display:preDisplay}" icon="el-icon-plus"  class="themed-button">新增预存类代理商</el-button>
				</el-form-item> -->
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="代理商名称"> </el-table-column>
        <el-table-column prop="createDatetime" label="创建时间">
        </el-table-column>
        <el-table-column prop="createUser" label="添加人"> </el-table-column>
        <el-table-column prop="checkPhone" label="手机号"> 
           <template slot-scope="scope">
          <span>{{ showPhone(scope.row.checkPhone) }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="interestRate" label="返点">
				</el-table-column> -->
        <el-table-column prop="bank" label="提现银行"> </el-table-column>
        <el-table-column prop="bankCode" label="提现账号"> </el-table-column>
        <el-table-column prop="bankReceiver" label="收款人"> </el-table-column>
        <el-table-column label="代理商类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == '1'">TOC</span>
            <span v-else-if="scope.row.type == '2'">TOB</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column prop="zhifubao" label="支付宝账号"> </el-table-column>
        <el-table-column prop="zhifubaoReceiver" label="支付宝收款人">
        </el-table-column>
        <el-table-column label="账户类型">
          <template slot-scope="scope">
            <span v-if="scope.row.agentType == '1'">普通代理商</span>
            <span v-else-if="scope.row.agentType == '2'">VIP代理商</span>
          </template>
        </el-table-column>
        <el-table-column label="结算方式">
          <template slot-scope="scope">
            <span v-if="scope.row.settlType == '1'">套餐结算</span>
            <span v-else-if="scope.row.settlType == '2'">按量结算</span>
          </template>
        </el-table-column>

        <el-table-column label="收款方式">
          <template slot-scope="scope">
            <span v-if="scope.row.collectType == '1'">平台收款</span>
            <span v-else-if="scope.row.collectType == '2'">代理商收款</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>

        <el-table-column label="操作" width="320px">
          <!-- 	<template slot-scope="scope"> -->
          <template v-slot:default="scope">
            <!-- 	<span @click='updateAgentRecord(scope.row.id)' class="right_agent">编辑 </span>
						<span @click='adminUpdatePassword(scope.row.id)' class="right_agent">账号管理</span>
						<span @click='deleteAgentRecord(scope.row.id)'>删除</span> -->
            <el-button
              type="info"
              class="themed-button"
              @click="updateAgentRecord(scope.row.id)"
              >编辑</el-button
            >
            <el-dropdown
              class="themed-button"
              size="mini"
              trigger="click"
              @command="handleCommand"
            >
              <el-button
                class="themed-button"
                type="primary"
                @click="setTempRow(scope.row)"
              >
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="5" v-if="currentRole == 0"
                  >充值链接</el-dropdown-item
                >
                <el-dropdown-item
                  command="1"
                  v-if="currentRole == 0 && scope.row.type == 2"
                  >更改账号</el-dropdown-item
                >
                <el-dropdown-item command="2" v-if="scope.row.agentThreeName!=curAgentThreeName"
                  >修改手机</el-dropdown-item
                >
                <el-dropdown-item command="6" v-if="scope.row.agentType==2&&scope.row.fatherAgentId=='0'" >设置提前停机</el-dropdown-item>
                <el-dropdown-item command="3">账号管理</el-dropdown-item>
                <el-dropdown-item command="4">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="修改代理商"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item
          label="代理商名："
          :label-width="formLabelWidth"
          prop="name"
        >
          <el-input
            v-model="form.name"
            autocomplete="off"
            readonly
            :disabled="currentRole != 0"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="登录号码："
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input
            v-model="form.phone"
            autocomplete="off"
            :disabled="currentRole != 0"
          ></el-input>
        </el-form-item>
        <el-form-item label="提现银行（选填）：" :label-width="formLabelWidth">
          <el-input
            v-model="form.bank"
            autocomplete="off"
            :disabled="currentRole != 0"
          ></el-input>
        </el-form-item>
        <el-form-item label="提现账号（选填）：" :label-width="formLabelWidth">
          <el-input
            v-model="form.bankCode"
            autocomplete="off"
            :disabled="currentRole != 0"
          ></el-input>
        </el-form-item>
        <el-form-item label="收款人（选填）：" :label-width="formLabelWidth">
          <el-input
            v-model="form.bankReceiver"
            autocomplete="off"
            :disabled="currentRole != 0"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付宝账户:" :label-width="formLabelWidth">
          <el-input
            v-model="form.zhifubao"
            autocomplete="off"
            :disabled="currentRole != 0"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付宝收款人:" :label-width="formLabelWidth">
          <el-input
            v-model="form.zhifubaoReceiver"
            autocomplete="off"
            :disabled="currentRole != 0"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="返点比例：" :label-width="formLabelWidth" prop="interestRate">
					<el-input v-model="form.interestRate" autocomplete="off" type="number" min="0.0" step="0.1" max="1"></el-input>
				</el-form-item> -->
        <el-form-item
          label="代理商类型："
          :label-width="formLabelWidth"
          v-if="currentRole == 0"
        >
          <el-select v-model="form.agentType" @change="typeChangeOne">
            <el-option label="普通类型" value="1"></el-option>
            <el-option label="VIP代理商" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="结算方式："
          :label-width="formLabelWidth"
          v-if="currentRole == 0 && isshowAgentType"
        >
          <el-select v-model="form.settlType">
            <el-option label="套餐结算" value="1"></el-option>
            <el-option label="按量结算" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="收款方式："
          :label-width="formLabelWidth"
          v-if="currentRole == 0 && isshowAgentType"
        >
          <el-select v-model="form.collectType">
            <el-option
              label="平台收款"
              value="1"
              v-if="form.settlType == 1"
            ></el-option>
            <el-option label="代理商收款" value="2"></el-option>
          </el-select>
          <p v-if="form.collectType == '1'" style="color: #f00">
            需要绑定平台商户号。在已充值套餐未过期前，代理商商户号不能从系统中删除，如果删除了会影响退款。
          </p>
          <p v-if="form.collectType == '2'" style="color: #f00">
            需要绑定代理商商户号
          </p>
        </el-form-item>
        <el-form-item
          label="超额流量单价(元/G)："
          :label-width="formLabelWidth"
          v-show="ispreAgent"
        >
          <el-input v-model.trim="form.flowPrice" autocomplete="off"></el-input>
          <i
            class="el-icon-question"
            style="
              color: blue;
              font-size: 18px;
              vertical-align: middle;
              margin-left: 10px;
            "
            title="当配置超额流量单价，套餐流量用完后按单价扣余额，直至余额扣完停机。若不使用扣余额，留空。"
          ></i>
        </el-form-item>
        <el-form-item
          label="套餐分类方式："
          :label-width="formLabelWidth"
          v-if="currentRole == 0"
        >
          <el-select v-model="form.mealClassifyType">
            <el-option label="套餐类型" value="1"></el-option>
            <el-option label="主套餐和加油包" value="2"></el-option>
            <el-option label="天卡周期" value="3"></el-option>
            <el-option label="新客特惠" value="4"></el-option>
          </el-select>
          <div style="color:#f00">！选中后自定义套餐会失效，请慎重选择。</div>
        </el-form-item>
        <el-form-item
          label="套餐显示样式："
          :label-width="formLabelWidth"
          v-if="currentRole == 0"
        >
          <el-select v-model="form.mealShowStyle">
            <el-option label="通用" value="1"></el-option>
            <el-option label="标题-金额-每月-总量" value="2"></el-option>
            <el-option label="标题-总量-售价" value="3"></el-option>
          </el-select>
          <div style="color:#f00">！选中后自定义套餐会失效，请慎重选择。</div>
        </el-form-item>
        <el-form-item
          label="返利方式："
          :label-width="formLabelWidth"
          prop="rebateType"
        >
          <el-select v-model="form.rebateType" @change="rebateTypeChange">
            <el-option
              v-for="item in accountArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div v-if="rebateTypeTip" style="color: #f00">
            <div v-if="rebateTypeShow">未设置返利方式将会影响后期提现。</div>
            <div v-else>
              当前账号下没有配置账号提现限制，如需配置，请打开菜单
              “代理商管理》返利设置”中进行配置。
            </div>
          </div>
        </el-form-item>
          <el-form-item label="邮箱：" :label-width="formLabelWidth">
          <el-input
            v-model="form.email"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" :label-width="formLabelWidth">
          <el-input
            v-model="form.remark"
            autocomplete="off"
            :disabled="remarkIsEdit"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="baseFormSubmit('ruleForm')"
          class="themed-button"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="baseFormReset('ruleForm')"
          class="themed-button"
          >重置</el-button
        >
        <el-button
          type="info"
          @click="cancelDialog('ruleForm')"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="新增代理商"
      :visible.sync="dialogAddTOCVisible"
      v-if="dialogAddTOCVisible"
    >
      <add-agent @close="dialogAddTOCVisible = false" @refresh="refreshData">
      </add-agent>
    </el-dialog>
    <el-dialog
      title="修改手机"
      :visible.sync="dialogmodifyPhoneVisible"
      v-if="dialogmodifyPhoneVisible"
    >
      <el-form :model="modifyPhoneform">
        <el-form-item
          label="手机号码："
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input
            v-model="modifyPhoneform.checkPhone"
            autocomplete="off"
            @input="isPhoneChange = false"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="modifyPhoneformSubmit"
          class="themed-button"
          :disabled="isPhoneChange"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="modifyPhoneformReset"
          class="themed-button"
          >重置</el-button
        >
        <el-button
          type="info"
          @click="dialogmodifyPhoneVisible = false"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="修改账户"
      :visible.sync="dialogmodifyAccount"
      v-if="dialogmodifyAccount"
    >
      <el-form :model="modifyAccountForm">
        <el-form-item label="老账号（手机号）：" :label-width="formLabelWidth">
          <el-input
            v-model="modifyAccountForm.phoneOne"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新账号（手机号）："
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input
            v-model="modifyAccountForm.phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="modifyAccountFormSubmit"
          class="themed-button"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="modifyAccountFormReset"
          class="themed-button"
          >重置</el-button
        >
        <el-button
          type="info"
          @click="dialogmodifyAccount = false"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>
 <el-dialog
      title="设置提前停机"
      :visible.sync="dialogStopAhead"
      v-if="dialogStopAhead"
    >
      <el-form :model="stopAhead">
        <el-form-item
          label="提前停机天数："
          :label-width="formLabelWidth"
          prop="stopAheadDays"
        >
         <el-input-number v-model="stopAhead.stopAheadDays" :step="1" step-strictly :precision="0"   :min="0"  ></el-input-number>
          <!-- <el-input
            v-model="stopAhead.stopAheadDays"
            autocomplete="off"
            clearable
          ></el-input> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="modifyStopAhead"
          class="themed-button"
          >确 定</el-button
        >
        <el-button
          type="info"
          @click="dialogStopAhead = false"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>
   
  </div>
</template>

<script>
import addAgent from "./addTocAgent.vue";
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import { messageBox } from "@/utils/common.js";
import {
  agentList,
  getAgentType,
  deleteAgent1,
  editAgent1,
  submitAgentChange,
  addTocAgentSubmit,
  addTobAgentSubmit,
  addPreAgentSubmit,
  updagePhone,
  modifyAccount,
  getRechargeUrl,
  updateStopAheadDays,
  exportExcelAsync
} from "@/api/agentManagement.js";
export default {
  name: "agent",
  mixins: [onresize],
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请输入初始密码！"));
      } else {
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请输入代理商名！"));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请输入联系号码！"));
      } else {
        callback();
      }
    };
    var validateRebateType = (rule, value, callback) => {
      if (!value || "" == value.trim()) {
        return callback(new Error("请选择返利方式！"));
      } else {
        callback();
      }
    };
    /* var validateInterestRate = (rule, value, callback) => {
				if (value === "") {
					return callback(new Error("请输入返点比例！"))
				}
				var interestRate = parseFloat(value);
				if (isNaN(interestRate) || interestRate < 0 || interestRate > 1) {
					return callback(new Error("请输入正确的返点比例，0到1之间"))
				}
				callback()
			} */
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        ids: "",
        id: "",
        status: "",
        pageSearchKeys: "",
      },
      tocDisplay: "",
      tobDisplay: "",
      preDisplay: "",
      tabledata: [],
      rules: {
        name: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        rebateType: [
          {
            required: true,
            validator: validateRebateType,
            trigger: "change",
          },
        ],

        /* interestRate: [{
						required: true,
						validator: validateInterestRate,
						trigger: 'blur'
					}, ] */
      },
      dialogFormVisible: false,
      form: {
        id: "",
        fatherAgentId: "",
        createUserid: "",
        createUser: "",
        createDatetime: "",
        balanceMoney: "",
        totalMoney: "",
        area: "",
        agentThreeId: "",
        agentThreeName: "",
        name: "",
        phone: "",
        bank: "",
        bankCode: "",
        bankReceiver: "",
        // interestRate: "",
        agentType: "",
        settlType: "",
        collectType: "",
        flowPrice: "",
        remark: "",
        mealClassifyType: "",
        mealShowStyle: "",
        rebateType: "",
        zhifubao: "",
        zhifubaoReceiver: "",
        email:"",
      },
      formLabelWidth: "200px",
      ispreAgent: false,
      dialogmodifyPhoneVisible: false,
      modifyPhoneform: {
        id: "",
        checkPhone: "",
      },
      resetData: {
        id: "",
        checkPhone: "",
      },
      dialogAddTOCVisible: false,
      isPhoneChange: false,
      isshowAgentType: false,
      accountArr: [
        { id: "0", name: "-未设置-" },
        { id: "1", name: "普通" },
        { id: "2", name: "秒返" },
      ],
      rebateTypeShow: false,
      rebateTypeTip: false,
      isPhoneChange: false,
      remarkIsEdit: false,
      dialogmodifyAccount: false,
      modifyAccountForm: {
        phone: "",
        agentId: "",
        phoneOne: "",
      },
      tempRow: {},
      curAgentThreeName:"",
      dialogStopAhead:false,
      stopAhead:{
        stopAheadDays:"",
        }
    };
  },
  created() {
    this.getAgentList(this.formInline);
    getAgentType(this.formInline).then((responseResult) => {
      //  console.log(responseResult)
      this.curAgentThreeName = responseResult.agentThreeName
      if (responseResult.agentType == "1") {
        this.preDisplay = "none";
        this.tobDisplay = "none";
      }
      if (responseResult.agentType == "4") {
        this.tocDisplay = "none";
        this.tobDisplay = "none";
      }

    });
  },
  components: {
    addAgent,
  },
  computed: {
    ...mapGetters(["currentRole", "currentAgentId"]),
  },
  methods: {
    exportExcel(){
            exportExcelAsync(this.formInline).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
    modifyStopAhead(){
        let params = {
          id:this.tempRow.id,
          stopAheadDays:this.stopAhead.stopAheadDays
        }
     updateStopAheadDays(params).then(res=>{
        console.log(res)
        if (res.system_result_key == "0") {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          this.dialogStopAhead = false;
          this.getAgentList(this.formInline);
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
     })
    },
    showPhone(phone){
      let showphone
     if (phone) {
        showphone = "*******" + phone.toString().substr(7);
      }
      return showphone
    },
    setTempRow(row) {
      this.tempRow = row;
    },
    handleCommand(type) {
		let that = this
      if (type == 1) {
        this.editAccount(this.tempRow.id, this.tempRow.phone);
      } else if (type == 2) {
        this.modifyPhone(this.tempRow.id, this.tempRow.checkPhone);
      } else if (type == 3) {
        this.adminUpdatePassword(this.tempRow.id);
      } else if (type == 4) {
        this.deleteAgentRecord(this.tempRow.id);
      } else if (type == 5) {
        getRechargeUrl({ id: this.tempRow.id }).then((res) => {
          // messageBox(this, res, "公众号充值链接")
          this.$confirm(res, "公众号充值链接", {
            confirmButtonText: "确定",
            cancelButtonText: "复制",
          })
            .then(() => {})
            .catch(() => {
              this.$copyText(res).then((result) => {
                that.$notify({
                  title: "复制链接成功",
                  type: "success",
                });
              });
            });
        });
      }else if(type == 6){
        //设置提前停机
        this.dialogStopAhead = true
        this.stopAhead.stopAheadDays = this.tempRow.stopAheadDays?this.tempRow.stopAheadDays:0
      }
    },
    rebateTypeChange(val) {
      if (val == "") {
        this.rebateTypeTip = false;
      } else {
        this.rebateTypeTip = true;
      }
      if (val == "0") {
        this.rebateTypeShow = true;
      } else {
        this.rebateTypeShow = false;
      }
    },
    typeChangeOne(value) {
      if (value == "1") {
        this.isshowAgentType = false;
      } else if (value == "2") {
        this.isshowAgentType = true;
      }
    },
    refreshData() {
      console.log("427");
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.formInline);
      this.getAgentList(params);
    },
    handleClose(done) {
      this.$refs["ruleForm"].resetFields();
      done();
    },
    getAgentList(params) {
      agentList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.formInline);
      this.getAgentList(params);
    },
    onPageChange(page) {
      this.page = page;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.formInline);
      this.getAgentList(params);
    },
    searchData() {
      this.getAgentList(this.formInline);
    },
    addTOC() {
      this.dialogAddTOCVisible = true;
    },
    /* addTOB() {
				this.dialogAddTOBVisible = true
			},
			addPre() {
				this.dialogAddPreVisible = true
			}, */
    modifyPhone(id, phone) {
      this.dialogmodifyPhoneVisible = true;
      this.modifyPhoneform.id = id;
      this.isPhoneChange = true;
      if (phone) {
        phone = "*******" + phone.toString().substr(7);
      }
      this.modifyPhoneform.checkPhone = phone;
      this.resetData.id = id;
      this.resetData.checkPhone = phone;
    },
    modifyPhoneformReset() {
      this.modifyPhoneform.id = this.resetData.id;
      this.modifyPhoneform.checkPhone = this.resetData.checkPhone;
      this.isPhoneChange = true;
    },
    modifyPhoneformSubmit() {
      if (!this.modifyPhoneform.checkPhone) {
        messageBox(this, "请输入手机号");
        return;
      }
      if (this.modifyPhoneform.checkPhone) {
        let r = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!r.test(this.modifyPhoneform.checkPhone)) {
          messageBox(this, "手机号格式不对");
          return;
        }
      }
      updagePhone(this.modifyPhoneform).then((res) => {
        if (res.system_result_key == "0") {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          this.dialogmodifyPhoneVisible = false;
          this.getAgentList(this.formInline);
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    updateAgentRecord(id) {
      this.dialogFormVisible = true;
      this.form.id = id;
      if (id == this.currentAgentId) {
        this.remarkIsEdit = true;
      } else {
        this.remarkIsEdit = false;
      }
      editAgent1({ id }).then((res) => {
        //this.form = res.entity
        this.form.fatherAgentId = res.entity.fatherAgentId;
        this.form.createUserid = res.entity.createUserid;
        this.form.createUser = res.entity.createUser;
        this.form.createDatetime = res.entity.createDatetime;
        this.form.balanceMoney = res.entity.balanceMoney;
        this.form.totalMoney = res.entity.totalMoney;
        this.form.area = res.entity.area;
        this.form.agentThreeId = res.entity.agentThreeId;
        this.form.agentThreeName = res.entity.agentThreeName;
        this.form.name = res.entity.name;
        this.form.phone = res.entity.phone;
        this.form.bank = res.entity.bank;
        this.form.bankCode = res.entity.bankCode;
        this.form.bankReceiver = res.entity.bankReceiver;
        this.form.interestRate = res.entity.interestRate;
        this.form.agentType = res.entity.agentType
          ? res.entity.agentType + ""
          : "";
        this.form.zhifubao = res.entity.zhifubao;
        this.form.zhifubaoReceiver = res.entity.zhifubaoReceiver;
        if (this.form.agentType == "1") {
          this.isshowAgentType = false;
        } else if (this.form.agentType == "2") {
          this.isshowAgentType = true;
          this.form.settlType = res.entity.settlType
            ? res.entity.settlType + ""
            : "";
          this.form.collectType = res.entity.collectType
            ? res.entity.collectType + ""
            : "";
        }
        this.form.rebateType = res.entity.rebateType
          ? res.entity.rebateType + ""
          : "";
        if (this.form.rebateType == "") {
          this.rebateTypeTip = false;
        } else {
          this.rebateTypeTip = true;
        }
        if (this.form.rebateType == "0") {
          this.rebateTypeShow = true;
        } else {
          this.rebateTypeShow = false;
        }
        this.form.flowPrice = res.entity.flowPrice;
        this.form.remark = res.entity.remark;
        this.form.mealClassifyType = res.entity.mealClassifyType;
        this.form.mealShowStyle = res.entity.mealShowStyle;
        this.form.email = res.entity.email;

        if (res.entity.type == "3") {
          this.ispreAgent = true;
        } else {
          this.ispreAgent = false;
        }
      });
    },

    baseFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          if(this.form.email){
         let r = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
           
                if(!r.test(this.form.email)){
                    messageBox(this,'请输入正确的邮箱格式')
                    return 
                }
          }
          if(this.form.agentType ==1 ){
             this.form.collectType = "1"
          }
          this.dialogFormVisible = false;
          submitAgentChange(this.form).then((responseResult) => {
            if (responseResult.slide_msg_key == 1) {
              this.$message({
                showClose: true,
                message: responseResult.slide_msg_message_key,
                type: "warning",
              });
            }
            let params = {
              pageSize: this.pageSize,
              pageNo: this.page,
            };
            Object.assign(params, this.formInline);
            this.getAgentList(params);
          });
        } else {
          return false;
        }
      });
    },
    baseFormReset(formName) {
      editAgent1(this.form).then((res) => {
        //this.form = res.entity
        this.form.fatherAgentId = res.entity.fatherAgentId;
        this.form.createUserid = res.entity.createUserid;
        this.form.createUser = res.entity.createUser;
        this.form.createDatetime = res.entity.createDatetime;
        this.form.balanceMoney = res.entity.balanceMoney;
        this.form.totalMoney = res.entity.totalMoney;
        this.form.area = res.entity.area;
        this.form.agentThreeId = res.entity.agentThreeId;
        this.form.agentThreeName = res.entity.agentThreeName;
        this.form.name = res.entity.name;
        this.form.phone = res.entity.phone;
        this.form.bank = res.entity.bank;
        this.form.bankCode = res.entity.bankCode;
        this.form.bankReceiver = res.entity.bankReceiver;
        this.form.flowPrice = res.entity.flowPrice;
        this.form.remark = res.entity.remark;
        this.form.mealClassifyType = res.entity.mealClassifyType;
        this.form.mealShowStyle = res.entity.mealShowStyle;
        this.form.rebateType = res.entity.rebateType
          ? res.entity.rebateType + ""
          : "";
        this.form.zhifubao = res.entity.zhifubao;
        this.form.zhifubaoReceiver = res.entity.zhifubaoReceiver;
        //this.form.interestRate = res.entity.interestRate
        this.form.email = res.entity.email;
      });
      this.$refs[formName].clearValidate(["name", "phone", "interestRate"]);
    },
    cancelDialog(formName) {
      this.dialogFormVisible = false;
      this.$refs[formName].clearValidate(["name", "phone", "interestRate"]);
    },

    adminUpdatePassword(id) {
      let selections = [
        {
          id: "",
          status: "",
          type: 1,
          agentId: id,
          realname: "",
        },
      ];
      this.$router.replace({
        path: "/agentManagement/accountManagement",
        query: selections[0],
      });
    },
    editAccount(id, mobile) {
      this.dialogmodifyAccount = true;
      this.modifyAccountForm.agentId = id;
      this.modifyAccountForm.phoneOne = mobile;
    },
    modifyAccountFormSubmit() {
      if (!this.modifyAccountForm.phone) {
        messageBox(this, "请输入手机号");
        return;
      }
      if (this.modifyAccountForm.phone) {
        let r = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!r.test(this.modifyAccountForm.phone)) {
          messageBox(this, "手机号格式不对");
          return;
        }
      }
      modifyAccount(this.modifyAccountForm).then((res) => {
        if (res.system_result_key == "0") {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          this.dialogmodifyAccount = false;
          this.getAgentList(this.formInline);
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    modifyAccountFormReset() {
      this.modifyAccountForm.phone = "";
    },
    deleteAgentRecord(id) {
      this.$confirm("确定要删除吗？", "消息提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            deleteId: id,
          };
          let params1 = {
            ids: "",
            id: "",
            status: "",
            pageSearchKeys: "",
          };
          Object.assign(params, params1);
          deleteAgent1(params).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getAgentList(params1);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
.agent .el-form-item__label {
  vertical-align: top;
}
.agent .el-input--small {
  width: 80%;
}

/* .el-input {
		width: 297px;
	} */
</style>
