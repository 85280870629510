/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function accountList(params){
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data:params
    })
}
export function noloopcard(params){
    return request({
        url: 'card/noloopcard/list.do?',
        method: 'post',
        data:params
    })
}

export function deleteById_(params){
    return request({
        url: 'card/noloopcard/deleteById.do?',
        method: 'post',
        data:params
    })
}
export function importExcel(params){
    return request({
        url: 'card/noloopcard/importExcelSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function exportExcelAsync(params) {
    return request({
        url: "/card/noloopcard/exportExcelAsync.do?",
        method: 'post',
        data: params
    })
}

// /card/noloopcard/exportExcelAsync