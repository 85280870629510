<template>
    <div class="history-card-record fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">窜卡记录</span>
            <el-form inline ref="cardRecordForm" v-model="cardRecordData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
                    <el-input v-model="cardRecordData.iccidMark" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                 <el-form-item>
                    <el-input v-model="cardRecordData.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="cardRecordData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item label="开卡公司">
                    <search-select  :optionsMetaAll="optionsCardCompany" v-model="cardRecordData.accountId"></search-select>
                </el-form-item>
                <el-form-item label="状态">
                    <search-select  :optionsMetaAll="optionsCardStatus" v-model="cardRecordData.status"></search-select>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="shortIccid"
                    label="短号"
                    min-width="140"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号"
                    min-width="140"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="开卡公司"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="opTypeStr"
                    label="操作类型"
                    min-width="100"
                    >
                </el-table-column>
                <el-table-column
                    prop="opSourceStr"
                    label="操作来源"
                    min-width="100"
                    >  
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="卡状态"
                    min-width="100"
                    > 
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">待激活</span>
                        <span v-else-if="scope.row.status==2">正常</span>
                        <span v-else-if="scope.row.status==3">断网</span>
                        <span v-else-if="scope.row.status==4">停机</span>
                        <span v-else-if="scope.row.status==5">异常</span>
                    </template> 
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="agentTreeName"
                    label="层级关系"
                    min-width="120"
                    >  
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    min-width="120"
                    >  
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createDate"
                    label="操作时间"
                    min-width="150"
                    >  
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import { cardRecording,exportCardRecord} from '@/api/history.js'
import {cardCompanyList} from '@/api/order.js'
import loading from '@/utils/Loading.js'
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
export default {
    name:'cardRecording',
     components:{
        SearchSelect
    },
    mixins:[resize],
    data(){
        return {
            cardRecordData:{
                iccidMark:'',
                phone:'',
                accountId:'',
                status:'',
                shortIccid:"",
            },
            optionsCardCompany:[{label:"全部",value:""}],
            optionsCardStatus:[
                {label:"全部",value:""},
                {label:"待激活",value:"1"},
                {label:"正常",value:"2"},
                {label:"断网",value:"3"},
                {label:"停机",value:"4"},
                {label:"异常",value:"5"}
                
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,

        }
    },
    created(){
        this.getCardCompanyList()
        this.getCardRecordList()
    },
    methods:{
        getCardCompanyList(){
            cardCompanyList().then(res=>{
                let arrList =[{label:"全部",value:""}]
                 res.forEach(element => {
                     arrList.push({label:element.accountName,value:element.id})
                 })               
                 this.optionsCardCompany=arrList
            })
        },
        getCardRecordList(){
            let params={
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.cardRecordData,params)
            cardRecording(this.cardRecordData).then(res=>{
                this.tableData= res.rows.map(item=>{
                    item.createDate = Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(item.createDate))
                    return item
                })
                this.total=res.count
            })
        },
        handleSizeChange(size){
            this.pageSize=size
           this.getCardRecordList()
        },
        onPageChange(page){
            this.page=page
            this.getCardRecordList()
        },
        onSearch(){
            this.page = 1
            this.getCardRecordList()
        },
        exportExcel(){
            exportCardRecord(this.cardRecordData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
    }
}
</script>

<style>
    .history-card-record .el-form-item__label{
        vertical-align: top;
    }
</style>