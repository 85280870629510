<template>
  <div class="acceleratorPackageList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">加速包列表</span>
       <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form "
       style="line-height: 40px;height:auto;"
      >
      
        <el-form-item label="标题:">
          <el-input
            v-model="formInline.title"
            placeholder="请输入标题"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="addPackage"
            icon="el-icon-plus"
            class="themed-button"
            >新增</el-button
          >
            <el-button
            type="primary"
            @click="switchConfig"
            class="themed-button"
            >加速包开关配置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="id" label="ID" min-width="180">
        </el-table-column>
        <el-table-column prop="title" label="标题" min-width="130">
        </el-table-column>
        <el-table-column prop="price" label="价格" min-width="100">
        </el-table-column>
        <el-table-column prop="content" label="内容" min-width="100">
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status == '0'">下架</span>
            <span v-else-if="scope.row.status == '1'">上架</span>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间" min-width="130">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="130">
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="130">
        </el-table-column>

        <el-table-column label="操作" width="320px" >
          <template v-slot:default="scope">
            <el-button
              type="info"
              class="themed-button"
              @click="editData(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="info"
              class="themed-button"
              @click="deleteRow(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogAddVisible"
      v-if="dialogAddVisible"
    >
      <el-form :model="addForm" :rules="rules" ref="addForm">
       
        <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
          <el-input
            v-model="addForm.title"
            placeholder="请输入标题"
            clearable
            maxlength="5"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            maxlength="15"
            show-word-limit
            v-model="addForm.content"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="价格" :label-width="formLabelWidth" prop="price">
           <el-input
              v-model="addForm.price"
              clearable
              autocomplete="off"
            ></el-input>
        </el-form-item>
         <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
          <el-select
            v-model="addForm.status"
            filterable
            placeholder="请选择加速包状态"
            clearable
          >
            <el-option label="上架" value="1"></el-option>
            <el-option label="下架" value="0"></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input v-model="addForm.remark" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="formAddSubmit('addForm')"
          class="themed-button"
          >确 定</el-button
        >
      
        <el-button
          type="info"
          @click="dialogAddVisible = false"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="开关配置" :visible.sync="dialogFormVisible" v-if="dialogFormVisible">
  <el-form :model="form">
    <!-- 0 关闭 1 开启 -->
    <el-form-item label="加速包开关:" :label-width="formLabelWidth">
       <el-radio-group v-model="form.ACCELERATION_PACK_SWITCH">
    <el-radio :label="0">关闭</el-radio>
    <el-radio :label="1">开启</el-radio>
  </el-radio-group>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="sureConfig"  class="marginLeft">确 定</el-button> 
    <el-button @click="dialogFormVisible = false">关闭</el-button>
  
  </div>
</el-dialog>
  </div>
</template>

<script>
import {
  getConfigurationModify,
  setConfigurationModify,
} from "@/api/systemManagement.js";
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  getAcceleratorPackage,
  delPackageById,
  addAcceleratorPackage,
  updateAcceleratorPackage
} from "@/api/acceleratorPackage.js";

export default {
  name: "getAcceleratorPackageList",
  mixins: [onresize],
  data() {
    var validateFee = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入加速包费用"));
      } else {
        if (!/^\d+(\.\d{0,1})?$/.test(value)) {
          callback(new Error("加速包费用只能为数字最多保留1位小数"));
        }
         if (value>=1000) {
          callback(new Error("加速包费用不能大于等于1000"));
        }
        callback();
      }
    };
    return {
      dialogFormVisible:false,
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        title: null,
      },
      tabledata: [],
      formLabelWidth: "200px",
      dialogAddVisible: false,
      addForm: {
        title:"",
        price:"",
        status:"",
        content:"",
        remark:"",
        id:"",
      },
      rules: {
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
          },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
        content: [{  required: true,
            message: "内容不能为空",
            trigger: "blur", }],
        price: [{ required: true, validator: validateFee, trigger: "blur" }],
      },
      dialogTitle: "",
      form:{
ACCELERATION_PACK_SWITCH:"",
      }
    };
  },
  created() {
    this.getAcceleratorPackageList();
  },
  components: {},
  computed: {
    ...mapGetters(["currentRole"]),
  },
  methods: {
     sureConfig(){
      
     
      let params = {
        group:"ACCELERATION_PACK",
        ACCELERATION_PACK_SWITCH:this.form.ACCELERATION_PACK_SWITCH
      }
      
      console.log(params)
      setConfigurationModify(params).then(res=>{
       console.log(res,465)
        this.$message({
              showClose: true,
              message: "加速包开关配置设置成功",
              type: "success",
              center: true,
            });
       this.dialogFormVisible= false
       
      })  
   
  },
    switchConfig(){
     this.dialogFormVisible = true
    this.getSwitchConfig();
    },
    getSwitchConfig(){
getConfigurationModify({group:"ACCELERATION_PACK"}).then(res=>{
   console.log(res,277) 
    for(let i  of res){
       console.log( i)
       if(i.dictKey =="ACCELERATION_PACK_SWITCH"){
          this.form.ACCELERATION_PACK_SWITCH = parseInt(i.dictValue ) 
          console.log(this.form.ACCELERATION_PACK_SWITCH)
       }
       
    }
  
   
})
    },
    formAddSubmit(formName) {
      this.$refs["addForm"].validate((validate) => {
        if (validate) {
          if( this.dialogTitle == "新增加速包"){
            addAcceleratorPackage(this.addForm).then((res) => {
            this.$message({
              type: "success",
              message: "新增加速包成功",
            });
           
            this.$refs[formName].resetFields();
            this.dialogAddVisible = false;
            this.getAcceleratorPackageList()
          });
          }else{
              
            updateAcceleratorPackage(this.addForm).then((res) => {
            this.$message({
              type: "success",
              message: "编辑加速包成功",
            });
           
            this.$refs[formName].resetFields();
            this.dialogAddVisible = false;
            this.getAcceleratorPackageList()
          });
          
         
        }
        }
      });
    },
    editData(row) {
      //编辑
      this.dialogAddVisible = true;
      this.dialogTitle = "编辑加速包";
       this.addForm.title = row.title;
      this.addForm.price = row.price;
      this.addForm.status = row.status+"";
       this.addForm.content = row.content;
      this.addForm.remark = row.remark;
      this.addForm.id = row.id;
    },
    addPackage() {
      this.dialogAddVisible = true;
      this.dialogTitle = "新增加速包";
      this.addForm.title = "";
      this.addForm.price = "";
      this.addForm.status = "";
       this.addForm.content = "";
      this.addForm.remark = "";
      this.addForm.id = ""
    },
    deleteRow(id) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delPackageById({ id }).then((res) => {
          this.getAcceleratorPackageList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },

    getAcceleratorPackageList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };

      if (!!this.formInline.title) {
        params.title = this.formInline.title;
      }
      console.log(312)
      getAcceleratorPackage(params).then((res) => {
        /* 表格数据 */
        console.log(315)
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getAcceleratorPackageList();
    },
    onPageChange(page) {
      this.page = page;
      this.getAcceleratorPackageList();
    },
    searchData() {
      this.page = 1;
      this.getAcceleratorPackageList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
/* .getAcceleratorPackageList .el-form-item__label {
  vertical-align: top;
} */
/* .acceleratorPackageList /deep/  .el-form--inline .el-form-item__content{vertical-align: middle;} */
.acceleratorPackageList  .el-form--inline .el-form-item__content{vertical-align: middle;}
</style>
