<template>
    <div class="system-management-administrator fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">管理员管理</span>
            <el-form inline ref="administratorForm" v-model="administratorFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="administratorFormData.realname" placeholder="名称"></el-input>
                </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch()">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-circle-plus" class="themed-button" type="info" @click="add()">新增</el-button>
				</el-form-item>

            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                show-overflow-tooltip
                    prop="realname"
                    label="名称"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="mobile"
                    label="登录账号"
                    min-width="150"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="securityMobile"
                    label="安全手机号"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="registTime"
                    label="创建时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="状态"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==0">正常</span>
                        <span v-else>停用</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="180">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        class="themed-button" type="info"
                        @click="handleEdit(scope.row.id)"
                        >编辑</el-button>
                        <el-button
                        size="mini"
                        class="themed-button" type="info"
                        @click="changePWD(scope.row.id)"
                        >修改密码</el-button>
                        <el-button
                        v-if="scope.row.status==0"
                        size="mini"
                        class="themed-button" type="primary"
                        @click="roleEnable(scope.row.id,scope.row.status)"
                        >禁用</el-button>
                        <el-button
                        v-else
                        size="mini"
                        class="themed-button" type="primary"
                        @click="roleEnable(scope.row.id,scope.row.status)"
                        >启用</el-button>
                    </template>
                </el-table-column>
                  
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="新增用户" :visible.sync="addDialogVisible" width="700px" :destroy-on-close='true'>
            <el-form :model="addForm"  ref="addForm" style="max-height:500px;overflow-y:scroll">
                <el-form-item label="名称" prop='realname'>
                    <el-input v-model="addForm.realname"></el-input>
                </el-form-item>
                <el-form-item label="登录账号" prop='mobile'>
                    <el-input v-model="addForm.mobile"></el-input>
                </el-form-item>
                 <el-form-item label="安全手机号" prop='securityMobile'>
                    <el-input v-model="addForm.securityMobile" placeholder="请输入手机号码用于安全校验"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop='password'>
                    <el-input v-model="addForm.password" type='password'></el-input>
                </el-form-item>
                <el-form-item label="状态" prop='status'>
                    <el-radio-group v-model="addForm.status">
                    <el-radio :label="0">启用</el-radio>
                    <el-radio :label="1">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="角色权限">
                    <el-checkbox-group v-model="addForm.checkList">
                      <div v-for="item in agentRoleList" :key="item.index" style="display:inline-block;width:33%">
                             <el-checkbox :label="item.id" >{{item.roleName}}</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </el-form-item>
               
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureAdd('addForm')">确 定</el-button>
                <el-button class="themed-button" type="info" @click="reset('addForm')">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="addDialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>


        <el-dialog title="修改角色" :visible.sync="editDialogVisible" width="700px" :destroy-on-close='true'>
            <el-form :model="editForm"  ref="editForm" style="max-height:500px;overflow-y:scroll">
                <el-form-item label="名称" prop="realname">
                    <el-input v-model="editForm.realname"></el-input>
                </el-form-item>
                <el-form-item label="登录账号" prop="mobile">
                    <el-input v-model="editForm.mobile"></el-input>
                </el-form-item>
                 <el-form-item label="安全手机号" prop='securityMobile'>
                    <el-input v-model="editForm.securityMobile" ></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="editForm.status">
                    <el-radio :label="0">启用</el-radio>
                    <el-radio :label="1">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="角色权限">
                    <el-checkbox-group v-model="editForm.checkList">
                      <div v-for="item in agentRoleList" :key="item.index" style="display:inline-block;width:33%">
                             <el-checkbox :label="item.id" >{{item.roleName}}</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </el-form-item>
                 <el-form-item label="销售" prop='saleId'  v-if="editForm.checkList.indexOf('472E942F586B40F58FBBFDEE766A38E3')>-1">
                 <el-select v-model="editForm.saleId"  filterable>
					<el-option v-for="item in saleList" :key="item.id" :label="item.saleName" :value="item.id">
					</el-option>
				</el-select>
            </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureEdit()">确 定</el-button>
                <el-button class="themed-button" type="info" @click="resetEdit('editForm')">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="editDialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 修改密码 -->
        <el-dialog title="修改密码" :visible.sync="changePWDDialogVisible" width="500px" :destroy-on-close='true'>
            <el-form :model="changePWDForm" :rules="pwdrules" ref="changePWDForm" >
                <el-form-item label="密码" prop="password" label-width="100px">
                    <el-input v-model="changePWDForm.password"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureChangePWD('changePWDForm')">确 定</el-button>
                <el-button class="themed-button" type="info" @click="reset('changePWDForm')">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="changePWDDialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>

        

    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { messageBox } from "@/utils/common.js"
import { adminUser ,agentRoleList ,updateFormAdminUser,updateAdminUser,updatePassword,updateAdminStatus,addAdminUser,getAllList} from "@/api/systemManagement.js";
export default {
    name:'administrator',
    mixins:[resize],
    data(){
        return {
            administratorFormData:{
                type:'0',
                realname:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            addDialogVisible:false,
            addForm:{
                type:'0',
                realname:'',
                mobile:'',
                password:'',
                status:0,
                checkList:[],
                securityMobile:""
            },
            agentRoleList:[],
            editDialogVisible:false,
            editForm:{
                realname:'',
                mobile:'',
                status:0,
                checkList:[],
                securityMobile:"",
                saleId:"",
                type:"",
            },
            editId:'',

            changePWDDialogVisible:false,
            changePWDForm:{
                password:''
            },
            changePWDId:'',
            pwdrules:{
                password:[{ required: true, message: '请输入密码', trigger: 'blur' }]
            },
            saleList:[],
        }
    },
    created(){
        this.getAdministratorList()
        this.getAllSaleList()
    },
    methods:{
        getAllSaleList(){
           getAllList().then(res=>{
            console.log(res)
            this.saleList = res
           })
        },
        getAdministratorList(){
            let params={
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.administratorFormData,params)
            adminUser(this.administratorFormData).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        onSearch(){
            this.page=1
            this.getAdministratorList()
        },
        onPageChange(page){
            this.page = page
           this.getAdministratorList()
			
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getAdministratorList()
        },
        add(){
            this.addDialogVisible = true
            this.addForm.status = 0
            let params = {
                status:'0',
                type:'0'
            }
            agentRoleList(params).then(res=>{
                this.agentRoleList = res.filter(item=>{
                    if(item.status == '0'){
                        return item.id != '1'
                    }
                })
            })
        },
        sureAdd(formName){
             if(!this.addForm.securityMobile){
                 messageBox(this,'请输入安全手机号')
                    return 
			  }
              if(this.addForm.securityMobile){
                let r = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                if(!r.test(this.addForm.securityMobile)){
                    messageBox(this,'手机号格式不对')
                    return 
                }
            }
            let roles = ''
            if(this.addForm.checkList.length>0){
               roles='_'+this.addForm.checkList.join('_')
            }
            console.log(roles,267)
            let params= {
                'type':'0',
                'realname':this.addForm.realname,
                'mobile':this.addForm.mobile,
                'status':this.addForm.status,
                'password':this.addForm.password,
                'roles':roles,
                "securityMobile":this.addForm.securityMobile,
            }
            addAdminUser(params).then(res=>{
                this.$message({
					type: 'success',
					message:'添加成功'
                });
                this.$refs[formName].resetFields()
                this.addDialogVisible=false
                this.getAdministratorList()
            })

        },
        reset(formName){
            this.$refs[formName].resetFields()
        },
        handleEdit(id){
            this.editId = id
            if(id ==1){
                this.$alert('1号ID角色是默认角色不允许进行修改操作!', "消息提示", {
						confirmButtonText: '确定',
				})
				return
            }
            this.editDialogVisible = true
            let params = {
                status:'0',
                type:'0',
                id:id
            }
            agentRoleList(params).then(res=>{
                this.agentRoleList = res.filter(item=>{
                    if(item.status == '0'){
                        return item.id != '1'
                    }
                })
            })

            // 获取已勾选
            updateFormAdminUser(params).then(res=>{
                this.editForm.realname = res.entity.realname
                this.editForm.status = res.entity.status
                this.editForm.mobile = res.entity.mobile
                this.editForm.securityMobile = res.entity.securityMobile
                this.editForm.saleId = res.entity.saleId
                this.editForm.type = res.entity.type
                let arr = res.entity.roleUserEntitys
                let arrList = []
                if(arr.length > 0){
                    arr.forEach(item=>{
                    arrList.push(item.roleId)
                    })
                }
                this.editForm.checkList = arrList
                console.log( this.editForm.checkList)
                
            })


        },
        sureEdit(){
            if(!this.editForm.securityMobile){
                 messageBox(this,'请输入安全手机号')
                    return 
			  }
              if(this.editForm.securityMobile){
                let r = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                if(!r.test(this.editForm.securityMobile)){
                    messageBox(this,'手机号格式不对')
                    return 
                }
            }
            if(!this.editForm.saleId&&this.editForm.checkList.indexOf('472E942F586B40F58FBBFDEE766A38E3')>-1){
              messageBox(this,'请选择销售人员')
                    return 
            }
            let roles = ''
            if(this.editForm.checkList.length>0){
               roles='_'+this.editForm.checkList.join('_')
            }
            let params={
                "id":this.editId,
                'status':this.editForm.status,
                'realname':this.editForm.realname,
                'mobile':this.editForm.mobile,
                'type':this.editForm.type,
                'roles':roles,
                 "securityMobile":this.editForm.securityMobile,
                 "saleId":this.editForm.saleId,
            }
            updateAdminUser(params).then(res=>{
                this.$message({
					type: 'success',
					message:'修改成功'
                });
                this.editDialogVisible = false
                this.getAdministratorList()
            })
        },
        resetEdit(){
            let id=this.editId
            this.handleEdit(id)
        },
        changePWD(id){
            this.changePWDId = id
            this.changePWDDialogVisible = true
        },
        sureChangePWD(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = {
                        id:this.changePWDId,
                        password:this.changePWDForm.password
                    }
                    updatePassword(params).then(res=>{
                        this.$message({
                            showClose: true,
					        message: res.slide_msg_message_key,
					        type: 'success',
							center: true
                        })
                        this.changePWDDialogVisible = false
                        this.getAdministratorList()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
        },

        roleEnable(id,status){
            let params ={
                'id':id,
                'status':status
            }
            if(status=='1'){
                params.status = '0'
                updateAdminStatus(params).then(res=>{
                        this.$message({
								showClose: true,
                                message: res.slide_msg_message_key,
                                type: 'success',
                                center: true
                            });
                         this.getAdministratorList()
                    })        
            }else{
                this.$confirm("您确定要停用该角色吗?", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    params.status = '1'
                    updateAdminStatus(params).then(res=>{
                        this.$message({
								showClose: true,
                                message: res.slide_msg_message_key,
                                type: 'success',
                                center: true
                            });
                         this.getAdministratorList()
                    })
                    
                })
            }
        },
    }

}
</script>

<style>
.system-management-administrator .el-dialog .el-form-item__content{
    margin-left:150px;
}
</style>