<template>
    <div class="dictionary-setting fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">字典设置</span>
            <el-form inline ref="dicSettingForm" v-model="dicSettingData" class="right themed-form search-form" style="height: 40px;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="dicSettingData.dictGroup" placeholder="字典组"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="dicSettingData.dictKey" placeholder="字典key"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="dicSettingData.dictValue" placeholder="字典value"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-circle-plus" class="themed-button" type="info" @click="add()">新增字典</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="dictGroup"
                    label="字典组"
                    min-width="150">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="dictKey"
                    label="字典key"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="dictValue"
                    label="字典value"
                    min-width="150">
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="120">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleEdit(scope.row.id)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog :title="handleTitle" :visible.sync="dialogEditFormVisible" width="500" :destroy-on-close='true'>
            <el-form :model="editForm" :rules="rules" ref="editForm">
                <el-form-item label="字典组" :label-width="formLabelWidth" prop="dictGroup">
                <el-input v-model="editForm.dictGroup" autocomplete="off" ></el-input>
                </el-form-item>
                <el-form-item label="字典key" :label-width="formLabelWidth" prop="dictKey">
                <el-input v-model="editForm.dictKey" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="字典value" :label-width="formLabelWidth" prop="dictValue">
                <el-input v-model="editForm.dictValue" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
                <el-input v-model="editForm.sort" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureRecord('editForm')">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogEditFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {dictSettingList,findDictById,updateDict,addDict} from '@/api/stockManagement.js'
import resize from '@/mixins/resize.js'
export default {
    name:'dictionarySettings',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return {
            dicSettingData:{
                dictGroup:'',
                dictKey:'',
                dictValue:''

            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogEditFormVisible:false,
            formLabelWidth:'150px',
            handleType:'',
            handleTitle:'',
            editForm:{
                dictGroup:'',
                dictKey:'',
                dictValue:'',
                sort:'',
                id:''
            },
            rules:{
                dictGroup:[
                    {required: true, message: '请输入字典组', trigger: 'blur'},
                ],
                dictKey:[
                    {required: true, message: '请输入字典key', trigger: 'blur'}
                ],
                dictValue:[
                    {required: true, message: '请输入字典value', trigger: 'blur'},
                ],
                sort:[
                    {required: true, message: '请输入排序', trigger: 'blur'},
                ],
            },

        }
    },
    created(){
        this.getDictList()
    },
    methods:{
        getDictList(){
            let parame = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.dicSettingData,parame)
            dictSettingList(this.dicSettingData).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getDictList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getDictList()
        },
        onSearch(){
            this.page = 1
            this.getDictList()
        },
        add(){
            this.handleType = 'add'
            this.handleTitle = '新增字典'
            this.dialogEditFormVisible = true
            this.editForm.dictGroup = ''
                this.editForm.dictKey = ''
                this.editForm.dictValue = ''
                this.editForm.sort = ''
                this.editForm.id= ''
        },
        handleEdit(id){
            this.handleType = 'edit'
            this.handleTitle = '修改字典'
            this.dialogEditFormVisible = true
            findDictById({id}).then(res=>{
                this.editForm.dictGroup = res.entity.dictGroup
                this.editForm.dictKey = res.entity.dictKey
                this.editForm.dictValue = res.entity.dictValue
                this.editForm.sort = res.entity.sort
                this.editForm.id= res.entity.id
            })
        },
        sureRecord(formName){
            this.$refs[formName].validate((valid) => {
                if(valid){
                    this.$confirm("确定此操作吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
                    if(this.handleType == 'edit'){
                        updateDict(this.editForm).then(res=>{
						this.$message({
							type: 'success',
							message:'修改成功'
						});
                        })
                    }else if(this.handleType == 'add'){
                        addDict(this.editForm).then(res=>{
						this.$message({
							type: 'success',
							message:'添加成功'
						});
                        })
                    }
                    this.dialogEditFormVisible = false
                    this.handleType = ''
                    this.handleTitle = ''
					this.getDictList()
				})
                }
            })
            
        },
    }
}
</script>
<style>
    .withdrawal-application .el-form-item__label{
        vertical-align: top;
    }
</style>