<template>

<div class="form-update">
    <el-form label-position="right" label-width="200px" :model="formSet" style="max-height:550px;overflow:auto">
            <el-form-item label="套餐名称:" required>
                <el-input v-model="formSet.name"></el-input>
            </el-form-item>
            <el-form-item label="套餐类型:">
                <el-select v-model="formSet.type" @change="changeData">
                    <!-- <el-option label="叠加包" value="1"></el-option>
                    <el-option label="语音叠加包" value="101"></el-option>
                    <el-option label="月卡套餐" value="2"></el-option>
                    <el-option label="天卡套餐" value="7"></el-option>
                    <el-option label="自然月套餐" value="9"></el-option>
                    <el-option label="自然月套餐（本月/次月生效）" value="10"></el-option>
                    <el-option label="联通自然月(自动顺延)" value="14"></el-option>
                    <el-option label="电信自然月(自动顺延)" value="15"></el-option>
                    <el-option label="赠送套餐" value="11"></el-option>
                    <el-option label="复合套餐(联通)" value="20"></el-option>
                    <el-option label="复合套餐" value="21"></el-option>
                    <el-option label="自然月累计套餐" value="23"></el-option> -->
                     <el-option v-for="item in $store.getters.packageType" :key="item.dictKey" :label="item.groupName" :value="item.dictValue">
					</el-option>
                </el-select>
            </el-form-item>
             <!-- <el-form-item label="是否预存款自动续费套餐:" v-if="isCaptal!='1'" > -->
                <el-form-item label="是否预存款自动续费套餐:" v-if="parseInt(isGuangyao)">
                <el-radio-group v-model="formSet.ifAdvance" @input="changeAdvance">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
              <el-form-item label="是否无极套餐:" v-if="showOverFlowPrice">
        <el-radio-group v-model="formSet.ifWuJi" >
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="低消:"  v-if="showOverFlowPrice">
   <el-input v-model="formSet.minCharge" style="width:260px" clearable></el-input>
        <i
          class="el-icon-question"
          style="
            color: blue;
            font-size: 18px;
            vertical-align: middle;
            margin-left: 10px;
          "
          title="格式：低消流量,价格。举例：每月低消4G，4元。填入：4096,4  填入的流量单位是M,价格是元"   
        ></i>
 </el-form-item>

            <el-form-item label="阶梯超套单价:" required v-if="showOverFlowPrice" >
                <!-- <el-input v-model="formSet.flowPriceRule" style="width:60%;margin-left:3px;" ></el-input> -->
            <el-button type="primary" class="themed-button leftMar" @click="showAddPrice">阶梯超套单价设置</el-button>
             <!-- <el-input-number v-model="formSet.overFlowPrice" :precision="2" :step="0.1" ></el-input-number> -->
            </el-form-item>
    <el-form-item label="每次超出时续流量(G):" required  v-if="showOverFlowPrice">
       <el-input  v-model="formSet.plusFlowCount"   clearable>
          </el-input>
      </el-form-item>
            <el-form-item label="是否按残月计算价格："  v-if="ifComputeDay">
                <el-radio-group v-model="formSet.ifComputeDay" @input="changeComputeDay">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
                 <i class="el-icon-question" style="color:blue;font-size:18px;vertical-align: middle;margin-left:10px;" title="按残月计算价格：当用户充值时间为非1号时，按天平均折算首月套餐金额，同时可以配置当月充值后套餐剩余时间小于一定天数的时候会打折；"></i>
            </el-form-item>
             <el-form-item label="按残月计算价格规则:" v-if="leftDayDiscount">
               <!-- <el-input v-model="formSet.leftDayDiscount" style="width:65%;" ></el-input> -->
                <el-button type="primary" class="themed-button leftMar" @click="showLeftDayDiscount">添加残月计算价格规则</el-button>
                <!-- <i class="el-icon-question" style="color:blue;font-size:18px;vertical-align: middle;margin-left:10px;" title="按残月计算价格：当用户充值时间为非1号时，按天平均折算首月套餐金额，同时当月充值后套餐剩余时间小于20天和小于10的时候会打折；"></i> -->
            </el-form-item>
            <el-form-item label="周期类型:" v-if="termTypeTr">
                <el-select v-model="formSet.termType">
                    <el-option label="月" value="1"></el-option>
                    <el-option label="天" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="二级套餐类型:" required v-if="secondTypeTr">
                <el-select @change="changeData" v-model="formSet.secondType">
                    <el-option v-for="(item,index) in mealList" :label="item.label" :value="item.value" :key="index"></el-option>
                </el-select>  
            </el-form-item>
            <!-- <el-form-item label="按流量合并显示:" required  v-if="formSet.type == 6 || formSet.type == 9"> -->
                 <el-form-item label="按流量合并显示:" required  v-if=" formSet.type == packageList['ZRYTC_']">
                <el-radio-group v-model="formSet.groupFlow">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="套餐数量:" required v-if="formSet.type == packageList['TKTC_']">
                <el-input v-model="formSet.termCount" placeholder="默认1" style="width:30%"></el-input>
                <span>个周期</span>
            </el-form-item>
            <el-form-item label="总天数:" required v-if="formSet.type == packageList['TKTC_']">
                <el-input v-model="formSet.cardCount" style="width:30%"></el-input>
            </el-form-item>
            <el-form-item label="套餐数量:" required v-if="formSet.type != packageList['TKTC_']">
                <el-input v-model="formSet.cardCount"></el-input>
            </el-form-item>
            <el-form-item label="套餐对账价:" required v-show="hideItem">
                <el-input v-model="formSet.accountMoney"></el-input>
            </el-form-item>
            <el-form-item label="套餐原价:" required v-show="hideItem">
                <el-input v-model="formSet.agoMoney"></el-input>
            </el-form-item>
            <el-form-item label="套餐折扣:" required v-show="hideItem">
                <el-input v-model="formSet.discount"></el-input>
            </el-form-item>
            <el-form-item label="套餐金额:" required>
                <el-input v-model.trim="formSet.money" @input='setMealPrice'></el-input>
                <span style="color:red;" v-if="parseInt(isGuangyao)">无极套餐套餐金额需设置为0</span>
            </el-form-item>
            <el-form-item label="超套单价:" required v-if="overPriceTr">
                <el-input v-model="formSet.overPrice"></el-input>
            </el-form-item>
            <el-form-item label="流量:" required>
                <el-input v-model="formSet.flowSize"></el-input>
                <p>（套餐所含流量，只能输入数字）</p>
            </el-form-item>
            <el-form-item label="语音量:" required  v-if="isCaptal!='1'">
                <el-input v-model="formSet.voiceSize"></el-input>
                <p>（套餐所含语音分钟数，只能输入数字）</p>
            </el-form-item>
            <el-form-item label="限速流量:" required v-show="hideItem&&speedLimitFlowTr">
                <el-input v-model="formSet.speedLimitFlow"></el-input>
                <p>（单位为MB，目前仅针对电信天卡套餐生效）</p>
            </el-form-item>

            <el-form-item label="所属类别:" required>
                <el-select v-model="formSet.category"  @change="changeCategory">
                    <el-option v-for="(item,index) in categorys" :label="item.name" :value="item.value" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="子类别:" required>
                <el-select  v-model="formSet.secondCategory" placeholder="请选择" @change="changeSecondCategory">
                    <el-option v-for="(item,index) in categoryList" :label="item.label" :value="item.value" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="映射套餐:"  v-if="isCaptal!='1'">
                <el-select  v-model="formSet.accountMealId" placeholder="请选择" >
                    <el-option v-for="(item,index) in accountMealList" :label="item.mealName+'-'+item.mealId" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="限速规则:" required>
                <el-select  v-model="formSet.speedLimitId" filterable placeholder="请选择">
                    <el-option v-for="(item,index) in limitRuleList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="增加已使用流量百分比A:" required>
                <el-input v-model="formSet.flowAddStrategy"></el-input>
                 <el-button type="primary" class="themed-button leftMar" @click="showAddStrategy">阶梯模式设置</el-button>
            </el-form-item>
            <el-form-item label="增加已使用流量百分比B:" required>
                <el-input v-model="formSet.flowAddStrategySecond"></el-input>
                <el-button type="primary" class="themed-button leftMar" @click="showAddStrategySecond" >阶梯模式设置</el-button>
            </el-form-item>
            <el-form-item label="虚量选择:" required>
                <el-radio-group v-model="formSet.defaultStrategy">
                     <el-radio  :label="item.dictValue" v-for ="item in $store.getters.defaultStrategyList" :key="item.dictKey">{{item.groupName}}</el-radio>
                    <!-- <el-radio label="1">A</el-radio>
                    <el-radio label="2">B</el-radio> -->
                </el-radio-group>
            </el-form-item>
            <el-form-item label="增加已使用语音量百分比:" required  v-if="isCaptal!='1'">
                <el-input v-model="formSet.voiceAddDiscount" placeholder="取值1~100"></el-input>
            </el-form-item>
            <el-form-item label="普通用户使用流量范围:" required v-show="hideItem">
                <el-input v-model="formSet.normalUserFlowBegin" style="width:20%"></el-input>
                <span> - </span>
                <el-input v-model="formSet.normalUserFlowEnd" style="width:20%"></el-input>
                <span>(单位为GB,注意,判断的是虚流量)</span>
            </el-form-item>
            <el-form-item label="是否特殊:" required v-show="hideItem">
                <el-radio-group v-model="formSet.isSpecial">
                    <el-radio :label="0">非特殊</el-radio>
                    <el-radio :label="1">特殊</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否允许修改价格:" required v-show="hideItem">
                <el-radio-group v-model="formSet.isChangePrice">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="小流量监控:" required v-show="hideItem">
                <el-radio-group v-model="formSet.isMonitor">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="一级是否合并:" required>
                <el-radio-group v-model="formSet.isChild">
                    <el-radio :label="2">是</el-radio>
                    <el-radio :label="1">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="一级合并编号:" required>
                <el-input v-model="formSet.codeChild" style="width:65%"></el-input>
                 <i class="el-icon-question" style="color:blue;font-size:18px;vertical-align: middle;margin-left:10px;" :title="'当前运营商合并编号:'+currentMergeCode"></i>
                <p>（合并编号，按需填写）</p>
            </el-form-item>
            <el-form-item label="二级是否合并:" required>
                <el-radio-group v-model="formSet.secondIsChild">
                    <el-radio :label="2">是</el-radio>
                    <el-radio :label="1">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="二级合并编号:" required>
                <el-input v-model="formSet.secondCodeChild"></el-input>
                <p>（合并编号，按需填写）</p>
            </el-form-item>
            <el-form-item label="第三方套餐ID:" required v-show="hideItem">
                <el-input v-model="formSet.thirdMealId"></el-input>
            </el-form-item>
            <el-form-item label="状态:" required v-show='currentRole==0'>
                <el-select v-model="formSet.status" >
                    <el-option label="上架" value="1"></el-option>
                    <el-option label="下架" value="2"></el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item label="第一次充值运营商套餐:" required v-show='isMobileAccount'>
                <el-select v-model="formSet.vendorSetMealId">
                    <el-option label="无" value=""></el-option>
                    <el-option v-for="(item,index) in vendorSetMealIds"  :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="第一次充值运营商套餐数量:" required v-show='isMobileAccount'>
                <el-input v-model="formSet.firstVendorRechargeCount"></el-input>
            </el-form-item>
            <el-form-item label="第二次充值运营商套餐:" required v-show='isMobileAccount'>
                <el-select v-model="formSet.secondVendorSetMealId">
                    <el-option label="无" value=""></el-option>
                    <el-option v-for="(item,index) in vendorSetMealIds"  :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="第二次充值运营商套餐数量:" required v-show='isMobileAccount'>
                <el-input v-model="formSet.secondVendorRechargeCount"></el-input>
            </el-form-item>
            <el-form-item label="运营商充值剩余分钟数:" required v-show='isMobileAccount'>
                <el-input v-model="formSet.secondVendorSetMealMinute"></el-input>
                <p>在剩余多少语音分钟数（真实分钟数）时，充值运营商套餐</p>
            </el-form-item>
            <el-form-item label="加速服务份数:" v-show="hideItem">
                <el-input v-model="formSet.accCount"></el-input>
            </el-form-item>
            <el-form-item label="加速天数:" v-show="hideItem">
                <el-input v-model="formSet.accDay"></el-input>
            </el-form-item>
            <el-form-item label="加速模式:" v-show="hideItem">
                <el-select v-model="formSet.accType">
                    <el-option label="直充" value="1"></el-option>
                    <el-option label="订阅" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="前端是否显示:" required>
                <el-select v-model="formSet.hideFlag">
                    <el-option label="显示" value=""></el-option>
                    <el-option label="不显示" value="1"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="是否仅轮询:" required v-show='currentRole==0'  style="display:none"> -->
                 <el-form-item label="是否仅轮询:" required   style="display:none">
                <el-select v-model="formSet.onlyLoop">
                    <el-option label="非仅轮询" value="0"></el-option>
                    <el-option label="仅轮询" value="1"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="是否允许立即生效:" required v-show='currentRole==0||imEffectAbleIsShow'> -->
                <el-form-item label="是否允许立即生效:" required v-show='currentRole==0||(currentRole==4&&agentTree==1)'>
                <el-radio v-model="formSet.imEffectAble" label="true">是</el-radio>
                <el-radio v-model="formSet.imEffectAble" label="false">否</el-radio>
            </el-form-item>
            <el-form-item label="是否允许次月生效:" required v-show='allowNextMonthIsShow'>
                <el-radio v-model="formSet.allowNextMonth" label="true">是</el-radio>
                <el-radio v-model="formSet.allowNextMonth" label="false">否</el-radio>
            </el-form-item>
            <el-form-item label="超套不停机:" required v-show="overFlowNotStopShow">
                <el-radio v-model="formSet.overFlowNotStop" label="true">是</el-radio>
                <el-radio v-model="formSet.overFlowNotStop" label="false">否</el-radio>
            </el-form-item>
            <!-- <el-form-item label="后半月充值五折:" required v-show='currentRole==0' style="display:none"> -->
                <el-form-item label="后半月充值五折:" required  style="display:none">
                <el-radio v-model="formSet.tailMonthDiscount" label="true">是</el-radio>
                <el-radio v-model="formSet.tailMonthDiscount" label="false">否</el-radio>
            </el-form-item>
             <el-form-item label="不停复机:" required v-show='currentRole==0'>
                <el-radio v-model="formSet.noStopRecovery" label="true">是</el-radio>
                <el-radio v-model="formSet.noStopRecovery" label="false">否</el-radio>
            </el-form-item>
               <el-form-item label="返佣分期数:"  >
                <el-input v-model="formSet.rebateTerm"></el-input>
            </el-form-item>
             <el-form-item label="无佣金标识:" required >
                <el-radio v-model="formSet.noProfitFlag" label="1">是</el-radio>
                <el-radio v-model="formSet.noProfitFlag" label="0">否</el-radio>
                 <div style="color:#f00">选择无佣金不会有任何返利，请谨慎选择</div>
            </el-form-item>
             <el-form-item label="首充套餐:" required >
                <el-radio v-model="formSet.firstRecharge" label="true">是</el-radio>
                <el-radio v-model="formSet.firstRecharge" label="false">否</el-radio>
            </el-form-item>
             <el-form-item label="VIP套餐:"  v-show="(currentRole == 0 || (currentRole == 4 && agentTree == 1))">
        <el-radio v-model="formSet.vipFlag" label="true">是</el-radio>
        <el-radio v-model="formSet.vipFlag" label="false">否</el-radio>
      </el-form-item>
             <el-form-item label="关闭自动续费:" required  v-if="ifautoRecharge" >
                <el-radio v-model="formSet.closeAutoRecharge" label="true">是</el-radio>
                <el-radio v-model="formSet.closeAutoRecharge" label="false">否</el-radio>
            </el-form-item>
            <el-form-item label="排序:" required>
                <el-input v-model="formSet.mealSort"></el-input>
            </el-form-item>
            <el-form-item label="充值标题:" >
                <el-input v-model="formSet.title" placeholder="例如：199元/年"></el-input>
            </el-form-item>
            <el-form-item label="充值描述:" >
                <el-input type="textarea" v-model="formSet.description"></el-input>
            </el-form-item>
              <el-form-item label="标签:" >
                <el-input  v-model="formSet.label"></el-input>
            </el-form-item>
              <el-form-item label="套餐标记:" >
                <el-input  v-model="formSet.redMark" maxlength="15"></el-input>
            </el-form-item>
             <el-form-item label="套餐显示分类:">
         <el-select v-model="formSet.viewClassify">
          <el-option label="默认" value=""></el-option>
          <el-option
            v-for="(item, index) in mealTypeList"
            :label="item.name"
            :value="item.name"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
        </el-form>
        <div class="align-right">
            <el-button type="primary" class="themed-button" @click="sure">确定</el-button>
            <el-button type="primary" class="themed-button" @click="reset">重置</el-button>
            <el-button type="primary" class="themed-button" @click="cancle">取 消</el-button>
        </div>
          <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%" v-if="dialogVisible"  append-to-body>
         <Strategy   @close="getStrategy" :totalValue="totalValue"></Strategy>
        </el-dialog> 
            <el-dialog title="设置残月套餐价格"  :visible.sync="dialogVisibleOne" width="50%" v-if="dialogVisibleOne"  append-to-body>
         <leftday-discount   @close="getPrice" :curDiscount ="curDiscount"></leftday-discount>
        </el-dialog>  
        <el-dialog title="设置超套价格" :visible.sync="dialogVisibleTwo" width="50%" v-if="dialogVisibleTwo"  append-to-body>
         <over-price   @close="getOverPrice"  :totalValue="overPricevalue" ></over-price>
        </el-dialog>
</div>

</template>

<script type="text/ecmascript-6">
import {getFirstName,getMealList,findById,getByCategory,getSpeedModel,setmealSave,update,} from '@/api/setMealManagment/setMeal.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import {limitRuleListSeal} from "@/api/speedLimit/index.js";
import {
  operatorMealList,
} from "@/api/operator.js";
import {mapGetters} from 'vuex'
import store from '@/store'
import Strategy from './strategy.vue'
import leftdayDiscount  from "./leftdayDiscount.vue"
import overPrice  from "./overPrice.vue"
import {
  getConfigurationModify,
} from "@/api/systemManagement.js";
export default {
    props:['id'],
    data(){
        return {
             mealTypeList:[],
            hideItem:false,  //隐藏项
            limitRuleList:[],
            parmsForm:{
                id:'',
                balanceMoney:'0',
                totalMoney :'0',
                fatherAgentId :'0'
            },
            resetData:{},
            formSet:{
                flowAddStrategy:'',
                flowAddStrategySecond:'',
                name:'',
                type:'1',
                termType:'',
                secondType:'',
                groupFlow:0,
                cardCount:'',
                accountMoney:'',
                agoMoney:'',
                discount:'10',
                money:'',
                overPrice:'',
                flowSize:'',
                voiceSize:'',
                speedLimitFlow:'',
                dayFlowSize:'',
                userFlow:'',
                speedVal:'',
                userFlow1:'',
                speedVal1:'',
                userFlow2:'',
                speedVal2:'',
                defaultStrategy:'1',
                voiceAddDiscount:'',
                normalUserFlowBegin:'',
                normalUserFlowEnd:'',
                isSpecial:0,
                isChangePrice:1,
                isMonitor:2,
                isChild:1,
                codeChild:'',
                secondIsChild:1,
                secondCodeChild:'',
                thirdMealId:'',
                status:'1',
                category:'1',
                secondCategory:'',
                speedLimitId:'',
                vendorSetMealId:'',
                firstVendorRechargeCount:'',
                secondVendorSetMealId:'',
                secondVendorRechargeCount:'',
                secondVendorSetMealMinute:'',
                hideFlag:'',
                onlyLoop:'1',
                imEffectAble:'false',
                allowNextMonth:"false",
                overFlowNotStop:'false',
                tailMonthDiscount:'false',
                noStopRecovery:"false",
                mealSort:'',
                title:'',
                description:'',
                termCount:'',
                accCount:0,
                accDay:0,
                accType:'1',
                label:"",
                noProfitFlag:"0",
                rebateTerm:"",
                firstRecharge:"",
                accountMealId:"",
                ifComputeDay:"0",
                leftDayDiscount:"",
                redMark:"",
                ifAdvance:"0",
                overFlowPrice:"",
                closeAutoRecharge:"",
                ifautoRecharge:false,
                flowPriceRule:'',
                ifWuJi:"0",
                vipFlag:"",
                plusFlowCount:null,
                viewClassify:"",
                minCharge:"",
            },
            // categorys:[],
            categoryList:[],
            vendorSetMealIds:[],
            limitOpts:[],
            secondTypeTr:false, //二级套餐类型
            speedLimitFlowTr:false, //限速流量
            dayFlowSizeTr:false, //日限速流量
            multiLimit:false,   //一二三级限速流
            termTypeTr:false,
            isMobileAccount:false, //移动运营商
            currentMergeCode:'',  //当前合并码
             packageList:{},
            categorySlist:{},
            allowNextMonthIsShow:false,
             dialogVisible:false,
            dialogTitle:"",
            totalValue:"",
            overFlowNotStopShow:true,
             accountMealList:[],
            ifComputeDay:false,
            leftDayDiscount:false,
            dialogVisibleOne:false,
            curDiscount:"",
            showOverFlowPrice:false,
            dialogVisibleTwo:false,
            overPricevalue:"",
             xiaoNuoisShow:this.isGuangyao,
        }
    },
    computed:{
        ...mapGetters(
            	['currentRole',"categorys","agentTree",'isCaptal','isGuangyao']
            ),
        
        overPriceTr(){ //超套单价
            if(this.formSet.type === this.packageList['LTZRY_'] && this.formSet.secondType === 5){
                return true 
            }else{
                return false
            }
        },
        mealList(){
            if(this.formSet.type == this.packageList['FHTC_ZQ'] || this.formSet.type == this.packageList['FHTC_']){
                if(this.isCaptal=="1"){
                 return [
                    {label:'共享-计费组套餐',value:3},
                    {label:'单卡-计费组套餐',value:4},
                    {label:'共享单月清零-计费组套餐',value:5},
                    {label:'单卡月清零-计费组套餐',value:6},
                ]
                }else{
                return [
                    {label:'月卡套餐',value:1},
                    {label:'累计套餐',value:2},
                    {label:'共享-计费组套餐',value:3},
                    {label:'单卡-计费组套餐',value:4},
                    {label:'共享单月清零-计费组套餐',value:5},
                    {label:'单卡月清零-计费组套餐',value:6},
                ]
                }
               
            }else if(this.formSet.type == this.packageList['LTZRY_'] || this.formSet.type == this.packageList['DXZRY_']){
                return [
                    {label:'日限速',value:1},
                    {label:'月限速',value:2},
                    {label:'月不限速',value:3},
                    {label:'日月都限速',value:4},
                    {label:'月不限速(预存)',value:5}
                ]

            }
        },
        imEffectAbleIsShow(){
            if( parseInt(this.isGuangyao)  ){
                return false
            }else if(this.currentRole==4){//window.g.name为空且为vip代理商显示
               return true
            }else{
                return false
            }
        }
    },
    created(){
      if(this.formSet.type == this.packageList['DJB_']||this.formSet.type == this.packageList['ZRYTC_']){
                this.ifComputeDay = true

            }else{
                 this.ifComputeDay = false
            }
     if(this.formSet.ifAdvance=="0"){
        this.showOverFlowPrice = false
       }else{
         this.showOverFlowPrice = true
       }
       if(this.formSet.type == this.packageList['DJB_'] ||this.formSet.type == this.packageList['YYDJB_']  ||this.formSet.type == this.packageList['ZSTC_']    ){//自然月套餐
                this.ifautoRecharge = false
            }else{
                this.ifautoRecharge = true
            }
      
    },
    mounted(){
            if(store.getters.packageType.length<1||store.getters.defaultStrategyList.length<1 ||store.getters.mergeCode.length<1){
           store.dispatch('getPackageType').then(()=>{
               if(store.getters.packageType.length>0){
            store.getters.packageType.forEach(item=>{
               let key =  item.dictKey
               let value = item.dictValue
                this.packageList[key] = value
            })
               }
         
           })
        }else{
           store.getters.packageType.forEach(item=>{
               let key =  item.dictKey
               let value = item.dictValue
                this.packageList[key] = value
            })
        }
         if(store.getters.categorys.length<1){
          store.dispatch('getCategory').then(()=>{
                  if(store.getters.categorys.length>0){
            store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.categorySlist[name] =value
            })
               }
          })
        }else{
             store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.categorySlist[name] =value
            })
        }
        this._findById()
        this._getMealTypeList();
    },
    methods:{
         _getMealTypeList(){
     console.log(904)
      this.mealTypeList = [];
      getConfigurationModify({ group: "SYSTEM_CONFIG" }).then((res) => {
        res.forEach((item) => {
          if (item.dictKey == "CUSTOM_MEAL_CLASSIFY") {
            if (item.dictValue) {
              console.log(JSON.parse(item.dictValue));
              this.mealTypeList = JSON.parse(item.dictValue);
             
              //调用接口传数据
            } else {
              this.mealTypeList = [];
              
            }
          }
        });
      });
    },
          changeAdvance(val){
          if(val=="0"){
        this.showOverFlowPrice = false
       }else{
         this.showOverFlowPrice = true
       }
        },
         showLeftDayDiscount(){
          this.dialogVisibleOne = true
          this.curDiscount  =  this.formSet.leftDayDiscount 
        },
        changeComputeDay(val){
        console.log(val,576)
        if(val=="1"){
        this.leftDayDiscount = true
        }else if(val=="0"){
        this.leftDayDiscount = false
        }
        },
         getOperatorMealList(val){
            this.accountMealList= []
            
         operatorMealList({accountId:val})
        .then((res) => {
         this.accountMealList= res.rows
        })
        .catch(() => {
           this.accountMealList= []
        });
        },
        setMealPrice(){
            this.formSet.accountMoney = this.formSet.money
            this.formSet.agoMoney = this.formSet.money
        },
        reset(){
            this._setValue(this.resetData)
        },
        cancle(){
            this.$emit("close")
        },
        sure(){
            if(this.formSet.discount == ''){
                messageBox(this,'折扣率不能为空')
                return 
            }
            var re = /^\d+(?:\.\d{0,2})?$/;
            if (this.formSet.discount.match(re) == null){
                messageBox(this,"请填写正确的折扣率");
                return;
            }
         if((!!this.formSet.plusFlowCount && isNaN(this.formSet.plusFlowCount)) || (!!this.formSet.plusFlowCount &&  (this.formSet.plusFlowCount > 1000 || this.formSet.plusFlowCount <= 0))){
           messageBox(this, "流量值只能是1-1000的整数");
        return;
      }
            if(this.formSet.discount > 10 || this.formSet.discount <= 0){
                messageBox(this,"请填写正确的折扣率");
                return;
            }
            // if(this.formSet.type == "6") {
            //     if(this.formSet.codeChild == "" ||this.formSet.isChild !== "2") {
            //         messageBox(this,"累积套餐的\"一级合并编号\"必须填写，且\"一级是否合并\"必须选\"是\"。");
            //         return;
            //     }
            // }
            
            if(this.formSet.mealSort == ''){
                messageBox(this,'请选择排序值')
                return 
            }
            if(this.formSet.type == this.packageList['LTZRY_'] &&  this.formSet.secondType == 5 &&  !this.formSet.overPrice){
                messageBox(this,"预存型套餐，超套金额不能为空")
                return ;
            }
             if(this.formSet.money == ''){
                messageBox(this,'套餐金额不能为空')
                return 
            }
             if( !!isNaN(this.formSet.money) ){
               messageBox(this,'套餐金额只能为数字')
                return 
            }else{
                  let x = String(this.formSet.money).indexOf(".")+1;
                  let y = String(this.formSet.money).length - x
                  if(x==0){
                  }else{
                    if(y>=3){
                         messageBox(this,'套餐金额只能保留两位小数')
                         return 
                    }
                  }
            }
            //无极套餐 套餐金额 判断
            console.log(this.formSet.ifWuJi,this.showOverFlowPrice)
       if(this.formSet.ifWuJi=="1" &&this.showOverFlowPrice){
        console.log(671)
          if( parseFloat(this.formSet.money) !=0){
            messageBox(this, "无极套餐，套餐金额只能为0");
            return;
          }
       }
            if (this.formSet.flowAddStrategy != null && this.formSet.flowAddStrategy !== '') {
                if (isNaN(this.formSet.flowAddStrategy)) {
                    var pairs = this.formSet.flowAddStrategy.split(";")
                    for (var pair of pairs) {
                        var arr = pair.split(",")
                        if (arr.length < 1 || isNaN(arr[0]) || isNaN(arr[1])) {
                            messageBox(this,"增加已使用流量百分比值A输入有误")
                            return 
                        }
                    }
                }
            }
             if(this.formSet.rebateTerm!=""){
               let r = /^[1-9]\d*$/
               if(!r.test( this.formSet.rebateTerm )){
                    messageBox(this,"返佣分期数只能是大于0的整数");
                return;
               }
            }
            if (this.formSet.flowAddStrategySecond != null && this.formSet.flowAddStrategySecond !== '') {
                if (isNaN(this.formSet.flowAddStrategySecond)) {
                    var pairs = this.formSet.flowAddStrategySecond.split(";")
                    for (var pair of pairs) {
                        var arr = pair.split(",")
                        if (arr.length < 1 || isNaN(arr[0]) || isNaN(arr[1])) {
                            messageBox(this,"增加已使用流量百分比值B输入有误")
                            return 
                        }
                    }
                }
            }
            //    if(this.formSet.ifAdvance=='1' &&  !this.formSet.overFlowPrice){
            //     messageBox(this,"预存型自动续费套餐，超套金额不能为空")
            //     return ;
            // }
            //  if(this.formSet.ifAdvance=='1' &&  this.formSet.overFlowPrice<=0){
            //     messageBox(this,"预存型自动续费套餐，超套金额不能小于0")
            //     return ;
            // }
            
              if(this.formSet.minCharge.trim()!= null &&
        this.formSet.minCharge.trim() !== "" && this.formSet.ifAdvance == "1"){
        if(this.formSet.minCharge.indexOf(",")==-1){
               messageBox(this, "低消输入格式不正确");
              return;
        } else{
         let arr =  this.formSet.minCharge.split(",")
          if(arr.length!=2){
            messageBox(this, "低消输入格式不正确");
              return;
         }
         if (!/^[1-9]\d*$/.test(arr[0])) {
             messageBox(this, "低消流量是大于0的正整数");
              return;
        }
        if (arr[0]>1024000 || arr[0]<=0) {
          messageBox(this, "低消流量需要大于0小于等于1024000");
              return;
        }
        if (!/^\d+(\.\d{0,2})?$/.test(arr[1])) {
           messageBox(this, "低消价格只能为数字最多保留2位小数");
              return;
        }
          if (arr[1]>99999 || arr[1]<=0) {
          messageBox(this, "低消价格需要大于0小于等于99999");
              return;
        }
               
        }
      }
            if (this.formSet.ifAdvance=='1' && this.formSet.flowPriceRule != null && this.formSet.flowPriceRule !== '') {
                if (isNaN(this.formSet.flowPriceRule)) {
                    var pairs = this.formSet.flowPriceRule.split(";")
                    for (var pair of pairs) {
                        var arr = pair.split(",")
                        if (arr.length < 1 || isNaN(arr[0]) || isNaN(arr[1])) {
                            messageBox(this,"阶梯超套单价输入有误")
                            return 
                        }
                    }
                }
            }
            if(this.formSet.flowPriceRule.trim() == ''&&this.formSet.ifAdvance == "1"){
               messageBox(this,"阶梯超套单价不能为空")
                            return 
            }
            update(Object.assign(this.parmsForm, this.formSet)).then((res)=>{
                  if(res.system_result_key == 0){
               this.$message({
                            showClose: true,
					        message: '编辑成功',
					        type: 'success',
							center: true
                })
                this.$emit("close",1)
                }else{
                    this.$message({
							showClose: true,
							message: res.slide_msg_message_key,
						})
                }
                
            })
            
        },
        changeCategory(val){
            this._getByCategory()
            this._getMealList()
            if(val==this.categorySlist['中国移动']){
            // if(val=='7'){
                this.isMobileAccount = true
            }else{
                this.isMobileAccount = false
            }
              if(val==this.categorySlist['虚拟卡']){
                this.overFlowNotStopShow = true
            }else{
                this.overFlowNotStopShow = false
            }
        },
        changeSecondCategory(val){
              let  accountId = ""
             for(let i of this.categoryList){
                if(i.value == val){
                   accountId= i.id
                }
             } 
            this._getMealList()
            this._getSpeedModel()
            this._getLimitRuleList(val)
            this.getOperatorMealList(accountId)
            let name =''
            this.categoryList.forEach(item=>{
                if(item.value==val){
                    name = item.label
                }
            })
            this.showMergeCode(name)
        },

         showMergeCode(name){
            // switch (name)
            //     {
            //         case '扬州电信':
            //             this.currentMergeCode="01";
            //             break;
            //         case '浙江联通':
            //             this.currentMergeCode="02";
            //             break;
            //         case '青岛联通':
            //             this.currentMergeCode="03";
            //             break;
            //         case '广州移动':
            //             this.currentMergeCode="04";
            //             break;
            //         case '广州移动4G':
            //             this.currentMergeCode="05";
            //             break;
            //         case '湖南联通':
            //             this.currentMergeCode="06";
            //             break;
            //         case '深圳移动2G':
            //             this.currentMergeCode="07";
            //             break;
            //         case '累计套餐':
            //             this.currentMergeCode="09";
            //             break;
            //         case '电信累计':
            //             this.currentMergeCode="10";
            //             break;
            //         case '常州移动':
            //             this.currentMergeCode="12";
            //             break;
            //         case '联通预存类':
            //             this.currentMergeCode="13";
            //             break;
            //         case '湖南联通cmp':
            //             this.currentMergeCode="14";
            //             break;
            //         case '重庆移动':
            //             this.currentMergeCode="15";
            //             break;
            //         case '联通CMP':
            //             this.currentMergeCode="16";
            //             break;
            //         case '浙江联通TOB':
            //             this.currentMergeCode="17";
            //             break;
            //         case '中翼电信':
            //             this.currentMergeCode="18";
            //             break;
            //         case '腾讯':
            //             this.currentMergeCode="19";
            //             break;
            //         case '徐州移动':
            //             this.currentMergeCode="20";
            //             break;
            //         case '虚拟卡':
            //             this.currentMergeCode="21";
            //             break;
            //         case '虚拟卡DB03I':
            //             this.currentMergeCode="22";
            //             break;
            //         case 'TOB浙江联通自然月顺延套餐':
            //             this.currentMergeCode="99";
            //             break;
            //         case '放单卡计费组套餐':
            //             this.currentMergeCode="98";
            //             break;
            //         case '硕软电信':
            //             this.currentMergeCode="23";
            //             break;
            //         case '宁波电信':
            //             this.currentMergeCode="24";
            //             break;
            //         case '虚拟卡DB01C':
            //             this.currentMergeCode="25";
            //             break;
            //         case '济南电信恒天':
            //             this.currentMergeCode="26";
            //             break;
            //         case '华为电信':
            //             this.currentMergeCode="27";
            //             break;
            //         case '浙江联通+济南电信':
            //             this.currentMergeCode="28";
            //             break;
            //         case '浙江联通7044':
            //             this.currentMergeCode="29";
            //             break;
            //         case '济南电信枫融':
            //             this.currentMergeCode="35";
            //             break;
            //         default:
            //             this.currentMergeCode="";
            //     }
              if(store.getters.mergeCode.length>0){
               store.getters.mergeCode.forEach(item=>{
                  if(name == item.groupName){
                 this.currentMergeCode=item.dictValue;
              }
            })
            }
        },

        _getLimitRuleList(val){
            this.limitRuleList=[]
            let curAccount = this.categoryList.filter((item)=>{
                return item.value == val
            })
            let id = curAccount[0].id
            limitRuleListSeal({accountId:id}).then(res=>{
                let list = res.rows
                if(res.rows.length>0){
                    list.forEach(item=>{
                        this.limitRuleList.push({name:item.name,id:item.id})
                    })
                }else{
                    this.limitRuleList=[]
                    this.formSet.speedLimitId = ''
                }
            })
        },

        setUnicomLimitOpt(){
            this.limitOpts = [
                {label:"不限速",value:"476WLW004066_NJ_DATA_4G"},
                {label:"限速上行5.76Mb/s，下行14.4Mb/s",value:"476WLW004066_NJ_DATA_4G_LTD1"},
                {label:"限速上行5.76Mb/s，下行7.2Mb/s",value:"476WLW004066_NJ_DATA_4G_LTD2"},
                {label:"限速上行2Mb/s,下行2Mb/s",value:"476WLW004066_NJ_DATA_4G_LTD3"},
                {label:"限速上行1Mb/s，下行1Mb/s",value:"476WLW004066_NJ_DATA_4G_LTD4"},
                {label:"限速上行512Kb/s,下行512Kb/s",value:"476WLW004066_NJ_DATA_4G_LTD5"},
                {label:"限速上行256Kb/s，下行256Kb/s",value:"476WLW004066_NJ_DATA_4G_LTD6"},
            ]
        },
        setTelecomLimitOpt(){
            this.limitOpts = [
                {label:'1kbps',value:'10'},
                {label:'512Kbps',value:'11'},
                {label:'1Mbps',value:'12'},
                {label:'3Mbps',value:'13'},
                {label:'5Mbps',value:'14'},
                {label:'7Mbps',value:'15'},
                {label:'10Mbps',value:'16'},
                {label:'20Mbps',value:'17'}
            ]
        },
        changeData(){
             if(this.formSet.type == this.packageList['FHTC_ZQ'] || this.formSet.type == this.packageList['FHTC_'] || this.formSet.type == this.packageList['LTZRY_']  || this.formSet.type == this.packageList['DXZRY_'] ){
            // if(this.formSet.type == 20 || this.formSet.type == 21 || this.formSet.type == 14 || this.formSet.type == 15){
                this.secondTypeTr = true
            }else{
                this.secondTypeTr = false
            }

            if(this.formSet.type == this.packageList['ZSTC_']){//赠送套餐11
                this.termTypeTr = true
            }else{
                this.termTypeTr = false
            }
            if(this.formSet.type == this.packageList['FHTC_ZQ'] || this.formSet.type == this.packageList['FHTC_']){
            // if(this.formSet.type == 20 || this.formSet.type == 21){
                this.multiLimit = false
                this.dayFlowSizeTr = false
                this.speedLimitFlowTr = true

                if(this.formSet.secondType == 6){
                    this.dayFlowSizeTr = true
                }else{
                    this.dayFlowSizeTr = false
                }
            }else if(this.formSet.type == this.packageList['LTZRY_'] ){
            // }else if(this.formSet.type == 14){

                this.multiLimit = false 
                this.dayFlowSizeTr = true 
                this.speedLimitFlowTr = false 

                if(this.formSet.secondType == 1){
                    this.multiLimit = true
                    this.dayFlowSizeTr = false
                }else if(this.formSet.secondType == 2){
                    this.multiLimit = true
                    this.dayFlowSizeTr = false
                }else if(this.formSet.secondType == 3){
                    this.multiLimit = false
                    this.dayFlowSizeTr = false
                }else if(this.formSet.secondType == 5){
                    this.multiLimit = true
                    this.dayFlowSizeTr = true
                    this.speedLimitFlowTr = true
                }else{
                    this.multiLimit = true
                    this.dayFlowSizeTr = true
                    this.speedLimitFlowTr = true
                }
                
                this.setUnicomLimitOpt()
            }else if(this.formSet.type == this.packageList['DXZRY_']){
            // }else if(this.formSet.type == 15){
                this.multiLimit = true
                this.dayFlowSizeTr = true
                this.speedLimitFlowTr = false
                this.setTelecomLimitOpt()
               }else if(this.formSet.type == this.packageList['TKTC_']){
            // }else if(this.formSet.type == 7){
                this.multiLimit = true
                this.dayFlowSizeTr = true
                this.speedLimitFlowTr = false
                if(this.formSet.category == this.categorySlist['中国联通']){//4
                    this.setUnicomLimitOpt()
                }else if(this.formSet.category == this.categorySlist['中国电信']){//1
                    this.setTelecomLimitOpt()
                }
            }else{
                this.speedLimitFlowTr = true
            }
            if(this.currentRole==4 ||this.currentRole==0){
               if(this.formSet.type == this.packageList['ZRYTC_'] ||this.formSet.type == this.packageList['LTZRY_']||this.formSet.type == this.packageList['DXZRY_']  ){//自然月套餐
                this.allowNextMonthIsShow = true
            }else{
                this.allowNextMonthIsShow = false
            }
            }
        if(this.formSet.type == this.packageList['DJB_']||this.formSet.type == this.packageList['ZRYTC_']){
                this.ifComputeDay = true
                if(this.formSet.ifComputeDay =="1"){
                   this.leftDayDiscount = true
                }else{
                    this.leftDayDiscount = false
                }
            }else{
                 this.ifComputeDay = false
                 this.leftDayDiscount = false
            }
     if(this.formSet.type == this.packageList['DJB_'] ||this.formSet.type == this.packageList['YYDJB_']  ||this.formSet.type == this.packageList['ZSTC_']    ){//自然月套餐
                this.ifautoRecharge = false
            }else{
                this.ifautoRecharge = true
            }
        },
        _setValue(data){
            this.formSet.name= data.name
            this.formSet.type = data.type +''
            this.formSet.termType = data.termType+''
            this.formSet.secondType = data.secondType
            this.formSet.groupFlow = data.groupFlow
            this.formSet.termCount = data.termCount
            this.formSet.cardCount= data.cardCount
            this.formSet.plusFlowCount = data.plusFlowCount? data.plusFlowCount:null;
            this.formSet.accountMoney= data.accountMoney
            this.formSet.agoMoney= data.agoMoney
            this.formSet.discount= data.discount+''
            this.formSet.money= data.money
            this.formSet.overPrice= data.overPrice
            this.formSet.flowSize= data.flowSize
            this.formSet.voiceSize= data.voiceSize
            this.formSet.speedLimitFlow= data.speedLimitFlow
            this.formSet.dayFlowSize= data.dayFlowSize
            this.formSet.userFlow= data.userFlow
            this.formSet.speedVal= data.speedVal+''
            this.formSet.userFlow1= data.userFlow1
            this.formSet.speedVal1= data.speedVal1+''
            this.formSet.userFlow2= data.userFlow2
            this.formSet.speedVal2= data.speedVal2+''
            this.formSet.flowAddStrategy = data.flowAddStrategy
            this.formSet.flowAddStrategySecond = data.flowAddStrategySecond
            this.formSet.defaultStrategy= data.defaultStrategy + ''
            this.formSet.voiceAddDiscount= data.voiceAddDiscount
            this.formSet.normalUserFlowBegin= data.normalUserFlowBegin
            this.formSet.normalUserFlowEnd= data.normalUserFlowEnd
            this.formSet.isSpecial = parseInt(data.isSpecial)
            this.formSet.isChangePrice = parseInt(data.isChangePrice)
            this.formSet.isMonitor = parseInt(data.isMonitor)
            this.formSet.isChild = parseInt(data.isChild)
            this.formSet.codeChild= data.codeChild
            this.formSet.secondIsChild = parseInt(data.secondIsChild)
            this.formSet.secondCodeChild= data.secondCodeChild
            this.formSet.thirdMealId= data.thirdMealId
            this.formSet.status = data.status+''
            this.formSet.category = data.category
            if(data.category=="11"){
               this.overFlowNotStopShow = true
            }else{
                this.overFlowNotStopShow = false
            }
            this.formSet.secondCategory= data.secondCategory+''
            this.formSet.speedLimitId = data.speedLimitId
            this.formSet.vendorSetMealId= data.vendorSetMealId
            this.formSet.firstVendorRechargeCount= data.firstVendorRechargeCount
            this.formSet.secondVendorSetMealId= data.secondVendorSetMealId
            this.formSet.secondVendorRechargeCount= data.secondVendorRechargeCount
            this.formSet.secondVendorSetMealMinute= data.secondVendorSetMealMinute
            this.formSet.hideFlag= data.hideFlag?data.hideFlag+"":""
            this.formSet.onlyLoop = data.onlyLoop
            this.formSet.imEffectAble = data.imEffectAble+''
            this.formSet.allowNextMonth = data.allowNextMonth+''
            this.formSet.overFlowNotStop = data.overFlowNotStop+''
            this.formSet.tailMonthDiscount = data.tailMonthDiscount+''
            this.formSet.noStopRecovery = data.noStopRecovery+''
            this.formSet.mealSort= data.mealSort
            this.formSet.title= data.title
            this.formSet.description= data.description
            this.formSet.accCount = data.accCount!=''?parseInt(data.accCount):''
            this.formSet.accDay = data.accDay!=''?parseInt(data.accDay):''
            this.formSet.accType = data.accType+''
            this.formSet.label = data.label
            this.formSet.noProfitFlag = data.noProfitFlag+""
            this.formSet.rebateTerm = data.rebateTerm
            if(data.firstRecharge===""){
              this.formSet.firstRecharge = false+""
            }else{
         this.formSet.firstRecharge = data.firstRecharge+""
            }
            if(data.vipFlag===""){
              this.formSet.vipFlag = false+""
            }else{
         this.formSet.vipFlag = data.vipFlag+""
            }
            if(data.closeAutoRecharge===""){
              this.formSet.closeAutoRecharge = false+""
            }else{
         this.formSet.closeAutoRecharge = data.closeAutoRecharge+""
            }
            
            this.formSet.accountMealId = data.accountMealId
            this.formSet.ifComputeDay = data.ifComputeDay?"1":"0" 
            this.formSet.leftDayDiscount = data.leftDayDiscount
        if(data.ifComputeDay){
        this.leftDayDiscount = true
        }else{
        this.leftDayDiscount = false
        }
        this.formSet.redMark = data.redMark
        this.formSet.viewClassify = data.viewClassify 
        this.formSet.ifAdvance = data.ifAdvance?"1":"0" 
        if(data.ifAdvance){
           this.showOverFlowPrice = true
           this.formSet.overFlowPrice = data.overFlowPrice
            this.formSet.flowPriceRule= data.flowPriceRule
             this.formSet.minCharge= data.minCharge
        }else{
            this.showOverFlowPrice = false 
            this.formSet.overFlowPrice = data.overFlowPrice
             this.formSet.flowPriceRule= data.flowPriceRule
              this.formSet.minCharge= data.minCharge
        }
         this.formSet.ifWuJi = data.ifWuJi?"1":"0" 
        
        },
        // _getFirstName(){
        //     getFirstName(Object.assign(this.parmsForm, this.formSet)).then((res)=>{
        //         this.categorys = []
        //         if(res.length>0){
        //             res.forEach((item) => {
        //                 if(item.firstName){
        //                     this.categorys.push({
        //                         name:item.firstName,
        //                         value:item.category
        //                     }) 
        //                 }
        //             })
        //         }
        //         this.categorys.unshift({
        //             name:"请选择运营商",
        //             value:""
        //         })
        //     })
        // },
        _getMealList(){
            if(this.formSet.category == this.categorySlist['中国移动'] || this.formSet.category == this.categorySlist['中国联通']){
                let params = {
                    category:this.formSet.category,
                    secondCategory:this.formSet.secondCategory
                }
                getMealList(params).then((res)=>{
                    let list = res.list
                    if(list.length<=0){
                        this.vendorSetMealIds = []
                        return 
                    }
                    this.vendorSetMealIds = list.map((item)=>{
                        let name = item.vendorMealName
                        if(item.type === 1){
                            name += ("("+item.voiceSize+"分钟)")
                        }else{
                            name += ("("+item.flowSize+"MB)")
                        }
                        return {
                            name,
                            id:item.id
                        }
                    })
                })
            }else{
                this.vendorSetMealIds = []
            }
             if(this.formSet.type == this.packageList['TKTC_']){
            // if(this.formSet.type == 7){
                if(this.formSet.category == this.categorySlist['中国联通']){//4
                    this.setUnicomLimitOpt()
                }else if(this.formSet.category == this.categorySlist['中国电信']){//1
                    this.setTelecomLimitOpt()
                }
            }
            
        },
        _findById(){
            this.parmsForm.id = this.id
            let secondCategory =''
            findById({id:this.id}).then((res)=>{
                let data = res.entity
                secondCategory = data.secondCategory
                this.resetData = res.entity
                // this._getFirstName()
                  let  accountId = ""
             for(let i of this.categoryList){
                if(i.value == res.entity.secondCategory){
                   accountId= i.id
                }
             } 
                this._setValue(data)
                this.changeData()
                this._getMealList()
                this._getByCategory()
                this._getSpeedModel()
                this._getEditLimitRule(res.entity.secondCategory)
                this.getOperatorMealList(accountId)
                this.changeCategory(res.entity.category)
            })
        },

        _getEditLimitRule(secondCategory){
            getByCategory(Object.assign(this.parmsForm, this.formSet)).then((res)=>{
                this.categoryList = []
                if(res.length>0){
                    this.categoryList = res.map((item)=>{
                        return {
                            label:item.accountName,
                            value:item.secondCategory,
                            id:item.id
                        }
                    })
                }
                this.categoryList.unshift({
                    label:"请选择运营商",
                    value:""
                })

                this._getLimitRuleList(secondCategory)
            })
        },


        _getByCategory(){
            getByCategory(Object.assign(this.parmsForm, this.formSet)).then((res)=>{
                this.categoryList = []
                if(res.length>0){
                    this.categoryList = res.map((item)=>{
                        return {
                            label:item.accountName,
                            value:item.secondCategory,
                            id:item.id
                        }
                    })
                }
                this.categoryList.unshift({
                    label:"请选择运营商",
                    value:""
                })
            })
            
        },
        _getSpeedModel(){
            getSpeedModel(Object.assign(this.parmsForm, this.formSet)).then((res)=>{ 
                if(res.length>0){
                    this.limitOpts = res.map((item)=>{
                        return {
                            label:item.speedKey,
                            value:item.speedValue
                        }
                    })

                    let flag = false
                    let flag1 = false
                    let flag2 = false
                    this.limitOpts.forEach((item)=>{
                        if(item.value == this.formSet.speedVal){
                            flag = true
                        }
                        if(item.value == this.formSet.speedVal1){
                            flag1 = true
                        }
                        if(item.value == this.formSet.speedVal2){
                            flag2 = true
                        }
                    })

                    if(!flag){
                        this.formSet.speedVal = this.limitOpts[0].value
                    }
                    
                    if(!flag1){
                        this.formSet.speedVal1 = this.limitOpts[0].value
                    }
                    
                    if(!flag2){
                        this.formSet.speedVal2 = this.limitOpts[0].value
                    }
                }
                
            })
        },
        showAddPrice(){
        this.dialogVisibleTwo = true
        this.overPricevalue = this.formSet.flowPriceRule
        },
          showAddStrategy(){
          this.dialogVisible = true
          this.dialogTitle = "设置增加已使用流量百分比A"
          this.totalValue = this.formSet.flowAddStrategy
        },
        showAddStrategySecond(){
          this.dialogVisible = true
          this.dialogTitle = "设置增加已使用流量百分比B"
          this.totalValue = this.formSet.flowAddStrategySecond
        },
        getStrategy(data){
          
          if(data){
            if(this.dialogTitle=="设置增加已使用流量百分比A"){
               this.formSet.flowAddStrategy = data
            }else{
                this.formSet.flowAddStrategySecond = data
            }
            
          }
          this.dialogVisible = false
        },
        getPrice(data){
        if(data){
       this.formSet.leftDayDiscount = data
        }
        this.dialogVisibleOne = false
        },
        getOverPrice(data){
         if(data){
       this.formSet.flowPriceRule = data
        }
        this.dialogVisibleTwo = false
        },
    },
     components:{
        Strategy,
        leftdayDiscount,
        overPrice
    }
}
</script>