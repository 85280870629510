<template>
    <div class="expiration-time-change fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">过期时间变更记录</span>
            <el-form inline ref="expiraTimeChangeForm" v-model="expiraTimeChangeData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="expiraTimeChangeData.iccidMark" placeholder="卡号" class="iccid-width"></el-input>
                </el-form-item>
                 <el-form-item>
                    <el-input v-model="expiraTimeChangeData.shortIccid" placeholder="短号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="expiraTimeChangeData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="expiraTimeChangeData.remark" placeholder="备注"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>
        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="卡号"
                    min-width="180"
                    >
                </el-table-column>
                 <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="130"
                    />
                <el-table-column
                    prop="phone"
                    label="接入号"
                    min-width="130"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="setMealName"
                    label="当前套餐"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="typeStr"
                    label="类型"
                    min-width="80"
                    >
                </el-table-column>
                <el-table-column
                    prop="day"
                    label="天数"
                    min-width="80"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="变更前过期时间"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span>{{dateFormat(scope.row.originalExpireDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="变更后过期时间"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.newExpireDate">{{dateFormat(scope.row.newExpireDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="操作类型"
                    min-width="100"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.operationType==1">批量变更</span>
                        <span v-else-if="scope.row.operationType==2">单独变更</span>
                        <span v-else-if="scope.row.operationType==3">删除</span>
                        <span v-else-if="scope.row.operationType==4">新增</span>
                        <span v-else>{{scope.row.operationType}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="操作时间"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span>{{dateFormat(scope.row.createDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="userName"
                    label="操作人"
                    min-width="100"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="备注"
                    min-width="180"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.remark">{{scope.row.remark}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="操作"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleShowDetail(scope.row.id)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

         <el-dialog title="过期时间变更记录详情" :visible.sync="dialogDetailExpiraChangeVisible" width="40%" :destroy-on-close='true'>
            <el-form :model="detailForm"  ref="detailForm">
                <el-form-item label="卡号" :label-width="formLabelWidth">
                <el-input v-model="detailForm.iccidMark" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="接入号" :label-width="formLabelWidth">
                <el-input v-model="detailForm.phone" autocomplete="off" disabled></el-input>
                </el-form-item>
                 <el-form-item label="短号" :label-width="formLabelWidth">
                <el-input v-model="detailForm.shortIccid" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="套餐" :label-width="formLabelWidth">
                <el-input v-model="detailForm.setMealName" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="变更前过期时间" :label-width="formLabelWidth">
                <!-- <el-input v-model="detailForm.origExpireDate" autocomplete="off" disabled></el-input> -->
                <el-input v-model="detailForm.originalExpireDate" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="变更后过期时间" :label-width="formLabelWidth">
                <el-input v-model="detailForm.newExpireDate" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="操作类型" :label-width="formLabelWidth">
                <!-- <el-input v-model="detailForm.opTypeName" autocomplete="off" disabled></el-input> -->
                <el-input v-model="detailForm.operationType" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="操作时间" :label-width="formLabelWidth">
                <el-input v-model="detailForm.createDate" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="操作人" :label-width="formLabelWidth">
                <el-input v-model="detailForm.userName" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input type="textarea" autosize v-model="detailForm.remark" disabled></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="primary" @click="dialogDetailExpiraChangeVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import resize from '@/mixins/resize.js'
import { expraTimeChangeList,expraTimeChangeDetail,exportExpraTimeChange} from '@/api/history.js'
export default {
    name:'expirationTimeChange',
    mixins:[resize],
    data(){
        return {
            expiraTimeChangeData:{
                iccidMark:'',
                phone:'',
                remark:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            formLabelWidth:'150px',
            dialogDetailExpiraChangeVisible:false,
            detailForm:{
                iccidMark:'',
                phone:'',
                setMealName:'',
                originalExpireDate:'',
                newExpireDate:'',
                operationType:'',
                createDate:'',
                userName:'',
                remark:'',
                shortIccid:""
            }
        }
    },
    created(){
        this.getExpraTimeChangeList()
    },
    methods:{
        getExpraTimeChangeList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.expiraTimeChangeData,params)
            expraTimeChangeList(this.expiraTimeChangeData).then(res=>{
                this.tableData=res.rows
                this.total=res.count
            })
        },
        handleShowDetail(id){
            this.dialogDetailExpiraChangeVisible = true
            expraTimeChangeDetail({'id':id}).then(res=>{
                this.detailForm.iccidMark = res.entity.iccidMark
                this.detailForm.phone = res.entity.phone
                this.detailForm.setMealName = res.entity.setMealName
                this.detailForm.originalExpireDate = this.expireStr(res.entity.originalExpireDate)
                this.detailForm.newExpireDate = this.expireStr(res.entity.newExpireDate)
                this.detailForm.operationType = this.showOpTypeName(res.entity.operationType)
                this.detailForm.createDate = this.dateFormat(res.entity.createDate)
                this.detailForm.userName = res.entity.userName
                this.detailForm.remark = res.entity.remark
                this.detailForm.shortIccid = res.entity.shortIccid
            })
        },
        handleSizeChange(size){
            this.pageSize = size
            this.getExpraTimeChangeList()
        },
        onPageChange(page){
            this.page = page
            this.getExpraTimeChangeList()
        },
        onSearch(){
            this.page=1
            this.getExpraTimeChangeList()
        },
        exportExcel(){
            exportExpraTimeChange(this.expiraTimeChangeData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        dateFormat(time){
            let date = new Date(time);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m= m < 10?'0'+m:m
            let d = date.getDate();
            d= d < 10?'0'+d:d
            let hour = date.getHours();
            hour= hour < 10?'0'+hour:hour
            let min = date.getMinutes();
            min= min < 10?'0'+min:min
            return y + '-' + m + '-' + d + ' ' + hour + ':' + min;
        },
        expireStr(time){
            if(!time) return ''
            let date = new Date(time);
            let y = date.getFullYear()
            let m = date.getMonth() + 1
            m= m < 10?'0'+m:m
            let d = date.getDate()
            d= d < 10?'0'+d:d
            return y + '-' + m + '-' + d
        },
        showOpTypeName(val){
            let opTypeName = ''
            if(val == 1){
                opTypeName = "批量变更"
            }else if(val==2){
                opTypeName = "单独变更"
            }
            else if(val ==3){
                opTypeName = "删除"
            }
            else if(val ==4){
                opTypeName = "新增"
            }else{
                opTypeName = val
            }

            return opTypeName
        },
    }
}
</script>