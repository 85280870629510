<template>
    <div class="history-forward-record fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">转代理记录</span>
            <el-form inline ref="forwardRecordForm" v-model="forwardRecordData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作起始日期" v-model="forwardRecordData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="操作结束日期" v-model="forwardRecordData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                 <el-form-item>
                    <el-input v-model="forwardRecordData.iccidMark" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>
                
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createDate"
                    label="操作时间"
                    min-width="150"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="oldAgentTreeName"
                    label="原代理商"
                    min-width="150"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="newAgentTreeName"
                    label="新代理商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="处理状态"
                    min-width="100">
                    <template slot-scope="scope">
                       <span v-if="scope.row.status==0">成功</span>
                        <span v-else-if="scope.row.status==1">失败</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="failReason"
                    label="失败原因"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    min-width="120"
                    >  
                </el-table-column>
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { changeAgentRecord } from "@/api/history.js";
export default {
    name:'forwardingRecord',
     mixins:[resize],
    data(){
        return {
            forwardRecordData:{
                iccidMark:'',
                startTime:'',
                endTime:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getForwardRecordList()
    },
    methods: {
        getForwardRecordList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.forwardRecordData,params)
            changeAgentRecord(this.forwardRecordData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getForwardRecordList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getForwardRecordList()
        },

        onSearch(){
            this.page=1
            this.getForwardRecordList()
        },
    },
}
</script>