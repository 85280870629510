<template>
  <div class="setItem fadeInRight">
    <el-form ref="addForm" style="max-height: 500px; overflow-y: scroll" >
      <el-table :data="tableList" style="margin-top: 15px">
        <el-table-column label="邮箱地址" prop="" min-width="90">
          <template slot-scope="scope">
            <el-input   placeholder="请输入邮箱"   v-model="scope.row.percent"  clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="danger"
              plain
              size="mini"
              :disabled="tableList.length==1"
              @click="deleteParams(scope.row, scope.$index)"
              >删除</el-button
            >
            <el-button
              type="info"
              plain
              size="mini"
              :disabled="isDisable"
              @click="afterAddParams(scope.row, scope.$index)"
              >插入一行</el-button
            >
          </template>
        </el-table-column>
      </el-table>
     
      <span></span>
      <div class="align-right">
        <el-button type="primary" class="themed-button" @click="cancle"
          >取消</el-button
        >
        <el-button type="primary" class="themed-button" @click="sure"
          >确认</el-button
        >
      </div>
    </el-form>
  
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import { messageBox, confirmBox } from "@/utils/common.js";
export default {
  name: "setItem",
  mixins: [resize],
  props: ["curmail"],
  data() {
    return {
      tableList: [
       
      ],
      currentTable:this.curmail
    };
  },
  created() {
     if(!this.currentTable){
      console.log(this.currentTable)
      this.tableList.push({
        percent: "",
      });
    } else if (this.currentTable.length > 0) {
      console.log(this.currentTable)
        // "80;90;99"
        if(this.currentTable==";;"){
            this.tableList.push({
              percent: "",
            })
         }else{
        this.currentTable = this.currentTable + ";";
         let arr = this.currentTable.split(";");
          arr.forEach(item=>{
            if(item!=""){
             this.tableList.push({
              percent: item,
            })
            }
          })
         }
    } else {
      console.log(this.currentTable)
      this.tableList.push({
       percent: "",
      });
    }
  },
  computed: {
    isDisable() {
      if (this.tableList.length >= 3) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    deleteParams(data, index) {
      
      this.tableList.splice(parseInt(index), 1);
    },
    afterAddParams(data, index) {
    this.tableList.splice(parseInt(index)+1,0,{ percent:"",})
    },
    cancle() {
      this.$emit("cancel");
    },

    sure() {
      //校验
      if (this.tableList.length > 0) {
        let value = "";
        var arr = this.tableList
        console.log(arr)
        for(var i =0;i<arr.length;i++){
          if(arr[i].percent){
            let r = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            console.log(arr[i].percent,r.test(arr[i].percent))
                if(!r.test(arr[i].percent)){
                    messageBox(this,'请输入正确的邮箱格式')
                    return 
                }
          }
        }
        for(var i =0;i<arr.length-1;i++){
         
           if(arr[i].percent==arr[i+1].percent && arr[i].percent!="" ){
             messageBox(this, "重复设置邮箱");
            return;
           }
        }
         
        for (let i of arr) {
          
          value += i.percent + ";";
        }
        value = value.substring(0, value.length - 1);
        console.log(value);
        this.$emit("close", value);
      }
    },
  },
};
</script>

<style>
.setItem .el-form-item__label {
  vertical-align: top;
}
.setItem .inputWidth {
  width: 50%;
}
</style>