<template>
	<div class="officialSubscirbe">
		<div class="title-box clearfix">
			<span class="m-title">公众号关注列表</span>
			<el-form :inline="true" :model="formInline" class="right themed-form search-form"
				style="height: auto;line-height: 40px;">
				<el-form-item label="openid:">
					<el-input v-model="formInline.openid" class="iccid-width"></el-input>
				</el-form-item>
				<el-form-item label="公众号:">
					<el-select v-model="formInline.appId" >
					<el-option value="">请选择</el-option>
					   <el-option
					     v-for="item in accountArr"
					     :key="item.appId"
					     :label="item.name"
					     :value="item.appId">
					   </el-option>
					 </el-select>
				</el-form-item>
				<el-form-item label="微信名或代理商:">
					<el-input v-model="formInline.pageSearchKeys"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询
					</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="headImgUrl" label="">
					<template v-slot:default="scope">
						<img :src="scope.row.headImgUrl" alt="" style='width: 24px;height: 24px'>
					</template>
				</el-table-column>
				<el-table-column prop="nickname" label="微信昵称">
				</el-table-column>
				<el-table-column prop="openid" label="openid">
				</el-table-column>
				<el-table-column prop="city" label="市">
				</el-table-column>
				<el-table-column prop="province" label="省">
				</el-table-column>
				<el-table-column prop="country" label="国">
				</el-table-column>
				<el-table-column prop="appName" label="公众号">
				</el-table-column>
				<el-table-column prop="agentName" label="代理商">
				</el-table-column>
				
				<el-table-column label="操作">
					<template slot-scope="scope">
						<span v-if="scope.row.agentId ==null ||scope.row.agentId =='' ">
							<a href='javascript:;' @click='assAgent(scope.row.openid)' style="color: #337ab7;">关联代理商</a>
							</span>
							<span v-else><a href='javascript:;' @click='delAgent(scope.row.openid)'  style="color: #337ab7;">解除关联</a></span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize"
				style="text-align: left;margin-top: 20px;" background layout="total,sizes,prev, pager, next,jumper"
				:total="total" @size-change="handleSizeChange" @current-change="onPageChange">
			</el-pagination>
		</div>
		<el-dialog title="关联代理商" :visible.sync="dialogVisible" v-if="dialogVisible">
			<subscribe-agent  :openId="para"  @close="dialogVisible=false" @reload="reload"></subscribe-agent>
		</el-dialog>
	</div>
</template>

<script>
	import subscribeAgent from "./subscribeAgent.vue"
	import onresize from "@/mixins/resize.js"
	import loading from '@/utils/Loading.js'
	import {subscribeList,officialList,delSubscribeList} from "@/api/payManagment.js"
	export default{
		name:'officialSubscribe',
		mixins: [onresize],
		data(){
			return {
				accountArr:[],
				formInline:{
					appId:"",
					pageSearchKeys:"",
					openid:'',
				},
				page:1,
				pageSize:15,
				total:0,
				para:0,
				dialogVisible:false,
				tabledata:[],
			}
		},
		created(){
		  this.getSubscribeList(this.formInline)
		  this.getOfficialList(this.formInline)
		},
		components:{
			subscribeAgent,
		},
		methods:{
			
			getSubscribeList(params){
				subscribeList(params).then(res=>{
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
					this.tabledata  = res.rows
				})
			},
			getOfficialList(params){
				officialList(params).then(res=>{
					this.accountArr  =  res.rows
				})
			},
			searchData(){
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				 this.getSubscribeList(params)
			},
			handleSizeChange(size){
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				 this.getSubscribeList(params)
			},
			onPageChange(page){
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				 this.getSubscribeList(params)
			},
			delAgent(openid){
				  this.$confirm('确定要解除关联吗？', '消息提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							let params = {
								key:openid,
							}
							Object.assign(params,this.formInline)
							delSubscribeList(params).then(()=>{
								this.$message({
								  type: 'success',
								  message: '解除成功。'
								});
								let params = {
									pageSize: this.pageSize,
									pageNo: this.page
								}
								Object.assign(params, this.formInline)
								 this.getSubscribeList(params)
							})
							
				          
				        }).catch(() => {
				          this.$message({
				            type: 'info',
				            message: '已取消解除关联'
				          });          
				        });
			},
			assAgent(openid){
			this.para = openid	
			this.dialogVisible = true 
			},
			reload(){
			let params = {
				pageSize: this.pageSize,
				pageNo: this.page
			}
			Object.assign(params, this.formInline)
			 this.getSubscribeList(params)
			}
		}
	}
</script>

<style>
	.officialSubscirbe .el-form-item__label {
		vertical-align: top;
	}
</style>
