<template>
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
       <el-col :span="18" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              ref="queryParams"
              class="demo-form-inline"
              :rules="rulesOne"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
              <el-form-item label="" :label-width="formLabelWidth" prop="gyAgentId">
                <treeselect
                  v-model="queryParams.gyAgentId"
                  :options="agentOptions"
                  :normalizer="normalizer"
                  :show-count="true"
                  placeholder="请选择代理商"
                />
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth" prop="name">
                <el-input
                  placeholder="名称"
                  v-model="queryParams.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth" prop="imei">
                <el-input
                  placeholder="IMEI"
                  v-model="queryParams.imei"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button  
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
        <el-col :span="4" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="add"
                  >新增</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        :max-height="maxTableHeight"
        style="width: 100%"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column prop="name" label="名称" min-width="100">
        </el-table-column>
        <el-table-column prop="gyAgent.agentName" label="代理商名称" min-width="100">
        </el-table-column>
        <el-table-column
          prop="speedLimit"
          label="限速规则触发后下发的速率限制"
          min-width="100"
        >
        <template slot-scope="scope">
          <span>{{ scope.row.speedLimit}}kbps</span>
       </template>
        </el-table-column>
        <el-table-column
          prop="threshold"
          label="限速规则的阈值"
          min-width="100"
        >
         <template slot-scope="scope">
          <span>{{ scope.row.threshold}}GB</span>
       </template>
        </el-table-column>
        <el-table-column
          prop="duration"
          label="限速规则监测持续时间"
          min-width="100"
        >
       <template slot-scope="scope">
          <span>{{ scope.row.duration}}h</span>
       </template>
        </el-table-column>
        <el-table-column prop="createTime" label="操作时间" min-width="100">
        </el-table-column>
        <el-table-column prop="createBy" label="操作人" min-width="100">
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="updata(scope.row)"
              >修改</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="association(scope.row)"
              >关联</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

      <el-dialog :title="title" :visible.sync="dialogFormVisible" width="40%">
        <el-form
          :model="addForm"
          :rules="rules"
          ref="addForm"
          label-width="20%"
        >
         <el-form-item label="代理商选择" prop="gyAgentId" v-show="currentRole==0">
           <treeselect style="width:70%"
                v-model="addForm.gyAgentId"
                :options="agentOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="请选择代理商"
                @select="treeSelectChange"
                :disabled="disabled"
              />
          </el-form-item>
          <el-form-item label="类型名称" prop="name">
            <el-input v-model="addForm.name" style="width: 70%"></el-input>
          </el-form-item>
          <el-form-item label="限速规则" prop="duration">
            <span>
              <el-input
                v-model="addForm.duration"
                style="width: 60px; margin-right: 5px"
              ></el-input>
              小时内，使用超过
              <el-input
                v-model="addForm.threshold"
                style="width: 60px; margin: 0 5px"
              ></el-input>
              GB，限速
              <el-input
                v-model="addForm.speedLimit"
                style="width: 60px; margin: 0 5px"
              ></el-input
              >kbps
            </span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false"  type="info" class="themed-button">取 消</el-button>
          <el-button type="primary" @click="sureAdd('addForm')" class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { agentGetAllAgent } from "@/api/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {mapGetters} from 'vuex'
import {
  rateLimitPageList,
  rateLimitSave,
  rateLimitDelete
} from "@/api/speedlimitBox/speedlimitBox.js";
export default {
  name: "materialsList",
  mixins:[resize],
  components: { Treeselect },
  //mixins: [resize],
  data() {
    var validateFee = (rule, value, callback) => {
      if (
        !this.addForm.duration &&
        !this.addForm.threshold &&
        !this.addForm.speedLimit
      ) {
        callback(new Error("请输入对应信息"));
      }
      if (!this.addForm.duration) {
        callback(new Error("请输入限速规则的时间"));
      }
      if (!this.addForm.threshold) {
        callback(new Error("请输入限速规则的阈值"));
      }
      if (!this.addForm.speedLimit) {
        callback(new Error("请输入限速规则需限速值"));
      }
      let r = /^[1-9][0-9]*$/;
      if (!r.test(this.addForm.duration)) {
        callback(new Error("限速规则时间只能输入数字且不能为0"));
      }
      if (!r.test(this.addForm.speedLimit)) {
        callback(new Error("限速规则需限速值只能输入数字且不能为0"));
      }
      let w =/^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/;   
      if (!w.test(this.addForm.threshold)) {
        callback(new Error("限速规则阈值只能为数字且保留两位小数且不能为0"));
      }
      callback();
    };
    return {
      formInline: {
        spec: "",
        material: "",
      },
      addForm: {},
      tableData: [],
      materialList: [],
      specList: [],
      dialogFormVisible: false,
      agentOptions: [],
      queryParams: {
        imei: "",
        name: "",
        gyAgentId: undefined,
        gyAgentName: undefined,
      },
      rules: {
        duration: [{ validator: validateFee, required: true, trigger: "blur" }],
        name: [{ required: true, message: "请输入类型名称", trigger: "blur" },
        { pattern:/^[\u4e00-\u9fa5_a-zA-Z0-9_.-]{1,32}$/ , message: "类型名称只能为1到32位,包含中文数字字母下划线点连接符", trigger: "blur" }
        ], 
        },
         rulesOne: {
         imei:[{message: "请输入IMEI", trigger: "blur" },
         { pattern:/^[\d]/g , message: "只能为数字", trigger: "blur" },
         { min: 15, max: 17, message: '长度在 15 到 17个字符', trigger: 'blur' }
        ],
        },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
      title: "",
      disabled:false
    };
  },
  computed:{
         ...mapGetters(
             ['currentRole','agentTree']
         ),
     },
  created() {
    this.getDeviceList();
    this.getAllAgent();
  },
  methods: {
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams, params);
      rateLimitPageList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },
    /** 代理商数据 */
    getAllAgent() {
      agentGetAllAgent().then((response) => {
        this.agentOptions = response;
      });
    },
    /** 转换代理商数据结构 */
    normalizer(node) {
      if (
        node.children == null ||
        (node.children == "null" && node.children && !node.children.length)
      ) {
        delete node.children;
      }
      return {
        id: node.agentId,
        label: node.agentName,
        children: node.children,
      };
    },
     treeSelectChange(raw, instanceId) {
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        this.addForm.gyAgentId = raw.agentId;
        this.addForm.gyAgentName = raw.agentName;
      });
    },
    /** 搜索 */
     searchData() {
    this.$refs["queryParams"].validate((valid) => {
      if (valid) {
      this.page = 1;
      this.getDeviceList();
        }})
      // this.page = 1;
      // this.getDeviceList();
    },
    /** 筛选 */
    screenDate() {
      this.page = 1;
      this.getDeviceList();
    },
    /** 提交 */
    sureAdd() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
           if (this.currentRole==0) {
        if (!!this.addForm.gyAgentId) {
        if (this.title != "新增") {
            rateLimitSave(this.addForm).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          } else {
            rateLimitSave(this.addForm).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          }
            } else {
              this.$alert("请选择代理商", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
          }else{
        if (this.title != "新增") {
            rateLimitSave(this.addForm).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          } else {
            rateLimitSave(this.addForm).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          }
          }
          // if (this.title != "新增") {
          //   rateLimitSave(this.addForm).then((response) => {
          //     this.$modal.msgSuccess("修改成功");
          //     this.dialogFormVisible = false;
          //     this.getDeviceList();
          //   });
          // } else {
          //   rateLimitSave(this.addForm).then((response) => {
          //     this.$modal.msgSuccess("新增成功");
          //     this.dialogFormVisible = false;
          //     this.getDeviceList();
          //   });
          // }
        }
      });
    },
    /** 删除 */
    delect(id) {
      let paramsCount = new FormData()
      paramsCount.append('ruleId',id)
      this.$confirm("是否确认删除该项数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        rateLimitDelete(paramsCount).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getDeviceList();
        });
      });
    },
    /** 重置表单 */
    reset() {
      this.addForm = {
        gyAgentId: undefined,
        imeiArray: "",
        radioLable: "0",
      };
      //this.resetForm("addForm");
    },
    /** 新增按钮弹出dialog */
    add() {
      this.reset();
      this.dialogFormVisible = true;
      this.title = "新增";
      this.disabled = false;
    },
    /** 修改按钮弹出dialog */
    updata(row) {
      console.log(row, 11111);
      this.addForm = JSON.parse(JSON.stringify(row));
      //this.resetForm("addForm");
      this.dialogFormVisible = true;
      this.title = "修改";
      this.disabled = true;
    },
    /** 关联跳转 */
    association(row) {
      console.log(row,12122)
      this.$router.push({
        path: "/equipmentManagement/association",
        query: { data: JSON.stringify(row) },
      });
    },
    /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 100px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>