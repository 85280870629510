<template>
    <div class="batch-active-rate fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">批次激活率统计</span>
            <el-form inline ref="batchActiveRateForm" v-model="batchActiveRateData" class="right themed-form search-form" style="height: 40px;line-height: 40px;">
                <el-form-item label="批次号">
                    <search-select :optionsMetaAll="optionsInCode" v-model="batchActiveRateData.inCode"></search-select>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="inCode"
                    label="入库批次"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="materielCode"
                    label="物料编号"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    label="产品名称"
                    min-width="120">
                    <template slot-scope="scope">
                        <span>{{dictMapTrans(scope.row.productName,dictMap.productName)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    label="产品类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <span>{{dictMapTrans(scope.row.productType,dictMap.productType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    label="业务类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <span>{{dictMapTrans(scope.row.businessType,dictMap.businessType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    label="产品制式"
                    min-width="120">
                    <template slot-scope="scope">
                        <span>{{dictMapTrans(scope.row.productStandard,dictMap.productStandard)}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    label="产品功能"
                    min-width="120">
                    <template slot-scope="scope">
                        <span>{{dictMapTrans(scope.row.productAbility,dictMap.productAbility)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    label="产品分组"
                    min-width="120">
                    <template slot-scope="scope">
                        <span>{{dictMapTrans(scope.row.productGroup,dictMap.productGroup)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="stockExpireTime"
                    label="储存到期时间"
                    min-width="150">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="cardFee"
                    label="卡板费"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="batchAmount"
                    label="入库数量"
                    min-width="120">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="giveOut4"
                    label="过去第4个月发卡数"
                    min-width="200">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="giveOut3"
                    label="过去第3个月发卡数"
                    min-width="200">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="giveOut2"
                    label="过去第2个月发卡数"
                    min-width="200">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="giveOut1"
                    label="过去第1个月发卡数"
                    min-width="200">
                </el-table-column>

                 <el-table-column
                :show-overflow-tooltip="true"
                    prop="active3"
                    label="过去第3个月激活卡数"
                    min-width="200">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="active2"
                    label="过去第2个月激活卡数"
                    min-width="200">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="active1"
                    label="过去第1个月激活卡数"
                    min-width="200">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rate4"
                    label="过去第4个月发卡激活率"
                    min-width="200">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rate3"
                    label="过去第3个月发卡激活率"
                    min-width="200">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rate2"
                    label="过去第2个月发卡激活率"
                    min-width="200">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rate1"
                    label="过去第1个月发卡激活率"
                    min-width="200">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rateSeason"
                    label="过去一个季度的发卡的激活率"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="expendTime"
                    label="剩余卡能消耗的月份"
                    min-width="200">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    label="风险"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if='scope.row.risk.indexOf("高危险")!= -1' style='color:red'>{{scope.row.risk}}</span>
                        <span v-else-if='scope.row.risk.indexOf("中危险")!= -1' style='color:red'>{{scope.row.risk}}</span>
                        <span v-else>{{scope.row.risk}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="operator"
                    label="操作人"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="createTime"
                    label="统计时间"
                    min-width="150">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="remark"
                    label="备注"
                    min-width="150">
                </el-table-column> 

                 <el-table-column
                    label="操作"
                    min-width="120">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleShowDetail(scope.row.inCode)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import {inCodeList,batchActiveRateList,exportActiveRate} from '@/api/stockManagement.js'
import resize from '@/mixins/resize.js'
export default {
    name:'batchActivationRate',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return {
            batchActiveRateData:{
                inCode:''
            },
            optionsInCode:[
                {label:'全部',value:''}
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getInCodeList()
        this.getBatchActiveRateList()
    },
    methods:{
        getInCodeList(){
            inCodeList().then(res=>{
                this.dictMap = res.dict
                let arrList = [{label:'全部',value:''}]
                res.data.forEach(item=>{
                    arrList.push({label:item.inCode,value:item.inCode})
                })
                this.optionsInCode = arrList
            })
        },
        dictMapTrans(val,dictGroup){
            let returnVal = val
            dictGroup.forEach(element=>{
                if(val == element.dictKey){
                    returnVal = element.dictValue
                }
            })
            return returnVal
        },
        getBatchActiveRateList(){
            let parame = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.batchActiveRateData,parame)
            batchActiveRateList(this.batchActiveRateData).then(res=>{
                this.tableData = res.data.rows
                this.total = res.data.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getBatchActiveRateList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getBatchActiveRateList()
        },
        onSearch(){
            this.page = 1
            this.getBatchActiveRateList()
        },
        exportExcel(){
            exportActiveRate(this.batchActiveRateData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        handleShowDetail(inCode){
            this.$router.push({
                path:'/stockManagement/stockInCardList',
                query:{"inCode":inCode}
            })
        },
    }
}
</script>
<style>
    .batch-active-rate .el-form-item__label{
        vertical-align: top;
    }
</style>