<template>
    <div class="order-rebate-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">返利列表</span>
            <el-form inline ref="rebateForm" v-model="rebateFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="代理商名称"> 
                    <search-select :optionsMetaAll="optionsAgentName" v-model="rebateFormData.agentId"></search-select>
                </el-form-item>

                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="起始日期" v-model="rebateFormData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="结束日期" v-model="rebateFormData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="agentTreeName"
                    label="代理商名称"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="agentName"
                    label="所属总代理商"
                    min-width="120">
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="startTime"
                    label="更新时间"
                    min-width="120">
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="endTime"
                    label="核算时间"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    prop="orderNum"
                    label="订单总数"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    prop="orderAmount"
                    label="订单总额"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    prop="refundMoney"
                    label="退款总额"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    prop="applyMoney"
                    label="可提现金额"
                    min-width="100">
                </el-table-column>
                <!-- <el-table-column
                    prop="deApplyMoney"
                    label="应扣返利总额"
                    min-width="100"> 
                </el-table-column> -->
                <el-table-column
                    label="状态"
                    min-width="100">
                     <template slot-scope="scope">
                        <span v-if="scope.row.status==1 || scope.row.status==4">待结算</span>
                        <span v-else-if="scope.row.status==2">申请结算</span>
                        <span v-else-if="scope.row.status==3">结算完成</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="checkName"
                    label="结算人"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="checkTime"
                    label="结算时间"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="150">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="primary" @click="handleShowDetail(scope.row.id,scope.row.agentId)">返利详情</el-button>
                        <!-- <el-button v-if="scope.row.status==4" size="mini" class="themed-button" type="primary" @click="applyMoney(scope.row.id)">提现</el-button> -->
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import resize from '@/mixins/resize.js'
import { getAgentList } from "@/api/agentManagement.js";
import {rebateList,applyMoney,exportRebate} from '@/api/order.js'
export default {
    name:'rebateList',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return{
            rebateFormData:{
                agentId:'',
                startTime:'',
                endTime:''
            },
            optionsAgentName:[{label:"全部",value:''}],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,

        }
    },
    created(){
        this.getAgentList()
        this.getRebateList()
    },
    methods:{
        getAgentList(){
            getAgentList().then(res=>{
                let arrList =[{label:"全部",value:""}]
                 res.forEach(element => {
                     arrList.push({label:element.name,value:element.id})
                 })               
                 this.optionsAgentName=arrList
            })
        },
        getRebateList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.rebateFormData,params)
            rebateList(this.rebateFormData).then(res=>{
                this.tableData = res.rows
                this.total=res.count
            })
        },
        handleSizeChange(size){
            this.pageSize = size
            this.getRebateList()
        },
        onPageChange(page){
            this.page = page
            this.getRebateList()
        },
        onSearch(){
            this.page = 1
            this.getRebateList()
        },
        exportExcel(){
            exportRebate(this.rebateFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        handleShowDetail(id,agentId){
            let params = {
                "agentId":agentId,
                'applyId':id,
                // "startTime":this.rebateFormData.startTime,
                // "endTime":this.rebateFormData.endTime
            }
            this.$router.push({path:'/orderManagement/agentProfitDetail',query:params})
        },
        // applyMoney(){
        //     this.$confirm('确定要提现吗？', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //     }).then(() => {
        //         applyMoney().then(res=>{
        //             this.$message({
        //             type: 'success',
        //             message: '提交成功!'
        //             });
        //             this.getRebateList()
        //         })
                
        //     }).catch(() => {
        //         this.$message({
        //         type: 'info',
        //         message: '已取消提现'
        //         });          
        // });
        // },
    }
}
</script>
<style>
    .order-rebate-list .el-form-item__label{
        vertical-align: top;
    }
</style>