<template>
	<div class="userActive">

		<div class="title-box clearfix">
			<span class="m-title">活跃用户统计</span>
			<el-form :inline="true" :model="formInline" class="right themed-form search-form"
				style="height: auto;line-height: 40px;">
				<el-form-item label="时间:">
					<el-date-picker v-model="formInline.time" type="month" style="width:100%"
						value-format="yyyy-MM">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="产品类型:">
					<el-select v-model="formInline.productType">
						<el-option value="">全部</el-option>
						<el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询
					</el-button>
				</el-form-item>

			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe style="width: 100%;" border ref="table" :max-height="maxTableHeight">
				<el-table-column prop="productType" label="支付类型" show-overflow-tooltip>
					<template v-slot:default="scope">
						{{_getProductType(scope.row.productType)}}
						<!-- {{getProductType(scope.row.productType)}} -->
					</template>
				</el-table-column>
				<el-table-column prop="newNum" label="新增用户数" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="loseNum" label="流失用户数" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="growNum" label="净增用户数" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="activeNum" label="当前活跃用户数" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="activationNum" label="累计激活用户数" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="createTime" label="统计时间" show-overflow-tooltip>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize"
				style="text-align: left;margin-top: 20px;" background layout="total,sizes,prev, pager, next,jumper"
				:total="total" @size-change="handleSizeChange" @current-change="onPageChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {
		userActive,
	} from "@/api/staticsManagement.js"
	import onresize from "@/mixins/resize.js"
	import dictGroup from '@/mixins/dictGroup.js'

	export default {
		name:'userActive',
		mixins: [onresize,dictGroup],
		data() {
			return {
				formInline: {
					time: "",
					productType: ""
				},
				tabledata: [],
				page: 1,
				pageSize: 15,
				total: 0,


			}
		},
		created() {
			this.userActiveList(this.formInline)
		},
		methods: {
			// getProductType(productType){
			// 	if(productType==1){
			// 		 return '大河卡'
			// 	}else if(productType==2){
			// 		return '手表卡'
			// 	} else if(productType==3){
			// 		return '大流量卡'
			// 	}
			// },
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.userActiveList(params)
			},
			userActiveList(params) {
				userActive(params).then(res => {
					this.tabledata = res.rows
					this.total = res.total
					this.page = res.pageNo
					this.pageSize = res.pageSize
				})
			},
			onPageChange(page) {
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.userActiveList(params)

			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				this.userActiveList(params)
			}
		}

	}
</script>

<style>
	
	.userActive  .el-form-item__label {vertical-align: top;}
</style>
