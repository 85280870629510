<template>
    <div class="sale-agent fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">销售代理商</span>
            <el-form inline ref="saleAgentForm" v-model="saleAgentFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
					<el-button icon="el-icon-circle-plus" class="themed-button" type="info" @click="add()">新增</el-button>
				</el-form-item>
            </el-form>
        </div>

         <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    label="销售名称"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span>{{scope.row.sale.saleName}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="代理商"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span>{{scope.row.agent.name}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    label="创建时间"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.createTime">{{formatTime(scope.row.createTime)}}</span>
                    </template>
                </el-table-column>
   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="新增" :visible.sync="addDialogFormVisible" width="500px" :destroy-on-close='true'>
            <el-form :model="addForm" :rules="rules" ref="addForm">
                <el-form-item label="销售" :label-width="formLabelWidth" prop="saleId">
                    <search-select :optionsMetaAll="sellerOperators" v-model="addForm.saleId" style="width:170px"></search-select>
                </el-form-item>
                <el-form-item label="代理商" :label-width="formLabelWidth" prop="agentId">
                    <search-select :optionsMetaAll="dailiOptions" v-model="addForm.agentId" style="width:170px"></search-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureAdd('addForm')">确 定</el-button>
                <el-button class="themed-button" type="primary" @click="addDialogFormVisible = false">关 闭</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import resize from '@/mixins/resize.js'
import {saleAgentList,daiLiList,allSaleList,addSaleAgent} from "@/api/saleCardBatch.js"
import Utils from '@/utils/utils.js'
export default {
    name:'salesAgent',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return {
            dailiOptions:{},
            sellerOperators:{},
            saleAgentFormData:{},
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            addDialogFormVisible:false,
            addForm:{
                saleId:'',
                agentId:'',
            },
            formLabelWidth:'150px',
            rules:{
                saleId:[
                    {required: true, message: '请选择销售', trigger: 'blur'}
                ],
                 agentId:[
                    {required: true, message: '请选择代理商', trigger: 'blur'}
                ],
            },
        }
    },
    created(){
        this.getSaleAgentList()
        this.getDaiLiOperatorList()
        this.getSellerOperators()
    },
    methods:{
        getDaiLiOperatorList(){
            daiLiList().then(res=>{
                 let arrList =[]
                 res.forEach(element => {
                     arrList.push({label:element.name,value:element.id})
                 })               
                 this.dailiOptions=arrList
             })
        },
        getSellerOperators(){
            allSaleList().then(res=>{
                 let arrList =[]
                 res.forEach(element => {
                     arrList.push({label:element.saleName,value:element.id})
                 })               
                 this.sellerOperators=arrList
             })
        },
        getSaleAgentList(){
            let params={
                pageNo:this.page,
                pageSize:this.pageSize
            }
            saleAgentList(params).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
        },
        add(){
            this.addDialogFormVisible=true
            this.$nextTick(()=>{
                this.$refs['addForm'].resetFields();
            })
            this.addForm.saleId = this.sellerOperators[0].value
            this.addForm.agentId = this.dailiOptions[0].value
        },
        sureAdd(formName){
            console.log(this.addForm.agentId,150)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    addSaleAgent(this.addForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '添加成功',
					        type: 'success',
							center: true
                        })
                        this.addDialogFormVisible=false
                        this.addForm.saleId = ''
                        this.addForm.agentId = ''
                        this.getSaleAgentList() 
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        onPageChange(page){
            this.page = page
            this.getSaleAgentList() 
		},
		handleSizeChange(size){
            this.pageSize = size
            this.getSaleAgentList() 
        },
    }
}
</script>