import request from './request.js'

// 自动续费界面
export function validateCard(params){
    return request({
        url: 'autoRecharge/validateCard.do?',
        method: 'post',
        data:params
    })
}
export function setIsAutoRecharge(params){
    return request({
        url: 'autoRecharge/set.do?',
        method: 'post',
        data:params
    })
}
export function getAgentSwitch(params){  //获取自动续费通知开关
    return request({
        url: 'agent/agentSwitch/getAgentSwitch.do?',
        method: 'post',
        data:params
    })
}
export function setAgentSwitch(params){  //设置自动续费通知
    return request({
        url: 'agent/agentSwitch/switchChange.do?',
        method: 'post',
        data:params
    })
}

//网卡列表页面
export function validateInfo(params){
    return request({
        url: 'autoRecharge/validateInfo.do?',
        method: 'post',
        data:params
    })
}
export function updateRecharge(params){
    return request({
        url: 'autoRecharge/update.do?',
        method: 'post',
        data:params
    })
}
export function ManualValidate(params){
    return request({
        url: 'recharge2/validate.do?',
        method: 'post',
        data:params
    })
}
export function getMaxAvailRechargePeriod(params){
    return request({
        url: 'recharge2/getMaxAvailRechargePeriod.do?',
        method: 'post',
        data:params
    })
}
export function rechargeCalculation(params){
    return request({
        url: 'recharge2/calculation.do?',
        method: 'post',
        data:params
    })
}
export function tobBatchRecharge(params){
    return request({
        url: 'recharge2/tobBatchRecharge.do?',
        method: 'post',
        data:params
    })
}
export function validateFailed(params){
    return request({
        url: 'recharge2/validate/failed.do?',
        method: 'post',
        data:params
    })
}
export function validateByCondition(params){
    return request({
        url: 'recharge2/validateByCondition.do?',
        method: 'post',
        data:params
    })
}

//续费服务
export function autoRechargeInfo(params){
    return request({
        url: 'renewal/autoRechargeInfo.do?',
        method: 'post',
        data:params
    })
}
export function manualRechargeInfo(params){
    return request({
        url: 'renewal/manualRechargeInfo.do?',
        method: 'post',
        data:params
    })
}
export function deliveredRechargeInfo(params){
    return request({
        url: 'renewal/deliveredRechargeInfo.do?',
        method: 'post',
        data:params
    })
}
export function totalRechargeInfo(){
    return request({
        url: 'renewal/totalRechargeInfo.do?',
        method: 'post'
    })
}
// 计费组管理界面
export function rechargePrice(params){  //获取流量池价格
    return request({
        url: 'pool/rechargePrice.do?',
        method: 'post',
        data:params
    })
}
export function addRefueling(params){  //流量池续费
    return request({
        url: 'pool/addRefueling.do?',
        method: 'post',
        data:params
    })
}
export function autoRecharge(params){  //自动续费池
    return request({
        url: 'pool/autoRecharge.do?',
        method: 'post',
        data:params
    })
}
export function getAutoRecharge(params){  //获取自动续池状态
    return request({
        url: 'pool/getAutoRecharge.do',
        method: 'post',
        data:params
    })
}
//历史续费记录界面
export function renewalRecordList(params){  
    return request({
        url: 'renewal/record/list.do?',
        method: 'post',
        data:params
    })
}
export function recordICCID(params){  //查看ICCID
    return request({
        url: 'renewal/record/iccids.do?',
        method: 'post',
        data:params
    })
}

//流量池叠加包
export function poolRefuelingList(params){  
    return request({
        url: 'poolRefueling/list.do?',
        method: 'post',
        data:params
    })
}
export function poolRefuelingDelete(params){ 
    return request({
        url: 'poolRefueling/delete.do?',
        method: 'post',
        data:params
    })
}
//续费导出 
export function exportCardInfo(params){
    return request({
      url: 'renewal/exportRechargeIccid.do?',
      method: 'post',
      params:params,
      
    })
  }