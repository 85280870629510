<template>
    <div class="network-cut-history fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">切网历史记录</span>
            <el-form inline ref="networkCutForm" v-model="networkCutFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="networkCutFormData['virtualCard.virtualIccid']" placeholder="虚拟号" class="iccid-width"></el-input>
                </el-form-item>
                 <el-form-item>
                    <el-input v-model="networkCutFormData['shortIccid']" placeholder="短号" ></el-input>
                </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                show-overflow-tooltip
                    label="虚拟号"
                    min-width="180"
                    >
                    <template slot-scope="scope">
                        <span>{{scope.row.virtualCard.virtualIccid}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                show-overflow-tooltip
                    label="短号"
                    min-width="130"
                    >
                    <template slot-scope="scope">
                        <span>{{scope.row.virtualCard.shortIccid}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM1"
                    min-width="180"
                    >
                    <template slot-scope="scope">
                        <span>{{getOperate(scope.row.virtualCard.iccidMark1)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM2"
                    min-width="180"
                    >
                    <template slot-scope="scope">
                        <span>{{getOperate(scope.row.virtualCard.iccidMark2)   }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="ESIM3"
                    min-width="180"
                    >
                    <template slot-scope="scope">
                        <span>{{getOperate(scope.row.virtualCard.iccidMark3)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="USIM1"
                    min-width="180"
                    >
                    <template slot-scope="scope">
                        <span>{{ getOperate(scope.row.virtualCard.iccidMark4)   }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="activeIccid"
                    label="在线卡号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="切换状态"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.changeStatus == '1'">成功</span>
                        <span v-else-if="scope.row.changeStatus == '0'">失败</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="changeReason"
                    show-overflow-tooltip
                    label="切换原因"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.changeReason == '1'">设备策略</span>
                        <span v-else-if="scope.row.changeReason == '2'">运营策略</span>
                        <span v-else-if="scope.row.changeReason == '3'">手动切网</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="changeTime"
                    label="切换时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="120">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
                
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import { networkCutHistory,exportNetworkCutHistory,deleteNetworkCutHistory } from "@/api/virtualCard.js"
export default {
    name:'networkCutHistory',
     mixins:[resize],
    data(){
        return {
            networkCutFormData:{
                'virtualCard.virtualIccid':'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getNetworkCutHistoryList()
    },
    methods: {
             getOperate(iccid){
        let str = iccid.substring(0,6)
        let operator="广电"
            if(str=="898600"||str=="898602"||str=="898604"||str=="898607" ||str=="898608"){
              operator ="移动"
            }
            if(str=="898601"||str=="898606"||str=="898609"){
              operator ="联通"
            }
            if(str=="898603"||str=="898611"){
              operator ="电信"
            }
            if(!!iccid  && iccid.length>=19){
            return iccid+"("+operator+")"
            }else{
             return ""
            }
            
        },
        getNetworkCutHistoryList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.networkCutFormData,params)
            networkCutHistory(this.networkCutFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getNetworkCutHistoryList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getNetworkCutHistoryList()
        },

        onSearch(){
            this.page=1
            this.getNetworkCutHistoryList()
        },

        exportExcel(){
            exportNetworkCutHistory(this.networkCutFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
         },

         handleDelete(id){
             this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    deleteNetworkCutHistory({id}).then(res=>{
                        this.$message({
								type: 'success',
								message:'删除成功'
                            });
                        this.getNetworkCutHistoryList()
                    })
                    
                })
         }

        
    },
}
</script>

<style>
    .network-cut-history .el-form-item__label{
        vertical-align: top;
    }
</style>