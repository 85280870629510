<template>

<div class="form-add">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
        <el-form-item label="批次编号:">
            <el-input v-model="form.inCode"  readonly></el-input>
        </el-form-item>
        <el-form-item label="产品名称:" required>
            <el-select v-model="form.productName" @change="changeInCode">
                <el-option v-for="(item,index) in productNameList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="业务类型:" required>
            <el-select v-model="form.businessType">
                <el-option v-for="(item,index) in businessTypeList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="产品类型:" required>
            <el-select v-model="form.productType" @change="changeInCode">
                <el-option v-for="(item,index) in productTypeList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="产品制式:" required>
            <el-select v-model="form.productStandard">
                <el-option v-for="(item,index) in productStandardList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="产品功能:" required>
            <el-select v-model="form.productAbility">
                <el-option v-for="(item,index) in productAbilityList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="产品分组:" required>
            <el-select v-model="form.productGroup">
                <el-option v-for="(item,index) in productGroupList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="仓库类别:" required>
            <el-select v-model="form.stockHouse">
                <el-option v-for="(item,index) in stockHouseList" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="备注:" prop="remark">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="采购时间:" prop='purchaseTime' required>
            <el-date-picker
                v-model="form.purchaseTime"
                type="date"
                value-format="MM/dd/yyyy"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="入库时间:" prop="stockTime" required>
            <el-date-picker
                v-model="form.stockTime"
                type="date"
                value-format="MM/dd/yyyy"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="开户时间:" prop="createAccountTime" required>
            <el-date-picker
                v-model="form.createAccountTime"
                type="date"
                value-format="MM/dd/yyyy"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="卡测试到期时间:" prop="testExpireTime" required>
            <el-date-picker
                v-model="form.testExpireTime"
                type="date"
                value-format="MM/dd/yyyy"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="卡库存到期时间:" prop="stockExpireTime" required>
            <el-date-picker
                v-model="form.stockExpireTime"
                type="date"
                value-format="MM/dd/yyyy"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="库存期时长:" prop="stockTerm" required>
            <el-input v-model="form.stockTerm"></el-input>
        </el-form-item>
        <el-form-item label="卡板费:" prop="cardFee" required>
            <el-input v-model="form.cardFee"></el-input>
        </el-form-item>
        <el-form-item label="续费单价:" prop="renewPrice" required>
            <el-input v-model="form.renewPrice"></el-input>
        </el-form-item>
        <el-form-item label="上传卡号列表（excel）:" required>
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
            </div>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel('form')">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {findById,getNewBatchCode,importAdd} from '@/api/stockManagement/stock.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    data(){
        var checkRemark = (rule, value, callback) => {
            if (value.length>32) {
                return callback(new Error('备注不能大于32个字符'));
            }else{
                callback()
            }
        };
        return {
            form:{
                flowNumber:'',
                inCode:'',
                productName:'',
                businessType:'',
                productType:'',
                productStandard:'',
                productAbility:'',
                productGroup:'',
                stockHouse:'',
                remark:'',
                purchaseTime:'',
                stockTime:'',
                createAccountTime:'',
                testExpireTime:'',
                stockExpireTime:'',
                stockTerm:'',
                cardFee:'',
                renewPrice:''
            },
            dictGroup:{},
            productNameList:[],
            businessTypeList:[],
            productTypeList:[],
            productStandardList:[],
            productAbilityList:[],
            productGroupList:[],
            stockHouseList:[],
            rules: {
                stockTerm: [
                    { required: true, message: '请输入库存期时长', trigger: 'blur' }
                ],
                cardFee: [
                    { required: true, message: '请输入卡板费', trigger: 'blur' }
                ],
                renewPrice: [
                    { required: true, message: '请输入续费单价', trigger: 'blur' }
                ],
                purchaseTime: [
                    { required: true, message: '请选择采购时间', trigger: 'blur' }
                ],
                businessType: [
                    { required: true, message: '请选择业务类型', trigger: 'blur' }
                ],
                stockTime: [
                    { required: true, message: '请选择入库时间', trigger: 'blur' }
                ],
                createAccountTime: [
                    { required: true, message: '请选择开户时间', trigger: 'blur' }
                ],
                testExpireTime: [
                    { required: true, message: '请选择卡测试到期时间', trigger: 'blur' }
                ],
                stockExpireTime: [
                    { required: true, message: '请选择卡库存到期时间', trigger: 'blur' }
                ],
                remark: [
                    { validator: checkRemark, trigger: 'blur' }
                ]
            },
        }
    },
    mounted(){
        this._getNewBatchCode()
    },
    methods:{
        uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    console.log(11111)
                    console.log(formName)
                    console.log(this.$refs[formName])
                    console.log(this.$refs[formName].validate())
                    this.$refs[formName].validate((valid) => {
                        console.log(1)
                        console.log(valid)
                        if (valid) {
                            // alert('submit!');
                            console.log(111)
                            let params = new FormData()
                            params.append('fileurl',file)
                            params.append('flowNumber',this.form.flowNumber)
                            params.append('inCode',this.form.inCode)
                            params.append('productName',this.form.productName)
                            params.append('businessType',this.form.businessType)
                            params.append('productType',this.form.productType)
                            params.append('productStandard',this.form.productStandard)
                            params.append('productAbility',this.form.productAbility)
                            params.append('productGroup',this.form.productGroup)
                            params.append('stockHouse',this.form.stockHouse)
                            params.append('remark',this.form.remark)
                            params.append('purchaseTime',this.form.purchaseTime)
                            params.append('stockTime',this.form.stockTime)
                            params.append('createAccountTime',this.form.createAccountTime)
                            params.append('testExpireTime',this.form.testExpireTime)
                            params.append('stockExpireTime',this.form.stockExpireTime)
                            params.append('stockTerm',this.form.stockTerm)
                            params.append('cardFee',this.form.cardFee)
                            params.append('renewPrice',this.form.renewPrice)
                            importAdd(params).then(res=>{
                                if (res.system_result_key == '0') {
                                    this.$alert('导入成功，请到导入记录中下载', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.$emit('close-dialog')
                                        this.$router.push('/tob/i_import_list')
                                    });
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                }
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        importExcel(){
            let name = 'import.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        changeInCode(){
            let productType = ''
            this.dictGroup.productType.forEach((item)=>{
                if(item.dictKey == this.form.productType){
                    productType = item.dictValue
                    return 
                }
            })
            this.form.inCode = this.form.productName + productType + this.form.flowNumber
        },
        _findById(){
            findById(this.form).then((res)=>{
                console.log(res)
                this.dictGroup = res.dict
                this.productNameList = res.dict.productName
                this.businessTypeList = res.dict.businessType
                this.productTypeList = res.dict.productType
                this.productStandardList = res.dict.productStandard
                this.productAbilityList = res.dict.productAbility
                this.productGroupList = res.dict.productGroup
                this.stockHouseList = res.dict.stockHouse

                this.form.productName = res.dict.productName[0]['dictKey']
                this.form.businessType = res.dict.businessType[0]['dictKey']
                this.form.productType = res.dict.productType[0]['dictKey']
                this.form.productStandard = res.dict.productStandard[0]['dictKey']
                this.form.productAbility = res.dict.productAbility[0]['dictKey']
                this.form.productGroup = res.dict.productGroup[0]['dictKey']
                this.form.stockHouse = res.dict.stockHouse[0]['dictKey']
                this.changeInCode();
            })
        },
        _getNewBatchCode(){
            getNewBatchCode(this.form).then((res)=>{
                this.form.flowNumber = res.data
                console.log(res)
                this._findById()
            })
        }
    }
}
</script>

