<template>

<div class="table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">网卡回收</span>
        <el-form inline class="hook right themed-form search-form" style="height: 40px;line-height: 40px;">
            <!-- <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item> -->
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="importRecord">批量回收</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="导入总数" prop="importTotal"></el-table-column>
            <el-table-column label="成功数" prop="importSuccess"></el-table-column>
            <el-table-column label="失败数" prop="importFail"></el-table-column>
            <el-table-column label="操作时间" prop="createDatetime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template  slot-scope="scope">
                    <el-button type="primary" class="themed-button" @click="loadFail(scope.row.id)">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>


    <el-dialog title="网卡回收" :visible.sync="dialogBox">
        <el-form label-position="right" label-width="120px" :model="form" ref="form" v-loading="dialogLoading">
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">确认</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">

import {importList,importExcel} from '@/api/cardManagement/batchCard.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import resize from '@/mixins/resize.js'

export default {
    name:'batchCard',
    mixins:[resize],
    data(){
        return {
            dialogLoading:false,
            type:"",
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogBox:false,
            form:{}
        }
    },
    mounted(){
        this._importList()
    },
    methods:{
        exportTemplateExcel(){
            // let name = 'sim_recycle.xls'
            let   name ="importIccid.xls"
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('type','10')
                    params.append('fromRange',false)
                    let url = "record/cardRecycleRecord/cardRecycleByExcelSync.do?"
                    importExcel(url,params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        loadFail(isId){
            this.$router.push({
                path: "/cardManagement/batchCardFailList",
                query: {isId},
            })
        },
        
        searchTable(){
            this._importList()
        },
        importRecord(){
            this.dialogBox = true
        },
        handleSizeChange(val){
            this.pageSize = val
            this._importList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._importList()
        },
        _importList(){
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            params.type = '10'
            importList(params).then((res) => {
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    },
}
</script>
