<template>

<div class="useraddress table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">网卡更换</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="状态:">
                <el-select v-model="form.isChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已发货" value="1"></el-option>
                    <el-option label="准备中" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="卡号">
                <el-input v-model="form.iccidMark" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="新卡号">
                <el-input v-model="form.newIccidMark" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="收货人手机">
                <el-input v-model="form.userPhone"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">导出Excel</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="importExcel">导入Excel</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="setTip">网卡更换提示</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight" >
            <el-table-column label="卡号" prop="iccidMark" width="180px"> </el-table-column>
            <el-table-column label="新卡" prop="newIccidMark" show-overflow-tooltip></el-table-column>
            <el-table-column label="当前套餐" prop="currentMealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="过期时间" prop="currentExpire" show-overflow-tooltip></el-table-column>
            <el-table-column label="代理商" prop="agentThreeName" show-overflow-tooltip></el-table-column>
            <el-table-column label="收货人" prop="userName" show-overflow-tooltip></el-table-column>
            <el-table-column label="收货人手机" prop="userPhone" show-overflow-tooltip></el-table-column>
            <el-table-column label="收货地址" prop="userAddress" show-overflow-tooltip></el-table-column>
            <el-table-column label="提交时间" prop="createDateString" show-overflow-tooltip></el-table-column>
            <el-table-column label="物流公司" prop="logisticsCompany" show-overflow-tooltip></el-table-column>
            <el-table-column label="物流单号" prop="logisticsNumber" show-overflow-tooltip></el-table-column>
            <el-table-column label="发货状态" prop="isChangeString" show-overflow-tooltip></el-table-column>
            <el-table-column label="审核状态" prop="statusString" show-overflow-tooltip></el-table-column>
            <el-table-column label="订单号" prop="orderNumber" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" prop="expireDate">
                <template slot-scope="scope">
                    <el-button type="primary" class="themed-button"  @click='updateStatus(scope.row.id)'>处理</el-button>
                    
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog  title="替换卡导入" :visible.sync="dialogImportCard">
        <el-form label-position="right" label-width="200px">
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">导入excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogImportCard = false">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog  title="网卡更换设置提示" :visible.sync="dialogSetTip">
        <el-form label-position="right" label-width="200px" :model="tipForm" :rules="rulesTip" ref="tipForm">
            <el-form-item label="状态:" required prop="status">
                <el-radio-group v-model="tipForm.status">
                    <el-radio :label="1">启用</el-radio>
                    <el-radio :label="2">禁用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="内容:" required prop="content">
                <el-input type="textarea"  v-model="tipForm.content"></el-input>
                <p>{{tipForm.content.length}}/200字</p>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="sureTip('tipForm')">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogSetTip = false">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog  title="修改状态" :visible.sync="dialogUpdate">
        <el-form label-position="right" label-width="200px" :model="updateForm" ref="updateForm">
            <el-form-item label="换卡sim号:" required>
                <el-input  v-model="updateForm.iccidMark" ></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名:" required>
                <el-input  v-model="updateForm.userName" ></el-input>
            </el-form-item>
            <el-form-item label="收货人电话:" required>
                <el-input  v-model="updateForm.userPhone" ></el-input>
            </el-form-item>
            <el-form-item label="收货人地址:" required>
                <el-input  v-model="updateForm.userAddress" ></el-input>
            </el-form-item>
            <el-form-item label="新sim号:" required>
                <el-input  v-model="updateForm.newIccidMark" ></el-input>
            </el-form-item>
            <el-form-item label="物流公司:" required>
                <el-input  v-model="updateForm.logisticsCompany" ></el-input>
            </el-form-item>
            <el-form-item label="物流单号:" required>
                <el-input  v-model="updateForm.logisticsNumber" ></el-input>
            </el-form-item>
            <el-form-item label="处理结果:" required>
                <el-radio-group v-model="updateForm.status">
                    <el-radio :label="1">通过</el-radio>
                    <el-radio :label="2">拒绝</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="处理详情:" required>
                <el-input  v-model="updateForm.refundRemark" ></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="sureUpdate('updateForm')">确定</el-button>
            <el-button type="primary" class="themed-button" @click="resetUpdate('updateForm')">重置</el-button>
            <el-button type="primary" class="themed-button" @click="dialogUpdate = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {useraddress,exportExcel_,importCard,importCardSync,findById,update} from '@/api/cardManagement/useraddress.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import resize from '@/mixins/resize.js'
export default {
    name:'useraddress',
    mixins:[resize],
    data(){
        return {
            form:{
                isChange:'',
                iccidMark:'',
                newIccidMark:'',
                userPhone:''
            },
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogImportCard:false,
            dialogSetTip:false,
            tipForm:{
                status:1,
                content:''
            },
            rulesTip:{
                content:[
                    {require:true,massage:'请填写提示内容',trigger: 'blur'}
                ]
            },
            dialogUpdate:false,
            updateForm:{
                iccidMark:'',
                userName:'',
                userPhone:'',
                userAddress:'',
                newIccidMark:'',
                logisticsCompany:'',
                logisticsNumber:'',
                status:1,
                refundRemark:'',
            },
            resetForm:{
                iccidMark:'',
                userName:'',
                userPhone:'',
                userAddress:'',
                newIccidMark:'',
                logisticsCompany:'',
                logisticsNumber:'',
                status:1,
                refundRemark:'',
            }
        }
    },
    mounted(){
        this._useraddress()
    },
    methods:{
        setTip(){
            this.dialogSetTip = true
        },
        sureTip(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    importCard(this.tipForm).then((res)=>{
                        if(res.slide_msg_key==1){
                            messageBox(this,res.slide_msg_message_key);
                        }
                        this.dialogSetTip = false
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        importExcel(){
            this.dialogImportCard = true
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    importCardSync(params).then((res)=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogImportCard = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        exportExcel(){
            let params = {name:'网卡替换详情表'}

            exportExcel_(Object.assign(params, this.form)).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        searchTable(){
            this._useraddress()
        },
        updateStatus(id){
            this.dialogUpdate = true
            findById({id,status:1}).then((res)=>{
                res = res.entity
                this.updateForm.iccidMark = res.iccidMark
                this.updateForm.userName= res.userName
                this.updateForm.userPhone= res.userPhone
                this.updateForm.userAddress= res.userAddress
                this.updateForm.newIccidMark= res.newIccidMark
                this.updateForm.logisticsCompany= res.logisticsCompany
                this.updateForm.logisticsNumber= res.logisticsNumber
                this.updateForm.status= parseInt(res.status)
                this.updateForm.refundRemark= res.refundRemark

                this.resetForm.iccidMark = res.iccidMark
                this.resetForm.userName= res.userName
                this.resetForm.userPhone= res.userPhone
                this.resetForm.userAddress= res.userAddress
                this.resetForm.newIccidMark= res.newIccidMark
                this.resetForm.logisticsCompany= res.logisticsCompany
                this.resetForm.logisticsNumber= res.logisticsNumber
                this.resetForm.status= parseInt(res.status)
                this.resetForm.refundRemark= res.refundRemark
            })
        },
        sureUpdate(){
            update(this.updateForm).then((res)=>{
                if (res.slide_msg_key == 1) {
                    messageBox(this,res.slide_msg_message_key);
                }
                this.dialogUpdate = false
            })
        },
        resetUpdate(){
            this.updateForm.iccidMark = this.resetForm.iccidMark
            this.updateForm.userName= this.resetForm.userName
            this.updateForm.userPhone= this.resetForm.userPhone
            this.updateForm.userAddress= this.resetForm.userAddress
            this.updateForm.newIccidMark= this.resetForm.newIccidMark
            this.updateForm.logisticsCompany= this.resetForm.logisticsCompany
            this.updateForm.logisticsNumber= this.resetForm.logisticsNumber
            this.updateForm.status= this.resetForm.status
            this.updateForm.refundRemark= this.resetForm.refundRemark
        },
        handleSizeChange(val){
            this.pageSize = val
            this._flowmonitorList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._flowmonitorList()
        },
        _useraddress(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            useraddress(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        }
    }
}

</script>
