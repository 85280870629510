<template>
  <div class="fadeInRight purchaseRecord">
     <div class="noRenZhen" v-if="showRZ">
			<p>因运营商规则，在线购卡需进行企业相关资料及法人信息审核， 提交资料后即可购卡</p>
			<p>企业信息认证  <span>{{renZhenStatus}}</span></p>
			<div>
				<el-button type="warning" class="warning"  @click="toRenZhen">去认证</el-button>
			</div>
		</div>
    <div v-else>
  <div class="title-box clearfix">
      <span class="m-title">购卡记录</span>
      <el-form
        inline
        class="right themed-form search-form"
        style="line-height: 40px"
      >
        <el-form-item>
          <el-input
            placeholder="输入订单号"
            v-model="searchForm.orderNum"
            class="iccid-width"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input placeholder="输入姓名" v-model="searchForm.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="输入联系电话"
            v-model="searchForm.phone"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-input
            placeholder="输入地址信息"
            v-model="searchForm.addressInfo"
            class="iccid-width"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-select v-model="searchForm.status" placeholder="选择订单状态">
            <el-option label="选择订单状态" value=""></el-option>
            <el-option label="待支付" value="1"></el-option>
            <el-option label="待审核" value="2"></el-option>
            <el-option label="待发货" value="3"></el-option>
            <el-option label="审核未通过" value="4"></el-option>
            <el-option label="已发货" value="5"></el-option>
            <el-option label="完成" value="6"></el-option>
            <el-option label="退款" value="7"></el-option>
            <el-option label="已关闭" value="8"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <div class="purchaseRecordHeader">
        <div class="recordHeaderOne">
          <span class="table_header_cell flex-3">订单类型</span>
          <span class="table_header_cell flex-2">套餐价格</span>
          <span class="table_header_cell flex-1">总周期</span>
          <span class="table_header_cell flex-1">沉默期</span>
          <span class="table_header_cell flex-1">单张卡费</span>
          <span class="table_header_cell flex-1">卡片数量</span>
        </div>
        <div class="recordHeaderTwo">
          <span class="table_header_cell flex-4">订单价格</span>
          <span class="table_header_cell flex-4">订单状态</span>
          <span class="table_header_cell flex-3">操作</span>
        </div>
      </div>
      <div class="purchaseRecordContent">
        <div v-if="orderList.length==0" style="text-align:center">
           暂无数据
        </div>
        <div v-else
          class="record_content"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <div class="record_content_title">
            {{ item.createTime + " 订单号:" + item.orderNum }}
          </div>
          <div class="record_content_line">
            <div class="record_content_item">
              <div
                class="segment_item"
                v-for="(info, index) in item.itemList"
                :key="index"
              >
                <div class="flex-3">
                  <div>
                    <span>{{ info.productName }}</span
                    >&nbsp;
                    <span>{{
                      _getOperatorName(info.accountType, w_operator_list)
                    }}</span
                    >&nbsp;
                    <span v-if="info.mealType == 1">按月套餐</span>
                    <span v-if="info.mealType == 2">累计套餐</span>
                  </div>
                </div>
                <div class="flex-2">
                  ￥
                  <span> {{ info.mealPrice }} </span>
                </div>
                <div class="flex-1">
                  <span>{{ info.subscribeCount }}月</span>
                </div>
                <div class="flex-1">{{ info.silentCount }}月</div>
                <div class="flex-1">￥{{ info.singleFee }}</div>
                <div class="flex-1">
                  <el-button
                    v-if="item.status == '5' || item.status == '6'"
                    type="text"
                    class="link"
                    @click="exportDetail(item.orderNum)"
                    >{{ info.cardCount }}张</el-button
                  >
                  <span v-else>{{ info.cardCount }}张</span>
                </div>
              </div>
            </div>
            <div class="record_content_itemTwo">
              <div class="flex-4">
                ￥{{ item.totalFee }}
                <br />
                <span>(含运费：￥{{ item.expressFee }})</span>
              </div>
              <div class="flex-4">
                <div>{{ getStatus(item.status) }}</div>
              </div>
              <div class="flex-3">
                <div>
                  <el-button type="text" class="text"  @click="getDetaile(item.orderNum)"
                    >查看详情</el-button
                  >
                </div>
                <div v-if="item.status == 5 || item.status == 6">
                  <el-button type="text" class="text" @click="getCardInfo(item.orderNum,item.itemList[0].accountType)"
                    >查看卡信息</el-button
                  >
                </div>
               
                  <div v-if="item.status != 1">
                  <el-button type="text" class="text" @click="buyAgain(item.orderNum)"
                    >再次购买</el-button
                  >
                </div>
                <div v-if="item.status == 1">
                  <el-button
                    type="warning" class="warning" 
                    @click="paymentImm(item.totalFee, item.orderNum)"
                    >立即支付</el-button
                  >
                </div>
                 <el-button v-if="item.status==5"
             type="warning" class="warning" 
               @click="confirmRev(item.orderNum)"
              >确认收货</el-button
            >
                <div v-if="item.status == 1">
                  <el-button type="text" class="text"  @click="cancelOrer(item.orderNum)"
                    >取消订单</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="purchaseRecordFooter">
        <el-pagination
          :current-page.sync="page"
          :page-sizes="[10, 15, 25, 100]"
          :page-size="pageSize"
          style="text-align: center; margin-top: 20px"
          background
          layout="total,sizes,prev, pager, next,jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="onPageChange"
        >
        </el-pagination>
      </div>
    </div>
    </div>
  
    <el-dialog title="支付订单" :visible.sync="dialogVisible" width="30%">
      <div class="dialogItem">
        应付金额：<span class="pay_should">{{ shouldPay }}</span>
      </div>
      <div class="dialogItem">
        账户余额：<span>{{ accountBalance }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="surePay" :disabled="isSufficient"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import {
  orderList,
  exportOrderCardList,
  payOrder,
  getLeftMoney,
  cancelOrder,
  getOrderpageList,
  confirmRev
} from "@/api/onlinePurchase.js";
import dictGroup from "@/mixins/dictGroup.js";
import store from "@/store";
export default {
  name: "purchaseRecord",
  mixins: [onresize, dictGroup],
  data() {
    return {
      searchForm: {
        addressInfo: "",
        orderNum: "",
        status: "",
        createTimeBegin: "",
        createTimeEnd: "",
      },
      time: [],
      tabledata: [],
      page: 1,
      pageSize: 15,
      total: 0,
      orderList: [
        // {
        //   time: "2022-10-13 20:16:37",
        //   orderNo: "202210132016375343",
        //   packageList: [
        //     {
        //       package: "100M/月",
        //       operator: "中国联通",
        //       packageType: " 按月套餐",
        //       packagePrice: "25.20/36个月",
        //       totalPeriod: "3",
        //       clientPeriod: "3",
        //       singleCardPrice: "4.50",
        //       cardNum: 1,
        //     },
        //     {
        //       package: "100M/月",
        //       operator: "中国联通",
        //       packageType: " 按月套餐",
        //       packagePrice: "25.20/36个月",
        //       totalPeriod: "3",
        //       clientPeriod: "3",
        //       singleCardPrice: "4.50",
        //       cardNum: 1,
        //     },
        //   ],
        //   orderPrice: "4.15", //订单价格
        //   fare: "6.80",
        //   status: "已关闭",
        // },
      ],
      dialogVisible: false,
      isSufficient: false, //余额是否够付款
      shouldPay: "",
      accountBalance: "",
      currentOrderNum: "",
      showRZ:true,
      renZhenStatus:'',
    };
  },
  created() {
    if(store.getters.currentType== 2){
  if (store.getters.authStatus == 0) {
      //未认证
      this.renZhenStatus = "待认证";
      this.showRZ = true
    } else if (store.getters.authStatus == 1) {
      //审核通过
      this.renZhenStatus = "审核通过";
      this.showRZ = false
    } else if (store.getters.authStatus == 2) {
      //审核失败
      this.renZhenStatus = "审核失败"; 
      this.showRZ = true
    }
    }else{
       this.renZhenStatus = "审核通过";
      this.showRZ = false
    }
    
    this.getOrderList();
  },
  watch: {},

  computed: {},
  methods: {
     toRenZhen(){
				this.$router.push({path:'/enterprise/AccountSettings',query:{id:"quality"}})
			},
     confirmRev(orderNum){
       confirmRev({orderNum}).then(res=>{
            this.getOrderList();
          this.$message({
            type: "success",
            message: '确认收货成功',
          })
       })
    },
    getStatus(status) {
      let statusStr = "";
      if (status == 1) {
        statusStr = "待支付";
      } else if (status == 2) {
        statusStr = "待审核";
      } else if (status == 3) {
        statusStr = "待发货";
      } else if (status == 4) {
        statusStr = "审核未通过";
      } else if (status == 5) {
        statusStr = "已发货";
      } else if (status == 6) {
        statusStr = "完成";
      } else if (status == 7) {
        statusStr = "退款";
      } else if (status == 8) {
        statusStr = "已关闭";
      }
      return statusStr;
    },
    getOrderList() {
      //this.searchForm
        let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.searchForm, params);
      getOrderpageList(this.searchForm).then((res) => {
        console.log(res);
        this.orderList = res.rows;
        this.total = res.count;
      });
    },
    onSearch() {
       this.page = 1;
      this.getOrderList()
    },
    timeChange(val) {
      console.log(val);
      this.searchForm.createTimeBegin = val[0];
      this.searchForm.createTimeEnd = val[1];
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getOrderList()
      
    },
    onPageChange(page) {
      this.page = page;
      this.getOrderList()
      
    },
    exportDetail(num) {
      //下载订单卡信息
      exportOrderCardList({ orderNum: num }).then((res) => {
        console.log(res)
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    cancelOrer(orderNum) {
      this.$confirm("此操作将取消此订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cancelOrder({ orderNum }).then((res) => {
            this.$message({
              type: "success",
              message: "取消成功!",
            });
            this.getOrderList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "未取消订单",
          });
        });
    },
    buyAgain(orderNum) {  //暂时未开放 路径需要修改
      //再次购买 跳转到购物车界面
      this.$router.push({
        // path: "/onlinePurchase/shoppingCart",
        path: "/shopping/shoppingCart",
        query: { orderNum },
      });
    },
    getCardInfo(orderNum,accountType) {
      //跳转到卡信息界面
      this.$router.push({ path: "/enterpriseCard/listenterprise",query:{orderNum,accountType:accountType} });
    },
    getDetaile(orderNum) {
      ////跳转到订单详情
      this.$router.push({
        path: "/shopping/orderDetails",
        query: { orderNum },
      });
    },
    paymentImm(price, num) {
      this.dialogVisible = true;
      this.shouldPay = price; //判断是否够付 显示按钮状态
      this.currentOrderNum = num;
      getLeftMoney({}).then((res) => {
        console.log(res.balanceMoney);
        this.accountBalance = res.balanceMoney;
        if (parseFloat(this.accountBalance) >= parseFloat(this.shouldPay)) {
          this.isSufficient = false;
        } else {
          this.isSufficient = true;
        }
      });
    },
    surePay() {
      payOrder({ orderNum: this.currentOrderNum }).then((res) => {
        this.$message({
          type: "success",
          message: "支付成功",
        });
        this.dialogVisible = false;
        this.getOrderList()
      });
    },
  },
};
</script>

<style>
.dialogItem .pay_should {
  font-size: 18px;
  color: #f65623;
}
.purchaseRecord .dialogItem {
  font-size: 15px;
  margin: 20px 0;
}
.purchaseRecord .purchaseRecordContent {
  margin-top: 20px;
  min-height: 200px;
  width: 100%;
}
.purchaseRecord .record_content_item {
  width: 60%;
  border-right: 1px solid #ddd;
}
.purchaseRecord .record_content {
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}
.purchaseRecord .record_content_title {
  padding: 0 20px;
  background-color: #f5f8fa;
  height: 40px;
  line-height: 40px;
}
.purchaseRecord .purchaseRecordHeader,
.purchaseRecord .segment_item {
  display: flex;
  width: 100%;
    height: 45px;
    line-height: 45px;
}
.purchaseRecord .purchaseRecordHeader{ background-color: #f5f8fa;}
.purchaseRecord .segment_item > div {
  line-height: 40px;
}
.purchaseRecord .record_content_itemTwo > div:first-child {
  border-left: none;
}
.purchaseRecord .record_content_itemTwo > div {
  border-left: 1px solid #ddd;
}
.purchaseRecord .record_content_itemTwo > div,
.purchaseRecord .segment_item > div {
  min-height: 40px;
  display: inline-block;
  border-top: 1px solid #ddd;
  margin: 0;
  padding: 15px 0;
  letter-spacing: 0;
  text-align: center;
  vertical-align: top;
}
.record_content .record_content_line {
  width: 100%;
  display: flex;
  letter-spacing: -0.5em;
}
.recordHeaderOne {
  display: flex;
  width: 60%;
}
.purchaseRecord .recordHeaderTwo,
.purchaseRecord .record_content_itemTwo {
  display: flex;
  width: 40%;
}
.purchaseRecordHeader .table_header_cell {
  display: block;
  text-align: center;
  letter-spacing: 0;
}
.purchaseRecord .flex-4 {
  flex: 4;
}
.purchaseRecord .flex-3 {
  flex: 3;
}
.purchaseRecord .flex-2 {
  flex: 2;
}
.purchaseRecord .flex-1 {
  flex: 1;
}
.noRenZhen{line-height: 60px; color:#999; text-align: center;}
.text.el-button--text{color:#ff6b01}
.purchaseRecord .search-form .el-range-editor--small.el-input__inner{height:26px;line-height: 26px;margin-top: 3px;}
</style>
