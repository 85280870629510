<template>

<div class="rickArealist table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">高危地区管理</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addRickArea">新增高危地区</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="省"  prop="province" min-width="120"></el-table-column>
            <el-table-column label="市" min-width="120" prop="city" show-overflow-tooltip></el-table-column>
            <el-table-column label="区" prop="town" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="运营商" prop="accountName" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="更新时间" prop="createTime" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="激活时间" prop="activeTime" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="操作" min-width="200">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button"  @click="handleUpdate(scope.row)">编辑</el-button>
                    <el-button type="danger" class="themed-button"  @click="deleteById(scope.row.id)">删除</el-button>
                    <el-button type="primary" class="themed-button"  @click="whiteListDetail(scope.row.id)">白名单详情</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>


    <el-dialog :title="title" :visible.sync="dialogBox">
        <el-form label-position="right" label-width="200px" :model="form">
            <el-form-item label="省份">
                <el-select v-model="form.province" @change="selProvince">
                    <el-option label="请选择" value=""></el-option>  
                    <el-option v-for="(item,index) in provinceList" :label="item.name" :value="item.name" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="市级">
                <el-select v-model="form.city" @change="selCity">
                    <el-option label="请选择" value=""></el-option>   
                    <el-option v-for="(item,index) in cityList" :label="item.name" :value="item.name" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="地区">
                <el-select v-model="form.town">
                    <el-option label="请选择" value=""></el-option>  
                    <el-option v-for="(item,index) in areaList" :label="item" :value="item" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="运营商">
                <el-select v-model="form.accountIds" multiple collapse-tags  filterable placeholder="请选择运营商" style="width:250px">
                    <el-option v-for="(item,index) in accountList" :key="index" :label="item.accountName" :value="item.id"></el-option>
                </el-select>
			</el-form-item>

            <el-form-item label="激活时间">
                <el-date-picker v-model="form.activeTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">提交</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {getAccountList} from '@/api/cardManagement/cardBill.js'
import {cardriskarea,del,add,update} from '@/api/cardManagement/rickArealist.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import resize from '@/mixins/resize.js'

import {provinceList} from '@/api/area.js'

export default {
    name:'rickArealist',
    mixins:[resize],
    data(){
        return {
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            form:{
                id:'',
                province:'',
                city:'',
                town:'',
                activeTime:'',
                accountIds:[],
            },
            provinceList:provinceList,
            cityList:[],
            areaList:[],
            accountList:[],
            title:'',
        }
    },
    mounted(){
        this._cardriskarea()
        this.getAccountList()
    },
    methods:{
        getAccountList(){
            getAccountList().then(res=>{
                this.accountList = res        
             })
        },
        whiteListDetail(riskAreaId){
            this.$router.push({
                path: '/cardManagement/whiteList',
                query: {
                    riskAreaId
                }
        })
        },
        selProvince(val){
            this.provinceList.forEach((item)=>{
                if(val === item.name){
                    this.cityList = item.cityList
                }
            })
        },
        selCity(val){
            this.cityList.forEach((item)=>{
                if(val === item.name){
                    this.areaList = item.areaList
                }
            })
        },
        deleteById(id){
            confirmBox(this,'确定要删除吗？','消息提示',()=>{
                del({id,importType:2}).then((res)=>{
                   messageBox(this,'删除成功') 
                   this._cardriskarea()
                })
            })
        },
        addRickArea(){
            this.dialogBox = true
            this.title = '添加高危地区'
            this.resetForm()
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },

        handleUpdate(row){
            this.dialogBox = true
            this.title = '编辑'
            this.form.province = row.province
            this.form.city = row.city
            this.form.town = row.town
            this.form.activeTime = row.activeTime
            this.form.accountIds = row.accountIds
            this.form.id = row.id
        },

        resetForm(){
            this.form ={
                id:'',
                province:'',
                city:'',
                town:'',
                activeTime:'',
                accountIds:[],
            }
        },

        comfirmImport(){
            if(this.form.province === ''){
                messageBox(this,'请选择省份') 
                return
            }
            if(this.title=='添加高危地区'){
                let params = Object.assign(this.form,{jihuotype:2})
                add(params).then((res)=>{
                if (res.slide_msg_key == 1) {
                    messageBox(res.slide_msg_message_key);
                }
                this.dialogBox = false
                this._cardriskarea()
                })
            }else{
                update(this.form).then(res=>{
                    messageBox(this,'更新成功')
                    this.dialogBox = false
                    this._cardriskarea()
                })
            }
            
        },
        handleSizeChange(val){
            this.pageSize = val
            this._cardriskarea()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._cardriskarea()
        },
        _cardriskarea(){
            let params = {importType:2,pageNo:this.pageNo,pageSize:this.pageSize}
            cardriskarea(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        }
    },
    components:{
        SearchSelect
    }
}

</script>
