/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from '../request.js'
export function bindImei2List(params){
    return request({
        url: 'bindImei2/list.do?',
        method: 'get',
        params:params
    })
}

export function updateBindImei2(params){
    return request({
        url: 'bindImei2/updateImei.do?',
        method: 'get',
        params:params
    })
}


