<template>
  <div class="trafficPoolDeductionRecord fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">扣费记录</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="流量池:">
          <el-select
            v-model="formInline.poolId"
            filterable
            placeholder="请选择流量池"
            clearable
          >
            <el-option
              v-for="(item, index) in carPoolLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易类型:">
          <el-select
            v-model="formInline.tradeType"
            filterable
            placeholder="请选择交易类型"
            clearable
          >
            <el-option label="入池" value="1"></el-option>
            <el-option label="池扣费" value="2"></el-option>
            <el-option label="单卡扣费" value="3"></el-option>
            <el-option label="停机保号费" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号:">
          <el-input
            v-model="formInline.iccid"
            placeholder="请输入卡号"
            clearable
          ></el-input>
        </el-form-item>
          <el-form-item label="订单号:">
          <el-input
            v-model="formInline.orderNumber"
            placeholder="请输入订单号"
            clearable
          ></el-input>
        </el-form-item>
         <el-form-item label="导入时间:">
                <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="formInline.startTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>至</el-form-item>
            <el-form-item>
                <el-date-picker placeholder="选择日期" value-format="yyyy-MM-dd" v-model="formInline.endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="exportList"
            icon="el-icon-plus"
            class="themed-button"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="orderNumber" label="订单号" min-width="190"> </el-table-column>
        <el-table-column prop="tradeType" label="交易类型" min-width="120">
            <template slot-scope="scope">
            <span v-if="scope.row.tradeType == '1'">入池</span>
            <span v-else-if="scope.row.tradeType == '2'">池扣费</span>
            <span v-else-if="scope.row.tradeType == '3'">单卡扣费</span>
              <span v-else-if="scope.row.tradeType == '4'">停机保号费</span>
          </template>  
             </el-table-column>
        <el-table-column prop="tradeMoney" label="交易金额(元)" min-width="120"> </el-table-column>
        <el-table-column prop="balanceMoney" label="剩余金额(元)" min-width="120"> </el-table-column>
        <el-table-column prop="payType" label="付款类型" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.payType == '1'">预付扣费</span>
            <span v-else-if="scope.row.payType == '2'">后付</span>
          </template>
        </el-table-column>
        <el-table-column prop="agentName" label="代理商" min-width="120">
        </el-table-column>
        <el-table-column prop="poolName" label="卡池" min-width="120">
        </el-table-column>
        <el-table-column prop="iccid" label="卡号" min-width="190"> </el-table-column>
        <el-table-column prop="usedFlow" label="当前用量(M)" min-width="120"> </el-table-column>
        <el-table-column prop="lastUsedFlow" label="上次用量(M)" min-width="120"> </el-table-column>
        <el-table-column prop="billMonth" label="账单月" min-width="120"> </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="120"></el-table-column>
        <el-table-column prop="createTime" label="时间" min-width="180"> </el-table-column>
       
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
 
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  recordExport,
  recordList,
  getCardPoolList
} from "@/api/trafficPoolManagement.js";

export default {
  name: "trafficPoolDeductionRecord",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        iccid: "",
        poolId: "",
        orderNumber: "",
        tradeType:"",
        startTime:"",
        endTime:'',
      },
      tabledata: [],
      formLabelWidth: "200px",
      carPoolLists: [],
    };
  },
  created() {
    this.getCardPoolList();
    this.trafficPoolDeductionRecordList();
  },
  methods: {
    getCardPoolList() {
      let params = {
        pageSize: 1000000,
        pageNo: 1,
      };
      getCardPoolList(params).then((res) => {
        this.carPoolLists = res.rows.map((value, index, arr) => {
          return { value: value.id, label: value.name };
        });
      });
    },
    exportList() {
      //导出
           let params = { };
if(this.formInline.iccid){
params.iccid = this.formInline.iccid
}
if(this.formInline.poolId){
params.poolId = this.formInline.poolId
}
if(this.formInline.orderNumber){
params.orderNumber = this.formInline.orderNumber
}
if(this.formInline.tradeType){
params.tradeType = this.formInline.tradeType
}
if(this.formInline.startTime){
params.startTime = this.formInline.startTime
}
if(this.formInline.endTime){
params.endTime = this.formInline.endTime
}
      recordExport(params).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },

    trafficPoolDeductionRecordList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };

if(this.formInline.iccid){
params.iccid = this.formInline.iccid
}
if(this.formInline.poolId){
params.poolId = this.formInline.poolId
}
if(this.formInline.orderNumber){
params.orderNumber = this.formInline.orderNumber
}
if(this.formInline.tradeType){
params.tradeType = this.formInline.tradeType
}
if(this.formInline.startTime){
params.startTime = this.formInline.startTime
}
if(this.formInline.endTime){
params.endTime = this.formInline.endTime
}

      // Object.assign(params, this.formInline);
      recordList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.trafficPoolDeductionRecordList();
    },
    onPageChange(page) {
      this.page = page;
      this.trafficPoolDeductionRecordList();
    },
    searchData() {
      this.page = 1;
      this.trafficPoolDeductionRecordList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
.trafficPoolDeductionRecord .el-form-item__label {
  vertical-align: top;
}
</style>

