<template>

<div class="use-card table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">代理商卡使用周期</span>
        <el-form inline v-model="form"  class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="产品类型:">
                <el-select v-model="form.productType" filterable>
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="(item,index) in w_PRODUCT_" :label="item.groupName" :value="item.dictValue" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="代理商:">
                <el-select v-model="form.agentId" filterable>
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="(item,index) in agentList" :label="item.agentThreeName" :value="item.agentThreeId" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-download" class="themed-button" @click="exportExcel">导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="产品类型" prop="productType">
                <template  slot-scope="scope">
                    <span>{{productTypeName(scope.row.productType)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="代理商" prop="agentName"></el-table-column>
            <el-table-column label="1个月" prop="month1"></el-table-column>
            <el-table-column label="2个月" prop="month2"></el-table-column>
            <el-table-column label="3个月" prop="month3" show-overflow-tooltip></el-table-column>
            <el-table-column label="4个月" prop="month4" show-overflow-tooltip></el-table-column>
            <el-table-column label="5个月" prop="month5" show-overflow-tooltip></el-table-column>
            <el-table-column label="6个月" prop="month6" show-overflow-tooltip></el-table-column>
            <el-table-column label="7个月" prop="month7" show-overflow-tooltip></el-table-column>
            <el-table-column label="8个月" prop="month8" show-overflow-tooltip></el-table-column>
            <el-table-column label="9个月" prop="month9" show-overflow-tooltip></el-table-column>
            <el-table-column label="10个月" prop="month10" show-overflow-tooltip></el-table-column>
            <el-table-column label="11个月" prop="month11" show-overflow-tooltip></el-table-column>
            <el-table-column label="12个月" prop="month12" show-overflow-tooltip></el-table-column>
            <el-table-column label="13-24个月" prop="month13_24" show-overflow-tooltip></el-table-column>
            <el-table-column label="25-36个月" prop="month25_36" show-overflow-tooltip></el-table-column>
            <el-table-column label="36个月以上" prop="month36" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {getAgentList,getList,cardActiveExportSync} from '@/api/staticsManagement/cardUseCycel.js'
import {messageBox,confirmBox} from '@/utils/common.js'

import resize from '@/mixins/resize.js'
import dictGroup from '@/mixins/dictGroup.js'

export default {
    name:'agentCardUseCycle',
    mixins:[resize,dictGroup],
    data(){
        return {
            form:{
                productType:'',
                agentName:''
            },
            agentList:[],
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,

        }
    },
    mounted(){
        this._getAgentList()
        this._getList()
    },
    methods:{
        searchTable(){
            this._getList()
        },
        exportExcel(){
            cardActiveExportSync(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._getList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._getList()
        },
        productTypeName(productType){
            let result = this.w_PRODUCT_.find((item)=>{
                return item.dictValue == productType
            })
            if(!result){
                return '-'
            }
            if(result.label){
                return result.label
            }else{
                return '-'
            }
        },
        _getAgentList(){
            getAgentList().then((res)=>{
                this.agentList = res
            })
        },
        _getList(){
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            params.type = this.type
            getList(Object.assign(params,this.form)).then((res) => {
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    }
}
</script>
