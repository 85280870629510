<template>
  <div class="content table-hook fadeInRight">
    <div class="title-box">
      <span class="m-title">API卡列表</span>
      <el-form
        inline class="hook right themed-form search-form"
        v-model="form"
      >
        <el-form-item label="ICCID">
          <el-input v-model="form.iccidMark" clearable> </el-input>
        </el-form-item>
        <el-form-item label="短号">
          <el-input v-model="form.shortIccid" clearable></el-input>
        </el-form-item>
        <el-form-item label="接入号">
          <el-input v-model="form.phone" clearable></el-input>
        </el-form-item>
        <el-form-item label="代理商">
          <el-input v-model="form.agentName" clearable> </el-input>
        </el-form-item>
        <el-form-item label="运营商">
          <el-input v-model="form.accountName" clearable> </el-input>
        </el-form-item>
        <el-form-item label="运营商子类别">
          <el-input v-model="form.secondCategory" clearable> </el-input>
        </el-form-item>
        <el-form-item label="运营商账号">
          <el-input v-model="form.account" clearable> </el-input>
        </el-form-item>
        <el-form-item label="售后渠道">
          <el-input v-model="form.postSale" clearable> </el-input>
        </el-form-item>
        

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="themed-button"
            @click="searchTable"
            >搜索查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="themed-button"
            @click="batchExport"
            >批量导入</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-delete"
            class="themed-button"
            @click="batchDel"
            >批量删除</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="themed-button"
            @click="batchModify"
            >批量修改备注</el-button
          >
        </el-form-item>
           <el-form-item>
                <el-button type="primary" icon="el-icon-download" class="themed-button" @click="exportExcel">导出</el-button>
            </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table ref="table" :data="tableData" stripe border>
        <el-table-column label="卡号" prop="iccidMark"></el-table-column>
        <el-table-column label="接入号" prop="phone"></el-table-column>
        <el-table-column label="短号" prop="shortIccid"></el-table-column>
        <el-table-column label="代理商" prop="agentName"></el-table-column>
        <el-table-column label="运营商" prop="accountName"></el-table-column>
        <el-table-column
          label="运营商子类别"
          prop="secondCategory"
        ></el-table-column>
        <el-table-column label="运营商账号" prop="account"></el-table-column>
        <el-table-column label="售后渠道" prop="postSale"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" prop="opt" width="150px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              @click="editData(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              style="margin-left:10px;"
              class="themed-button"
              @click="deleteCardList(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="编辑" :visible.sync="dialogEditBox" v-if="dialogEditBox">
      <el-form label-position="right" label-width="200px" v-model="editForm">
        <el-form-item label="短号:">
          <el-input
          type="number"
           min="0"
            v-model="editForm.shortIccid"
            placeholder="请输入短号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="代理商名称:">
          <el-input
            v-model="editForm.agentName"
            placeholder="请输入代理商名称"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="运营商账号:">
          <el-input
            v-model="editForm.account"
            placeholder="请输入运营商账号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            v-model="editForm.remark"
            placeholder="请输入备注"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="themed-button" @click="sureEdit"
          >确认</el-button
        >
        <el-button
          type="primary"
          class="themed-button"
          @click="dialogEditBox = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog :title="dialogTitle" :visible.sync="dialogBox">
      <el-form label-position="right" label-width="200px">
        <el-form-item label="请选择excel文件:">
          <input class="file-input" type="file" ref="file" />
          <div class="mt5">
            <span class="text-color" style="font-size: 12px"
              >仅支持.xls/.xlsx格式</span
            >
          </div>
        </el-form-item>
       
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="themed-button" @click="importModel"
          >导入excel模板</el-button
        >
        <el-button
          type="primary"
          class="themed-button"
          @click="exportTemplateExcel"
          >导出excel模板</el-button
        >
        <el-button
          type="primary"
          class="themed-button"
          @click="dialogBox = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  getApiCardList,
  deleteApiCardList,
  updateApiCardList,
  apiCardUpdateRemarkBatch,
  apiCardDeleteBatch,
  apiCardImportBatch,
  exportExcelAsyncApi
} from "@/api/cardManagement/apiCardList.js";

import { messageBox, confirmBox } from "@/utils/common.js";

import Utils from "@/utils/utils.js";
import { exportTemplate } from "@/api/statistics.js";
import resize from "@/mixins/resize.js";

export default {
  name: "apiCardList",
  mixins: [resize],
  data() {
    return {
      tableData: [],
      pageSize: 15,
      currentPage: 1,
      total: 0,
      dialogBox: false,
      dialogEditBox: false,
      editForm: {
        shortIccid: "",
        agentName: "",
        account: "",
        remark:"",
        id:"",
      },
      form: {
        iccidMark: "",
        shortIccid: "",
        phone: "",
        agentName:"",
        accountName:"",
        secondCategory:"",
        account:"",
        postSale:"",
      },
      dialogTitle:"",
    };
  },
  computed: {
  fileName() {
    const titles = {
      "批量导入": "importApiCard.xls",
      "批量删除": "importIccidOrShort.xls",
      "批量修改备注": "remark_iccid.xls"
    };
    return titles[this.dialogTitle] || "";
  }
},

  mounted() {
    this._listData();
    try {
      console.log("诺行版权");
    } catch (error) {
      console.log("notioni:" + error);
    }
  },
  methods: {
     exportExcel(){
            exportExcelAsyncApi(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
    clearFileInput() {
  this.$nextTick(() => {
     this.$refs["file"].value = "";
  });
},

    batchExport(){
     this.dialogBox = true
     this.dialogTitle = "批量导入"
      this.clearFileInput()
    },
     batchDel(){
     this.dialogBox = true
     this.dialogTitle = "批量删除"
      this.clearFileInput()
    },
     batchModify(){
    this.dialogBox = true
     this.dialogTitle = "批量修改备注"
      this.clearFileInput()
    },
    sureEdit() {
    updateApiCardList(this.editForm).then(res=>{
      console.log(res)
      if (res.system_result_key == "0") {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          this.dialogEditBox = false;
          this._listData()
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
    })
    },
    editData(row) {
        this.dialogEditBox = true
    this.editForm.id  = row.id 
    this.editForm.account = row.account
    this.editForm.remark = row.remark
    this.editForm.agentName = row.agentName
    this.editForm.shortIccid = row.shortIccid
    },
    searchTable() {
      this.currentPage = 1;
      this._listData();
    },
    deleteCardList(id) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteApiCardList({ id }).then((res) => {
          this._listData();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },

    exportTemplateExcel() {
    //   let name = ""
    //   if( this.dialogTitle == "批量导入"){
    //        name= "importApiCard.xls"
    //   }
    //   if( this.dialogTitle == "批量删除"){
    //        name= "importIccidOrShort.xls"
    //   }
    //   if( this.dialogTitle == "批量修改备注"){
    //        name= "remark_iccid.xls"
    //   }

      exportTemplate({ name:this.fileName }).then((res) => {
        const filename = this.fileName;
        Utils.createDownload(res, filename);
      });
    },
    importModel() {
      confirmBox(this, "确定导入吗？", "操作提示", () => {
        try{
       this.comfirmImport();
        }catch(error){
         console.log(error)
        }
       
      });
    },
    comfirmImport() {
      let file = this.$refs["file"].files[0];
      if (file) {
        let fileName = file.name;
        let index = fileName.lastIndexOf(".");
        if (
          index < 0 ||
          !(
            fileName.substring(index + 1, fileName.length) == "xls" ||
            fileName.substring(index + 1, fileName.length) == "xlsx"
          )
        ) {
          messageBox(this, "文件格式错误");
          return;
        } else if (!!Utils.checkFile(file)) {
          if (Utils.checkFile(file) == 1) {
            messageBox(this, "上传文件不能超过20M");
            return;
          } else if (Utils.checkFile(file) == 2) {
            messageBox(this, "上传文件名称长度不能超过36个字符");
            return;
          }
        } else {
          let params = new FormData();
          params.append("fileurl", file);
   
           let methods =""
      if( this.dialogTitle == "批量导入"){
           methods= apiCardImportBatch
      }
      if( this.dialogTitle == "批量删除"){
           methods= apiCardDeleteBatch
      }
      if( this.dialogTitle == "批量修改备注"){
           methods= apiCardUpdateRemarkBatch
      }
     
          
          methods(params).then((res) => {
            if (res.system_result_key == "0") {
              this.$alert("导入成功，请到导入记录中下载", "提示", {
                confirmButtonText: "确定",
              }).then(() => {
                this.dialogBox = false;
                this.$router.push("/tob/i_import_list");
              });
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
        }
      }  else {
        messageBox(this, "请选择文件");
        return;
      }
    },
    exportModel() {},
    loadFail(importCardId) {
      this.$router.push({
        path: "/cardManagement/failList",
        query: { importCardId },
      });
    },
    distributionCard() {
      this.dialogBox = true;
      getagentList().then((res) => {
        this.agentLists = res.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this._listData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this._listData();
    },
    _listData() {
      let params = {};
      params.pageSize = this.pageSize;
      params.pageNo = this.currentPage;
      Object.assign(params, this.form);
      getApiCardList(params).then((res) => {
        // console.log(res)
        this.total = res.total;
        this.currentPage = res.pageNo;
        this.pageSize = res.pageSize;
        this.tableData = res.rows;
      });
    },
  },
};
</script>

