/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from '../request.js'

export function bindCardList(params){
    return request({
        url: 'bindImeiCard/list.do?',
        method: 'post',
        data:params
    })
}

export function findCardById(params){
    return request({
        url:'bindImeiCard/findById.do?',
        method:'post',
        data:params
    })
}

export function deleteBindCard(params){
    return request({
        url:'bindImeiCard/deleteById.do?',
        method:'post',
        data:params
    })
}

export function exportBindImeiCard(params){
    return request({
        url:'bindImeiCard/exportSync.do?',
        method:'post',
        data:params
    })
}

