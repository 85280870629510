<template>
  <div class="operate-management-operator fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">运营商</span>
      <el-form
        inline
        ref="operatorForm"
        v-model="operatorFormData"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <!-- <el-form-item>
                    <el-input v-model="operatorFormData.accountName" placeholder="运营商名称"></el-input>
                </el-form-item> -->
        <el-form-item label="运营商类别:">
          <el-select v-model="operatorFormData.category">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(item, index) in w_ACCOUNT_"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
            <!-- <el-option v-for="(item,index) in $store.getters.categorys" :label="item.name" :value="item.value" :key="index"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="operatorFormData.accountNameTwo"
            placeholder="名称"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-circle-plus"
            class="themed-button"
            type="info"
            @click="createSupplier()"
            >新增</el-button
          >
        </el-form-item>
         <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        border
        stripe
        ref="table"
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
      >
        <!-- <el-table-column
                show-overflow-tooltip
                    prop="firstName"
                    label="运营商"
                    min-width="150"
                    >
                     <template slot-scope="scope">
                        <span>{{_getCategory(scope.row.firstName,1)}}</span>
                    </template>
                </el-table-column> -->
        <el-table-column label="运营商类别" min-width="100">
          <template slot-scope="scope">
            <!-- <span v-if="scope.row.category==1">电信</span>
                        <span v-else-if="scope.row.category==2">其他卡务</span>
                        <span v-else-if="scope.row.category==3">移动</span>
                        <span v-else-if="scope.row.category==4">联通</span>
                        <span v-else-if="scope.row.category==5">DCP电信</span>
                        <span v-else-if="scope.row.category==6">联通Q</span>
                        <span v-else-if="scope.row.category==7">新移动</span>
                        <span v-else-if="scope.row.category==8">新联通</span> -->
            <span>{{ _getCategory(scope.row.category, 1) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="accountNameTwo"
          label="名称"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="platformCode"
          label="平台编号"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="monthSettl"
          label="月结类型"
          min-width="150"
        >
          <template slot-scope="scope">
            <span>{{ getMonthSettl(scope.row.monthSettl) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="pollSwitch"
          label="轮询开关"
          min-width="150"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.pollSwitch == 1">开启</span>
            <span v-else>关闭</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="pollSign"
          label="轮询标识"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="pollParam"
          label="轮询参数"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="remark"
          label="备注"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="supplier"
          label="供应商"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="flowPool"
          label="流量池子"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="createDatetime"
          label="修改时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createUser"
          label="修改人"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="secondCategory"
          label="子类别"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="apnName"
          label="主题"
          min-width="150"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip label="秒返禁用" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.miaofanEnable == 1">未禁用</span>
            <span v-else>已禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="350">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="themed-button"
              type="info"
              @click="updateRecord(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              class="themed-button"
              type="info"
              @click="validateAccount(scope.row.id)"
              >验证</el-button
            >
            <!-- <el-button
                        size="mini"
                        class="themed-button" type="info"
                        @click="addapn(scope.row.id)"
                        >设置apn</el-button> -->
            <el-button
              v-if="scope.row.miaofanEnable == 1"
              size="mini"
              class="themed-button"
              type="primary"
              @click="miaofanUpdate(scope.row.id, '2')"
              >禁用</el-button
            >
            <el-button
              v-else
              size="mini"
              class="themed-button"
              type="primary"
              @click="miaofanUpdate(scope.row.id, '1')"
              >启用</el-button
            >
             <el-button
              size="mini"
              class="themed-button"
              type="primary"
              @click="pushAddress(scope.row.id)"
              >token推送地址</el-button
            >
            <el-button
              size="mini"
              class="themed-button"
              type="primary"
              @click="copy(scope.row.id)"
              >复制</el-button
            >
            <!-- <el-button
                        size="mini"
                        class="themed-button" type="info"
                        @click="realName(scope.row.id)"
                        >实名认证</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>

    <el-dialog
      title="新增账户"
      :visible.sync="addDialogFormVisible"
      :destroy-on-close="true"
      v-if="addDialogFormVisible"
    >
      <el-form
        :model="addForm"
        :rules="rules"
        ref="addForm"
        style="max-height: 500px; overflow-y: scroll"
      >
        <!-- <el-form-item label="开卡运营商" :label-width="formLabelWidth" prop="accountName">
                <el-input v-model="addForm.accountName" autocomplete="off"></el-input>
                </el-form-item> -->
        <!-- <el-form-item label="运营商:"  :label-width="formLabelWidth" prop="firstName" >
                <el-select v-model="addForm.firstName">
                     <el-option v-for="(item,index) in w_ACCOUNT_" :key="index" :label="item.groupName" :value="item.dictValue"></el-option>
                </el-select>
            </el-form-item> -->
        <el-form-item
          label="运营商类别"
          :label-width="formLabelWidth"
          prop="category"
        >
          <el-select v-model="addForm.category" placeholder="请选择所属类别">
            <el-option
              v-for="(item, index) in w_ACCOUNT_"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="名称"
          :label-width="formLabelWidth"
          prop="accountNameTwo"
        >
          <el-input
            v-model="addForm.accountNameTwo"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="平台类型"
          :label-width="formLabelWidth"
          prop="platformCode"
        >
          <el-select v-model="addForm.platformCode">
            <el-option
              v-for="(item, index) in platformTypeList"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="月结日"
          :label-width="formLabelWidth"
          prop="monthSettl"
        >
          <el-select v-model="addForm.monthSettl">
            <el-option
              v-for="(item, index) in monthSettlList"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="轮询开关"
          :label-width="formLabelWidth"
          prop="pollSwitch"
        >
          <el-select v-model="addForm.pollSwitch" placeholder="全部">
            <el-option label="关闭" value="0"></el-option>
            <el-option label="开启" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="轮询标识"
          :label-width="formLabelWidth"
          prop="pollSign"
        >
          <el-input
            v-model="addForm.pollSign"
            autocomplete="off"
            @input="myTrim(addForm.pollSign, 1)"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item
          label="轮询参数"
          :label-width="formLabelWidth"
          prop="pollParam"
        >
          <el-input
            type="textarea"
            v-model="addForm.pollParam"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->

        <el-form-item v-if="addForm.category != 11">
          <el-form-item label="轮询参数" :label-width="formLabelWidth">
          </el-form-item>
          <!-- <label style="display:block;width:140px;text-align:right;">轮询参数</label> -->

          <el-table :data="pollParamData">
            <el-table-column label="名称" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input v-model.trim="scope.row.name" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="查询状态" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.queryStatus"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="轮询间隔" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.interval"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="批量查询个数" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.batchQueryNum"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="线程数" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.threadCount"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="请求间隔" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.reqInterval"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  :disabled="!!(pollParamData.length == 1)"
                  plain
                  size="mini"
                  @click="deleteDayRule(scope.row, scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <el-col :span="12">
              <el-form-item>
                <el-button
                  :disabled="!!(pollParamData.length >= 3)"
                  icon="el-icon-plus"
                  class="themed-button"
                  type="primary"
                  @click="addOneDay"
                  >新增一行</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item
          label="实名方式"
          :label-width="formLabelWidth"
          prop="realNameType"
        >
          <el-select
            v-model="addForm.realNameType"
            placeholder="请选择"
            @change="realNameTypeChange"
          >
            <el-option label="不用实名" value="4"></el-option>
            <el-option label="先实名后充值" value="1"></el-option>
            <el-option label="先充值后强制实名" value="2"></el-option>
            <el-option label="可选择下次实名" value="3"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="实名链接"
          :label-width="formLabelWidth"
          prop="realNameUrl"
          v-if="isShowReal"
        >
          <el-input v-model="addForm.realNameUrl" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="实名提示"
          :label-width="formLabelWidth"
          prop="realNameContent"
          v-if="isShowReal"
        >
          <el-input
            type="textarea"
            v-model="addForm.realNameContent"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="绑手机不上传身份证"
          :label-width="formLabelWidth"
          prop="bindNoIdCard"
        >
          <el-radio-group v-model="addForm.bindNoIdCard">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="供应商"
          :label-width="formLabelWidth"
          prop="supplier"
        >
          <el-input v-model="addForm.supplier" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="流量池子"
          :label-width="formLabelWidth"
          prop="flowPool"
        >
          <el-input v-model="addForm.flowPool" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="不续费充值"
          :label-width="formLabelWidth"
          prop="isRecharge"
        >
          <el-radio-group v-model="addForm.isRecharge">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="网卡更换"
          :label-width="formLabelWidth"
          prop="isRecard"
        >
          <el-radio-group v-model="addForm.isRecard">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- <el-form-item
          label="子类别"
          :label-width="formLabelWidth"
          prop="secondCategory"
        >
          <el-input
            type="text"
            v-model.number="addForm.secondCategory"
            maxlength="3"
            show-word-limit
          ></el-input>
        </el-form-item> -->

        <el-form-item
          label="支持语音"
          :label-width="formLabelWidth"
          prop="hasVoice"
        >
          <el-radio-group v-model="addForm.hasVoice">
            <el-radio :label="1">支持</el-radio>
            <el-radio :label="0">不支持</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="接口协议"
          :label-width="formLabelWidth"
          prop="protocol"
        >
          <!-- <el-input v-model="addForm.protocol" autocomplete="off"></el-input> -->
          <el-select v-model="addForm.protocol" placeholder="请选择接口协议">
            <el-option
              v-for="(item, index) in w_platform_protocol"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="地理位置设置"
          :label-width="formLabelWidth"
          prop="gpsType"
        >
          <el-select v-model="addForm.gpsType" placeholder="请选择地理位置设置">
            <el-option label="不提示获取位置" value="1"></el-option>
            <el-option label="提示获取位置" value="2"></el-option>
            <el-option label="无位置禁止登陆" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="是否设置推送"
          :label-width="formLabelWidth"
          prop="vendorPushEnable"
        >
          <el-radio-group v-model="addForm.vendorPushEnable">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否需要获取token"
          :label-width="formLabelWidth"
          prop="hasTokenInterface"
        >
          <el-radio-group v-model="addForm.hasTokenInterface">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否支持接口实名"
          :label-width="formLabelWidth"
          prop="hasRealnameInterface"
        >
          <el-radio-group v-model="addForm.hasRealnameInterface">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否支持Lbs"
          :label-width="formLabelWidth"
          prop="hasLbs"
        >
          <el-radio-group v-model="addForm.hasLbs">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="是否需要隐藏ICCID"
          :label-width="formLabelWidth"
          prop="hideIccid"
        >
          <el-radio-group v-model="addForm.hideIccid">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

         <el-form-item
          label="是否真三卡作假"
          :label-width="formLabelWidth"
          prop="mock"
        > 
          <el-radio-group v-model="addForm.mock">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item
          label="是否提前停机"
          :label-width="formLabelWidth"
          prop="stopAhead"
        > 
          <el-radio-group v-model="addForm.stopAhead">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item
          label="提前停机天数"
          :label-width="formLabelWidth"
          prop="stopAheadDays"
          v-if="addForm.stopAhead"
        > 
           <el-input-number v-model="addForm.stopAheadDays" :step="1" step-strictly :precision="0"   :min="0"  ></el-input-number>
        </el-form-item>
         <el-form-item
          label="不轮询网卡定期检查"
          :label-width="formLabelWidth"
          prop="needIdlePolling"
        > 
          <el-radio-group v-model="addForm.needIdlePolling">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item
          label="支持设备流量推送"
          :label-width="formLabelWidth"
          prop="openDeviceFlow"
        > 
          <el-radio-group v-model="addForm.openDeviceFlow">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      <el-form-item
          label="使用设备限速"
          :label-width="formLabelWidth"
          prop="usedDeviceLimit"
         v-if="addForm.category== 11" 
        > 
          <el-radio-group v-model="addForm.usedDeviceLimit">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        

        <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
          <el-input
            type="textarea"
            v-model="addForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="info" @click="sureAdd('addForm')"
          >确 定</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="addDialogFormVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="修改账户"
      :visible.sync="editDialogFormVisible"
      :destroy-on-close="true"
    >
      <el-form
        :model="editForm"
        :rules="rules"
        ref="editForm"
        style="max-height: 500px; overflow-y: scroll"
      >
        <!-- <el-form-item label="开卡运营商" :label-width="formLabelWidth" prop="accountName">
                    <el-input v-model="editForm.accountName" autocomplete="off"></el-input>
                </el-form-item> -->
        <!-- <el-form-item label="运营商:"  :label-width="formLabelWidth" prop="firstName" >
                <el-select v-model="editForm.firstName">
                    <el-option v-for="(item,index) in w_ACCOUNT_" :key="index" :label="item.groupName" :value="item.dictValue"></el-option>
                </el-select>
            </el-form-item> -->
        <el-form-item label="运营商类别" :label-width="formLabelWidth">
          <el-select v-model="editForm.category" placeholder="请选择所属类别">
            <el-option
              v-for="(item, index) in w_ACCOUNT_"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="名称"
          :label-width="formLabelWidth"
          prop="accountNameTwo"
        >
          <el-input
            v-model="editForm.accountNameTwo"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="平台类型"
          :label-width="formLabelWidth"
          prop="platformCode"
        >
          <el-select v-model="editForm.platformCode">
            <el-option
              v-for="(item, index) in platformTypeList"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="月结日"
          :label-width="formLabelWidth"
          prop="monthSettl"
        >
          <el-select v-model="editForm.monthSettl">
            <el-option
              v-for="(item, index) in monthSettlList"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="轮询开关"
          :label-width="formLabelWidth"
          prop="pollSwitch"
        >
          <el-select v-model="editForm.pollSwitch" placeholder="全部">
            <el-option label="关闭" value="0"></el-option>
            <el-option label="开启" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="轮询标识"
          :label-width="formLabelWidth"
          prop="pollSign"
        >
          <el-input
            v-model="editForm.pollSign"
            autocomplete="off"
            @input="myTrim(editForm.pollSign)"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item
          label="轮询参数"
          :label-width="formLabelWidth"
          prop="pollParam"
        >
          <el-input
            type="textarea"
            v-model="editForm.pollParam"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <el-form-item v-if="editForm.category != 11">
          <el-form-item label="轮询参数" :label-width="formLabelWidth">
          </el-form-item>
          <!-- <label style="display:block;width:140px;text-align:right;">轮询参数</label> -->

          <el-table :data="editPollParamData">
            <el-table-column label="名称" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input v-model.trim="scope.row.name" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="查询状态" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.queryStatus"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="轮询间隔" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.interval"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="批量查询个数" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.batchQueryNum"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="线程数" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.threadCount"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="请求间隔" prop="" min-width="90">
              <template slot-scope="scope">
                <el-input
                  v-model.trim="scope.row.reqInterval"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  :disabled="!!(editPollParamData.length == 1)"
                  plain
                  size="mini"
                  @click="deleteDayEditRule(scope.row, scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <el-col :span="12">
              <el-form-item>
                <el-button
                  :disabled="!!(editPollParamData.length >= 3)"
                  icon="el-icon-plus"
                  class="themed-button"
                  type="primary"
                  @click="addOneDayEdit"
                  >新增一行</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item
          label="供应商"
          :label-width="formLabelWidth"
          prop="supplier"
        >
          <el-input v-model="editForm.supplier" autocomplete="off"></el-input>
        </el-form-item>
         <el-form-item
          label="绑手机不上传身份证"
          :label-width="formLabelWidth"
          prop="bindNoIdCard"
        >
          <el-radio-group v-model="editForm.bindNoIdCard">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="实名方式"
          :label-width="formLabelWidth"
          prop="realNameType"
        >
          <el-select
            v-model="editForm.realNameType"
            placeholder="请选择"
            @change="realNameTypeChange"
          >
            <el-option label="不用实名" value="4"></el-option>
            <el-option label="先实名后充值" value="1"></el-option>
            <el-option label="先充值后强制实名" value="2"></el-option>
            <el-option label="可选择下次实名" value="3"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="实名链接"
          :label-width="formLabelWidth"
          prop="realNameUrl"
          v-if="isShowReal"
        >
          <el-input
            v-model="editForm.realNameUrl"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="实名提示"
          :label-width="formLabelWidth"
          prop="realNameContent"
          v-if="isShowReal"
        >
          <el-input
            type="textarea"
            v-model="editForm.realNameContent"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="流量池子"
          :label-width="formLabelWidth"
          prop="flowPool"
        >
          <el-input v-model="editForm.flowPool" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="不续费充值" :label-width="formLabelWidth">
          <el-radio-group v-model="editForm.isRecharge">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="网卡更换" :label-width="formLabelWidth">
          <el-radio-group v-model="editForm.isRecard">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- <el-form-item
          label="子类别"
          :label-width="formLabelWidth"
          prop="secondCategory"
        >
          <el-input
            type="text"
            v-model.number="editForm.secondCategory"
            autocomplete="off"
            maxlength="3"
            show-word-limit
          ></el-input>
        </el-form-item> -->
        <el-form-item label="支持语音" :label-width="formLabelWidth">
          <el-radio-group v-model="editForm.hasVoice">
            <el-radio :label="1">支持</el-radio>
            <el-radio :label="0">不支持</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="接口协议"
          :label-width="formLabelWidth"
          prop="protocol"
        >
          <!-- <el-input v-model="editForm.protocol" autocomplete="off"></el-input> -->
          <el-select v-model="editForm.protocol" placeholder="请选择接口协议">
            <el-option
              v-for="(item, index) in w_platform_protocol"
              :key="index"
              :label="item.groupName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地理位置设置" :label-width="formLabelWidth">
          <el-select
            v-model="editForm.gpsType"
            placeholder="请选择地理位置设置"
          >
            <el-option label="不提示获取位置" value="1"></el-option>
            <el-option label="提示获取位置" value="2"></el-option>
            <el-option label="无位置禁止登陆" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否设置推送" :label-width="formLabelWidth">
          <el-radio-group v-model="editForm.vendorPushEnable">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否需要获取token"
          :label-width="formLabelWidth"
          prop="hasTokenInterface"
        >
          <el-radio-group v-model="editForm.hasTokenInterface">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否支持接口实名"
          :label-width="formLabelWidth"
          prop="hasRealnameInterface"
        >
          <el-radio-group v-model="editForm.hasRealnameInterface">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否支持Lbs"
          :label-width="formLabelWidth"
          prop="hasLbs"
        >
          <el-radio-group v-model="editForm.hasLbs">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item
          label="是否需要隐藏ICCID"
          :label-width="formLabelWidth"
          prop="hideIccid"
        >
          <el-radio-group v-model="editForm.hideIccid">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否真三卡作假"
          :label-width="formLabelWidth"
          prop="mock"
        > 
          <el-radio-group v-model="editForm.mock">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
          <el-form-item
          label="是否提前停机"
          :label-width="formLabelWidth"
          prop="stopAhead"
        > 
          <el-radio-group v-model="editForm.stopAhead">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="提前停机天数"
          :label-width="formLabelWidth"
          prop="stopAheadDays"
          v-if="editForm.stopAhead"
        > 
           <el-input-number v-model="editForm.stopAheadDays" :step="1" step-strictly :precision="0"   :min="0"  ></el-input-number>
        </el-form-item>
         <el-form-item
          label="不轮询网卡定期检查"
          :label-width="formLabelWidth"
          prop="needIdlePolling"
        > 
          <el-radio-group v-model="editForm.needIdlePolling">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item
          label="支持设备流量推送"
          :label-width="formLabelWidth"
          prop="openDeviceFlow"
        > 
          <el-radio-group v-model="editForm.openDeviceFlow">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item
          label="使用设备限速"
          :label-width="formLabelWidth"
          prop="usedDeviceLimit"
            v-if="editForm.category== 11" 
        > 
          <el-radio-group v-model="editForm.usedDeviceLimit">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
          <el-input
            type="textarea"
            v-model="editForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="info"
          @click="sureEdit('editForm')"
          >确 定</el-button
        >
        <el-button class="themed-button" type="info" @click="resetEdit()"
          >重 置</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="editDialogFormVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="apn设置"
      :visible.sync="apnDialogFormVisible"
      :destroy-on-close="true"
    >
      <el-form :model="apnForm" ref="apnForm">
        <el-form-item label="请选择apn设置主题：" label-width="200px">
          <el-select v-model="apnForm.apnId" placeholder="请选择">
            <el-option
              v-for="item in apnList"
              :key="item.index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="info" @click="sureApn('apnForm')"
          >确 定</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="apnDialogFormVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>

        <el-dialog
      title="token推送地址"
      :visible.sync="tokenVisible"
      :destroy-on-close="true"
      width="600"
      v-if="tokenVisible"
    >
      <token-address :accountId="currId"> </token-address>
    </el-dialog>

    <!-- <el-dialog title="实名认证" :visible.sync="realNameDialogFormVisible" :destroy-on-close='true'>
            <el-form :model="realNameForm" :rules="rules" ref="realNameForm">

                <el-form-item label="实名方式" :label-width="formLabelWidth" prop="realNameType">
                    <el-select v-model="realNameForm.realNameType" placeholder="请选择">
                        <el-option  label="不用实名" value="4"></el-option>
                        <el-option  label="先实名后充值" value="1"></el-option>
                        <el-option  label="先充值后强制实名" value="2"></el-option>
                        <el-option  label="可选择下次实名" value="3"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="实名链接" :label-width="formLabelWidth" prop="realNameUrl">
                    <el-input v-model="realNameForm.realNameUrl" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="实名提示" :label-width="formLabelWidth" prop="realNameContent">
                    <el-input type="textarea" v-model="realNameForm.realNameContent"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureRealName('realNameForm')">确 定</el-button>
                <el-button class="themed-button" type="primary" @click="realNameDialogFormVisible = false">关 闭</el-button>
            </div>
        </el-dialog> -->
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import {
  operatorList,
  addSupplier,
  initEditOperator,
  editSupplier,
  checkOperator,
  getApnAllList,
  editApn,
  editMiaoFan,
  editRealName,
  getDictGroupList,
  copySupplier,
  exportExcelAsync
} from "@/api/operator.js";
import dictGroup from "@/mixins/dictGroup.js";
import store from "@/store";
import tokenAddress  from "@/views/operatorManagement/tokenAddress.vue"

export default {
  name: "operator",
  mixins: [resize, dictGroup],
  data() {
    var secondCategory = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error("子类别不能为空"));
      }
      if (!Number.isInteger(value)) {
        callback(new Error("请输入数字值"));
      } else {
        callback();
      }
    };
    return {
      operatorFormData: {
        category: "",
        accountNameTwo: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      platformTypeList: [],
      monthSettlList: [],
      addDialogFormVisible: false,
      addForm: {
        accountNameTwo: "",
        platformCode: "",
        monthSettl: "",
        pollSwitch: "0",
        pollSign: "",
        pollParam: "",
        realNameType: "",
        realNameUrl: "",
        realNameContent: "",
        supplier: "",
        flowPool: "",
        isRecharge: 2,
        isRecard: 2,
        category: "",
        gpsType: "1",
        remark: "",
        // secondCategory: "",
        hasVoice: 1,
        protocol: "",
        vendorPushEnable: 0,
        hasTokenInterface: 0,
        hasRealnameInterface: 0,
        hasLbs: 0,
        hideIccid: 1,
        mock:0,
        stopAhead:0, 
        needIdlePolling:0,
        bindNoIdCard:false,
        openDeviceFlow:0,
        usedDeviceLimit:0,
        stopAheadDays:0,
      },
      editDialogFormVisible: false,
      editForm: {
        id: "",
        accountNameTwo: "",
        platformCode: "",
        monthSettl: "",
        pollSwitch: "0",
        pollSign: "",
        pollParam: "",
        realNameType: "",
        realNameUrl: "",
        realNameContent: "",
        supplier: "",
        flowPool: "",
        isRecharge: "",
        isRecard: "",
        category: "",
        gpsType: "",
        remark: "",
        // secondCategory: "",
        hasVoice: "",
        protocol: "",
        vendorPushEnable: 0,
        hasTokenInterface: "",
        hasRealnameInterface: "",
        hasLbs: "",
        hideIccid: "",
        mock:'',
        stopAhead:"",
        needIdlePolling:'',
        bindNoIdCard:"",
        openDeviceFlow:"",
        usedDeviceLimit:0,
        stopAheadDays:"",
      },

      apnForm: {
        id: "",
        apnId: "",
      },
      apnDialogFormVisible: false,
      apnList: [],

      realNameDialogFormVisible: false,
      realNameForm: {
        id: "",
        realNameType: "",
        realNameUrl: "",
        realNameContent: "",
      },
      pollParamData: [
        {
          name: "",
          queryStatus: "",
          interval: "",
          batchQueryNum: "",
          threadCount: "",
          reqInterval: "",
        },
      ],
      editPollParamData: [],

      rules: {
        // firstName:[
        //         {required: true, message: '请选择运营商', trigger: 'blur'}
        //     ],
        accountNameTwo: [
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
        platformCode: [
          { required: true, message: "请选择平台类型", trigger: "blur" },
        ],
        monthSettl: [
          { required: true, message: "请选择月结类型", trigger: "blur" },
        ],
        pollSwitch: [
          { required: true, message: "请选择轮询开关", trigger: "blur" },
        ],
        pollSign: [
          { required: true, message: "请输入轮询标识", trigger: "blur" },
        ],
        // pollParam: [
        //   { required: true, message: "请输入轮询参数", trigger: "blur" },
        // ],
        realNameType: [
          { required: true, message: "请选择实名方式", trigger: "blur" },
        ],
        // realNameUrl:[
        //     {required: true, message: '请输入实名链接', trigger: 'blur'}
        // ],
        realNameContent: [
          { required: true, message: "请输入实名提示", trigger: "blur" },
        ],
        // secondCategory: [{ validator: secondCategory, trigger: "blur" }],
        protocol: [
          { required: true, message: "请选择接口协议", trigger: "change" },
        ],
      },
      formLabelWidth: "150px",
      isShowReal: true,
      tokenVisible:false,
      currId:"",
    };
  },
  mounted() {
    if (store.getters.categorys.length < 1) {
      store.dispatch("getCategory");
    }
  },
  created() {
    this._getDictGroupList();
    this.getOperatorList();
     try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
  },
  methods: {
     exportExcel(){
            exportExcelAsync(this.operatorFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
    copy(id){
     copySupplier({id}).then(res=>{
         if (res.system_result_key == '0') {
                    this.$message({
							showClose: true,
							message: '复制成功',
							type: 'success',
						})
               this.getOperatorList();    
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
     })
    },
    pushAddress(id){
     this.tokenVisible = true
     this.currId = id
    },
    realNameTypeChange(val) {
      if (val == "4") {
        this.isShowReal = false;
      } else {
        this.isShowReal = true;
      }
    },
    getMonthSettl(value) {
      let monthSettl = "-";
      if (!!this.monthSettlList.length) {
        this.monthSettlList.forEach((item) => {
          if (item.dictValue == value) {
            monthSettl = item.groupName;
          }
        });
      }
      return monthSettl;
    },
    myTrim(val, type) {
      if (type) {
        this.addForm.pollSign = val.replace(/^\s+|\s+$/gm, "");
      } else {
        this.editForm.pollSign = val.replace(/^\s+|\s+$/gm, "");
      }
    },
    getOperatorList() {
      let params = {
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.operatorFormData, params);
      operatorList(this.operatorFormData).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onSearch() {
      this.page = 1;
      this.getOperatorList();
    },
    onPageChange(page) {
      this.page = page;
      this.getOperatorList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getOperatorList();
    },
    addOneDay() {
      this.pollParamData.push({
        name: "",
        queryStatus: "",
        interval: "",
        batchQueryNum: "",
        threadCount: "",
        reqInterval: "",
      });
    },
    addOneDayEdit() {
      this.editPollParamData.push({
        name: "",
        queryStatus: "",
        interval: "",
        batchQueryNum: "",
        threadCount: "",
        reqInterval: "",
      });
    },
    deleteDayRule(data, index) {
      this.pollParamData.splice(index, 1);
    },
    deleteDayEditRule(data, index) {
      this.editPollParamData.splice(index, 1);
    },
    createSupplier() {
      this.addDialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["addForm"].resetFields();
      });
      this.addForm.accountNameTwo = "";
      this.addForm.platformCode = "";
      this.addForm.monthSettl = "";
      this.addForm.pollSwitch = "0";
      this.addForm.pollSign = "";
      this.addForm.pollParam = "";
      this.addForm.realNameType = "";
      this.addForm.realNameUrl = "";
      this.addForm.realNameContent = "";
      this.addForm.supplier = "";
      this.addForm.flowPool = "";
      this.addForm.isRecard = 2;
      this.addForm.isRecharge = 2;
      this.addForm.category = "";
      this.addForm.gpsType = "1";
      this.addForm.remark = "";
      // this.addForm.secondCategory = "";
      this.addForm.hasVoice = 1;
      this.addForm.protocol = "";
      this.addForm.vendorPushEnable = 0;
      this.addForm.hasTokenInterface = 0;
      this.addForm.hasRealnameInterface = 0;
      this.addForm.hasLbs = 0;
       this.addForm.hideIccid = 1;
       this.addForm.mock = 0 
       this.addForm.stopAhead = 0 
       this.addForm.needIdlePolling = 0
       this.addForm.openDeviceFlow = 0 
       this.addForm.usedDeviceLimit = 0 
       this.addForm.stopAheadDays = 0
      this.pollParamData = [
        {
          name: "",
          queryStatus: "",
          interval: "",
          batchQueryNum: "",
          threadCount: "",
          reqInterval: "",
        },
      ];
    },
    sureAdd(formName) {
      console.log(2222);

      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(1111);
          if (this.addForm.category != 11) {
            for (let i of this.pollParamData) {
              if (i.name.trim() == "") {
                this.$alert("轮询参数名称不能为空", "提示", {
                  confirmButtonText: "确定",
                });
                return;
              }
              let ret = /^[1-9]\d*$/;
              if (!ret.test(i.interval)) {
                this.$alert("轮询间隔需是大于0的整数", "提示", {
                  confirmButtonText: "确定",
                });
                return;
              }
              let reg = /^([1-9]|[1-9]\d|100)$/;
              if (!reg.test(i.batchQueryNum)) {
                this.$alert("批量查询个数需是1-100的整数", "提示", {
                  confirmButtonText: "确定",
                });
                return;
              }
              let reg1 = /^[1-9]\d*$/;
              if (
                !(
                  reg1.test(i.threadCount) &&
                  parseInt(i.threadCount) >= 1 &&
                  parseInt(i.threadCount) <= 5
                )
              ) {
                this.$alert("线程数需是1-5的整数", "提示", {
                  confirmButtonText: "确定",
                });
                return;
              }
            }
          }

          if (this.addForm.platformCode != "mobileOnelink") {
            if (
              this.addForm.realNameType != "4" &&
              this.addForm.realNameUrl.trim() == ""
            ) {
              this.$alert("请输入实名链接", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
          }
          this.$confirm(
            "新增运营商后需要重启运营商服务才会及时生效，请联系平台运营人员处理。",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.addForm.pollParam = JSON.stringify(this.pollParamData);
              console.log(this.addForm.pollParam);
              addSupplier(this.addForm).then((res) => {
                this.$message({
                  showClose: true,
                  message: "新增成功",
                  type: "success",
                  center: true,
                });
                this.$refs[formName].resetFields();
                this.addDialogFormVisible = false;
                this.getOperatorList();
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消新增运营商",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetEdit() {
      let id = this.editForm.id;
      this.updateRecord(id);
    },
    updateRecord(id) {
      this.editDialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["editForm"].clearValidate();
      });
      this.editForm.id = id;

      initEditOperator({ id: id }).then((res) => {
        this.editForm.accountNameTwo = res.entity.accountNameTwo;

        this.editForm.supplier = res.entity.supplier;
        this.editForm.flowPool = res.entity.flowPool;
        this.editForm.isRecharge = parseInt(res.entity.isRecharge);
        this.editForm.isRecard = parseInt(res.entity.isRecard);
        this.editForm.category = res.entity.category.toString();
        this.editForm.gpsType = res.entity.gpsType.toString();
        this.editForm.remark = res.entity.remark;
        this.editForm.platformCode = res.entity.platformCode + "";
        this.editForm.monthSettl = res.entity.monthSettl;
        this.editForm.pollSwitch = res.entity.pollSwitch + "";
        this.editForm.pollSign = res.entity.pollSign;
        // this.editForm.pollParam = res.entity.pollParam;
        this.editPollParamData = JSON.parse(res.entity.pollParam);
        console.log(this.editPollParamData, "editPollParamData");
        // this.editForm.secondCategory = parseInt(res.entity.secondCategory);
        res.entity.hasVoice =  res.entity.hasVoice===''?0:res.entity.hasVoice
        this.editForm.hasVoice = parseInt(res.entity.hasVoice);
        this.editForm.vendorPushEnable =
          parseInt(res.entity.vendorPushEnable) == 1 ? 1 : 0;
        this.editForm.protocol = res.entity.protocol;
        this.editForm.realNameType = res.entity.realNameType.toString();
        if (this.editForm.realNameType == "4") {
          this.isShowReal = false;
        } else {
          this.isShowReal = true;
          this.editForm.realNameUrl = res.entity.realNameUrl;
          this.editForm.realNameContent = res.entity.realNameContent;
        }
        this.editForm.hasTokenInterface = res.entity.hasTokenInterface ? 1 : 0;
        this.editForm.hasRealnameInterface = res.entity.hasRealnameInterface
          ? 1
          : 0;
        this.editForm.hasLbs = res.entity.hasLbs ? 1 : 0;
        this.editForm.hideIccid = res.entity.hideIccid ? 1 : 0;
        this.editForm.mock = res.entity.mock ? 1 : 0;
        this.editForm.stopAhead = res.entity.stopAhead ? 1 : 0;
        if(this.editForm.stopAhead==1){  // 修改无论是否开启  都给提前停机赋值；
           this.editForm.stopAheadDays = res.entity.stopAheadDays
        }else {
          console.log(res.entity.stopAheadDays , isNaN ( res.entity.stopAheadDays) , 1740)
           this.editForm.stopAheadDays = isNaN (res.entity.stopAheadDays) ?0:res.entity.stopAheadDays
        }
        this.editForm.needIdlePolling = res.entity.needIdlePolling ? 1 : 0;
        this.editForm.openDeviceFlow = res.entity.openDeviceFlow ? 1 : 0;
        if(this.editForm.category==11){
         this.editForm.usedDeviceLimit = res.entity.usedDeviceLimit ? 1 : 0; 
        }else{
          this.editForm.usedDeviceLimit = 0 
        }
        console.log(res.entity.bindNoIdCard,1623)
        this.editForm.bindNoIdCard = res.entity.bindNoIdCard?true:false;
      });
    },
    sureEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.editForm.category != 11) {
            if (this.editPollParamData.length == 0) {
              this.$alert("轮询参数不能为空", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
            for (let i of this.editPollParamData) {
              console.log(i);
              if (i.name.trim() == "") {
                this.$alert("轮询参数名称不能为空", "提示", {
                  confirmButtonText: "确定",
                });
                return;
              }
              let ret = /^[1-9]\d*$/;
              if (!ret.test(i.interval)) {
                this.$alert("轮询间隔需是大于0的整数", "提示", {
                  confirmButtonText: "确定",
                });
                return;
              }
              let reg = /^([1-9]|[1-9]\d|100)$/;
              if (!reg.test(i.batchQueryNum)) {
                this.$alert("批量查询个数需是1-100的整数", "提示", {
                  confirmButtonText: "确定",
                });
                return;
              }
              let reg1 = /^[1-9]\d*$/;
              if (
                !(
                  reg1.test(i.threadCount) &&
                  parseInt(i.threadCount) >= 1 &&
                  parseInt(i.threadCount) <= 5
                )
              ) {
                this.$alert("线程数需是1-5的整数", "提示", {
                  confirmButtonText: "确定",
                });
                return;
              }
            }
          }

          if (this.editForm.platformCode != "mobileOnelink") {
            if (
              this.editForm.realNameType != "4" &&
              this.editForm.realNameUrl.trim() == ""
            ) {
              this.$alert("请输入实名链接", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
          }

          this.$confirm(
            "编辑运营商后需要重启运营商服务才会及时生效，请联系平台运营人员处理。",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.editForm.pollParam = JSON.stringify(this.editPollParamData);
              editSupplier(this.editForm).then((res) => {
                this.$message({
                  showClose: true,
                  message: "编辑成功",
                  type: "success",
                  center: true,
                });
                this.editDialogFormVisible = false;
                this.getOperatorList();
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消编辑",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    validateAccount(id) {
      checkOperator({ id: id }).then((res) => {
        this.$alert(res.validateResultStr, "验证结果", {
          confirmButtonText: "确定",
        });
      });
    },
    addapn(id) {
      this.apnDialogFormVisible = true;
      this.apnForm.id = id;
      getApnAllList().then((res) => {
        this.apnList = res;
      });
    },
    sureApn() {
      editApn(this.apnForm).then((res) => {
        this.$message({
          showClose: true,
          message: res.slide_msg_message_key,
          type: "success",
          center: true,
        });
        this.apnDialogFormVisible = false;
        this.getOperatorList();
      });
    },

    miaofanUpdate(id, status) {
      let dlgMsg = "";
      if (status == "1") {
        dlgMsg = "确定是否取消禁用该池子的秒返";
      } else {
        dlgMsg = "禁用后将停用该池子所有卡的秒返，请确定是否禁用";
      }

      this.$confirm(dlgMsg, "操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        editMiaoFan({ acountId: id, miaofanEnable: status }).then((res) => {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "success",
            center: true,
          });
          this.getOperatorList();
        });
      });
    },
    realName(id) {
      this.realNameDialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["realNameForm"].clearValidate();
      });
      this.realNameForm.id = id;
      let params = {
        id: id,
        realNameType: "4",
      };
      initEditOperator(params).then((res) => {
        this.realNameForm.realNameType = res.entity.realNameType.toString();
        this.realNameForm.realNameUrl = res.entity.realNameUrl;
        this.realNameForm.realNameContent = res.entity.realNameContent;
      });
    },
    sureRealName(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editRealName(this.realNameForm).then((res) => {
            this.$message({
              showClose: true,
              message: "编辑成功",
              type: "success",
              center: true,
            });
            this.realNameDialogFormVisible = false;
            this.getOperatorList();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    _getDictGroupList() {
      let params = {
        dictGroup: "monthSettl,platform_type",
        type: "1",
      };
      getDictGroupList(params).then((res) => {
        console.log(res);
        this.platformTypeList = res.platform_type;
        this.monthSettlList = res.monthSettl;
        console.log(this.monthSettlList, this.platformTypeList);
      });
    },
  },
  components:{
   tokenAddress
  }
};
</script>
<style>
.operate-management-operator .el-form-item__label {
  vertical-align: top;
}
.operate-management-operator .el-input--small {
  width: 80%;
}
</style>
