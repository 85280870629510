<template>

<div class="batchCard table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">微信用户定位信息</span>
        <el-form inline class="hook right themed-form search-form" style="height: 40px;line-height: 40px;">
            <el-form-item label="openid:">
                <el-input v-model='id'></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="id" prop="id"></el-table-column>
            <el-table-column label="经度" prop="longitude"></el-table-column>
            <el-table-column label="纬度" prop="latitude"></el-table-column>
            <el-table-column label="省" prop="province"></el-table-column>
            <el-table-column label="市" prop="city"></el-table-column>
            <el-table-column label="区" prop="town"></el-table-column>
            <el-table-column label="省" prop="province"></el-table-column>
            <el-table-column label="地址详情" prop="address" show-overflow-tooltip></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {wxLocation} from '@/api/cardManagement/location.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'

export default {
    name:'wxLocation',
    mixins:[resize],
    data(){
        return {
            id:"",
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0
        }
    },
    mounted(){
        this._wxLocation()
    },
    methods:{
        searchTable(){
            this.currentPage = 1
            this._wxLocation()
        },
        handleSizeChange(val){
            this.pageSize = val
            this._wxLocation()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._wxLocation()
        },
        _wxLocation(){
            let params = {
                id:this.id,
                pageNo:this.currentPage,
                pageSize:this.pageSize
            }
            wxLocation(params).then((res)=>{
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
                this.tableData = res.rows
            })
        }
    }
}
</script>
