<template>
  <div class="singleCardStatusQuery fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">单卡查询</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="月份:">
           <el-date-picker
      v-model="formInline.month"
      type="month"
      value-format="yyyy-MM"
      placeholder="选择月">
    </el-date-picker>
        </el-form-item>
        <el-form-item label="卡号:">
          <el-input
            v-model="formInline.iccid"
            placeholder="请输入卡号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="iccid" label="卡号"> </el-table-column>
        <el-table-column prop="month" label="月份">
             </el-table-column>
        <el-table-column prop="usedFlow" label="用量"> </el-table-column>
        <el-table-column prop="agentName" label="代理商"> </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
 
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  cardUsageList
} from "@/api/trafficPoolManagement.js";

export default {
  name: "singleCardStatusQuery",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        iccid: "",
        month: "",
      },
      tabledata: [],
      formLabelWidth: "200px",
      carPoolLists: [],
    };
  },
  created() {
    this.singleCardStatusQueryList();
  },
  methods: {
    singleCardStatusQueryList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.formInline);
      cardUsageList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.singleCardStatusQueryList();
    },
    onPageChange(page) {
      this.page = page;
      this.singleCardStatusQueryList();
    },
    searchData() {
      this.page = 1;
      this.singleCardStatusQueryList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
 .singleCardStatusQuery  .el-date-editor.el-input{width:100%!important;}
.singleCardStatusQuery .el-form-item__label {
  vertical-align: top;
}
</style>

