<template>
  <div class="trafficPoolHistoryUsage fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">账单管理</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="流量池:">
          <el-select
            v-model="formInline.poolId"
            filterable
            placeholder="请选择流量池"
            clearable
          >
            <el-option
              v-for="(item, index) in carPoolLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="代理商:"   v-if="currentRole==0">
          <el-select
            v-model="formInline.agentId"
            filterable
            placeholder="请选择代理商"
            clearable
          >
            <el-option
              v-for="(item, index) in agentLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账期:">
          <el-date-picker
            v-model="formInline.month"
            type="month"
            value-format="yyyy-MM"
            placeholder="选择月"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="exportList"
            icon="el-icon-plus"
            class="themed-button"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="billNo" label="订单号" min-width="190"> </el-table-column>
        <el-table-column prop="poolName" label="流量池" min-width="120">
        </el-table-column>
        <el-table-column prop="agentName" label="代理商" min-width="120"> </el-table-column>
        <el-table-column prop="accountName" label="运营商" min-width="120"> </el-table-column>
        <el-table-column prop="month" label="月份" min-width="120"> </el-table-column>
        <el-table-column prop="totalFlow" label="总流量(M)" min-width="120"> </el-table-column>
        <el-table-column prop="usedFlow" label="已用流量(M)" min-width="120"> </el-table-column>
        <el-table-column prop="leftFlow" label="剩余流量(M)" min-width="120"> </el-table-column>
        <el-table-column prop="createTime" label="生成时间" min-width="170"> </el-table-column>
        <el-table-column prop="payType" label="支付方式" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.payType == '1'">预付</span>
            <span v-else-if="scope.row.payType == '2'">后付</span>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" label="支付状态" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.payStatus == '1'">未支付 </span>
            <span v-else-if="scope.row.payStatus == '2'">已支付</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="120"></el-table-column>
        <!-- <el-table-column label="操作" width="320px" v-if="currentRole==0"> -->
           <el-table-column label="操作" width="320px" > 
          <template v-slot:default="scope">
            <el-button
              type="info"
              class="themed-button"
              v-if="!!scope.row.proofUrl&&scope.row.payStatus == '2'&&currentRole==0"
              @click="view(scope.row.proofUrl)"
              >下载凭据</el-button
            >
            <el-button
              v-if="scope.row.payStatus == '1'&&currentRole==0"
              type="info"
              class="themed-button"
              @click="upload(scope.row.id)"
              >已付</el-button
            >
            <el-button
              type="info"
              class="themed-button"
              @click="getDetail(scope.row.id)"
              >账单详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="上传凭据"
      :visible.sync="dialogAddVisible"
      v-if="dialogAddVisible"
    >
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item
          label="上传凭证"
          :label-width="formLabelWidth"
          prop="proofUrl"
          required
        >
          <input
            ref="fileInput"
            type="file"
            class="form-control add_file_inter"
            @change="changeFile()"
            name="file"
          />
        </el-form-item>
         <el-form-item
          label="备注"
          :label-width="formLabelWidth"
        >
         <el-input
  placeholder="请输入备注"
  v-model="addForm.remark"
  clearable>
</el-input> 
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="formAddSubmit('addForm')"
          class="themed-button"
          >确 定</el-button
        >
        <el-button
          type="info"
          @click="dialogAddVisible = false"
          class="themed-button"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAgentForImportCard } from "@/api/cardManagement/cardImport.js";
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  billExport,
  billList,
  alreadyPayBill,
  getCardPoolList
} from "@/api/trafficPoolManagement.js";
import { uploadPayOfflineUrl} from "@/api/agentManagement.js";
export default {
  name: "trafficPoolHistoryUsage",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        agentId: "",
        poolId: "",
        month: "",
      },
      tabledata: [],
      formLabelWidth: "200px",
      carPoolLists: [],
      agentLists: [],
      dialogAddVisible: false,
      addForm: {
        proofUrl: "",
        remark:"",
        id:""
      },
      rules: {
        proofUrl: [
          { required: true, message: "请上传凭据", trigger: "change" },
        ],
      },
    };
  },
   computed: {
    ...mapGetters(["currentRole"]),
  },
  created() {
    this._getAgentForImportCard();
    this.getCardPoolList();
    this.trafficPoolHistoryUsageList();
  },
  methods: {

    getDetail(id){
     let  selections = [{"id":id,}];
										this.$router.replace({
											path:"/trafficPoolHistoryUsage/trafficPoolDetail",
											query:selections[0],
										})
    },
    upload(id) {
      this.dialogAddVisible = true;
      this.addForm.id = id
    },
    formAddSubmit(formName) {
      this.$refs["addForm"].validate((validate) => {
        if (validate) {
          if (!this.addForm.proofUrl) {
            this.$alert("请上传凭证", "提示", {
              confirmButtonText: "确定",
            });
            return;
          }
          alreadyPayBill(this.addForm).then((res) => {
            this.$message({
              type: "success",
              message: "提交凭据成功",
            });
            this.$refs["fileInput"].value = "";
            this.$refs[formName].resetFields();
            this.dialogAddVisible = false;
            this.trafficPoolHistoryUsageList()
          });
        }
      });
    },
    changeFile() {
      let formData = new FormData();
      formData.append("file", this.$refs["fileInput"].files[0]);
      uploadPayOfflineUrl(formData).then((res) => {
        this.addForm.proofUrl = res.url;
      });
    },
    view(url) {
      window.location.href = url;
    },
    _getAgentForImportCard() {
      getAgentForImportCard()
        .then((res) => {
          this.agentLists = res.map((value, index, arr) => {
            return { value: value.id, label: value.agentThreeName };
          });
          this.agentLists.unshift({ value: "", label: "请选择代理商" });
        })
        .catch(() => {
          this.agentLists = [];
        });
    },
    getCardPoolList() {
      let params = {
        pageSize: 1000000,
        pageNo: 1,
      };
      getCardPoolList(params).then((res) => {
        this.carPoolLists = res.rows.map((value, index, arr) => {
          return { value: value.id, label: value.name };
        });
      });
    },
    exportList() {
      //导出
        let params = {
      };
      if(this.formInline.agentId){
       params.agentId = this.formInline.agentId
      }
      if(this.formInline.poolId){
       params.poolId = this.formInline.poolId
      }
      if(this.formInline.month){
       params.month = this.formInline.month
      }
      billExport(params).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },

    trafficPoolHistoryUsageList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
     
      if(this.formInline.agentId){
       params.agentId = this.formInline.agentId
      }
      if(this.formInline.poolId){
       params.poolId = this.formInline.poolId
      }
      if(this.formInline.month){
       params.month = this.formInline.month
      }
      // Object.assign(params, this.formInline);
      billList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.trafficPoolHistoryUsageList();
    },
    onPageChange(page) {
      this.page = page;
      this.trafficPoolHistoryUsageList();
    },
    searchData() {
      this.page = 1;
      this.trafficPoolHistoryUsageList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
.trafficPoolHistoryUsage .el-form-item__label {
  vertical-align: top;
}
.trafficPoolHistoryUsage  .el-date-editor.el-input{width:100%!important;}
</style>

