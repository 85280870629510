<template>

<div class="proAgentAmount table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">预存话费挡位</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
             <el-form-item label="代理商:">
                <el-select v-model="form.agentName" @change="agentChange" filterable>
                    <el-option label="全部" value=""></el-option>
                      <el-option v-if="agentType==4&&agentTree==1" :label="agentThreeName" :value="loginAgentId"></el-option>
                    <el-option v-for="(item,index) in agentList" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addRecord">添加预存话费挡位</el-button>
            </el-form-item>
            <!-- <el-form-item>
                <el-button type="primary"  class="themed-button" @click="returnList">返回</el-button>
            </el-form-item> -->
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="充值金额" prop="rechargeAmount"></el-table-column>
            <el-table-column label="赠送金额" prop="sendMoney"></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="区" prop="town" show-overflow-tooltip></el-table-column> -->
            <el-table-column label="更新时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作"  prop="status">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==0">
                          <el-button  type="danger" size="mini" class="themed-button"  @click="addSetAmonut(scope.row.id)" :disabled= "isDisable">分销</el-button>
                          <!-- <el-button type="info" class="themed-button"  @click="editDate(scope.row.id,scope.row.rechargeAmount,scope.row.sendMoney)">编辑</el-button> -->
                    </span>
                    <span v-else-if="scope.row.status==1">
                        <el-button type="danger" class="themed-button"  @click="delAgentAmount(scope.row.id)">移除</el-button>
                    </span>
                    <span v-else>
                   <el-button type="info" class="themed-button"  @click="editDate(scope.row.id,scope.row.rechargeAmount,scope.row.sendMoney,scope.row.remark)">编辑</el-button>
                    <el-button type="danger" class="themed-button"  @click="delDate(scope.row.id)">删除</el-button>
                    </span>
                   
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>


    <el-dialog :title = title  :visible.sync="dialogBox">
        <el-form label-position="right" label-width="200px">
            <el-form-item label="预存话费挡位:">
                <el-input v-model="rechargeAmount"></el-input>
            </el-form-item>
            <el-form-item label="赠送金额:">
                <el-input v-model="sendMoney"></el-input>
            </el-form-item>
              <el-form-item label="备注:">
                <el-input v-model="remark"></el-input>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">提交</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {amountList,edit,add,del,agentList,delAgentAmount,addSetAmonut} from '@/api/cardManagement/proAgentAmount.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import resize from '@/mixins/resize.js'
import {orderGetAgents} from '@/api/agentApplyCheck.js'
  import {getAgentType} from "@/api/agentManagement.js"
import {provinceList} from '@/api/area.js'

export default {
    name:'proAgentAmount',
    mixins:[resize],
    data(){
        return {
            form:{
            agentName:"",
            },
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            rechargeAmount:'',
            msg:'',
            id:'',
            title:"",
            sendMoney:"",
            agentValue:"",
            agentList:[],
            remark:"",
           agentType:"",
           agentThreeName :"",
            agentTree:"",
            loginAgentId:"",
            isDisable:false,
        }
    },
    mounted(){
         this.getAgentType()
        this._orderGetAgents()
        this._amountList()
       
    },
    methods:{
          getAgentType(){
				getAgentType().then(res=>{
                    this.agentType = res.agentType
                    this.agentThreeName = res.agentThreeName
                    this.agentTree = res.agentTree
                    this.loginAgentId = res.agentId
				})
            },
        editDate(id,rechargeAmount,sendMoney,remark){
            this.title = "修改订单"
            this.dialogBox = true
            this.msg = '确定修改吗？'
            this.rechargeAmount = rechargeAmount
            this.sendMoney = sendMoney
            this.id = id
            this.remark = remark
        },
        delDate(id){
            confirmBox(this,'确定要删除吗？','消息提示',()=>{
                del({id,importType:2}).then((res)=>{
                   messageBox(this,'删除成功') 
                   this._amountList()
                })
            })
        },
        delAgentAmount(id){
         confirmBox(this,'确定要移除吗？','消息提示',()=>{
                delAgentAmount({id:id,agentId:this.agentValue}).then((res)=>{
                   messageBox(this,'移除成功') 
                   this.agentChange(this.agentValue)
                })
            })
        },
        addSetAmonut(id){
            this.isDisable = true
          addSetAmonut({id:id,agentId:this.agentValue}).then(res=>{
            
             messageBox(this,'分销成功') 
             this.agentChange(this.agentValue)
             this.isDisable = false
          })
        },
        addRecord(){
            this.title = "添加订单"
            this.dialogBox = true
            this.msg = '确定添加吗？'
            this.rechargeAmount = ''
            this.sendMoney = ""
            this.id = ''
            this.remark =""
        },
        uploadExcel(){
            confirmBox(this,this.msg,'操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            if(this.rechargeAmount.trim() === ''){
                messageBox(this,'请输入预存话费挡位！') 
                return
            }
            let checkInt = /^[1-9]\d*$/
            if(!checkInt.test(this.rechargeAmount)){
              messageBox(this,'预存话费挡位只能是大于0的整数！') 
                return
            }
            if(!!this.sendMoney){
               if(!checkInt.test(this.sendMoney)){
              messageBox(this,'赠送金额只能是大于0的整数！') 
                return
            }
            }
            if(this.id){
                this._edit()
            }else{
                this._add()
            }
        },
        handleSizeChange(val){
            this.pageSize = val
            if(this.agentValue==""){
               this._amountList()
            }else{
             this.getProAgentList(this.agentValue)
            }
            
        },
        handleCurrentChange(val){
            this.pageNo = val
            if(this.agentValue==""){
               this._amountList()
            }else{
             this.getProAgentList(this.agentValue)
            }
            
        },
        _add(){
            //  if(){
                
            // }
            let params = {}
            params.rechargeAmount = this.rechargeAmount
            params.sendMoney  = this.sendMoney
            params.remark = this.remark
            params.jihuotype = 2
           
            add(params).then((res)=>{
                if (res.slide_msg_key == 1) {
                    messageBox(res.slide_msg_message_key);
                }
                this.dialogBox = false
                this._amountList()
            })
        },
        _edit(){
            let params = {}
            params.rechargeAmount = this.rechargeAmount
            params.sendMoney  = this.sendMoney
            params.id = this.id
            params.remark = this.remark
            edit(params).then((res)=>{
                if (res.slide_msg_key == 1) {
                    messageBox(res.slide_msg_message_key);
                }
                this.dialogBox = false
                if(this.agentValue==""){
                this._amountList()
                }else{
                this.agentChange(this.agentValue)  
                }
                
            })
        },
        _amountList(){
     let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      Object.assign(params, {importType:2});

            amountList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
        agentChange(val){
            this.agentValue = val
            if(val==""){
             this._amountList()
            }else{
             this.getProAgentList(val)
            }
           
        },
        getProAgentList(val){
             let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      Object.assign(params, {agentName:val});
        agentList(params).then(res=>{
               this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
           })
        },
        // returnList(){
        //    this.form.agentName=""
        //    this.agentValue = ""
        //    this._amountList();
        // },
          _orderGetAgents(){
            this.agentList = []
            orderGetAgents({status:0}).then((res)=>{
                if(res.length>0){
                    res.forEach((item)=>{
                        this.agentList.push({
                            label:item.name,
                            value:item.id
                            })
                    })
                }
            })
        },
    },
    components:{
        SearchSelect
    }
}

</script>

