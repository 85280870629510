/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

//flowMonitorTelecom.vue
export function flowmonitorList(params){
    return request({
        url: 'flowmonitor/list.do?',
        method: 'post',
        data:params
    })
}
export function flowmonitorExport(params){
    return request({
        url: 'flowmonitor/exportSync.do?',
        method: 'post',
        data:params
    })
}
// export function accountList(params){
//     return request({
//         url: '/system/cardOperator/getAccountListByAgent.do?',
//         method: 'post',
//         data:params
//     })
// }
export function accountList(params){
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data:params
    })
}

//imeiBase.vue
export function imeiList(params){
    return request({
        url: 'card/imeibase/list.do?',
        method: 'post',
        data:params
    })
}

//imeiRepo.vue
export function imeiRepoList(params){
    return request({
        url: 'imei/list.do?',
        method: 'post',
        data:params
    })
}
export function imeiRepoBindList(params){
    return request({
        url: 'imei/bindList.do?',
        method: 'post',
        data:params
    })
}
export function batchImport(params){
    return request({
        url: 'imei/batchImportSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function agentList(params){
    return request({
        url: 'agent/list4agent.do?',
        method: 'post',
        data:params
    })
}
export function getSettings(params){
    return request({
        url: 'imei/getSettings.do?',
        method: 'post',
        data:params
    })
}
export function saveSettings(params){
    return request({
        url: 'imei/saveSettings.do?',
        method: 'post',
        data:params
    })
}