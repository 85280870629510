<template>

<div class="white_number_list"> </div>

</template>

<script type="text/ecmascript-6">
export default {
}
</script>

