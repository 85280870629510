<template>
    <div class="agent-recharge-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">代理商充值记录</span>
            <el-form inline ref="agentRechargeForm" v-model="agentRechargeFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="agentRechargeFormData.orderNumber" placeholder="订单号"></el-input>
                </el-form-item>
                
                <el-form-item label="状态">
                    <el-select v-model="agentRechargeFormData.status" placeholder="全部" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已支付" value="1"></el-option>
                        <el-option label="未支付" value="2" ></el-option>
                        <el-option label="审核中" value="3" ></el-option>
                        <el-option label="审核不通过" value="4" ></el-option>
                    </el-select>
                </el-form-item>              
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <!-- <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item> -->
            </el-form>

            
        </div>
        <div class="content-box">
            <el-table
                ref='table'
                border
                stripe
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :max-height="maxTableHeight">
            <el-table-column
                prop="orderNumber"
                label="订单号"
                min-width="180"
                >
            </el-table-column>
            
            <el-table-column
                    show-overflow-tooltip
                    label="充值方式"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.rechargeType==1">微信</span>
                        <span v-else-if="scope.row.rechargeType==2">支付宝</span>
                        <span v-else-if="scope.row.rechargeType==3">银行卡</span>
                    </template>
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="money"
                    label="充值金额"
                    min-width="120">
            </el-table-column>
             <el-table-column
                prop="mchId"
                label="商户ID"
                min-width="180"
                >
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="充值时间"
                    min-width="180">
            </el-table-column>

            <el-table-column
                    show-overflow-tooltip
                    label="状态"
                    min-width="120">
                    <template slot-scope="scope">
                       <span v-if="scope.row.status=='1'">已支付</span>
                       <span v-else-if="scope.row.status=='2'">未支付</span>
                       <span v-else-if="scope.row.status=='3'">审核中</span>
                       <span v-else-if="scope.row.status=='4'">审核不通过</span>
                    </template>
            </el-table-column>
            
            <el-table-column
                label="操作"
                min-width="150">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="primary" @click="handleShowDetail(scope.row)">详情</el-button>
                    </template>
            </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog
        title="详情"
        :visible.sync="dialogDetailVisible"
        :destroy-on-close='true'
        >
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">订单号:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.orderNumber}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">充值方式:</div></el-col>
        <el-col :span="12">
            <div v-if="detailInfo.rechargeType==1">微信</div>
            <div v-else-if="detailInfo.rechargeType==2">支付宝</div>
            <div v-else-if="detailInfo.rechargeType==3">银行卡</div>
        </el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">充值金额:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.money}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">充值时间:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.createTime}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">当前状态:</div></el-col>
        <el-col :span="12"><div v-if="detailInfo.status==1">已支付</div>
            <div v-else-if="detailInfo.status==2">未支付</div>
            <div v-else-if="detailInfo.status==3">审核中</div>
            <div v-else-if="detailInfo.status==4">审核不通过</div>
        </el-col>
        </el-row>
        <el-row :gutter="20" v-if='detailInfo.proofUrl'>
        <el-col :span="12"><div class="dialog-row-label">下载凭证:</div></el-col>
        <el-col :span="12"><div><span v-if="detailInfo.proofUrl" @click="download(detailInfo.proofUrl)" style="color: rgb(51, 122, 183);cursor:pointer;">点击下载</span></div></el-col>
        </el-row>

        <el-row :gutter="20" v-if='detailInfo.accountNumber'>
        <el-col :span="12"><div class="dialog-row-label">账号:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.accountNumber}}</div></el-col>
        </el-row>

        <el-row :gutter="20" v-if='detailInfo.transactionId'>
        <el-col :span="12"><div class="dialog-row-label">转账单号:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.transactionId}}</div></el-col>
        </el-row>

        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">备注:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.remark}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">审核备注:</div></el-col>
        <el-col :span="12"><div>{{detailInfo.auditRemark}}</div></el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button"  @click="dialogDetailVisible = false">关闭</el-button>
        </span>
        </el-dialog>

    </div>
</template>
<script>
import {agentRechargeList,exportSelfRecharge} from "@/api/agentManagement.js"
import resize from '@/mixins/resize.js'
export default {
    name:'agentRechargeList',
    mixins:[resize],
    data(){
        return{
            agentRechargeFormData:{
                orderNumber:'',
                status:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogDetailVisible:false,
            detailInfo:{
                orderNumber:'',
                money:'',
                rechargeType:'',
                createTime:'',
                status:'',
                proofUrl:'',
                remark:'',
                auditRemark:'',
                accountNumber:'',
                transactionId:'',
            }
        }
    },
    mounted() {
         this.getAgentRechargeList()
    },
    methods:{
        getAgentRechargeList(){
            let params = {
                agentId:this.agentId,
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.agentRechargeFormData,params)
            agentRechargeList(this.agentRechargeFormData).then(res=>{
                this.tableData = res.rows
                this.total= res.count
            })
        },
        onSearch(){
            this.page = 1
            this.getAgentRechargeList()
        },
        exportExcel(){
            exportSelfRecharge(this.agentRechargeFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        onPageChange(page){
			this.page = page
			this.getAgentRechargeList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getAgentRechargeList()
        },
        handleShowDetail(data){
            this.dialogDetailVisible = true
                this.detailInfo.orderNumber = data.orderNumber
                this.detailInfo.money = data.money
                this.detailInfo.rechargeType = data.rechargeType
                this.detailInfo.createTime = data.createTime
                this.detailInfo.status = data.status
                this.detailInfo.proofUrl = data.proofUrl
                this.detailInfo.remark = data.remark
                this.detailInfo.auditRemark = data.auditRemark 
                this.detailInfo.accountNumber = data.accountNumber
                this.detailInfo.transactionId = data.transactionId         
        },
        download(url){
            window.location.href = url
        }
    }
}
</script>
<style>
    .agent-recharge-list .el-form-item__label{
        vertical-align: top;
    }
    .agent-recharge-list .dialog-row-label{
        text-align: right;
    }
</style>