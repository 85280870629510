<template>
  <div class="withdrawalManagement fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">提现管理</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item>
          <el-select
            v-model="formInline.status"
            placeholder="状态"
            clearable
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="mobilePhone" label="手机号"> </el-table-column>
        <el-table-column prop="nickname" label="微信昵称">
        </el-table-column>
        <el-table-column prop="timePeriod" label="周期"> </el-table-column>
        <el-table-column prop="profit" label="收益金额"> </el-table-column>
        <el-table-column prop="paid" label="已付金额"> </el-table-column>
        <el-table-column prop="leftPay" label="剩余待返"> </el-table-column>
        <el-table-column prop="status" label="状态"> 
          <template slot-scope="scope">
             <span>{{getStatus(scope.row.status)}}</span>         
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="时间"> </el-table-column>
        <el-table-column label="操作" width="200px"> 
              <template slot-scope="scope">
                        <el-button v-if ="scope.row.status!=3"  size="mini" class="themed-button" type="primary" @click="rebatePayment(scope.row)">返利打款</el-button>
                        <el-button  style="margin-top:5px"  size="mini" class="themed-button" type="primary" @click="paymentRecord(scope.row.id)">打款记录</el-button>
                    </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="返利打款" :visible.sync="dialogFormVisible" v-if="dialogFormVisible">
  <el-form :model="form">
   
    <el-form-item label="打款方式" :label-width="formLabelWidth">
      <el-select v-model="form.method" placeholder="请选择">
        <el-option label="微信" value="1"></el-option>
        <el-option label="其他" value="2"></el-option>
      </el-select>
    </el-form-item>
     <el-form-item label="金额" :label-width="formLabelWidth">
      <el-input-number v-model="form.money" :precision="2" :step="0.1" ></el-input-number>
    </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth">
      <el-input v-model="form.remark" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="sureSubmit">确 定</el-button>
  </div>
</el-dialog>

    <el-dialog title="打款记录" :visible.sync="dialogTableVisible" width="90%">
      <pay-record  :curId ="currentId"  v-if="dialogTableVisible"></pay-record>
    </el-dialog>

  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import { payList ,payTransfer} from "@/api/partner.js";
import payRecord from "./components/payRecord.vue"

export default {
  name: "withdrawalManagement",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        status: "",
      },
      tabledata: [],
      formLabelWidth: "200px",
      statusList: [{id:1,value:"待支付"},{id:2,value:"部分支付"},{id:3,value:"已支付"},],
      dialogTableVisible:false,
      currentId:"",
      dialogFormVisible:false,
      form:{
        method:"",
        money:"",
        remark:"",
        id:"",
      },
      leftPay:0,
    };
  },
  created() {
    this.getPayList();
  },
  components:{
    payRecord,
  },
  methods: {
    sureSubmit(){
      if(!this.form.method){
        this.$alert('请选择打款方式', '提示', {
								confirmButtonText: '确定',
							});
							return
      }
      if(!this.form.money){
          this.$alert('金额需要大于0', '提示', {
								confirmButtonText: '确定',
							});
							return
      }
      if(parseFloat(this.form.money)>parseFloat(this.leftPay)){
          this.$alert('金额需小于等于剩余金额', '提示', {
								confirmButtonText: '确定',
							});
							return
      }
       payTransfer(this.form).then(res=>{
               this.dialogFormVisible = false
               this.getPayList()
       })   
    },
    getStatus(value){
      let str =""
     if(value ==1){
       str = "待支付"
     }else if(value ==2){
      str = "部分支付"
     }else if(value ==3){
      str = "已支付"
     }
     return  str
    },
    rebatePayment(row){
       this.form.id = row.id
       this.leftPay = row.leftPay
       this.form.method =""
       this.form.money = ""
       this.form.remark = ""
       
       this.dialogFormVisible = true
       
    },
    paymentRecord(id){
     this.dialogTableVisible = true
     this.currentId = id
    },
    getPayList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      if(this.formInline.status!=""){
          Object.assign(params, this.formInline);
      }
      
      payList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPayList();
    },
    onPageChange(page) {
      this.page = page;
      this.getPayList();
    },
    searchData() {
      this.page = 1;
      this.getPayList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
.withdrawalManagement .el-date-editor.el-input {
  width: 100% !important;
}
.withdrawalManagement .el-form-item__label {
  vertical-align: top;
}
</style>

