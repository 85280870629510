import request from './request.js'
// 销售员
// 销售员列表
export function sellerList(params) {
    return request({
        url: 'batch/sale/list.do?',
        method: 'post',
        data: params
    })
}

// 部门选项表
export function departmentOptionsList(params) {
    return request({
        url: 'department/list.do?',
        method: 'post',
        data: params
    })
}

// 添加销售员
export function addSeller(params) {
    return request({
        url: 'batch/sale/save.do?',
        method: 'post',
        data: params
    })
}

// 获取批次列表
export function salesBatchList(params) {
    return request({
        url: 'batch/saleBatch/list.do?',
        method: 'post',
        data: params
    })
}

// 代理商选项表
export function daiLiList(params) {
    return request({
        url: 'agent/list5agents.do?',
        method: 'post',
        data: params
    })
}


// 销售员选项表
export function allSaleList(params) {
    return request({
        url: 'batch/sale/allList.do?',
        method: 'post',
        data: params
    })
}

// 添加批次
export function addSaleBatch(params) {
    return request({
        url: 'batch/saleBatch/save.do?',
        method: 'post',
        data: params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 卡批次
// 卡批次列表
export function cardBatchList(params) {
    return request({
        url: 'batch/cardBatch/list.do?',
        method: 'post',
        data: params
    })
}

// 批量删除
export function deleteCardBatchByExcel(params) {
    return request({
        url: 'batch/saleBatch/deleteIccidBatch.do?',
        method: 'post',
        data: params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 销售代理商
// 销售代理商表
export function saleAgentList(params) {
    return request({
        url: 'batch/saleAgent/list.do?',
        method: 'post',
        data: params
    })
}

// 添加代理商
export function addSaleAgent(params) {
    return request({
        url: 'batch/saleAgent/save.do?',
        method: 'post',
        data: params
    })
}
