<template>
  <div>
    <h3 class="page-title">
      组织机构
    </h3>
    <el-breadcrumb separator-class="el-icon-arrow-right" style="background-color: #f7f7f7;margin: 10px 0;padding: 8px;">
      <el-breadcrumb-item><i class="el-icon-s-home"></i> 主页</el-breadcrumb-item>
      <el-breadcrumb-item>{{breadMenu[0]}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{breadMenu[1]}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>

</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {

      }
    },
    computed:{
        ...mapGetters([
          'breadMenu',

        ]),
    },
  }
</script>

<style>
  .page-title {
    padding: 0px;
    font-size: 30px;
    letter-spacing: -1px;
    display: block;
    color: #666;
    margin: 0px 0px 15px 0px;
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
    /* subtitle */
  }
</style>
