<template>

<div class="newCoupons">
    <el-form label-position="right" label-width="200px" ref="form" :model="form" :rules="rules">
        <el-form-item label="折扣名称:" prop="discountName" required>
            <el-input v-model="form.discountName"></el-input>
        </el-form-item>
        <el-form-item label="折扣额度:" prop="discountValue" required>
            <el-input v-model="form.discountValue"></el-input>
        </el-form-item>
        <el-form-item label="折扣开始时间:" prop="startTime" required>
            <el-date-picker type="datetime" placeholder="选择日期时间" value-format="yyyy/MM/dd HH:mm:ss"  v-model="form.startTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="折扣结束时间:" prop="endTime" required>
            <el-date-picker type="datetime" placeholder="选择日期" value-format="yyyy/MM/dd HH:mm:ss" v-model="form.endTime"></el-date-picker>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="sure('form')">确定</el-button>
        <el-button type="primary" class="themed-button" @click="cancle('form')">取 消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
    import {discountSave} from '@/api/eventDiscountManagement/discountManagement.js'

    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'

export default {
    data(){
        return {
            form:{
                id:'',
                discountName:'',
                discountValue:'',
                startTime:'',
                endTime:''
            },
            rules:{
                discountName:[
                    { required: true, message: '折扣名称不能为空', trigger: 'blur' }
                ],
                discountValue:[
                    { required: true, message: '折扣金额不能为空', trigger: 'blur' }
                ],
                startTime:[
                    { required: true, message: '请设置折扣开始时间', trigger: 'blur' }
                ],
                endTime:[
                    { required: true, message: '请设置折扣结束时间', trigger: 'blur' }
                ]

            }
        }

    },
    methods:{
        sure(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    discountSave(this.form).then((res)=>{
                        if(res.slide_msg_key == 1){
                            messageBox(this,res.slide_msg_message_key)
                        }
                        // this.$refs[formName].resetFields();
                        this.$emit('refresh')

                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        cancle(formName){
            this.$refs[formName].resetFields();
            this.$emit('close')
        }
    },
    components:{
        SearchSelect
    }
}
</script>

<style></style>
