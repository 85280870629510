<template>
    <div class="informationOnOfficialSite fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">光耀官网客户信息</span>
            <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
                <el-table-column label="公司名称"  prop="company" width="180px">
                </el-table-column>
                <el-table-column label="联系人名称" prop="name" show-overflow-tooltip></el-table-column>
                <el-table-column label="联系电话" prop="phone" show-overflow-tooltip></el-table-column>
                <el-table-column label="邮箱" prop="email" show-overflow-tooltip></el-table-column>
                <el-table-column label="地址" prop="address" show-overflow-tooltip></el-table-column>
                <el-table-column label="主营业务" prop="bussiness" show-overflow-tooltip></el-table-column>
                <el-table-column label="留言" prop="remark" show-overflow-tooltip></el-table-column>
                <el-table-column label="创建时间" prop="createDatetime" show-overflow-tooltip></el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

       

    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {informationOnOfficialSiteList,gyCustomerExport} from "@/api/systemManagement.js"
import { messageBox, confirmBox } from "@/utils/common.js"
import Utils from '@/utils/utils.js'
export default {
    name:'informationOnOfficialSite',
     mixins:[resize],
    data(){
        return {
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    mounted(){
        this.getInformationList()
    },
    methods: {
       
        onPageChange(page){
			this.page = page
			this.getInformationList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getInformationList()
        },
        onSearch(){
            this.page = 1
            this.getInformationList()
        },
       
        exportExcel(){
            gyCustomerExport({}).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        getInformationList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize,
            }
            informationOnOfficialSiteList(params).then((res)=>{
                // res = res.data
                this.tableData = res.rows
                this.page = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.count
            })
        }
    },
}
</script>