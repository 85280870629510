<template>

<div class="table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">绑定手机记录</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="ICCID号:">
                <el-input v-model="form.iccidMark" class="iccid-width"></el-input>
            </el-form-item>
             <el-form-item label="短号:">
                <el-input v-model="form.shortIccid" class="iccid-width" clearable></el-input>
            </el-form-item>
            <el-form-item label="接入号:">
                <el-input v-model="form.phone" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="手机号:">
                <el-input v-model="form.bindPhone" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item label="开卡公司:">
                <search-select :optionsMetaAll="accountLists"  v-model="form.accountId" placeholder="请选择"></search-select>
            </el-form-item>
            <el-form-item label="操作时间:" prop="startTime">
                <el-date-picker
                    v-model="form.startTime"
                    type="date"
                    placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 100%;">
                </el-date-picker>
            </el-form-item>
            <el-form-item>至</el-form-item>
            <el-form-item>
                <el-date-picker
                    v-model="form.endTime"
                    type="date"
                    placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 100%;">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="状态:">
                <el-select placeholder="请选择" v-model="form.status">
                    <el-option label="请选择" value=""></el-option>
                    <el-option label="成功" value="1"></el-option>
                    <el-option label="失败" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="batchadd">批量导入</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="batchdelete">批量删除</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">导出excel</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID号" prop="iccidMark" width="180px"> </el-table-column>
             <el-table-column label="短号" prop="shortIccid" width="160px"> </el-table-column>
            <el-table-column label="接入号" prop="phone" show-overflow-tooltip></el-table-column>
            <el-table-column label="手机号" prop="bindPhone" show-overflow-tooltip></el-table-column>
            <el-table-column label="开卡公司" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="代理商" prop="agentThreeName" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作时间" prop="importTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作人" prop="createUser" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status === 1">成功</span>
                    <span v-else>失败</span>
                </template>
            </el-table-column>
            <el-table-column label="失败原因" prop="failReason" show-overflow-tooltip></el-table-column>
            <el-table-column label="绑定时间" prop="createDate" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{createDate(scope.row.createDate)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" prop="expireDate">
                <template slot-scope="scope">
                    <el-button type="danger" class="themed-button"  @click="deleteId(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog  title="批量删除" :visible.sync="dialogDele">
        <el-form label-position="right" label-width="200px" ref="dForm">
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='fileD'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcelD">导入excel数据</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('importIccid.xls')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogDele = false">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog  title="批量导入" :visible.sync="dialogPil">
        <el-form label-position="right" label-width="200px" ref="pForm">
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">导入excel数据</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('importIccid.xls')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogPil = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {accountList,recordList,deleteById,importBindCard,importDeleteCard,exportTOCListSync} from '@/api/cardManagement/phoneBindRecord.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import resize from '@/mixins/resize.js'

export default {
    name:'phoneBindRecord',
    mixins:[resize],
    data(){
        return {
            form:{
                iccidMark:'',
                phone:'',
                bindPhone:'',
                accountId:'',
                startTime:'',
                endTime:'',
                status:'',
                shortIccid:""
            },
            accountLists:[],
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,

            dialogPil:false,
            dialogDele:false
        }
    },
    mounted(){
        this._accountList()
        this._recordList()
    },
    methods:{
        exportExcel(){
            exportTOCListSync(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },
        createDate(createDate){
            if(createDate){
                var date = new Date(createDate);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                var d = date.getDate();
                return y + '-' +m + '-' + d;
            } else return "-";
        },
        deleteId(id){
            confirmBox(this,'确定要删除吗？','消息提示',()=>{
                deleteById({id}).then(()=>{
                    messageBox(this,'删除成功。','消息提示')
                    this._recordList()
                })

            })
            
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    importBindCard(params).then((res)=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogPil = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        uploadExcelD(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImportD()
            })
        },
        comfirmImportD(){
            let file = this.$refs['fileD'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    importDeleteCard(params).then((res)=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogDele = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
        batchdelete(){
            this.dialogDele = true
        },
        batchadd(){
            this.dialogPil = true
        },
        searchTable(){
            this._recordList()
        },
        handleSizeChange(val){
            this.pageSize = val
            this._recordList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._recordList()
        },
        _recordList(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            recordList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
        _accountList(){
            accountList(this.form).then((res)=>{
                this.accountLists = res.map((item)=>{
                    return {
                        label:item.accountName,
                        value:item.id
                    }
                })
            })
        }
    },
    components:{
        SearchSelect
    }
}

</script>

