<template>
    <el-form :model="formData" :rules="rules" ref="dataForm">
        <el-form-item label="配置名:" prop="name" :label-width="formLabelWidth">
            <el-input v-model="formData.name" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="广告图1:" prop="pic1" :label-width="formLabelWidth">
            <el-image v-if="jsonObject.pic1" style="width: 100px; height: 100px" :src="jsonObject.pic1" ></el-image>
            <el-button v-if="jsonObject.pic1" type="primary" icon="el-icon-delete" @click="jsonObject.pic1 = ''" ></el-button>
            <input class="file-input" type="file" ref='fileImportInput1'  @change="fileChange('1')"  />
        </el-form-item>
        <el-form-item label="图1链接:" prop="pic1Url" :label-width="formLabelWidth">
            <el-input v-model="jsonObject.pic1Url" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="广告图2:" prop="pic2" :label-width="formLabelWidth">
            <el-image v-if="jsonObject.pic2" style="width: 100px; height: 100px" :src="jsonObject.pic2" ></el-image>
            <el-button v-if="jsonObject.pic2" type="primary" icon="el-icon-delete" @click="jsonObject.pic2 = ''" ></el-button>
            <input class="file-input" type="file" ref='fileImportInput2'  @change="fileChange('2')"  />
        </el-form-item>
        <el-form-item label="图2链接:" prop="pic2Url" :label-width="formLabelWidth">
            <el-input v-model="jsonObject.pic2Url" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="广告图3:" prop="pic3" :label-width="formLabelWidth">
            <el-image v-if="jsonObject.pic3" style="width: 100px; height: 100px" :src="jsonObject.pic3" ></el-image>
            <el-button v-if="jsonObject.pic3" type="primary" icon="el-icon-delete" @click="jsonObject.pic3 = ''" ></el-button>
            <input class="file-input" type="file" ref='fileImportInput3'  @change="fileChange('3')"  />
        </el-form-item>
        <el-form-item label="图3链接:" prop="pic3Url" :label-width="formLabelWidth">
            <el-input v-model="jsonObject.pic3Url" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="悬浮按钮链接:" prop="flowButtonUrl" :label-width="formLabelWidth">
            <el-input v-model="jsonObject.flowButtonUrl" autocomplete="off"></el-input>
        </el-form-item>
    </el-form>
    
</template>

<script>
    import {uploadPayOfflineUrl} from "@/api/agentManagement.js"
    export default {
        name: "AdContent",
        props: ['initData'],
        watch: {
            jsonObject:{
                handler (newVal, oldVal) {
                    this.formData.jsonStr = JSON.stringify(newVal)
                },
                deep: true
            },
            initData:function (newVal, oldVal) {
                this.formData = newVal;
                this.jsonObject = JSON.parse(newVal.jsonStr)
            }
        },
        data() {
            return {
                formData:this.initData,
                jsonObject:this.initDataMethod(this.initData),
                formLabelWidth: "110px",
                rules: {
                    name: [{
                        required: true,
                        message: '不能为空',
                        trigger: 'blur'
                    },],
                },
            }
        },
        methods:{
            initDataMethod(data){
                console.log(data)
                if (data.jsonStr == undefined || data.jsonStr == '' || data.jsonStr == '{}') {
                    console.log("1")
                    return {pic1:'',pic3:'',pic2:'',pic1Url:'',pic2Url:'',pic3Url:'',flowButtonUrl:''}
                } else {
                    console.log("2")
                    return JSON.parse(data.jsonStr)
                }
            },
            fileChange(val){
                if(this.$refs['fileImportInput'+val].files[0]){
                    let fileName = this.$refs['fileImportInput'+val].files[0].name
                    let index = fileName.lastIndexOf(".")
                    if (index < 0 || !(fileName.substring(index + 1, fileName.length) == "png" || fileName
                        .substring(index + 1, fileName.length) == "jpg"|| fileName
                        .substring(index + 1, fileName.length) == "jpeg"|| fileName
                        .substring(index + 1, fileName.length) == "svg")) {
                        this.$alert('文件格式错误', '提示', {
                            confirmButtonText: '确定',
                        });
                        return
                    }
                    let param = new FormData()
                    param.append("file", this.$refs['fileImportInput'+val].files[0])
                    uploadPayOfflineUrl(param).then(res=>{

                        this.jsonObject['pic'+val] = res.url
                        console.log(this.jsonObject)
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>