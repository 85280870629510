<template>
	<div class="daheCardFlow fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">流量语音统计</span>
			<el-form inline v-model="formInline" class="right themed-form search-form">
				<el-form-item label="开始时间:">
					<el-date-picker style="width: 100%" v-model="formInline.startTime" type="date"
						value-format="yyyy-MM-dd" />
				</el-form-item>
				<el-form-item label="结束时间:">
					<el-date-picker style="width: 100%" v-model="formInline.endTime" type="date"
						value-format="yyyy-MM-dd" />
				</el-form-item>
				<el-form-item label="ICCID:">
					<el-input v-model="formInline.iccidMark" class="iccid-width"></el-input>
				</el-form-item>
				<el-form-item label="代理商名称:">
					<el-input v-model="formInline.agentThreeName"></el-input>
				</el-form-item>
				<el-form-item label="网卡状态:">
					<!-- <el-input v-model="formInline.status"></el-input> -->
					<el-select v-model="formInline.status">
						<el-option value="">请选择</el-option>
						<el-option v-for="(item,index) in options" :key="index" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询
					</el-button>
				</el-form-item>
					<el-form-item>
					<el-button type="primary" @click="exportExcel" class="themed-button" icon="el-icon-caret-left">
						导出excel</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table ref="table" border stripe :data="tableData" tooltip-effect="dark" :max-height="maxTableHeight">
				<el-table-column prop="iccidMark" label="ICCID"> </el-table-column>
				<el-table-column prop="phone" label="接入号"> </el-table-column>
				<el-table-column prop="agentThreeName" label="代理商"> </el-table-column>
				<el-table-column prop="accountName" label="开卡公司">
				</el-table-column>
				<el-table-column prop="flowSum" label="周期累计用量"> </el-table-column>
				<el-table-column prop="voiceSum" label="周期累计语音">
				</el-table-column>
				<el-table-column prop="status" label="网卡状态">
					<template  v-slot:default="scope">
                   {{getStatus(scope.row.status)}}
					</template>
				</el-table-column>
				<el-table-column prop="startTime" label="统计开始时间">
				</el-table-column>
                <el-table-column prop="endTime" label="统计结束时间">
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="pageNo" :page-sizes="[10, 15, 25, 100]" :page-size="pageSize" background
				layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
				@current-change="onPageChange"></el-pagination>
		</div>
	</div>
</template>
<script>
	import loading from "@/utils/Loading.js";
	import onresize from "@/mixins/resize.js";
	import {
		CardDayStaticsList,cardDayStaticsExport
	} from "@/api/staticsManagement.js";
	export default {
		name:'daheCardFlow',
		mixins: [onresize],
		data() {
			return {
				formInline: {
					id: "",
					startTime: "",
					endTime: "",
					iccidMark:"",
					agentThreeName:"",
					status:"",
				},
				pageNo: 1,
				pageSize: 15,
				total: 0,
				tableData: [],
				options:[{label:"待激活",value:"1"},{label:"正常",value:"2"},{label:"断网",value:"3"},{label:"停机",value:"4"},{label:"异常",value:"5"},{label:"其他",value:"6"}],
			};
		},
		created() {
			this.getCardDayStaticsList(this.formInline);
		},
		methods: {
			onSearch() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};
				Object.assign(params, this.formInline);
				this.getCardDayStaticsList(params);
			},
			handleSizeChange(size) {
				this.pageSize = size;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};
				Object.assign(params, this.formInline);
				this.getCardDayStaticsList(params);
			},
			onPageChange(page) {
				this.pageNo = page;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};
				Object.assign(params, this.formInline);
				this.getCardDayStaticsList(params);
			},
			getCardDayStaticsList(params) {
				CardDayStaticsList(params).then((res) => {
					this.tableData = res.rows;
					this.pageNo = res.pageNo;
					this.pageSize = res.pageSize;
					this.total = res.total;
				});
			},
			getStatus(status){
            
			 if(status=='1')return "待激活";
			 if(status=='2')return "正常";
			 if(status=='3')return "断网";
			 if(status=='4')return "停机";
			 if(status=='5')return "异常";
			 else return "其他";
			},
			exportExcel(){
				 loading.show()
				 /* ../../statistics/adminstatis/cardCycleExport.do?id=&flowSize=&agentId=&startTime=2021-05-05&endTime=&agentThreeName=&iccidMark= */
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				cardDayStaticsExport(params).then(res => {
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
							confirmButtonText: '确定',
						}).then(() => {
							this.$router.push('/tob/i_export_list')
						});
					} else {
						this.$message({
							showClose: true,
							message: res.system_result_message_key,
							type: 'error'
						});
					}
				})
			}
			
		},
	};
</script>
<style>
	.daheCardFlow .el-form-item__label {
		vertical-align: top;
	}
</style>
