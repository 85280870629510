<template>

<div class="pin-order table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">拼单推广</span>
        <el-form inline class="hook right themed-form search-form">   
            <el-form-item>
                <el-input placeholder="请输入代理商" v-model="agentName" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
                </el-input>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="代理商" prop="agentName" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="拼单折扣" prop="discountPin" show-overflow-tooltip>
                <template slot-scope="scope">
                    <input type="text" v-model="scope.row.discountPin" @blur="(event)=>{
                        updateSort(event,scope.row.agentId)
                    }">
                </template>
            </el-table-column> -->
            <el-table-column label="是否开启" prop="allowPin" show-overflow-tooltip>
                <template slot-scope="scope">
                    <strong v-if="scope.row.allowPin == 1">已开启</strong>
                    <span v-else>未开启</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" @click="delFilter(scope.row.agentId,'0')">关闭</el-button>
                    <el-button type="info" class="themed-button" @click="delFilter(scope.row.agentId,'1')">开启</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

</div>

</template>

<script type="text/ecmascript-6">
    import {pinList,updateDiscount,switchPin} from '@/api/eventDiscountManagement/recommend.js'

    import {messageBox,confirmBox } from '@/utils/common.js'
    import resize from '@/mixins/resize.js'

    export default {
        name:'pinOrder',
        mixins:[resize],
        data(){
            return {
                agentName:'',
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0
            }
        },
        mounted(){
            this._pinList()
        },
        methods:{
            updateSort(event,id){
                let value = event.target.value
                if (isNaN(value) || value < 0 || value > 100) {
                    messageBox(this,"无效输入")
                    return;
                }
                let params = {
                    agentId:id,
                    discount:value
                }
                updateDiscount(params).then((res)=>{
                    this._pinList()
                })
            },
            delFilter(id,type){
                let params = {
                    agentId:id,
                    type:type
                }
                console.log(params)
               switchPin(params).then((res)=>{
                   this._pinList()
               })  
            },
            searchData(){
                this._pinList()
            },
            handleSizeChange(val) {
                this.pageSize = val
                this._pinList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this._pinList()
            },
            _pinList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                params.agentName = this.agentName
                pinList(params).then((res)=>{
                    console.log(res)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        }
    }
</script>

