<template>
	<div class="cycleCost fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">用户生命周期统计</span>
			<el-form inline v-model="formInline" class="right themed-form search-form">
				<el-form-item label="日期">
					<el-date-picker style="width: 100%" v-model="formInline.month" type="date"
						value-format="yyyy-MM-dd" />
				</el-form-item>
			
				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询
					</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table ref="table" border stripe :data="tableData" tooltip-effect="dark" :max-height="maxTableHeight">
				<el-table-column prop="accountName" label="运营商"> </el-table-column>
				<el-table-column prop="createTime" label="月份"> </el-table-column>
				<el-table-column prop="useMonth" label="平均生命周期（月）"> </el-table-column>
				<el-table-column prop="cost" label="平均月消费金额（元）">
				</el-table-column>
				
			</el-table>
			<el-pagination :current-page.sync="pageNo" :page-sizes="[10, 15, 25, 100]" :page-size="pageSize" background
				layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
				@current-change="onPageChange"></el-pagination>
		</div>
	</div>
</template>
<script>
	import loading from "@/utils/Loading.js";
	import onresize from "@/mixins/resize.js";
	import {
		getAgentList,cardCycleList
	} from "@/api/staticsManagement.js";
	export default {
		name:'cycleCost',
		mixins: [onresize],
		data() {
			return {
				formInline: {
					month: "",
				},
				pageNo: 1,
				pageSize: 15,
				total: 0,
				tableData: [],
			};
		},
		created() {
            let params = {status:0}
            Object.assign(params,this.formInline);
			this.getAgentList(params)
            this.getCardCycleList(this.formInline)
		},
		methods: {
			onSearch() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};
				Object.assign(params, this.formInline);
				this.getCardCycleList(params);
			},
			handleSizeChange(size) {
				this.pageSize = size;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};
				Object.assign(params, this.formInline);
				this.getCardCycleList(params);
			},
			onPageChange(page) {
				this.pageNo = page;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};
				Object.assign(params, this.formInline);
				this.getCardCycleList(params);
			},
            getAgentList(params){
            getAgentList(params).then(res=>{
                if(res.slide_msg_key==1){
                  this.$alert(res.slide_msg_message_key,"提示",{
									confirmButtonText: '确定',
								})
                }
            })
            },
			getCardCycleList(params) {
				cardCycleList(params).then((res) => {
					this.tableData = res.rows;
					this.pageNo = res.pageNo;
					this.pageSize = res.pageSize;
					this.total = res.total;
				});
			},
			
		},
	};
</script>
<style>
	.cycleCost .el-form-item__label {
		vertical-align: top;
	}
</style>
