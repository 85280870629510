<template>
  <div class="closeBalanceList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">关闭余额列表</span>
      <el-form
        :inline="true"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
        id="baseForm"
      >
        <el-form-item label="iccid/短号:">
          <el-input v-model="form.iccidMark" placeholder="输入ICCID"></el-input>
        </el-form-item>
        <!-- <el-form-item label="短号:">
          <el-input v-model="form.shortIccid" placeholder="输入短号"></el-input>
        </el-form-item> -->
        <el-form-item label="接入号:">
          <el-input v-model="form.phone" placeholder="输入接入号"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            class="themed-button"
            icon="el-icon-search"
            >搜索查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="themed-button"
            @click="batchadd"
            >批量增加</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="themed-button"
            @click="batchdelete"
            >批量删除</el-button
          >
        </el-form-item>
        <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        ref="table"
        :max-height="maxTableHeight"
        border
      >
        <el-table-column prop="iccidMark" label="iccid"> </el-table-column>
        <el-table-column prop="phone" label="接入号"> </el-table-column>
        <el-table-column prop="shortIccid" label="短号"> </el-table-column>
        <el-table-column prop="accountName" label="开卡公司"> </el-table-column>
        <el-table-column prop="createDate" label="创建时间">
          <template slot-scope="scope">
            <span>{{ getTime(scope.row.createDate) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="操作人">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="themed-button"
              type="danger"
              @click="deleteRecord(scope.row.iccidMark)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="删除卡"
      :visible.sync="dialogDeleteCardByExcel"
      destroy-on-close
      v-if="dialogDeleteCardByExcel"
    >
      <el-form
        label-position="right"
        label-width="180px"
        :model="deleteCardByExcelFrom"
        ref="deleteCardByExcelFrom"
      >
        <el-form-item label="上传卡号列表(excel):">
          <input class="file-input" type="file" ref="deleteCardfile" />
          <div class="mt5">
            <span class="text-color" style="font-size: 12px"
              >仅支持.xls/.xlsx格式</span
            >
          </div>
        </el-form-item>
        <el-form-item label="ICCID/短号:">
          <el-input
            v-model="deleteCardByExcelFrom.iccids"
            type="textarea"
            placeholder="请输入ICCID/短号每行一个"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="info" @click="deleteCardExcel()"
          >导入excel模板</el-button
        >
        <el-button
          class="themed-button"
          type="info"
          @click="exportTemplateExcel('balanceclose.xlsx')"
          >导出excel模板</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogDeleteCardByExcel = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="新增卡"
      :visible.sync="dialogImportCard"
      destroy-on-close
      v-if="dialogImportCard"
    >
      <el-form
        label-position="right"
        label-width="120px"
        :model="importCardForm"
        ref="importCardForm"
      >
        <el-form-item label="选择excel文件:">
          <input class="file-input" type="file" ref="importCardfile" />
          <div class="mt5">
            <span class="text-color" style="font-size: 12px"
              >仅支持.xls/.xlsx格式</span
            >
          </div>
        </el-form-item>
        <el-form-item label="ICCID/短号:">
          <el-input
            v-model="importCardForm.iccids"
            type="textarea"
            placeholder="请输入ICCID/短号每行一个"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="info" @click="importCardExcel()"
          >导入excel模板</el-button
        >
        <el-button
          class="themed-button"
          type="info"
          @click="exportTemplateExcel('balanceclose.xlsx')"
          >导出excel模板</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogImportCard = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import {
  closeBalanceList,
  delCloseBalanceRecord,
  deleteByExcelBalance,
  importBalanceCard,
  exportExcelSync
} from "@/api/cardManagement/closeBalanceList.js";
// import {messageBox} from '@/utils/common.js'
// import dictGroup from '@/mixins/dictGroup.js'
// import {orderGetAgents} from '@/api/agentApplyCheck.js'
import Utils from "@/utils/utils.js";
import { exportTemplate } from "@/api/statistics.js";
export default {
  name: "closeBalanceList",
  mixins: [onresize],
  data() {
    return {
      form: {
        iccidMark: "",
        phone: "",
        // shortIccid:""
      },
      page: 1,
      pageSize: 15,
      total: 0,
      tabledata: [],
      dialogDeleteCardByExcel: false,
      dialogImportCard: false,
      importCardForm: {
        iccids: "",
      },
      deleteCardByExcelFrom: {
        iccids: "",
      },
    };
  },
  created() {
    this.getCloseBalanceList();
  },
  methods: {
     exportExcel(){
            exportExcelSync(this.form).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
    getCloseBalanceList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.form, params);
      closeBalanceList(this.form).then((res) => {
        this.tabledata = res.rows;
        console.log(this.tableData);
        this.total = res.count;
      });
    },
    getTime(time) {
      let end = time.indexOf("T");
      let time1 = time.substring(0, end);
      return time1;
    },
    searchData() {
      this.page = 1;
      this.getCloseBalanceList();
    },
    onPageChange(page) {
      this.page = page;
      this.getCloseBalanceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getCloseBalanceList();
    },
    deleteRecord(iccid) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delCloseBalanceRecord({ iccid }).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
        this.getCloseBalanceList();
      });
    },
    batchadd() {
      this.importCardForm.iccids = "";
      this.dialogImportCard = true;
    },
    batchdelete() {
      this.deleteCardByExcelFrom.iccids = "";
      this.dialogDeleteCardByExcel = true;
    },
    exportTemplateExcel(name) {
      exportTemplate({ name: name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
    deleteCardExcel() {
      this.$confirm("确定导入吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let file = this.$refs["deleteCardfile"].files[0];
        if (file) {
          let fileName = file.name;
          let index = fileName.lastIndexOf(".");
          if (
            index < 0 ||
            !(
              fileName.substring(index + 1, fileName.length) == "xls" ||
              fileName.substring(index + 1, fileName.length) == "xlsx"
            )
          ) {
            this.$alert("文件格式错误", "提示", {
              confirmButtonText: "确定",
            });
            return;
          } else if (!!Utils.checkFile(file)) {
            if (Utils.checkFile(file) == 1) {
              messageBox(this, "上传文件不能超过20M");
              return;
            } else if (Utils.checkFile(file) == 2) {
              messageBox(this, "上传文件名称长度不能超过36个字符");
              return;
            }
          } else {
            let param = new FormData();
            param.append("fileurl", file);
            deleteByExcelBalance(param).then((res) => {
              if (res.system_result_key == "0") {
                this.$alert("导入成功，请到导入记录中下载", "提示", {
                  confirmButtonText: "确定",
                }).then(() => {
                  this.dialogDeleteCardByExcel = false;
                  this.$router.push("/tob/i_import_list");
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.system_result_message_key,
                  type: "error",
                });
              }
            });
          }
        } else if (!!this.deleteCardByExcelFrom.iccids.trim()) {
          if (!Utils.checkICCID(this.deleteCardByExcelFrom.iccids)) {
            messageBox(this, "iccid只能包含数字和字母");
            return;
          }
          let param = new FormData();
          param.append("iccids", this.deleteCardByExcelFrom.iccids);
          deleteByExcelBalance(param).then((res) => {
              if (res.system_result_key == '0') {
                            this.$alert("删除成功", "提示", {
              confirmButtonText: "确定",
            }).then(() => {
              this.dialogDeleteCardByExcel = false;
              this.getCloseBalanceList();
            });
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
          });
        } else {
          this.$alert("请选择文件或输入ICCID", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      });
    },
    importCardExcel() {
      this.$confirm("确定导入吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let file = this.$refs["importCardfile"].files[0];
        if (file) {
          let fileName = file.name;
          let index = fileName.lastIndexOf(".");
          if (
            index < 0 ||
            !(
              fileName.substring(index + 1, fileName.length) == "xls" ||
              fileName.substring(index + 1, fileName.length) == "xlsx"
            )
          ) {
            this.$alert("文件格式错误", "提示", {
              confirmButtonText: "确定",
            });
            return;
          } else if (!!Utils.checkFile(file)) {
            if (Utils.checkFile(file) == 1) {
              messageBox(this, "上传文件不能超过20M");
              return;
            } else if (Utils.checkFile(file) == 2) {
              messageBox(this, "上传文件名称长度不能超过36个字符");
              return;
            }
          } else {
            let param = new FormData();
            param.append("fileurl", file);
            importBalanceCard(param).then((res) => {
              if (res.system_result_key == "0") {
                this.$alert("导入成功，请到导入记录中下载", "提示", {
                  confirmButtonText: "确定",
                }).then(() => {
                  this.dialogImportCard = false;
                  this.$router.push("/tob/i_import_list");
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.system_result_message_key,
                  type: "error",
                });
              }
            });
          }
        } else if (!!this.importCardForm.iccids.trim()) {
          if (!Utils.checkICCID(this.importCardForm.iccids)) {
            messageBox(this, "iccid只能包含数字和字母");
            return;
          }
          let param = new FormData();
          param.append("iccids", this.importCardForm.iccids);
          importBalanceCard(param).then((res) => {
             if (res.system_result_key == '0') {
                        this.$alert("添加成功", "提示", {
              confirmButtonText: "确定",
            }).then(() => {
              this.dialogImportCard = false;
              this.getCloseBalanceList();
            });     
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
          });
        } else {
          this.$alert("请选择文件或输入ICCID", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      });
    },
  },
};
</script>

<style>
.closeBalanceList .el-form-item__label {
  vertical-align: top;
}
</style>
