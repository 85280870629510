import request from './request.js'
export function getWithdrawList(params) {
    return request({
		url:"/balance/withdraw/list.do?",
        method: 'post',
        data: params
    })
}
export function auditInfo(params) {
    return request({
        url: '/balance/withdraw/update.do?',
        method: 'post',
        data: params
    })
}

export function WithdrawDetail(params) {
    return request({
        url: '/balance/withdraw/findById.do?',
        method: 'post',
        data: params
    })
}
