<template>
    <div class="history- nic-recovery fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">网卡回收记录</span>
            <el-form inline ref="NICrRecoveryForm" v-model="NICrRecoveryData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="导入起始日期" v-model="NICrRecoveryData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="导入结束日期" v-model="NICrRecoveryData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
                    <el-input v-model="NICrRecoveryData.iccidMark" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                 <el-form-item>
                    <el-input v-model="NICrRecoveryData.shortIccid" placeholder="短号"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID"
                    min-width="20%"
                    >
                </el-table-column>
                 <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="20%"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="createDate"
                    label="回收时间"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    label="原代理商"
                    min-width="20%"
                    >
                    <template slot-scope="scope">
                        <span v-if="-1 !=scope.row.oldAgentTreeName.indexOf('_')">{{handleAgentName(scope.row.oldAgentTreeName)}}</span>
                        <span v-else>{{scope.row.oldAgentTreeName}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="处理状态"
                    min-width="10%"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.recycleStatus ==0">成功</span>
                        <span v-else-if="scope.row.recycleStatus ==1">失败</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="failDescribe"
                    label="失败原因"
                    min-width="20%"
                    >   
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    min-width="10%"
                    >   
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import { NICrRecovery } from "@/api/history.js";
export default {
    name:'NICrRecovery',
    mixins:[resize],
    data(){
        return {
            NICrRecoveryData:{
                startTime:'',
                endTime:'',
                iccidMark:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getNICrRecoveryList()
    },
    methods:{
        getNICrRecoveryList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.NICrRecoveryData,params)
            NICrRecovery(this.NICrRecoveryData).then(res=>{
                this.tableData = res.rows.map(item=>{
                    item.createDate = Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(item.createDate))
                    return item
                })
                this.total=res.count
            })
        },
        handleAgentName(name){
            return name.substring(name.lastIndexOf("_") + 1, name.length);
        },
         handleSizeChange(size){
            this.pageSize=size
            this.getNICrRecoveryList()
        },
        onPageChange(page){
            this.page=page
            this.getNICrRecoveryList()
        },
        onSearch(){
            this.page=1
            this.getNICrRecoveryList()
        },
    }
}
</script>