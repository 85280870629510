/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function getCouponsList(params){
    return request({
        url: 'promotion/coupons/list.do?',
        method: 'post',
        data:params
    })
}
export function updateData(params){
    return request({
        url: 'promotion/coupons/update.do',
        method: 'post',
        data:params
    })
}
export function deleteData(params){
    return request({
        url: 'promotion/coupons/delete.do?',
        method: 'post',
        data:params
    })
}
//newCoupons.vue 
export function couponsSave(params){
    return request({
        url: 'promotion/coupons/save.do?',
        method: 'post',
        data:params
    })
}
//filterShow.vue 
export function listRuleById(params){
    return request({
        url: 'promotion/coupons/listRuleById.do?',
        method: 'post',
        data:params
    })
}
export function delShowFilterById(params){
    return request({
        url: 'promotion/coupons/delShowFilterById.do?',
        method: 'post',
        data:params
    })
}
//dialogShow.vue
export function getAgentList(params){
    return request({
        url: 'agent/list4agent.do?',
        method: 'post',
        data:params
    })
}
export function getMealListByAgentId(params){
    return request({
        url: 'promotion/coupons/getMealListByAgentId.do?',
        method: 'post',
        data:params
    })
}
export function addShowFilterById(params){
    return request({
        url: 'promotion/coupons/addShowFilterById.do?',
        method: 'post',
        data:params
    })
}

//dialogUse.vue
export function delUseFilterById(params){
    return request({
        url: 'promotion/coupons/delUseFilterById.do?',
        method: 'post',
        data:params
    })
}
export function addUseFilterById(params){
    return request({
        url: 'promotion/coupons/addUseFilterById.do?',
        method: 'post',
        data:params
    })
}
export function rechargeAmountlist(params){
    return request({
        url: 'rechargeAmount/list.do?',
        method: 'post',
        data:params
    })
}

