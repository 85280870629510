<template>
    <div class="history-nic-logout fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">网卡注销记录</span>
            <el-form inline ref="NICLogoutForm" v-model="NICLogoutData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
                    <el-input v-model="NICLogoutData.iccidMark" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="NICLogoutData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID号"
                    >
                </el-table-column>
                <el-table-column
                    prop="phone"
                    label="接入号"
                    >
                </el-table-column>
                 <el-table-column
                    prop="cardStatusStr"
                    label="状态"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    >  
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createDate"
                    label="注销时间"
                    >  
                </el-table-column>
                
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import { cancelCardRecord } from "@/api/history.js";
export default {
    name:'NICLogout',
    mixins:[resize],
    data(){
        return {
            NICLogoutData:{
                iccidMark:'',
                phone:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getCancelCardList()
    },
    methods:{
        getCancelCardList(){
            let params={
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.NICLogoutData,params)
            cancelCardRecord(this.NICLogoutData).then(res=>{
                this.tableData = res.rows.map(item=>{
                    item.createDate = Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(item.createDate))
                    return item
                })
                this.total = res.count
            })
        },
        onSearch(){
            this.page=1
            this.getCancelCardList()
        },
        handleSizeChange(size){
            this.pageSize=size
            this.getCancelCardList()
        },
        onPageChange(page){
            this.page=page
            this.getCancelCardList()
        },
    }
}
</script>