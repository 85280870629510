<template>
	<div class="toNewCard">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px">
			<el-form-item label="新卡卡号/短号:" prop="newIccidMark" required>
				<el-input v-model="ruleForm.newIccidMark"></el-input>
			</el-form-item>
			<el-form-item label="选择运营商类型:" prop="accountType" required>
				<el-radio-group v-model="ruleForm.accountType">
					<el-radio :label="1" @change="hide">相同运营商转卡</el-radio>
					<el-radio :label="2" @change="show">不同运营商转卡</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="仅转层级关系:" prop="transType" required  style="display:none;">
				<el-checkbox-group v-model="ruleForm.transType">
					<el-checkbox name="transType"></el-checkbox>
				</el-checkbox-group>
			</el-form-item>

			<el-form-item v-if="isShow" label="转后套餐运营商:" prop="category" required>
				<el-radio-group v-model="ruleForm.category" @change="_getSetMealByCategory">
					<el-radio :label="1">电信</el-radio>
					<el-radio :label="3">移动</el-radio>
					<el-radio :label="5">dcp电信</el-radio>
					<el-radio :label="6">联通</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="isShow" label="新卡套餐:" prop="mealId" required>
				<search-select :optionsMetaAll="mealList" v-model="ruleForm.mealId"></search-select>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" class="themed-button" @click="submitForm('ruleForm')"   v-preventReClick='2000' :disabled="isDisable">确认</el-button>
				<el-button type="primary"  class="themed-button" @click="close">关闭</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script type="text/ecmascript-6">
	import SearchSelect from "@/components/SearchSelect";

	import {
		getSetMealByCategory,
		toNewCard
	} from "@/api/cardManagement/cardBillDetail.js";

	import {
		messageBox,
		confirmBox
	} from '@/utils/common.js'

	export default {
		name:'toNewCard',
		props: ["iccidMark"],
		data() {
			return {
				isShow: false,
				ruleForm: {
					newIccidMark: "",
					accountType: "",
					transType: false,
					category: "",
					mealId: "",
				},
				mealList: [],
				rules: {
					newIccidMark: [{
						required: true,
						message: "新卡卡号不能为空",
						trigger: "blur"
					}, ],
					accountType: [{
						required: true,
						message: "选择运营商",
						trigger: "blur"
					}],
					category: [{
						required: true,
						message: "选择转后套餐运营商",
						trigger: "blur"
					}, ],
					mealId: [{
						required: true,
						message: "选择新卡套餐",
						trigger: "change"
					}, ],
				},
				isDisable:false,
			};
		},
		methods: {
			submitForm(formName) {
				//   console.log(this.ruleForm);
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//   alert("submit!");
						this._toNewCard()
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			close() {
				this.$refs["ruleForm"].resetFields()
				this.$emit('close')
			},
			hide() {
				this.isShow = false;
				this.ruleForm.category = "";
				this.ruleForm.mealId = "";
			},
			show() {
				this.isShow = true;
			},
			_getSetMealByCategory() {
				let category = "";
				if (this.ruleForm.category == "") {
					category = 1;
				} else {
					category = this.ruleForm.category;
				}
				let oldIccidMark = this.iccidMark;
				let status = 1;
				let params = {
					oldIccidMark,
					category,
					status,
				};
				getSetMealByCategory(params).then((res) => {
					this.mealList = res.map((item) => {
						return {
							label: item.description,
							value: item.id,
						};
					});
					// console.log(this.mealList)
				});
			},
			_toNewCard() {
				let params = {}
				params.oldIccidMark = this.iccidMark
				params.iccidMark = this.iccidMark
				params.newIccidMark = this.ruleForm.newIccidMark
				if (this.ruleForm.accountType === 2) {
					params.category = this.ruleForm.category
					params.mealId = this.ruleForm.mealId
					params.accountType = 2
				}
				if (this.ruleForm.transType) {
					params.transType = 2
				} else {
					params.transType = 1
				}
				this.isDisable = true
				toNewCard(params).then((res) => {
					   this.isDisable = false
					if (res.app_result_key === "0") {
						messageBox(this, '操作成功。')
						this.close()
					} else {
						console.log(1)
						messageBox(this, res.system_result_message_key)
					}
				}).catch(() => {
					   this.isDisable = false
					// alert('找后台核对参数')
				})
			}
		},
		components: {
			SearchSelect,
		},
	}
</script>

<style>
	.toNewCard .el-input {
		width: auto;
	}

	.el-select-dropdown__list {
		max-width: 550px
	}
</style>
