<template>
    <div class="virtual-card-config fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">虚拟卡配置列表</span>
            <el-form inline ref="virtualConfigForm" v-model="virtualConfigFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="虚拟运营商">
                    <el-select v-model="virtualConfigFormData.accountId">
                        <el-option value="" label="全部">全部</el-option>
                        <el-option v-for="item in virtualAgentList" :key="item.id" :label="item.accountName" :value="item.id">
                        </el-option>
                    </el-select>
			    </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button type="primary" @click="add" icon="el-icon-plus"  class="themed-button">新增</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                 show-overflow-tooltip
                    prop="accountName"
                    label="虚拟运营商"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="priorityName1"
                    label="优先级一"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="priorityName2"
                    label="优先级二"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="priorityName3"
                    label="优先级三"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    label="是否强制"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.isForce == '1'">是</span>
                        <span v-else-if="scope.row.isForce == '0'">否</span>
                    </template>
                </el-table-column>
               
                <el-table-column
                    show-overflow-tooltip
                    prop="defaultDuration"
                    label="默认冷却时间"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="创建时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="updateTime"
                    label="更新时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="160">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="info" @click="handleEdit(scope.row.id)">编辑</el-button>
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
                
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="新建" :visible.sync="dialogVisiableAdd" destroy-on-close>
        	<el-form label-position="right" label-width="120px" :model="addForm"  ref="addForm">
            <el-form-item label="虚拟运营商"  prop="accountId">
                    <el-select v-model="addForm.accountId" placeholder="请选择" filterable>
                        <el-option v-for="item in virtualAgentList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
			<el-form-item label="优先级一"  prop="priority1">
                    <el-select v-model="addForm.priority1" placeholder="请选择" filterable>
                        <el-option v-for="item in accountIdList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="优先级二"  prop="priority2">
                    <el-select v-model="addForm.priority2" placeholder="请选择" filterable>
                        <el-option v-for="item in accountIdList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="优先级三"  prop="priority3">
                    <el-select v-model="addForm.priority3" placeholder="请选择" filterable>
                        <el-option v-for="item in accountIdList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="默认冷却时间" prop="defaultDuration">
					<el-input v-model="addForm.defaultDuration"></el-input>
				</el-form-item>
            <el-form-item label="是否强制"  prop="isForce">
                    <el-select v-model="addForm.isForce" placeholder="请选择">
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
            </el-form-item>
        	</el-form>
        	<div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureAdd('addForm')">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogVisiableAdd = false">关 闭</el-button>
            </div>
    	</el-dialog>


        <el-dialog title="编辑" :visible.sync="dialogVisiableEdit" destroy-on-close>
        	<el-form label-position="right" label-width="120px" :model="editForm"  ref="editForm">
            <el-form-item label="虚拟运营商"  prop="accountId">
                    <el-select v-model="editForm.accountId" placeholder="请选择" filterable>
                        <el-option v-for="item in virtualAgentList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
			<el-form-item label="优先级一"  prop="priority1">
                    <el-select v-model="editForm.priority1" placeholder="请选择" filterable>
                        <el-option v-for="item in accountIdList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="优先级二"  prop="priority2">
                    <el-select v-model="editForm.priority2" placeholder="请选择" filterable>
                        <el-option v-for="item in accountIdList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="优先级三"  prop="priority3">
                    <el-select v-model="editForm.priority3" placeholder="请选择" filterable>
                        <el-option v-for="item in accountIdList" :key='item.index' :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="默认冷却时间" prop="defaultDuration">
					<el-input v-model="editForm.defaultDuration"></el-input>
				</el-form-item>
            <el-form-item label="是否强制"  prop="isForce">
                    <el-select v-model="editForm.isForce" placeholder="请选择">
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
            </el-form-item>
        	</el-form>
        	<div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureEdit('editForm')">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogVisiableEdit = false">关 闭</el-button>
            </div>
    	</el-dialog>
        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {agentOperatorList} from '@/api/order.js'
import { virtualConfigList,addVirtualConfig,editVirtualConfig,deleteVirtualConfig,findVirtualConfigById} from "@/api/virtualCard.js"
export default {
    name:'virtualCardConfig',
    mixins:[resize],
    data(){
        return {
            virtualConfigFormData:{
                accountId:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            accountIdList:[],
            virtualAgentList:[],
            dialogVisiableAdd:false,
            dialogVisiableEdit:false,
            addForm:{
                accountId:'',
                priority1:'',
                priority2:'',
                priority3:'',
                defaultDuration:'',
                isForce:'',
            },
            editForm:{
                id:'',
                accountId:'',
                priority1:'',
                priority2:'',
                priority3:'',
                defaultDuration:'',
                isForce:'',
            },
        }
    },
    created(){
        this.getAgentOperatorList()
        this.getVirtualConfigList()  
    },
    methods: {
         getAgentOperatorList(){
            agentOperatorList().then(res=>{
                this.accountIdList = res
                this.virtualAgentList = res.filter(item=>{
                    return item.accountName.includes('虚拟')
                })
             })
        },
        getVirtualConfigList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.virtualConfigFormData,params)
            virtualConfigList(this.virtualConfigFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getVirtualConfigList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getVirtualConfigList()
        },

        onSearch(){
            this.page=1
            this.getVirtualConfigList()
        },

        formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
         },

         add(){
             this.dialogVisiableAdd = true
         },

         sureAdd(formName){
            this.$confirm('确定要新增吗？','消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                if(this.addForm.priority1==this.addForm.priority2 || this.addForm.priority1==this.addForm.priority3 ||this.addForm.priority2==this.addForm.priority3) {
                    this.$alert("优先级不能相同","提示信息",{
						 confirmButtonText: '确定',
					})
					return
                } else {
                    addVirtualConfig(this.addForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '新建成功',
					        type: 'success',
							center: true
                        })
                        this.$refs[formName].resetFields()
                        this.dialogVisiableAdd=false
                        this.getVirtualConfigList() 
                    })

                }
            });
        },

        handleEdit(id){
            this.dialogVisiableEdit = true
            findVirtualConfigById({id}).then(res=>{
                this.editForm.id = res.id
                this.editForm.accountId = res.accountId
                this.editForm.priority1 = res.priority1
                this.editForm.priority2 = res.priority2
                this.editForm.priority3 = res.priority3
                this.editForm.defaultDuration = res.defaultDuration
                this.editForm.isForce = res.isForce.toString()
            })
        },

        sureEdit(formName){
            this.$confirm('确定要修改吗？','消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                if(this.editForm.priority1==this.editForm.priority2 || this.editForm.priority1==this.editForm.priority3 ||this.editForm.priority2==this.editForm.priority3) {
                    this.$alert("优先级不能相同","提示信息",{
						 confirmButtonText: '确定',
					})
					return
                } else {
                    editVirtualConfig(this.editForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '修改成功',
					        type: 'success',
							center: true
                        })
                        this.$refs[formName].resetFields()
                        this.dialogVisiableEdit=false
                        this.getVirtualConfigList() 
                    })

                }
            });
        },

        handleDelete(id){
            this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    deleteVirtualConfig({'id':id}).then(res=>{
                        this.$message({
								type: 'success',
								message:'删除成功'
                            });
                        this.getVirtualConfigList()
                    })
                    
                })
        }  
    },
}
</script>

<style>
    .virtual-card-config .el-form-item__label{
        vertical-align: top;
    }
</style>