<template>
  <div class="orderList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">订单列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
       <el-form-item label="订单号:" >
                <el-input v-model="formInline.orderNum"></el-input>
            </el-form-item>
       
        <el-form-item>
          <el-select
            v-model="formInline.status"
            placeholder="订单状态"
            filterable
          >
            <el-option label="选择订单状态" value=""></el-option>
            <el-option label="待支付" value="1"></el-option>
            <el-option label="待审核" value="2"></el-option>
             <el-option label="待发货" value="3"></el-option>
            <el-option label="审核未通过" value="4"></el-option>
             <el-option label="已发货" value="5"></el-option>
            <el-option label="完成" value="6"></el-option>
             <el-option label="退款" value="7"></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="订单地址信息:" >
                <el-input v-model="formInline.addressInfo"></el-input>
            </el-form-item>
            <el-form-item>
          <el-date-picker
            v-model="time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
          <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="exportExcel">导出Excel</el-button>
        </el-form-item>
      
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="orderNum" label="订单号" min-width="120">
        </el-table-column>
        <el-table-column prop="agentName" label="客户" min-width="120">
        </el-table-column>
        <el-table-column prop="totalFee"   label="金额" min-width="120">
         
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="status"
          label="状态"
          min-width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">待支付</span>
            <span v-else-if="scope.row.status == 2">待审核</span>
            <span v-else-if="scope.row.status == 3">待发货</span>
            <span v-else-if="scope.row.status == 4">审核未通过</span>
            <span v-else-if="scope.row.status == 5">已发货</span>
            <span v-else-if="scope.row.status == 6">完成</span>
            <span v-else-if="scope.row.status == 7">退款</span>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="contact"
          label="联系人"
          min-width="120"
        >
         
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="contactPhone"
          label="联系人手机号"
          min-width="120"
        >
        
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="创建时间"
          min-width="120"
        >
        
        </el-table-column>
          <el-table-column
          show-overflow-tooltip
          prop="payTime"
          label="支付时间"
          min-width="120"
        >
        
        </el-table-column>
          <el-table-column
          show-overflow-tooltip
          prop="auditTime"
          label="审核时间"
          min-width="120"
        >
        
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="saleName"
          label="销售"
          min-width="120"
        >
        
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="remark"
          label="备注"
          min-width="120"
        >
        
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="expressNum"
          label="快递单号"
          min-width="120"
        >
        
        </el-table-column>
        <!-- 1 代理商订单 2 微信订单。 -->
        <el-table-column
          show-overflow-tooltip
          prop="orderType"
          label="订单类型"
          min-width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.orderType == 1">代理商订单</span>
            <span v-else-if="scope.row.orderType == 2">微信订单</span>
          
          </template>
        
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="expressName"
          label="快递名称"
          min-width="120"
        >
        
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop=""
          label="开票状态"
          min-width="120"
        >
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="refundMoney"
          label="退款金额"
          min-width="120"
        >
        </el-table-column>

        <el-table-column label="操作" min-width="200">
          <template slot-scope="scope">
             <!-- <el-button v-if="scope.row.status==4||scope.row.status==6" -->
             <el-button 
              type="primary"
              class="themed-button"
              @click="getDetail(scope.row)"
              >详情</el-button
            >
             <el-button v-if="scope.row.status==2"
              type="primary"
              class="themed-button"
              @click="audit(scope.row)"
              >审核</el-button
            >
            <el-button v-if="scope.row.status==3"
              type="primary"
              class="themed-button"
              @click="deliver(scope.row)"
              >发货</el-button
            >
            
             <el-button v-if="scope.row.status==3||scope.row.status==5||scope.row.status==6"
              type="primary"
              class="themed-button"
              @click="goBackMoney(scope.row)"
              >退款</el-button
            >
            <!-- <el-button v-if="scope.row.status==5"
              type="primary"
              class="themed-button"
              @click="exportOrderCard(scope.row)"
              >导卡</el-button
            > -->
            <!-- <el-button
              type="danger"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
              状态为未审核时，按钮显示审核；待发货时，显示发货按钮；已发货时，显示导卡按钮；完成或失败的订单，显示详情。
            > -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
      <el-dialog title="审核" :visible.sync="dialogAudit"   v-if="dialogAudit"> 
             <el-form :model="auditform"  >
				<el-form-item label="订单号：" :label-width="formLabelWidth" prop="orderNum">
					<el-input v-model="auditform.orderNum" autocomplete="off"  :disabled="true"></el-input>
				</el-form-item>
         <el-form-item label="审核是否通过:" :label-width="formLabelWidth">
					<el-radio-group v-model="auditform.type">
						<el-radio :label="1">通过</el-radio>
						<el-radio :label="2">不通过</el-radio>
					</el-radio-group>
				</el-form-item>
        	<el-form-item label="备注：" :label-width="formLabelWidth" prop="remark">
					<el-input v-model="auditform.remark" autocomplete="off"  ></el-input>
				</el-form-item>
			 </el-form>
			 <div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="auditformSubmit" class="themed-button" >确 定</el-button>
				<el-button type="info" @click="dialogAudit=false" class="themed-button">取 消</el-button>
			</div>
		</el-dialog>
     <el-dialog title="发货信息录入" :visible.sync="dialogBox">
        <el-form label-position="right" label-width="120px">
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            	<el-form-item label="订单号：" :label-width="formLabelWidth" prop="orderNum">
					<el-input v-model="deliverForm.orderNum" autocomplete="off"  :disabled="true"></el-input>
				</el-form-item>
        <el-form-item label="快递单号：" :label-width="formLabelWidth" prop="expressNum">
					<el-input v-model="deliverForm.expressNum" autocomplete="off"  ></el-input>
				</el-form-item>
         <el-form-item label="快递公司名称" :label-width="formLabelWidth" prop="expressName">
					<el-input v-model="deliverForm.expressName" autocomplete="off"  ></el-input>
				</el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">确定导入</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('importDeliver.xls')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取消</el-button>
        </div>
    </el-dialog>
     <el-dialog title="退款" :visible.sync="dialogRefund"   v-if="dialogRefund"> 
             <el-form :model="refundform"  >
				<el-form-item label="订单号：" :label-width="formLabelWidth" prop="orderNum">
					<el-input v-model="refundform.orderNum" autocomplete="off"  :disabled="true"></el-input>
				</el-form-item>
        
        <el-form-item label="退款金额：" :label-width="formLabelWidth" prop="refundMoney">
					<el-input v-model="refundform.refundMoney" autocomplete="off"  ></el-input>
				</el-form-item>
         <el-form-item label="是否清楚数据:" :label-width="formLabelWidth">
					<el-radio-group v-model="refundform.clearData">
						<el-radio :label="true">是</el-radio>
						<el-radio :label="false">否</el-radio>
					</el-radio-group>
				</el-form-item>
        	<el-form-item label="退款备注：" :label-width="formLabelWidth" prop="refundRemark">
					<el-input v-model="refundform.refundRemark" autocomplete="off"  ></el-input>
				</el-form-item>
			 </el-form>
			 <div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="refundformSubmit" class="themed-button" >确 定</el-button>
				<el-button type="info" @click="dialogRefund=false" class="themed-button">取 消</el-button>
			</div>
		</el-dialog>
    </div>
  </div>
</template>

<script>
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import { getorderList ,checkOrder,deliverExport,refundOrder,orderPageList,orderListexportRecord} from "@/api/goodList.js";
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
export default {
  name: "orderList",
  mixins: [resize],
  data() {
    return {
      formInline: {
        orderNum:"",
        addressInfo:"",
        createTimeBegin:"",
        createTimeEnd:"",
        status:"",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      time:"",
      dialogAudit:false,
      formLabelWidth:"120px",
      auditform:{
        orderNum:"",
        type:"",
        remark:""
      },
      deliverForm:{
        orderNum:"",
        expressNum:"",
        expressName:"",
      },
      dialogBox:false,
     dialogRefund:false,
     refundform:{
       orderNum:"",
       refundMoney:"",
       refundRemark:"",
       clearData:false,
     },
     currentMoney:"",
    };
  },
  created() {
    if (store.getters.categorys.length < 1) {
      store.dispatch("getCategory");
    }
    this.getorderList();
  },
  methods: {
      exportExcel(){
            orderListexportRecord(this.formInline).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },
   
    goBackMoney(row){
  this.dialogRefund= true 
  this.refundform.orderNum= row.orderNum
  this.currentMoney = row.totalFee
  this.refundform.refundRemark= ""
  this.refundform.refundMoney= ""
  
    },
    refundformSubmit(){
      if (this.refundform.refundMoney === "") {
        this.$alert('请输入退款金额', '提示', {
								confirmButtonText: '确定',
							});
							return
       
      } else {
        if (!/^\d+(\.\d{0,2})?$/.test(this.refundform.refundMoney)) {
           this.$alert('退款金额只能是数字且最多保留两位小数', '提示', {
								confirmButtonText: '确定',
							});
							return
        }
      }
      if(parseFloat(this.refundform.refundMoney)>parseFloat(this.currentMoney)){
       this.$alert('退款金额不能大于订单金额', '提示', {
								confirmButtonText: '确定',
							});
							return
      }
       refundOrder(this.refundform).then(res=>{
      this.dialogRefund=false
       this.getorderList();
          this.$message({
            type: "success",
            message: '退款成功',
          })
     })
    },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else if(this.deliverForm.expressNum.trim()==""){
                     messageBox(this,'请输入快递单号')
                            return
                }else if(this.deliverForm.expressName.trim()==""){
                     messageBox(this,'请输入快递公司名称')
                            return
                }
                else{
                    
                    let params = new FormData()
                    params.append('file',file)
                    params.append('expressNum',this.deliverForm.expressNum)
                    params.append('expressName',this.deliverForm.expressName)
                    params.append('orderNum',this.deliverForm.orderNum)
                    deliverExport(params).then(res=>{
                      console.log(res)
                        if (res.system_result_key == '0') {
                           this.dialogBox = false
                            this.$alert('导入成功！', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                              this.getorderList();
                                // this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
    exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
				Utils.createDownload(res,filename)
            })
        },
    auditformSubmit(){
     checkOrder(this.auditform).then(res=>{
      this.dialogAudit=false
       this.getorderList();
          this.$message({
            type: "success",
            message: '审核完成',
          })
     })
    },
     exportOrderCard(){//导卡
      
    },
    deliver(row){//发货
     this.dialogBox  =true 
     this.deliverForm.orderNum = row.orderNum
     this.deliverForm.expressNum = row.expressNum
     this.deliverForm.expressName = row.expressName
    },
    audit(row){//审核
    this.dialogAudit = true
   
    this.auditform.orderNum= row.orderNum
     this.auditform.type= ""
     this.auditform.remark= ""
    },
    timeChange(val){
      console.log(val)
      console.log(this.time )
      
      this.formInline.createTimeBegin=val[0]
       this.formInline.createTimeEnd=val[1]
    },
    getDetail(data){
      //跳转订单详情
       this.$router.push({path:'/purchaseCard/orderDetails',query:{orderNum:data.orderNum}})

    },
    categoryString(category) {
      let cuCatagorys;
      if (store.getters.categorys.length > 0) {
        store.getters.categorys.forEach((item) => {
          if (category == item.value) {
            cuCatagorys = item.name;
          }
        });
      }
      return cuCatagorys;
    },
    searchData() {
      this.page = 1;
      this.getorderList();
    },
    getorderList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.formInline, params);
      orderPageList(this.formInline).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onPageChange(page) {
      this.page = page;
      this.getorderList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getorderList();
    },
    
  },
};
</script>

<style>
.orderList .el-form-item__label {
		vertical-align: top;
	}
</style>
