<template>

<div class="agentBatchRechargeLog table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">代理商批量充值</span>
        <el-form inline class="hook right themed-form search-form" style="height: 40px;line-height: 40px;" :model="form">
            <el-form-item label="导入时间:">
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.startTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>至</el-form-item>
            <el-form-item>
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="batchRecharge">批量充值</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ICCID开始号段" prop="iccidMarkStart" width="180px">
                <template slot-scope="scope">
                    <span v-if="scope.row.iccidMarkStart">{{scope.row.iccidMarkStart}}</span>
                    <span v-else>-</span>
                </template>    
            </el-table-column>
            <el-table-column label="ICCID结束号段" prop="iccidMarkEnd" width="180px">
                <template slot-scope="scope">
                    <span v-if="scope.row.iccidMarkEnd">{{scope.row.iccidMarkEnd}}</span>
                    <span v-else>-</span>
                </template>  
            </el-table-column>
            <el-table-column label="导入时间" prop="createDate" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{createDate(scope.row.createDate)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="导入数量" prop="importCount"></el-table-column>
            <el-table-column label="成功数量" prop="successCount"></el-table-column>
            <el-table-column label="失败数量" prop="failCount"></el-table-column>
            <el-table-column label="套餐名称" prop="mealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐数量" prop="mealCount"></el-table-column>
            <el-table-column label="操作人" prop="agentName"></el-table-column>
            <el-table-column label="操作">
                <template  slot-scope="scope">
                    <el-button type="primary" class="themed-button" @click="viewFail(scope.row.id)">查看失败</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog title="批量导入" :visible.sync="dialogBox" v-if="dialogBox">
        <el-form label-position="right" label-width="120px">
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="套餐:"  required>
                <el-select v-model="packageIds" placeholder="请选择" filterable>
                    <el-option v-for="item in packageIdsList" :key='item.index' :label="item.label" :value="item.value"></el-option>
                </el-select>
                <!-- <search-select :optionsMetaAll="packageIdsList" v-model="packageIds"></search-select> -->
            </el-form-item>
             <el-form-item label="ICCID/短号:">
                <el-input v-model="iccids" type="textarea"  placeholder="请输入ICCID/短号,每行一组"></el-input>
            </el-form-item>
        </el-form>
         <div style="color:#f00">
            注意：批量充值卡片只能充值同样的套餐，非同一个运营商的卡请不要混和操作，单次操作请不要超过5W张，否则会引起系统卡顿
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">导入excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('batch_recharge_template.xls')">导出excel模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {agentLogList,getAgentPackageAll,batchImportCardPackage} from '@/api/cardManagement/batchRechargeLog.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'
import resize from '@/mixins/resize.js'

export default {
    name:'agentBatchRechargeLog',
    mixins:[resize],
    data(){
        return {
            form:{
                startTime:'',
                endTime:''
            },
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            packageIds:'',
            packageIdsList:[],
            iccids:"",
            
        }
    },
    mounted(){
        this._agentLogList()
    },
    methods:{
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        searchTable(){
            this._agentLogList()
        },
        batchRecharge(){
            let params = {category:1,countNum:1}
            getAgentPackageAll(params).then((res)=>{
                this.packageIdsList = res.map((item)=>{
                    return {
                        label:item.name+"("+item.accountName+")",
                        value:item.id
                    }
                })
            })
            this.dialogBox = true
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    if(this.packageIds === ''){
                        messageBox(this,'请选择套餐')
                        return
                    }
                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('countNum',1)
                    params.append('packageIds',this.packageIds)
                    batchImportCardPackage(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else if(!!this.iccids.trim()){
                    if(!Utils.checkICCID(this.iccids)){
              messageBox(this,'iccid只能包含数字和字母')
              return
            }
                   if(this.packageIds === ''){
                        messageBox(this,'请选择套餐')
                        return
                    }
                    let params = new FormData()
                    params.append('countNum',1)
                    params.append('packageIds',this.packageIds)
                    params.append('iccids',this.iccids)
                         batchImportCardPackage(params).then(res=>{
                                 if (res.system_result_key == '0'){
                              this.$alert('添加成功', '提示', {
                                confirmButtonText:'确定',
                            		}).then(()=>{
                                  this.dialogBox= false
                            	});
                                 }else{
                                     this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                                 }
                            })
                    }else{
                messageBox(this,'请选择文件或输入ICCID')
                return
            }
        },
        viewFail(batchRechargeLogId){
            this.$router.push({
                path: "/cardManagement/viewFail",
                query: {batchRechargeLogId},
            })
        },
        createDate(val){
            let date = new Date(val);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            let d = date.getDate();
            let hour = date.getHours(),min = date.getMinutes();
            return y + '-' +m + '-' + d+' '+hour+':'+min;
        },
        _agentLogList(){
            let params = Object.assign(this.form,{pageNo:this.pageNo,pageSize:this.pageSize})
            agentLogList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._agentLogList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._agentLogList()
        }
    },
    components:{
        SearchSelect
    }
}

</script>
