<template>
    <div class="renew-history fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">历史续费记录</span>
            <el-form v-model="form" inline class="hook right themed-form search-form" style="line-height: 40px;">
                <el-form-item>
                    <el-input v-model="form.iccid" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="form.id" placeholder="记录编号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker class="renew_datetimerange" placeholder="选择时间范围" v-model="effectiveDate" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>                

                
            </el-form>
        </div>

        <div class="content-box" v-loading='tableLoading'>
            <el-table ref="table" :data="tableData" stripe :max-height="maxTableHeight"
             :default-sort = "{prop: 'date', order: 'descending'}">
                <el-table-column label="操作时间" prop="operationTime" min-width="100px" show-overflow-tooltip></el-table-column>
                <el-table-column label="记录编号" prop="id" min-width="100px" show-overflow-tooltip></el-table-column>
                <el-table-column label="ICCID"  show-overflow-tooltip> 
                    <template slot-scope="scope">
						<span v-if="scope.row.mealType.indexOf('流量池')==(-1)">
                            <el-link type="primary" @click="checkICCID(scope.row.id)">查看ICCID</el-link>
                        </span>     
					</template>
                </el-table-column>
                 <!-- 1 代理商订单 2 微信订单。 -->
        <el-table-column
          show-overflow-tooltip
          prop="orderType"
          label="订单类型"
          min-width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.orderType == 1">代理商订单</span>
            <span v-else-if="scope.row.orderType == 2">微信订单</span>
          
          </template>
        
        </el-table-column>
                <el-table-column label="操作客户" prop="createBy" width="150px" show-overflow-tooltip></el-table-column>
                <el-table-column label="运营商" prop="accountName" show-overflow-tooltip></el-table-column>
                <el-table-column label="套餐名称" prop="mealName" show-overflow-tooltip></el-table-column>
                <el-table-column label="套餐类型" prop="mealType" show-overflow-tooltip></el-table-column>
                <el-table-column label="购买时长" prop="duration" show-overflow-tooltip></el-table-column>
                <el-table-column label="总价" prop="totalPrice" show-overflow-tooltip></el-table-column>
                <el-table-column label="描述" prop="description" show-overflow-tooltip></el-table-column>
                
            </el-table>
            <el-pagination :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="ICCID明细" :visible.sync="dialogVisible">
            <div>{{iccids}}</div>
        </el-dialog>
    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {renewalRecordList, recordICCID} from "@/api/renew.js"

export default {
    name:'renewHistory',
    mixins:[resize],
    data(){
        return {
            form:{
                iccid:'',
                id:'',
                startDate:'',
                endDate:'',
            },
            effectiveDate:'',
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            tableLoading:false,
            dialogVisible:false,
            iccids:''
        }
    },
    watch:{
        effectiveDate(value){
            this.form.startDate = value == null ? "" : value[0]
            this.form.endDate = value == null ? "" : value[1]
        }
    },
    mounted(){
        this._renewalRecordList()
    },
    methods:{
        checkICCID(renewRecordId){
            recordICCID({renewRecordId}).then(res=>{
                let iccids = res
                if(!iccids.length){
                    let msg = "暂时没有iccid"
                    this.$modal.alertSuccess(msg)
                }else{
                    this.dialogVisible = true
                    this.iccids = iccids.join(", ")
                }
                
            })
        },
        onPageChange(page){
			this.page = page
			this._renewalRecordList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._renewalRecordList()
        },
        onSearch(){
            this._renewalRecordList()
        },
        _renewalRecordList(){
            let params = {
                pageSize: this.pageSize,
                pageNo: this.page,
            }
            
            renewalRecordList(Object.assign(params, this.form)).then(res =>{
                this.tableData = res.rows
                this.total = res.total
            })
        }

    }
}
</script>
<style>
.iccid-width .el-input__inner{width:180px !important;}
.renew-history .el-range-editor--small.el-input__inner{height:26px;}
.renew-history .el-range-editor.el-input__inner{padding:0 15px;}
.renew_datetimerange {margin-top:4px;}
.el-time-panel{left:-30px;}
</style>