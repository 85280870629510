<template>

<div class="imeiWhitelist table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">IMEI白名单</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="IMEI:">
                <el-input v-model="form.imei" clearable></el-input>
            </el-form-item>
            <el-form-item label="随机码:">
                <el-input v-model="form.chipid" clearable></el-input>
            </el-form-item>
          
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchData">搜索查询</el-button>
            </el-form-item>
        
             <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="importAdd">导入</el-button>
            </el-form-item>
          
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="IMEI" prop="imei" show-overflow-tooltip></el-table-column>
            <!-- ：type=1 设备白名单，type=2 贴片卡白名单 -->
             <el-table-column label="类型" prop="type" show-overflow-tooltip>
                <template slot-scope="scope">
                   <span v-if="scope.row.type==1">设备白名单</span>
                    <span v-if="scope.row.type==2">贴片卡白名单</span>
                </template>

             </el-table-column>
            <el-table-column label="随机码" prop="chipid" show-overflow-tooltip></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" @click="deleteById(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogBox" v-if="dialogBox">
        <export-imei v-if="dialogContent == 'export-imei'"  @close="dialogBox=false" @close-dialog='refresh'></export-imei>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {imeiWhitelist,deleteByIdImei,} from '@/api/meCardBindManagement/imeiWhite.js'
    import {messageBox,confirmBox } from '@/utils/common.js'
    import resize from '@/mixins/resize.js'

    import exportImei from '@/views/meCardBindManagement/imeiWhiteList/exportImei.vue'
   

    export default {
        name:'imeiWhitelist',
        mixins:[resize],
        data(){
            return {
                form:{
                    imei:'',
                    chipid:''
                },
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
               dialogBox:false,
              
            }
        },
        mounted(){
            this._listImei()
        },
        methods:{
             refresh(){
                this._listImei()
                this.dialogBox = false
            },
             importAdd(){
                this.dialogTitle = '导入IMEI白名单'
                this.dialogContent = 'export-imei'
                this.dialogBox = true
            },
               deleteById(data){
                let msg = "IMEI：" + data.imei + "是否确认删除?"
                confirmBox(this,msg,'操作提示',()=>{
                    deleteByIdImei({imei:data.imei}).then((res)=>{
                        messageBox(this,'删除成功')
                        this._listImei()
                    })
                })
            },
            searchData(){
                this.currentPage = 1
                this._listImei()
            },
            handleSizeChange(val) {
                this.pageSize = val
                this._listImei()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this._listImei()
            },
          
            _listImei(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                imeiWhitelist(Object.assign(params,this.form)).then((res)=>{
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        },
        components:{
           exportImei
        }
    }
</script>

