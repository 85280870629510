<template>
  <div class="flowPool table-hook fadeInRight">
    <div class="title-box">
      <span class="m-title">流量池监控</span>
      <el-form
        ref="operatorMeal"
        inline
        class="hook right themed-form search-form"
        style="height: 40px; line-height: 40px"
      >
        <el-form-item label="运营商:">
          <el-select v-model="searchForm.accountId" filterable>
            <el-option
              v-for="item in accountLists"
              :key="item.index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="themed-button"
            @click="searchTable"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="addMeal"
            icon="el-icon-plus"
            class="themed-button"
            >添加</el-button
          >
        </el-form-item>
      
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        :data="tableData"
        stripe
        border
        :max-height="maxTableHeight"
      >
        <el-table-column
          label="运营商"
          prop="accountName"
          width="150px"
        ></el-table-column>
        <el-table-column
          label="流量池ID"
          prop="pool"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="总量(MB)"
          prop="totalFlow"
          show-overflow-tooltip
        ></el-table-column>
          <el-table-column
          label="已用(MB)"
          prop="usedFlow"
          show-overflow-tooltip
        >
        <template slot-scope="scope">
          <span>{{scope.row.usedFlow.toFixed(2)}}</span>
        </template>
        </el-table-column>
        <el-table-column
          label="已用百分比(%)"
          prop="usedPercent"
          width="150px"
          show-overflow-tooltip
        ></el-table-column>
         <el-table-column
          label="剩余(MB)"
          prop="leftFlow"
          show-overflow-tooltip
        >
        <template slot-scope="scope">
          <span>{{scope.row.leftFlow.toFixed(2)}}</span>
        </template>
        </el-table-column>
         <el-table-column
          label="更新时间"
          prop="updateTime"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              class="themed-button"
              type="danger"
              @click="update(scope.row.id,scope.row.warnPercent,scope.row.phoneNumber,scope.row.mailAddress)"
              >预警</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        background
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新增"
      :visible.sync="dialogVisible"
      width="500px"
      destroy-on-close
    >
      <el-form
        label-width="120px"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="运营商" prop="accountId">
          <el-select v-model="ruleForm.accountId" filterable>
            <el-option
              v-for="item in accountLists"
              :key="item.index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
       
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="info"
          @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="onAddAccount('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
     <el-dialog
      title="预警"
      :visible.sync="dialogShow"
      width="300px"
      destroy-on-close
    >
    <div class="setDiv">
   <el-button
          class="themed-button setrow"
          type="primary"
          @click="setPercent">设置预警百分比</el-button>
          <br>
           <el-button
          class="themed-button setrow"
          type="primary"
          @click="setPhone">设置通知手机</el-button>
          <br>
           <el-button
          class="themed-button setrow"
          type="primary"
          @click="setmail">设置通知邮箱</el-button>
    </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="primary"
          @click="onAddAll"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog  
      title="设置预警百分比"
      :visible.sync="dialogShowOne"
      width="500px"
      append-to-body
      v-if= "dialogShowOne">
   <set-item  @cancel = "dialogShowOne =false"     @close="getPercent" :curPercent ="ruleFormOne.warnPercent" ></set-item>
      </el-dialog>
      <el-dialog  
      title="设置通知手机"
      :visible.sync="dialogShowtwo"
      width="500px"
      append-to-body
      v-if= "dialogShowtwo" >
   <set-phone   @cancel = "dialogShowtwo= false"  @close="getPhone" :curphone ="ruleFormOne.phoneNumber" ></set-phone>
      </el-dialog>
       <el-dialog  
      title="设置通知邮箱"
      :visible.sync="dialogShowthree"
      width="500px"
      append-to-body
      v-if= "dialogShowthree">
   <set-mail    @cancel = "dialogShowthree=false"    @close="getMail" :curmail ="ruleFormOne.mailAddress" ></set-mail>
      </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  getAccountList,flowPoolList,addflowPool,updateflowPool
} from "@/api/operator.js";
import { messageBox, confirmBox } from "@/utils/common.js";

// import { getAccountList } from "@/api/cardManagement/cardBill.js";
import resize from "@/mixins/resize.js";
import dictGroup from "@/mixins/dictGroup.js";
import SearchSelect from "@/components/SearchSelect.vue";
import setItem from "./setItem.vue"
import setPhone from "./setPhone.vue"
import setMail from "./setMail.vue"
import loading from '@/utils/Loading.js'
export default {
  name: "flowPool",
  mixins: [resize, dictGroup],
  components: { SearchSelect,setItem ,setPhone,setMail},
  data() {
    return {
      searchForm: {
        accountId: "",
      },
      tableData: [],
      pageSize: 15,
      currentPage: 1,
      total: 0,
      accountLists: [{ label: "请选择", value: "" }],
      dialogVisible: false,
      ruleForm: {
        accountId: "",
      },
      rules: {
        accountId: [
          { required: true, message: "请选择运营商", trigger: "change" },
        ],
      },
      dialogShow:false,
       ruleFormOne:{
        id:"",
        warnPercent:"",
        phoneNumber:"",
        mailAddress:"",
       },
      //  list:{
      //  warnPercentData:[ ],
      //  phoneNumberData:[],
      //  mailAddressData:[],
      //  },
      //  tableTitle:"",
       dialogShowOne:false,
       dialogShowtwo:false,
       dialogShowthree:false,
    };
  },
  mounted() {
    this._getAccountList();
    this.getflowPoolList();
  },
  methods: {
    getPercent(data){
       this.ruleFormOne.warnPercent = data
        this.dialogShowOne = false
    },
    getPhone(data){
      console.log(data,typeof(data) )
       this.ruleFormOne.phoneNumber = data
        this.dialogShowtwo = false 
    },
    getMail(data){
       this.ruleFormOne.mailAddress = data
        this.dialogShowthree = false 
    },
    onAddAll(){
    //设置
    updateflowPool(this.ruleFormOne).then(res=>{
       if (res.system_result_key == "0") {
              messageBox(this, "设置成功");
              this.getflowPoolList();
              this.dialogShow = false;
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
    })
     
    },
    setPercent(){
     this.dialogShowOne = true
    },
    setPhone(){
    this.dialogShowtwo = true
    },
    setmail(){
      this.dialogShowthree = true 
    },
    update(id,warnPercent,phoneNumber,mailAddress){
    this.dialogShow = true
    this.ruleFormOne.id = id
    this.ruleFormOne.mailAddress = mailAddress
    this.ruleFormOne.phoneNumber = phoneNumber
    this.ruleFormOne.warnPercent = warnPercent
    console.log(this.ruleFormOne)
    },
   
    onAddAccount(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        //   alert("submit!");
         loading.show()
          this.dialogVisible = false;
          addflowPool(this.ruleForm).then((res) => {
            if (res.system_result_key == "0") {
              messageBox(this, "添加成功");
              this.getflowPoolList();
              this.dialogVisible = false;
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addMeal() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
      this.ruleForm.accountId = "";
    },
 
    _getAccountList() {
        let params = {
           platformCode:"mobileOnelink" 
        }
      getAccountList(params).then((res) => {
        this.accountLists = [{ label: "请选择", value: "" }];
        if (res.length > 0) {
          res.forEach((item) => {
            this.accountLists.push({ label: item.accountName, value: item.id });
          });
        }
      });
    },
    getflowPoolList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
      };
      Object.assign(this.searchForm, params);
      flowPoolList(this.searchForm)
        .then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
          this.currentPage = res.pageNo;
          this.pageSize = res.pageSize;
        })
        .catch(() => {
          this.tableData = [];
        });
    },
    searchTable() {
      this.currentPage = 1;
      this.getflowPoolList();
    },

    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getflowPoolList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getflowPoolList();
    },
  },
};
</script>
<style>
.setDiv{ text-align:center;}
.setDiv  .setrow{margin-top:10px;}
</style>

