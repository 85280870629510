<template>
<div  class="fadeInRight">
		<div class="title-box clearfix">
			 <span class="m-title">流量使用管理</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item>
					<el-button type="primary" @click="addAgentRecord" icon="el-icon-caret-left" class="themed-button">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="agentName" label="代理商名称">
				</el-table-column>
				<el-table-column prop="totalUseFlow" label="已使用流量">
				</el-table-column>
				<el-table-column prop="leftAmount" label="剩余金额">
				</el-table-column>
				
				<el-table-column prop="pricePerMb" label="流量单价">
				</el-table-column>
				<el-table-column  label="ApiClose">
					<template slot-scope="scope2">
						<span >{{scope2.row.ifApiClose}}</span>
					</template>
				</el-table-column>
				<el-table-column  label="更新时间">
					<template slot-scope="scope">
						<span >{{formatDatebox(scope.row.updateTime,"yyyy-MM-dd hh:mm:ss")}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="updateByName" label="更新者">
				</el-table-column>
				<el-table-column  label="操作" width="200px">
					<template slot-scope="scope1">
						<span v-if="!scope1.row.ifApiClose" ><el-button class="themed-button" type="warning" @click='clickdisable(scope1.row.id)'>关闭Api</el-button></span>
						<span v-else><el-button class="themed-button" type="warning" @click='clickable(scope1.row.id)'>打开Api</el-button></span>
						<span class="left_agent"><el-button class="themed-button" type="info" @click='updateAgentRecord(scope1.row.id)'>编辑</el-button></span>
						<span class="left_agent"><el-button class="themed-button" type="danger" @click='deleteAgentRecord(scope1.row.id)'>删除</el-button></span>
							<!-- <el-button class="themed-button" type="warning" @click='clickdisable(scope1.row.id)'>关闭Api</el-button>
							<el-button class="themed-button" type="warning" @click='clickable(scope1.row.id)'>打开Api</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
		
		<el-dialog title="提示" :visible.sync="dialogVisible"  width="30%" >
		<span >确定要关闭Api么？</span>
		<span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false" class="themed-button">取 消</el-button>
		    <el-button type="primary" @click="changApi()" class="themed-button">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="dialogVisible1"  width="30%" >
		<span >确定要打开Api么？</span>
		<span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible1 = false" class="themed-button">取 消</el-button>
		    <el-button type="primary" @click="changApi()" class="themed-button">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog title="消息提示" :visible.sync="dialogVisible2"  width="30%" >
		<span >确定要删除吗？</span>
		<span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible2 = false" class="themed-button">取 消</el-button>
		    <el-button type="primary" @click="deleteApi()" class="themed-button">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog title="修改代理商" :visible.sync="dialogFormVisible"  :before-close="handleClose">
		  <el-form :model="form" ref="form" :rules="rules">
		    <el-form-item label="流量单价（元/MB）：" :label-width="formLabelWidth" prop="pricePerMb" min="0.0" step="10" max="9999"  type="number">
		      
					<el-input v-model="form.pricePerMb" autocomplete="off"></el-input>
		    </el-form-item>
				<el-form-item label="提醒：" :label-width="formLabelWidth"  prop="remindPercent">
					<el-input v-model="form.remindPercent" autocomplete="off"  placeholder="填写剩余金额(元)" min="0.0" step="0.1" max="1" type="number" ></el-input>
				</el-form-item>
		    <el-form-item label="关闭API：" :label-width="formLabelWidth" >
		    	<el-input v-model="form.closeApiPercent" autocomplete="off" placeholder="填写剩余金额(元)" min="0.0" step="0.1" max="1" type="number"></el-input>
		    </el-form-item>
				<el-form-item label="停机：" :label-width="formLabelWidth" >
					<el-input v-model="form.stopCardPercent" autocomplete="off" placeholder="填写剩余金额(元)" min="0.0" step="0.1" max="1" type="number"></el-input>
				</el-form-item>
				<el-form-item label="企业微信推送地址：" :label-width="formLabelWidth">
					<el-input v-model="form.entWechatUrl" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="钉钉推送地址：" :label-width="formLabelWidth"  prop="dingTalkUrl" >
					
					<el-input v-model="form.dingTalkUrl" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="提醒内容：" :label-width="formLabelWidth" required  prop="remindMsg">
					 
					<el-input v-model="form.remindMsg" autocomplete="off"></el-input>
				</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm('form')" class="themed-button">确 定</el-button>
				<el-button type="primary" @click="resetForm('form')" class="themed-button">重置</el-button>
		    <el-button @click="cancelDialog('form')" type="info" class="themed-button">取 消</el-button>
		    
		  </div>
		</el-dialog>
		<el-dialog title="新增代理商" :visible.sync="dialogFormVisible1" :before-close="handleClose1">
		  <el-form >
		    <el-form-item label="代理商:"  :rules="{required:true}">
		       <el-select v-model="form1.agentId" placeholder="请选择" @change="select" filterable>
		         <el-option label="请选择" value="0"></el-option>
						 <el-option  v-for="item in agentItem"  :key="item.id" :label="item.name"  :value="item.id" :data_money="item.balanceMoney"></el-option>
		       </el-select>
		     </el-form-item>
				 <el-form-item label="注意: 此功能目前仅支持湖南联通和浙江联通"  class = "agent_color" >
				 </el-form-item>
			
		   
		  </el-form>
		  <div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitAddForm" class="themed-button">确 定</el-button>
				<el-button type="info" @click="resetAddForm" class="themed-button">重置</el-button>
		    <el-button type="info" @click="cancelAddForm" class="themed-button">取 消</el-button>
		    
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import onresize from "@/mixins/resize.js"
	import {
	trafficUsageMaList,
	closeApi,
	editAgent,
	saveAgent,
	deleteAgent,
	getAgentList,
	saveAddAgent,
	} from "@/api/agentManagement.js"
	
	export default {
		name:'trafficUsageMa',
		mixins: [onresize],
		data(){
			return {
				tabledata:[],
				page:1,
				pageSize:15,
				total:0,
				dialogVisible:false,
				dialogVisible1:false,
				dialogVisible2:false,
				dialogVisible3:false,
				dialogFormVisible1:false,
				currentID :"",
				dialogFormVisible:false,
				formLabelWidth:"160px",
				form:{
					id:"",
					pricePerMb:"",
					pricePerMb:"",
					remindPercent:"",
					closeApiPercent:"",
					stopCardPercent:"",
					entWechatUrl:"",
					dingTalkUrl:"",
					remindMsg:"",
				},
				agentItem:[],
			  form1:{
					agentId:"0",
					agentName:"",
				},
				currentItem:{},
				rules:{
					pricePerMb:[{required: true, message: '请输入单价！', trigger: 'blur'}],
					remindPercent:[{required: true, message: '请填写剩余金额(元)', trigger: 'blur'}],
					dingTalkUrl:[{required: true, message: '请填写钉钉推送地址', trigger: 'blur'}],
					remindMsg:[{required: true, message: '请填写提醒内容', trigger: 'blur'}],
				},
			}
		},
		created(){
			this.getTrafficUsageMaList()
		},
		methods:{
			select(value){
				this.currentItem = this.agentItem.find(item=>{
					return item.id ===value
				})
				this.form1.agentName = this.currentItem.name
			},
			onPageChange(page){
				this.page = page
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page
				}
				this.getTrafficUsageMaList(params)
			},
			handleSizeChange(size){
				this.pageSize = size
				let params = {
					pageSize:this.pageSize,
					pageNo:this.page
				}
				this.getTrafficUsageMaList(params)
			},
			/* 关闭api */
			clickdisable(id){
				this.dialogVisible = true
				this.currentID = id
			},
			/* 开启api */
			clickable(id){
				this.dialogVisible1 = true
				this.currentID = id
			},
			changApi(){
				let params = {
					id:this.currentID,
					ifApiClose:true
				}
				if(this.dialogVisible == false){
					params.ifApiClose = false
				}
				closeApi(params).then(res=>{
					this.getTrafficUsageMaList()
				})
				this.dialogVisible = false
				this.dialogVisible1 = false
			},
			updateAgentRecord(id){
				this.dialogFormVisible = true
				this.currentID = id
				let params = {
					id:id,
					pricePerMb:"",
					remindPercent:"",
					closeApiPercent:"",
					stopCardPercent:"",
					entWechatUrl:"",
					dingTalkUrl:"",
					remindMsg:""
				}
				editAgent(params).then(res=>{
					this.form.pricePerMb = res.entity.pricePerMb
					this.form.remindPercent = res.entity.remindPercent
					this.form.stopCardPercent = res.entity.stopCardPercent
					this.form.entWechatUrl = res.entity.entWechatUrl
					this.form.dingTalkUrl = res.entity.dingTalkUrl
					this.form.remindMsg = res.entity.remindMsg
					this.form.closeApiPercent = res.entity.closeApiPercent
					this.form.id = res.entity.id
				})
			},
			
			getTrafficUsageMaList(params){
				trafficUsageMaList(params).then(res=>{
					//console.log(res)
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			formatDatebox(value,format) {
			    if (value == null || value == '') {
			        return '';
			    }
			    var dt;
			    if (value instanceof Date) {
			        dt = value;
			    } else {
			        dt = new Date(value);
			    }
			    //console.log(dt);
			    var o = {
			        "M+": dt.getMonth() + 1, // month
			        "d+": dt.getDate(), // day
			        "h+": dt.getHours(), // hour
			        "m+": dt.getMinutes(), // minute
			        "s+": dt.getSeconds(), // second
			        "q+": Math.floor((dt.getMonth() + 3) / 3), // quarter
			        "S": dt.getMilliseconds()
			        // millisecond
			    }
			    if (/(y+)/.test(format))
			        format = format.replace(RegExp.$1, (dt.getFullYear() + "")
			            .substr(4 - RegExp.$1.length));
			    for (var k in o)
			        if (new RegExp("(" + k + ")").test(format))
			            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
			    return format; 
			},
			resetForm(formName){
				let params = {
					id:this.currentID,
					pricePerMb:"",
					remindPercent:"",
					closeApiPercent:"",
					stopCardPercent:"",
					entWechatUrl:"",
					dingTalkUrl:"",
					remindMsg:""
				}
				editAgent(params).then(res=>{
					this.form.pricePerMb = res.entity.pricePerMb
					this.form.remindPercent = res.entity.remindPercent
					this.form.stopCardPercent = res.entity.stopCardPercent
					this.form.entWechatUrl = res.entity.entWechatUrl
					this.form.dingTalkUrl = res.entity.dingTalkUrl
					this.form.remindMsg = res.entity.remindMsg
					this.form.closeApiPercent = res.entity.closeApiPercent
					this.form.id = res.entity.id
				})
				this.$refs[formName].clearValidate()
			},
			submitForm(formName){
				  this.$refs[formName].validate((valid) => {
				          if (valid) {
										this.dialogFormVisible = false
										saveAgent(this.form).then(res=>{
											if (res.slide_msg_key == 1){
												this.$message({
																	showClose: true,
												          message: res.slide_msg_message_key,
												          type: 'warning',
																	center: true
												        })
											 }
											this.getTrafficUsageMaList()
											
										})
				          } else {
				            return false;
				          }
				        });
			
			},
			handleClose(done){
				this.$refs['form'].clearValidate()
				done()
			},
			cancelDialog(formName){
				this.$refs[formName].clearValidate()
				this.dialogFormVisible = false
			},
			deleteAgentRecord(id){
				this.currentID = id
				this.dialogVisible2 = true
			},
			deleteApi(){
				this.dialogVisible2 = false
				let params = {
					id:this.currentID,
				}
				deleteAgent(params).then(()=>{
				 this.$message({
					         showClose: true,
				           message: '删除成功',
				           type: 'success',
									 center: true
				         })
					this.getTrafficUsageMaList()
				})
			},
			addAgentRecord(){
				this.dialogFormVisible1 = true
				let params = {
					agentName:0,
					agentId:"",
				}
				getAgentList(params).then(res=>{
					/* if(res.system_result_key == "0" && res.app_result_key == "0"){
							this.agentItem = res
					} */
				this.agentItem = res
				})
			},
			submitAddForm(){
				if(this.form1.agentId=="0" ||this.form1.agentId == ""||this.form1.agentId==null ){
					this.$alert("请选择代理商","系统提示",{
						 confirmButtonText: '确定',
					})
					return
				}
				
				saveAddAgent(this.form1).then((responseResult)=>{
					if(responseResult.slide_msg_key==1){
					    this.$message({
								showClose: true,
								message: responseResult.slide_msg_message_key,
								type: 'warning',
								center: true
							});
					}
					this.getTrafficUsageMaList()
					this.form1.agentId = "0"
					this.dialogFormVisible1= false
				})
			},
			resetAddForm(){
				this.form1.agentId = "0"
			},
			cancelAddForm(){
				this.dialogFormVisible1= false
				this.form1.agentId = "0"
			},
			handleClose1(done){
				this.form1.agentId = "0"
				done()
			}
		
			
		}
	}
</script>

<style>
	.left_agent{
		margin-left: 8px;
	}
	.agent_color{color: #f00;}
	.agent_color .el-form-item__label{color:#f00}
</style>
