<template>

<div class="exportImei">
    <el-form :model="form" label-position="right" label-width="200px" :rules="rules" style="max-height:500px;overflow:auto" ref="form">
      
        <el-form-item label="操作:" required prop="operation">
            <el-select filterable v-model="form.operation" >
                <el-option label="导入" value="1"></el-option>
                <el-option label="删除" value="2"></el-option>
            </el-select>
        </el-form-item>
      
       
        <el-form-item label="上传IMEI文件（excel）:" >
            <input class="file-input" type="file" ref='file'/>
            <div class="mt5">
                <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
            </div>
        </el-form-item>
           <el-form-item label="类型:" required prop="type">
            <el-select filterable v-model="form.type" >
                <el-option label="设备白名单" value="1"></el-option>
                <el-option label="贴片白名单" value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="IMEI:">
             <el-input v-model="form.imeis" type="textarea"  placeholder="请输入imei,每行一组"></el-input>
        </el-form-item>
      
      
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="uploadExcel('form')">导入excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="importExcel">导出excel模板</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {batchImportSync} from '@/api/meCardBindManagement/imeiWhite.js'

import { messageBox, confirmBox } from "@/utils/common.js"

import Utils from '@/utils/utils.js'
import {exportTemplate} from '@/api/statistics.js'

export default {
    data(){
        return {
            form:{
                imeis:'',
                type:"",
                operation:"",
            },
              rules: {
                operation: [
                    { required: true, message: '请选择操作类型', trigger: 'change' }
                ],
                type: [
                    { required: true, message: '请选择白名单类型', trigger: 'change' }
                ],            
            },
        }
    },
    mounted(){
       
    },
    methods:{
        uploadExcel(formName){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(formName)
            })
        },
        comfirmImport(formName){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let params = new FormData()
                            params.append('file',file)
                            params.append('type',this.form.type)
                            params.append('operation',this.form.operation)
                            batchImportSync(params).then(res=>{
                                if (res.system_result_key == '0') {
                                    this.$alert('操作成功，请到导入记录中下载', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.$emit('close-dialog')
                                        this.$router.push('/tob/i_import_list')
                                    });
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                }
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
            }else if(!!this.form.imeis){
                        this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let params = new FormData()
                            params.append('imeis',this.form.imeis)
                            params.append('type',this.form.type)
                            params.append('operation',this.form.operation)
                            batchImportSync(params).then(res=>{
                                if (res.system_result_key == '0') {
                                    this.$alert('操作成功', '提示', {
                                        confirmButtonText:'确定',
                                    }).then(()=>{
                                        this.$emit('close-dialog')
                                        // this.$router.push('/tob/i_import_list')
                                    });
                                }else{
                                    this.$message({
                                        showClose: true,
                                        message: res.system_result_message_key,
                                        type: 'error'
                                    });
                                }
                            })
                        } 
                    });
            }else{
                messageBox(this,'请选择文件或输入IMEI')
                return
            }
        },
        importExcel(){
            let name = 'importImei.xls'
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        cancle(){
            this.$emit('close')
        },
        
    }
}
</script>

