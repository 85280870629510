<template>

<div class="use-card table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">资源使用率统计</span>
        <el-form inline v-model="form" class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="月份:">
                <el-date-picker v-model="form.month" type="month"  value-format="yyyy-MM" placeholder="选择月" style="width:100%"></el-date-picker>
            </el-form-item>
            <el-form-item label="运营商:">
                <el-select v-model="form.accountId" filterable>
                    <el-option v-for="(item,index) in accountList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="月份" prop="month"></el-table-column>
            <el-table-column label="运营商" prop="accountName"></el-table-column>
            <el-table-column label="使用流量" prop="useFlow"></el-table-column>
            <el-table-column label="使用语言" prop="useVoice"></el-table-column>
            <el-table-column label="订购语音" prop="orderVoice" show-overflow-tooltip></el-table-column>
            <el-table-column label="订购流量" prop="orderFlow" show-overflow-tooltip></el-table-column>
            <el-table-column label="浪费率" show-overflow-tooltip>
                <template  slot-scope="scope">
                    <span>{{(parseFloat(scope.row.orderFlow)-parseFloat(scope.row.useFlow))/parseFloat(scope.row.orderFlow)*100}}%</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {getAccountList,accountFlow} from '@/api/staticsManagement/cardUseCycel.js'
import {messageBox,confirmBox} from '@/utils/common.js'

import resize from '@/mixins/resize.js'

export default {
    name:'resourceUsageStatistics',
    mixins:[resize],
    data(){
        return {
            form:{
                month:'',
                accountId:''
            },
            accountList:[],
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,

        }
    },
    mounted(){
        this._accountFlow()
    },
    methods:{
        searchTable(){
            this._accountFlow()
        },
        importRecord(){
            this.dialogBox = true
        },
        handleSizeChange(val){
            this.pageSize = val
            this._accountFlow()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._accountFlow()
        },
        _getAccountList(){
            getAccountList().then((res)=>{
                this.accountList = res
            })
        },
        _accountFlow(){
            let params = {}
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            params.type = this.type
            accountFlow(Object.assign(params,this.form)).then((res) => {
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    }
}
</script>
