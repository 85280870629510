<template>
  <div class="cardPoolManagement fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">卡池管理</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="流量池名称:">
          <el-input v-model="formInline.name" placeholder="流量池名称" clearable></el-input>
        </el-form-item>
         <el-form-item label="运营商:"  v-if="currentRole==0">
           <el-select v-model="formInline.accountId" filterable placeholder="请选择运营商" clearable >
                    <el-option v-for="(item,index) in accountLists" :key="index" :label="item.label"  :value="item.value"></el-option>
            </el-select>
        </el-form-item>
         <el-form-item label="代理商:"  v-if="currentRole==0">
          <el-select v-model="formInline.agentId" filterable placeholder="请选择代理商" clearable >
                    <el-option v-for="(item,index) in agentLists" :key="index" :label="item.label"  :value="item.value"></el-option>
                </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="addPool"
            icon="el-icon-plus"
            class="themed-button"
            v-if="currentRole==0"
            >新增卡池</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <!-- <div>{{ruleLists}}</div> -->
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="流量池名称"  min-width="100"> </el-table-column>
        <el-table-column prop="type" label="统计模式" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.statMode == '1'">组池</span>
            <span v-else-if="scope.row.statMode == '2'">单卡</span>
          </template>
        </el-table-column>
        <el-table-column prop="discount" label="扣费模式"  min-width="100"> 
            <template slot-scope="scope">
            <span v-if="scope.row.deductMode == '1'">预付</span>
            <span v-else-if="scope.row.deductMode == '2'">后付</span>
          </template>
        </el-table-column>
        <el-table-column prop="agentName" label="代理商" min-width="100"> </el-table-column>
         <el-table-column prop="accountName" label="运营商" min-width="100"> </el-table-column>
         <el-table-column prop="joinRuleName" label="入池规则"  min-width="100"> </el-table-column>
         <el-table-column prop="overFlowRuleName" label="扣费规则"  min-width="100"> </el-table-column>
          <el-table-column prop="keepNumFee" label="停机保号费(元)"  min-width="130"> </el-table-column>
            <el-table-column prop="totalFlow" label="总流量(M)" min-width="100"> </el-table-column>
            <el-table-column prop="usedFlow" label="已用流量(M)" min-width="110"> </el-table-column>
             <el-table-column prop="stoped" label="当前状态" min-width="100"> 
            <template slot-scope="scope">
            <span v-if="scope.row.stoped ">停用</span>
            <span v-else>激活</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100"> </el-table-column>
        
        <el-table-column label="操作" width="320px" v-if="currentRole==0" min-width="120">
          <template v-slot:default="scope">
              <el-button
              type="info"
              class="themed-button"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="info"
              class="themed-button"
              @click="changeStatus(scope.row)"
              v-if="scope.row.stoped"
              >激活</el-button
            >
            <el-button
              type="info"
              class="themed-button"
              @click="changeStatus(scope.row)"
              v-else
              >停用</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
      <el-dialog
      title="添加卡池"
      :visible.sync="dialogAddVisible"
      width="50%"
      v-if="dialogAddVisible"
    >
      <add-card-pool
        @close="getRefreshData"
        :dialogAccountLists ="accountLists"
        :dialogAgentLists ="agentLists"
        :dialogRuleLists ="ruleLists"
      ></add-card-pool>
    </el-dialog>
     <el-dialog
      title="编辑卡池"
      :visible.sync="dialogEditVisible"
      width="50%"
      v-if="dialogEditVisible"
    >
      <edit-card-pool
        @close = "refreshData"
        :dialogEditRuleLists = "ruleLists"
        :edit = "editData"
      ></edit-card-pool>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onresize from "@/mixins/resize.js";
 import {getAccountList ,getAgentForImportCard,} from '@/api/cardManagement/cardImport.js'
import { getCardPoolList ,changeStatus,getRuleList} from "@/api/trafficPoolManagement.js";
import addCardPool  from "./addCardPool.vue";
import editCardPool  from "./editCardPool.vue";
export default {
  name: "rulerManagement",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        name: "",
    accountId:"",
    agentId:"",
      },
      tabledata: [],
      formLabelWidth: "200px",
       accountLists:[], //运营商
       agentLists:[],  //代理商
       ruleLists:[],//规则列表
       dialogAddVisible:false,
       dialogEditVisible:false,
       editData:"",
    };
  },
  created() {
    this._getAccountList()
    this._getAgentForImportCard()
    // this.getRuleList();
    this.getCardPoolList();
    console.log(this.ruleLists)
  },
  components: {
    addCardPool,
    editCardPool
  },
  computed: {
    ...mapGetters(["currentRole"]),
  },
  methods: {
    edit(row){
       this.getRuleList();
     this.editData = row
     this.dialogEditVisible = true 
    },
    addPool(){
       this.getRuleList();
    this.dialogAddVisible = true 
    },
    getRefreshData(){
    this.dialogAddVisible = false 
    this.getCardPoolList()
    },
    refreshData(){
      this.dialogEditVisible = false 
    this.getCardPoolList()
    },
    changeStatus(row){
        let params = {
                id:row.id,
                stoped:!row.stoped
            }
        let content =""
        if(row.stoped){
         content="是否确定激活？"
        }else{
           content="是否确定停用？"  
        }
       this.$confirm(content, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            
         changeStatus(params).then(res=>{
           if (res.system_result_key == "0") {
              this.$message({
                showClose: true,
                message: "设置成功",
                type: "success",
              });
              this.getCardPoolList();
            } else {
              this.$message({
                showClose: true,
                message: res.system_result_message_key,
                type: "error",
              });
            }
         })
        }).catch(() => {
                   
        });
    },
    getRuleList() {
      let params = {
        pageSize: 100000,
        pageNo: 1,
      };
      getRuleList(params).then((res) => {
        /* 表格数据 */
       let arr = res.rows
       this.ruleLists =   arr.map((value,index,arr)=>{
                        return {value:value.id,label:value.name,type:value.type}
                    })
        // this.ruleLists.unshift({value:'',label:'请选择扣费规则'})
        console.log(this.ruleLists)
      }).catch(()=>{
					this.ruleLists = []
				})
              
    },
     _getAgentForImportCard(){
                getAgentForImportCard().then((res) => {
                    this.agentLists = res.map((value,index,arr)=>{
                        return {value:value.id,label:value.agentThreeName}
                    })
                    this.agentLists.unshift({value:'',label:'请选择代理商'})
                }).catch(()=>{
					this.agentLists = []
				})
            },
     _getAccountList(){
                getAccountList().then((res) => {
                    this.accountLists = res.map((value,index,arr)=>{
                        return {'value':value.id,'label':value.accountName}
                    })
                }).catch(()=>{
					this.accountLists = []
				})
            },
  
    getCardPoolList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.formInline);
      getCardPoolList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getCardPoolList();
    },
    onPageChange(page) {
      this.page = page;
      this.getCardPoolList();
    },
    searchData() {
      this.page = 1;
      this.getCardPoolList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
.cardPoolManagement .el-form-item__label {
  vertical-align: top;
}

</style>
