<template>
	<div class="main-statisitcs">
		<div>
			<div class="title-box clearfix">
				<span class="m-title">流量月报(GB/月)</span>
				<el-form inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
					<el-form-item>
						<el-select placeholder="选择年" v-model="searchForm.flowYear" @change="flowYearChanged">
							<!-- <el-option value="2020" label="2020"></el-option>
							<el-option value="2019" label="2019"></el-option> -->
							<el-option v-for="(item,index) in yearList"  :label="item" :value = "item" :key= "index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="showFlowMonth">
						<el-select placeholder="选择月" v-model="searchForm.flowMonth">
							<el-option label="请选择" value="0"></el-option>
							<el-option label="1月" value="1"></el-option>
							<el-option label="2月" value="2"></el-option>
							<el-option label="3月" value="3"></el-option>
							<el-option label="4月" value="4"></el-option>
							<el-option label="5月" value="5"></el-option>
							<el-option label="6月" value="6"></el-option>
							<el-option label="7月" value="7"></el-option>
							<el-option label="8月" value="8"></el-option>
							<el-option label="9月" value="9"></el-option>
							<el-option label="10月" value="10"></el-option>
							<el-option label="11月" value="11"></el-option>
							<el-option label="12月" value="12"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="showFlowMonth">
						<el-button icon="el-icon-download" class="themed-button" type="primary" @click="exportFlow">导出Excel</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="content-box" style="padding-left: 0;padding-right: 0; position: relative;">
				<div id="chartFlow" style="height: 400px;"></div>  
				<div class="no-data full-parent" v-if="!hasChartFlowData" style="height: 400px;"><span>请按条件筛选您需要的报表</span></div>
			</div>
		</div>
		<div class="mt20">
			<div class="title-box clearfix">
				<span class="m-title">短信月报</span>
				<el-form inline class="right themed-form search-form" style="height: 40px;line-height: 40px;">
					<el-form-item>
						<el-select placeholder="选择年" v-model="searchForm.smsYear" @change="smsYearChanged">
							<!-- <el-option value="2020" label="2020"></el-option>
							<el-option value="2019" label="2019"></el-option> -->
							<el-option v-for="(item,index) in yearList"  :label="item" :value = "item" :key= "index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="showSMSMonth">
						<el-select placeholder="选择月" v-model="searchForm.smsMonth">
							<el-option label="请选择" value="0"></el-option>
							<el-option label="1月" value="1"></el-option>
							<el-option label="2月" value="2"></el-option>
							<el-option label="3月" value="3"></el-option>
							<el-option label="4月" value="4"></el-option>
							<el-option label="5月" value="5"></el-option>
							<el-option label="6月" value="6"></el-option>
							<el-option label="7月" value="7"></el-option>
							<el-option label="8月" value="8"></el-option>
							<el-option label="9月" value="9"></el-option>
							<el-option label="10月" value="10"></el-option>
							<el-option label="11月" value="11"></el-option>
							<el-option label="12月" value="12"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="showSMSMonth">
						<el-button icon="el-icon-download" class="themed-button" type="primary"  @click="exportSms">导出Excel</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="content-box" style="padding-left: 0;padding-right: 0; position: relative;">
				<div id="chartSMS" style="height: 400px;"></div>  
				<div class="no-data full-parent" v-if="!hasChartSMSData"  style="height: 400px;"><span>请按条件筛选您需要的报表</span></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {ListMonthFlow,ListMonthSms,exportMonthFlow,exportMonthSms} from '@/api/statistics.js'
	import echarts from 'echarts'
	import Utils from '@/utils/utils.js'
	export default{
	
		data(){
			return{
				searchForm:{
					flowMonth:'0',
					smsMonth:'0'
				},
				hasChartFlowData:false,
				hasChartSMSData:false,
				flowChart:'',
				smsChart:'',
				showFlowMonth:false,
				showSMSMonth:false,
				yearList:[]
			}
		},
		created() {
			window.onresize = () => {
				return (() => {
					
					if (!!this.flowChart) {
						this.flowChart.resize()
					}
					if (!!this.smsChart) {
						this.smsChart.resize()
					}
					
				})()
			}
			for(let i = new Date().getFullYear();i>=2019;i--){
               this.yearList.push(i)
			}

		},
		methods:{
			exportFlow(){
				exportMonthFlow({month:this.searchForm.flowMonth,year:this.searchForm.flowYear}).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
					
				})
			},
			exportSms(){
				exportMonthSms({month:this.searchForm.smsMonth,year:this.searchForm.smsYear}).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});;
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
			},
			flowYearChanged(val){
				this.showFlowMonth = true
				ListMonthFlow({yearNum:val}).then(res=>{
					if (!!res && res.length > 0) {
						this.hasChartFlowData = true
						this.setChartFlow(res)
					}else{
						this.hasChartFlowData = false
					}
				})
			},
			smsYearChanged(val){
				this.showSMSMonth = true
				ListMonthSms({yearNum:val}).then(res=>{
					if (!!res && res.length > 0) {
						this.hasChartSMSData = true
						this.setChartSms(res)
					}else{
						this.hasChartSMSData = false
					}
				})
			},
			
			setChartFlow(dataList){
				var titleArr = [], valueArr = [];
				for (var i = 0; i < dataList.length; i++) {
				    var record = dataList[i];
				    titleArr.push(record.month);
				    valueArr.push(record.flowNum);
				}
				
				var myChart = echarts.init(document.getElementById("chartFlow"));
				this.flowChart = myChart
				let option = {
				    xAxis: {
				        type: 'category',
				        boundaryGap: false,
				        data: titleArr
				    },
				    yAxis: {
				        type: 'value'
				    },
				    series: [{
				        itemStyle: {
				            normal: {label: {show: true}}
				
				        },
				        color: '#ff6b01', lineStyle: {
				            color: '#A5B6E0'
				        },
				        data: valueArr,
				        type: 'line',
				        areaStyle: {}
				    }]
				};
				myChart.setOption(option);
			},
			setChartSms(dataList){
				var titleArr = [], valueArr = [];
				for (var i = 0; i < dataList.length; i++) {
				    var record = dataList[i];
				    titleArr.push(record.month);
				    valueArr.push(record.smsNum);
				}
				
				var myChart = echarts.init(document.getElementById("chartSMS"));
				this.flowChart = myChart
				let option = {
				    xAxis: {
				        type: 'category',
				        boundaryGap: false,
				        data: titleArr
				    },
				    yAxis: {
				        type: 'value'
				    },
				    series: [{
				        itemStyle: {
				            normal: {label: {show: true}}
				
				        },
				        color: '#ff6b01', lineStyle: {
				            color: '#A5B6E0'
				        },
				        data: valueArr,
				        type: 'line',
				        areaStyle: {}
				    }]
				};
				myChart.setOption(option);
			}
		},
		
	}
</script>

<style>
	.full-parent{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
</style>
