<template>
    <div class="bind-bank  fadeInRight">
        <div class="title-box clearfix">
        <span class="m-title">库绑定</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item label="IMEI库编号">
                <el-select filterable v-model="bindBankForm.imeiBankCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in imeiBankCodeList" :label="item.imeiBankCode" :value="item.imeiBankCode" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="卡库编号">
                <el-select filterable v-model="bindBankForm.cardBankCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in cardBankCodeList" :label="item.cardBankCode" :value="item.cardBankCode" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>

            <el-form-item>
                	<el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addBank()">新增库绑定</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>
        </div>

        <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="IMEI库编号" prop="imeiBankCode" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="卡库编号" prop="cardBankCode" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="产品名称" prop="productName" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="运营商名称" prop="accountName" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="绑定时间" prop="createTime" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="操作人" prop="operator" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip min-width="130"></el-table-column>
            <el-table-column label="操作" min-width="120">
                <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="danger" @click="deleteById(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination :current-page.sync="page"  :page-sizes="[5, 10, 15, 50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
    </div>

    <el-dialog title="新增库" :visible.sync="dialogAddBank" destroy-on-close>
        <el-form label-position="right" label-width="120px" :model="addBankForm"  ref="addBankForm" :rules="rules">
            <el-form-item label="IMEI库编号:"  prop="imeiBankCode">
                    <el-select v-model="addBankForm.imeiBankCode" placeholder="请选择" filterable>
                        <el-option v-for="(item,index) in imeiBankCodeList" :label="item.imeiBankCode" :value="item.imeiBankCode" :key="index"></el-option>
                    </el-select>
            </el-form-item>
			<el-form-item label="卡库编号:"  prop="cardBankCode">
                    <el-select v-model="addBankForm.cardBankCode" placeholder="请选择" filterable>
                        <el-option v-for="(item,index) in cardBankCodeList" :label="item.cardBankCode" :value="item.cardBankCode" :key="index"></el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label="备注"  prop="remark">
                    <el-input v-model="addBankForm.remark" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="info" @click="sureAdd('addBankForm')">确 定</el-button>
                <el-button class="themed-button" type="primary" @click="dialogAddBank = false">关 闭</el-button>
            </div>
    </el-dialog>


    </div>
</template>
<script>
import resize from '@/mixins/resize.js'
import {imeiBankCodeList,cardBankCodeList} from '@/api/meCardBindManagement/imeiBindHistory.js'
import {addBindBank,bindBankList,exportBindBank,findBankById,deleteBindBank} from '@/api/meCardBindManagement/bindBank.js'
import {messageBox,confirmBox } from '@/utils/common.js'
export default {
    // name:'bindBank',
    mixins:[resize],
    data(){
        return{
            imeiBankCodeList:[],
            cardBankCodeList:[],
            bindBankForm:{
                imeiBankCode:'',
                cardBankCode:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogAddBank:false,
            addBankForm:{
                imeiBankCode:'',
                cardBankCode:'',
                remark:''
            },
            rules:{
                imeiBankCode:[
                    {required: true, message: '请选择IMEI库', trigger: 'blur'}
                ],
                 cardBankCode:[
                    {required: true, message: '请选择卡库', trigger: 'blur'}
                ],

            },
        }
    },
    created() {
       this.getImeiBankCodeList() 
       this.getCardBankCodeList()
       this.getBindBankList()
    },
    methods: {
        getImeiBankCodeList(){
            imeiBankCodeList().then(res=>{
                this.imeiBankCodeList = res
            })
        },
        getCardBankCodeList(){
            cardBankCodeList().then(res=>{
                this.cardBankCodeList = res
            })
        },
        addBank(){
            this.dialogAddBank = true
        },
        sureAdd(formName){
            this.$confirm('确定要新增吗？','消息提示',{
                confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
            }).then(()=>{
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    addBindBank(this.addBankForm).then(res=>{
                        this.$message({
                            showClose: true,
					        message: '新增成功',
					        type: 'success',
							center: true
                        })
                        this.$refs[formName].resetFields()
                        this.dialogAddBank=false
                        this.getBindBankList() 
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            }).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
			});
        },
        getBindBankList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.bindBankForm,params)
            bindBankList(this.bindBankForm).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        deleteById(id){
            findBankById({id}).then(res=>{
                this.notice = `IMEI库编号:${res.entity.imeiBankCode}</br>
                卡库编号:${res.entity.cardBankCode}</br>
                客户:${res.entity.customerName}</br>
                绑定时间:${res.entity.createTime}</br>
                删除绑定关系后，该库中绑定的卡将全部解绑。</br>
                请确认是否继续？`
            confirmBox(this,this.notice,'消息提示',()=>{
                deleteBindBank({id}).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						})
						this.getBindBankList()
				})
            })
         })

            
        },
        searchTable(){
            this.page = 1 
            this.getBindBankList()
        },
        onPageChange(page){
			this.page = page
			this.getBindBankList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getBindBankList()
        },
        exportExcel(){
             exportBindBank(this.bindBankForm).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
         },
    },
}
</script>
<style>
    
</style>
