<template>
  <div class="chargeResourceManagement table-hook fadeInRight">
    <div class="title-box">
      <span class="m-title">付费项授权管理</span>
      <el-form
        inline
        class="hook right themed-form search-form"
        style="line-height: 40px"
      >
        <el-form-item label="代理商:" prop="agentId">
          <el-select
            placeholder="代理商"
            v-model="form.agentId"
            filterable
            clearable
            @change="getChargeList"
          >
            <el-option
              v-for="item in operationAgent"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <!-- <span style="color:#f00;margin-left:6px;">请选择代理商</span> -->
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="themed-button"
            @click="addRecord"
            >添加付费项</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        :data="tableData"
        stripe
        border
        :max-height="maxTableHeight"
      >
        <el-table-column label="资源名" prop="resourceName"></el-table-column>

        <el-table-column
          label="添加时间"
          prop="createTime"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          label="操作人"
          prop="createBy"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column label="操作" prop="status">
          <template slot-scope="scope">
            <el-button
              type="danger"
              class="themed-button"
              @click="delDate(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog title="添加付费资源" :visible.sync="dialogBox" v-if="dialogBox">
      <el-form label-position="right" label-width="200px" ref="addForm" :rules="rules" :model="addForm">
        <!-- <el-form-item label="代理商:" prop="agentId" required>
          <el-select
            placeholder="代理商"
            v-model="addForm.agentId"
            filterable
            clearable
          >
            <el-option
              v-for="item in operationAgent"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="付费资源:" prop="resourceId" required>
          <el-select
            placeholder="选择资源"
            v-model="addForm.resourceId"
            filterable
            clearable
          >
            <el-option
              v-for="item in resourceList"
              :key="item.id"
              :label="item.resourceName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="themed-button" @click="submit('addForm')"
          >提交</el-button
        >
        <el-button
          type="primary"
          class="themed-button"
          @click="dialogBox = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  getAgentSwitchList,
  delChargeConfig,
  getResourceList,
  getChargeResources,
  addChargeResources
} from "@/api/agentManagement.js";

import {
  amountList,
  edit,
  add,
  del,
  agentList,
  delAgentAmount,
  addSetAmonut,
} from "@/api/cardManagement/proAgentAmount.js";
import { messageBox, confirmBox } from "@/utils/common.js";

import resize from "@/mixins/resize.js";
import { orderGetAgents } from "@/api/agentApplyCheck.js";
import { getAgentType } from "@/api/agentManagement.js";

export default {
  name: "chargeResourceManagement",
  mixins: [resize],
  data() {
    return {
      form: {
        agentId: "",
      },
      tableData: [],
      pageNo: 1,
      pageSize: 15,
      total: 0,
      operationAgent: [],
      dialogBox: false,
      addForm:{
        agentId: "",
        resourceId:"",
      },
       rules: {
           agentId: [
            { required: true, message: '请选择代理商', trigger: 'change' }
          ],
          resourceId: [
            { required: true, message: '请选择付费资源', trigger: 'change' }
          ],}
    };
  },
  mounted() {
    this.getChargeList();
    this.getAgentSwitchList();
    this.getResourceData()
  },
  methods: {
    submit(formName){
     this.$refs[formName].validate((valid) => {
          if (valid) {
           addChargeResources(this.addForm).then(res=>{
             if (res.slide_msg_key == 1) {
          messageBox(res.slide_msg_message_key);
        }
              messageBox(this,'添加成功')
              this.getChargeList();
              this.dialogBox = false
           })
          } else {
           
            return false;
          }
        });
    },
    getResourceData(){
    getChargeResources({}).then(res=>{
     console.log(res)
this.resourceList = res
    })
    },
    getChargeList(val) {
      if (!this.form.agentId) {
        this.tableData=[]
        return;
      }
      let params = {};
      params.pageSize = this.pageSize;
      params.pageNo = this.pageNo;
      getResourceList(Object.assign(params, this.form)).then((res) => {
        console.log(res,221)
        this.pageNo = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
        this.tableData = res.rows;
      });
    },
    getAgentSwitchList() {
      getAgentSwitchList({}).then((responseResult) => {
        if (responseResult.slide_msg_key == 1) {
          this.$alert(responseResult.slide_msg_message_key, "消息提示", {
            confirmButtonText: "确定",
          });
        }
        this.operationAgent = responseResult;
      });
    },
    delDate(id) {
      confirmBox(this, "确定要删除吗？", "消息提示", () => {
        delChargeConfig({ id }).then((res) => {
          messageBox(this, "删除成功");
          this.getChargeList();
        });
      });
    },

    addRecord() {
      if(!this.form.agentId){
         this.$message({
          type: "error",
          message: "请选择代理商",
        });
        return;
      }
      this.dialogBox = true
     this.addForm.agentId = this.form.agentId
      this.addForm.resourceId =""

    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getChargeList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getChargeList();
    },
   
  },
};
</script>

