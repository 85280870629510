<template>
  <div class="flexibleCode table-hook fadeInRight">
    <div class="title-box">
      <span class="m-title">通用配置</span>
      <el-form
        inline
        :model="formInline"
        class="hook right themed-form search-form"
      >
        <el-form-item>
          <el-input
            placeholder="请输入名称"
            clearable
            v-model="name"
            class="input-with-select"
          >
            <el-button slot="append" icon="el-icon-search" @click="searchData"
              >搜索</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item label="类型:" prop="type" :label-width="formLabelWidth">
          <el-select
            placeholder="配置类型"
            v-model="curType"
            filterable
            @change="getCommonList"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="add"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="name" label="名称" min-width="180">
        </el-table-column>

        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
        <component
          :is="comArr[curType]"
          ref="childRef"
          :initData="formData"
          @flexCode="closeFlexCode"
        ></component>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeForm" type="primary" class="themed-button"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="sureAdd('addForm')"
            class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import MobileMenu from "./configStyle/MobileMenu";
import AdContent from "./configStyle/AdContent";
import flexibleCode from "./configStyle/flexibleCode";
import realName from "./configStyle/realName";
import {
  getCommonConfigList,
  addCommonConfig,
  updateCommonConfig,
  delCommonConfig,
  getTypeList,
} from "@/api/configurationManagement.js";
import { mapGetters } from "vuex";

export default {
  name: "commonConfig",
  components: { MobileMenu, AdContent, flexibleCode, realName },
  mixins: [resize],
  data() {
    return {
      typeList: [],
      curType: "",
      comArr: [, "flexible-code", , "mobile-menu", "ad-content", , "real-name"],
      formInline: {},
      tableData: [],

      dialogTitle: "",
      dialogFormVisible: false,
      formData: {},

      page: 1,
      pageSize: 15,
      total: 0,
      name: "",
      formLabelWidth: "70px",
    };
  },
  created() {
    this.selectInit();
  },
  methods: {
    searchData() {
      this.page = 1;
      this.getCommonList();
    },
    closeFlexCode() {
      this.dialogFormVisible = false;
      this.$router.push({
        path: "/mappingManage/mappingList",
      });
    },
    selectInit() {
      
      getTypeList({ tag: "common" }).then((res) => {
        var typeList = [];
        for (let i in res) {
          if (!parseInt(this.isGuangyao)) {
            console.log(res[i]);
            if (
            parseInt(this.isHuoma) 
            ) {
              if (res[i] == "广告位") {
                continue;
              }
            } else {
              if (res[i] == "广告位" || res[i] == "活码") {
                continue;
              }
            }
          }
          typeList.push({ id: i, name: res[i] });
        }
        this.typeList = typeList;
      });
    },
    showForm(title) {
      this.dialogTitle = title;
      this.dialogFormVisible = true;
    },
    closeForm() {
      this.dialogFormVisible = false;
      this.searchData();
    },
    onPageChange(page) {
      this.page = page;
      this.getCommonList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getCommonList();
    },
    getCommonList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        type: this.curType,
        name: this.name,
      };

      getCommonConfigList(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
        this.formData = { jsonStr: "{}" };
      });
    },
    clearValidate() {
      this.$refs["childRef"].$refs["dataForm"].clearValidate();
    },
    edit(row) {
      // this.searchData()
      console.log(row, 191);
      this.initForm(row);
      this.showForm("编辑");
      this.$nextTick(() => {
        this.clearValidate();
      });
    },
    add() {
      if (!this.curType) {
        this.$message({
          type: "error",
          message: "请选择配置类型",
        });
        return;
      }
      console.log(this.curType, 189);

      this.initForm({ jsonStr: "{}" });
      this.showForm("新增");
      this.$nextTick(() => {
        this.clearValidate();
      });
    },
    initForm(object) {
      this.formData = object;
      console.log(object, 196);
    },
    sureAdd() {
      let formData = this.$refs["childRef"].formData;
      this.$refs["childRef"].$refs["dataForm"].validate((valid) => {
        if (valid) {
          console.log(JSON.parse(formData.jsonStr));
          console.log(this.comArr[this.curType]);
          if (this.comArr[this.curType] == "mobile-menu" && parseInt(this.isGuangyao)) {
            if (JSON.parse(formData.jsonStr).showProfit == undefined) {
              this.$message({
                showClose: true,
                message: "请配置话费提现开关",
                type: "warning",
              });
              return;
            }
          }
          if (this.comArr[this.curType] == "flexible-code" && parseInt(this.isGuangyao)) {
            if (
              JSON.parse(formData.jsonStr).kefuPage.directJump &&
              JSON.parse(formData.jsonStr).kefuPage.directJumpUrl.trim() == ""
            ) {
              this.$message({
                showClose: true,
                message: "请配置跳转地址",
                type: "warning",
              });
              return;
            }
          }

          if (this.comArr[this.curType] == "real-name") {
            if (Object.keys(JSON.parse(formData.jsonStr)).length === 0) {
              this.$message({
                showClose: true,
                message: "请设置优先级规则",
                type: "warning",
              });
              return;
            }
            const requiredProps = ["first", "second"];
            if (!this.hasAllPropertiesWithValues(JSON.parse(formData.jsonStr),requiredProps) ) {
              this.$message({
                showClose: true,
                message: "第一第二优先级必须设置",
                type: "warning",
              });
              return;
            }


             if (!! JSON.parse(formData.jsonStr).fourth  && (JSON.parse(formData.jsonStr).third =="" || JSON.parse(formData.jsonStr).third == undefined )  ) {
              this.$message({
                showClose: true,
                message: "请优先设置第三优先级",
                type: "warning",
              });
              return;
            }


            if (this.hasDuplicateValues(JSON.parse(formData.jsonStr))) {
              this.$message({
                showClose: true,
                message: "优先级设置的运营商不能相同",
                type: "warning",
              });
              return;
            }
          }

          console.log(JSON.parse(formData.jsonStr).third)

          formData.type = this.curType;
          if (formData.id) {
            updateCommonConfig(formData).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.getCommonList();
              if (this.comArr[this.curType] == "flexible-code") {
                console.log(999);
                this.$refs["childRef"].getCommonList();
              }
            });
          } else {
            addCommonConfig(formData).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.getCommonList();
              if (this.comArr[this.curType] == "flexible-code") {
                console.log(999);
                this.$refs["childRef"].getCommonList();
              }
            });
          }

          this.closeForm();
        } else {
          return false;
        }
      });
    },
    hasDuplicateValues(obj) {
      //检查对象是否有重复属性值   true 代表有
      const values = Object.values(obj);
      return values.some((value, index) => values.includes(value, index + 1));
    },
   
    hasAllPropertiesWithValues(obj, props) {
     return props.every(prop => obj.hasOwnProperty(prop) && !!obj[prop]);
},
   

    delect(id) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delCommonConfig({ id }).then((res) => {
          this.getCommonList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
 
  computed: {
    ...mapGetters(["isCaptal","isHuoma","isGuangyao"]),
  },
};
</script>

<style>
/* .materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
} */
/* .flexibleCode .el-form-item__label {
  vertical-align: top;
}
.flexibleCode  .el-form-item--small .el-form-item__content{line-height: none;} */
.flexibleCode .el-form-item__content .el-input-group {
  vertical-align: middle;
}
</style>
