<template>
  <div class="reviewOperate">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <el-form-item label="账号信息：">
        <el-row>
          <el-col :span="12">
            <el-form-item label="账号:">
              <el-input
                v-model="ruleForm.mobile"
                placeholder="账号"
                :disabled="true"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="联系人名称:">
              <el-input
                v-model="ruleForm.contact"
                placeholder="联系人名称"
                :disabled="true"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="邀请人:">
              <el-input
                v-model="ruleForm.inviter"
                placeholder="邀请人"
                :disabled="true"
              ></el-input>
             
             
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="销售:">
             
              <el-select v-model="ruleForm.sale" class="search-select">
                 
                  <el-option
                    v-for="item in inviterList"
                    :key="item.id"
                    :label="item.saleName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行业信息:" prop="industryInfo">
              <el-cascader
                v-model="ruleForm.industryInfo"
                :options="options"
              
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
        <el-form-item class="buttonView">
        <el-button
          type="primary"
          class="themed-button"
          @click="modifyAccount"
          >确认</el-button
        >
      </el-form-item>
      <el-form-item label="工商信息:">
        <el-form-item label="企业名称:">
          <el-input v-model="ruleForm.company" :disabled="true"></el-input>
        </el-form-item>
        <!-- <el-form-item label="执照号:">
          <el-input
            v-model="ruleForm.licenseNumber"
            :disabled="true"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="执照:">
          <div @click="previewPic">
            
              <el-image :src="ruleForm.imgUrl">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            
          </div>
        </el-form-item>
        <el-form-item label="审核备注:">
          <el-input v-model="ruleForm.remark"></el-input>
        </el-form-item>
      </el-form-item>
      <el-form-item class="buttonView">
        <el-button
          type="primary"
          class="themed-button"
          @click="passCompany"
          :disabled="companyStatus"
          >通过</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          :disabled="companyStatus"
          @click="failCompany"
          >不通过</el-button
        >
      </el-form-item>
      <el-form-item label="法人信息:">
        <el-form-item label="法人身份证:">
          <el-input
            v-model="ruleForm.legalPersonID"
            :disabled="true"
          ></el-input>
        </el-form-item>
         <el-form-item label="身份类型:">
          <el-select v-model="ruleForm.corType" disabled >
				   <el-option label="法人" value="1"></el-option>
				   <el-option label="法定授权人" value="2"></el-option>
				 </el-select>
        </el-form-item>

        <el-form-item label="身份证正面:">
          <div @click="previewIDCardFront">
            
              <el-image :src="ruleForm.idCardFront">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
          
          </div>
        </el-form-item>
        <el-form-item label="身份证反面:">
          <div @click="previewIDCardBack">
            
              <el-image :src="ruleForm.idCardBack">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
           
          </div>
        </el-form-item>
         <el-form-item label="证明图片:" v-if="ruleForm.corType==2">
          <div @click="previewAuthPerson">
            
              <el-image :src="ruleForm.authPersonCerfication">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
           
          </div>
        </el-form-item>
        <el-form-item label="审核备注:">
          <el-input v-model="ruleForm.remarkOne"></el-input>
        </el-form-item>
      </el-form-item>

      <el-form-item class="buttonView">
        <el-button
          type="primary"
          class="themed-button"
          @click="pass"
          :disabled="personStatus"
          >通过</el-button
        >
        <el-button
          type="primary"
          :disabled="personStatus"
          class="themed-button"
          @click="fail"
          >不通过</el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
  </div>
  
</template>

<script type="text/ecmascript-6">
import {
  getBussinessInfo,
  getTobCompanyInfo,
  checkTobCompanyInfo,
  checkTobCorperationInfo,
  getSaleList,
  updateSale
} from "@/api/agentManagement.js";

import { messageBox } from "@/utils/common.js";
import dictGroup from "@/mixins/dictGroup.js";

export default {
  props: ["reviewData"],
  mixins: [dictGroup],
  data() {
    return {
      ruleForm: {
        mobile: this.reviewData.mobile, //账号
        contact: "", //联系人名称
        sale: "", //销售
        industryInfo: "", //行业信息
        company: "", //企业名称
        // licenseNumber: "", //执照号
        imgUrl: "", //执照图片
        legalPersonID: "", //法人身份证
        idCardFront: "", //身份证正面
        idCardBack: "", //身份证反面
        remark: "", //审核备注
        remarkOne: "",
        inviter:this.reviewData.sale,
        corType:"",
      },
      options: [],
      companyStatus: false,
      personStatus: false,
      dialogVisible:false,
      dialogImageUrl:"",
      inviterList:[]
    
    };
  },

  created() {
    console.log(this.reviewData);
    if(this.reviewData.authFlag===0){
       this.companyStatus = false
    }else{
      this.companyStatus = true
    }
     if(this.reviewData.corAuthFlag===0){
       this.personStatus = false
    }else{
      this.personStatus = true
    }
    this.getProfessionalInfo();
    this.getTobCompanyInfo();
    this.getSaleList()
  },
  methods: {
    modifyAccount(){
      if(!this.ruleForm.sale){
         this.$alert('请选择销售人员', '提示', {
								confirmButtonText: '确定',
							});
              return 
      }
      let params = {
        agentId:this.reviewData.id,
        saleId:this.ruleForm.sale,
        professionalInfo:JSON.stringify(
            this.ruleForm.industryInfo
          ),
      }
    updateSale(params).then(res=>{
      this.$alert('修改账户信息成功', '提示', {
								confirmButtonText: '确定',
							});
    })
    },
     getSaleList() {
      getSaleList({}).then((res) => {
        console.log(res);
        this.inviterList = res;
      });
    },
    getTobCompanyInfo() {
      getTobCompanyInfo({ agentId: this.reviewData.id }).then((res) => {
        console.log(res);

        this.ruleForm.company = res[0].bussinessName;
        // this.ruleForm.licenseNumber = res[0].bussinessRegisterNum;
        this.ruleForm.imgUrl = res[0].bussinessLicenseImage;
        // this.ruleForm.imgUrl = "https://8bu8rkbghw.oss-cn-hangzhou.aliyuncs.com/1666081178608_97.png"
        this.ruleForm.contact = res[0].contact;
        try{
          this.ruleForm.industryInfo = JSON.parse(res[0].professionalInfo);
        }catch(error){
          this.ruleForm.industryInfo =(res[0].professionalInfo);
        }
        
        this.ruleForm.legalPersonID = res[0].corporationIdCard;
        this.ruleForm.idCardFront = res[0].corporationIdCardImageF;
        this.ruleForm.idCardBack = res[0].corporationIdCardImageB;
        this.ruleForm.corType = res[0].corType+"";
        this.ruleForm.authPersonCerfication = res[0].authPersonCerfication
        // console.log(this.ruleForm)
        //  this.ruleForm.idCardFront = "https://8bu8rkbghw.oss-cn-hangzhou.aliyuncs.com/1666081178608_97.png"
        // this.ruleForm.idCardBack ="https://8bu8rkbghw.oss-cn-hangzhou.aliyuncs.com/1666081178608_97.png"
        // console.log(this.ruleForm)
      });
    },
    getProfessionalInfo() {
      //获取行业信息  给this.options 赋值；
      getBussinessInfo().then((res) => {
        console.log(res);
        this.options = res;
      });
    },

    previewPic() {
      //预览执照
      this.dialogVisible = true
      this.dialogImageUrl =  this.ruleForm.imgUrl
    },
    previewIDCardFront() {
      //预览身份证正面
      this.dialogVisible = true
      this.dialogImageUrl =  this.ruleForm.idCardFront
    },
    previewIDCardBack() {
      //预览身份证反面
      this.dialogVisible = true
      this.dialogImageUrl =   this.ruleForm.idCardBack
    },
    previewAuthPerson(){
    this.dialogVisible = true
      this.dialogImageUrl =   this.ruleForm.authPersonCerfication
    },
    pass() {
      let params = {
        agentId: this.reviewData.id,
        status: 1,
        remark2: this.ruleForm.remarkOne,
      };
      checkTobCorperationInfo(params).then((res) => {
        this.personStatus = true;
         this.$alert('法人信息审核通过', '提示', {
								confirmButtonText: '确定',
                 callback: action => {
            if (this.companyStatus && this.personStatus) {
             this.close();
        }
          }
							});
      });
    },
    fail() {
      let params = {
        agentId: this.reviewData.id,
        status: 2,
        remark2: this.ruleForm.remarkOne,
      };
      checkTobCorperationInfo(params).then((res) => {
        this.personStatus = true;
        this.$alert('法人信息审核不通过，请联系代理商重新修改法人信息', '提示', {
								confirmButtonText: '确定',
                callback: action => {
            if (this.companyStatus && this.personStatus) {
             this.close();
        }
          }
							});
      
      });
    },
    passCompany() {
      let params = {
        agentId: this.reviewData.id,
        status: 1,
        remark: this.ruleForm.remark,
      };
      checkTobCompanyInfo(params).then((res) => {
        this.companyStatus = true;
    this.$alert('工商信息审核通过', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            if (this.companyStatus && this.personStatus) {
             this.close();
        }
          }
        });
       
      });
    },

    failCompany() {
      let params = {
        agentId: this.reviewData.id,
        status: 2,
        remark: this.ruleForm.remark,
      };
      checkTobCompanyInfo(params).then((res) => {
       
        this.companyStatus = true;
            this.$alert('工商信息审核不通过，请联系代理商重新修改工商信息', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            if (this.companyStatus && this.personStatus) {
             this.close();
        }
          }
        });
       
      });
    },
    close() {
      this.$emit("close");
    },
   
  },
};
</script>

<style>
.reviewOperate .el-input {
  width: auto;
}
.el-select-dropdown__list {
  max-width: 550px;
}
.buttonView   .el-form-item__content{
  text-align: right;
}
</style>
