<template>
    <div class="weixin-order fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">微信订单</span>
            <el-form inline ref="weixinOrderForm" v-model="weixinOrderData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="下单起始日期" v-model="weixinOrderData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="下单结束日期" v-model="weixinOrderData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item label="所属代理商:">
                <el-select v-model="weixinOrderData.agentTreeIdNew"    filterable clearable >
					<el-option v-for="item in agentThreeLists" :key="item.index" :label="item.agentName" :value="item.agentId">
					</el-option>
				</el-select>
                </el-form-item>
                <!-- <el-form-item>
                    <el-input v-model="weixinOrderData.agentThreeName" placeholder="所属代理商"></el-input>
                </el-form-item> -->
                <el-form-item>
                    <el-input v-model="weixinOrderData.wechatAgentName" placeholder="下单代理商"></el-input>
                </el-form-item>
                <el-form-item label="充值类型">
                    <search-select :optionsMetaAll="rechargeOperators" v-model="weixinOrderData.isFirstRecharge"></search-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="weixinOrderData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="weixinOrderData.mealName" placeholder="套餐"></el-input>
                </el-form-item>
                <el-form-item label="开卡公司">
                    <el-select filterable v-model="weixinOrderData.accountId">
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="(item,index) in cardCompanyOperators" :key="index" :value="item.id" :label="item.accountName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="weixinOrderData.cardId" placeholder="卡号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="weixinOrderData.shortIccid" placeholder="短号" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item label="卡来源">
                    <el-select filterable v-model="weixinOrderData.type">
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="(item,index) in w_ACCOUNT_" :key="index" :value="item.dictValue" :label="item.groupName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="weixinOrderData.orderNumber" placeholder="订单号"></el-input>
                </el-form-item>
                
                 <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                ref='table'
                border
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%">

                <el-table-column
                fixed
                label="操作"
                min-width="150">
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleShowDetail(scope.row.id)">详情</el-button>
                        <el-button size="mini" class="themed-button" type="primary" @click="handleRefund(scope.row.id,scope.row.orderMoney)">退款</el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderNumber"
                    label="订单号"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="agentThreeName"
                    label="所属代理商"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="agentName"
                    label="所属总代理商"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="wechatAgentName"
                    label="下单代理商"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    label="iccid"
                    min-width="180">
                    <template slot-scope="scope">
                        <el-link :underline='false' type="primary" @click="toKawu(scope.row.id,scope.row.cardId)">{{scope.row.cardId}}</el-link>
                    </template>
                </el-table-column>
                  <el-table-column
                  prop="shortIccid"
                    label="短号"
                    min-width="130">
                   
                </el-table-column>

                <el-table-column
                    prop="phone"
                    label="接入号"
                    min-width="140">
                </el-table-column>
                 
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="mealName"
                    label="套餐名称"
                    min-width="170">
                </el-table-column>
                <el-table-column
                    label="充值状态"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.rechargeStatus==1">待充值</span>
                        <span v-else-if="scope.row.rechargeStatus==2">已充值</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="推送状态"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.pushStatus==1">待推送</span>
                        <span v-else-if="scope.row.pushStatus==2">已推送</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="次月生效"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="(scope.row.effectType==2)&&(scope.row.mealType==10||scope.row.mealType==12)">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="mealTypeStr"
                    label="套餐类型"
                    min-width="150">
                </el-table-column>
                <el-table-column
                    prop="mealSecondTypeStr"
                    label="二级套餐类型"
                    min-width="112">
                </el-table-column>
                <el-table-column
                    prop="orderMoney"
                    label="下单金额"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="orderMoney"
                    label="实际支付金额"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    label="支付状态"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payStatus==1">已支付</span>
                        <span v-else-if="scope.row.payStatus==2">待支付</span>
                        <span v-else-if="scope.row.payStatus==3">已取消</span>
                    </template>
                </el-table-column>
                  <el-table-column
                    label="支付方式"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payMethod==2">余额</span>
                         <span v-else-if="scope.row.payMethod==6">组合</span>
                         <span v-else-if="scope.row.payMethod==7">小诺</span>
                        <span v-else>微信</span>
                    </template>
                </el-table-column>
                <el-table-column
                prop="mchId"
                label="商户ID"
                min-width="180"
                >
            </el-table-column>
                 <el-table-column label="无佣金标识" prop="noProfitFlag" >
                <template slot-scope="scope">
                   <span v-if="scope.row.noProfitFlag==1" style="color:#f00">无佣金</span>
                   <span v-else-if="scope.row.noProfitFlag==0" style="color:#f00"></span>
                   <span v-else style="color:#f00"></span>
                </template>
            </el-table-column>
                <el-table-column
                    prop="orderCount"
                    label="数量"
                    min-width="50">
                </el-table-column>
                <el-table-column
                    prop="accountName"
                    label="开卡公司"
                    min-width="98">
                </el-table-column>
                <el-table-column
                    prop="typeStr"
                    label="卡来源"
                    min-width="78">
                </el-table-column>
                <el-table-column
                    label="充值类型"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isFirstRecharge==1">首次充值</span>
                        <span v-else-if="scope.row.isFirstRecharge==2">续费充值</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="下单时间"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    prop="applyMoney"
                    label="返现"
                    min-width="78">
                </el-table-column>

                <el-table-column
                    label="销售类型"
                    min-width="98">
                    <template slot-scope="scope">
                        <span v-if="scope.row.saleType==1">线上</span>
                        <span v-else-if="scope.row.saleType==2">线下</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="合作类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.coType==1">普通类型</span>
                        <span v-else-if="scope.row.coType==2">按量结算</span>
                        <span v-else-if="scope.row.coType==3">api充值</span> -->
                        <span>{{_getCoType(scope.row.coType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="产品类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.productType==1">大河卡</span>
                        <span v-else-if="scope.row.productType==2">手表卡</span>
                        <span v-else-if="scope.row.productType==3">大流量卡</span> -->
                        <span>{{_getProductType(scope.row.productType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="业务类型"
                    min-width="120">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.toType=='C'">ToC</span>
                        <span v-else-if="scope.row.toType=='B'">ToB</span>
                        <span v-else-if="scope.row.toType=='API'">API</span> -->
                        <span>{{_getToType(scope.row.toType)}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="netMoney"
                    label="最终支付金额"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="orderExpireDate"
                    label="套餐到期时间"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true"
                    prop="saleName"
                    label="销售名称"
                    min-width="120">
                </el-table-column>
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
             
        <el-dialog
        title="订单详情"
        :visible.sync="dialogDetailVisible"
        :destroy-on-close='true'
        >
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">订单号</div></el-col>
        <el-col :span="12"><div>{{detailInfo.orderNumber}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">代理商名称</div></el-col>
        <el-col :span="12"><div>{{detailInfo.agentName}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">卡号</div></el-col>
        <el-col :span="12"><div>{{detailInfo.cardId}}</div></el-col>
        </el-row>
         <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">短号</div></el-col>
        <el-col :span="12"><div>{{detailInfo.shortIccid}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">套餐名称</div></el-col>
        <el-col :span="12"><div>{{detailInfo.mealName}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">套餐类型</div></el-col>
        <el-col :span="12">
            <!-- <div v-if="detailInfo.mealType == 1">月卡叠加包</div>
            <div v-else-if="detailInfo.mealType == 2">月卡续费</div>
            <div v-else-if="detailInfo.mealType == 3">年卡叠加包</div>
            <div v-else-if="detailInfo.mealType == 4">年卡续费</div> -->
            <div>{{_getMealType(detailInfo.mealType)}}</div>
        </el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">用户支付金额</div></el-col>
        <el-col :span="12"><div>{{detailInfo.orderMoney}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">支付状态</div></el-col>
        <el-col :span="12">
            <div v-if="detailInfo.payStatus == 1">已支付</div>
            <div v-else-if="detailInfo.payStatus == 2">待支付</div>
            <div v-else-if="detailInfo.payStatus == 3">已取消</div>
            <div v-else-if="detailInfo.payStatus == 4">已退款</div>
            <div v-else>退款中</div>
        </el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">数量</div></el-col>
        <el-col :span="12"><div>{{detailInfo.orderCount}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">开卡公司</div></el-col>
        <el-col :span="12"><div>{{detailInfo.accountName}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">卡来源</div></el-col>
        <el-col :span="12">
            <div v-if="detailInfo.type == 1">电信</div>
            <div v-else>其他卡务</div>
        </el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">返现金额</div></el-col>
        <el-col :span="12"><div>{{detailInfo.money}}</div></el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">下单时间</div></el-col>
        <el-col :span="12"><div>{{detailInfo.createTime}}</div></el-col>
        </el-row>

        <el-row v-if='detailInfo.payStatus == 4' :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">退款理由</div></el-col>
        <el-col :span="12"><div>{{detailInfo.refundReason}}</div></el-col>
        </el-row>

        <el-row v-if='detailInfo.payStatus == 4' :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">退款时间</div></el-col>
        <el-col :span="12"><div>{{detailInfo.refundTime}}</div></el-col>
        </el-row>

        <el-row v-if='detailInfo.payStatus == 4' :gutter="20">
        <el-col :span="12"><div class="dialog-row-label">退款操作人</div></el-col>
        <el-col :span="12"><div>{{detailInfo.refundCreateUserName}}</div></el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="dialogDetailVisible = false">关闭</el-button>
        </span>
        </el-dialog>

        <el-dialog title="退款" :visible.sync="dialogRefundVisible" :destroy-on-close='true'>
        <el-form :model="refundForm" :rules="rules" ref="refundForm">
            <el-form-item label="退款金额" prop="refundFee">
            <el-input v-model="refundForm.refundFee" autocomplete="off" placeholder="请填写退款金额" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="退款理由" prop="refundReason">
             <el-input type="textarea" v-model="refundForm.refundReason"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="info" class="themed-button" @click="dialogRefundVisible = false">取 消</el-button>
            <el-button type="primary" class="themed-button" @click="sureRefund('refundForm')" v-preventReClick='1000' :disabled="isDisable" >确 定</el-button>
        </div>
        </el-dialog>
    </div>
</template>
<script>
import {agentListForUser } from '@/api/cardManagement/cardBill.js'
import SearchSelect from '@/components/SearchSelect.vue'
import {cardCompanyList,weChatOrderList,weChatOrderDetailInfo,weChatOrderRefund,exportWeixinOrder} from '@/api/order.js'
import resize from '@/mixins/resize.js'
import dictGroup from '@/mixins/dictGroup.js'
import {mapMutations} from "vuex"
export default {
    name:'weixinOrder',
    components:{SearchSelect},
    mixins:[resize,dictGroup],
    data(){
        return {
            weixinOrderData:{
                payStatus:'1',
                startTime:'',
                endTime:'',
                agentThreeName:'',
                agentTreeIdNew:"",
                wechatAgentName:'',
                isFirstRecharge:'',
                phone:'',
                mealName:'',
                accountId:'',
                cardId:'',
                type:'',
                orderNumber:'',
                shortIccid:""
            },
            agentThreeLists:[],
            rechargeOperators:[
                {label:'全部',value:''},
                {label:'首次充值',value:'1'},
                {label:'续费充值',value:'2'}
            ],
            cardCompanyOperators:[],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogDetailVisible:false,
            detailInfo:{
                orderNumber:'',
                agentName:'',
                cardId:'',
                mealName:'',
                mealType:'',
                orderMoney:'',
                payStatus:'',
                orderCount:'',
                type:'',
                money:'',
                createTime:'',
                refundReason:'',
                refundTime:'',
                refundCreateUserName:'',
                accountName:'',
                shortIccid:"",
            },
            dialogRefundVisible:false,
            refundForm:{
                refundFee:'',
                refundReason:''
            },
            
            rules:{
                refundFee:[
                    {required: true, message: '请输入退款金额', trigger: 'blur'},
                ],
                refundReason:[
                    {required: true, message: '请填写退款理由', trigger: 'blur'}
                ]
            },
            refundID:'',
            refundOrderMoney:'',
            isDisable:false,
        }
    },
    created(){
        this.getAgentListForUser()
        this.orderNumbers = this.$route.query.orderNumbers?this.$route.query.orderNumbers:'',
        this.getcardCompanyOperators()
        this.getWeixinOrderList()
         try{
               console.log("诺行版权")
            }catch(error){
               console.log("notioni:"+error)
            }
    },
    methods:{
        ...mapMutations(
                ["SET_ICCID_OR_PHONE","SET_STATUS","SET_TYPE"]
            ),
             getAgentListForUser(){
             agentListForUser().then(res=>{
                console.log(391)
                this.agentThreeLists = res
             })
            },
        getcardCompanyOperators(){
            cardCompanyList().then(res=>{              
                 this.cardCompanyOperators = res
            })
        },
        getWeixinOrderList(){
            let params = {
                orderNumbers:this.orderNumbers,
                 pageSize:this.pageSize,
                 pageNo:this.page
             }
             Object.assign(this.weixinOrderData,params)
             weChatOrderList(this.weixinOrderData).then(res=>{
                 this.tableData=res.rows
                 this.total=res.count
             })   
        },
        onSearch(){
            this.page = 1
            this.getWeixinOrderList()
         },
         exportExcel(){
             exportWeixinOrder(this.weixinOrderData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
         },
        onPageChange(page){
			this.page = page
			this.getWeixinOrderList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getWeixinOrderList()
        },
        handleShowDetail(id){
            this.dialogDetailVisible = true
            let params ={
                id:id
            }
            weChatOrderDetailInfo(params).then(res=>{
                let result = res.entity
                console.log(result)
                this.detailInfo.orderNumber = result.orderNumber
                this.detailInfo.agentName = result.agentName
                this.detailInfo.cardId = result.cardId
                this.detailInfo.mealName = result.mealName
                this.detailInfo.mealType = result.mealType
                this.detailInfo.orderMoney = result.orderMoney
                this.detailInfo.payStatus = result.payStatus
                this.detailInfo.orderCount = result.orderCount
                this.detailInfo.accountName = result.accountName
                this.detailInfo.type = result.type
                this.detailInfo.money = result.money
                this.detailInfo.createTime = result.createTime

                this.detailInfo.refundReason = result.refundReason
                this.detailInfo.refundTime = result.refundTime
                this.detailInfo.refundCreateUserName = result.refundCreateUserName
                this.detailInfo.shortIccid = result.shortIccid
            })
        },
        handleRefund(id,orderMoney){
            this.dialogRefundVisible =true
            this.refundForm.refundFee = orderMoney
            this.refundForm.refundReason = ''
            this.refundID = id
            this.refundOrderMoney = orderMoney
        },
        sureRefund(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                if(!!this.refundForm.refundFee && parseFloat(this.refundForm.refundFee) > parseFloat(this.refundOrderMoney)){
                    this.$message("输入金额大于订单金额！")
                    return false;
                }else{
                    let params = {
                        id:this.refundID,
                        orderMoney:this.refundOrderMoney
                    }
                    Object.assign(this.refundForm,params)
                    this.isDisable = true
                    weChatOrderRefund(this.refundForm).then(res=>{
                        this.isDisable = false
                        if (res.system_result_key == '0') {
                            this.$message(res.system_result_message_key)
                        }
                        this.dialogRefundVisible =false
                        this.getWeixinOrderList()
                        // this.refundForm={}
                        this.refundForm={  refundFee:'', refundReason:''}
                    }).catch(()=>{
                        this.isDisable = false
                    })
                }
            } else {
                console.log('error!!');
                return false;
            }
            });
        },
        toKawu(id,cardId){
            this.SET_ICCID_OR_PHONE(cardId)
            this.SET_STATUS('0')
            this.SET_TYPE('0')
             this.$router.push({path:'../cardManagement/cardBillDetail',query:{'iccidOrPhone':cardId,'status':0,'type':0}})
        }
    }
    
}
</script>
<style>
    .weixin-order .el-form-item__label{
        vertical-align: top;
    }
    .el-row .el-col .dialog-row-label{
        text-align: right;
    }
</style>