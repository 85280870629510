/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function getRecommendList(params){
    return request({
        url: 'promotion/recommend/list.do?',
        method: 'post',
        data:params
    })
}
export function deleteData(params){
    return request({
        url: 'promotion/recommend/delete.do?',
        method: 'post',
        data:params
    })
}

//newRew.vue 
export function recommendSave(params){
    return request({
        url: 'promotion/recommend/save.do?',
        method: 'post',
        data:params
    })
}

export function getAgentList(params){
    return request({
        url: 'agent/list4agent.do?',
        method: 'post',
        data:params
    })
}
export function getMealListByAgentId(params){
    return request({
        url: 'promotion/coupons/getMealListByAgentId.do?',
        method: 'post',
        data:params
    })
}

//pimOrder.vue
export function pinList(params){
    return request({
        url: 'agent/pin/list.do?',
        method: 'post',
        data:params
    })
}
export function updateDiscount(params){
    return request({
        url: 'agent/pin/updateDiscount.do?',
        method: 'post',
        data:params
    })
}
export function switchPin(params){
    return request({
        url: 'agent/pin/switchPin.do?',
        method: 'post',
        data:params
    })
}