<template>
  <div class="add">
      <el-form  v-model="setForm" label-position="right" label-width="90px" style="max-height:500px;overflow:auto">
            <el-form-item label="ICCID:">
                <el-input v-model="setForm.iccidMark"></el-input>
            </el-form-item>
            <el-form-item label="联系人:">
                <el-input v-model="setForm.contacts"></el-input>
            </el-form-item>
            <el-form-item label="手机号:">
                <el-input v-model="setForm.telephone"></el-input>
            </el-form-item>
            <el-form-item label="风险:">
                <el-select v-model="setForm.riskType">
                    <el-option label="高风险" value="1"></el-option>
                    <el-option label="普通" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="客诉类型:">
                <el-select v-model="setForm.type">
                    <el-option v-for="(item,index) in typeList" :value="item.value" :label="item.name" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="渠道:">
                <el-select v-model="setForm.channel">
                    <el-option label="终端用户" value="1"></el-option>
                    <el-option label="客服" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="投诉内容:">
                <el-input type="textarea" autosize v-model="setForm.problem"></el-input>
            </el-form-item>
            <el-form-item label="回复内容:">
                <el-input type="textarea" autosize v-model="setForm.reply"></el-input>
            </el-form-item>
            <el-form-item label="备注:">
                <el-input type="textarea" autosize v-model="setForm.remark"></el-input>
            </el-form-item>
        </el-form>  
        <div class="align-right">
            <el-button type="primary" class="themed-button" @click="sure">确定</el-button>
            <el-button type="primary" class="themed-button" @click="close">取消</el-button>
        </div>
  </div>
</template>

<script type="text/ecmascript-6">
import {complaintAdd} from "@/api/cardManagement/complaintsList.js"
import { messageBox, confirmBox } from "@/utils/common.js"

export default {
    data(){
        return {
            setForm:{
                iccidMark:'',
                contacts:'',
                telephone:'',
                riskType:'2',
                type:'1',
                channel:'1',
                problem:'',
                reply:'',
                remark:'',
                status:'0'
            },
            typeList:[
                {name:'无法使用',value:'1'},
                {name:'套餐过期',value:'2'},
                {name:'套餐用超',value:'3'},
                {name:'换设备',value:'4'},
                {name:'不会使用',value:'5'},
                {name:'功能性问题',value:'6'},
                {name:'虚量大问题',value:'7'},
                {name:'限速问题',value:'8'},
                {name:'虚假宣传',value:'9'},
                {name:'无效投诉',value:'10'}
            ],
        }
    },
    methods:{
        close(){
            this.$emit('close')
        },
        sure(){
            if(this.setForm.telephone){
                let r = /^[0-9-]+$/
                if(!r.test(this.setForm.telephone)){
                    messageBox(this,'手机号格式不对')
                    return 
                }
            }
            complaintAdd(this.setForm).then((res)=>{
                messageBox(this,'添加成功')
                this.$emit('refresh')
                this.$emit('close')
            })
        }
    }



};
</script>
