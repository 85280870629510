<template>
  <div class="agencyReviewList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">代理商审核</span>
      <el-form
        inline
        ref="agencyReviewList"
        v-model="agencyReviewList"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item>
          <el-input
            v-model="agencyReviewList.agentName"
            placeholder="公司名称"
          ></el-input>
          <!-- <el-select
            v-model="agencyReviewList.agentId"
            placeholder="请选择代理商"
            filterable
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in accountList"
              :key="item.id"
              :label="item.accountName"
              :value="item.id"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="agencyReviewList.sale"
            placeholder="销售"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="agencyReviewList.mobile"
            placeholder="账号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="agentName" label="公司名称" min-width="180">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createDatetime"
          label="创建时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="type"
          label="类型"
          min-width="150"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">TOB</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="status"
          label="状态"
          min-width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">未审核</span>
            <span v-if="scope.row.status == 1">已审核</span>
            <span v-if="scope.row.status == 2">审核未完成</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="sale"
          label="销售"
          min-width="120"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="mobile"
          label="账号"
          min-width="150"
        >
        </el-table-column>
        <!-- <el-table-column
          show-overflow-tooltip
          prop="bussinessName"
          label="企业名称"
          min-width="120"
        >
        </el-table-column> -->
        <el-table-column
          show-overflow-tooltip
          prop=""
          label="企业资质"
          min-width="120"
        >
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.authFlag.toString() == '' &&
                scope.row.corAuthFlag.toString() == ''
              "
              >未提交</span
            >
            <span
              v-else-if="
                scope.row.authFlag.toString() == 0 &&
                scope.row.corAuthFlag.toString() == 0
              "
              >已提交</span
            >
            <span
              v-else-if="
                scope.row.authFlag.toString() == 1 &&
                scope.row.corAuthFlag.toString() == 1
              "
              >已审核</span
            >
            <span
              v-else-if="
                scope.row.authFlag.toString() == 2 ||
                scope.row.corAuthFlag.toString() == 2
              "
              >审核未完成</span
            >
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.authFlag.toString() === '0' ||
                scope.row.corAuthFlag.toString() === '0'
              "
              class="themed-button"
              type="primary"
              @click="review(scope.row)"
              >审核</el-button
            >
            <el-button
              type="danger"
              class="themed-button"
              @click="removeList(scope.row.id)"
              >删除</el-button
            >
            <el-button
              class="themed-button"
              type="primary"
              @click="edit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      title="审核"
      :visible.sync="dialogReview"
      v-if="dialogReview"
      width="600"
      :before-close="handleClose"
    >
      <review-operate
        :reviewData="reviewList"
        @close="refresh"
      ></review-operate>
    </el-dialog>
    <el-dialog
      title="删除"
      :visible.sync="dialogDelete"
      v-if="dialogDelete"
      @close="closeDialog"
    >
      <el-form label-position="left" label-width="100px" :model="ruleForm">
        <el-form-item label="手机验证码" required>
          <el-input
            v-model="ruleForm.phoneCode"
            style="width: 250px"
          ></el-input>
          <el-button
            class="rightEl"
            @click="getPhoneCode"
            :disabled="disButton"
            >{{ butMsg }}</el-button
          >
        </el-form-item>
        <p style="color: #f00">
          *删除后将关联删除代理商，账号等信息，无法恢复，确认删除吗?
        </p>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="themed-button" @click="sure"
          >确定</el-button
        >
        <el-button type="primary" class="themed-button" @click="closeDialog"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="编辑"
      :visible.sync="dialogEdit"
      v-if="dialogEdit"
      @close="closeDialogEdit"
    >
      <el-form label-position="left" label-width="100px" :model="editForm">
        <el-form-item label="销售:">
          <el-select v-model="editForm.sale" class="search-select">
            <el-option
              v-for="item in inviterList"
              :key="item.id"
              :label="item.saleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业信息:" prop="industryInfo">
          <el-cascader
            v-model="editForm.industryInfo"
            :options="options"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="themed-button" @click="editSure"
          >确定</el-button
        >
        <el-button type="primary" class="themed-button" @click="closeDialogEdit"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { messageBox } from "@/utils/common.js";
import ReviewOperate from "./reviewOperate.vue";
import {
  getAccountList,
  deleteAgentReviewInfo,
  getAgencyReviewList,
  getPhoneCode,
  getSaleList,
  updateSale,
  getBussinessInfo,
  getTobCompanyInfo
} from "@/api/agentManagement.js";
import resize from "@/mixins/resize.js";
export default {
  name: "agencyReviewList",
  mixins: [resize],
  data() {
    return {
      dialogEdit: false,
      editForm: {
        sale: "",
        industryInfo: "",
      },
      accountList: [],
      agencyReviewList: {
        agentId: "",
        sale: "",
        mobile: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      dialogDelete: false,
      ruleForm: {
        agentId: "",
        phoneCode: "",
      },
      disButton: false,
      time: 120,
      timeInterval: "",
      butMsg: "获取验证码",
      dialogReview: false,
      reviewList: "",
      options: [],
      inviterList: [],
      currentID:"",
    };
  },
  created() {
    this.getOperatorList(); //获取代理商列表
    this.getAgencyReviewList(); //获取代理商审核列表
     this.getProfessionalInfo();
    
    this.getSaleList()
  },
  methods: { 
   
    getProfessionalInfo() {
      //获取行业信息  给this.options 赋值；
      getBussinessInfo().then((res) => {
        console.log(res);
        this.options = res;
      });
    },

    getSaleList() {
      getSaleList({}).then((res) => {
        console.log(res);
        this.inviterList = res;
      });
    },
    edit(row) {
      this.dialogEdit= true
      getTobCompanyInfo({ agentId: row.id }).then((res) => {
        if(res.length>0){
           try{
          this.editForm.industryInfo = JSON.parse(res[0].professionalInfo);
        }catch(error){
          this.editForm.industryInfo =(res[0].professionalInfo);
        }
        }else{
          this.editForm.industryInfo=""
        }
       
         this.editForm.sale = row.saleId
         this.currentID = row.id
        
       
      });

    },
    closeDialogEdit() {
      this.dialogEdit = false
    },
    editSure() {
        if(!this.editForm.sale){
         this.$alert('请选择销售人员', '提示', {
								confirmButtonText: '确定',
							});
              return 
      }
      let params = {
        agentId:this.currentID,
        saleId:this.editForm.sale,
        professionalInfo:JSON.stringify(
            this.editForm.industryInfo
          ),
      }
    updateSale(params).then(res=>{
      this.getAgencyReviewList();
      this.dialogEdit = false
      this.$alert('修改成功', '提示', {
								confirmButtonText: '确定',
							});
    })
    },

    handleClose(done) {
      done();
      this.getAgencyReviewList();
    },
    refresh() {
      this.dialogReview = false;
      this.getAgencyReviewList();
    },
    review(row) {
      this.dialogReview = true;
      this.reviewList = row;
    },
    getPhoneCode() {
      getPhoneCode().then((res) => {
        //获取验证码
        this.disButton = true;
        this.timeInterval = setInterval(() => {
          this.time--;
          console.log(this.time);
          this.butMsg = "重新获取验证码(" + this.time + ")";
          if (this.time == 0) {
            this.disButton = false;
            clearInterval(this.timeInterval);
            this.time = 120;
            this.butMsg = "获取验证码";
          }
        }, 1000);
      });
    },
    getOperatorList() {
      getAccountList().then((res) => {
        this.accountList = res;
      });
    },
    removeList(id) {
      this.dialogDelete = true;
      this.ruleForm.agentId = id;
    },
    sure() {
      if (!this.ruleForm.phoneCode) {
        this.$alert("请输入验证码", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      deleteAgentReviewInfo(this.ruleForm)
        .then((res) => {
          if (res.system_result_key == "0") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.dialogDelete = false;
            this.ruleForm.phoneCode = "";
            this.getAgencyReviewList();
          } else {
            this.$message({
              showClose: true,
              message: res.system_result_message_key,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAgencyReviewList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.agencyReviewList, params);
      getAgencyReviewList(this.agencyReviewList).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onSearch() {
      this.page = 1;
      this.getAgencyReviewList();
    },

    onPageChange(page) {
      this.page = page;
      this.getAgencyReviewList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getAgencyReviewList();
    },
    closeDialog() {
      this.dialogDelete = false;
      this.ruleForm.phoneCode = "";
    },
  },
  components: {
    ReviewOperate,
  },
};
</script>
<style>
.agencyReviewList .el-form-item__label {
  vertical-align: top;
}
.agencyReviewList .dialog-row-label {
  text-align: right;
}
</style>