<template>
  <div class="filterShow">
      <div class="content-header">
          <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addTicket">新增折扣规则</el-button>
      </div>
      <div class="content-body">
        <el-table ref="table"  :data="tableData" style="width:100%">
            <el-table-column label="iccid限制" prop="filterIccid" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐限制" prop="filterMeal" show-overflow-tooltip></el-table-column>
            <el-table-column label="套餐名" prop="filterMealName" show-overflow-tooltip></el-table-column>
            <el-table-column label="生效时间" prop="startTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.startTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="失效时间" prop="endTime" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{formatDatebox(scope.row.endTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="90px">
                <template slot-scope="scope">
                    <a href="javascript:void(0)" @click="delFilter(scope.row.id)">删除</a>
                </template>
            </el-table-column>
        </el-table>
    </div>

    <el-dialog title="新增规则" :visible.sync="dialogBox" append-to-body>
        <dialog-show :promotionId="id" @close="dialogBox = false" @refresh="refresh"></dialog-show>
    </el-dialog>
    
  </div>
</template>

<script type="text/ecmascript-6">
import { listRuleById,delShowFilterById } from "@/api/eventDiscountManagement/discountManagement.js";

import { messageBox, confirmBox } from "@/utils/common.js";
import SearchSelect from "@/components/SearchSelect";

import DialogShow from '@/views/eventDiscountManagement/discountManagement/dialogShow'

export default {
    props:['id'],
    data(){
        return {
            tableData:[],
            dialogBox:false
        }
    },
    mounted(){
        this._listRuleById()
    },
    methods:{
        addTicket(){
            this.dialogBox = true
        },
        delFilter(id){
            delShowFilterById({id}).then((res)=>{
                this._listRuleById()
            })
        },
        refresh(){
            this._listRuleById()
        },
        formatDatebox(value){
            if (value == null || value == '') {  
                return '';  
            }  
            var dt;  
            if (value instanceof Date) {  
                dt = value;  
            } else {  
                dt = new Date(value);  
            }  
            return  dt.toJSON().substr(0, 19).replace('T', ' ')
        },
        _listRuleById(){
            let params = {id:this.id}
            listRuleById(params).then((res)=>{
                if(res.app_result_key == 1){
                    messageBox(this,res.system_result_key)
                    return
                }
                this.tableData = res.couponsShowList
            })
        }
    },
    components:{
        DialogShow
    }
};
</script>
<style>
    .content-header{margin-bottom:15px}
</style>
