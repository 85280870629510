<template>
  <div class="order-bill-apply fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">发票列表</span>
      <el-form
        inline
        ref="billApplyForm"
        v-model="billApplyData"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="状态:">
          <el-select v-model="billApplyData.status">
            <el-option label="全部" value=""></el-option>
            <el-option label="未开票" value="0"></el-option>
            <el-option label="已开票" value="1"></el-option>
            <el-option label="不开票" value="2"></el-option>
            <el-option label="待作废" value="3"></el-option>
            <el-option label="已作废" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="billApplyData.orderNumber"
            placeholder="订单号"
            class="iccid-width"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="billApplyData.phone"
            placeholder="收票人手机"
            class="iccid-width"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="billApplyData.unitName"
            placeholder="单位名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="发票类型:">
          <el-select v-model="billApplyData.billType">
            <el-option label="请选择发票类型" value=""></el-option>
            <el-option label="普通发票" value="0"></el-option>
            <el-option label="增值发票" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            style="width: 140px"
            placeholder="开始时间"
            v-model="billApplyData.startDateStr"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            style="width: 140px"
            placeholder="结束时间"
            v-model="billApplyData.endDateDateStr"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-download"
            class="themed-button"
            type="info"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="themed-button" @click="emailSet"
            >邮箱设置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column label="订单号" min-width="180">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              type="primary"
              @click="toWeChartOrder(scope.row.orderNumber)"
              >{{ scope.row.orderNumber }}</el-link
            >
          </template>
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="iccidMark"
          label="卡号"
          min-width="180"
        >
        </el-table-column>
         <el-table-column
          show-overflow-tooltip
          prop="personName"
          label="个人名称"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="merchantName"
          label="商户号名称"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="orderCount"
          label="订单数量"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="phone"
          label="收票人手机"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="mail"
          label="收票人邮箱"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="unitName"
          label="单位名称"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="regPhone"
          label="注册电话"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="address"
          label="注册地址"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="taxNo"
          label="纳税人识别号"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="bank"
          label="开户银行"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="bankCode"
          label="银行账号"
          min-width="120"
        >
        </el-table-column>
        <el-table-column label="类型" min-width="80">
          <template slot-scope="scop">
            <span v-if="scop.row.type == 1">个人</span>
            <span v-else-if="scop.row.type == 2">单位</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="发票类型" min-width="100">
          <template slot-scope="scop">
            <span v-if="scop.row.billType == 1">增值发票</span>
            <span v-else-if="scop.row.type == 0">普通发票</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="创建时间"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="money"
          label="开票金额"
          min-width="120"
        >
        </el-table-column>
        <el-table-column label="状态" min-width="120">
          <template slot-scope="scop">
            <span v-if="scop.row.status == 0">未开票</span>
            <span v-else-if="scop.row.status == 1">已开票</span>
            <span v-else-if="scop.row.status == 2">不开票</span>
            <span v-else-if="scop.row.status == 3">待作废</span>
            <span v-else-if="scop.row.status == 4">已作废</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="reason"
          label="备注"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="billInfo"
          label="发票抬头"
          min-width="120"
        >
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 1"
              size="mini"
              class="themed-button"
              type="primary"
              @click="handleShowDetail(scope.row.loadUrl)"
              >详情</el-button
            >
            <el-button
              v-if="scope.row.status == 0"
              size="mini"
              class="themed-button"
              type="primary"
              @click="kaiPiao(scope.row.id)"
              >开票</el-button
            >
            <el-button
              v-if="scope.row.status == 0"
              size="mini"
              class="themed-button"
              type="danger"
              @click="weiKaiPao(scope.row.id)"
              >未开票</el-button
            >
            <el-button
              v-if="scope.row.status == 3"
              size="mini"
              class="themed-button"
              type="danger"
              @click="zuofei(scope.row.id)"
              >作废</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>

    <el-dialog
      title="详情"
      :visible.sync="dialogDetail"
      :destroy-on-close="true"
    >
      <el-form :model="DetailForm">
        <el-form-item label="发票地址" prop="loadUrl">
          <el-input
            v-model="DetailForm.loadUrl"
            autocomplete="off"
            readonly
          ></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="开票"
      :visible.sync="dialogKaiPiao"
      :destroy-on-close="true"
    >
      <el-form :model="kaiPiaoForm" ref="kaiPiaoForm">
        <el-form-item label="发票地址" prop="loadUrl">
          <el-input v-model="kaiPiaoForm.loadUrl" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="primary"
          @click="surekaiPiao('kaiPiaoForm')"
          >确 定</el-button
        >
        <el-button
          class="themed-button"
          type="info"
          @click="dialogKaiPiao = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="未开票"
      :visible.sync="dialogWeiKaiPiao"
      :destroy-on-close="true"
    >
      <el-form :model="weiKaiPiaoForm" ref="weiKaiPiaoForm">
        <el-form-item label="未开票理由" prop="reason">
          <el-input type="textarea" v-model="weiKaiPiaoForm.reason"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="primary"
          @click="sureWeiKaiPiao('weiKaiPiaoForm')"
          >确 定</el-button
        >
        <el-button
          class="themed-button"
          type="info"
          @click="dialogWeiKaiPiao = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="邮箱设置"
      :visible.sync="emailDialogVisible"
      width="30%"
      v-if="emailDialogVisible"
    >
      <el-form :model="emailForm" ref="emailForm" label-width="80px">
        <el-form-item label="邮箱类型" prop="emailType">
          <el-select v-model="emailForm.emailType" placeholder="请选择"  @change="changeEmailType">
            <el-option
              v-for="item in emailTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱地址" prop="email">
          <el-input v-model="emailForm.email"></el-input>
          <br><p style="color:#f00;">最多可以设置三个邮箱，邮箱之间用英文逗号","隔开</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="emailDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="emailSureSet">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getConfigurationModify,
  setConfigurationModify,
} from "@/api/systemManagement.js";
import resize from "@/mixins/resize.js";
import {
  billApplyList,
  exportBillApply,
  handleBill,
  disableBill,
} from "@/api/order.js";
export default {
  name: "billApply",
  mixins: [resize],
  data() {
    return {
      map:null,
        emailTypeList:[],
      emailDialogVisible: false,
      emailForm: {
        emailType: "",
        email: "",
      },
      billApplyData: {
        status: "",
        unitName: "",
        startDateStr: "",
        endDateDateStr: "",
        orderNumber: "",
        phone: "",
        billType: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      dialogDetail: false,
      DetailForm: {
        loadUrl: "",
      },
      dialogKaiPiao: false,
      kaiPiaoForm: {
        id: "",
        loadUrl: "",
        type: "2",
      },
      dialogWeiKaiPiao: false,
      weiKaiPiaoForm: {
        id: "",
        reason: "",
        type: "1",
      },
    };
  },
  created() {
    this.getBillApplyList();
    this.getEmailType();
  },
  methods: {
    changeEmailType(e){
     console.log(e,455)
    this.emailForm.email = this.map.get(e);
    },
    getEmailType(){
      this.emailTypeList=[]
getConfigurationModify({group:"INVOICE_INFORM"}).then(res=>{
   console.log(res,448)
   this.map = new Map();
        if (!!res) {
          res.forEach((item) => {
            this.map.set(item.dictKey, item.dictValue);
          });
        }
   res.forEach(item => {
    if(item.dictKey){
       this.emailTypeList.push({label:item.groupName,value:item.dictKey})
    }
   });
   
})
    },
    emailSureSet() {
      let key = this.emailForm.emailType
      let params = {
        group:"INVOICE_INFORM",
      }
      params[key] = this.emailForm.email
      setConfigurationModify(params).then(res=>{
       console.log(res,465)
        this.$message({
              showClose: true,
              message: "邮箱设置成功",
              type: "success",
              center: true,
            });
       this.emailDialogVisible= false
       this.getEmailType()
      })   
    },
    emailSet() {
      this.emailDialogVisible = true;
      this.emailForm.emailType = ""
      this.emailForm.email = ""
       
    },
    toWeChartOrder(orderNumbers) {
      this.$router.push({
        path: "../orderManagement/weixinOrder",
        query: { orderNumbers },
      });
    },
    getBillApplyList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(this.billApplyData, params);
      billApplyList(this.billApplyData).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onPageChange(page) {
      this.page = page;
      this.getBillApplyList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getBillApplyList();
    },
    onSearch() {
      this.page = 1;
      this.getBillApplyList();
    },
    exportExcel() {
      exportBillApply(this.billApplyData).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    handleShowDetail(url) {
      this.dialogDetail = true;
      this.DetailForm.loadUrl = url;
    },
    zuofei(id) {
      this.$confirm("确定此操作吗？", "消息提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          disableBill({ id: id }).then((res) => {
            this.$message({
              showClose: true,
              message: "作废成功",
              type: "success",
              center: true,
            });

            this.getBillApplyList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    kaiPiao(id) {
      this.dialogKaiPiao = true;
      this.kaiPiaoForm.id = id;
    },
    surekaiPiao(formName) {
      this.$confirm("确定此操作吗？", "消息提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              handleBill(this.kaiPiaoForm).then((res) => {
                this.$message({
                  showClose: true,
                  message: "开票成功",
                  type: "success",
                  center: true,
                });
                this.$refs[formName].resetFields();
                this.dialogKaiPiao = false;
                this.getBillApplyList();
              });
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    weiKaiPao(id) {
      this.dialogWeiKaiPiao = true;
      this.weiKaiPiaoForm.id = id;
    },
    sureWeiKaiPiao(formName) {
      this.$confirm("确定此操作吗？", "消息提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              handleBill(this.weiKaiPiaoForm).then((res) => {
                this.$message({
                  showClose: true,
                  message: "备注成功",
                  type: "success",
                  center: true,
                });
                this.$refs[formName].resetFields();
                this.dialogWeiKaiPiao = false;
                this.getBillApplyList();
              });
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style>
.order-bill-apply .el-form-item__label {
  vertical-align: top;
}
</style>