<template>
    <div class="fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">异常虚拟卡</span>
            <el-form inline ref="form" v-model="form" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="IMEI:">
                    <el-input v-model="form.imei" class="iccid-width"></el-input>
                </el-form-item>

                 <el-form-item label="虚拟ICCID:">
                    <el-input v-model="form.virtualIccid" class="iccid-width"></el-input>
                 </el-form-item>
                  <el-form-item label="短号:">
                    <el-input v-model="form.shortIccid" ></el-input>
                 </el-form-item>

                 <el-form-item label="chipId:">
                    <el-input v-model="form.chipId" class="iccid-width"></el-input>
                 </el-form-item>

                 <el-form-item label="异常类型">
                    <el-select v-model="form.type">
                        <el-option value="" label="全部">全部</el-option>
                        <el-option value="1" label="IMEI异常">IMEI异常</el-option>
                        <el-option value="2" label="chipid异常">chipid异常</el-option>
                        <el-option value="3" label="贴片卡异常">贴片卡异常</el-option>
                        <el-option value="4" label=" 接口调用异常">接口调用异常</el-option>
                        <el-option value="5" label=" 长时间未激活">长时间未激活</el-option>
                    </el-select>
			    </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
                    <el-button type="primary" icon="el-icon-download" class="themed-button" @click="exportExcel">导出excel</el-button>
                </el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                 show-overflow-tooltip
                    prop="imei"
                    label="IMEI"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="virtualIccid"
                    label="虚拟号"
                    min-width="120"
                    >
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    prop="shortIccid"
                    label="短号"
                    min-width="120"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="chipId"
                    label="chipId"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="iccid1"
                    label="ESIM1"
                    min-width="230"
                    >
                     <template slot-scope="scope">
                    {{getOperate(scope.row.iccid1)}}
                </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="iccid2"
                    label="ESIM2"
                    min-width="230"
                    >
                     <template slot-scope="scope">
                    {{getOperate(scope.row.iccid2)}}
                </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="iccid3"
                    label="ESIM3"
                    min-width="120"
                    >
                     <template slot-scope="scope">
                    {{getOperate(scope.row.iccid3)}}
                </template>
                </el-table-column>
                  <el-table-column
                    show-overflow-tooltip
                    prop="iccid4"
                    label="USIM1"
                    min-width="120"
                    >
                     <template slot-scope="scope">
                    {{getOperate(scope.row.iccid4)}}
                </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="type"
                    label="异常类型"
                    min-width="150"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==1">IMEI异常</span>
                        <span v-else-if="scope.row.type==2">chipid异常</span>
                        <span v-else-if="scope.row.type==3">贴片卡异常</span>
                        <span v-else-if="scope.row.type==4"> 接口调用异常</span>
                        <span v-else-if="scope.row.type==5"> 长时间未激活</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="details"
                    label="异常详情"
                    min-width="150"
                    >
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="创建时间"
                    min-width="150"
                    >
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    prop="updateTime"
                    label="更新时间"
                    min-width="150"
                    >
                </el-table-column>

                <el-table-column
                    label="操作"
                    min-width="120">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {virtualCardException,virtualCardExceptionDelete,virtualCardExceptionExport} from "@/api/virtualCard.js"
export default {
    name:'virtualException',
    mixins:[resize],
    data(){
        return {
            form:{
                imei:'',
                virtualIccid:'',
                chipId:'',
                type:'',
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getVirtualExceptList() 
    },
    methods: {
           getOperate(iccid){
            if( iccid.length>=19){

            }
        let str = iccid.substring(0,6)
        let operator="广电"
            if(str=="898600"||str=="898602"||str=="898604"||str=="898607" ||str=="898608"){
              operator ="移动"
            }
            if(str=="898601"||str=="898606"||str=="898609"){
              operator ="联通"
            }
            if(str=="898603"||str=="898611"){
              operator ="电信"
            }
            if(!!iccid &&iccid.length>=19){
            return iccid+"("+operator+")"
            }else{
             return ""
            }
            
        },
        getVirtualExceptList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.form,params)
            virtualCardException(this.form).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getVirtualExceptList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getVirtualExceptList()
        },

        onSearch(){
            this.page=1
            this.getVirtualExceptList()
        },

         exportExcel(){
            virtualCardExceptionExport(this.form).then(res=>{
                if (res.system_result_key == '0') {
                    this.$alert('导出成功，请到导出记录中下载', '提示', {
                        confirmButtonText:'确定',
                    }).then(()=>{
                        this.$router.push('/tob/i_export_list')
                    });
                }else{
                    this.$message({
                        showClose: true,
                        message: res.system_result_message_key,
                        type: 'error'
                    });
                }
            })
        },

        handleDelete(id){
            this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
                    virtualCardExceptionDelete({'id':id}).then(res=>{
                        this.$message({
								type: 'success',
								message:'删除成功'
                            });
                        this.getVirtualExceptList()
                    })
                    
                })
        }  
    },
}
</script>

<style>
.el-form-item__label {
		vertical-align: top;
	}
</style>