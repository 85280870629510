<template>
  <div class="goodsDistribution fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">商品分销</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item>
          <el-select
            v-model="formInline.agentId"
            placeholder="请选择代理商"
            filterable
            @change="getGoodsDistrListByAgentId"
          >
            <el-option label="请选择代理商" value=""></el-option>
            <el-option
              v-for="(item, index) in agentLists"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="accountType"
          :label-width="formLabelWidth"
          placeholder="请选择运营商"
        >
          <el-select v-model="formInline.accountType" @change="changeAccount">
            <el-option label="请选择运营商" value=""></el-option>
            <el-option
              v-for="(item, index) in categorys"
              :label="item.name"
              :value="item.value"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="display:none">
          <el-select
            v-model="formInline.productId"
            placeholder="请选择商品"
            filterable
          >
            <el-option label="请选择商品" value=""></el-option>
            <el-option
              v-for="item in goodsList"
              :key="item.id"
              :label="item.productName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        
        <el-table-column prop="productName" label="商品名称" min-width="180">
        </el-table-column>
        <el-table-column prop="defaultFee" label="默认金额" min-width="120">
        </el-table-column>
        <el-table-column prop="fee" label="金额" min-width="120">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.fee"
              @change="
                (val) => {
                  updateSort(val, scope.row, 1);
                }
              "
            ></el-input>
           
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="hide"
          label="是否隐藏"
          min-width="120"
        >
         
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.hide"
              @change="
                (val) => {
                  updateSort(val, scope.row, 2);
                }
              "
            >
              <el-option label="是" value="true"></el-option>
              <el-option label="否" value="false"></el-option>
            </el-select>
            
          </template>
        </el-table-column>
       
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

   
    </div>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import { mapGetters } from "vuex";
import {
  getGoodList,
  distributionGoodinfoList,
  distributionGoodinfo,
  cancelDistributionGoodinfo,
  updateDistributionGoodinfo,
} from "@/api/goodList.js";
import { getAgentList } from "@/api/agentManagement.js";
import Loading from '@/utils/Loading.js'
// import {
//   getAccountList,
// } from "@/api/agentManagement.js";
export default {
  name: "goodList",
  mixins: [resize],
  data() {
    
    return {
      formInline: {
        agentId: "",
        productId: "",
        accountType: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      goodsList: [],
      agentLists: [],
      dialogBox: false,
      formLabelWidth: "120px",
      // formDis: {
      //   id: "",
      //   agentId: "",
      //   productId: "",
      //   fee: "",
      //   hide: false,
      //   accountType: "",
      // },
      // dialogTitle: "",
      // rules: {
      //   agentId: [
      //     { required: true, message: "请选择代理商", trigger: "change" },
      //   ],
      //   accountType: [
      //     { required: true, message: "请选择运营商", trigger: "change" },
      //   ],
      //   productId: [
      //     { required: true, message: "请选择分销商品", trigger: "change" },
      //   ],
      //   fee: [{ validator: validateFee, trigger: "blur" }],
      // },
    };
  },
  computed: {
    ...mapGetters(["categorys"]),
  },
  created() {
    if (store.getters.categorys.length < 1) {
      store.dispatch("getCategory");
    }
    this.getAgentList();
    this.getGoodList();
    // this.getGoodsDistrList();
    // this.getGoodsDistrListByAgentId();
  },
  methods: {
    updateSort(val, row, num) {
      if(num==1){
     if (val === "") {
        return;
      } else {
        if (!/^\d+(\.\d{0,2})?$/.test(val)) {
          this.$alert("分销费用只能为数字且保留两位小数", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        
        if(val>99999){
            this.$alert("分销费用不能大于99999", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      }
      }
      Loading.show();
      let postdata = {
        // id: row.id,
        // agentId: row.agentId,
        // productId: row.productId,
      };
     
    if(row.id){
      postdata.id = row.id
    }else{
      postdata.agentId = this.formInline.agentId
      postdata.productId = row.productId
    }
      
      let parms = {};
      if (num == 1) {
        parms.fee = val;
      } else if (num == 2) {
        parms.hide = val;
      }
      Object.assign(parms, postdata);
      if(row.id){
      updateDistributionGoodinfo(parms)
        .then((res) => {
          this.getGoodsDistrListByAgentId();
          Loading.hide();
        })
        .catch((res) => {
          // messageBox(this,'请求错误','操作繁忙请稍后重试或联系客服。')
        });
      }else{
       distributionGoodinfo(parms)
        .then((res) => {
          this.getGoodsDistrListByAgentId();
          Loading.hide();
        })
        .catch((res) => {
          // messageBox(this,'请求错误','操作繁忙请稍后重试或联系客服。')
        }); 
      }
      
    },
    getGoodsDistrListByAgentId() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };

      Object.assign(this.formInline, params);
      distributionGoodinfoList(this.formInline).then((res) => {
        this.tableData = res.rows;
        this.tableData.forEach(item=>{
          item.hide = item.hide+""
        })
        this.total = res.count;
      });
    },
    changeAccount(val) {
      this.goodsList = [];
      getGoodList({ accountType: val }).then((res) => {
        this.goodsList = res.rows;
      });
    },
    delect(id) {
      this.$confirm("是否确认取消分销该商品?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelDistributionGoodinfo({ id }).then((res) => {
          this.getGoodsDistrList();
          this.$message({
            type: "success",
            message: "取消分销成功",
          });
        });
      });
    },
    importModel(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formDis.id) {
            updateDistributionGoodinfo(this.formDis).then((res) => {
              this.$message({
                type: "success",
                message: "编辑成功",
              });
              this.dialogBox = false;
              this.getGoodsDistrList();
            });
          } else {
            distributionGoodinfo(this.formDis).then((res) => {
              this.$message({
                type: "success",
                message: "分销成功",
              });
              this.dialogBox = false;
              this.getGoodsDistrList();
            });
          }
        } else {
          return false;
        }
      });
    },
    getGoodsDistrList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };

      Object.assign(this.formInline, params);
      distributionGoodinfoList(this.formInline).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    edit(data) {
      this.dialogTitle = "编辑分销商品";
      this.dialogBox = true;
      this.goodsList = [];
      getGoodList({}).then((res) => {
        this.goodsList = res.rows;
        this.formDis.id = data.id;
        this.formDis.agentId = data.agentId;
        this.formDis.productId = data.productId;
        this.formDis.fee = data.fee;
        this.formDis.hide = data.hide;
      });
    },
    getAgentList() {
      getAgentList({ type: 2 }).then((res) => {
        this.agentLists = res.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      }); //弹框分销
    },

    searchData() {
      this.page = 1;
      this.getGoodsDistrListByAgentId();
    },
    getGoodList() {
      getGoodList().then((res) => {
        this.goodsList = [];
        this.goodsList = res.rows;
      });
    },
    onPageChange(page) {
      this.page = page;
      this.getGoodsDistrListByAgentId();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getGoodsDistrListByAgentId();
    },
    add() {
      //分销
      this.dialogBox = true;
      this.formDis.id = "";
      this.formDis.agentId = "";
      this.formDis.productId = "";
      this.formDis.fee = "";
      this.formDis.hide = false;
      this.dialogTitle = "商品分销";
      this.formDis.accountType = "";
      this.$nextTick(() => {
        this.$refs["ruleFormDis"].clearValidate();
      });
    },
  },
};
</script>

<style>
</style>
