import request from './request.js'
export function autoRechargeSetmeal(params) {//列表
    return request({
        url: "/card/autoRechargeSetmeal/list.do?",
        method: 'post',
        data: params
    })
}

export function delAutoRechargeSetmeal(params){//删除
    return request({
        url: '/card/autoRechargeSetmeal/delete.do?',
        method: 'post',
        data:params
    })
}

export function exportAutoRechargeSetmeal(params) {//导出
    return request({
        url: "/card/autoRechargeSetmeal/exportSync.do?",
        method: 'post',
        data: params
    })
}

export function importAutoRechargeSetmeal(params){//导入
    return request({
        url: '/card/autoRechargeSetmeal/importAddSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function deleteAutoRechargeSetmeal(params){//删除
    return request({
        url: '/card/autoRechargeSetmeal/importSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}