<template>
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <!-- <el-row :gutter="40">
        <el-col :span="20" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              ref="queryParams"
              v-model="queryParams"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top: 15px"
            >
              <el-form-item label="" :label-width="formLabelWidth">
                <treeselect
                  v-model="queryParams.agentId"
                  :options="agentOptions"
                  :normalizer="normalizer"
                  :show-count="true"
                  placeholder="请选择代理商"
                />
              </el-form-item>
              <el-form-item style="margin-right: 40px">
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  @click="screenDate"
                  >筛选</el-button
                >
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth">
                <el-input
                  placeholder="设备号"
                  v-model="queryParams.imei"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row> -->
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        :max-height="maxTableHeight"
        style="width: 100%"
      >
        <el-table-column prop="name" label="名称" min-width="180">
        </el-table-column>
        <el-table-column prop="successAmount" label="成功数量" min-width="120">
        </el-table-column>
        <el-table-column prop="failAmount" label="失败数量" min-width="120">
        </el-table-column>
        <el-table-column prop="state" label="导入状态" min-width="180">
          <template slot-scope="scope">
            <el-tag :type="scope.row.state == '1' ? 'warning' : scope.row.state == '2'?'danger' :'success' ">{{
              scope.row.state === "1"
                ? "正在处理"
                : scope.row.state === "2"
                ? "处理完成（部分失败）"
                : "处理完成（全部成功）"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="导入时间" min-width="180">
        </el-table-column>
        <el-table-column prop="createBy" label="上传人员" min-width="180">
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button  v-if="scope.row.state == '2'"
              type="primary"
              class="themed-button"
              @click="download(scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {
  importRecordPageList,
  importRecordDownload,
} from "@/api/deviceAssignment/historyList.js";
export default {
  name: "materialsList",
  mixins:[resize],
  components: { Treeselect },
  data() {
    return {
      addForm: {
        radioLable: 0,
        imeiArray: "",
        gyAgentId: undefined,
        gyAgentName: "",
      },
      tableData: [],
      agentOptions: [],
      dialogFormVisible: false,
      queryParams: {
        imei: "",
        agentId: undefined,
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
      dialogTitle: "新增分配",
    };
  },
  created() {
    this.getDeviceList();
  },
  methods: {
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams, params);
      importRecordPageList(this.queryParams).then((res) => {
        console.log(res,7878787);
        this.tableData = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },
    download(row) {
      importRecordDownload({ recordId: row.id }).then((res) => {
        const filename = "导入失败列表" + "_" + new Date().getTime() + ".xlsx";
        Utils.createDownload(res, filename);
      });
    },
    /** 搜索 */
    searchData() {
      this.page = 1;
      this.getDeviceList();
    },
    /** 筛选 */
    screenDate() {
      this.page = 1;
      this.getDeviceList();
    },
    /** 删除 */
    delect(id) {
      this.$confirm("是否确认删除该项分配数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delDevice(id).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getDeviceList();
        });
      });
    },
    /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 100px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>