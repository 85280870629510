import request from './../request.js'

// 列表渲染以及查询 /dev-api/iot/importRecord/pageList
export function importRecordPageList(query) {
  return request({
    url: '/iot/importRecord/pageList.do?',
    method: 'get',
    params: query
  })
}
// 下载文件模板/dev-api/iot/importRecord/download
  export function importRecordDownload(query){
    return request({
      url: '/iot/importRecord/download.do?',
      method: 'get',
      params: query,
      responseType: 'blob'
    })
  }
