<template>

<div class="pin-order-set table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">折扣返佣配置</span>
        <el-form inline class="hook right themed-form search-form">   
                <el-form-item>
					<el-button icon="el-icon-circle-plus" class="themed-button" type="info" @click="add()">新增</el-button>
				</el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="ID" prop="id" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="套餐金额" prop="money" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="拼单折扣" prop="discount" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="推广折扣" prop="shareDiscount" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="返佣金额" prop="rebateMoney" show-overflow-tooltip min-width="120"></el-table-column>
            <el-table-column label="更新时间" prop="updateTime" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="更新人" prop="updateBy" show-overflow-tooltip min-width="150"></el-table-column>
            <el-table-column label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" @click="handleUpdate(scope.row.id)">更新</el-button>
                    <el-button type="danger" class="themed-button" @click="handleDelete(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog title="更新" :visible.sync="dialogVisible" width="500px">
        <el-form label-position="right" label-width="100px" :model="editForm" ref="editForm">
            <el-form-item label="ID">
				<el-input v-model="editForm.id" disabled></el-input>
			</el-form-item>
            <el-form-item label="套餐金额">
				<el-input v-model="editForm.money" disabled></el-input>
			</el-form-item>
            <el-form-item label="拼单折扣">
				<el-input v-model="editForm.discount"></el-input>
			</el-form-item>
             <el-form-item label="推广折扣">
				<el-input v-model="editForm.shareDiscount"></el-input>
			</el-form-item>
            <el-form-item label="返佣金额">
				<el-input v-model="editForm.rebateMoney"></el-input>
			</el-form-item>
            <el-form-item label="操作时间:">
                <el-date-picker
                    v-model="editForm.updateTime"
                    type="date"
                    value-format="yyyy-MM-dd" style="width:100%" disabled>
                </el-date-picker>
            </el-form-item>
            <el-form-item label="操作人">
				<el-input v-model="editForm.updateBy" disabled></el-input>
			</el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="handleSubmit">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogVisible = false">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog title="新增" :visible.sync="dialogAddVisible" width="500px">
        <el-form label-position="right" label-width="100px" :model="addForm" :rules="addRules" ref="addForm">
            <el-form-item label="套餐金额" required prop="money">
				<el-input v-model="addForm.money"></el-input>
			</el-form-item>
            <el-form-item label="拼单折扣" required prop="discount">
				<el-input v-model="addForm.discount"></el-input>
			</el-form-item>
            <el-form-item label="推广折扣" required prop="shareDiscount">
				<el-input v-model="addForm.shareDiscount"></el-input>
			</el-form-item>
            <el-form-item label="返佣金额" required prop="rebateMoney">
				<el-input v-model="addForm.rebateMoney"></el-input>
			</el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="handleAdd('addForm')">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogAddVisible = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {pinOrderDiscountList,getPinDiscountDetail,delPinDiscountById,updatePinDiscount,addPinDiscount} from '@/api/eventDiscountManagement/pinDiscountSetting.js'
    import {messageBox,confirmBox } from '@/utils/common.js'
    import resize from '@/mixins/resize.js'

    export default {
        name:'pinOrderSet',
        mixins:[resize],
        data(){
            return {
                dialogVisible:false,
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
                editForm:{
                    id:'',
                    money:'',
                    discount:'',
                    shareDiscount:'',
                    rebateMoney:'',
                    updateTime:'',
                    updateBy:'',
                },
                dialogAddVisible:false,
                addForm:{
                    money:'',
                    discount:'',
                    shareDiscount:'',
                    rebateMoney:'',
                },
                addRules:{
                    money:[
                        { required: true, message: '套餐金额不能为空', trigger: 'blur' }
                    ],
                    discount:[
                        { required: true, message: '拼单折扣不能为空', trigger: 'blur' }
                    ],
                    shareDiscount:[
                        { required: true, message: '推广折扣不能为空', trigger: 'blur' }
                    ],
                    rebateMoney:[
                        { required: true, message: '返佣金额不能为空', trigger: 'blur' }
                    ]
                },
            }
        },
        mounted(){
            this.getList()
        },
        methods:{
            getList(){
                let params = {
                    pageSize:this.pageSize,
                    pageNo:this.currentPage
                }
                pinOrderDiscountList(params).then((res)=>{
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            },

            add(){
                this.resetFrom()
                this.dialogAddVisible = true
            },

            handleAdd(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(parseFloat(this.addForm.discount)<0||parseFloat(this.addForm.discount)>1){
                            messageBox(this,"拼单折扣值在0~1之间")
                            return;
                        }
                        if(parseFloat(this.addForm.shareDiscount)<0||parseFloat(this.addForm.shareDiscount)>1){
                            messageBox(this,"推广折扣值在0~1之间")
                            return;
                        }

                        addPinDiscount(this.addForm).then(res=>{
                            this.$message({
						    type: 'success',
						    message:'操作成功'
                            });
                            this.dialogAddVisible = false
					        this.getList()
                        })
                    } 
                });
                
            },

            resetFrom(){
                this.addForm.money = ''
                this.addForm.discount = ''
                this.addForm.shareDiscount = ''
                this.addForm.rebateMoney = ''
            },
            handleUpdate(id){
                getPinDiscountDetail({id}).then(res=>{
                    let ret = res.entity
                    this.editForm.id= ret.id
                    this.editForm.money = ret.money
                    this.editForm.discount = ret.discount
                    this.editForm.shareDiscount = ret.shareDiscount
                    this.editForm.rebateMoney = ret.rebateMoney
                    this.editForm.updateTime = ret.updateTime
                    this.editForm.updateBy= ret.updateBy
                })
                this.$nextTick(()=>{
                     this.dialogVisible = true
                }) 
            },

            handleDelete(id){
                this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delPinDiscountById({id}).then(res=>{
						this.$message({
							type: 'success',
							message:'删除成功'
                        });
                        this.getList()
					})
					
				})
            },
            
            handleSubmit(){
                if(parseFloat(this.editForm.discount)<0||parseFloat(this.editForm.discount)>1){
                    messageBox(this,"拼单折扣值在0~1之间")
                    return;
                }
                if(parseFloat(this.editForm.shareDiscount)<0||parseFloat(this.editForm.shareDiscount)>1){
                    messageBox(this,"推广折扣值在0~1之间")
                    return;
                }
                updatePinDiscount(this.editForm).then(res=>{
                    this.$message({
						type: 'success',
						message:'操作成功'
                    });
                    this.dialogVisible = false
					this.getList()
                })
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getList()
            },
        }
    }
</script>

