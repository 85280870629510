/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function cardlocation(params){
    return request({
        url: 'cardlocation/grid.do?',
        method: 'post',
        data:params
    })
}
export function limitExportCount(params){
    return request({
        url: 'cardlocation/exportExcelSync.do?',
        method: 'post',
        data:params
    })
}
export function cardStopClear(params){
    return request({
        url: 'cardlocation/cardStopClear.do?',
        method: 'post',
        data:params
    })
}

//wxLocation.vue
export function wxLocation(params){
    return request({
        url: 'location/list.do?',
        method: 'post',
        data:params
    })
}