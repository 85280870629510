<template>
    <div class="voice-card-order fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">语音卡订购清单</span>
            <el-form inline ref="voiceCardOrderForm" v-model="voiceCardOrderFormData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item label="订购状态">
                    <search-select :optionsMetaAll="statusOperators" v-model="voiceCardOrderFormData.successFlag"></search-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="voiceCardOrderFormData.iccidMark" placeholder="ICCID号" class="iccid-width"></el-input>
                </el-form-item>
                 <el-form-item>
                    <el-input v-model="voiceCardOrderFormData.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="voiceCardOrderFormData.phone" placeholder="接入号"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="voiceCardOrderFormData.vendorOrderNo" placeholder="第三方订单号"></el-input>
                </el-form-item>
                 
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="180"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号码"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="vendorMealName"
                    label="运营商套餐"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="vendorMealCode"
                    label="产品编号"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createDateStr"
                    label="订购时间"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="successFlagStr"
                    label="订购状态"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="apiResponse"
                    label="接口返回"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="vendorOrderNo"
                    label="第三方订单号"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <el-button v-if="(scope.row.successFlag!==0)||(scope.row.apiResp && scope.row.apiResponse !== '')" size="mini" class="themed-button" type="info" @click="handleDetail(scope.row.id,scope.row.apiResponse)">失败详情</el-button>
                    </template>
                </el-table-column>
                  
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        
        <el-dialog
            title="失败详情"
            :visible.sync="failDetailDialogVisible"
            width="500px"
            :destroy-on-close='true'
            >
            <span>{{failDetailMsg}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="primary" @click="failDetailDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue'
import resize from '@/mixins/resize.js'
import { voiceRechargeRecord ,exportVoiceOrder} from "@/api/history.js";
export default {
    name:'voiceCardOrder',
     components:{SearchSelect},
     mixins:[resize],
    data(){
        return {
            voiceCardOrderFormData:{
                successFlag:'',
                iccidMark:'',
                phone:'',
                vendorOrderNo:'',
                shortIccid:"",
            },
            statusOperators:[
                {label:"全部",value:""},
                {label:"成功",value:"0"},
                {label:"失败",value:"1"},
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            failDetailDialogVisible:false,
            failDetailMsg:''
        }
    },
    created(){
        this.getVoiceOrderList()
    },
    methods: {
        getVoiceOrderList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.voiceCardOrderFormData,params)
            voiceRechargeRecord(this.voiceCardOrderFormData).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        handleDetail(id,msg){
            this.failDetailDialogVisible= true
            this.failDetailMsg = msg
        },
        onPageChange(page){
			this.page = page
			this.getVoiceOrderList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getVoiceOrderList()
        },

        onSearch(){
            this.page=1
            this.getVoiceOrderList()
        },

        exportExcel(){
            exportVoiceOrder(this.voiceCardOrderFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
    },
}
</script>

<style>
    .voice-card-order .el-form-item__label{
        vertical-align: top;
    }
</style>