import {Layout} from '@/layout'

let routers = [
	// {
    //     path: '/orderManagement',
    //     component:Layout,
    //     children: [{
    //             path: 'orderDetail',
    //             meta: {
    //               isMenu:false,
    //              title: '基本信息',
    //             },
    //             component: () => import('@/views/orderManagement/orderDetail.vue')
    //         },
            
    //     ]
    //   },
]
export default routers