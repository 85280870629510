<template>
	<div class="skuUpdate">
		<el-form :model="formUpdateSku"  ref="ruleFormUpdateSku">
			<el-form-item label="选择excel文件：" :label-width="formLabelWidth" required>
				<input class="file-input" type="file" ref='fileImportInput' />
				<!-- <input type="file" id="fileurl" name="fileurl" size="80%"> -->
			</el-form-item>
			<el-form-item label="运营商：" :label-width="formLabelWidth"  required>
				<el-select v-model="formUpdateSku.accountList" @change="accountListChange">
					<el-option value="">请选择运营商</el-option>
					<el-option v-for="item in accountListArr" :key="item.id" :label="item.accountName" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="套 餐：" :label-width="formLabelWidth">
				<el-select v-model="formUpdateSku.setMealList" @change="setMealListChange"  filterable>
					<el-option value="">请选择套餐</el-option>
					<el-option v-for="item in setMealListArr" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer" style="text-align: right;">
			<el-button class="themed-button" type="info" @click="uploadImportExcel">导入excel模板</el-button>
			<el-button class="themed-button" type="info" @click="exportTemplateExcel('importPhone.xls')">导出excel模板
			</el-button>
			<el-button class="themed-button" type="primary" @click="cancelDialog()">取消</el-button>
		</div>
	</div>
</template>
<script>
	import {
		accountList,
		geMealList,
		importAgentSKU,
	} from "@/api/agentManagement.js";
	import loading from '@/utils/Loading.js'
	import Utils from '@/utils/utils.js'
	import {
		exportTemplate
	} from '@/api/statistics.js'
	import {messageBox,confirmBox} from '@/utils/common.js'
	export default {
		name:'importSku',
		data() {
			return {
				setMealListArr: [],
				accountListArr: [],
				formUpdateSku: {
					agentId: "",
					accountId: "",
					jihuotype: 2,
					accountList: "",
					setMealList: "",
				},
				formLabelWidth: "150px"
			};
		},
		created() {
			this.getAccountList(this.formUpdateSku);

		},
		methods: {
			initData(params) {
				agentSKUfindById(params).then((res) => {
					// this.formUpdateSku.accountId = res[0].accountId;
					this.formUpdateSku.orderNo = res[0].tbOrderNo;
					// this.formUpdateSku.mealId = res[0].mealId;
				});
			},
			getAccountList(params) {
				accountList(params).then((res) => {
					this.accountListArr = res;
				});
			},
			accountListChange() {
				this.formUpdateSku.accountId = this.formUpdateSku.accountList;
				if (this.formUpdateSku.accountList) {
					geMealList(this.formUpdateSku).then((res) => {
						this.setMealListArr = res;
						if (this.setMealListArr.length > 0) {
							this.setMealListArr = this.setMealListArr.find(function(item) {
								return item.type + "" != "11";
							});
						}
					});
				}
			},
			setMealListChange() {},
			cancelDialog() {
				this.$emit("close");
			},
			uploadImportExcel() {
				this.$confirm("确定导入吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						let file = this.$refs['fileImportInput'].files[0]
						if (file) {
							let fileName = file.name
							let index = fileName.lastIndexOf(".")
							if (index < 0 || !(fileName.substring(index + 1, fileName.length) == "xls" || fileName
									.substring(index + 1, fileName.length) == "xlsx")) {
								this.$alert('文件格式错误', '提示', {
									confirmButtonText: '确定',
								});
								return
							}else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                } else {
								let param = new FormData()
								if (!!this.formUpdateSku.accountList) {
									param.append("accountList", this.formUpdateSku.accountList)
								} else {
									this.$alert('请选择运营商', '提示', {
										confirmButtonText: '确定',
									});
									return
								}
								if (!!this.formUpdateSku.setMealList) {
									param.append("setMealList", this.formUpdateSku.setMealList)
								} else {
									this.$alert('请选择套餐', '提示', {
										confirmButtonText: '确定',
									});
									return
								}
								param.append("fileurl", file)
								importAgentSKU(param).then(res => {
									/* this.$message({
										type: 'success',
										message: '导入成功'
									});
									this.$emit("refresh")
									this.$emit("close") */
									/* this.getGiftPackageList()
									this.count = 0
									this.dialogImportByExcelForm = {}
									this.$refs['fileImportInput'].value = '' */

									if (res.system_result_key == '0') {
										this.$alert('导入成功，请到导入记录中下载', '提示', {
											confirmButtonText: '确定',
										}).then(() => {
											this.$emit("close");
											this.$router.push('/tob/i_import_list')
										});
									} else {
										this.$message({
											showClose: true,
											message: res.system_result_message_key,
											type: 'error'
										});
									}
								

								})
							}
						} else {
							this.$alert('请选择文件', '提示', {
								confirmButtonText: '确定',
							});
							return
						}
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消导入",
						});
					});
			},
			exportTemplateExcel(name) {
				exportTemplate({
					'name': name
				}).then(res => {
					const filename = name
					Utils.createDownload(res, filename)
				})
			},
			},
			}
</script>
