<template>
    <div class="single-card-limit-history fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">单卡限速历史记录列表</span>
            <el-form inline ref="searchForm" v-model="searchForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="searchForm.iccidMark" placeholder="iccid" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="searchForm.shortIccid" placeholder="短号" class="iccid-width"></el-input>
                </el-form-item>


                <el-form-item label="运营商">
                    <el-select v-model="searchForm.accountId" filterable placeholder="运营商">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                 <!-- <el-form-item label="代理商">
                    <el-select v-model="searchForm.agentId" placeholder="请选择" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in agentIdList" :key='item.index' :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item> -->
                  <el-form-item label="代理商">
                   <el-input v-model="searchForm.agentName" placeholder="代理商" class="iccid-width"></el-input>
                </el-form-item>

                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>   
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="130"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="accessNumber"
                    label="接入号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="agentName"
                    label="代理商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="卡状态"
                    min-width="120"
                    prop="cardStatusStr"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="speed"
                    label="限速值"
                    min-width="120"
                    >
                </el-table-column>
                  <el-table-column
                    show-overflow-tooltip
                    prop="deviceSpeedValue"
                    label="设备速率"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="限速规则"
                    prop="ruleTypeStr"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.limitRuleId"> {{scope.row.limitRuleName}} </span>
                        <span v-else>固定值</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="optTypeStr"
                    label="操作"
                    min-width="120"
                    >
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="操作时间"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="operator"
                    label="操作人"
                    min-width="120"
                    >
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {agentOperatorList} from '@/api/order.js'
import {getAgentList} from "@/api/agentManagement.js"
import {singleCardHistoryList,exportSingleCardHistory} from "@/api/speedLimit/index.js";
export default {
     mixins:[resize],
    data(){
        return {
            accountIdList:[],
            agentIdList:[],
            searchForm:{
                iccidMark:'',
                // agentId:'',
                accountId:'',
                agentName:"",
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
           
    },
    created(){
        this.getAgentOperatorList()
        this.getAgentList()
        this.getSingleCardHistoryList()
    },
    methods: {
        getAgentOperatorList(){
            agentOperatorList().then(res=>{
                this.accountIdList = res        
             })
        },
        getAgentList(){
             getAgentList().then(res=>{
				this.agentIdList = res
			})
        },
        getSingleCardHistoryList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.searchForm,params)
            singleCardHistoryList(this.searchForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },

        onPageChange(page){
			this.page = page
			this.getSingleCardHistoryList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSingleCardHistoryList()
        },

        onSearch(){
            this.page=1
            this.getSingleCardHistoryList()
        },

        exportExcel(){
            exportSingleCardHistory(this.searchForm).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },

    },
}
</script>

<style>
    .single-card-limit-history .el-form-item__label{
        vertical-align: top;
    }
</style>