<template>

<div class="patchCardList table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">贴片卡列表</span>
        <el-form inline class="hook right themed-form search-form"   v-model="form"  style="line-height: 40px;">
              <el-form-item label="库编号:">
                <el-select filterable v-model="form.imeiBankCode">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item,index) in imeiBankCodeList" :label="item.imeiBankCode" :value="item.imeiBankCode" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="IMEI:">
                <el-input v-model="form.imei"></el-input>
            </el-form-item>
            <el-form-item label="贴片卡:">
                <el-input v-model="form.iccid"></el-input>
            </el-form-item>
          
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchData">搜索查询</el-button>
            </el-form-item>
        
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="importAdd">新增</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-minus" class="themed-button" @click="importDelete">删除</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-back" class="themed-button" @click="exportExcel">批量导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
             <el-table-column label="库编号" prop="imeiBankCode" show-overflow-tooltip></el-table-column>
            <el-table-column label="imei" prop="imei" show-overflow-tooltip></el-table-column>
            <el-table-column label="贴片卡号" prop="iccid" show-overflow-tooltip></el-table-column>
            <el-table-column label="卡序号" prop="slotNumber" show-overflow-tooltip>
                <template slot-scope="scope">
                    第{{ scope.row.slotNumber }}卡槽
                </template>
            </el-table-column>
            <el-table-column label="添加方式" prop="addType" show-overflow-tooltip></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="更新时间" prop="updateTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="创建人" prop="createBy" show-overflow-tooltip></el-table-column>
            <el-table-column label="来源" prop="channel" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="客户" prop="agentName" show-overflow-tooltip style="display:none"></el-table-column> -->
            <el-table-column label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button type="info" class="themed-button" @click="deleteById(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogBox" v-if="dialogBox">
        <add-patch v-if="dialogContent == 'add-patch'"  @close="dialogBox=false" @close-dialog='dialogBox=false'   @refresh="refesh"></add-patch>
        <delete-patch v-if="dialogContent == 'delete'" @close="dialogBox=false" @close-dialog='dialogBox=false' ></delete-patch> 
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {deleteByIdPatch,listPatch,exportPatchSync,imeiBankCode} from '@/api/meCardBindManagement/patchCardList.js'
    import {messageBox,confirmBox } from '@/utils/common.js'
    import resize from '@/mixins/resize.js'
    import AddPatch from '@/views/meCardBindManagement/patchCardList/addPatch'
    import DeletePatch from '@/views/meCardBindManagement/patchCardList/deletePatch'

    export default {
        // name:'imeiList',
        mixins:[resize],
        data(){
            return {
                form:{
                    imei:'',
                    imeiBankCode:'',
                    chipid:''
                },
                imeiBankCodeList:[],
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,

                dialogBox:false,
                dialogTitle:'',
                dialogContent:'',
                id:''
            }
        },
        mounted(){
            this._imeiBankCode()
            this.patchCardList()
        },
        methods:{
            importAdd(){
                this.dialogTitle = '新增贴片卡'
                this.dialogContent = 'add-patch'
                this.dialogBox = true
            },
            importDelete(){
                this.dialogTitle = '删除贴片卡'
                this.dialogContent = 'delete'
                this.dialogBox = true
            },
           
            deleteById(data){
                let msg = "IMEI：" + data.imei + "</br>" + "IMEI库编号：" + data.imeiBankCode + "</br>" + "绑定卡：" + data.jsonUpdateFlag + "</br><br>设备和卡片关系将一起删除。</br>是否确认删除?"
                confirmBox(this,msg,'操作提示',()=>{
                    deleteByIdPatch(Object.assign({id:data.id},this.form)).then((res)=>{
                        messageBox(this,'删除成功')
                        this.patchCardList()
                    })
                })
            },
            exportExcel(){
                exportPatchSync(this.form).then((res)=>{
                    if (res.system_result_key == '0') {
                        this.$alert('导出成功，请到导出记录中下载', '提示', {
                            confirmButtonText:'确定',
                        }).then(()=>{
                            this.$router.push('/tob/i_export_list')
                        });
                    }else{
                        this.$message({
                            showClose: true,
                            message: res.system_result_message_key,
                            type: 'error'
                        });
                    }
                })
            },
            searchData(){
                this.currentPage =1
                this.patchCardList()
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.patchCardList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.patchCardList()
            },
            _imeiBankCode(){
                imeiBankCode().then((res)=>{
                    this.imeiBankCodeList = res
                })
            },
            patchCardList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                listPatch(Object.assign(params,this.form)).then((res)=>{
                    this.total = res.total
                    // 处理日期并添加更新时间
                    this.tableData = res.rows.map(row => ({
                        ...row,
                        createTime: this.formatDate(row.createTime),
                        updateTime: row.updateTime ? this.formatDate(row.updateTime) : this.formatDate(row.createTime)
                    }))
                })
            },
            formatDate(dateString) {
                const date = new Date(dateString);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            },
            refesh(){
                 this.patchCardList()
            }
        },
        components:{
            AddPatch,
            DeletePatch,
        }
    }
</script>

