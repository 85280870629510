<template>
	<div class="payEnsure">
		<el-form :model="formInline" :rules="rules" ref="payEnsureFrom">
			<el-form-item label="提现编号:" required :label-width="formLabelWidth">
				<el-input readonly v-model="formInline.rebateNo"></el-input>
			</el-form-item>
			<el-form-item label="付款金额:"  required :label-width="formLabelWidth" prop='payMoney'>
				<el-input v-model="formInline.payMoney"></el-input>
			</el-form-item>
			<el-form-item label="代理商:"  required :label-width="formLabelWidth" prop='agentName'>
				<el-input readonly v-model="formInline.agentName"></el-input>
			</el-form-item>
			<el-form-item label="支付方式:"  required :label-width="formLabelWidth" prop='payType'>
				<el-select v-model="formInline.payType" placeholder="请选择支付方式" @change="changePayType">
                        <el-option label="微信" value="1"></el-option>
                        <el-option label="充值至准备金" value="3"></el-option>
                        <el-option label="支付宝" value="4"></el-option>
						<el-option label="银行卡" value="5"></el-option>
						<el-option label="其他" value="6"></el-option>
                </el-select>
			</el-form-item>
			<el-form-item v-if="payType=='1'" label="付款商户号:" required :label-width="formLabelWidth">
				<el-select v-model="formInline.mchId" filterable placeholder="">
				    <el-option
				      v-for="item in options"
				      :key="item.mchId"
				      :label="item.name"
				      :value="item.mchId">
				    </el-option>
				  </el-select>
			</el-form-item>
			<el-form-item v-if="payType=='1'" label="收款微信:" required :label-width="formLabelWidth" >
				<!-- <el-input v-model="formInline.nickname" readonly></el-input> -->
				<el-select v-model="formInline.openId" filterable placeholder="">
				    <el-option
				      v-for="item in agentWxList"
				      :key="item.id"
				      :label="item.name"
				      :value="item.id">
				    </el-option>
				  </el-select>
			</el-form-item>
			<el-form-item v-if="payType=='4'" label="支付宝账号:" required :label-width="formLabelWidth" >
				<el-input v-model="formInline.zhifubao" readonly></el-input>
			</el-form-item>
			<el-form-item v-if="payType=='4'" label="支付宝名:" required :label-width="formLabelWidth" >
				<el-input v-model="formInline.zhifubaoReceiver" readonly></el-input>
			</el-form-item>
			<el-form-item v-if="payType=='5'" label="收款行:" required :label-width="formLabelWidth" >
				<el-input v-model="formInline.bank" readonly></el-input>
			</el-form-item>
			<el-form-item v-if="payType=='5'" label="账号:" required :label-width="formLabelWidth" >
				<el-input v-model="formInline.bankCode" readonly></el-input>
			</el-form-item>
			<el-form-item v-if="payType=='5'" label="收款人:" required :label-width="formLabelWidth">
				<el-input readonly v-model="formInline.bankReceiver"></el-input>
			</el-form-item>
			<el-form-item label="身份证:" required :label-width="formLabelWidth">
				<el-input readonly v-model="formInline.idCardNo"></el-input>
			</el-form-item>
			<el-form-item label="上传凭证："  :label-width="formLabelWidth">
				 <input name="proof" id="proof" style="width: 60%;" ref="fileInput"  class="filebox" type="file"  @change="fileChange">
			</el-form-item>
			<el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input type="textarea" autosize v-model="formInline.selfRemark"></el-input>
            </el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="baseFormSubmit('payEnsureFrom')" class="themed-button">确 定</el-button>
		</div>
	</div>
</template>

<script>
	import {findById,merchantList,uploadFile,rebateEnsure,agentBandWx} from "@/api/payManagment.js"
	export default{
		name:'payEnsure',
		props:["payId"],
		data(){
			return {
				options:[],
				agentWxList:[],
				form:{
					payId: "",
					certPath: 0,
					mchId: "",
					
				},
				leftRebateMoney:"",
				formInline:{
					payId:'',
					rebateNo:'',
					payMoney:'',
					agentName:'',
					payType:'',
					idCardNo:'',
					openId:'',
					mchId:'',
					certPath:'',
					zhifubao:'',
					zhifubaoReceiver:'',
					bank:'',
					bankCode:'',
					bankReceiver:'',
					selfRemark:'',
				},
				id:"",
				payTypeMap:{"1":"微信","2":"线下","3":"准备金"},
				formLabelWidth:"150px",
				payType:"",
				rules:{
                    payMoney:[
						{ required: true, message: '不能为空', trigger: 'blur' },
                    ],
					payType:[
                        { required: true, message: '不能为空', trigger: 'blur' }
					],	
				}
			}
		},
		created(){
			this.id = this.payId
			let params = {
				id:this.id
			}
			Object.assign(params,this.form)
			this.getFindById(params)
			this.getMchList(params)
		},
		methods:{
			getFindById(params){
				findById(params).then(res=>{
					this.formInline.payId = res.id
					this.formInline.rebateNo = res.rebateNo
					this.formInline.agentName = res.agentName
					this.formInline.payType = res.payType.toString()
					this.formInline.idCardNo = res.idCardNo
					this.formInline.openId = res.openId
					this.formInline.mchId = res.mchId
					this.formInline.payMoney = res.leftRebateMoney
					this.leftRebateMoney = res.leftRebateMoney
					this.formInline.zhifubao = res.zhifubao
					this.formInline.zhifubaoReceiver = res.zhifubaoReceiver
					this.formInline.bank = res.bank
					this.formInline.bankCode = res.bankCode
					this.formInline.bankReceiver = res.bankReceiver
					this.payType = res.payType.toString()
					this.formInline.selfRemark = res.selfRemark
					let agentId = res.agentId
					this.getAgentWxList(agentId)
				})
			},
			getAgentWxList(agentId){
				agentBandWx({agentId}).then(res=>{
					this.agentWxList = res
				})
			},

			getMchList(params){
				merchantList(params).then(res=>{
					this.options = res.rows
				})	
			},
			baseFormSubmit(formName){
				this.$confirm('确定付款吗？','消息提示',{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
            	}).then(()=>{
                	this.$refs[formName].validate((valid) => {
                	if (valid) {
						if(parseFloat(this.formInline.payMoney) < 1 || parseFloat(this.formInline.payMoney) > parseFloat((this.leftRebateMoney))){
							this.$alert("付款金额必须在1至剩余返利金额之间", "消息提示")
							return;
						}else{
							rebateEnsure(this.formInline).then(res=>{
							if (res.slide_msg_key == 1) {
								this.$alert(res.slide_msg_message_key, "消息提示")
								return;
							}
								this.$emit("reload")
								this.$emit("close")
							})
						}
					} else {
						console.log('error submit!!');
						return false;
					}
					});
				}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
				});
        	},
			changePayType(val){
				this.payType = val
				console.log(this.payType,126)
			},
			fileChange() {
				let formData = new FormData();
				formData.append("file", this.$refs['fileInput'].files[0]);
				uploadFile(formData).then(res => {
					//console.log(res.filePath)
					if(res.app_result_key === '0'){
						this.form.certPath = res.filePath
						this.formInline.certPath = res.filePath
					}else{
						this.form.certPath = ""
						this.formInline.certPath = ""
					}
					
					
				})
			},
			
		},
		
	}
</script>

<style>
	.payEnsure .dialog-footer{
		text-align: right;
	}
</style>
