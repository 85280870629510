<template>
  <div class="systemParams table-hook fadeInRight">
    <div class="title-box">
      <span class="m-title">参数列表</span>
      <el-form
        inline
        class="hook right themed-form search-form"
        style="line-height: 40px" 
      >
       <el-form-item>
          <el-input
            v-model="searchIteam.searchKey"
            placeholder="关键字搜索"
          ></el-input>
        </el-form-item>
        <el-form-item>
						<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索</el-button>
					</el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="themed-button"
            @click="addParam"
            >新增参数</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        :data="tableData"
        stripe
        border
        :max-height="maxTableHeight"
      >
        <el-table-column
          label="参数名"
          prop="dictKey"
          width="300px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="参数值" prop="dictValue"  width="300px" show-overflow-tooltip>
        <template slot-scope="scope">
            <el-popover
              placement="bottom"
              title=""
              width="300"
              trigger="click" > 
              <span>{{scope.row.dictValue}}</span>
              <el-button class="themed-button" type="primary" slot="reference">点击查看详细参数值</el-button>
            </el-popover>
          </template>   
        </el-table-column>
        <el-table-column
          label="参数说明"
          prop="remark"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="info"
              class="themed-button"
              @click="updateParamRecord(scope.row)"
              >编辑</el-button
            >
            <el-button class="themed-button" type="danger" @click="deleteParams(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog :title="title" :visible.sync="dialogBox" v-if="dialogBox">
      <el-form
        label-position="right"
        label-width="120px"
        :model="form"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="参数名" prop="dictKey">
          <el-input v-model="form.dictKey" :disabled="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="参数值" prop="dictValue">
          <el-input v-model="form.dictValue"></el-input>
        </el-form-item>
        <el-form-item label="参数说明" prop="remark">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <div style="color:#f00;margin-left:120px"> <span v-if="!isEdit">*前端参数名以FRONT_CONFIG_开头;</span><br><span>参数值如果是bool类型，0代表false  1代表true</span></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="themed-button" @click="onSure"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  getParamsList,
  editParams,
  addParams,
  deleParams
} from "@/api/systemManagement.js"
import { messageBox, confirmBox } from "@/utils/common.js";
import resize from "@/mixins/resize.js";

export default {
  name: "systemParams",
  mixins: [resize],
  data() {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 15,
      total: 0,
      dialogBox: false,
      form: {
        id: "",
        dictKey: "",
        dictValue: "",
        remark: "",
      },
      title: "",
      rules: {
        dictKey: [{ required: true, message: "请写参数名", trigger: "blur" }],
        dictValue: [
          { required: true, message: "请写参数值", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请填写参数说明", trigger: "blur" },
        ],
      },
      searchIteam:{
         searchKey:""
      },
      isEdit:false,
    };
  },

  mounted() {
    this.getParamsList();
  },
  methods: {
    addParam() {
      this.title = "新增参数";
      this.dialogBox = true;
      this.isEdit = false
       this.form.id = ""
      this.form.dictKey = ""
      this.form.dictValue = ""
      this.form.remark = ""
    },
    updateParamRecord(row) {
      this.title = "编辑参数";
      this.dialogBox = true;
      this.form.id = row.id; //
      this.form.dictKey = row.dictKey;
      this.form.dictValue = row.dictValue;
      this.form.remark = row.remark;
      this.isEdit = true
    },
    onSure() {
      if (this.form.id) {
        this.$refs["ruleForm"].validate((validate) => {
          if (validate) {
            editParams(this.form).then((res) => {
              this.$message({
                type: "success",
                message: "编辑参数成功",
              });
              this.$refs["ruleForm"].resetFields();
              this.dialogBox = false;
              this.getParamsList();
            });
          }
        });
      } else {
        this.$refs["ruleForm"].validate((validate) => {
          if (validate) {
            let params = {
              dictKey: this.form.dictKey,
              dictValue: this.form.dictValue,
              remark: this.form.remark,
            };
            addParams(params).then((res) => {
              this.$message({
                type: "success",
                message: "新增参数成功",
              });
              this.$refs["ruleForm"].resetFields();
              this.dialogBox = false;
              this.getParamsList();
            });
          }
        });
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getParamsList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getParamsList();
    },
    getParamsList() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
       searchKey: this.searchIteam.searchKey
      };
      getParamsList(params).then((res) => {
        console.log(res)
        this.pageNo = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
        this.tableData = res.rows;
      });
    },
    deleteParams(id){
    this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleParams({id}).then(res=>{
						this.$message({
							type: 'success',
							message:'删除成功'
						});
					})
					this.getParamsList()
				})
    },
    onSearch(){
				this.pageNo = 1
				this.getParamsList()
			},
  },
};
</script>
