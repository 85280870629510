<template>
    <div class="history-card-active fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">网卡激活记录</span>
            <el-form inline ref="cardActiveForm" v-model="cardActiveData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                 <el-form-item>
                    <el-input v-model="cardActiveData.iccidMark" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="cardActiveData.phone" placeholder="接入号"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号"
                    min-width="140"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createDate"
                    label="操作时间"
                    min-width="160"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createUserName"
                    label="操作人"
                    min-width="140"
                    >  
                </el-table-column>
                <el-table-column
                    label="是否成功"
                    min-width="80"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSuccess==0">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="激活结果（接口返回）"
                    > 
                    <template slot-scope="scope">
                        <span v-if="scope.row.apiResult">{{scope.row.apiResult.replace(/<(?!img).*?>/g, "")}}</span>
                        <span v-else></span>
                    </template>  
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import { activeCard } from "@/api/history.js";
export default {
    name:'networkCardActivation',
    mixins:[resize],
    data(){
        return {
            cardActiveData:{
                iccidMark:'',
                phone:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getActiveCardList()
    },
    methods:{
        getActiveCardList(){
            let params ={
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.cardActiveData,params)
            activeCard(this.cardActiveData).then(res=>{
                this.tableData = res.rows.map(item=>{
                    item.createDate=Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(item.createDate))
                    return item
                })
                this.total=res.count
            })
        },
        onSearch(){
            this.page=1
            this.getActiveCardList()
        },
        exportExcel(){

        },
        handleSizeChange(size){
            this.pageSize=size
           this.getActiveCardList()
        },
        onPageChange(page){
            this.page=page
            this.getActiveCardList()
        },
    }
}
</script>