<template>
    <div class="history-delete-cardRecord fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">网卡删除记录</span>
            <el-form inline ref="deleteNICRecordForm" v-model="deleteNICRecordData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="导入起始日期" v-model="deleteNICRecordData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="导入结束日期" v-model="deleteNICRecordData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
                <el-form-item>
                    <el-input v-model="deleteNICRecordData.pageSearchKeys" placeholder="卡号" class="iccid-width"></el-input>
                </el-form-item>
               
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="删除时间"
                    >
                    <template slot-scope="scope">
                        <span>{{dateFormat(scope.row.deleteTime)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="adminUserName"
                    label="操作人"
                   >
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import resize from '@/mixins/resize.js'
import { deleteCardRecordList, exportDeleteCardRecord} from "@/api/history.js";
export default {
    name:'deleteNetworkCardRecord',
    mixins:[resize],
    data(){
        return {
            deleteNICRecordData:{
                startTime:'',
                endTime:'',
                pageSearchKeys:'',
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.getDeleteCardRecordList()
    },
    methods:{
        getDeleteCardRecordList(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.deleteNICRecordData,params)
            deleteCardRecordList(this.deleteNICRecordData).then(res=>{
                this.tableData=res.rows
                this.total= res.count
            })
        },
        handleSizeChange(size){
            this.pageSize=size
            this.getDeleteCardRecordList()
        },
        onPageChange(page){
            this.page = page
            this.getDeleteCardRecordList()
        },
        onSearch(){
            this.page=1
            this.getDeleteCardRecordList()
        },
        exportExcel(){
            exportDeleteCardRecord(this.deleteNICRecordData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        dateFormat(time){
            let date = new Date(time);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            let d = date.getDate();
            let hour = date.getHours(), min = date.getMinutes();
            return y + '-' + m + '-' + d + ' ' + hour + ':' + min;
        },
    }
}
</script>
<style >
    
</style>