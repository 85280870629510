<template>
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
        <el-col :span="18" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              ref="queryParams"
              class="demo-form-inline"
              :rules="rulesOne"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
              <el-form-item label="" :label-width="formLabelWidth" prop="gyAgentId">
                <treeselect
                  v-model="queryParams.gyAgentId"
                  :options="agentOptions"
                  :normalizer="normalizer"
                  :show-count="true"
                  placeholder="请选择代理商"
                />
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth" prop="name">
                <el-input
                  placeholder="配置名称"
                  v-model="queryParams.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth" prop="imei">
                <el-input
                  placeholder="IMEI"
                  v-model="queryParams.imei"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button  
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
        <el-col :span="4" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="add"
                  >新增</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        :max-height="maxTableHeight"
        tooltip-effect="dark"
        style="width: 100%"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <!-- <el-table-column prop="gyAgentId" label="代理商ID" min-width="100">
        </el-table-column> -->
         <el-table-column prop="gyAgent.agentName" label="代理商名称" min-width="100">
        </el-table-column>
        <el-table-column prop="name" label="配置名称" min-width="100">
        </el-table-column>
        <el-table-column prop="displayContentCustom" label="用户自定义数据" min-width="180">
        </el-table-column>
        <el-table-column prop="displayMode" label="显示类型" min-width="100">
        </el-table-column>
        <el-table-column prop="flowRateMult" label="速率倍数" min-width="200">
        </el-table-column>
        <el-table-column prop="state" label="状态" min-width="180">
          <template slot-scope="scope">
            <el-switch
            v-model="scope.row.state"
            active-value="1" inactive-value="0"
            @change="chg(scope.row)"
            :disabled="scope.row.state==1"
            >
           </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="updata(scope.row)"
              >修改</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="getManagementDetails(scope.row)"
              >管理详情</el-button
            >
            <el-button
              type="primary"
              class="themed-button"
              size="mini"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

      <el-dialog :title="title" :visible.sync="dialogFormVisible" width="40%">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="25%">
           <el-form-item label="代理商选择" prop="gyAgentId" v-show="currentRole==0">
           <treeselect style="width:70%"
                v-model="addForm.gyAgentId"
                :options="agentOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="请选择代理商"
                @select="treeSelectChange"
                :disabled="disabled"
              />
          </el-form-item>
           <el-form-item label="屏幕显示配置名称" prop="name" >
            <el-input v-model="addForm.name" style="width:70%"></el-input>
         </el-form-item>
         <el-form-item label="显示类型"  prop="displayMode">
        <el-select v-model="addForm.displayMode" placeholder="请选择显示类型" style="width:70%">
        <el-option
              v-for="item in ModeOptions"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
        </el-select>
        </el-form-item>
          <el-form-item label="速率倍数" prop="flowRateMult" v-if="addForm.displayMode == 4">
            <el-input v-model="addForm.flowRateMult" style="width:70%"></el-input>
         </el-form-item>
          <el-form-item label="用户自定义数据" prop="displayContentCustom" v-if="addForm.displayMode == 5">
          <el-input v-model="addForm.displayContentCustom" style="width:70%"></el-input>
         </el-form-item>
          <!-- <el-form-item label="营运商" prop="opShow">
          <el-input v-model="addForm.opShow" style="width:70%" ></el-input>
         </el-form-item> -->
       </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false"  type="info" class="themed-button">取 消</el-button>
    <el-button type="primary" @click="sureAdd" class="themed-button">确 定</el-button>
  </div>
</el-dialog>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { agentGetAllAgent } from "@/api/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {mapGetters} from 'vuex'
import {
  screenPageList,
  screenAdd,
  screenSave,
  qrcodeEnable,
  screenRemove
} from "@/api/screenSetting/screenSetting.js";
export default {
  name: "materialsList",
  mixins:[resize],
  components: { Treeselect },
  //mixins: [resize],
  data() {
    return {
     ModeOptions:[
        {
          label: "不显示",
          value: 1,
        },
        {
          label: "显示日期",
          value: 2,
        },
        {
          label: "设备本地统计流量",
          value: 3,
        },
         {
          label: "本地倍数流量",
          value: 4,
        },
         {
          label: "自定义文字",
          value: 5,
        },
         {
          label: "流量系统同步流量",
          value: 6,
        }
      ],
      formInline: {
        spec: "",
        material: "",
      },
      addForm: {
      opShow:""  
      },
      tableData: [],
      agentOptions: [],
      materialList: [],
      specList: [],
      dialogFormVisible: false,
      queryParams: {
        imei: "",
        name:'',
        gyAgentId: undefined,
        gyAgentName: undefined,
      },
      rules: {
        displayMode:[
          { required: true, message: '请选择显示类型', trigger: 'change' }
          ],
          flowRateMult:[{ required: true, message: "请输入速率倍数", trigger: "blur" },
          {required: true,pattern: /^([0-9]|10)(\.\d{2})?$/,message: "请输入0-10之间的整数或小数，小数位数保留两位",trigger: "blur"}
        ],
         displayContentCustom:[{ required: true, message: "请输入用户自定义数据", trigger: "blur" },
          {required: true,pattern:/^([A-Z]|[a-z]|[\d])*$/,message: "输入数据只能为英文和数字",trigger: "blur"},
          { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
        ],
        name: [{ required: true, message: "请输入屏幕显示配置名称", trigger: "blur" },
        { pattern:/^[\u4e00-\u9fa5_a-zA-Z0-9_.-]{1,32}$/ , message: "屏幕显示配置名称只能为1到32位,包含中文数字字母下划线点连接符", trigger: "blur" }
        ],
        opShow:[
          {pattern:/^([A-Z]|[a-z]|[\d])*$/,message: "输入数据只能为英文和数字",trigger: "blur"},
          { min: 1, max: 7, message: '长度在 1 到 7 个字符', trigger: 'blur' }
        ],
        },
         rulesOne: {
         imei:[{message: "请输入IMEI", trigger: "blur" },
         { pattern:/^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,8}/ , message: "运营商只能为1到8位,包含中文数字字母下划线和连接符", trigger: "blur" },
        ],
        },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
      title: "",
      disabled:false
    };
  },
  computed:{
         ...mapGetters(
             ['currentRole','agentTree']
         ),
     },
  created() {
    this.getDeviceList();
    this.getAllAgent();
  },
  methods: {
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.queryParams, params);
      screenPageList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.tableData.forEach((ele=>{
        this.ModeOptions.forEach((textele=>{
        if(textele.value==ele.displayMode){
         ele.displayMode =textele.label
          }
        }))
        }))
        this.total=res.total
        this.loading = false;
      });
    },
     /** 代理商数据 */
    getAllAgent() {
      agentGetAllAgent().then((response) => {
        this.agentOptions = response;
      });
    },
    /** 转换代理商数据结构 */
    normalizer(node) {
      if (
        node.children == null ||
        (node.children == "null" && node.children && !node.children.length)
      ) {
        delete node.children;
      }
      return {
        id: node.agentId,
        label: node.agentName,
        children: node.children,
      };
    },
     treeSelectChange(raw, instanceId) {
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        this.addForm.gyAgentId = raw.agentId;
        this.addForm.gyAgentName = raw.agentName;
      });
    },
    /** 搜索 */
    searchData() {
    this.$refs["queryParams"].validate((valid) => {
      if (valid) {
      this.page = 1;
      this.getDeviceList();
        }})
    },
    /** 筛选 */
    screenDate() {
      this.page = 1;
      this.getDeviceList();
    },
    /** 导出模板 */
    exportTemplateExcel(name) {
      deviceExport({ name: name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
     /** 重置表单 */
    reset() {
       this.addForm = {
       displayMode:undefined,
       flowRateMult:undefined,
       displayContentCustom:undefined,
       name:undefined,
       opShow:undefined
      };
      //this.resetForm("addForm");
    },
      /** 新增按钮弹出dialog */
    add() {
      this.reset()
      this.dialogFormVisible = true;
      this.title = "新增";
      this.disabled = false;
    },
    /** 修改按钮弹出dialog */
    updata(row) {
     console.log(row,'rowrow')
      this.addForm= JSON.parse(JSON.stringify(row))
       this.ModeOptions.forEach((item) => {
        console.log(item)
        if (this.addForm.displayMode == item.label) {
          this.$set(this.addForm, "displayMode", item.value)
        }
      })
      this.dialogFormVisible = true;
      this.title = "修改";
      this.disabled = true;
    },
    /** 提交 */
    sureAdd() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
        if (this.currentRole==0) {
        if (!!this.addForm.gyAgentId) {
        if (this.title != "新增") {
          if(this.addForm.displayMode!==4){
           this.addForm.flowRateMult=""
          }
          if(this.addForm.displayMode!==5){0
           this.addForm.displayContentCustom=""
          }
           screenSave(this.addForm).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          } else {
            screenAdd(this.addForm).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          }
            } else {
              this.$alert("请选择代理商", "提示", {
                confirmButtonText: "确定",
              });
              return;
            }
          }else{
        if (this.title != "新增") {
          if(this.addForm.displayMode!==4){
           this.addForm.flowRateMult=""
          }
          if(this.addForm.displayMode!==5){0
           this.addForm.displayContentCustom=""
          }
           screenSave(this.addForm).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          } else {
            screenAdd(this.addForm).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.dialogFormVisible = false;
              this.getDeviceList();
            });
          }
          }
        // if (this.title != "新增") {
        //   if(this.addForm.displayMode!==4){
        //    this.addForm.flowRateMult=""
        //   }
        //   if(this.addForm.displayMode!==5){0
        //    this.addForm.displayContentCustom=""
        //   }
        //    screenSave(this.addForm).then((response) => {
        //       this.$modal.msgSuccess("修改成功");
        //       this.dialogFormVisible = false;
        //       this.getDeviceList();
        //     });
        //   } else {
        //     screenAdd(this.addForm).then((response) => {
        //       this.$modal.msgSuccess("新增成功");
        //       this.dialogFormVisible = false;
        //       this.getDeviceList();
        //     });
        //   }
        }
      });
    },
    /** 删除 */
    delect(id) {
      this.$confirm("是否确认删除该项数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let queryFox = new FormData();
        queryFox.append("id", id);
        screenRemove(queryFox).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getDeviceList();
        });
      });
    },
     /** 关联跳转 */
    getManagementDetails(row){
       this.$router.push({
        path: "/equipmentManagement/screenDetails",
        query: { data: JSON.stringify(row) },
      });

    },
     chg(r){
      let text = r.active == "0" ? "未禁用" : "启用";
      this.$modal.confirm('是否' + text).then(function() {
      return qrcodeEnable({id:r.id,state:r.state});
      }).then(() => {
        this.$modal.msgSuccess(text + "成功");
        this.getDeviceList();
      }).catch(function() {
        r.state = r.state == "0" ? "1" : "0";
      });
    },
    /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
     handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 100px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>