<template>
  <div class="dictionaryManagement fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">字典管理</span>
      <el-form
        inline
        ref="dicSettingForm"
        v-model="dicSettingData"
        class="right themed-form search-form"
        style="height: 40px; line-height: 40px"
      >
        <el-form-item>
          <el-input
            v-model="dicSettingData.dictGroup"
            placeholder="字典组"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dicSettingData.groupName"
            placeholder="字典名称"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-circle-plus"
            class="themed-button"
            type="info"
            @click="add()"
            >新增字典</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        border
        stripe
        ref="table"
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="dictGroup"
          label="字典组"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="groupName"
          label="字典名称"
          min-width="120"
        >
          <!-- <template slot-scope="scope">
            <router-link
              :to="'/dict/type/data/' + scope.row.dictId"
              class="link-type"
            >
              <span>{{ scope.row.dictKey }}</span>
            </router-link>
          </template> -->
          <template slot-scope="scope">
            <a href="javascript:void(0)" @click="detailDictionary(scope.row.dictGroup)">{{
              scope.row.groupName
            }}</a>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="status"
          label="状态"
          min-width="150"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == '1'">正常</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="remark"
          label="备注"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          prop="createTime"
          label="创建时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="themed-button"
              type="info"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              class="themed-button"
              type="info"
              @click="detailDictionary(scope.row.dictGroup)"
              >列表</el-button
            >
            <el-button
              size="danger"
              class="themed-button"
              type="info"
              @click="deleteDict(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :current-page.sync="pageNo"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="字典组" prop="dictGroup">
          <el-input v-model="form.dictGroup" placeholder="请输入字典名称" />
        </el-form-item>
        <el-form-item label="字典名称" prop="groupName">
          <el-input v-model="form.groupName" placeholder="请输入字典类型" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in statusOptions"
              :key="dict.dictValue"
              :label="dict.dictValue"
              >{{ dict.dictLabel }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import {
  getDictManageList,
  delDictManageList,
  addDictManageList,
  editDictManageList,
} from "@/api/systemManagement.js";
import { messageBox } from "@/utils/common.js";
export default {
  name: "dictionaryManagement",
  mixins: [resize],
  data() {
    return {
      dicSettingData: {
        dictGroup: "",
        groupName: "",
      },
      pageNo: 1,
      pageSize: 15,
      tableData: [],
      total: 0,
      title: "",
      open: false,
      statusOptions: [
        { dictLabel: "正常", dictValue: "1" },
        { dictLabel: "停用", dictValue: "0" },
      ],
      form: {
        dictGroup: "",
        groupName: "",
        status: "1",
        remark: "",
        type: "0",
      },
      rules: {
        dictGroup: [
          { required: true, message: "字典组不能为空", trigger: "blur" },
        ],
        groupName: [
          { required: true, message: "字典名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getDictManageList();
  },
  methods: {
      detailDictionary(dictGroup){
      this.$router.push({
          path:"/systemManagement/dictionaryDetail",
          query:{dictGroup}
      })
      },
    reset() {
      this.form = {
        id: undefined,
        dictGroup: undefined,
        groupName: undefined,
        status: "1",
        remark: undefined,
        type: "0",
      };
      	if (this.$refs["form"]) {
		this.$refs["form"].resetFields();
	}
    },
    handleEdit(row) {
      this.reset();
      this.form.id = row.id;
      this.form.dictGroup = row.dictGroup;
      this.form.groupName = row.groupName;
      this.form.status = row.status;
      this.form.remark = row.remark;
      this.open = true;
      this.title = "修改字典组";
    },
    cancel() {
      this.open = false;
      this.reset();
    },
    add() {
      //添加字典
      this.reset();
      this.open = true;
      this.title = "新增字典组";
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            let params = {
              id: this.form.id,
              dictGroup: this.form.dictGroup,
              groupName: this.form.groupName,
              status: this.form.status,
              remark: this.form.remark,
              type: this.form.type,
            };
            editDictManageList(params).then(res => {
              messageBox(this, "修改成功")
              this.open = false
              this.getDictManageList()
            });
          } else {
              let params = {
              dictGroup: this.form.dictGroup,
              groupName: this.form.groupName,
              status: this.form.status,
              remark: this.form.remark,
              type: this.form.type,
              }
            addDictManageList(params).then(res => {
              messageBox(this, "新增成功")
              this.open = false
              this.getDictManageList()
            });
          }
        }
      });
    },
    getDictManageList() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        type: "0",
      };
      Object.assign(params, this.dicSettingData);
      getDictManageList(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onSearch() {
      this.pageNo = 1;
      this.getDictManageList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDictManageList();
    },
    onPageChange(page) {
      this.pageNo = page;
      this.getDictManageList();
    },
    deleteDict(id) {
      this.$confirm('是否确认删除字典编号为"' + id + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delDictManageList({ id }).then((res) => {
          this.getDictManageList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
};
</script>
