<template>
    <div class="customer-inventory fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">客户库存统计</span>
            <el-form inline ref="customerInventoryForm" v-model="customerInventoryData" class="right themed-form search-form" style="height: 40px;line-height: 40px;">
                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="agentName"
                    label="客户名称"
                    min-width="150">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="fromGuangyao3"
                    :label="fromGuangyao3"
                    min-width="250">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="fromGuangyao2"
                    :label="fromGuangyao2"
                    min-width="250">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="fromGuangyao1"
                    :label="fromGuangyao1"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="giveOut4"
                    label="过去第4个月发卡数"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="giveOut3"
                    label="过去第3个月发卡数"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="giveOut2"
                    label="过去第2个月发卡数"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="giveOut1"
                    label="过去第1个月发卡数"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="active3"
                    label="过去第3个月激活卡数"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="active2"
                    label="过去第2个月激活卡数"
                    min-width="250">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="active1"
                    label="过去第1个月激活卡数"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rate4"
                    label="过去第4个月发卡激活率"
                    min-width="250">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rate3"
                    label="过去第3个月发卡激活率"
                    min-width="250">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rate2"
                    label="过去第2个月发卡激活率"
                    min-width="250">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rate1"
                    label="过去第1个月发卡激活率"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="rateSeason"
                    label="过去一个季度的发卡的激活率"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="stockAmountLastMonth"
                    label="最近一个月末的库存"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="expendTime"
                    label="剩余卡能消耗的月份"
                    min-width="250">
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    label="风险"
                    min-width="120">
                    <template slot-scope="scope">
                        <span v-if='scope.row.risk.indexOf("高危险")!= -1' style='color:red'>{{scope.row.risk}}</span>
                        <span v-else-if='scope.row.risk.indexOf("中危险")!= -1' style='color:red'>{{scope.row.risk}}</span>
                        <span v-else>{{scope.row.risk}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                :show-overflow-tooltip="true"
                    prop="operator"
                    label="操作人"
                    min-width="120">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="createTime"
                    label="统计时间"
                    min-width="150">
                </el-table-column>
                <el-table-column
                :show-overflow-tooltip="true"
                    prop="remark"
                    label="备注"
                    min-width="150">
                </el-table-column> 
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

    </div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchSelect from '@/components/SearchSelect.vue'
import {customerInventoryList,exportCustomerInventory} from '@/api/stockManagement.js'
import resize from '@/mixins/resize.js'
export default {
    name:'customerInventory',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return {
            customerInventoryData:{
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            fromGuangyao3:"过去第3个月"+  parseInt(this.isGuangyao) ?"光耀":""+"给其的发卡数",
            fromGuangyao2:"过去第2个月"+ parseInt(this.isGuangyao)?"光耀":""+"给其的发卡数",
            fromGuangyao1:"过去第1个月"+ parseInt(this.isGuangyao)?"光耀":""+"给其的发卡数",

        }
    },
    created(){
        this.getCustomerInventoryList()
    },
    methods:{
        getCustomerInventoryList(){
            let parame = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.customerInventoryData,parame)
            customerInventoryList(this.dicSettingData).then(res=>{
                this.tableData = res.data.rows
                this.total = res.data.count
            })
        },
        onPageChange(page){
			this.page = page
			this.getCustomerInventoryList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getCustomerInventoryList()
        },
        onSearch(){
            this.page = 1
            this.getCustomerInventoryList()
        },
        exportExcel(){
            exportCustomerInventory(this.backOrderFormData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
    },
      computed: {
    ...mapGetters(['isGuangyao']),
  },
    

}
</script>
<style>
    .customer-inventory .el-form-item__label{
        vertical-align: top;
    }
</style>