<template>
  <div class="fadeInRight agentSwitch">
    <div class="title-box clearfix">
      <span class="m-title">代理商开关配置</span>
      <el-form
        :inline="true"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item label="分销商:">
          <el-select
            placeholder="分销商"
            v-model="searchIteam.agentId"
            filterable
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in operationAgent"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        ref="table"
        :max-height="maxTableHeight"
        border
      >
        <el-table-column prop="switchTypeStr" label="开关类型">
          <!-- <template slot-scope="scope">
						<span>{{getSwitchType(scope.row.switchType)}}</span>
					</template> -->
        </el-table-column>
        <el-table-column prop="switchValue" label="开关状态">
          <template slot-scope="scope1">
            <span>{{ getSwitchValue(scope1.row.switchValue) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>

        <el-table-column prop="updateUser" label="修改人"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope2">
            <span v-if="scope2.row.switchValue == 1"
              ><el-button
                class="themed-button"
                type="warning"
                @click="closeSwitch(scope2.row.id)"
                >关闭</el-button
              ></span
            >
            <span v-else-if="scope2.row.switchValue == 0"
              ><el-button
                class="themed-button"
                type="warning"
                @click="openSwitch(scope2.row.id,scope2.row.switchTypeStr)"
                >开启</el-button
              ></span
            >
            <span  style="margin-left:10px" v-if="scope2.row.switchValue == 1&&scope2.row.editable&&scope2.row.switchType==40"
              ><el-button
                class="themed-button"
                type="warning"
                @click="edit(scope2.row)"
                >编辑</el-button></span>
            <!-- 	<el-button class="themed-button" type="info" @click='closeSwitch(scope2.row.id)'>关闭</el-button>
						<el-button class="themed-button" type="info" @click='openSwitch(scope2.row.id)'>开启</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
  title="编辑"
  :visible.sync="dialogVisible"
  width="30%"
  v-if="dialogVisible">
  <el-form :inline="true" :model="editForm" class="demo-form-inline">
  <el-form-item label="vip充值额" >
          <el-input v-model="editForm.configDetail.beVipMoney" @focus="errorShow=false" style='width:200px'></el-input>
          <br>
          <span style="color:#f00;" v-if="errorShow">{{errorMsg}}</span>
        </el-form-item>
     </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="sureEdit">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onresize from "@/mixins/resize.js";
import {
  getAgentSwitchList,
  getAgentStatus,
  changeSwitchStatus,
  configDetail
} from "@/api/agentManagement.js";
import { getOperateList } from "@/api/cardManagement/cardBillDetail.js";
export default {
  name: "agentSwitchConfig",
  mixins: [onresize],
  data() {
    return {
      searchIteam: {
        agentId: "",
      },
      operationAgent: [],
      tabledata: [],
      page: 1,
      pageSize: 15,
      total: 0,
      dialogVisible:false,
      editForm:{
        switchId:"",
        configDetail:{
        beVipMoney:0,
      }
      },
      errorShow:false,
      errorMsg:"",
    
    };
  },
  created() {
    getAgentSwitchList(this.searchIteam).then((responseResult) => {
      //console.log(responseResult)
      if (responseResult.slide_msg_key == 1) {
        this.$alert(responseResult.slide_msg_message_key, "消息提示", {
          confirmButtonText: "确定",
        });
      }
      this.operationAgent = responseResult;
    });
    this.getAgentStatusList(this.searchIteam);
  },
  computed: {
    ...mapGetters(["isCaptal"]),
  },
  methods: {
    edit(row){
     this.dialogVisible = true
     this.editForm.switchId = row.id
     this.editForm.configDetail  =  row.configDetail ?JSON.parse(row.configDetail):{ beVipMoney:"",}
    },
    sureEdit(){
      let val = this.editForm.configDetail.beVipMoney
     	if(val!=null){
											val = val.trim()
										}
										
										if(val===""||val===null) {
                      this.errorMsg = "请输入金额"
                      this.errorShow = true
                    return false;
										}
										if(val<=0) {
                       this.errorMsg = "金额错误。"
                      this.errorShow = true
                    return false;
										}
										if (isNaN(val)) {
                       this.errorMsg = "金额错误。"
                      this.errorShow = true
                    return false;
										}
                    this.editForm.configDetail= JSON.stringify( this.editForm.configDetail)
                    console.log(this.editForm.configDetail)
         configDetail(this.editForm).then(res=>{
            console.log(res)
            this.$message("编辑成功！")
            this.dialogVisible = false
            this.searchData()
         })           
    },
    getAgentStatusList(params) {
      getAgentStatus(params).then((res) => {
        console.log(res.rows);

        this.page = res.pageNo;
        this.pageSize = res.pageSize;

        if (this.isCaptal == "1") {
          this.getOperateList(res.rows);
        } else {
          this.total = res.total;
          this.tabledata = res.rows;
        }
      });
    },
    getOperateList(arrlist) {
      getOperateList({ group: "agent_switch_column" }).then((res) => {
        console.log(res);
        let arr = [];
        arr = res.filter((itemone) => {
          return itemone.dictValue == "1";
        });
        try {
          this.tabledata = arrlist.filter((item) => {
            return arr.some((ele) => {
              console.log(ele.dictKey, item.switchType);
              return ele.dictKey === item.switchType.toString();
            });
          });
          console.log(this.tabledata);
		   this.total = this.tabledata.length
        } catch (error) {}
      });
    },

    getSwitchType(switchType) {
      if (switchType == 1) return "价格修改开关";
      else if (switchType == 2) return "价格促销开关";
      else if (switchType == 10) return "API权限开关";
      else return "其他";
    },
    getSwitchValue(switchValue) {
      if (switchValue == 1) return "打开";
      else return "关闭";
    },
    searchData() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        agentId: this.searchIteam.agentId,
      };
      this.getAgentStatusList(params);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        agentId: this.searchIteam.agentId,
      };
      this.getAgentStatusList(params);
    },
    onPageChange(page) {
      this.page = page;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        agentId: this.searchIteam.agentId,
      };
      this.getAgentStatusList(params);
    },
    closeSwitch(id) {
      let params = {
        switchId: id,
        switchValue: 0,
        pageSize: this.pageSize,
        pageNo: this.page,
        agentId: this.searchIteam.agentId,
      };
      this.changeSwiSta(params);
    },
    openSwitch(id,name) {
      let params = {
        switchId: id,
        switchValue: 1,
        pageSize: this.pageSize,
        pageNo: this.page,
        agentId: this.searchIteam.agentId,
      };
      if(name=="无需绑定手机"){
       	this.$confirm('开启无需绑定手机开关后，会导致部分功能有影响。确认开启么？', '消息提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.changeSwiSta(params);
       
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消开启'
					});
				});
      }else{
      this.changeSwiSta(params);
      }
      
    },
    changeSwiSta(params) {
      changeSwitchStatus(params).then((responseResult) => {
        if (responseResult.slide_msg_key == 1) {
          this.$message({
            message: responseResult.slide_msg_message_key,
          });
        }
        let params = {
          pageSize: this.pageSize,
          pageNo: this.page,
          agentId: this.searchIteam.agentId,
        };
        this.getAgentStatusList(params);
      });
    },
  },
};
</script>

<style>
.agentSwitch .el-form-item__label {
  vertical-align: top;
}
</style>
