<template>

<div class="show-new">
    <div>{{getMealList}}</div>
    <el-form :model="form" label-position="right" label-width="100px" >
        <el-form-item label="选择代理商:" style="display:none;">
            <el-select v-model="form.agentId"  filterable    @change="getAgentMeal">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item,index) in agentList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="所属类别:" style="display:none;">
            <el-select v-model="form.category" @change="changeCategory">
                <!-- <el-option label="电信" value="1"></el-option>
                <el-option label="其他卡务" value="2"></el-option>
                <el-option label="移动" value="3"></el-option>
                <el-option label="联通" value="4"></el-option>
                <el-option label="联通Q" value="6"></el-option>
                <el-option label="DCP电信" value="5"></el-option>
                <el-option label="新移动" value="7"></el-option>
                <el-option label="新联通" value="8"></el-option> -->
                 <el-option label="请选择运营商" value=""></el-option>
                 <el-option v-for="(item,index) in $store.getters.categorys" :label="item.name" :value="item.value" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="form.category == categorySlist['中国移动'] || form.category == categorySlist['中国联通']" label="子类别:">
            <el-select v-model="form.secondCategory" filterable  @change="getAgentMeal">
                <!-- <el-option label="全部" value=""></el-option> -->
                <el-option v-for="(item,index) in secondCategoryList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="套餐选择:"  >
            <!-- <div style="max-height:250px;overflow:auto">
                <el-checkbox-group v-model="promotionMeal">
                    <el-checkbox v-for="(item,index) in mealList" :label="item.id+'@@'+item.name" :key="index">{{item.name}}</el-checkbox>
                </el-checkbox-group>
            </div> -->
             <el-select
    v-model="promotionMeal"
    multiple
    clearable
    filterable
    collapse-tags
    placeholder="请选择套餐">
    <el-option
      v-for="item in mealList"
      :key="item.id"
      :label="item.name"
      :value="item.id+'@@'+item.name">
    </el-option>
  </el-select>
        </el-form-item>
    </el-form>
    <div class="align-right">
        <el-button type="primary" class="themed-button" @click="sure">确定</el-button>
        <el-button type="primary" class="themed-button" @click="cancle">取 消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import { getAgentList,getMealListByAgentId,addUseFilterById } from "@/api/eventDiscountManagement/couponManagement.js";
import {getAccountList} from '@/api/cardManagement/cardBill.js'
import { messageBox, confirmBox } from "@/utils/common.js";
import SearchSelect from "@/components/SearchSelect";
import store from '@/store'
export default {
    props:['promotionId'],
    data(){
        return {
            form:{
                agentId:'',
                filterAgentName:'',
                category:"",
                secondCategory:''
            },
            promotionMeal:[],
            agentList:[],
            secondCategoryList:[],
            mealList:[],
            categorySlist:{}
        }
    },
    mounted(){
          if(store.getters.categorys.length<1){
          store.dispatch('getCategory').then(()=>{
                  if(store.getters.categorys.length>0){
            store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.categorySlist[name] =value
            })
               }
          })
        }else{
             store.getters.categorys.forEach(item=>{
               let name =  item.name
               let value = item.value
                this.categorySlist[name] =value
            })
        }
         if(store.getters.secondCategory_yd.length<1||store.getters.secondCategory_lt.length<1){
           store.dispatch("getPackageType")
        }
        this._getAgentList()
        this.getAgentMeal()
    },
    methods:{
        sure(){
            this.agentList.forEach((item)=>{
                if(item.id == this.form.agentId){
                    this.form.filterAgentName = item.name
                    return 
                }
            })
            if(this.form.agentId == ''){
                this.form.filterAgentName = ''
            }
            let parames = {
                promotionId: this.promotionId,
                promotionMeal:this.promotionMeal.join(',')
            }
            Object.assign(parames,this.form)
            addUseFilterById(parames).then((res)=>{
                if(res.slide_msg_key == 1){
                    messageBox(this.res.slide_msg_message_key)
                    this.$emit('close')
                    return
                }
                this.$emit('refresh')
                this.$emit('close')
                
            })
        },
        cancle(){
            this.$emit('close')
        },
        changeCategory(){
            if(this.form.category == this.categorySlist['中国移动']||this.form.category == this.categorySlist['中国联通']){
                this.secondCategoryList = []
               getAccountList({category:this.form.category}).then((res) => {
                    this.secondCategoryList = [{label:'请选择',value:''}]
                    if(res.length>0){
                        res.forEach((item)=>{
                            this.secondCategoryList.push({label:item.accountName,value:item.secondCategory})
                        })
                    }
                })
            }
              
            // if(this.form.category == this.categorySlist['中国移动']){
            //     // this.secondCategoryList = [
            //     //     {label:'请选择',value:''},
            //     //     {label:'GD_IOT',value:'1'},
            //     //     {label:'OneLink',value:'2'},
            //     //     {label:'深圳移动',value:'3'}
            //     // ]
            //       this.secondCategoryList = []
            //     if(store.getters.secondCategory_yd.length>0){
            //       store.getters.secondCategory_yd.forEach(item=>{
            //           if(item.groupName){
            //          this.secondCategoryList.push({label:item.groupName,value:item.dictValue})
            //           }
            //       }) 
            //     }
            //       this.secondCategoryList.unshift( {label:'请选择',value:''})
            // }else if(this.form.category == this.categorySlist['中国联通']){
            //     // this.secondCategoryList = [
            //     //     {label:'请选择',value:''},
            //     //     {label:'浙江联通',value:'1'},
            //     //     {label:'青岛联通',value:'2'},
            //     //     {label:'湖南联通',value:'3'}
            //     // ]
            //      this.secondCategoryList = []
            //     if(store.getters.secondCategory_lt.length>0){
            //       store.getters.secondCategory_lt.forEach(item=>{
            //           if(item.groupName){
            //          this.secondCategoryList.push({label:item.groupName,value:item.dictValue})
            //           }
            //       }) 
            //     }
            //       this.secondCategoryList.unshift( {label:'请选择',value:''})
            // }
            this.getAgentMeal()
        },
        getAgentMeal(){
            this.promotionMeal = []
            let parames = {
                agtId:this.form.agentId,
                cate1:this.form.category,
                secondCategory2:this.form.secondCategory
            }
            getMealListByAgentId(parames).then((res)=>{
                if(res.mealList!=null && res.mealList.length>0){
                    this.mealList = res.mealList
                }else{
                    this.mealList = []
                }
                
            })
        },
        _getAgentList(){
            let parames = {
                promotionId:this.promotionId,
                status:0
            }
            Object.assign(parames,this.form)
            getAgentList(parames).then((res)=>{
                if(res.length>0){
                    this.agentList = res
                }else{
                    this.agentList = []
                }
            })
        }
    },
   computed:{
        getMealList(){
            let newArr =[]
            let result =""
            if(this.promotionMeal.length>0){
      this.promotionMeal.forEach(item=>{
    //  console.log(item.substring(item.indexOf("@@")+2))   
       newArr.push(item.substring(item.indexOf("@@")+2))
})
            }
            if(newArr.length==0){
              result=""
            }else{
                result=newArr.join(",")
            }
            return result
       
        }
    }
}
</script>


