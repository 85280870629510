<template>
    <div class="single-card-limit fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">单卡限速</span>
            <el-form inline ref="searchForm" v-model="searchForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="searchForm.iccidMark" placeholder="iccid" class="iccid-width"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="searchForm.shortIccid" placeholder="短号" ></el-input>
                </el-form-item>

                <el-form-item label="运营商">
                    <el-select v-model="searchForm.accountId" filterable placeholder="运营商">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                 <!-- <el-form-item label="代理商">
                    <el-select v-model="searchForm.agentId" placeholder="请选择" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in agentIdList" :key='item.index' :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="代理商">
                    <el-input v-model="searchForm.agentName" placeholder="代理商" class="iccid-width"></el-input>
                </el-form-item>
  
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>

                <el-form-item>
						<el-button icon="el-icon-plus" type="info" class="themed-button" @click="handleAdd">新增</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="importByExcel">批量导入</el-button>
				</el-form-item>
                
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    prop="shortIccid"
                    label="短号"
                    min-width="130"
                    />
                <el-table-column
                    show-overflow-tooltip
                    prop="accessNumber"
                    label="接入号"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="accountName"
                    label="运营商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="agentName"
                    label="代理商"
                    min-width="150"
                    >
                </el-table-column>
                <el-table-column
                    label="卡状态"
                    min-width="120"
                    prop="cardStatusStr"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="curSpeed"
                    label="真实速率"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="speedVal"
                    label="限速值"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="deviceSpeedValue"
                    label="设备速率"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="限速规则"
                    min-width="120"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.limitRuleId"> {{scope.row.limitRuleName}} </span>
                        <span v-else>固定值</span>
                    </template>
                    
                </el-table-column>

                <el-table-column
                    show-overflow-tooltip
                    prop="updateTime"
                    label="操作时间"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    prop="updateBy"
                    label="操作人"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="remark"
                    label="备注"
                    min-width="120"
                    >
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="180">
                    <template slot-scope="scope">
                        <el-button  size="mini" class="themed-button" type="info" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button  size="mini" class="themed-button" type="danger" @click="handleDelete(scope.row.iccidMark)">删除</el-button>
                    </template>
                </el-table-column>
                
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog :title="addTitle" :visible.sync="dialogAddvisible" width="500" :destroy-on-close='true'>
            <el-form :model="addForm"  ref="addForm" label-width="150px">
                <el-form-item label="iccid/短号">
                    <el-input v-model="addForm.iccidMark" @blur='queryIccidInfo'></el-input>
                </el-form-item>

                <el-form-item label="运营商">
                    <el-input v-model="addForm.accountName" disabled></el-input>
                </el-form-item>
                
                <el-form-item label="代理商">
                    <el-input v-model="addForm.agentName"  disabled></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-input v-model="addForm.status" disabled></el-input>
                </el-form-item>

                <el-form-item label="限速类型">
                    <el-select v-model="limitRuleType" placeholder="请选择" @change="changeRuleType">
                        <el-option label="固定值" value="1"></el-option>
                        <el-option label="限速规则" value="2"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="速率" v-if="limitRuleType == 1">
                    <el-select v-model="addForm.speed" placeholder="请选择" filterable @change="getDeviceSpeed">
                        <el-option v-for="(item,index) in speedList" :key='index' :label="item.speedKey" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="限速规则" v-if="limitRuleType == 2">
                    <el-select v-model="addForm.limitRuleId" placeholder="请选择" filterable @change="$forceUpdate()">
                        <el-option v-for="(item,index) in limitRuleList" :key='index' :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注">
                    <el-input v-model="addForm.remark"></el-input>
                </el-form-item>
                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="submitForm">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogAddvisible = false">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="批量导入" :visible.sync="dialogImport" width="500" :destroy-on-close='true'>
            <el-form label-position="right" label-width="120px">
                <el-form-item label="选择excel文件:" required>
                    <input class="file-input" type="file" ref='file'/>
                    <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                    </div>
                </el-form-item>
                <el-form-item label="运营商:"  required>
                    <el-select v-model="importAccountId" filterable placeholder="运营商" @change='changeAccount'>
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="item in accountIdList" :key="item.index" :label="item.accountName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="限速类型:">
                    <el-select v-model="limitRuleType" placeholder="请选择" @change="changeLimitRuleType">
                        <el-option label="固定值" value="1"></el-option>
                        <el-option label="限速规则" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="限速速率:" v-if="limitRuleType == 1"  required>
                    <el-select v-model="importSpeedId" filterable placeholder="限速速率" @change="getDeviceSpeedValue">
                        <el-option v-for="item in accountSpeedList" :key="item.index" :label="item.speedKey" :value="item.speedValue"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="限速规则" v-if="limitRuleType == 2">
                    <el-select v-model="limitRuleId" placeholder="请选择" filterable>
                        <el-option v-for="(item,index) in limitRuleList" :key='index' :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="themed-button" @click="uploadExcel">导入excel模板</el-button>
                <el-button type="primary" class="themed-button" @click="exportTemplateExcel('importIccidOrShort.xls')">导出excel模板</el-button>
                <el-button type="primary" class="themed-button" @click="dialogImport =false">取消</el-button>
            </div>
    </el-dialog>


        
    </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {agentOperatorList} from '@/api/order.js'
import {getAgentList} from "@/api/agentManagement.js"
import {messageBox,confirmBox} from '@/utils/common.js'
import {exportTemplate} from '@/api/statistics.js'
import {singleCardList,findSpeedByIccid,saveSingleLimit,exportSingleCardLimit,deleteSingleLimit,importSingleLimitByExcel, limitRuleListSeal} from "@/api/speedLimit/index.js";
import {getSpeedList} from '@/api/virtualCard/accountRateList.js'
export default {
     mixins:[resize],
    data(){
        return {
            accountSpeedList:[],
            accountIdList:[],
            agentIdList:[],
            searchForm:{
                iccidMark:'',
                // agentId:'',
                accountId:'',
                agentName:"",
                shortIccid:"",
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            addTitle:'',
            dialogAddvisible:false,
            addForm:{
                iccidMark:'',
                agentId:'',
                accountId:'',
                status:'',
                speed:'',
                remark:'',
                type:'',
                deviceSpeedValue:""
            },
            limitRuleType: '1',
            limitRuleList:[],
            limitRuleId:'',
            importAccountId:'',
            importSpeedId:'',
            dialogImport:false,
            speedList:[]
        }
           
    },
    created(){
        this.getAgentOperatorList()
        this.getAgentList()
        this.getSingleCardLimitList()
    },
    methods: {
        _getLimitRuleList(accountId){
            this.limitRuleList = []
            limitRuleListSeal({accountId}).then(res=>{
                let list = res.rows
                if(res.rows.length>0){
                    list.forEach(item=>{
                        this.limitRuleList.push({name:item.name,id:item.id})
                    })
                }
            })
        },
        changeLimitRuleType(value){
            if(value == '1'){  //固定值
                this._getSpeedList(this.importAccountId)
            }else{  //限速规则
                this.limitRuleId = ''
                this._getLimitRuleList(this.importAccountId)
            }
        },
        changeRuleType(value){
            if(!this.addForm.iccidMark){
                messageBox(this,'输入iccid')
                return
            } 
            if(value == '2'){  //获取限速规则
                this.addForm.limitRuleId = ''
                this._getLimitRuleList(this.addForm.accountId)
            }
        },
        getAgentOperatorList(){
            agentOperatorList().then(res=>{
                this.accountIdList = res        
             })
        },
        getAgentList(){
             getAgentList().then(res=>{
				this.agentIdList = res
			})
        },
        getSingleCardLimitList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.searchForm,params)
            singleCardList(this.searchForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },

        onPageChange(page){
			this.page = page
			this.getSingleCardLimitList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getSingleCardLimitList()
        },

        onSearch(){
            this.page=1
            this.getSingleCardLimitList()
        },

        handleAdd(){ 
            this.dialogAddvisible = true
            this.resetAddForm()
            this.addTitle =  '新增'
            this.addForm.type = '1'
            this.limitRuleType = "1"
            this.limitRuleList = []
        },

        handleEdit(data){
            this.addForm.iccidMark = data.iccidMark
            this.dialogAddvisible = true
            this.addTitle =  '编辑'
            this.addForm.type = '2'
            if(data.limitRuleId){
                this.limitRuleType = '2'
                this.addForm.limitRuleId = data.limitRuleId + ''
            }else{
                this.limitRuleType = '1'
            }
            findSpeedByIccid({iccidMark:data.iccidMark}).then(res=>{
                this.speedList = res.speedList
                this.addForm.speed = data.speed
                this.addForm.remark = data.remark
                this.addForm.accountName = res.accountName
                this.addForm.accountId = res.accountId
                this.addForm.agentName = res.agentName
                this.addForm.status = res.statusStr
                if(this.limitRuleType == '2'){
                    this._getLimitRuleList(this.addForm.accountId)
                }
            })
        },

        importByExcel(){
            this.limitRuleType = '1'
            this.dialogImport = true
            this.importAccountId = ''
            this.importSpeedId = ''
            this.importDeviceSpeed  = ""
            this.changeAccount('')
        },

        changeAccount(accountId){
            if(this.limitRuleType == '1'){  //固定值
                this._getSpeedList(accountId)
            }else{  //限速规则
                this.limitRuleId = ''
                this._getLimitRuleList(accountId)
            }
        },
        _getSpeedList(accountId){
            this.accountSpeedList=[]
            this.importSpeedId = ''
            getSpeedList({accountId}).then(res=>{
                let list= res.rows
                if(list.length>0){
                    list.forEach((item)=>{
                        this.accountSpeedList.push({speedKey:item.speedKey,speedValue:item.id,deviceSpeedValue:item.deviceSpeedValue})
                    })
                }
            })
        },

        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },

        comfirmImport(){
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    if(this.importAccountId === ''){
                        messageBox(this,'请选择运营商')
                        return
                    }
                    if(this.importSpeedId === '' && this.limitRuleType == '1'){
                        messageBox(this,'请选择限速速率')
                        return
                    }
                    if(this.limitRuleId === '' && this.limitRuleType =='2'){
                        messageBox(this,'请选择限速规则')
                        return
                    }
                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('accountId',this.importAccountId)
                    if(this.limitRuleType == '1'){
                        params.append('speedId',this.importSpeedId)
                         params.append('deviceSpeedValue',this.importDeviceSpeed)
                        
                    }else{
                        params.append('limitRuleId',this.limitRuleId)
                    }
                    
                    importSingleLimitByExcel(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },

        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },
        
        resetAddForm(){
            this.addForm = {
                iccidMark:'',
                agentName:'',
                accountName:'',
                status:'',
                speed:'',
                type:'',
                deviceSpeedValue:""
            }
            this.speedList = []
        },

        queryIccidInfo(){
            if(!this.addForm.iccidMark){
                messageBox(this,'输入iccid')
                return
            }
            findSpeedByIccid({iccidMark:this.addForm.iccidMark}).then(res=>{
                this.speedList = res.speedList
                this.addForm.accountName = res.accountName
                this.addForm.agentName = res.agentName
                this.addForm.status = res.statusStr
                this.addForm.accountId =res.accountId
                if(this.limitRuleType == '2'){
                    this.addForm.limitRuleId = ''
                    this._getLimitRuleList(this.addForm.accountId)
                }
                
            })
        },
        getDeviceSpeed(val){
         console.log(val,542)
        this.speedList.forEach(item=>{
            if(item.speedValue==val){
             this.addForm.deviceSpeedValue = item.deviveSpeedValue
            }
        })
        },
        getDeviceSpeedValue(val){
        console.log(val,552)
        this.accountSpeedList.forEach(item=>{
            if(item.speedValue==val){
             this.importDeviceSpeed = item.deviveSpeedValue
            }
        })
        },

        submitForm(){
            if(!this.addForm.iccidMark || (this.limitRuleType == '1' && !this.addForm.speed) || (this.limitRuleType == '2' && !this.addForm.limitRuleId)){
                messageBox(this,'请完善信息')
                return
            }
            if(this.limitRuleType == '2'){
                this.addForm.speed = ""
            }else{
                this.addForm.limitRuleId = ""
            }
            saveSingleLimit(this.addForm).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                    });
                this.dialogAddvisible = false
                this.getSingleCardLimitList()
            })
        },

        handleDelete(iccidMark){
            this.$confirm('确定删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteSingleLimit({iccidMark}).then(res=>{
                    this.$message({
                    type: 'success',
                    message: '删除成功!'
                    });
                    this.getSingleCardLimitList()
                })
                
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消'
                });          
            });
        },

        exportExcel(){
            exportSingleCardLimit(this.searchForm).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },

    },
}
</script>

<style>
    .single-card-limit .el-form-item__label{
        vertical-align: top;
    }
</style>