<template>
  <div>
    <el-form :model="form" ref="dataForm">
      <el-form-item label="类型:" :label-width="formLabelWidth">
        <el-radio-group v-model="form.type" @change="changeType">
          <el-radio :label="1">文字</el-radio>
          <el-radio :label="2">图片</el-radio>
          <el-radio :label="3">链接</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="文字内容:"
        :label-width="formLabelWidth"
        v-if="form.type == 1"
      >
        <el-input v-model="form.text" autocomplete="off"></el-input>
      </el-form-item>
      <!-- <el-form-item label="图片:"  :label-width="formLabelWidth"  v-if="form.type==2">
     
</el-form-item> -->

      <el-form-item
        label="图片:"
        :label-width="formLabelWidth"
        v-if="form.type == 2"
      >
        <!-- <el-image v-if="jsonObject.pic1" style="width: 100px; height: 100px" :src="jsonObject.pic1" ></el-image>
            <el-button v-if="jsonObject.pic1" type="primary" icon="el-icon-delete" @click="jsonObject.pic1 = ''" ></el-button> -->
        <input
          class="file-input"
          type="file"
          ref="fileImportInput"
          @change="fileChange"
        />
      </el-form-item>
      <!-- <el-form-item
        label="图片链接:"
        :label-width="formLabelWidth"
        v-if="form.type == 2"
      >
        <el-input v-model="form.url" autocomplete="off"></el-input>
      </el-form-item> -->

      <el-form-item
        label="链接文字:"
        :label-width="formLabelWidth"
        v-if="form.type == 3"
      >
        <el-input
          v-model="form.text"
          autocomplete="off"
          placeholder="输入链接文字"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="链接地址:"
        :label-width="formLabelWidth"
        v-if="form.type == 3"
      >
        <el-input
          v-model="form.url"
          autocomplete="off"
          placeholder="输入链接地址"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeForm" type="primary" class="themed-button"
        >取 消</el-button
      >
      <el-button type="primary" @click="sureAdd" class="themed-button"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
import { uploadPayOfflineUrl } from "@/api/agentManagement.js";

export default {
  name: "step",
  props:["rowData","index"],
  data() {
    return {
      form: {
        type: 1,
        text: "",
        url: "",
      },
      formLabelWidth: "110px",
    };
  },
  created(){
  console.log(this.rowData,this.rowData.type)
 if(this.rowData.type){
   this.form.type = this.rowData.type
   this.form.text = this.rowData.text
   this.form.url = this.rowData.url
 }



  },

  methods: {
    changeType(val) {
      console.log(val);
      this.form.text = "";
      this.form.url = "";
     
    },
    sureAdd() {
      if(this.index!=""){
       this.$emit("addEvent", this.form,this.index);
      }else{
        this.$emit("addEvent", this.form);
      }
      
      this.$emit("close");
    },
    closeForm() {
      this.$emit("close");
    },
    fileChange(val) {
      if (this.$refs["fileImportInput"].files[0]) {
        let fileName = this.$refs["fileImportInput"].files[0].name;
        let index = fileName.lastIndexOf(".");
        if (
          index < 0 ||
          !(
            fileName.substring(index + 1, fileName.length) == "png" ||
            fileName.substring(index + 1, fileName.length) == "jpg" ||
            fileName.substring(index + 1, fileName.length) == "jpeg" ||
            fileName.substring(index + 1, fileName.length) == "svg"
          )
        ) {
          this.$alert("文件格式错误", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        let param = new FormData();
        param.append("file", this.$refs["fileImportInput"].files[0]);
        uploadPayOfflineUrl(param).then((res) => {
          this.form.url = res.url;
        });
      }
    },
  },
};
</script>

<style scoped>
</style>