/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from '../request.js'

export function bindBankList(params){
    return request({
        url: 'bindBank/list.do?',
        method: 'post',
        data:params
    })
}

export function addBindBank(params){
    return request({
        url:'bindBank/add.do?',
        method:'post',
        data:params
    })
}

export function exportBindBank(params){
    return request({
        url:'bindBank/exportSync.do?',
        method:'post',
        data:params
    })
}

export function findBankById(params){
    return request({
        url:'bindBank/findById.do?',
        method:'post',
        data:params
    })
}

export function deleteBindBank(params){
    return request({
        url:'bindBank/deleteById.do?',
        method:'post',
        data:params
    })
}

